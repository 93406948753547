import { DialogContent, Divider, Button, IconButton, Chip, Typography, makeStyles, Snackbar, Fade } from "@material-ui/core"
import { Dialog } from "@mui/material"
import { CartCheckIcon, CircleCheckIcon, IData, IDataResponse, SaveForLaterInCart } from "../../../../../../../../../../../../common"
import { Theme } from "../../../../../../../../../../../../theme"
import CloseIcon from '@material-ui/icons/Close';
import TableContent from "../../../data/components/TableContent"
import { useContext, useEffect, useState } from "react";
import BrowseCollectionsPopupContext from "../../../../../../browseCollectionsPopupContext/browseCollectionsPopup.context";
import { TransitionProps } from "@material-ui/core/transitions";
import ArrowBackIcon from '@material-ui/icons/ArrowBack';

export const MINIMUM_DATA_COUNT_REQUIRED_TO_PROCEED_AHEAD = 5
export const MINIMUM_DATA_COUNT_REQUIRED_TO_PROCEED_AHEAD_FOR_AiAssistedLabelling = 1

type props = {
	open: boolean,
	scenario: "dataCart" | "savedForLater",
	isAiAssistedLabellingDataCartScenario?: boolean,
	setOpen: (newState: boolean) => void,
	setDataCartOpen: (newState: boolean) => void,
	aiAssistedLabelledData?: IData
	isPageOpenFromHomePageChatModelSummaryPage?:boolean
}

const { primary, grey } = Theme.palette

export const useStyles = makeStyles({
	dialogPaper: {
		maxWidth: '100vw !important',
		height: '100vh !important'
	},
	dialogTitleRoot: {
		display: "flex",
		alignItems: "center",
		flexDirection: "row",
		justifyContent: "space-between"
	},
	dataCountChipContained: {
		borderRadius: '8px',
		backgroundColor: 'white',
		margin: '0 6px',
		boxShadow: '#00000029 0px 0px 10px 0px',
		height: '1.5rem',
		color: primary.main,
		'&:hover': {
			cursor: 'pointer'
		}
	},
	dataCountChipOutlined: {
		borderRadius: '8px',
		color: primary.main,
		border: `solid 1px ${primary.main}`,
		backgroundColor: 'white',
		margin: '0 6px',
		boxShadow: '#00000029 0px 0px 10px 0px',
		fontSize: '1.1rem'
	},
	snackbarRoot: {
		backgroundColor: '#EF8A8A',
		border: 'solid #bb6161 1.5px',
		fontSize: '1rem',
		color: 'black',
		marginBottom: '8rem'
	}
})


const SelectedDataListDialog = (props: props) => {

	const classes = useStyles()
	const context = useContext(BrowseCollectionsPopupContext)
	const {isPageOpenFromHomePageChatModelSummaryPage}=props

	const {
		itemsAddedToCart,
		itemsSavedForLater
	} = context

	const [selectedItems, setSelectedItems] = useState<IData[]>([])
	const [snackbarState, setSnackbarState] = useState<{
		open: boolean;
		Transition: React.ComponentType<TransitionProps & { children?: React.ReactElement<any, any> }>;
	}>({
		open: false,
		Transition: Fade,
	})

	const MINIMUM_DATA_COUNT_REQUIRED_IN_DATA_CART_TO_PROCEED_AHEAD = (() => {
		if (props.isAiAssistedLabellingDataCartScenario) {
			return 1;
		}
		return MINIMUM_DATA_COUNT_REQUIRED_TO_PROCEED_AHEAD
	})();

	useEffect(() => {
		// if (props.open === false) {
		// 	setSnackbarState((prevState) => {
		// 		return {
		// 			...prevState,
		// 			open: false
		// 		}
		// 	})
		// }

		if(context.itemsAddedToCart.resources?.length <  MINIMUM_DATA_COUNT_REQUIRED_IN_DATA_CART_TO_PROCEED_AHEAD){
			setSnackbarState((prevState) => {
				return {
					...prevState,
					open: true
				}
			})
		}
		// if(selectedItems.length < MINIMUM_DATA_COUNT_REQUIRED_TO_PROCEED_AHEAD){
		// 	setSnackbarState((prevState) => {
		// 		return {
		// 			...prevState,
		// 			open: true
		// 		}
		// 	})
		// }
		if(context.itemsAddedToCart.resources?.length >= MINIMUM_DATA_COUNT_REQUIRED_IN_DATA_CART_TO_PROCEED_AHEAD){
			setSnackbarState((prevState) => {
				return {
					...prevState,
					open: false
				}
			})
		}

	}, [context.itemsAddedToCart, props.open, selectedItems])

	return (
		<Dialog
			open={props.open}
			fullWidth
			classes={{ paper: classes.dialogPaper }}
		>
			<div style={{ display: 'flex', justifyContent: 'space-around', padding: '22px 0' }}>
				<div style={{ display: 'flex', alignItems: 'center', marginLeft: '12px' }}>
					<IconButton onClick={() => props.setOpen(false)}>
						<ArrowBackIcon style={{color: 'black'}} />
					</IconButton>
					{
						props.scenario === "savedForLater" && <SaveForLaterInCart style={{width: '1.5rem'}} />
					}
					<Typography style={{ fontWeight: 'bold', display: 'inline-block', fontSize: '1.5rem', margin: '0 12px', whiteSpace: 'nowrap' }}>
						{
							props.isAiAssistedLabellingDataCartScenario
							? "Selected Data"
							:	props.scenario === "dataCart" 
								? "Data Cart" 
								: "Saved For Later"
						}
					</Typography>
					{
						!props.isAiAssistedLabellingDataCartScenario && props.scenario === "dataCart" && <CartCheckIcon style={{ margin: '0 20px 0 0', minWidth: '2rem' }} fill="black" stroke="black"/>
					}
					{
						!props.isAiAssistedLabellingDataCartScenario &&
						<Chip
							label={
								props.scenario === "dataCart" ? (itemsAddedToCart.resources?.length || 0) : (itemsSavedForLater.resources?.length || 0)
							}
							className={classes.dataCountChipOutlined}
							style={props.scenario === "savedForLater" ? {border: 'none', color: 'black'} : {}}
						/>
					}
				</div>
				<div style={{ display: 'flex', margin: '0 12px 0 auto', alignItems: 'center', gap: '12px' }}>
					{
						!props.isAiAssistedLabellingDataCartScenario &&
						props.scenario === "dataCart"
						&&
						<Button
							disabled={selectedItems.length === 0||isPageOpenFromHomePageChatModelSummaryPage}
							variant="contained"
							color="primary"
							style={{ width: '316px', height: '2.5 rem' }}
							onClick={() => {
								context.setItemsSavedForLater({ resources: [...(context.itemsSavedForLater.resources || []), ...selectedItems.filter(item => !context.itemsSavedForLater.resources?.includes(item))] })
								context.setItemsAddedToCart({... context.itemsAddedToCart, resources: [...context.itemsAddedToCart.resources?.filter(item => !selectedItems.includes(item))] })
								setSelectedItems([])
							}}
						>
							<SaveForLaterInCart style={{ height: '1.5rem' }} fill="white"/>
							<Typography style={{ margin: '0 6px', textTransform: 'none' }}>Save for Later</Typography>
							{
								!selectedItems.length
								&&
								<Chip
									label={context.itemsSavedForLater.resources?.length || 0}
									className={classes.dataCountChipContained}
								/>
							}
							
						</Button>
					}
					{
						!props.isAiAssistedLabellingDataCartScenario &&
						<Button
							disabled={isPageOpenFromHomePageChatModelSummaryPage?true:props.scenario === "dataCart" && (context.itemsAddedToCart.resources?.length < MINIMUM_DATA_COUNT_REQUIRED_IN_DATA_CART_TO_PROCEED_AHEAD || selectedItems.length < 5)}
							variant="contained"
							color="primary"
							style={{ width: '316px', height: '2.5rem' }}
							onClick={() => {
								if(props.scenario === "dataCart"){
									props.setOpen(false)
								}
								else{
									props.setDataCartOpen(true)
								}
							}}
						>
							{
								props.scenario === "dataCart" &&
								<>
									<CircleCheckIcon />
									<Typography style={{ margin: '0 6px', textTransform: 'none' }}>Confirm Dataset</Typography>
								</>
							}
							{
								props.scenario === "savedForLater" &&
								<>
									<CartCheckIcon fill="white" stroke="white" style={{width: '1.5rem'}}/>
									<Typography style={{ margin: '0 6px', textTransform: 'none' }}>Data Cart</Typography>
									<Chip
										label={context.itemsAddedToCart.resources?.length || 0}
										className={classes.dataCountChipContained}
									/>
								</>
							}
						</Button>
					}
					<IconButton
						onClick={() => props.setOpen(false)}
						style={{ display: 'flex', marginLeft: '20px', color: 'black' }}
					>
						<CloseIcon />
					</IconButton>
				</div>
			</div>
			<Divider />
			<DialogContent>
				<TableContent
					scenario={props.scenario}
					rowsForDataCart={
						props.aiAssistedLabelledData
						?	[props.aiAssistedLabelledData]
						:	props.scenario === "dataCart" 
							?	itemsAddedToCart.resources 
							:	itemsSavedForLater.resources
					}
					currentSelectedItems={selectedItems}
					setCurrentSelectedItems={setSelectedItems}
					isPageOpenFromHomePageChatModelSummaryPage={isPageOpenFromHomePageChatModelSummaryPage}
				/>
			</DialogContent>
			{
				props.scenario === "dataCart"
				&&
				<Snackbar
					open={snackbarState.open}
					// onClose={() => setSnackbarState(prevState => {
					// 	return {
					// 		...prevState,
					// 		open: false
					// 	}
					// })}
					TransitionComponent={snackbarState.Transition}
					message={<div>&#9888; &nbsp; Please add at least {MINIMUM_DATA_COUNT_REQUIRED_IN_DATA_CART_TO_PROCEED_AHEAD} resources to proceed ahead</div>}
					key={snackbarState.Transition.name}
					ContentProps={{
						classes: { root: classes.snackbarRoot }
					}}
				/>
			}

		</Dialog>
	)
}

export default SelectedDataListDialog
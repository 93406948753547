import { v4 as uuidv4 } from "uuid";

const GUEST_SESSION_ID_LOCAL_STORAGE_KEY = "guestSessionId";

export class GuestSessionIdService {
  private generateGuestSessionId(params: {
    organisationName: string;
    chatAssistantName: string;
  }): string {
    const guestSessionId = `${params.organisationName}-${
      params.chatAssistantName
    }-${uuidv4()}`;
    return guestSessionId;
  }

  static clearGuestSessionId(): void {
    localStorage.removeItem(GUEST_SESSION_ID_LOCAL_STORAGE_KEY);
  }

  /**
   * Retrieves the guest session ID from local storage if it exists,
   * otherwise generates a new one using the provided parameters and stores it in local storage.
   * @returns The guest session ID.
   */
  static getGuestSessionId(params: {
    organisationName: string;
    chatAssistantName: string;
  }): string {
    // Check if the guest session ID is already stored in local storage
    const guestSessionIdStoredInLocalStorage = localStorage.getItem(
      GUEST_SESSION_ID_LOCAL_STORAGE_KEY
    );
    if (guestSessionIdStoredInLocalStorage) {
      // Return the stored guest session ID
      return guestSessionIdStoredInLocalStorage;
    }

    // Generate a new guest session ID
    const guestSessionIdService = new GuestSessionIdService();
    const guestSessionId = guestSessionIdService.generateGuestSessionId(params);

    // Store the generated guest session ID in local storage
    localStorage.setItem(GUEST_SESSION_ID_LOCAL_STORAGE_KEY, guestSessionId);

    // Return the generated guest session ID
    return guestSessionId;
  }
}

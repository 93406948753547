import { Box, Input, InputAdornment, Grid, Card, CardContent, Typography, Button, IconButton, Chip, Avatar, Link, Breadcrumbs, makeStyles, CircularProgress } from '@material-ui/core';
import { generateRedictionLinkForTestAndTrainDatasets } from '../../projects/features/aiMarketPlace/components/AIMarketPlaceViewModel';
import Skeleton from '@material-ui/lab/Skeleton';
import { ILatestModel, IPopularModel } from '../../projects/features/aiMarketPlace/aiMarketPlaceInterfaces/interfaces';
import { loaders } from '../../../../../common';
import { useHistory, useLocation } from 'react-router';
import OpenInNewIcon from '@material-ui/icons/OpenInNew';
import {useStyles} from '../chatbot/chatMessage.component'
const TestDataSetPreviewStyles = makeStyles((theme) => ({
    root: {
        display: 'flex',
    },
    loader: {
        ...loaders.centralLoader,
        left:'50%'
    },
    customSampleTestDatasetCSSinBrowsingMode:{
        padding:'16px',
        borderRadius:'12px',
        backgroundColor:'white',
        border: '1px solid #D7D6D6'
    },
    resourcesImagesDivCssinBrowsingMode:{
        boxShadow:'0px 0px 24px 0px rgba(0, 0, 0, 0.16)',
        borderRadius: '12px',
        padding: '7.68px',
    }
}));

const TestDatasetPreview = (props:any) => {
    const TestDataSetPreviewCSS= TestDataSetPreviewStyles();
    const classes = useStyles();
    const history=useHistory()

    const {modelFetchStatus,fetchedModelResources,availableModelsData,isFetching,scenario}=props
    console.log({availableModelsData,fetchedModelResources})
    const heightAndWidthOfResourceImages = scenario==="homePageBrowsingMode"?'100px':'120px'
    // const isGeneralPurposeModel = availableModelsData[0]?.purposeModel?.purposeType === "general" ? true : false
    // const trainDataSetName = isGeneralPurposeModel ? "" : availableModelsData[0]?.trainingDataDirectory?.[0] || "NA"
    // const trainDataSetLink = isGeneralPurposeModel ? "" :generateRedictionLinkForTestAndTrainDatasets(
    //     {
    //         projectName: availableModelsData[0]?.project?.name,
    //         modelName: availableModelsData[0]?.model?.name,
    //         projectId: availableModelsData[0]?.project?._id,
    //         modelId: availableModelsData[0]?.model?._id,
    //         modelType: availableModelsData[0]?.model?.type,
    //         dataSetName: availableModelsData[0]?.trainingDataDirectory?.[0]
    //     }
    // )
    // const testDataSetName = isGeneralPurposeModel ? availableModelsData[0]?.purposeModel?.testDataSet?.label : availableModelsData[0]?.checkpointFiles?.files?.analysis?.dataSetCollection?.name;
    // const testDataSetLink = isGeneralPurposeModel ? availableModelsData[0]?.purposeModel?.testDataSet?.link : generateRedictionLinkForTestAndTrainDatasets(
    //     {
    //         projectName: availableModelsData[0]?.project?.name,
    //         modelName: availableModelsData[0]?.model?.name,
    //         projectId: availableModelsData[0]?.project?._id,
    //         modelId: availableModelsData[0]?.model?._id,
    //         modelType: availableModelsData[0]?.model?.type,
    //         dataSetName: testDataSetName
    //     }
    // )
    const handleViewMoreButtonClick = () => {
        // const url = generateModelAnalyticsLink(availableModelsData[0]);
        // history.push(url);
      };
    // const generateModelAnalyticsLink = (TrainingPodDetails: ILatestModel | IPopularModel) => {
    //     // @ts-ignore
    //     let accuracy = TrainingPodDetails?.checkpointFiles?.files?.analysis?.cachedMetrics?.accuracy?.accuracy
    //     const isSegmentationModelEnabled = TrainingPodDetails?.model?.isSegmentationModelAnalytics
    //     let url = `/admin/projects/${TrainingPodDetails?.project?.name}/${TrainingPodDetails?.model?.name}/collections/modelAnalytics/resources?model=${TrainingPodDetails?.model?._id}&project=${TrainingPodDetails?.project?._id}&limit=10&offset=1&type=${TrainingPodDetails?.model?.type}&status=dashboard&modelCollectionIdFilterSelected=${TrainingPodDetails?._id}&modelCollectionIdsToCompare=${TrainingPodDetails?._id}&scenario=integrity&accuracy=${accuracy}&sortField=predicted_status&sortOrder=ascending&bandFilterSelected=ALL&`
    //     if (isSegmentationModelEnabled) {
    //         url += `&iouThresholdValue=${TrainingPodDetails?.model?.iouThresholdValue}`
    //     }
    //     return url
    // }
    return (
        <Box display="flex" flexDirection="column" style={{gap:'1rem'}} className={`${scenario==="homePageBrowsingMode" &&!isFetching&& TestDataSetPreviewCSS.customSampleTestDatasetCSSinBrowsingMode}`}>
            {isFetching ? <Box className={TestDataSetPreviewCSS.loader}>
                <CircularProgress size={30} />
            </Box>:<>
            <Box display="flex" flexWrap="wrap" style={{gap:'1rem'}} mt={2}>
                {availableModelsData &&
                    availableModelsData.map((eachModelData: IPopularModel | ILatestModel) => {
                        const modelId = eachModelData?.purposeModel?._id;
                        const modelStatus = modelFetchStatus[modelId] || {};
                        const modelResources = fetchedModelResources[modelId] || {};

                        // Check if there are no fetching statuses or resources
                        const noResourcesAvailable = Object.keys(modelStatus).length === 0 && Object.keys(modelResources).length === 0;

                        return noResourcesAvailable ? (
                            <Typography key={modelId}>No resources available</Typography>
                        ) : (
                            <Box  display="flex" flexWrap="wrap" style={{gap:'1rem'}} className={`${scenario==="homePageBrowsingMode" &&!isFetching&& TestDataSetPreviewCSS.resourcesImagesDivCssinBrowsingMode}`}>
                                {/* Iterate over modelFetchStatus */}
                                {Object.entries(modelStatus).map(([resourceId, isFetching]) => {
                                    if (isFetching) {
                                        return <Skeleton key={resourceId} variant="rect" width={heightAndWidthOfResourceImages} height={heightAndWidthOfResourceImages} style={{borderRadius:'8px'}} />;
                                    }

                                    // Check if the resource is available
                                    const resourceData = modelResources[resourceId];
                                    if (resourceData) {
                                        return (
                                            <img
                                                key={resourceId}
                                                src={resourceData.resource}
                                                alt="Resource"
                                                style={{width:heightAndWidthOfResourceImages, height:heightAndWidthOfResourceImages,borderRadius:'8px',border: '0.609px solid #C5CAD2'}}
                                            />
                                        );
                                    }

                                    return null; // Skip if neither fetching nor resource available
                                })}
                            </Box>
                        );
                    })}
            </Box>
            {scenario==="homePageBrowsingMode" && <Box display="flex" mt={1}>
                <Button variant='outlined' size="small" disabled={true} className={classes.OpenInNewButton} onClick={handleViewMoreButtonClick} endIcon={<OpenInNewIcon />}>View More</Button>
            </Box>}
            </>
            }
        </Box>
    )
}
export default TestDatasetPreview 
import { IconButton, makeStyles } from "@material-ui/core";
import { AddToPhotos } from "@material-ui/icons";

const useStyles = makeStyles((theme) => ({
  icon: {
    cursor: "pointer",
  },
}));

export default function AddResourceToDataSetCollectionButton(props: {
  onClick: () => void;
}) {
  const classes = useStyles();
  return (
    <IconButton
      onClick={() => props.onClick()}
      color="primary"
      className={`${classes.icon}`}    
      style={{outline: "initial"}}    
    >
      <AddToPhotos />
    </IconButton>
  );
}

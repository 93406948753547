import { connect, ConnectedProps } from "react-redux";

import { IReduxState } from "../../../../../../../../../../../common";
import { fetchDataSetCollectionsForDataTableFilter, setShouldBlockDataTableKeyboardEvents, updateResourceInUI } from '../../../../../../../../../../../store/actions/dataActions';

import {
  getData,
  updateData,
  clearDrawerDataInData,
  setSideDrawer,
  setTopActionButton,
  setSelectedData,
  setResources,
  setResourcesTotalCount,
  addDataSetCollectionInDataSetCollectionsFilter,
  updateDataViewType,
  updateResourceWhichAreBeingUpdatedInDB,
  updateResourceImageGroupAnnotationsInDB,
  updateResourceImageAnnotationsInDB,
  updateResourceMultipleImageAnnotationsInDB,
  updateResourceRemarks
} from "../../../../../../../../../../../store/actions";
import {
  setSelectedItems,
  addResourcesToDataSetCollection,
} from "../../../../../../../../../../../store/actions/dataActions";

const mapProps = (state: IReduxState) => {
  return {
    data: state.data.data,
    // drawerData: state.data.drawerData,
    isFetching: state.data.isFetching,
    isUpdating: state.data.isUpdating,
    approvedWaitingList: state.data.approvedWaitingList,
    deletedWaitingList: state.data.deletedWaitingList,
    rejectedWaitingList: state.data.rejectedWaitingList,
    approvalWaitingList: state.data.approvalWaitingList,
    shouldBlockDataTableKeyboardEvents: state.data.shouldBlockDataTableKeyboardEvents,
    selected: state.data.selected,
    drawerType: state.optionsDrawer.type,
    isSideDrawerOpen: state.optionsDrawer.isOpen,
    selectedItems: state.data.selectedItems,
    resourcesWhichAreBeingAddedToDataSetCollectionInDB:
      state.data.resourcesWhoseDataSetCollectionsAreBeingUpdatedInDB,
    dataSetCollectionsForFilter: state.data.dataSetCollectionsForFilter,
    isDataSetCollectionsBeingFetchedForFilter: state.data.dataSetCollectionsBeingFetchedForFilter,
    dataView: state.data.dataView,
    isApiRequestCancelled : state.data.isApiRequestCancelled
  };
};

export const tableContentConnector = connect(mapProps, {
  getData,
  updateData,
  setSideDrawer: setSideDrawer,
  clearDrawerDataInData,
  setTopActionButton,
  setSelectedData,
  setResources,
  setResourcesTotalCount,
  setSelectedItems,
  addResourcesToDataSetCollection,
  fetchDataSetCollectionsForDataTableFilter,
  addDataSetCollectionInDataSetCollectionsFilter,
  updateDataViewType,
  setShouldBlockDataTableKeyboardEvents,
  updateResourceWhichAreBeingUpdatedInDB,
  updateResourceImageGroupAnnotationsInDB,
  updateResourceImageAnnotationsInDB,
  updateResourceMultipleImageAnnotationsInDB,
  updateResourceRemarks,
  updateResourceInUI
});

export type TPropsFromRedux = ConnectedProps<typeof tableContentConnector>;

import React, { useCallback, useEffect, useState, useRef } from "react";
import { connect, ConnectedProps, useDispatch } from "react-redux";
import {
  convertDate,
  IModelCollection,
  IReduxState,
  IModelArchitecturesAvailable,
} from "../../../../../../../../../../../common";
import {
  Chip,
  Dialog,
  IconButton,
  Theme,
  Tooltip,
  Button,
  CircularProgress,
  TextField,
  Typography,
  Radio,
  Link,
} from "@material-ui/core";
import { makeStyles } from "@material-ui/styles";
import { Cancel } from "@material-ui/icons";
import NavigateBeforeIcon from "@material-ui/icons/NavigateBefore";
import NavigateNextIcon from "@material-ui/icons/NavigateNext";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import ExpandLessIcon from "@material-ui/icons/ExpandLess";
import EditIcon from "@material-ui/icons/Edit";
import ClearIcon from "@material-ui/icons/Clear";
import CheckIcon from "@material-ui/icons/Check";
import ModelStatus from "./ModelStatus";
import { ModelCollectionAccuracy } from "./ModelCollectionAccuracy";
import GetAppRoundedIcon from "@material-ui/icons/GetAppRounded";
import {
  DataService,
  ModelService,
} from "../../../../../../../../../../../services";
import clsx from "clsx";
import { useURLQuery } from "../../../../../../../../../../../hooks/useURLQuery";
import { deleteButtonIconColor } from "../../data/constants/constants";
import AddTestCollection from "./AddTestCollection";
// import DeploySVGIcon from "../../../../../../../../../../../assets/svg/deploy.svg";
import PerformModelCollectionDeploymentDialog from "../../data/components/PerformModelCollectionDeploymentDialog";
import { useLocation } from "react-router";
import { ModelCollectionStartCheckpointUndefinedValue } from "../constants/constants";
import {
  deleteModelCollection,
  setEmptyModelCollection,
  setModelCollection,
  setModelCollectionDefaultCheckPoint,
  setModelCollectionTotalCount,
  setSelectedModelCollection,
  updateModelCollectionData,
  updateModelCollectionDefaultCheckPoint,
  confirmDeleteCollection,
} from "../../../../../../../../../../../store/actions/collectionActions";
import {
  ICollectionMetrics,
  IModelCollectionCheckpointFile,
  IModelCollectionDeleteResponse,
} from "../../../../../../../../../../../common/constants/interfaces/collections";
import CloudStorageService from "../../../../../../../../../../../services/CloudStorageService";
import reTestIcon from "../components/Icons/Re-test.svg";
import testIcon from "../components/Icons/Test.svg";
import { InputFieldState } from "../../../../../../../../../../../common/constants/interfaces/inputFieldState";
import { setSideDrawer } from "../../../../../../../../../../../store/actions";
import { Delete } from "@material-ui/icons";
import ConfusionTable from "./ConfusionTable";
import DeleteCollectionConfirmPopup from "./confirmCollectionDelete";
import ModelCollectionDeployButton from "./ModelCollectionDeployButton";
import ShowModelCollectionHistoryViewButton from "./ShowModelCollectionHistoryViewButton";
import { isNullOrUndefined } from "../../../../../../../../../../../services/variableHelperService";
import { convertJsonToArrayOfKeyValuePair } from "../../../../../../../../../../../services/jsonHelperService";
import Autocomplete from "@material-ui/lab/Autocomplete/Autocomplete";
import { removeDuplicateElementsFromArray } from "../../../../../../../../../../../services/arrayHelperService";
const useStyles = makeStyles((theme: Theme) => ({
  root: {
    overflow: "auto",
    padding: "20px 0px",
    "&p": {
      padding: "0px",
    },
  },
  floating: {
    "& legend": {
      float: "unset",
    },
  },
  body: {
    padding: "10px",
    // width: "95vw",
    // height: "95vh",
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    "@media(min-width:1300px)": {
      width: "90vw",
      height: "80vh",
    },
    "@media(min-width:1000px) and (max-width: 1300px)": {
      width: "88vw",
      height: "80vh",
    },
    "@media(min-width:700px) and (max-width: 1000px)": {
      width: "90vw",
      height: "80vh",
    },
    "@media(max-width: 700px)": {
      width: "85vw",
      height: "80vh",
    },
  },
  dialogContainer: {
    overflow: "visible",
  },
  dialogContainerWidth: {
    maxWidth: "calc(100% - 64px)",
  },
  closeBtn: {
    position: "absolute",
    right: -10,
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    // padding: "2px",
    top: -10,
    backgroundColor: "white",
    borderRadius: "50%",
    // color: "white",
    cursor: "pointer",
    width: "25px",
    height: "25px",
  },
  navigateButton: {
    padding: "0px",
    "&:hover": {
      outline: "none",
    },
    "&:focus": {
      outline: "none",
    },
    "&:active": {
      outline: "none",
    },
  },
  showResource: {
    width: "calc(100% - 96px)",
    height: "100%",
    "@media(min-width:1300px)": {
      display: "flex",
      jsutifyContent: "space-between",
    },
    "@media(min-width:1050px) and (max-width: 1300px)": {
      display: "flex",
      jsutifyContent: "space-between",
    },
    "@media(min-width:700px) and (max-width: 1050px)": {
      // display: "flex",
    },
    "@media(max-width: 700px)": {
      // display: "flex",
    },
  },
  checkpointFiles: {
    // width: '58%',
    padding: "20px",
    "@media(min-width:1300px)": {
      width: "60%",
    },
    "@media(min-width:1050px) and (max-width: 1300px)": {
      width: "60%",
    },
    "@media(min-width:700px) and (max-width: 1050px)": {
      display: "flex",
      justifyContent: "center",
      width: "90%",
    },
    "@media(max-width: 700px)": {
      display: "flex",
      justifyContent: "center",
      width: "90%",
    },
    // width: '60%'
  },
  detailsSection: {
    "@media(min-width:1300px)": {
      width: "40%",
    },
    "@media(min-width:1050px) and (max-width: 1300px)": {
      width: "40%",
    },
    "@media(min-width:700px) and (max-width: 1050px)": {
      display: "flex",
      justifyContent: "center",
      width: "100%",
      height: "65%",
    },
    "@media(max-width: 700px)": {
      display: "flex",
      justifyContent: "center",
      width: "100%",
      height: "65%",
    },
  },
  actoinButtonWidth: {
    "@media(min-width:1300px)": {
      position: "sticky",
      top: "0px",
      width: "90%",
    },
    "@media(min-width:1050px) and (max-width: 1300px)": {
      position: "sticky",
      top: "0px",
      width: "90%",
    },
    "@media(min-width:700px) and (max-width: 1050px)": {
      position: "fixed",
      bottom: "7vh",
      display: "flex",
      justifyContent: "center",
      width: "60%",
    },
    "@media(max-width: 700px)": {
      position: "fixed",
      bottom: "7vh",
      display: "flex",
      justifyContent: "center",
      width: "60%",
    },
  },
  resourceDetails: {
    margin: "10px 4px 60px",
    maxHeight: "92%",
    overflow: "auto",
    "@media(min-width:1300px)": {
      // maxHeight: "80%",
    },
    "@media(min-width:1050px) and (max-width: 1300px)": {
      // maxHeight: "80%",
    },
    "@media(min-width:700px) and (max-width: 1050px)": {
      // maxHeight: "60%",
      width: "360px",
    },
    "@media(max-width: 700px)": {
      // maxHeight: "60%",
      width: "360px",
    },
  },

  info: {
    display: "flex",
    flexDirection: "row",
    paddingBottom: theme.spacing(2),
  },
  infoLevel2: {
    paddingLeft: 10,
  },
  infoLabel: {
    margin: 0,
    // width: "25%",
    width: "38%",
    font: "normal normal normal 13px/22px Nunito Sans",
    letterSpacing: "0px",
    // height: "22px",
    color: "#98A6B3",
  },
  infoValue: {
    margin: 0,
    // fontWeight: 500,
    overflow: "auto",
    textOverflow: "inherit",
    whiteSpace: "normal",
    maxWidth: "70%",

    font: "normal normal normal 13px/22px Nunito Sans",
    letterSpacing: "0px",
    color: "#212121",
  },
  flex: {
    display: "flex",
  },
  addDescriptionBtns: {
    justifyContent: "flex-end",
  },
  deleteButton: {
    color: deleteButtonIconColor,
  },
  showAsLink: {
    color: "#008D9C !important",
    "&:hover": {
      textDecoration: "underline",
    },
  },
  stringArrayValue: {
    whiteSpace: "normal",
    textOverflow: "inherit",
  },
  chip: {
    minWidth: "75px",
    marginBottom: 5,
    backgroundColor: theme.palette.grey[50],
    marginRight: theme.spacing(1),
  },
  progress: {
    padding: "10px",
  },
  deployButton: {
    color: "#008D9C",
  },
  delete: {
    color: "#EF8A8A",
  },
  tableDisabledCell: {
    pointerEvents: "none",
    opacity: "0.5",
  },
}));

interface IProps extends TPropsFromRedux {
  disabledDeployButton?: boolean;
  open: boolean;
  handleClose: () => void;
  modelCollectionId: string;
  onMoveToRight: () => void;
  onMoveToLeft: () => void;
  isReTestApiFetching: boolean;
  handleReTestButtonClick: (collection: IModelCollection) => {};
  isForceReTestApiFetching: boolean;
  // setShouldBlockDataTableKeyboardEvents : Function;
  // onDeleteClick?:()=>void;
  onTestClick?: () => void;
  onDeleteButtonClicked?: () => void;
  // ActionButtonArray?:any;
  // resourceImageList: ImageList[];
  isFetchingResources?: boolean;
  isResourceExistBefore: boolean;
  isResourceExistAfter: boolean;
  isFetching: boolean;
  architecturesAvailable: IModelArchitecturesAvailable;
  // isAdding?: boolean;
  // selected:string;
  // modelCollectionsAvailabel ?: IModelCollection[]
  // totalCount  : number;
  accuracyValue?: number;
  collectionMetrics?: ICollectionMetrics[];
  isFetchingCollectionMetrics?: boolean;
}

function ShowMoreLink(props: any) {
  return (
    <div style={{ minWidth: "71px" }}>
      <Link
        style={{
          color: "#008D9C",
          font: "normal normal normal 13px/22px Nunito Sans",
          letterSpacing: "0px",
          textDecoration: "underline",
          cursor: "pointer",
        }}
        {...props}
      >
        Show More
      </Link>
      <ExpandMoreIcon
        style={{
          marginLeft: "3px",
          height: "19px",
          marginTop: "-2px",
          cursor: "pointer",
        }}
      />
    </div>
  );
}

function ShowLessLink(props: any) {
  return (
    <div>
      <Link
        style={{
          color: "#008D9C",
          font: "normal normal normal 13px/22px Nunito Sans",
          letterSpacing: "0px",
          textDecoration: "underline",
          cursor: "pointer",
        }}
        {...props}
      >
        Show Less
      </Link>
      <ExpandLessIcon
        style={{
          marginLeft: "3px",
          height: "19px",
          marginTop: "-2px",
          cursor: "pointer",
        }}
      />
    </div>
  );
}

function FileDownloadIcon() {
  return (
    // <FileDownloadSVGIcon style={{width: "18px"}} />
    <GetAppRoundedIcon color="primary" />
  );
}

function ModelCollectionPopup(props: IProps) {
  const classes = useStyles();
  const dispatch = useDispatch();
  const location = useLocation();
  const urlQuery = useURLQuery();
  const [modelCollection, setSelectedModelCollection] =
    useState<IModelCollection | null>();
  const [isEditingDescription, setIsEditingDescription] = useState(false);
  const [descriptionValue, setDescriptionValue] = useState("");
  const [isRemarksBeingUpdatedInDB, setIsRemarksBeingUpdatedInDB] =
    useState(false);
  const [remarksTextState, setRemarksTextState] = useState<
    InputFieldState<string>
  >({ value: modelCollection?.remarks?.text || "" });
  // const [projectId, setProjectId] = useState<string>("");
  const [collectionsBeingDeleted, setCollectionsBeingDeleted] = useState<
    string[]
  >([]);
  const [selectedCollections, setSelectedCollections] = useState<
    IModelCollection[]
  >([]);
  const [
    performModelCollectionDeploymentPopupState,
    setPerformModelCollectionDeploymentPopupState,
  ] = useState<{ isOpen: boolean; selected: IModelCollection | undefined }>({
    isOpen: false,
    selected: undefined,
  });
  // const [architecturesAvailable, setArchitecturesAvailable] = useState<IModelArchitecturesAvailable>([]);
  const [
    liveModelCollectionAccuracyIfPresent,
    setLiveModelCollectionVersionIfPresent,
  ] = useState<number | null>(0);
  const dialogRef = useRef<any>(null);
  // tags
  const [isModelBeingUpdatedInDB, setIsModelBeingUpdatedInDB] = useState(false);
  const [tagsState, setTagsState] = useState<InputFieldState<string[]>>({
    value: [],
  });
  function handleTagsInputChangeEvent(eventValue: string) {
    if (eventValue.includes(",")) {
      const values: string[] = eventValue
        .split(",")
        .map((value) => value.trim())
        .filter((value: string) => {
          if (!value) {
            return false;
          }
          return true;
        });

      if (values?.length > 0) {
        const oldValue = tagsState.value;
        for (const value of values) {
          if (!oldValue.includes(value)) {
            oldValue.push(value);
          }
        }

        const tagsToSave = removeDuplicateElementsFromArray(oldValue);

        setTagsState({
          ...tagsState,
          isDirty: true,
          isTouched: true,
          value: [...tagsToSave],
        });
      }
    }
  }
  function updateTags() {
    if (!modelCollection) return;
    setIsModelBeingUpdatedInDB(true);
    DataService.updateModelCollection({
      id: modelCollection?._id,
      tags: tagsState.value,
    })
      .catch((err) => console.log(err))
      .finally(() => setIsModelBeingUpdatedInDB(false));
  }
  useEffect(() => {
    props.modelCollectionsAvailable &&
      props.modelCollectionsAvailable.forEach((collection) => {
        if (collection._id === props.modelCollectionId) {
          console.log("DATTTA", collection);
          setSelectedModelCollection(collection);
          if (collection.tags) {
            const tagValues = collection.tags.map((tag) => tag.value);
            setTagsState({ ...tagsState, value: tagValues });
          }
        }
        if (collection.status === "Live") {
          if (collection?.accuracy) {
            setLiveModelCollectionVersionIfPresent(collection.accuracy);
          }
        }
      });
  }, [
    props.modelCollectionsAvailable,
    props.modelCollectionId,
    setLiveModelCollectionVersionIfPresent,
  ]);

  const onClose = () => {
    props.handleClose();
  };
  const arrowClicked = (direction: "left" | "right") => {
    if (direction === "left") {
      props.onMoveToLeft();
    } else {
      props.onMoveToRight();
    }
  };

  useEffect(() => {
    setDescriptionValue(modelCollection?.modelDescription || "");
    setRemarksTextState((oldState) => {
      return {
        ...oldState,
        value: modelCollection?.remarks?.text || "",
      };
    });
  }, [modelCollection && modelCollection?._id]);

  const handleCollectionDeleteButtonClick = async (
    collectionIdToDelete: string
  ) => {
    setCollectionsBeingDeleted((oldValue) => {
      if (oldValue.indexOf(collectionIdToDelete) === -1) {
        oldValue.push(collectionIdToDelete);
      }
      return [...oldValue];
    });
    try {
      // const apiResponse = await DataService.deleteModelCollections({
      //   collections: [collectionIdToDelete],
      // });
      const data: IModelCollectionDeleteResponse | undefined =
        await props.deleteModelCollection({
          collections: [collectionIdToDelete],
        });

      // if (props.deleteModelCollectionTotalCount > 0) {
      //   fetchCollections();
      // }
      //   setSideDrawer({ isOpen: false });
      if (
        props.isResourceExistAfter &&
        props.isDeleteSuccuss &&
        (!data?.errorMessagesDueToDataSetLinkedSomewhereElse ||
          data?.errorMessagesDueToDataSetLinkedSomewhereElse.length === 0)
      ) {
        props.onMoveToRight();
      } else if (
        props.isResourceExistBefore &&
        props.isDeleteSuccuss &&
        (!data?.errorMessagesDueToDataSetLinkedSomewhereElse ||
          data?.errorMessagesDueToDataSetLinkedSomewhereElse.length === 0)
      ) {
        props.onMoveToLeft();
      }
    } catch (error: any) {
      alert(error.response.data);
    }
    setCollectionsBeingDeleted((oldValue) => {
      for (let index = 0; index < oldValue.length; index++) {
        const element = oldValue[index];
        if (element === collectionIdToDelete) {
          oldValue.splice(index, 1);
          break;
        }
      }
      return [...oldValue];
    });

    setSelectedCollections((oldValue) => {
      for (let index = 0; index < oldValue.length; index++) {
        const collection = oldValue[index];
        if (collection._id === collectionIdToDelete) {
          oldValue.splice(index, 1);
          break;
        }
      }
      return [...oldValue];
    });
  };

  function AddTestCollectionComponentWrapperForTrainingScenario(
    collection?: IModelCollection
  ) {
    return (
      <AddTestCollection
        scenario={"testModelCollections"}
        selectedModelCollection={
          selectedCollections?.length > 0
            ? selectedCollections
            : collection
            ? [collection]
            : []
        }
      />
    );
  }
  function handleTestButtonClick(collection: IModelCollection) {
    console.log("handle test button click");
    // onClose();
    // setSideDrawer({isOpen: true})
    props.setSideDrawer({
      type: "addCollection",
      component: () =>
        AddTestCollectionComponentWrapperForTrainingScenario(collection),
      isOpen: true,
    });
    onClose();
  }

  const fetchModelCollection = useCallback(
    (limitValue: string = "", offsetValue: string = ""): Promise<void> => {
      return new Promise<void>(async (resolve, reject) => {
        const query = new URLSearchParams(location.search);
        const limit = limitValue || query.get("limit") || "10";
        const model = query.get("model") || "";
        const offset = offsetValue || query.get("offset") || "1";

        try {
          dispatch(setEmptyModelCollection(true));
          // fetching live model collection and model collections
          const [liveModelCollectionApiResponse, modelCollectionApiResponse] =
            await Promise.all([
              await DataService.fetchModelCollectionsFromDB({
                limit: 1,
                model,
                offset: 1,
                shouldFetchLiveModelCollection: true,
                jsonFileData: "true",
              }),
              await DataService.fetchModelCollectionsFromDB({
                limit: Number(limit),
                // limit: 1,
                model,
                jsonFileData: "true",
                offset: Number(offset),
                shouldFetchLiveModelCollection: false,
              }),
            ]);

          // Dispatch action for setting model collection and total count to the redux action
          if (
            liveModelCollectionApiResponse &&
            liveModelCollectionApiResponse.data.collections.length > 0
          ) {
            const newModelCollectionArray =
              liveModelCollectionApiResponse.data.collections.concat(
                modelCollectionApiResponse.data.collections
              );
            dispatch(setModelCollection(newModelCollectionArray));
          } else {
            dispatch(
              setModelCollection(modelCollectionApiResponse.data.collections)
            );
          }

          dispatch(
            setModelCollectionTotalCount(
              modelCollectionApiResponse.data.totalCount
            )
          );
        } catch (error) {}
      });
    },
    [location.search]
  );

  // const fetchCollections = useCallback(
  //     (limitValue: string = "", offsetValue: string = ""): Promise<void> => {
  //       return new Promise<void>(async (resolve, reject) => {
  //         const query = new URLSearchParams(location.search);
  //         const limit = limitValue || query.get("limit") || "10";
  //         const model = query.get("model") || "";
  //         const offset = offsetValue || query.get("offset") || "1";
  //         // let limitWhenLiveModelCollectionAvailable;
  //         // if(isLiveModelCollectionPresent){
  //         //   limitWhenLiveModelCollectionAvailable = (Number(limit) - 1).toString();
  //         // }
  //         // setRowsPerPage(parseInt(limit, 10));
  //         // setPage(parseInt(offset, 10) - 1);

  //         try {
  //           // setIsFetchingModelCollections(true);
  //           const apiResponse = await DataService.fetchModelCollectionsFromDB({
  //             limit: Number(limit),
  //             // limit: 1,
  //             model,
  //             jsonFileData: "true",
  //             offset: Number(offset),
  //             shouldFetchLiveModelCollection: false,
  //             // sortOrder: "descending",
  //             // sortField: "accuracy"
  //             // shouldCheckBestModelCollection: true
  //           });

  //           dispatch(setModelCollection(apiResponse.data.collections))

  //           dispatch(setModelCollectionTotalCount(apiResponse.data.totalCount));
  //         } catch (error) {}
  //         // setIsFetchingModelCollections(false);
  //       });
  //     },
  //     [location.search]
  //   );

  // const fetchLiveCollections = useCallback(
  //   (limitValue: string = "", offsetValue: string = ""): Promise<void> => {
  //     return new Promise<void>(async (resolve, reject) => {
  //       const query = new URLSearchParams(location.search);
  //       const limit = "1";
  //       const model = query.get("model") || "";
  //       const offset = "1";

  //       try {
  //         // setIsFetchingLiveModelCollection(true);
  //         dispatch(setEmptyModelCollection(true))
  //         const apiResponse = await DataService.fetchModelCollectionsFromDB({
  //           limit: Number(limit),
  //           model,
  //           offset: Number(offset),
  //           shouldFetchLiveModelCollection: true,
  //           jsonFileData: "true"
  //         });

  //         dispatch(setLiveModelCollection(apiResponse.data.collections))

  //         fetchCollections(limitValue, offsetValue);
  //         // dispatch(setModelCollectionTotalCount(apiResponse.data.totalCount));
  //       } catch (error) {}
  //       // setIsFetchingLiveModelCollection(false);
  //       resolve();
  //     });
  //   },
  //   [location.search]
  // );

  // const urlSearchParams = new URLSearchParams(window.location.search);

  // const modelId = urlSearchParams.get("model") || "";
  const [showMore, setShowMore] = useState(true);

  const showMoreClick = () => {
    setShowMore(false);
  };

  const showLessClick = () => {
    setShowMore(true);
  };

  /**
   * TO update the description of selected model
   * @param value updated description text
   */
  const updateModelDescription = async (value: string) => {
    if (modelCollection) {
      try {
        const data = await DataService.updateModelCollection({
          id: modelCollection._id,
          modelDescription: value,
        });
        if (data.status === 200) {
          props.updateModelCollectionData({
            ...modelCollection,
            modelDescription: data.data.modelDescription,
            updated_at: data.data.updated_at,
          });
        }
      } catch (e) {
        alert(e);
      }
      setIsEditingDescription(false);
    }
  };
  const modelId: string = urlQuery.get("model") || "";
  const projectId: string = urlQuery.get("project") || "";

  // useEffect(() => {
  //   const query = new URLSearchParams(location.search);
  //   const project = query.get("project") || "";
  //   setProjectId(project);
  //   setModelConfigurations();
  // }, [projectId, modelId])

  const onSaveRemarksClick = async () => {
    if (modelCollection) {
      setIsRemarksBeingUpdatedInDB(true);
      try {
        const data = await DataService.updateModelCollection({
          id: modelCollection?._id,
          remarks: remarksTextState.value,
        });
        if (data.status === 200) {
          props.updateModelCollectionData({
            ...modelCollection,
            remarks: data.data.remarks,
            updated_at: data.data.updated_at,
          });
          setRemarksTextState((oldState) => {
            return {
              ...oldState,
              isDirty: false,
              isTouched: false,
            };
          });
          setIsRemarksBeingUpdatedInDB(false);
        }
      } catch (e) {
        setIsRemarksBeingUpdatedInDB(false);
        alert(e);
      }
    }
  };

  const setModelConfigurations = async () => {
    const modelId: string = urlQuery.get("model") || "";
    const apiResponse = await ModelService.getModelDetails({
      modelId: modelId,
      modelSelectQuery: "architecturesAvailable",
    });
    if (apiResponse.data) {
      // setArchitecturesAvailable(apiResponse.data.architecturesAvailable)
    }
  };

  const DefaultModelCollectionCheckbox = (props: {
    isDefaultCheckPoint: boolean;
    fileId: string;
    collectionId: string;
    selected: IModelCollection | null;
    files: IModelCollection["checkpointFiles"] | null;
  }) => {
    const [isDefaultCheckpoint, setDefaultCheckpoint] = useState(
      props.isDefaultCheckPoint
    );
    const dispatch = useDispatch();

    const changeDefaultCheckpoint = (e: any) => {
      setDefaultCheckpoint(e.target.checked);
      dispatch(
        updateModelCollectionDefaultCheckPoint({
          collectionId: props.collectionId,
          fileId: props.fileId,
          isDefaultCheckPoint: e.target.checked,
        })
      );
      let file =
        props?.files?.map((file) =>
          file._id === props.fileId
            ? {
                ...file,
                isDefaultCheckpoint: e.target.checked,
              }
            : { ...file, isDefaultCheckpoint: false }
        ) || [];
      console.log("Set selected model collection ::::::: ", file);
      // setSelectedModelCollection({
      //   ...props.selected,
      //   files : [
      //     ...file
      //   ]
      // } as unknown as IModelCollection)
      dispatch(
        setModelCollectionDefaultCheckPoint({
          ...props.selected,
          checkpointFiles: [...file],
        } as unknown as IModelCollection)
      );
    };
    return (
      <div>
        {/* <RadioGroup row aria-label="position" name="position" defaultValue="top">    
        </RadioGroup> */}
        {/* <RadioButton
            checked={isDefaultCheckpoint}
            onChange={changeDefaultCheckpoint}
            inputProps={{ 'aria-label': 'primary checkbox' }}
          /> */}
        <Radio
          color="primary"
          checked={isDefaultCheckpoint}
          onChange={changeDefaultCheckpoint}
        />
      </div>
    );
  };

  const renderCheckpointFileDetails = (
    checkpointFile: IModelCollectionCheckpointFile,
    currentModelCollection: IModelCollection
  ) => {
    return (
      <div style={{ marginBottom: 14 }}>
        <div className={classes.info} style={{ paddingBottom: 0 }}>
          <p
            className={classes.infoLabel}
            style={{ width: "30%", fontWeight: "normal" }}
          >
            Name:
          </p>
          <p
            className={classes.infoValue}
            style={{
              textOverflow: "ellipsis",
              width: "56%",
              overflow: "hidden",
              whiteSpace: "nowrap",
            }}
          >
            <Tooltip title={checkpointFile.name}>
              <span
                // target="_blank"
                // rel="noreferrer"
                // download
                // href={file.url}
                className={`${classes.showAsLink}`}
              >
                {checkpointFile.name}
              </span>
            </Tooltip>
          </p>
          {checkpointFile.gcsFileName && (
            <p
              style={{
                width: "11%",
                display: "flex",
                justifyContent: "end",
                cursor: "pointer",
                marginBottom: "0px",
              }}
              onClick={async () => {
                const apiResponse =
                  await CloudStorageService.getResourceBucketFileDownloadURL({
                    storageFileName: checkpointFile.gcsFileName || "",
                    projectId: projectId,
                  });
                if (apiResponse.data) {
                  window.open(apiResponse.data, "_blank");
                }
              }}
            >
              <FileDownloadIcon />
            </p>
          )}
        </div>
        {checkpointFile.description && (
          <div className={classes.info} style={{ paddingBottom: 0 }}>
            <p
              className={classes.infoLabel}
              style={{ width: "25%", fontWeight: "normal" }}
            >
              Description:{" "}
            </p>
            <p className={classes.infoValue}>
              <Tooltip title={checkpointFile.description}>
                <span>
                  &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                  {checkpointFile.description}
                </span>
              </Tooltip>
            </p>
          </div>
        )}
        <div className={classes.info} style={{ paddingBottom: 0 }}>
          <div className={classes.info} style={{ paddingBottom: 0 }}>
            <p
              className={classes.infoLabel}
              style={{ width: "48%", fontWeight: "normal" }}
            >
              Default Checkpoint:
            </p>
            <p
              className={classes.infoValue}
              style={{
                marginLeft: "4px",
              }}
            >
              <DefaultModelCollectionCheckbox
                isDefaultCheckPoint={checkpointFile.isDefaultCheckpoint}
                fileId={checkpointFile._id}
                collectionId={currentModelCollection._id}
                selected={currentModelCollection}
                files={currentModelCollection.checkpointFiles}
              />
            </p>
          </div>
        </div>
        {checkpointFile?.files?.analysis?.length > 0 && (
          <div>
            <div className={classes.info} style={{ padding: 0 }}>
              <p className={classes.infoLabel} style={{ fontWeight: "normal" }}>
                Analysis:
              </p>
            </div>
            <div style={{ marginLeft: "15px" }}>
              {checkpointFile?.files?.analysis.map(
                (analysisObject, analysisIndex) => {
                  return (
                    <div
                      style={{
                        paddingBottom: "10px",
                      }}
                    >
                      {analysisObject?.accuracy !== undefined && (
                        <div className={classes.info} style={{ padding: 0 }}>
                          <p
                            className={classes.infoLabel}
                            style={{ width: "48%", fontWeight: "normal" }}
                          >
                            Accuracy:
                          </p>
                          <p
                            className={classes.infoValue}
                            style={{ width: "48%", fontWeight: "normal" }}
                          >
                            {modelCollection?.model
                              ?.isSegmentationModelAnalytics
                              ? // @ts-ignore
                                analysisObject?.segmentationStatistics?.[
                                  modelCollection?.model
                                    ?.segmentationModelAnalyticsAccuracyMetric ||
                                    ""
                                ]
                              : analysisObject.accuracy}
                          </p>
                        </div>
                      )}

                      {analysisObject.description && (
                        <div className={classes.info} style={{ padding: 0 }}>
                          <p
                            className={classes.infoLabel}
                            style={{ width: "48%", fontWeight: "normal" }}
                          >
                            Description:
                          </p>
                          <p
                            className={classes.infoValue}
                            style={{ width: "48%", fontWeight: "normal" }}
                          >
                            {analysisObject.description}
                          </p>
                        </div>
                      )}

                      {analysisObject.defaultDataSetCollectionName && (
                        <div className={classes.info} style={{ padding: 0 }}>
                          <p
                            className={classes.infoLabel}
                            style={{ width: "30%", fontWeight: "normal" }}
                          >
                            Test Data Set:
                          </p>
                          <p
                            className={classes.infoValue}
                            style={{
                              fontWeight: "normal",
                              textOverflow: "ellipsis",
                              width: "56%",
                              overflow: "hidden",
                              whiteSpace: "nowrap",
                            }}
                          >
                            <Tooltip
                              title={
                                analysisObject.defaultDataSetCollectionName
                              }
                            >
                              <span
                                // target="_blank"
                                // rel="noreferrer"
                                // download
                                // href={file.url}
                                className={`${classes.showAsLink}`}
                              >
                                {analysisObject.defaultDataSetCollectionName}
                              </span>
                            </Tooltip>
                          </p>
                          <p
                            style={{
                              width: "11%",
                              display: "flex",
                              justifyContent: "end",
                              cursor: "pointer",
                              marginBottom: "0px",
                            }}
                            onClick={async () => {
                              const apiResponse =
                                await CloudStorageService.getResourceBucketFileDownloadURL(
                                  {
                                    storageFileName:
                                      analysisObject.defaultDataSetResourcesGcsFileName ||
                                      "",
                                    projectId: projectId,
                                  }
                                );
                              if (apiResponse.data) {
                                window.open(apiResponse.data, "_blank");
                              }
                            }}
                          >
                            <FileDownloadIcon />
                          </p>
                        </div>
                      )}

                      {(props.collectionMetrics ||
                        props.isFetchingCollectionMetrics) && (
                        <>
                          <div>
                            <div
                              className={classes.info}
                              style={{ padding: 0 }}
                            >
                              <div
                                className={classes.infoLabel}
                                style={{ width: "30%" }}
                              >
                                Statistics
                              </div>
                              {props.isFetchingCollectionMetrics &&
                                !props.collectionMetrics?.find(
                                  (obj) =>
                                    obj.modelCollectionId ===
                                    props.modelCollectionId
                                ) && (
                                  <div className={classes.infoValue}>
                                    Fetching...
                                  </div>
                                )}
                            </div>
                          </div>
                          <div style={{ marginLeft: "5px" }}>
                            {!isNullOrUndefined(
                              props.collectionMetrics?.find(
                                (obj) =>
                                  obj.modelCollectionId ===
                                  props.modelCollectionId
                              )?.["F1"]
                            ) && (
                              <div
                                className={classes.info}
                                style={{ padding: 0 }}
                              >
                                <div className={classes.infoLabel}>F1</div>
                                <div className={classes.infoValue}>
                                  {
                                    props.collectionMetrics?.find(
                                      (obj) =>
                                        obj.modelCollectionId ===
                                        props.modelCollectionId
                                    )?.F1
                                  }
                                </div>
                              </div>
                            )}
                            {!isNullOrUndefined(
                              props.collectionMetrics?.find(
                                (obj) =>
                                  obj.modelCollectionId ===
                                  props.modelCollectionId
                              )?.["Precision"]
                            ) && (
                              <div
                                className={classes.info}
                                style={{ padding: 0 }}
                              >
                                <div className={classes.infoLabel}>
                                  Precision
                                </div>
                                <div className={classes.infoValue}>
                                  {
                                    props.collectionMetrics?.find(
                                      (obj) =>
                                        obj.modelCollectionId ===
                                        props.modelCollectionId
                                    )?.["Precision"]
                                  }
                                </div>
                              </div>
                            )}
                            {!isNullOrUndefined(
                              props.collectionMetrics?.find(
                                (obj) =>
                                  obj.modelCollectionId ===
                                  props.modelCollectionId
                              )?.["Recall"]
                            ) && (
                              <div
                                className={classes.info}
                                style={{ padding: 0 }}
                              >
                                <div className={classes.infoLabel}>Recall</div>
                                <div className={classes.infoValue}>
                                  {
                                    props.collectionMetrics?.find(
                                      (obj) =>
                                        obj.modelCollectionId ===
                                        props.modelCollectionId
                                    )?.["Recall"]
                                  }
                                </div>
                              </div>
                            )}
                            {!isNullOrUndefined(
                              props.collectionMetrics?.find(
                                (obj) =>
                                  obj.modelCollectionId ===
                                  props.modelCollectionId
                              )?.["Average IoU"]
                            ) && (
                              <div
                                className={classes.info}
                                style={{ padding: 0 }}
                              >
                                <div className={classes.infoLabel}>
                                  Average IoU
                                </div>
                                <div className={classes.infoValue}>
                                  {
                                    props.collectionMetrics?.find(
                                      (obj) =>
                                        obj.modelCollectionId ===
                                        props.modelCollectionId
                                    )?.["Average IoU"]
                                  }
                                </div>
                              </div>
                            )}
                            {!isNullOrUndefined(
                              props.collectionMetrics?.find(
                                (obj) =>
                                  obj.modelCollectionId ===
                                  props.modelCollectionId
                              )?.["Mean Average Precision"]
                            ) && (
                              <div
                                className={classes.info}
                                style={{ padding: 0 }}
                              >
                                <div className={classes.infoLabel}>
                                  Mean Average Precision
                                </div>
                                <div className={classes.infoValue}>
                                  {
                                    props.collectionMetrics?.find(
                                      (obj) =>
                                        obj.modelCollectionId ===
                                        props.modelCollectionId
                                    )?.["Mean Average Precision"]
                                  }
                                </div>
                              </div>
                            )}
                            {Object.keys(
                              props.collectionMetrics?.find(
                                (obj) =>
                                  obj.modelCollectionId ===
                                  props.modelCollectionId
                              )?.Labels || {}
                            )?.length > 0 && (
                              <>
                                <div
                                  className={classes.info}
                                  style={{ padding: 0 }}
                                >
                                  <div className={classes.infoLabel}>
                                    Labels
                                  </div>
                                </div>
                                {convertJsonToArrayOfKeyValuePair(
                                  props.collectionMetrics?.find(
                                    (obj) =>
                                      obj.modelCollectionId ===
                                      props.modelCollectionId
                                  )?.Labels || {}
                                )?.map((keyValuePair) => {
                                  return (
                                    <>
                                      <div
                                        className={classes.info}
                                        style={{
                                          padding: 0,
                                          marginLeft: "5px",
                                          paddingTop: 5,
                                        }}
                                      >
                                        <div className={classes.infoLabel}>
                                          {keyValuePair.key}
                                        </div>
                                      </div>
                                      <div
                                        style={{
                                          padding: 0,
                                          marginLeft: "5px",
                                        }}
                                      >
                                        {convertJsonToArrayOfKeyValuePair(
                                          keyValuePair?.keyValue || {}
                                        )?.map((labelKeyValuePair) => {
                                          return (
                                            <div
                                              className={classes.info}
                                              style={{
                                                padding: 0,
                                                marginLeft: "5px",
                                              }}
                                            >
                                              <div
                                                className={classes.infoLabel}
                                              >
                                                {labelKeyValuePair.key}
                                              </div>
                                              <div
                                                className={classes.infoValue}
                                              >
                                                {labelKeyValuePair.keyValue}
                                              </div>
                                            </div>
                                          );
                                        })}
                                      </div>
                                    </>
                                  );
                                })}
                              </>
                            )}
                          </div>
                        </>
                      )}

                      {analysisObject?.fileObjects?.length > 0 && (
                        <>
                          <div className={classes.info} style={{ padding: 0 }}>
                            <p className={classes.infoLabel}>Files</p>
                          </div>
                          <div
                            style={{
                              marginLeft: "5px",
                            }}
                          >
                            {analysisObject?.fileObjects?.map(
                              (fileObject, index) => {
                                return (
                                  <div>
                                    <div
                                      className={classes.info}
                                      style={{
                                        padding: 0,
                                        marginTop:
                                          fileObject.fileName.startsWith(
                                            "confusion_"
                                          ) &&
                                          fileObject.fileName.endsWith(".json")
                                            ? "10px"
                                            : "0px",
                                      }}
                                    >
                                      <p
                                        className={classes.infoLabel}
                                        style={{
                                          width: "30%",
                                          fontWeight: "normal",
                                        }}
                                      >
                                        File:
                                      </p>
                                      <p
                                        className={classes.infoValue}
                                        style={{
                                          textOverflow: "ellipsis",
                                          width: "56%",
                                          overflow: "hidden",
                                          whiteSpace: "nowrap",
                                        }}
                                      >
                                        <Tooltip title={fileObject.fileName}>
                                          <span
                                            // target="_blank"
                                            // rel="noreferrer"
                                            // download
                                            // href={file.url}
                                            className={`${classes.showAsLink}`}
                                          >
                                            {fileObject.fileName}
                                          </span>
                                        </Tooltip>
                                      </p>
                                      <p
                                        style={{
                                          width: "11%",
                                          display: "flex",
                                          justifyContent: "end",
                                          cursor: "pointer",
                                          marginBottom: "0px",
                                        }}
                                        onClick={async () => {
                                          const apiResponse =
                                            await CloudStorageService.getResourceBucketFileDownloadURL(
                                              {
                                                storageFileName:
                                                  fileObject.gcsFileName,
                                                projectId: projectId,
                                              }
                                            );
                                          if (apiResponse.data) {
                                            window.open(
                                              apiResponse.data,
                                              "_blank"
                                            );
                                            console.log(
                                              "APi Response ",
                                              apiResponse.data
                                            );
                                          }
                                        }}
                                      >
                                        <FileDownloadIcon />
                                      </p>
                                    </div>
                                    <div>
                                      {fileObject.fileName.startsWith(
                                        "confusion_"
                                      ) &&
                                        fileObject.fileName.endsWith(
                                          ".json"
                                        ) && (
                                          <div style={{ margin: "10px 20px " }}>
                                            <ConfusionTable
                                              fileName={fileObject.fileName}
                                              gcsFileName={
                                                fileObject.gcsFileName
                                              }
                                              projectId={projectId}
                                              analysisObjectId={
                                                analysisObject?._id
                                              }
                                            />
                                          </div>
                                        )}
                                    </div>
                                  </div>
                                );
                              }
                            )}
                          </div>
                        </>
                      )}
                    </div>
                  );
                }
              )}
            </div>
          </div>
        )}

        {checkpointFile?.files?.models?.fileObjects?.length > 0 && (
          <div>
            <div className={classes.info} style={{ padding: 0 }}>
              <p className={classes.infoLabel} style={{ fontWeight: "normal" }}>
                Models:
              </p>
            </div>
            <div style={{ marginLeft: "15px" }}>
              {checkpointFile?.files?.models?.description && (
                <div className={classes.info} style={{ padding: 0 }}>
                  <p
                    className={classes.infoLabel}
                    style={{ width: "48%", fontWeight: "normal" }}
                  >
                    Description:
                  </p>
                  <p
                    className={classes.infoValue}
                    style={{ width: "48%", fontWeight: "normal" }}
                  >
                    {checkpointFile?.files?.models?.description}
                  </p>
                </div>
              )}
              <div
                style={{
                  paddingBottom: "10px",
                }}
              >
                {true && (
                  <>
                    <div className={classes.info} style={{ padding: 0 }}>
                      <p className={classes.infoLabel}>Files</p>
                    </div>
                    <div
                      style={{
                        marginLeft: "5px",
                      }}
                    >
                      {checkpointFile?.files?.models?.fileObjects?.map(
                        (fileObject) => {
                          return (
                            <div
                              className={classes.info}
                              style={{ padding: 0 }}
                            >
                              <p
                                className={classes.infoLabel}
                                style={{ width: "30%", fontWeight: "normal" }}
                              >
                                File:
                              </p>
                              <p
                                className={classes.infoValue}
                                style={{
                                  textOverflow: "ellipsis",
                                  width: "56%",
                                  overflow: "hidden",
                                  whiteSpace: "nowrap",
                                }}
                              >
                                <Tooltip title={fileObject.fileName}>
                                  <span
                                    // target="_blank"
                                    // rel="noreferrer"
                                    // download
                                    // href={file.url}
                                    className={`${classes.showAsLink}`}
                                  >
                                    {fileObject.fileName}
                                  </span>
                                </Tooltip>
                              </p>
                              <p
                                style={{
                                  width: "11%",
                                  display: "flex",
                                  justifyContent: "end",
                                  cursor: "pointer",
                                  marginBottom: "0px",
                                }}
                                onClick={async () => {
                                  const apiResponse =
                                    await CloudStorageService.getResourceBucketFileDownloadURL(
                                      {
                                        storageFileName: fileObject.gcsFileName,
                                        projectId: projectId,
                                      }
                                    );
                                  if (apiResponse.data) {
                                    window.open(apiResponse.data, "_blank");
                                  }
                                }}
                              >
                                <FileDownloadIcon />
                              </p>
                            </div>
                          );
                        }
                      )}
                    </div>
                  </>
                )}
              </div>
            </div>
          </div>
        )}
        {checkpointFile?.files?.additionalFiles?.length > 0 && (
          <div>
            <div className={classes.info} style={{ padding: 0 }}>
              <p className={classes.infoLabel} style={{ fontWeight: "normal" }}>
                Additional Files:
              </p>
            </div>
            <div style={{ marginLeft: "15px" }}>
              {checkpointFile?.files?.additionalFiles.map(
                (additionalObject) => {
                  return (
                    <div
                      style={{
                        paddingBottom: "10px",
                      }}
                    >
                      {additionalObject.description && (
                        <div className={classes.info} style={{ padding: 0 }}>
                          <p
                            className={classes.infoLabel}
                            style={{ width: "48%", fontWeight: "normal" }}
                          >
                            Description:
                          </p>
                          <p
                            className={classes.infoValue}
                            style={{ width: "48%", fontWeight: "normal" }}
                          >
                            {additionalObject.description}
                          </p>
                        </div>
                      )}

                      {additionalObject?.fileObjects?.length > 0 && (
                        <>
                          <div className={classes.info} style={{ padding: 0 }}>
                            <p className={classes.infoLabel}>Files</p>
                          </div>
                          <div
                            style={{
                              marginLeft: "5px",
                            }}
                          >
                            {additionalObject?.fileObjects?.map(
                              (fileObject) => {
                                return (
                                  <div
                                    className={classes.info}
                                    style={{ padding: 0 }}
                                  >
                                    <p
                                      className={classes.infoLabel}
                                      style={{
                                        width: "30%",
                                        fontWeight: "normal",
                                      }}
                                    >
                                      File:
                                    </p>
                                    <p
                                      className={classes.infoValue}
                                      style={{
                                        textOverflow: "ellipsis",
                                        width: "56%",
                                        overflow: "hidden",
                                        whiteSpace: "nowrap",
                                      }}
                                    >
                                      <Tooltip title={fileObject.fileName}>
                                        <span
                                          // target="_blank"
                                          // rel="noreferrer"
                                          // download
                                          // href={file.url}
                                          className={`${classes.showAsLink}`}
                                        >
                                          {fileObject.fileName}
                                        </span>
                                      </Tooltip>
                                    </p>
                                    <p
                                      style={{
                                        width: "11%",
                                        display: "flex",
                                        justifyContent: "end",
                                        cursor: "pointer",
                                        marginBottom: "0px",
                                      }}
                                      onClick={async () => {
                                        const apiResponse =
                                          await CloudStorageService.getResourceBucketFileDownloadURL(
                                            {
                                              storageFileName:
                                                fileObject.gcsFileName,
                                              projectId: projectId,
                                            }
                                          );
                                        if (apiResponse.data) {
                                          window.open(
                                            apiResponse.data,
                                            "_blank"
                                          );
                                        }
                                      }}
                                    >
                                      <FileDownloadIcon />
                                    </p>
                                  </div>
                                );
                              }
                            )}
                          </div>
                        </>
                      )}
                    </div>
                  );
                }
              )}
            </div>
          </div>
        )}
      </div>
    );
  };
  const handleOnKeyUp = useCallback(
    (event: KeyboardEvent) => {
      if (event.shiftKey) {
        return;
      }
      const keyBoardEvent: any = event;
      /**
       * keyBoardEvent.path[0].nodeName !== "DIV"
       * Above condition to prevent arrow press inside a text field
       */
      if (
        keyBoardEvent.path?.length &&
        keyBoardEvent.path[0].nodeName !== "DIV"
      ) {
        return;
      }
      if (props.isFetching) {
        return;
      }
      if (event.key === "ArrowLeft" && props.isResourceExistBefore) {
        props.onMoveToLeft();
      } else if (event.key === "ArrowRight" && props.isResourceExistAfter) {
        props.onMoveToRight();
      }
    },
    [
      props.isResourceExistBefore,
      props.isResourceExistAfter,
      props.onMoveToLeft,
      props.onMoveToRight,
      props.isFetching,
    ]
  );

  useEffect(() => {
    if (dialogRef && dialogRef.current) {
      dialogRef.current.addEventListener("keyup", handleOnKeyUp);
    }
    return () => {
      if (dialogRef && dialogRef.current) {
        dialogRef.current.removeEventListener("keyup", handleOnKeyUp);
      }
    };
  }, [dialogRef.current, handleOnKeyUp]);

  /**
   * fetch startCheckpoint file details to show in popup
   */
  useEffect(() => {
    const executeFunction = async () => {
      if (
        modelCollection?.startCheckpoint &&
        modelCollection.startCheckpoint !==
          ModelCollectionStartCheckpointUndefinedValue &&
        !modelCollection.startCheckpointFileName
      ) {
        const parentCheckpointFileDetailsResponse =
          await DataService.getModelCollectionParentCheckpointFileDetails({
            parentCheckpointFileId: modelCollection?.startCheckpoint,
            getModelCollectionVersionOfParentCheckpointFile: "true",
            getParentCheckpointFileGCSName: "true",
            getParentCheckpointFileName: "true",
          });

        if (parentCheckpointFileDetailsResponse.data) {
          props.updateModelCollectionData({
            ...modelCollection,
            startCheckpointFileName:
              parentCheckpointFileDetailsResponse.data.modelCollection
                .parentCheckpointFile.name,
            startCheckpointFileModelCollectionVersion:
              parentCheckpointFileDetailsResponse.data.modelCollection.version,
            startCheckpointFileGCSName:
              parentCheckpointFileDetailsResponse.data.modelCollection
                .parentCheckpointFile.gcsName,
          });
        }
      }
    };
    executeFunction();
  }, [
    modelCollection,
    modelCollection?._id,
    modelCollection?.startCheckpoint,
    modelCollection?.startCheckpointFileName,
  ]);

  console.log("ModelCollectionDeployButton", props?.disabledDeployButton);
  console.log("modleColectio", modelCollection);
  return (
    <Dialog
      ref={dialogRef}
      onClose={onClose}
      open={props.open}
      maxWidth="lg"
      fullWidth={false}
      classes={{
        paperScrollPaper: classes.dialogContainer,
        paperWidthLg: classes.dialogContainerWidth,
      }}
    >
      <div className={classes.root}>
        <div
          className={classes.closeBtn}
          onClick={() => {
            onClose();
          }}
        >
          <Cancel style={{ fontSize: "40px", padding: "4px" }} />
        </div>

        {!props.isFetching ? (
          <div className={classes.body}>
            {/* left arrow  */}
            {
              <IconButton
                onClick={() => arrowClicked("left")}
                className={classes.navigateButton}
                style={{
                  visibility: props.isResourceExistBefore
                    ? "visible"
                    : "hidden",
                }}
              >
                <NavigateBeforeIcon
                  style={{ color: "black", fontSize: "xxx-large" }}
                />
              </IconButton>
            }

            {/* model collection resource details */}
            <div className={classes.showResource}>
              <div className={classes.checkpointFiles}>
                <div style={{ overflow: "auto", height: "100%" }}>
                  {modelCollection?.checkpointFiles &&
                    modelCollection?.checkpointFiles.length > 0 && (
                      <div
                        className={classes.info}
                        style={{
                          flexDirection: "column",
                          paddingBottom: "16px",
                        }}
                      >
                        <p className={classes.infoLabel}>Checkpoint Files:</p>
                        <p
                          className={`${classes.infoValue} ${classes.infoLevel2}`}
                          style={{ maxWidth: "94%" }}
                        >
                          {modelCollection?.checkpointFiles.map(
                            (checkpointFile) => {
                              if (checkpointFile.isDefaultCheckpoint) {
                                return renderCheckpointFileDetails(
                                  checkpointFile,
                                  modelCollection
                                );
                              }
                            }
                          )}
                          {modelCollection?.checkpointFiles.find(
                            (checkpointFile) =>
                              checkpointFile.isDefaultCheckpoint !== true
                          ) && (
                            <>
                              {showMore && (
                                <ShowMoreLink onClick={showMoreClick} />
                              )}
                              {!showMore && (
                                <ShowLessLink onClick={showLessClick} />
                              )}
                              {!showMore &&
                                modelCollection?.checkpointFiles?.map(
                                  (checkpointFile) => {
                                    if (!checkpointFile.isDefaultCheckpoint) {
                                      return renderCheckpointFileDetails(
                                        checkpointFile,
                                        modelCollection
                                      );
                                    }
                                  }
                                )}
                              {!showMore && (
                                <ShowLessLink onClick={showLessClick} />
                              )}
                            </>
                          )}
                        </p>
                      </div>
                    )}
                </div>
              </div>

              <div className={classes.detailsSection}>
                {/* Action Button section */}
                <div
                  style={{
                    backgroundColor: "white",
                    opacity: 1,
                    zIndex: 5,
                    marginLeft: "4px",
                  }}
                  className={classes.actoinButtonWidth}
                >
                  <div style={{ display: "flex" }}>
                    <div style={{ display: "flex", alignItems: "center" }}>
                      <p
                        style={{
                          marginBottom: "10px",
                          color: `${"#8e969e"}`,
                          marginRight: "30px",
                          width: "120px",
                        }}
                      >
                        Actions :{" "}
                      </p>
                    </div>
                    <div style={{ width: "200px" }}>
                      {/* <props.ActionButtonArray/> */}
                      <div style={{ display: "flex" }}>
                        {/* <p className={classes.infoLabel}
                                    style={{
                                        display: "flex",
                                        alignSelf: "center"
                                    }}
                                    >Actions:</p> */}
                        <p
                          // className={classes.infoValue}
                          style={{
                            width: "100%",
                          }}
                        >
                          {modelCollection &&
                          collectionsBeingDeleted.indexOf(
                            modelCollection?._id
                          ) >= 0 ? (
                            <CircularProgress
                              classes={{
                                root: clsx(classes.progress, classes.delete),
                              }}
                              size={48}
                            />
                          ) : (
                            <div
                              style={{
                                display: "flex",
                                justifyContent: "flex-start",
                              }}
                            >
                              {modelCollection !== undefined &&
                                (modelCollection?.checkpointFiles?.length !==
                                0 ? (
                                  <Tooltip title={"Test the collection"}>
                                    <IconButton
                                      onClick={() => {
                                        modelCollection &&
                                          handleTestButtonClick(
                                            modelCollection
                                          );
                                      }}
                                    >
                                      {/* <img src="https://cdn-icons-png.flaticon.com/512/1581/1581884.png" height={24} width={24} /> */}
                                      <img
                                        alt=""
                                        src={testIcon}
                                        height={24}
                                        width={24}
                                      />
                                    </IconButton>
                                  </Tooltip>
                                ) : (
                                  <Tooltip
                                    title={
                                      "Test: Collection does not contain any checkpoint file!"
                                    }
                                  >
                                    <div>
                                      <IconButton
                                        style={{
                                          pointerEvents: "none",
                                          opacity: "0.5",
                                          padding: "15px 12px 12px",
                                        }}
                                        disabled
                                      >
                                        {/* <img src="https://cdn-icons-png.flaticon.com/512/1581/1581884.png" height={24} width={24} /> */}
                                        <img
                                          alt=""
                                          src={testIcon}
                                          height={24}
                                          width={24}
                                        />
                                      </IconButton>
                                    </div>
                                  </Tooltip>
                                ))}
                              {/* <Tooltip title={modelCollection && 
                                          (modelCollection?.checkpointFiles?.length <= 0)? 
                                          "Checkpoint file should be there to perform deployment" :
                                          (architecturesAvailable.find(architecture => architecture.label === modelCollection?.modelArchitecture) === undefined) ?
                                          "Model collection architecture is Invalid" :
                                          (architecturesAvailable.find(architecture => (architecture.label === modelCollection?.modelArchitecture && architecture.isDeploymentFeatureEnabled)) === undefined) ? 
                                          "Deployment feature is not enabled"  :
                                          (architecturesAvailable.find(architecture => (architecture.label === modelCollection?.modelArchitecture && architecture.apiUrlToCallForDeployment !== "")) === undefined) ?
                                           "Api Url to perform deployment is not available" :
                                          "Deploy"
                                        }>
                                        <div>
                                        {modelCollection && 
                                        <IconButton
                                            // classes={{ root: `${classes.deployButton} ${(currentModelCollection?.checkpointFiles?.length > 0) && (architecturesAvailable.find(architecture => architecture.label === currentModelCollection.modelArchitecture && architecture.apiUrlToCallForDeployment !== "" && architecture.isDeploymentFeatureEnabled)) ? "" : classes.tableDisabledCell} ${currentModelCollection?.accuracy && liveModelCollectionAccuracyIfPresent && (currentModelCollection?.accuracy >= liveModelCollectionAccuracyIfPresent) ? "" : classes.tableDisabledCell }` }}
                                            classes={{ root: `${classes.deployButton} ${(modelCollection?.checkpointFiles?.length > 0) && (architecturesAvailable.find(architecture => architecture.label === modelCollection?.modelArchitecture && architecture.apiUrlToCallForDeployment !== "" && architecture.isDeploymentFeatureEnabled)) ? "" : classes.tableDisabledCell} ` }}
                                            onClick={()=>{
                                            setPerformModelCollectionDeploymentPopupState(oldState=>({...oldState, isOpen: true, selected: modelCollection}))
                                            }}
                                        >
                                            <img style={{width: "30px", transform: "scale(1.5)"}} src={DeploySVGIcon}/>
                                        </IconButton>}
                                        </div>
                                        </Tooltip> */}
                              {/* {modelCollection && <ModelCollectionDeployButton disabledDeployButton={props?.disabledDeployButton} modelRow={modelCollection} architecturesAvailable={architecturesAvailable} setPerformModelCollectionDeploymentPopupState={setPerformModelCollectionDeploymentPopupState} />} */}
                              {modelCollection && (
                                <ModelCollectionDeployButton
                                  disabledDeployButton={false}
                                  modelRow={modelCollection}
                                  architecturesAvailable={
                                    props.architecturesAvailable
                                  }
                                  setPerformModelCollectionDeploymentPopupState={
                                    setPerformModelCollectionDeploymentPopupState
                                  }
                                />
                              )}
                              {modelCollection && (
                                <ShowModelCollectionHistoryViewButton
                                  modelCollectionId={modelCollection._id}
                                />
                              )}
                              <Tooltip title={"Delete the collection"}>
                                <IconButton
                                  classes={{ root: classes.deleteButton }}
                                  aria-label="export"
                                  onClick={() =>
                                    modelCollection &&
                                    props.confirmDeleteCollection({
                                      multiDelete: false,
                                      collectionToDelete: modelCollection,
                                      showConfirmDeleteModal: true,
                                      selectedCollectionIds: [],
                                      collectionType: "model",
                                      deletedCollectionFromPopup: true,
                                    })
                                  }
                                >
                                  <Delete />
                                </IconButton>
                              </Tooltip>
                              {props.isReTestApiFetching ? (
                                <CircularProgress
                                  style={{ margin: "14px 0px 0px 8px" }}
                                  size={24}
                                />
                              ) : // (((!currentModelCollection.accuracy || currentModelCollection.accuracy <= 0) && (currentModelCollection.status ===  ("Trained" || "Re-Trained" || "Live"))
                              // !currentModelCollection.accuracy || currentModelCollection.accuracy <= 0
                              true &&
                                (modelCollection?.status === "Trained" ||
                                  modelCollection?.status === "Re-Trained" ||
                                  modelCollection?.status === "Live") ? (
                                <Tooltip title={"Re-Test the collection"}>
                                  <IconButton
                                    onClick={() => {
                                      modelCollection &&
                                        props.handleReTestButtonClick(
                                          modelCollection
                                        );
                                    }}
                                  >
                                    <img
                                      alt=""
                                      src={reTestIcon}
                                      height={24}
                                      width={24}
                                    />
                                  </IconButton>
                                </Tooltip>
                              ) : (
                                <Tooltip
                                  title={
                                    modelCollection?.accuracy &&
                                    modelCollection?.accuracy > 0
                                    ? 
                                    "Model is already tested"
                                    : 
                                    "Re-Test: Not Enough details to Re-Train the collection "
                                  }
                                >
                                  <div>
                                    <IconButton
                                      style={{
                                        pointerEvents: "none",
                                        opacity: "0.5",
                                        padding: "15px 12px 12px",
                                      }}
                                      disabled
                                    >
                                      <img
                                        alt=""
                                        src={reTestIcon}
                                        height={24}
                                        width={24}
                                      />
                                    </IconButton>
                                  </div>
                                </Tooltip>
                              )}
                            </div>
                          )}
                        </p>
                      </div>
                    </div>
                  </div>
                </div>

                <div style={{ overflow: "auto", height: "85%" }}>
                  <div className={classes.info}>
                    <p className={classes.infoLabel}>Version:</p>
                    <p className={classes.infoValue}>
                      <Tooltip title={modelCollection?.version || ""}>
                        <span>{modelCollection?.version}</span>
                      </Tooltip>
                    </p>
                  </div>

                  <div className={classes.info}>
                    <p
                      className={classes.infoLabel}
                      style={{
                        display: "flex",
                        alignSelf: "center",
                      }}
                    >
                      Status:
                    </p>
                    <p className={classes.infoValue}>
                      <Tooltip title={modelCollection?.status || ""}>
                        {/* <span>{currentModelCollection.status}</span> */}
                        <ModelStatus
                          status={modelCollection?.status || "Queued"}
                        />
                      </Tooltip>
                    </p>
                  </div>

                  <div className={classes.info}>
                    <p
                      className={classes.infoLabel}
                      style={{
                        display: "flex",
                        alignSelf: "center",
                      }}
                    >
                      Accuracy:
                    </p>
                    <p
                      className={classes.infoValue}
                      style={{
                        width: "31%",
                      }}
                    >
                      {/* {currentModelCollection && (currentModelCollection?.accuracy === undefined || currentModelCollection?.accuracy < 0 ? "NA" : currentModelCollection?.accuracy <= 9 ? "0"+ currentModelCollection?.accuracy + "%" : currentModelCollection?.accuracy + "%")} */}
                      {modelCollection?.reTestingDetail ? (
                        <Tooltip
                          title={
                            modelCollection?.reTestingDetail.status ===
                            "preparingRequest"
                              ? "Preparing Request"
                              : modelCollection?.reTestingDetail.status ===
                                "queued"
                              ? " Re-Testing "
                              : modelCollection?.reTestingDetail.status ===
                                "failed"
                              ? " Failed "
                              : " NA "
                          }
                        >
                          <Typography component="p" noWrap>
                            <Chip
                              style={{ maxWidth: "100%" }}
                              label={
                                modelCollection?.reTestingDetail.status ===
                                "preparingRequest"
                                  ? "Preparing Request"
                                  : modelCollection?.reTestingDetail.status ===
                                    "queued"
                                  ? " Re-Testing"
                                  : modelCollection?.reTestingDetail.status ===
                                    "failed"
                                  ? " Failed "
                                  : " NA "
                              }
                              classes={{ root: classes.chip }}
                            />
                          </Typography>
                        </Tooltip>
                      ) : (
                        <p style={{ width: "85%", margin: 0 }}>
                          {modelCollection && (
                            <ModelCollectionAccuracy
                              isLiveModelCollection={
                                modelCollection?.status === "Live"
                                  ? true
                                  : false
                              }
                              liveCollectionAccuracy={
                                (props?.modelCollectionsAvailable &&
                                  props?.modelCollectionsAvailable.filter(
                                    (collection: IModelCollection) =>
                                      collection.status === "Live"
                                  )[0]?.accuracy) ||
                                0
                              }
                              accuracyValue={props.accuracyValue}
                              jsonFileData={
                                modelCollection.jsonFileDataForAccuracy
                              }
                            />
                          )}
                        </p>
                      )}
                      {/* <ModelCollectionAccuracy 
                                    isLiveModelCollection={currentModelCollection?.status === "Live" ? true : false}
                                    liveCollectionAccuracy={
                                    props.modelCollection?.filter((collection: IModelCollection)=>collection.status === "Live")[0]?.accuracy || 0
                                    }
                                    accuracyValue={currentModelCollection.accuracy || 0} 
                                    jsonFileData={currentModelCollection.jsonFileDataForAccuracy}
                                />               */}
                    </p>
                  </div>

                  {modelCollection?.reTestingDetail && (
                    <div className={classes.info} style={{ marginTop: "12px" }}>
                      <p className={classes.infoLabel} style={{ width: "37%" }}>
                        Re-Testing Started At:
                      </p>
                      <p className={classes.infoValue}>
                        {modelCollection?.reTestingDetail &&
                          convertDate(
                            modelCollection?.reTestingDetail.created_at
                          )}
                      </p>
                    </div>
                  )}
                  {/* 
                            {
                                modelCollection?.checkpointFiles && modelCollection?.checkpointFiles.length>0 &&
                                <div
                                className={classes.info}
                                style={{ flexDirection: "column", paddingBottom: "16px" }}            
                                >
                                <p className={classes.infoLabel}>Checkpoint Files:</p>
                                <p
                                    className={`${classes.infoValue} ${classes.infoLevel2}`}
                                    style={{ maxWidth: "94%" }}              
                                >
                                    {modelCollection.checkpointFiles.map((checkpointFile)=>{
                                    if (checkpointFile.isDefaultCheckpoint) {
                                        return renderCheckpointFileDetails(checkpointFile, modelCollection)
                                    }
                                    })}
                                    {
                                    modelCollection.checkpointFiles.find(checkpointFile=>checkpointFile.isDefaultCheckpoint !== true) &&
                                    <>
                                        {showMore && 
                                        <ShowMoreLink 
                                            onClick={showMoreClick}
                                        />
                                        }
                                        {!showMore && <ShowLessLink onClick={showLessClick}/>}
                                        {
                                        !showMore && modelCollection?.checkpointFiles?.map((checkpointFile) => {
                                            if (!checkpointFile.isDefaultCheckpoint) {
                                            return renderCheckpointFileDetails(checkpointFile, modelCollection)
                                            }
                                        })
                                        }
                                        {!showMore && <ShowLessLink onClick={showLessClick}/>}                                       
                                    </>
                                    }
                                </p>
                                </div>
                            } */}

                  <div className={classes.info}>
                    {!isEditingDescription && (
                      <p className={classes.infoLabel}>
                        Description:
                        <Tooltip title={"Edit Description"}>
                          <IconButton
                            id={"editButton-desciption-btn"}
                            color="primary"
                            aria-label="export"
                            size="small"
                            onClick={() => {
                              setIsEditingDescription(true);
                            }}
                          >
                            <EditIcon fontSize="inherit" />
                          </IconButton>
                        </Tooltip>
                      </p>
                    )}
                    {!isEditingDescription && (
                      <p
                        className={classes.infoValue}
                        style={{
                          overflow: "auto",
                          textOverflow: "inherit",
                          whiteSpace: "normal",
                          maxWidth: 200,
                        }}
                      >
                        {modelCollection?.modelDescription}
                      </p>
                    )}
                    {isEditingDescription && modelCollection && (
                      <div style={{ width: "100%" }}>
                        <TextField
                          label="Description"
                          id="description-input"
                          classes={{ root: classes.flex }}
                          value={descriptionValue}
                          onChange={(e) => {
                            setDescriptionValue(e.target.value);
                          }}
                          variant="outlined"
                          size="small"
                        />
                        <div
                          className={`${classes.addDescriptionBtns} ${classes.flex}`}
                        >
                          <Tooltip title={"Cancel"}>
                            <IconButton
                              id={"cancel-description-btn"}
                              color="primary"
                              classes={{ root: classes.deleteButton }}
                              aria-label="export"
                              onClick={() => {
                                modelCollection?.modelDescription &&
                                  setDescriptionValue(
                                    modelCollection.modelDescription
                                  );
                                setIsEditingDescription(false);
                              }}
                            >
                              <ClearIcon fontSize="small" />
                            </IconButton>
                          </Tooltip>
                          <Tooltip title={"Save"}>
                            <IconButton
                              id={"save-desciption-btn"}
                              color="primary"
                              aria-label="export"
                              onClick={() => {
                                updateModelDescription(descriptionValue);
                              }}
                            >
                              <CheckIcon fontSize="small" />
                            </IconButton>
                          </Tooltip>
                        </div>
                      </div>
                    )}
                  </div>

                  {modelCollection && (
                    <div className={classes.info}>
                      <p className={classes.infoLabel}>Resources File:</p>
                      <p
                        className={`${classes.infoValue} ${classes.showAsLink}`}
                        style={{
                          overflow: "hidden",
                          textOverflow: "ellipsis",
                          whiteSpace: "nowrap",
                          width: "40%",
                        }}
                      >
                        <Tooltip title={modelCollection.resourcesFileName}>
                          {/* <Link
                                    target="_blank"
                                    rel="noreferrer"
                                    download
                                    href={currentModelCollection.resourcesFileUrl}
                                    >
                                    {currentModelCollection.resourcesFileName}
                                    </Link> */}
                          <span>{modelCollection?.resourcesFileName}</span>
                        </Tooltip>
                      </p>
                      {modelCollection?.resourcesGcsFileName && (
                        <p
                          style={{
                            width: "20%",
                            display: "flex",
                            justifyContent: "center",
                            cursor: "pointer",
                            marginBottom: "0px",
                          }}
                          onClick={async () => {
                            if (modelCollection) {
                              const apiResponse =
                                await CloudStorageService.getResourceBucketFileDownloadURL(
                                  {
                                    storageFileName:
                                      modelCollection.resourcesGcsFileName,
                                    projectId: projectId,
                                  }
                                );
                              if (apiResponse.data) {
                                window.open(apiResponse.data, "_blank");
                              }
                            }
                          }}
                        >
                          <FileDownloadIcon />
                        </p>
                      )}
                    </div>
                  )}

                  {modelCollection?.defaultDataSetCollectionName && (
                    <div className={classes.info}>
                      <p className={classes.infoLabel}>
                        Default Dataset Collection:
                      </p>
                      <p
                        className={classes.infoValue}
                        style={{
                          overflow: "hidden",
                          textOverflow: "ellipsis",
                          whiteSpace: "nowrap",
                          width: "40%",
                        }}
                      >
                        {/* <Tooltip title={currentModelCollection?.populatedDefaultDataSetCollection?.name || currentModelCollection?.defaultDataSetCollection?.name || ""}>
                                <Chip
                                    classes={{ root: classes.chip }}
                                    label={currentModelCollection?.populatedDefaultDataSetCollection?.name || currentModelCollection?.defaultDataSetCollection?.name}
                                />
                                </Tooltip>               */}
                        <Tooltip
                          title={
                            modelCollection?.defaultDataSetCollectionName || ""
                          }
                        >
                          <span className={`${classes.showAsLink}`}>
                            {modelCollection?.defaultDataSetCollectionName ||
                              ""}
                          </span>
                        </Tooltip>
                      </p>
                      {modelCollection?.defaultDataSetGcsFileName && (
                        <p
                          style={{
                            width: "20%",
                            display: "flex",
                            justifyContent: "center",
                            cursor: "pointer",
                            marginBottom: "0px",
                          }}
                          onClick={async () => {
                            if (modelCollection) {
                              const apiResponse =
                                await CloudStorageService.getResourceBucketFileDownloadURL(
                                  {
                                    storageFileName:
                                      modelCollection.defaultDataSetGcsFileName ||
                                      "",
                                    projectId: projectId,
                                  }
                                );
                              if (apiResponse.data) {
                                window.open(apiResponse.data, "_blank");
                              }
                            }
                          }}
                        >
                          <FileDownloadIcon />
                        </p>
                      )}
                    </div>
                  )}

                  <div className={classes.info}>
                    <p className={classes.infoLabel}>Model Architecture:</p>
                    <p className={classes.infoValue}>
                      <Tooltip title={modelCollection?.modelArchitecture || ""}>
                        <span>{modelCollection?.modelArchitecture}</span>
                      </Tooltip>
                    </p>
                  </div>

                  <div className={classes.info}>
                    <p className={classes.infoLabel}>Hyper Parameter:</p>
                    <p className={classes.infoValue}>
                      <Tooltip title={modelCollection?.hyperParameter || ""}>
                        <span>{modelCollection?.hyperParameter}</span>
                      </Tooltip>
                    </p>
                  </div>

                  <div className={classes.info}>
                    <p className={classes.infoLabel}>Start Checkpoint:</p>
                    <p
                      className={`${classes.infoValue} ${
                        modelCollection?.startCheckpoint &&
                        modelCollection?.startCheckpoint !==
                          ModelCollectionStartCheckpointUndefinedValue
                          ? classes.showAsLink
                          : ""
                      }`}
                      style={{
                        overflow: "hidden",
                        textOverflow: "ellipsis",
                        whiteSpace: "nowrap",
                        width: "40%",
                      }}
                    >
                      <Tooltip
                        title={
                          modelCollection?.startCheckpointFileName ||
                          modelCollection?.startCheckpoint ||
                          ""
                        }
                      >
                        <span>
                          {modelCollection?.startCheckpointFileName ||
                            modelCollection?.startCheckpoint}
                        </span>
                      </Tooltip>
                    </p>
                    {modelCollection?.startCheckpointFileGCSName && (
                      <p
                        style={{
                          width: "20%",
                          display: "flex",
                          justifyContent: "center",
                          cursor: "pointer",
                          marginBottom: "0px",
                        }}
                        onClick={async () => {
                          const apiResponse =
                            await CloudStorageService.getResourceBucketFileDownloadURL(
                              {
                                storageFileName:
                                  modelCollection?.startCheckpointFileGCSName ||
                                  "",
                                projectId: projectId,
                              }
                            );
                          if (apiResponse.data) {
                            window.open(apiResponse.data, "_blank");
                          }
                        }}
                      >
                        <FileDownloadIcon />
                      </p>
                    )}
                  </div>

                  {modelCollection?.startCheckpointFileModelCollectionVersion && (
                    <div className={classes.info}>
                      <p className={classes.infoLabel}>
                        Start Checkpoint Model Version:
                      </p>
                      <p className={`${classes.infoValue}`}>
                        <Tooltip
                          title={
                            modelCollection?.startCheckpointFileModelCollectionVersion ||
                            ""
                          }
                        >
                          <span>
                            {
                              modelCollection?.startCheckpointFileModelCollectionVersion
                            }
                          </span>
                        </Tooltip>
                      </p>
                    </div>
                  )}

                  {modelCollection?.numberOfLayersToFreeze !== null &&
                    modelCollection?.numberOfLayersToFreeze !== undefined && (
                      <div className={classes.info}>
                        <p className={classes.infoLabel}>
                          Number of Layers to Freeze:
                        </p>
                        <p className={classes.infoValue}>
                          <Tooltip
                            title={
                              modelCollection?.numberOfLayersToFreeze || ""
                            }
                          >
                            <span>
                              {modelCollection?.numberOfLayersToFreeze}
                            </span>
                          </Tooltip>
                        </p>
                      </div>
                    )}

                  <div className={classes.info}>
                    <p className={classes.infoLabel}>
                      Training Dataset Collections
                    </p>
                    <Tooltip title={""}>
                      <div
                        className={`
                                    ${classes.infoValue} 
                                    ${classes.stringArrayValue}
                                    `}
                      >
                        {modelCollection?.trainingDataDirectory.map(
                          (trainingDataDirectory: string) => (
                            <Chip
                              key={trainingDataDirectory}
                              classes={{ root: classes.chip }}
                              label={trainingDataDirectory}
                            />
                          )
                        )}
                      </div>
                    </Tooltip>
                  </div>
                  <div className={classes.info}>
                    <p className={classes.infoLabel}>Created At:</p>
                    <p className={classes.infoValue}>
                      {modelCollection &&
                        convertDate(modelCollection?.created_at)}
                    </p>
                  </div>
                  {modelCollection?.created_by && (
                    <div className={classes.info}>
                      <p className={classes.infoLabel}>Created By:</p>
                      <p
                        className={classes.infoValue}
                        style={{
                          maxWidth: "200px",
                          wordWrap: "break-word",
                          whiteSpace: "unset",
                        }}
                      >
                        {modelCollection?.created_by}
                      </p>
                    </div>
                  )}
                  <div className={classes.info}>
                    <p className={classes.infoLabel}>Last Updated At:</p>
                    <p className={classes.infoValue}>
                      {modelCollection &&
                        convertDate(modelCollection?.updated_at)}
                    </p>
                  </div>
                  {modelCollection?.last_deployed_at && (
                    <div className={classes.info}>
                      <p className={classes.infoLabel}>Last Deployed At:</p>
                      <p className={classes.infoValue}>
                        {convertDate(modelCollection?.last_deployed_at)}
                      </p>
                    </div>
                  )}
                  <Autocomplete
                    multiple
                    id="Tags Available"
                    options={[]}
                    freeSolo
                    disabled={isModelBeingUpdatedInDB}
                    value={tagsState.value}
                    getOptionSelected={() => false}
                    onChange={(event, value) => {
                      setTagsState((oldState) => {
                        const tagsToSave = removeDuplicateElementsFromArray(
                          value as string[]
                        );

                        return {
                          ...oldState,
                          isDirty: true,
                          isTouched: true,
                          value: [...tagsToSave],
                        };
                      });
                    }}
                    renderTags={(value, getTagProps) => {
                      return value.map((option, index) => (
                        <Chip
                          variant="outlined"
                          color="primary"
                          // className={`${classes.chip}`}
                          // classes={{ root: classes.autoCompleteChipRoot }}
                          label={
                            <Tooltip title={option}>
                              <span>{option}</span>
                            </Tooltip>
                          }
                          {...getTagProps({ index })}
                        />
                      ));
                    }}
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        variant="outlined"
                        size="small"
                        margin="normal"
                        className={classes.floating}
                        fullWidth
                        label="Tags"
                        placeholder="Add Tags"
                        onChange={(e) => {
                          handleTagsInputChangeEvent(e.target.value);
                        }}
                        onBlur={(e) => {
                          handleTagsInputChangeEvent(e.target.value + ",");
                        }}
                        error={
                          tagsState.isTouched && tagsState.errorMessage
                            ? true
                            : false
                        }
                        helperText={
                          tagsState.isTouched && tagsState.errorMessage
                            ? tagsState.errorMessage
                            : "Add multiple tag with <enter> or <comma>"
                        }
                      />
                    )}
                  />
                  <Button
                    color="primary"
                    variant="contained"
                    onClick={updateTags}
                    disabled={
                      !tagsState.isTouched || isModelBeingUpdatedInDB
                        ? true
                        : false
                    }
                    style={{ marginBottom: "20px", minWidth: 150 }}
                  >
                    {isModelBeingUpdatedInDB ? (
                      <CircularProgress size={"1.5rem"} />
                    ) : (
                      "Update Tags"
                    )}
                  </Button>
                  {/* Remarks Sections */}
                  <>
                    <p
                      style={{ marginBottom: "10px" }}
                      className={classes.infoLabel}
                    >
                      Add Remarks:
                    </p>
                    <textarea
                      style={{
                        width: "100%",
                        minHeight: "83px",
                        marginBottom: "10px",
                        fontSize: "13px",
                        fontFamily: "Nunito Sans",
                        borderRadius: "5px",
                      }}
                      value={remarksTextState.value}
                      onChange={(event) => {
                        setRemarksTextState((oldState) => ({
                          ...oldState,
                          isDirty: true,
                          value: event.target.value,
                        }));
                      }}
                      disabled={isRemarksBeingUpdatedInDB}
                    />
                    <Button
                      color="primary"
                      variant="contained"
                      onClick={() => {
                        onSaveRemarksClick();
                        return;
                      }}
                      disabled={
                        isRemarksBeingUpdatedInDB || !remarksTextState.isDirty
                          ? true
                          : false
                      }
                      style={{ marginBottom: "20px" }}
                    >
                      Save Remarks
                    </Button>
                  </>
                </div>
              </div>
            </div>

            {/* right arrow  */}
            {
              <IconButton
                onClick={() => arrowClicked("right")}
                className={classes.navigateButton}
                style={{
                  visibility: props.isResourceExistAfter ? "visible" : "hidden",
                }}
              >
                <NavigateNextIcon
                  style={{ color: "black", fontSize: "xxx-large" }}
                />
              </IconButton>
            }
          </div>
        ) : (
          <div className={classes.body} style={{ justifyContent: "center" }}>
            <CircularProgress />
          </div>
        )}
      </div>
      {performModelCollectionDeploymentPopupState.isOpen && (
        <PerformModelCollectionDeploymentDialog
          open={true}
          modelCollection={
            performModelCollectionDeploymentPopupState.selected || ({} as any)
          }
          onClose={() => {
            setPerformModelCollectionDeploymentPopupState((oldState) => ({
              ...oldState,
              isOpen: false,
            }));
          }}
          fetchCollections={fetchModelCollection}
        />
      )}
      {/* </div> */}
      <DeleteCollectionConfirmPopup
        singleDeleteCallBack={handleCollectionDeleteButtonClick}
        isParentPopup={true}
      />
    </Dialog>
  );
}

const mapProps = (state: IReduxState) => {
  return {
    isAdding: state.collection.isAdding,
    selected: state.collection.modelCollection.modelCollectionSelected,
    modelCollectionsAvailable:
      state.collection.modelCollection.modelCollections,
    totalCount:
      state.collection.modelCollection.totalModelCollectionsTableCount,
    isDeleteSuccuss: state.collection.isDeleteSuccess,
    errorMessagesDueToModelCollectionLinkedSomewhereElse:
      state.collection.modelCollection
        .errorMessagesDueToModelCollectionLinkedSomewhereElse,
  };
};
const connector = connect(mapProps, {
  setSelectedModelCollection,
  setSideDrawer,
  deleteModelCollection,
  updateModelCollectionData,
  confirmDeleteCollection,
});
export type TPropsFromRedux = ConnectedProps<typeof connector>;

export default connector(ModelCollectionPopup);

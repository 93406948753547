import { CircularProgress, makeStyles, Paper, Tooltip } from '@material-ui/core';
import React, { useCallback, useEffect, useRef, useState } from 'react';
import { AnalyticsResourceView, IAnalyticsModelData, IAnalyticsScenario, IAnalyticsTableFilterObject, IData, IGetAnalyticsResourcesQueryParams, IGetIntegrity, IIntegrity, IModel, IModelAnalyticsFetchTestStatisticalDataQueryParams, IModelAnalyticsTestStatisticalData, IntegrityBand, IResourceAnalyticsCollection, ITestDriveResultsTableHeaderValues, queryObjectToString, ResourceImageAnnotation } from '../../../../../../../../../../../common';
import AnalyticsTable, { ResourceLabelFilterOperator } from './AnalyticsTable';
import AnalyticsTableEnhancedToolbar from './AnalyticsTableEnhancedToolbar';
import {
    tablePaperStyle,
} from "../../../constants/tableStyles";
import { useHistory, useLocation } from 'react-router';
import { useURLQuery } from '../../../../../../../../../../../hooks/useURLQuery';
import { convertURLQueryParamsToJSON } from '../../../../../../../../../../../services/urlQueryParamService';
import { DataService, ModelService } from '../../../../../../../../../../../services';
import { AddOrDeleteResourcesToDataSetCollectionSelectionPopup } from '../../data/components/AddOrDeleteResourcesToDataSetCollectionSelectionPopup';
import { removeResourceIdsWhichAreAddedToDataSetCollectionInDB } from '../../../../../../../../../../../store/reducers/data';
import { resourceContainsDataSetCollection } from '../../../../../../../../../../../services/resourceHelperService';
import { tooltipTextForAddResourceDataSetCollectionButton, tooltipTextForEditResourceDataSetCollectionButton } from '../../data/constants/constants';
import AddResourceToDataSetCollectionButton from '../../data/components/AddResourceToDataSetCollectionButton';
import { convertStringtoContainsExpressionInRegex, convertStringtoEndsWithExpressionInRegex, convertStringtoEqualsExpressionInRegex, convertStringtoStartsWithExpressionInRegex } from '../../../../../../../../../../../services/regexHelperService';
import { IOU_THRESHOLD_DEFAULT_VALUE_FOR_SEGMENTATION_ANALYTICS } from './integrity';
import { IAnalyticsPurposeModelData, IPurposeModel } from '../../../../../../../../../../../common/constants/interfaces/purposeModels'
import PurposeModelService from '../../../../../../../../../../../services/PurposeModelService';
import { isNullOrUndefined } from '../../../../../../../../../../../services/variableHelperService';
import useAuth from '../../../../../../../../../../../hooks/useAuth';
import { ITestDriveVM } from '../../../../../../aiMarketPlace/services/types/testDrive';

enum URLQueryParams {
    problemType="problemType"
}

export type AddResourcesFromAnalyticsToCollectionQueryParamsReqPayload = {
    modelId: string,
    modelVersion: string,
    limit: string,
    sortField: "label" | "confidence_score" | "predicted_status",
    sortOrder: "ascending" | "descending",
    labelRegex: string, // example: ['/door_stop/', '/door_open/']
    predictionRegex: string
    resourceConfidenceScoreMinValue: string,
    resourceConfidenceScoreMaxValue: string,
    resourceIouScoreMinValue: string,
    resourceIouScoreMaxValue: string,

    getSameHandFeedbackNotYetGiven:string,
    getSameHandWithMultipleIds:string,
    getSameHandWithNoMultipleIds:string,
    predictionStatusFilter: "onlyCorrect" | "onlyIncorrect"
}

const useStyles = makeStyles((theme) => ({
    paper: tablePaperStyle(theme),
    table: {
        // minWidth: 750,
        tableLayout: 'fixed'
    },
    root: {
        marginBottom: theme.spacing(2),
        position: "sticky",
        top: "0px",
        backgroundColor: "#f0f5fc",
        zIndex: 1,
    },
    tableContainerStyleInChatBotWindowScenario:{
        display:"flex",
        flexDirection:"column",
        justifyContent:"space-between",
        height:"100%",
        borderRadius:'12px'
    },
    tableContainerStyleInChatBotTestDrive:{
        display:'flex'
    }
}));

export type OtherModelDutchTranslationPrediction = {
    modelCollectionId: string,
    modelVersion: string,
    resourceId: string,
    predicted: string,
    confidenceScore: string
}

export type OtherImageAnnotationPrediction = {
    modelCollectionId: string,
    modelVersion: string,
    resourceId: string,
    predictedAnnotations: ResourceImageAnnotation[],
    confidenceScore: string
    predictedAnnotation?: ResourceImageAnnotation
}

export type AnalyticsResourceReferenceGuideDataType = {
    linkedAnnotationLabels?: string[];
    fileUrl: string,
    fileName: string
}

type queryParamsForNonDefaultScenarios = {
    model?: string;
    project?: string;
    limit?: number;
    offset?: number;
    type?: string;
    status?: string;
    modelCollectionIdFilterSelected?: string;
    modelCollectionIdsToCompare?: string;
    scenario?: string;
    accuracy?: number;
    sortField?: string;
    sortOrder?: "ascending" | "descending";
    bandFilterSelected?: string;
    sortByValueFilterSelected?: string;
    iouThreshold?: number;
    iouScoreMax?: number;
    iouScoreMin?: number;
    labelFilterValue?: string;
    labelFilterOperator?: string;
    statusFilterOperator?: string;
    statusFilter?: string;
    predictionValue?: string;
    wordErrorRateMax?: number;
    wordErrorRateMin?: number;
    confidenceScoreMax?: number;
    confidenceScoreMin?: number;
    sortByClassFilterSelected?: string;
    testCollectionId?: string;
    getSameHandFeedbackNotYetGiven?: string;
    getSameHandWithMultipleIds?: string;
    getSameHandWithNoMultipleIds?: string;
    purposeModelId?: string;
    confidenceScoreThreshold?:number;
    isSegmentationModelAnalyticsEnabled?:boolean;
    testDataSetCollectionId?: string;
}
interface IProps {
    scenario?: "ChatBotWindow" |"TestDriveChatBotWindow",
    testDriveVm?: ITestDriveVM | null
    /** can be used to pass the query params as props when being used in non-default scenarios */
    queryParamsForNonDefaultScenarios?: queryParamsForNonDefaultScenarios,
}

const ModelAnalyticsResources = (props: IProps) => {
    const classes = useStyles();
    const bandOptions = [
        'ALL',
        '0% - 10%',
        '10% - 20%',
        '20% - 30%',
        '30% - 40%',
        '40% - 50%',
        '50% - 60%',
        '60% - 70%',
        '70% - 80%',
        '80% - 90%',
        '90% - 99%',
        '99% - 99.99%',
        '100%',
    ]

    const isTestDriveChatBotWindowScenario = props.scenario==="TestDriveChatBotWindow"
    const isChatBotWindowScenario = props.scenario === 'ChatBotWindow' || isTestDriveChatBotWindowScenario
    console.log({isChatBotWindowScenario})
    // const scenarioSpecificQueryParamsKey = `${props.scenario}_qParams`

    let urlQuery = new URLSearchParams(window.location.search)

    const getScenarioSpecificQParamKey = (key: string) => {
        const keyName = props.scenario ? `${props.scenario}_` + key : key;
        // console.log(keyName)
        return keyName;
    }
    const { userEmail } = useAuth()
    useEffect(() => {
        if(props.queryParamsForNonDefaultScenarios && props.scenario){
            // let queryParamsObj = {}
            // Object.entries(props.queryParamsForNonDefaultScenarios).forEach(([key, value]) => {
            //     //@ts-ignore
            //     queryParamsObj[key] = value;
            // })
            let queryParamString = '';
            Object.entries(props.queryParamsForNonDefaultScenarios).forEach(([key, value],index) => {
                queryParamString += (index === 0 ? '?' : '&') + getScenarioSpecificQParamKey(key) + '=' + value;
            })
            window.history.replaceState({}, '', queryParamString)
            urlQuery = new URLSearchParams(window.location.search)

        }
        
    }, [])

    const [isFetchingModelResources, setIsFetchingModelResources] =
        useState<boolean>(false);
    const [classFilterOption, setClassFilterOption] = useState < Array<string>>([])
    const [bandValue, setBandValue] = useState('');
    const [selectedModelCollectionId, setSelectedModelCollectionId] = useState<string>('');
    const [modelResourcesData, setModelResourcesData] = useState<Array<IResourceAnalyticsCollection>>([]);
    const [TestDriveResultsTableHeaderValues,setTestDriveResultsTableHeaderValues]=useState<ITestDriveResultsTableHeaderValues|{}>({})
    console.log({TestDriveResultsTableHeaderValues})
    const [isAccuracyInTestDriveResultsTableFetching,setIsAccuracyInTestDriveResultsTableFetching] = useState(false)
    const [isIntegrityInTestDriveResultsTableFetching,setIsIntegrityInTestDriveResultsTableFetching] = useState(false)
    const [modelInformation, setModelInformation] = useState<IAnalyticsModelData>();
    const modelInformationRef = useRef(modelInformation);
    modelInformationRef.current = modelInformation;

    const [taskDetails, setTaskDetails] = useState<IModel>();
    const taskDetailsRef = useRef(taskDetails);
    taskDetailsRef.current = taskDetails;

    const [totalResourceCount, setTotalResourceCount] = useState(0);
    const [uniquePredictedValues, setUniquePredictedValues] = useState<string[]>([])
    const [classFilterValue, setClassFilterValue] = useState('');
    const [modelCollectionOptions, setModelCollectionOptions] = useState<Array<IModelAnalyticsTestStatisticalData>>([]);
    const [analyticsResourcesViewType, setAnalyticsResourcesViewType] = useState<AnalyticsResourceView>("list-view");
    const [tableFilters, setTableFilters] = useState<IAnalyticsTableFilterObject>({} as unknown as IAnalyticsTableFilterObject)
    // const [modelVersionToCompareConfidenceScoreWith, setModelVersionToCompareConfidenceScoreWith] = useState("");
    const [modelCollectionToCompareConfidenceScoreWith, setModelCollectiontoCompareConfidenceScoreWith] = useState<IModelAnalyticsTestStatisticalData>();
    const [isFetchingConfidenceScoresForModelCollectionResources, setIsFetchingConfidenceScoresForModelCollectionResources] = useState(false);
    const [resourceIdsSelectedForAddingToDataSetCollection, setResourceIdsSelectedForAddingToDataSetCollection] = useState<string[]>([]);
    const [isAddResourcesToDataSetCollectionPopupShowing, setIsAddResourcesToDataSetCollectionPopupShowing] = useState(false);
    const [tableResourceIdsSelected, setTableResourceIdsSelected] = useState<string[]>([]);
    const [isDataSetCollectionsBeingFetchedForFilter, setIsDataSetCollectionsBeingFetchedForFilter] = useState(false);
    const [dataSetCollectionsAvailableForFilter, SetDataSetCollectionsAvailableForFilter] = useState<{_id: string, name: string}[]>([]);
    const [resourcesWhoseDataSetCollectionsAreBeingUpdatedInDB, setResourcesWhoseDataSetCollectionsAreBeingUpdatedInDB] = useState<string[]>([]);
    const [isDefaultConfidenceScoreFilterValue, setIsDefaultConfidenceScoreFilterValue] = useState(true);
        const [isDefaultLabelFilter, setIsDefaultLabelFilter] = useState(true);
    const [areMoreTableResourcesSelectedByUserWhichAreNotEvenDisplayingToUser, setAreMoreTableResourcesSelectedByUserWhichAreNotEvenDisplayingToUser] = useState(false);
 
    const [isFetchingOtherModelTranslatedlanguageTextpredictions, setIsFetchingOtherModelTranslatedlanguageTextpredictions] = useState(false);
    const [otherModelTranslatedLanguageTextPredictions, setOtherModelTranslatedLanguageTextPredictions] = useState<OtherModelDutchTranslationPrediction[]>([]);

    const [isFetchingOtherImageAnnotationPredictions, setIsFetchingOtherImageAnnotationPredictions] = useState(false);
    const [OtherImageAnnotationPredictions, setOtherImageAnnotationPredictions] = useState<OtherImageAnnotationPrediction[]>([]);
    
    const [projectModelDetails, setProjectModelDetails] = useState<IModel>();
    const [isFetchingProjectModelDetails, setIsFetchingProjectModelDetails] = useState(false);

    const isSpectrogramEnabled = projectModelDetails?.isSpectrogramEnabled || false;


    const [modelReferenceGuides, setModelReferenceGuides] = useState<AnalyticsResourceReferenceGuideDataType[]>([]);
    const [shouldModalOpenedAfterFetchingData,setShouldModalOpenedAfterFetchingData] = useState<boolean>(false);
    /**
     * Url & Query hooks
     */
    // const urlQuery = useURLQuery();

    // function parseQueryParamJson(input: any) {
    //     try {
    //       // Attempt to parse the input string as-is
    //       const parsed = JSON.parse(input);
    //       return parsed; // Return the requested property or the full object
    //     } catch (error) {
    //       console.warn("Invalid JSON string. Attempting to fix...");
          
    //       // Try to fix the string by escaping double quotes
    //       try {
    //         const fixedInput = input
    //           .replace(/"/g, '\\"')
    //           .replace(/^/, '"')  
    //           .replace(/$/, '"'); 
    //         const parsedFixed = JSON.parse(fixedInput);
    //         return parsedFixed;
    //       } catch (fixError) {
    //         console.error("Unable to parse JSON after fixing. Please check the input.");
    //         return null;
    //       }
    //     }
    //   }

    const getQueryParamValue = (key: keyof queryParamsForNonDefaultScenarios) => {
        const urlQuery = new URLSearchParams(window.location?.search);
        switch (props.scenario) {
            case "ChatBotWindow":
            case "TestDriveChatBotWindow":
                const queryKey = getScenarioSpecificQParamKey(key);
                return urlQuery.get(queryKey)
            default:
                return urlQuery.get(key);
        }
    }

    const modelId = getQueryParamValue('model') || '';
    const modelIdRef = useRef(modelId);
    modelIdRef.current = modelId;

    //@ts-ignore
    const purposeModelProblemType: IPurposeModel["problemType"] | "" = (getQueryParamValue(URLQueryParams.problemType) || "") as IPurposeModel["problemType"];

    const isSegmentationModelAnalyticsEnabled = 
        purposeModelProblemType
        ? PurposeModelService.isProblemTypeApplicableForImageAnnotationComparisonViewInModelAnalytics(purposeModelProblemType)
        : projectModelDetails?.isSegmentationModelAnalytics || false;    

    const history = useHistory();
    const location = useLocation();
    
    /**
     * Table Pagination state
     */
    const [rowsPerPage, setRowsPerPage] = useState(10);
    const [page, setPage] = useState(0);
    
    /**
     * Intial page load
     */
    useEffect(() => {
        const query = new URLSearchParams(location?.search);

        const {offset, limit, model, scenario, selectedModelCollectionIdValue, sortFieldQuery, sortOrderQuery, bandRangeValue, classAccuracyValue, modelCollectionIdOptions, modelCollectionOptionsValue, labelFilterValue, predictedStatusFilter, confidenceScoreMax, confidenceScoreMin, iouScoreMax,iouScoreMin,labelFilterOperator, statusValue, statusFilterOperator,getSameHandFeedbackNotYetGiven,getSameHandWithMultipleIds,getSameHandWithNoMultipleIds,
            wordErrorRateMax, wordErrorRateMin
        } = getQueryParamValues();

        setPage(parseInt(offset, 10) - 1);
        setRowsPerPage(parseInt(limit))
        const tableFilterObj = {
            sortField: sortFieldQuery,
            sortOrder: sortOrderQuery,
            labelRegex: labelFilterValue,
            predictionStatusFilter: predictedStatusFilter,
            resourceConfidenceScoreMinValue: confidenceScoreMin,
            resourceConfidenceScoreMaxValue: confidenceScoreMax,
            resourceWordErrorRateMaxValue: wordErrorRateMax,
            resourceWordErrorRateMinValue: wordErrorRateMin,
            resourceIouScoreMinValue: iouScoreMin,
            resourceIouScoreMaxValue: iouScoreMax,
            predictedStatusRegex: statusValue,
            getSameHandFeedbackNotYetGiven: getSameHandFeedbackNotYetGiven,
            getSameHandWithMultipleIds:getSameHandWithMultipleIds,
            getSameHandWithNoMultipleIds:getSameHandWithNoMultipleIds,
            statusFilterOperator: statusFilterOperator as unknown as ResourceLabelFilterOperator,
            labelFilterOperator: labelFilterOperator as unknown as ResourceLabelFilterOperator
        };
        handleTableFiltersChange(tableFilterObj)
        if (selectedModelCollectionIdValue && model) {
            fetchClasses(modelCollectionOptionsValue, model);
            fetchUniquePredictionValue(selectedModelCollectionIdValue, model)
        }
        if (scenario === 'integrity') {
            setIsDefaultLabelFilter(false)
        } else {
            setIsDefaultConfidenceScoreFilterValue(false)
        }
        handleBandChange(confidenceScoreMax ? 'CUSTOM' : bandRangeValue, tableFilterObj);
        handleModelCollectionChange(selectedModelCollectionIdValue);
        handleClassFilterChange(labelFilterValue.length || (labelFilterOperator && labelFilterOperator !== 'equals') ? 'CUSTOM' : classAccuracyValue, tableFilterObj);
        fetchModelCollectionIdsWithAccuracy(modelCollectionIdOptions);
        let isResourceToCompareWithIsThere = !isNullOrUndefined(query.get('resourceToCompareWith'))
        query.delete('resourceToCompareWith');
        location.search = query.toString();
        if(isResourceToCompareWithIsThere){
            history.replace(`${location?.pathname}?${query.toString()}`);
        }
    }, []);
    useEffect(()=>{
        if(isTestDriveChatBotWindowScenario){
            fetchAccuracy()
            fetchIntegrtity()
        }
    },[isTestDriveChatBotWindowScenario])
    const fetchAccuracy = async() =>{
        setIsAccuracyInTestDriveResultsTableFetching(true)
        const modelCollectionId = props?.queryParamsForNonDefaultScenarios?.modelCollectionIdsToCompare || "";
        const testDataSetCollectionId = getQueryParamValue("testDataSetCollectionId");
        let integrityApiRequestPayload: IGetIntegrity = {
            modelCollectionIds: `[\"${modelCollectionId}\"]`,
            modelId: props?.queryParamsForNonDefaultScenarios?.model || "",
            modelCollectionsAdditionalData:[],
            ...(testDataSetCollectionId ? { dataSetCollectionId: testDataSetCollectionId}: {}),
            testCollectionId: "",
            isAnalyticsPageOpenedFromTestDrive:isTestDriveChatBotWindowScenario, requestorEmailId:userEmail,tryOutVmId: props?.testDriveVm?._id
        };
        if (props?.queryParamsForNonDefaultScenarios?.isSegmentationModelAnalyticsEnabled) {
            integrityApiRequestPayload.modelCollectionsAdditionalData = JSON.stringify([{
                modelCollectionId: modelCollectionId,
                iouThreshold: props?.queryParamsForNonDefaultScenarios?.iouThreshold,
            }]);
        }
        const apiResponse = await DataService.getIntegrity(integrityApiRequestPayload);
        if (apiResponse.data) {
            apiResponse.data?.forEach((item: IIntegrity) => {
                const bands = item.bands
                let goodDataPointes = 0
                let totalDataPoints = item.totalDataSetsCount
                let accuracy = 0

                bands?.forEach((band: IntegrityBand) => {
                    goodDataPointes += band?.totalGoodDataPointsCount || 0
                })

                if (totalDataPoints) {
                    accuracy = (goodDataPointes / totalDataPoints) * 100
                }

                //@ts-ignore
                let originalObj = apiResponse.data?.find(obj => obj.modelCollectionId === item._id)
                if (originalObj) {
                    originalObj.testAccuracy.value = accuracy
                }

                //@ts-ignore cache accuracy
                setTestDriveResultsTableHeaderValues(
                    prevState => ({
                        ...prevState,
                        accuracy:accuracy
                    }))
            })
        }
        setIsAccuracyInTestDriveResultsTableFetching(false)
    }
    const fetchIntegrtity = async ()=>{
        setIsIntegrityInTestDriveResultsTableFetching(true)
        const modelId = props?.queryParamsForNonDefaultScenarios?.model || ''
        const modelCollectionId = props?.queryParamsForNonDefaultScenarios?.modelCollectionIdsToCompare || "";
        const testDataSetCollectionId = getQueryParamValue("testDataSetCollectionId");
        const apiResponse = await DataService.getModelAnalyticsTestStatisticalData({
            modelId: modelId,
            getIntegrityFrequency: true,
            modelCollectionIds: JSON.stringify([modelCollectionId]),
            getIntegrityFrequencyAccuracyPercent: true,
            ...(testDataSetCollectionId ? {dataSetCollectionId: testDataSetCollectionId} : {}),
            modelCollectionsAdditionalData: !props?.queryParamsForNonDefaultScenarios?.isSegmentationModelAnalyticsEnabled ? JSON.stringify([])
                : JSON.stringify([{ modelCollectionId: modelCollectionId, iouThreshold: props?.queryParamsForNonDefaultScenarios?.iouThreshold , confidenceScoreThreshold : props?.queryParamsForNonDefaultScenarios?.confidenceScoreThreshold}]),
                isAnalyticsPageOpenedFromTestDrive:isTestDriveChatBotWindowScenario, requestorEmailId:userEmail,tryOutVmId: props?.testDriveVm?._id
        });
        if (apiResponse?.data) {
            setTestDriveResultsTableHeaderValues(
                prevState => ({
                    ...prevState,
                    integrity: apiResponse?.data?.[0]?.integrityFrequencyAccuracy?.value
                }))
        }
        setIsIntegrityInTestDriveResultsTableFetching(false)
    }
    const fetchClasses = async (selectedModel: string, modelId: string) => {

        if(!getQueryParamValues().purposeModelId){
        const urlSearchParams = new URLSearchParams(window.location.search);

        const testCollectionId = urlSearchParams.get("testCollectionId") || "";
        const testDataSetCollectionId = getQueryParamValue("testDataSetCollectionId") || "";

        const classesData = await DataService.getIndividualClassAccuracy({
            modelId: modelId,
            modelCollectionIds: selectedModel,
            testCollectionId: testCollectionId,
            testDataSetCollectionId
        });

        const labels: string[] = [];
        classesData.data.forEach((classData: { classes: {class: string}[]}) => {
            classData.classes.forEach((classValue: { class: string; }) => {
                if (!labels.includes(classValue.class)) {
                    labels.push(classValue.class)
                }
            })
        })

        setClassFilterOption([...labels])
        }

    }

    const fetchUniquePredictionValue = async (selectedModel: string, modelId: string) => {
        if(getQueryParamValues().purposeModelId) return;
        const predictionValuesData = await DataService.getUniquePredictionValues({
            modelId: modelId,
            modelCollectionId: selectedModel,
            isAnalyticsPageOpenedFromTestDrive: isTestDriveChatBotWindowScenario ? "true" : "",
            requestorEmailId: userEmail,
            tryOutVmId: props?.testDriveVm?._id
        });
        setUniquePredictedValues([...predictionValuesData.data.uniquePredictionsOfResources])
    }

    const fetchModelCollectionIdsWithAccuracy = async (modelCollectionIdsToCompare: any) => {
        try{
        if(!modelIdRef.current){
            return;
        }
        const testCollectionId = getQueryParamValue("testCollectionId") || ''
        let apiResponseForAccuracy
        if(getQueryParamValues().purposeModelId){
            // apiResponseForAccuracy = await DataService.getModelAnalyticsTestStatisticalData({
            //     purposeModelId: getQueryParamValues().purposeModelId,
            //     getIntegrityFrequencyAccuracyPercent: true
            // })
        }
        else{

        const QueryParams: IModelAnalyticsFetchTestStatisticalDataQueryParams = {
            modelId: modelId,
            modelCollectionIds: modelCollectionIdsToCompare,
            getIntegrityFrequency: true,
            getIntegrityFrequencyAccuracyPercent: true,
            getIntegrityFrequencyAccuracyPercentComparisonWithLiveCollection: true,
            getTestAccuracy: true,
            getTestAccuracyComparisonWithLiveCollection: true,
            getModelCollectionCreatedTime: true,
            testCollectionId: testCollectionId,
        }

        await populateTaskDetailsRef.current();

        if (taskDetailsRef.current?.isAnalyticsBasedOnWordErrorRateEnabled) {
            const modelCollectionAdditionalData = JSON.parse(modelCollectionIdsToCompare).map((modelCollectionId: string)=>{
                return {
                    modelCollectionId: modelCollectionId,
                    confidenceScoreThreshold: taskDetailsRef.current?.confidenceScoreThresholdForIntegrity,
                    wordErrorRateThreshold: taskDetailsRef.current?.wordErrorRateThreshold
                }
            })
            QueryParams.modelCollectionsAdditionalData = JSON.stringify(modelCollectionAdditionalData)

        }

        const testDataSetCollectionId = getQueryParamValue("testDataSetCollectionId");
        if(testDataSetCollectionId){
            QueryParams.dataSetCollectionId = testDataSetCollectionId;
        }

        apiResponseForAccuracy = await DataService.getModelAnalyticsTestStatisticalData(QueryParams)
    }
        const data = apiResponseForAccuracy?.data.reduce((max, model) => {
            if (model.testAccuracy?.value > max) {
                max = model.testAccuracy.value
            }
            return max;
        }, 0);
        const checkIfLiveDataAvailable = apiResponseForAccuracy?.data.find(model => model.status === 'Live');
        let updatedData = apiResponseForAccuracy?.data;
        if (!checkIfLiveDataAvailable) {
            let highestAccuracy = apiResponseForAccuracy?.data?.[0].testAccuracy?.value;
            apiResponseForAccuracy?.data.forEach(model => {
                highestAccuracy = model.testAccuracy?.value > ( highestAccuracy || 0) ? model.testAccuracy.value : highestAccuracy;
            })
            updatedData = apiResponseForAccuracy?.data.map(model => {
                return model.testAccuracy?.value !== highestAccuracy ? {...model, testAccuracy: {...model.testAccuracy, type: 'lessThanLive'}} : {...model, testAccuracy: {...model.testAccuracy, type: 'greaterThanLive'}}
            })
        }
        setModelCollectionOptions([...(updatedData || [])])
        }
        catch(error){
            console.log(error)
        }
    }

    /**
     * To get query param value
     * @returns Values for all query parameters
     */
    const getQueryParamValues = () => {
        // const query = new URLSearchParams(window.location?.search);
        const offset = getQueryParamValue("offset") || "1";
        const limit = getQueryParamValue("limit") || "5";
        const model = getQueryParamValue("model") || "";
        const scenario: IAnalyticsScenario = getQueryParamValue('scenario') as unknown as IAnalyticsScenario || 'classAccuracy';
        const selectedModelCollectionIdValue = getQueryParamValue('modelCollectionIdFilterSelected') || '';
        const modelCollectionOptionsValue = getQueryParamValue("modelCollectionIdsToCompare") || '';
        const modelCollectionIdOptions = JSON.stringify(modelCollectionOptionsValue?.split(","));
        const sortFieldQuery = getQueryParamValue('sortField') || '';
        const sortOrderQuery = getQueryParamValue('sortOrder') || '';
        const labelFilterQuery = getQueryParamValue('labelFilterValue') || '';
        const labelFilterValue = labelFilterQuery ? labelFilterQuery.split(',') : [];
        const predictedStatusFilter = getQueryParamValue('statusFilter') || '';
        const confidenceScoreMax = getQueryParamValue('confidenceScoreMax') || '';
        const confidenceScoreMin = getQueryParamValue('confidenceScoreMin') || '';
        const wordErrorRateMax = getQueryParamValue('wordErrorRateMax') || '';
        const wordErrorRateMin = getQueryParamValue('wordErrorRateMin') || '';
        const iouScoreMax = getQueryParamValue('iouScoreMax') || '';
        const iouScoreMin = getQueryParamValue('iouScoreMin') || '';
        const labelFilterOperator = getQueryParamValue('labelFilterOperator') || '';
        const classAccuracyValue = getQueryParamValue('sortByClassFilterSelected') || '';
        const testCollectionId = getQueryParamValue("testCollectionId") || "";
        const statusRegexQuery = getQueryParamValue('predictionValue') || "";
        const statusValue = statusRegexQuery ? statusRegexQuery.split(',') : [];
        const statusFilterOperator = getQueryParamValue('statusFilterOperator') || 'equals';
        const bandRangeValue = getQueryParamValue("bandFilterSelected") || '';
        const iouThreshold = getQueryParamValue("iouThreshold") || "";

        const  getSameHandFeedbackNotYetGiven = getQueryParamValue("getSameHandFeedbackNotYetGiven") || '';
        const  getSameHandWithMultipleIds = getQueryParamValue("getSameHandWithMultipleIds") || '';
        const  getSameHandWithNoMultipleIds = getQueryParamValue("getSameHandWithNoMultipleIds") || '';
        
        const purposeModelId = getQueryParamValue("purposeModelId") || '';
        const sortField = getQueryParamValue("sortField") || '';
        const sortOrder = getQueryParamValue("sortOrder") || '';

        // @ts-expect-error
        const problemType: IPurposeModel["problemType"] | "" = getQueryParamValue("problemType") || '' as IPurposeModel["problemType"];
      
        return { offset, limit, model, scenario, selectedModelCollectionIdValue, sortFieldQuery, sortOrderQuery, labelFilterValue, predictedStatusFilter, confidenceScoreMax, confidenceScoreMin, iouScoreMax,iouScoreMin, labelFilterOperator, classAccuracyValue, testCollectionId, statusValue, statusFilterOperator, bandRangeValue, modelCollectionIdOptions, modelCollectionOptionsValue, iouThreshold ,getSameHandFeedbackNotYetGiven,getSameHandWithMultipleIds,getSameHandWithNoMultipleIds, purposeModelId, sortField, sortOrder, problemType,
            wordErrorRateMax, wordErrorRateMin,
        }


    }

    /**
     * To get formatted query values to fetch response from API
     * @returns Object with all query params to make fetch request
     */
    const formatQueryValuesForAPI = (): IGetAnalyticsResourcesQueryParams => {

        
        const { offset, limit, model, selectedModelCollectionIdValue, sortFieldQuery, sortOrderQuery, predictedStatusFilter, testCollectionId, bandRangeValue, labelFilterValue, confidenceScoreMax, confidenceScoreMin, iouScoreMax,iouScoreMin, labelFilterOperator, classAccuracyValue, statusValue, statusFilterOperator, iouThreshold ,getSameHandFeedbackNotYetGiven,getSameHandWithMultipleIds, getSameHandWithNoMultipleIds,
            wordErrorRateMax, wordErrorRateMin,
         } = getQueryParamValues();

        console.log(getQueryParamValue('model'))

        let bandRangeValueQuery = bandRangeValue.replaceAll('%20', '');
        bandRangeValueQuery = bandRangeValue.replaceAll('%', '');
        
        let queryParam: IGetAnalyticsResourcesQueryParams = {
            offset: parseInt(offset),
            limit: parseInt(limit),
            modelId: model,
            modelCollectionId: selectedModelCollectionIdValue,
            sortField: sortFieldQuery,
            sortOrder: sortOrderQuery,
            predictionStatusFilter: predictedStatusFilter,
            testCollectionId: testCollectionId,
            iouThreshold: iouThreshold,
           
        }
       if(isTestDriveChatBotWindowScenario){
        queryParam={...queryParam,isAnalyticsPageOpenedFromTestDrive:isTestDriveChatBotWindowScenario, requestorEmailId:userEmail,tryOutVmId: props?.testDriveVm?._id}
       } 
       if(getSameHandFeedbackNotYetGiven)
       { 
        queryParam={...queryParam,getSameHandFeedbackNotYetGiven:getSameHandFeedbackNotYetGiven}
       }
       if(getSameHandWithMultipleIds)
       {
        queryParam={...queryParam,getSameHandWithMultipleIds:getSameHandWithMultipleIds}
       }
       if(getSameHandWithNoMultipleIds)
       {
        queryParam={...queryParam,getSameHandWithNoMultipleIds:getSameHandWithNoMultipleIds}
       }
        if (confidenceScoreMax) {
            queryParam = { ...queryParam, resourceConfidenceScoreMaxValue: confidenceScoreMax, resourceConfidenceScoreMinValue: confidenceScoreMin }
        } else if (bandRangeValueQuery.includes('100')) {
            queryParam = { ...queryParam, resourceConfidenceScoreMaxValue: '100', resourceConfidenceScoreMinValue: '100' }
        } else if (bandRangeValueQuery.includes('99.99')) {
            queryParam = { ...queryParam, resourceConfidenceScoreMaxValue: '99.99', resourceConfidenceScoreMinValue: '99' }
        } else {
            const [min, max] = bandRangeValueQuery !== 'ALL' ? bandRangeValueQuery.length ? bandRangeValueQuery.split(' - ') : ['0', '100'] : ['0', '100'];
            queryParam = { ...queryParam, resourceConfidenceScoreMaxValue: bandRangeValueQuery.length && bandRangeValueQuery !== 'ALL' ? (parseInt(max) - 0.001) + '' : '100', resourceConfidenceScoreMinValue: min || '' }
        }
        if (labelFilterValue.length) {
            queryParam = { ...queryParam, labelRegex: labelFilterValue.length && labelFilterOperator ? JSON.stringify([...convertLabelFilterToRegex(labelFilterOperator as unknown as ResourceLabelFilterOperator, labelFilterValue)]) : '' }
        } else {
            queryParam = { ...queryParam, labelRegex: classAccuracyValue && classAccuracyValue !== 'ALL' ? JSON.stringify([...convertLabelFilterToRegex(labelFilterOperator as unknown as ResourceLabelFilterOperator, [classAccuracyValue])]) : '' }
        }
        if (statusValue.length) {
            queryParam = { ...queryParam, predictionRegex: JSON.stringify([...convertLabelFilterToRegex(statusFilterOperator as unknown as ResourceLabelFilterOperator, statusValue)]) || '' }
        }
        if (wordErrorRateMax) {
            queryParam = { ...queryParam, wordErrorRateMax: wordErrorRateMax, wordErrorRateMin: wordErrorRateMin || '0' }
        }
       if(iouScoreMax)
       {
        
        queryParam = { ...queryParam, resourceIouScoreMaxValue: iouScoreMax, resourceIouScoreMinValue: iouScoreMin }
       }
    
        return queryParam;
    
    }
    
    const fetchOtherModelTranslatedLanguageTextPredictions = async (resourceIds: string[]) => {
        const modelCollectionIds: string[] = (()=>{
            return new URLSearchParams(window.location.search).get("modelCollectionIdsToCompare")?.split(",") || []
        })();

        const query = new URLSearchParams(window.location.search);

        const testCollectionId = query.get("testCollectionId") || "";

        const selectedModelCollectionIdValue = query.get('modelCollectionIdFilterSelected') || '';

        const modelCollectionIdsExcludingSelectedByUser = modelCollectionIds.filter(modelCollectionId=>{
            if (modelCollectionId !== selectedModelCollectionIdValue) {
                return true
            } else {
                return false
            }
        })

        const modelCollectionPredictedTextsPromises: Promise<unknown>[] = [];

        for (const modelCollectionId of modelCollectionIdsExcludingSelectedByUser) {
            const modelCollectionPredictedTextsPromise = new Promise(async (resolve, reject)=>{
                setOtherModelTranslatedLanguageTextPredictions([]);
                const apiResponse = await DataService.getResourceConfidenceScoresWithoutCancelMechanism({
                  modelCollectionId: modelCollectionId,
                  modelId: modelId,
                  resourceIds: resourceIds,
                  testCollectionId: testCollectionId  
                })
                if (apiResponse.data?.resources?.length>0) {
                    setOtherModelTranslatedLanguageTextPredictions(oldState=>{
                        return [
                            ...oldState,
                            ...apiResponse.data?.resources.map(resource=>{
                                return {
                                    modelCollectionId: modelCollectionId,
                                    modelVersion: resource.modelVersion,
                                    resourceId: resource._id,
                                    predicted: resource.predicted,
                                    confidenceScore: resource.confidenceScore 
                                }
                            }) 
                        ]
                    })
                }
                // @ts-ignore
                resolve();
            })
            modelCollectionPredictedTextsPromises.push(modelCollectionPredictedTextsPromise);
        }

        try {
            setIsFetchingOtherModelTranslatedlanguageTextpredictions(true);
            await Promise.all(modelCollectionPredictedTextsPromises);
        } catch (error) {}
        setIsFetchingOtherModelTranslatedlanguageTextpredictions(false);


    };

    const fetchOtherModelImageAnnotationPredictions = async (resourceIds: string[]) => {
        const modelCollectionIds: string[] = (()=>{
            return new URLSearchParams(window.location.search).get("modelCollectionIdsToCompare")?.split(",") || []
        })();

        const query = new URLSearchParams(window.location.search);

        const testCollectionId = query.get("testCollectionId") || "";

        const selectedModelCollectionIdValue = query.get('modelCollectionIdFilterSelected') || '';

        const modelCollectionIdsExcludingSelectedByUser = modelCollectionIds.filter(modelCollectionId=>{
            if (modelCollectionId !== selectedModelCollectionIdValue) {
                return true
            } else {
                return false
            }
        })

        const modelCollectionPredictedImageAnnotationsPromises: Promise<unknown>[] = [];

        for (const modelCollectionId of modelCollectionIdsExcludingSelectedByUser) {
            const modelCollectionPredictedImageAnnotationsPromise = new Promise(async (resolve, reject)=>{
                setOtherImageAnnotationPredictions([]);
                const apiResponse = await DataService.getResourceConfidenceScoresWithoutCancelMechanism({
                  modelCollectionId: modelCollectionId,
                  modelId: modelId,
                  resourceIds: resourceIds,
                  testCollectionId: testCollectionId  
                })
                if (apiResponse.data?.resources?.length>0) {
                    setOtherImageAnnotationPredictions(oldState=>{
                        return [
                            ...oldState,
                            ...apiResponse.data?.resources.map(resource=>{
                                return {
                                    modelCollectionId: modelCollectionId,
                                    modelVersion: resource.modelVersion,
                                    resourceId: resource._id,
                                    predicted: resource.predicted,
                                    confidenceScore: resource.confidenceScore,
                                    predictedAnnotations: resource.predictedAnnotations,

                                }
                            }) 
                        ]
                    })
                }
                // @ts-ignore
                resolve();
            })
            modelCollectionPredictedImageAnnotationsPromises.push(modelCollectionPredictedImageAnnotationsPromise);
        }

        try {
            setIsFetchingOtherImageAnnotationPredictions(true);
            await Promise.all(modelCollectionPredictedImageAnnotationsPromises);
        } catch (error) {}
        setIsFetchingOtherImageAnnotationPredictions(false);


    };

    const populateTaskDetails = async () => {
        return new Promise<void>(async (resolve, reject)=>{
            if (!taskDetailsRef.current) {
                if (!modelIdRef.current) {
                    if(isChatBotWindowScenario){
                        return resolve();
                    }
                    throw new Error("modelIdRef.current is null or undefined")
                }
                const fetchModelApiResponse = await ModelService.getModelDetails({
                    modelId: modelIdRef.current
                })
                setTaskDetails({...fetchModelApiResponse.data})
                taskDetailsRef.current = fetchModelApiResponse.data;
            }   
            resolve()     
        })
    }
    const populateTaskDetailsRef = useRef(populateTaskDetails);
    populateTaskDetailsRef.current = populateTaskDetails;

    const fetchAnalyticsModels = useCallback(async () => {
        
        const { model, limit, offset, purposeModelId, sortField, sortOrder, problemType, iouThreshold, bandRangeValue } = getQueryParamValues();
        
        if(purposeModelId){
            setIsFetchingModelResources(true);
            
            let query = formatQueryValuesForAPI()

            query.purposeModelId = purposeModelId

            delete query.modelCollectionId
            delete query.modelId
            delete query.iouThreshold
            delete query.testCollectionId

            if(problemType === "Segmentation" || problemType === "Detection" ){
                query.iouThreshold = iouThreshold
            }

            try{
                setRowsPerPage(parseInt(limit, 10));
                setPage(parseInt(offset, 10) - 1);

                const apiResponse = await DataService.getAnalyticsResourceData(query)

                if(apiResponse.data?.resourcesData){
                    const modifiedDataForPredictionValue: IResourceAnalyticsCollection[] = apiResponse.data.resourcesData.resources.map(resource => {
                        return resource.label === resource.prediction ? { ...resource, prediction: 'predicted' } : { ...resource }
                    })

                    //append more analytics details to each resource
                    
                    const promisesForAppendingModelInformation = modifiedDataForPredictionValue.map(async (resource) => {
                        const apiResponse = await DataService.getResourceFromDBById({
                            resourceId: resource._id
                        })

                        let analyticsPurposeModelData: IAnalyticsPurposeModelData = {}
                        
                        analyticsPurposeModelData.model = apiResponse.data?.model

                        if(apiResponse.data?.model){
                            const response = await ModelService.getModelDetails({
                                modelId: apiResponse.data.model._id,
                                modelSelectQuery: "isSegmentationModelAnalytics"
                            })

                            analyticsPurposeModelData.isSegmentationModelAnalyticsEnabled = response.data?.isSegmentationModelAnalytics || false
                        }

                        resource.analyticsPurposeModelData = analyticsPurposeModelData

                        return resource;
                    })

                    await Promise.all(promisesForAppendingModelInformation)
                    const averageInferenceTimeInSeconds = apiResponse.data.resourcesData?.averageInferenceTimeInSeconds || 0;
                    const tryOutVmHardwareConfig = apiResponse.data.resourcesData?.tryOutVmHardwareConfig
                    setTotalResourceCount(apiResponse.data.resourcesData.totalCount);
                    setTestDriveResultsTableHeaderValues(
                        prevState => ({
                            ...prevState,
                            averageInferenceTimeInSeconds,
                            tryOutVmHardwareConfig
                        }))
                    setModelResourcesData([...modifiedDataForPredictionValue])
                }

                setIsFetchingModelResources(false)
            }
            catch(error){
                setIsFetchingModelResources(false)
                console.log(error)
            }

        }

        if (model && !isFetchingModelResources && !getQueryParamValues().purposeModelId) {
            setIsFetchingModelResources(true);
            try {
                setRowsPerPage(parseInt(limit, 10));
                setPage(parseInt(offset, 10) - 1);
        
                let queryParam = formatQueryValuesForAPI();

                if((isChatBotWindowScenario && modelId) || !isChatBotWindowScenario){
                await populateTaskDetailsRef.current();
                }

                if (taskDetailsRef.current?.isAnalyticsBasedOnWordErrorRateEnabled) {
                    queryParam.wordErrorRateThreshold = String(taskDetailsRef.current?.wordErrorRateThreshold || 0)
                }

                const testDataSetCollectionId = getQueryParamValue("testDataSetCollectionId") || "";
                if(testDataSetCollectionId){
                    queryParam.testDataSetCollectionId = testDataSetCollectionId;
                }
                
                
                const fetchedData = await DataService.getAnalyticsResourceData(queryParam);
                const modifiedDataForPredictionValue: IResourceAnalyticsCollection[] = fetchedData.data.resourcesData.resources.map(resource => {
                    return resource.label === resource.prediction ? { ...resource, prediction: 'predicted' } : { ...resource }
                })
                const tryOutVmHardwareConfig = fetchedData.data.resourcesData?.tryOutVmHardwareConfig
                const averageInferenceTimeInSeconds = fetchedData.data.resourcesData?.averageInferenceTimeInSeconds || 0;
                setModelResourcesData([...modifiedDataForPredictionValue])
                setTestDriveResultsTableHeaderValues(
                    prevState => ({
                        ...prevState,
                        averageInferenceTimeInSeconds,
                        tryOutVmHardwareConfig
                    }))
                if (fetchedData?.data?.modelCollection?.modelType === "bookTranslation") {
                    fetchOtherModelTranslatedLanguageTextPredictions(
                        fetchedData.data?.resourcesData?.resources?.map(resource=>{
                            return resource._id
                        })
                    );
                } else if (fetchedData?.data?.modelCollection?.modelType === "imageAnnotation") {
                    fetchOtherModelImageAnnotationPredictions(
                        fetchedData.data?.resourcesData?.resources?.map(resource=>{
                            return resource._id
                        })                        
                    )
                }

                
                setTotalResourceCount(fetchedData.data.resourcesData.totalCount);
                setModelInformation(fetchedData.data.modelCollection);
                setIsFetchingModelResources(false)
            }
            catch (err) {
                setIsFetchingModelResources(false)
            }
        }

    }, [window.location, props.scenario])


    const handleTablePageChange = (event: unknown, newPage: number) => {
        if (!isFetchingModelResources) {
            let queryParamsJson = convertURLQueryParamsToJSON(urlQuery.entries());
            // if(isChatBotWindowScenario){
            //     queryParamsJson = {
            //         ...queryParamsJson,
            //         [scenarioSpecificQueryParamsKey]: {
            //             ...(JSON.parse(queryParamsJson[scenarioSpecificQueryParamsKey] || '') || {}),
            //             offset: newPage + 1,
            //         }
            //     }
            // }
            // else {
                queryParamsJson = {
                    ...queryParamsJson,
                    [getScenarioSpecificQParamKey('offset')]: newPage + 1,
                };
            // }
            const queryString = `?${queryObjectToString(queryParamsJson)}`;
            history.replace(`${location.pathname}${queryString}`);

            setIsFetchingModelResources(true);
            setModelResourcesData([]);
            // setTestDriveResultsTableHeaderValues({})
            fetchAnalyticsModels();
        }
    };

    const handleChangeRowsPerPage = (async (
        event: React.ChangeEvent<HTMLInputElement>
    ) => {
        if (!isFetchingModelResources) {
            let queryParamsJson = convertURLQueryParamsToJSON(urlQuery.entries());
            console.log(queryParamsJson);
            // if(isChatBotWindowScenario){
            //     queryParamsJson = {
            //         ...queryParamsJson,
            //         [scenarioSpecificQueryParamsKey]: {
            //             ...(parseQueryParamJson(queryParamsJson[scenarioSpecificQueryParamsKey] || '') || {}),
            //             limit: Number(event.target.value),
            //             offset: 1,
            //         }
            //     }
            //     const queryString = `?${queryObjectToString(queryParamsJson)}`;
            //     history.replace(`${location.pathname}${queryString}`);
            // }
            // else {
                queryParamsJson = {
                    ...queryParamsJson,
                    [getScenarioSpecificQParamKey('limit')]: Number(event.target.value),
                    [getScenarioSpecificQParamKey('offset')]:1,
                };
                const queryString = `?${queryObjectToString(queryParamsJson)}`;
                history.replace(`${location.pathname}${queryString}`);
            // }
            setIsFetchingModelResources(true);
            setModelResourcesData([])
            // setTestDriveResultsTableHeaderValues({})
            fetchAnalyticsModels();
        }
    })


     const handleTableFiltersChange = (filters: IAnalyticsTableFilterObject, classFilter?: string) => {
        if (
            tableFilters.sortField !== filters.sortField ||
            tableFilters.sortOrder !== filters.sortOrder ||
            tableFilters.resourceIouScoreMaxValue !== filters.resourceIouScoreMaxValue ||
             tableFilters.resourceIouScoreMinValue !== filters.resourceIouScoreMinValue ||
            tableFilters.predictionStatusFilter !== filters.predictionStatusFilter ||
            tableFilters.getSameHandFeedbackNotYetGiven !== filters.getSameHandFeedbackNotYetGiven ||
            tableFilters.getSameHandWithMultipleIds !== filters.getSameHandWithMultipleIds ||
            tableFilters.getSameHandWithNoMultipleIds !== filters.getSameHandWithNoMultipleIds ||
            tableFilters.resourceConfidenceScoreMaxValue !== filters.resourceConfidenceScoreMaxValue ||
            tableFilters.resourceConfidenceScoreMinValue !== filters.resourceConfidenceScoreMinValue || 
            tableFilters.resourceWordErrorRateMaxValue !== filters.resourceWordErrorRateMaxValue ||
            tableFilters.resourceWordErrorRateMinValue !== filters.resourceWordErrorRateMinValue ||
            tableFilters.labelFilterOperator !== filters.labelFilterOperator ||
            tableFilters.labelRegex.join(',') !== filters.labelRegex.join(',') ||
            tableFilters.statusFilterOperator !== filters.statusFilterOperator ||
            tableFilters.predictedStatusRegex.join(',') !== filters.predictedStatusRegex.join(',')
        ) {
            setTableFilters({ ...filters });

        }
        
        const query = new URLSearchParams(location?.search);
       
        const scenario: IAnalyticsScenario = getQueryParamValue('scenario') as unknown as IAnalyticsScenario || 'classAccuracy';
        if (scenario === 'integrity') {
            if (filters.resourceConfidenceScoreMaxValue && bandValue !== 'CUSTOM' && !isDefaultConfidenceScoreFilterValue) {
                if (!bandValue.includes(filters.resourceConfidenceScoreMaxValue) || !bandValue.includes(filters.resourceConfidenceScoreMinValue)) {
                    handleBandChange('CUSTOM', filters)
                }
            } else if (!filters.resourceConfidenceScoreMaxValue) {
                const bandValueQueryValue = query.get('bandFilterSelected') || '';
                handleBandChange(bandValueQueryValue, filters)
            }
        } else {
            const classValue = classFilter ? classFilter : classFilterValue;
            if (filters.labelRegex && filters.labelFilterOperator && classValue && classValue !== 'CUSTOM' && !isDefaultLabelFilter) {
                if (filters.labelRegex.length && (filters.labelRegex.length > 1 || filters.labelRegex[0].toUpperCase() !== classValue.toUpperCase() || filters.labelFilterOperator !== 'equals')) {
                    handleClassFilterChange('CUSTOM', filters)
                }
                //@ts-ignore
            } else if ((!filters.labelRegex || !filters.labelRegex.length || filters.labelRegex === []) && classValue !== 'ALL') {
                const classFilterQuery = query.get('sortByClassFilterSelected') || '';
                handleClassFilterChange(classFilterQuery, filters)
            }

        }
    }
    
    /**
     * Compare Table Filters with Toolbar filters
     */
    const confidenceScorefilterComparisonHandler = () => {
        if (bandValue !== 'CUSTOM') {
            if (bandValue.includes('100') && tableFilters.resourceConfidenceScoreMaxValue === '100' && tableFilters.resourceConfidenceScoreMinValue === '100') {
                setIsDefaultConfidenceScoreFilterValue(true)
            } else if (bandValue.includes('99.99') && tableFilters.resourceConfidenceScoreMaxValue === '100' && tableFilters.resourceConfidenceScoreMinValue === '99') {
                setIsDefaultConfidenceScoreFilterValue(true)
            } else if (tableFilters.resourceConfidenceScoreMaxValue && tableFilters.resourceConfidenceScoreMinValue && (bandValue.includes(tableFilters.resourceConfidenceScoreMaxValue) && bandValue.includes(tableFilters.resourceConfidenceScoreMinValue))) {
                setIsDefaultConfidenceScoreFilterValue(true)
            } else if (bandValue === 'ALL' && tableFilters.resourceConfidenceScoreMaxValue === '100' && tableFilters.resourceConfidenceScoreMinValue === '0') {
                setIsDefaultConfidenceScoreFilterValue(true)
            } else {
                setIsDefaultConfidenceScoreFilterValue(false)
                handleBandChange('CUSTOM')
            }
        } else {
            setIsDefaultConfidenceScoreFilterValue(false)
        }
    }

    const labelFilterComparisonHandler = () => {
        if (classFilterValue !== 'CUSTOM') {
            if (tableFilters.labelRegex.length === 1 && tableFilters.labelRegex[0].toUpperCase() === classFilterValue.toUpperCase() && tableFilters.labelFilterOperator === 'equals') {
                setIsDefaultLabelFilter(true)
            } else if (classFilterValue === 'ALL' && !tableFilters.labelRegex.length) {
                setIsDefaultLabelFilter(true)
            } else {
                setIsDefaultLabelFilter(false);
                handleClassFilterChange('CUSTOM')
            }
        } else {
            setIsDefaultLabelFilter(false);
        }
    }

    /**
     * Toolbar value change
     */
    
    const handleBandChange = (value: string, filters?: IAnalyticsTableFilterObject) => {
        if (value) {
            setBandValue(value)
        }
        if (value && value !== 'CUSTOM') {
            const filterValue = filters ? {...filters} : {...tableFilters}
            if (value.includes('100')) {
                handleTableFiltersChange({ ...filterValue, resourceConfidenceScoreMaxValue: '100', resourceConfidenceScoreMinValue: '100' })
            } else if (value.includes('99.99')) {
                handleTableFiltersChange({ ...filterValue, resourceConfidenceScoreMaxValue: '100', resourceConfidenceScoreMinValue: '99' })
            } else {
                const [min, max] = value === 'ALL' ? ['0', '100'] : value.replaceAll('%', '').split(' - ');
                handleTableFiltersChange({ ...filterValue, resourceConfidenceScoreMaxValue: max, resourceConfidenceScoreMinValue: min })
            }
        }
    }
    
    const handleModelCollectionChange = (value: string) => {
        setSelectedModelCollectionId(value)
    }
    
    const handleClassFilterChange = (value: string, filters?: IAnalyticsTableFilterObject) => {
        if (value) {
            setClassFilterValue(value);
        }
        if (value && value === 'ALL') {
            const filterValue = filters ? { ...filters } : { ...tableFilters }
            handleTableFiltersChange({...filterValue, labelRegex: []}, value)
        }
        else if (value && value !== 'CUSTOM' && tableFilters?.labelRegex?.join(',') !== filters?.labelRegex.join(',')) {
            const filterValue = filters ? { ...filters } : { ...tableFilters }
            handleTableFiltersChange({ ...filterValue, labelRegex: [classFilterOption.filter(option => option.toUpperCase() === value.toUpperCase())[0] || value.toLowerCase()], labelFilterOperator: 'equals'}, value)
        }
    }

    const handleModelCollectionChangeToCompareConfidenceScoreWith = useCallback(
      async (modelCollectionIdChangedByUser: string) => {

        const query = new URLSearchParams(window.location?.search);
        const modelId = query.get("model") || "";
        const testCollectionId = query.get("testCollectionId") || "";
        // setModelVersionToCompareConfidenceScoreWith(modelCollectionIdChangedByUser || "");
        for(const modelCollectionOption of modelCollectionOptions){
            if(modelCollectionOption.modelCollectionId === modelCollectionIdChangedByUser){
                setModelCollectiontoCompareConfidenceScoreWith(modelCollectionOption);
                break;
            }
        }

        if (!modelCollectionIdChangedByUser) {
            return;
        }

        let anyOldResourceContainModelCollectionToCompareConfidenceScore = false;
        for (const resource of modelResourcesData) {
            if (
                resource?.modelCollectionSpecificData && 
                resource?.modelCollectionSpecificData[modelCollectionIdChangedByUser || ""]?.confidenceScore
            ) {
                anyOldResourceContainModelCollectionToCompareConfidenceScore = true;
                break;
            }
        }
        if (anyOldResourceContainModelCollectionToCompareConfidenceScore) {
            return;
        }


        let shouldContinueFetching = false

        setIsFetchingConfidenceScoresForModelCollectionResources(true)
        const apiResponse = await DataService.getResourceConfidenceScores({
            modelId: modelId,
            modelCollectionId: modelCollectionIdChangedByUser || "",
            resourceIds: modelResourcesData.map(resource=>resource._id),
            testCollectionId: testCollectionId
        }).catch(e=>{shouldContinueFetching = true})
        if (apiResponse?.data?.resources) {
            setModelResourcesData((oldResources)=>{
                const updatedResources = [...oldResources];
                let isAnyResourceUpdated = false;

                for (const resource of updatedResources) {
                    let resourceConfidenceScoreFromDB = null;
                    for (let resourceIndex = 0; resourceIndex < apiResponse.data.resources.length; resourceIndex++) {
                        const resourceFromDB = apiResponse.data.resources[resourceIndex];
                        if (resourceFromDB._id.toString() === resource._id.toString()) {
                            resourceConfidenceScoreFromDB = resourceFromDB.confidenceScore;
                            apiResponse.data.resources.splice(resourceIndex, 1);
                            isAnyResourceUpdated = true;
                            break;
                        }
                    }
                    if (!resource.modelCollectionSpecificData) {
                        resource.modelCollectionSpecificData={};
                    }
                    if (!resource.modelCollectionSpecificData[modelCollectionIdChangedByUser || ""]) {
                        resource.modelCollectionSpecificData[modelCollectionIdChangedByUser || ""] = {
                            confidenceScore: resourceConfidenceScoreFromDB || ""
                        }
                    }
                }
                if (isAnyResourceUpdated) {
                    return [...updatedResources]
                } else {
                    return oldResources;
                }
            })
        }
        if (!shouldContinueFetching) {
            setIsFetchingConfidenceScoresForModelCollectionResources(false)        
        }
      },
      [modelResourcesData],
    )

    /**
     * View type change
     */
     const changeAnalyticsResourcesViewType = (event: React.MouseEvent<HTMLElement>, newView: AnalyticsResourceView) => {
        setAnalyticsResourcesViewType(newView);
    };

    useEffect(() => {
        const executeFunction = async () => {
            const apiResponse = await ModelService.getModelReferenceData({
                modelId: modelId
            })
            setModelReferenceGuides(apiResponse.data);
        }
  
        if (modelId && !getQueryParamValues().purposeModelId) {
            executeFunction();
        }
      
      }, [modelId])    

    useEffect(()=>{
        const executeFunction = async () => {
            if(getQueryParamValues().purposeModelId) return;
            setIsDataSetCollectionsBeingFetchedForFilter(true);
            const apiResponse = await DataService.getCollections({
                model: modelId,
                projectionQuery: JSON.stringify({ name: 1 }),
                limit: "-1",
                offset: "-1"
            })
            SetDataSetCollectionsAvailableForFilter(apiResponse.data.collections)
            setIsDataSetCollectionsBeingFetchedForFilter(false);
        }
        if (modelId) {
            executeFunction();
        }
    }, [modelId])

    useEffect(() => {
        const executeFunction = async () => {
            setIsFetchingProjectModelDetails(true)
            const apiResponse = await ModelService.getModelDetails({
                modelId: modelId
            })
            setProjectModelDetails(apiResponse.data);
            setIsFetchingProjectModelDetails(false)
        }
  
        if (modelId && !getQueryParamValues().purposeModelId) {
          executeFunction();
        }
      
      }, [modelId])    
    
    useEffect(() => {
        
        const urlQuery = new URLSearchParams(window.location?.search);
        
        if(isChatBotWindowScenario){
            
            let queryParamsJson = convertURLQueryParamsToJSON(urlQuery.entries());
            
                queryParamsJson = {
                    ...queryParamsJson,
                    [getScenarioSpecificQParamKey('labelFilterValue')]: tableFilters?.labelRegex?.toString()||"",
                    [getScenarioSpecificQParamKey('labelFilterOperator')]: tableFilters?.labelFilterOperator||"",
                    [getScenarioSpecificQParamKey('statusFilter')]: tableFilters?.predictionStatusFilter||"",
                    [getScenarioSpecificQParamKey('confidenceScoreMax')]: tableFilters?.resourceConfidenceScoreMaxValue||"100",
                    [getScenarioSpecificQParamKey('confidenceScoreMin')]: tableFilters?.resourceConfidenceScoreMinValue||"0",
                    [getScenarioSpecificQParamKey('iouScoreMax')]: tableFilters?.resourceIouScoreMaxValue||"",
                    [getScenarioSpecificQParamKey('iouScoreMin')]: tableFilters?.resourceIouScoreMinValue||"",
                    [getScenarioSpecificQParamKey('sortField')]: tableFilters?.sortField||"",
                    [getScenarioSpecificQParamKey('sortOrder')]: tableFilters?.sortOrder||"",
                };
                const queryString = `?${queryObjectToString(queryParamsJson)}`;
                history.replace(`${location.pathname}${queryString}`);
                
            fetchAnalyticsModels();
            return;
        }
        // const urlQuery = new URLSearchParams(location?.search);
        const scenario: IAnalyticsScenario = getQueryParamValue('scenario') as unknown as IAnalyticsScenario || 'classAccuracy';

        urlQuery.set('iouScoreMax', tableFilters.resourceIouScoreMaxValue);
        urlQuery.set('iouScoreMin', tableFilters.resourceIouScoreMinValue);

        if (scenario === 'integrity') {
            tableFilters.getSameHandFeedbackNotYetGiven?
            urlQuery.set('getSameHandFeedbackNotYetGiven',tableFilters.getSameHandFeedbackNotYetGiven || ''): urlQuery.delete('getSameHandFeedbackNotYetGiven')
            tableFilters.getSameHandWithMultipleIds?
            urlQuery.set('getSameHandWithMultipleIds',tableFilters.getSameHandWithMultipleIds || ''): urlQuery.delete('getSameHandWithMultipleIds')
            tableFilters.getSameHandWithNoMultipleIds?
            urlQuery.set('getSameHandWithNoMultipleIds',tableFilters.getSameHandWithNoMultipleIds || ''): urlQuery.delete('getSameHandWithNoMultipleIds')
        
           

            urlQuery.set('labelFilterValue', tableFilters.labelRegex?.toString() || '');
            if (bandValue) {
                confidenceScorefilterComparisonHandler();
                
                if (bandValue === 'CUSTOM') {
                    urlQuery.set('confidenceScoreMax', tableFilters.resourceConfidenceScoreMaxValue);
                    urlQuery.set('confidenceScoreMin', tableFilters.resourceConfidenceScoreMinValue);
                    confidenceScorefilterComparisonHandler();
                } else if (bandValue !== 'CUSTOM') {
                    urlQuery.set("bandFilterSelected", bandValue);
                    urlQuery.set('confidenceScoreMax', '');
                    urlQuery.set('confidenceScoreMin', '');
                }
            }
            
        } else {
            urlQuery.set('confidenceScoreMax', tableFilters.resourceConfidenceScoreMaxValue);
            urlQuery.set('confidenceScoreMin', tableFilters.resourceConfidenceScoreMinValue);
           
            if (classFilterValue) {
                labelFilterComparisonHandler();
                if (classFilterValue === 'CUSTOM') {
                    urlQuery.set('labelFilterValue', tableFilters.labelRegex?.toString() || '');
                } else if (classFilterValue === 'ALL' || classFilterValue === 'all') { 
                    urlQuery.set('sortByClassFilterSelected', classFilterValue)
                    urlQuery.set('labelFilterValue', '');
                } else {
                    urlQuery.set('sortByClassFilterSelected', classFilterOption.filter(option => option.toUpperCase() === classFilterValue.toUpperCase())[0] || classFilterValue.toLowerCase())
                    urlQuery.set('labelFilterValue', '');
                }
            }
        }
        
        // urlQuery.set("bandFilterSelected", bandValue);
        urlQuery.set("modelCollectionidFilterSelected", selectedModelCollectionId?.toString() || '');
        urlQuery.set('sortField', tableFilters.sortField || '');
        urlQuery.set('sortOrder', tableFilters.sortOrder || '');
        urlQuery.set('predictionValue', tableFilters.predictedStatusRegex?.toString() || '')
        urlQuery.set('wordErrorRateMax', tableFilters.resourceWordErrorRateMaxValue || '');
        urlQuery.set('wordErrorRateMin', tableFilters.resourceWordErrorRateMinValue || '');
        // urlQuery.set('labelFilterValue', tableFilters.labelRegex?.toString() || '');
        if ('labelFilterOperator' in tableFilters) {
            urlQuery.set('labelFilterOperator', tableFilters.labelFilterOperator);
        }
        if ('statusFilterOperator' in tableFilters) {
            urlQuery.set('statusFilterOperator', tableFilters.statusFilterOperator)
        }
        if ('predictionStatusFilter' in tableFilters) {
            urlQuery.set('statusFilter', tableFilters.predictionStatusFilter || '');
        }
        // urlQuery.set('confidenceScoreMax', tableFilters.resourceConfidenceScoreMaxValue);
        // urlQuery.set('confidenceScoreMin', tableFilters.resourceConfidenceScoreMinValue);
        location.search = urlQuery.toString();
        history.replace(`${location?.pathname}?${urlQuery.toString()}`);
        if (selectedModelCollectionId) {
            fetchAnalyticsModels();
        }

    }, [bandValue, selectedModelCollectionId, classFilterValue, tableFilters])
    
    /**
     * @description
     * This effect is used to change selected modelVersionToCompareConfidenceScoreWith if both
     * selectedModelVersion and modelVersionToCompareConfidenceScoreWith are same
     */
    useEffect(()=>{
        if (selectedModelCollectionId === modelCollectionToCompareConfidenceScoreWith?.modelCollectionId) {
            handleModelCollectionChangeToCompareConfidenceScoreWith(selectedModelCollectionId)
        }
    }, [handleModelCollectionChangeToCompareConfidenceScoreWith, modelCollectionToCompareConfidenceScoreWith, selectedModelCollectionId])

    /**
     * @description
     * This effect checks if modelVersionToCompareConfidenceScoreWith is selected by user and all resources showing in table do not have modelVersionToCompareConfidenceScoreWith confidence score value, then to automatically fetch it
     * from backend and set it for each resource
     */
    useEffect(()=>{
        if (
            !modelCollectionToCompareConfidenceScoreWith ||
            !modelResourcesData ||
            modelResourcesData?.length===0
        ) {
            return;
        }

        let anyOldResourceContainModelCollectionToCompareConfidenceScore = false;
        for (const resource of modelResourcesData) {
            if (
                resource?.modelCollectionSpecificData && 
                resource?.modelCollectionSpecificData[modelCollectionToCompareConfidenceScoreWith?.modelCollectionId || ""]?.confidenceScore
            ) {
                anyOldResourceContainModelCollectionToCompareConfidenceScore = true;
                break;
            }
        }
        if (!anyOldResourceContainModelCollectionToCompareConfidenceScore && modelCollectionToCompareConfidenceScoreWith?.modelCollectionId) {
            handleModelCollectionChangeToCompareConfidenceScoreWith(modelCollectionToCompareConfidenceScoreWith?.modelCollectionId)
        }
    }, [handleModelCollectionChangeToCompareConfidenceScoreWith, modelResourcesData,modelCollectionToCompareConfidenceScoreWith])

    const addToCollectionsPayload: AddResourcesFromAnalyticsToCollectionQueryParamsReqPayload | any = () => {

        const queryParam = formatQueryValuesForAPI();

        if (queryParam?.offset) {
            // @ts-ignore
            delete queryParam?.offset;
        }

        const addToCollectionPayload = {
            ...queryParam,
            limit: 50000
        }

        if (queryParam.labelRegex) {
            addToCollectionPayload.labelRegex = JSON.parse(queryParam.labelRegex)
        }

        if (queryParam.predictionRegex) {
            addToCollectionPayload.predictionRegex = JSON.parse(queryParam.predictionRegex)
        }

        return addToCollectionPayload;
    }

    const shouldRenderAnalyticsEnhancedToolbar = !isChatBotWindowScenario

    return (
        <Paper className={`${classes.paper} ${isChatBotWindowScenario? classes.tableContainerStyleInChatBotWindowScenario : ''} ${isTestDriveChatBotWindowScenario && !isFetchingModelResources?classes.tableContainerStyleInChatBotTestDrive:''}`} variant="outlined">

            {
                isFetchingModelResources
                ? <div 
                    style={{
                        display: "flex",
                        width: "100%",
                        height: "100%",
                        justifyContent: "center",
                        marginTop: "60px",
                    }}
                >
                    <CircularProgress color="primary" />
                </div>
                :
                    <>
                        {
                            shouldRenderAnalyticsEnhancedToolbar
                            &&
                        <AnalyticsTableEnhancedToolbar
                            tableFilters={tableFilters}
                            modelCollectionToCompareOptions={modelCollectionOptions}
                            bandFiltersOptions={bandOptions}
                            isWordErrorRateModelAnalyticsEnabled={taskDetails?.isAnalyticsBasedOnWordErrorRateEnabled ? true : false}
                            selectedBandFilter={bandValue}
                            isSegmentationModelAnalyticsEnabled={isSegmentationModelAnalyticsEnabled}
                            noOfResourcesSelectedCount={
                                !areMoreTableResourcesSelectedByUserWhichAreNotEvenDisplayingToUser
                                ? tableResourceIdsSelected?.length || 0
                                : totalResourceCount < 50000
                                    ? totalResourceCount
                                    : 50000
                            }
                            isDataLoading={isFetchingModelResources}
                            selectedModelCollectionIdFilter={selectedModelCollectionId}
                            modelType={modelInformation?.modelType || 'audio'}
                            classFilterOptions={classFilterOption}
                            selectedClassFilter={classFilterValue}
                            currentAnalyticsResourcesViewType= {analyticsResourcesViewType}
                            changeAnalyticsResourcesViewType={changeAnalyticsResourcesViewType}
                            actions={{
                                bandValueChangeHandler: handleBandChange,
                                selectedModelCollectionChangeHandler: handleModelCollectionChange,
                                classFilterChangeHandler: handleClassFilterChange,
                                handleTableFiltersChange: handleTableFiltersChange
                            }}
                            modelCollectionToCompareConfidenceScoreWith={modelCollectionToCompareConfidenceScoreWith}
                            handleModelCollectionChangeToCompareConfidenceScoreWith={handleModelCollectionChangeToCompareConfidenceScoreWith}
                            tableResourceIdsSelected={tableResourceIdsSelected}
                            AddResourceToDataSetCollectionButton={
                                resourcesWhoseDataSetCollectionsAreBeingUpdatedInDB?.length===0 ||
                                !resourcesWhoseDataSetCollectionsAreBeingUpdatedInDB
                                ?
                                <Tooltip 
                                    title={
                                        tableResourceIdsSelected?.length === 1
                                        ? tooltipTextForEditResourceDataSetCollectionButton
                                        : tooltipTextForAddResourceDataSetCollectionButton
                                    }
                                >
                                    <span>
                                        <AddResourceToDataSetCollectionButton
                                            onClick={() => {
                                                setResourceIdsSelectedForAddingToDataSetCollection(
                                                    [...tableResourceIdsSelected]
                                                )
                                                setIsAddResourcesToDataSetCollectionPopupShowing(true)
                                            } }
                                        /> 
                                    </span>                                                
                                </Tooltip>      
                                : <CircularProgress color="primary" size={25} style={{marginRight: "12px"}} /> 
                            }
                            iouThreshold={parseFloat(getQueryParamValue('iouThreshold') || IOU_THRESHOLD_DEFAULT_VALUE_FOR_SEGMENTATION_ANALYTICS.toString())}
                        />
                        }
                        <AnalyticsTable
                            shouldModalOpenedAfterFetchingData={shouldModalOpenedAfterFetchingData}
                            setShouldModalOpenedAfterFetchingData={setShouldModalOpenedAfterFetchingData}
                            modelCollectionsToCompareWith={modelCollectionOptions}
                            modelInformation={modelInformation}
                            isSpectrogramEnabled={isSpectrogramEnabled}
                            isSegmentationModelAnalyticsEnabled={isSegmentationModelAnalyticsEnabled}
                            classFilterOptions={classFilterOption}
                            uniquePredictedValues={uniquePredictedValues}
                            selectedModelCollectionId={selectedModelCollectionId}
                            isDataFetching={isFetchingModelResources}
                            tableFilters={tableFilters}
                            bandFilterValue={bandValue}
                            classFilterValue={classFilterValue}
                            isDefaultLabelFilter={isDefaultLabelFilter}
                            isDefaultConfidenceScoreFilterValue={isDefaultConfidenceScoreFilterValue}
                          
                          
                            tableData={modelResourcesData}
                            setTableData={setModelResourcesData}
                            testDriveTableHearderValues={TestDriveResultsTableHeaderValues}
                            isIntegrityInTestDriveResultsTableFetching = {isIntegrityInTestDriveResultsTableFetching}
                            isAccuracyInTestDriveResultsTableFetching = {isAccuracyInTestDriveResultsTableFetching}
                            modelReferenceGuides={modelReferenceGuides}
                            isFetchingOtherModelTranslatedlanguageTextpredictions={isFetchingOtherModelTranslatedlanguageTextpredictions}
                            otherModelTranslatedLanguageTextPredictions={otherModelTranslatedLanguageTextPredictions}
                            isFetchingOtherImageAnnotationPredictions={isFetchingOtherImageAnnotationPredictions}
                            OtherImageAnnotationPredictions={OtherImageAnnotationPredictions}
                            actions={{
                                tablePageChangeHandler: handleTablePageChange,
                                tableRowsPerPageChangeHandler: handleChangeRowsPerPage,
                                handleTableFiltersChange: handleTableFiltersChange
                            }}
                            pagination={{
                                currentPage: page,
                                rowsPerPage: rowsPerPage,
                                totalCount: totalResourceCount
                            }}
                            currentAnalyticsResourcesViewType={analyticsResourcesViewType}
                            modelCollectionIdToCompareConfidenceScoreWith={modelCollectionToCompareConfidenceScoreWith?.modelCollectionId}
                            isFetchingConfidenceScoresForModelCollectionResources={isFetchingConfidenceScoresForModelCollectionResources}
                            resourceIdsSelectedForAddingToDataSetCollection={resourceIdsSelectedForAddingToDataSetCollection}
                            isAddResourcesToDataSetCollectionPopupShowing={isAddResourcesToDataSetCollectionPopupShowing}
                            tableResourceIdsSelected={tableResourceIdsSelected} 
                            setResourceIdsSelectedForAddingToDataSetCollection={setResourceIdsSelectedForAddingToDataSetCollection} 
                            setIsAddResourcesToDataSetCollectionPopupShowing={setIsAddResourcesToDataSetCollectionPopupShowing}
                            resourcesWhoseDataSetCollectionsAreBeingUpdatedInDB={resourcesWhoseDataSetCollectionsAreBeingUpdatedInDB}     
                            setTableResourceIdsSelected={setTableResourceIdsSelected}    
                            areMoreTableResourcesSelectedByUserWhichAreNotEvenDisplayingToUser={areMoreTableResourcesSelectedByUserWhichAreNotEvenDisplayingToUser}
                            setAreMoreTableResourcesSelectedByUserWhichAreNotEvenDisplayingToUser={setAreMoreTableResourcesSelectedByUserWhichAreNotEvenDisplayingToUser}    
                            purposeModelProblemType={purposeModelProblemType}
                            scenario={props.scenario}
                        />
                    </>
            }

                {
                    isAddResourcesToDataSetCollectionPopupShowing &&
                    <AddOrDeleteResourcesToDataSetCollectionSelectionPopup
                    modelId={modelId}
                    open={isAddResourcesToDataSetCollectionPopupShowing}
                    onClose={() => {
                        setIsAddResourcesToDataSetCollectionPopupShowing(false);
                        setResourceIdsSelectedForAddingToDataSetCollection([]);
                    } }
                    resourceIdsSelected={resourceIdsSelectedForAddingToDataSetCollection}
                    totalResourceCount={totalResourceCount}
                    scenario={resourceIdsSelectedForAddingToDataSetCollection.length === 1
                        ? 'editing'
                        : 'adding'}
                    dataSetCollectionsAvailable={dataSetCollectionsAvailableForFilter}
                    isFetchDataSetCollectionApiExecuting={isDataSetCollectionsBeingFetchedForFilter}
                    areAllRowsMatchingTableFilterSelectedByUserAndHasRowsNotShowingInUI={areMoreTableResourcesSelectedByUserWhichAreNotEvenDisplayingToUser}
                    dataSetPayload={
                        areMoreTableResourcesSelectedByUserWhichAreNotEvenDisplayingToUser 
                        ? addToCollectionsPayload() 
                        : undefined
                    }
                    resources={modelResourcesData as unknown as IData[]} 
                    addResourcesToDataSetCollection={async (payload) => {
                        setResourcesWhoseDataSetCollectionsAreBeingUpdatedInDB(oldState=>([...oldState, ...payload.resourceIds || []]))
                        
                            payload.resourceIds
                            ?
                                await DataService.addResourceToDataSetCollection(
                                    {
                                        resourceIds: payload.resourceIds,
                                        dataSetCollectionIds: payload.dataSetCollections.map(
                                        (dataSetCollection) => dataSetCollection._id
                                        )
                                    }
                                )
                            : await DataService.addResourcesFromAnalyticsFileToDataSetCollection(
                                {
                                    dataSetCollectionIds: payload.dataSetCollections.map(
                                    (dataSetCollection) => dataSetCollection._id
                                    ),
                                    addToDataSetCollectionQueryParams:
                                    payload.addToDataSetCollectionQueryParams as unknown as AddResourcesFromAnalyticsToCollectionQueryParamsReqPayload,
                                }
                            )

                        const updatedResourcesWhichAreBeingAddedToDataSetCollectionInDB = removeResourceIdsWhichAreAddedToDataSetCollectionInDB(payload.resourceIds || [], resourcesWhoseDataSetCollectionsAreBeingUpdatedInDB, modelResourcesData);
                        let updatedResources = modelResourcesData;  
                        for (const resource of updatedResources) {
                            if (payload.resourceIds) {
                                for (const resourceIdBeingUpdated of payload.resourceIds) {
                                    if (resource._id.toString() === resourceIdBeingUpdated.toString()) {
                                        if (!resource.dataSetCollections) {
                                            resource.dataSetCollections = [];
                                        }
                                        for (const dataSetCollection of payload.dataSetCollections) {
                                            if (!resourceContainsDataSetCollection(resource, dataSetCollection)) {
                                                resource.dataSetCollections.push({
                                                    _id: dataSetCollection._id,
                                                    name: dataSetCollection.name
                                                })
                                            }
                                        }
                                    }
                                }
                            } else {
                                for (const dataSetCollection of payload.dataSetCollections) {
                                    if (!resourceContainsDataSetCollection(resource, dataSetCollection)) {
                                    if (!resource.dataSetCollections) {
                                        resource.dataSetCollections = [];
                                    }
                                    resource.dataSetCollections.push({
                                      _id: dataSetCollection._id,
                                      name: dataSetCollection.name,
                                    });
                                  }
                                }
                            }
                        }  
                        
                        setResourcesWhoseDataSetCollectionsAreBeingUpdatedInDB([...updatedResourcesWhichAreBeingAddedToDataSetCollectionInDB]);
                        setModelResourcesData([...updatedResources])
                    }} 
                    setDataSetCollectionsInResource={async (payload)=>{

                        const resourceIds = [payload.resourceId];

                        setResourcesWhoseDataSetCollectionsAreBeingUpdatedInDB(
                            (oldState) => ([...oldState, ...resourceIds])
                        )

                        await DataService.updateResourceDataSetCollections({
                            dataSetCollectionIds: payload.dataSetCollections.map(collection=>collection._id),
                            resourceId: payload.resourceId
                        });

                        const resourcesToUpdate = modelResourcesData;
                        const updatedResourcesWhichAreBeingAddedToDataSetCollectionInDB = removeResourceIdsWhichAreAddedToDataSetCollectionInDB([payload.resourceId], resourcesWhoseDataSetCollectionsAreBeingUpdatedInDB);

                        for (let resourceIndex = 0; resourceIndex < resourcesToUpdate.length; resourceIndex++) {
                            const resource = resourcesToUpdate[resourceIndex];
                            if (resource._id.toString() === payload.resourceId.toString()) {
                                resource.dataSetCollections = payload.dataSetCollections;
                                resourcesToUpdate[resourceIndex] = resource;
                                break;
                            }
                        }
                        
                        setResourcesWhoseDataSetCollectionsAreBeingUpdatedInDB(
                            [...updatedResourcesWhichAreBeingAddedToDataSetCollectionInDB]
                        );

                        setModelResourcesData([...resourcesToUpdate]);
                    }} 
                    addDataSetCollectionInDataSetCollectionsFilter={(payload) => {
                        let doesCollectionAlreadyExist: boolean = false;
                        for (const collection of dataSetCollectionsAvailableForFilter) {
                            if (collection._id.toString() === payload.dataSetCollectionToAdd._id.toString()) {
                                doesCollectionAlreadyExist = true;
                                break;  
                            }
                        }
                        const dataSetCollections = dataSetCollectionsAvailableForFilter;
                        if (!doesCollectionAlreadyExist) {
                            dataSetCollections.push(payload.dataSetCollectionToAdd)
                        }            
                        SetDataSetCollectionsAvailableForFilter([...dataSetCollections])            
                    }} 
                    updateResourceWhichAreBeingUpdatedInDB={async (resourceIds)=>{
                        setResourcesWhoseDataSetCollectionsAreBeingUpdatedInDB([...resourceIds])
                    }}                   
                />
                }                
        </Paper>
    )
}

function convertLabelFilterToRegex(searchOperator: ResourceLabelFilterOperator, searchValue: string[]): string[] {
    if (searchOperator === 'contains') {
        return searchValue.map(value => convertStringtoContainsExpressionInRegex(value));
    } else if (searchOperator === 'equals') {
        return searchValue.map(value => convertStringtoEqualsExpressionInRegex(value));
    } else if (searchOperator === 'starts with') {
        return searchValue.map(value => convertStringtoStartsWithExpressionInRegex(value));
    } else if (searchOperator === 'ends with') {
        return searchValue.map(value => convertStringtoEndsWithExpressionInRegex(value))
    } else {
        return searchValue;
    }
}

export default ModelAnalyticsResources;

import { useEffect, useState } from "react";
import { DataService } from "../../../../../../../../../../../services";
import { Cache } from "../../../../../../../../../../../classes/Cache";

const resourcesMetadataFileDownloadUrlsCache = new Cache();

export const useResourceMetadataDownloadUrlFromResourceId = (
  resourceId: string
) => {
  const [
    isFetchingResourceMetadataFileUrl,
    setIsFetchingResourceMetadataFileUrl,
  ] = useState(false);
  const [resourceMetadataFileDownloadUrl, setResourceMetadataFileDownloadUrl] =
    useState("");

  useEffect(() => {
    const executeMainLogic = async () => {
      setIsFetchingResourceMetadataFileUrl(true);
      setResourceMetadataFileDownloadUrl("");

      const resourceMetadataFileDownloadUrlInCache =
        resourcesMetadataFileDownloadUrlsCache.get(resourceId);

      if (resourceMetadataFileDownloadUrlInCache) {
        setResourceMetadataFileDownloadUrl(
          resourceMetadataFileDownloadUrlInCache
        );
      } else {
        const apiResponse =
          await DataService.getResourceMetadataFileDownloadUrl({
            resourceId: resourceId,
          });

        setResourceMetadataFileDownloadUrl(
          apiResponse.data.metadataFileDownloadUrl
        );
        resourcesMetadataFileDownloadUrlsCache.set(
          resourceId,
          apiResponse.data.metadataFileDownloadUrl,
          3600 * 1000 /** 1 hour */
        );
      }

      setIsFetchingResourceMetadataFileUrl(false);
    };
    if (!resourceId) {
      setIsFetchingResourceMetadataFileUrl(false);
      setResourceMetadataFileDownloadUrl("");
      return;
    }
    executeMainLogic();
  }, [resourceId]);

  return {
    isFetchingResourceMetadataFileUrl,
    resourceMetadataFileDownloadUrl,
  };
};

import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { I18nextProvider } from 'react-i18next';
import { normalize, denormalize, schema } from 'normalizr';
import css from "./twoDimensionalImage.module.css"
import {
	Button,
	ButtonGroup,
} from 'reactstrap';
// import 'bootstrap/dist/css/bootstrap.css';
import '../../../../../../../../assets/css/twoDimensionalImageBoostrap.css';
import './twoDimensionalImage.scss';
import { MdAdd, MdAutorenew } from 'react-icons/md';
import { FaCommentAlt } from 'react-icons/fa';
import { UndoRedo } from '../../../../models/UndoRedo';
import { highContrastingColors as colors } from '../../../../shared/utils/colorUtils.js';
import { getRandomInt } from '../../../../shared/utils/mathUtils.js';
import { Polygon } from '../../models/polygon';
import { Vertex } from '../../models/vertex';
import { getUniqueKey } from '../../utils/utils';
import MagnifierDropdown from '../MagnifierDropdown/MagnifierDropdown.jsx';
import TwoDimensionalImageContext from './twoDimensionalImageContext';
import AnnotationList from '../AnnotationList/AnnotationList.jsx';
import UndoRedoButton from '../UndoRedoButton/UndoRedoButton.jsx';
import { Button as MuiButton } from '@material-ui/core';
import Canvas from '../Canvas/Canvas.jsx';
import i18nextInstance from './i18n';
import { themePrimaryMainColor } from '../../../../../../../../theme';
import InfoIcon from '@material-ui/icons/Info';
import { generateUniqueId } from '../../../../../../../../services/idHelperService';
import { removeDuplicateElementsFromArrayByElementId } from '../../../../../../../../services/arrayHelperService';
import { setImageAnnotationsInAnnotationComponentForSavingInDBIfModelTypeImageAnnotation, setImageGroupAnnotationsInAnnotationComponentForSavingInDBIfModelTypeImageGroupAnnotation } from '../../../../../../../../services/imageAnnotationHelperService';
import { copyByValue, isNullOrUndefined } from '../../../../../../../../services/variableHelperService';
import { Menu, MenuItem, Tooltip } from '@material-ui/core';
import { Alert } from '@material-ui/lab';
import FullscreenIcon from '@material-ui/icons/Fullscreen';
import FullscreenExitIcon from '@material-ui/icons/FullscreenExit';
import ReferenceGuide from '../../../../../../../../features/admin/features/projects/features/ReferenceGuide'
// import { prototype } from 'events';
import ArrowRightIcon from '@material-ui/icons/ArrowRight';
import ArrowLeftIcon from '@material-ui/icons/ArrowLeft';


const SHORTCUTS = {
	MAGNIFIER: {
		'1X': { key: '1', code: 49 },
		'2X': { key: '2', code: 50 },
		'3X': { key: '3', code: 51 },
		'4X': { key: '4', code: 52 },
	},
	BUTTON: {
		ADD: { key: 'c', code: 67 },
		BOUNDING_BOX: {key: 'b', code: 66},
		SAVE: { key: 's', code: 83 },
		NEXT: { key: 'd', code: 68 },
		SKIP: { key: 'a', code: 65 },
		RIGHTARROW: { key: 'rightArrow', code: 39 },
		LEFTARROW: { key: 'leftArrow', code: 37 },
		TOGGLE_LABEL: { key: 'shift', code: 16 },
	},
	UNDO_REDO: {
		UNDO: { key: 'z', code: 90 },
		REDO: { key: 'x', code: 88 },
	},
};

const CanvasContextMenu = ({
	open = false,
	handleRemovePointClick,
	handleModifyAnnotationFromThisPointClick,
	handleViewAnnotation,
	xCoordinate,
	yCoordinate,
	handleOnClose
}) => {
	return (
		<Menu
			open={open}
			onClose={() => { handleOnClose() }}
			anchorReference="anchorPosition"
			anchorPosition={
				yCoordinate !== null && xCoordinate !== null
					? { top: yCoordinate, left: xCoordinate }
					: undefined
			}
		>
			<MenuItem onClick={() => handleViewAnnotation()}>View this annotation</MenuItem>
			<MenuItem onClick={() => handleRemovePointClick()}>Remove point</MenuItem>
			<MenuItem onClick={() => handleModifyAnnotationFromThisPointClick()}>Modify Annotation from this point</MenuItem>
		</Menu>
	);
}

class TwoDimensionalImage extends Component {
	constructor(props) {
		super(props);
		const {
			defaultAnnotations,
			defaultAnnotationGroups,
			isLabelOn,
			imageWidth,
			allowUserToSetAnyLabelForImageAnnotation,
			fullScreenMode,
			referenceGroups
		} = props;
		
		const entities = { options: {}, annotations: {}, annotationGroups: [] };
		let rootOptionId = '';
		let annotations = [];
		// const imageWidth = fullScreenMode ? window.innerWidth - 100 : imageWidthProp
		// normalize
		if (props.options && Object.keys(props.options).length !== 0) {
			const option = new schema.Entity('options');
			const children = new schema.Array(option);
			option.define({ children });
			const normalizedOptions = normalize(props.options, option);
			entities.options = normalizedOptions.entities.options;
			rootOptionId = normalizedOptions.result;
		} else {
			rootOptionId = '0';
			entities.options['0'] = { id: '0', value: 'root', children: [] };
		}

		if (defaultAnnotations && defaultAnnotations.length !== 0) {
			const annotation = new schema.Entity('annotations');
			const normalizedAnn = normalize(defaultAnnotations, [annotation]);
			entities.annotations = normalizedAnn.entities.annotations;
			annotations = normalizedAnn.result;
		}

		if (defaultAnnotationGroups && defaultAnnotationGroups.length !== 0) {
			entities.annotationGroups = defaultAnnotationGroups;
		}

		this.state = {
			active: "1",
			displayLabel: true,
			displayReference: false,
			isAdding: false,
			isAddingRectangle: false,
			focusedName: '',
			focusedGroupAnnotationId: "",
			magnifyingPower: 1,
			isLabelOn,
			entities,
			customizedOptionInputFocused: false,
			rootOptionId,
			imageScaleFactor: 1,
			imageHeight: 0,
			imageWidth,
			allowUserToSetAnyLabelForImageAnnotation,
			fullScreenMode,
			annotations,
			isVertexBeingRemoved: false,
			canvasContextMenu: {
				isOpen: false,
				xCoordinate: 0,
				yCoordinate: 0,
				event: null
			},
			stageScale: 1,
			naturalWidth: 0,
			naturalHeight: 0,
			mouseAnnotationCoordinates: {
				xAxis: 0,
				yAxis: 0
			}
		};
		this.UndoRedoState = new UndoRedo();
	}

	componentDidMount = () => {
		document.addEventListener('keydown', this.handleKeydown, false);
	}

	triggerAnnotationsChangedByUserEvent() {
		this.handleSubmit("onAnnotationsChange")
	}

	componentDidUpdate(prevProps, prevState) {
		// Object.entries(this.props).forEach(([key, val]) =>
		// 	prevProps[key] !== val && console.log(`Prop '${key}' changed`)
		// );
		// if (this.state) {
		// 	Object.entries(this.state).forEach(([key, val]) =>
		// 		prevState[key] !== val && console.log(`State '${key}' changed`)
		// 	);
		// }

		if (prevProps.resourceId !== this.props.resourceId) {
			this.setStageScale(1);
		}

		if (prevState.imageWidth !== this.state.imageWidth) {
			this.setState({ imageScaleFactor: this.state.imageWidth / this.state.naturalWidth })
		}

		if (
			JSON.stringify(prevProps.options || {}) !== JSON.stringify(this.props.options || {}) ||
			JSON.stringify(prevProps.defaultAnnotations || []) !== JSON.stringify(this.props.defaultAnnotations || []) ||
			JSON.stringify(prevProps.defaultAnnotationGroups || []) !== JSON.stringify(this.props.defaultAnnotationGroups || []) ||
			prevProps.resourceId !== this.props.resourceId
		) {
			this.setLatestAnnotationsFromResource();
		}


		if (prevProps.fullScreenMode !== this.props.fullScreenMode) {
			this.setState({ fullScreenMode: this.props.fullScreenMode })
		}

		if (prevProps.imageWidth !== this.props.imageWidth) {
			this.setState({ imageWidth: this.props.imageWidth })
		}

		if (prevProps.allowUserToSetAnyLabelForImageAnnotation !== this.props.allowUserToSetAnyLabelForImageAnnotation) {
			this.setState({ allowUserToSetAnyLabelForImageAnnotation: this.props.allowUserToSetAnyLabelForImageAnnotation })
		}

		if (prevProps.isLabelOn !== this.props.isLabelOn) {
			this.setState({ isLabelOn: this.props.isLabelOn })
		}

		if (prevProps.fullScreenMode !== this.props.fullScreenMode) {
			this.setState({ fullScreenMode: this.props.fullScreenMode })
		}

	}

	setLatestAnnotationsFromResource() {
		const {
			defaultAnnotations,
			defaultAnnotationGroups,
			options
		} = this.props;

		const entities = { options: {}, annotations: {}, annotationGroups: [] };
		let rootOptionId = '';
		let annotations = [];

		// normalize
		if (options && Object.keys(options).length !== 0) {
			const option = new schema.Entity('options');
			const children = new schema.Array(option);
			option.define({ children });
			const normalizedOptions = normalize(options, option);
			entities.options = normalizedOptions.entities.options;
			rootOptionId = normalizedOptions.result;
		} else {
			rootOptionId = '0';
			entities.options['0'] = { id: '0', value: 'root', children: [] };
		}

		if (defaultAnnotations && defaultAnnotations.length !== 0) {
			const annotation = new schema.Entity('annotations');
			const normalizedAnn = normalize(defaultAnnotations, [annotation]);
			entities.annotations = normalizedAnn.entities.annotations;
			annotations = normalizedAnn.result;
		}

		if (defaultAnnotationGroups && defaultAnnotationGroups.length !== 0) {
			entities.annotationGroups = defaultAnnotationGroups;
		}

		this.setState({
			entities: { ...entities },
			rootOptionId,
			annotations: [...annotations]
		})

		this.UndoRedoState = new UndoRedo();
	}

	componentWillUnmount = () => {
		if (document.body.style?.cursor === "crosshair") {
			document.body.style.cursor = "default";
		}
		document.removeEventListener('keydown', this.handleKeydown, false);
	}

	componentWillReceiveProps(nextProps) {
		this.setState({ imageWidth: nextProps.imageWidth, fullScreenMode: nextProps.fullScreenMode });
	}

	/**
	 * @param {number} scaleToSet 
	 */
	setStageScale = (scaleToSet) => {
		if (scaleToSet !== 1) {
			/**
			 * Resetting magnifier property to default as magnifier doesn't work properly
			 * on zoomed image
			 */
			this.handleMagnifierChange(1);
		}
		this.setState({ stageScale: scaleToSet })
	}

	/* ==================== shortkey ==================== */
	handleKeydown = (e) => {
		const { onPreviousClick, onSkipClick, onNextClick } = this.props;
		const { customizedOptionInputFocused } = this.state;
		if (customizedOptionInputFocused) return;
		// console.log("key down on Two D",e.keyCode);
		const focusedElement = document.activeElement;
		if (
			focusedElement.tagName.toLowerCase() === "textarea" ||
			(focusedElement.tagName.toLowerCase() === "input" &&
				(focusedElement.type.toLowerCase() === "number" || focusedElement.type.toLowerCase() === "text"))) {
			return;
		}

		switch (e.keyCode) {
			case SHORTCUTS.UNDO_REDO.UNDO.code:
				this.handleUndoClick();
				break;
			case SHORTCUTS.UNDO_REDO.REDO.code:
				this.handleRedoClick();
				break;
			case SHORTCUTS.BUTTON.TOGGLE_LABEL.code:
				this.handleToggleLabel();
				break;
			case SHORTCUTS.BUTTON.ADD.code:
				this.handleAddClick();
				break;
			case SHORTCUTS.BUTTON.BOUNDING_BOX.code:
				this.handleAddRectangleClick();
				break;
			// case SHORTCUTS.BUTTON.PREVIOUS.code:
			// 	if (onPreviousClick) this.handleSubmit('Previous');
			// 	break;
			case SHORTCUTS.BUTTON.SAVE.code:
				if (onPreviousClick) this.handleSubmit('Save');
				break;
			case SHORTCUTS.BUTTON.SKIP.code:
				if (onSkipClick) this.handleSubmit('Skip');
				break;
			case SHORTCUTS.BUTTON.NEXT.code:
				if (onNextClick) this.handleSubmit('Next');
				break;
			case SHORTCUTS.BUTTON.RIGHTARROW.code:
				if (onNextClick && this.props.scenario === "copilotImageAnnotationPopUp") this.handleSubmit('Next');
				break;
			case SHORTCUTS.BUTTON.LEFTARROW.code:
				if (onPreviousClick && this.props.scenario === "copilotImageAnnotationPopUp") this.handleSubmit('Previous');
				break;
			case SHORTCUTS.MAGNIFIER['1X'].code:
				this.handleMagnifierChange(1);
				break;
			case SHORTCUTS.MAGNIFIER['2X'].code:
				this.handleMagnifierChange(2);
				break;
			case SHORTCUTS.MAGNIFIER['3X'].code:
				this.handleMagnifierChange(3);
				break;
			case SHORTCUTS.MAGNIFIER['4X'].code:
				this.handleMagnifierChange(4);
				break;
			default:
		}
	}

	updateCanvasContextMenuState = (canvasContextMenuState) => {
		this.setState(prevState => {
			return {
				...prevState,
				canvasContextMenu: {
					...prevState.canvasContextMenu,
					...canvasContextMenuState
				}
			}
		})
	}

	/* ==================== control ==================== */
	handleMagnifierChange = (power) => {
		this.setState({ magnifyingPower: power });
	}

	handleToggleLabel = () => {
		this.setState(prevState => ({ isLabelOn: !prevState.isLabelOn }));
	}

	handleAddClick = () => {
		this.setState(prevState => ({ isAdding: !prevState.isAdding, isAddingRectangle: false, focusedName: '', focusedGroupAnnotationId: '' }));
	}
	handleAddRectangleClick = () => {
		this.setState(prevState => ({ isAddingRectangle: !prevState.isAddingRectangle, isAdding: false, focusedName: '', focusedGroupAnnotationId: '' }));
	}
	handleLabelClick = (event) => {

		this.setState({
			active: event.target.id,
			displayLabel: true,
			displayReference: false,
		});
		
	};
	handlelReferenceClick = (event) => {

		this.setState({
			active: event.target.id,
			displayLabel: false,
			displayReference: true,
		});
	};
	/**
	 * @param {string} annotationGroupId 
	 */
	handleAddImageAnnotationInExistingGroupButtonClick = (annotationGroupId) => {
		this.setState(prevState => ({ isAdding: !prevState.isAdding, focusedName: '', focusedGroupAnnotationId: annotationGroupId }));
	}

	/* ==================== undo/redo ==================== */
	handleUndoClick = () => {
		if (this.UndoRedoState.previous.length === 0) return;
		this.setState((prevState) => {
			const state = this.UndoRedoState.undo(prevState);
			return { ...state };
		});
	}

	handleRedoClick = () => {
		if (this.UndoRedoState.next.length === 0) return;
		this.setState((prevState) => {
			const state = this.UndoRedoState.redo(prevState);
			return { ...state };
		});
	}

	/* ==================== canvas ==================== */
	handleCanvasImgLoad = (e) => {
		const { imageWidth } = this.state;
		const { target } = e;
		console.log(imageWidth, target.naturalWidth, target.naturalHeight, target.height)
		this.setState({ imageScaleFactor: imageWidth / target.naturalWidth, imageHeight: target.height, naturalWidth: target.naturalWidth, naturalHeight: target.naturalHeight });
	}

	handleCanvasStageMouseDown = (e) => {

		/** @type {import('../../../../../../../../common').IModelType} */
		const modelType = this.props.modelType;

		const stage = e.target.getStage();
		const uniqueKey = getUniqueKey();
		const uniqueAnnotationGroupId = generateUniqueId();
		const color = colors[getRandomInt(colors.length)];
		// let { x, y } = stage.getPointerPosition();
		let { x, y } = stage.getRelativePointerPosition();

		let vertices;
		this.setState((prevState) => {
			const {
				isAdding, isAddingRectangle, focusedName, annotations, entities, imageWidth, imageHeight, focusedGroupAnnotationId
			} = prevState;
			if (!isAdding && !isAddingRectangle) return {};
			// prevent x, y exceeding boundary
			x = x < 0 ? 0 : x; x = x > imageWidth ? imageWidth : x;
			y = y < 0 ? 0 : y; y = y > imageHeight ? imageHeight : y;
			this.UndoRedoState.save(prevState);
			// first time adding
			if (!focusedName) {
				vertices = [];
				vertices.push(Vertex({
					id: `${uniqueKey}`, name: `${uniqueKey}`, x, y,
				}));
				entities.annotations[`${uniqueKey}`] = Polygon({
					id: `${uniqueKey}`, name: `${uniqueKey}`, color, vertices,
				});
				if (modelType === 'imageAnnotationGroup') {
					entities.annotations[`${uniqueKey}`].groupAnnotationId = focusedGroupAnnotationId || uniqueAnnotationGroupId;
				}
				if (isNullOrUndefined(entities.annotations[`${uniqueKey}`]?.confidenceScore)) {
					entities.annotations[`${uniqueKey}`].confidenceScore = 100;
				}
				return {
					focusedName: `${uniqueKey}`,
					focusedGroupAnnotationId:
						modelType === 'imageAnnotationGroup'
							? focusedGroupAnnotationId || uniqueAnnotationGroupId
							: "",
					annotations: [...annotations, `${uniqueKey}`],
					entities: {
						...entities,
						annotations: entities.annotations,
						annotationGroups:
							modelType === 'imageAnnotationGroup'
								? removeDuplicateElementsFromArrayByElementId([...entities.annotationGroups, { id: focusedGroupAnnotationId || uniqueAnnotationGroupId, name: "" }])
								: ""
					},
				};
			}
			// continuing adding
			entities.annotations?.[focusedName]?.vertices.push(Vertex({
				id: `${uniqueKey}`, name: `${uniqueKey}`, x, y,
			}));

			if (isAddingRectangle) {
				if (entities.annotations?.[focusedName]?.vertices?.length === 2) {
					// means user is about to add 2nd vertice in annotation

					// creating other two vertices to form a rectangle
					const secondVerticeX = entities.annotations?.[focusedName]?.vertices?.[1]?.x; 
					const secondVerticeY = entities.annotations?.[focusedName]?.vertices?.[0]?.y; 

					const secondVerticeUniqueId = generateUniqueId();
					
					const fourthVerticeX = entities.annotations?.[focusedName]?.vertices?.[0]?.x; 
					const fourthVerticeY = entities.annotations?.[focusedName]?.vertices?.[1]?.y; 
					
					const fourthVerticeUniqueId = generateUniqueId();

					// adding 2nd vertice
					entities.annotations?.[focusedName]?.vertices.splice(
						// adding element at 1st index
						1,
						0,
						{
							x: secondVerticeX,
							y: secondVerticeY,
							id: secondVerticeUniqueId,
							name: secondVerticeUniqueId,
						}
					)

					// adding 4th vertice
					entities.annotations?.[focusedName]?.vertices.splice(
						// adding element at 1st index
						3,
						0,
						{
							x: fourthVerticeX,
							y: fourthVerticeY,
							id: fourthVerticeUniqueId,
							name: fourthVerticeUniqueId,
						}
					)

					// creating it as polygon
					entities.annotations[focusedName].isClosed = true;

					this.setState({isAddingRectangle: false})
				}
			}

			return { entities: { ...entities, annotations: entities.annotations } };
		}, () => {
			this.triggerAnnotationsChangedByUserEvent();
		});
	}

	handleCanvasVertexRemove = (e) => {
		/**
		 * This function will remove the activeVertex
		 */

		if (this.props.isViewOnlyMode) {
			return;
		}

		const activeVertex = e.target;
		const group = activeVertex.getParent();
		this.setState((prevState) => {
			const { focusedName, entities } = prevState;
			const { annotations } = entities;
			if (group.name() === focusedName) {

				if (annotations[focusedName] && isNullOrUndefined(annotations[focusedName]?.confidenceScore)) {
					annotations[focusedName].confidenceScore = 100;
				}

				for (let vertexIndex = 0; vertexIndex < annotations[focusedName].vertices.length; vertexIndex++) {
					const vertex = annotations[focusedName].vertices[vertexIndex];
					if (
						vertex.x === activeVertex.attrs.x &&
						vertex.y === activeVertex.attrs.y
					) {
						annotations[focusedName].vertices.splice(vertexIndex, 1)
						if (annotations[focusedName].vertices.length === 2) {
							annotations[focusedName].isClosed = false;
						}
						break;
					}
				}
			}
			return {
				...prevState,
				entities: {
					...prevState.entities,
					annotations
				}
			}
		}, () => {
			this.triggerAnnotationsChangedByUserEvent();
		});
	}

	handleCanvasVertexExtendInAnnotation = async (e) => {
		if (this.props.isViewOnlyMode) {
			return;
		}

		const activeVertex = e.target;
		const group = activeVertex.getParent();

		if (!group) {
			return;
		}

		this.setState((prevState) => {
			const { focusedName, entities } = prevState;
			const { annotations } = entities;

			if (group.name() === focusedName) {
				/**
				 * making annotation a line if its a polygon
				 */
				entities.annotations[focusedName].isClosed = false
			}

			if (entities.annotations[focusedName] && isNullOrUndefined(entities.annotations[focusedName]?.confidenceScore)) {
				entities.annotations[focusedName].confidenceScore = 100;
			}

			for (let vertexIndex = 0; vertexIndex < annotations[focusedName].vertices.length; vertexIndex++) {
				const vertexWhichShouldGoToEnd = copyByValue(annotations[focusedName].vertices[vertexIndex]);
				if (
					vertexWhichShouldGoToEnd.x === activeVertex.attrs.x &&
					vertexWhichShouldGoToEnd.y === activeVertex.attrs.y
				) {

					/**
					 * making vertex which user selected to become the last vertex in vertices array and shifting all other vertices accordingly
					 * for eg: if array elements are ['a','b','c','d'] and user selected
					 * 'c' then array elements order after shifting will be: ['d','a','b','c']
					 */
					annotations[focusedName].vertices = annotations[focusedName].vertices.concat(annotations[focusedName].vertices.splice(0, vertexIndex + 1));


					/**
					 * as per library the first vertex id and name should be equal to annotation id.
					 * therefore making changes below for that
					 */
					for (const vertex of annotations[focusedName].vertices) {
						if (vertex.id === annotations[focusedName].id) {
							vertex.id = copyByValue(annotations[focusedName].vertices[0].id);
							vertex.name = copyByValue(annotations[focusedName].vertices[0].name);
							break;
						}
					}
					annotations[focusedName].vertices[0].id = copyByValue(annotations[focusedName].id);
					annotations[focusedName].vertices[0].name = copyByValue(annotations[focusedName].id);

					break;
				}
			}

			return {
				...prevState,
				isAdding: true,
				entities: {
					...entities
				}
			}
		}, () => {
			this.triggerAnnotationsChangedByUserEvent();
		});
	}
	handleViewAnnotation = async (e) => {

		/**
		 * This function is used to scroll to particular annotation in annotation list
		 */

		if (this.props.isViewOnlyMode) {
			return;
		}

		const activeVertex = e.target;
		const group = activeVertex.getParent();

		if (!group || !group.name()) {
			return;
		}

		/** @type {string} */
		const groupName = group.name();

		/**
		 * waiting for few seconds to get view updated before we perform manual scroll
		 */
		await new Promise((resolve, reject) => {
			setTimeout(() => {
				resolve();
			}, 500);
		})

		/** @type {HTMLElement} */
		const annotationHtmlElement = document.getElementsByName(`${groupName}`)[0];

		if (!annotationHtmlElement) {
			return;
		}

		const annotationListElement = document.getElementById("annotation-list");
		if (!annotationListElement) {
			return;
		}

		/**
		 * performing calculations to get better view of annotation to which user wants to scroll
		 */
		annotationListElement.scrollTop =
			(annotationHtmlElement.offsetTop - 96) > annotationListElement.scrollTop
				? annotationHtmlElement.offsetTop - 10
				: annotationHtmlElement.offsetTop - 50

	}

	handleCanvasVertexMouseDown = (e) => {
		const activeVertex = e.target;
		const group = activeVertex.getParent();
		this.setState((prevState) => {
			const { isAdding, focusedName, entities } = prevState;
			if (isAdding) {
				const { annotations } = entities;
				if (group.name() === focusedName && annotations[focusedName].vertices[0].name === activeVertex.name()) {
					annotations[focusedName].isClosed = true;

					if (isNullOrUndefined(annotations[focusedName]?.confidenceScore)) {
						annotations[focusedName].confidenceScore = 100;
					}

					return { isAdding: false, entities: { ...entities, annotations } };
				}
				return {};
			}
			return { focusedName: group.name() };
		}, () => {
			this.triggerAnnotationsChangedByUserEvent();
		});
	}

	handleCanvasVertexDragEnd = (e) => {
		const activeVertex = e.target;
		const group = activeVertex.getParent();
		this.setState((prevState) => {
			const {
				isAdding, entities, imageWidth, imageHeight,
			} = prevState;
			if (isAdding) return {};
			const { annotations } = entities;
			const vertices = annotations[group.name()].vertices.map((v) => {
				if (v.name !== activeVertex.name()) return v;
				// prevent x, y exceeding boundary
				let x = activeVertex.x(); let y = activeVertex.y();
				x = x < 0 ? 0 : x; x = x > imageWidth ? imageWidth : x;
				y = y < 0 ? 0 : y; y = y > imageHeight ? imageHeight : y;
				return { ...v, x, y };
			});
			annotations[group.name()].vertices = vertices;

			if (isNullOrUndefined(annotations[group.name()]?.confidenceScore)) {
				annotations[group.name()].confidenceScore = 100;
			}

			return { entities: { ...entities, annotations } };
		}, () => {
			this.triggerAnnotationsChangedByUserEvent();
		});
	}

	handleCanvasFocusing = (e) => {
		const activeShape = e.target;
		const annotations = this.state.annotations;
		const focusedName = activeShape.name();

		/* below is the logic to bring the focused annotations name at the end of annotations array
			ex: annotations array: [a,b,c,d,e]
			focused annotation name: c
			newAnnotationsArray = [a,b,d,e,c]
		
			Why so: this will help the focused annotation to stay on the top level, hence allowing all functionalities become active like dragging vertix of bottom most shape
		*/
		let newAnnotationsArray = []
		for(let i = 0; i < annotations.length; i++) {
			if(annotations[i] == focusedName) continue;
			newAnnotationsArray.push(annotations[i]);
		}

		newAnnotationsArray.push(focusedName);

		this.setState((prevState) => {
			if (prevState.isAdding) return {};
			return { 
				...prevState,
				focusedName: activeShape.name(),
				annotations: [...newAnnotationsArray],
			};
		});
	}

	/* ==================== anootation list ==================== */
	handleAnnotationClick = (name) => {
		if (this.state.focusedName === name) {
			this.setState({ focusedName: "" });
		} else {
			this.setState({ focusedName: name });
		}
	};

	handleAnnotationDeleteClick = (name) => {
		this.setState((prevState) => {
			const { entities } = prevState;
			const { annotations } = entities;
			delete annotations[name];
			const i = prevState.annotations.indexOf(name);
			prevState.annotations.splice(i, 1);
			return { annotations: prevState.annotations, entities: { ...entities, annotations } };
		}, () => {
			this.triggerAnnotationsChangedByUserEvent();
		});
	}

	/**
	 * @param {string} annotationGroupIdToDelete 
	 */
	handleAnnotationGroupDeleteClick = (annotationGroupIdToDelete) => {
		const { annotations, annotationGroups } = this.state.entities;

		/** @type {string[]} */
		const annotationIds = this.state.annotations
		for (let index = 0; index < annotationGroups.length; index++) {
			const annotationGroup = annotationGroups[index];
			if (annotationGroup.id === annotationGroupIdToDelete) {
				annotationGroups.splice(index, 1);
			}
		}
		for (let index = 0; index < Object.keys(annotations).length; index++) {
			const annotatationId = Object.keys(annotations)[index];
			const annotation = annotations[annotatationId];
			if (annotation.groupAnnotationId === annotationGroupIdToDelete) {
				const annotationIdIndexToDelete = annotationIds.indexOf(annotatationId);
				if (annotationIdIndexToDelete !== -1) {
					annotationIds.splice(annotationIdIndexToDelete, 1);
				}
				delete annotations[annotatationId];
				index = index - 1;
			}
		}
		this.setState(oldState => ({
			...oldState,
			annotations: [...annotationIds],
			entities: {
				...oldState.entities,
				annotationGroups,
				annotations
			}
		}), () => {
			this.triggerAnnotationsChangedByUserEvent();
		})
	}

	/* ==================== option list ==================== */
	handleOptionCustomizedInputFocus = () => this.setState({ customizedOptionInputFocused: true });

	handleOptionCustomizedInputBlur = () => this.setState({ customizedOptionInputFocused: false });

	handleOptionCustomizedFormSubmit = (e, parentId, value) => {
		e.preventDefault();
		this.setState((prevState) => {
			const { entities } = prevState;
			const { options } = entities;
			const uniqueKey = getUniqueKey();
			options[uniqueKey] = { id: uniqueKey, value, children: [] };
			options[parentId].children.push(uniqueKey);
			return { entities: { ...entities, options } };
		}, () => {
			this.triggerAnnotationsChangedByUserEvent();
		});
	}

	handleOptionSelect = (name, selectedIds) => {
		this.setState((prevState) => {
			const { entities } = prevState;
			const selectedOptions = selectedIds.map(id => entities.options[id]).map(s => ({ id: s.id, value: s.value }));
			const updatedAnn = { ...entities.annotations[name], selectedOptions };
			return { entities: { ...entities, annotations: { ...entities.annotations, [name]: updatedAnn } } };
		}, () => {
			this.triggerAnnotationsChangedByUserEvent();
		});
	}

	handleAllowAnyAnnotationLabelOptionSelect = (name, selectedIds, labelName) => {
		this.setState((prevState) => {
			const { entities } = prevState;
			
			const selectedOptions = selectedIds.map(id => entities.options[id]).map(s => ({ id: s.id, value: s.value }));
			selectedOptions.push({
				id: generateUniqueId(),
				value: labelName
			})
			const updatedAnn = { ...entities.annotations[name], selectedOptions };
			return { entities: { ...entities, annotations: { ...entities.annotations, [name]: updatedAnn } } };
		}, () => {
			this.triggerAnnotationsChangedByUserEvent();
		});
	}

	/**
	 * @param {string} updatedLabel 
	 * @param {string} annotationGroupIdWhoseLabelToChange 
	 */
	handleAnnotationGroupLabelChange = (updatedLabel, annotationGroupIdWhoseLabelToChange) => {
		this.setState(oldState => {
			const entities = copyByValue(oldState.entities);
			if (Array.isArray(entities.annotationGroups)) {
				for (const annotationGroup of entities.annotationGroups) {
					if (annotationGroup.id === annotationGroupIdWhoseLabelToChange) {
						annotationGroup.name = updatedLabel;
					}
				}
			}
			return {
				...oldState,
				entities: {
					...oldState.entities,
					...entities
				}
			}
		}, () => {
			this.triggerAnnotationsChangedByUserEvent();
		})
	}

	handleOptionDeleteClick = (deleteIds) => {
		this.setState((prevState) => {
			const { entities } = prevState;
			const { options } = entities;
			delete options[deleteIds[deleteIds.length - 1]];
			const i = options[deleteIds[deleteIds.length - 2]].children.indexOf(deleteIds[deleteIds.length - 1]);
			options[deleteIds[deleteIds.length - 2]].children.splice(i, 1);
			return { entities: { ...entities, options } };
		}, () => {
			this.triggerAnnotationsChangedByUserEvent();
		});
	}


	/* ==================== submit ==================== */

	/**
	 * @param {"onAnnotationsChange" | "Skip" | "Save" | "Previous" | "Next"} type 
	 */
	handleSubmit = (type) => {
		const {
			imageScaleFactor, imageWidth, imageHeight, annotations, entities, rootOptionId,
		} = this.state;
		const { annotationGroups } = entities;
		const { url, onSkipClick, onPreviousClick, onNextClick, onSaveClick } = this.props;

		/** @type {import('../../../../../../../../common').IModelType} */
		const modelType = this.props.modelType;

		const annotation = new schema.Entity('annotations');
		let denormalizedAnnotations = denormalize({ annotations }, { annotations: [annotation] }, entities).annotations;
		const option = new schema.Entity('options');
		const children = new schema.Array(option);
		option.define({ children });
		const denormalizedOptions = denormalize({ options: rootOptionId }, { options: option }, entities).options;

		const imageGroupAnnotations =
			modelType === 'imageAnnotationGroup'
				? setImageGroupAnnotationsInAnnotationComponentForSavingInDBIfModelTypeImageGroupAnnotation(
					denormalizedAnnotations,
					annotationGroups,
					imageScaleFactor
				)
				: [];

		denormalizedAnnotations =
			modelType === 'imageAnnotation' || 'multipleImageAnnotation'
				? setImageAnnotationsInAnnotationComponentForSavingInDBIfModelTypeImageAnnotation(
					denormalizedAnnotations,
					imageScaleFactor
				)
				: [];

		if (type === "Save") {
			if (this.state.isAdding) {
				this.setState({ isAdding: false })
			}
		}

		switch (type) {
			case 'Skip':
				onSkipClick({
					url, imageScaleFactor, imageWidth, imageHeight, annotations: denormalizedAnnotations, options: denormalizedOptions,
				});
				break;
			case 'Save':
				onSaveClick({
					url, imageScaleFactor, imageWidth, imageHeight, annotations: denormalizedAnnotations, imageGroupAnnotations: imageGroupAnnotations, options: denormalizedOptions,
				});
				break;
			case 'Previous':
				onPreviousClick({
					url, imageScaleFactor, imageWidth, imageHeight, annotations: denormalizedAnnotations, options: denormalizedOptions,
				});
				break;
			case 'Next':
				onNextClick({
					url, imageScaleFactor, imageWidth, imageHeight, annotations: denormalizedAnnotations, options: denormalizedOptions,
				});
				break;
			case "onAnnotationsChange":
				if (this.props.onAnnotationsChange) {
					this.props.onAnnotationsChange({ annotations: denormalizedAnnotations, imageGroupAnnotations: imageGroupAnnotations })
				}
				break;
			default:
				break;
		}
	}

	/**
	 * @param {number} xAxis 
	 * @param {number} yAxis 
	 */
	setMouseAnnotationCoordinates = (xAxis, yAxis) => {
		this.setState({
			mouseAnnotationCoordinates: {
				xAxis,
				yAxis
			}
		})
	}

	render() {
		const {
			isAdding,
			isAddingRectangle,
			focusedName,
			focusedGroupAnnotationId,
			magnifyingPower,
			isLabelOn,
			imageWidth,
			fullScreenMode,
			imageHeight,
			annotations,
			entities,
			rootOptionId
		} = this.state;
		const {
			className,
			url,
			emptyAnnotationReminderText,
			isDynamicOptionsEnable,
			disabledOptionLevels,
			isViewOnlyMode,
			hasPreviousButton,
			hasNextButton,
			hasSaveButton,
			hasSkipButton,
			alert
		} = this.props;
		const twoDimensionalImageContext = {
			url,
			isAdding,
			isAddingRectangle,
			entities,
			isViewOnlyMode,
			canvasStageHeight: this.props.canvasStageHeight,
			annotations,
			height: imageHeight,
			width: imageWidth,
			allowUserToSetAnyLabelForImageAnnotation: this.props.allowUserToSetAnyLabelForImageAnnotation,
			settingManuallyCanvasWidth: this.props.settingManuallyCanvasWidth,
			focusedName,
			focusedGroupAnnotationId,
			isLabelOn,
			magnifyingPower,
			modelType: this.props.modelType,
			emptyAnnotationReminderText,
			onAnnotationClick: this.handleAnnotationClick,
			onAnnotationDeleteClick: this.handleAnnotationDeleteClick,
			isDynamicOptionsEnable,
			disabledOptionLevels,
			onOptionSelect: this.handleOptionSelect,
			handleAllowAnyAnnotationLabelOptionSelect: this.handleAllowAnyAnnotationLabelOptionSelect,
			onOptionDeleteClick: this.handleOptionDeleteClick,
			onOptionCustomizedInputFocus: this.handleOptionCustomizedInputFocus,
			onOptionCustomizedInputBlur: this.handleOptionCustomizedInputBlur,
			onOptionCustomizedFormSubmit: this.handleOptionCustomizedFormSubmit,
			onCanvasStageMouseDown: this.handleCanvasStageMouseDown,
			onCanvasVertexMouseDown: this.handleCanvasVertexMouseDown,
			onCanvasVertexDragEnd: this.handleCanvasVertexDragEnd,
			onCanvasLabelMouseDown: this.handleCanvasFocusing,
			onCanvasLineMouseDown: this.handleCanvasFocusing,
			onCanvasImgLoad: this.handleCanvasImgLoad,
			rootOptionId,
			handleCanvasVertexRemove: this.handleCanvasVertexRemove,
			handleCanvasVertexExtendInAnnotation: this.handleCanvasVertexExtendInAnnotation,
			handleAddImageAnnotationInExistingGroupButtonClick: this.handleAddImageAnnotationInExistingGroupButtonClick,
			handleAnnotationGroupDeleteClick: this.handleAnnotationGroupDeleteClick,
			handleAnnotationGroupLabelChange: this.handleAnnotationGroupLabelChange,
			canvasContextMenu: this.state.canvasContextMenu,
			updateCanvasContextMenuState: this.updateCanvasContextMenuState,
			scenario: this.props.scenario,
			stageScale: this.state.stageScale,
			setStageScale: this.setStageScale,
			isShowingCompressedImageBecauseOriginalImageIsDownloading: this.props.isShowingCompressedImageBecauseOriginalImageIsDownloading,
			isZoomFeatureEnabled: this.props.isZoomFeatureEnabled,
			setMouseAnnotationCoordinates: this.setMouseAnnotationCoordinates,
			imageScaleFactor: this.state.imageScaleFactor,
			originalImageWidth: this.props.originalImageWidth,
			originalImageHeight: this.props.originalImageHeight,
			naturalWidth: this.state.naturalWidth,
			naturalHeight: this.state.naturalHeight,
			maxImageHeightAllowed: this.props.maxImageHeightAllowed,
			isFullScreenMode: fullScreenMode
		};
		document.body.style.cursor = isAdding ? 'crosshair' : 'default';

		const isAiMarketplaceScenario = this.props.scenario === "aiMarketPlaceDataCart" || this.props.scenario === "aiMarketplaceDataSelection"

		const toggleLabelButtonUI = (
			<Button color='link' onClick={this.handleToggleLabel} className='two-dimensional-image__label-button d-flex align-items-center'>
				<FaCommentAlt className='pr-1' />
				{isLabelOn ? 'On' : 'Off'}
				<small className='pl-1'>{`(${SHORTCUTS.BUTTON.TOGGLE_LABEL.key})`}</small>
			</Button>
		);
		const previousButtonUI = hasPreviousButton ? (
			<Button color='secondary' onClick={() => this.handleSubmit('Previous')}>
				Previous
				<small>{`(${SHORTCUTS.BUTTON.PREVIOUS.key})`}</small>
			</Button>
		) : '';
		const ImageOptionsUI = () => {
			return (
				<div
					className={`
					mb-3 d-flex
					${this.props.scenario === 'coPilotActivePageReadOnlyAnnotation' ? 'displayNone' : ''}
					${css.imageOptionsToolbar}
				`}
				>
					<div className='d-flex mr-auto'>
						{toggleLabelButtonUI}
						{
							!isViewOnlyMode && this.props.scenario !== "coPilotActivePageEditAnnotation" &&
							<MagnifierDropdown
								handleChange={this.handleMagnifierChange}
								power={magnifyingPower}
								shortcuts={SHORTCUTS.MAGNIFIER}
							/>
						}

						{
							this.props.isZoomFeatureEnabled &&
							<div
								style={{
									padding: "6px 12px",
									display: "flex"
								}}
							>
								<span title="Ctrl + scroll to zoom an image">Zoom:</span>&nbsp;<div title="1 is the default zoom level" style={{ minWidth: "35px" }}>{this.state.stageScale?.toFixed(1)}</div>&nbsp;
								{/* <Tooltip>

							</Tooltip> */}
								{/* <span
								style={{
									cursor: "pointer"
								}}
								onClick={()=>{
									this.setStageScale(1);
								}}
								title="Reset zoom level to 1"
							><MdAutorenew /></span> */}
								<MuiButton
									// variant="contained"
									style={{
										paddingTop: 0,
										paddingBottom: 0,
										textTransform: "none",
										cursor: "pointer",
										fontSize: "16px"
									}}
									onClick={() => {
										this.setStageScale(1)
									}}
									size="small"
									// color="secondary"
									startIcon={<MdAutorenew style={{ fontSize: "15px" }} />}
								>
									Reset Zoom
								</MuiButton>
							</div>
						}

						{
							this.props.shouldShowMouseAnnotationCoordinates &&
							<div
								className={`${css.coordinatesSection}`}
							>
								<div title="X mouse coordinate" className={css.label}>X:&nbsp;</div> <div className={css.value}>{Math.floor(this.state.mouseAnnotationCoordinates.xAxis)}</div>
								<div title="Y mouse coordinate" className={css.label}>Y:&nbsp;</div> <div className={css.value}>{Math.floor(this.state.mouseAnnotationCoordinates.yAxis)}</div>
							</div>
						}
					</div>
					{
						!isViewOnlyMode &&
						<UndoRedoButton
							undoRedoState={this.UndoRedoState}
							onUndoClick={this.handleUndoClick}
							onRedoClick={this.handleRedoClick}
							shortcuts={SHORTCUTS.UNDO_REDO}
						/>
					}
				</div>
			)
		}
		const nextButtonUI = hasNextButton ? (
			<Button color='secondary' onClick={() => this.handleSubmit('Next')}>
				Next
				<small>{`(${SHORTCUTS.BUTTON.NEXT.key})`}</small>
			</Button>
		) : '';
		const skipButtonUI = hasSkipButton ? (
			<Button color='secondary' onClick={() => this.handleSubmit('Skip')}>
				Skip
				<small>{`(${SHORTCUTS.BUTTON.SKIP.key})`}</small>
			</Button>
		) : '';
		const saveButtonUI = hasSaveButton ? (
			<Button style={{ backgroundColor: themePrimaryMainColor }} onClick={() => this.handleSubmit('Save')}>
				Save Annotation (s)
				{/* <small>{`(${SHORTCUTS.BUTTON.SKIP.key})`}</small> */}
			</Button>
		) : '';

		const addButtonUI = (
			isAiMarketplaceScenario ? null :
			<div
				style={{
					display: "flex",
					justifyContent: "space-between"
				}}
			>
				<Button
					outline
					className={`d-flex align-items-center mb-3 two-dimensional-image__add-button ${css.addButton}`}
					color='primary'
					// style={{marginLeft : '16px'}}
					onClick={() => this.handleAddClick()}
				>
					<MdAdd />
					{isAdding ? 'Adding Polygon' : 'Add Polygon'}
					<small>{`(${SHORTCUTS.BUTTON.ADD.key})`}</small>
				</Button>
				<Button
					outline
					className={`d-flex align-items-center mb-3 two-dimensional-image__add-button ${css.addButton}`}
					color='primary'
					// style={{marginLeft : '16px'}}
					onClick={() => this.handleAddRectangleClick()}
				>
					<MdAdd />
					{isAddingRectangle ? 'Adding Bounding Box' : 'Add Bounding Box'}
					<small>{`(${SHORTCUTS.BUTTON.BOUNDING_BOX.key})`}</small>
				</Button>
			</div>
		);

		const PointOptionsInfo = () => {
			return (
				<div style={{ display: "flex", padding: "10px", color: "#007bff" }}>
					<InfoIcon size="small" />&nbsp;Right click on point for options
				</div>
			)
		}

		const rootClassName = `two-dimensional-image${className ? ` ${className}` : ''}`;

		const FullScreenIconComponent = () => (
			<Tooltip title={`Open in Expand View (f)`}>
				<FullscreenIcon
					style={{ fontSize: '30px' }}
					color="primary" />
			</Tooltip>
		)

		const FullScreenExitIconComponent = () => (
			<Tooltip title={`Exit Expand View (f)`}>
				<FullscreenExitIcon
					style={{ fontSize: '30px' }}
					color="primary" />
			</Tooltip>
		)

		console.log("full screen mode , ", fullScreenMode)
		return (
			<I18nextProvider i18n={i18nextInstance}>
				<TwoDimensionalImageContext.Provider value={twoDimensionalImageContext}>

					<div className={`
						${rootClassName}
						${this.props.isZoomFeatureEnabled ? css.zoomFeatureEnabled : ""}
						${this.props.isFocusMode ? css.focusMode : ""}
					` }>
						{!isViewOnlyMode && (
							<div
								className={`d-flex justify-content-center pb-3
									${this.props.scenario === "coPilotActivePageReadOnlyAnnotation" || this.props.scenario === "coPilotActivePageEditAnnotation" ? "padding-top-0 padding-bottom-0" : ""}
								`}
							>
								<ButtonGroup>
									{previousButtonUI}
									{nextButtonUI}
								</ButtonGroup>
							</div>
						)}

						<div className={`d-flex flex-wrap justify-content-around py-3 two-dimensional-image__image-canvas-container
							${this.props.scenario === "coPilotActivePageEditAnnotation" ? 'justify-content-space-between' : ''}
							${this.props.scenario === "coPilotActivePageEditAnnotation" || this.props.scenario === "coPilotActivePageReadOnlyAnnotation" || this.props.scenario === "coPilotRejectPageReadOnlyAnnotation" || this.props.scenario === "modelAnalyticsResourcePageViewPopoup" ? 'padding-top-0 padding-bottom-0' : ''}
							${this.props.scenario === "coPilotRejectPageReadOnlyAnnotation" ? "justify-content-space-between" : ""}
							${css.imageCanvasContainer}
						`}
						>
							<div className={`
								mb-3
								${this.props.scenario === 'sideDrawer' ? "two-dimensional-image__canvasContainerIfSideDrawer" : ""}
								${css.lhsSection}
							`}
								style={{ ...(this.props.canvasBorderTimerAnimationStyle ? this.props.canvasBorderTimerAnimationStyle : {}), position: this.props.isDrawerOpen ? "fixed" : "sticky" }}
							>

								{
									(
										this.props.scenario !== 'coPilotActivePageReadOnlyAnnotation' &&
										this.props.scenario !== 'coPilotActivePageEditAnnotation'
									) &&
									<ImageOptionsUI />
								}

								<div style={{
									
									position: 'relative',
									width: this.props.isExpandFeatureEnabled && this.props.settingManuallyCanvasWidth
										? this.props.settingManuallyCanvasWidth
										: this.props.scenario === "coPilotActivePageReadOnlyAnnotation" || this.props.scenario === "coPilotActivePageEditAnnotation"
											? "448px"
											: "",
								}}
									onContextMenu={(e) => e.preventDefault()}
								>
									
								{this.props.modelType==="multipleImageAnnotation" && this.props.showPrevArrow &&  <div onClick={()=>{this.props.onSwitch(-1);console.log("ArrClick")}} style={{zIndex:100000, cursor:"pointer", position:"absolute",top:"80px", left: -38}}> <ArrowLeftIcon style={{fontSize:"40px", color:"#008D9C"}}/> </div>}
									<Canvas
										entities={entities}
										focusedName={focusedName}
										power={magnifyingPower}
										isLabelOn={isLabelOn}
									/>
									{this.props.modelType==="multipleImageAnnotation" && this.props.showNextArrow &&  <div  onClick={()=>{this.props.onSwitch(1);console.log("ArrClick")}} style={{cursor:"pointer", position:"absolute",top:"80px", right:-35}}><ArrowRightIcon style={{fontSize:"40px", color:"#008D9C"}}/> </div> }
									
									{this.props.isExpandFeatureEnabled &&
										<div style={{
											position: (this.props.scenario === "dialog" || this.props.scenario === 'aiMarketPlaceDataCart' || this.props.scenario === 'aiMarketplaceDataSelection' ) && fullScreenMode ? "sticky" : 'absolute', bottom: this.props.scenario === "modelAnalyticsResourcePageViewPopoup" ? 0 : 92, right: 0, cursor: 'pointer', background: 'rgba(255, 255, 255, 0.3)',
											...((this.props.scenario === "dialog" || this.props.scenario === 'aiMarketPlaceDataCart' || this.props.scenario === 'aiMarketplaceDataSelection') && fullScreenMode ? { textAlign: "end", background: "transparent" } : {})
										}}
											onClick={() => {
												this.props.onClickFullScreenMode()
											}} >
											<>
												{fullScreenMode ?
													<FullScreenExitIconComponent /> :
													<FullScreenIconComponent />}
											</>
										</div>}
									{!isViewOnlyMode && this.props.scenario !== "coPilotActivePageReadOnlyAnnotation" && <PointOptionsInfo />}
									{!this.props.isFocusMode && this.props.ActionsSection && (
										<div
											className={`
												${this.props.scenario === 'dialog' || this.props.scenario === 'aiMarketPlaceDataCart' || this.props.scenario === 'aiMarketplaceDataSelection' || this.props.scenario === 'copilotImageAnnotationPopUp' ? 'two-dimensional-image__actionSectionContainerForDialogScenario' : ''}
											`}
										>
											{this.props.ActionsSection}
										</div>
									)}
								</div>
							</div>
							<div className={`mb-3 ${this.props.isDrawerOpen ? "annotations-list" : ""}
								${this.props.scenario === 'dialog' || this.props.scenario === 'aiMarketPlaceDataCart' || this.props.scenario === 'aiMarketplaceDataSelection' || this.props.scenario === 'copilotImageAnnotationPopUp' ? "two-dimensional-image__addScrollbarForDialogScenario" : ""}`}
								style={{width: this.props.scenario === "coPilotActivePageEditAnnotation" ? '49%' : ''}}>
								<div
									className={`
											${this.props.scenario === 'sideDrawer' ? "two-dimensional-image__addAnnotationButtonRowForSideDrawerScenario" : "two-dimensional-image__addAnnotationButtonRow"}
											${this.props.scenario === 'dialog' || this.props.scenario === 'aiMarketPlaceDataCart' || this.props.scenario === 'aiMarketplaceDataSelection' || this.props.scenario === 'copilotImageAnnotationPopUp' ? "two-dimensional-image__addAnnotationButtonRowForDialogScenario" : ""}
										`}
								>

									{
										(
											this.props.scenario === "coPilotActivePageEditAnnotation"
										) &&
										<ImageOptionsUI />
									}
									{!isViewOnlyMode &&  this.props.scenario == "coPilotActivePageEditAnnotation"? <div className={css.buttonSection}>
										<Button key={1}
											className={this.state.active === "1" ? css.active : css.labelButton} id={"1"}
											onClick={this.handleLabelClick}>Label</Button>
										<Button key={2}
											className={this.state.active === "2" ? css.active : css.referenceButton} id={"2"}
											onClick={this.handlelReferenceClick}>Reference</Button>
									</div> : <></>}
									{!this.props.isFocusMode && !isViewOnlyMode && this.props.canShowAddAnnotationButton && this.state.displayLabel ? addButtonUI : <></>}
									{this.props.isFocusMode && (
										<div
											style={{
												display: "flex",
												marginBottom: "20px",
												gap: "10px"
											}}
										>
											{addButtonUI}
											{saveButtonUI}
										</div>
									)}
									{
										!this.props.isFocusMode && !isViewOnlyMode && this.state.displayReference ?
										<div style={{maxWidth: '520px', height: '219px', overflow: 'auto', backgroundColor: '#646464', color: 'white'}}>
											<ReferenceGuide referenceGroups={this.props.referenceGroups}/>
										</div> 
										: 
										<></>
									
									}
									{
										(this.props.fullScreenMode && this.props.isFocusMode) &&
										this.props.viewReferencesSwitchComponent
									}
									{
										(this.props.fullScreenMode && this.props.isFocusMode) &&
										this.props.focusModeComponent
									}
									{this.props.isFocusMode && (
										<>
											{this.props.ActionsSection}
											{/* {this.props.viewReferencesSwitchComponent && this.props.viewReferencesSwitchComponent} */}
											<div style={{
												marginBottom: "20px"
											}}></div>
										</>
									)}
									{this.props.fullscreenButton}
								</div>

								{/* {
										this.props.scenario !== "coPilotActivePageReadOnlyAnnotation" && this.props.scenario!=="coPilotRejectPageReadOnlyAnnotation" &&
										this.props.referenceDataComponent
										? <>{this.props.referenceDataComponent}</>
										: <AnnotationList />
									} */}


								{
									this.props.referenceDataComponent &&
									<div style={{ marginBottom: "10px" }}>
										{this.props.referenceDataComponent}
									</div>
								}


								{
									this.props.scenario !== "coPilotActivePageReadOnlyAnnotation" && this.props.scenario !== "coPilotRejectPageReadOnlyAnnotation" && this.state.displayLabel &&
									<AnnotationList />
					
									
								}

								{
									this.props.ResourceRemarksSection &&
									<div
										style={{ marginTop: "20px", position: "sticky", zIndex: 10 }}
									>
										{this.props.ResourceRemarksSection}
									</div>
								}

								{
									this.props.scenario === "coPilotActivePageEditAnnotation"  && 
									<div
										style={{
											marginTop: "20px"
										}}
									>
										{this.state.displayLabel ? saveButtonUI : <></>}
									</div>
								}
							</div>
						</div>
						{!isViewOnlyMode && !this.props.isFocusMode && (
							<div
								className={`d-flex justify-content-center pt-3
									${this.props.scenario === "coPilotActivePageEditAnnotation" ? "padding-top-0 padding-bottom-0" : ""}
								`}
							>{skipButtonUI}</div>
						)}

						<div style={{ position: 'relative' }}
							className={`
								${this.props.scenario === 'dialog' || this.props.scenario === 'aiMarketPlaceDataCart' || this.props.scenario === 'aiMarketplaceDataSelection' || this.props.scenario === 'copilotImageAnnotationPopUp' ? 'two-dimensional-image__saveAnnotationButtonRowForDialogScenario' : ''}
							`}


						>
							{
								this.props.scenario !== "coPilotRejectPageReadOnlyAnnotation" &&
								this.props.scenario !== "modelAnalyticsResourcePageViewPopoup" &&
								<div style={{ display: 'flex', justifyContent: 'center', position: 'absolute', zIndex: 99 }}
									className={`
								${this.props.scenario === 'coPilotActivePageEditAnnotation' ? `alertForEditAnnotation` : `alert ${css.alert}`}
							`}
								>

									{/* alert text   */}
									{(alert[0] === "success") &&
										<Alert severity="success" >
											{alert[1]}
										</Alert>
									}
									{alert[0] === "failed" &&
										<Alert severity="error" >
											{alert[1]}
										</Alert>
									}
								</div>
							}


							{!this.props.isFocusMode && !isViewOnlyMode && this.props.scenario !== "coPilotActivePageEditAnnotation" && !this.props.displayReference && (
								<div className='d-flex justify-content-center pt-3'>{saveButtonUI}</div>
							)}

						</div>





						{
							this.state.canvasContextMenu.isOpen &&
							<CanvasContextMenu
								open={this.state.canvasContextMenu.isOpen}
								handleRemovePointClick={() => {
									this.handleCanvasVertexRemove(this.state.canvasContextMenu.event);
									this.updateCanvasContextMenuState({ isOpen: false })
								}}
								handleModifyAnnotationFromThisPointClick={() => {
									this.handleCanvasVertexExtendInAnnotation(this.state.canvasContextMenu.event)
									this.updateCanvasContextMenuState({ isOpen: false })
								}}
								handleViewAnnotation={() => {
									this.handleViewAnnotation(this.state.canvasContextMenu.event);
									this.updateCanvasContextMenuState({ isOpen: false })
								}}
								xCoordinate={this.state.canvasContextMenu.xCoordinate}
								yCoordinate={this.state.canvasContextMenu.yCoordinate}
								handleOnClose={() => this.updateCanvasContextMenuState({ isOpen: false })}
							/>
						}

					</div>
				</TwoDimensionalImageContext.Provider>
			</I18nextProvider>
		);
	}
}

TwoDimensionalImage.propTypes = {
	showPrevArrow:PropTypes.bool,
	showNextArrow:PropTypes.bool,
	onSwitch:PropTypes.func,
	className: PropTypes.string,
	url: PropTypes.string,
	imageWidth: PropTypes.number,
	allowUserToSetAnyLabelForImageAnnotation: PropTypes.bool,
	defaultAnnotations: PropTypes.arrayOf(PropTypes.object),
	defaultAnnotationGroups: PropTypes.arrayOf(PropTypes.object),
	isDynamicOptionsEnable: PropTypes.bool,
	disabledOptionLevels: PropTypes.arrayOf(PropTypes.string),
	emptyAnnotationReminderText: PropTypes.string,
	isViewOnlyMode: PropTypes.bool,
	hasPreviousButton: PropTypes.bool,
	hasNextButton: PropTypes.bool,
	hasSkipButton: PropTypes.bool,
	hasSaveButton: PropTypes.bool,
	canShowAddAnnotationButton: PropTypes.bool,
	onPreviousClick: PropTypes.func,
	onAnnotationsChange: PropTypes.func,
	onSkipClick: PropTypes.func,
	onNextClick: PropTypes.func,
	onSaveClick: PropTypes.func,
	isLabelOn: PropTypes.bool,
	options: PropTypes.shape({
		id: PropTypes.string,
		value: PropTypes.string,
		children: PropTypes.array,
	}),
	ResourceRemarksSection: PropTypes.element,
	scenario: PropTypes.string,
	modelType: PropTypes.string,
	fullscreenButton: PropTypes.element,
	ActionsSection: PropTypes.element,
	alert: PropTypes.arrayOf(PropTypes.object),
	fullScreenMode: PropTypes.bool,
	onClickFullScreenMode: PropTypes.func,
	key: PropTypes.string,
	isExpandFeatureEnabled: PropTypes.bool,
	settingManuallyCanvasWidth: PropTypes.number || undefined,
	isShowingCompressedImageBecauseOriginalImageIsDownloading: PropTypes.bool,
	resourceId: PropTypes.string,
	isZoomFeatureEnabled: PropTypes.bool,
	shouldShowMouseAnnotationCoordinates: PropTypes.bool,
	originalImageWidth: PropTypes.number,
	originalImageHeight: PropTypes.number,
	maxImageHeightAllowed: PropTypes.number,
	referenceDataComponent: PropTypes.element,
	isFocusMode: PropTypes.bool,
	canvasStageHeight: PropTypes.number,
	viewReferencesSwitchComponent: PropTypes.element,
	focusModeComponent: PropTypes.element,
	referenceGroups: PropTypes.arrayOf(PropTypes.referenceGroup)
};
TwoDimensionalImage.defaultProps = {
	showPrevArrow:false,
	showNextArrow:false,
    onSwitch: (val)=>{},
	isShowingCompressedImageBecauseOriginalImageIsDownloading: false,
	onAnnotationsChange: null,
	className: '',
	url: '',
	imageWidth: 400,
	allowUserToSetAnyLabelForImageAnnotation: false,
	canvasBorderTimerAnimationStyle: null,
	defaultAnnotations: [],
	defaultAnnotationGroups: [],
	options: {},
	isDynamicOptionsEnable: false,
	disabledOptionLevels: [],
	isLabelOn: false,
	isViewOnlyMode: false,
	emptyAnnotationReminderText: '',
	hasPreviousButton: false,
	hasNextButton: false,
	hasSkipButton: false,
	hasSaveButton: false,
	canShowAddAnnotationButton: true,
	ResourceRemarksSection: undefined,
	onPreviousClick: () => { },
	onSkipClick: () => { },
	onSaveClick: () => { },
	onNextClick: () => { },
	fullscreenButton: undefined,
	ActionsSection: undefined,
	modelType: "",
	alert: ["", ""],
	fullScreenMode: false,
	onClickFullScreenMode: () => { },
	key: "",
	isExpandFeatureEnabled: false,
	settingManuallyCanvasWidth: undefined,
	isFocusMode: false,
	canvasStageHeight: 0,
	viewReferencesSwitchComponent: undefined,
	focusModeComponent: undefined,
	referenceGroups: []
};
export default React.memo(TwoDimensionalImage);

import React, { useEffect, useMemo, useState } from "react";
import Button from "@material-ui/core/Button";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogContentText from "@material-ui/core/DialogContentText";
import DialogTitle from "@material-ui/core/DialogTitle";
import useMediaQuery from "@material-ui/core/useMediaQuery";
import { useTheme } from "@material-ui/core/styles";
import { Autocomplete } from "@material-ui/lab";
import { ICollection } from "../../../../../../../../../../../common/constants/interfaces/collections";
import { DataService } from "../../../../../../../../../../../services";
import { InputFieldState } from "../../../../../../../../../../../common/constants/interfaces/inputFieldState";
import { Chip, makeStyles, TextField, Tooltip } from "@material-ui/core";
import { connect, ConnectedProps } from "react-redux";
import { IReduxState } from "../../../../../../../../../../../common/constants/interfaces/redux";
import {
  addDataSetCollectionInDataSetCollectionsFilter,
  addResourcesToDataSetCollection,
  setDataSetCollectionsInResource,
  updateResourceWhichAreBeingUpdatedInDB,
} from "../../../../../../../../../../../store/actions/dataActions";
import { DataSetCollectionQueryParams, ResourceDataSetCollection } from "../../../../../../../../../../../common";
import WarningIcon from '@material-ui/icons/Warning';

const useStyles = makeStyles((theme) => ({
  chip: {
    margin: theme.spacing(0.5),
    maxWidth: "30px",
  },
  autoCompleteChipRoot: {
    maxWidth: "170px",
  },
  floating: {
    "& legend": {
      float: "unset"
    }
  }
}));

interface IProps extends TPropsFromRedux {
  modelId: string;
  open: boolean;
  resourceIdsSelected: string[];
  totalResourceCount?: number;
  dataSetPayload?: DataSetCollectionQueryParams;
  onClose: () => void;
  dataSetCollectionsAvailable: ResourceDataSetCollection[];
  isFetchDataSetCollectionApiExecuting: boolean;
  scenario: "editing" | "adding" | "removing";
  areAllRowsMatchingTableFilterSelectedByUserAndHasRowsNotShowingInUI?: boolean,
}

export function AddOrDeleteResourcesToDataSetCollectionSelectionPopup(
  props: IProps
) {
  const { open, resourceIdsSelected, scenario, totalResourceCount } = props;
  const classes = useStyles();
  const theme = useTheme();
  const fullScreen = useMediaQuery(theme.breakpoints.down("sm"));
  const [dataSetCollectionsFieldState, setDataSetCollectionsFieldState] =
    useState<InputFieldState<string[]>>({ value: [] });

  const [confirmButtonClickProcessHappening, setConfirmButtonClickProcessHappening] = useState(false);

  useEffect(() => {
    if (scenario === "editing") {
      for (const resource of props.resources) {
        if (resource._id.toString() === resourceIdsSelected[0].toString())
          setDataSetCollectionsFieldState((oldState) => {
            return {
              ...oldState,
              value: [
                ...oldState.value,
                ...resource.dataSetCollections.map(
                  (dataSetCollection) => dataSetCollection._id
                ),
              ],
            };
          });
      }
    }
  }, [scenario, props.resources]);

  useEffect(()=>{
    let anyValueContainsForwardSlash = false;
    const dataSetCollectionsToUpdate = dataSetCollectionsFieldState.value;

    if (dataSetCollectionsToUpdate?.length>0) {
      for (let index = 0; index < dataSetCollectionsToUpdate.length; index++) {
        let dataSetCollectionNameOrId = dataSetCollectionsToUpdate[index];
        if (dataSetCollectionNameOrId.indexOf("/") !== -1) {
          anyValueContainsForwardSlash = true;
          dataSetCollectionNameOrId = dataSetCollectionNameOrId.replaceAll("/", "_");
          dataSetCollectionsToUpdate[index] = dataSetCollectionNameOrId;
        }
      }
    }

    if (anyValueContainsForwardSlash) {
      /**
       * "/" causes issue in nodejs path that is why converting it to "_"
       */      
      setDataSetCollectionsFieldState(oldState=>({
        ...oldState,
        value: [...dataSetCollectionsToUpdate]
      }))
    }

  }, [dataSetCollectionsFieldState.value])

  function handleDataSetCollectionInputChangeEvent(eventValue: string) {
    if (eventValue.includes(",")) {
      const values: string[] = eventValue
        .split(",")
        .map((value) => value.trim())
        .filter((value: string) => {
          if (!value) {
            return false;
          }
          return true;
        });

      if (values.length > 0) {
        setDataSetCollectionsFieldState((oldState) => {
          let oldValue = oldState.value;
          for (const value of values) {
            if (!oldValue.includes(value)) {
              oldValue.push(value);
            }
          }
          return {
            ...oldState,
            isDirty: true,
            isTouched: true,
            value: [...oldValue],
          };
        });
      }
    }
  }

  const handleClose = () => {
    props.onClose();
  };

  function getDataSetCollectionsAsPerActionPayload(
    dataSetCollectionIds: string[],
    dataSetCollectionsAvailable: ResourceDataSetCollection[]
  ): ResourceDataSetCollection[] {
    const dataSetCollectionsToSend: ResourceDataSetCollection[] = [];

    for (
      let dataSetCollectionIndex = 0;
      dataSetCollectionIndex < dataSetCollectionIds.length;
      dataSetCollectionIndex++
    ) {
      const dataSetCollectionId = dataSetCollectionIds[dataSetCollectionIndex];
      for (const dataSetCollection of dataSetCollectionsAvailable) {
        if (
          dataSetCollection._id.toString() === dataSetCollectionId.toString()
        ) {
          dataSetCollectionsToSend.push({
            _id: dataSetCollection._id.toString(),
            name: dataSetCollection.name,
          });
        }
      }
    }

    return dataSetCollectionsToSend;
  }

  function getUpdatedNewDataSetCollectionIdsInInputField(
    newDataSetCollections: ResourceDataSetCollection[],
    fieldValue: string[]
  ): string[] {
    const oldValue = fieldValue;

    for (const newDataSetCollection of newDataSetCollections) {
      for (let index = 0; index < oldValue.length; index++) {
        let dataSetCollectionId = oldValue[index];
        if (
          dataSetCollectionId.toString() ===
          newDataSetCollection.name.toString()
        ) {
          dataSetCollectionId = newDataSetCollection._id;
          oldValue[index] = dataSetCollectionId;
        }
      }
    }

    return oldValue;
  }

  function getDataSetCollectionsWhichAreNotPresentInDB(
    dataSetCollectionsAvailableInDB: ResourceDataSetCollection[],
    dataSetCollectionIdsToCheck: string[]
  ): string[] {
    const dataSetCollectionsNotPresentInDB: string[] = [];

    for (const dataSetCollectionIdToCheck of dataSetCollectionIdsToCheck) {
      if (
        !isDataSetCollectionIdPresent(
          dataSetCollectionIdToCheck,
          dataSetCollectionsAvailableInDB
        )
      ) {
        if (
          dataSetCollectionsNotPresentInDB.indexOf(
            dataSetCollectionIdToCheck
          ) === -1
        ) {
          dataSetCollectionsNotPresentInDB.push(dataSetCollectionIdToCheck);
        }
      }
    }

    return dataSetCollectionsNotPresentInDB;
  }

  function arrayContainsElementId(array: ResourceDataSetCollection[], id: string): boolean {
    for (const element of array) {
      if (element._id.toString() === id.toString()) {
        return true;
      }
    }
    return false;
  }

  function removeDuplicateElements(array: ResourceDataSetCollection[]): ResourceDataSetCollection[] {
    let arrayUniqueElements: ResourceDataSetCollection[] = [];

    for (const element of array) {
      if (!arrayContainsElementId(arrayUniqueElements, element._id.toString())) {
        arrayUniqueElements.push(element)
      }
    }

    return arrayUniqueElements;
  }

  async function handleConfirmButtonClicked() {
    setConfirmButtonClickProcessHappening(true);
    const dataSetCollectionsNamesNotPresentInDB =
      getDataSetCollectionsWhichAreNotPresentInDB(
        props.dataSetCollectionsAvailable,
        dataSetCollectionsFieldState.value
      );
    let updatedValueToSetInFieldState: string[] = [];
    let updatedValueToSetInAvailableDataSetCollections: ResourceDataSetCollection[] = [];

    if (dataSetCollectionsNamesNotPresentInDB.length > 0) {
      const newDataSetCollections: ICollection[] = [];

      for (
        let dataSetCollectionIndex = 0;
        dataSetCollectionIndex < dataSetCollectionsNamesNotPresentInDB.length;
        dataSetCollectionIndex++
      ) {
        const dataSetCollectionName =
          dataSetCollectionsNamesNotPresentInDB[dataSetCollectionIndex];
        try {
          const apiResponse = await DataService.addCollection({
            name: dataSetCollectionName,
            description: "",
            model: props.modelId,
          });
          props.addDataSetCollectionInDataSetCollectionsFilter(
            {dataSetCollectionToAdd: apiResponse.data}
          )
          newDataSetCollections.push(apiResponse.data);
        } catch (error: any) {
          alert(error.response.data);
        }
      }
      if (newDataSetCollections.length > 0) {
        updatedValueToSetInAvailableDataSetCollections = [
          ...props.dataSetCollectionsAvailable,
          ...newDataSetCollections,
        ];
        updatedValueToSetInAvailableDataSetCollections = removeDuplicateElements(
          updatedValueToSetInAvailableDataSetCollections
        );
      }
      updatedValueToSetInFieldState =
        getUpdatedNewDataSetCollectionIdsInInputField(
          newDataSetCollections,
          dataSetCollectionsFieldState.value
        );
      setDataSetCollectionsFieldState((oldState) => {
        return {
          ...oldState,
          value: [...updatedValueToSetInFieldState],
        };
      });
    }

    if (updatedValueToSetInFieldState.length === 0) {
      updatedValueToSetInFieldState = dataSetCollectionsFieldState.value;
    }

    if (updatedValueToSetInAvailableDataSetCollections.length === 0) {
      updatedValueToSetInAvailableDataSetCollections =
        props.dataSetCollectionsAvailable;
    }

    if (scenario === "adding") {
      const payloadForAddResoucesToCollection = props.areAllRowsMatchingTableFilterSelectedByUserAndHasRowsNotShowingInUI ?
        {
          dataSetCollections: getDataSetCollectionsAsPerActionPayload(
            updatedValueToSetInFieldState,
            updatedValueToSetInAvailableDataSetCollections
          ),
          addToDataSetCollectionQueryParams: props.dataSetPayload
        } : {
          resourceIds: resourceIdsSelected,
          dataSetCollections: getDataSetCollectionsAsPerActionPayload(
            updatedValueToSetInFieldState,
            updatedValueToSetInAvailableDataSetCollections
          ),
        }
      if (props.areAllRowsMatchingTableFilterSelectedByUserAndHasRowsNotShowingInUI) {
        props.updateResourceWhichAreBeingUpdatedInDB(resourceIdsSelected)
      }
      props.addResourcesToDataSetCollection(payloadForAddResoucesToCollection);
    } else if (scenario === "editing") {
      props.setDataSetCollectionsInResource({
        resourceId: resourceIdsSelected[0],
        dataSetCollections: getDataSetCollectionsAsPerActionPayload(
          updatedValueToSetInFieldState,
          updatedValueToSetInAvailableDataSetCollections
        ),
      });
    }

    setConfirmButtonClickProcessHappening(false);
    props.onClose();
  }

  return (
    <div>
      <Dialog
        fullScreen={fullScreen}
        open={open}
        onClose={handleClose}
        maxWidth="sm"
        fullWidth={true}
        aria-labelledby="responsive-dialog-title"
      >
        <DialogTitle id="responsive-dialog-title">
          {scenario === 'editing'
            ? "Manage Resource's Data-Set Collections"
            : "Add Resources to Data-Set Collections"}
        </DialogTitle>
        <DialogContent>
          <DialogContentText>
            {scenario === 'editing'
              ? `Add or remove data-set collections of this resource`
              : `Select data-set collections to add in selected resources`}
          </DialogContentText>

          {
            props.areAllRowsMatchingTableFilterSelectedByUserAndHasRowsNotShowingInUI && resourceIdsSelected.length>1 &&
            <DialogContentText style={{ display: "flex", fontStyle: "italic", color: "darkorange" }}>
                <WarningIcon fontSize="small" />&nbsp;
                {
                  totalResourceCount && totalResourceCount < 50000 ? `This action will affect all ${totalResourceCount ? totalResourceCount : resourceIdsSelected.length} data matching table filter.` : `This action will affect first 50000 data matching table filter.`
                }
            </DialogContentText>
          }
          <div>
            <Autocomplete
              multiple
              id="data-set collections"
              freeSolo
              options={props.dataSetCollectionsAvailable.map(
                (collection) => collection._id
              )}
              value={dataSetCollectionsFieldState.value}
              getOptionLabel={(collectionId) =>
                props.dataSetCollectionsAvailable.filter(
                  (collection) =>
                    collection._id.toString() === collectionId.toString()
                )[0]?.name
              }
              loading={props.isFetchDataSetCollectionApiExecuting ? true : false}
              loadingText={
                props.isFetchDataSetCollectionApiExecuting ? "loading" : ""
              }
              onChange={(event, value) => {
                setDataSetCollectionsFieldState((oldState) => {
                  return {
                    ...oldState,
                    isDirty: true,
                    isTouched: true,
                    value: [...value],
                  };
                });
              }}
              renderTags={(value, getTagProps) => {
                return value.map((option, index) => (
                  <Chip
                    variant="outlined"
                    color="primary"
                    className={`${classes.chip}`}
                    classes={{ root: classes.autoCompleteChipRoot }}
                    label={
                      <Tooltip 
                        title={
                          <DataSetCollectionName 
                            dataSetCollectionId={option}
                            dataSetCollections={props.dataSetCollectionsAvailable}
                          />
                        }
                      >
                        <span>
                          {index + 1}:
                          {props.dataSetCollectionsAvailable.filter(
                            (collection) =>
                              collection._id.toString() === option.toString()
                          )[0]?.name || option}
                        </span>
                      </Tooltip>
                    }
                    {...getTagProps({ index })}
                  />
                ));
              }}
              renderInput={(params) => (
                <TextField
                  className={classes.floating}
                  {...params}
                  variant="outlined"
                  size="small"
                  margin="normal"
                  fullWidth
                  label="Data-Set Collections"
                  placeholder="Add Data-Set Collections"
                  onChange={(e) => {
                    handleDataSetCollectionInputChangeEvent(e.target.value);
                  }}
                  onBlur={(e) => {
                    handleDataSetCollectionInputChangeEvent(
                      e.target.value + ","
                    );
                  }}
                  error={
                    dataSetCollectionsFieldState.isTouched &&
                    dataSetCollectionsFieldState.errorMessage
                      ? true
                      : false
                  }
                  helperText={
                    dataSetCollectionsFieldState.isTouched &&
                    dataSetCollectionsFieldState.errorMessage
                      ? dataSetCollectionsFieldState.errorMessage
                      : "Add multiple data-set collections with <enter> or <comma>"
                  }
                />
              )}
            />
          </div>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose} color="primary">
            Cancel
          </Button>
          <Button
            onClick={() => handleConfirmButtonClicked()}
            disabled={!dataSetCollectionsFieldState.isDirty || confirmButtonClickProcessHappening}
            color="primary"
            variant="contained"
          >
            OK
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
}

const mapProps = (state: IReduxState) => {
  return {
    resources: state.data.data.resources,
  };
};

const connector = connect(mapProps, {
  addResourcesToDataSetCollection,
  setDataSetCollectionsInResource,
  addDataSetCollectionInDataSetCollectionsFilter,
  updateResourceWhichAreBeingUpdatedInDB
});

export type TPropsFromRedux = ConnectedProps<typeof connector>;

export default connector(AddOrDeleteResourcesToDataSetCollectionSelectionPopup);

function isDataSetCollectionIdPresent(
  dataSetCollectionIdToCheck: string,
  dataSetCollectionsAvailableInDB: ResourceDataSetCollection[]
): boolean {
  for (const dataSetCollection of dataSetCollectionsAvailableInDB) {
    if (
      dataSetCollection._id.toString() === dataSetCollectionIdToCheck.toString()
    ) {
      return true;
    }
  }
  return false;
}

function DataSetCollectionName(props: {
  dataSetCollectionId: string,
  dataSetCollections: {
    _id: string,
    name: string
  }[]
}) {
  const dataSetCollectionName = useMemo(() => {
    if (Array.isArray(props.dataSetCollections)) {
      for (const dataSetCollection of props.dataSetCollections) {
        if (dataSetCollection._id === props.dataSetCollectionId) {
          return dataSetCollection.name;
        }
      }
    }
    return "";
  }, [props.dataSetCollectionId, props.dataSetCollections]);

  return <>{dataSetCollectionName}</>;
}
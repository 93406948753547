import React, { useState, useRef, useEffect } from 'react';
import { startChatStream } from '@e-llm-studio/streaming-response';
import { v4 as uuidv4 } from 'uuid';
import { getChatHistoryAPIService, getChatSessionIdFromCookies, LLM_STUDIO_CHAT_WEB_SOCKET_URL, resetChatHistoryService } from './services/llmStudioChat.service';
import ReactMarkdown from 'react-markdown';
import useAuth from '../../../../hooks/useAuth';
import { GuestSessionIdService } from '../homePage/guestSessionIdService';

interface Message {
  text: string;
  sender: 'user' | 'assistant';
}

const ChatComponent = () => {
  const { userEmail } = useAuth()
  const [messages, setMessages] = useState<Message[]>([]);
  const [inputValue, setInputValue] = useState('');
  const [isStreaming, setIsStreaming] = useState(false);
  const [loadingDots, setLoadingDots] = useState(''); 
  const [loadingTextIndex, setLoadingTextIndex] = useState(0);

  const messageEndRef = useRef<HTMLDivElement | null>(null);

  const loadingTexts = ["Thinking", "Generating response", "Processing your request"];
  const botResponses = [
    "Hi there! How can I assist you today?",
    "I see. Can you provide more details?",
    "Got it! Here’s what I suggest.",
    "Thank you for your input. Let’s proceed to the next step.",
    "Great! Is there anything else you’d like to discuss?",
  ];
  const [responseIndex, setResponseIndex] = useState(0);

  const scrollToBottom = () => {
    messageEndRef.current?.scrollIntoView({ behavior: 'smooth' });
  };

  useEffect(() => {
    scrollToBottom();
  }, [messages]);

  useEffect(() => {
    let dotInterval: NodeJS.Timeout;
    let textInterval: NodeJS.Timeout;

    if (isStreaming) {
      dotInterval = setInterval(() => {
        setLoadingDots((prev) => (prev.length < 3 ? prev + '.' : ''));
      }, 500);

      textInterval = setInterval(() => {
        setLoadingTextIndex((prevIndex) => (prevIndex + 1) % loadingTexts.length);
      }, 1500);
    }

    return () => {
      clearInterval(dotInterval);
      clearInterval(textInterval);
      setLoadingTextIndex(0)
    };
  }, [isStreaming]);


  const fetchChatHistory = async () => {
    const chatHistory = await getChatHistoryAPIService();
    if(chatHistory){
      chatHistory.map(msgObj => {
        setMessages(prev => {
          return [...prev, { text: msgObj.content, sender: msgObj.role }]
        })
      })
    }
  }

  useEffect(() => {
    void fetchChatHistory()
  }, []);

  const handleSendMessage = () => {
    if (!inputValue.trim()) return;

    const userMessage: Message = { text: inputValue, sender: 'user' };
    setMessages((prev) => [...prev, userMessage]);
    setInputValue(''); // Clear input field

    // Prepare parameters for startChatStream
    const params = {
      WEBSOCKET_URL: LLM_STUDIO_CHAT_WEB_SOCKET_URL, // replace with actual WebSocket URL
      organizationName: 'techolution',
      chatAssistantName: 'trainingpodchat',
      selectedAIModel: 'bestai',
      replyMessage: '', // If there is no previous response, leave empty
      userName: userEmail,
      userEmailId: userEmail,
      userId: 'user-123',
      query: inputValue,
      // sessionId: getChatSessionIdFromCookies(), // Generates a unique session ID
      requestId: `requestId-${uuidv4()}`, // Generates a unique request ID
      // customPrompt: '',
      enableForBackend: false,
      // isDocumentRetrieval:false,                            // To bypass the document retrieval.
      onStreamEvent: (data: any) => {
        const msgContent = data?.chatStream?.continueChatResult?.message?.content;
        if(!msgContent.startsWith("WaitMessage:")){
        setMessages((prev) => {
          const updatedMessages = [...prev];
          if (updatedMessages[updatedMessages.length - 1].sender === 'assistant') {
            updatedMessages[updatedMessages.length - 1].text = msgContent;
          } else {
            updatedMessages.push({ text: msgContent, sender: 'assistant' });
          }
          return updatedMessages;
        });
      }
      },
      onStreamEnd: () => setIsStreaming(false),
      onError: (error: any) => console.error('Stream error:', error),
    };

    // Start the streaming process
    setIsStreaming(true);
    startChatStream(params);

    // Add an empty bot message to indicate loading
    setMessages((prev) => [...prev, { text: '', sender: 'assistant' }]);
    setIsStreaming(true);

    // Simulate delay for bot response
    // setTimeout(() => {
    //   const nextResponse = botResponses[responseIndex];
    //   setMessages((prev) => {
    //     const updatedMessages = [...prev];
    //     updatedMessages[updatedMessages.length - 1].text = nextResponse;
    //     return updatedMessages;
    //   });

    //   // Update the response index
    //   setResponseIndex((prevIndex) => (prevIndex + 1) % botResponses.length);
    //   setIsStreaming(false);
    // }, 3000); // Simulate 1 second delay
  };
  const handleClearHistory = async () => {
    await resetChatHistoryService();
    GuestSessionIdService.clearGuestSessionId();
    setMessages([]); 
    setIsStreaming(false);
    setLoadingDots('');
    setLoadingTextIndex(0);
  };
  return (
    <div style={styles.chatContainer}>
       <div style={styles.header}>
        <h2>Chat</h2>
        <button onClick={handleClearHistory} style={styles.clearButton}>
          Clear History
        </button>
      </div>
      <div style={styles.messagesContainer}>
        {messages.map((message, index) => (
          <div
            key={index}
            style={{
              ...styles.message,
              alignSelf: message.sender === 'user' ? 'flex-end' : 'flex-start',
              backgroundColor: message.sender === 'user' ? '#DCF8C6' : '#FFFFFF',
            }}
          >
            <ReactMarkdown components={{p: ({ node, ...props }) => <p style={{ margin: 0 }} {...props} />}}>
              {message.text || (message.sender === 'assistant' && isStreaming ? `${loadingTexts[loadingTextIndex]}${loadingDots}` : '')}
            </ReactMarkdown>
          </div>
        ))}
        <div ref={messageEndRef} />
      </div>
      <div style={styles.inputContainer}>
        <input
          type="text"
          value={inputValue}
          onChange={(e) => setInputValue(e.target.value)}
          onKeyDown={(e) => e.key === 'Enter' && handleSendMessage()}
          placeholder="Type a message..."
          style={styles.input}
        />
        <button onClick={handleSendMessage} style={styles.sendButton} disabled={isStreaming}>
          Send
        </button>
      </div>
    </div>
  );
};

// Inline styles
const styles = {
  customMarkdown: {
    p: {
      margin: 0,
    },
  },
  chatContainer: {
    display: 'flex',
    flexDirection: 'column' as 'column',
    height: '100vh',
    maxWidth: '600px',
    margin: '0 auto',
    border: '1px solid #ccc',
    borderRadius: '8px',
    overflow: 'hidden',
  },
  header: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    padding: '10px',
    borderBottom: '1px solid #ccc',
    backgroundColor: '#f5f5f5',
  },
  clearButton: {
    padding: '5px 10px',
    backgroundColor: '#ff4d4d',
    color: '#fff',
    border: 'none',
    borderRadius: '5px',
    cursor: 'pointer',
    outline: 'none',
  },
  messagesContainer: {
    flex: 1,
    padding: '10px',
    overflowY: 'auto' as 'auto',
    display: 'flex',
    flexDirection: 'column' as 'column',
    gap: '10px',
  },
  message: {
    maxWidth: '70%',
    padding: '10px',
    borderRadius: '10px',
    boxShadow: '0px 1px 3px rgba(0, 0, 0, 0.2)',
    wordWrap: 'break-word' as 'break-word',
  },
  inputContainer: {
    display: 'flex',
    padding: '10px',
    borderTop: '1px solid #ccc',
  },
  input: {
    flex: 1,
    padding: '10px',
    borderRadius: '20px',
    border: '1px solid #ccc',
    outline: 'none',
  },
  sendButton: {
    marginLeft: '10px',
    padding: '10px 20px',
    backgroundColor: '#007bff',
    color: '#fff',
    border: 'none',
    borderRadius: '20px',
    cursor: 'pointer',
    outline: 'none',
  },
};

export default ChatComponent;

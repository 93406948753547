import React, { useState } from 'react';
import { Box, Grid, Card, CardContent, Typography, Button, IconButton, Chip, Avatar, Link, Breadcrumbs } from '@material-ui/core';
import Skeleton from '@material-ui/lab/Skeleton';
import { ILatestModel, IPopularModel } from '../../projects/features/aiMarketPlace/aiMarketPlaceInterfaces/interfaces';
import homepageCss from '../RLEF_homepage.module.css'
import { makeStyles } from '@material-ui/core/styles';
import { loaders } from '../../../../../common';
import CircularProgress, { CircularProgressProps } from '@material-ui/core/CircularProgress';
import { generateUniqueId } from '../../../../../services/idHelperService';
// import  CircularProgress,{
//     CircularProgressProps,
//   } from '@mui/material/CircularProgress';

// function CircularProgressWithLabel(
//     props: CircularProgressProps & { value: number },
//   ) {
//     return (
//       <Box style={{ position: 'relative', display: 'inline-flex' }}>
//         <CircularProgress variant="determinate" {...props} />
//         <Box
//           style={{
//             top: 0,
//             left: 0,
//             bottom: 0,
//             right: 0,
//             position: 'absolute',
//             display: 'flex',
//             alignItems: 'center',
//             justifyContent: 'center',
//           }}
//         >
//           <Typography
//             variant="caption"
//             component="div"
//           >{`${Math.round(props.value)}%`}</Typography>
//         </Box>
//       </Box>
//     );
// }
function CircularProgressWithLabel(props: CircularProgressProps & { value: number }) {
    return (
        <Box position="relative" display="inline-flex" >
            <CircularProgress
                variant="determinate"
                value={100}
                thickness={5}
                style={{
                    color: '#E0E0E0', // Light gray for the background circle
                    position: 'absolute',
                }}
            />

            <CircularProgress variant="determinate" {...props} style={{color:'#65B168'}} thickness={5}/>
            <Box
                top={0}
                left={0}
                bottom={0}
                right={0}
                position="absolute"
                display="flex"
                alignItems="center"
                justifyContent="center"
            >
                <Typography variant="caption" component="div" style={{color:'#65B168',fontFamily:'Inter',fontWeight:700,fontSize:'11px'}} >{`${Math.round(
                    props.value,
                )}%`}</Typography>
            </Box>
        </Box>
    );
}

// Define the styles
const useStyles = makeStyles((theme) => ({
    root: {
        flexGrow: 1,
        // marginTop: theme.spacing(4),
        width: '93%'
    },
    actionButton: {
        marginTop: theme.spacing(2),
        // width: '100%',
        borderRadius: '20px',
        alignSelf: 'center',
        textTransform: 'capitalize',
        fontSize: '12px',
        color: "#64748B",
        fontWeight: 500
    },
    card: {
        display: 'flex',
        border: '1px solid #E0E0E0',
        backgroundColor: '#FFF',
        marginBottom: theme.spacing(2),
        borderRadius: '16px',
        boxShadow: '0px 4px 20px rgba(0, 0, 0, 0.1)',
        padding: 0,
        cursor:'pointer',
        overflow:'visible',
    },
    cardContent: {
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'center',
        padding: '11px',
        width: '100%',
        '&:last-child': {
            paddingBottom: '11px',
        },
    },
    purposeModelcardContent: {
        padding: '16px',
        '&:last-child': {
            paddingBottom: '16px',
        },
    },
    specificChip: {
        borderRadius: '22px',
        background: '#E9EFFA',
        padding: '3.5px 5.6px',
        color: "#2264CD",
        fontFamily:"Inter",
        fontWeight:600
    },
    generalChip: {
        borderRadius: '22px',
        padding: '3.5px 5.6px',
        border: '0px solid #59BB9E',
        background: 'rgba(89, 187, 158, 0.12)',
        color: '#59BB9E',
        fontFamily:"Inter",
        fontWeight:600
    },
    loader: {
        ...loaders.centralLoader,
        left:'50%'
    }
}));

// Define the props interface
interface HomepageModelsCardProps {
    availableModelsData: Array<IPopularModel> | Array<ILatestModel> | [];
    modelFetchStatus: Record<string, any>;
    fetchedModelResources: Record<string, any>;
    scenario?: "latestModelsData"|"popularModelsData" | "homePageChat",
    isFetching?: boolean
    handleShowMoreAIDetailsWindow?:any
}

const HomepageModelsCard: React.FC<HomepageModelsCardProps> = ({
    availableModelsData,
    modelFetchStatus,
    fetchedModelResources,
    scenario,
    isFetching,
    handleShowMoreAIDetailsWindow
}) => {
    const [popImgId,setPopImgId]=useState<string|null>(null)
    const classes = useStyles();
    const generateModelAnalyticsLink = (TrainingPodDetails: ILatestModel | IPopularModel) => {
        // @ts-ignore
        let accuracy = TrainingPodDetails?.checkpointFiles?.files?.analysis?.cachedMetrics?.accuracy?.accuracy
        const isSegmentationModelEnabled = TrainingPodDetails?.model?.isSegmentationModelAnalytics
        let url = `/admin/projects/${TrainingPodDetails?.project?.name}/${TrainingPodDetails?.model?.name}/collections/modelAnalytics/resources?model=${TrainingPodDetails?.model?._id}&project=${TrainingPodDetails?.project?._id}&limit=10&offset=1&type=${TrainingPodDetails?.model?.type}&status=dashboard&modelCollectionIdFilterSelected=${TrainingPodDetails?._id}&modelCollectionIdsToCompare=${TrainingPodDetails?._id}&scenario=integrity&accuracy=${accuracy}&sortField=predicted_status&sortOrder=ascending&bandFilterSelected=ALL&`
        if (isSegmentationModelEnabled) {
            url += `&iouThresholdValue=${TrainingPodDetails?.model?.iouThresholdValue}`
        }
        return url
    }
    if(scenario && scenario==="homePageChat" && isFetching){
        return <Box className={classes.loader}>
            <CircularProgress size={30} />
        </Box>  
    }
    const handleButtonClick = (TrainingPodDetails: IPopularModel | ILatestModel) =>{
        if(scenario==="homePageChat"){
            // @ts-ignore
            let testDataSetId = TrainingPodDetails?.checkpointFiles?.files?.analysis?.dataSetCollectionId || ''
            const metaData={
                button_text:'Model summary page',
                pageType:'summary_page',
                trainingPods:[{
                        trainingPodId:TrainingPodDetails?.purposeModel?._id || '',
                        modelCollectionId:TrainingPodDetails?._id || '',
                        testDataSetId:testDataSetId,
                        // modelDetails: 
                        training_pod_name: TrainingPodDetails?.purposeModel?.modelName || ''
                      }]

            }
            handleShowMoreAIDetailsWindow(metaData, generateUniqueId());
        }
    }

    return (
        <>
            {availableModelsData && availableModelsData.map((eachModelData: IPopularModel | ILatestModel) => (
                <Card className={`${classes.card} ${scenario==="homePageChat" ? homepageCss.chatResultCardOnHover : homepageCss[scenario === "latestModelsData" ? "latestModelCard" : "popularModelCard"]}`} style={{ width: '100%' }} key={eachModelData?.purposeModel._id} onClick={()=>handleButtonClick(eachModelData)}>
                    <CardContent className={`${classes.cardContent} ${classes.purposeModelcardContent}`} style={{paddingTop:`${scenario==='homePageChat'? '10px' : '16px'}`}} >
                        <Box display="flex" flexDirection="column" style={{ width: '100%',gap:`${scenario==="homePageChat" ? '0':'8px'}`}}>
                            <Box display="flex" alignItems="center" justifyContent="space-between">
                                <Box display="flex" alignItems="center" style={{ gap: '12px' }}>
                                    <Typography variant='body2' style={{ color: "#292E36", fontFamily: "Inter", fontWeight: 600, fontSize:`${scenario==='homePageChat' ? '1.5rem' : ''}` }}>{eachModelData?.purposeModel.modelName}</Typography>
                                    {eachModelData?.purposeModel?.purposeType === 'specific' ?
                                        <Chip label="Specific" size="small" className={classes.specificChip} />
                                        : <Chip label="General" size="small" className={classes.generalChip} />
                                    }
                                </Box>
                                <Box display="flex" justifyContent="space-between">
                                    {/* <BookmarkBorderIcon /> */}
                                    {/* {scenario==="homePageChat"?<CircularProgressWithLabel value={Number(eachModelData?.similarityScore)} /> : ''} */}
                                    &nbsp;
                                </Box>
                            </Box>
                            <Typography variant='body2' style={{fontFamily:"Inter",textAlign:'left',marginBottom:"8px" }}><span style={{ color: '#666666', fontWeight: 400,fontSize:'12px' }}>{eachModelData?.purposeModel?.aiTask || "NA"} </span><span style={{ color: '#292E36', fontWeight: 500,fontSize:'12px' }}>({eachModelData?.purposeModel?.problemType || "NA"})</span></Typography>
                            <Typography variant='body2' className={homepageCss.ellipsisText} style={{textAlign:"left",marginBottom:'10px'}}><span style={{ color: '#64748B', fontWeight: 400,fontSize:'14px' }}>{"Description:"} </span> <span style={{ color: '#292E36', fontWeight: 600,fontSize:'14px' }}>{eachModelData?.purposeModel?.modelDescription} </span></Typography>
                            
                            <hr style={{width:'100%',marginTop:'0',marginBottom:'10px',backgroundColor:'#E8E8E8'}}/>
                            
                            {scenario === 'homePageChat' &&
                                <Box display="flex" justifyContent="space-between" alignItems="center">
                                    {eachModelData?.purposeModel?.purposeType === 'specific' ? <Box>
                                        <Box display="flex" justifyContent="space-between">
                                            <Typography variant="body2" style={{ color: "#64748B", fontSize: '14px',fontFamily:'Inter',fontWeight:400 }}>
                                                Test dataset
                                            </Typography>
                                            <Typography variant="body2" style={{ fontFamily:'Inter',fontSize: '12px',color:'#008D9C' ,fontWeight: 600, textAlign: 'right' }}>
                                                {/* <Link href={generateModelAnalyticsLink(eachModelData)} target="_blank" rel="noopener" >
                                                   {'View more >'}
                                                  
                                                </Link> */}
                                                 &nbsp;
                                            </Typography>
                                        </Box>
                                        {Object.keys(modelFetchStatus[eachModelData.purposeModel?._id] || {}).length === 0 && Object.keys(fetchedModelResources[eachModelData.purposeModel?._id] || {}).length === 0 ? (
                                            <Typography>No resources available</Typography>
                                        ) : (
                                            <Box display="flex" mt={1} style={{ gap: '0.5rem', backgroundColor: '#F3F3F3', borderRadius: '8px' }} p={1}>
                                                {/* Display loader or images */}
                                                {Object.entries(modelFetchStatus[eachModelData.purposeModel?._id] || {}).slice(0, 4).map(([resourceId, isFetching]) => (
                                                    isFetching ? (
                                                        <Skeleton key={resourceId} variant="rect" width='50px' height= '50px' style={{borderRadius: "8px"}}/>
                                                    ) : (
                                                        fetchedModelResources[eachModelData.purposeModel?._id]?.[resourceId] && (
                                                                <div style={{ width: '50px', height: '50px', borderRadius: '8px',position:'relative'}}>
                                                                    {
                                                                        popImgId===resourceId && <div style={{position:'absolute',height:'120px',width:'120px',bottom:'calc(100% + 16px)',left:'0',borderRadius:'8px'}}>
                                                                        <img key={resourceId} src={fetchedModelResources[eachModelData.purposeModel?._id][resourceId].resource} alt="Resource" style={{ width: '100%', height: '100%', borderRadius: '8px'}} />
                                                                        
                                                                    </div>
                                                                    }
                                                                    <img key={resourceId} src={fetchedModelResources[eachModelData.purposeModel?._id][resourceId].resource} alt="Resource" style={{ width: '100%', height: '100%', borderRadius: '8px' }} onMouseEnter={()=>{setPopImgId(resourceId)}} onMouseLeave={()=>setPopImgId(null)}/>
                                                                </div>
                                                        )
                                                    )
                                                ))}
                                            </Box>
                                        )}
                                    </Box>:<Box>&nbsp;</Box>}
                                    <Box display="flex" justifyContent="space-between" style={{ border:'1px solid #ABD0D6',padding:'.5rem',justifyContent:'space-evenly',gap:'1rem',borderRadius:'4px',backgroundColor:'#F2F6F7'}}>
                                        <Box display="flex" flexDirection="column" justifyContent="center" style={{ width: '60px',backgroundColor: 'transparent',flex:1 }}>
                                            <Typography variant='body2' style={{ color: "#CA9F08", textAlign: 'center', fontWeight: 600,fontFamily: 'Inter',fontSize:'1rem' }}>{eachModelData?.purposeModel?.clonesCount || "0"}</Typography>
                                            <Typography variant='body2' style={{ color: "#558E98", textAlign: 'center',fontFamily: 'Inter',fontWeight:300 }}>Forks</Typography>

                                        </Box>
                                        <Box display="flex" flexDirection="column" justifyContent="center" style={{ width:'64px', backgroundColor: 'transparent',flex:1}}>
                                            {eachModelData?.purposeModel?.purposeType === 'specific' ?
                                                // @ts-ignore 
                                                <Typography variant='body2'  style={{ textAlign: 'center', fontWeight: 600 ,color:'#045461',fontFamily: 'Inter',fontSize:'1rem' }}>{Number.isInteger(eachModelData?.checkpointFiles?.files?.analysis?.cachedMetrics?.accuracy?.accuracy) ? eachModelData.checkpointFiles.files.analysis.cachedMetrics.accuracy.accuracy : eachModelData.checkpointFiles.files.analysis.cachedMetrics.accuracy.accuracy.toFixed(2)}%</Typography>
                                                :
                                                <Typography variant='body2'  style={{ textAlign: 'center', fontWeight: 600 ,color:'#045461',fontFamily: 'Inter',fontSize:'1rem'  }}>-</Typography>
                                            }
                                            <Typography variant='body2'  style={{ textAlign: 'center',color:'#558E98',fontFamily: 'Inter',fontWeight:300  }}>Accuracy</Typography>

                                        </Box>
                                        <Box display="flex" flexDirection="column" justifyContent="center" style={{ width:'64px', backgroundColor:'transparent',flex:1 }}>
                                            {eachModelData?.purposeModel?.purposeType === 'specific' ?
                                                <Typography variant='body2' style={{ textAlign: 'center', fontWeight: 600,color:'#045461',fontFamily: 'Inter',fontSize:'1rem' }}>
                                                    {// @ts-ignore 
                                                        eachModelData?.checkpointFiles?.files?.analysis?.cachedMetrics?.integrity?.integrity !== undefined ? (Number.isInteger(eachModelData.checkpointFiles.files.analysis.cachedMetrics.integrity.integrity) ? eachModelData.checkpointFiles.files.analysis.cachedMetrics.integrity.integrity : eachModelData.checkpointFiles.files.analysis.cachedMetrics.integrity.integrity.toFixed(2)) + "%" : "NA"}
                                                </Typography>
                                                :
                                                <Typography variant='body2' style={{ textAlign: 'center', fontWeight: 600 ,color:'#045461',fontFamily: 'Inter',fontSize:'1rem'}}>-</Typography>
                                            }
                                            <Typography variant='body2' style={{ textAlign: 'center',color:'#558E98',fontFamily: 'Inter',fontWeight:300 }}>Integrity</Typography>

                                        </Box>
                                    </Box>
                                </Box>
                            }

                            {scenario !== 'homePageChat' && <Box display="flex" justifyContent="space-between" style={{ fontFamily: 'Inter' }}>
                                {scenario!=="latestModelsData" && <Box display="flex" flexDirection="column" justifyContent="center" style={{ width: '30%', backgroundColor: '#FBF3D9', borderRadius: '8px', padding: '4px 0px' }}>
                                    <Typography variant='body2' style={{ color: "#CFA100", textAlign: 'center', fontWeight: 600 }}>{eachModelData?.purposeModel?.clonesCount || "0"}</Typography>
                                    <Typography variant='body2' style={{ color: "#CFA100", textAlign: 'center' }}>Forks</Typography>

                                </Box>}
                                <Box display="flex" flexDirection="column" justifyContent="center" style={{ width: scenario === "latestModelsData" ? '40%' :'30%', backgroundColor: '#F3F3F3', borderRadius: '8px', padding: '4px 0px' }}>
                                    {eachModelData?.purposeModel?.purposeType === 'specific' ?
                                        // @ts-ignore 
                                        <Typography variant='body2'  color="primary" style={{ textAlign: 'center', fontWeight: 600 }}>{Number.isInteger(eachModelData?.checkpointFiles?.files?.analysis?.cachedMetrics?.accuracy?.accuracy) ? eachModelData.checkpointFiles.files.analysis.cachedMetrics.accuracy.accuracy : eachModelData.checkpointFiles.files.analysis.cachedMetrics.accuracy.accuracy.toFixed(2)}%</Typography>
                                        :
                                        <Typography variant='body2'  color="primary" style={{ textAlign: 'center', fontWeight: 600 }}>-</Typography>
                                    }
                                    <Typography variant='body2' color="primary" style={{textAlign: 'center' }}>Accuracy</Typography>

                                </Box>
                                <Box display="flex" flexDirection="column" justifyContent="center" style={{ width: scenario === "latestModelsData" ? '40%' :'30%', backgroundColor: '#F3F3F3', borderRadius: '8px', padding: '4px 0px' }}>
                                    {eachModelData?.purposeModel?.purposeType === 'specific' ?
                                        <Typography variant='body2' color="primary" style={{ textAlign: 'center', fontWeight: 600 }}>
                                            {// @ts-ignore 
                                                eachModelData?.checkpointFiles?.files?.analysis?.cachedMetrics?.integrity?.integrity !== undefined ? (Number.isInteger(eachModelData.checkpointFiles.files.analysis.cachedMetrics.integrity.integrity) ? eachModelData.checkpointFiles.files.analysis.cachedMetrics.integrity.integrity : eachModelData.checkpointFiles.files.analysis.cachedMetrics.integrity.integrity.toFixed(2)) + "%" : "NA"}
                                        </Typography>
                                        :
                                        <Typography variant='body2' color="primary" style={{ textAlign: 'center', fontWeight: 600 }}>-</Typography>
                                    }
                                    <Typography variant='body2' color="primary" style={{ textAlign: 'center' }}>Integrity</Typography>

                                </Box>
                            </Box>}

                            

                            {scenario !== 'homePageChat' && eachModelData?.purposeModel?.purposeType === 'specific' && <Box>
                                <Box display="flex" justifyContent="space-between">
                                    <Typography variant="body2" style={{ color: "#838C95", fontSize: '12px' }}>
                                        Test dataset
                                    </Typography>
                                    <Typography variant="body2" color="primary" style={{ fontSize: '12px', fontWeight: 600, textAlign: 'right' }}>
                                        <Link href={generateModelAnalyticsLink(eachModelData)} target="_blank" rel="noopener" >
                                            View more
                                        </Link>
                                    </Typography>
                                </Box>
                                {Object.keys(modelFetchStatus[eachModelData.purposeModel?._id] || {}).length === 0 && Object.keys(fetchedModelResources[eachModelData.purposeModel?._id] || {}).length === 0 ? (
                                    <Typography>No resources available</Typography>
                                ) : (
                                    <Box display="flex" mt={1} style={{ gap: '0.5rem', backgroundColor: '#F3F3F3', overflow: 'hidden', borderRadius: '8px' }} p={1}>
                                        {/* Display loader or images */}
                                        {Object.entries(modelFetchStatus[eachModelData.purposeModel?._id] || {}).map(([resourceId, isFetching]) => (
                                            isFetching ? (
                                                <Skeleton key={resourceId} variant="rect" width="20%" height={50} style={{borderRadius: "8px"}}/>
                                            ) : (
                                                fetchedModelResources[eachModelData.purposeModel?._id]?.[resourceId] && (
                                                    <img key={resourceId} src={fetchedModelResources[eachModelData.purposeModel?._id][resourceId].resource} alt="Resource" style={{ width: '50px', height: '50px', borderRadius: "8px" }} />
                                                )
                                            )
                                        ))}
                                    </Box>
                                )}
                            </Box>}
                        </Box>
                    </CardContent>
                </Card>
            ))}
            {availableModelsData && availableModelsData.length > 3 && scenario==="latestModelsData"||scenario==="popularModelsData" &&<Box display="flex" justifyContent="center">
                <Button variant="outlined" className={classes.actionButton} size='small'>View All</Button>
            </Box>}
            {availableModelsData && availableModelsData.length == 0 && <Typography variant='body2'>No Data</Typography>}
        </>
    );
};

export default HomepageModelsCard;


import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Paper from '@material-ui/core/Paper';
import { Button, Typography } from '@material-ui/core';
import { getParsedCsvStringDataAPIResponseType } from '../../../../../../../../../../../../services/DataService';


type ShowCsvStringInTablePropsType={
    handleCloseShowCsvInModalTable:()=>void,
    apiResponseAfterCsvStringParse:getParsedCsvStringDataAPIResponseType
}

const ShowCsvStringInTable = (props:ShowCsvStringInTablePropsType) => {
   
    const {columns,rows}=props?.apiResponseAfterCsvStringParse


    return <div style={{ position: 'absolute', top: '50%', left: '50%', transform: 'translate(-50%,-50%)', backgroundColor: 'white',padding:"16px",display:"flex",flexDirection:"column",borderRadius:"8px",maxHeight:"500px",maxWidth:"90%"}}>
        <h3>Metadata</h3>
        <RenderCsvDataInTable columns={columns} rows={rows}/>
        <div style={{display:"flex",justifyContent:"flex-end",marginTop:"16px"}}>
            <Button variant="contained" color="primary" style={{textTransform:"none"}} onClick={props.handleCloseShowCsvInModalTable}>Close</Button>
        </div>
    </div>
}

export default ShowCsvStringInTable

export function RenderCsvDataInTable(props:{columns:{colName:string}[],rows:{[key:string]:string}[]}) {

  return (
    <TableContainer component={Paper}>
      <Table style={{minWidth:'400px'}} size="small" aria-label="a dense table">
        <TableHead>
          <TableRow>
            {props?.columns?.map(col=><TableCell align="left">{col?.colName}</TableCell>)}
          </TableRow>
        </TableHead>
        <TableBody>
          {props?.rows?.map((row,index) => (
            <TableRow key={index}>
                {props?.columns?.map(col=><TableCell align="left">{row[col?.colName]}</TableCell>)}
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </TableContainer>
  );
}

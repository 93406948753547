import { API } from "./API";

class DatacartService extends API {

    async sendImageForAnnotating(payload : {
        imageDownloadUrl: string,
        fileName: string,
        gcsBucketName: string
        imageAnnotations: any[]
    }) {
        const response = await this.api.post('datacart/sendImageForAnnotating', payload)
        return response.data
    }
}

export default new DatacartService()
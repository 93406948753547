import { useEffect, useState } from "react";
import { Box, CircularProgress, FormControl, Grid, makeStyles, TextField, Typography } from "@material-ui/core";
// import { useForm } from "react-hook-form";

import { AddProjectSVG, CustomButton, IReduxState } from "../../../../../common";
import { connect, ConnectedProps } from "react-redux";
import { addProject, getProjects } from "../../../../../store/actions";
// import TextareaAutosize from '@material-ui/core/TextareaAutosize';
// import { CheckSharp } from "@material-ui/icons";
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
// import Slide from '@material-ui/core/Slide';
// import { TransitionProps } from '@material-ui/core/transitions';
import Button from '@material-ui/core/Button';
import ErrorOutlineIcon from '@material-ui/icons/ErrorOutline';
import Switch from '@material-ui/core/Switch';
import FormControlLabel from '@material-ui/core/FormControlLabel';
// import Preview from "../../../../../assets/components/react-annotation-tool/src/apps/two-dimensional-video/components/Preview/Preview";
// import { generateUniqueId } from "../../../../../services/idHelperService";
import { ProjectService } from "../../../../../services";
import { encryptGcpTextHelper } from "../../../../../services/gcpTextEncryptService";
import { CLOUD_TYPE } from "../../../../../services/constants";


const useStyles = makeStyles((theme) => ({
    root: {
        marginTop: 'auto',
        marginBottom: 'auto',
    },
    illustration: {
        width: '150px',
        height: '150px',
    },
    form: {
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
    },
    formControl: {
        minWidth: 300,
        maxWidth: 300,
        "& legend": {
            float: "unset"
        }
    },
    center: {
        justifyContent: 'center'
    },
    labelPlacement:{
        margin: '10px 0px'
    }
}));

const AddProject = (props: TPropsFromRedux) => {
    // const { addProject, isAdding, isAddSuccess } = props;
    // const { register, handleSubmit, reset,getValues,resetField, formState , formState: { errors , isSubmitSuccessful } } = useForm<IProjectAddPayload>();
    const classes = useStyles();
    const [apiErrorMessage, setApiErrorMessage] = useState<string>("");
    const [publicKey, setPublicKey] = useState<string>("");
    const [dialogPopupState, setDialogPopupState] = useState<boolean>(false);
    const [userWantsToUseOurGCP, setUserWantsToUseOurGCP] = useState<boolean>(true);
    const [name, setName] = useState<string>("");
    const [nameIsDirty, setNameIsDirty] = useState<boolean>(false);
    const [keyDataIsDirty, setKeyDataIsDirty] = useState<boolean>(false);
    const [ keyData, setKeyData] = useState<string>("")
    const [id, setId] = useState<string>("")
    const [isAdding, setIsAdding] = useState<boolean>(false);
    const onSubmit = async(e:any): Promise<void> => {
        // setDialogPopupState(true)
        e.preventDefault();
        // console.log("data is ::: ",e, name, keyData, userWantsToUseOurGCP);
        // const id = generateUniqueId()
        let data;
        if(userWantsToUseOurGCP === false){
            if(Boolean(name.length === 0)){
                if(Boolean(!checkJson(keyData)) || keyData.length === 0){
                    setKeyDataIsDirty(true);
                    setNameIsDirty(true);
                }else{
                    setNameIsDirty(true);
                }
            }
            else if(Boolean(!checkJson(keyData)) || keyData.length === 0){
                if(Boolean(name.length === 0)){
                    setKeyDataIsDirty(true);
                    setNameIsDirty(true);
                }else{
                    setKeyDataIsDirty(true);
                }
        }}
        if(userWantsToUseOurGCP){
            data = {
                name : name,
                userWantsToUseOurGCP : userWantsToUseOurGCP,
            }
        }
        else{
            data = {
                name : name,
                userWantsToUseOurGCP : userWantsToUseOurGCP,
                // keyData : keyData,
                // id : id,
            }
        }
        let apiResponse
        try{
            setIsAdding(true);
            if( publicKey && ((userWantsToUseOurGCP && name.length !== 0) || (!userWantsToUseOurGCP && name.length !== 0 && keyData.length !== 0 && checkJson(keyData) === true)))
            {
                if(!data.userWantsToUseOurGCP){
                    // data.keyData = encryptGcpTextHelper(data.keyData, publicKey) 
                    // data.keyData = encryptGcpTextHelper(data.keyData, publicKey) 

                    // @ts-ignore
                    data.decodedKeyData = JSON.parse(keyData);
                }
                apiResponse = await ProjectService.addProject(data);  
                setNameIsDirty(false)
                setName("")
                setKeyData("")
                setId("")
                setKeyDataIsDirty(false) 
                setUserWantsToUseOurGCP(true)
                props.getProjects({
                    offset: "1",
                    limit: "1000"
                })
            }
            else{
                if(name.length === 0){
                    setNameIsDirty(true)
                }
                else if (keyData.length === 0 || checkJson(keyData) === false){
                    setKeyDataIsDirty(true)
                }
            }
            console.log("apiResponse", apiResponse)
            setIsAdding(false)
        }
        catch(error:any){
            setDialogPopupState(true)
            setApiErrorMessage(error.response.data || error.message || "something went wrong" )
            console.log("Error is :: ",error.response.data);
        }
    };
    const checkJson = (value : any) => {
        try {
            JSON.parse(value);
        } catch (e) {
            return false;
        }
        return true;
    }
    const closeDialogPopup = () => {
        setDialogPopupState(false);
        setApiErrorMessage("");
        setIsAdding(false);
    }
    const handleNameChange =(e:any) => {
        console.log("e ::: " ,e.isTouched, e) 
        setNameIsDirty(true)
        setName(e.target.value)
    }

    const handleKeyDataChange =(e:any) => {
        console.log("e ::: " ,e.isTouched, e) 
        setKeyDataIsDirty(true)
        setKeyData(e.target.value)
    }

    useEffect(()=>{
        (async () => {
            const publicKey = await  ProjectService.getPublicKey();
            if(publicKey && publicKey.data){
                setPublicKey(publicKey.data) 
            }
        })();
    },[])

    return (
        <div>
        <Grid classes={{ root: classes.root }} container direction="column" alignItems="center" justify="center">
            <AddProjectSVG className={classes.illustration} />
            <Box m={1.5} />
            <Typography component="h5" variant="h5">Add Project</Typography>
            <Box m={1.5} />
            <form className={classes.form} onSubmit={(e) => onSubmit(e)} noValidate>
            {/* <> */}
                <FormControl className={classes.formControl}>
                    <TextField
                        variant="outlined"
                        value={name}
                        size="small"
                        margin="normal"
                        required
                        fullWidth
                        id='name'
                        label='Project Name'
                        autoComplete='name'
                        onChange={(e)=> handleNameChange(e)}
                        onBlur={(e)=> handleNameChange(e)}
                        // onFocus={() => setNameIsDirty(true)}
                        // {...register('name', {
                        //     required: `Project Name is required`,
                        // })}
                        error={nameIsDirty && Boolean(name.length === 0)}
                        helperText= {nameIsDirty && (name.length === 0 && "Project Name is required")}
                        autoFocus
                    />
                </FormControl>
                {
                    CLOUD_TYPE === "gcp" &&
                    <FormControl className={classes.formControl}>
                    <FormControlLabel
                    value={userWantsToUseOurGCP}
                    classes={{labelPlacementStart : classes.labelPlacement}}
                    control={<Switch 
                        checked={!userWantsToUseOurGCP} 
                        onChange={() => setUserWantsToUseOurGCP(currentState => !currentState)} 
                        color="primary" />}
                    label={`Do you want to preserve data in your own GCP?`}
                    labelPlacement="start"
                    // {...register('userWantsToUseOurGCP')}
                    />
                    </FormControl>
                }
                {!userWantsToUseOurGCP && 
                <FormControl className={classes.formControl}>
                    <TextField
                            variant="outlined"
                            value={keyData}
                            // minRows={4}
                            size="small"
                            margin="normal"
                            required
                            fullWidth
                            id='keyData'
                            label='Enter Your GCP Key Data'
                            autoComplete='keyData'
                            onChange={(e) => handleKeyDataChange(e)}
                            onBlur= {(e) => handleKeyDataChange(e)}
                            // {...register('keyData', {
                            //     validate: value => checkJson(value) === true || "Key Data should be in JSON format",
                            //     required:  "Key Data is Required"
                            // })}
                            error={keyDataIsDirty && (Boolean(!checkJson(keyData)) || keyData.length === 0)}
                            helperText={keyDataIsDirty && (keyData.length === 0 ? "Key Data is Required" :checkJson(keyData) === false && "Key Data should be in JSON format")}
                            // autoFocus
                    />
                </FormControl>}
                <Box m={1.5} />
                <CustomButton type="submit" 
                // onSubmit={() => onSubmit(name, keyData, id, userWantsToUseOurGCP)} 
                disabled={isAdding}>{isAdding ? <CircularProgress size={20} /> : 'Add Project'}</CustomButton>
            </form>
            {/* </> */}
        </Grid>
        {
            dialogPopupState &&
            <div>
              <Dialog
                open={dialogPopupState}
                onClose={closeDialogPopup}
                aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description"
              >
                <DialogTitle id="alert-dialog-title" style={{paddingBottom: "9px"}}><ErrorOutlineIcon style={{marginBottom: "4px", color: "#f54309"}} /> 
                {/* {"Re-Testing is already going on..."} */}
                <span style={{marginLeft: "5px"}}>Error!</span>
                </DialogTitle>
                <DialogContent>
                  <DialogContentText id="alert-dialog-description">
                    {apiErrorMessage}
                  </DialogContentText>
                </DialogContent>
                <DialogActions>
                  <Button variant="outlined" color="primary" style={{width: '18%', outline: 'none'}} onClick={closeDialogPopup}>
                    Cancel
                  </Button>
                </DialogActions>
              </Dialog>
            </div>
          }
        </div>
    )
}

const mapProps = (state: IReduxState) => {
    return {
        isAdding: state.project.isAdding,
        isAddSuccess: state.project.isAddSuccess,
    };
}

const connector = connect(mapProps, { addProject, getProjects });

export type TPropsFromRedux = ConnectedProps<typeof connector>

export default connector(AddProject);

import React, { useMemo } from 'react';
import css from './TextDiffCChangeComponent.module.css';
import { Change, diffWords } from 'diff';

interface IProps {
    originalText: string
    modifiedText: string
}

const TextDiffChangeComponent = (props: IProps) => {

    const textDiffResult: Change[] = useMemo(() => {
        return diffWords(props.originalText, props.modifiedText, {
        });
      }, [props.modifiedText, props.originalText]);


    return (
        <div
            className={`
                    ${css.textDiffSectionComponentRoot}S
                `}
        >
            {textDiffResult?.map((diffToken) => {

                return (
                    <span
                        className={`
                                ${diffToken.added &&
                                css.addedWord
                            }
                                ${diffToken.removed &&
                                css.removedWord
                            }
                            ${(diffToken.removed || diffToken.added) && diffToken.value !== " " ? css.characterWithoutWhiteSpace : ""}
                            `}
                    >
                        {diffToken.value}
                    </span>
                );
            })}
        </div>
    );
}

export default TextDiffChangeComponent

import {ReactComponent as VideoAnnotationIconSVG} from "../../../../../../../../../assets/svg/Video Annotation Icon.svg"

type Props = {
  IconComponentProps?: any
}

export function ModelTypeVideoAnnotationIcon(props: Props) {
  return (
    <VideoAnnotationIconSVG {...props.IconComponentProps} />
  );
}
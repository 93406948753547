import React, { useEffect, useState } from 'react';
import { makeStyles, Theme, createStyles, lighten, Toolbar, Typography, TextField, Chip, Tooltip, IconButton,Popover, Button, Box } from '@material-ui/core';
import Switch from '@material-ui/core/Switch';
import clsx from 'clsx';
import { Autocomplete } from '@material-ui/lab';
import ArrowBackIcon from '@material-ui/icons/ArrowBack';
import { useURLQuery } from '../../../../../../../../../../../hooks/useURLQuery';
import { AnalyticsResourceView, IAnalyticsScenario, IModelAnalyticsTestStatisticalData, IAnalyticsTableFilterObject, IModelType } from '../../../../../../../../../../../common';
import DropdownWithAccuracyComponent from './modelAnalyticsDashboard/DropdownWithAccuracyComponent';
import { useHistory, useLocation } from 'react-router';
import ToggleButton from '@material-ui/lab/ToggleButton';
import ToggleButtonGroup from '@material-ui/lab/ToggleButtonGroup';
import ListIcon from '@material-ui/icons/List';
import AppsIcon from '@material-ui/icons/Apps';
import { ModelCollectionsTabElementId } from '../Collections';
import MoreVertIcon from '@material-ui/icons/MoreVert';
import FilterListIcon from '@material-ui/icons/FilterList';
import { TableColumnResetFilterButton } from '../../data/components/TableColumnResetFilterButton';

const useToolbarStyles = makeStyles((theme: Theme) =>
    createStyles({
        root: {
            paddingLeft: theme.spacing(2),
            paddingRight: theme.spacing(1),
            display: 'flex',
            justifyContent: 'space-between'
        },
        toolbarLhsSection: {
            display: 'flex',
            width: "100%",
          
            "& label": {
                color: '#838C95',
                font: 'normal normal 600 14px/19px Nunito Sans',
                textTransform: 'uppercase'
            }
        },
        highlight:
            theme.palette.type === 'light'
                ? {
                    color: theme.palette.secondary.main,
                    backgroundColor: lighten(theme.palette.secondary.light, 0.85),
                }
                : {
                    color: theme.palette.text.primary,
                    backgroundColor: theme.palette.secondary.dark,
                },
        title: {
            alignSelf: 'center'
        },
        buttons: {
            display: 'flex',
            paddingRight: '8px',
        },
        floating: {
            "& legend": {
                float: "unset"
            }
        },
        chip: {
            // minWidth: '100px',
            backgroundColor: theme.palette.grey[50],
        },
        dataSetCollectionFilterAutoCompleteChipRoot: {
            maxWidth: "170px",
        },
        backBtnStyles: {
            color: '#008D9C'
        },
        flex: {
            display: 'flex',
            alignItems: 'center'
        },
        outlineInput: {
            marginTop: '12px',
            "& input": {
                font: 'normal normal 600 14px/19px Nunito Sans'
            },
            "& fieldset": {
                borderColor: '#008D9C'
            }
        },
        sortByInput: {
            "& div:first-child": {
                paddingRight: '45px !important'
            }
        }
    }),
);


interface IToolbarProps {
    modelCollectionToCompareOptions: Array<IModelAnalyticsTestStatisticalData>,
    bandFiltersOptions?: Array<string>,
    classFilterOptions?: Array<string>,
    selectedClassFilter?: string,
    selectedBandFilter?: string,
    selectedModelCollectionIdFilter: string,
    isDataLoading: boolean;
    noOfResourcesSelectedCount: number;
    tableFilters: IAnalyticsTableFilterObject;
    handleModelCollectionChangeToCompareConfidenceScoreWith: (modelCollectionIdChangedByUser: string) => Promise<void>
    modelType: string;
    currentAnalyticsResourcesViewType: AnalyticsResourceView;
    modelCollectionToCompareConfidenceScoreWith ?: IModelAnalyticsTestStatisticalData;
    changeAnalyticsResourcesViewType: any;
    actions: {
        bandValueChangeHandler: Function;
        selectedModelCollectionChangeHandler: Function;
        classFilterChangeHandler: Function;
        handleTableFiltersChange: Function;
    };
    tableResourceIdsSelected: string[];
    AddResourceToDataSetCollectionButton: JSX.Element;
    isSegmentationModelAnalyticsEnabled: boolean;
    iouThreshold: number;
    isWordErrorRateModelAnalyticsEnabled: boolean;
}
const useStyles = makeStyles((theme) => ({
   
    table: {
        // minWidth: 750,
        tableLayout: 'fixed'
    },
    tableRow: {
        width: "100%",
        "& th": {
            color: 'rgb(152, 166, 179)',
            font: "14px/22px Nunito Sans"
        }
    },
    tableCellCheckbox: {
       
    },
    tableCellVersion: {
    
    },
    centerTextAlign: {
        textAlign: 'center'
    },
    leftTextAlign: {
        textAlign: 'left'
    },
    tableCellStatus: {
     
        textAlign: "left",
    },
    tableCellPredictedStatus: {
    
        textAlign: "center"
    },
    tableCellLabels: {
        width: "auto",
        // width: `calc(38.3333333333% - (${tableCellTotalFixedWidth}))`
        textAlign: "left",
    },
    labelColumn: {
        width: '180px !important'
    },
    tableCellTraningDataDirectory: {
        width: "auto",
        maxWidth: "150px",
        // width: `calc(38.3333333333% - (${tableCellTotalFixedWidth}))`
        textAlign: "left",
        minWidth: "250px"
    },
    tableCellDescription: {
        width: "auto",
        textAlign: "left",
        // width: `calc(23.3333333333% - (${tableCellTotalFixedWidth}))`
    },
    tableCellActions: {
      
        textAlign: "left",
    },
    tableCellActionsButtons: {
        
        textAlign: "left",
    },
    tableCellView: {
       
        textAlign: "center",
    },
    chip: {
        minWidth: "100px",
        marginRight: theme.spacing(1),
        background: '#F4F5F7'
    },
   
    root: {
        marginBottom: theme.spacing(2),
        position: "sticky",
        top: "0px",
        backgroundColor: "#f0f5fc",
        zIndex: 1,
    },
    indicator: {
        backgroundColor: "transparent",
    },
    delete: {
        
    },
    progress: {
        padding: "10px",
    },
    tableCellAccuracy: {
        width: "150px",
        textAlign: "center",
    },
    accuracyChip: {
        backgroundColor: theme.palette.grey[50],
    },
    rowSelected: {
        backgroundColor: "#CCE8EB !important",
        "&:hover": {
            backgroundColor: "#CCE8EB !important",
            opacity: 1
        }
    },
    tableCellName: {
        width: "212px",
    },
    lastModifiedTime: {
        color: '#008D9C',
        fontSize: '16px',
    },
    lastModifiedDate: {
        color: '#212121',
        fontSize: '16px'
    },
    tableCellConfidenceScore: {
        paddingRight: 0,
        paddingLeft: 0,
        textAlign: 'center',
        
    },
    tableCellLabel: {
        // width: "180px"
        width: "200px",
        paddingRight: 0,
        paddingLeft: 0,
    },
    tableCellLabelForForcastingModel: {
        // width: "180px"
        maxWidth: "275px",
        minWidth: "250px !important",
        width: "auto",
        paddingRight: 0,
        paddingLeft: 0,
    },
   
    tableCellResourceContainerWhenModelTypeDLP: {
        width: "100px"
    },
    tableCellResourceContainerWhenModelTypeBookTranslation: {
        width: "86px"
    },
    image: {
        borderWidth: '2px',
        borderColor: '#fff',
        borderStyle: 'solid',
        boxShadow: theme.shadows[1],
    },
    incorrect: {
        background: '#EF8A8A'
    },
    correct: {
        background: '#F4F5F7'
    },
    grid: {
        midWidth: '220px',
    },
    sortLabelIcon: {
        display: 'none'
    },
    sortLabelRoot: {
        justifyContent: 'flex-end',
        "&:hover .MuiTableSortLabel-icon": {
            display: 'flex'
        }
    },
    sortLabelActive: {
        minWidth: '111px',
        color: '#98a6b3 !important',
        "& .MuiTableSortLabel-icon": {
            display: 'flex'
        }
    },
    columnOptionsIcon: {
        paddingLeft: 0,
        paddingRight: 0,
    },
    tableColumnMenuHeading: {
        display: 'flex', justifyContent: 'space-between'
    },
    floating: {
        "& legend": {
            float: "unset"
        }
    },
    autoCompleteChipRoot: {
        maxWidth: "170px",
    },
    // tableColumnMenuHeading: {
    //     display: 'flex', justifyContent: 'space-between'
    // },
    selectAllDataText: {
        cursor: 'pointer',
        color: theme.palette.primary.main
    },
    clearDataSelectionText: {
        cursor: 'pointer',
        color: theme.palette.primary.main
    }    
}));
const AnalyticsTableEnhancedToolbar = (props: IToolbarProps) => {
    const classes = useToolbarStyles();
    const urlQuery = useURLQuery();
    const location = useLocation();
    const history = useHistory();
   const modelId=urlQuery.get("model")
 
    const [scenario, setScenario] = useState<IAnalyticsScenario | null>(null)
    const {tableFilters, modelCollectionToCompareOptions, bandFiltersOptions, selectedBandFilter, selectedModelCollectionIdFilter, actions , currentAnalyticsResourcesViewType, changeAnalyticsResourcesViewType, modelCollectionToCompareConfidenceScoreWith} = props;
    const [isVideoHandFilter,setIsVideoHandFilter]=useState<boolean>(false)
    const [isFilterApplied,setIsFilterApplied]=useState<boolean>((tableFilters.getSameHandFeedbackNotYetGiven || tableFilters.getSameHandWithMultipleIds || tableFilters.getSameHandWithNoMultipleIds )?true:false || false)
    
    const [filterState, setFilterState] = useState({
        getSameHandWithMultipleIds:tableFilters.getSameHandWithMultipleIds?true:false || false,
        getSameHandWithNoMultipleIds: tableFilters.getSameHandWithNoMultipleIds?true:false || false,
        getSameHandFeedbackNotYetGiven: tableFilters.getSameHandFeedbackNotYetGiven?true:false || false,
      });
  
     
    
    useEffect(() => {
        const scenarioQueryValue = urlQuery.get('scenario') as unknown as IAnalyticsScenario;
        setScenario(scenarioQueryValue || null)
    }, [])

    const backBtnClickHandler = () => {

        history.goBack();
        return;

        let query = new URLSearchParams(location?.search);
        const model = query.get('model') || '';
        const project = query.get('project') || '';
        const testCollectionId = query.get("testCollectionId") || "";
        const modelCollectionIdsToCompareQuery = query.get('modelCollectionIdsToCompare') || '';

        const modelType: IModelType = query.get("type") as IModelType;
        
        // query.delete('modelVersionFilterSelected');
        // query.delete('bandFilterSelected');
        // query.delete('sortByValueFilterSelected');
        // query.delete('sortByClassFilterSelected');
        query = new URLSearchParams();
        // let newPath = window.location.pathname;
        // newPath = newPath.replace('analytics', 'modelAnalytics');
        query.set('model', model);
        query.set('project', project);
        if (testCollectionId) {
            query.set("testCollectionId", testCollectionId);
        }
        query.set('type', props.modelType);
        query.set('status', 'dashboard')
        query.set('modelCollectionIdsToCompare', modelCollectionIdsToCompareQuery)

        if (props.isSegmentationModelAnalyticsEnabled) {
            history.push({ pathname: '../modelAnalytics/dashboard', search: query.toString() })
        } else if (scenario === 'integrity') {
            if (modelType  === "bookTranslation") {
                const modelCollectionTabElement = document.getElementById(ModelCollectionsTabElementId);
                if (modelCollectionTabElement && modelCollectionTabElement?.click) {
                    // @ts-ignore
                    modelCollectionTabElement.click();
                    return;
                }
            }
            history.push({ pathname: '../modelAnalytics/integrity', search: query.toString()})
        } else {
            history.push({ pathname: '../modelAnalytics/individualClassAccuracy', search: query.toString() })
        }
    }
    const isBackHistoryAvailable=window.history.length >1;

    return (
        <Toolbar
            className={
                clsx(classes.root, {
                    [classes.highlight]: props.tableResourceIdsSelected?.length>0
                })
            }
         style={{paddingLeft:"0px"}}
        >
            <div className={`${classes.toolbarLhsSection}` } >
                {
                    props.tableResourceIdsSelected?.length>0 &&
                    <Typography className={classes.title} component="div">
                        {props.noOfResourcesSelectedCount || 0} selected
                    </Typography>
                }
                {
                    props.tableResourceIdsSelected?.length===0 &&
                    (
                        <>
                        {  isBackHistoryAvailable && (

                         <IconButton onClick={backBtnClickHandler} className={classes.title} aria-label="back" style={{ width: '55px', height: '55px' }}>
                         <ArrowBackIcon classes={{root: classes.backBtnStyles}} />
                          </IconButton>
                        )
                        }
                        <section className={classes.flex} style={{width: "100%", columnGap: '50px'}}>
                            {
                                !urlQuery.get('purposeModelId')
                                &&
                            <div className={classes.flex} style={{minWidth: '300px'}}>
                                <label style={{marginBottom: '0', marginRight: '20px'}} htmlFor='model_field'>Model</label>
                                <DropdownWithAccuracyComponent modelsList={props.modelCollectionToCompareOptions} selectedModel={modelCollectionToCompareOptions.filter(model => model.modelCollectionId === selectedModelCollectionIdFilter)[0]} updateSelectedModel={(e: IModelAnalyticsTestStatisticalData) => {
                                    props.actions.selectedModelCollectionChangeHandler(e.modelCollectionId)
                                }} 
                                    iouThreshold={props.iouThreshold}
                                    doNotShowAccuracy={props.modelType === "bookTranslation" ? true : false}
                                    marginTop={'0px'} />
                            </div>
                            }
                            <div className={classes.flex}>
                                {
                                    props.modelType !== "bookTranslation"
                                    ?
                                    (scenario && scenario === 'integrity') ?
                                        <>
                                            <label style={{ marginBottom: '0', marginRight: '20px', marginLeft: '16px' }} htmlFor='bands_field'>Bands</label>
                                            <Autocomplete
                                                style={{ width: '180px' }}
                                                id="bands_field"
                                                closeIcon={() => {}}
                                                options={bandFiltersOptions|| []}
                                                value={selectedBandFilter}
                                                disabled={props.isDataLoading}
                                                onChange={(event, value) => {
                                                    actions.bandValueChangeHandler(value)
                                                }}
                                                renderTags={(value, getTagProps) => {
                                                    return value.map((option, index) => (
                                                        <Chip
                                                            variant="outlined"
                                                            color="primary"
                                                            className={`${classes.chip}`}
                                                            classes={{ root: classes.dataSetCollectionFilterAutoCompleteChipRoot }}
                                                            label={<Tooltip title={option}>
                                                                <span>
                                                                    {index + 1}:
                                                                    {bandFiltersOptions?.filter(
                                                                        (bandOption) => bandOption === option.toString()
                                                                    )[0] || ''}
                                                                </span>
                                                            </Tooltip>}
                                                            {...getTagProps({ index })} />
                                                    ));
                                                }}
                                                renderInput={(params) => (
                                                    <TextField
                                                        {...params}
                                                        variant="outlined"
                                                        size="small"
                                                        margin="normal"
                                                        fullWidth
                                                        className={classes.floating}
                                                        classes={{ root: classes.outlineInput }}
                                                    />
                                                )} />
                                        </>
                                        :
                                        <>
                                            <label style={{ marginBottom: '0', marginRight: '20px' }} htmlFor='bands_field'>Classes</label>
                                            <Autocomplete
                                                style={{ width: '180px' }}
                                                id="classFilter_field"
                                                closeIcon={() => {}}
                                                options={props.classFilterOptions? [ 'ALL', ...props.classFilterOptions.map(option => option.toUpperCase())] : []}
                                                value={props.selectedClassFilter?.toUpperCase()}
                                                disabled={props.isDataLoading}
                                                onChange={(event, value) => {
                                                    actions.classFilterChangeHandler(value)
                                                }}
                                                renderTags={(value, getTagProps) => {
                                                    return value.map((option, index) => (
                                                        <Chip
                                                            variant="outlined"
                                                            color="primary"
                                                            className={`${classes.chip}`}
                                                            classes={{ root: classes.dataSetCollectionFilterAutoCompleteChipRoot }}
                                                            label={<Tooltip title={option}>
                                                                <span>
                                                                    {index + 1}:
                                                                    {props.classFilterOptions?.filter(
                                                                        (bandOption) => bandOption.toUpperCase() === option.toUpperCase()
                                                                    )[0] || ''}
                                                                </span>
                                                            </Tooltip>}
                                                            {...getTagProps({ index })} />
                                                    ));
                                                }}
                                                renderInput={(params) => (
                                                    <TextField
                                                        {...params}
                                                        variant="outlined"
                                                        size="small"
                                                        margin="normal"
                                                        fullWidth
                                                        classes={{ root: classes.outlineInput }}
                                                        className={classes.floating}
                                                    />
                                                )} />
                                        </>
                                        : <></>
                                }
                            </div>
                            {!props.isWordErrorRateModelAnalyticsEnabled && !props.isSegmentationModelAnalyticsEnabled && !urlQuery.get('purposeModelId') &&<Autocomplete
                            style={{border: 'none', width: "230px" }}
                            closeIcon={() =>{}}
                            options={props.modelCollectionToCompareOptions.filter(option => option.modelCollectionId !== selectedModelCollectionIdFilter)}
                                // .map(option => option.modelCollectionId)}
                            value={modelCollectionToCompareConfidenceScoreWith}
                            getOptionLabel={(versionOption) => {
                                const versionLabel = props.modelCollectionToCompareOptions.filter(
                                    (option) => option.version === versionOption.version.toString()
                                )[0]?.version;
                                if (versionLabel) {
                                    return versionLabel.toString();
                                } else {
                                    return versionOption.version;
                                }
                            }
                            }
                            onChange={async (event, value) => {
                                props.handleModelCollectionChangeToCompareConfidenceScoreWith(value?.modelCollectionId || "");                  
                            }}
                            
                            renderInput={(params) => (
                                <>
                                    <TextField
                                        {...params}
                                        size="small"
                                        fullWidth
                                        value={modelCollectionToCompareConfidenceScoreWith}
                                        label="Model Version To Compare"
                                        // classes={{ root: classes.compareWithInput }}
                                    // className={classes.floating}
                                    />
                                    
                                </>
                            )} /> } 
                            
                            { modelId==="656f5e0e29b391c0646cc554" && <div id="videoHandFilterOption"  onClick={()=>{
                                                                    setIsVideoHandFilter(true)
                                                                   
                                                                }} style={{display:"flex",flexDirection:"row" , alignItems:"center",cursor:"pointer"}}>
                                                 <Typography
                                                 style={{color: '#838C95',
                                                 font: 'normal normal 600 14px/19px Nunito Sans'}}
                                                  variant="body1">
                                                   Hand Id Filter
                                                 </Typography> {!props.isSegmentationModelAnalyticsEnabled &&  isFilterApplied
                                                            ? (
                                                                <Tooltip  title="Filter applied ">
                                                                    <FilterListIcon  />
                                                                </Tooltip>
                                                            )
                                                            : <div ><MoreVertIcon  /></div> 
                                                    }
                                                    </div> } 
                                                    
                                                     {isVideoHandFilter &&   <VideoHandFilter
                                                    open={true}
                                                    onClose={() => {
                                                       setIsVideoHandFilter(false)
                                                    }}
                                                   
                                                    resetFilters={() => {
                                                        actions.handleTableFiltersChange({ ...tableFilters,
                                                            getSameHandWithMultipleIds: false,
                                                            getSameHandWithNoMultipleIds: false,
                                                            getSameHandFeedbackNotYetGiven: false });
                                                      setIsFilterApplied(false)
                                                      setIsVideoHandFilter(false)
                                                    }}
                                                    isFilterApplied={(tableFilters.getSameHandFeedbackNotYetGiven || tableFilters.getSameHandWithMultipleIds || tableFilters.getSameHandWithNoMultipleIds )?true:false}
                                                    onApplyFilterButtonClick={(filteredState) => {
                                                        actions.handleTableFiltersChange({ ...tableFilters,
                                                            getSameHandWithMultipleIds: filteredState.getSameHandWithMultipleIds,
                                                            getSameHandWithNoMultipleIds: filteredState.getSameHandWithNoMultipleIds,
                                                            getSameHandFeedbackNotYetGiven: filteredState.getSameHandFeedbackNotYetGiven });
                                                       setIsFilterApplied(true);
                                                       setIsVideoHandFilter(false);
                                                      setFilterState(filteredState)
                                                       
                                                    }}
                                                    filterState={filterState}
                                                     />}
                                                     </section>
                            <section style={{ display: 'flex',  justifyContent: 'flex-end', alignItems: 'center' }}>
                            <div>
                                {
                                    props.modelType !== "bookTranslation" &&
                                    <ToggleButtonGroup
                                        // value=
                                        exclusive
                                        onChange={changeAnalyticsResourcesViewType}
                                        aria-label="Model Analytics Resource View"
                                    >
                                        <Tooltip title={"List View"}>
                                            <ToggleButton color="primary" value="list-view" aria-label="List View" style={{marginTop: '12px', outline: "none"}}
                                                selected={currentAnalyticsResourcesViewType === "list-view"}
                                            >
                                                <ListIcon />
                                            </ToggleButton>
                                        </Tooltip>
                                        <Tooltip title={"Grid View"}>
                                            <ToggleButton color="primary" value="grid-view" aria-label="Grid View" style={{marginTop: '12px', outline: "none"}} 
                                                selected={currentAnalyticsResourcesViewType === "grid-view"}
                                            >
                                                <AppsIcon />
                                            </ToggleButton>
                                        </Tooltip>
                                    </ToggleButtonGroup>
                                }
                            </div>
                        </section>                        
                        </>
                    )

                }
            </div>

            {
                props.tableResourceIdsSelected?.length>0 &&
                (
                    <div id="modelAnalyticsToolbarRhsSection" >
                        {props.AddResourceToDataSetCollectionButton}
                    </div>
                )
            }
        </Toolbar >
    )

}

function VideoHandFilter(props: {
    open: boolean,
    onClose: ((event: {}, reason: "backdropClick" | "escapeKeyDown") => void) | undefined
    resetFilters: () => void
    isFilterApplied: boolean
    onApplyFilterButtonClick: (filterValue: {
        getSameHandWithMultipleIds: boolean;
        getSameHandWithNoMultipleIds: boolean;
        getSameHandFeedbackNotYetGiven: boolean;
      }) => void
    filterState: {
        getSameHandWithMultipleIds: boolean;
        getSameHandWithNoMultipleIds: boolean;
        getSameHandFeedbackNotYetGiven: boolean;
      };
      
}) {
    const classes = useStyles();
    const [temporaryFilterState, setTemporaryFilterState] = useState(props.filterState);
    const [changesMade, setChangesMade] = useState(false);

    const handleSwitchChange = (filterName: string, checked: boolean) => {
        setTemporaryFilterState((prevFilterState) => ({
          ...prevFilterState,
          getSameHandWithMultipleIds: filterName === 'getSameHandWithMultipleIds' ? checked : false,
          getSameHandWithNoMultipleIds: filterName === 'getSameHandWithNoMultipleIds' ? checked : false,
          getSameHandFeedbackNotYetGiven: filterName === 'getSameHandFeedbackNotYetGiven' ? checked : false,
        }));
        const allSwitchesOff = Object.values({
            ...temporaryFilterState,
            [filterName]: checked,
          }).every((value) => !value);
        
          setChangesMade(!allSwitchesOff);
      };
    
    
      const handleApplyFilters = () => {
        const activeSwitch = Object.entries(temporaryFilterState).find(([key, value]) => value);
        
     
        if (activeSwitch) {
            const [filterName] = activeSwitch;
            setTemporaryFilterState((prevFilterState) => ({
                ...prevFilterState,
                getSameHandWithMultipleIds: filterName === 'getSameHandWithMultipleIds',
                getSameHandWithNoMultipleIds: filterName === 'getSameHandWithNoMultipleIds',
                getSameHandFeedbackNotYetGiven: filterName === 'getSameHandFeedbackNotYetGiven',
              }));
        
         
          
          
          setChangesMade(false);
          
         
            props.onApplyFilterButtonClick(temporaryFilterState)
           
        }
      };
     
      const handleResetFilters = () => {
        
        setTemporaryFilterState({
          getSameHandWithMultipleIds: false,
          getSameHandWithNoMultipleIds: false,
          getSameHandFeedbackNotYetGiven: false,
        });
      
        setChangesMade(false);
      };
    
    
   return (
        <Popover
            id="name-column-menu"
            anchorEl={document.getElementById("videoHandFilterOption")}
            keepMounted
            anchorOrigin={{
                vertical: 'bottom',
                horizontal: 'right',
            }}
            transformOrigin={{
                vertical: 'top',
                horizontal: 'left',
            }}
            open={props.open}
            onClose={props.onClose}
            PaperProps={{
                style: {
                    width: "400px",
                },
            }}
        >
            {props.open && (
                <Box id="nameMenuItemContainer" style={{ padding: 15 }}>
                    <div className={`${classes.tableColumnMenuHeading}`}>
                        <Typography
                            variant="body1"
                            style={{ marginBottom: 20 }}
                            color="primary"
                        >
                            &#8288;Video Hand Column Filter
                        </Typography>
                        <Tooltip title="Reset Filters">
                            <Box>
                                <TableColumnResetFilterButton
                                    onClick={() => {props.resetFilters();handleResetFilters()}}
                                    
                                    disabled={!props.isFilterApplied}
                                />
                            </Box>
                        </Tooltip>
                    </div>
                    <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
                 
    
 <div style={{display:"flex",flexDirection:"column",width:"100%"}}>
    <div style={{display:"flex",justifyContent:"space-between",flexDirection:"row"}}>
    <Typography style={{ font: 'normal normal 400 14px/19px Nunito Sans'}}>
        Video having same hand multiple IDs
            </Typography><Switch
                  checked={temporaryFilterState.getSameHandWithMultipleIds}
                  onChange={(e) => handleSwitchChange('getSameHandWithMultipleIds', e.target.checked)}
                  name="getSameHandWithMultipleIds"
                />
       </div>
    <div style={{display:"flex",justifyContent:"space-between",flexDirection:"row"}}>
       <Typography style={{ font: 'normal normal 400 14px/19px Nunito Sans'}}>
        Video having same hand same IDs
            </Typography ><Switch
                  checked={temporaryFilterState.getSameHandWithNoMultipleIds}
                  onChange={(e) => handleSwitchChange('getSameHandWithNoMultipleIds', e.target.checked)}
                  name="getSameHandWithNoMultipleIds"
                />
        </div>
        <div style={{display:"flex",justifyContent:"space-between",flexDirection:"row"}}>
            <Typography style={{ font: 'normal normal 400 14px/19px Nunito Sans'}}>
            Feedback not yet given for videos
            </Typography> <Switch
                  checked={temporaryFilterState.getSameHandFeedbackNotYetGiven}
                  onChange={(e) => handleSwitchChange('getSameHandFeedbackNotYetGiven', e.target.checked)}
                  name="getSameHandFeedbackNotYetGiven"
                />
    </div>
 </div>
        
       
                    </div>

                    <div id="resetFilterButtonContainer"
                        style={{ marginTop: '40px', display: 'flex', justifyContent: 'space-between' }}
                    >
                        <div></div>
                        <Button variant="contained" color="primary"
                            onClick={() => {
                              
                               handleApplyFilters()
                            }}
                            disabled={!changesMade} 
                        >
                            Apply Filters
                        </Button>
                    </div>
                </Box>
            )}
        </Popover>
    );
}

export default AnalyticsTableEnhancedToolbar;
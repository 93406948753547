import {
    Button,
    Dialog,
    DialogActions,
    DialogContent,
    DialogTitle,
    makeStyles,
    Paper,
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TableRow,
  } from "@material-ui/core";
  import ReportProblemIcon from "@material-ui/icons/ReportProblem";
  import { IReduxState } from "../../../../../../../../../../../common";
  import { resetDeleteModelCollectionErrorState } from "../../../../../../../../../../../store/actions";
  import { connect, ConnectedProps } from "react-redux";
  
  const useStyles = makeStyles((theme) => ({
    root: {
      "& > *": {
        display: "flex",
        alignItems: "center",
      },
      "& svg": {
        margin: "auto 10px",
        marginLeft: 0,
      },
    },
    boldText: {
      fontWeight: "bold",
    },
    dialogContent: {
      maxHeight: "350px",
      overflowY: "auto",
    },
    tableContainer: {
      minWidth: "500px",
    },
  }));
  
  const DeleteModelCollectionErrorPopup = (props: TPropsFromRedux) => {
    const classes = useStyles();
    return (
      <Dialog
        open={props.deleteModelCollectionErrorMessages.length > 0}
        onClose={() => props.resetDeleteModelCollectionErrorState()}
        aria-labelledby="delete--model-collection-alert-popup"
        aria-describedby="delete-model-collection-not-successful"
      >
        <DialogTitle
          id="delete-model-collection-alert"
          classes={{ root: classes.root }}
        >
          <ReportProblemIcon color={"error"} />
          {"Error deleting below model collections"}
        </DialogTitle>
        <DialogContent classes={{ root: classes.dialogContent }}>
          <TableContainer
            classes={{ root: classes.tableContainer }}
            component={Paper}
          >
            <Table aria-label="simple table">
              <TableHead>
                <TableRow>
                  <TableCell classes={{ root: classes.boldText }}>
                    Model Collection Versions
                  </TableCell>
                  <TableCell classes={{ root: classes.boldText }}>
                    Linked To
                  </TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {props.deleteModelCollectionErrorMessages.map((row, index) => {
                  return (
                    <TableRow key={row.modelCollection + index}>
                      <TableCell component="th" scope="row">
                        {row.modelCollection}
                      </TableCell>
                      <TableCell>
                        {row.linkedToCollections.testCollection &&
                          row.linkedToCollections.testCollection?.length > 0 && (
                            <span>
                              <strong>Test Versions:</strong>&nbsp;
                              {row.linkedToCollections.testCollection.join(", ")}
                            </span>
                          )}
                         {
                           (row.linkedToCollections?.modelCollection && row.linkedToCollections?.modelCollection?.length > 0) && (
                             <br/> 
                           )
                         } 
                        {row.linkedToCollections?.modelCollection &&
                          row.linkedToCollections?.modelCollection?.length > 0 && (
                            <span>
                              <strong>Model Versions:</strong>&nbsp;
                              {row.linkedToCollections?.modelCollection?.join(", ")}
                            </span>
                          )}
                      </TableCell>
                    </TableRow>
                  );
                })}
              </TableBody>
            </Table>
          </TableContainer>
        </DialogContent>
        <DialogActions>
          <Button
            onClick={() => props.resetDeleteModelCollectionErrorState()}
            color="primary"
            autoFocus
          >
            OK
          </Button>
        </DialogActions>
      </Dialog>
    );
  };
  
  const mapProps = (state: IReduxState) => {
    return {
      deleteModelCollectionErrorMessages:
        state.collection.modelCollection.errorMessagesDueToModelCollectionLinkedSomewhereElse,
    };
  };
  
  const connector = connect(mapProps, {
    resetDeleteModelCollectionErrorState,
  });
  
  export type TPropsFromRedux = ConnectedProps<typeof connector>;
  
  export default connector(DeleteModelCollectionErrorPopup);
  
import { TableRow, TableCell, Typography } from "@material-ui/core";

type Props = {
    colSpan: number
}

const NoTableData = (props: Props) => {
    const {colSpan} = props;
    return (
        <TableRow style={{ height: 53 }}>
            <TableCell colSpan={colSpan}>
                <Typography component="h5" variant="body1">
                    No Data Found
                </Typography>
            </TableCell>
        </TableRow>
    );
}

export { NoTableData };
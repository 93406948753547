import moment from "moment";

export function convertDate(date: string) {
    return moment(date).format('DD-MM-YYYY HH:mm:ss');
}

export const dateFormats = {
    hh_mm_a : "hh:mm a",
    mmm_d_yyyy: "MMMM D, YYYY",
    dd_mmm_yyyy:"DD-MMMM-YYYY"
}

export function formatDate(format: string, date?: string) {
    if(!date){
        /**
         * this needs to be removed after variable added in backend
         */
       return moment(new Date()).format(format);
    }
    return moment(date).format(format);
}

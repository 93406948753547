import { AxiosResponse } from "axios";
import { UserEmailId, UserPassword } from "../common/constants/interfaces/user";

import { API } from "./API";

const URL_STRINGS = {
  TRAININGPOD_CONFIGURATION: "trainingpodConfiguration",
};

class TrainingpodConfigurationService extends API {

  async getConfigurations(type?:"all" | "general" | "specific"): Promise<any> {
    const apiResponse = await this.api.get(`${URL_STRINGS.TRAININGPOD_CONFIGURATION}${type?"?type="+type:""}`)
    return apiResponse.data
  }

  async putConfigurations(requestBody: any): Promise<any> {
    const apiResponse = await this.api.put(`${URL_STRINGS.TRAININGPOD_CONFIGURATION}`, requestBody)
    return apiResponse.data
  }

  async postConfigurations(requestBody: any): Promise<any> {
    const apiResponse = await this.api.post(`${URL_STRINGS.TRAININGPOD_CONFIGURATION}`, requestBody)
    return apiResponse.data
  }

//   async isLoggedInUserAccessTokenValid(): Promise<{isValidAccessToken: boolean}> {
//     const apiResponse = await this.api.get(`${URL_STRINGS.USER}/isValidAccessToken`);
//     return apiResponse.data;
//   }

//   async isLoggedInUserRefreshTokenValid(): Promise<{isValidRefreshToken: boolean}> {
//     const apiResponse = await this.api.get(`${URL_STRINGS.USER}/isLoggedInUserRefreshTokenValid`);
//     return apiResponse.data;
//   }

//   async generateLoggedInUserNewAccessToken(): Promise<{accessToken: string}> {
//     const apiResponse = await this.api.post(`${URL_STRINGS.USER}/loggedInUserNewAccessToken`)
//     return apiResponse.data
//   }

//   async updateViewPreferencesInDB(data: { modelViewPreferences: "model" | "modelGroup" }): Promise<void> {
//     const apiResponse = await this.api.patch(`${URL_STRINGS.USER}/viewPreferences`, data);
//     return apiResponse.data;
//   }

//   async getViewPreferencesInDB(): Promise<{ modelViewPreferences: "model" | "modelGroup" }> {
//     const apiResponse = await this.api.get(`${URL_STRINGS.USER}/viewPreferences`);
//     return apiResponse.data;
//   }

//   async getLoggedInUserEmail(): Promise<string> {
//     const apiResponse = await this.api.get(`${URL_STRINGS.USER}/getLoggedInUserEmail`)
//     return apiResponse.data
//   }

//   async getLoggedInUserIdWithEmail(emailId: string): Promise<string> {
//     const apiResponse = await this.api.get(`${URL_STRINGS.USER}/getLoggedInUserIdWithEmail/${emailId}`)
//     return apiResponse.data
//   }
}

export default new TrainingpodConfigurationService();

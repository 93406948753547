import { useEffect, useState, useRef, useContext } from "react";
import {
    Typography,
    Box,
    Button,
    Chip,
    CircularProgress,
} from "@material-ui/core";
import { ReactComponent as CLONESVG } from "../../../../../../../assets/svg/clone.svg";
import AiMarketPlaceCSS from '../aiMarketPlace.module.css';
import AutorenewOutlinedIcon from '@mui/icons-material/AutorenewOutlined';
import ArrowOutwardOutlinedIcon from '@mui/icons-material/ArrowOutwardOutlined';
import BookmarkBorderOutlinedIcon from '@mui/icons-material/BookmarkBorderOutlined';
import { makeStyles } from '@material-ui/core/styles';
import { loaders } from "../../../../../../../common/styles/loaders";
import { IGenerateRedictionLinkForTestAndTrainDatasets, IGetModelCollectionResponse, IHyperParameterList } from "../aiMarketPlaceInterfaces/interfaces";
import BrowseCollectionsPopup from "../../models/features/model/features/collections/components/BrowseCollectionsPopup";
import BrowseCollectionsPopupContext from "../../models/browseCollectionsPopupContext/browseCollectionsPopup.context";
import TestDatasetPreview from "../../../../homePage/components/TestDatasetPreview";
import useAuth from "../../../../../../../hooks/useAuth";
import { homepageChatAssistantScenarioValue } from "../../../../homePage/components/HomepageChatPreviewWindow";
import { SCENARIO_TEXT } from "../../models/features/model/constants/hardcodedConstants";
import ChatContext from "../../../../chat/contexts/chat.context";

const formatDateTime = (timestamp: string) => {
    const date = new Date(timestamp);
    const day = String(date.getDate()).padStart(2, '0');
    const month = String(date.getMonth() + 1).padStart(2, '0'); // Months are zero-based
    const year = date.getFullYear();

    const hours = String(date.getHours()).padStart(2, '0');
    const minutes = String(date.getMinutes()).padStart(2, '0');
    const seconds = String(date.getSeconds()).padStart(2, '0');

    return `${day}-${month}-${year} | ${hours}:${minutes}:${seconds} Local Time`;
}
const formatNumberOfParams = (numParams: string | number) => {
    let num = parseFloat(numParams.toString());
    if (isNaN(num)) {
        return 'NA';
    }

    if (num >= 1000000) {
        return (num / 1000000).toFixed(1) + 'M';
    } else if (num >= 1000) {
        return (num / 1000).toFixed(1) + 'K';
    }
    return num.toString();
}
const useStyles = makeStyles((theme) => ({
    root: {
        display: 'flex',
    },
    loader: {
        ...loaders.centralLoader,
        left: '50%'
    }
}));
export const generateRedictionLinkForTestAndTrainDatasets = ({ projectName = "", modelName = "", projectId = "", modelId = "", modelType = "", dataSetName = "" }: IGenerateRedictionLinkForTestAndTrainDatasets) => {
    if (!projectName || !modelName || !projectId || !modelId || !modelType || !dataSetName) {
        return "NA";
    }
    return (
        `${window.location.origin}/admin/projects/${projectName}/${modelName}/data/data-set?resourceStatus=collections&resourceOffset=1&resourceLimit=10&project=${projectId}&model=${modelId}&type=${modelType}&nameSearchValue=${dataSetName}&nameSearchOperator=equals`
    )
}



const AIMarketPlaceViewModel = (props: any) => {
    const { isFetching, viewModelPageData, scenario, fetchedModelInformationPageResources, modelInformationPageFetchStatus, isPreviewWindowLoading } = props
    const classes = useStyles();
    const { userEmail } = useAuth()
    const dataCartContext = useContext(BrowseCollectionsPopupContext)
    const originalSearchQueryRef = useRef(window.location.search)
    const [isDataCartOpen, setIsDataCartOpen] = useState<boolean>(false);
    const [hyperParametersList, setHyperParametersList] = useState<IHyperParameterList[]>([]);
    const [TagsList, setTagList] = useState([])
    const [modelCollectionDetailsData, setModelCollectionDetailsData] = useState<IGetModelCollectionResponse | undefined>()
    const [isFetchingDataCartState, setIsFetchingDataCartState] = useState<boolean>(false)


    useEffect(() => {
        // Update hyperParametersList when isFetching is true
        setModelCollectionDetailsData(viewModelPageData)
        if (isFetching && viewModelPageData?.purposeModel?.hyperParameters) {
            const updatedList = Object.keys(viewModelPageData?.purposeModel?.hyperParameters).map((key) => {
                const item = viewModelPageData.purposeModel.hyperParameters[key];
                return {
                    label: item.display_name,
                    value: item.value || "NA"
                };
            });
            setHyperParametersList(updatedList);

        }
        if (isFetching && viewModelPageData?.purposeModel?.tags) {
            setTagList(viewModelPageData?.purposeModel?.tags)
        }
    }, [isFetching, viewModelPageData, hyperParametersList]);


    const fetchInitialDataOfDataCart = async () => {
        setIsFetchingDataCartState(true);
        await dataCartContext.setAiMarketplaceDataCartInitialStateFromDB();
        setIsFetchingDataCartState(false)
    }

    useEffect(() => {
        if (!isFetchingDataCartState) {
            fetchInitialDataOfDataCart();
        }
    }, [originalSearchQueryRef.current])

    const safeGetValue = (data: any, fallback: string) => (data ? data : fallback);
    const isGeneralPurposeModel = viewModelPageData?.purposeModel?.purposeType === "general" ? true : false
    const yearOfRelease = isGeneralPurposeModel ? `${viewModelPageData.purposeModel?.yearOfRelease || 'NA'}`
        : viewModelPageData?.created_at?.substring(0, 4) || 'NA';
    // const TagsList = viewModelPageData?.purposeModel.tags?.length ? viewModelPageData?.purposeModel.tags : []  
    const authorName = isGeneralPurposeModel ? viewModelPageData?.purposeModel?.createdByUser?.emailId || "NA" : viewModelPageData?.created_by || "NA";
    const testDataSetName = isGeneralPurposeModel ? viewModelPageData?.purposeModel?.testDataSet?.label : viewModelPageData?.checkpointFiles?.files?.analysis?.dataSetCollection?.name;
    const testDataSetLink = isGeneralPurposeModel ? viewModelPageData?.purposeModel?.testDataSet?.link : generateRedictionLinkForTestAndTrainDatasets(
        {
            projectName: viewModelPageData?.project?.name,
            modelName: viewModelPageData?.model?.name,
            projectId: viewModelPageData?.project?._id,
            modelId: viewModelPageData?.model?._id,
            modelType: viewModelPageData?.model?.type,
            dataSetName: testDataSetName
        }
    )
    const trainDataSetName = isGeneralPurposeModel ? "" : viewModelPageData?.trainingDataDirectory?.[0] || "NA"
    const trainDataSetLink = isGeneralPurposeModel ? "" : generateRedictionLinkForTestAndTrainDatasets(
        {
            projectName: viewModelPageData?.project?.name,
            modelName: viewModelPageData?.model?.name,
            projectId: viewModelPageData?.project?._id,
            modelId: viewModelPageData?.model?._id,
            modelType: viewModelPageData?.model?.type,
            dataSetName: viewModelPageData?.trainingDataDirectory?.[0]
        }
    )
    const modelInfoList = [
        {
            label: 'Name',
            value: safeGetValue(viewModelPageData?.purposeModel?.modelName, "NA")
        },
        {
            label: 'Year of Release',
            value: safeGetValue(yearOfRelease, "NA")
        },
        {
            label: 'Num Parameters',
            value: formatNumberOfParams(safeGetValue(viewModelPageData?.numberOfModelParams?.toString(), "NA"))
        },
        {
            label: 'Inference hardware',
            value: viewModelPageData && viewModelPageData?.purposeModel?.inferenceHardwareGPUCount && viewModelPageData?.purposeModel?.inferenceHardware
                ? `${viewModelPageData.purposeModel.inferenceHardwareGPUCount}x${viewModelPageData.purposeModel.inferenceHardware}`
                : "NA"
        },
        {
            label: 'Inference time (ms)',
            value: 'NA'
        },
        {
            label: 'References',
            value: viewModelPageData && viewModelPageData?.purposeModel?.references || []
        },
    ]


    const onClickTryOutButton = () => {
        setIsDataCartOpen(true);
    }    
    const chatContext = useContext(ChatContext);

    const { pushPageInWorkModeStack, workModePageStack, setModelCloningStage } = chatContext;
    const currentPageData = workModePageStack[workModePageStack.length-1]

    const onClickTestDriveButtonInHomePage = ()=>{
        pushPageInWorkModeStack({
            pageType: "test_drive",
            aiResponseMetaData: currentPageData.aiResponseMetaData,
            isTestDriveButtonClickFromModelSummaryPage: true,
            messageUuid: props.messageUuidFromParentPage,
            metadata: {
                pageTitle: `Test drive "${safeGetValue(viewModelPageData?.purposeModel?.modelName, "")}"`,
                trainingPodId: props.viewModelPageData?.purposeModel?._id,
                modelCollectionId: props.viewModelPageData?._id,
            },
        });
    }

    const onClickViewMoreTestData = () => {
        if (props.onClickViewMoreTestData) {
            props.onClickViewMoreTestData();
        }
    }
    const RedirectToTrainingPodPage = () => {
        const projectName = safeGetValue(viewModelPageData?.project?.name, '')
        const modelName = safeGetValue(viewModelPageData?.model?.name, '')
        const modelId = safeGetValue(viewModelPageData?.model?._id, '')
        const projectId = safeGetValue(viewModelPageData?.project?._id, '')
        const purposeModelId = safeGetValue(viewModelPageData?.purposeModel?._id, '')
        let url = `/admin/projects/${projectName}/${modelName}/addNewModel/aboutModel/modelPurpose?offset=1&limit=10&model=${modelId}&project=${projectId}&purposeModelId=${purposeModelId}`
        // if (authorName !== userEmail && scenario === homepageChatAssistantScenarioValue) {
        //     url += `&${SCENARIO_TEXT}=${homepageChatAssistantScenarioValue}`
        // }
        window.open(url, '_blank');
    }

    const onClickCloneButton = () => {
        pushPageInWorkModeStack({
            pageType: 'model_cloning_page',
            aiResponseMetaData : currentPageData.aiResponseMetaData,
            messageUuid: currentPageData.messageUuid,
            searchQueryBeforeThisPage: window.location.search,
            metadata: {
                pageTitle: "Clone",
                trainingPodId: currentPageData?.aiResponseMetaData?.trainingPods[0]?.trainingPodId,
            },
        })
        setModelCloningStage('initial')
    }

    return (
        <Box className={AiMarketPlaceCSS.viewModelPageContainer}>
            {isFetching ? <Box className={classes.loader}>
                <CircularProgress size={30} />
            </Box> :
                <Box display="flex" flexDirection="column">
                    <Box className={AiMarketPlaceCSS.columnFlex} style={{ gap: '15px' }}>
                        <Box className={AiMarketPlaceCSS.rowFlex} justifyContent="space-between" flexWrap="wrap">
                            <Box className={AiMarketPlaceCSS.columnFlex} style={{ gap: '12px' }}>
                                <Box className={AiMarketPlaceCSS.rowFlex} style={{ gap: '12px' }}>
                                    <Typography variant="h6" className={AiMarketPlaceCSS.viewModelSubHeading}>{safeGetValue(viewModelPageData?.purposeModel?.modelName, "NA")}</Typography>
                                    {isGeneralPurposeModel ?
                                        <Chip className={AiMarketPlaceCSS.generalPurposeType} label={"General"} />
                                        :
                                        <Chip className={AiMarketPlaceCSS.specificPurposeType} label={"Specific"} />
                                    }
                                </Box>
                                <Typography variant="body2" style={{ color: '#666' }}>{viewModelPageData?.purposeModel?.aiTask} - <span style={{ color: '#292E36', fontWeight: 500 }}>{viewModelPageData?.purposeModel?.problemType}</span></Typography>
                            </Box>
                            <Box className={`${AiMarketPlaceCSS.rowFlex} ${AiMarketPlaceCSS.forksAccuracyIntegrityContainer}`} justifyContent="space-between">
                                <Box className={AiMarketPlaceCSS.columnFlex} justifyContent="center" alignItems="center">
                                    <Typography variant="body1" style={{ color: '#CA9F08', fontWeight: 600, fontFamily: 'Inter' }}>{viewModelPageData?.purposeModel?.clonesCount || 0}</Typography>
                                    <Typography variant="body2" style={{ color: '#558E98', fontSize: '10px', fontFamily: 'Inter' }}>Forks</Typography>
                                </Box>
                                <Box className={AiMarketPlaceCSS.columnFlex} justifyContent="center" alignItems="center">
                                    {viewModelPageData?.purposeModel?.purposeType === 'specific' ?
                                        // @ts-ignore 
                                        <Typography variant='body1' color="primary" style={{ textAlign: 'center', fontWeight: 600, color: '#045461', fontFamily: 'Inter' }}>{Number.isInteger(viewModelPageData?.checkpointFiles?.files?.analysis?.cachedMetrics?.accuracy?.accuracy) ? viewModelPageData.checkpointFiles.files.analysis.cachedMetrics.accuracy.accuracy : viewModelPageData.checkpointFiles.files.analysis.cachedMetrics.accuracy.accuracy.toFixed(2)}%</Typography>
                                        :
                                        <Typography variant='body1' color="primary" style={{ textAlign: 'center', fontWeight: 600, color: '#045461' }}>-</Typography>
                                    }
                                    <Typography variant="body2" style={{ color: '#558E98', fontSize: '10px', fontFamily: 'Inter' }}>Accuracy</Typography>
                                </Box>
                                <Box className={AiMarketPlaceCSS.columnFlex} justifyContent="center" alignItems="center">
                                    {viewModelPageData?.purposeModel?.purposeType === 'specific' ?
                                        <Typography variant='body1' style={{ textAlign: 'center', fontWeight: 600, color: '#045461', fontFamily: 'Inter' }}>
                                            {// @ts-ignore 
                                                viewModelPageData?.checkpointFiles?.files?.analysis?.cachedMetrics?.integrity?.integrity !== undefined ? (Number.isInteger(viewModelPageData.checkpointFiles.files.analysis.cachedMetrics.integrity.integrity) ? viewModelPageData.checkpointFiles.files.analysis.cachedMetrics.integrity.integrity : viewModelPageData.checkpointFiles.files.analysis.cachedMetrics.integrity.integrity.toFixed(2)) + "%" : "NA"}
                                        </Typography>
                                        :
                                        <Typography variant='body1' style={{ textAlign: 'center', fontWeight: 600, color: '#045461' }}>-</Typography>
                                    }
                                    <Typography variant="body2" style={{ color: '#558E98', fontSize: '10px', fontFamily: 'Inter' }}>Integrity</Typography>
                                </Box>
                            </Box>
                        </Box>
                        <Box className={AiMarketPlaceCSS.rowFlex} style={{ gap: '8px' }}>
                            {TagsList.length > 0 && TagsList.map((item: { value: string }) => <Chip size="small" variant="default" label={item?.value || "NA"} className={AiMarketPlaceCSS.Customchip} />)}
                        </Box>
                        <Box className={`${AiMarketPlaceCSS.columnFlex} ${AiMarketPlaceCSS.CustomRoundedDiv}`} style={{ marginTop: '18px' }}>
                            <Typography variant="subtitle1" className={`${AiMarketPlaceCSS.viewModelSubHeading}`}>Description</Typography>
                            <Box style={{ border: '1px solid #DFDFDF', marginTop: '12px' }} />
                            <Typography variant="subtitle1" className={`${AiMarketPlaceCSS.viewModelSubHeading}`} style={{ marginTop: '24px' }}>{safeGetValue(viewModelPageData?.purposeModel?.modelDescription, "NA")}</Typography>
                            <Box className={AiMarketPlaceCSS.columnFlex} style={{ gap: '12px', marginTop: '14px' }}>
                                <Box className={AiMarketPlaceCSS.rowFlex} style={{ gap: '2rem' }}>
                                    <Typography variant="body2" style={{ color: '#64748B', width: '100px' }}>Project</Typography>
                                    <Typography variant="body2" style={{ color: '#292E36' }}>{safeGetValue(viewModelPageData?.project?.name, "NA")}</Typography>
                                </Box>
                                <Box className={AiMarketPlaceCSS.rowFlex} style={{ whiteSpace: 'nowrap', gap: '2rem' }}>
                                    <Typography variant="body2" style={{ color: '#64748B', width: '100px' }}>Last Modified</Typography>
                                    <Typography variant="body2" style={{ color: '#292E36' }}>{isGeneralPurposeModel ? formatDateTime(safeGetValue(viewModelPageData?.purposeModel?.updated_at, "NA")) : formatDateTime(viewModelPageData?.updated_at)}</Typography>
                                </Box>
                                {authorName !== "NA" && <Box className={AiMarketPlaceCSS.rowFlex} style={{ gap: '2rem' }}>
                                    <Typography variant="body2" style={{ color: '#64748B', width: '100px' }}>Author</Typography>
                                    <Typography variant="body2" style={{ color: '#292E36' }}>{authorName}</Typography>
                                </Box>
                                }
                            </Box>
                        </Box>
                    </Box>
                    {scenario === "homepageChatAssistant" && <Box className={`${AiMarketPlaceCSS.columnFlex} ${AiMarketPlaceCSS.CustomRoundedDiv}`} style={{ marginTop: '32px' }}>
                        <Box className={AiMarketPlaceCSS.rowFlex} justifyContent="space-between">
                            <Typography variant="subtitle1" className={`${AiMarketPlaceCSS.viewModelSubHeading}`}>Samples from Testing Dataset</Typography>
                            <Button color="primary" variant="outlined" size="small" onClick={onClickViewMoreTestData} disabled={isGeneralPurposeModel}>View More</Button>
                        </Box>
                        <Box style={{ border: '1px solid #DFDFDF', marginTop: '12px' }} />
                        <TestDatasetPreview availableModelsData={[viewModelPageData]} fetchedModelResources={fetchedModelInformationPageResources} modelFetchStatus={modelInformationPageFetchStatus} isFetching={isPreviewWindowLoading} />
                    </Box>}
                    <Box display="flex" justifyContent="space-between" flexWrap="wrap" mt={4} style={{ gap: '1rem', paddingBottom: '70px' }}>
                        {/*Model Info */}
                        <Box className={`${AiMarketPlaceCSS.columnFlex} ${AiMarketPlaceCSS.CustomRoundedDiv}`}>
                            <Typography variant="subtitle1" className={`${AiMarketPlaceCSS.viewModelSubHeading}`}>Model Info</Typography>
                            <Box style={{ border: '1px solid #DFDFDF', marginTop: '12px' }} />
                            {/* <TableContainer component={Paper} style={{width:'fit-content'}}>
                                <Table size="small" aria-label="Info table">
                                    <TableBody>
                                        {modelInfoList.map((eachItem) =>
                                            <TableRow>
                                                <TableCell style={{
                                                    fontWeight: 600,
                                                    backgroundColor: "#F9FAFB",
                                                    borderRight: "1px solid rgba(0, 0, 0, 0.12)",
                                                    borderTop: "1px solid rgba(0, 0, 0, 0.12)",
                                                    borderLeft: "1px solid rgba(0, 0, 0, 0.12)", borderRadius: '4px',
                                                    whiteSpace: "nowrap"
                                                }}>
                                                    {eachItem.label}
                                                </TableCell>
                                                <TableCell style={{
                                                    borderRight: "1px solid rgba(0, 0, 0, 0.12)",
                                                    borderTop: "1px solid rgba(0, 0, 0, 0.12)",
                                                    whiteSpace: "nowrap"
                                                }}>
                                                    {eachItem.label === 'References' ? (
                                                        eachItem.value.length > 0 ? (
                                                            eachItem.value.map((ref: any, idx: number) => (
                                                                <a key={idx} href={ref.link} target="_blank" rel="noopener noreferrer" style={{ marginRight: '10px', textDecoration: 'underline', color: "rgb(0, 141, 156)" }}>
                                                                    {ref.label}
                                                                </a>
                                                            ))
                                                        ) : 'NA'
                                                    ) : (
                                                        eachItem.value
                                                    )}
                                                </TableCell>
                                            </TableRow>)}
                                    </TableBody>
                                </Table>
                            </TableContainer> */}
                            <Box mt={2}>
                                {modelInfoList.map((item, index) => (
                                    <Box
                                        key={index}
                                        display="flex"
                                        alignItems="center"
                                        style={{
                                            marginBottom: index !== modelInfoList.length - 1 ? '8px' : 0,
                                            gap: '3rem',
                                        }}
                                    >
                                        {/* Label */}
                                        <Box>
                                            <Typography
                                                style={{
                                                    fontWeight: 500,
                                                    color: '#6B7280',
                                                    whiteSpace: 'nowrap',
                                                    width: '150px'
                                                }}
                                            >
                                                {item.label}
                                            </Typography>
                                        </Box>

                                        {/* Value */}
                                        {item.label === 'References' && Array.isArray(item.value) ? (
                                            <Box style={{ display: 'flex', gap: '8px' }}>
                                                {item.value.length > 0 ? (
                                                    item.value.map((ref, idx) => (
                                                        <a
                                                            key={idx}
                                                            href={ref.link}
                                                            target="_blank"
                                                            rel="noopener noreferrer"
                                                            style={{
                                                                textDecoration: 'underline',
                                                                color: 'rgb(0, 141, 156)',
                                                                fontWeight: 'bold',
                                                            }}
                                                        >
                                                            {ref.label}
                                                        </a>
                                                    ))
                                                ) : (
                                                    <Typography
                                                        style={{
                                                            fontWeight: 'bold',
                                                            color: '#292E36',
                                                        }}
                                                    >
                                                        NA
                                                    </Typography>
                                                )}
                                            </Box>
                                        ) : (
                                            <Typography
                                                style={{
                                                    fontWeight: 'bold',
                                                    color: '#292E36',
                                                }}
                                            >
                                                {item.value}
                                            </Typography>
                                        )}
                                    </Box>
                                ))}
                            </Box>
                        </Box>
                        {/* hyperParameter */}
                        <Box className={`${AiMarketPlaceCSS.columnFlex} ${AiMarketPlaceCSS.CustomRoundedDiv}`}>
                            <Typography variant="subtitle1" className={`${AiMarketPlaceCSS.viewModelSubHeading}`}>
                                Hyper Parameters
                            </Typography>
                            <Box style={{ border: '1px solid #DFDFDF', marginTop: '12px' }} />
                            {/* <TableContainer component={Paper} style={{width:'fit-content'}}>
                                <Table size="small" aria-label="Hyper Parameters table">
                                    <TableBody>
                                        {hyperParametersList && hyperParametersList.map((eachItem: IHyperParameterList) =>
                                            <TableRow>
                                                <TableCell
                                                    style={{
                                                        color: "black",
                                                        fontWeight: 600,
                                                        backgroundColor: "#F9FAFB",
                                                        borderRight: "1px solid rgba(0, 0, 0, 0.12)",
                                                        borderTop: "1px solid rgba(0, 0, 0, 0.12)",
                                                        borderLeft: "1px solid rgba(0, 0, 0, 0.12)", borderRadius: '4px', whiteSpace: 'nowrap'
                                                    }}
                                                >{eachItem.label}</TableCell>
                                                <TableCell
                                                    style={{
                                                        borderRight: "1px solid rgba(0, 0, 0, 0.12)",
                                                        borderTop: "1px solid rgba(0, 0, 0, 0.12)",
                                                        borderRadius: '4px'
                                                    }}>{eachItem.value}</TableCell>
                                            </TableRow>)}
                                    </TableBody>
                                </Table>
                            </TableContainer> */}
                            <Box mt={2}>
                                {hyperParametersList && hyperParametersList.map((eachItem: IHyperParameterList, index) => (
                                    <Box
                                        key={index}
                                        display="flex"
                                        justifyContent="space-between"
                                        alignItems="center"
                                        style={{
                                            marginBottom: index !== modelInfoList.length - 1 ? '8px' : 0,
                                            gap: '2rem'
                                        }}
                                    >
                                        {/* Label */}
                                        <Box style={{ width: 'fit-content' }}>
                                            <Typography
                                                style={{
                                                    fontWeight: 500,
                                                    color: '#6B7280',
                                                    whiteSpace: 'nowrap',
                                                }}
                                            >
                                                {eachItem.label}
                                            </Typography>
                                        </Box>

                                        {/* Value */}
                                        <Box>
                                            <Typography
                                                style={{
                                                    fontWeight: 'bold',
                                                    color: '#292E36',
                                                }}
                                            >
                                                {eachItem.value}
                                            </Typography>
                                        </Box>
                                    </Box>
                                ))}
                            </Box>
                        </Box>
                        {/* datasetUsed */}
                        {/* <Box> */}
                        {/* <Typography variant="subtitle2" style={{ marginBottom: '8px', color: "#838C95" }}>Dataset Used</Typography> */}
                        {/* training datast only for specific  */}
                        {/* {!isGeneralPurposeModel && <Box style={{
                                border: "1px solid #E5E7EB", backgroundColor: "#FFFFFF",
                                borderRadius: '8px', minWidth: '300px', boxShadow: "0px 2px 8px 0px #0000000D",
                                display: 'flex',
                                flexDirection: 'column',
                                justifyContent: 'center',
                                alignItems: 'center',
                                gap: '1rem',
                                padding: '8px',
                                marginBottom: '0.5rem',
                                width:'fit-content'
                            }}>
                                <Box style={{
                                    border: "1px solid #E5E7EB", backgroundColor: "#FFFFFF",
                                    borderRadius: '4px', padding: '10px', boxShadow: "0px 2px 8px 0px #0000000D",
                                    borderLeft: "3px solid #008D9C",
                                    display: 'flex',
                                    justifyContent: "space-between",
                                    alignItems: 'center',
                                    width: '100%',
                                    gap: '1.5rem'
                                }}>
                                    <Typography variant="body2" style={{ whiteSpace: 'nowrap' }}>Training Dataset</Typography>
                                    <Link href={trainDataSetLink} target="_blank" style={{ opacity: '0.7', fontSize: '12px', whiteSpace: 'nowrap', color: "rgb(0, 141, 156)", textDecoration: 'underline' }}>
                                        {trainDataSetName}
                                    </Link>
                                </Box>
                            </Box>} */}
                        {/* testing dataset */}
                        {/* <Box style={{
                                border: "1px solid #E5E7EB", backgroundColor: "#FFFFFF",
                                borderRadius: '8px', minWidth: '300px', boxShadow: "0px 2px 8px 0px #0000000D",
                                display: 'flex',
                                flexDirection: 'column',
                                justifyContent: 'center',
                                alignItems: 'center',
                                gap: '1rem',
                                padding: '8px',
                                width:'fit-content'
                            }}>
                                <Box style={{
                                    border: "1px solid #E5E7EB", backgroundColor: "#FFFFFF",
                                    borderRadius: '4px', padding: '10px', boxShadow: "0px 2px 8px 0px #0000000D",
                                    borderLeft: "3px solid #008D9C",
                                    display: 'flex',
                                    justifyContent: "space-between",
                                    alignItems: 'center',
                                    width: '100%',
                                    gap: '1.5rem'
                                }}>
                                    <Typography variant="body2" style={{ whiteSpace: 'nowrap' }}>Testing Dataset</Typography>
                                    <Link href={testDataSetLink} target="_blank" style={{ opacity: '0.7', fontSize: '12px', whiteSpace: 'nowrap', color: "rgb(0, 141, 156)", textDecoration: 'underline' }}>
                                        {testDataSetName}
                                    </Link>
                                </Box>
                            </Box> */}
                        {/* </Box> */}
                    </Box>
                    <Box className={`${AiMarketPlaceCSS.rowFlex} ${AiMarketPlaceCSS.viewModelFooter} `} >
                        {/* <Box className={AiMarketPlaceCSS.rowFlex} alignItems="center" style={{ gap: '10px',cursor:'pointer' }}>
                            <BookmarkBorderOutlinedIcon style={{ color: "#4D4D4D",width:'20px',height:'20px',opacity:'0.5' }} />
                            <Typography variant="subtitle2" style={{ color: "#4D4D4D",opacity:'0.5' }}>Bookmark</Typography>
                        </Box>
                        <Box className={AiMarketPlaceCSS.rowFlex} alignItems="center" style={{ gap: '10px',cursor:'pointer' }}>
                            <ArrowOutwardOutlinedIcon style={{ color: "#4D4D4D",width:'20px',height:'20px',opacity:'0.5' }} />
                            <Typography variant="subtitle2" style={{ color: "#4D4D4D",opacity:'0.5' }}>Visit training pod</Typography>
                        </Box> */}
                        <Button variant="outlined" color="primary" size="small" disabled={true} startIcon={<BookmarkBorderOutlinedIcon />} style={{ textTransform: 'capitalize', border: '0' }}>
                            Bookmark
                        </Button>
                        <Button variant="outlined" size="small" startIcon={<ArrowOutwardOutlinedIcon />} style={{ textTransform: 'capitalize', border: '0', color: '#242424CC' }} onClick={RedirectToTrainingPodPage}>
                            Visit training pod
                        </Button>
                        {/* startIcon={<CLONESVG />} */}
                        <Button variant="outlined" color="primary" size="small" style={{ textTransform: 'capitalize', padding: '5px 25px' }} onClick={onClickCloneButton}>
                            Clone
                        </Button>
                        {/* disabled={isFetchingDataCartState} */}
                        <Button variant="contained" color="primary" size="small" disabled={scenario === homepageChatAssistantScenarioValue ? false : isFetchingDataCartState} startIcon={scenario === homepageChatAssistantScenarioValue ? "" : <AutorenewOutlinedIcon />} style={{ textTransform: 'capitalize', padding: '5px 25px' }} onClick={scenario === homepageChatAssistantScenarioValue ? onClickTestDriveButtonInHomePage : onClickTryOutButton}>
                            Test Drive
                        </Button>
                    </Box>
                    <BrowseCollectionsPopup
                        isOpen={isDataCartOpen}
                        setOpen={setIsDataCartOpen}
                        resourceDataSetCollectionIds={[]}
                        originalSearchQuery={originalSearchQueryRef.current}
                        setDataCartOpen={() => { }}
                        setSavedForLaterOpen={() => { }}
                        scenario='ai-marketplace-datacart'
                    />
                </Box>}
        </Box>
    );
};

export default AIMarketPlaceViewModel
import { Skeleton } from "@material-ui/lab";
import { useEffect, useRef, useState } from "react";
import { FileMetaData, IData } from "../../../../../../../../../../../common";
import ImageScalarService from "../../../../../../../../../../../services/imageHelperService";
import ImageService from "../../../../../../../../../../../services/ImageService";
import { useCompressedAndOriginalImageLoader } from "../../../../../../../../../../../hooks/useCompressedAndOriginalImageLoader";
import { DownloadingOriginalImageLoader } from "./downloadingOriginalImageLoader/downloadingOriginalImageLoader";

type ImageAspectRationScalerProps = {
  maxImageWidthAllowed: number;
  maxImageHeightAllowed: number;
  imageUrl: string;
  style?: React.CSSProperties;
  image500Url?: string;
  imageMetaData ?: FileMetaData;
  image500UrlMetaData ?: FileMetaData;
  doNotShowSkeleton?: boolean;
};

export default function ImageAspectRatioScaler(
  props: ImageAspectRationScalerProps
) {

  const [ referenceImageWidth , setReferenceImageWidth] = useState<number>(0)
  const [ referenceImageHeight , setReferenceImageHeight] = useState<number>(0)
  const [loadingRequired , setLoadingRequired] = useState<boolean>(false);
	const [isImageLoading, setIsImageLoading] = useState(false);


  useEffect(() => {
    (async () => {
      // @ts-ignore
      if(false && props.imageMetaData && props.imageMetaData?.size && props.imageMetaData?.size < 3){
        // @ts-ignore
        if(props.imageMetaData.width > 0 && props.imageMetaData.height > 0){
          setLoadingRequired(true)
          // @ts-ignore
          setReferenceImageWidth(props.imageMetaData.width)
          // @ts-ignore
          setReferenceImageHeight(props.imageMetaData.height)
        }
        else{
          setLoadingRequired(true)
          const imageWidthAndHeight = await ImageService.getImageWidthHeightFromURL(props.imageUrl);
          if(imageWidthAndHeight){
            setReferenceImageWidth(imageWidthAndHeight.width)
            setReferenceImageHeight(imageWidthAndHeight.height)
          }
        }
      }
      else if(false && props.image500Url){
        // @ts-ignore
        if(props.image500UrlMetaData && (props.image500UrlMetaData.width > 0 && props.image500UrlMetaData.height)){
          setLoadingRequired(true)
          // @ts-ignore
          setReferenceImageWidth(props.image500UrlMetaData.width)
          // @ts-ignore
          setReferenceImageHeight(props.image500UrlMetaData.height)
        }else { 
          setLoadingRequired(true)
          // @ts-ignore
          const imageWidthAndHeight = await ImageService.getImageWidthHeightFromURL(props.image500Url);
          if(imageWidthAndHeight){
            setReferenceImageWidth(imageWidthAndHeight.width)
            setReferenceImageHeight(imageWidthAndHeight.height)
          }
        }
      } else {
        // @ts-ignore
        if(false && props.imageMetaData && props.imageMetaData.width > 0 && props.imageMetaData.height > 0){
          setLoadingRequired(true)
          // @ts-ignore
          setReferenceImageWidth(props.imageMetaData.width)
          // @ts-ignore
          setReferenceImageHeight(props.imageMetaData.height)
        }
        else{
          setLoadingRequired(true)
          const imageWidthAndHeight = await ImageService.getImageWidthHeightFromURL(props.imageUrl);
          if(imageWidthAndHeight){
            setReferenceImageWidth(imageWidthAndHeight.width)
            setReferenceImageHeight(imageWidthAndHeight.height)
          }
        }
      }

    })();
  },[props.image500Url, props.image500UrlMetaData, props.imageMetaData, props.imageUrl])

  const canvasElementRef = useRef<HTMLCanvasElement>();

  useEffect(() => {
    setIsImageLoading(true);
    let image = new Image();
    if (props.imageMetaData?.size && props.imageMetaData?.size < 3) {
      image.src = props.imageUrl;
    }
    else if (props.image500Url) {
      image.src = props.image500Url;
    }
    else {
      image.src = props.imageUrl;
    }

    image.onload = function () {
      setIsImageLoading(false);
    }

  }, [props.imageUrl, props.image500Url]);

  const {
    imageWidthToSet,
    imageHeightToSet,
  }: { imageWidthToSet: number; imageHeightToSet: number } = (() => {
    if (loadingRequired) {
      const imageWidth = referenceImageWidth || 0;
      const imageHeight = referenceImageHeight || 0;

      const imageAspectRatio = imageWidth / imageHeight;

      let imageWidthToSet: number = 0;
      let imageHeightToSet: number = 0;

      if (imageWidth >= imageHeight) {
        imageWidthToSet = props.maxImageWidthAllowed;
        imageHeightToSet = imageWidthToSet / imageAspectRatio;
        imageHeightToSet = ImageScalarService.getImageHeight(
          imageWidthToSet,
          imageAspectRatio
        );

        if (imageHeightToSet > props.maxImageHeightAllowed) {
          imageHeightToSet = props.maxImageHeightAllowed;
          imageWidthToSet = ImageScalarService.getImageWidth(
            imageHeightToSet,
            imageAspectRatio
          );
        }
      } else if (imageHeight >= imageWidth) {
        imageHeightToSet = props.maxImageHeightAllowed;
        imageWidthToSet = imageAspectRatio * imageHeightToSet;
        imageWidthToSet = ImageScalarService.getImageWidth(
          imageHeightToSet,
          imageAspectRatio
        );

        if (imageWidthToSet > props.maxImageWidthAllowed) {
          imageWidthToSet = props.maxImageWidthAllowed;
          imageHeightToSet = ImageScalarService.getImageHeight(
            imageWidthToSet,
            imageAspectRatio
          );
        }
      }

      return {
        imageWidthToSet: imageWidthToSet,
        imageHeightToSet: imageHeightToSet,
      };
    }

    return {
      imageWidthToSet: props.maxImageWidthAllowed,
      imageHeightToSet: props.maxImageHeightAllowed,
    };
  })();


  // useEffect(() => {
  //   if (!canvasElementRef.current || !imageRef.current) {
  //     return;
  //   }

  //   const canvasContext = canvasElementRef.current.getContext("2d");

  //   if (!canvasContext) {
  //     return;
  //   }

  //   canvasContext.drawImage(imageRef.current, 0, 0, imageWidthToSet, imageHeightToSet);

  //   console.log(":::: ",imageRef)
  //   return () => {};
  // }, [imageRef , imageHeightToSet, imageWidthToSet]);

  const compressedAndOriginalImageLoader = useCompressedAndOriginalImageLoader({
    compressedImageDownloadUrl: props.image500Url || "",
    originalImageDownloadUrl: props.imageUrl || ""
  })

  return (
    !isImageLoading
    ?
    <>
    {
      // props.imageMetaData && props.imageMetaData?.size && props.imageMetaData?.size < 3
      compressedAndOriginalImageLoader.originalImageDownloadStatus === "downloadCompleted"
      ? 
        <img
        alt=''
        src={props.imageUrl}
        width={imageWidthToSet}
        height={imageHeightToSet}
        style={{
          width: `${imageWidthToSet}px`,
          height: `${imageHeightToSet}px`,
          ...(props.style || {}),
        }}
        />
      // : props.image500Url
      : compressedAndOriginalImageLoader.compressedImageDownloadStatus === "downloadCompleted"
        ?
          <div
            style={{
              position: "relative",
            }}
          >
            {
              compressedAndOriginalImageLoader.originalImageDownloadStatus === "downloadInProgress" &&
              <DownloadingOriginalImageLoader
                tooltipWhenDownloading="Downloading high resolution image"
                downloadingProgressStyle={{
                  position: "absolute",
                  right: "1px",
                  top: "1px"
                }}
              />
            }
            <img
            alt=''
            src={props.image500Url}
            width={imageWidthToSet}
            height={imageHeightToSet}
            style={{
              width: `${imageWidthToSet}px`,
              height: `${imageHeightToSet}px`,
              ...(props.style || {}),
            }}
            />
          </div>
        :

            <canvas
              width={imageWidthToSet}
              height={imageHeightToSet}
              style={{
                backgroundImage: `url(${props.imageUrl})`,
                backgroundSize : `${imageWidthToSet}px ${imageHeightToSet}px`,
                width: `${imageWidthToSet}px`,
                height: `${imageHeightToSet}px`,
                ...(props.style || {}),
              }}
              //   @ts-ignore
              ref={canvasElementRef}
            ></canvas>
        
    }

    {/* eslint-disable-next-line jsx-a11y/alt-text */}

    </>
    :
    !props.doNotShowSkeleton
    ?
      <Skeleton
        variant="rect"
        style={{
          margin: 'auto',
        }}
        width={props.maxImageWidthAllowed}
        height={props.maxImageHeightAllowed}
      />
    : <></>
    
  );
}

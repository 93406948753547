import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  IconButton,
  makeStyles,
  Tooltip,
  useMediaQuery,
  useTheme,
} from "@material-ui/core";
import { Cancel } from "@material-ui/icons";
import HistoryIcon from "@material-ui/icons/History";
import { useMemo, useState } from "react";
import { generateUniqueId } from "../../../../../../../../../../../services/idHelperService";
import ModelCollectionHistoryView from "./ModelCollectionHistoryView";

export default function ShowModelCollectionHistoryViewButton(props: {
  modelCollectionId: string;
  tooltip?: string;
}) {
  const tooltip = props.tooltip || "History View";

  const [isHistoryDialogOpen, setIsHistoryDialogOpen] = useState(false);

  return (
    <>
      <Tooltip title={tooltip}>
        <IconButton
          onClick={() => {
            setIsHistoryDialogOpen(true);
          }}
        >
          <HistoryIcon color="primary" />
        </IconButton>
      </Tooltip>

      {isHistoryDialogOpen && (
        <HistoryViewDialog
          isOpen={isHistoryDialogOpen}
          modelCollectionId={props.modelCollectionId}
          handleClose={() => {
            setIsHistoryDialogOpen(false);
          }}
        />
      )}
    </>
  );
}

const useHistoryViewDialogStyles = makeStyles((theme) => ({
  ModelCollectionHistoryViewContainer: {
    [theme.breakpoints.up("sm")]: {
      minHeight: "400px",
    },
    [theme.breakpoints.up("md")]: {
      minHeight: "500px",
    },
  },
}));

function HistoryViewDialog(props: {
  modelCollectionId: string;
  isOpen: boolean;
  handleClose: Function;
}) {
  const theme = useTheme();
  const classes = useHistoryViewDialogStyles();
  const fullScreen = useMediaQuery(theme.breakpoints.down("xs"));

  const uniqueId = useMemo(() => generateUniqueId(), []);

  return (
    <div>
      <Dialog
        // fullScreen={fullScreen}
        fullScreen
        fullWidth={true}
        maxWidth={"md"}
        open={props.isOpen}
        onClose={props.handleClose as any}
        aria-labelledby={"responsive-dialog-title" + uniqueId}
      >
        <DialogTitle id={"responsive-dialog-title" + uniqueId}>
          <div
            style={{
              display: "flex",
              justifyContent: "space-between"
            }}
          >
            <span>
              {"Model Collection History"}
            </span>
            <Tooltip title="Exit">
              <Cancel 
                style={{ 
                  fontSize: "40px", 
                  padding: "4px", 
                  cursor: "pointer" 
                }} 
                onClick={props.handleClose as any}
              />
            </Tooltip>
          </div>
        </DialogTitle>
        <DialogContent>
          {/* <DialogContentText>
              Let Google help apps determine location. This means sending anonymous location data to
              Google, even when no apps are running.
            </DialogContentText> */}
          <div className={`${classes.ModelCollectionHistoryViewContainer}`}>
            <ModelCollectionHistoryView
              modelCollectionId={props.modelCollectionId}
            />
          </div>
        </DialogContent>
        {/* <DialogActions>
          <Button onClick={props.handleClose as any} color="primary">
            Close
          </Button>
        </DialogActions> */}
      </Dialog>
    </div>
  );
}

import { useCallback } from 'react';
import { useEffect, useRef } from 'react';
import { useState } from 'react';
import { connect, ConnectedProps, useDispatch } from 'react-redux';
import { convertDate, dateFormats, formatDate, IReduxState, ResourceActions, Status } from '../../../../../../../../../common';
// import ImageAspectRatioScaler from '../../../../../../../../../common/components/ImageAspectRatioScaler';
import ImageAspectRatioScaler from '../../../../models/features/model/features/data/components/imageAspectRatioScalerWithAlignment.component';
import { InputFieldState } from '../../../../../../../../../common/constants/interfaces/inputFieldState';
import { setShouldBlockDataTableKeyboardEvents, updateResourceInUI, updateSelectedCoPilotResource } from '../../../../../../../../../store/actions';
import { getResources, updateCoPilotResource, updateCopilotResourceImageAnnotationsInDB, updateIsDataFetching } from '../../../../../../../../../store/actions/coPilotDetailPageActions';
import { ImageKeyPointsEditDialog, RemarksSection } from '../../../../../../projects/features/models/features/model/features/data/components/ImageKeyPointsEditDialog';
import ColorSelector from '../ColorSelector';
import styles from './addRemark.module.scss';
import DrawerButton from '../../../components/DrawerButton';
import OnRejectModal from '../Modal/OnRejectModal';
import { TwoDimensionalImage } from '../../../../../../../../../assets/components/react-annotation-tool/src/apps/two-dimensional-image';
import { Button, Link, withStyles } from '@material-ui/core';
import { AnnotationOption, AnnotationOptionChildren, defaultAnnotationOptions, getDefaultAnnotationsToMapInAnnotationComponentIfModelTypeImageGroupAnnotation, setResourceImageAnnotationsForSendingToAnnotationComponent, setResourceImageGroupAnnotationsForSendingToAnnotationComponent, setResourceImageAnnotationsOfOriginalImageForCompressedImage } from '../../../../../../../../../services/imageAnnotationHelperService';
import { UPDATE_COPILOT_RESOURCE_IMAGE_ANNOTATIONS_Action_Payload } from '../../../../../../../../../common/constants/interfaces/copilot';
import copilotService from '../../../../../../../../../services/copilotService';
import { copyByValue } from '../../../../../../../../../services/variableHelperService';
import { getImageScaleFactorForImageWidth } from '../../../../../../../../../services/imageHelperService';
import { ICoPilotResource } from '../../../../../../../../../common/constants/interfaces/coPilotResource';
import { convertURLQueryParamsToJSON } from '../../../../../../../../../services/urlQueryParamService';
import FileNameClipper from '../../../../../../../../../common/components/FileNameClipper';
// import { CopilotDetailPageTabSelected } from '../../../../../../../../../common/constants/interfaces/coPilotDetailPage';
import VisibilityIcon from '@material-ui/icons/Visibility';
import clsx from 'clsx';
import { useHistory, useLocation } from 'react-router';
import TextTrimUI from '../../../../models/features/model/features/data/components/TextTrimUI/textTrimUI';
import usePreviousValue from '../../../../../../../../../hooks/usePreviousValue';
import ImageService from '../../../../../../../../../services/ImageService';
import { AudioTranscriptionSection } from '../../audioTranscriptionSection/audioTranscriptionSection';
import useForceReRender from '../../../../../../../../../hooks/useForceReRender';

interface IProps extends TPropsFromRedux{
    restoreResource?: Function;
}

const AUDIO_ELEMENT_ID = "AUDIO_ELEMENT_ID";


export function ShowLessLink(props:{
    onClick: ()=>any,
    color?: string
}) {
  const { color } = props;

    return (
        <div>
            <Link
                style={{ cursor: "pointer", color: color ? color : "#007bff" }}
                onClick={props.onClick}
            >
                show less
            </Link>
        </div>
    );
}

export function ShowMoreLink(props:{
    onClick: ()=>any,
    color?: string
}) {
  const { color } = props;

    return (
        <div style={{minWidth:  '71px'}}>
            <Link
                style={{ cursor: "pointer", color: color ? color : "#007bff" }}
                onClick={props.onClick}
            >
                show more
            </Link>
        </div>
    );
}

const csvInitialMaxLengthLimit=8;  

function ImageAnnotationFullScreenButton(props: { 
    onClick?: () => void,
    iconButtonStyles?: {},
    type:String
 }) {

    const FullScreenButton = withStyles({
        root: {
          textTransform: 'none',
        },
      })(Button);

    return (
        <div style={{width:'100%',display:'flex',justifyContent:'center'}}>
            <FullScreenButton 
            startIcon={props.type==="image"?<VisibilityIcon/>: null}
            onClick={() => {
            if (props.onClick) {
              props.onClick();
            }
          }}
          color='primary' variant='contained'>{props.type==="image"?"View":"Edit Annotation"}</FullScreenButton>
        </div>
    );
}

const MaxAnnotatedImageWidthAllowed = 300;
const MaxAnnotatedImageHeightAllowed = 220;

const AddRemark = (props: IProps) => {
    const {typeOfCopilot,resource} = props;
    const dispatch = useDispatch();
    // const [resourceRemarksTextState, setResourceRemarksTextState] = useState<InputFieldState<string>>({value: props.resource?.remarks?.text || ""});
    const [resourceRemarksTextState, setResourceRemarksTextState] = useState<InputFieldState<string>>({value: ""});
    
    const [csvExpandedFormShowing, setCsvExpandedFormShowing] = useState<boolean>(false);

    const annotatedImageWidthToSetRef = useRef(MaxAnnotatedImageWidthAllowed);

    const [openModal, setOpenModal] = useState(false)
    const [CompOpen, setCompOpen] = useState(true)
    const [isImageAnnotationModalOpen, setIsImageAnnotationModalOpen] = useState(false)
    // const [annotationOptions, setAnnotationOptions] = useState<AnnotationOption>(defaultAnnotationOptions);
    const [alert, setAlert] = useState("none");
    const [alertMessage, setAlertMessge] = useState("none");
    const selectedColor = useRef("")
    const [annotationOptions, setAnnotationOptions] = useState<AnnotationOption>(defaultAnnotationOptions);
    console.log("logging",resource?.imageAnnotations);
    const [hasImageScalingCompleted, setHasImageScalingCompleted] = useState(false);
    const [selectedDataObjectForSideDrawerImageAnnotation, setSelectedDataObjectForSideDrawerImageAnnotation] = useState<ICoPilotResource>();
    const [isTakenAction, setTakenAction] = useState(false)
    // const imageWidthToSetForSideDrawerScenario = 300;
    const history = useHistory();
    const location = useLocation();
    const updateResourceInUI=props.updateResourceInUI
    const previousResourceId = usePreviousValue(props.resource?._id || "");

    const isResourceIdChanged = (()=>{
        if (props.resource?._id !== previousResourceId) {
          return true;
        }
        return false;
      })();

    const isResourceIdChangedRef = useRef(isResourceIdChanged);
    isResourceIdChangedRef.current = isResourceIdChanged;

    const propsResourceRef = useRef(props.resource);
    propsResourceRef.current = props.resource;      

    const canShowCompressedAnnotatedImageInsteadOfOriginalImage: boolean = (() => {
        return props.resource?.resourceMetaData?.width && props.resource?.resourceMetaData?.height && props.resource?.resource500WUrl && props.resource.resource500WMetaData?.width && props.resource.resource500WMetaData?.height
        ? true
        : false
    })();

    // const selectedTab: CopilotDetailPageTabSelected = new URLSearchParams(window.location.search).get("status") as CopilotDetailPageTabSelected || "" as CopilotDetailPageTabSelected;

    useEffect(()=>{
        setSelectedDataObjectForSideDrawerImageAnnotation(resource);
    },[resource])
    useEffect(()=>{
        const executeFunction = async () => {
            if (!isImageAnnotationModalOpen) {
                setHasImageScalingCompleted(false);
    
                let image: {
                    width: number,
                    height: number
                  } | undefined = undefined;

                  if (canShowCompressedAnnotatedImageInsteadOfOriginalImage && resource?.resource500WUrl && resource?.resource500WMetaData?.width && resource?.resource500WMetaData?.height) {
                    image = {
                      width: resource?.resource500WMetaData?.width || 0,
                      height: resource?.resource500WMetaData?.height || 0,
                    }
                  } else if (resource?.resourceMetaData?.width && resource?.resourceMetaData?.height) {
                    image = {
                        width: resource.resourceMetaData.width,
                        height: resource.resourceMetaData.height,
                      }
                  } else {
                    image = await ImageService.getImageWidthHeightFromURL(
                          canShowCompressedAnnotatedImageInsteadOfOriginalImage
                          ? resource?.resource500WUrl || ""
                          : resource?.resource || ""
                      )
                  }   

                  let differenceBetweenCanvasAndImageToSet = 0;
                  let canvasWidthToSet = 0;        
                  let canvasHeightToSet = 0;                  
                  
                  if (image.width >= image.height) {
                    differenceBetweenCanvasAndImageToSet = image.width / MaxAnnotatedImageWidthAllowed ;
                    canvasHeightToSet = image.height / differenceBetweenCanvasAndImageToSet ;          
                    canvasWidthToSet = MaxAnnotatedImageWidthAllowed;          
          
                    
                  } else if (image.height > image.width) {
                    differenceBetweenCanvasAndImageToSet = image.height / MaxAnnotatedImageHeightAllowed;
                    canvasWidthToSet = image.width / differenceBetweenCanvasAndImageToSet;
                    canvasHeightToSet = MaxAnnotatedImageHeightAllowed;          
          
                    if(canvasWidthToSet > MaxAnnotatedImageWidthAllowed){
                      differenceBetweenCanvasAndImageToSet = canvasWidthToSet / MaxAnnotatedImageWidthAllowed;
                      canvasHeightToSet = canvasHeightToSet / differenceBetweenCanvasAndImageToSet;
                      canvasWidthToSet = MaxAnnotatedImageWidthAllowed;
                    }          
                  }
          
                  annotatedImageWidthToSetRef.current = canvasWidthToSet;                     
    
                const imageScaleFactor = getImageScaleFactorForImageWidth(
                    image.width,
                    annotatedImageWidthToSetRef.current
                );

                const imageGroupAnnotationsToSet = 
                    typeOfCopilot === "imageAnnotationGroup"
                      ? setResourceImageGroupAnnotationsForSendingToAnnotationComponent(
                            copyByValue(resource?.imageGroupAnnotations  || []),
                            imageScaleFactor
                        )
                      : []

                let imageAnnotationsToSet = 
                    typeOfCopilot === "imageAnnotation"
                      ? setResourceImageAnnotationsForSendingToAnnotationComponent(
                            copyByValue(resource?.imageAnnotations || []),
                            imageScaleFactor
                        )
                      : []

                      if (canShowCompressedAnnotatedImageInsteadOfOriginalImage) {
                        /**
                         * Converting imageAnnotations vertices as per compressedImage width and height
                         */                    
    
                         imageAnnotationsToSet = setResourceImageAnnotationsOfOriginalImageForCompressedImage(
                            imageAnnotationsToSet,
                            resource?.resourceMetaData?.width || 0,
                            resource?.resourceMetaData?.height || 0,
                            resource?.resource500WMetaData?.width || 0,
                            resource?.resource500WMetaData?.height || 0          
                        )                    
                    }              
    
    
                // @ts-ignore
                setSelectedDataObjectForSideDrawerImageAnnotation(oldState=>{return {
                    ...oldState,
                    imageGroupAnnotations: [...imageGroupAnnotationsToSet],
                    imageAnnotations: [...imageAnnotationsToSet]
                }})
    
                setHasImageScalingCompleted(true)
            }
        }
        
        executeFunction();

        // clearImageKeyPointsInCanvas();     
      }, [resource?.resource,  isImageAnnotationModalOpen,props.copilotSelectedId, resource?.imageAnnotations])     

    useEffect(()=>{
        const executeFunction = async () => {
          const apiResponse = await copilotService.getCoPilotById({
            coPilotId: resource?.coPilot._id || "",
            coPilotSelectQuery: "annotationLabelsAvailable -_id"
          });
    
          if (apiResponse.data) {
            const annotationOptionsToSave = copyByValue(defaultAnnotationOptions);
            annotationOptionsToSave.children = [];
    
            const annotataionOptionChildrens: AnnotationOptionChildren[] = apiResponse.data.annotationLabelsAvailable as AnnotationOptionChildren[];
            annotataionOptionChildrens.map(annotationOptionChildren=>{
                annotationOptionChildren.children = [];
                return annotationOptionChildren
            })
    
            annotationOptionsToSave.children
            .push(
                ...annotataionOptionChildrens
            );
    
            setAnnotationOptions(copyByValue(annotationOptionsToSave));        
          }
        }
        if (resource?.coPilot._id ) {
          executeFunction();
        }
      }, [resource?.coPilot._id ])

    const updateRemarks = useCallback((remarks: string) => {

        const urlQuerySearchParams = new URLSearchParams(window.location.search);
        const getQueryParams = convertURLQueryParamsToJSON(urlQuerySearchParams.entries())

        const data: any = {
            id: [props.resource?._id],
            remarks,
            getQueryParams: getQueryParams
        }
        if(selectedColor.current){
            data["label"] = selectedColor.current
        }
        setTakenAction(false)
        dispatch(updateCoPilotResource(data))
    },[selectedColor, props.resource?._id])

    const handleColorChange = (color: string) => {
        selectedColor.current = color;
    }


    function iterateOverResources(direction:number){
        for(var i=0;i<props.resourcelist.length;i++){
            if(props.resourcelist[i]._id===props.copilotSelectedId){
                if(i+1<props.resourcelist.length && direction===1){
                    dispatch(updateSelectedCoPilotResource(props.resourcelist[i+1]._id))
                }
                else if(i+1 === props.resourcelist.length && direction===1 ){
                    let currentUrlParams = new URLSearchParams(window.location.search);
                    let offsetParam = currentUrlParams.get('offset') || "1";
                    const limit: string = currentUrlParams.get('limit') as string;;
                    const globalCurrentRowValue = Number(offsetParam) * Number(limit);

                    if((props.totalCount || 0) > globalCurrentRowValue) {
                        const currentTab = currentUrlParams.get('status') || "";
                        offsetParam = (parseInt(offsetParam) + 1).toString();
                        currentUrlParams.set('offset', offsetParam);
                        fetchResources(currentTab, offsetParam)
                        history.push(window.location.pathname + "?" + currentUrlParams.toString());
                    }
                }
                else if(i-1>=0 && direction===-1){
                    dispatch(updateSelectedCoPilotResource(props.resourcelist[i-1]._id))
                }
            }
            
        }
        // console.log("direction",direction);
        // console.log("selected id",props.copilotSelectedId);
    }

    const fetchResources = (currentTab: "active" | "expired" | string, offsetValue?: string) => {
        let offsetParam = offsetValue ? offsetValue : new URLSearchParams(window.location.search).get('offset') || "1";
        let limitParam = new URLSearchParams(window.location.search).get('limit') || "20";
        const coPilot = new URLSearchParams(location.search).get('coPilot') || "";
        const data = {
          offset: offsetParam,
          limit: limitParam,
          status: currentTab,
          sortField: "statusLastModifiedAt",
          sortOrder: "ascending",
          coPilot 
        }
        dispatch(updateIsDataFetching(true))
        dispatch(getResources(data, props.resourcelist))
      }

    const approveResource = () => {

        const urlSearchParams = new URLSearchParams(window.location.search);
        const getQueryParams = convertURLQueryParamsToJSON(urlSearchParams.entries())

        const data: any = {
            id: [props.resource?._id],
            status: Status.APPROVED
        }
        if(selectedColor.current){
            data["label"] = selectedColor.current
        }
        if(getQueryParams){
            data["getQueryParams"] = getQueryParams
        }
        setTakenAction(true);
       (async() => {
            await dispatch(updateCoPilotResource(data))

            setTakenAction(false);
            /** If we are approving the selected resource, we need to remove the resourceId from URL */
            const currentIndex = props.resourcelist.findIndex(resource => resource._id === props.resource?._id);
            const newPath=location.pathname;
            if(currentIndex>=0 && currentIndex < props.resourcelist.length - 1){
                urlSearchParams.set("resourceId", props.resourcelist[currentIndex+1]._id)
            }
            else if(currentIndex>=0 && currentIndex === props.resourcelist.length - 1){
                urlSearchParams.set("resourceId", props.resourcelist[currentIndex - 1]._id)
            }
            else{
                urlSearchParams.delete("resourceId")
            }
            history.push(`${newPath}?${urlSearchParams.toString()}`);
       })();
    }
    
    useEffect(() => {
        selectedColor.current = props.resource?.label || ""
    },[props.resource?.label])

    useEffect(() => {
        setResourceRemarksTextState({value: props.resource?.remarks?.text || ""})
    },[props.resource?.remarks?.text])
    // useEffect(()=>{
    //     window.addEventListener('keydown',(e)=>{
    //         if(drawerState){
    //             if(e.code==="ArrowRight"){
    //                 console.log("right");
    //                 iterateOverResources(1);
    //             }
    //             else if(e.code==="ArrowLeft"){
    //                 console.log("left")
    //                 iterateOverResources(-1);
    //             }
    //         }
    //     });
    // },[props.copilotSelectedId])
    useEffect(()=>{
        setCompOpen(false);
        setTimeout(()=>{setCompOpen(true)},0);
        console.log("called",resource);
    },[resource?.resource,isImageAnnotationModalOpen])

    // const onSwitch = (i: number) => { 
    //     console.log(i);
    // };

    const ImageViewActionSection=(props:{type:string})=>(
        <div style={props.type==="image"?{position:'relative',height:'60px' ,marginTop:'20px',width:"400px"}:{position:'relative',height:'60px' ,marginTop:'20px',width:"100%"}}>
            <div  onClick={() => setOpenModal(true)}>
                <DrawerButton status={ResourceActions.REJECT} side="left" scenario={"annotationPopup"}/>
            </div>
            <div onClick={approveResource}>
                <DrawerButton status={ResourceActions.APPROVE} side="right" scenario={"annotationPopup"}/>
            </div>
        </div>
    )

    const audioElementRef = useRef<HTMLAudioElement>();
    // @ts-ignore
    audioElementRef.current = document.getElementById(AUDIO_ELEMENT_ID);

    const currentAudioTime = audioElementRef.current?.currentTime || 0;

    const forceReRender = useForceReRender();
    
    const [audioTranscriptionSectionState, setAudioTranscriptionSectionState] = useState<{
        mistakesMode: "showing" | "hiding"
    }>({
        mistakesMode: "hiding"
    })

    return(
        <>
            {props.copilotSelectedId !== "" && <div key={props.copilotSelectedId} className={clsx([styles.wrapper], { [styles.fadedView]: isTakenAction })}>
            {/* MODAL FOR ANNOTATING IMAGES  */}
            {isImageAnnotationModalOpen && typeOfCopilot!==undefined && resource!==undefined &&
                <ImageKeyPointsEditDialog
                updateResourceInUI={updateResourceInUI}
                multipleImageAnnotationResourceImageIndex={0}
                setCurrentMultipleImageAnnotationResourceImageIndex={()=>{}}                
                resourceRemarksTextState={resourceRemarksTextState}
                setResourceRemarksTextState={setResourceRemarksTextState}
                open={isImageAnnotationModalOpen}
                modelReferenceData={[]}
                onSwitch={iterateOverResources}
                imageExpandFeatureEnabled={typeOfCopilot==="image"?false:true}
                onClose={() => {
                    setIsImageAnnotationModalOpen(false);
                } }
                isZoomFeatureEnabledForImageAnnotation={typeOfCopilot==="image"?false:true}
                modelOrCopilotType={typeOfCopilot}
                isFetchingResource={false}
                isViewOnlyMode={typeOfCopilot==="image"?true:false}
                annotationOption={annotationOptions}
                resource={resource}
                allowUserToSetAnyLabel={false}
                resources={props.resourcelist}
                totalCount={props.totalCount || 0}
                confidenceScore = {props.resource?.confidenceScore}
                inCopilot={true}
                modelType={typeOfCopilot}
                // setIsImageKeyPointsEditDialogOpen={setIsImageAnnotationModalOpen}
                ActionsSection={props.resource?.status === Status.EXPIRED?(props.resource.coPilot.type==="image"?<ImageViewActionSection type='image'/>:props.resource.coPilot.type==="imageAnnotation"?<ImageViewActionSection type='imageAnnotation'/>:<div></div>):<div></div>}
                alert={alert}
                scenario={typeOfCopilot==="image"?"copilotImagePopUp":typeOfCopilot==="imageAnnotation"?"copilotImageAnnotationPopUp":"dialog"}
                alertMessage={alertMessage}
                onImageAnnotationSaveClick={async (data) => {
                    const requestData:UPDATE_COPILOT_RESOURCE_IMAGE_ANNOTATIONS_Action_Payload={
                            resourceId:resource._id,
                            imageAnnotations:data.annotations
                    }
                    console.log("clicked save",requestData)
                    try{
                        await dispatch(updateCopilotResourceImageAnnotationsInDB(requestData))
                        setAlertMessge("Changes Saved !");
                        setAlert("success");
                        setTimeout(() => {
                            setAlert("");
                        }, 2000);
                    }catch{
                        setAlertMessge("Something went wrong !!");
                        setAlert("failed");
                        setTimeout(() => {
                            setAlert("");
                        }, 2000);
                    }
                } } 
                setShouldBlockDataTableKeyboardEvents={props.setShouldBlockDataTableKeyboardEvents}
                onSaveRemarksClick={() => {
                    updateRemarks(resourceRemarksTextState.value)
                    setResourceRemarksTextState(oldState=>{return {
                        ...oldState,
                        isDirty: false,
                        isTouched: false
                    }})                             
                }}  
            />
            }
            {/* MODAL FOR REJECTING COPILOTS  */}
            {openModal && <OnRejectModal updatedLabel={selectedColor.current} open={openModal} setOpen={setOpenModal} resource={props.resource}/>}
            <div className={styles.remark_container}>
                {
                    CompOpen && typeOfCopilot==='imageAnnotation' && selectedDataObjectForSideDrawerImageAnnotation!==undefined && hasImageScalingCompleted &&
                    <TwoDimensionalImage
                    key={JSON.stringify(selectedDataObjectForSideDrawerImageAnnotation.imageAnnotations || [])}
                    modelType={typeOfCopilot} 
                    // url={selectedDataObjectForSideDrawerImageAnnotation?.resource}
                    url={
                        canShowCompressedAnnotatedImageInsteadOfOriginalImage
                        ? selectedDataObjectForSideDrawerImageAnnotation?.resource500WUrl
                        : selectedDataObjectForSideDrawerImageAnnotation?.resource
                      }                    
                    isViewOnlyMode={true}
                    resourceId={resource?._id || ""}
                    // alert={[]}
                    hasSaveButton={false}
                    hideAnnotationList={true}
                    isShowingCompressedImageBecauseOriginalImageIsDownloading={canShowCompressedAnnotatedImageInsteadOfOriginalImage}
                    // imageWidth={imageWidthToSetForSideDrawerScenario}
                    imageWidth={annotatedImageWidthToSetRef.current}
                    settingManuallyCanvasWidth={MaxAnnotatedImageWidthAllowed}
                    // onNextClick={()=>{iterateOverResources(+1)}}
                    // onPreviousClick={()=>{iterateOverResources(-1)}}
                    // ActionsSection={
                    //     <ActionsSection
                    //       resourcesWhichAreBeingAddedToDataSetCollectionInDB={props.resourcesWhichAreBeingAddedToDataSetCollectionInDB}  
                    //     />
                    // }
                    // canShowAddAnnotationButton={
                    //     false && selectedDataObject?.status === 'approved'
                    //     ? false
                    //     : true
                    // }
                    scenario="coPilotRejectPageReadOnlyAnnotation"

                    options={annotationOptions}
                    defaultAnnotations={
                        typeOfCopilot === 'imageAnnotation'
                          ? selectedDataObjectForSideDrawerImageAnnotation.imageAnnotations
                          : typeOfCopilot === 'imageAnnotationGroup'
                            ? getDefaultAnnotationsToMapInAnnotationComponentIfModelTypeImageGroupAnnotation(
                                selectedDataObjectForSideDrawerImageAnnotation
                                .imageGroupAnnotations||[]
                              )     
                            : []
                      }
                      defaultAnnotationGroups={
                        selectedDataObjectForSideDrawerImageAnnotation
                        .imageGroupAnnotations
                        ?.map(imageGroupAnnotation=>{
                            // @ts-ignore
                            // delete imageGroupAnnotation.imageAnnotations;
                            return imageGroupAnnotation;
                        }) || []
                    }   
                    isDynamicOptionsEnable
                    // scenario="sideDrawer"
                    // fullscreenButton={
                 
                    // }
// =======
//                 <ImageAspectRatioScaler
//                     maxImageWidth={230}
//                     maxImageHeight={180}
//                     imageUrl={props.resource?.resource || ""}
//                     canvasStyle={{
//                         borderRadius: "4px"
//                     }} 
// >>>>>>> 2ead85a515a94ce387c2838832d25fb9d39d5079
                />
                }
                { 
                    typeOfCopilot==='image' && 
                    // <ImageAspectRatioScaler
                    //     maxImageWidth={300}
                    //     maxImageHeight={300}
                    //     imagePlotterSuffix='addRemark'
                    //     imageUrl={props.resource?.resource || ""} 
                    // />
                    <div className={styles.imageContainer}>
                        <ImageAspectRatioScaler
                            imageUrl={props?.resource?.resource!}
                            image500Url={props?.resource?.resource500WUrl ?? undefined}
                            imageMetaData={props?.resource?.resourceMetaData ?? undefined}
                            image500UrlMetaData={props?.resource?.resource500WMetaData ?? undefined}
                            maxImageWidthAllowed={300}
                            maxImageHeightAllowed={300}
                        />
                    </div>
                }

                    {
                        props.resource?.coPilot?.type === "video" &&
                        <video controls width="300" height="300">
                            <source src={props.resource.resource} type="video/mp4" />
                        </video>
                    }
                {
                    (props.resource?.coPilot?.type === "audio" || props.resource?.coPilot?.type === "audioTextTranscription") &&
                    <audio controls 
                        id={AUDIO_ELEMENT_ID}
                        // @ts-ignore
                        onTimeUpdate={()=>{
                            forceReRender();
                        }}
                    >
                        <source src={props.resource.resource} type="audio/wav" />
                    </audio>
                }
                {
                    typeOfCopilot === "text" &&
                    <div
                        style={{
                            width: "300px",
                            height: "206px",
                            overflow: "auto",
                            whiteSpace: "break-spaces",
                            border: "1px solid rgb(118, 118, 118)",
                            borderRadius: "8px",
                            padding: "10px"
                        }}
                    >
                        {props.resource?.resource}
                    </div>
                }
                {
                    (typeOfCopilot === "textAnnotation" || typeOfCopilot === 'DLP') &&
                    // <div
                    //     style={{
                    //         width: "300px",
                    //         height: "300px",
                    //         overflow: "auto",
                    //         whiteSpace: "break-spaces",
                    //         border: "1px solid black",
                    //         borderRadius: "10px",
                    //         padding: "6px"
                    //     }}
                    // >
                    //     {props.resource?.resource}
                    // </div>
                    
                    props.resource &&
                    <TextTrimUI
                        key={JSON.stringify(props.resource.trimmedTexts)}
                        resource={props.resource} 
                        updateResourceTrimmedTextsInUI={()=>{}}
                        isInCopilot={true}                    
                        isViewMode={true}
                        scenario={"copilotSideDrawer"}
                        modelType={props.resource?.coPilot?.type}
                    />                    
                }
                
                { props.resource?.status === Status.EXPIRED &&
                    <div className={styles.actionBtns}>
                        <div  onClick={() => setOpenModal(true)}>
                            <DrawerButton status={ResourceActions.REJECT} side="left"/>
                        </div>
                        <div onClick={approveResource}>
                            <DrawerButton status={ResourceActions.APPROVE} side="right"/>
                        </div>
                    </div>
                }
                {[Status.REJECTED, Status.APPROVED].includes(props.resource?.status as Status) && !props.resource?.isInvalidated &&
                    <div className={styles.actionBtns}>
                        <div onClick={() => props?.restoreResource && props.restoreResource()}>
                            <DrawerButton status={ResourceActions.ACTIVE} side="left"/>
                        </div>
                    </div>
                }
                <div style={{marginTop:  props.resource?.status !== Status.DELETED ? '50px' : "0"}}>
                    {
                        typeOfCopilot !== "imageAnnotation" && typeOfCopilot !== "textAnnotation" && typeOfCopilot !== "audioTextTranscription" && typeOfCopilot !== "DLP" &&
                        <ColorSelector allowUserToSetAnyLabel={props.resource?.coPilot.allowUserToSetAnyLabel} selectedColor={props.resource?.label || ""} labelsAvailable={props.resource?.coPilot.labelsAvailable || []} colorChange={handleColorChange} resource={props.resource}/>
                    }
                    {
                        typeOfCopilot === "audioTextTranscription" &&
                        <AudioTranscriptionSection 
                        doNotShowEditButton={true}
                        label={props.resource?.label || ""} 
                        prediction={props.resource?.prediction || ""}
                        mode={'view'} 
                        editedText={props.resource?.label || ""} 
                        onEditClick={()=>{}} 
                        OnSaveClick={()=>{}} 
                        OnCancelClick={()=>{}} 
                        isSaving={false} 
                        onEditedTextChange={()=>{}} 
                        mistakesMode={audioTranscriptionSectionState.mistakesMode} 
                        onMistakeModeChange={(misTakeModeToSet)=>{
                            setAudioTranscriptionSectionState(oldState=>{
                              return {
                                ...oldState,
                                mistakesMode: misTakeModeToSet
                              }
                            })
                          }} 
                        audioTranscriptionWordsMetadatas={props.resource?.audioTranscriptionWordsMetadatas || []} 
                        audioCurrentTime={currentAudioTime || 0}                            
                        />
                    }
                </div>
                {
                    typeOfCopilot !== "imageAnnotation" &&
                    typeOfCopilot !== "textAnnotation" && 
                    typeOfCopilot !== "DLP" &&
                    <div className={styles.confidenceContainer}>
                    <div>{ props.resource?.confidenceScore}%</div>
                    </div>
                }
            </div>
            <div 
                className={`
                    ${styles.resourceInfo}
                    ${typeOfCopilot === "imageAnnotation" || typeOfCopilot === "textAnnotation" || typeOfCopilot === "DLP" ? "padding-top-0" : ""}
                `
                }
            >
                {
                    typeOfCopilot !== "text" && typeOfCopilot !== "textAnnotation"  && typeOfCopilot !== "DLP" &&
                    <div><p className={styles.resourceText}>File Name:</p><p><FileNameClipper fileName={props.resource?.filename} currentPage="viewDetails"/></p></div>
                }
                <div><p className={styles.resourceText}>Tag:</p><p>{props.resource?.tag}</p></div>
                <div><p className={styles.resourceText}>Created On:</p><p>{formatDate( dateFormats.mmm_d_yyyy, props.resource?.created_at)}</p></div>
                <div><p className={styles.resourceText}>Confidence Score:</p><p>{props.resource?.confidenceScore}</p></div>
                <div><p className={styles.resourceText}>Last Modified:</p><p>{convertDate(props.resource?.statusLastModifiedAt || "")}</p></div>
                { props.resource?.status === Status.REJECTED &&
                  <div><p className={styles.resourceText}>Rejected Reason:</p><p style={{color: "#008D9C"}}>{props?.resource?.rejectedReason || "NA"}</p></div>
                }

                <div
                    style={{
                        display: "flex"
                    }}
                >
                    <p className={styles.resourceText}>CSV:</p>
                    <p
                        style={{
                            display: "flex",
                            flexDirection: csvExpandedFormShowing ? "column" : "row",
                            width: "auto",
                            overflow: "hidden",
                            whiteSpace: "normal",
                            textOverflow: "ellipsis",
                            wordWrap: "break-word"                            
                        }}
                    >
                        {
                            props.resource?.csv?.length || 0 > csvInitialMaxLengthLimit
                            ? csvExpandedFormShowing
                                ? <><ShowLessLink onClick={()=>setCsvExpandedFormShowing(false)} /> {" "} <span dangerouslySetInnerHTML={{__html: props.resource?.csv || ""}} /> {"  "} </>
                                : <span dangerouslySetInnerHTML={{__html: props.resource?.csv?.substring(0, csvInitialMaxLengthLimit)+"..."}} />
                            : <span dangerouslySetInnerHTML={{__html: props.resource?.csv || ""}} />
                        }
                        {
                            props.resource?.csv?.length || 0 > csvInitialMaxLengthLimit
                            ? csvExpandedFormShowing
                                ?   (
                                    <ShowLessLink onClick={()=>setCsvExpandedFormShowing(false)} />
                                )
                                :   (
                                    <ShowMoreLink onClick={()=>setCsvExpandedFormShowing(true)} />
                                )
                            : null
                        }                    
                    </p>
                </div>
                {
                    ((![Status.REJECTED, Status.APPROVED, Status.DELETED].includes(props.resource?.status as Status) && typeOfCopilot === "imageAnnotation") ||
                    typeOfCopilot === "image")
                    &&
                    <ImageAnnotationFullScreenButton type={typeOfCopilot || "image"}
                     onClick={() => {
                         setIsImageAnnotationModalOpen(true);
                     }}
                 /> 
                }
                { ![Status.REJECTED, Status.APPROVED, Status.DELETED].includes(props.resource?.status as Status) &&
                  <div className={styles.remarkInputContainer}>
                        <RemarksSection key={props.resource?._id}
                        updateResourceInUI={updateResourceInUI}
                            resourceRemarksTextState={resourceRemarksTextState}
                            setResourceRemarksTextState ={setResourceRemarksTextState}
                            isResourceRemarksBeingUpdatedInDB={false}
                            resourceId={props.resource?._id || ""}
                            setShouldBlockDataTableKeyboardEvents={props.setShouldBlockDataTableKeyboardEvents}
                            label={"Add Remarks"}
                            labelColor={"#838C95"}
                            allowUserToSetAnyLabel={false}
                            // updateCopilotResourceRemarks = {updateRemarks}
                            isShowingInSideDrawer={true}
                            onSaveRemarksClick={()=>{
                                updateRemarks(resourceRemarksTextState.value)
                                setResourceRemarksTextState(oldState=>{return {
                                    ...oldState,
                                    isDirty: false,
                                    isTouched: false
                                }})                                
                            }}
                          />
                    </div>
                }
                
            </div>
        </div>
            }
        </>
    )
}

const mapProps = (state: IReduxState) => {
    return {
      resource: state.coPilotDetailPage.coPilotResourceSelectedForSeeingDetails,
      status: state.coPilotDetailPage.coPilotResourceSelectedForSeeingDetails?.status,
      typeOfCopilot:state.coPilotDetailPage.coPilotResourceSelectedForSeeingDetails?.coPilot.type,
      resourcelist:state.coPilotDetailPage.coPilotResourcesState.coPilotResources,
      copilotSelectedId:state.coPilotDetailPage.coPilotResourceIdSelectedForSeeingDetails,
      drawerState:state.optionsDrawer.isOpen,
      totalCount: state.coPilotDetailPage.coPilotResourcesState.totalCount
    };
  };
  
  const connector = connect(mapProps, {
    updateResourceInUI,
    setShouldBlockDataTableKeyboardEvents,
    updateSelectedCoPilotResource
  });
  
  export type TPropsFromRedux = ConnectedProps<typeof connector>;
  
  export default connector(AddRemark);
import React, { useEffect, useContext, useState, useCallback } from 'react';
import PropTypes from 'prop-types';
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableContainer from "@material-ui/core/TableContainer";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import Paper from "@material-ui/core/Paper";
import { CircularProgress, makeStyles, Tooltip } from "@material-ui/core";
import PlayCircleOutlineIcon from "@material-ui/icons/PlayCircleOutline";
import TextField from '@material-ui/core/TextField';
// import PublishIcon from "@material-ui/icons/Publish";
import DeleteOutlineIcon from "@material-ui/icons/DeleteOutline";
import { SpectrogramLabelField } from '../../../../../../../../features/admin/features/projects/features/coPilot/features/SpectrogramLabelField';


const useStyles = makeStyles((theme) => ({
   playPauseButtonOfOriginalAudio: {
     width: "40px",
     height: "40px",
     marginRight: "11px",
     cursor: "pointer"
   },
   trimmedAudiosTableHeadCell: {
     fontWeight: 600,
     // backgroundColor: "#f5f5f5"
   },
   trimmedAudiosTableCellAnnotationColor: {
     width: "158px"
   },
   trimmedAudiosTableCellStartTimeColor: {
     width: "72px"
   },
   trimmedAudiosTableCellEndTimeColor: {
     width: "67px"
   },
   trimmedAudiosTableCellActions: {
     width: "122px"
   },
   trimAudioTableRowDisabled: {
     opacity: 0.4,
     pointerEvents: "none"
   },
   trimmedAudiosTableDivContainer: {
     height: "calc(100% - 176px)"
   },
 }));
 const modelAnnotationLabelsAvailable = ["door", "close", "open", "stop"];

const AnnotationsRegionList = (props) => {
   const {trimmedAudioAnnotation, setTrimmedAudioAnnotation, labelsAvailable, allowAnyLabel} = props;
   console.log("trimmed", trimmedAudioAnnotation);
   // console.log("trimmedAudioAnnotations", trimmedAudioAnnotation);
   const classes = useStyles();
   const [spectrogramAnnotationsLabel, setSpectrogramAnnotationsLabel] = useState([]);

   const handleTrimAudioDeleteClick = (id) => {
    setTrimmedAudioAnnotation(prevState => {
      return [...prevState.filter(annotation => annotation._id!=id)]
    })
   }

   useEffect(() => {
      const labels = labelsAvailable.map((label) => {
         return {id: label.value, value: label.value};
      })
      setSpectrogramAnnotationsLabel(labels);
   }, [labelsAvailable])

   const labelChanged = (label, audioId) => {
      const index = trimmedAudioAnnotation.findIndex((annotation) => annotation._id === audioId);
      const newTrimmedAudioAnnotation = [...trimmedAudioAnnotation];
      const trimAudio = trimmedAudioAnnotation.find((audio)=>audio._id === audioId);
      newTrimmedAudioAnnotation[index] = {...trimAudio, label: label}
      trimAudio.label = label;
      setTrimmedAudioAnnotation(newTrimmedAudioAnnotation);
   }

   const confidenceScoreChanged = (confidenceScore, annotationId, index) => {
    if(confidenceScore<0||confidenceScore>100) return;
    setTrimmedAudioAnnotation((prevState) => {
      let updatedAnnotation = prevState.find(annotation => annotation._id===annotationId)
      let updatedAnnotations = prevState;
      updatedAnnotations[index] = {...updatedAnnotation, confidenceScore: confidenceScore}
      return [
        ...updatedAnnotations
      ]
    })
 }

	return (
		<TableContainer component={Paper}
        style={{
          // maxHeight: "300px"
          maxHeight: "calc(100%)"
        }}
      >
       <Table>
        <TableHead>
         <TableRow>
          <TableCell className={`${classes.trimmedAudiosTableHeadCell}`} style={{width:"5",maxWidth:"5%"}}>Color</TableCell>
          <TableCell className={`${classes.trimmedAudiosTableHeadCell} ${classes.trimmedAudiosTableCellStartTimeColor}`} style={{width:"5%",maxWidth:"5%"}}>Start</TableCell>
          <TableCell className={`${classes.trimmedAudiosTableHeadCell} ${classes.trimmedAudiosTableCellEndTimeColor}`} style={{width:"5%",maxWidth:"5%"}}>End</TableCell>
          <TableCell className={`${classes.trimmedAudiosTableHeadCell} ${classes.trimmedAudiosTableCellAnnotationColor}`}  style={{width:"5%",maxWidth:"5%"}} >Confidence Score</TableCell>
          <TableCell className={`${classes.trimmedAudiosTableHeadCell} ${classes.trimmedAudiosTableCellAnnotationColor}`} >Label</TableCell>
          <TableCell className={`${classes.trimmedAudiosTableHeadCell} ${classes.trimmedAudiosTableCellActions}`}>Actions</TableCell>
         </TableRow>
        </TableHead>
        <TableBody>
         {trimmedAudioAnnotation
         .map((trimmedAudio, trimAudioIndex) => {
          return (
           <TableRow key={trimmedAudio._id}
           >
            <TableCell>
              <div 
                style={{
                  backgroundColor: trimmedAudio.color,
                width:"30px",
                  height: "30px",
                  borderRadius: "4px",
                }}
              ></div>
            </TableCell>
            <TableCell>{trimmedAudio.startTimeInSeconds !== -1 ? trimmedAudio.startTimeInSeconds ? (trimmedAudio.startTimeInSeconds|| 0).toFixed(2) : "Loading" : "Loading"}</TableCell>
            <TableCell>{trimmedAudio.endTimeInSeconds !== -1 ? trimmedAudio.endTimeInSeconds ? (trimmedAudio.endTimeInSeconds||0).toFixed(2) : "Loading" : "Loading"}</TableCell>
            <TableCell >
            <TextField value={trimmedAudio.confidenceScore===-1?"":trimmedAudio.confidenceScore}
                  onChange={(event, value) => {
                    confidenceScoreChanged(event.target.value, trimmedAudio._id, trimAudioIndex)
                  }}
             type='number' id="filled-basic" size='small' />
            </TableCell>
            <TableCell>
             <SpectrogramLabelField label={trimmedAudio?.label} update={labelChanged} audioId={trimmedAudioAnnotation[trimAudioIndex]?._id} modelAnnotationLabelsAvailable={spectrogramAnnotationsLabel} allowAnyLabel={allowAnyLabel}/>
            </TableCell>
            <TableCell>
                  <>
                    <Tooltip title="Play the audio">
                      <PlayCircleOutlineIcon
                        color="primary"
                        style={{
                          marginRight: "20px",
                          cursor: "pointer"
                        }}

                        onClick={() => {
                          props.setSelectedSpectrogramAnnotation(trimmedAudio._id)
                        }}
                       
                      />
                    </Tooltip>
                    <Tooltip title="Delete the audio">
                      <DeleteOutlineIcon
                        color="error"
                        style={{
                          cursor: "pointer"
                        }}
                        onClick={() => {
                        handleTrimAudioDeleteClick(trimmedAudio._id);
                        }}
                      />             
                    </Tooltip>
                  </>
              
            </TableCell>
           </TableRow>
          );
         })}
        </TableBody>
       </Table>
      </TableContainer>
	);
};

export default AnnotationsRegionList;

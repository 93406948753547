import { API } from "../../../../../../../services/API";

const SAVED_DATASET_URL = '/datacart/savedDatasetsOfUser'

class SavedDatasetService extends API {
    createDataSet(payload: {
        name: string, rlefResourceIds: string[], localFileNames?: string[], userEmail: string
    }) {
        return this.api.post(SAVED_DATASET_URL, payload);
    }

    DatasetExistence(payload: {
        resourceIds: string[], userEmail: string
    }) {
        return this.api.post('/datacart/savedDatasetExistence', payload)
    }
}

export default new SavedDatasetService();
type Props = {
  IconComponentProps?: any,
  isActive?: boolean
}

const ModelTypePrompt = (props: Props) => {
  return (
    <div className={props.IconComponentProps.className} style={{ fontSize: '15px', color: props?.isActive !== undefined && props.isActive === false ? '#898989' : '#008D9C', fontWeight: 'bold', width: '11px' }}>
      <span>P</span>
    </div>
  )
}

export default ModelTypePrompt
import { Theme } from "@material-ui/core";
import { CSSProperties } from "@material-ui/styles";

export const tablePaperStyle = (theme: Theme): CSSProperties => {
  return {
    width: "100%",
    marginBottom: theme.spacing(2),
    height: "calc(100% - 50px)",
  };
};
export const tableContainerStyle: CSSProperties = {
  height: "calc(100% - 115px)",
  overflowY: "auto",
};

import { useContext, useEffect, useState } from 'react'
import './Timer.css'
import alramIcon from '../../assets/alarm.png'
import finishedIcon from '../../assets/check_circle.png'
import ChatContext from '../../../../../chat/contexts/chat.context'

type props = {
    stateValue : string
}

function Timer({ stateValue } : props) {
	const [progressDone, setProgressDone] = useState(true)
	const chatContext = useContext(ChatContext)

	useEffect(() => {
		let timeoutId : NodeJS.Timeout;
		if(stateValue === 'Uploaded') timeoutId = setTimeout(() => setProgressDone(true), 1000)
		else setProgressDone(false)
	
		chatContext.setIsTestDriveCompleted(stateValue === 'Uploaded');
		return () => clearTimeout(timeoutId)
	}, [stateValue])

	return (
		<div className='timer__body'>
			<img src={progressDone ? finishedIcon : alramIcon} />
			<div>
				{progressDone ? "Your results are ready!" : "This process may take time. Please wait or click on the below button to get notified when the process is done."}
			</div>
		</div>
	)
}

export default Timer
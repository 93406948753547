import Button from "@material-ui/core/Button";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogContentText from "@material-ui/core/DialogContentText";
import DialogTitle from "@material-ui/core/DialogTitle";
import { connect, ConnectedProps } from "react-redux";
import { IReduxState } from "../../../../../../../../../../../common/constants/interfaces/redux";

interface IProps extends TPropsFromRedux {
  open: boolean;
  resourcesCountWhichWillBeAffected: number;
  onClose: () => void;
  onConfirmClick: () => void;
}

export function WarnUserBeforeUpdatingDataRowsWhichAreNotShowingDialog(
  props: IProps
) {
  const { open, resourcesCountWhichWillBeAffected } = props;
  return (
    <div>
      <Dialog
        open={open}
        onClose={() => props.onClose()}
        maxWidth="sm"
        // fullScreen={true}
        fullWidth={true}
        aria-labelledby="responsive-dialog-title"
      >
        <DialogTitle id="responsive-dialog-title">
          Confirm bulk action
        </DialogTitle>
        <DialogContent>
          <DialogContentText>
            This action will affect all {resourcesCountWhichWillBeAffected}{" "}
            datas matching table filter. Are you sure you want to continue?
          </DialogContentText>
        </DialogContent>
        <DialogActions style={{ padding: "16px 24px", paddingTop: "8px" }}>
          <Button size="small" onClick={() => props.onClose()}>
            Cancel
          </Button>
          <Button
            size="small"
            onClick={() => props.onConfirmClick()}
            color="primary"
            variant="contained"
          >
            OK
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
}

const mapProps = (state: IReduxState) => {
  return {};
};

const connector = connect(mapProps, {});

export type TPropsFromRedux = ConnectedProps<typeof connector>;

export default connector(
  WarnUserBeforeUpdatingDataRowsWhichAreNotShowingDialog
);

import { Breadcrumbs, Chip, Button, Dialog, DialogContent, DialogTitle, Divider, IconButton, Typography, makeStyles, DialogActions, Tabs, Tab, Box, ListItem, ListItemAvatar, Avatar, ListItemText, ListItemSecondaryAction, CircularProgress, Checkbox, ListItemIcon } from '@material-ui/core';
import { CartCheckIcon, CheckoutCartIcon, IData, IModel, IProject, SaveForLaterInCart, ShoppingCart } from '../../../../../../../../../../../common';
import DataSetCollection from './DataSet-Collection';
import { useContext, useEffect, useState } from 'react';
import { useHistory } from 'react-router';
import CloseIcon from '@material-ui/icons/Close';
import BrowseCollectionsPopupContext from '../../../../../browseCollectionsPopupContext/browseCollectionsPopup.context';
import { Theme } from '../../../../../../../../../../../theme';
import { ModelService, ProjectService } from '../../../../../../../../../../../services';
import ModelsList from '../../../../../components/ModelsList';
import ProjectsList from '../../../../../../../components/ProjectsList';
import { useStyles as useStyles_2 } from '../dataMapping/features/SelectedDataListDialog';
import KeyboardArrowRightIcon from '@material-ui/icons/KeyboardArrowRight';
import DropdownWithRadio from '../../../../../../../../../../../common/components/form/dropdown-with-radio';
import InputLabelWithInfo from '../../../../../../../../../../../common/components/form/input-label-with-info';
import ShoppingCartOutlinedIcon from '@material-ui/icons/ShoppingCartOutlined';
import TableContent from '../../data/components/TableContent';
import { deleteLocalResourcesFromDataCart, updateDataCart, uploadALocalResourceInDataCart } from '../../../../../../aiMarketPlace/aiMarketPlaceServices';
import { Upload } from '@mui/icons-material';
import { Stack } from '@mui/material';
import { Delete } from '@material-ui/icons';
import { deleteButtonIconColor } from '../../data/constants/constants';
import { IDataCartLocalResourceDetails } from '../../../../../../aiMarketPlace/aiMarketPlaceInterfaces/interfaces';

type scenario = "ai-assistant-labelling" | "ai-marketplace-datacart"
type aiMarketPlacePages = "aiMarketplaceEmptyCart" | "aiMarketplaceSelectProjectAndTask" | "aiMarketplaceDataSetPage" | "aiMarketPlaceDataPage" | "aiMarketplaceSelectedDataPage" | "aiMarketplaceLocalDataUploadPage"

type browseCollectionsProps = {
    isOpen: boolean;
    setOpen: (newState: boolean) => void;
    projectId?: string;
    modelId?: string;
    modelType?: string;
    resourceDataSetCollectionIds: string[];
    originalSearchQuery: string;    //the search query in url before the popup, just pass window.location.search
    setDataCartOpen: (newState: boolean) => void;
    setSavedForLaterOpen: (newState: boolean) => void;
    scenario?: scenario
}

const useStyles = makeStyles((theme) => ({
    browseCollectionsPopupPaper: {
        maxWidth: '100%',
        height: '97%'
    },
    breadCrumbWrapper: {
        '&:last-child' : {
            color: Theme.palette.primary.main
        },
    },
    breadCrumb: {
        '&:hover': {
            textDecoration: 'underline',
            cursor: "pointer"
        }
    },
    tabsRoot: {
        backgroundColor: 'white !important',
        borderBottom: '1px solid lightgrey'
    },
    imageThumbnail: {
        width: '3rem',
        height: '3rem',
        display: 'block',
        margin: 'auto',
        borderRadius: 6,
    },
    listItemRoot: {
        listStyle: 'none !important',
        listStyleType: 'none !important',
        borderRadius: '1rem',
        backgroundColor: theme.palette.grey[50]
    },
    listItemAvatar: {
        overflow: 'hidden',
        objectFit: 'contain'
    }
}))

const BrowseCollectionsPopup = (props: browseCollectionsProps) => {

    const classes = useStyles()
    const classes2 = useStyles_2()
    const history = useHistory()
    const context = useContext(BrowseCollectionsPopupContext)
    const path = window.location.pathname

    const [page, setPage] = useState<"collections" | "models" | "projects" | aiMarketPlacePages>('projects')
    // const [model, setModel] = useState<IModel | null>(null)
    const [modelName, setModelName] = useState<string | null>(null)
    const [projectName, setProjectName] = useState<string | null>(null)
    const [selectedTabInAiMarketplaceSelectedDataPage, setSelectedTabInAiMarketplaceSelectedDataPage] = useState<"RLEF Data" | "RLEF Collections" | "Local Data">("RLEF Data")
    const [rlefResourcesInDataCart, setRlefResourcesInDataCart] = useState<IData[]>([]);
    const [localFiles, setLocalFiles] = useState<File[]>([]);
    const [selectedLocalFilesIds, setSelectedLocalFilesIds] = useState<string[]>([]);

    // loading flags ............................
    const [isSavingItemsInDB, setIsSavingItemsInDB] = useState<boolean>(false)
    const [localResourceIdsWhichAreBeingDeleted, setLocalResourceIdsWhichAreBeingDeleted] = useState<string[]>([]);

    const projectsState = context.projectsState;
    const tasksState = context.tasksState;


    async function fetchModelName(modelId: string) {
        
        const apiResponse = await ModelService.getModelDetails({
            modelId: modelId,
            modelSelectQuery: 'name project'
        })

        if (apiResponse.data) {
            setModelName(apiResponse.data.name)
            
        }
    }

    async function fetchProjectName(projectId: string) {
       
        const res = await ProjectService.getProjectDetailsById({
            id: projectId
        })

        if (res.data.name) {
            setProjectName(res.data.name)
           
        }
    }


    //set path
    useEffect(() => {
        if(props.scenario !== 'ai-marketplace-datacart'){
            fetchModelName(props.modelId || '')
            fetchProjectName(props.projectId || '')
        
            if (props.isOpen) {
                let newPath;
                if(!props.projectId){
                    setPage("projects")
                    newPath = `${path}`
                }
                else if(!props.modelId){
                    setPage("models")
                    newPath = `${path}?offset=1&limit=1000&project=${props.projectId}&view=model`
                }
                else {
                    setPage("collections")
                    newPath = `${path}?resourceStatus=collections&resourceOffset=1&resourceLimit=10&project=${props.projectId}&model=${props.modelId}&type=audio&resourceSortOrder=descending&resourceSortField=statusLastModifiedAt&resourceDataSetCollectionIds=${String(props.resourceDataSetCollectionIds)}`
                }
                history.replace(newPath)
            }
            context.setBreadCrumbs([])
        }
        else if (props.scenario === 'ai-marketplace-datacart' && props.isOpen){
            // set initial states of ai marketplace datacart
            // context.setAiMarketplaceDataCartInitialStateFromDB()

            if(context.aiMarketplaceDataCartState.rlefResources?.length || context.aiMarketplaceDataCartState.localFiles.length){
                setPage("aiMarketplaceSelectedDataPage")
            }
            else if(projectsState.selectedProject && tasksState.selectedTask){
                setPage("aiMarketplaceDataSetPage")
                setPathForRLEFDataPickingPage();
            }
            else if(projectsState.selectedProject || tasksState.selectedTask){
                setPage("aiMarketplaceSelectProjectAndTask")
            }
            else {
                setPage('aiMarketplaceEmptyCart')
            }
        }
        
    }, [props.isOpen])

    useEffect(() => {
        if(props.scenario === 'ai-marketplace-datacart' && props.isOpen && page === 'aiMarketplaceSelectProjectAndTask'){
            context.fetchAllProjects()
        }
        if(props.scenario === 'ai-marketplace-datacart' && props.isOpen && context.projectsState.selectedProject && page === 'aiMarketplaceSelectProjectAndTask'){
            context.fetchAllTasks(projectsState.selectedProject?._id || '');
        }
    }, [props.isOpen, props.scenario, projectsState.selectedProject, page])

   
 
    //set breadCrumbs
    useEffect(() => {
        if(props.scenario !== 'ai-marketplace-datacart'){
        if(!projectName) fetchProjectName(props.projectId || '')
        if(!modelName) fetchModelName(props.modelId || '')

        switch (page) {
            case "projects":
                context.setBreadCrumbs([
                    "Home"
                ])
                break;
            case "models":
                context.setBreadCrumbs([
                    "Home",
                    `${projectName}`
                ])
                break;
            case "collections":
                
                context.setBreadCrumbs([
                    "Home",
                    `${projectName}`,
                    `${modelName}`
                ])
                break;
            default:
                break;
        }
        }

        if(props.scenario === 'ai-marketplace-datacart'){
            switch(page) {
                case 'aiMarketplaceDataSetPage':
                    context.setBreadCrumbs([
                        "Project & task selection",
                        "Collections"
                    ])
                    break;
                case 'aiMarketplaceSelectProjectAndTask':
                    context.setBreadCrumbs([
                        "Project & task selection"
                    ])
                    break;
                case 'aiMarketplaceEmptyCart':
                    context.setBreadCrumbs([])
                    break;
                case 'aiMarketplaceSelectedDataPage':
                    context.setBreadCrumbs([]);
                    break;
                case 'aiMarketplaceLocalDataUploadPage':
                    context.setBreadCrumbs([
                        "Drop data"
                    ])
                    break;
                default:
                    break;
            }
        }
    }, [context.setBreadCrumbs, projectName, modelName, page,props.isOpen])
    
  
    

    const handleDrop = (event: any) => {
        event.preventDefault();
        const droppedFiles = Array.from(event.dataTransfer.files) as File[];
        setLocalFiles(prevFiles => [...prevFiles, ...droppedFiles]);
    };

    const handleFileSelect = (event: any) => {
        const selectedFiles = Array.from(event.target.files) as File[];
        setLocalFiles(prevFiles => [...prevFiles, ...selectedFiles]);
    };

    const onClickAddDataFromRlef = () => {
        setPage("aiMarketplaceSelectProjectAndTask")
    }

    const onClickAddDataFromLocal = () => {
        setPage('aiMarketplaceLocalDataUploadPage')
    }

    function onClose() {
        props.setOpen(false)
        setTimeout(() => {
            history.replace(`${path}${props.originalSearchQuery}`)
        }, 300)
        if(props.scenario === 'ai-marketplace-datacart'){
            context.resetLocalStatesOnDialogClose()
        }
    }

    function onModelViewClick(model: IModel) {
        setModelName("")
        fetchModelName(model._id)
        setPage("collections")
        const newPath = `${path}?resourceStatus=collections&resourceOffset=1&resourceLimit=10&project=${model.project._id}&model=${model._id}&type=audio&resourceSortOrder=descending&resourceSortField=statusLastModifiedAt`
        history.push(newPath)
        context.setBreadCrumbs((prevState: string[]) => {
            return [...prevState, model.name]
        })
    }

    function onProjectClick(project: IProject) {
        setProjectName("")
        fetchProjectName(project._id)
        setPage("models")
        const newPath = `${path}?offset=1&limit=1000&project=${project._id}&view=model`
        history.push(newPath)
        context.setBreadCrumbs((prevState: string[]) => {
            return [...prevState, project.name]
        })
    }

    const onSelectProject = (event: any) => {
        context.selectProject(event.target.value as IProject)
    }

    const onSelectTask = (event: any) => {
        context.selectTask(event.target.value as IModel)
    }

    // const getSelectedRLEFResources = async () => {
    //     try {
    //         const response = await DataService.getData({
    //             ids: JSON.stringify(context.aiMarketplaceDataCartState.resourceIds)
    //         })
    //         setRlefResourcesInDataCart(response.data.resources)
    //     } catch (error) {
    //         console.error('Error fetching RLEF resources:', error)
    //     }
    // }

    function formatFileSize(sizeInBytes: number) {
        const sizes = ['bytes', 'KB', 'MB', 'GB', 'TB'];
        if (sizeInBytes === 0) return '0 bytes';
        const i = Math.floor(Math.log(sizeInBytes) / Math.log(1024));
        const size = sizeInBytes / Math.pow(1024, i);
        return `${size.toFixed(2)} ${sizes[i]}`;
    }

    // delete specified file from the localFiles state
    function deleteALocalFileFromLocalState (fileName: string) {
        setLocalFiles(prevFiles => prevFiles.filter(file => file.name!== fileName))
    }

    async function deleteALocalFileFromDB (fileName: string) {
        const localResourceId = context.aiMarketplaceDataCartState.localResources?.find(r => r.filename === fileName)?._id || ''
        setLocalResourceIdsWhichAreBeingDeleted(prev => [...prev, localResourceId])
        await deleteLocalResourcesFromDataCart({
            dataCartId: context.aiMarketplaceDataCartState.dataCartId || '',
            resourceIds: [localResourceId]
        })
        await context.setAiMarketplaceDataCartInitialStateFromDB()
        setLocalResourceIdsWhichAreBeingDeleted(prev => [...prev.filter(id => id !== localResourceId)])
    }

    const renderEmptyDataCartScreen = () => {
        return <div style={{display: 'flex', alignItems: 'center', justifyContent: 'center', flexDirection: 'column', gap: '1rem', height: '100%'}}>
            <ShoppingCart />
            <Typography variant='h4' style={{color: 'rgba(0,0,0,0.3)', marginBottom: '1rem'}}>Please add data to your <strong>Data Cart</strong> to try out</Typography>
            <Button variant='contained' color='primary' style={{textTransform: 'none', minWidth: '10rem'}} onClick={onClickAddDataFromRlef}>
                Add data from RLEF
            </Button>
            <Button variant='outlined' color='primary' style={{textTransform: 'none', minWidth: '10rem'}} onClick={onClickAddDataFromLocal}>
                Add data from local
            </Button>
        </div>
    }

    const renderSelectProjectAndTaskScreen = () => {

        return <div style={{display: 'flex', alignItems: 'center', justifyContent: 'center', flexDirection: 'column', gap: '2rem', height: '100%'}}>
            <div style={{minWidth: '50%'}}>
                <InputLabelWithInfo label='Select Project' />
                <DropdownWithRadio
                    searchBarVisible
                    searchTerm={projectsState.searchTerm}
                    setSearchTerm={context.setProjectsSearchTerm}
                    items={projectsState.availableProjects}
                    valueKey='_id'
                    displayNameKey='name'
                    selectedValue={projectsState.selectedProject}
                    isItemsLoading={projectsState.isFetchingProjects}
                    onChange={onSelectProject}
                />
            </div>
            <div style={{minWidth: '50%'}}>
                <InputLabelWithInfo label='Select Task' />
                <DropdownWithRadio
                    items={tasksState.availableTasks}
                    selectedValue={tasksState.selectedTask}
                    isItemsLoading={tasksState.isFetchingTasks}
                    valueKey='_id'
                    displayNameKey='name'
                    onChange={onSelectTask}
                    searchBarVisible
                    setSearchTerm={context.setTasksSearchTerm}
                    searchTerm={tasksState.searchTerm}
                />
            </div>
            
        </div>
    }

    const renderRLEFDataPickingPage = () => {
        return <div style={{ backgroundColor: 'white' }}>
            <Tabs classes={{ root: classes.tabsRoot }}
                value={
                    page === 'aiMarketplaceDataSetPage' ? "Collections"
                        : page === 'aiMarketPlaceDataPage' ? "Data"
                            : ''
                }
            >
                <Tab label="Collection" value="Collections" disableRipple disableFocusRipple disableTouchRipple 
                    onClick={() => {
                        if(page !== 'aiMarketplaceDataSetPage') setPage('aiMarketplaceDataSetPage')
                    }}
                />
                <Tab label="Data" value="Data" disableRipple disableFocusRipple disableTouchRipple 
                    onClick={() => {
                        if(page !== 'aiMarketPlaceDataPage') setPage('aiMarketPlaceDataPage')
                    }}
                />
            </Tabs>
            {
                page === 'aiMarketplaceDataSetPage'
                &&
                <DataSetCollection scenario={"aiMarketplaceDataSelection"} />
            }
            {
                page === 'aiMarketPlaceDataPage'
                &&
                <Typography>Coming soon...</Typography>
            }
        </div>
    }

    const renderAiMarketplaceSelectedDataPage = () => {
        return <div style={{height: '100%', overflow: 'hidden'}}>
            <Tabs classes={{root: classes.tabsRoot}}
                value={selectedTabInAiMarketplaceSelectedDataPage}
            >
                <Tab label="RLEF Data" onClick={() => setSelectedTabInAiMarketplaceSelectedDataPage("RLEF Data")} value="RLEF Data" disableRipple disableFocusRipple disableTouchRipple />
                <Tab label="RLEF Collections" value="RLEF Collections" onClick={() => setSelectedTabInAiMarketplaceSelectedDataPage("RLEF Collections")} disableRipple disableFocusRipple disableTouchRipple />
                <Tab label="Local Data" value="Local Data" onClick={() => setSelectedTabInAiMarketplaceSelectedDataPage("Local Data")} disableRipple disableFocusRipple disableTouchRipple />
            </Tabs>
            {
                selectedTabInAiMarketplaceSelectedDataPage === 'RLEF Data'
                &&
                <TableContent
					scenario={"aiMarketPlaceDataCart"}
					rowsForDataCart={context.aiMarketplaceDataCartState.rlefResources}
					// currentSelectedItems={selectedItems}
					// setCurrentSelectedItems={setSelectedItems}
				/>
            }
            {
                selectedTabInAiMarketplaceSelectedDataPage === 'Local Data'
                &&
                <>
                    {
                        !context.aiMarketplaceDataCartState.localResources?.length ? <Box style={{display: 'flex', alignItems: 'center', justifyContent: 'center', height: '90%'}}>No data found!</Box>
                        : renderLocalFilesList({
                                files: context.aiMarketplaceDataCartState.localResources || [], 
                                fileDeleteCallback: deleteALocalFileFromDB,
                                checkboxesVisible: true,
                                selectedItems: selectedLocalFilesIds,
                                onChangeItemSelection: (_id: string, checked: boolean) => { checked ? setSelectedLocalFilesIds(prev => [...prev, _id]) : setSelectedLocalFilesIds(prev => [...prev.filter(id => id !== _id)]) }
                            })}
                </>
            }
            {
                selectedTabInAiMarketplaceSelectedDataPage === 'RLEF Collections'
                &&
                <DataSetCollection scenario={"aiMarketplaceSelectedDataList"} />
            }
        </div>
    }

    const renderLocalFilesList = (props: {
        files: File[] | IDataCartLocalResourceDetails[],
        fileDeleteCallback: (fileName: string) => void | Promise<void>,
        listHelperText?: string,
        checkboxesVisible?: boolean,
        selectedItems?: Array<string>,
        onChangeItemSelection?: (itemId: string, checked: boolean) => void
    }) => {
        return <Box style={{overflowY: 'auto', height: 'calc(100% - 35px)', padding: '2rem',}} >
            <div style={{marginBottom: '1rem'}}>
                {props.listHelperText}
            </div>
            <Stack style={{ listStyle: 'none', gap: '1rem' }}>
                {
                    props.files.map((file, index) => (
                        <ListItem key={`localfile-${index}`} classes={{ root: classes.listItemRoot }} >
                            {
                                props.checkboxesVisible
                                &&
                                <ListItemIcon style={{marginRight: -24}}>
                                    <Checkbox
                                        edge="start"
                                        //@ts-ignore
                                        onChange={(e) => props.onChangeItemSelection(file._id, e.target.checked)}
                                        //@ts-ignore
                                        checked={props.selectedItems?.includes(file._id)}
                                    />
                                </ListItemIcon>
                            }
                            <ListItemAvatar classes={{ root: classes.listItemAvatar }} >
                                {/* @ts-ignore */}
                                <img src={file.signedUrl ? file.signedUrl : URL.createObjectURL(file)} alt="" className={classes.imageThumbnail} />
                            </ListItemAvatar>
                            {/* @ts-ignore */}
                            <ListItemText primary={file.name || file.filename} secondary={file.size ? formatFileSize(file.size) : null} />
                            <ListItemSecondaryAction>
                                {/* @ts-ignore */}
                                <IconButton onClick={async () => await props.fileDeleteCallback(file.name || file.filename)}
                                    // @ts-ignore
                                    disabled={localResourceIdsWhichAreBeingDeleted.includes(file._id || '')}
                                >
                                    {
                                        //@ts-ignore
                                        localResourceIdsWhichAreBeingDeleted.includes(file._id) ?
                                            <CircularProgress size={20} style={{ color: deleteButtonIconColor }} />
                                            : <Delete style={{ color: deleteButtonIconColor }} />
                                    }
                                </IconButton>
                            </ListItemSecondaryAction>
                        </ListItem>
                    ))
                }
            </Stack>
        </Box>
    }

    const renderAiMarketplaceUploadLocalDataPage = () => {
        return <div style={{height: 'calc(100% - 2rem)', display: 'flex', flexDirection: 'column', gap: '2rem', overflow: 'hidden'}}>
            <Box
                onDrop={handleDrop}
                onDragOver={(e) => e.preventDefault()}
                onClick={() => document.getElementById('aiMarketplaceLocalFileInput')?.click()}
                style={{
                    border: `2px dashed ${Theme.palette.primary.main}`,
                    padding: '40px',
                    textAlign: 'center',
                    borderRadius: '10px',
                    cursor: 'pointer',
                    backgroundColor: '#f9fbfc',
                    height: 'auto',
                    minHeight: '40%',
                    flexGrow: 1,
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'center',
                }}
            >
                <input
                    id="aiMarketplaceLocalFileInput"
                    type="file"
                    accept="image/png, image/jpeg"
                    style={{ display: 'none' }}
                    onChange={handleFileSelect}
                    multiple
                />
                <Box>
                    <Upload />
                    <Typography variant="h6" style={{fontWeight: 'bold'}}>Drag & drop your sample data here</Typography>
                    <Typography variant="body2" style={{ margin: '10px 0', color: 'grey' }}>
                        Files supported are png, jpg
                    </Typography>
                    <Typography variant="body1" style={{fontWeight: 'bold'}}>or</Typography>
                    <Button
                        variant="outlined"
                        color="primary"
                        style={{ marginTop: '10px', textTransform: 'none' }}
                        onClick={() => document.getElementById('fileInput')?.click()}
                    >
                        Upload from local
                    </Button>
                </Box>
            </Box>
            {
                localFiles.length > 0
                &&
                renderLocalFilesList({
                    files: localFiles, 
                    fileDeleteCallback: deleteALocalFileFromLocalState,
                    listHelperText: 'Data added from local'
                })
            }
        </div>
    }

    function renderDialogContent(scenerio?: scenario) {
        switch (page) {
            case "collections":
                return <DataSetCollection scenario={"browseCollectionsPopup"} aiAssistantSenerio={scenerio==="ai-assistant-labelling"} />
            case "models":
                return <ModelsList scenario='browseCollectionsPopup' goInsideModel={onModelViewClick} />
            case "projects":
                return <ProjectsList scenario='browseCollectionsPopup' onProjectClick={onProjectClick} />
            case "aiMarketplaceEmptyCart":
                return renderEmptyDataCartScreen();
            case "aiMarketplaceSelectProjectAndTask":
                return renderSelectProjectAndTaskScreen();
            case "aiMarketplaceDataSetPage":
            case "aiMarketPlaceDataPage":
                return renderRLEFDataPickingPage();
            case "aiMarketplaceSelectedDataPage":
                return renderAiMarketplaceSelectedDataPage();
            case "aiMarketplaceLocalDataUploadPage":
                return renderAiMarketplaceUploadLocalDataPage();
            default:
                return <></>
        }
    }

    function onBreadCrumbClick(index: number) {
        if(index === context.breadCrumbs.length - 1) return;
        if(props.scenario === 'ai-marketplace-datacart'){
            switch(index) {
                case 0:
                    setPage("aiMarketplaceSelectProjectAndTask")
                    break;
                case 1:
                    setPage("aiMarketplaceDataSetPage")
                    break;
                default:
                    setPage("aiMarketplaceEmptyCart")
            }
            const newBreadCrumbs = [...context.breadCrumbs]
            newBreadCrumbs.splice(index + 1)
            context.setBreadCrumbs(newBreadCrumbs)
        }
        else {
        switch (index) {
            case 0:
                setPage("projects")
                break;
            case 1:
                setPage("models")
                break;
            case 2:
                setPage("collections")
                break;
            default:
                setPage("collections")
        }

        const newBreadCrumbs = context.breadCrumbs
        newBreadCrumbs.splice(index + 1)
        context.setBreadCrumbs([...newBreadCrumbs])
        }
    }

    const setPathForRLEFDataPickingPage = () => {
        // remove query params project and model from the origianlSearchQuery if they exist
        const queryParamPairs = new URLSearchParams(props.originalSearchQuery.split('?')[1])
        queryParamPairs.delete('project')
        queryParamPairs.delete('model')
        const newSearchQuery = queryParamPairs.toString()

        history.replace(path + '?' + newSearchQuery + `&project=${projectsState.selectedProject?._id}&model=${tasksState.selectedTask?._id}`)
    }

    const onClickNextAfterSelectingProjectAndTask = () => {
        setPage("aiMarketplaceDataSetPage")
        setPathForRLEFDataPickingPage()
    }

    const onClickNextButtonInRlefDataSelectionStep = async () => {
        //save selections in db
        setIsSavingItemsInDB(true)
        await updateDataCart({
            _id: context.aiMarketplaceDataCartState.dataCartId || '',
            rlefResourceIds: context.aiMarketplaceDataCartState.resourceIds,
            rlefDataSetIds: context.aiMarketplaceDataCartState.currentSelectedDataSetIds,
        })
        // get latest state from database
        await context.setAiMarketplaceDataCartInitialStateFromDB()
        setIsSavingItemsInDB(false)

        setPage("aiMarketplaceSelectedDataPage")
        setSelectedTabInAiMarketplaceSelectedDataPage("RLEF Data")
    }

    const handleUploadLocalData = async () => {
        setIsSavingItemsInDB(true);
        for(const file of localFiles){
            await uploadALocalResourceInDataCart({
                dataCartId: context.aiMarketplaceDataCartState.dataCartId || '',
                file: file
            })
        }
        await context.setAiMarketplaceDataCartInitialStateFromDB()
        setIsSavingItemsInDB(false);
        setPage("aiMarketplaceSelectedDataPage")
        setSelectedTabInAiMarketplaceSelectedDataPage("Local Data")
        setLocalFiles([])
    }

    const renderDialogActions = () => {
        if(props.scenario === 'ai-marketplace-datacart'){
            switch(page){
                case 'aiMarketplaceSelectProjectAndTask':
                    return <Button
                     variant='contained'
                     size='small' 
                    style={{textTransform: 'none', minWidth: '6rem'}}
                    color='primary' 
                    onClick={onClickNextAfterSelectingProjectAndTask}
                    disabled={!projectsState.selectedProject || !tasksState.selectedTask}
                    >
                        Next
                    </Button>
                case 'aiMarketplaceDataSetPage':
                case 'aiMarketPlaceDataPage':
                    return <Button
                        variant='contained'
                        size='small' 
                        style={{textTransform: 'none', minWidth: '6rem'}}
                        color='primary' 
                        onClick={onClickNextButtonInRlefDataSelectionStep}
                        disabled={shouldNextButtonBeDisabledInRlefDataSelectionStep}
                    >
                        {
                            isSavingItemsInDB ? 'Saving...' : 'Next'
                        }
                    </Button>
                case 'aiMarketplaceSelectedDataPage':
                    return <Button
                        variant='contained'
                        size='small' 
                        style={{textTransform: 'none', minWidth: '6rem'}}
                        color='primary' 
                        onClick={resetProjectsAndTasksSelection}
                        disabled
                    >
                        Try out
                    </Button>
                case 'aiMarketplaceLocalDataUploadPage':
                    return <Button
                        variant='contained'
                        size='small' 
                        style={{textTransform: 'none', minWidth: '6rem'}}
                        color='primary' 
                        onClick={handleUploadLocalData}
                        disabled={localFiles.length === 0 || isSavingItemsInDB}
                    >
                        {
                            isSavingItemsInDB ? "Saving..."
                            : "Add to cart"
                        }
                    </Button>
                default:
                    return null;
            }
        }

        return null;
    }


    const resetProjectsAndTasksSelection = () => {
        context.selectProject(null)
        context.selectTask(null)
    }

    const returnDialogTitle = (): string => {
        if(props.scenario === 'ai-assistant-labelling'){
            return "Browse Data"
        }
        else if(props.scenario === 'ai-marketplace-datacart'){
            switch(page){
                case 'aiMarketplaceEmptyCart':
                case 'aiMarketplaceSelectedDataPage':
                    return 'Data in cart'
                case 'aiMarketplaceSelectProjectAndTask':
                    return 'Select the project & task'
                case 'aiMarketplaceDataSetPage':
                    return 'Select from collection'
                case 'aiMarketPlaceDataPage':
                    return 'Select data'
            }
        }

        return "Browse Dataset Collection";
    }

    const onClickAddDataFromRLEF = () => {
        setPage("aiMarketplaceSelectProjectAndTask")
    }

    const shouldNotRenderBreadcrumbs = props.scenario === 'ai-marketplace-datacart' && page === 'aiMarketplaceSelectedDataPage'
    const shouldRenderDialogActions = props.scenario === 'ai-marketplace-datacart' && (page === 'aiMarketplaceSelectProjectAndTask' 
                                                                                        || page === 'aiMarketplaceSelectedDataPage'
                                                                                        || page === 'aiMarketplaceDataSetPage'
                                                                                        || page === 'aiMarketPlaceDataPage'
                                                                                        || page === 'aiMarketplaceLocalDataUploadPage'
                                                                                    )
    const shouldNextButtonBeDisabledInRlefDataSelectionStep = context.aiMarketplaceDataCartState?.currentSelectedDataSetIds?.length === 0
                                                                && context.aiMarketplaceDataCartState.resourceIds.length === 0
                                                                || isSavingItemsInDB

    const dialogBodyContainerHeight = page === 'aiMarketplaceSelectedDataPage'  ?
                                                '100%'
                                                : 'calc(100% - 35px)'

    return (
        <div>
            <Dialog
                open={props.isOpen}
                onClose={onClose}
                classes={{ paper: classes.browseCollectionsPopupPaper }}
                fullWidth={true}
                disableBackdropClick
                // PaperProps={{style: props.scenario === 'ai-marketplace-datacart' ? {borderRadius: '3rem'} : {}}}
            >
                <DialogTitle id="alert-dialog-title" style={{paddingBottom: 0}}>
                    <div style={{ display: 'flex', justifyContent: 'space-around' }}>
                        <Typography style={{ fontWeight: 'bold', display: 'inline-block', fontSize: '1.5rem', margin: '0 12px' }}>
                            {returnDialogTitle()}
                        </Typography>
                        <div style={{ display: 'flex', margin: '0 12px 0 auto', alignItems: 'center', gap: '12px' }}>

                                {
                                    props.scenario !== "ai-assistant-labelling" && props.scenario !== "ai-marketplace-datacart" &&
                                    <Button
                                        variant="contained"
                                        color="primary"
                                        style={{ width: '316px', display: 'flex', justifyContent: 'center', alignItems: 'center' }}
                                        onClick={() => {
                                            props.setSavedForLaterOpen(true)
                                        }}
                                    >
                                        <SaveForLaterInCart fill='white' style={{ height: '1.5rem' }} />
                                        <Typography style={{ margin: '0 6px', textTransform: 'none' }}>Saved for Later</Typography>
                                        <Chip
                                            label={context.itemsSavedForLater.resources?.length || 0}
                                            className={classes2.dataCountChipContained}
                                        />
                                    </Button>
                                }

                                {
                                    props.scenario !== "ai-assistant-labelling" && props.scenario !== "ai-marketplace-datacart" &&
                                    <Button
                                        variant="contained"
                                        color="primary"
                                        style={{ width: '316px', display: 'flex', justifyContent: 'center', alignItems: 'center' }}
                                        onClick={() => {
                                            props.setDataCartOpen(true)
                                        }}
                                    >
                                        {
                                            props.scenario !== "ai-assistant-labelling" &&
                                            <CartCheckIcon fill="white" stroke='white' style={{ height: '1.5rem' }}/>
                                        }
                                        <Typography style={{ margin: '0 6px', textTransform: 'none' }}>
                                            {
                                                props.scenario === "ai-assistant-labelling"?
                                                "View selected Data"
                                                : "Data Cart"
                                            }
                                        </Typography>
                                        {props.scenario !== "ai-assistant-labelling"&&<Chip
                                            label={context.itemsAddedToCart.resources?.length || 0}
                                            className={classes2.dataCountChipContained}
                                        />}


                                    </Button>
                                }

                            {
                                props.scenario === 'ai-marketplace-datacart' && page === 'aiMarketplaceSelectedDataPage'
                                &&
                                <>
                                    <Button
                                        variant="outlined"
                                        color="primary"
                                        onClick={onClickAddDataFromLocal}
                                    >
                                        <Typography style={{ margin: '0 6px', textTransform: 'none' }}>Add data from local</Typography>
                                    </Button>
                                    <Button
                                        variant="contained"
                                        color="primary"
                                        onClick={onClickAddDataFromRLEF}
                                    >
                                        <Typography style={{ margin: '0 6px', textTransform: 'none' }}>Add data from RLEF</Typography>
                                    </Button>
                                </>
                            }
                            
                            <IconButton
                                onClick={onClose}
                                style={{ display: 'flex', marginLeft: '20px', color: 'black' }}
                            >
                                <CloseIcon />
                            </IconButton>
                        </div>
                    </div>
                </DialogTitle>
                {
                    props.scenario !== 'ai-marketplace-datacart'
                    &&
                    <Divider />
                }
                <DialogContent style={{paddingBottom: '2px'}}>
                    {
                        !shouldNotRenderBreadcrumbs
                        &&
                    <Breadcrumbs separator={<KeyboardArrowRightIcon fontSize='small'/>} style={{ marginRight: 'auto', marginBottom: '10px' }} className={classes.breadCrumbWrapper}>
                        {
                            // show a datacart icon as the first item of breadcrumbs in the scenario of ai marketplace data cart
                            props.scenario === 'ai-marketplace-datacart'
                            &&
                            <span
                                className={classes.breadCrumb}
                                onClick={() => {setPage("aiMarketplaceEmptyCart"); resetProjectsAndTasksSelection()}}
                            >
                                <ShoppingCartOutlinedIcon fontSize='small' style={{marginTop: -4}} />
                            </span>
                        }
                        {
                            context.breadCrumbs?.map((breadCrumb: string, index: number) => {
                                return (
                                    //@ts-ignore
                                    <span
                                        key={index}
                                        className={classes.breadCrumb}
                                        onClick={() => onBreadCrumbClick(index)}
                                    >
                                        {breadCrumb}
                                    </span>
                                )
                            })
                        }
                    </Breadcrumbs>
                    }
                    {
                        <div style={{height: dialogBodyContainerHeight}}>
                            {renderDialogContent(props.scenario)}

                        </div>
                    }
                </DialogContent>
                {
                    shouldRenderDialogActions
                    &&
                    <DialogActions style={{borderTop: '1px solid lightgrey', paddingRight: '2rem'}}>
                        {renderDialogActions()}
                    </DialogActions>
                }
            </Dialog>
        </div>
    );
}


export default BrowseCollectionsPopup
import { Box } from "@material-ui/core";
import ShimmerCss from './shimmerPlaceHolder.module.css'
export const ShimmerPlaceholder = () => {
    return (
        <Box className={ShimmerCss.shimmerWrapper}>
            {Array.from({ length: 5 }).map((_, index) => (
                <Box className={ShimmerCss.shimmerCard}>
                <Box className={ShimmerCss.shimmerHeader}>
                    <Box className={ShimmerCss.shimmerLine} style={{ width: '60%' }}></Box> {/* Title */}
                    <Box className={ShimmerCss.shimmerTag}></Box> {/* Tag */}
                </Box>
                <Box className={ShimmerCss.shimmerLine} style={{ width: '40%' }}></Box> {/* Author + Date */}
                <Box className={ShimmerCss.shimmerLine} style={{ width: '70%' }}></Box> {/* Author Name */}
                <Box className={ShimmerCss.shimmerStatsContainer}>
                    <Box className={ShimmerCss.shimmerStatBox}></Box> {/* Forks */}
                    <Box className={ShimmerCss.shimmerStatBox}></Box> {/* Accuracy */}
                    <Box className={ShimmerCss.shimmerStatBox}></Box> {/* Integrity */}
                </Box>
                <Box className={ShimmerCss.shimmerLine} ></Box> {/* Description */}
                {/* <Box className={ShimmerCss.shimmerLine} style={{ width: '50%' }}></Box>  */}
                <Box className={ShimmerCss.shimmerImagesContainer}>
                    <Box className={ShimmerCss.shimmerImage}></Box>
                    <Box className={ShimmerCss.shimmerImage}></Box>
                    <Box className={ShimmerCss.shimmerImage}></Box>
                </Box>
            </Box>
                ))}
        </Box>
    );
};
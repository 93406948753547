import { createContext } from "react";
import { IData, IDataResponse, IModel, IProject } from "../../../../../../../common";
import { IDataCartProjectsState, IDataCartTasksState, aiMarketplaceDataCartState } from "./browseCollectionsPopupContextComponent";

//@ts-ignore
const BrowseCollectionsPopupContext = createContext<{
    itemsAddedToCart: IDataResponse;
    setItemsAddedToCart: (newState: IDataResponse) => void;
    aiMarketplaceDataCartState: aiMarketplaceDataCartState;
    toggleItemInCart: (dir: number, item: IData) => void;
    breadCrumbs: string[];
    setBreadCrumbs: Function;
    itemsSavedForLater: IDataResponse;
    setItemsSavedForLater: Function;
    toggleItemInSavedForLater: Function;
    projectsState: IDataCartProjectsState,
    tasksState: IDataCartTasksState,
    fetchAllProjects: () => Promise<void>;
    fetchAllTasks: (projectId: string) => Promise<void>;
    selectTask: (task: IModel | null) => void;
    selectProject: (project: IProject | null) => void;
    setProjectsSearchTerm: (term: string) => void;
    setTasksSearchTerm: (term: string) => void;
    addResourcesToAiMarketplaceDataCart: (resourceIds: string[]) => void;
    addDataSetsToAiMarketplaceDataCart: (dataSetIds: string[]) => void;
    removeDataSetsFromAiMarketplaceDataCart: (dataSetIds: string[]) => void;
    setAiMarketplaceDataCartInitialStateFromDB: () => Promise<void>;
    removeResourceFromAiMarketplaceDataCart: (resourceIds: string[]) => void;
    resetLocalStatesOnDialogClose: () => void;
}>();

export default BrowseCollectionsPopupContext;

import { Box, makeStyles, Theme, Tooltip } from "@material-ui/core";
import { ReactNode, useEffect, useState } from "react";
import { EditText, EditTextProps } from "react-edit-text";
import { connect, ConnectedProps, useSelector } from "react-redux";
import { IData, IReduxState } from "../../../../../../../../../../../common";
import { ICoPilotResource } from "../../../../../../../../../../../common/constants/interfaces/coPilotResource";
import {
  updateData,
  setResources,
} from "../../../../../../../../../../../store/actions/dataActions";

const useStyles = makeStyles((theme: Theme) => ({
  tagEditText: {
    outlineColor: theme.palette.primary.light,
    justifyContent: 'center',
    alignItems: 'center'    
  },
}));

interface Props extends TPropsFromRedux {
  tooltip?: string;
  defaultValue: string;
  editTextComponentProps?: JSX.IntrinsicAttributes &
    JSX.IntrinsicClassAttributes<EditText> &
    Readonly<EditTextProps> &
    Readonly<{ children?: ReactNode }>;
  resource: IData | ICoPilotResource;
  editTextComponentAdditionalCSSClassNames?: string,
  onEditTextClick: Function
  onEditTextBlurEvent: Function  
}

function TagEditText(props: Props) {
  const classes = useStyles();
  const resources = useSelector(
    (state: IReduxState) => state.data.data.resources
  );

  const [value, setValue] = useState<string>(props.defaultValue);

  useEffect(() => {
    setValue(props.defaultValue);
  }, [props.defaultValue]);

  function handleTagSave(tagValueToSave: string) {
    props
      .updateData(
        { id: [props.resource._id], tag: tagValueToSave },
        {},
        { shouldFetchLatestData: false }
      )
      .then(() => {
        for (
          let resourceIndex = 0;
          resourceIndex < resources.length;
          resourceIndex++
        ) {
          const resourceObject = resources[resourceIndex];
          if (resourceObject._id === props.resource._id) {
            resourceObject.tag = tagValueToSave;
            resources[resourceIndex] = resourceObject;
            break;
          }
        }
        props.setResources(resources);
      });
  }

  return (
    <Tooltip title={props.tooltip || ""}>
      <Box style={{ padding: 1, maxWidth: "215px" }}
        onClick={()=>{
          props.onEditTextClick();
        }}
        onBlur={()=>{
          props.onEditTextBlurEvent();
        }}      
      >
        <EditText
          name="tag"
          className={`
            ${classes.tagEditText}
            ${props.editTextComponentAdditionalCSSClassNames ? props.editTextComponentAdditionalCSSClassNames : ''}
          `}
          style={{
            width: "100%",
            borderWidth: 0,
            borderRadius: 20,
            backgroundColor: "#f3f4f6",
            textAlign: "center",
            height: 28,
          }}
          value={value}
          onChange={(changedValue) => {
            setValue(changedValue);
          }}
          onSave={(e) => {
            if (e.previousValue !== e.value) {
              handleTagSave(e.value);
            }
          }}
          {...props.editTextComponentProps}
        />
      </Box>
    </Tooltip>
  );
}

const connector = connect(undefined, {
  updateData,
  setResources,
});

export default connector(TagEditText);

export type TPropsFromRedux = ConnectedProps<typeof connector>;

import React, { useCallback, useEffect, useState, useRef } from "react";
import {
  IResourceAnalyticsCollection,
  ResourceImageAnnotation,
} from "../../../../../../../../../../../common";
import {
  Dialog,
  IconButton,
  Theme,
} from "@material-ui/core";
import { makeStyles } from "@material-ui/styles";
import {
  Cancel,
  KeyboardArrowLeft,
  KeyboardArrowRight,
} from "@material-ui/icons";
import clsx from "clsx";
import ImageService from "../../../../../../../../../../../services/ImageService";
import {
  getImageScaleFactorForImageWidth,
  getMaxImageWidthHeightWhichWeCanSet,
} from "../../../../../../../../../../../services/imageHelperService";
import {
  defaultAnnotationOptions,
  setResourceImageAnnotationsForSendingToAnnotationComponent,
} from "../../../../../../../../../../../services/imageAnnotationHelperService";
import { SegmentationAnalyticsTwoDimensionalImage } from "../../../../../../../../../../../assets/components/react-annotation-tool/src/apps/segmentation-analytics-two-dimensional-image";
import { AnalyticsResourceReferenceGuideDataType } from "./ModelAnalyticsResources";
import { TPropsFromRedux } from "./AddDataSetCollection";

const useStyles = makeStyles((theme: Theme) => ({
  root: {
    overflow: "auto",
    height: "100%",
    padding: "20px 0px",
    "&p": {
      padding: "0px",
    },
  },
  body: {
    padding: "10px",
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    width: "100%",
    height: "100%"
    // "@media(min-width:1300px)": {
    //   width: "70vw",
    //   height: "70vh",
    // },
    // "@media(min-width:1000px) and (max-width: 1300px)": {
    //   width: "85vw",
    //   height: "78vh",
    // },
    // "@media(min-width:700px) and (max-width: 1000px)": {
    //   width: "80vw",
    //   height: "70vh",
    // },
    // "@media(max-width: 700px)": {
    //   width: "78vw",
    //   height: "72vh",
    // },
  },
  dialogContainer: {
    overflow: "visible",
    height: "100%"
  },
  dialogContainerWidth: {
    maxWidth: "calc(100% - 64px)",
  },
  closeBtn: {
    position: "absolute",
    right: -10,
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    // padding: "2px",
    top: -10,
    backgroundColor: "white",
    borderRadius: "50%",
    // color: "white",
    cursor: "pointer",
    width: "25px",
    height: "25px",
  },
  showDetails: {
    width: "calc(100%)",
    height: "100%",
    display: "flex",
    // "@media(min-width:1300px)": {
    //   display: "flex",
    //   jsutifyContent: "space-between",
    // },
    // "@media(min-width:1050px) and (max-width: 1300px)": {
    //   display: "flex",
    //   jsutifyContent: "space-between",
    // },
    // "@media(min-width:700px) and (max-width: 1050px)": {
    //   // display: "flex",
    // },
    // "@media(max-width: 700px)": {
    //   // display: "flex",
    // },
  },
  imageContainer: {
    padding: "20px",
    "@media(min-width:1300px)": {
      width: "50%",
    },
    "@media(min-width:1050px) and (max-width: 1300px)": {
      width: "50%",
    },
    "@media(min-width:700px) and (max-width: 1050px)": {
      display: "flex",
      justifyContent: "center",
      width: "60%",
    },
    "@media(max-width: 700px)": {
      display: "flex",
      justifyContent: "center",
      width: "60%",
    },
  },
  references: {
    width: "50%",
    height: "100%"
    // "@media(min-width:1300px)": {
    //   width: "50%",
    // },
    // "@media(min-width:1050px) and (max-width: 1300px)": {
    //   width: "50%",
    // },
    // "@media(min-width:700px) and (max-width: 1050px)": {
    //   display: "flex",
    //   justifyContent: "center",
    //   width: "100%",
    //   height: "65%",
    // },
    // "@media(max-width: 700px)": {
    //   display: "flex",
    //   justifyContent: "center",
    //   width: "100%",
    //   height: "65%",
    // },
  },
  flex: {
    display: "flex",
  },
  rightIndicator: {
    left: 300,
  },
  indicators: {
    width: "50px",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    cursor: "pointer",
    transition: "opacity 0.25s ease-in-out",
    borderRadius: "50%",
    "& svg": {
      fill: "#000",
      fontSize: "2.5rem",
    },
  },
  disabledIcon: {
    "& svg": {
      fill: "rgba(0, 0, 0, 0.26)",
    },
  },
  leftIndicator: {
    left: 0,
  },
}));

interface IProps {
  open: boolean;
  onClose: () => void;
  referenceData?: any[];
  selectedResource?: IResourceAnalyticsCollection;
  selectedAnnotationId?: string;
  selectedAnnotationLabel?: string;
  modelReferenceGuides?: AnalyticsResourceReferenceGuideDataType[];
}

const imageContainerId = "imageContainerId";

function ReferenceDataPopup(props: IProps) {
  const classes = useStyles();
  const dialogRef = useRef<any>(null);
  const [showRightArrow, setShowRightArrow] = useState(true);
  const [showLeftArrow, setShowLeftArrow] = useState(true);
  const [
    imageAnnotationsForTwoDimensionalImage,
    setImageAnnotationsForTwoDimensionalImage,
  ] = useState<ResourceImageAnnotation[]>([]);
  const [
    imageWidthForTwoDimensionalImage,
    setImageWidthForTwoDimensionalImage,
  ] = useState(0);

  const onClose = () => {
    props.onClose();
  };

  const [imageUrlIdx, setImageUrlIdx] = useState(0);
  const [linkedReferenceImagesUrl, setLinkedReferenceImagesUrl] = useState<string[]>([]);

  const onArrowClickHanlder = (i: number) => {
    const newIndex = imageUrlIdx + i;
    if (
      newIndex >= 0 &&
      newIndex < (linkedReferenceImagesUrl.length || 0)
    ) {
      setImageUrlIdx(newIndex);
    }
  };
  
  useEffect(() => {
    setShowLeftArrow(imageUrlIdx > 0);
    setShowRightArrow(
      imageUrlIdx <
        (linkedReferenceImagesUrl.length || 0) - 1
    );
  }, [imageUrlIdx, linkedReferenceImagesUrl]);

  useEffect(() =>{
   const referenceData = props?.modelReferenceGuides;
   let linkedReferenceDataUrl : string[] = [];
   if(referenceData) {
       referenceData.forEach(data => {
           if (data?.linkedAnnotationLabels && data?.linkedAnnotationLabels?.includes(props.selectedAnnotationLabel || "")) {
               linkedReferenceDataUrl.push(data.fileUrl);
           }
         });
   }
   setLinkedReferenceImagesUrl(linkedReferenceDataUrl);
 }, [props.selectedAnnotationLabel])

  useEffect(() => {
    const executeFunction = async () => {
      let image:
        | {
            width: number;
            height: number;
          }
        | undefined = undefined;
      image = await ImageService.getImageWidthHeightFromURL(
        props?.selectedResource?.resourceUrl || ""
      );
      const imageAspectRatio = image.width / image.height;

      const imageContainerElement = document.getElementById(imageContainerId);

      let imageMaxWidthAllowed = imageContainerElement?.offsetWidth || 400;
      let imageMaxHeightAllowed = imageContainerElement?.offsetHeight || 400;

      //   let imageWidthToSet = 0;
      //   let imageHeightToSet = 0;

      let { imageWidthToSet, imageHeightToSet } =
        getMaxImageWidthHeightWhichWeCanSet({
          originalImageWidth: image.width,
          originalImageHeight: image.height,
          maxImageWidthAllowed: imageMaxWidthAllowed,
          maxImageHeightAllowed: imageMaxHeightAllowed,
        });

      let imageScaleFactor = getImageScaleFactorForImageWidth(
        image.width,
        imageWidthToSet
      );

      let selectedAnnotation: ResourceImageAnnotation[] = [];
      if (props?.selectedResource?.predictedAnnotations) {
        selectedAnnotation =
          props?.selectedResource?.predictedAnnotations.filter(
            (annotation) => props?.selectedAnnotationId === annotation.id
          );
      }
      const imageAnnotationsForTwoDimensionalImage =
        setResourceImageAnnotationsForSendingToAnnotationComponent(
          selectedAnnotation || [],
          imageScaleFactor
        );

      setImageAnnotationsForTwoDimensionalImage(
        imageAnnotationsForTwoDimensionalImage
      );

      setImageWidthForTwoDimensionalImage(imageWidthToSet);
    };

    if (props?.selectedResource?.resourceUrl) {
      executeFunction();
    }
  }, [
    props?.selectedResource?.predictedAnnotations,
    props?.selectedResource?.resourceUrl,
    props.selectedAnnotationId,
  ]);

  return (
    <Dialog
      ref={dialogRef}
      onClose={onClose}
      open={props.open}
      maxWidth="lg"
      fullWidth={true}
      classes={{
        paperScrollPaper: classes.dialogContainer,
        paperWidthLg: classes.dialogContainerWidth,
      }}
    >
      <div className={classes.root}>
        <div
          className={classes.closeBtn}
          onClick={() => {
            onClose();
          }}
        >
          <Cancel style={{ fontSize: "40px", padding: "4px" }} />
        </div>

        <div className={classes.body}>
          <div className={classes.showDetails} style={{ gap: "8px"}}>
            <div
              id={imageContainerId}
              style={{
                width: "50%",
                height: `100%`,
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
              }}
            >
              <SegmentationAnalyticsTwoDimensionalImage
                url={props?.selectedResource?.resourceUrl || ""}
                imageWidth={imageWidthForTwoDimensionalImage}
                AnnotationOption={defaultAnnotationOptions}
                defaultAnnotations={imageAnnotationsForTwoDimensionalImage}
                isDynamicOptionsEnable
                isViewOnlyMode={false}
                isLabelOn={true}
                isZoomFeatureEnabled={true}
              />
            </div>

            <div
              className={classes.references}
            >
              <div
                style={{
                  fontSize: "20px",
                  textAlign: "center",
                  margin: "10px",
                }}
              >
                Reference Images
              </div>
              <div
                style={{
                  display: "flex",
                  justifyContent: "space-between",
                  height: "90%",
                }}
              >
                <div
                  className={clsx(classes.leftIndicator, classes.indicators)}
                >
                  <IconButton
                    aria-label="previous"
                    color="primary"
                    disabled={!showLeftArrow}
                    classes={{ disabled: classes.disabledIcon }}
                    onClick={() => onArrowClickHanlder(-1)}
                  >
                    <KeyboardArrowLeft />
                  </IconButton>
                </div>
                <div
                  style={{ width: "100%", height: "100%", textAlign: "center" }}
                >
                  <img
                    width="90%"
                    height="90%"
                    style={{
                      objectFit: "contain"
                    }}
                    src={
                     linkedReferenceImagesUrl[
                        imageUrlIdx
                      ]
                    }
                    alt=""
                  />
                </div>
                <div
                  className={clsx(classes.rightIndicator, classes.indicators)}
                >
                  <IconButton
                    aria-label="previous"
                    color="primary"
                    disabled={!showRightArrow}
                    classes={{ disabled: classes.disabledIcon }}
                    onClick={() => onArrowClickHanlder(1)}
                  >
                    <KeyboardArrowRight />
                  </IconButton>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </Dialog>
  );
}

export default ReferenceDataPopup;

import { ReactElement, useCallback, useMemo, useState } from "react";
import { IData, IDataResponse, IModel, IProject } from "../../../../../../../common";
import BrowseCollectionsPopupContext from "./browseCollectionsPopup.context";
import { ModelService, ProjectService } from "../../../../../../../services";
import useAuth from "../../../../../../../hooks/useAuth";
import { createDataCart, getDataCartDetails, updateDataCart } from "../../aiMarketPlace/aiMarketPlaceServices";
import { AiMarketplaceQueryParams } from "../../aiMarketPlace/ai-marketplace-routes";
import { IDataCartLocalResourceDetails } from "../../aiMarketPlace/aiMarketPlaceInterfaces/interfaces";

export interface IDataCartProjectsState {
    availableProjects: Array<IProject>,
    isFetchingProjects: boolean,
    selectedProject: IProject | null,
    searchTerm: string,
}

export interface IDataCartTasksState {
    availableTasks: Array<IModel>,
    isFetchingTasks: boolean,
    selectedTask: IModel | null,
    searchTerm: string,
}

export interface aiMarketplaceDataCartState {
    /** resource ids which are selected from RLEF */
    resourceIds: Array<string>;
    /** dataset ids which are selected from RLEF and already saved in DB */
    dataSetIds: Array<string>;
    /** dataset ids which are in local state */
    currentSelectedDataSetIds: Array<string>;
    /** local files ready to upload in data cart */
    localFiles: Array<File>;
    /** _id of data cart in DB */
    dataCartId?: string;
    /** selected rlef resources details fetched from DB */
    rlefResources?: Array<IData>;   // full resource details
    /** uploaded local files details fetched from DB */
    localResources?: Array<IDataCartLocalResourceDetails>;
}

export default function BrowseCollectionsPopupContextComponent(props: { children: ReactElement }) {

    const { userId } = useAuth();

    const initialResources: IData[] = []
    //@ts-ignore
    const [itemsAddedToCart, setItemsAddedToCart] = useState<IDataResponse>({ resources: [...initialResources] })
    const [aiMarketplaceDataCartState, setAiMarketplaceDataCartState] = useState<aiMarketplaceDataCartState>({
        resourceIds: [],
        dataSetIds: [],
        currentSelectedDataSetIds: [],
        localFiles: [],
    })
    const [breadCrumbs, setBreadCrumbs] = useState<string[]>([])
    // @ts-ignore
    const [itemsSavedForLater, setItemsSavedForLater] = useState<IDataResponse>({ resources: [...initialResources] })
    const [projectsState, setProjectsState] = useState<IDataCartProjectsState>({
        availableProjects: [],
        isFetchingProjects: false,
        selectedProject: null,
        searchTerm: "",
    })
    const [tasksState, setTasksState] = useState<IDataCartTasksState>({
        availableTasks: [], 
        isFetchingTasks: false,
        selectedTask: null,
        searchTerm: "",
    })


    function toggleItemInCart(dir: number, item: IData) {
        //direction = 1 means add to cart and -1 means remove from cart
        if (dir !== 1 && dir !== -1)
            return;

        if (dir === 1) {
            //@ts-ignore
            setItemsAddedToCart((prevState) => {
                return {
                    resources: [
                        ...(prevState.resources || []),
                        item
                    ]
                }
            })
            // remove item form savedForLater if it exists
            //@ts-ignore
            setItemsSavedForLater((prevState) => {
                return {
                    resources: prevState.resources?.filter((obj: IData) => obj._id !== item._id)
                }
            })
        }
        else {
            //@ts-ignore
            setItemsAddedToCart((prevState) => {
                return {
                    resources: prevState.resources?.filter((obj: IData) => obj._id !== item._id)
                }
            })
        }
    }

    function toggleItemInSavedForLater(dir: number, item: IData, options?: {
        scenario?: "AI_Assisted_Labelling_Browse_Data" | undefined
    }) {

        const scenario = options?.scenario;

        //direction = 1 means add to savedForLater and -1 means remove from savedForLater
        if (dir !== 1 && dir !== -1)
            return;

        if (dir === 1) {
            //@ts-ignore
            setItemsSavedForLater((prevState) => {
                return {
                    resources: [
                        ...(prevState.resources || []),
                        item
                    ]
                }
            })
            if (scenario !== "AI_Assisted_Labelling_Browse_Data") {
                toggleItemInCart(-1, item)
            }
        }
        else {
            //@ts-ignore
            setItemsSavedForLater((prevState) => {
                return {
                    resources: prevState.resources.filter((obj: IData) => obj._id !== item._id)
                }
            })
            if (scenario !== "AI_Assisted_Labelling_Browse_Data") { 
                toggleItemInCart(1, item)
            }
        }
    }

    const fetchAllProjects = useCallback(async () => {
        try {
            setProjectsState(prev => ({...prev, isFetchingProjects: true}));
            const apiResponse = await ProjectService.getProjects({ offset: '1', limit: '1000' });
            if (apiResponse.data) {
                setProjectsState(prev => ({...prev, availableProjects: apiResponse.data.projects}));
            }
        } catch (error) {
            console.log("Error fetching projects", error);
        } finally {
            setProjectsState(prev => ({...prev, isFetchingProjects: false}));
        }
    }, []);

    const fetchAllTasks = useCallback(async (projectId: string) => {
        try {
            setTasksState(prev => ({...prev, isFetchingTasks: true}));
            if(!projectId){
                throw new Error("projectId is required");
            }
            const apiResponse = await ModelService.getProjectModels({
                project: projectId,
                limit: '100'
            })
            if(apiResponse.data){
                setTasksState(prev => ({...prev, availableTasks: apiResponse.data.models}))
            }
        }
        catch (error) {
            console.log("Error fetching tasks", error)
        }
        finally {
            setTasksState(prev => ({...prev, isFetchingTasks: false}));
        }
    }, [])

    const selectProject = (project: IProject | null) => {
        setProjectsState(prev => ({...prev, selectedProject: project }));
        // setBreadCrumbs([...breadCrumbs, project.name])
        // fetchAllTasks(project._id)
    }

    const selectTask = (task: IModel | null) => {
        setTasksState(prev => ({...prev, selectedTask: task }));
    }

    const setProjectsSearchTerm = (term: string) => {
        setProjectsState(prev => ({...prev, searchTerm: term}));
    }

    const setTasksSearchTerm = (term: string) => {
        setTasksState(prev => ({...prev, searchTerm: term}));
    }

    const addResourcesToAiMarketplaceDataCart = (resourceIds: Array<string>) => {
        setAiMarketplaceDataCartState(prev => {
            return {
               ...prev,
                resourceIds: [...prev.resourceIds,...(resourceIds.filter(id => !prev.resourceIds.includes(id)))]
            }
        })
    }

    const removeResourceFromAiMarketplaceDataCart = async (resourceIds: Array<string>) => {
        await updateDataCart({
            _id: aiMarketplaceDataCartState.dataCartId || '',
            rlefResourceIds: aiMarketplaceDataCartState.resourceIds.filter(id =>!resourceIds.includes(id))
        })


        await setAiMarketplaceDataCartInitialStateFromDB()
    }

    const addDataSetsToAiMarketplaceDataCart = (dataSetIds: Array<string>) => {
        setAiMarketplaceDataCartState(prev => {
            return {
                ...prev,
                currentSelectedDataSetIds: [...prev.currentSelectedDataSetIds,...(dataSetIds.filter(id => !prev.currentSelectedDataSetIds.includes(id)))]
            }
        })
    }

    const removeDataSetsFromAiMarketplaceDataCart = (dataSetIds: Array<string>) => {
        setAiMarketplaceDataCartState(prev => {
            return {
               ...prev,
                currentSelectedDataSetIds: prev.currentSelectedDataSetIds.filter(id =>!dataSetIds.includes(id))
            }
        })
    }

    const setAiMarketplaceDataCartInitialStateFromDB = async () => {
        const urlSearchParams = new URLSearchParams(window.location.search);
        const aiTask = urlSearchParams.get(AiMarketplaceQueryParams.aiTask) || ''
        const problemType = urlSearchParams.get(AiMarketplaceQueryParams.problemType) || ''

        let dataCartDetails = await getDataCartDetails({aiTask, problemType, userId})
        
        if(!dataCartDetails){
            // create data cart
            await createDataCart({aiTask, problemType, userId})
        }
        else {
            setAiMarketplaceDataCartState({
                dataSetIds: dataCartDetails.rlefDataSetIds || [],
                currentSelectedDataSetIds: dataCartDetails.rlefDataSetIds || [],
                resourceIds: dataCartDetails.rlefResourceIds,
                localFiles: [],
                localResources: dataCartDetails.localResources,
                rlefResources: dataCartDetails.rlefResources,
                dataCartId: dataCartDetails._id,
            })
        }
    }

    const resetLocalStatesOnDialogClose = () => {
        setAiMarketplaceDataCartState(prev => {
            return {
               ...prev,
                currentSelectedDataSetIds: prev.dataSetIds
            }
        })
    }

    const browseCollectionsPopupContextValue = useMemo(() => {
        return {
            itemsAddedToCart,
            setItemsAddedToCart,
            aiMarketplaceDataCartState,
            toggleItemInCart,
            breadCrumbs,
            setBreadCrumbs,
            itemsSavedForLater,
            setItemsSavedForLater,
            toggleItemInSavedForLater,
            projectsState,
            fetchAllProjects,
            tasksState,
            fetchAllTasks,
            selectProject,
            selectTask,
            setProjectsSearchTerm,
            setTasksSearchTerm,
            addResourcesToAiMarketplaceDataCart,
            addDataSetsToAiMarketplaceDataCart,
            removeDataSetsFromAiMarketplaceDataCart
        }
    }, [itemsAddedToCart, breadCrumbs, itemsSavedForLater, projectsState, tasksState, aiMarketplaceDataCartState])


    return (
        <BrowseCollectionsPopupContext.Provider
            value={{
                ...browseCollectionsPopupContextValue,
                setAiMarketplaceDataCartInitialStateFromDB,
                removeResourceFromAiMarketplaceDataCart,
                resetLocalStatesOnDialogClose,
            }}
        >
            {props.children}
        </BrowseCollectionsPopupContext.Provider>
    )
}
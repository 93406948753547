import React, { Dispatch, SetStateAction, useEffect, useState } from 'react'
import { Button, Menu, MenuItem, Tooltip, IconButton, Fade, CircularProgress, Box } from "@material-ui/core";
import MenuOpenIcon from '@material-ui/icons/MenuOpen';
import { ICollection, IDefaultDataSetCollection, IModelArchitecturesAvailable, IModelCollection, IModelType, ITrainingDataDirectoriesSelectedStateForAddModelCollection } from '../../../../../../../../../../../common';
// import DeploySVGIcon from "../../../../../../../../../../../assets/svg/deploy.svg";
import { GetApp, FileCopy } from '@material-ui/icons';
import TrainDataSetCollectionButton from './trainDataSetCollectionButton.component';
import ReTrainDataSetCollectionButton from './reTrainDataSetCollectionButton.component';
import { AddModelCollectionScenario } from './AddModelCollection';
import { aiTestForDatasetCollection, copyDataSetToAnotherModelButtonTooltipText, shuffleDataSetToAnotherModelButtonTooltipText, splitDataSetToAnotherModelButtonTooltipText } from '../../data/constants/constants';
import reTestIcon from "../components/Icons/Re-test.svg";
import ModelCollectionDeployButton from './ModelCollectionDeployButton';
import ShowModelCollectionHistoryViewButton from './ShowModelCollectionHistoryViewButton';
import InsertLinkIcon from '@material-ui/icons/InsertLink';
import AssistantIcon from '@material-ui/icons/Assistant';
import { ModelService } from '../../../../../../../../../../../services';
import ShuffleIcon from '@mui/icons-material/Shuffle';
import CallSplitIcon from '@mui/icons-material/CallSplit';
/*
const useStyles = makeStyles((theme) => ({
    deployButton: {
      color: "#008D9C",
    },
    tableDisabledCell: {
      pointerEvents: "none",
      opacity: "0.5",
    },
  }));
*/

function SubActionMenu(props: { modelId?: string, datasetRow?: ICollection, modelRow?: IModelCollection, disabledDeployButton?: boolean, handleAddPromptAnsInDataSetActionButtonClick?: () => any, architecturesAvailable?: IModelArchitecturesAvailable, handleTestButtonClick?: Function, setPerformModelCollectionDeploymentPopupState?: Dispatch<SetStateAction<{ isOpen: boolean, selected: IModelCollection | undefined }>>, collectionType: string, handleDataSetCollectionDownloadButtonClick?: Function, handleTrainButtonClick?: Function, addModelCollectionScenario?: AddModelCollectionScenario, modelType?: IModelType, handleReTrainButtonClick?: Function, trainingDataDirectoriesSelectedStateForAddModelCollection?: ITrainingDataDirectoriesSelectedStateForAddModelCollection, handleDatasetCopyBtnClick?: (param1: ICollection) => void, handleSplitDatasetBtnClick?: (param1:ICollection) => void, handleShuffleDatasetBtnClick?: (param1:ICollection) => void, handleDatasetAiTestBtnClick?: (param1: ICollection) => void, handleReTestButtonClick?: (collection: IModelCollection) => {}, loadingAiTest?: boolean, defaultDatasetCollection?: IDefaultDataSetCollection | null }) {
    const [moreActionMenu, setMoreActionMenu] = useState<null | HTMLElement>(null);
    // const classes = useStyles();
    const actionMenuOpen = Boolean(moreActionMenu)

    const handleClickMoreActions = (event: React.MouseEvent<HTMLElement>) => {
        setMoreActionMenu(event.currentTarget)
    }

    const handleCloseMoreActions = () => {
        setMoreActionMenu(null)
    }

    const [aiTestDescription, setAiTestDescription] = useState('');
    const [aiTestEnabled, setAiTestEnabled] = useState(false);
    useEffect(() => {
        const exectueFunction = async () => {
            if (props?.modelId) {
                const apiResponse = await ModelService.getModelDetails({
                    modelId: props?.modelId,
                    modelSelectQuery: `aiTestEnabled aiTestDescription`
                })
                setAiTestDescription(apiResponse.data?.aiTestDescription || '')
                setAiTestEnabled(apiResponse.data?.aiTestEnabled || false)
            }
        }
        exectueFunction();
    }, []);

    return (
        <>
            <Tooltip title={"More Actions"}>
                <Button aria-controls="fade-menu" aria-haspopup="true" onClick={handleClickMoreActions} style={{ outline: 'none' }}>
                    <MenuOpenIcon />
                </Button>
            </Tooltip>
            <Menu
                id="fade-menu"
                anchorEl={moreActionMenu}
                getContentAnchorEl={null}
                anchorOrigin={{ vertical: "bottom", horizontal: "center" }}
                transformOrigin={{ vertical: "top", horizontal: "center" }}
                keepMounted
                open={actionMenuOpen}
                onClose={handleCloseMoreActions}
                TransitionComponent={Fade}
                elevation={2}
                MenuListProps={{
                    style: {
                        display: "flex"
                    }
                }}
            >
                {props.collectionType === "modelCollection" &&
                    <MenuItem onClick={handleCloseMoreActions}>{
                        props.modelRow?.checkpointFiles?.length !== 0 ?
                            <Tooltip title={"Test the collection"}>
                                <IconButton onClick={() => { props?.handleTestButtonClick && props.handleTestButtonClick(props.modelRow) }} style={{ width: "55px" }}>
                                    <img alt='' src="https://cdn-icons-png.flaticon.com/512/1581/1581884.png" height={24} width={24} />
                                </IconButton>
                            </Tooltip>
                            :
                            <Tooltip title={"Test: Collection does not contain any checkpoint file!"}>
                                <div>
                                    <IconButton style={{ pointerEvents: "none", opacity: "0.5", padding: "15px 12px 12px", width: "68px" }} disabled>
                                        <img alt='' src="https://cdn-icons-png.flaticon.com/512/1581/1581884.png" height={24} width={24} />
                                    </IconButton>
                                </div>
                            </Tooltip>
                    }
                    </MenuItem>}
                {/* {
            props.modelType === "prompt" &&
            <MenuItem onClick={handleCloseMoreActions}>{
                <Tooltip title={"Add prompt answers as txt file in a text file model data-set"}>
                    <IconButton onClick={() => { props.handleAddPromptAnsInDataSetActionButtonClick && props.handleAddPromptAnsInDataSetActionButtonClick() }}  style={{width:"55px"}}>
                        <InsertLinkIcon />
                    </IconButton>
                </Tooltip>
                }
            </MenuItem>
        } */}
                {props.collectionType === "modelCollection" &&
                    <MenuItem onClick={handleCloseMoreActions}>
                        {/* <Tooltip title={props?.modelRow && 
                (props?.modelRow?.checkpointFiles?.length <= 0)? 
                "Checkpoint file should be there to perform deployment" :
                (props.architecturesAvailable && props.architecturesAvailable.find(architecture => architecture.label === props?.modelRow?.modelArchitecture) === undefined) ?
                "Model collection architecture is Invalid" :
                (props.architecturesAvailable && props.architecturesAvailable.find(architecture => ( architecture.label === props?.modelRow?.modelArchitecture &&  architecture.isDeploymentFeatureEnabled)) === undefined) ? 
                "Deployment feature is not enabled"  :
                (props.architecturesAvailable && props.architecturesAvailable.find(architecture => ( architecture.label === props?.modelRow?.modelArchitecture && architecture.apiUrlToCallForDeployment !== "")) === undefined) ?
                "Api Url to perform deployment is not available" :
            "Deploy"
            }>
            <div>
            <IconButton
                classes={{ root: `${classes.deployButton} ${(props?.modelRow && props?.modelRow?.checkpointFiles?.length > 0) && (props.architecturesAvailable && props.architecturesAvailable.find(architecture => architecture.label === (props?.modelRow && props?.modelRow.modelArchitecture) && architecture.apiUrlToCallForDeployment !== "" && architecture.isDeploymentFeatureEnabled)) ? "" : classes.tableDisabledCell}` }}
                onClick={()=>{
                props.setPerformModelCollectionDeploymentPopupState && props.setPerformModelCollectionDeploymentPopupState((oldState : any)=>({...oldState, isOpen: true, selected: props.modelRow}))
                }}
            >
                <img style={{width: "30px", transform: "scale(1.5)"}} src={DeploySVGIcon}/>
            </IconButton>
            </div>
            </Tooltip> */}

                        <ModelCollectionDeployButton modelRow={props?.modelRow} architecturesAvailable={props?.architecturesAvailable} setPerformModelCollectionDeploymentPopupState={props?.setPerformModelCollectionDeploymentPopupState} disabledDeployButton={props.disabledDeployButton} />
                    </MenuItem>}
                {props.collectionType === 'modelCollection' &&
                    <MenuItem onClick={handleCloseMoreActions}>
                        {
                            (
                                (
                                    (
                                        // !currentModelCollection.accuracy || currentModelCollection.accuracy <= 0
                                        true
                                    )
                                    &&
                                    (
                                        props.modelRow?.status === "Trained" ||
                                        props.modelRow?.status === "Re-Trained" ||
                                        props.modelRow?.status === "Live"
                                    )
                                )
                            ) ?
                                <Tooltip title={"Re-Test the collection"}>
                                    <IconButton onClick={() => { props.modelRow && props.handleReTestButtonClick && props.handleReTestButtonClick(props.modelRow) }}>
                                        <img alt='' src={reTestIcon} height={24} width={24} />
                                    </IconButton>
                                </Tooltip>
                                :
                                <Tooltip title={(
                                    (
                                        props.modelRow?.accuracy && props.modelRow?.accuracy > 0
                                    )
                                    &&
                                    (
                                        props?.modelRow?.status === "Trained" ||
                                        props?.modelRow?.status === "Re-Trained" ||
                                        props?.modelRow?.status === "Live"
                                    )
                                ) ? "Re-Test: Model is already tested" : !(
                                    props?.modelRow?.status === "Trained" ||
                                    props?.modelRow?.status === "Re-Trained" ||
                                    props?.modelRow?.status === "Live"
                                ) ? "Re-Test: Collection should be Live / Trained / Re-Trained" : "Re-Test: Not Enough details to Re-Train the collection "}>
                                    <div>
                                        <IconButton style={{ pointerEvents: "none", opacity: "0.5", padding: "15px 12px 12px" }} disabled>
                                            <img alt='' src={reTestIcon} height={24} width={24} />
                                        </IconButton>
                                    </div>
                                </Tooltip>
                        }
                    </MenuItem>
                }
                {props.collectionType === "modelCollection" &&
                    <ShowModelCollectionHistoryViewButton
                        modelCollectionId={props.modelRow?._id || ""}
                    />
                }
                {props.collectionType === "dataSetCollection" &&
                    <MenuItem onClick={handleCloseMoreActions}>
                        {
                            props?.datasetRow?.isResourcesFileDownloadLinkBeingFetched
                                ? <CircularProgress style={{ alignSelf: 'center', margin: '12px' }} size="25px" color="primary" />
                                : <Tooltip
                                    title={
                                        !(props?.datasetRow?.totalNoOfResources && props.datasetRow.totalNoOfResources > 0)
                                            ? "Collection does not have any resources to download"
                                            : "Download collection's resources details in a csv file"
                                    }
                                >
                                    <Box>
                                        <IconButton
                                            color="primary"
                                            disabled={
                                                !(props?.datasetRow?.totalNoOfResources && props?.datasetRow?.totalNoOfResources > 0)
                                                    ? true
                                                    : false
                                            }
                                            onClick={() => {
                                                props.handleDataSetCollectionDownloadButtonClick && props.handleDataSetCollectionDownloadButtonClick(props?.datasetRow)
                                            }}
                                        >
                                            <GetApp />
                                        </IconButton>
                                    </Box>
                                </Tooltip>
                        }
                    </MenuItem>}
                {props.collectionType === "dataSetCollection" &&
                    <MenuItem onClick={handleCloseMoreActions}>
                        <TrainDataSetCollectionButton
                            onClick={() => { props.handleTrainButtonClick && props.handleTrainButtonClick(props?.datasetRow) }}
                            isSelected={
                                props?.addModelCollectionScenario === 'trainingDataSetCollections' && props?.trainingDataDirectoriesSelectedStateForAddModelCollection && props?.datasetRow && props?.trainingDataDirectoriesSelectedStateForAddModelCollection.value.indexOf(props?.datasetRow.name) !== -1
                                    ? true
                                    : false
                            }
                            isDefaultDataSetCollectionExist={(props.defaultDatasetCollection && props.defaultDatasetCollection.id.length > 0) || false}
                        />
                    </MenuItem>}
                {props.collectionType === "dataSetCollection" &&
                    <MenuItem onClick={handleCloseMoreActions}>
                        <ReTrainDataSetCollectionButton
                            onClick={() => { props.handleReTrainButtonClick && props.handleReTrainButtonClick(props?.datasetRow) }}
                            isSelected={
                                props?.addModelCollectionScenario === 'reTrainingDataSetCollections' && props?.trainingDataDirectoriesSelectedStateForAddModelCollection && props?.datasetRow && props?.trainingDataDirectoriesSelectedStateForAddModelCollection.value.indexOf(props?.datasetRow.name) !== -1
                                    ? true
                                    : false
                            }
                            isDefaultDataSetCollectionExist={(props.defaultDatasetCollection && props.defaultDatasetCollection.id.length > 0) || false}
                        />
                    </MenuItem>}
                {props.collectionType === "dataSetCollection" &&
                    <MenuItem onClick={handleCloseMoreActions}>
                        <Tooltip title={copyDataSetToAnotherModelButtonTooltipText}>
                            <IconButton
                                color="primary"
                                onClick={() => {
                                    (props.handleDatasetCopyBtnClick && props.datasetRow) && props.handleDatasetCopyBtnClick(props?.datasetRow)
                                }}
                            >
                                <FileCopy />
                            </IconButton>
                        </Tooltip>
                    </MenuItem>}
                    {props.collectionType === "dataSetCollection" &&
                <MenuItem onClick={handleCloseMoreActions}>
                    <Tooltip title={shuffleDataSetToAnotherModelButtonTooltipText}>
                        <IconButton
                            color="primary"
                            onClick={() => {
                                (props.handleShuffleDatasetBtnClick && props.datasetRow) && props.handleShuffleDatasetBtnClick(props?.datasetRow)
                            }}
                        >
                            <ShuffleIcon />
                        </IconButton>
                    </Tooltip>
                </MenuItem>}
                {props.collectionType === "dataSetCollection" &&
                <MenuItem onClick={handleCloseMoreActions}>
                    <Tooltip title={splitDataSetToAnotherModelButtonTooltipText}>
                        <IconButton
                            color="primary"
                            onClick={() => {
                                (props.handleSplitDatasetBtnClick && props.datasetRow) && props.handleSplitDatasetBtnClick(props?.datasetRow)
                            }}
                        >
                            <CallSplitIcon />
                        </IconButton>
                    </Tooltip>
                </MenuItem>}    
                {props.collectionType === "dataSetCollection" && aiTestEnabled &&
                    <MenuItem onClick={handleCloseMoreActions}>
                        <Tooltip title={aiTestDescription}>
                            <IconButton
                                // color="primary"
                                disabled={props.loadingAiTest}
                                style={{ color: '#DAA520' }}
                                onClick={() => {
                                    (props.handleDatasetAiTestBtnClick && props.datasetRow) && props.handleDatasetAiTestBtnClick(props?.datasetRow)
                                }}
                            >
                                {
                                    props.loadingAiTest ?
                                        <CircularProgress style={{ width: '25px', height: '25px' }} /> :
                                        <AssistantIcon />
                                }
                            </IconButton>
                        </Tooltip>
                    </MenuItem>}
            </Menu>
        </>
    )
}

export default SubActionMenu
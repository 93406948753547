// import TextsmsIcon from '@material-ui/icons/Textsms';
type Props = {
  IconComponentProps?: any,
  isActive?: boolean
}

export function ModelTypeDLP(props: Props) {
  return (
    // <TextsmsIcon {...props.IconComponentProps} />
    <div className={props.IconComponentProps.className} style={{fontSize: '11px', color: props?.isActive !== undefined && props.isActive === false ? '#898989' : '#008D9C', fontWeight: 'bold'}}>
      <strong>DLP</strong>
    </div>
  );
}
import React, { useEffect } from 'react';
import { makeStyles, Theme } from '@material-ui/core/styles';
import FormControl from '@material-ui/core/FormControl';
import Select from '@material-ui/core/Select';
import MenuItem from '@material-ui/core/MenuItem';
import clsx from 'clsx';
import { Status } from '../../../../../../../../common/enum/coPilot';
import { ICoPilotResource } from '../../../../../../../../common/constants/interfaces/coPilotResource';
// import InputLabel from '@material-ui/core/InputLabel';
import { CopilotLabelAvailable } from '../../../../../../../../common/constants/interfaces/copilot';
import { Autocomplete } from '@material-ui/lab';
import { ignoreRegexSpecialCharactersInText } from '../../../../../../../../services/regexHelperService';
import { removeDuplicateElementsFromArray } from '../../../../../../../../services/arrayHelperService';
import { TextField } from '@material-ui/core';


const useStyles = makeStyles((theme: Theme) => ({
    Orange:{
      backgroundColor: '#ff9131',
    },
    Brown:{
      backgroundColor: '#ad6937',
    },
    Grey:{
      backgroundColor: '#bcbcbc',
    },
    Green:{
      backgroundColor: '#7cd74b',
    },
    DarkBlue:{
      backgroundColor: '#4567b7',
    },
    Blue:{
      backgroundColor: '#06a3ce',
    },
    Red:{
      backgroundColor: '#ff625c',
    },
    Lavender:{
      backgroundColor: '#e09bfe',
    },
    Aqua:{
      backgroundColor: '#50d9b7',
    },
    Yellow:{
      backgroundColor: '#ffdd44',
    },
    HunterGreen:{
      backgroundColor: '#409544',
    },
    White:{
      backgroundColor: '#ffffff',
    },
    NoColor:{
      backgroundColor: '#F4F5F7',
      "&&::before":{
      content:"--Select-Color--",
      backgroundColor: '#F4F5F7',
      color: '#000000'}
    },
    drawer:{
      "&&&:before": {
        borderBottom: "none",
        content: "none"
      },
      "&&:after": {
        borderBottom: "none",
        content: "none"
      },
      '& .Muilist-padding':{
        padding:'0px'
      },
      "& .MuiSelect-select":{
        padding:"0px"
      },
      "& .MuiSelect-select:focus":{
        backgroundColor:"transparent"
      }
    },
    LabelDropdown : {
      height: '28px', 
      margin:'4px', 
      borderRadius:"3px", 
      justifyContent: 'center'
    },
    menuPaper: {
      maxHeight:'150px',
    }
  }));


// const colors = ["Yellow", "Orange", "Brown", "Grey", "Green", "Dark Blue", "Blue", "Red", "Lavender", "Aqua", "Hunter Green", "White", "NoColor", "Burritos", "Veggie Burritos"]

const No_Color = "NoColor";

const ColorSelector = (props: {
  colorChange?:Function, 
  selectedColor?: string, 
  resource?: ICoPilotResource,
  labelsAvailable: CopilotLabelAvailable[]
  allowUserToSetAnyLabel?: boolean
}) => {
    const classes = useStyles();
    const defaultMessage = "-- Select Option --";
    console.log("selectedColor", props.selectedColor)
    const [color, setColor] = React.useState(props.selectedColor || defaultMessage);
    useEffect(() => {
      
      const colors = props.labelsAvailable.map((labelAvailable)=>labelAvailable.value);
      
      // if(colors.includes(props.selectedColor || "")){
      //   setColor(props.selectedColor || "")
      // }
      if(color===""){
        setColor(defaultMessage)
      }
    },[props.labelsAvailable, props.selectedColor])

    const handleChange = (event: React.ChangeEvent<{ value: unknown }>) => {
      setColor(event.target.value as string);
      props.colorChange && props.colorChange(event.target.value)
    };
    console.log("allowUserToSetAnyLabel" ,props.allowUserToSetAnyLabel)
  return (
    <div>
        <FormControl variant='standard' style={{width: '100%'}} disabled={[Status.REJECTED, Status.APPROVED, Status.DELETED].includes(props.resource?.status as Status) ? true : false}>
                                 { !props.allowUserToSetAnyLabel? <Select
                                    value={color}
                                    onChange={handleChange}
                                    className= {clsx(
                                    //  {[classes.Yellow]:color === colors[0]},
                                    //   {[classes.Orange]:color === colors[1]},
                                    //  {[classes.Brown]:color === colors[2]},
                                    //  {[classes.Grey]:color === colors[3]},
                                    //  {[classes.Green]:color === colors[4]},
                                    //  {[classes.DarkBlue]:color === colors[5]},
                                    //  {[classes.Blue]:color === colors[6]},
                                    //  {[classes.Red]:color === colors[7]},
                                    //  {[classes.Lavender]:color === colors[8]},
                                    //  {[classes.Aqua]:color === colors[9]},
                                    //  {[classes.HunterGreen]:color === colors[10]},
                                    //  {[classes.White]:color === colors[11]},
                                    //  {[classes.NoColor]:color === colors[12]},
                                    //  {[classes.NoColor]:color === colors[13]},
                                     {[classes.NoColor]:color === No_Color},
                                     classes.drawer)}
                                    inputProps={{ 'aria-label': 'Without label' }}
                                    style={{
                                      height: '40px', 
                                      padding:"0px 4px", 
                                      borderRadius:"25px", 
                                      textAlign:"center",
                                      backgroundColor: props.labelsAvailable?.find(labelAvailable=>labelAvailable.value === color)?.textBackgroundColour,
                                      color: props.labelsAvailable?.find(labelAvailable=>labelAvailable.value === color)?.textColour
                                    }}
                                    variant='standard' 
                                    MenuProps={{
                                      anchorOrigin: {
                                        vertical: "bottom",
                                        horizontal: "left"
                                      },
                                      // transformOrigin: {
                                      //   vertical: "top",
                                      //   horizontal: "left"
                                      // },
                                      getContentAnchorEl: null,
                                      classes: { paper: classes.menuPaper}
                                    }}
                                    native={false}
                                    displayEmpty={true}
                                    renderValue={() => (color!==No_Color? color : <option value={defaultMessage} style={{backgroundColor: '#F4F5F7'}}>{defaultMessage}</option>)}
                                    >
                                    
                                    {
                                      props.labelsAvailable?.map(labelAvailable => {
                                        return (
                                          <MenuItem value={labelAvailable.value} style={{backgroundColor: labelAvailable.textBackgroundColour, color: labelAvailable.textColour}}  className={classes.LabelDropdown}>{labelAvailable.value}</MenuItem>
                                        )
                                      })
                                    }


                                    {/* <MenuItem value='Orange' style={{backgroundColor: '#ff9131'}}  className={classes.LabelDropdown}>Orange</MenuItem>
                                    <MenuItem value='Brown' style={{backgroundColor: '#ad6937'}}  className={classes.LabelDropdown}>Brown</MenuItem>
                                    <MenuItem value='Grey' style={{backgroundColor: '#bcbcbc'}}  className={classes.LabelDropdown}>Grey</MenuItem>
                                    <MenuItem value='Green' style={{backgroundColor: '#7cd74b'}}  className={classes.LabelDropdown}>Green</MenuItem>
                                    <MenuItem value='Dark Blue' style={{backgroundColor: '#4567b7'}}  className={classes.LabelDropdown}>Dark Blue</MenuItem>
                                    <MenuItem value='Blue' style={{backgroundColor: '#06a3ce'}} className={classes.LabelDropdown}>Blue</MenuItem>
                                    <MenuItem value='Red' style={{backgroundColor: '#ff625c'}} className={classes.LabelDropdown}>Red</MenuItem>
                                    <MenuItem value='Lavender' style={{backgroundColor: '#e09bfe'}} className={classes.LabelDropdown}>Lavender</MenuItem>
                                    <MenuItem value='Aqua' style={{backgroundColor: '#50d9b7'}} className={classes.LabelDropdown}>Aqua</MenuItem>
                                    <MenuItem value='Yellow' style={{backgroundColor: '#ffdd44'}} className={classes.LabelDropdown}>Yellow</MenuItem>
                                    <MenuItem value='Hunter Green' style={{backgroundColor: '#409544'}} className={classes.LabelDropdown}>Hunter Green</MenuItem>
                                    <MenuItem value='White' style={{backgroundColor: '#ffffff'}} className={classes.LabelDropdown}>White</MenuItem>
                                    <MenuItem value='Burritos' style={{backgroundColor: '#ffffff'}} className={classes.LabelDropdown}>Burritos</MenuItem>
                                    <MenuItem value='Veggie Burritos' style={{backgroundColor: '#ffffff'}} className={classes.LabelDropdown}>Veggie Burritos</MenuItem> */}
                                  </Select> :
        <Autocomplete
          options={props.labelsAvailable.map((state) => state.value)}
          freeSolo={props.allowUserToSetAnyLabel ? true : false}
          style={{
            height: '40px', 
            padding:"0px 4px", 
            borderRadius:"25px", 
            textAlign:"center",
            backgroundColor: props.labelsAvailable?.find(labelAvailable=>labelAvailable.value === color)?.textBackgroundColour,
            color: props.labelsAvailable?.find(labelAvailable=>labelAvailable.value === color)?.textColour
          }}
          className={clsx(
            { [classes.NoColor]: color === No_Color },
            classes.drawer)}
          filterOptions={(options, state) => {
            console.log("Autocomplete~options", options)
            let inputValue = state.inputValue;

            if (!inputValue) {
              return options;
            }

            inputValue = ignoreRegexSpecialCharactersInText(inputValue);

            const filteredOptionsByBeginningLetter = options.filter((option) => {
              return new RegExp(`^${inputValue}`, 'i').test(option)
            })
            const filteredOptionsByLetterContains = options.filter((option) => {
              return new RegExp(`${inputValue}`, 'i').test(option)
            })
            return [
              ...removeDuplicateElementsFromArray(
                [
                  ...filteredOptionsByBeginningLetter,
                  ...filteredOptionsByLetterContains
                ]
              )
            ]
          }}
          disabled={[Status.REJECTED, Status.APPROVED, Status.DELETED].includes(props.resource?.status as Status) ? true : false}
          onChange={(event, value) => {
            console.log("colortesting",event)
            if (
              value !== "" &&
              value !== null &&
              value !== undefined
            ){
            console.log("colortesting", value)
            setColor(value as string);
            }
          }}
          onInputChange={(event, value) => {
            console.log("colortesting", value)
            props.colorChange && props.colorChange(value)
          }}
          autoHighlight
          blurOnSelect
          getOptionLabel={(option) => option}
          value={color}
          renderOption={(color) => <span>{color}</span>}
          renderInput={(params) => (
            <TextField
              {...params}
              InputProps={{
                ...params.InputProps,
                disableUnderline: true,
                // classes: {
                //   disabled: classes.disabled,
                // },
                style: {
                  paddingRight: 0,
                },
              }}
              fullWidth
            inputProps={{
              ...params.inputProps,
              style: {
                fontSize: "16px",
                height: "28px",
                overflow: "hidden",
                textOverflow: "ellipsis",
                whiteSpace: "nowrap",
                // margin:"0px 4px",
                margin: "0px 10px",
                textAlign: "center"
              },
              autoComplete: "new-password", // disable autocomplete and autofill
            }}
            />
          )}
        />}
      </FormControl>
    </div>
  )
}

export default ColorSelector
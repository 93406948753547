import { CircularProgress, Tooltip } from "@material-ui/core";
import { CustomLink } from "../../../../../../../../../../../../common/components/CustomLink/CustomLink.component";
import { useFetchResourcesDetails } from "../../../../../../../../../../../../hooks/useFetchResourcesDetails";
import css from "./referredDataValueInResourcePopup.module.css";
import { ResourceUrlService } from "../../../../../../../../../../../../services/resourceUrlService";

type Props = {
  resourceIds: string[];
};

export const ReferredDataValueInResourcePopup = (props: Props) => {
  const { resources } = useFetchResourcesDetails({
    resourceIds: props.resourceIds,
    resourceCache: {
      timeToLiveInMilliSeconds: 15 * 1000,
    },
    shouldFetchResourceProjectDetails: true,
  });

  return (
    <>
      {resources.length === 0 && <CircularProgress color="primary" size={24} />}
      {resources?.map((resource, resourceIndex) => {
        const nextResource = resources[resourceIndex + 1];
        const resourceLink =
          ResourceUrlService.generateUrlToShowResourceInItsTaskDataTable(
            resource
          );
        return (
          <span
            style={{
              display: "flex",
              width: "100%",
            }}
          >
            <Tooltip title={resource.filename}>
              <span
                style={{
                  width: "100%",
                }}
              >
                <CustomLink
                  linkProps={{
                    color: "primary",
                    href: resourceLink,
                    target: "_blank",
                    className: `${css.resourceLink}`,
                  }}
                  linkText={resource.filename}
                />
              </span>
            </Tooltip>
            {nextResource && <span>, </span>}
          </span>
        );
      })}
    </>
  );
};

import React, { Fragment, useRef, useState } from 'react';
import { makeStyles } from '@material-ui/core';
import clsx from 'clsx';

// @ts-ignore
import WaveSurfer from "wavesurfer.js/dist/wavesurfer.min";


import { Audiotrack } from '@material-ui/icons';
import { Box } from '@material-ui/core';
import {useEffect} from 'react';
import {useMemo} from 'react';
import { generateUniqueId } from '../../../../services/idHelperService';

const useStyles = makeStyles((theme) => ({
    infoBlock: {
        paddingTop: theme.spacing(4),
    },
    typeIcon: {
        fontSize: '20px',
        color: theme.palette.primary.main
    },
    type: {
        background: '#d4e8ea', //TODO take color from pallete
        width: '45px',
        height: '45px',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        borderRadius: '50%'
    },
    info: {
        display: 'flex',
        justifyContent: 'center',
        paddingBottom: theme.spacing(2)
    },
    infoLabel: {
        margin: 0,
        minWidth: '100px',
        color: theme.palette.grey[600],
        marginBottom: 'auto',
        marginTop: 'auto',
    },
    infoValue: {
        margin: 0,
        minWidth: '200px',
        fontWeight: 500,
        display: 'inline-block',
        whiteSpace: 'nowrap',
        overflow: 'hidden',
        textOverflow: 'ellipsis', 
    },
    actions: {
        minWidth: '200px',
        minHeight: '48px',
        display: 'flex',
        alignItems: 'center'
    },
    labelBlock: {
        width: '300px',
        marginLeft: 'auto',
        marginRight: 'auto',
        marginBottom: theme.spacing(2),
    },
    indicators: {
        position: 'absolute',
        bottom: '40px',
        width: '50px',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        cursor: 'pointer',
        transition: 'opacity 0.25s ease-in-out',
        borderRadius: '50%',
        background: 'rgba(2, 141, 154, 0.1)',
    },
    leftIndicator: {
        left: 10,
    },
    rightIndicator: {
        right: 10,
    },
    status: {
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        justifyContent: 'center',
        height: '100%'
    },
    delete: {
        color: '#EF8A8A'
    },
    progress: {
        padding: '10px',
    },
    playContainer: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
    },
    playBlock: {
        width: '50px',
        height: '50px',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        borderRadius: '50%',
        // marginLeft: '0.5rem',
        // marginRight: '0.5rem',
        cursor: 'pointer',
    },
    onPlay: {
        background: '#008D9C',
    },
    onPause: {
        background: '#EF8A8A',
    },
    play: {
        color: '#fff',
    },
    pause: {
        color: '#fff',
    },
    forward: {
        color: '#98A6B3',
        cursor: 'pointer',
    },
    backward: {
        color: '#98A6B3',
        cursor: 'pointer',
    },
    equBlock: {
        display: 'flex',
        justifyContent: 'center',
        marginTop: '2rem',
        marginBottom: '2rem',
    },
    equ: {
        fontSize: '8rem',
        color: '#008D9C',
    },
    audioNotReadyToPlay: {
        opacity: 0.4
    }
}));

interface IProps {
    src: string;
    startTimeInSeconds?: number;
    endTimeInSeconds?: number;
}

// const AudioPlayer = (props: IProps) => {
//     const { src } = props;
//     const classes = useStyles();
//     const audioRef = useRef<HTMLAudioElement>(null);
//     const [playing, setPlaying] = useState(false);

//     const handleClick = () => {
//         setPlaying(!playing);
//         if (audioRef.current) {
//             audioRef.current.src = src; //TODO explore why src is empty?
//         }
//         if (playing) {
//             audioRef?.current?.pause();
//         } else {
//             // const audio = new Audio(src);
//             // audio.currentTime=0.5
//             // audio.play();
//             audioRef?.current?.play();
//         }
//     };
//     const handleOnended = () => {
//         setPlaying(false);
//     }
//     return (
//         <Fragment>
           
//             <div className={classes.playContainer} >
                
//                 {/* <audio ref={audioRef} onEnded={handleOnended}>
//                     <source src={src} type="audio/mp3" />
//                     Your browser does not support the audio element.
//                 </audio> */}
//                 {/* <FastRewindIcon fontSize="large" classes={{ root: classes.backward }} /> */}
//                 <div className={clsx(classes.playBlock, {
//                     [classes.onPlay]: !playing,
//                     [classes.onPause]: playing,
//                 })} onClick={() => handleClick()}>
//                     <audio ref={audioRef} onEnded={handleOnended}
//                         onPause={()=>{
//                             setPlaying(false);
//                         }}
//                     >
//                     <source src={src} type="audio/mp3" />
//                     Your browser does not support the audio element.
//                 </audio>
//                     <Box className={classes.type}>
//                     <Audiotrack className={classes.typeIcon}/></Box>
//                 </div>
//                 {/* <FastForwardIcon fontSize="large" classes={{ root: classes.forward }} /> */}
//             </div>
//         </Fragment>
//     );
// }

const AudioPlayer = (props: IProps) => {
    const { src, startTimeInSeconds, endTimeInSeconds } = props;
    const classes = useStyles();
    const audioRef = useRef<HTMLAudioElement>(null);
    const [playing, setPlaying] = useState(false);
    const [isAudioReadyToPlay, setisAudioReadyToPlay] = useState(false);

    const waveFormContainerId = useMemo(() => {
        return "waveform"+generateUniqueId().replaceAll("-","")
    }, [])

    const wavesurferRef = useRef(undefined);
    

    useEffect(()=>{
        if (wavesurferRef.current) {
            // @ts-ignore
            wavesurferRef.current.destroy();
        }
            setisAudioReadyToPlay(false);
            wavesurferRef.current = WaveSurfer.create({
                container: `#${waveFormContainerId}`,
                // waveColor: "#d4e8e9",
                // progressColor: "#008d9c",
                // responsive: true,
                // barWidth: 3,
                // barRadius: 3,
                // cursorWidth: 1,
                // height: 100,
                // barGap: 3,
                // plugins: [
                //   WaveSurferRegionPlugin.create({}),
                //   WaveSurferTimelinePlugin.create({
                //     container: "#waveform-timeline",
                //     formatTimeCallback: formatTimeCallback,     // It is used for formatting the timeline notches
                //     timeInterval: timeInterval,                 // It is the gap between the secondaryLabelInterval (Note: To get the primaryLabelInterval multiple the timeInterval with 5)
                //     primaryLabelInterval: 5,      // It tells after how much secondary bars primary bar is required
                //     secondaryLabelInterval: 5,    // No of secondary bars needed inbetween primary bars 
                //   })
                // ],
               });   

               //    @ts-ignore
                  wavesurferRef.current.on("ready", function() {
                    //   alert("audio ready")
                    setisAudioReadyToPlay(true);
                  })
               //    @ts-ignore
               wavesurferRef.current.on("pause", function() {
                   //   alert("audio ready")
                   setPlaying(false)
                })
                //    @ts-ignore
                  wavesurferRef.current.on("finish", function() {
                    //   alert("audio ready")
                    setPlaying(false)
                  })
               //    @ts-ignore
               wavesurferRef.current.load(src);
        return () => {
            if (wavesurferRef.current) {
                // @ts-ignore
                wavesurferRef.current.destroy();
            }            
        }
    }, [src, waveFormContainerId])

    const handleClick = () => {

        if (!isAudioReadyToPlay) {
            return;
        }

        setPlaying(!playing);
        if (audioRef.current) {
            audioRef.current.src = src; //TODO explore why src is empty?
        }
        if (playing) {
            // audioRef?.current?.pause();
            // @ts-ignore
            wavesurferRef.current.stop();
        } else {
            // audioRef?.current?.play();

            // @ts-ignore
            wavesurferRef.current.play(
                startTimeInSeconds, 
                endTimeInSeconds
            );
        }
    };
    const handleOnended = () => {
        setPlaying(false);
    }
    return (
        <Fragment>

            <div id={waveFormContainerId} style={{display: "none"}}></div>
           
            <div className={`${classes.playContainer} ${!isAudioReadyToPlay ? classes.audioNotReadyToPlay : ""}`} >
                
                {/* <audio ref={audioRef} onEnded={handleOnended}>
                    <source src={src} type="audio/mp3" />
                    Your browser does not support the audio element.
                </audio> */}
                {/* <FastRewindIcon fontSize="large" classes={{ root: classes.backward }} /> */}
                <div className={clsx(classes.playBlock, {
                    [classes.onPlay]: !playing,
                    [classes.onPause]: playing,
                })} onClick={() => handleClick()}>
                    <audio ref={audioRef} onEnded={handleOnended}
                        onPause={()=>{
                            setPlaying(false);
                        }}
                    >
                    <source src={src} type="audio/mp3" />
                    Your browser does not support the audio element.
                </audio>
                    <Box className={classes.type}>
                    <Audiotrack className={classes.typeIcon}/></Box>
                </div>
                {/* <FastForwardIcon fontSize="large" classes={{ root: classes.forward }} /> */}
            </div>
        </Fragment>
    );
}

export default AudioPlayer;

/**
 * @description
 * used in text comparison task type
 */

import { useEffect, useRef, useState } from "react";
import { IData, WordMetadata } from "../../../../common";
import css from "./TextDiffSection.module.css";
import { isNullOrUndefined } from "../../../../services/variableHelperService";

type ForTextType = "one" | "two";

type TextDiffSectionProps = {
  forText: ForTextType;
  text: string;
  textDifferences: IData["textsDifferences"];
  className?: string;
  originalAudioCurrentTime?:number;
  audioTranscriptionWordsMetadatas?:WordMetadata[];
  audioTranscription?: string
};

function doesCharacterHasDifferenceInText(
  forText: ForTextType,
  textDifferences: IData["textsDifferences"],
  characterIndexPositionToCheckFor: number
): boolean {
  for (const textDifference of textDifferences || []) {
    if (
      textDifference.characterIndexPosition ===
        characterIndexPositionToCheckFor &&
      forText === textDifference.inText
    ) {
      return true;
    }
  }
  return false;
}

function textHighlightColor(
  forText: ForTextType,
  textDifferences: IData["textsDifferences"],
  characterIndexPositionToCheckFor: number
): string {
  for (const textDifference of textDifferences || []) {

    if (
      textDifference.characterIndexPosition ===
        characterIndexPositionToCheckFor &&
      forText === textDifference.inText
    ) {
      return textDifference?.highlightColor||"";
    }
  }
  return "";
}

function convertWordIndexToCharacterIndex(text: string, wordIndexToCheck: number): number {
  let characterIndexToSend = -1;
  let wordIndex = -1;
  let wordStarted = false;
  for (let characterIndex = 0; characterIndex < text.split("").length; characterIndex++) {
      const character = text.split("")[characterIndex];
      const previousCharacter = text.split("")[characterIndex-1];
      const nextCharacter = text.split("")[characterIndex+1];
      console.log('convertWordIndexToCharacterIndex ~ nextCharacter:', nextCharacter)
      console.log('convertWordIndexToCharacterIndex ~ previousCharacter:', previousCharacter)
      console.log('convertWordIndexToCharacterIndex ~ character:', character)

      
      
      if (!previousCharacter) {
          wordIndex += 1;
      } else if (previousCharacter === " " && character !== " ") {
          wordIndex += 1;
      }

      console.log('convertWordIndexToCharacterIndex ~ wordIndex:', wordIndex)        
      if (wordIndex > wordIndexToCheck) {
          break;
      }

      if (
          !(
              character === " " && wordIndex === wordIndexToCheck
          )
      ) {
          characterIndexToSend +=1;
          console.log('convertWordIndexToCharacterIndex ~ characterIndexToSend:', characterIndexToSend)
      }
  }

  return characterIndexToSend;
}

export const TextDiffSection = (props: TextDiffSectionProps) => {
  const [lastCharacterIndexToHighlightInPlayback, setLastCharacterIndexToHighlightInPlayback] = useState<number>(-1)

  const convertWordIndexToCharacterIndexMemCacheRef = useRef({});
 
  console.log("audioTranscriptionWordsMetadatas", props.audioTranscriptionWordsMetadatas)
  useEffect(() => {
    props.audioTranscriptionWordsMetadatas?.forEach((wordMetadata, index) => {
      if (props.originalAudioCurrentTime === undefined) return;
      const timeFrameInAudio = (()=>{
        if ((props.audioTranscriptionWordsMetadatas || [])[index+1]) {
          return (props.audioTranscriptionWordsMetadatas || [])?.[index+1]?.timeFrameInAudio
        } else {
          return wordMetadata.timeFrameInAudio;
        }
      })();

      const timeFrameIndex = (()=>{
        // return index;
        if ((props.audioTranscriptionWordsMetadatas || [])[index+1]) {
          return index+1
        } else {
          return index
        }        
      })();
      if (props.originalAudioCurrentTime * 1000 > timeFrameInAudio?.startInMilliSeconds) {
        
        // @ts-ignore
        if (isNullOrUndefined(convertWordIndexToCharacterIndexMemCacheRef.current[timeFrameIndex])) {
          
          const characterIndex = convertWordIndexToCharacterIndex((props.audioTranscription||""),timeFrameIndex+1);
          // @ts-ignore
          convertWordIndexToCharacterIndexMemCacheRef.current[timeFrameIndex] = characterIndex
          setLastCharacterIndexToHighlightInPlayback(characterIndex);
        } else {
          // @ts-ignore
          setLastCharacterIndexToHighlightInPlayback(convertWordIndexToCharacterIndexMemCacheRef.current[timeFrameIndex]);
        }




      //  indices.push(wordMetadata?.characterIndex || 0);
      }
    });

  }, [props.originalAudioCurrentTime])

  console.log("lastCharacterIndexToHighlightInPlayback", lastCharacterIndexToHighlightInPlayback)

  // const updateHighlightedIndices = () => {
  //   if (originalAudioCurrentTime === undefined) return;
  //   const indices: number[] = [];
  //   audioTranscriptionWordsMetadatas?.forEach((wordMetadata, index) => {
  //     if (originalAudioCurrentTime * 1000 > wordMetadata.timeFrameInAudio?.startInMilliSeconds) {
  //       setlastCharacterIndexToHighlightInPlayback(convertWordIndexToCharacterIndex(wordMetadata?.word,index))
  //     //  indices.push(wordMetadata?.characterIndex || 0);
  //     }
  //   });
  //   setHighlightedIndices(indices);
  // };
  
  return (
    <div
      className={`
                ${css.TextDiffSectionComponentRoot}
                ${
                  props.forText === "one"
                    ? css.forTextOne
                    : props.forText === "two"
                    ? css.forTextTwo
                    : ""
                }
                ${props.className ? props.className : ""}
            `}
    >
      {props.text?.split("")?.map((character, characterIndex) => {
        const does_CharacterHasDifferenceInText =
          doesCharacterHasDifferenceInText(
            props.forText,
            props.textDifferences,
            characterIndex
          );
          const highlightColor: string = textHighlightColor(
            props.forText,
            props.textDifferences,
            characterIndex
          )

        return (
          <span
            key={characterIndex}
            className={`
                ${css.character}
                ${
                  does_CharacterHasDifferenceInText
                    ? css.characterHasDifferenceInText
                    : 
                    characterIndex<=lastCharacterIndexToHighlightInPlayback?
                    css.audioPlaybackHighlightColor
                    :""
                }
            `}
            // style={{ backgroundColor: highlightColor}}
          >
            {character}
          </span>
        );
      })}
    </div>
  );
};

import React, { CSSProperties, useMemo, useState } from 'react';
import { IModelUniqueTrainingDataSet, IUniqueTestCollectionDataSet, IUniqueTestCollectionModel } from '../../../../../../../../../../../common';
import { InputFieldState } from '../../../../../../../../../../../common/constants/interfaces/inputFieldState';
import {
    Popover,
    Chip,
    Tooltip,
    Typography,
    Button,
    Box,
    TextField,
    Slider
  } from "@material-ui/core";
import { TableColumnResetFilterButton } from '../../data/components/TableColumnResetFilterButton';
import { Autocomplete } from '@material-ui/lab';
import { AccuracyStatuses } from '../constants/constants';

function ModelCollectionTableFilterComponent() {
  return (
    <div>ModelCollectionTableFilterComponent</div>
  )
}

export function VersionColumnMenu(props: { 
    open: boolean,
    versionSearchValue: string[],
    onClose: ((event: {}, reason: "backdropClick" | "escapeKeyDown") => void) | undefined
    resetVersionFilters: () => void
    isVersionFilterApplied: boolean
    classes: { tableColumnMenuHeading: CSSProperties; floating: CSSProperties; chip: CSSProperties; autoCompleteChipRoot: CSSProperties }
    onVersionApplyFilterButtonClick: (value: string[]) => void
    setVersionFilterSearchValue: (value: string[]) => Promise<void>
    uniqueVersionsAvailable : string[]
}) {
    // const classes : useStyles();
    const [versionSearchTextFieldState, setVersionSearchTextFieldState] = useState<string[]>(props.versionSearchValue);

    const [ uniqueVersions, ] = useState<string[]>(props.uniqueVersionsAvailable)
    // const [ uniquePredictions, setUniquePredictions] = useState<string[]>([])

    // const query = new URLSearchParams(window.location.search);
    // const modelId = query.get("model") || "";

    return (
      <Popover
        id="version-column-menu"
        anchorEl={document.getElementById("versionColumnOptions")}
        keepMounted
        anchorOrigin={{
            vertical: 'bottom',
            horizontal: 'right',
        }}
        transformOrigin={{
            vertical: 'top',
            horizontal: 'right',
        }}        
        open={props.open}
        onClose={props.onClose}
        PaperProps={{
          style: {
            width: "450px",
          },
        }}
      >
        {props.open && (
          <Box id="versionMenuItemContainer" style={{ padding: 15 }}>
              <div className={`${props.classes.tableColumnMenuHeading}`}>
                    <Typography
                    variant="body1"
                    style={{ marginBottom: 20 }}
                    color="primary"
                    >
                    &#8288;Version Column Filter
                    </Typography>
                    <Tooltip title="Reset Filters">
                        <Box>
                            <TableColumnResetFilterButton 
                                onClick={()=>props.resetVersionFilters()}
                                disabled={!props.isVersionFilterApplied}
                            />
                        </Box>
                    </Tooltip>
              </div>              
            <div style={{display: 'flex', justifyContent: 'space-between'}}>
                <Autocomplete
                    multiple
                    id="version-search-filter-value"
                    freeSolo
                    style={{width: '95%'}}
                    options={uniqueVersions}
                    value={versionSearchTextFieldState.filter(value => value !== "")}
                    onChange={(event, value) => {
                        setVersionSearchTextFieldState([...value]);
                    }}
                    renderTags={(value, getTagProps) => {
                        return value.map((option, index) => (
                            <Chip
                                variant="outlined"
                                color="primary"
                                className={`${props.classes.chip}`}
                                classes={{ root: `${props.classes.autoCompleteChipRoot}` }}
                                label={
                                    <Tooltip title={option}>
                                        <span>
                                            {option}
                                        </span>
                                    </Tooltip>
                                }
                                {...getTagProps({ index })}
                            />
                        ));
                    }}
                    renderInput={(params) => (
                        <TextField
                            className={`${props.classes.floating}`}
                            {...params}
                            variant="outlined"
                            size="small"
                            fullWidth
                            label="Version Filter Value"
                            placeholder="Add Version Filter"
                            onChange={(e) => {
                                if (e.target.value.includes(',') && e.target.value.substring(0, e.target.value.indexOf(',')).length > 1) {
                                    setVersionSearchTextFieldState([...versionSearchTextFieldState, e.target.value.substring(0, e.target.value.indexOf(','))])
                                }
                            }}
                            onBlur={(e) => {
                                if (e.target.value && e.target.value.replaceAll(',', '').length) {
                                    const val = e.target.value.replaceAll(',','');
                                    setVersionSearchTextFieldState([...versionSearchTextFieldState, val])
                                }
                            }}
                        />
                    )}
                />
                
            </div>



            <div id="resetFilterButtonContainer"
                style={{marginTop: '40px', display: 'flex', justifyContent: 'space-between'}}
            >
                <div></div>
                <Button variant="contained" color="primary"
                    onClick={()=>{
                            console.log(" ::::::::::::::: :: ", versionSearchTextFieldState)
                        props.setVersionFilterSearchValue(versionSearchTextFieldState)
                        .then(()=>{
                            props.onVersionApplyFilterButtonClick(versionSearchTextFieldState);
                        })
                    }}
                    disabled={(versionSearchTextFieldState ===  props.versionSearchValue)}
                >
                    Apply Filters
                </Button>
            </div>            
          </Box>
        )}
      </Popover>
    );
}

export function StatusColumnMenu(props: { 
    open: boolean,
    statusSearchValue: string[],
    onClose: ((event: {}, reason: "backdropClick" | "escapeKeyDown") => void) | undefined
    resetStatusFilters: () => void
    isStatusFilterApplied: boolean
    classes: { tableColumnMenuHeading: CSSProperties; floating: CSSProperties; chip: CSSProperties; autoCompleteChipRoot: CSSProperties }
    onStatusApplyFilterButtonClick: (value: string[]) => void
    setStatusFilterSearchValue: (value: string[]) => Promise<void>
    uniqueStatusesAvailable : string[]
}) {
    // const classes : useStyles();
    const [statusSearchTextFieldState, setStatusSearchTextFieldState] = useState<string[]>(props.statusSearchValue);

    const [ uniqueStatuses, ] = useState<string[]>(props.uniqueStatusesAvailable)
    // const [ uniquePredictions, setUniquePredictions] = useState<string[]>([])

    // const query = new URLSearchParams(window.location.search);
    // const modelId = query.get("model") || "";

    return (
      <Popover
        id="status-column-menu"
        anchorEl={document.getElementById("statusColumnOptions")}
        keepMounted
        anchorOrigin={{
            vertical: 'bottom',
            horizontal: 'right',
        }}
        transformOrigin={{
            vertical: 'top',
            horizontal: 'right',
        }}        
        open={props.open}
        onClose={props.onClose}
        PaperProps={{
          style: {
            width: "450px",
          },
        }}
      >
        {props.open && (
          <Box id="statusMenuItemContainer" style={{ padding: 15 }}>
              <div className={`${props.classes.tableColumnMenuHeading}`}>
                    <Typography
                    variant="body1"
                    style={{ marginBottom: 20 }}
                    color="primary"
                    >
                    &#8288;Status Column Filter
                    </Typography>
                    <Tooltip title="Reset Filters">
                        <Box>
                            <TableColumnResetFilterButton 
                                onClick={()=>props.resetStatusFilters()}
                                disabled={!props.isStatusFilterApplied}
                            />
                        </Box>
                    </Tooltip>
              </div>              
            <div style={{display: 'flex', justifyContent: 'space-between'}}>
                <Autocomplete
                    multiple
                    id="status-search-filter-value"
                    freeSolo
                    style={{width: '95%'}}
                    options={uniqueStatuses}
                    value={statusSearchTextFieldState.filter(value => value !== "")}
                    onChange={(event, value) => {
                        setStatusSearchTextFieldState([...value]);
                    }}
                    renderTags={(value, getTagProps) => {
                        return value.map((option, index) => (
                            <Chip
                                variant="outlined"
                                color="primary"
                                className={`${props.classes.chip}`}
                                classes={{ root: `${props.classes.autoCompleteChipRoot}` }}
                                label={
                                    <Tooltip title={option}>
                                        <span>
                                            {option}
                                        </span>
                                    </Tooltip>
                                }
                                {...getTagProps({ index })}
                            />
                        ));
                    }}
                    renderInput={(params) => (
                        <TextField
                            className={`${props.classes.floating}`}
                            {...params}
                            variant="outlined"
                            size="small"
                            fullWidth
                            label="Status Filter Value"
                            placeholder="Add Status Filter"
                            onChange={(e) => {
                                if (e.target.value.includes(',') && e.target.value.substring(0, e.target.value.indexOf(',')).length > 1) {
                                    setStatusSearchTextFieldState([...statusSearchTextFieldState, e.target.value.substring(0, e.target.value.indexOf(','))])
                                }
                            }}
                            onBlur={(e) => {
                                if (e.target.value && e.target.value.replaceAll(',', '').length) {
                                    const val = e.target.value.replaceAll(',','');
                                    setStatusSearchTextFieldState([...statusSearchTextFieldState, val])
                                }
                            }}
                        />
                    )}
                />
                
            </div>



            <div id="resetFilterButtonContainer"
                style={{marginTop: '40px', display: 'flex', justifyContent: 'space-between'}}
            >
                <div></div>
                <Button variant="contained" color="primary"
                    onClick={()=>{
                            console.log(" ::::::::::::::: :: ", statusSearchTextFieldState)
                        props.setStatusFilterSearchValue(statusSearchTextFieldState)
                        .then(()=>{
                            props.onStatusApplyFilterButtonClick(statusSearchTextFieldState);
                        })
                    }}
                    disabled={(statusSearchTextFieldState ===  props.statusSearchValue)}
                >
                    Apply Filters
                </Button>
            </div>            
          </Box>
        )}
      </Popover>
    );
}

export function TrainingDataSetCollectionNameColumnMenu(props: { 
    open: boolean,
    trainingDataSetCollectionNameSearchValue: string[],
    onClose: ((event: {}, reason: "backdropClick" | "escapeKeyDown") => void) | undefined
    resetTrainingDataSetCollectionNameFilters: () => void
    isTrainingDataSetCollectionNameFilterApplied: boolean
    classes: { tableColumnMenuHeading: CSSProperties; floating: CSSProperties; chip: CSSProperties; autoCompleteChipRoot: CSSProperties }
    onTrainingDataSetCollectionNameApplyFilterButtonClick: (value: string[]) => void
    setTrainingDataSetCollectionNameFilterSearchValue: (value: string[]) => Promise<void>
    uniqueTrainingDataSetCollectionNamesAvailable : IModelUniqueTrainingDataSet[]
}) {
    // const classes : useStyles();
    const [trainingDataSetCollectionNameSearchTextFieldState, setTrainingDataSetCollectionNameSearchTextFieldState] = useState<string[]>(props.trainingDataSetCollectionNameSearchValue);

    const [ uniqueTrainingDataSetCollectionNames, ] = useState<IModelUniqueTrainingDataSet[]>(props.uniqueTrainingDataSetCollectionNamesAvailable)
    // const [ uniquePredictions, setUniquePredictions] = useState<string[]>([])

    // const query = new URLSearchParams(window.location.search);
    // const modelId = query.get("model") || "";

    return (
      <Popover
        id="trainingDataSetCollectionName-column-menu"
        anchorEl={document.getElementById("trainingDataSetCollectionNameColumnOptions")}
        keepMounted
        anchorOrigin={{
            vertical: 'bottom',
            horizontal: 'right',
        }}
        transformOrigin={{
            vertical: 'top',
            horizontal: 'right',
        }}        
        open={props.open}
        onClose={props.onClose}
        PaperProps={{
          style: {
            width: "450px",
          },
        }}
      >
        {props.open && (
          <Box id="trainingDataSetCollectionNamesMenuItemContainer" style={{ padding: 15 }}>
              <div className={`${props.classes.tableColumnMenuHeading}`}>
                    <Typography
                    variant="body1"
                    style={{ marginBottom: 20 }}
                    color="primary"
                    >
                    &#8288;Training Data Sets Column Filter
                    </Typography>
                    <Tooltip title="Reset Filters">
                        <Box>
                            <TableColumnResetFilterButton 
                                onClick={()=>props.resetTrainingDataSetCollectionNameFilters()}
                                disabled={!props.isTrainingDataSetCollectionNameFilterApplied}
                            />
                        </Box>
                    </Tooltip>
              </div>              
            <div style={{display: 'flex', justifyContent: 'space-between'}}>
                <Autocomplete
                    multiple
                    id="training-data-sets-search-filter-value"
                    freeSolo
                    style={{width: '95%'}}
                    options={uniqueTrainingDataSetCollectionNames.map(uniqueValue => uniqueValue.name)}
                    value={trainingDataSetCollectionNameSearchTextFieldState.filter(value => value !== "")}
                    onChange={(event, value) => {
                        setTrainingDataSetCollectionNameSearchTextFieldState([...value]);
                    }}
                    renderTags={(value, getTagProps) => {
                        return value.map((option, index) => (
                            <Chip
                                variant="outlined"
                                color="primary"
                                className={`${props.classes.chip}`}
                                classes={{ root: `${props.classes.autoCompleteChipRoot}` }}
                                label={
                                    <Tooltip title={option}>
                                        <span>
                                            {option}
                                        </span>
                                    </Tooltip>
                                }
                                {...getTagProps({ index })}
                            />
                        ));
                    }}
                    renderInput={(params) => (
                        <TextField
                            className={`${props.classes.floating}`}
                            {...params}
                            variant="outlined"
                            size="small"
                            fullWidth
                            label="Training Data Sets Filter Value"
                            placeholder="Add Training Data Sets Filter"
                            onChange={(e) => {
                                if (e.target.value.includes(',') && e.target.value.substring(0, e.target.value.indexOf(',')).length > 1) {
                                    setTrainingDataSetCollectionNameSearchTextFieldState([...trainingDataSetCollectionNameSearchTextFieldState, e.target.value.substring(0, e.target.value.indexOf(','))])
                                }
                            }}
                            onBlur={(e) => {
                                if (e.target.value && e.target.value.replaceAll(',', '').length) {
                                    const val = e.target.value.replaceAll(',','');
                                    setTrainingDataSetCollectionNameSearchTextFieldState([...trainingDataSetCollectionNameSearchTextFieldState, val])
                                }
                            }}
                        />
                    )}
                />
                
            </div>



            <div id="resetFilterButtonContainer"
                style={{marginTop: '40px', display: 'flex', justifyContent: 'space-between'}}
            >
                <div></div>
                <Button variant="contained" color="primary"
                    onClick={()=>{
                            console.log(" ::::::::::::::: :: ", trainingDataSetCollectionNameSearchTextFieldState)
                        props.setTrainingDataSetCollectionNameFilterSearchValue(trainingDataSetCollectionNameSearchTextFieldState)
                        .then(()=>{
                            props.onTrainingDataSetCollectionNameApplyFilterButtonClick(trainingDataSetCollectionNameSearchTextFieldState);
                        })
                    }}
                    disabled={(trainingDataSetCollectionNameSearchTextFieldState ===  props.trainingDataSetCollectionNameSearchValue)}
                >
                    Apply Filters
                </Button>
            </div>            
          </Box>
        )}
      </Popover>
    );
}

export function ArchitectureColumnMenu(props: { 
    open: boolean,
    architectureSearchValue: string[],
    onClose: ((event: {}, reason: "backdropClick" | "escapeKeyDown") => void) | undefined
    resetArchitectureFilters: () => void
    isArchitectureFilterApplied: boolean
    classes: { tableColumnMenuHeading: CSSProperties; floating: CSSProperties; chip: CSSProperties; autoCompleteChipRoot: CSSProperties }
    onArchitectureApplyFilterButtonClick: (value: string[]) => void
    setArchitectureFilterSearchValue: (value: string[]) => Promise<void>
    uniqueArchitecturesAvailable : string[]
}) {
    // const classes : useStyles();
    const [architectureSearchTextFieldState, setArchitectureSearchTextFieldState] = useState<string[]>(props.architectureSearchValue);

    const [ uniqueArchitectures, ] = useState<string[]>(props.uniqueArchitecturesAvailable)
    // const [ uniquePredictions, setUniquePredictions] = useState<string[]>([])

    // const query = new URLSearchParams(window.location.search);
    // const modelId = query.get("model") || "";

    return (
      <Popover
        id="architecture-column-menu"
        anchorEl={document.getElementById("architectureColumnOptions")}
        keepMounted
        anchorOrigin={{
            vertical: 'bottom',
            horizontal: 'right',
        }}
        transformOrigin={{
            vertical: 'top',
            horizontal: 'right',
        }}        
        open={props.open}
        onClose={props.onClose}
        PaperProps={{
          style: {
            width: "450px",
          },
        }}
      >
        {props.open && (
          <Box id="architectureMenuItemContainer" style={{ padding: 15 }}>
              <div className={`${props.classes.tableColumnMenuHeading}`}>
                    <Typography
                    variant="body1"
                    style={{ marginBottom: 20 }}
                    color="primary"
                    >
                    &#8288;Architecture Column Filter
                    </Typography>
                    <Tooltip title="Reset Filters">
                        <Box>
                            <TableColumnResetFilterButton 
                                onClick={()=>props.resetArchitectureFilters()}
                                disabled={!props.isArchitectureFilterApplied}
                            />
                        </Box>
                    </Tooltip>
              </div>              
            <div style={{display: 'flex', justifyContent: 'space-between'}}>
                <Autocomplete
                    multiple
                    id="architecture-search-filter-value"
                    freeSolo
                    style={{width: '95%'}}
                    options={uniqueArchitectures}
                    value={architectureSearchTextFieldState.filter(value => value !== "")}
                    onChange={(event, value) => {
                        setArchitectureSearchTextFieldState([...value]);
                    }}
                    renderTags={(value, getTagProps) => {
                        return value.map((option, index) => (
                            <Chip
                                variant="outlined"
                                color="primary"
                                className={`${props.classes.chip}`}
                                classes={{ root: `${props.classes.autoCompleteChipRoot}` }}
                                label={
                                    <Tooltip title={option}>
                                        <span>
                                            {option}
                                        </span>
                                    </Tooltip>
                                }
                                {...getTagProps({ index })}
                            />
                        ));
                    }}
                    renderInput={(params) => (
                        <TextField
                            className={`${props.classes.floating}`}
                            {...params}
                            variant="outlined"
                            size="small"
                            fullWidth
                            label="Architecture Filter Value"
                            placeholder="Add Architecture Filter"
                            onChange={(e) => {
                                if (e.target.value.includes(',') && e.target.value.substring(0, e.target.value.indexOf(',')).length > 1) {
                                    setArchitectureSearchTextFieldState([...architectureSearchTextFieldState, e.target.value.substring(0, e.target.value.indexOf(','))])
                                }
                            }}
                            onBlur={(e) => {
                                if (e.target.value && e.target.value.replaceAll(',', '').length) {
                                    const val = e.target.value.replaceAll(',','');
                                    setArchitectureSearchTextFieldState([...architectureSearchTextFieldState, val])
                                }
                            }}
                        />
                    )}
                />
                
            </div>



            <div id="resetFilterButtonContainer"
                style={{marginTop: '40px', display: 'flex', justifyContent: 'space-between'}}
            >
                <div></div>
                <Button variant="contained" color="primary"
                    onClick={()=>{
                            console.log(" ::::::::::::::: :: ", architectureSearchTextFieldState)
                        props.setArchitectureFilterSearchValue(architectureSearchTextFieldState)
                        .then(()=>{
                            props.onArchitectureApplyFilterButtonClick(architectureSearchTextFieldState);
                        })
                    }}
                    disabled={(architectureSearchTextFieldState ===  props.architectureSearchValue)}
                >
                    Apply Filters
                </Button>
            </div>            
          </Box>
        )}
      </Popover>
    );
}

export function AccuracyColumnMenu(props: { 
    open: boolean,
    minValue: number,
    maxValue: number,
    onClose: ((event: {}, reason: "backdropClick" | "escapeKeyDown") => void) | undefined
    resetFilters: () => void
    isFilterApplied: boolean
    onApplyFilterButtonClick: (filter: any, value : string[]) => void
    // setFilterMinMaxValue: (value: [number, number]) => Promise<void>
    classes: { tableColumnMenuHeading: CSSProperties; floating: CSSProperties; chip: CSSProperties; autoCompleteChipRoot: CSSProperties}
    accuracyStatusSearchValue : string[],
    uniqueAccuracyStatusesAvailable: string[]
}) {

    const [sliderFieldState, setSliderFieldState] = useState<InputFieldState<[number, number]>>({value: [props.minValue, props.maxValue]});
    const [accuracyStatusFieldState, setAccuracyStatusFieldState] = useState<string[]>(props.accuracyStatusSearchValue);
    const uniqueAccuracy = useMemo(() => {
        const uniqueAccuracy = props.uniqueAccuracyStatusesAvailable.map((status: string) => {
            return {status:  status, value : AccuracyStatuses[status]}
        });
        return uniqueAccuracy;
    }, [props.uniqueAccuracyStatusesAvailable]);

    console.log("uniqueAccuracy :::", uniqueAccuracy);
    

    // const uniqueAccuracy = [
    //     {
    //     status : "preparingRequest",
    //     value : "Preparing Request"
    //     },
    //     {
    //     status : "queued",
    //     value : "Re-Testing"
    //     },
    //     {
    //     status : "failed",
    //     value : "Failed"
    //     },
    // ]

    return (
      <Popover
        id="accuracy-column-menu"
        anchorEl={document.getElementById("accuracyColumnOptions")}
        keepMounted
        anchorOrigin={{
            vertical: 'bottom',
            horizontal: 'right',
        }}
        transformOrigin={{
            vertical: 'top',
            horizontal: 'right',
        }}        
        open={props.open}
        onClose={props.onClose}
        PaperProps={{
          style: {
            width: "450px",
          },
        }}
      >
        {props.open && (
          <Box id="accuracyMenuItemContainer" style={{ padding: 15 }}>
              <div className={`${props.classes.tableColumnMenuHeading}`}>
                    <Typography
                    variant="body1"
                    style={{ marginBottom: 20 }}
                    color="primary"
                    >
                    &#8288;Accuracy Column Filter
                    </Typography>
                    <Tooltip title="Reset Filters">
                        <Box>
                            <TableColumnResetFilterButton 
                                onClick={()=>props.resetFilters()}
                                disabled={!props.isFilterApplied}
                            />
                        </Box>
                    </Tooltip>
              </div>
              <div id="rangeSection">
                <div>Accuracy value range</div>
                <div
                    id="rangeInputFieldWrapper"
                    style={{
                    width: "calc(95% - 15px)",
                    marginLeft: "15px",
                    }}
                >
                    <Slider
                        value={sliderFieldState.value}
                        min={0}
                        max={100}
                        color="primary"
                        step={1}
                        marks={[
                            { value: 0, label: "0%" },
                            { value: 50, label: "50%" },
                            { value: 100, label: "100%" },
                        ]}

                        // @ts-ignore
                        onChange={(e, value: [number, number]) => {
                            setSliderFieldState(oldState=>{return {
                                ...oldState, isTouched: true, isDirty: true,
                                value: [value[0], value[1]]
                            }})
                        }}

                        valueLabelDisplay="auto"
                        aria-labelledby="range-slider"
                    />
                </div>

                <div style={{paddingTop : "30px"}}>
                <Autocomplete
                    multiple
                    id="accuracy-search-filter-value"
                    freeSolo
                    style={{width: '95%'}}
                    options={uniqueAccuracy.map(accuracy => accuracy.status)}
                    value={accuracyStatusFieldState.filter(value => value !== "")}
                    getOptionLabel={(accuracyStatus) => {
                        const accuracy = uniqueAccuracy.filter(
                            (value) => value.status.toString() === accuracyStatus.toString()
                        ).map(uniqueAccuracyStatus => uniqueAccuracyStatus.value)[0];
                        if (accuracy) {
                            return accuracy;
                        } else {
                            return accuracyStatus;
                        }
                    } }
                    onChange={(event, value) => {
                        setAccuracyStatusFieldState([...value]);
                    }}

                    renderTags={(value, getTagProps) => {
                        const accuracy = uniqueAccuracy.filter(
                            (uniqueAccuracy) => value.includes(uniqueAccuracy.status)
                        ).map(uniqueAccuracyStatus => uniqueAccuracyStatus.value) || value;
                        return accuracy.map((option, index) => (
                            
                            <Chip
                                variant="outlined"
                                color="primary"
                                className={`${props.classes.chip}`}
                                classes={{ root: `${props.classes.autoCompleteChipRoot}` }}
                                label={
                                    
                                    <Tooltip title={option}>
                                        <span>
                                            {option}
                                        </span>
                                    </Tooltip>
                                }
                                {...getTagProps({ index })}
                            />
                        ));
                    }}
                    renderInput={(params) => (
                        <TextField
                            className={`${props.classes.floating}`}
                            {...params}
                            variant="outlined"
                            size="small"
                            fullWidth
                            label="Accuracy Status Filter Value"
                            placeholder="Add Accuracy Status Filter"
                            onChange={(e) => {
                                if (e.target.value.includes(',') && e.target.value.substring(0, e.target.value.indexOf(',')).length > 1) {
                                    setAccuracyStatusFieldState([...accuracyStatusFieldState, e.target.value.substring(0, e.target.value.indexOf(','))])
                                }
                            }}
                            onBlur={(e) => {
                                if (e.target.value && e.target.value.replaceAll(',', '').length) {
                                    const val = e.target.value.replaceAll(',','');
                                    setAccuracyStatusFieldState([...accuracyStatusFieldState, val])
                                }
                            }}
                        />
                    )}
                />
                </div>
                </div>
                      
            <div id="resetFilterButtonContainer"
                style={{marginTop: '40px', display: 'flex', justifyContent: 'space-between'}}
            >
                <div></div>
                <Button variant="contained" color="primary"
                    onClick={()=>{
                        // props.setFilterMinMaxValue(sliderFieldState.value)
                        // .then(()=>{
                            if(!sliderFieldState.isDirty && (sliderFieldState.value[0] === 0 && sliderFieldState.value[1] === 0)){
                                props.onApplyFilterButtonClick([] , accuracyStatusFieldState);
                            }else{
                                props.onApplyFilterButtonClick(sliderFieldState.value , accuracyStatusFieldState);
                            }
                        // })
                    }}
                    disabled={ (accuracyStatusFieldState ===  props.accuracyStatusSearchValue) && !sliderFieldState.isDirty }
                >
                    Apply Filters
                </Button>
            </div>            
          </Box>
        )}
      </Popover>
    );
}

export function DataSetCollectionNameColumnMenu(props: { 
    open: boolean,
    dataSetCollectionNameSearchValue: string[],
    onClose: ((event: {}, reason: "backdropClick" | "escapeKeyDown") => void) | undefined
    resetDataSetCollectionNameFilters: () => void
    isDataSetCollectionNameFilterApplied: boolean
    classes: { tableColumnMenuHeading: CSSProperties; floating: CSSProperties; chip: CSSProperties; autoCompleteChipRoot: CSSProperties }
    onDataSetCollectionNameApplyFilterButtonClick: (value: string[]) => void
    setDataSetCollectionNameFilterSearchValue: (value: string[]) => Promise<void>
    uniqueDataSetCollectionNamesAvailable : IUniqueTestCollectionDataSet[]
}) {
    // const classes : useStyles();
    const [dataSetCollectionNameSearchTextFieldState, setDataSetCollectionNameSearchTextFieldState] = useState<string[]>(props.dataSetCollectionNameSearchValue);

    const [ uniqueDataSetCollectionNames, ] = useState<IUniqueTestCollectionDataSet[]>(props.uniqueDataSetCollectionNamesAvailable)
    // const [ uniquePredictions, setUniquePredictions] = useState<string[]>([])

    // const query = new URLSearchParams(window.location.search);
    // const modelId = query.get("model") || "";

    return (
      <Popover
        id="dataSetCollectionName-column-menu"
        anchorEl={document.getElementById("dataSetCollectionNameColumnOptions")}
        keepMounted
        anchorOrigin={{
            vertical: 'bottom',
            horizontal: 'right',
        }}
        transformOrigin={{
            vertical: 'top',
            horizontal: 'right',
        }}        
        open={props.open}
        onClose={props.onClose}
        PaperProps={{
          style: {
            width: "450px",
          },
        }}
      >
        {props.open && (
          <Box id="dataSetCollectionNamesMenuItemContainer" style={{ padding: 15 }}>
              <div className={`${props.classes.tableColumnMenuHeading}`}>
                    <Typography
                    variant="body1"
                    style={{ marginBottom: 20 }}
                    color="primary"
                    >
                    &#8288;Data Set Collection Filter
                    </Typography>
                    <Tooltip title="Reset Filters">
                        <Box>
                            <TableColumnResetFilterButton 
                                onClick={()=>props.resetDataSetCollectionNameFilters()}
                                disabled={!props.isDataSetCollectionNameFilterApplied}
                            />
                        </Box>
                    </Tooltip>
              </div>              
            <div style={{display: 'flex', justifyContent: 'space-between'}}>
                <Autocomplete
                    multiple
                    id="data-sets-search-filter-value"
                    freeSolo
                    style={{width: '95%'}}
                    options={uniqueDataSetCollectionNames.map(uniqueNames => uniqueNames._id)}
                    value={dataSetCollectionNameSearchTextFieldState.filter(value => value !== "")}
                    onChange={(event, value) => {
                        setDataSetCollectionNameSearchTextFieldState([...value]);
                    }}
                    getOptionLabel={(dataSetCollectionName) => {
                        const dataSetName = props.uniqueDataSetCollectionNamesAvailable.filter(
                            (option) => option._id === dataSetCollectionName.toString()
                        ).map(uniqueDataSetName => uniqueDataSetName.name)[0];
                        if (dataSetName) {
                            return dataSetName.toString();
                        } else {
                            return dataSetCollectionName;
                        }
                    }}
                    renderTags={(value, getTagProps) => {
                        const dataSet = uniqueDataSetCollectionNames.filter(
                            (uniqueDataSet) => value.includes(uniqueDataSet._id)
                        ).map(uniqueDataSetName => uniqueDataSetName.name) || value;
                        return dataSet.map((option, index) => (
                            <Chip
                                variant="outlined"
                                color="primary"
                                className={`${props.classes.chip}`}
                                classes={{ root: `${props.classes.autoCompleteChipRoot}` }}
                                label={
                                    <Tooltip title={option}>
                                        <span>
                                            {option}
                                        </span>
                                    </Tooltip>
                                }
                                {...getTagProps({ index })}
                            />
                        ));
                    }}
                    renderInput={(params) => (
                        <TextField
                            className={`${props.classes.floating}`}
                            {...params}
                            variant="outlined"
                            size="small"
                            fullWidth
                            label="Data Sets Filter Value"
                            placeholder="Add Data Sets Filter"
                            onChange={(e) => {
                                if (e.target.value.includes(',') && e.target.value.substring(0, e.target.value.indexOf(',')).length > 1) {
                                    setDataSetCollectionNameSearchTextFieldState([...dataSetCollectionNameSearchTextFieldState, e.target.value.substring(0, e.target.value.indexOf(','))])
                                }
                            }}
                            onBlur={(e) => {
                                if (e.target.value && e.target.value.replaceAll(',', '').length) {
                                    const val = e.target.value.replaceAll(',','');
                                    setDataSetCollectionNameSearchTextFieldState([...dataSetCollectionNameSearchTextFieldState, val])
                                }
                            }}
                        />
                    )}
                />
                
            </div>



            <div id="resetFilterButtonContainer"
                style={{marginTop: '40px', display: 'flex', justifyContent: 'space-between'}}
            >
                <div></div>
                <Button variant="contained" color="primary"
                    onClick={()=>{
                            console.log(" ::::::::::::::: :: ", dataSetCollectionNameSearchTextFieldState)
                        props.setDataSetCollectionNameFilterSearchValue(dataSetCollectionNameSearchTextFieldState)
                        .then(()=>{
                            props.onDataSetCollectionNameApplyFilterButtonClick(dataSetCollectionNameSearchTextFieldState);
                        })
                    }}
                    disabled={(dataSetCollectionNameSearchTextFieldState ===  props.dataSetCollectionNameSearchValue)}
                >
                    Apply Filters
                </Button>
            </div>            
          </Box>
        )}
      </Popover>
    );
}

export function ModelCollectionNameColumnMenu(props: { 
    open: boolean,
    modelCollectionNameSearchValue: string[],
    onClose: ((event: {}, reason: "backdropClick" | "escapeKeyDown") => void) | undefined
    resetModelCollectionNameFilters: () => void
    isModelCollectionNameFilterApplied: boolean
    classes: { tableColumnMenuHeading: CSSProperties; floating: CSSProperties; chip: CSSProperties; autoCompleteChipRoot: CSSProperties }
    onModelCollectionNameApplyFilterButtonClick: (value: string[]) => void
    setModelCollectionNameFilterSearchValue: (value: string[]) => Promise<void>
    uniqueModelCollectionNamesAvailable : IUniqueTestCollectionModel[]
}) {
    // const classes : useStyles();
    const [modelCollectionNameSearchTextFieldState, setModelCollectionNameSearchTextFieldState] = useState<string[]>(props.modelCollectionNameSearchValue);

    const [ uniqueModelCollectionNames, ] = useState<IUniqueTestCollectionModel[]>(props.uniqueModelCollectionNamesAvailable)
    // const [ uniquePredictions, setUniquePredictions] = useState<string[]>([])

    // const query = new URLSearchParams(window.location.search);
    // const modelId = query.get("model") || "";

    return (
      <Popover
        id="modelCollectionName-column-menu"
        anchorEl={document.getElementById("modelCollectionNameColumnOptions")}
        keepMounted
        anchorOrigin={{
            vertical: 'bottom',
            horizontal: 'right',
        }}
        transformOrigin={{
            vertical: 'top',
            horizontal: 'right',
        }}        
        open={props.open}
        onClose={props.onClose}
        PaperProps={{
          style: {
            width: "450px",
          },
        }}
      >
        {props.open && (
          <Box id="modelCollectionNamesMenuItemContainer" style={{ padding: 15 }}>
              <div className={`${props.classes.tableColumnMenuHeading}`}>
                    <Typography
                    variant="body1"
                    style={{ marginBottom: 20 }}
                    color="primary"
                    >
                    &#8288;Model Collection Filter
                    </Typography>
                    <Tooltip title="Reset Filters">
                        <Box>
                            <TableColumnResetFilterButton 
                                onClick={()=>props.resetModelCollectionNameFilters()}
                                disabled={!props.isModelCollectionNameFilterApplied}
                            />
                        </Box>
                    </Tooltip>
              </div>              
            <div style={{display: 'flex', justifyContent: 'space-between'}}>
                <Autocomplete
                    multiple
                    id="model-collection-search-filter-value"
                    freeSolo
                    style={{width: '95%'}}
                    options={uniqueModelCollectionNames.map(uniqueNames => uniqueNames._id)}
                    value={modelCollectionNameSearchTextFieldState.filter(value => value !== "")}
                    onChange={(event, value) => {
                        setModelCollectionNameSearchTextFieldState([...value]);
                    }}
                    getOptionLabel={(ModelCollectionName) => {
                        const ModelVersion = props.uniqueModelCollectionNamesAvailable.filter(
                            (option) => option._id === ModelCollectionName.toString()
                        ).map(uniqueModelVersion => uniqueModelVersion.version)[0];
                        if (ModelVersion) {
                            return ModelVersion.toString();
                        } else {
                            return ModelCollectionName;
                        }
                    }}
                    renderTags={(value, getTagProps) => {
                        const model = uniqueModelCollectionNames.filter(
                            (uniqueModel) => value.includes(uniqueModel._id)
                        ).map(uniqueModelName => uniqueModelName.version) || value;
                        return model.map((option, index) => (
                            <Chip
                                variant="outlined"
                                color="primary"
                                className={`${props.classes.chip}`}
                                classes={{ root: `${props.classes.autoCompleteChipRoot}` }}
                                label={
                                    <Tooltip title={option}>
                                        <span>
                                            {option}
                                        </span>
                                    </Tooltip>
                                }
                                {...getTagProps({ index })}
                            />
                        ));
                    }}
                    renderInput={(params) => (
                        <TextField
                            className={`${props.classes.floating}`}
                            {...params}
                            variant="outlined"
                            size="small"
                            fullWidth
                            label="Models Filter Value"
                            placeholder="Add Models Filter"
                            onChange={(e) => {
                                if (e.target.value.includes(',') && e.target.value.substring(0, e.target.value.indexOf(',')).length > 1) {
                                    setModelCollectionNameSearchTextFieldState([...modelCollectionNameSearchTextFieldState, e.target.value.substring(0, e.target.value.indexOf(','))])
                                }
                            }}
                            onBlur={(e) => {
                                if (e.target.value && e.target.value.replaceAll(',', '').length) {
                                    const val = e.target.value.replaceAll(',','');
                                    setModelCollectionNameSearchTextFieldState([...modelCollectionNameSearchTextFieldState, val])
                                }
                            }}
                        />
                    )}
                />
                
            </div>



            <div id="resetFilterButtonContainer"
                style={{marginTop: '40px', display: 'flex', justifyContent: 'space-between'}}
            >
                <div></div>
                <Button variant="contained" color="primary"
                    onClick={()=>{
                            console.log(" ::::::::::::::: :: ", modelCollectionNameSearchTextFieldState)
                        props.setModelCollectionNameFilterSearchValue(modelCollectionNameSearchTextFieldState)
                        .then(()=>{
                            props.onModelCollectionNameApplyFilterButtonClick(modelCollectionNameSearchTextFieldState);
                        })
                    }}
                    disabled={(modelCollectionNameSearchTextFieldState ===  props.modelCollectionNameSearchValue)}
                >
                    Apply Filters
                </Button>
            </div>            
          </Box>
        )}
      </Popover>
    );
}

export default ModelCollectionTableFilterComponent
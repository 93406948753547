import React, { useContext, useEffect, useState } from 'react';
import { connect, ConnectedProps } from 'react-redux';
import { useHistory, useLocation } from 'react-router-dom';
import { Box, CircularProgress, Grid, makeStyles } from '@material-ui/core';

import { IProject, IReduxState, LastSearched, LastSearchedNormalized, loaders } from '../../../../../common';

import ProjectCard from './ProjectCard';
import { getProjects, unsubscribeToProjectChanges } from '../../../../../store/actions/projectActions';
import { addBreadcrumbs, setBreadcrumbsTillHome, setSideDrawer, setTopActionButton } from '../../../../../store/actions';
import Analytics from './Analytics';
import AddProject from './AddProject';
import { QueryParams, QueryParamsValue } from '../features/models/components/projectModels/projectModels';
import UserDBService from '../../../../../services/UserDBService';
import { SearchData, SearchDataContext } from '../../../../../common/contexts/searchContext/SearchDataContext';

interface IProps extends TPropsFromRedux {
    scenario?: "browseCollectionsPopup";
    onProjectClick?: (data: IProject) => void;
    getProjectLink?: (data: IProject) => void;
}

const useStyles = makeStyles((theme) => ({
    loader: {
        ...loaders.centralLoader
    }
}));

function ProjectsList(props: IProps) {

    const { projects, isFetching, getProjects, setTopActionButton, setSideDrawer, addBreadcrumbs } = props;
    const classes = useStyles();
    const history = useHistory();
    const location = useLocation();
    const {searchData,setSearchData} = useContext(SearchDataContext);
    const [searchResults,setSearchResults] = useState<IProject[]>([]);
    const [lastSearchedProjects,setLastSearchedProjects] = useState<LastSearchedNormalized[]>([]);
    
    useEffect(() => {
        setSideDrawer({ type: 'projectAnalytics', component: Analytics, isOpen: false });
        setTopActionButton({ type: 'addProject', onClickComponentToRender: AddProject });
        if(props.scenario !== "browseCollectionsPopup") 
            props.setBreadcrumbsTillHome();
        
    }, []);
    useEffect(()=>{
        setSearchData({
            autoCompleteData:[],
            searchQuery:'',
            autoCompleteValueChange:''
        })
        UserDBService.getLastSearchedProjects()
        .then((res)=>{
            const lastSearchedProjects = res.lastSearchedProjects as LastSearched[];
            // the structure is id:{_id,name} and need to convert to {_id,name}
            const normalized = lastSearchedProjects.map(item=>item.id);
            setLastSearchedProjects(normalized)
            setSearchData((prevData: SearchData)=>{
                return {...prevData,autoCompleteData:normalized}
            })
        })
        .catch(err=>console.log(err))
        
    },[])
    useEffect(() => {
        const query = new URLSearchParams(location?.search);
        const limit = query?.get('limit') || '1000';
        const offset = query?.get('offset') || '1';
        // const project = query?.get('project') || '';
        (async () => {
            await getProjects({ offset, limit });
            // await listenToGetProjects();
        })();
    }, [location]);
    useEffect(()=>{
        if(searchData.searchQuery){
            const reg = new RegExp(searchData.searchQuery,"i")
            if(projects.length > 0){
                const result = projects.filter(project=>{
                    const shouldBeInResult = reg.test(project.name)
                    return shouldBeInResult 
                })
                setSearchResults(result)
                setSearchData((prevData: SearchData)=>{
                    return {...prevData,autoCompleteData:result.map(item=>{
                        return {_id:item._id,name:item.name}
                    })}
                })
            }
        }
        else{
            setSearchData((prevData: SearchData)=>{
                return {...prevData,autoCompleteData:lastSearchedProjects}
            })
        }
    },[searchData.searchQuery,projects])
    // useEffect(()=>{
    //     if(searchData.autoCompleteValueChange){
    //         const index = projects.findIndex(project=>project._id === searchData.autoCompleteValueChange)
    //         if(index !== -1)
    //             onClick(projects[index])
    //     }
    // },[searchData.autoCompleteValueChange])
    const onClick = (data: IProject) => {
        
        UserDBService.updateLastSearchedProject(data._id);
        const userModelViewPreferences = localStorage.getItem("viewPreferences") as QueryParamsValue["view"] ?? "model";
        const projectModelsViewQueryParamValue: QueryParamsValue["view"] = userModelViewPreferences;

        const queryParamString: string = `?offset=1&limit=1000&project=${data._id}&${QueryParams.view}=${projectModelsViewQueryParamValue}`;
        const newPath = `${location.pathname}/${data.name}${queryParamString}`;
        addBreadcrumbs([{ label: data.name, path: newPath }]);
        history.push(`${newPath}`);
    };
    const getProjectLink=(data: IProject):string=>{
        const userModelViewPreferences = localStorage.getItem("viewPreferences") as QueryParamsValue["view"] ?? "model";
        const projectModelsViewQueryParamValue: QueryParamsValue["view"] = userModelViewPreferences;

        const queryParamString: string = `?offset=1&limit=1000&project=${data._id}&${QueryParams.view}=${projectModelsViewQueryParamValue}`;
        const newPath = `${location.pathname}/${data.name}${queryParamString}`;
        return newPath
    }
    
    return (
        <Grid container spacing={3}>
            {
                isFetching ?
                    <Box className={classes.loader}>
                        <CircularProgress size={30} />
                    </Box> :
                    (searchData.searchQuery?searchResults : projects)?.map((project, index) => <Grid key={project._id || index} xs={12} md={3} lg={3} item>
                        <ProjectCard project={project} getProjectLink={getProjectLink} onClick={props.scenario === "browseCollectionsPopup" ? (props.onProjectClick ? props.onProjectClick : onClick) : onClick} />
                    </Grid>)
            }
        </Grid>
    );
}

const mapProps = (state: IReduxState) => {
    return {
        isUpdating: state.collection.isUpdating,
        projects: state.project.projects,
        total: state.project.total,
        isFetching: state.project.isFetching
    };
}

const connector = connect(mapProps, {
    getProjects,
    setTopActionButton,
    setSideDrawer,
    addBreadcrumbs,
    unsubscribeToProjectChanges,
    setBreadcrumbsTillHome
});

export type TPropsFromRedux = ConnectedProps<typeof connector>

export default connector(ProjectsList);

import { ILatestModel } from "../../projects/features/aiMarketPlace/aiMarketPlaceInterfaces/interfaces";

export const modelRecommendationPayload = (userFeedback: string, trainingPodIdsToExclude: string[], testDriveDataDescription: string) => {
    const payload = {
        "model_details": {
            // "model_architecture_description": trainingPodDetails.purposeModel?.modelArchitectureDescription || '',
            // "trainingPodId": trainingPodDetails?.purposeModel?._id,
            // "modelPurpose": trainingPodDetails?.purposeModel?.purposeType,
            // "trainingPodName": trainingPodDetails?.purposeModel?.modelName,
            // "description": trainingPodDetails?.purposeModel?.modelDescription,
            //  "performance_metrics" : {
            //     //@ts-ignore
            //     "accuracy" : trainingPodDetails?.checkpointFiles?.files?.analysis?.cachedMetrics?.accuracy?.accuracy || '',
            //     //@ts-ignore
            //     "integrity" : trainingPodDetails?.checkpointFiles?.files?.analysis?.cachedMetrics?.integrity?.integrity || '',
            //     // "inference_time": x,
            //   }
        },
        // "general_dataset_description": "General dataset description (for general purpose training pod)",
        "dataset_description": testDriveDataDescription,
        "user_feedback": userFeedback,
        "exclude_model_ids": trainingPodIdsToExclude
    }

    return payload;

}
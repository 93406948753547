import {
  Box,
  makeStyles,
  TextField,
  Theme,
  Tooltip,
} from "@material-ui/core";
import { Autocomplete } from "@material-ui/lab";
import { useEffect, useState } from "react";
// import { EditText, EditTextProps } from "react-edit-text";
import { connect, ConnectedProps, useSelector } from "react-redux";
import { IData, IReduxState } from "../../../../../../../../../../../common";
import { removeDuplicateElementsFromArray } from '../../../../../../../../../../../services/arrayHelperService';
import { ignoreRegexSpecialCharactersInText } from "../../../../../../../../../../../services/regexHelperService";
import {
  updateData,
  setResources,
} from "../../../../../../../../../../../store/actions/dataActions";
import { ModelResourcePredictedText } from "../constants/constants";

const useStyles = makeStyles((theme: Theme) => ({
  labelEditText: {
    outlineColor: theme.palette.primary.light,
    width: "100%",
    borderWidth: 0,
    borderRadius: 20,
    backgroundColor: "#f3f4f6 !important",
    textAlign: "center",
    height: 28,
    justifyContent: "center",
    alignItems: "center",
    lineHeight: "17px",
  },
  labelEditTextWhenPredictionIncorrect: {
    backgroundColor: "#EF8A8A !important",
  },
  endAdornment: {
    display: "none",
  },
  disabled: {
    color: "#000000de !important",
  },
  textAlignCenter: {
    textAlign: "center",
  },
  floating: {
    "& legend": {
      float: "unset"
    }
  }
}));

interface Props extends TPropsFromRedux {
  resource: IData;
  readonly: boolean;
  editTextComponentAdditionalCSSClassNames?: string;
  onEditTextFocus: Function;
  onEditTextBlurEvent: Function;
  placeWhereComponentBeingUsed: "data-table" | "data-side-drawer" | "reference-resource-analytics";
  labelsAvailable: {label: string; group: string}[];
  allowUserToSetAnyLabel?: boolean;
  scenario?: "modelAnalyticsSegmentationAnalyticsEnabled" | "labelAndErrorDeviation" | "correctPrediction" | "incorrectPrediction"
  maximumMisPredictionDeviationAllowedInNumber?: number
}

function LabelEditText(props: Props) {
  const classes = useStyles();
  const resources = useSelector(
    (state: IReduxState) => state.data.data.resources
  );
  const { resource, labelsAvailable, allowUserToSetAnyLabel } = props;

  const [value, setValue] = useState<{ label: string, group: string }>({label: props.resource.label, group: ''});

  const [scenario, setScenario] = useState<
    "updatingLabel" | "updatingLabelAndPrediction" 
  >("updatingLabel");
  useEffect(() => {
    if (props.scenario === "modelAnalyticsSegmentationAnalyticsEnabled") {
      setScenario("updatingLabelAndPrediction");
      return;
    }
    if (props.scenario === "incorrectPrediction") {
      setScenario("updatingLabel");
      return
    }
    if (props.scenario === "correctPrediction") {
      setScenario("updatingLabelAndPrediction");
      return
    }
    if (resource.prediction === "predicted") {
      setScenario("updatingLabelAndPrediction");
    } else {
      setScenario("updatingLabel");
    }
  }, [props.scenario, resource.label, resource.prediction]);

  useEffect(() => {
    if (scenario === "updatingLabelAndPrediction") {
      setValue({label: resource.label, group: ''});
    } else if (scenario === "updatingLabel") {
      setValue({label: `${resource.prediction}->${resource.label}`, group: ''});
    }
  }, [resource.label, resource.prediction, scenario]);


  useEffect(() => {
    if (props.scenario === "labelAndErrorDeviation" && resource?.prediction!=="predicted") {
      if (Math.abs(Number(resource?.label) - Number(resource?.prediction)) <= Number(props?.maximumMisPredictionDeviationAllowedInNumber || 0)) {
        setValue({label: resource.label, group: ''});
        setScenario("updatingLabelAndPrediction")
        // console.log("testing deviation true", resource.label, resource.prediction, Number(resource?.label) - Number(resource?.prediction))
      }
      else {
        setValue({label: `${resource.prediction}->${resource.label}`, group: ''});
        setScenario("updatingLabel")
        // console.log("testing deviation false", resource.label, resource.prediction)
      }
    }
  }, [resource.label, resource.prediction, scenario])

  function handleLabelSave(labelValueToSave: string) {
    let thingsToUpdate: { label: string; prediction?: string } = {
      label: labelValueToSave,
      prediction:
        scenario === "updatingLabelAndPrediction" ? resource.label : "",
    };

    if (labelValueToSave === props.resource.prediction) {
      /**
       * if user updated label is equal to what was already predicted.
       * then setting prediction as predicted
       */
      thingsToUpdate.prediction = ModelResourcePredictedText;
    }

    if (!thingsToUpdate.prediction) {
      delete thingsToUpdate.prediction;
    }

    props
      .updateData(
        { id: [props.resource._id], ...thingsToUpdate },
        {},
        { shouldFetchLatestData: false }
      )
      .then(() => {
        for (
          let resourceIndex = 0;
          resourceIndex < resources.length;
          resourceIndex++
        ) {
          const resourceObject = resources[resourceIndex];
          if (resourceObject._id === props.resource._id) {
            if (scenario === "updatingLabelAndPrediction") {
              resourceObject.prediction = resource.label;
            }
            resourceObject.label = labelValueToSave;

            if (thingsToUpdate.prediction) {
              resourceObject.prediction = thingsToUpdate.prediction;
            }

            resources[resourceIndex] = resourceObject;
            break;
          }
        }
        props.setResources(resources);
      });
  }

  return (
    <Tooltip title={value.label || ""}>
      <Box style={{ padding: 1 }}>
        <Autocomplete
          options={allowUserToSetAnyLabel ? [] : labelsAvailable}
          freeSolo={allowUserToSetAnyLabel ? true : false}
          groupBy={(option) => option.group}
          classes={{
            endAdornment: classes.endAdornment,
          }}
          onFocus={() => {
            props.onEditTextFocus();
            setValue({
              label: '',
              group: ''
            });
          }}
          onBlur={() => {
            props.onEditTextBlurEvent();
            if (scenario === "updatingLabel") {
              setValue({label: resource.prediction + "->" + resource.label, group: ''});
            } else if (scenario === "updatingLabelAndPrediction") {
              setValue({label: resource.label, group: ''});
            }
          }}
          filterOptions={(options, state)=>{
            let inputValue = state.inputValue;

            if (!inputValue) {
              return options;
            }

            inputValue = ignoreRegexSpecialCharactersInText(inputValue);

            const filteredOptionsByBeginningLetter = options.filter((option)=>{
              return new RegExp(`^${inputValue}`, 'i').test(option.label)
            })
            const filteredOptionsByLetterContains = options.filter((option)=>{
              return new RegExp(`${inputValue}`, 'i').test(option.label)
            })
            return [
              ...removeDuplicateElementsFromArray(
                [
                  ...filteredOptionsByBeginningLetter,
                  ...filteredOptionsByLetterContains
                ]
              )
            ]
          }}
          disabled={props.readonly}
          className={`
        ${
          props.placeWhereComponentBeingUsed === "data-table"
            ? classes.labelEditText
            : ""
        }
        ${
          scenario === "updatingLabel" || props.scenario === "incorrectPrediction"
            ? classes.labelEditTextWhenPredictionIncorrect
            : ""
        }
        ${
          props.editTextComponentAdditionalCSSClassNames
            ? props.editTextComponentAdditionalCSSClassNames
            : ""
        }
      `}
          style={{
            fontSize: "14px",
            height: "39px",
            textAlign: "center",
          }}
          onChange={(event, value) => {
            if (
              //@ts-ignore
              value && value.label !== "" && value.label !== null && value.label !== undefined && resource.label !== value.label
            ) {
              //@ts-ignore
              setValue(value);
              //@ts-ignore
              handleLabelSave(value.label);
            }
            else if (value){
              //@ts-ignore
              setValue({group: '', label: value})
              //@ts-ignore
              handleLabelSave(value);
            }
          }}
          autoHighlight
          blurOnSelect
          getOptionLabel={(option) => option.label}
          value={ value }
          renderOption={(option) => (
            <>
              <span>{option.label}</span>
            </>
          )}
          renderInput={(params) => (
            <TextField
              {...params}
              InputProps={{
                ...params.InputProps,
                disableUnderline: true,
                classes: {
                  disabled: classes.disabled,
                },
                style: {
                  paddingRight: 0,
                },
              }}
              fullWidth
              inputProps={{
                ...params.inputProps,
                className: `
                ${
                  props.placeWhereComponentBeingUsed === "data-table"
                    ? classes.textAlignCenter
                    : ""
                } 
                ${classes.floating}
              `,
                style: {
                  fontSize: "14px",
                  height: "28px",
                  overflow: "hidden",
                  textOverflow: "ellipsis",
                  whiteSpace: "nowrap",
                  // margin:"0px 4px",
                  margin: "0px 10px",
                },
                autoComplete: "new-password", // disable autocomplete and autofill
              }}
            />
          )}
        />
      </Box>
    </Tooltip>
  );
}

const connector = connect(undefined, {
  updateData,
  setResources,
});

export default connector(LabelEditText);

export type TPropsFromRedux = ConnectedProps<typeof connector>;

import { Fragment, useEffect, useState } from "react";
import { Box, Button, Chip, CircularProgress, IconButton, Link, makeStyles, TextField, Tooltip } from "@material-ui/core";

import ImagePlotter, { ImageCoordinate } from "../../../../../../../../../components/ModelData/ImagePlotter";
import { Check, Delete, Fullscreen, KeyboardArrowLeft, KeyboardArrowRight, Restore  } from "@material-ui/icons";
import clsx from "clsx";
import moment from "moment";
import { useHistory, useLocation } from "react-router";
import { DataSortField, DataSortFieldDefaultValue, DataTableResourceNameSearchValue, DataTableResourceNameSearchValueQueryParam, IData, IModelType, IReduxState, SortOrder, SortOrderDefaultValue } from "../../../../../../../../../../../common";
import { connect, ConnectedProps, useDispatch } from "react-redux";
import { updateData, setResourceDataBoost, setSelectedData, addDataSetCollectionInDataSetCollectionsFilter, setShouldBlockDataTableKeyboardEvents, updateResourceImageAnnotationsInDB, updateResourceRemarks, updateResourceWhichAreBeingUpdatedInDB } from "../../../../../../../../../../../store/actions";
import AudioPlayer from "../../../../../../../../../components/ModelData/AudioPlayer";

import 'react-edit-text/dist/index.css';
import classNames from 'classnames';

import { convertNameSearchOperatorToRegex, convertTagSearchOperatorToRegex, ResourceLimitQueryParam, ResourceOffsetQueryParam, ResourceSortFieldQueryParam, ResourceSortOrderQueryParam, ResourceStatusQueryParam, ResourceTagSearchOperator } from "./TableContent";
import { tooltipTextForEditResourceDataSetCollectionButton, tooltipTextForApprovedIcon, tooltipTextForDeleteIconWhenDeletingResource, tooltipTextForDeleteIconWhenMovingToDeleteTab, tooltipTextForBacklogIcon, tooltipTextForSetApprovalStatusButton, tooltipTextForSetRejectedStatusButton, LABEL_GROUP_RESOURCE_LEVEL, LABEL_GROUP_TASK_LEVEL } from '../constants/constants';
import { getData, setSelectedItems, setResourcesTotalCount, setResources, addResourcesToDataSetCollection, setDataSetCollectionsInResource, updateResourceImageGroupAnnotationsInDB, updateResourceInUI } from '../../../../../../../../../../../store/actions/dataActions';
import { useURLQuery } from "../../../../../../../../../../../hooks/useURLQuery";
import { ResourceStatus, DataTableResourceNameSearchOperatorQueryParam, DataTableResourceNameSearchOperatorDefaultValue, DataTableResourceTagSearchValue, DataTableResourceTagSearchValueQueryParam, DataTableResourceTagSearchOperatorQueryParam, DataTableResourceTagSearchOperatorDefaultValue } from '../../../../../../../../../../../common/constants/interfaces/data';
import { ResourceNameSearchOperator } from './TableContent';
import TagEditText from "./TagEditText";
import LabelEditText from "./LabelEditText";
import { AddOrDeleteResourcesToDataSetCollectionSelectionPopup } from "./AddOrDeleteResourcesToDataSetCollectionSelectionPopup";
import AddResourceToDataSetCollectionButton from "./AddResourceToDataSetCollectionButton";
import { ModelService } from "../../../../../../../../../../../services";
import ImageKeyPointsEditDialog from "./ImageKeyPointsEditDialog";
import { InputFieldState } from "../../../../../../../../../../../common/constants/interfaces/inputFieldState";
// import { generateRandomBrightColor } from "../../../../../../../../../../../services/colorHelperService";
// import { CoordinatesList } from "./CoordinatesList";
import { TwoDimensionalImage } from "../../../../../../../../../../../assets/components/react-annotation-tool/src/apps/two-dimensional-image";
import { getImageScaleFactorForImageWidth } from "../../../../../../../../../../../services/imageHelperService";
import { copyByValue } from '../../../../../../../../../../../services/variableHelperService';
import { AnnotationComponentSaveClickData, AnnotationOption, AnnotationOptionChildren, defaultAnnotationOptions, getDefaultAnnotationsToMapInAnnotationComponentIfModelTypeImageGroupAnnotation, setResourceImageAnnotationsForSendingToAnnotationComponent, setResourceImageGroupAnnotationsForSendingToAnnotationComponent } from "../../../../../../../../../../../services/imageAnnotationHelperService";
import SetApprovalStatusButton from "./SetApprovalStatusButton";
import SetRejectedStatusButton from "./SetRejectedStatusButton";
import { Autocomplete } from "@material-ui/lab";
import { AddModelResourceToCopilotDialog } from "./AddModelResourceToCopilotDialog";
import { themePrimaryMainColor } from "../../../../../../../../../../../theme";
import ViewAsCSVDialog from "./ViewAsCSVDialog";

/*
import {
    Dropdown,
    DropdownToggle,
    DropdownMenu,
    DropdownItem,
  } from 'reactstrap';
*/

const duplicateDropdownData  =  Array(100).fill(0).map((e,i)=>(i+1).toString())
const useStyles = makeStyles((theme) => ({
    infoBlock: {
        paddingTop: theme.spacing(4),
    },
    infoBlockWhenModelTypeImageAnnotation: {
        paddingTop: 0
    },
    pointerEventsNone: {
        pointerEvents: 'none'
    },
    actionsSectionWhenSideDrawerImageScenario: {
        position: "sticky",
        top: "300px",
        backgroundColor: SideDrawerBackgroundColor,
        zIndex: 1        
    },
    cursorNotAllowed: {
        cursor: "not-allowed"
    },
    editBtn:{
        height:'0.6em',
    },
    info: {
        display: 'flex',
        lineHeight: '1 !important',
        paddingBottom: '5px'
    },
    infoLabel: {
        margin: 0,
        minWidth: '100px',
        color: theme.palette.grey[600],
        marginBottom: 'auto',
        marginTop: 'auto',
    },
    infoValue: {
        width: 'auto',
        // fontWeight: 500,
        display: 'inline-block',
        whiteSpace: 'nowrap',
        overflow: 'hidden',
        textOverflow: 'ellipsis',
        margin:'0px',
        marginLeft: '5px',
        fontSize: '14px'
    },
    actions: {
        minWidth: '200px',
        minHeight: '48px',
        display: 'flex',
        alignItems: 'center'
    },
    labelBlock: {
        width: '300px',
        marginLeft: 'auto',
        marginRight: 'auto',
        marginBottom: theme.spacing(2),
    },
    indicators: {
        position: 'absolute',
        bottom: '100px',
        width: '50px',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        cursor: 'pointer',
        transition: 'opacity 0.25s ease-in-out',
        borderRadius: '50%',
        background: 'rgba(2, 141, 154, 0.1)',
    },
    leftIndicator: {
        left: 0,
    },
    rightIndicator: {
        left: 300,
    },
    status: {
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        justifyContent: 'center',
        height: '100%'
    },
    delete: {
        color: '#EF8A8A'
    },
    progress: {
        padding: '10px',
    },
    input: {
        width: '160px',
        margin: 0,
        fontWeight: 500,
        display: 'inline-block',
        whiteSpace: 'nowrap',
        overflow: 'hidden',
        textOverflow: 'ellipsis',
        border: 'none',
        borderBottom: '1px solid gray',
        height: '30px'
    },

    csvText: {
        // overflowX: 'auto',
        // textOverflow: 'unset'
        wordWrap: "break-word",
        whiteSpace: "normal"    
    },
    editText: {
        backgroundColor: 'transparent !important',
        textAlign: "left !important" as "left"
    },
    chip: {
        minWidth: "75px",
        marginBottom: 5,
        backgroundColor: theme.palette.grey[50],
        marginRight: theme.spacing(1),
      },
    alertDiv:{
        position:'absolute',
        top:'20%',
        right:30,
        left:30,
        background: 'rgba(2, 141, 154, 0.1)',
        
    },
    actionsSection: {
        position: "sticky",
        zIndex: 1,
        top: '300px',
        backgroundColor: 'inherit',
        paddingTop : '20px'        
    },
    actionsSectionWhenImageModelSideDrawerScenario: {
        paddingTop : '0px'        
    },
    actionSectionWhenAnnotationDialogScenario: {
        paddingTop: '0px'
    },
    actionSectionWhenImageModelDialogScenario: {
        paddingTop: '0px',
        bottom: '0px',
        top: '0px',
        backgroundColor: 'white'
    }
}));

// const showName = (data: IData) => {
//     const filter = data.model_type === 'audio' ? 'auto-ai_image-data/' : 'image-data/'
//     const start = data.resource.indexOf(filter) + 19;
//     const end = data.resource.indexOf('?');
//     return data.resource.substring(start, end);
// }


const showName = (data: IData) => {
        let resouceString = data?.filename;
        if(resouceString?.length>20){
            resouceString = "..."+resouceString.substring(resouceString.length-18,resouceString.length);
        }
    //  let resouceString =data.resource.substring(start,end).split('').reverse().join('').substring(0,20)+'..........';
        // resouceString = resouceString.split('').reverse().join('')

    if (!data || Object.keys(data)?.length === 0) {
        return "";
    }
  //  let resouceString =data.resource.substring(start,end).split('').reverse().join('').substring(0,20)+'..........';
    // resouceString = resouceString.split('').reverse().join('')

        return resouceString;
}


// const BootstrapInput = withStyles((theme: Theme) =>
//     createStyles({
//         root: {
//             'label + &': {
//                 marginTop: theme.spacing(3),
//             },
//         },
//         input: {
//             borderRadius: 20,
//             position: 'relative',
//             backgroundColor: theme.palette.background.paper,
//             border: '1px solid #ced4da',
//             fontSize: 16,
//             padding: '10px 26px 10px 12px',
//             transition: theme.transitions.create(['border-color', 'box-shadow']),
//             '&:focus': {
//                 borderRadius: 4,
//                 borderColor: '#80bdff',
//                 boxShadow: '0 0 0 0.2rem rgba(0,123,255,.25)',
//             },
//         },
//     }),
// )(InputBase);

interface IProps extends TPropsFromRedux {
    // onSwitch: (index: number) => void;
}

const SideDrawerBackgroundColor = "#e7edf3";

const circleRadius = 3;
const defaultImageDifference = 1;

// const sideDrawerCanvasId = "sideDrawerCanvas";
// const dialogCanvasId = "dialogCanvas";

const imageWidthToSetForSideDrawerScenario = 350;

function ImageAnnotationFullScreenButton(props: { 
    onClick?: () => void,
    iconButtonStyles?: {}
 }) {

    const iconButtonStyles = props.iconButtonStyles || {};

    return (
      <IconButton
        onClick={() => {
          if (props.onClick) {
            props.onClick();
          }
        }}
        style={{alignSelf: 'baseline', paddingTop: "6px", paddingBottom: "6px", ...iconButtonStyles}}
      >
        <Fullscreen />
      </IconButton>
    );
}

function DataView(props: IProps) {
    const classes = useStyles();
    const history = useHistory();
    const dispatch = useDispatch();
    const cvsClasses = classNames(classes.infoValue, classes.csvText);
    const loc = useLocation();
    const { selected, isFetching, isUpdating, approvedWaitingList, deletedWaitingList, approvalWaitingList, rejectedWaitingList, resources, updateData ,setSelectedData} = props;
    const [selectedDataObject, setSelectedDataObject] = useState<IData>();

    const modelType = selectedDataObject?.model?.type || "";

    const [selectedDataObjectForSideDrawerImageAnnotation, setSelectedDataObjectForSideDrawerImageAnnotation] = useState<IData>();

    const [isAddResourcesToDataSetCollectionPopupShowing, setIsAddResourcesToDataSetCollectionPopupShowing] = useState(false);

    const [labelsAvailable, setLabelsAvailable] = useState<string[]>([])

    const [isImageKeyPointsEditDialogOpen, setIsImageKeyPointsEditDialogOpen] = useState(false);

    const [imageCoordinatesState, setImageCoordinatesState] = useState<InputFieldState<ImageCoordinate[]>>({value: []});

    const [imageDifference, setImageDifference] = useState(
        defaultImageDifference
      );

    const [hasImageScalingCompleted, setHasImageScalingCompleted] = useState(false);
    const [resourcesForWhichImageScalingGoingOn, setResourcesForWhichImageScalingGoingOn] = useState<string[]>([])

    const [shouldShowImageAnnotationComponent, setShouldShowImageAnnotationComponent] = useState(true);

    const modelId: string = selectedDataObject?.model?._id || '';

    const resourceImageUrl = selectedDataObject?.resource || '';

    //states to handle alert box
    const [alert, setAlert] = useState("none");
    const [alertMessage, setAlertMessge] = useState("none");
    // const imageKeyPointsSavedInDB = selectedDataObject && selectedDataObject?.imageKeyPoints || []; 

    const [annotationOptions, setAnnotationOptions] = useState<AnnotationOption>(defaultAnnotationOptions);

    const [resourceRemarksTextState, setResourceRemarksTextState] = useState<InputFieldState<string>>({value: ""});
    const [showLeftArrow, setShowLeftArrow] = useState(true);
    const [showRightArrow, setShowRightArrow] = useState(true);

    // const [dropdownOpen, setDropdownOpen] = useState(false);
    const [dataBoost,setDataBoost]  = useState('1')
    // const toggle = () => setDropdownOpen((prevState) => !prevState);

    const onDataBoostChange = (ele: string) => {
        if(selectedDataObject?._id) {
            updateData({  id: [selectedDataObject?._id],dataBoost: Number(ele) }, { }, {shouldFetchLatestData: false});
        }
        setDataBoost(ele.toString())
        setSelectedDataObject((prev) => (JSON.parse(JSON.stringify({...prev, dataBoost: ele.toString()}))));
        dispatch(setResourceDataBoost({resourceId: selectedDataObject?._id || "", dataBoost: Number(ele) }))
    }

    useEffect(()=>{
        const executeFunction = async () => {
            const apiResponse = await ModelService.getModelDetails({
                modelId: modelId,
                modelSelectQuery: "annotationLabelsAvailable -_id"
            })
            const annotationOptionsToSave = copyByValue(defaultAnnotationOptions);
            annotationOptionsToSave.children = [];

            const annotataionOptionChildrens: AnnotationOptionChildren[] = apiResponse.data.annotationLabelsAvailable as AnnotationOptionChildren[];
            annotataionOptionChildrens.map(annotationOptionChildren=>{
                annotationOptionChildren.children = [];
                return annotationOptionChildren
            })

            annotationOptionsToSave.children
            .push(
                ...annotataionOptionChildrens
            );

            setAnnotationOptions(copyByValue(annotationOptionsToSave));
        }
        if (modelId && (modelType === 'imageAnnotation' || modelType === 'imageAnnotationGroup')) {
            executeFunction();
        }
    }, [modelId, modelType])

    useEffect(()=>{
        setImageCoordinatesState({
            value: [],
            isDirty: false,
            isTouched: false
        });   
        // clearImageKeyPointsInCanvas();     
      }, [resourceImageUrl])  
      
      useEffect(()=>{
        setShouldShowImageAnnotationComponent(false);
        setTimeout(()=>{
            setShouldShowImageAnnotationComponent(true);
        }, 500)
      }, [selected])

    // useEffect(() => {
    //     if (imageKeyPointsSavedInDB?.length>0) {
    //         const imageCoordinates: ImageCoordinate[] = [];
    //         for (const imageKeyPointSavedInDB of imageKeyPointsSavedInDB) {
    //             imageCoordinates.push({
    //                 colorCode: generateRandomBrightColor(),
    //                 xCoordinate: imageKeyPointSavedInDB.xCoordinate,
    //                 yCoordinate: imageKeyPointSavedInDB.yCoordinate
    //             })
    //         }
    //         setImageCoordinatesState((oldState)=>{ return {
    //             ...oldState,
    //             value: [...imageCoordinates]
    //         }})
    //     }
    //   return () => {
    //   }
    // }, [resourceImageUrl])
    

    useEffect(()=>{
        const executeFunction = async () => {
            const apiResponse = await ModelService.getModelLabelsAvailable({modelId: modelId})
            if (apiResponse.data) {
                setLabelsAvailable([...apiResponse.data])
            } else {
                setLabelsAvailable([])
            }
        }
        if (modelId) {
            executeFunction();
        }
    }, [modelId])

    const addOrRemoveArrows = (index: number) => {
        const OffsetValue: string = urlQuery.get(ResourceOffsetQueryParam) as string;;
        const limit: string = urlQuery.get(ResourceLimitQueryParam) as string;;
        const globalCurrentRowValue = ((Number(OffsetValue) - 1) * Number(limit)) + (index + 1);
        if(globalCurrentRowValue === 1){
            setShowLeftArrow(false)
        }
        else{
            setShowLeftArrow(true)
        }
        if(globalCurrentRowValue === props.totalCount){
            setShowRightArrow(false)
        }
        else{
            setShowRightArrow(true)
        }
    }

    useEffect(() => {
        console.log(resources)
            if (resources?.length>0) {
            for (let i=0 ; i<resources.length; i++) {
                if (resources[i]._id === selected) {
                    addOrRemoveArrows(i)
                    setSelectedDataObject(JSON.parse(JSON.stringify(resources[i])));
                    resources[i]?.dataBoost ?
                        setDataBoost(resources[i]?.dataBoost) : setDataBoost('1')
                    return;
                }
            }
        }
        setSelectedDataObject(undefined);
    }, [selected, resources])

    useEffect(()=>{
        setSelectedDataObjectForSideDrawerImageAnnotation(copyByValue(selectedDataObject));
    }, [selectedDataObject])    

    useEffect(()=>{
        if (!isImageKeyPointsEditDialogOpen) {
            setHasImageScalingCompleted(false);
            setResourcesForWhichImageScalingGoingOn(oldState=>[...oldState, selectedDataObject?._id.toString() || ""])
            const image = new Image()  ;
            image.src = selectedDataObject?.resource || "";
            image.onload = function () {
                const imageScaleFactor = getImageScaleFactorForImageWidth(
                    image.width,
                    imageWidthToSetForSideDrawerScenario
                );

                const imageGroupAnnotationsToSet = 
                    selectedDataObject?.model.type === "imageAnnotationGroup"
                      ? setResourceImageGroupAnnotationsForSendingToAnnotationComponent(
                            copyByValue(selectedDataObject?.imageGroupAnnotations  || []),
                            imageScaleFactor
                        )
                      : []

                const imageAnnotationsToSet = 
                    selectedDataObject?.model.type === "imageAnnotation"
                      ? setResourceImageAnnotationsForSendingToAnnotationComponent(
                            copyByValue(selectedDataObject?.imageAnnotations || []),
                            imageScaleFactor
                        )
                      : []

    
                // @ts-ignore
                setSelectedDataObjectForSideDrawerImageAnnotation(oldState=>{return {
                    ...oldState,
                    imageGroupAnnotations: [...imageGroupAnnotationsToSet],
                    imageAnnotations: [...imageAnnotationsToSet]
                }})
    
                setHasImageScalingCompleted(true)
                setResourcesForWhichImageScalingGoingOn(oldState=>{
                    const newArray = [...oldState];
                    for (let index = 0; index < newArray.length; index++) {
                        const resourceId = newArray[index];
                        if (resourceId === selectedDataObject?._id) {
                            newArray.splice(index, 1);
                            break;
                        }
                    }
                    return [...newArray]
                })
            }
        }
        // clearImageKeyPointsInCanvas();     
      }, [selectedDataObject?.resource, isImageKeyPointsEditDialogOpen, selectedDataObject?.model.type, selectedDataObject?.imageGroupAnnotations, selectedDataObject?.imageAnnotations, selectedDataObject?._id])     

    // async function handleClearButtonClick() {
    //     clearImageKeyPointsInCanvas();
    //     await clearImageKeyPointsForThisResourceInDB({_id: selectedDataObject?._id?.toString() || ""});
    //     setImageCoordinatesState({
    //       value: [],
    //       isDirty: false,
    //       isTouched: false,
    //     });
    //   }  

    //   async function clearImageKeyPointsForThisResourceInDB(resource: {_id: string}) {
        // if (resource._id) {
        //   await props.updateResourceImageKeyPointsInDB({
        //     imageGroupAnnotations: [],
        //     resourceId: resource._id.toString(),
        //   });
        // }
    //   }      
      
    //   function clearImageKeyPointsInCanvas() {
    //     let canvas = document.getElementById(sideDrawerCanvasId) || document.getElementById(dialogCanvasId);
    //     if (canvas) {
    //         // @ts-ignore
    //         let context = canvas.getContext("2d");
    //         // @ts-ignore
    //         context?.clearRect(0, 0, canvas?.width || 0, canvas?.height || 0);
    //     }
    //   }      

    // let imageName = "";
    // let createdAt_1 = selected?.created_at;
    let disableSaveDelete = false;
    // if (selected) {
   // const [selectedData, setSelectedDatas] = useState(selected);
    /* if (selected) {
        imageName = showName(selected);
    } */
    //const [tag, setTag] = useState(selected?.tag);
    //let label = selected?.label?.toString();
    //const [csv, setCsv] = useState(selected?.csv);
    
    const urlQuery = useURLQuery();

    const csvInitialMaxLengthLimit=24;
    const changesMadeInitialMaxLengthLimit=12;
    const [csvExpandedFormShowing, setCsvExpandedFormShowing] = useState<boolean>(false);
    const [changesMadeExpandedFormShowing, setchangesMadeExpandedFormShowing] = useState<boolean>(false);
    
    const onSwitch = (i: number) => { 
        var resourceData: IData[] = [];
        resourceData = resources;
        var filteredData;
        for(var item = 0; item < resourceData?.length; item++){
            if(selectedDataObject?._id === resourceData[item]?._id){
                 if (item+i === -1){
                    /**
                     * user pressed left arrow on table 1st row
                     */                     

                    // filteredData = resourceData[0];

                    const oldOffsetValue: string = urlQuery.get(ResourceOffsetQueryParam) as string;
                    const limit: string = urlQuery.get(ResourceLimitQueryParam) as string;
                    const newOffsetValue: number = Number(oldOffsetValue)-1;
                    if (newOffsetValue < 1) {
                        /**
                         * cannot reduce pageNumber from 1 to 0 as 1 is the lowest
                         */
                        return;
                    }
                    urlQuery.set(ResourceOffsetQueryParam, String(newOffsetValue));
                    urlQuery.set('index', String(Number(limit)-1));
                    history.push(`${loc.pathname}?${urlQuery.toString()}`);                        
                } else if (item+i > resourceData.length-1){
                    /**
                     * user pressed right arrow on table last row
                     */

                    const oldOffsetValue: string = urlQuery.get(ResourceOffsetQueryParam) as string;
                    const newOffsetValue: number = Number(oldOffsetValue)+1;

                    urlQuery.set(ResourceOffsetQueryParam, String(newOffsetValue));
                    urlQuery.set('index', String(0));
                    history.push(`${loc.pathname}?${urlQuery.toString()}`);                        
                } else {
                    filteredData = resourceData[item+i];
                }
                if (filteredData) {
                    setSelectedData(filteredData._id);
                }
                //props.selected = filteredData;
          //      updateData({id: ['selectedData?._id'], status: 'newStatus' }, {  });
              //  updateData([],[])
               // setSideDrawer({ type: 'dataSelected', component: TableContent });
               
            }
        }
    };
    //     imageName = showName(selected);
    // }
    // const [tag, setTag] = useState(selected?.tag);
    // let label =  selected?.label.toString();
    // const [csv, setCsv] = useState(selected?.csv);
    
    // const [predicted, setPredicted] = useState(selected?.prediction);
    // const [cScore, setCScore] = useState(selected?.confidence_score);
    // setLabel(selected?.label.toString());
    // const handleSave = (e: any) => {
    //     console.log(e);
    //     // const query = new URLSearchParams(loc?.search);
    //     // const limit = query?.get('limit') || '10';
    //     // const offset = query?.get('offset') || '1';
    //     // const status = query?.get('status') || 'backlog';
    //     // const model = query?.get('model') || '';
    //     // const model_type = query?.get('type') || '';
    //     // updateData({ id, status: newStatus }, { limit, offset, status, model, model_type });
       
    //     // updateData({ id: [selected?.id], selected?.status,  label : "1"}, { offset: `${page + 1}`, limit: `${rowsPerPage}`, status });
    //     disableSaveDelete = true;
    //   };
    //   var [toggle, setToggle] = useState(true);
    //   function toggleInput() {
    //     setToggle(false);
    //   }

      
//   function handleChange(event) {
//     setLabel(event.target.value);
//   }


// const handleChange = (e: any) => {
//     label = e.target.value;
//     console.log(e.target.value);
//     console.log(label);
//   }

    function getSelectedSubTab(): ResourceStatus {
        const query = new URLSearchParams(loc.search);
        const status = query?.get(ResourceStatusQueryParam) || 'backlog';
        return status as ResourceStatus;
    }
  
    const update = (ids: string[], newStatus: ResourceStatus) => {
        const query = new URLSearchParams(loc?.search);
        const limit = query?.get(ResourceLimitQueryParam) || '10';
        const offset = query?.get(ResourceOffsetQueryParam) || '1';
        const status: ResourceStatus = query?.get(ResourceStatusQueryParam) as ResourceStatus || 'backlog' ;
        const model = query?.get('model') || '';
        // const model_type = query?.get('type') || '';
        const sortOrder: SortOrder = query?.get(ResourceSortOrderQueryParam) as SortOrder || SortOrderDefaultValue;
        const sortField: DataSortField = query?.get(ResourceSortFieldQueryParam) as DataSortField || DataSortFieldDefaultValue;
        
        const nameSearchValue: DataTableResourceNameSearchValue = query?.get(DataTableResourceNameSearchValueQueryParam) as DataTableResourceNameSearchValue;
        const nameSearchOperator: ResourceNameSearchOperator = query?.get(DataTableResourceNameSearchOperatorQueryParam) as ResourceNameSearchOperator || DataTableResourceNameSearchOperatorDefaultValue;
        const nameRegex = convertNameSearchOperatorToRegex(nameSearchOperator, nameSearchValue);

        const tagSearchValue: DataTableResourceTagSearchValue = query?.get(DataTableResourceTagSearchValueQueryParam) as DataTableResourceTagSearchValue;
        const tagSearchOperator: ResourceTagSearchOperator = query?.get(DataTableResourceTagSearchOperatorQueryParam) as ResourceTagSearchOperator || DataTableResourceTagSearchOperatorDefaultValue;
        const tagRegex = convertTagSearchOperatorToRegex(tagSearchOperator, tagSearchValue);
        
        updateData({ id: ids, status: newStatus }, { limit, offset, status, model, sortOrder, sortField, nameRegex, tagRegex }, {shouldFetchLatestData: false});
        if (ids.length === 1 && newStatus) {
            let totalCount = props.totalCount;
            let resources = props.resources;
            for (let resourceIndex = 0; resourceIndex < resources.length; resourceIndex++) {
                const resource = resources[resourceIndex];
                for (let idIndex = 0; idIndex < ids.length; idIndex++) {
                    const id = ids[idIndex];
                    if (resource._id === id) {
                        totalCount -= 1;
                        const indexOfIdInSelectedItems: number = props.selectedItems.indexOf(id);
                        if (indexOfIdInSelectedItems !== -1) {
                            let selectedItemsValueToUpdate = [...props.selectedItems];
                            selectedItemsValueToUpdate.splice(indexOfIdInSelectedItems, 1);
                            props.setSelectedItems([...selectedItemsValueToUpdate])
                        }
                        resources.splice(resourceIndex, 1);
                        resourceIndex = resourceIndex - 1;
                    }
                }
            }
            props.setResourcesTotalCount(totalCount);
            props.setResources(resources);            
        }
    };
    
    function ShowLessLink() {
        return (
            <div>
                <Link
                    style={{ cursor: "pointer" , color: "#008D9C"}}
                    onClick={() => {
                    setCsvExpandedFormShowing(false);
                    }}
                >
                    show less
                </Link>
            </div>
        );
      }

    function ActionsSection(props: {
        resourcesWhichAreBeingAddedToDataSetCollectionInDB: string[];
        scenario?: "side-drawer-image" | "annotationDialog" | "imageModelDialog"
    }) {

        const [addResourceToCopilotDialogState, setAddResourceToCopilotDialogState] = useState({
            isOpen: false
        })

        // ${props.scenario === 'side-drawer-image' ? classes.actionsSectionWhenSideDrawerImageScenario : ""}
        return (
        <div className={`
            ${classes.info}
            ${classes.actionsSection}
            ${props.scenario === "annotationDialog" ? classes.actionSectionWhenAnnotationDialogScenario : ""}
            ${props.scenario === "side-drawer-image" ? classes.actionsSectionWhenImageModelSideDrawerScenario : ""}
            ${props.scenario === "imageModelDialog" ? classes.actionSectionWhenImageModelDialogScenario : ""}
        `}
    >
        
        <p className={classes.infoLabel} style={{minWidth: "59px"}}>Actions:</p>
        <div className={classes.actions}>
            {
                selectedDataObject?.status === 'approval' 
                && (approvedWaitingList.indexOf(selectedDataObject._id) >= 0 && !disableSaveDelete ?
                    <CircularProgress classes={{ root: classes.progress }} size={48} /> :
                    <Tooltip title={tooltipTextForApprovedIcon}>
                        <IconButton aria-label="approve" color="primary"
                            disabled={
                                isUpdating || isFetching || deletedWaitingList.indexOf(selectedDataObject._id) >= 0 || disableSaveDelete ||
                                props.resourcesWhichAreBeingAddedToDataSetCollectionInDB.indexOf(selectedDataObject._id.toString()) !== -1
                            } 
                            onClick={() => update([selectedDataObject._id], 'approved')}><Check /></IconButton>
                    </Tooltip>
                )
            }
            {
                (selectedDataObject?.status === 'deleted') && (approvedWaitingList.indexOf(selectedDataObject._id) >= 0 && !disableSaveDelete ?
                    <CircularProgress classes={{ root: classes.progress }} size={48} /> :
                    <Tooltip title={tooltipTextForBacklogIcon}>
                        <IconButton aria-label="approve" color="primary" 
                            disabled={
                                isUpdating || isFetching || deletedWaitingList.indexOf(selectedDataObject._id) >= 0 || disableSaveDelete ||
                                props.resourcesWhichAreBeingAddedToDataSetCollectionInDB.indexOf(selectedDataObject._id.toString()) !== -1
                            } 
                            onClick={() => update([selectedDataObject._id], 'backlog')}><Restore /></IconButton>
                    </Tooltip>
                )
            }
            {
                selectedDataObject?.status === 'approved' &&
                    (
                        props.resourcesWhichAreBeingAddedToDataSetCollectionInDB.indexOf(selectedDataObject._id) === -1
                        ?   (
                            <Tooltip title={tooltipTextForEditResourceDataSetCollectionButton}>
                                <span>
                                    <AddResourceToDataSetCollectionButton
                                        onClick={()=>{
                                            setIsAddResourcesToDataSetCollectionPopupShowing(true)
                                        }}
                                    />  
                                </span>
                            </Tooltip>                                                
                        )
                        :    <CircularProgress classes={{ root: classes.progress }} size={48} />
                    )
            }            
            {
                (
                    selectedDataObject?.status === 'backlog' ||
                    selectedDataObject?.status === 'rejected'
                ) &&
                (approvalWaitingList.indexOf(selectedDataObject?._id || "") >= 0 ?
                    <CircularProgress classes={{ root: clsx(classes.progress) }} size={48} /> :
                    <Tooltip
                        title={
                            tooltipTextForSetApprovalStatusButton
                        }
                    >
                        <span>
                            <SetApprovalStatusButton 
                                // color="primary"
                                color={themePrimaryMainColor}
                                disabled={
                                    isUpdating || isFetching || approvalWaitingList.indexOf(selectedDataObject?._id || "") >= 0 || disableSaveDelete ||
                                    props.resourcesWhichAreBeingAddedToDataSetCollectionInDB.indexOf(selectedDataObject?._id?.toString() || "") !== -1
                                }
                                onClick={()=>{
                                    selectedDataObject?._id && update([selectedDataObject._id], 'approval')
                                }}                                
                            />
                        </span>
                    </Tooltip>
                )
            }            
            {
                (
                    selectedDataObject?.status === 'approval' ||
                    selectedDataObject?.status === 'approved'
                ) &&
                (rejectedWaitingList.indexOf(selectedDataObject?._id || "") >= 0 ?
                    <CircularProgress classes={{ root: clsx(classes.progress) }} size={48} /> :
                    <Tooltip
                        title={
                            tooltipTextForSetRejectedStatusButton
                        }
                    >
                        <span>
                            <SetRejectedStatusButton 
                                color="primary"
                                disabled={
                                    isUpdating || isFetching || rejectedWaitingList.indexOf(selectedDataObject?._id || "") >= 0 || disableSaveDelete ||
                                    props.resourcesWhichAreBeingAddedToDataSetCollectionInDB.indexOf(selectedDataObject?._id?.toString() || "") !== -1
                                }
                                onClick={()=>{
                                    selectedDataObject?._id && update([selectedDataObject._id], 'rejected')
                                }}                                
                            />
                        </span>
                    </Tooltip>
                )
            }            
            {
                (deletedWaitingList.indexOf(selectedDataObject?._id || "") >= 0 ?
                    <CircularProgress classes={{ root: clsx(classes.progress, classes.delete) }} size={48} /> :
                    <Tooltip
                        title={
                            selectedDataObject?.status === 'deleted'
                            ? tooltipTextForDeleteIconWhenDeletingResource
                            : tooltipTextForDeleteIconWhenMovingToDeleteTab
                        }
                    >
                        <IconButton aria-label="delete" classes={{ root: classes.delete }} 
                            disabled={
                                isUpdating || isFetching || approvedWaitingList.indexOf(selectedDataObject?._id || "") >= 0 || disableSaveDelete ||
                                props.resourcesWhichAreBeingAddedToDataSetCollectionInDB.indexOf(selectedDataObject?._id?.toString() || "") !== -1
                            } 
                            onClick={() => selectedDataObject?._id && update([selectedDataObject._id], 'deleted')}><Delete />
                        </IconButton>
                    </Tooltip>
                )
            }
            {
                (selectedDataObject?.status === "backlog" ||
                selectedDataObject?.status === "approval" ||
                selectedDataObject?.status === "approved") &&
                (
                    selectedDataObject?.model?.type==="image" ||  
                    selectedDataObject?.model?.type==="imageAnnotation"  
                ) &&
                <>
                    <Button
                        onClick={()=>{
                            setAddResourceToCopilotDialogState(oldState=>({...oldState, isOpen: true}))
                        }}
                        variant="contained"
                        color="primary"
                        style={{height: "35px", padding: "10px 5px", width: "125px"}}
                    >Add to Copilot</Button>
                    {
                        addResourceToCopilotDialogState.isOpen &&
                        <AddModelResourceToCopilotDialog
                            open={addResourceToCopilotDialogState.isOpen}
                            resource={selectedDataObject}
                            onClose={()=>setAddResourceToCopilotDialogState(oldState=>({...oldState, isOpen: false}))}
                        />
                    }

                </>
            }
        </div>
    </div>              
        );
    }

      useEffect(()=>{
        setResourceRemarksTextState(oldState=>{return {
            ...oldState,
            value: selectedDataObject?.remarks?.text || "",
        }})
      }, [selectedDataObject?.remarks?.text])

      useEffect(()=>{
        setResourceRemarksTextState(oldState=>{return {
            ...oldState,
            isDirty: false,
            isTouched: false,
        }})
      }, [selected])
      let confidenceScore = Number(selectedDataObject?.confidence_score)
      let dataSetCollections = selectedDataObject?.dataSetCollections
      let changesMade = selectedDataObject?.changesMade


      const labelOptions = () => {
        const labelOptionsAvailable = [
            ...(selectedDataObject?.labelOptions?.map(labelObj => {
                return {
                    label: labelObj.labelOption,
                    group: LABEL_GROUP_RESOURCE_LEVEL,
                }
            }) || []
            ),
            ...(labelsAvailable?.map(label => { return {label, group: LABEL_GROUP_TASK_LEVEL} }) || []),
        ]
      
        return labelOptionsAvailable
      }

    return (
        <Fragment>
        

            {
                selectedDataObject && Object.keys(selectedDataObject).length &&
                <Fragment>

                    {
                        isImageKeyPointsEditDialogOpen &&
                        <ImageKeyPointsEditDialog 
                        updateResourceInUI={props.updateResourceInUI}
                            multipleImageAnnotationResourceImageIndex={0}
                            setCurrentMultipleImageAnnotationResourceImageIndex={()=>{}} 
                            resourceRemarksTextState={resourceRemarksTextState}
                            setResourceRemarksTextState={setResourceRemarksTextState}
                            open={isImageKeyPointsEditDialogOpen}
                            imageExpandFeatureEnabled={false}
                            isZoomFeatureEnabledForImageAnnotation={true}
                            onSwitch={onSwitch}
                            modelReferenceData={[]}
                            onClose={() => {
                                setIsImageKeyPointsEditDialogOpen(false);
                            } }
                            scenario="dialog"
                            allowUserToSetAnyLabel={false}
                            modelOrCopilotType={selectedDataObject.model.type}
                            isViewOnlyMode={false && selectedDataObject?.status === 'approved' ? true : false}
                            annotationOption={annotationOptions}
                            resource={selectedDataObject}
                            isFetchingResource={false}
                            resources={resources}
                            totalCount ={props.totalCount}
                            confidenceScore={confidenceScore}
                            dataSetCollections={dataSetCollections}
                            changesMade={changesMade}
                            inCopilot={false}
                            modelType={selectedDataObject.model.type}
                            // setIsImageKeyPointsEditDialogOpen={setIsImageKeyPointsEditDialogOpen}
                            ActionsSection={<ActionsSection
                                resourcesWhichAreBeingAddedToDataSetCollectionInDB={props.resourcesWhichAreBeingAddedToDataSetCollectionInDB}
                                scenario={selectedDataObject?.model?.type === 'imageAnnotation' ||
                                    selectedDataObject?.model?.type === 'imageAnnotationGroup'
                                    ? "annotationDialog"
                                    : selectedDataObject?.model?.type === 'image'
                                        ? "imageModelDialog"
                                        : undefined} />}
                            alert={alert}
                            alertMessage={alertMessage}
                            onImageAnnotationSaveClick={(data) => {
                                data = copyByValue(data);
                                if (selectedDataObject.model.type === "imageAnnotationGroup") {
                                    props.updateResourceImageGroupAnnotationsInDB({
                                        imageGroupAnnotations: data.imageGroupAnnotations,
                                        resourceId: selectedDataObject._id.toString()
                                    }).then((res) => {
                                        setAlertMessge("Changes Saved !");
                                        setAlert("success");
                                        setTimeout(() => {
                                            setAlert("");
                                        }, 2000);
                                    }).catch((err) => {
                                        setAlertMessge("Something went wrong !!");
                                        setAlert("failed");
                                        setTimeout(() => {
                                            setAlert("");
                                        }, 2000);
                                    });
                                } else if (selectedDataObject.model.type === "imageAnnotation") {
                                    props.updateResourceImageAnnotationsInDB({
                                        imageAnnotations: data.annotations,
                                        resourceId: selectedDataObject._id.toString()
                                    }).then((res) => {
                                        setAlertMessge("Changes Saved !");
                                        setAlert("success");
                                        setTimeout(() => {
                                            setAlert("");
                                        }, 2000);
                                    }).catch((err) => {
                                        setAlertMessge("Something went wrong !!");
                                        setAlert("failed");
                                        setTimeout(() => {
                                            setAlert("");
                                        }, 2000);
                                    });
                                }
                            } } 
                            onSaveRemarksClick={() => {
                                props.updateResourceRemarks && props.updateResourceRemarks({
                                    resourceId: selectedDataObject._id || "",
                                    resourceRemarksText: resourceRemarksTextState.value
                                })
                                setResourceRemarksTextState(oldState=>{return {
                                  ...oldState,
                                  isDirty: false,
                                  isTouched: false
                              }})                                
                            }}  
                        />
                    }
                    {
                        isAddResourcesToDataSetCollectionPopupShowing &&
                        <AddOrDeleteResourcesToDataSetCollectionSelectionPopup
                            modelId={selectedDataObject.model._id}
                            open={isAddResourcesToDataSetCollectionPopupShowing}
                            onClose={() => {
                                setIsAddResourcesToDataSetCollectionPopupShowing(false);
                            } }
                            resources={props.resources}
                            resourceIdsSelected={[selectedDataObject._id]}
                            addResourcesToDataSetCollection={props.addResourcesToDataSetCollection}
                            scenario={'editing'}
                            updateResourceWhichAreBeingUpdatedInDB={props.updateResourceWhichAreBeingUpdatedInDB}
                            setDataSetCollectionsInResource={props.setDataSetCollectionsInResource}
                            addDataSetCollectionInDataSetCollectionsFilter={props.addDataSetCollectionInDataSetCollectionsFilter} 
                            dataSetCollectionsAvailable={props.dataSetCollectionsAvailableForFilter} 
                            isFetchDataSetCollectionApiExecuting={props.isDataSetCollectionsBeingFetchedForFilter}
                        /> 
                    }
                    
                    <Box id="resourceContainer"
                        style={{
                            position: "sticky",
                            top: "0px",
                            backgroundColor: SideDrawerBackgroundColor, 
                            zIndex: 2
                        }}
                    >
                        {
                            selectedDataObject.model.type === 'audio' 
                            ? <AudioPlayer src={selectedDataObject.resource} /> 
                            : selectedDataObject.model.type === 'image' || selectedDataObject.model.type === 'imageAnnotation' || selectedDataObject.model.type === 'imageAnnotationGroup'
                                ?   
                                // <div style={{marginLeft: '14px', width: '95%', height: "360px", overflow: 'auto', paddingTop: '16px'}}>
                                (
                                    <>
                                        {
                                            selectedDataObject?.model?.type === 'image' &&
                                            <ImagePlotter modelType={selectedDataObject.model.type} image={selectedDataObject?.resource} label={selectedDataObject?.label} draw={selectedDataObject?.draw}
                                                resourceData={selectedDataObject}
                                                scenario="sideDrawer"
                                                imageCoordinatesState={imageCoordinatesState}
                                                setImageCoordinatesState={setImageCoordinatesState}
                                                setIsImageKeyPointsEditDialogOpen={setIsImageKeyPointsEditDialogOpen}
                                                canvasId={'sideDrawerCanvas'}
                                                circleRadius={circleRadius} 
                                                defaultImageDifference={defaultImageDifference} 
                                                setImageDifference={setImageDifference} 
                                                imageDifference={imageDifference}
                                            />
                                        }

                                    </>
                                )
                                    // </div>
                                :   <VideoSection src={selectedDataObject?.resource} />
                        }
                    </Box>
                    {/* <IconButton>
                        <Edit />
                    </IconButton> */}
                        {/* // ${selectedDataObject.model.type === 'imageAnnotation' ? classes.infoBlockWhenModelTypeImageAnnotation : ""} */}

                                            {
                                                  shouldShowImageAnnotationComponent && hasImageScalingCompleted && (selectedDataObjectForSideDrawerImageAnnotation?.model?.type === 'imageAnnotation' || selectedDataObjectForSideDrawerImageAnnotation?.model?.type === 'imageAnnotationGroup') &&
                                                  resourcesForWhichImageScalingGoingOn.indexOf(selectedDataObject._id) === -1 &&
                                                  !isImageKeyPointsEditDialogOpen &&
                                                <div
                                                    className={`
                                                    `}
                                                    // ${selectedDataObjectForSideDrawerImageAnnotation?.status === 'approved' ? classes.cursorNotAllowed : ""}
                                                >
                                                    {/* ${selectedDataObjectForSideDrawerImageAnnotation?.status === 'approved' ? classes.pointerEventsNone : ""} */}
                                                    <div
                                                        className={`
                                                        `}
                                                    >
                                                        <TwoDimensionalImage
                                                            modelType={selectedDataObject.model.type} 
                                                            url={selectedDataObjectForSideDrawerImageAnnotation.resource}
                                                            isViewOnlyMode={false && selectedDataObject?.status === 'approved' ? true : false}
                                                            alert={[alert,alertMessage]}
                                                            onSaveClick={(data: AnnotationComponentSaveClickData)=>{
                                                                data = copyByValue(data);
                                                                if (selectedDataObject.model.type === "imageAnnotationGroup") {
                                                                    props.updateResourceImageGroupAnnotationsInDB({
                                                                        imageGroupAnnotations: data.imageGroupAnnotations,
                                                                        resourceId: selectedDataObject._id.toString()
                                                                    }).then((res)=>{
                                                                        setAlertMessge("Changes Saved !");
                                                                        setAlert("success");
                                                                        setTimeout(()=>{
                                                                          setAlert("");
                                                                        },2000);
                                                                      }).catch((err)=>{
                                                                        setAlertMessge("Something went wrong !!");
                                                                        setAlert("failed");
                                                                        setTimeout(()=>{
                                                                          setAlert("");
                                                                        },2000);
                                                                      })
                                                                } else if (selectedDataObject.model.type === "imageAnnotation") {
                                                                    props.updateResourceImageAnnotationsInDB({
                                                                        imageAnnotations: data.annotations,
                                                                        resourceId: selectedDataObject._id.toString()
                                                                    }).then((res)=>{
                                                                        setAlertMessge("Changes Saved !");
                                                                        setAlert("success");
                                                                        setTimeout(()=>{
                                                                          setAlert("");
                                                                        },2000);
                                                                      }).catch((err)=>{
                                                                        setAlertMessge("Something went wrong !!");
                                                                        setAlert("failed");
                                                                        setTimeout(()=>{
                                                                          setAlert("");
                                                                        },2000);
                                                                      })
                                                                }
                                                            }}
                                                            hasSaveButton={true || selectedDataObject?.status !== 'approved' ? true : false}
                                                            imageWidth={imageWidthToSetForSideDrawerScenario}
                                                            ActionsSection={
                                                                <ActionsSection
                                                                  resourcesWhichAreBeingAddedToDataSetCollectionInDB={props.resourcesWhichAreBeingAddedToDataSetCollectionInDB}  
                                                                />
                                                            }
                                                            canShowAddAnnotationButton={
                                                                false && selectedDataObject?.status === 'approved'
                                                                ? false
                                                                : true
                                                            }
                                                            options={annotationOptions}
                                                            defaultAnnotations={
                                                                selectedDataObject.model.type === 'imageAnnotation'
                                                                ? selectedDataObjectForSideDrawerImageAnnotation.imageAnnotations
                                                                : selectedDataObject.model.type === 'imageAnnotationGroup'
                                                                    ?   getDefaultAnnotationsToMapInAnnotationComponentIfModelTypeImageGroupAnnotation(
                                                                            selectedDataObjectForSideDrawerImageAnnotation?.imageGroupAnnotations || []
                                                                        )
                                                                    :   []                                                            
                                                            }
                                                            defaultAnnotationGroups={
                                                                selectedDataObject.model.type === 'imageAnnotationGroup'
                                                                    ?   selectedDataObjectForSideDrawerImageAnnotation
                                                                        .imageGroupAnnotations
                                                                        ?.map(imageGroupAnnotation=>{
                                                                            // @ts-ignore
                                                                            delete imageGroupAnnotation.imageAnnotations;
                                                                            return imageGroupAnnotation;
                                                                        })
                                                                    :   []
                                                            }
                                                            isDynamicOptionsEnable
                                                            scenario="sideDrawer"
                                                            isDrawerOpen={props.drawerState}
                                                            fullscreenButton={
                                                                <ImageAnnotationFullScreenButton
                                                                    onClick={() => {
                                                                        setIsImageKeyPointsEditDialogOpen(true);
                                                                    }}
                                                                /> 
                                                            }
                                                        />
                                                    </div>
                                                    
                                                </div>

                                        }
                    
                        {
                            (
                                selectedDataObject?.model?.type !== 'imageAnnotation' &&
                                selectedDataObject?.model?.type !== 'imageAnnotationGroup'
                            ) &&
                            (
                                <>
                                {
                                    selectedDataObject?.model?.type === 'audio' &&
                                    <div
                                        style={{position: "sticky",
                                        top: "240px",
                                        zIndex: 1,
                                        backgroundColor: "rgb(231, 237, 243)"
                                        }}
                                    >&nbsp;
                                    <div>&nbsp;</div><div>&nbsp;</div></div>
                                }
                                {
                                    selectedDataObject?.model?.type !== "image" &&
                                    <ActionsSection 
                                        resourcesWhichAreBeingAddedToDataSetCollectionInDB={props.resourcesWhichAreBeingAddedToDataSetCollectionInDB}
                                        scenario={undefined}
                                    />
                                }
                                {
                                    selectedDataObject?.model?.type === "image" &&
                                    <div
                                        style={{
                                            display: 'flex',
                                            justifyContent: 'space-evenly',
                                            position: "sticky",
                                            top: "300px",
                                            backgroundColor: 'inherit',
                                            zIndex: 1,     
                                            paddingTop:'10px',   
                                            paddingLeft:'2px'                                    
                                        }}
                                    >
                                        <ActionsSection 
                                            resourcesWhichAreBeingAddedToDataSetCollectionInDB={props.resourcesWhichAreBeingAddedToDataSetCollectionInDB}
                                            scenario={'side-drawer-image'}
                                        />
                                        <ImageAnnotationFullScreenButton 
                                            onClick={() => {
                                                setIsImageKeyPointsEditDialogOpen(true);
                                            }}                                            
                                            iconButtonStyles={{
                                                alignSelf: 'auto'
                                            }}
                                        />
                                    </div>
                                }
                                </>
                            )
                        }
                        
                        
                    <div 
                        className={`
                        ${classes.infoBlock}
                         `}
                    >
                        
                        {/* {
                            selectedDataObject.model.type === 'imageAnnotation' &&
                            <div className={classes.info} style={{marginTop: "8px", flexDirection: 'column'}}>
                                <p className={classes.infoLabel} style={{marginBottom: '6px'}}>Coordinates:</p>
                                <div className={classes.infoValue}>
                                    <CoordinatesList 
                                        canvasId={sideDrawerCanvasId} 
                                        scenario={"sideDrawer"} 
                                        imageCoordinatesState={imageCoordinatesState} 
                                        setImageCoordinatesState={setImageCoordinatesState} 
                                        imageDifference={imageDifference} 
                                        circleRadius={circleRadius} 
                                        shouldNotAllowEditing={selectedDataObject.status==='approved' ? true : false}
                                    />
                                    {
                                        selectedDataObject.status !== 'approved' &&
                                        <div style={{ display: "flex", marginTop: "7px", marginBottom: "7px" }}>
                                            <Button
                                            size="small"
                                            color="primary"
                                            variant="contained"
                                            style={{
                                                marginRight: "5px",
                                                width: "50%",
                                            }}
                                            onClick={async () => {
                                                await props.updateResourceImageKeyPointsInDB({
                                                    resourceId: selectedDataObject?._id.toString() || "",
                                                    imageAnnotations: imageCoordinatesState.value,
                                                });
                                                setImageCoordinatesState((oldState) => {
                                                return {
                                                    ...oldState,
                                                    isDirty: false,
                                                    isTouched: false,
                                                };
                                                });
                                            }}
                                            disabled={
                                                selectedDataObject?.areImageKeyPointsBeingUpdatedInUI ||
                                                !imageCoordinatesState.isDirty
                                                ? true
                                                : false
                                            }
                                            >
                                            Save
                                            </Button>
                                            <div></div>
                                            <Button
                                            size="small"
                                            variant="contained"
                                            style={{
                                                backgroundColor: "white",
                                                width: "50%",
                                            }}
                                            onClick={() => {
                                                handleClearButtonClick();
                                            }}
                                            // disabled={
                                            //     selectedDataObject?.areImageKeyPointsBeingUpdatedInUI ||
                                            //     (imageCoordinatesState.value.length === 0 &&
                                            //         selectedDataObject?.imageKeyPoints?.length === 0)
                                            //     ? true
                                            //     : false
                                            // }
                                            >
                                            Clear
                                            </Button>
                                        </div>
                                    }
                                </div>
                            </div>
                        } */}
                        

                        <div className={classes.info} style={{marginTop: "8px"}}>
                            <p className={classes.infoLabel}><ResourceNameLabel modelType={selectedDataObject.model.type} />:</p>
                            <p className={classes.infoValue}>
                            <Tooltip title={selectedDataObject?.filename}>
                                <span>{showName(selectedDataObject)}</span>
                            </Tooltip>
                            </p>
                        </div>
                        <div className={classes.info} style={{marginTop: "8px"}}>
                        <p className={classes.infoLabel} style={{maxWidth: "40px"}}>Data Boost:</p>
                        {/* <Dropdown isOpen={dropdownOpen} toggle={toggle} direction={'down'}>
                        <DropdownToggle caret>{dataBoost}</DropdownToggle>
                        <DropdownMenu style={{height: '200px', overflow:"auto"}}>
                             {
                                duplicateDropdownData.map((ele) => (
                                    <DropdownItem onClick={() => { onDataBoostChange(ele)
                                        }}>{ele}</DropdownItem>
                                ))
                             }
                        </DropdownMenu>
                        </Dropdown> */}
                         <p className={classes.infoValue} style={{width: '59%', display: "flex", alignItems: "end"}}> 
                         <div style={{marginBottom: "8px", marginRight: "4px"}}>x</div>
                         <Autocomplete
                          onChange={(event: any, newValue: string | null) => {
                            onDataBoostChange(newValue?.toString()  || '1');
                          }}
                          disableClearable
                            value={dataBoost.toString()}
                            // disablePortal
                            id="combo-box-demo"
                            options={duplicateDropdownData}
                            renderInput={(params: any) => <TextField {...params} />}
                            />
                         </p>
                        </div>

                        <div className={classes.info} style= { {marginTop: "8px"}}>
                            <p className={classes.infoLabel}>Tag:</p>
                            <p className={classes.infoValue} style={{width: '59%'}}>
                                <TagEditText
                                    onEditTextClick={()=>{
                                        props.setShouldBlockDataTableKeyboardEvents(true);
                                    }}
                                    onEditTextBlurEvent={()=>{
                                        props.setShouldBlockDataTableKeyboardEvents(false);
                                    }}
                                    tooltip={selectedDataObject?.tag}
                                    defaultValue={selectedDataObject?.tag}
                                    resource={selectedDataObject}
                                    editTextComponentProps={{
                                        readonly: getSelectedSubTab() === 'approved'
                                            ? true : false
                                        }}
                                    editTextComponentAdditionalCSSClassNames={`${classes.editText}`}
                                />
                            </p> 
                        </div>
                        <div className={classes.info} style= { {marginTop: "8px"}}>
                            <p className={classes.infoLabel}>Label:</p>
                            <p className={classes.infoValue} style={{width: '59%'}}> 
                            {/* {selectedDataObject?.prediction === 'predicted' ? selectedDataObject?.label : selectedDataObject?.prediction+" -> "+selectedDataObject?.label} */}
                            <LabelEditText
                                    readonly={
                                        // getSelectedSubTab() === 'approved'
                                        false
                                        ? true : false
                                    }
                                    resource={selectedDataObject}
                                    editTextComponentAdditionalCSSClassNames={classes.editText}                                    
                                    onEditTextBlurEvent={()=>props.setShouldBlockDataTableKeyboardEvents(false)}
                                    onEditTextFocus={()=>props.setShouldBlockDataTableKeyboardEvents(true)}
                                    placeWhereComponentBeingUsed="data-side-drawer"
                                    labelsAvailable={labelOptions()}
                                />                            
                            </p>
                        </div>
                        <div className={classes.info} style= { {marginTop: "8px"}}>
                            <p className={classes.infoLabel} style= { {width: "80px"}} >Confidence Score:</p>
                            <p className={classes.infoValue}>{selectedDataObject?.confidence_score}%
                            </p>
                        </div>
                        <div className={classes.info} style= { {marginTop: "8px"}}>
                            <p className={classes.infoLabel}>Created At:</p>
                            <p className={classes.infoValue}>
                                {/* {moment(selectedData?.created_at).format('DD-MM-YYYY hh:mm:ss')} */}
                                {moment(selectedDataObject?.created_at).format('DD-MM-YYYY hh:mm:ss A')}
                            </p>
                        </div>
                        <div className={classes.info} style= { {marginTop: "8px"}}>
                            <p className={classes.infoLabel}>Last Modified:</p>
                            <p className={classes.infoValue}>
                                {/* {moment(selectedData?.created_at).format('DD-MM-YYYY hh:mm:ss')} */}
                                {moment(selectedDataObject?.statusLastModifiedAt).format('DD-MM-YYYY hh:mm:ss A')}
                            </p>
                        </div>
                        <div className={classes.info} style= { {marginTop: "8px"}}>
                            <p className={classes.infoLabel} >CSV:</p>
                            <p className={cvsClasses}>
                                {/* {selectedData?.csv} */}
                                <span>
                                    {
                                    selectedDataObject?.csv?.length > csvInitialMaxLengthLimit
                                    ? csvExpandedFormShowing
                                            ? (
                                                <><ShowLessLink /> {" "} <span dangerouslySetInnerHTML={{__html: selectedDataObject?.csv}} /> {"  "} </>
                                            )
                                            : <span dangerouslySetInnerHTML={{__html: selectedDataObject?.csv?.substring(0, csvInitialMaxLengthLimit)+"..."}} />
                                    : <span dangerouslySetInnerHTML={{__html: selectedDataObject?.csv}} />
                                    }
                                </span>
                                {
                                    selectedDataObject?.csv?.length > csvInitialMaxLengthLimit
                                    ? csvExpandedFormShowing
                                        ?   (
                                            <ShowLessLink />
                                        )
                                        :   (
                                            <Link 
                                                style={{cursor: 'pointer', color: "#008D9C"}}
                                                onClick={()=>{
                                                    setCsvExpandedFormShowing(true)
                                                }}
                                            >
                                                show more
                                            </Link>
                                        )
                                    : null
                                }
                            </p>
                        </div>
                        <div className={classes.info} style= { {marginTop: "8px"}}>
                            <p className={classes.infoLabel}>Data-Set Collections:</p>
                            <p className={classes.infoValue} style={{whiteSpace: 'normal'}}>
                                {
                                    selectedDataObject.dataSetCollections?.map(dataSetCollection=>{
                                        return (
                                            <Chip
                                            key={dataSetCollection._id}
                                            classes={{ root: classes.chip }}
                                            label={dataSetCollection.name}
                                          />
                                        )
                                    })
                                }
                            </p>
                        </div>

                        <div className={classes.info} style= { {marginTop: "8px"}}>
                            <p className={classes.infoLabel}>Change Log:</p>
                            <p className={classes.infoValue} style={{whiteSpace: 'normal'}}>
                            
                                <div>
                                  { changesMade !== undefined && selectedDataObject.changesMade.length > 0 ? changesMadeExpandedFormShowing 
                                  ? <> 
                                  <Link 
                                                style={{cursor: 'pointer', color: "#008D9C"}}
                                                onClick={()=>{
                                                    setchangesMadeExpandedFormShowing(false)
                                                }}
                                            >
                                                show less
                                    </Link>
                                  {/* <ShowLessLink onClick={()=>setchangesMadeExpandedFormShowing(false)} /> */}
                                  { changesMade !== undefined &&(
                                      [...selectedDataObject.changesMade]?.reverse()?.map(changeMade=>{
                                          return (
                                              <div style={{paddingBottom:"10px"}}>
                                                  <div>
                                                      {changeMade?.change} by {changeMade?.initiatedBy?.emailId} on {moment(changeMade?.created_at).format(`hh:mm A DD-MMM-YYYY`)}
                                                  </div>
                                              </div>
                                          )
                                      }))
                                  }
                                  <Link 
                                                style={{cursor: 'pointer', color: "#008D9C"}}
                                                onClick={()=>{
                                                    setchangesMadeExpandedFormShowing(false)
                                                }}
                                            >
                                                show less
                                    </Link>
                                  {/* <ShowLessLink onClick={()=>setchangesMadeExpandedFormShowing(false)} /> */}
                                   </>
                                : <div style={{display: 'flex'}}> <p style={{padding:"0px 5px"}}>{changesMade[0].change.substring(0,changesMadeInitialMaxLengthLimit)}...</p>
                                 <Link 
                                                style={{cursor: 'pointer', color: "#008D9C"}}
                                                onClick={()=>{
                                                    setchangesMadeExpandedFormShowing(true)
                                                }}
                                            >
                                                show more
                                    </Link>
                                 {/* <ShowMoreLink onClick={()=>setchangesMadeExpandedFormShowing(true)} /> */}
                                 </div> : null}
                                </div>
                                {/* {
                                    [...selectedDataObject.changesMade]?.reverse()?.map(changeMade=>{
                                        return (
                                            <div
                                                style={{
                                                    marginTop: "5px",
                                                    marginBottom: "5px"
                                                }}
                                            >
                                                <div>
                                                    {changeMade.change} by {changeMade.initiatedBy?.emailId} on {moment(changeMade.created_at).format(`hh:mm A DD-MMM-YYYY`)}
                                                </div>
                                            </div>
                                        )
                                    })
                                } */}
                            </p>
                        </div>
                        <div className={classes.info} style={{marginTop: "8px", flexDirection: "column"}}>
                            <p className={classes.infoLabel} style={{marginBottom: "10px"}}>Remarks:</p>
                            <p className={classes.infoValue}>
                                <textarea 
                                    style={{width: "100%", height: "83px", marginBottom: "10px", fontSize: '14px', borderRadius: "5px"}}
                                    value={resourceRemarksTextState.value}
                                    onFocus={()=>props.setShouldBlockDataTableKeyboardEvents(true)}
                                    onChange={(event)=>{
                                        setResourceRemarksTextState(oldState=>({
                                            ...oldState,
                                            isDirty: true,
                                            value: event.target.value
                                        }))
                                    }}
                                    onBlur={()=>{
                                        setResourceRemarksTextState(oldState=>({
                                            ...oldState,
                                            isTouched: true,
                                        }))
                                        props.setShouldBlockDataTableKeyboardEvents(false)
                                    }}
                                    disabled={
                                        selectedDataObject.isResourceRemarksBeingUpdatedInDB
                                        ? true
                                        : false
                                    }
                                />
                            </p>
                            <div style={{
                                display: "flex",
                                flexDirection: "row",
                                gap: 10
                            }}>
                            <Button color="primary" variant="contained"
                                onClick={()=>{
                                    props.updateResourceRemarks({
                                        resourceId: selected || "",
                                        resourceRemarksText: resourceRemarksTextState.value
                                    })
                                    setResourceRemarksTextState(oldState=>{return {
                                        ...oldState,
                                        isDirty: false,
                                        isTouched: false
                                    }})
                                }}
                                disabled={
                                    selectedDataObject.isResourceRemarksBeingUpdatedInDB || !resourceRemarksTextState.isDirty ? true : false
                                }
                            >
                                Save Remarks
                            </Button>
                            </div>
                        </div>

                        <Box m={1.5} />

                        {/* <div className={classes.info}
                            style={{
                                position: "sticky",
                                bottom: "0px",
                                backgroundColor: SideDrawerBackgroundColor
                            }}
                        >
                            <p className={classes.infoLabel}>Actions:</p>
                            <div className={classes.actions}>
                                {
                                    selectedDataObject?.status === 'backlog' && (approvedWaitingList.indexOf(selectedDataObject._id) >= 0 && !disableSaveDelete ?
                                        <CircularProgress classes={{ root: classes.progress }} size={48} /> :
                                        <Tooltip title={tooltipTextForApprovedIcon}>
                                            <IconButton aria-label="approve" color="primary"
                                                disabled={
                                                    isUpdating || isFetching || deletedWaitingList.indexOf(selectedDataObject._id) >= 0 || disableSaveDelete ||
                                                    props.resourcesWhichAreBeingAddedToDataSetCollectionInDB.indexOf(selectedDataObject._id.toString()) !== -1
                                                } 
                                                onClick={() => update([selectedDataObject._id], 'approved')}><Check /></IconButton>
                                        </Tooltip>
                                    )
                                }
                                {
                                    (selectedDataObject?.status === 'deleted' || selectedDataObject?.status === 'approved') && (approvedWaitingList.indexOf(selectedDataObject._id) >= 0 && !disableSaveDelete ?
                                        <CircularProgress classes={{ root: classes.progress }} size={48} /> :
                                        <Tooltip title={tooltipTextForPendingReviewIcon}>
                                            <IconButton aria-label="approve" color="primary" 
                                                disabled={
                                                    isUpdating || isFetching || deletedWaitingList.indexOf(selectedDataObject._id) >= 0 || disableSaveDelete ||
                                                    props.resourcesWhichAreBeingAddedToDataSetCollectionInDB.indexOf(selectedDataObject._id.toString()) !== -1
                                                } 
                                                onClick={() => update([selectedDataObject._id], 'backlog')}><Restore /></IconButton>
                                        </Tooltip>
                                    )
                                }
                                {
                                    selectedDataObject?.status === 'approved' &&
                                        (
                                            props.resourcesWhichAreBeingAddedToDataSetCollectionInDB.indexOf(selectedDataObject._id) === -1
                                            ?   (
                                                <Tooltip title={tooltipTextForEditResourceDataSetCollectionButton}>
                                                    <span>
                                                        <AddResourceToDataSetCollectionButton
                                                            onClick={()=>{
                                                                setIsAddResourcesToDataSetCollectionPopupShowing(true)
                                                            }}
                                                        />  
                                                    </span>
                                                </Tooltip>                                                
                                            )
                                            :    <CircularProgress classes={{ root: classes.progress }} size={48} />
                                        )
                                }
                                {
                                    (deletedWaitingList.indexOf(selectedDataObject._id) >= 0 ?
                                        <CircularProgress classes={{ root: clsx(classes.progress, classes.delete) }} size={48} /> :
                                        <Tooltip
                                            title={
                                                selectedDataObject?.status === 'deleted'
                                                ? tooltipTextForDeleteIconWhenDeletingResource
                                                : tooltipTextForDeleteIconWhenMovingToDeleteTab
                                            }
                                        >
                                            <IconButton aria-label="delete" classes={{ root: classes.delete }} 
                                                disabled={
                                                    isUpdating || isFetching || approvedWaitingList.indexOf(selectedDataObject._id) >= 0 || disableSaveDelete ||
                                                    props.resourcesWhichAreBeingAddedToDataSetCollectionInDB.indexOf(selectedDataObject._id.toString()) !== -1
                                                } 
                                                onClick={() => selectedDataObject?._id && update([selectedDataObject._id], 'deleted')}><Delete />
                                            </IconButton>
                                        </Tooltip>
                                    )
                                }
                                
                            </div>
                        </div> */}
                    </div>

                </Fragment>
            }
            {/* {selected.status === 'backlog' &&
                        <FormControl classes={{ root: classes.labelBlock }}>
                            <InputLabel id="label">Label</InputLabel>
                            <NativeSelect
                                id="label"
                                value={label}
                                onChange={handleChange}
                                input={<BootstrapInput />}
                            >
                                <option value="0">Real</option>
                                <option value="1">Spoof</option>
                                <option value="2">Blur</option>
                            </NativeSelect>
                        </FormControl>
                    } */}
            <div style={{position: "fixed", bottom: "100px"}}>
                {showLeftArrow && <div className={clsx(classes.leftIndicator, classes.indicators)}>
                    <IconButton aria-label="previous" color="primary" disabled={[isUpdating ,isFetching].includes(true)} onClick={() => onSwitch(-1)}><KeyboardArrowLeft />
                    </IconButton>
                </div>}
                {showRightArrow && <div className={clsx(classes.rightIndicator, classes.indicators)}>
                    <IconButton aria-label="previous" color="primary" disabled={[isUpdating ,isFetching].includes(true)} onClick={() => onSwitch(1)}><KeyboardArrowRight />
                    </IconButton>
                </div>
                }   
            </div>
               {/* div for alert
            <div className={classes.alertDiv}>
                {alert==="success" && <Alert severity="success" onClose={() => {setAlert("")}}>{alertMessage}</Alert>}
                {alert==="failed" && <Alert severity="success" onClose={()=>{setAlert("")}} >{alertMessage}</Alert>}
            </div>
             */}
        </Fragment>
    )
}

function ResourceNameLabel(props: {modelType: IModelType}) {
    const modelType: IModelType = props.modelType;

    if (modelType === 'audio') {
        return <>Audio name</>;
    } else if (modelType === 'video') {
        return <>Video name</>;
    } else if (
        modelType === 'image' ||
        modelType === 'imageAnnotation' ||
        modelType === 'imageAnnotationGroup'
    ) {
        return <>Image name</>;
    }
    return <></>;
}

function VideoSection(props: {src: string}) {
    return (
        // <div id="videoSection">
            <video style={{marginLeft: '10px', width: '96%', height: '300px', marginTop: '15px'}} muted autoPlay src={props.src} controls />
        // </div>
    );
}

const mapProps = (state: IReduxState) => {
    return {
        selected: state.data.selected,
        totalCount: state.data.data.totalCount,
        isUpdating: state.collection.isUpdating,
        isFetching: state.data.isFetching,
        approvedWaitingList: state.data.approvedWaitingList,
        deletedWaitingList: state.data.deletedWaitingList,
        approvalWaitingList: state.data.approvalWaitingList,
        rejectedWaitingList: state.data.rejectedWaitingList,
        component: state.optionsDrawer.component,
        resources: state.data.data.resources,
        selectedItems: state.data.selectedItems,
        resourcesWhichAreBeingAddedToDataSetCollectionInDB: state.data.resourcesWhoseDataSetCollectionsAreBeingUpdatedInDB,
        dataSetCollectionsAvailableForFilter: state.data.dataSetCollectionsForFilter,
        isDataSetCollectionsBeingFetchedForFilter: state.data.dataSetCollectionsBeingFetchedForFilter,
        drawerState:state.optionsDrawer.isOpen
    };
}

const connector = connect(mapProps, {
    updateData,
    setSelectedData, getData, setSelectedItems, setResourcesTotalCount,
    setResources,
    addResourcesToDataSetCollection,
    setDataSetCollectionsInResource,
    addDataSetCollectionInDataSetCollectionsFilter,
    setShouldBlockDataTableKeyboardEvents,
    updateResourceImageGroupAnnotationsInDB,
    updateResourceImageAnnotationsInDB,
    updateResourceRemarks,
    updateResourceWhichAreBeingUpdatedInDB,
    updateResourceInUI
});

export type TPropsFromRedux = ConnectedProps<typeof connector>

export default connector(DataView);

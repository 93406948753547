/**
 * This file contains helper functions for regex
 */

export function convertStringtoStartsWithExpressionInRegex(
  value: string
): string {
  value = ignoreRegexSpecialCharactersInText(value)
  return `/^${value}/`;
}

export function convertStringtoContainsExpressionInRegex(
  value: string
): string {
  value = ignoreRegexSpecialCharactersInText(value)
  return `/${value}/`;
}

export function convertStringtoEqualsExpressionInRegex(value: string): string {
  value = ignoreRegexSpecialCharactersInText(value);
  return `/^${value}$/`;
}

const specialCharactersUsedInRegexExpression = ["(", ")", "$", "^", "*", "{", "}", "[", "]", "|", ".", "+", "?", "\\"];

export function ignoreRegexSpecialCharactersInText(text: string): string {
  if (!text) {
    return text;
  }
  let textWithSpecialCharacterIgnored = ""
  for (const character of text) {
    if (specialCharactersUsedInRegexExpression.includes(character)) {
      textWithSpecialCharacterIgnored = textWithSpecialCharacterIgnored + "\\" + character;
    } else {
      textWithSpecialCharacterIgnored += character;
    }
  }
  return textWithSpecialCharacterIgnored;
}

export function convertStringtoEndsWithExpressionInRegex(
  value: string
): string {
  value = ignoreRegexSpecialCharactersInText(value);
  return `/${value}$/`;
}

import { useContext, useEffect, useState } from "react";
import TestDriveProgressComponent from "./TestDriveProgressBar"
import { Box, Button } from "@material-ui/core";
import { TestDriveStage2LiveStatusComponet } from "./TestDriveStage2LiveStatusComponent";
import { TestDriveResultsComponnet } from "./TestDriveResultsComponent";
import { DataSelector, useDataSelectorStates, RlefUiLibraryThemeProvider, RlefUiLibraryStylesProvider } from "rlef-ui-library";
import ChatContext from "../../chat/contexts/chat.context";
import testDriveService from "../../projects/features/aiMarketPlace/services/testDriveService";
import { IGetModelCollectionResponse } from "../../projects/features/aiMarketPlace/aiMarketPlaceInterfaces/interfaces";

interface IProps {
    trainingPodId: string;
    modelSummaryPageData?:IGetModelCollectionResponse | {},
}
export const TestDrive = (props: IProps) => {
    const {modelSummaryPageData}=props
    const { currentScreenState } = useDataSelectorStates();
    const chatContext = useContext(ChatContext);

    const [dataSelectorPageTitle, setDataSelectorPageTitle] = useState<string>("Add data to test drive")
    const {isTestDriveCompleted} = chatContext
    const createVM = async () => {
        try {
            const apiResponse = await testDriveService.createVM({
                podId: props.trainingPodId,
                podType: 'TP',
                isVmDeleted: 'False',
                initiateTheVm: true
            })

            if(apiResponse.status === 200){
                chatContext.setTestDriveVm(apiResponse.data?.updated_document || null);
            }
        }
        catch (error) {
            //@ts-ignore
            if (error?.response?.status === 409){
                //@ts-ignore
                chatContext.setTestDriveVm(error.response?.data?.tryOutVm || null);
                return;
            }
            console.error("Error creating VM for test-drive:", error);
        }
    }

    // initiate the VM for test-drive in background
    useEffect(() => {
        createVM();
        chatContext.setShouldShowTestDriveResultScreen(prev=>{
            if(prev) chatContext.updateTestDriveStage(2, 100)
            else chatContext.updateTestDriveStage(0, 50)
            return false;            
        })
    }, [])

    
    const renderActiveComponent = () => {
        const activeStage = chatContext.testDriveStage.find((stage) => stage.isActive);
        if (!activeStage) return null; // If no active stage, return nothing
    
        switch (activeStage.stage) {
          case "Select Data":
                return <div style={{ flexGrow: 1, height: '100%', padding: '1rem 0' }}>
                    <RlefUiLibraryStylesProvider>
                    <RlefUiLibraryThemeProvider>
                    <DataSelector
                        renderDefaultActionsFooter={false}
                        pageTitle={dataSelectorPageTitle}
                        selectedRlefResources={chatContext.selectedRlefResourcesForTestDrive}
                        setSelectedRlefResources={chatContext.setSelectedRlefResourcesForTestDrive}
                    />
                    </RlefUiLibraryThemeProvider>
                    </RlefUiLibraryStylesProvider>
                </div>
          case "Test Drive":
            return <TestDriveStage2LiveStatusComponet />;
          case "Results":
            return <TestDriveResultsComponnet modelSummaryPageData={modelSummaryPageData}/>;
          default:
            return null;
        }
      };
    useEffect(() => {
        switch (currentScreenState){
            case 'dataSelectOptionsScreen':
            case 'selectedDataListScreen':
                setDataSelectorPageTitle("Add data to test drive");
                break;
            case 'projectAndTaskSelectionScreen':
                setDataSelectorPageTitle("Select the project & task");
                break;
            case 'rlefDataSetCollectionsScreen':
            case 'rlefDataPointsInSelectedDataSetCollectionScreen':
                setDataSelectorPageTitle("Select from collections");
                break;
            default:
                break;
        }
    }, [currentScreenState])

    useEffect(()=>{
        return ()=>chatContext.updateTestDriveStage(0, 50)
    },[])

    return (
        <Box style={{display: 'flex', flexDirection: 'column', height: '100%', padding: 0}} >
            <TestDriveProgressComponent progressState={chatContext.testDriveStage} />
            {/* <Button onClick={()=>chatContext.updateTestDriveStage(2,10)}>Go To test Drive</Button> */}
            <Box display="flex" flexDirection="column" flexGrow="1">{renderActiveComponent()}</Box>
        </Box>
    )
}
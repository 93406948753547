import { useCallback } from "react";
import { useHistory } from "react-router";
import UserDBService from "../services/UserDBService";
import { updateBreadPathcrumbs } from "../store/actions";
import { useDispatch, useSelector } from "react-redux";
import { IReduxState } from "../common";

type QueryParamsJson = {
  [key: string]: string;
};

export default function useSetUrlSearchParamsOfCurrentRoute() {
  const history = useHistory();

  const historyReplace = history.replace;
  const historyPush = history.push;
  const dispatch = useDispatch();
  const breadcrumbs = useSelector((state: IReduxState) => state.core.breadcrumbs);

  /**
   * @param queryParams example:- {name: "Joseph",color: "blue"}
   */
  const setUrlSearchParamsOfCurrentRoute = useCallback(
    (
      queryParams: QueryParamsJson,
      historySetStyle: "historyPush" | "historyReplace"
    ) => {
      const urlSearchParams = new URLSearchParams(window.location.search || "");

      /**
       * Setting urlSearchParams
       */
      for (const queryParamKey in queryParams) {
        if (Object.prototype.hasOwnProperty.call(queryParams, queryParamKey)) {
          const queryParamValue = queryParams[queryParamKey];
          localStorage.setItem('viewPreferences', queryParams[queryParamKey]);
          UserDBService.updateViewPreferencesInDB({ modelViewPreferences: queryParams[queryParamKey] as "model" | "modelGroup" });
          /**
           * update breadCrumbs
           */
          const previousModelViewType = breadcrumbs[1].path.split("view=")?.[1];
          if (previousModelViewType) {
            const newPath = breadcrumbs[1].path.split("view=")?.[0] + `view=${queryParams[queryParamKey]}`;
            dispatch(updateBreadPathcrumbs({ index: 1, path: newPath }));
          }
          urlSearchParams.set(queryParamKey, queryParamValue);
        }
      }

      let searchValueToSet = `?${urlSearchParams.toString()}`;

      if (historySetStyle === "historyPush") {
        historyPush({
          search: searchValueToSet,
        });
      } else if (historySetStyle === "historyReplace") {
        historyReplace({
          search: searchValueToSet,
        });
      }
    },
    [historyPush, historyReplace]
  );

  return setUrlSearchParamsOfCurrentRoute;
}

import css from "./ViewAndTrimAudioResource.module.css";
import React, { useCallback, useEffect, useMemo, useRef, useState } from 'react'
import { connect, ConnectedProps } from 'react-redux';
import { IData, IReduxState, ResourceImageAnnotation, ResourceStatus, TrimmedAudio } from '../../../../../../../../../../../common';
import {
    Dialog,
    IconButton,
    Theme,
    Button,
    CircularProgress,
    DialogTitle,
    Box,
    TextField,
    Tooltip,
    FormControl,
    FormGroup,
    FormControlLabel,
    Switch,
    Menu,
    MenuItem,
    Popover,
    PaperProps,
    Paper,
    Slider,
    DialogContent,
    Divider,
    Typography,
    Chip,
    useTheme,
  } from "@material-ui/core";
import { makeStyles } from "@material-ui/styles";
import CloseIcon from '@material-ui/icons/Close';
import { Cancel } from "@material-ui/icons";
import NavigateBeforeIcon from '@material-ui/icons/NavigateBefore';
import NavigateNextIcon from '@material-ui/icons/NavigateNext';
import { ShowResourceDetails } from './ImageKeyPointsEditDialog';
import { InputFieldState } from '../../../../../../../../../../../common/constants/interfaces/inputFieldState';
import { updateResourceInUI, updateResourceRemarks, updateResourcePromptOrAnswer } from '../../../../../../../../../../../store/actions';
import { AudioTrimUI } from './AudioTrimUI/AudioTrimUI';
import { IModelType, ModelAnnotationLabelAvailable } from '../../../../../../../../../../../common/constants/interfaces/models';
// import AddModelResourceToCopilotDialog from './AddModelResourceToCopilotDialog';
import { ICoPilotResource, ITrimmedAudioAnnotation } from '../../../../../../../../../../../common/constants/interfaces/coPilotResource';
import { updateCopilotResourceTrimmedTextsInUI } from '../../../../../../../../../../../store/actions/coPilotDetailPageActions';
import TextTrimUI from './TextTrimUI/textTrimUI';
import ImageAspectRatioScaler from './imageAspectRatioScalerWithAlignment.component';
import EditIcon from '@material-ui/icons/Edit';
import SaveIcon from '@material-ui/icons/Save';
import { CopilotService, DataService, ModelService } from '../../../../../../../../../../../services';
import { separateFileNameFromExtension } from '../../../../../../../../../../../services/fileNameHelperService';
import axios from 'axios';
import InfoOutlinedIcon from '@material-ui/icons/InfoOutlined';
import { AiAssistantMsgWithInfoIcon } from './VideoAnnotationDialogBox.component';
import { ImageRelatedModelReferenceData } from '../../../../../../../../../../../common/constants/interfaces/imageRelatedModelReferenceData';
import ReferenceImageDataLink from './referenceImageDataLink/referenceImageDataLink';
import Draggable from 'react-draggable';
import FullscreenIcon from '@material-ui/icons/Fullscreen';
import ImageService from '../../../../../../../../../../../services/ImageService';
import { SegmentationAnalyticsTwoDimensionalImage } from '../../../../../../../../../../../assets/components/react-annotation-tool/src/apps/segmentation-analytics-two-dimensional-image';
import { SpectrogramAudioTrimUI } from '../../../../../../coPilot/features/SpectrogramAudioItemCard';
import useImageOnLoad from '../../../../../../../../../../../hooks/useImageOnLoad';
import { getImageScaleFactorForImageWidth } from '../../../../../../../../../../../services/imageHelperService';
import AnnotationsRegionList from '../../../../../../../../../../../assets/components/react-annotation-tool/src/apps/spectrogram-audio-two-dimensional-image/components/TwoDimensionalImage/AnnotationsRegionList';
import { setResourceImageAnnotationsForSendingToAnnotationComponent } from '../../../../../../../../../../../services/imageAnnotationHelperService';
import ViewAsCSVDialog from './ViewAsCSVDialog';
import { TextDiffSection } from '../../../../../../../../TextDiffSection/TextDiffSection';
import { useResourceMetadataDownloadUrlFromResourceId } from "../hooks/useResourceMetadataDownloadUrlFromResourceId";
var ReferenceGroupObjectId = require("bson-objectid")

const useStyles = makeStyles((theme: Theme) => ({
    root: {
        overflow: "auto",
        padding: "20px 0px",
        "&p": {
          padding: "0px",
        },
    },
    body: {
    padding: "10px",
    // width: "95vw",
    // height: "95vh",
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    '@media(min-width:1300px)': {
        width: "95vw",
        height: "85vh",
    },
    '@media(min-width:1000px) and (max-width: 1300px)': {
        width: "92vw",
        height: "85vh",
    },
    '@media(min-width:700px) and (max-width: 1000px)': {
        width: "90vw",
        height: "86vh",
    },
    '@media(max-width: 700px)': {
        width: "85vw",
        height: "86vh",
    },
    },
    imageBody : {
        '@media(min-width:1300px)': {
            width: "1250px",
            height: "auto",
        },
    },
    dialogContainer: {
        overflow: 'visible'
    },
    closeBtn: {
        position: "absolute",
        right: -10,
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        // padding: "2px",
        top: -10,
        backgroundColor: "white",
        borderRadius: "50%",
        // color: "white",
        cursor: "pointer",
        width: "25px",
        height: "25px"
    },
    navigateButton:{
        padding: "0px",
        "&:hover":{
          outline: "none",
        },
        "&:focus":{
          outline: "none",
        },
        "&:active":{
          outline: "none",
        },
    },
    showResource: {
        width: '100%',
        height: '100%',
        '@media(min-width:1300px)': {
           display: "flex",
        },
        '@media(min-width:1050px) and (max-width: 1300px)': {
            display: "flex",
        },
        '@media(min-width:700px) and (max-width: 1050px)': {
            // display: "flex",
        },
        '@media(max-width: 700px)': {
            // display: "flex",
        },
    },
    showImageResource: {
        width : '90%',
        paddingBottom : '20px',
        display : 'flex',
        justifyContent : 'space-between',
    },
    audioTrimSection: {
        // width: '58%',
        padding: "20px 45px 20px 0px",
        '@media(min-width:1300px)': {
            width: "68%"
         },
         '@media(min-width:1050px) and (max-width: 1300px)': {
            width: "63%"
         },
         '@media(min-width:700px) and (max-width: 1050px)': {
             display: "flex",
             justifyContent: "center",
             width: "100%"
         },
         '@media(max-width: 700px)': {
             display: "flex",
             justifyContent: "center",
             width: "100%"
         },
        // width: '60%'
    },
    detailsSection: {
        '@media(min-width:1300px)': {
            width: "32%"
         },
         '@media(min-width:1050px) and (max-width: 1300px)': {
            width: "37%"
         },
         '@media(min-width:700px) and (max-width: 1050px)': {
             display: "flex",
             justifyContent: "center",
             width: "100%",
             height: "65%"
         },
         '@media(max-width: 700px)': {
             display: "flex",
             justifyContent: "center",
             width: "100%",
             height: "65%"
         },
        
    },
    imageTrimSection: {
        // width: '58%',
        padding: "20px 45px 20px 0px",
        width: "58%",
         '@media(min-width:700px) and (max-width: 1050px)': {
             display: "flex",
             justifyContent: "center",
             width: "100%"
         },
         '@media(max-width: 700px)': {
             display: "flex",
             justifyContent: "center",
             width: "100%"
         },
        // width: '60%'
    },
    imageDetailsSection : {
        width: "40%",
        '@media(min-width:1300px)': {
            maxHeight : "460px"
         },
         '@media(min-width:1050px) and (max-width: 1300px)': {
            maxHeight : "460px"
         },
    },
    dialogContainerWidth : {
        maxWidth: "calc(100% - 64px)"
    },
    actoinButtonWidth: {
        '@media(min-width:1300px)': {
            position: 'sticky', top:'0px',
            width: "90%"
         },
         '@media(min-width:1050px) and (max-width: 1300px)': {
            position: 'sticky', top:'0px',
            width: "90%"
         },
         '@media(min-width:700px) and (max-width: 1050px)': {
            position: 'fixed', bottom:'7vh',
            display: "flex",
             justifyContent: "center",
             width: "60%"
         },
         '@media(max-width: 700px)': {
            position: 'fixed', bottom:'7vh',
            display: "flex",
             justifyContent: "center",
             width: "60%"
         },
    },
    resourceDetails:{
        margin: "10px 4px 60px",
        maxHeight: "92%",
        overflow: "auto",
        '@media(min-width:1300px)': {
            // maxHeight: "80%",
         },
         '@media(min-width:1050px) and (max-width: 1300px)': {
            // maxHeight: "80%",
         },
         '@media(min-width:700px) and (max-width: 1050px)': {
            // maxHeight: "60%",
             width: "360px"
         },
         '@media(max-width: 700px)': {
            // maxHeight: "60%",
             width: "360px"
         }
    },
    videoContainer:{
        display: "flex",
        alignContent: "center",
        justifyContent: "flex-start",
        height: "100%",
        width: "100%"
    },
    image: {
        borderWidth: '2px',
        borderColor: '#fff',
        borderStyle: 'solid',
        boxShadow: theme.shadows[1],

    },
    imageAnnotation:{
        marginBottom: "-10px",
        paddingBottom: 0
      },
    textComparisonContainer: {
        width: "45%",
        heigth: "100%",
    },
    textComparisonParentClass: {
        display: "flex",
        flexDirection: "row",
        width: "100%",
        justifyContent: "space-around",
        height: "100%"
    },
    textComparisonColumn: {
        backgroundColor: "#f2f2f2",
        width: "100%",
        height: "90%",
        borderRadius: "5px",
        padding: "20px",
        overflow: "auto"
    },
    textComparisonHeader: {
        color: "#a2a2a2",
        fontWeight: 500
    }
}))
interface IProps extends TPropsFromRedux {
    open: boolean;
    handleClose: () => void;
    resource: IData | ICoPilotResource;
    previousResource?: IData | ICoPilotResource;
    nextResource?: IData | ICoPilotResource;
    onMoveToRight: () => void;
    onMoveToLeft: () => void;
    setShouldBlockDataTableKeyboardEvents : Function;
    onApproveButtonClicked?:()=>void;
    onRejectButtonClicked?:()=>void;
    onDeleteButtonClicked?:()=>void;
    ActionButtonArray?:any;
    // resourceImageList: ImageList[];
    LabelsAvailable ?: string[];
    modelRefernceData: ImageRelatedModelReferenceData[];
    modelAnnotationLabelsAvailable?: ModelAnnotationLabelAvailable[];
    modelMaxAudioTrimAllowedInMilliSeconds?: number;
    isFetchingResources?: boolean;
    isResourceExistBefore: boolean;
    isResourceExistAfter: boolean;
    isCopilot ?: boolean;
    allowUserToSetAnyLabel: boolean;
    showMetaDataForTextComparisonModel?: boolean;
    textOneHeadingForTextComparisonUI?: string;
    textTwoHeadingForTextComparisonUI?: string;
    scenario?: "aiMarketPlaceDataCart" | "aiMarketplaceDataSelection";
  }

function ViewAndTrimAudioResource(props : IProps) {
    const classes = useStyles();
    const theme = useTheme()
    const resourceRemarksText = props?.resource?.remarks?.text || "";
    const [resourceRemarksTextState, setResourceRemarksTextState] = useState<InputFieldState<string>>({value: props?.resource?.remarks?.text || ""});
    const [selectedSubTab, setSelectedSubTab] = useState<ResourceStatus | string>("");
    const imageContainerElementRef = useRef<HTMLDivElement>();
    const [imageMaxWidthAllowed, setImageMaxWidthAllowed] = useState(0);
    const [imageMaxHeightAllowed, setImageMaxHeightAllowed] = useState(0);
    const [imageBorderRadius, setImageBorderRadius] = useState(0);
    const [editMode, setEditMode] = useState<boolean>(false);
    const [savingInDB, setSavingInDB] = useState<boolean>(false);
    const [handelAudioPlayingOnPopupClose, setHandleAudioPlayingOnPopupClose] = useState<boolean>(false);
    // const [addResourceToCopilotDialogState, setAddResourceToCopilotDialogState] = useState({
    //     isOpen: false
    // })

    console.log('popup actioh', props.open);
    const [isViewingReferenceImage, setIsViewingReferenceImage] = useState(false);    

    function PaperComponent(props: PaperProps) {
        return (
          <Draggable handle="#draggable-dialog-title" cancel={'[class*="MuiDialogContent-root"]'}>
            <Paper {...props} />
          </Draggable>
        );
      }

      const ReferenceImageHTML = () => {

        const [anchorEl, setAnchorEl] = React.useState<HTMLElement | null>(null);
    
        const handleOpenFullScreenImage = (file: ImageRelatedModelReferenceData) => {
            setAnchorEl(document.getElementById("reference-image-container"));
            setImageSelectedForFullScreenView(file)
            setIsHideViewFullScreenPopOver(false)
        };
    
        const [imageSelectedForFullScreenView, setImageSelectedForFullScreenView] = useState<ImageRelatedModelReferenceData | undefined>()
        const [imageMagnifyPercentage, setImageMaginfyPercentage] = useState<number>(1)
        const [isHideViewFullScreenPopOver, setIsHideViewFullScreenPopOver] = useState(false)
        const handleCloseFullScreenImage = () => {
          console.log("close popover")
            setIsHideViewFullScreenPopOver(true)
        };
    
        //   const canvasWidth = 0;
        //   const canvasHeight = 0;
        //   const imageWidthToSet = () => {
        //         return window.innerWidth - 150;
        //   }
    
        // const imageHeightToSet = () => {
        // return 0.48 * window.innerHeight;
        // }
    
    
        //   const imageHeightToSetRef = useRef(imageHeightToSet);
        //   const imageWidthToSetRef = useRef(imageWidthToSet);	
    
    
        const open = Boolean(anchorEl);
        const id = open ? 'draggable-dialog-title' : undefined;
        //       useEffect(async () => {
        //         const onloadPromise = new Promise((resolve, reject) => {
        //             const image = new Image();
        //             image.onload = function () {
        //                 resolve(image)
        //             };
        //             image.src = imageSelectedForFullScreenView?.fileUrl || "";
        //         })
    
        //         const image = await onloadPromise;
    
        //         imageWidthToSetRef.current = imageWidthToSet;
        //         imageHeightToSetRef.current = imageHeightToSet;	
    
        //         let differenceBetweenCanvasAndImageToSet = 0;
        //         let canvasHeightToSet = imageHeightToSetRef.current;
        //         let canvasWidthToSet = imageWidthToSetRef.current;	
    
        //         const imageAspectRatio = image.width/ image.height
    
        //         if (image.width > image.height)
        //   {
        //     canvasWidthToSet = imageWidthToSetRef.current;
        //     canvasHeightToSet = ImageService.getImageHeight(
        //       canvasWidthToSet,
        //       imageAspectRatio
        //     )
        //     if (canvasHeightToSet > imageHeightToSetRef.current) {
        //       canvasHeightToSet = imageHeightToSetRef.current;
        //       canvasWidthToSet = ImageService.getImageWidth(
        //         canvasHeightToSet,
        //         imageAspectRatio
        //       );
        //     }
        //   }
        //   else if (image.height > image.width) {
        //     canvasHeightToSet = imageHeightToSetRef.current;
        //     canvasWidthToSet = ImageService.getImageWidthHeightFromURL(
        //       canvasHeightToSet,
        //       imageAspectRatio
        //     );
        //     if (canvasWidthToSet > imageWidthToSetRef.current) {
        //       canvasWidthToSet = imageWidthToSetRef.current;
        //       canvasHeightToSet = ImageService.getImageHeight(
        //         canvasWidthToSet,
        //         imageAspectRatio
        //       )
        //     }
        //   }
    
        //       }, [imageSelectedForFullScreenView])  
        
        
        
          useEffect(() => {
            const handleKeyPress = (event: KeyboardEvent) => {
                if (event.key === 'r' || event.key === 'R') {
    
                  const focusedElement = document.activeElement;
                  if (
                    focusedElement?.tagName?.toLowerCase() === "textarea" ||
                    (focusedElement?.tagName?.toLowerCase() === "input" &&
                    // @ts-ignore
                    (focusedElement?.type?.toLowerCase() === "number" || focusedElement?.type?.toLowerCase() === "text")))
                  {
                    return;
                  }
    
                  // Your code to handle the "R" key press goes here
                  console.log("key pressed")
                  if(imageSelectedForFullScreenView)
                    setIsHideViewFullScreenPopOver(prevState => !prevState)
                else{
                    // window.alert("No Reference Image selected")
                }
                }
              };
    
            window.addEventListener('keydown', handleKeyPress);
            
        
            return () => {
              window.removeEventListener('keydown', handleKeyPress);
            };
          }, [imageSelectedForFullScreenView]);        
    
          const [contentSize, setContentSize] = useState({ width: 'auto', height: 'auto' });
    
          console.log("contentSize", contentSize)
    
          // Function to measure the content size
          const measureContentSize = () => {
            const content = document.querySelector('.content-measure'); // Replace with your own selector
            if (content instanceof HTMLElement) {
              setContentSize({
                width: `${content.offsetWidth + 100}px`,
                height: `${content.offsetHeight+ 150}px`,
              });
            }
          };
        
          useEffect(() => {
            measureContentSize();
          }, [imageMagnifyPercentage]);
        
        return (
            <div
                id="reference-image-container"
                style={{
                    width: "400px",
                    height: "420px",
                    overflow: "auto"
                }}
            >
                {
                    props.modelRefernceData?.map(imageReferenceData => {
                        return (
                            <div
                            style={{ display: "flex",
                            flexDirection: "column",
                            gap: "20px",
                            padding: 10,
                            backgroundColor: imageSelectedForFullScreenView===imageReferenceData? "#dfffff": "white"
                        }}
                            >
                                <div
                                    style={{
                                        display: "flex",
                                        flexDirection: "row",
                                        alignItems: "flex-end",
                                    }}
                                >
                                    <Tooltip title={imageReferenceData.fileName}>
                                        <ImageAspectRatioScaler
                                            imageUrl={imageReferenceData.fileUrl}
                                            maxImageHeightAllowed={290}
                                            maxImageWidthAllowed={290}
                                            doNotShowSkeleton={true}
                                        />
                                    </Tooltip>
                                    <Tooltip title={"select an image an then press R to open and close FullSreen Mode"}>
                                    <Button
                                        size="small"
                                        onClick={(event: React.MouseEvent<HTMLButtonElement>) => {
                                            event.preventDefault()
                                            handleOpenFullScreenImage(imageReferenceData)
                                        }}
                                        style={{ 
                                            display:"flex",
                                            flexDirection:"row",
                                            justifyContent: "center",
                                            alignItems: "center"
                                        }}
                                    >
                                        <FullscreenIcon /> <p style={{fontSize: "16px", margin: "auto"}}> &#123; R &#125;</p>
                                    </Button>
                                    </Tooltip>
                                </div>
                            </div>
                        )
                    })
                }
                    <Dialog
                    style={{
                        display: isHideViewFullScreenPopOver?"none":"flex",
                    }}
                    maxWidth={"lg"}
                    fullWidth={true}
                        id={id}
                        open={open}
                        // anchorEl={anchorEl}
                        // PaperComponent={PaperComponent}
                        onClose={handleCloseFullScreenImage}
                        PaperComponent={PaperComponent}
                        PaperProps={{ style: contentSize }}
                        aria-labelledby="draggable-dialog-title"
                    >
                      <DialogTitle style={{ cursor: 'move' }} id="draggable-dialog-title">
                      <div>{imageSelectedForFullScreenView&&imageSelectedForFullScreenView.fileName}</div>
                      </DialogTitle>
                      <DialogContent>
    
                        <div
                            style={{
                                display: "flex",
                                flexDirection: "column",
                                justifyContent: "center",
                                alignItems: "center"
                            }}
                        >
                            {
                                imageSelectedForFullScreenView &&
                                <>
                                    <div className="content-measure"
                                    >
                                        <img src={imageSelectedForFullScreenView.fileUrl} width={800 * imageMagnifyPercentage > window.innerWidth-100 ?
                                            window.innerWidth-100 : 800 * imageMagnifyPercentage
                                        } alt="reference image" />
                                    </div>
                                </>
                            }
                            <div
                                style={{ width: "200px" }}
                            >
                                <Slider
                                    value={imageMagnifyPercentage}
                                    min={1}
                                    max={1.75}
                                    color="primary"
                                    step={0.25}
                                    marks={[
                                        { value: 1, label: "1x" },
                                        { value: 1.25, label: "1.25x" },
                                        { value: 1.5, label: "1.5x" },
                                        { value: 1.75, label: "1.75x" }
                                    ]}
    
                                    // @ts-ignore
                                    onChange={(e, value: number) => {
                                        setImageMaginfyPercentage(value)
                                    }}
    
                                    valueLabelDisplay="auto"
                                    aria-labelledby="range-slider"
                                />
                            </div>
                        </div>
                        </DialogContent>
                    </Dialog>
            </div>
        )
    } 
    
    const [promptTextFieldData, setPromptTextFieldData] = useState("");
    const [ansOfPromptTextFieldData, setAnsOfPromptTextFieldData] = useState("");

    const [isFetchingResourceTxtFileContents, setIsFetchingResourceTxtFileContents] = useState(false);
    const [isViewAsCSVOpen, setIsViewAsCSVOpen] = useState<boolean>(false)
    const handleCloseCSVDialog = () => {
        setIsViewAsCSVOpen(prevState=>!prevState)
    }
    // @ts-ignore
    const modelType: IModelType | undefined = props?.resource?.model?.type || ""

    const [isFetchingTxtFileContentsOnEditModeClick, setIsFetchingTxtFileContentsOnEditModeClick] = useState(false);

    const resourceFileName = props?.resource?.filename || ""

    const ansOfPrompt = props?.resource?.ansOfPrompt;

    const resourceFileUrl = props?.resource?.resource || "";

    const updateResourceInUI = props.updateResourceInUI;

    const resourceId = props?.resource?._id || ""

    useEffect(() => {

      const executeFunction = async ()=>{
        const isFileExtensionTxt = (() => {
            const fileData = separateFileNameFromExtension(resourceFileName);
            if (fileData.extensionOfFileName === "txt") {
                return true;
            }
            return false;
        })();

        if (isFileExtensionTxt) {
            setIsFetchingResourceTxtFileContents(true);
            const apiresponse = await axios.get(resourceFileUrl);
            updateResourceInUI({
                _id: resourceId,
                ansOfPrompt: typeof apiresponse.data === 'object'? JSON.stringify(apiresponse.data): apiresponse.data
            });
            setIsFetchingResourceTxtFileContents(false);          
        }

      }

      if (
        (modelType === "prompt") && !ansOfPrompt && resourceFileUrl
    ) {
            executeFunction();
    }
    }, [ansOfPrompt, modelType, resourceFileName, resourceFileUrl, resourceId, updateResourceInUI]);

    
    useEffect(() => {
        /**
         * fetching next resource file contents in advance so that user do not have to wait
         */
      const executeFunction = async ()=>{
        const isFileExtensionTxt = (() => {
            const fileData = separateFileNameFromExtension(props?.nextResource?.filename || "");
            if (fileData.extensionOfFileName === "txt") {
                return true;
            }
            return false;
        })();

        if (isFileExtensionTxt) {
            const apiresponse = await axios.get(props?.nextResource?.resource || "");
            updateResourceInUI({
                _id: props?.nextResource?._id,
                ansOfPrompt: typeof apiresponse.data === 'object'? JSON.stringify(apiresponse.data): apiresponse.data
            });
        }

      }

      if (
        (modelType === "prompt") && !props?.nextResource?.ansOfPrompt && props?.nextResource?.resource
    ) {
            executeFunction();
    }
    }, [modelType, props?.nextResource?._id, props?.nextResource?.filename, props?.nextResource?.resource, updateResourceInUI]);

    useEffect(() => {
        /**
         * fetching previous resource file contents in advance so that user do not have to wait
         */        
      const executeFunction = async ()=>{
        const isFileExtensionTxt = (() => {
            const fileData = separateFileNameFromExtension(props?.previousResource?.filename || "");
            if (fileData.extensionOfFileName === "txt") {
                return true;
            }
            return false;
        })();

        if (isFileExtensionTxt) {
            const apiresponse = await axios.get(props?.previousResource?.resource || "");
            updateResourceInUI({
                _id: props?.previousResource?._id,
                ansOfPrompt: typeof apiresponse.data === 'object'? JSON.stringify(apiresponse.data): apiresponse.data
            });
        }

      }

      if (
        (modelType === "prompt") && !props?.previousResource?.ansOfPrompt && props?.previousResource?.resource
    ) {
            executeFunction();
    }
    }, [modelType, props?.previousResource?._id, props?.previousResource?.filename, props?.previousResource?.resource, updateResourceInUI]);

    useEffect(() => {
        setResourceRemarksTextState(prevValue=>({
            value: resourceRemarksText,
            isDirty: false,
            isTouched: false
        }))
    }, [props.resource?._id, resourceRemarksText])

    useEffect(() => {
        const query = new URLSearchParams(window.location.search);
        const status = query?.get('status') || 'backlog';
        setSelectedSubTab(status)
    }, [])
    const onClose = ()=> {
        setHandleAudioPlayingOnPopupClose((prev) => !prev);
        props.handleClose();
    }
    const arrowClicked = (direction: string) => {
        // @ts-ignore
        if (props.resource?.model?.type === "prompt") {
            if (editMode) {
                handlePromptEditCancelClick();
            }
        }
        if(direction === "left"){
          props.onMoveToLeft()
        }
        else{
          props.onMoveToRight()
        }
    }
    const onSaveRemarksClick=() => {
        // props.updateRemarks(resourceRemarksTextState.value)
        props.updateResourceRemarks({
            resourceId: props.resource._id || "",
            resourceRemarksText: resourceRemarksTextState.value
        })
        setResourceRemarksTextState(oldState=>{return {
            ...oldState,
            isDirty: false,
            isTouched: false
        }})                             
    }


    const settingWidthAndHeight = () => {
        const containerElementWidth = (()=>{
            if (imageContainerElementRef.current) {
            return imageContainerElementRef.current.clientWidth;
            }
            return 0;
        })();
    
        setImageMaxWidthAllowed(containerElementWidth)
    
        const containerElementHeight = (()=>{
            console.log(imageContainerElementRef.current?.clientHeight, imageContainerElementRef.current?.clientWidth)
            if (imageContainerElementRef.current) {
            return imageContainerElementRef.current.clientHeight;
            }
            return 0;
        })();
    
        setImageMaxHeightAllowed(containerElementHeight);
    }

    const promptChanged = (text: string) => {
      props.updateResourcePromptOrAnswer({
        resourceId: props.resource._id || "",
        resource: text,
      });
    }

    const ansOfPromptChanged = (text: string) => {
      props.updateResourcePromptOrAnswer({
        resourceId: props.resource._id || "",
        ansOfPrompt: text
      });
    }

    const toggleEditMode = (value: boolean) => {
      setEditMode(value);
    }

    const handlePromptEditClick = async () => {
        const isFileExtensionTxt = (() => {
            const fileData = separateFileNameFromExtension(props.resource.filename);
            if (fileData.extensionOfFileName === "txt") {
                return true;
            }
            return false;
        })();

        setAnsOfPromptTextFieldData("");
        if (isFileExtensionTxt) {
            if (props.resource.ansOfPrompt) {
                setAnsOfPromptTextFieldData(props.resource.ansOfPrompt)
            } else {
                setIsFetchingTxtFileContentsOnEditModeClick(true)
                
                const apiresponse = await axios.get(props.resource.resource)
                props.updateResourceInUI({
                    _id: props.resource._id,
                    ansOfPrompt: apiresponse.data
                })
                setAnsOfPromptTextFieldData(apiresponse.data)
                setIsFetchingTxtFileContentsOnEditModeClick(false)
            }
        }

        setPromptTextFieldData(props.resource.prompt);

        setEditMode(true);
    }

    const handlePromptEditCancelClick = () => {
        setAnsOfPromptTextFieldData("");
        setPromptTextFieldData("");
        setEditMode(false);
    }

    const savePromptAndAnswerInDB = async () => {

        
        setSavingInDB(true);

        let updateDataApiPromise;
        let payloadToUpdateInResourceInUI: any = {
            _id: props.resource._id,
        };        

        if (props.resource.prompt !== promptTextFieldData) {
            const payload = { 
                id: [props.resource._id],
                prompt: promptTextFieldData,
          };
          updateDataApiPromise = DataService.updateData(payload);
          payloadToUpdateInResourceInUI.prompt = promptTextFieldData;
        }

        const isFileExtensionTxt = (() => {
            const fileData = separateFileNameFromExtension(props.resource.filename);
            if (fileData.extensionOfFileName === "txt") {
                return true;
            }
            return false;
        })();

        if (
            isFileExtensionTxt &&
            props.resource.ansOfPrompt !== ansOfPromptTextFieldData
        ) {
            const response = await DataService.updateResourceTxtFileContents(
                props.resource._id,
                ansOfPromptTextFieldData
            )
            payloadToUpdateInResourceInUI.resource = response.fileURL;
            payloadToUpdateInResourceInUI.ansOfPrompt = ansOfPromptTextFieldData;
        }

        await updateDataApiPromise;



        if (Object.keys(payloadToUpdateInResourceInUI).length>1) {
            // means something got updated by user

            props.updateResourceInUI(payloadToUpdateInResourceInUI)
        }

      setSavingInDB(false);
      toggleEditMode(!editMode);
    }
    const [selectedSpectrogramAnnotation, setSelectedSpectrogramAnnotation] = useState<string>("")

    const [spectrogramAudioNoLabelError, setSpectrogramAudioNoLabelError] = useState<string>("");
    const [isSavingSpectrogramImageAnnotation, setIsSavingSpectrogramImageAnnotation] = useState<boolean>(false)
    const MaxAnnotatedImageWidthAllowed = 448;
    const MaxAnnotatedImageHeightAllowed = 370;

    const imageWidthToSetRef = useRef(MaxAnnotatedImageWidthAllowed);

    const imageHeightToSetRef = useRef(MaxAnnotatedImageHeightAllowed);


    const [spectrogramAudioAnnotation, setSpectrogramAudioAnnotation] = useState<ITrimmedAudioAnnotation[]>(props?.resource?.trimmedAudioAnnotation || [])

    const [defaultSpectrogramImageAnnotation, setDefaultSpectrogramImageAnnotation] = useState<ResourceImageAnnotation[]>([]);

    const [defaultSpectrogramAudioAnnotation, setDefaultSpectrogramAudioAnnotation] = useState<TrimmedAudio[]>([]);
    const [isLabelDefined, setIsLabelDefined] = useState(true);

    const isLabelDefinedinAnnotaions = (annotations?: ITrimmedAudioAnnotation[]) => {
        if(!annotations) return true;
        let isCondition = true;
        annotations.forEach((annotation) => {
          if(annotation.label === "" || annotation.label === "undefined") {
            isCondition = false;
          }
        })
        return isCondition;
      }
    const saveSpectrogramAudioAnnotations = async (id: string) => {
        try {
          const isLabel = isLabelDefinedinAnnotaions(spectrogramAudioAnnotation);
          setIsLabelDefined(isLabel);

          if(!isLabel) {
            setSpectrogramAudioNoLabelError("Add Label to all Annotations before Saving");
            setTimeout(() => {
                setSpectrogramAudioNoLabelError("")
            }, 10000)
          }
          else {
            setIsSavingSpectrogramImageAnnotation(true)

            const trimmedAudioAnnotationData = spectrogramAudioAnnotation.map((annotation) => ({
                _id: ReferenceGroupObjectId().toString(),
                color: annotation.color,
                startTimeInSeconds: annotation.startTimeInSeconds,
                endTimeInSeconds: annotation.endTimeInSeconds,
                label: annotation.label,
                spectrogramCoordinates: annotation.spectrogramCoordinates,
                createdAt: annotation.createdAt,
                confidenceScore: annotation.confidenceScore
              }))

            const response
            = await DataService.updateData(
             {
               id: [id],
               trimmedAudioAnnotation: trimmedAudioAnnotationData
             }
           )
           await props.updateResourceInUI({
            _id: id,
            trimmedAudioAnnotation: trimmedAudioAnnotationData
           })
           setIsSavingSpectrogramImageAnnotation(false)
          }

        } catch (err) {
          console.log("Error while deleting Annotation", err)
        }
      }
    

    const getSpectrogramCoordinatesFromAudioDuration = async (newRegion: any, color: string) => {
        console.log("getSpectrogramCoordinatesFromAudioDuration", newRegion)
        try {
            let image: {
                width: number,
                height: number
              } | undefined = undefined;   
              image = await ImageService.getImageWidthHeightFromURL(props.resource.spectrogram ||""); 
              
              console.log("ImageService.getImageWidthHeightFromURL", image)
        
            setSpectrogramAudioAnnotation(prevState => {
                if (!newRegion.id) return [...prevState];
                return [...prevState, {
                    _id: `${newRegion.id}` || "",
                    label: `${newRegion.label}` || "",
                    confidenceScore: 100,
                    startTimeInSeconds: Number(newRegion.start || -1),
                    endTimeInSeconds: Number(newRegion.end || -1),
                    createdAt: Date.now().toString(),
                    color: color,
                    spectrogramCoordinates: {
                        y1: 0,
                        x1: -1,
                        x2: -1,
                        y2: Number(image?.height||0)
                    }
                }]
            })

            const response = await fetch(props.resource.resource || "");
            const blob = await response.blob();
            const reader = new FileReader();

            reader.onloadend = async () => {
                const result = reader.result;

                if (typeof result === 'string') {
                    // Handling the case where result is a string
                    const base64String = result.split(',')[1];
                    const getDurationApiUrl = "https://time-extraction-exjsxe2nda-el.a.run.app/process_audio"
                    const apiData = {
                        audio: base64String,
                        start_time: newRegion.start,
                        end_time: newRegion.end,
                        spectrogram_width: image?.width,
                        spectrogram_height: image?.height
                    }
                    const apiResponse = await axios.post(getDurationApiUrl, apiData)
                    setSpectrogramAudioAnnotation((prevState) => {
                        if (!prevState) return [];
                        const currentAnnotation: ITrimmedAudioAnnotation | undefined = prevState.find((annotation) => annotation._id === newRegion.id)
                        if (!currentAnnotation) return []
                        return [...prevState.filter((annotation) => annotation._id !== newRegion.id),
                        {
                            ...currentAnnotation,
                            spectrogramCoordinates: {
                                y1: 0,
                                x1: Number(apiResponse.data.x_coords[0] || 0),
                                y2: Number(image?.height || 0),
                                x2: Number(apiResponse.data.x_coords[1] || 0),
                            }
                        }
                        ]
                    })
                } else if (result instanceof ArrayBuffer) {
                    // Handling the case where result is an ArrayBuffer
                    const uint8Array = new Uint8Array(result);
                    const byteArray = Array.from(uint8Array);
                    const base64String = btoa(String.fromCharCode(...byteArray));
                    const getDurationApiUrl = "https://time-extraction-exjsxe2nda-el.a.run.app/process_audio"
                    const apiData = {
                        audio: base64String,
                        start_time: newRegion.start,
                        end_time: newRegion.end,
                        spectrogram_width: image?.width,
                        spectrogram_height: image?.height
                    }
                    const apiResponse = await axios.post(getDurationApiUrl, apiData)

                    setSpectrogramAudioAnnotation((prevState) => {
                        if (!prevState) return [];
                        const currentAnnotation: ITrimmedAudioAnnotation | undefined = prevState.find((annotation) => annotation._id === newRegion.id)
                        if (!currentAnnotation) return []
                        return [...prevState.filter((annotation) => annotation._id !== newRegion.id),
                        {
                            ...currentAnnotation,
                            spectrogramCoordinates: {
                                y1: 0,
                                x1: Number(apiResponse.data.x_coords[0] || 0),
                                y2: Number(image?.height||0),
                                x2: Number(apiResponse.data.x_coords[1] || 0),
                            }
                        }
                        ]
                    })
                }
            }
            reader.readAsDataURL(blob);

        } catch (error) {

        }
    }

    const initialiseSpectrogramImageAnnotation = async () => {
        if (!props?.resource?.trimmedAudioAnnotation) return;
        let image: {
            width: number,
            height: number
          } | undefined = undefined;   

        image = await ImageService.getImageWidthHeightFromURL(props.resource.spectrogram||"");

        let currentImageAnnotation: ResourceImageAnnotation[] = spectrogramAudioAnnotation.map((annotation, index) => {
            let vertices = [
                {
                    id: `${annotation._id}-vertice-${index}-1`,
                    name: `${annotation._id}-vertice-${index}-1`,
                    x: annotation.spectrogramCoordinates.x1,
                    y: annotation.spectrogramCoordinates.y2
                },
                {
                    id: `${annotation._id}-vertice-${index}-2`,
                    name: `${annotation._id}-vertice-${index}-2`,
                    x: annotation.spectrogramCoordinates.x1,
                    y: annotation.spectrogramCoordinates.y1
                },
                {
                    id: `${annotation._id}-vertice-${index}-3`,
                    name: `${annotation._id}-vertice-${index}-3`,
                    x: annotation.spectrogramCoordinates.x2,
                    y: annotation.spectrogramCoordinates.y1
                },
                {
                    id: `${annotation._id}-vertice-${index}-4`,
                    name: `${annotation._id}-vertice-${index}-4`,
                    x: annotation.spectrogramCoordinates.x2,
                    y: annotation.spectrogramCoordinates.y2
                },
            ]
            let selectedOptions = [
                {
                    id: `${annotation._id}-value`,
                    value: annotation.label
                }
            ];
            return {
                id: annotation._id,
                vertices: vertices,
                selectedOptions: selectedOptions,
                created_at: new Date(annotation.createdAt),
                color: annotation.color,
                isClosed: true
            };
        })
        let imageScaleFactor = getImageScaleFactorForImageWidth(
            image.width,
            imageWidthToSetRef.current
          );
          
        const imageAnnotationsForTwoDimensionalImage = setResourceImageAnnotationsForSendingToAnnotationComponent(
            currentImageAnnotation || [],
            imageScaleFactor
          );
        // console.log("imageWidth", imageWidthToSetRef.current, image.width, image.height)
        // console.log("currentImageAnnotation", currentImageAnnotation, imageAnnotationsForTwoDimensionalImage)

        setDefaultSpectrogramImageAnnotation(imageAnnotationsForTwoDimensionalImage)
    }

    const initialiseSpectrogramAudioAnnotation = () => {
        if (!props?.resource?.trimmedAudioAnnotation) return;
        let currentAudioAnnotation: TrimmedAudio[] = spectrogramAudioAnnotation.map((annotation, index) => {
            return {
                _id: `${annotation._id}-audio-annotation`,
                startTimeInSeconds: annotation.startTimeInSeconds,
                endTimeInSeconds: annotation.endTimeInSeconds,
                label: annotation.label,
                createdAt: annotation.createdAt,
                color: annotation.color
            };
        })
        setDefaultSpectrogramAudioAnnotation(currentAudioAnnotation)
    }

    const discardChanges = () => {
        setSpectrogramAudioAnnotation(props?.resource?.trimmedAudioAnnotation || [])
    }


    useEffect(() => {
        initialiseSpectrogramImageAnnotation()
        initialiseSpectrogramAudioAnnotation()
    }, [spectrogramAudioAnnotation])

    useEffect(() => {
        setSpectrogramAudioAnnotation(props?.resource?.trimmedAudioAnnotation || [])
    }, [props?.resource?.trimmedAudioAnnotation])


    const isShowingCompressedImageBecauseOriginalImageIsDownloading = (() => {
        return false;
    })();

    const isShowingCompressedImageBecauseOriginalImageIsDownloadingRef = useRef(isShowingCompressedImageBecauseOriginalImageIsDownloading);
    isShowingCompressedImageBecauseOriginalImageIsDownloadingRef.current = isShowingCompressedImageBecauseOriginalImageIsDownloading;
    const [isImageBeingDownloaded, setIsImageBeingDownloaded] = useState(false);

    useEffect(() => {
        const executeFunction = async () => {
            if (props.resource && props.resource.spectrogram) {

                let isShowingCompressedImageBecauseOriginalImageIsDownloading = isShowingCompressedImageBecauseOriginalImageIsDownloadingRef.current;

                let image: {
                    width: number,
                    height: number
                } | undefined = undefined;

                setIsImageBeingDownloaded(true);

                let differenceBetweenCanvasAndImageToSet = 0;
                let canvasWidthToSet = 0;
                let canvasHeightToSet = 0;

                image = await ImageService.getImageWidthHeightFromURL(props.resource.spectrogram || "");

                if (image.width > image.height) {
                    differenceBetweenCanvasAndImageToSet = image.width / MaxAnnotatedImageWidthAllowed;
                    canvasHeightToSet = image.height / differenceBetweenCanvasAndImageToSet;
                    canvasWidthToSet = MaxAnnotatedImageWidthAllowed;


                } else if (image.height > image.width) {
                    differenceBetweenCanvasAndImageToSet = image.height / MaxAnnotatedImageHeightAllowed;
                    canvasWidthToSet = image.width / differenceBetweenCanvasAndImageToSet;
                    canvasHeightToSet = MaxAnnotatedImageHeightAllowed;

                    if (canvasWidthToSet > MaxAnnotatedImageWidthAllowed) {
                        differenceBetweenCanvasAndImageToSet = canvasWidthToSet / MaxAnnotatedImageWidthAllowed;
                        canvasHeightToSet = canvasHeightToSet / differenceBetweenCanvasAndImageToSet;
                        canvasWidthToSet = MaxAnnotatedImageWidthAllowed;
                    }
                }

                imageWidthToSetRef.current = canvasWidthToSet;
                imageHeightToSetRef.current = canvasHeightToSet;

                const imageScaleFactor = getImageScaleFactorForImageWidth(
                    image.width,
                    imageWidthToSetRef.current
                );
                setIsImageBeingDownloaded(false);
            }
        }

        executeFunction();
    }, [props?.resource])


    const textSimilarityChipColor = (similarity: number) : string => {
        return (
            similarity <= 25 ?
                "red" :
                similarity <= 70 ?
                    "orange" :
                    "green"
        )
    }

    const {isFetchingResourceMetadataFileUrl, resourceMetadataFileDownloadUrl} = useResourceMetadataDownloadUrlFromResourceId(
        props.resource?.metadataFileName 
        ? props.resource?._id || ""
        : ""
    )

  return (
    <Dialog onClose={onClose} open={props.open} maxWidth="lg" fullWidth={false} classes={{ paperScrollPaper: classes.dialogContainer , paperWidthLg: !props.isCopilot &&
        props.resource &&
        // @ts-ignore
        props.resource?.model?.type !== "image" ? classes.dialogContainerWidth : ''}}>
        {         // @ts-ignore
         props.resource?.model?.type !== "audioTextTranscription"&&<AiAssistantMsgWithInfoIcon
              aiAssistantLastCalledAt={(props?.resource as IData)?.aiAssistantLastCalledDetails?.calledAt ? true : false}
              mt='34px'
              ml='57px'
          />}
        {     // @ts-ignore
            props.resource?.model?.type === "audioTextTranscription"&&<div style={{ display: "flex", flexDirection: "row", alignItems: "center", justifyContent: "space-between"}}><AiAssistantMsgWithInfoIcon 
          aiAssistantLastCalledAt={(props?.resource as IData)?.aiAssistantLastCalledDetails?.calledAt ? true : false}
          mt='34px'
          ml='57px'
        />

              {props.resource.textOne&&props.resource.textTwo&&<Box display={"flex"} ml={'auto'} mr={'3rem'} alignItems={'center'} mt={2}>
                  <Typography style={{ fontWeight: 600, fontSize: '1.05rem' }} >
                      Text Similarity
                  </Typography>
                  <Chip
                      label={`${parseFloat(String(100 - ((props.resource as IData)?.textDifferencePercentage || 0)))?.toFixed(2)}%`}
                      style={{
                          borderRadius: '4px',
                          margin: '0 8px',
                          color: 'white',
                          fontSize: '1.05rem',
                          backgroundColor: textSimilarityChipColor(100 - ((props.resource as IData)?.textDifferencePercentage || 0)),
                      }}
                  />
              </Box>}
        </div>}
        
        {!props.isCopilot &&
        props.resource &&
        // @ts-ignore
        props.resource?.model?.type === "image" &&
            <DialogTitle id="responsive-dialog-title" className={classes.imageAnnotation} style={{display: 'flex', justifyContent: 'flex-start', marginLeft: '40px', marginTop:'30px'}}>
                <p style={{display: 'flex', marginLeft: '0px'}}>Image Name: <p style={{color:"#008d9c", paddingLeft:"10px"}}>{props.resource?.filename}</p></p>          
            </DialogTitle>
        }

{/* {
            props.modelRefernceData?.length>0 &&
            <div
              style={{
                display: "flex",
                justifyContent: "end",
                marginRight: "12%",
                marginTop: "-20px",
                marginBottom: "20px",
              }}
            >
              <ReferenceImageDataLink
                imageReferenceData={props.modelRefernceData}
              />
            </div>
          }  */}
          {
            props.modelRefernceData?.length>0 &&
            <div
              style={{
                display: "flex",
                justifyContent: "end",
                marginRight: "77px"
              }}
            >
              <FormControl component="fieldset">
                <FormGroup row>
                  <FormControlLabel
                    control={
                      <Switch color="primary" checked={isViewingReferenceImage} 
                        onChange={(e)=>{
                          setIsViewingReferenceImage(e.target.checked)
                        }}
                      />
                    }
                    label="View Reference Images"
                    labelPlacement="end"
                  />
                </FormGroup>
              </FormControl>
              {/* <ReferenceImageDataLink
                imageReferenceData={props.modelReferenceData}
              /> */}
            </div>
          }          

        <div className={classes.root} style={{padding : !props.isCopilot &&
        props.resource &&
        // @ts-ignore
        props.resource?.model?.type === "image" ? '0px' : ''}}>
            <div
            className={classes.closeBtn}
            onClick={() => {
                onClose();
            }}
            >
                <Cancel style={{ fontSize: "40px", padding: "4px" }} />
            </div>

            <div className={`${classes.body} ${(!props.isCopilot &&
                props.resource &&
                // @ts-ignore
                props.resource?.model?.type === "image") && classes.imageBody}`}>

                {
                    props.isFetchingResources && !props.isCopilot &&
                    <section
                        style={{
                            width: "100%",
                            textAlign: "center"
                        }}
                    >
                        <CircularProgress color="primary" />
                    </section>
                }

                {
                    !props.isFetchingResources &&
                <Box display={"flex"} flexDirection={"column"} width={'100%'} height={'100%'}>
                    {
                        //@ts-ignore
                        props.resource?.model?.type === "textComparison"
                        &&
                        <Box style={{width: '100%', display: 'flex', justifyContent: 'space-around'}}>
                            <Box mr={'auto'} ml={'12px'}>
                                <Typography style={{fontWeight: 600, fontSize: '1.05rem'}} >
                                    Transcripted Text
                                </Typography>
                                <Typography variant="subtitle2" style={{ color: theme.palette.grey[400], marginTop: '6px', fontWeight: 600}}>
                                    Compare the text
                                </Typography>
                            </Box>
                            <Box display={"flex"} ml={'auto'} mr={'3rem'} alignItems={'center'}>
                                <Typography style={{fontWeight: 600, fontSize: '1.05rem'}} >
                                    Text Similarity
                                </Typography>
                                <Chip
                                    label={`${parseFloat(String( 100 - ((props.resource as IData)?.textDifferencePercentage || 0)))?.toFixed(2) || 0}%`}
                                    style={{
                                        borderRadius: '4px',
                                        margin: '0 8px',
                                        color: 'white',
                                        fontSize: '1.05rem',
                                        backgroundColor: textSimilarityChipColor(100 - ((props.resource as IData)?.textDifferencePercentage || 0)),
                                    }}
                                />
                            </Box>
                        </Box>
                    }
                    {
                        //@ts-ignore
                        props.resource?.model?.type === "textComparison"
                        &&
                        <Divider style={{marginTop: '1rem'}} />
                    }
                    <div
                        style={{display: 'flex', width: "100%", height: "100%"}}
                    >
                {/* left arrow  */}
                {
                props.scenario !== 'aiMarketPlaceDataCart'
                &&
                <IconButton onClick={() => arrowClicked("left")} className={classes.navigateButton} style={{visibility : props.isResourceExistBefore? "visible" : "hidden", height: 'fitContent', margin: 'auto' }}>
                    <NavigateBeforeIcon style={{color:"black",  fontSize:"xxx-large" }} />
                </IconButton>}
                {/* Resource Content */}
                <div className={`${classes.showResource} ${(!props.isCopilot &&
                props.resource &&
                // @ts-ignore
                props.resource?.model?.type === "image") && classes.showImageResource}`}>
                    <div style={{ width: (!props.showMetaDataForTextComparisonModel)?
                    "100%":""}} className={`${classes.audioTrimSection} ${!props.isCopilot &&
                            props.resource &&
                            // @ts-ignore
                            props.resource?.model?.type === "image" ? classes.imageTrimSection : ''}`}>
                        {
                            !props.isCopilot &&
                            props.resource && props?.modelAnnotationLabelsAvailable && 
                            props.resource.spectrogram&&
                            // @ts-ignore
                            props.resource?.model?.type === "audio" &&
                            <div style={{ marginLeft: 10}}>
                                      <SegmentationAnalyticsTwoDimensionalImage
                                          url={props.resource.spectrogram || ""}
                                          imageWidth={imageWidthToSetRef.current}
                                          AnnotationOption={[]}
                                          defaultAnnotations={defaultSpectrogramImageAnnotation}
                                          isDynamicOptionsEnable
                                          isViewOnlyMode={false}
                                          isLabelOn={true}
                                      // isZoomFeatureEnabled={true}
                                      />
                                      </div>
                        }
                        {
                            !props.isCopilot &&
                            props.resource?.spectrogram &&
                            props.resource && props?.modelAnnotationLabelsAvailable && 
                            // @ts-ignore
                            props.resource?.model?.type === "audio" &&
                            <SpectrogramAudioTrimUI resource={props.resource} updateResourceInUI={() => {}} copilotSpectrogramAudioAnnotation={defaultSpectrogramAudioAnnotation} copilotMaxAudioTrimAllowedInMilliSeconds={0}
                            setSelectedSpectrogramAnnotation={setSelectedSpectrogramAnnotation}
                            selectedSpectrogramAnnotation={selectedSpectrogramAnnotation}
                            getSpectrogramCoordinatesFromAudioDuration={getSpectrogramCoordinatesFromAudioDuration}
                            setWidth={`${imageWidthToSetRef.current}px`}
                            elementId={-1}
                                      />
                        }
                        {
                            !props.isCopilot &&
                            props.resource?.spectrogram &&
                            props.resource && props?.modelAnnotationLabelsAvailable && 
                            // @ts-ignore
                            props.resource?.model?.type === "audio" &&
                                      <AnnotationsRegionList
                                          trimmedAudioAnnotation={spectrogramAudioAnnotation}
                                          setTrimmedAudioAnnotation={setSpectrogramAudioAnnotation}
                                          setSelectedSpectrogramAnnotation={setSelectedSpectrogramAnnotation}
                                          // @ts-ignore
                                          labelsAvailable={props?.modelAnnotationLabelsAvailable}
                                          allowAnyLabel={props?.allowUserToSetAnyLabel}
                                      />
                        }
                        {
                                      !props.isCopilot &&
                                      props.resource?.spectrogram &&
                                      props.resource && props?.modelAnnotationLabelsAvailable &&
                                      // @ts-ignore
                                      props.resource?.model?.type === "audio" &&
                                      <div style={{display: "flex", flexDirection: "column", justifyContent: "center", alignItems: "center"}}>
                                          <div
                                              style={{
                                                  marginTop: "20px",
                                                  textDecoration: "underline",
                                                  cursor: "pointer"
                                              }}
                                              onClick={discardChanges}
                                          >
                                              Discard
                                          </div>
                                          {
                                            spectrogramAudioNoLabelError !==""&&
                                            <div style={{ color: "red"}}>
                                                {spectrogramAudioNoLabelError}
                                            </div>
                                          }
                                          <Button
                                          disabled={!isLabelDefinedinAnnotaions(spectrogramAudioAnnotation)}
                                           color='primary' variant='contained' onClick={()=>saveSpectrogramAudioAnnotations(props.resource._id)}>
                                              {
                                                isSavingSpectrogramImageAnnotation&&
                                                <CircularProgress style={{ color: "white", width: 15, height: 15}}/>
                                              }
                                              Save Annotation
                                          </Button>
                                      </div>
                            
                        }
                        {
                            !props.isCopilot &&
                            props.resource && props?.modelAnnotationLabelsAvailable && 
                            // @ts-ignore
                            (props.resource?.model?.type === "audio" ||  props.resource?.model?.type === "audioTextTranscription") && 
                            !props.resource?.spectrogram&&
                            <AudioTrimUI 
                                resource={props.resource as unknown as IData}
                                updateResourceInUI={updateResourceInUI}
                                pauseAudioOnPupupClose={!props.open}
                                modelAnnotationLabelsAvailable={props?.modelAnnotationLabelsAvailable}
                                modelMaxAudioTrimAllowedInMilliSeconds={props?.modelMaxAudioTrimAllowedInMilliSeconds || 0}

                                // @ts-ignore
                                taskType={props.resource?.model?.type}
                                 // @ts-ignore
                                audioTranscriptionWordsMetadatas={props?.resource?.audioTranscriptionWordsMetadatas}
                                textOneHeadingForTextComparisonUI={props.textOneHeadingForTextComparisonUI}
                                textTwoHeadingForTextComparisonUI={props.textTwoHeadingForTextComparisonUI}
                                scenario={props.scenario}
                            />
                        }
                        {
                            !props.isCopilot &&
                            props.resource &&
                            // @ts-ignore
                            (props.resource?.model?.type === "text" || props.resource?.model?.type === "textFile" || props.resource?.model?.type === "prompt") &&
                            <>
                            <div 
                                style={{
                                    display: 'flex',
                                    marginBottom: "20px",
                                    justifyContent: 'space-between'
                                }}
                            >
                                <TextResourceHeading 
                                    heading={
                                        // @ts-ignore
                                        props.resource?.model?.type === "textFile"
                                        ? props.resource.filename
                                        // @ts-ignore
                                        : props.resource?.model?.type === "prompt" ? "Prompt"
                                        : ""
                                    }
                                />
                                {
                                  // @ts-ignore
                                  props.resource?.model?.type === "prompt" &&
                                  <Box>
                                    {
                                      savingInDB ? 
                                      <CircularProgress style={{marginTop: "13.5px", marginRight: "10px"}} size="1.8rem" color="primary" />
                                      :
                                      !editMode ?
                                            isFetchingTxtFileContentsOnEditModeClick
                                            ? <CircularProgress style={{marginTop: "13.5px", marginRight: "10px"}} size="1.8rem" color="primary" />
                                            :
                                                <IconButton color="primary" onClick={() => handlePromptEditClick()}>
                                                <EditIcon></EditIcon>
                                                </IconButton>
                                        :
                                        <>
                                            <IconButton color="primary" onClick={() => handlePromptEditCancelClick()}>
                                            <CloseIcon></CloseIcon>
                                            </IconButton>
                                            <IconButton color="primary" onClick={() => savePromptAndAnswerInDB()}>
                                            <SaveIcon></SaveIcon>
                                            </IconButton>
                                        </>
                                    }
                                  </Box>
                                }
                            </div>
                            {
                              // @ts-ignore
                              props.resource?.model?.type === "prompt" &&
                              <Box style={{display: "flex", flexDirection: "column", rowGap: "10px", height: "82%"}}>
                                {
                                    editMode
                                    ?
                                        <>
                                            {/* <TextField rows={2} rowsMax={2} multiline id="outlined-basic" label="" variant="outlined" value={promptTextFieldData} disabled={!editMode} onChange={(event) => promptChanged(event.target.value)} /> */}
                                            <TextField label="" variant="outlined" value={promptTextFieldData} disabled={!editMode} onChange={(event) => setPromptTextFieldData(event.target.value)} />

                                            {
                                                separateFileNameFromExtension(props.resource.filename).extensionOfFileName === "txt"
                                                ?
                                                    // <TextField rows={18} rowsMax={16} multiline id="outlined-basic" label="" variant="outlined" disabled={!editMode} value={ansOfPromptTextFieldData} onChange={(event) => ansOfPromptChanged(event.target.value)} />
                                                    <TextField rows={18} rowsMax={16} multiline id="outlined-basic" label="" variant="outlined" disabled={!editMode} value={ansOfPromptTextFieldData} onChange={(event) => setAnsOfPromptTextFieldData(event.target.value)} />
                                                : 
                                                    <iframe 
                                                        src={props.resource.resource}
                                                        title={props.resource.filename}
                                                        style={{
                                                            width : '90%', 
                                                            height: '90%', 
                                                            overflow: 'auto',  
                                                            // padding: "10px", 
                                                            border: "none"                                      
                                                        }}
                                                    ></iframe>                                                     
                                            }

                                        </>
                                    : 
                                        <div style={{height: "100%"}}>
                                            <div style={{marginBottom: "20px", fontWeight: 600}}>{props.resource.prompt}</div>
                                            {/* <div>{props.resource.ansOfPrompt}</div> */}
                                            {/* iframe is not able to show inverted comma's correctly so commented its code */}
                                            {/* <iframe src={props.resource.resource}
                                                title={props.resource.filename}
                                                style={{
                                                    width : '90%', 
                                                    height: '90%', 
                                                    overflow: 'auto',  
                                                    // padding: "10px", 
                                                    border: "none"                                      
                                                }}
                                            ></iframe>  */}
                                            {
                                                isFetchingResourceTxtFileContents
                                                ?   <CircularProgress color="primary" />
                                                :
                                                    <div
                                                        title={ansOfPrompt}
                                                        style={{
                                                            width : '90%', 
                                                            height: '90%', 
                                                            overflow: 'auto',  
                                                            padding: "10px", 
                                                            border: "none",
                                                            color: "white",
                                                            backgroundColor: "black",
                                                            borderRadius: "10px",
                                                            whiteSpace: "break-spaces"                                      
                                                        }}                                                        
                                                    >{ansOfPrompt}</div>
                                            }  
                                            
                                        </div>
                                }
                              </Box>
                            }
                            {
                                // @ts-ignore
                                props.resource?.model?.type === "textFile" &&
                                <iframe src={props.resource.resource}
                                    title={props.resource.filename}
                                    style={{
                                        width : '90%', 
                                        height: '90%', 
                                        overflow: 'auto',  
                                        padding: "10px", 
                                        border: "none"                                      
                                    }}
                                ></iframe>
                            }
                            <div></div>
                            {
                                (
                                    // @ts-ignore
                                    props.resource?.model?.type !== "textFile" &&  props.resource?.model?.type !== "prompt"
                                ) &&
                                <div 
                                    style={{
                                        width : '90%', 
                                        maxHeight: '90%', 
                                        overflow: 'auto', 
                                        wordBreak: 'break-word',
                                        padding: "10px",
                                        border: "1px solid rgb(118, 118, 118)",
                                        borderRadius: "8px"
                                    }}
                                >
                                    {props.resource.resource}
                                </div>
                            }
                            {/* <div style={{width : '90%', maxHeight: '90%', overflow: 'auto', wordBreak: 'break-word'}}>
                                {props.resource.resource}
                            </div> */}
                            </>
                        }
                        {
                            //@ts-ignore
                            (props.resource?.model?.type === "textAnnotation" || props.resource?.coPilot?.type === "textAnnotation" || props.resource?.model?.type === "DLP" || props.resource?.coPilot?.type === "DLP") &&
                            <div
                                style={{width: "100%", height: "100%", overflow: "auto"}}
                            >
                                <TextTrimUI
                                    key={props.resource._id}
                                    resource={props.resource as unknown as IData}
                                    updateResourceTrimmedTextsInUI={!props.isCopilot ? props.updateResourceInUI : props.updateCopilotResourceTrimmedTextsInUI}
                                    annotationLabelsAvailable={props.modelAnnotationLabelsAvailable}
                                    isInCopilot={props.isCopilot || false}
                                    //@ts-ignore
                                    modelType = {props.resource?.model?.type || props.resource?.coPilot?.type as unknown as IModelType}
                                />
                            </div>
                        }
                        {!props.isCopilot &&
                            props.resource &&
                            // @ts-ignore
                            props.resource?.model?.type === "video" &&
                            <>
                            <div className ={classes.videoContainer}>
                                <video muted controls  style={{width: '90%',height: '90%', cursor: 'pointer'}} src={props.resource.resource} />
                            </div>
                            </>
                            
                        }
                                  {
                                      props.resource &&
                                      // @ts-ignore
                                      props.resource?.model?.type === "textComparison" && <>
                                          <div 
                                            className={`
                                                ${classes.textComparisonParentClass}
                                                ${props.showMetaDataForTextComparisonModel ? css.textComparisonParentClassWhenshowMetaDataForTextComparisonModelScenario : ""}
                                            `}
                                          >
                                              <div className={classes.textComparisonContainer}>
                                                  <div className={classes.textComparisonHeader}>{props.textOneHeadingForTextComparisonUI}</div>
                                                  <div className={classes.textComparisonColumn}>
                                                    <TextDiffSection forText={'one'} text={props.resource.textOne || ""} textDifferences={props.resource.textsDifferences} /> 
                                                  </div>
                                              </div>
                                              <div className={classes.textComparisonContainer}>
                                                  <div className={classes.textComparisonHeader}>{props.textTwoHeadingForTextComparisonUI}</div>
                                                  <div className={classes.textComparisonColumn}>
                                                      <TextDiffSection forText={'two'} text={props.resource.textTwo || ""} textDifferences={props.resource.textsDifferences} />
                                                  </div>
                                              </div>
                                          </div>
                                      </>
                                  }
                        {
                            props.resource &&
                            // @ts-ignore
                            props.resource?.model?.type === "textComparison" &&
                            <div style={{ backgroundColor: 'white', opacity: 1, zIndex: 5, marginLeft: '4px', top: -25, display: "flex", flexDirection: "row", justifyContent: "center", width: "100%"}} className={classes.actoinButtonWidth}>
                            <div style={{display:'flex'}}>
                                <p style={{marginBottom: "10px", color: `${"#8e969e"}`, alignSelf:'flex-end', marginRight: "30px", width: '120px'}}>Actions : </p>
                                <div style={{display: "flex", flexDirection: "row", width: "100%"}}>
                                <props.ActionButtonArray
                                    onActionButtonClick={()=>{
                                        handlePromptEditCancelClick();
                                    }}
                                />
                                </div>
                            </div>
                            </div>
                        }
                        {!props.isCopilot &&
                            props.resource &&
                            // @ts-ignore
                            props.resource?.model?.type === "image" &&
                            <>
                            <div 
                            // @ts-ignore
                            ref={(imageDOMref) => {
                                // @ts-ignore
                                imageContainerElementRef.current = imageDOMref
                                settingWidthAndHeight()
                            }} 
                            style={{
                                width: "100%",
                                height: `450px`,
                                display: "flex",
                                justifyContent: "center",
                                alignItems: "center",
                                backgroundColor: "white",
                                borderRadius: "10px"
                              }}
                            className ={classes.videoContainer}>
                                {/* <ImageComponent resource={props.resource as unknown as IData} 
                                    canvasElementClassNames={`${classes.image}`}
                                /> */}
                                    <ImageAspectRatioScaler 
                                        imageUrl={props.resource.resource}
                                        image500Url = {props.resource.resource500WUrl || undefined}
                                        imageMetaData = {props.resource.resourceMetaData || undefined}
                                        image500UrlMetaData = {props.resource.resource500WMetaData || undefined}
                                        maxImageWidthAllowed={imageMaxWidthAllowed}
                                        maxImageHeightAllowed={imageMaxHeightAllowed}
                                        style={{
                                            borderRadius: `${imageBorderRadius}px`
                                        }}
                                    />
                            </div>
                            </>
                            
                        }
                        {/* {
                            props.isCopilot &&
                            // @ts-ignore
                            props.resource?.coPilot?.type === "textAnnotation" &&
                            <div
                                style={{width: "90%", maxHeight: "90%", overflow: "auto"}}
                            >
                                {props.resource?.resource}
                            </div>
                        } */}
                    </div>
                    {((props.showMetaDataForTextComparisonModel && 
                    // @ts-ignore
                    props.resource?.model?.type === "textComparison")||
                    // @ts-ignore
                    (props.resource?.model?.type !== "textComparison")
                    )
                     && <div className={`${classes.detailsSection} ${!props.isCopilot &&
                            props.resource &&
                            // @ts-ignore
                            props.resource?.model?.type === "image" ? classes.imageDetailsSection : ''}`}>
                        {/* Actions Buttons */}
                        {
                            // @ts-ignore
                            props.resource?.model?.type !== "textComparison" && !["aiMarketPlaceDataCart", "aiMarketplaceDataSelection"].includes(props.scenario) &&
                            !props.isCopilot &&
                            <div style={{ backgroundColor: 'white', opacity: 1, zIndex: 5, marginLeft: '4px', top: -25}} className={classes.actoinButtonWidth}>
                            <div style={{display:'flex'}}>
                                <p style={{marginBottom: "10px", color: `${"#8e969e"}`, alignSelf:'flex-end', marginRight: "30px", width: '120px'}}>Actions : </p>
                                <div style={{width: "300px"}}>
                                <props.ActionButtonArray
                                    onActionButtonClick={()=>{
                                        handlePromptEditCancelClick();
                                    }}
                                />
                                </div>
                            </div>
                            {/* <div>
                                {
                                    !props.isCopilot &&
                                    // @ts-ignore
                                    props.resource && (props.resource.model.type === 'text' || props.resource.model.type === 'textAnnotation')  &&
                                    <Button color='primary' variant='contained'
                                    onClick={()=>{
                                        setAddResourceToCopilotDialogState(oldState=>({...oldState, isOpen: true}))
                                    }}>
                                        Add to Copilot
                                    </Button>
                                }
                                {
                                        addResourceToCopilotDialogState.isOpen && props.resource &&
                                        <AddModelResourceToCopilotDialog
                                            open={addResourceToCopilotDialogState.isOpen}
                                            resource={props.resource as unknown as IData}
                                            onClose={()=>setAddResourceToCopilotDialogState(oldState=>({...oldState, isOpen: false}))}
                                        />
                                    }
                            </div> */}
                        </div>}
                        <div  className={classes.resourceDetails}>
                        

                        {/* Resources Details Section */}
                        <div>

                        {
                            isViewingReferenceImage
                            ? <ReferenceImageHTML />
                            : <></>
                        }

                        {
                            props.isCopilot ?
                            <ShowResourceDetails 
                            updateResourceInUI={updateResourceInUI}
                            resource={props.resource} 
                            isFetchingResourceMetadataFileUrl={isFetchingResourceMetadataFileUrl}
                            resourceMetadataFileDownloadUrl={resourceMetadataFileDownloadUrl}
                            setShouldBlockDataTableKeyboardEvents={props.setShouldBlockDataTableKeyboardEvents} 
                            // @ts-ignore
                            resourceType={props.resource?.copilot?.type} 
                            inCopilot={true} 
                            isShowingInForecastingViewPopup={false}
                            LabelsAvailable ={props.LabelsAvailable}
                            selectedSubTab = {selectedSubTab}
                            allowUserToSetAnyLabel={props.allowUserToSetAnyLabel} 
                            />
                            :
                            <ShowResourceDetails 
                            updateResourceInUI={updateResourceInUI}
                            resource={props.resource} 
                            setShouldBlockDataTableKeyboardEvents={props.setShouldBlockDataTableKeyboardEvents} 
                            // @ts-ignore
                            confidenceScore={Number(props.resource?.confidence_score)} 
                            // @ts-ignore
                            resourceType={props.resource?.model?.type} 
                            // @ts-ignore
                            dataSetCollections={props.resource?.dataSetCollections}
                            // @ts-ignore 
                            changesMade={props.resource?.changesMade} 
                            inCopilot={false} 
                            isShowingInForecastingViewPopup={false}
                            isFetchingResourceMetadataFileUrl={isFetchingResourceMetadataFileUrl}
                            resourceMetadataFileDownloadUrl={resourceMetadataFileDownloadUrl}                            
                            LabelsAvailable ={props.LabelsAvailable}
                            selectedSubTab = {selectedSubTab}
                            allowUserToSetAnyLabel={props.allowUserToSetAnyLabel} 
                            scenario={props.scenario}
                            />
                            }
                        </div>

                        {/* Remarks Sections */}
                        {
                        !["aiMarketPlaceDataCart", "aiMarketplaceDataSelection"].includes(props.scenario || '')
                        &&
                        <div>
                        <>
                            <div style={{marginBottom: "10px", color: `${"#8e969e"}`}}>{"Add Remarks"}:</div>
                            <textarea 
                                style={{width: "100%", minHeight: "83px", marginBottom: "10px", fontSize: "15px", fontFamily: 'Nunito Sans', borderRadius: "5px"}}
                                value={resourceRemarksTextState.value}
                                onFocus={()=>props.setShouldBlockDataTableKeyboardEvents(true)}
                                onChange={(event)=>{
                                    setResourceRemarksTextState(oldState=>({
                                        ...oldState,
                                        isDirty: true,
                                        value: event.target.value
                                    }))
                                }}
                                onBlur={()=>{
                                    setResourceRemarksTextState(oldState=>({
                                        ...oldState,
                                        isTouched: true,
                                    }))
                                    props.setShouldBlockDataTableKeyboardEvents(false)                         
                                }}
                                disabled={
                                    props.resource?.isResourceRemarksBeingUpdatedInDB
                                    ? true
                                    : false
                                }
                            />  
                            <div style={{
                                display: "flex",
                                flexDirection: "row",
                                gap: 10
                            }}>
                            <Button color="primary" variant="contained"
                                onClick={()=>{
                                onSaveRemarksClick();
                                return;
                                }}
                                disabled={
                                    props.resource?.isResourceRemarksBeingUpdatedInDB || !resourceRemarksTextState.isDirty ? true : false
                                }
                            >
                                Save Remarks
                            </Button> 

                            <Button color="primary" variant="contained"
                                onClick={()=>{
                                setIsViewAsCSVOpen(true)
                                }}
                            >
                                View As CSV
                            </Button> 
                            {
                                isViewAsCSVOpen&&
                                <ViewAsCSVDialog resources={[props.resource]} open={isViewAsCSVOpen} setOpen={setIsViewAsCSVOpen} onClose={handleCloseCSVDialog}/>
                            }
                            </div>
                        </>
                        </div>
                        }
                    </div>
                    </div>
                }</div>
                {/* right arrow  */}
                {
                props.scenario !== 'aiMarketPlaceDataCart'
                &&
                <IconButton onClick={() => arrowClicked("right")} className={classes.navigateButton} style={{visibility : props.isResourceExistAfter? "visible" : "hidden", height: 'fitContent', margin: 'auto' }}>
                    <NavigateNextIcon style={{color:"black", fontSize:"xxx-large"}}/>
                </IconButton>}
                </div>
                </Box>
                }
            </div>
            {/* {
                // @ts-ignore
                (props.resource?.model?.type==="audioTextTranscription" && props.resource.textOne && props.resource.textTwo)&& <>
                    <div className={classes.textComparisonParentClass}>
                        <div className={classes.textComparisonContainer}>
                            <p className={classes.textComparisonHeader}>{props.textOneHeadingForTextComparisonUI}</p>
                            <div className={classes.textComparisonColumn}>
                              <TextDiffSection forText={'one'} text={props.resource.textOne || ""} textDifferences={props.resource.textsDifferences} /> 
                            </div>
                        </div>
                        <div className={classes.textComparisonContainer}>
                            <p className={classes.textComparisonHeader}>{props.textTwoHeadingForTextComparisonUI}</p>
                            <div className={classes.textComparisonColumn}>
                                <TextDiffSection forText={'two'} text={props.resource.textTwo || ""} textDifferences={props.resource.textsDifferences} />
                            </div>
                        </div>
                    </div>
                </>
                   
            } */}
        </div>
    </Dialog>
  )
}

type TextResourceHeadingProps = {
    heading?: string
}
export function TextResourceHeading(props: TextResourceHeadingProps) {
    return (
        <div
            style={{
                fontWeight: 600,
                fontSize: "26px"
            }}
        >
            {props.heading || "Text"}
        </div>
    )
}

const mapProps = (state: IReduxState) => {
    return {
    };
  };
  const connector = connect(mapProps, {
    updateResourceRemarks,
    updateResourceInUI,
    updateCopilotResourceTrimmedTextsInUI,
    updateResourcePromptOrAnswer,
  });
  export type TPropsFromRedux = ConnectedProps<typeof connector>;
export default connector(ViewAndTrimAudioResource);

function ImageComponent(props: {
    resource: IData,
    canvasElementClassNames: string
}) {
    const defaultCanvasWidth = 450
    const defaultCanvasHeight = 450
    const canvasRef = useRef<HTMLCanvasElement>(null);
    const [canvasWidth, setCanvasWidth] = useState(defaultCanvasWidth);
    const [canvasHeight, setCanvasHeight] = useState(defaultCanvasHeight);

    useEffect(() => {
        let base_image = new Image();
        base_image.src = props.resource.resource;
    
        base_image.onload = function () 
        {
            let imageWidth = base_image.width;
            let imageHeight = base_image.height;
            let differenceBetweenCanvasAndImageToSet: number = 0;
            let canvasHeightToSet: number = canvasHeight;
            let canvasWidthToSet: number = canvasWidth;
    
            if (imageWidth > imageHeight)
                {
                    differenceBetweenCanvasAndImageToSet = imageWidth / defaultCanvasWidth;
                    canvasHeightToSet = imageHeight / differenceBetweenCanvasAndImageToSet ;
                    canvasWidthToSet = defaultCanvasWidth;
                } 
            else if (imageHeight > imageWidth) {
                differenceBetweenCanvasAndImageToSet = imageHeight / defaultCanvasHeight;
                canvasWidthToSet = imageWidth / differenceBetweenCanvasAndImageToSet;
                canvasHeightToSet = defaultCanvasHeight;
            } 
    
            if(canvasHeightToSet){
                setCanvasHeight(canvasHeightToSet);
            }
            if(canvasWidthToSet){
                setCanvasWidth(canvasWidthToSet);
            }
        }      
    

    }, [canvasHeight, canvasWidth, props.resource.resource]);
    

    return (
        <>
            <div style={{width: `${defaultCanvasWidth}px`, 
                    height: `${defaultCanvasHeight}px`, display: 'flex', alignItems: 'center'}}>
                <canvas
                    ref={canvasRef} 
                    className={props.canvasElementClassNames}
                    
                    style ={{
                        backgroundImage: `url(${props.resource.resource})`,
                        width: `${canvasWidth}px`, 
                        height: `${canvasHeight}px`,
                        backgroundSize : `${canvasWidth}px ${canvasHeight}px`
                    }}
                    
                ></canvas>
            </div>  
            </>
    );
}
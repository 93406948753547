import { useContext, useEffect } from "react"
import Header from "./modelCloneSubComponents/Header/Header"
import ModelCloneSuccess from "./modelCloneSubComponents/ModelCloneSuccess/ModelCloneSuccess"
import ModelSpecifications from "./modelCloneSubComponents/ModelSpecifications/ModelSpecifications"
import ChatContext from "../../chat/contexts/chat.context"
import { IGetModelCollectionResponse } from "../../projects/features/aiMarketPlace/aiMarketPlaceInterfaces/interfaces"

interface IProps {
	trainingPodDetails: IGetModelCollectionResponse
}

function ModelCloningStage(props: IProps) {
	const chatContext = useContext(ChatContext)
	const {modelCloningStage, clonedPodDetails } = chatContext

	return (
		<div style={{ height: '100%', width: '100%', padding: '10px'}}>
			{
				modelCloningStage==='done' ?
				<ModelCloneSuccess clonedPodDetails={clonedPodDetails} />
				:<>
					<Header />
					<ModelSpecifications trainingPodDetails={props.trainingPodDetails} />
				</>
			}
		</div>
	)
}

export default ModelCloningStage
import React, { Suspense } from "react";
import { BrowserRouter, Route, RouteComponentProps, Switch } from 'react-router-dom';
import ErrorBoundary from './ErrorBoundary';
import ModelistContextComponent from "./features/admin/features/projects/features/models/modelistContext/modelistContextComponent";
import BrowseCollectionsPopupContextComponent from "./features/admin/features/projects/features/models/browseCollectionsPopupContext/browseCollectionsPopupContextComponent";
import ReferenceGuide from "./features/admin/features/projects/features/ReferenceGuide";
import BrowseModelCollectionsPopup from "./features/admin/features/projects/features/models/features/model/features/collections/components/BrowseModelCollectionsPopup";
import BrowseModelCollectionsPopupContextComponent from "./features/admin/features/projects/features/models/browseModelCollectionsPopupContext/browseModelCollectionsPopupContextComponent";
import RLEFHomePage from './features/admin/features/homePage/RLEF_homepage'
import ChatComponent from './features/admin/features/chat/ChatComponent'
import ChatContextProvider from "./features/admin/features/chat/contexts/chatContext.provider";
import { DataSelectorProvidor } from "rlef-ui-library";
import TrainingPodSessionContextComponent from "./features/admin/features/projects/features/models/TrainingPodTestingSessionContext/trainingPodTestingSessionContextComponent";

const GetReferenceData=React.lazy(()=>import('./features/referenceGuide/components/referenceGuide'))
const Productkey = React.lazy(() => import('./features/auth/Productkey'));
const SignInSide = React.lazy(() => import('./features/auth/login'));
const Admin = React.lazy(() => import('./features/admin/Admin'));
const BrowseCollectionsFeature = React.lazy(() => import('./features/admin/features/projects/features/models/features/model/features/collections/components/BrowseCollectionsFeature'))
const ProductOnboarding = React.lazy(() => import('./features/productOnboarding/productOnboarding'));

export default function Routes() {
    const NotFound = () => <div>Not found</div>;
    const routes = [
        {
            exact: true,
            path: '/',
            component: Productkey
        },
        {   exact: true,
            path: '/homepage',
            component: () => (<>
                <ChatContextProvider>
                <DataSelectorProvidor>
                    <RLEFHomePage />
                </DataSelectorProvidor>
                </ChatContextProvider>
            </>)
        },
        {
            exact: true,
            path: '/chat',
            component: ChatComponent
        },
        {
            exact: true,
            path: '/login',
            component: SignInSide
        },
        {
            path: '/admin',
            component: Admin
        },
        {
            path: '/productOnboarding',
            component: ProductOnboarding
        },

        {
            path: '/reference-guide',
            render: (props: RouteComponentProps) => {
                // Extract modelId from URL query parameters
                const modelId = (new URLSearchParams(props.location.search).get('modelId') || '') as string;
                
                // Render GetReferenceData component with the extracted modelId
                return <GetReferenceData modelId={modelId} />;
            }
        },
        // {
        //     path: '*',
        //     component: NotFound
        // }
        {
            exact: true,
            path: '/browseCollectionsPopupFeature',
            component: BrowseCollectionsFeature
        },
    ];
    return (
        <>
        <ModelistContextComponent>
        <TrainingPodSessionContextComponent>
        <BrowseModelCollectionsPopupContextComponent>
        <BrowseCollectionsPopupContextComponent>
        <ChatContextProvider>
        <Suspense fallback={""}>
            <BrowserRouter>
                <ErrorBoundary>
                    <Switch>
                            <>
                                {routes.map((route, index) => <Route {...route} key={index} />)}
                            </>
                    </Switch>
                </ErrorBoundary>
            </BrowserRouter >
        </Suspense>
        </ChatContextProvider>
        </BrowseCollectionsPopupContextComponent>
        </BrowseModelCollectionsPopupContextComponent>
        </TrainingPodSessionContextComponent>
        </ModelistContextComponent>
        </>
    )
}
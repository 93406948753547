import { Autocomplete } from '@material-ui/lab';
import { TextField, Tooltip } from "@material-ui/core";
import { ModelAnnotationLabelAvailable } from "../../../../../../../../../../../../common";
import { useState } from 'react';

export const LabelField = (props: {
    modelAnnotationLabelsAvailable: ModelAnnotationLabelAvailable[];
    audioId: string,
    update: Function,
    label: string,
    disabled?: boolean,
    }) => {
        
    const [annotationLabelValue, setAnnotationLabelValue] = useState(props.label || "");
    const [annotationLabelError, ] = useState(false);
    
    return (
        <div>
            <Autocomplete
                disabled={Boolean(props.disabled)}
                id="audioLabel"
                options={props.modelAnnotationLabelsAvailable.map(
                    (annotationLabel) => annotationLabel.value
                )}
                value={annotationLabelValue}
                renderInput={(params) => {
                    return (
                        <Tooltip title={annotationLabelValue}>
                            <TextField {...params} size="small" 
                            // label="Label"
                            error={annotationLabelError}
                            helperText={annotationLabelError ? "Label is required" : ""}
                            // onBlur={(e) => {
                            //     setAnnotationLabelError(!e.target.value.trim()) 
                            // }}
                            />
                        </Tooltip>
                    )
                }} 
                onChange={(event, value) => {
                    if(value?.trim() && value?.trim() !== ""){
                        setAnnotationLabelValue(value.trim() || "")
                        props.update(value.trim(), props.audioId)
                    }
                    else{
                        // setAnnotationLabelError(true) 
                    }
                }}
                />
        </div>
    );
};

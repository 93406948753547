import { LinearProgress, makeStyles, Theme } from "@material-ui/core"


const useStyles = makeStyles((theme: Theme) => ({
    container: {
        width: 'inherit',
        position: 'absolute',
        top: '10px',
        background: "transparent linear-gradient(0deg, #00000000 0%, #000000BF 100%) 0% 0% no-repeat padding-box",
        borderRadius: "10px",
    },
    progressContainer: {
        display: 'flex',
        flexDirection: 'row',
        width: 'inherit',
        padding: '10px 0',
    },
    progress: {
        height: '5px',
        flexGrow: 1,
        borderRadius: '4px',
        margin: '0 5px',
        display: 'flex',
        backgroundRepeat: 'no-repeat',
        backgroundSize: "200%",
        backgroundPosition: '100% 50%'
    },
    progressBg: {
        backgroundColor: '#cccccd',
    }
}));

export const MultipleVideoView = (props: { videoList: Array<any>, currentItem: number, currentVideoPlayValue: number}) => {
    const classes = useStyles();
    const progressValue = (index: number) => {
        if (index + 1 === props.currentItem) {
            return props.currentVideoPlayValue
        } else if (index + 1 < props.currentItem) {
            return 100
        } else {
            return 0;
        }
    }
    return (
        <div className={classes.container}>
            <div className={classes.progressContainer}>
                {
                    props.videoList.map((video, index) => {
                        return <LinearProgress key={index} variant="determinate" classes={{ root: classes.progress, colorPrimary: classes.progressBg }} value={progressValue(index)} />
                    })
                }
            </div>
        </div>
    )
}
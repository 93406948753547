import React, { Component, useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { I18nextProvider } from 'react-i18next';
import { normalize, denormalize, schema } from 'normalizr';
import css from "./twoDimensionalImage.module.css"
import {
	Button,
	ButtonGroup,
} from 'reactstrap';
// import 'bootstrap/dist/css/bootstrap.css';
import '../../../../../../../../assets/css/twoDimensionalImageBoostrap.css';
import './twoDimensionalImage.scss';
import { MdAdd, MdAutorenew } from 'react-icons/md';
import { FaCommentAlt } from 'react-icons/fa';
import { UndoRedo } from '../../../../models/UndoRedo';
import { highContrastingColors as colors } from '../../../../shared/utils/colorUtils.js';
import { getRandomInt } from '../../../../shared/utils/mathUtils.js';
import { Polygon } from '../../models/polygon';
import { Vertex } from '../../models/vertex';
import { getUniqueKey } from '../../utils/utils';
import MagnifierDropdown from '../MagnifierDropdown/MagnifierDropdown.jsx';
import TwoDimensionalImageContext from './twoDimensionalImageContext';
import AnnotationList from '../AnnotationList/AnnotationList.jsx';
import UndoRedoButton from '../UndoRedoButton/UndoRedoButton.jsx';
import {Button as MuiButton, Dialog, Typography, DialogContent, DialogTitle} from '@material-ui/core';
import Canvas from '../Canvas/Canvas.jsx';
import i18nextInstance from './i18n';
import { themePrimaryMainColor } from '../../../../../../../../theme';
import InfoIcon from '@material-ui/icons/Info';
import { generateUniqueId } from '../../../../../../../../services/idHelperService';
import { removeDuplicateElementsFromArrayByElementId } from '../../../../../../../../services/arrayHelperService';
import { setImageAnnotationsInAnnotationComponentForSavingInDBIfModelTypeImageAnnotation, setImageGroupAnnotationsInAnnotationComponentForSavingInDBIfModelTypeImageGroupAnnotation, setResourceImageAnnotationsForSendingToAnnotationComponent } from '../../../../../../../../services/imageAnnotationHelperService';
import { copyByValue, isNullOrUndefined } from '../../../../../../../../services/variableHelperService';
import { Menu, MenuItem, Tooltip } from '@material-ui/core';
import { Alert } from '@material-ui/lab';
import FullscreenIcon from '@material-ui/icons/Fullscreen';
import FullscreenExitIcon from '@material-ui/icons/FullscreenExit';
// import { prototype } from 'events';
import { convertJsonToArrayOfKeyValuePair } from '../../../../../../../../services/jsonHelperService';
import {ReactComponent as IconZoomIn} from "../../../../../../../../assets/svg/Icon-ZoomIn.svg"
import { getImageScaleFactorForImageWidth, getMaxImageWidthHeightWhichWeCanSet } from '../../../../../../../../services/imageHelperService';
import ImageService from '../../../../../../../../services/ImageService'
import { CircularProgress } from '@material-ui/core';
import ReferenceDataPopup from '../../../../../../../../features/admin/features/projects/features/models/features/model/features/collections/components/ReferenceDataPopup';

const SHORTCUTS = {
	MAGNIFIER: {
		'1X': { key: '1', code: 49 },
		'2X': { key: '2', code: 50 },
		'3X': { key: '3', code: 51 },
		'4X': { key: '4', code: 52 },
	},
	BUTTON: {
		ADD: { key: 'c', code: 67 },
		PREVIOUS: { key: 's', code: 83 },
		NEXT: { key: 'd', code: 68 },
		SKIP: { key: 'a', code: 65 },
		RIGHTARROW:{key: 'rightArrow', code: 39 },
		LEFTARROW:{key: 'leftArrow', code: 37 },
		TOGGLE_LABEL: { key: 'shift', code: 16 },
	},
	UNDO_REDO: {
		UNDO: { key: 'z', code: 90 },
		REDO: { key: 'x', code: 88 },
	},
};

const CanvasContextMenu = ({
	open=false,
	xCoordinate,
	yCoordinate,
	handleShowMetadata,
	handleReferenceDataPopup,
	handleOnClose,
	scenario,
	handlePlayAudioRegionOnGroundTruth,
	id,
	modelInformation,
	isLabelInReferenceData
}) => {
	return (
		<Menu
		open={open}
		onClose={()=>{handleOnClose()}}
		anchorReference="anchorPosition"
		anchorPosition={
			yCoordinate !== null && xCoordinate !== null
				? { top: yCoordinate, left: xCoordinate }
				: undefined
		}
	>
		<MenuItem onClick={()=>handleShowMetadata()}>Show Metadata</MenuItem>
		{ modelInformation?.model?.referenceData && modelInformation?.model?.referenceData.length > 0 && isLabelInReferenceData &&
			<MenuItem onClick={() => handleReferenceDataPopup()}>Compare annotation with reference data</MenuItem>
		}
		{scenario === "spectrogramAudio"&&<MenuItem onClick={()=>{
			handlePlayAudioRegionOnGroundTruth(id)}}>Play Audio</MenuItem>}
	</Menu>	
	);
}

// component to expand the modal image
const ExpandedImageDialog = ({ imageUrl, imageAnnotations, onClose }) => {
	const containerId = "containerId"
	const [isImageAnnotationsScalingGoingOn, setIsImageAnnotationsScalingGoingOn] = useState(false);
	const [imageWidthForTwoDimensionalImage, setImageWidthForTwoDimensionalImage] = useState(0)

	const [imageAnnotationsForTwoDimensionalImage, setImageAnnotationsForTwoDimensionalImage] = useState([])
	useEffect(() => {
		const executeFunction = async () => {
			setIsImageAnnotationsScalingGoingOn(true)
			const image = await ImageService.getImageWidthHeightFromURL(imageUrl || "");
			const imageContainerElement = document.getElementById(containerId);


			const imageMaxWidthAllowed = imageContainerElement?.offsetWidth || 400;
			const imageMaxHeightAllowed = imageContainerElement?.offsetHeight || 400;

			const { imageWidthToSet } = getMaxImageWidthHeightWhichWeCanSet({
				originalImageWidth: image.width,
				originalImageHeight: image.height,
				maxImageWidthAllowed: imageMaxWidthAllowed,
				maxImageHeightAllowed: imageMaxHeightAllowed
			})

			const imageScaleFactor = getImageScaleFactorForImageWidth(
				image.width,
				imageWidthToSet
			);

			const imageAnnotationsForTwoDimensionalImage = setResourceImageAnnotationsForSendingToAnnotationComponent(
				imageAnnotations || [],
				imageScaleFactor
			);

			setImageAnnotationsForTwoDimensionalImage(imageAnnotationsForTwoDimensionalImage);

			setImageWidthForTwoDimensionalImage(imageWidthToSet);

			setIsImageAnnotationsScalingGoingOn(false)

		}

		if (imageUrl) {
			executeFunction()
		}

	}, [imageAnnotations, imageUrl])


	return <Dialog open maxWidth='md' onClose={onClose}
		style={{ minWidth: '300px', position:"relative" }}> 
		<DialogContent style={{ minWidth: "400px" }}>
			<div id={containerId} style={{
				width: "58vw",
				height: `80vh`,
			}}>
			{isImageAnnotationsScalingGoingOn ? <div style={{ width: "100%", height: "50vh", display: "flex", justifyContent: "center", alignItems: "center" }}><CircularProgress /></div> : 
				<SegmentationAnalyticsTwoDimensionalImage url={imageUrl} imageWidth={imageWidthForTwoDimensionalImage} defaultAnnotations={imageAnnotationsForTwoDimensionalImage} showExpandIcon={false} isLabelOn={true}/>
			}
			</div>
		</DialogContent>
	</Dialog>
}


class SegmentationAnalyticsTwoDimensionalImage extends Component {
	constructor(props) {
		super(props);
		const {
			defaultAnnotations,
			defaultAnnotationGroups,
			isLabelOn,
			imageWidth,
			fullScreenMode
		} = props;

		const entities = { options: {}, annotations: {}, annotationGroups: [] };
		let rootOptionId = '';
		let annotations = [];
		// const imageWidth = fullScreenMode ? window.innerWidth - 100 : imageWidthProp
		// normalize
		if (props.options && Object.keys(props.options).length !== 0) {
			const option = new schema.Entity('options');
			const children = new schema.Array(option);
			option.define({ children });
			const normalizedOptions = normalize(props.options, option);
			entities.options = normalizedOptions.entities.options;
			rootOptionId = normalizedOptions.result;
		} else {
			rootOptionId = '0';
			entities.options['0'] = { id: '0', value: 'root', children: [] };
		}

		if (defaultAnnotations && defaultAnnotations.length !== 0) {
			const annotation = new schema.Entity('annotations');
			const normalizedAnn = normalize(defaultAnnotations, [annotation]);
			entities.annotations = normalizedAnn.entities.annotations;
			annotations = normalizedAnn.result;
		}

		if (defaultAnnotationGroups && defaultAnnotationGroups.length !== 0) {
			entities.annotationGroups = defaultAnnotationGroups;
		}

		this.state = {
			isAdding: false,
			focusedName: '',
			focusedGroupAnnotationId: "",
			magnifyingPower: 1,
			isLabelOn,
			entities,
			customizedOptionInputFocused: false,
			rootOptionId,
			imageScaleFactor: 1,
			imageHeight: 0,
			imageWidth,
			fullScreenMode,
			annotations,
			isVertexBeingRemoved: false,
			canvasContextMenu: {
				isOpen: false,
				xCoordinate: 0,
				yCoordinate: 0,
				event: null				
			},
			isMetadataDialogOpen: false,
			stageScale: 1,
			naturalWidth:0,
			naturalHeight:0,
			mouseAnnotationCoordinates: {
				xAxis: 0,
				yAxis: 0
			},
			isImageExpanded: false
		};
		this.UndoRedoState = new UndoRedo();
	}

	componentDidMount = () => {
		document.addEventListener('keydown', this.handleKeydown, false);
	}

	triggerAnnotationsChangedByUserEvent() {
		this.handleSubmit("onAnnotationsChange")
	}

	componentDidUpdate(prevProps, prevState) {
		// Object.entries(this.props).forEach(([key, val]) =>
		// 	prevProps[key] !== val && console.log(`Prop '${key}' changed`)
		// );
		// if (this.state) {
		// 	Object.entries(this.state).forEach(([key, val]) =>
		// 		prevState[key] !== val && console.log(`State '${key}' changed`)
		// 	);
		// }

		if (prevProps.resourceId !== this.props.resourceId) {
			this.setStageScale(1);
		}

		if (prevState.imageWidth !== this.state.imageWidth) {
			this.setState({imageScaleFactor: this.state.imageWidth / this.state.naturalWidth})
		}

		if (
			JSON.stringify(prevProps.options || {}) !== JSON.stringify(this.props.options || {}) ||
			JSON.stringify(prevProps.defaultAnnotations || []) !== JSON.stringify(this.props.defaultAnnotations || []) ||
			JSON.stringify(prevProps.defaultAnnotationGroups || []) !== JSON.stringify(this.props.defaultAnnotationGroups || [])
		) {
			this.setLatestAnnotationsFromResource();
		}


		if (prevProps.fullScreenMode !== this.props.fullScreenMode) {
			this.setState({fullScreenMode: this.props.fullScreenMode})
		}

		if (prevProps.imageWidth !== this.props.imageWidth) {
			this.setState({imageWidth: this.props.imageWidth})
		}

		if (prevProps.isLabelOn !== this.props.isLabelOn) {
			this.setState({isLabelOn: this.props.isLabelOn})
		}

		if (prevProps.fullScreenMode !== this.props.fullScreenMode) {
			this.setState({fullScreenMode: this.props.fullScreenMode})
		}
	
		if (prevProps.focusedName !== this.props.focusedName) {
			this.setState({focusedName: this.props.focusedName})
		}
	}

	setLatestAnnotationsFromResource() {
		const {
			defaultAnnotations,
			defaultAnnotationGroups,
			options
		} = this.props;

		const entities = { options: {}, annotations: {}, annotationGroups: [] };
		let rootOptionId = '';
		let annotations = [];

		// normalize
		if (options && Object.keys(options).length !== 0) {
			const option = new schema.Entity('options');
			const children = new schema.Array(option);
			option.define({ children });
			const normalizedOptions = normalize(options, option);
			entities.options = normalizedOptions.entities.options;
			rootOptionId = normalizedOptions.result;
		} else {
			rootOptionId = '0';
			entities.options['0'] = { id: '0', value: 'root', children: [] };
		}	
		
		if (defaultAnnotations && defaultAnnotations.length !== 0) {
			const annotation = new schema.Entity('annotations');
			const normalizedAnn = normalize(defaultAnnotations, [annotation]);
			entities.annotations = normalizedAnn.entities.annotations;
			annotations = normalizedAnn.result;
		}	
		
		if (defaultAnnotationGroups && defaultAnnotationGroups.length !== 0) {
			entities.annotationGroups = defaultAnnotationGroups;
		}
		
		this.setState({
			entities: {...entities},
			rootOptionId,
			annotations: [...annotations]
		})

		this.UndoRedoState = new UndoRedo();
	}

	componentWillUnmount = () => {
		if (document.body.style?.cursor === "crosshair") {
			document.body.style.cursor="default";
		}
		document.removeEventListener('keydown', this.handleKeydown, false);
	}

	componentWillReceiveProps(nextProps) {
		this.setState({ imageWidth: nextProps.imageWidth , fullScreenMode: nextProps.fullScreenMode });  
	  }

	/**
	 * @param {number} scaleToSet 
	 */
	setStageScale = (scaleToSet) => {
		if (scaleToSet !== 1) {
			/**
			 * Resetting magnifier property to default as magnifier doesn't work properly
			 * on zoomed image
			 */
			this.handleMagnifierChange(1);
		}
		this.setState({stageScale: scaleToSet})
	}

	/* ==================== shortkey ==================== */
	handleKeydown = (e) => {
		const { onPreviousClick, onSkipClick, onNextClick } = this.props;
		const { customizedOptionInputFocused } = this.state;
		if (customizedOptionInputFocused) return;
		// console.log("key down on Two D",e.keyCode);
		const focusedElement = document.activeElement;
		if (
			focusedElement.tagName.toLowerCase() === "textarea" ||
			(focusedElement.tagName.toLowerCase() === "input" &&
			(focusedElement.type.toLowerCase() === "number" || focusedElement.type.toLowerCase() === "text")))
		{
			return;
		}

		switch (e.keyCode) {
		case SHORTCUTS.UNDO_REDO.UNDO.code:
			this.handleUndoClick();
			break;
		case SHORTCUTS.UNDO_REDO.REDO.code:
			this.handleRedoClick();
			break;
		case SHORTCUTS.BUTTON.TOGGLE_LABEL.code:
			this.handleToggleLabel();
			break;
		case SHORTCUTS.BUTTON.ADD.code:
			this.handleAddClick();
			break;
		case SHORTCUTS.BUTTON.PREVIOUS.code:
			if (onPreviousClick) this.handleSubmit('Previous');
			break;
		case SHORTCUTS.BUTTON.SKIP.code:
			if (onSkipClick) this.handleSubmit('Skip');
			break;
		case SHORTCUTS.BUTTON.NEXT.code:
			if (onNextClick) this.handleSubmit('Next');
			break;
		case SHORTCUTS.BUTTON.RIGHTARROW.code:
			if (onNextClick && this.props.scenario==="copilotImageAnnotationPopUp") this.handleSubmit('Next');
			break;
		case SHORTCUTS.BUTTON.LEFTARROW.code:
			if (onPreviousClick  && this.props.scenario==="copilotImageAnnotationPopUp") this.handleSubmit('Previous');
			break;
		case SHORTCUTS.MAGNIFIER['1X'].code:
			this.handleMagnifierChange(1);
			break;
		case SHORTCUTS.MAGNIFIER['2X'].code:
			this.handleMagnifierChange(2);
			break;
		case SHORTCUTS.MAGNIFIER['3X'].code:
			this.handleMagnifierChange(3);
			break;
		case SHORTCUTS.MAGNIFIER['4X'].code:
			this.handleMagnifierChange(4);
			break;
		default:
		}
	}

	updateCanvasContextMenuState = (canvasContextMenuState) => {
		this.setState(prevState=>{
			return {
				...prevState,
				canvasContextMenu: {
					...prevState.canvasContextMenu,
					...canvasContextMenuState
				}
			}
		})
	}

	/* ==================== control ==================== */
	handleMagnifierChange = (power) => {
		this.setState({ magnifyingPower: power });
	}

	handleToggleLabel = () => {
		this.setState(prevState => ({ isLabelOn: !prevState.isLabelOn }));
	}

	handleAddClick = () => {
		this.setState(prevState => ({ isAdding: !prevState.isAdding, focusedName: '', focusedGroupAnnotationId: '' }));
	}

	/**
	 * @param {string} annotationGroupId 
	 */
	handleAddImageAnnotationInExistingGroupButtonClick = (annotationGroupId) => {
		this.setState(prevState => ({ isAdding: !prevState.isAdding, focusedName: '', focusedGroupAnnotationId: annotationGroupId }));
	}

	/* ==================== undo/redo ==================== */
	handleUndoClick = () => {
		if (this.UndoRedoState.previous.length === 0) return;
		this.setState((prevState) => {
			const state = this.UndoRedoState.undo(prevState);
			return { ...state };
		});
	}

	handleRedoClick = () => {
		if (this.UndoRedoState.next.length === 0) return;
		this.setState((prevState) => {
			const state = this.UndoRedoState.redo(prevState);
			return { ...state };
		});
	}

	/* ==================== canvas ==================== */
	handleCanvasImgLoad = (e) => {
		const { imageWidth } = this.state;
		const { target } = e;
		console.log(imageWidth, target.naturalWidth, target.naturalHeight, target.height)
		this.setState({ imageScaleFactor: imageWidth / target.naturalWidth, imageHeight: target.height, naturalWidth: target.naturalWidth, naturalHeight: target.naturalHeight });
	}

	handleCanvasStageMouseDown = (e) => {

		/** @type {import('../../../../../../../../common').IModelType} */
		const modelType = this.props.modelType;

		const stage = e.target.getStage();
		const uniqueKey = getUniqueKey();
		const uniqueAnnotationGroupId = generateUniqueId();
		const color = colors[getRandomInt(colors.length)];
		// let { x, y } = stage.getPointerPosition();
		let { x, y } = stage.getRelativePointerPosition();

		let vertices;
		this.setState((prevState) => {
			const {
				isAdding, focusedName, annotations, entities, imageWidth, imageHeight, focusedGroupAnnotationId
			} = prevState;
			if (!isAdding) return {};
			// prevent x, y exceeding boundary
			x = x < 0 ? 0 : x; x = x > imageWidth ? imageWidth : x;
			y = y < 0 ? 0 : y; y = y > imageHeight ? imageHeight : y;
			this.UndoRedoState.save(prevState);
			// first time adding
			if (!focusedName) {
				vertices = [];
				vertices.push(Vertex({
					id: `${uniqueKey}`, name: `${uniqueKey}`, x, y,
				}));
				entities.annotations[`${uniqueKey}`] = Polygon({
					id: `${uniqueKey}`, name: `${uniqueKey}`, color, vertices,
				});
				if (modelType === 'imageAnnotationGroup') {
					entities.annotations[`${uniqueKey}`].groupAnnotationId = focusedGroupAnnotationId || uniqueAnnotationGroupId;
				}
				if (isNullOrUndefined(entities.annotations[`${uniqueKey}`]?.confidenceScore)) {
					entities.annotations[`${uniqueKey}`].confidenceScore = 100;
				}
				return {
					focusedName: `${uniqueKey}`,
					focusedGroupAnnotationId: 
						modelType === 'imageAnnotationGroup'
							?	focusedGroupAnnotationId || uniqueAnnotationGroupId
							: "",
					annotations: [...annotations, `${uniqueKey}`],
					entities: { 
						...entities, 
						annotations: entities.annotations,
						annotationGroups: 
							modelType === 'imageAnnotationGroup'
							?	removeDuplicateElementsFromArrayByElementId([...entities.annotationGroups, {id: focusedGroupAnnotationId || uniqueAnnotationGroupId, name: ""}])
							:	""
					},
				};
			}
			// continuing adding
			entities.annotations?.[focusedName]?.vertices.push(Vertex({
				id: `${uniqueKey}`, name: `${uniqueKey}`, x, y,
			}));
			return { entities: { ...entities, annotations: entities.annotations } };
		}, ()=>{
			this.triggerAnnotationsChangedByUserEvent();
		});
	}

	handleCanvasVertexRemove = (e) => {
		/**
		 * This function will remove the activeVertex
		 */

		if (this.props.isViewOnlyMode) {
			return;
		}

		const activeVertex = e.target;
		const group = activeVertex.getParent();
		this.setState((prevState) => {
			const { focusedName, entities } = prevState;
				const { annotations } = entities;
				if (group.name() === focusedName) {

				if (annotations[focusedName] && isNullOrUndefined(annotations[focusedName]?.confidenceScore)) {
					annotations[focusedName].confidenceScore = 100;
				}

					for (let vertexIndex = 0; vertexIndex < annotations[focusedName].vertices.length; vertexIndex++) {
						const vertex = annotations[focusedName].vertices[vertexIndex];
						if (
							vertex.x === activeVertex.attrs.x &&
							vertex.y === activeVertex.attrs.y
						) {
							annotations[focusedName].vertices.splice(vertexIndex, 1)
							if (annotations[focusedName].vertices.length === 2) {
								annotations[focusedName].isClosed = false;
							}
							break;
						}
					}
				}
				return {
					...prevState,
					entities: {
						...prevState.entities,
						annotations
					}
				}
		}, ()=>{
			this.triggerAnnotationsChangedByUserEvent();
		});
	}

	handleCanvasVertexExtendInAnnotation = async (e) => {
		if (this.props.isViewOnlyMode) {
			return;
		}

		const activeVertex = e.target;
		const group = activeVertex.getParent();

		if (!group) {
			return;
		}

		this.setState((prevState) => {
			const { focusedName, entities } = prevState;
				const { annotations } = entities;

			if (group.name() === focusedName) {
				/**
				 * making annotation a line if its a polygon
				 */
				entities.annotations[focusedName].isClosed = false
			}	

			if (entities.annotations[focusedName] && isNullOrUndefined(entities.annotations[focusedName]?.confidenceScore)) {
				entities.annotations[focusedName].confidenceScore = 100;
			}			
			
			for (let vertexIndex = 0; vertexIndex < annotations[focusedName].vertices.length; vertexIndex++) {
				const vertexWhichShouldGoToEnd = copyByValue(annotations[focusedName].vertices[vertexIndex]);
				if (
					vertexWhichShouldGoToEnd.x === activeVertex.attrs.x &&
					vertexWhichShouldGoToEnd.y === activeVertex.attrs.y
				) {

					/**
					 * making vertex which user selected to become the last vertex in vertices array and shifting all other vertices accordingly
					 * for eg: if array elements are ['a','b','c','d'] and user selected
					 * 'c' then array elements order after shifting will be: ['d','a','b','c']
					 */
					annotations[focusedName].vertices = annotations[focusedName].vertices.concat(annotations[focusedName].vertices.splice(0,vertexIndex + 1));


					/**
					 * as per library the first vertex id and name should be equal to annotation id.
					 * therefore making changes below for that
					 */
					for (const vertex of annotations[focusedName].vertices) {
						if (vertex.id === annotations[focusedName].id) {
							vertex.id = copyByValue(annotations[focusedName].vertices[0].id);
							vertex.name = copyByValue(annotations[focusedName].vertices[0].name);
							break;
						}
					}
					annotations[focusedName].vertices[0].id = copyByValue(annotations[focusedName].id);
					annotations[focusedName].vertices[0].name = copyByValue(annotations[focusedName].id);

					break;
				}
			}			

			return {
				...prevState,
				isAdding: true,
				entities: {
					...entities
				}
			}
		}, ()=>{
			this.triggerAnnotationsChangedByUserEvent();
		});		
	}
	handleViewAnnotation = async (e) => {

		/**
		 * This function is used to scroll to particular annotation in annotation list
		 */

		if (this.props.isViewOnlyMode) {
			return;
		}

		const activeVertex = e.target;
		const group = activeVertex.getParent();

		if (!group || !group.name()) {
			return;
		}

		/** @type {string} */
		const groupName = group.name();

		/**
		 * waiting for few seconds to get view updated before we perform manual scroll
		 */
		await new Promise((resolve, reject)=>{
			setTimeout(() => {
				resolve();
			}, 500);
		})

		/** @type {HTMLElement} */
		const annotationHtmlElement = document.getElementsByName(`${groupName}`)[0];

		if (!annotationHtmlElement) {
			return;
		}

		const annotationListElement = document.getElementById("annotation-list");
		if (!annotationListElement) {
			return;
		}

		/**
		 * performing calculations to get better view of annotation to which user wants to scroll
		 */
		annotationListElement.scrollTop = 
			(annotationHtmlElement.offsetTop - 96) > annotationListElement.scrollTop
			? annotationHtmlElement.offsetTop - 10
			: annotationHtmlElement.offsetTop - 50

	}

	handleCanvasVertexMouseDown = (e) => {
		const activeVertex = e.target;
		const group = activeVertex.getParent();
		this.setState((prevState) => {
			const { isAdding, focusedName, entities } = prevState;
			if (isAdding) {
				const { annotations } = entities;
				if (group.name() === focusedName && annotations[focusedName].vertices[0].name === activeVertex.name()) {
					annotations[focusedName].isClosed = true;

				if (isNullOrUndefined(annotations[focusedName]?.confidenceScore)) {
					annotations[focusedName].confidenceScore = 100;
				}

					return { isAdding: false, entities: { ...entities, annotations } };
				}
				return {};
			}
			return { focusedName: group.name() };
		}, ()=>{
			this.triggerAnnotationsChangedByUserEvent();
		});
	}

	handleCanvasVertexDragEnd = (e) => {
		const activeVertex = e.target;
		const group = activeVertex.getParent();
		this.setState((prevState) => {
			const {
				isAdding, entities, imageWidth, imageHeight,
			} = prevState;
			if (isAdding) return {};
			const { annotations } = entities;
			const vertices = annotations[group.name()].vertices.map((v) => {
				if (v.name !== activeVertex.name()) return v;
				// prevent x, y exceeding boundary
				let x = activeVertex.x(); let y = activeVertex.y();
				x = x < 0 ? 0 : x; x = x > imageWidth ? imageWidth : x;
				y = y < 0 ? 0 : y; y = y > imageHeight ? imageHeight : y;
				return { ...v, x, y };
			});
			annotations[group.name()].vertices = vertices;

			if (isNullOrUndefined(annotations[group.name()]?.confidenceScore)) {
				annotations[group.name()].confidenceScore = 100;
			}

			return { entities: { ...entities, annotations } };
		}, ()=>{
			this.triggerAnnotationsChangedByUserEvent();
		});
	}

	handleCanvasFocusing = (e) => {
		const activeShape = e.target;
		this.setState((prevState) => {
			if (prevState.isAdding) return {};
			return { focusedName: activeShape.name() };
		});
	}

	/* ==================== anootation list ==================== */
	handleAnnotationClick = (name) => { 
		if (this.state.focusedName === name) {
			this.setState({ focusedName: "" }); 
		} else {
			this.setState({ focusedName: name }); 
		}
	};

	handleAnnotationDeleteClick = (name) => {
		this.setState((prevState) => {
			const { entities } = prevState;
			const { annotations } = entities;
			delete annotations[name];
			const i = prevState.annotations.indexOf(name);
			prevState.annotations.splice(i, 1);
			return { annotations: prevState.annotations, entities: { ...entities, annotations } };
		}, ()=>{
			this.triggerAnnotationsChangedByUserEvent();
		});
	}

	/**
	 * @param {string} annotationGroupIdToDelete 
	 */
	handleAnnotationGroupDeleteClick = (annotationGroupIdToDelete) => {
		const {annotations, annotationGroups} = this.state.entities;

		/** @type {string[]} */
		const annotationIds = this.state.annotations
		for (let index = 0; index < annotationGroups.length; index++) {
			const annotationGroup = annotationGroups[index];
			if (annotationGroup.id === annotationGroupIdToDelete) {
				annotationGroups.splice(index, 1);
			}
		}
		for (let index = 0; index < Object.keys(annotations).length; index++) {
			const annotatationId = Object.keys(annotations)[index];
			const annotation = annotations[annotatationId];
			if (annotation.groupAnnotationId === annotationGroupIdToDelete) {
				const annotationIdIndexToDelete = annotationIds.indexOf(annotatationId);
				if (annotationIdIndexToDelete !== -1) {
					annotationIds.splice(annotationIdIndexToDelete, 1);
				}
				delete annotations[annotatationId];
				index = index - 1;
			}
		}
		this.setState(oldState=>({
			...oldState,
			annotations: [...annotationIds],
			entities: {
				...oldState.entities,
				annotationGroups,
				annotations
			}
		}), ()=>{
			this.triggerAnnotationsChangedByUserEvent();
		})
	}

	/* ==================== option list ==================== */
	handleOptionCustomizedInputFocus = () => this.setState({ customizedOptionInputFocused: true });

	handleOptionCustomizedInputBlur = () => this.setState({ customizedOptionInputFocused: false });

	handleOptionCustomizedFormSubmit = (e, parentId, value) => {
		e.preventDefault();
		this.setState((prevState) => {
			const { entities } = prevState;
			const { options } = entities;
			const uniqueKey = getUniqueKey();
			options[uniqueKey] = { id: uniqueKey, value, children: [] };
			options[parentId].children.push(uniqueKey);
			return { entities: { ...entities, options } };
		}, ()=>{
			this.triggerAnnotationsChangedByUserEvent();
		});
	}

	handleOptionSelect = (name, selectedIds) => {
		this.setState((prevState) => {
			const { entities } = prevState;
			const selectedOptions = selectedIds.map(id => entities.options[id]).map(s => ({ id: s.id, value: s.value }));
			const updatedAnn = { ...entities.annotations[name], selectedOptions };
			return { entities: { ...entities, annotations: { ...entities.annotations, [name]: updatedAnn } } };
		}, ()=>{
			this.triggerAnnotationsChangedByUserEvent();
		});
	}

	/**
	 * @param {string} updatedLabel 
	 * @param {string} annotationGroupIdWhoseLabelToChange 
	 */
	handleAnnotationGroupLabelChange = (updatedLabel, annotationGroupIdWhoseLabelToChange) => {
		this.setState(oldState=>{
			const entities = copyByValue(oldState.entities);
			if (Array.isArray(entities.annotationGroups)) {
				for (const annotationGroup of entities.annotationGroups) {
					if (annotationGroup.id === annotationGroupIdWhoseLabelToChange) {
						annotationGroup.name = updatedLabel;
					}
				}
			}
			return {
				...oldState,
				entities: {
					...oldState.entities,
					...entities
				}
			}
		}, ()=>{
			this.triggerAnnotationsChangedByUserEvent();
		})
	}	

	handleOptionDeleteClick = (deleteIds) => {
		this.setState((prevState) => {
			const { entities } = prevState;
			const { options } = entities;
			delete options[deleteIds[deleteIds.length - 1]];
			const i = options[deleteIds[deleteIds.length - 2]].children.indexOf(deleteIds[deleteIds.length - 1]);
			options[deleteIds[deleteIds.length - 2]].children.splice(i, 1);
			return { entities: { ...entities, options } };
		}, ()=>{
			this.triggerAnnotationsChangedByUserEvent();
		});
	}


	/* ==================== submit ==================== */

	/**
	 * @param {"onAnnotationsChange" | "Skip" | "Save" | "Previous" | "Next"} type 
	 */
	handleSubmit = (type) => {
		const {
			imageScaleFactor, imageWidth, imageHeight, annotations, entities, rootOptionId,
		} = this.state;
		const { annotationGroups } = entities;
		const { url, onSkipClick, onPreviousClick, onNextClick, onSaveClick } = this.props;

		/** @type {import('../../../../../../../../common').IModelType} */
		const modelType = this.props.modelType;

		const annotation = new schema.Entity('annotations');
		let denormalizedAnnotations = denormalize({ annotations }, { annotations: [annotation] }, entities).annotations;
		const option = new schema.Entity('options');
		const children = new schema.Array(option);
		option.define({ children });
		const denormalizedOptions = denormalize({ options: rootOptionId }, { options: option }, entities).options;

		const imageGroupAnnotations = 
		 modelType === 'imageAnnotationGroup'
		 	? setImageGroupAnnotationsInAnnotationComponentForSavingInDBIfModelTypeImageGroupAnnotation(
					denormalizedAnnotations,
					annotationGroups,
					imageScaleFactor
				)
			:	[];

			denormalizedAnnotations =
				modelType === 'imageAnnotation'
					?	setImageAnnotationsInAnnotationComponentForSavingInDBIfModelTypeImageAnnotation(
							denormalizedAnnotations,
							imageScaleFactor
						)
					: [];
		
					if (type === "Save") {
						if (this.state.isAdding) {
							this.setState({isAdding: false})
						}
					}

		switch (type) {
		case 'Skip':
			onSkipClick({
				url, imageScaleFactor, imageWidth, imageHeight, annotations: denormalizedAnnotations, options: denormalizedOptions,
			});
			break;
		case 'Save':
			onSaveClick({
				url, imageScaleFactor, imageWidth, imageHeight, annotations: denormalizedAnnotations, imageGroupAnnotations: imageGroupAnnotations, options: denormalizedOptions,
			});
			break;
		case 'Previous':
			onPreviousClick({
				url, imageScaleFactor, imageWidth, imageHeight, annotations: denormalizedAnnotations, options: denormalizedOptions,
			});
			break;
		case 'Next':
			onNextClick({
				url, imageScaleFactor, imageWidth, imageHeight, annotations: denormalizedAnnotations, options: denormalizedOptions,
			});
			break;
		case "onAnnotationsChange":
			if (this.props.onAnnotationsChange) {
				this.props.onAnnotationsChange({annotations: denormalizedAnnotations, imageGroupAnnotations: imageGroupAnnotations})
			}
			break;
		default:
			break;
		}
	}


	handleShowMetadata = () => {
		this.setState({
			isMetadataDialogOpen: true
		})
	}

	handleReferenceDataPopup = () => {
		this.props.setIsReferenceDataDialogOpen(true)
	}

	/**
	 * @param {number} xAxis 
	 * @param {number} yAxis 
	 */
	setMouseAnnotationCoordinates = (xAxis, yAxis) => {
		this.setState({
			mouseAnnotationCoordinates: {
				xAxis,
				yAxis
			}
		})
	}

	render() {
		const {
			isAdding,
			focusedName,
			focusedGroupAnnotationId,
			magnifyingPower,
			isLabelOn,
			imageWidth,
			fullScreenMode,
			imageHeight,
			annotations,
			entities,
			rootOptionId
		} = this.state;
		const {
			className,
			url,
			emptyAnnotationReminderText,
			isDynamicOptionsEnable,
			disabledOptionLevels,
			isViewOnlyMode,
			hasPreviousButton,
			hasNextButton,
			hasSaveButton,
			hasSkipButton,
			alert,
			originalAnnotations,
			showExpandIcon
		} = this.props;
		const twoDimensionalImageContext = {
			url,
			isAdding,
			entities,
			isViewOnlyMode,
			canvasStageHeight: this.props.canvasStageHeight,
			annotations,
			height: imageHeight,
			width: imageWidth,
			settingManuallyCanvasWidth : this.props.settingManuallyCanvasWidth,
			focusedName,
			focusedGroupAnnotationId,
			isLabelOn,
			magnifyingPower,
			modelType: this.props.modelType,
			emptyAnnotationReminderText,
			onAnnotationClick: this.handleAnnotationClick,
			onAnnotationDeleteClick: this.handleAnnotationDeleteClick,
			isDynamicOptionsEnable,
			disabledOptionLevels,
			onOptionSelect: this.handleOptionSelect,
			onOptionDeleteClick: this.handleOptionDeleteClick,
			onOptionCustomizedInputFocus: this.handleOptionCustomizedInputFocus,
			onOptionCustomizedInputBlur: this.handleOptionCustomizedInputBlur,
			onOptionCustomizedFormSubmit: this.handleOptionCustomizedFormSubmit,
			onCanvasStageMouseDown: this.handleCanvasStageMouseDown,
			onCanvasVertexMouseDown: this.handleCanvasVertexMouseDown,
			onCanvasVertexDragEnd: this.handleCanvasVertexDragEnd,
			onCanvasLabelMouseDown: this.handleCanvasFocusing,
			onCanvasLineMouseDown: this.handleCanvasFocusing,
			onCanvasImgLoad: this.handleCanvasImgLoad,
			rootOptionId,
			handleCanvasVertexRemove: this.handleCanvasVertexRemove,
			handleCanvasVertexExtendInAnnotation: this.handleCanvasVertexExtendInAnnotation,
			handleAddImageAnnotationInExistingGroupButtonClick: this.handleAddImageAnnotationInExistingGroupButtonClick, 
			handleAnnotationGroupDeleteClick: this.handleAnnotationGroupDeleteClick,
			handleAnnotationGroupLabelChange: this.handleAnnotationGroupLabelChange,
			canvasContextMenu: this.state.canvasContextMenu,
			updateCanvasContextMenuState: this.updateCanvasContextMenuState,
			scenario: this.props.scenario,
			stageScale: this.state.stageScale,
			setStageScale: this.setStageScale,
			isShowingCompressedImageBecauseOriginalImageIsDownloading: this.props.isShowingCompressedImageBecauseOriginalImageIsDownloading,
			isZoomFeatureEnabled: this.props.isZoomFeatureEnabled,
			setMouseAnnotationCoordinates: this.setMouseAnnotationCoordinates,
			imageScaleFactor: this.state.imageScaleFactor,
			originalImageWidth: this.props.originalImageWidth,
			originalImageHeight: this.props.originalImageHeight,
			naturalWidth: this.state.naturalWidth,
			naturalHeight: this.state.naturalHeight,
			maxImageHeightAllowed: this.props.maxImageHeightAllowed,
			isFullScreenMode: fullScreenMode,
			handleSyncForImages:this.props.handleSyncForImages,
			draggingCoordinates:this.props.draggingCoordinates,
			onDrag:this.props.onDrag,
			stageScaleForSync:this.props.stageScaleForSync,
			zoomCursorPosition:this.props.zoomCursorPosition,
			condition:this.props.condition,
			metadata: this.props.metadata,
		};
		document.body.style.cursor = isAdding ? 'crosshair' : 'default';

		const toggleLabelButtonUI = (
			<Button color='link' onClick={ this.handleToggleLabel } className='two-dimensional-image__label-button d-flex align-items-center'>
				<FaCommentAlt className='pr-1' />
				{isLabelOn ? 'On' : 'Off'}
				<small className='pl-1'>{`(${SHORTCUTS.BUTTON.TOGGLE_LABEL.key})`}</small>
			</Button>
		);
		const previousButtonUI = hasPreviousButton ? (
			<Button color='secondary' onClick={ () => this.handleSubmit('Previous') }>
				Previous
				<small>{`(${SHORTCUTS.BUTTON.PREVIOUS.key})`}</small>
			</Button>
		) : '';
		const ImageOptionsUI = () => {
			return (
				<div 
				className={`
					mb-3 d-flex
					${this.props.scenario === 'coPilotActivePageReadOnlyAnnotation' ? 'displayNone' : ''}
					${css.imageOptionsToolbar}
				`}
			>
				<div className='d-flex mr-auto'>
					{toggleLabelButtonUI}
					{
						!isViewOnlyMode &&
						<MagnifierDropdown
							handleChange={ this.handleMagnifierChange }
							power={ magnifyingPower }
							shortcuts={ SHORTCUTS.MAGNIFIER }
						/>
					}

					{
						this.props.isZoomFeatureEnabled &&
						<div
							style={{
								padding: "6px 12px",
								display: "flex"
							}}
						>
							<span title="Ctrl + scroll to zoom an image">Zoom:</span>&nbsp;<div title="1 is the default zoom level" style={{minWidth: "35px"}}>{this.state.stageScale?.toFixed(1)}</div>&nbsp;
							{/* <Tooltip>

							</Tooltip> */}
							{/* <span
								style={{
									cursor: "pointer"
								}}
								onClick={()=>{
									this.setStageScale(1);
								}}
								title="Reset zoom level to 1"
							><MdAutorenew /></span> */}
							<MuiButton
								// variant="contained"
								style={{
									paddingTop: 0,
									paddingBottom: 0,
									textTransform: "none",
									cursor: "pointer",
									fontSize: "16px"
								}}
								onClick={()=>{
									this.setStageScale(1)
								}}
								size="small"
								// color="secondary"
								startIcon={<MdAutorenew style={{fontSize: "15px"}} />}
							>
								Reset Zoom
							</MuiButton>							
						</div>			
					}

				{
					this.props.shouldShowMouseAnnotationCoordinates &&
					<div
						className={`${css.coordinatesSection}`}
					>
						<div title="X mouse coordinate" className={css.label}>X:&nbsp;</div> <div className={css.value}>{Math.floor(this.state.mouseAnnotationCoordinates.xAxis)}</div>
						<div title="Y mouse coordinate" className={css.label}>Y:&nbsp;</div> <div className={css.value}>{Math.floor(this.state.mouseAnnotationCoordinates.yAxis)}</div>
					</div>
				}					
				</div>
				{
					!isViewOnlyMode &&
					<UndoRedoButton
						undoRedoState={ this.UndoRedoState }
						onUndoClick={ this.handleUndoClick }
						onRedoClick={ this.handleRedoClick }
						shortcuts={ SHORTCUTS.UNDO_REDO }
					/>
				}
			</div>				
			)
		}
		const nextButtonUI = hasNextButton ? (
			<Button color='secondary' onClick={ () => this.handleSubmit('Next') }>
				Next
				<small>{`(${SHORTCUTS.BUTTON.NEXT.key})`}</small>
			</Button>
		) : '';
		const skipButtonUI = hasSkipButton ? (
			<Button color='secondary' onClick={ () => this.handleSubmit('Skip') }>
				Skip
				<small>{`(${SHORTCUTS.BUTTON.SKIP.key})`}</small>
			</Button>
		) : '';
		const saveButtonUI = hasSaveButton ? (
			<Button style={{backgroundColor: themePrimaryMainColor}} onClick={ () => this.handleSubmit('Save') }>
				Save Annotation
				{/* <small>{`(${SHORTCUTS.BUTTON.SKIP.key})`}</small> */}
			</Button>
		) : '';

		const addButtonUI = (
			<Button
				outline
				className={`d-flex align-items-center mb-3 two-dimensional-image__add-button ${css.addButton}`}
				color='primary'
				// style={{marginLeft : '16px'}}
				onClick={ () => this.handleAddClick() }
			>
				<MdAdd />
				{isAdding ? 'Adding Annotation' : 'Add Annotation'}
				<small>{`(${SHORTCUTS.BUTTON.ADD.key})`}</small>
			</Button>
		);

		const PointOptionsInfo = () => {
			return (
				<div style={{display: "flex", padding: "10px", color: "#007bff"}}>
					<InfoIcon size="small" />&nbsp;Right click on point for options
				</div>				
			)
		}

		const rootClassName = `two-dimensional-image${className ? ` ${className}` : ''}`;

		const FullScreenIconComponent = () => (
			<Tooltip title={`Open in Expand View (f)`}>
				<FullscreenIcon
				style={{fontSize: '30px'}}
				color="primary" />
			</Tooltip>
		)

		const FullScreenExitIconComponent = () => (
			<Tooltip title={`Exit Expand View (f)`}>
				<FullscreenExitIcon
				style={{fontSize: '30px'}}
				color="primary" />
			</Tooltip>
		)

		console.log("full screen mode , ", fullScreenMode)

		let metadataArray;
		if(this.state.canvasContextMenu?.metadata){
			metadataArray = JSON.parse(this.state.canvasContextMenu?.metadata)
			metadataArray = convertJsonToArrayOfKeyValuePair(metadataArray)
		}
		if(this.props.metadata){
			metadataArray = JSON.parse(this.props.metadata)
			metadataArray = convertJsonToArrayOfKeyValuePair(metadataArray)
		}


		return (
			<I18nextProvider i18n={ i18nextInstance }>
				<TwoDimensionalImageContext.Provider value={ twoDimensionalImageContext }>
					{this.state.isImageExpanded && <ExpandedImageDialog imageUrl={url} imageAnnotations={originalAnnotations} onClose={()=>this.setState({isImageExpanded: false})}/>}

					<div className={`${rootClassName} two-dimensional-image__image-canvas-container`}>
						<div style={{
							position: 'relative',
						}}
							onContextMenu={(e) => e.preventDefault()}
						>
							<Canvas
								entities={entities}
								focusedName={focusedName}
								power={magnifyingPower}
								isLabelOn={isLabelOn}
								setSelectedAnnotationId={(id) => {
									if(this.props.setSelectedAnnotationId)
									{
										this.props.setSelectedAnnotationId(id)
									}
									else{
										this.setState( prevState => {
											return {
												...this.state,
												selectedAnnotationId: id,
												canvasContextMenuState: {
													isOpen: true,
												}
											}
										})
									}
									
								}}
								setSelectedAnnotationLabel={this.props.setSelectedAnnotationLabel}
							/>
							{showExpandIcon && <IconZoomIn style={{ opacity: 0.7, height: '28px', width: '28px', zIndex: 1, position: 'absolute', bottom: 8, right: 60, cursor: 'pointer' }} onClick={() => this.setState({ isImageExpanded: true })} />}
						</div>						
						{
						metadataArray&&metadataArray.length!=0&&this.state.canvasContextMenu.isOpen &&
						<CanvasContextMenu 
							open={this.state.canvasContextMenu.isOpen}
							handleShowMetadata={()=>{
								this.handleShowMetadata();
								this.updateCanvasContextMenuState({isOpen: false})
							}}
							handleReferenceDataPopup={() => {
								this.handleReferenceDataPopup();
								this.updateCanvasContextMenuState({isOpen: false})
							}}
							id={this.state.canvasContextMenu.event.target.attrs.name}
							handlePlayAudioRegionOnGroundTruth={this.props.handlePlayAudioRegionOnGroundTruth}
							scenario={this.props.scenario}
							xCoordinate={this.state.canvasContextMenu.xCoordinate}
							yCoordinate={this.state.canvasContextMenu.yCoordinate}
							handleOnClose={()=>this.updateCanvasContextMenuState({isOpen: false})}
							modelInformation={this.props.modelInformation}
							isLabelInReferenceData={this.props.isLabelInReferenceData}
						/>			
						}

						{
							<Dialog
								open={this.state.isMetadataDialogOpen}
								onClose={() => this.setState({isMetadataDialogOpen: false})}
								aria-labelledby='dialog-title'
								aria-describedby='dialog-description'
								maxWidth='md'
								style={{minWidth: '300px'}}
							>
								<DialogTitle id='dialog-title'>Metadata</DialogTitle>
								<DialogContent>
									{
										metadataArray && metadataArray.map(({key, keyValue}) => (
											<div key={key}>
												<strong style={{ paddingRight: "10px" }}>{key}  </strong>
												{Array.isArray(keyValue) ? (
													<ul>
														{keyValue.map((item, index) => (
															<li key={index}>{item}</li>
														))}
													</ul>
												) : (
													<span>{keyValue}</span>
												)}
											</div>
										))
										
									}
									{
										!metadataArray||metadataArray.length===0
										&&
										<Typography style={{color: '#858585'}}>
											Metadata does not exist
										</Typography>
									}
								</DialogContent>
							</Dialog>
						}

					</div>
				</TwoDimensionalImageContext.Provider>
			</I18nextProvider>
		);
	}
}

SegmentationAnalyticsTwoDimensionalImage.propTypes = {
	metadata: PropTypes.string,

	draggingCoordinates:PropTypes.object,
	onDrag:PropTypes.func,
	condition:PropTypes.string,
	handleSyncForImages:PropTypes.func,
	stageScaleForSync:PropTypes.number,
	zoomCursorPosition:PropTypes.object,
	className: PropTypes.string,
	url: PropTypes.string,
	imageWidth: PropTypes.number,
	defaultAnnotations: PropTypes.arrayOf(PropTypes.object),
	defaultAnnotationGroups: PropTypes.arrayOf(PropTypes.object),
	isDynamicOptionsEnable: PropTypes.bool,
	disabledOptionLevels: PropTypes.arrayOf(PropTypes.string),
	emptyAnnotationReminderText: PropTypes.string,
	isViewOnlyMode: PropTypes.bool,
	hasPreviousButton: PropTypes.bool,
	hasNextButton: PropTypes.bool,
	hasSkipButton: PropTypes.bool,
	hasSaveButton: PropTypes.bool,
	canShowAddAnnotationButton: PropTypes.bool,
	onPreviousClick: PropTypes.func,
	onAnnotationsChange: PropTypes.func,
	onSkipClick: PropTypes.func,
	onNextClick: PropTypes.func,
	onSaveClick: PropTypes.func,
	isLabelOn: PropTypes.bool,
	options: PropTypes.shape({
		id: PropTypes.string,
		value: PropTypes.string,
		children: PropTypes.array,
	}),
	ResourceRemarksSection: PropTypes.element,
	scenario: PropTypes.string,
	modelType: PropTypes.string,
	fullscreenButton: PropTypes.element,
	ActionsSection: PropTypes.element,
	alert: PropTypes.arrayOf(PropTypes.object),
	fullScreenMode : PropTypes.bool,
	onClickFullScreenMode: PropTypes.func,
	key : PropTypes.string,
	isExpandFeatureEnabled : PropTypes.bool,
	settingManuallyCanvasWidth : PropTypes.number || undefined,
	isShowingCompressedImageBecauseOriginalImageIsDownloading: PropTypes.bool,
	resourceId: PropTypes.string,
	isZoomFeatureEnabled: PropTypes.bool,
	shouldShowMouseAnnotationCoordinates: PropTypes.bool,
	originalImageWidth: PropTypes.number,
	originalImageHeight: PropTypes.number,
	maxImageHeightAllowed: PropTypes.number,
	referenceDataComponent: PropTypes.element,
	isFocusMode: PropTypes.bool,
	canvasStageHeight: PropTypes.number,
	viewReferencesSwitchComponent: PropTypes.element,
	focusModeComponent: PropTypes.element,
	modelInformation: PropTypes.object,
	selectedResource: PropTypes.object,
	setIsReferenceDataDialogOpen:PropTypes.func
};
SegmentationAnalyticsTwoDimensionalImage.defaultProps = {
	draggingCoordinates:()=>{},

	handleSyncForImages:()=>{},
	stageScaleForSync:1,
	isShowingCompressedImageBecauseOriginalImageIsDownloading: false,
	onAnnotationsChange: null,
	className: '',
	url: '',
	imageWidth: 400,
	canvasBorderTimerAnimationStyle: null,
	defaultAnnotations: [],
	defaultAnnotationGroups: [],
	options: {},
	isDynamicOptionsEnable: false,
	disabledOptionLevels: [],
	isLabelOn: false,
	isViewOnlyMode: false,
	emptyAnnotationReminderText: '',
	hasPreviousButton: false,
	hasNextButton: false,
	hasSkipButton: false,
	hasSaveButton: false,
	canShowAddAnnotationButton: true,
	ResourceRemarksSection: undefined,
	onPreviousClick: () => {},
	onSkipClick: () => {},
	onSaveClick: () => {},
	onNextClick: () => {},
	fullscreenButton: undefined,
	ActionsSection: undefined,
	modelType: "",
	alert:["",""],
	fullScreenMode : false,
	onClickFullScreenMode : () => {},
	key : "",
	isExpandFeatureEnabled : false,
	settingManuallyCanvasWidth : undefined,
	isFocusMode: false,
	canvasStageHeight: 0,
	viewReferencesSwitchComponent: undefined,
	focusModeComponent: undefined,

	// name of annotation which is in focus right now
	focusedName: ""
};
export default React.memo(SegmentationAnalyticsTwoDimensionalImage);

import { generateUniqueId } from "../services/idHelperService";

type PromiseConcurrencyConstructorParameters = {
 concurrencyLimit: number;
};

type PromiseCustomObject = {
 id: string;
 promise: Promise<any>;
};

export class PromiseConcurrency {
 concurrencyLimit: number;

 promiseCustomObjects: PromiseCustomObject[] = [];

 constructor(constructorParameters: PromiseConcurrencyConstructorParameters) {
  const Concurrency_limit_default_value = 1;

  this.concurrencyLimit =
   constructorParameters.concurrencyLimit || Concurrency_limit_default_value;
 }

 addPromise(promiseToAdd: Promise<any>) {
  const promiseCustomObject: PromiseCustomObject = {
   id: generateUniqueId().toString(),
   promise: promiseToAdd,
  };

  this.promiseCustomObjects.push(promiseCustomObject);

  promiseCustomObject.promise.then(() => {
   this.promiseCustomObjects = PromiseConcurrency.removePromiseCustomObject(
    promiseCustomObject.id,
    this.promiseCustomObjects
   );
  });
 }

 waitIfConcurrencyLimitReached(): Promise<void> {
  return new Promise(async (resolve, reject) => {
   try {
    if (this.promiseCustomObjects.length >= this.concurrencyLimit) {
     await Promise.race(this.promiseCustomObjects.map((obj) => obj.promise));
    }

    resolve();
   } catch (error) {
    reject(new Error(error.message));
   }
  });
 }

 waitUntilAllPromisesAreResolved(): Promise<void> {
  return new Promise(async (resolve, reject) => {
   try {
    if (this.promiseCustomObjects.length > 0) {
     await Promise.all(this.promiseCustomObjects.map((obj) => obj.promise));
    }

    resolve();
   } catch (error) {
    reject(new Error(error.message));
   }
  });
 }

 static removePromiseCustomObject = (
  promiseId: string,
  customPromiseObjects: PromiseCustomObject[]
 ): PromiseCustomObject[] => {
  for (
   let promiseIndex = 0;
   promiseIndex < customPromiseObjects.length;
   promiseIndex++
  ) {
   const promiseObject = customPromiseObjects[promiseIndex];
   if (promiseId === promiseObject.id) {
    customPromiseObjects.splice(promiseIndex, 1);
    break;
   }
  }
  return customPromiseObjects;
 };
}

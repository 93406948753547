type stateValues = [string, number, string]

export const stateValues : Record<string, stateValues>  = {
    "Initiating request" : ["Initiating request", 0, 'process' ],
    "Initiated request" : ["Initiated request", 0, 'done'],

    "Downloading Model" :  ["Loading Model", 1, 'process'],
    "Downloaded Model" : ["Model loaded", 1, 'done'],
    "Downloading Model Failed" : ["Failed", 1, 'failed'],

    "Downloading Data" : ["Downloading Data", 2, 'process'],
    "Downloaded Data" :  ["Data Downloaded", 2, 'done'],
    "Downloading Data Failed" : ["Failed", 2, 'failed'],

    "Inferencing" : ["Inferencing", 3, 'process'],
    "Inference Done" : ["Inference Done", 3, 'done'],
    "Inferencing Failed" : ["Failed", 3, 'failed'],

    "Uploading" : ["Preparing Results", 4, 'process'],
    "Uploaded" : ["Results", 4, 'done'],
    "Uploading Failed" : ["Failed", 4, 'failed'],
}

export const doneStateList = ["Initiated request", "Downloaded Model", "Downloaded Data", "Inference Done", "Uploaded"];

export const pendingStateList = ["Request", "Load Model", "Download Data", "Inference", "Result"];
import React, { ChangeEvent, useEffect, useRef, useState } from 'react';
import { Button, Dialog, DialogActions, DialogContent, Slider, Typography } from '@material-ui/core';
import ArrowBackIosIcon from '@material-ui/icons/ArrowBackIos';
import ArrowForwardIosIcon from '@material-ui/icons/ArrowForwardIos';
import FullscreenIcon from '@material-ui/icons/Fullscreen';
import { IReferenceGroupState } from '../../../../../common';
import CloseIcon from '@material-ui/icons/Close';
import PauseIcon from '@material-ui/icons/Pause';
import PlayArrowIcon from '@material-ui/icons/PlayArrow';
import { makeStyles, Theme } from '@material-ui/core/styles';
import ImageAspectRatioScalerViaParent from '../../../../../common/components/ImageAspectRatioScalerViaParent';
// import { ReactComponent as IconZoomIn } from "../../../../../../../assets/svg/Icon-ZoomIn.svg"



const useStyles = makeStyles((theme: Theme) => ({
    referenceDataItem: {
        width: '80%',
        padding: '6px',
        marginLeft: 'auto'
    },
    linearGradient: {
        position: "absolute",
        top: 0,
        left: 0,
        width: "100%",
        height: "50px",
        background: "linear-gradient(180deg, #000000 0%, #00000000 100%) 0% 0%"
    },
    dialogCloseButton: {
        position: "absolute",
        color: "#ffffff",
        right: "0px",
        cursor: "pointer",
        padding: "10px",
        zIndex: 1000
    },
    dialogLeftArrowButton: {
        position: "absolute",
        color: "#ffffff",
        top: "45%",
        left: 0,
        cursor: "pointer",
        padding: "10px",
        zIndex: 1000,
        background: "#0000004f"
    },
    dialogRightArrowButton: {
        position: "absolute",
        color: "#ffffff",
        top: "45%",
        right: 0,
        cursor: "pointer",
        padding: "10px",
        zIndex: 1000,
        background: "#0000004f"
    },
    customControls: {
        display: "flex",
        flexDirection: "row",
        alignItems: "center",
        gap: "10px",
        padding: 10
    },
    videoActionIcon: {
        color: "#ffffff"
    },
    dialogActionContainer: {
        background: "black",
        color: "#ffffff",
        display: "flex",
        flexDirection: "column",
        alignItems: "flex-start",
        padding: 0
    },
    timeLineSlider: {
        position: "absolute",
        width: "100%",
        marginLeft: 0,
        bottom: 25
    },
    track: {
        height: 4, // Adjust the height to make the track thicker
        borderRadius: 0, // Optional: Add border-radius for rounded corners
    }
}));


type urlSourceDataType = "image" | "video" | "text"


const ReferenceGuide = (
    props: {
        referenceGroups: IReferenceGroupState[];
        showSlideNumbers?: boolean;
    }) => {

    const classes = useStyles();

    const [carouselIndices, setCarouselIndices] = useState<number[]>(props.referenceGroups.map(() => 0))
    const [referenceDataSelectedForFullScreenUrl, setReferenceDataSelectedForFullScreenUrl] = useState<string>("")
    const [referenceDataSelectedForFullScreenCaption, setReferenceDataSelectedForFullScreenCaption] = useState<string>("")
    const [referenceDataSelectedForFullScreenFileName, setReferenceDataSelectedForFullScreenFileName] = useState<string>("")
    const [referenceDataFullScreenDialogIsOpen, setReferenceDataFullScreenDialogIsOpen] = useState<boolean>(false)
    const [referenceDataSourceType, setReferenceDataSourceType] = useState<urlSourceDataType | undefined>()


    useEffect(() => {
        setCarouselIndices(props.referenceGroups.map(() => 0))
    }, [props.referenceGroups])


    const mediaType = (fileName: string) => {
        if ([".jpeg", ".jpg", ".png", ".gif", ".tiff", ".svg"].some((extention) => fileName?.endsWith(extention))) return "image"
        if (["mp3", "wav", "ogg", "wma", "m4a"].some((extention) => fileName?.endsWith(extention))) return "audio"
        if (["mp4", "avi", "mkv", "mov", "wmv", "flv", "webm"].some((extention) => fileName?.endsWith(extention))) return "video"
        if (["txt", "pdf"].some((extention) => fileName?.endsWith(extention))) return "text"
        return "unsupported"
    }

    const handleReferenceImageFullScreenDialogOnClose = () => {
        setReferenceDataFullScreenDialogIsOpen(false)
    }


    return (
        <>
            {
                referenceDataFullScreenDialogIsOpen &&
                <FullScreenData
                    srcType={referenceDataSourceType}
                    isOpen={referenceDataFullScreenDialogIsOpen}
                    srcUrl={referenceDataSelectedForFullScreenUrl}
                    srcCaption={referenceDataSelectedForFullScreenCaption}
                    srcFileName={referenceDataSelectedForFullScreenFileName}
                    onClose={handleReferenceImageFullScreenDialogOnClose}
                    showLeftArrow={false}
                    showRightArrow={false}
                />
            }

            {
                (props.referenceGroups.length > 0) && props.referenceGroups.map((group, index) => {

                    if (group.referenceDatas.length === 0) return <></>

                    else return (
                        <div className={classes.referenceDataItem} >
                            {/* <Button onClick={() => console.log(props.referenceGroups)}> show reference </Button> */}
                            <>
                                <Typography variant='h6' style={{ fontWeight: 'bold' }}>{group.groupName}</Typography>
                                <div style={{ display: 'flex', justifyContent: 'space-between', padding: '8px 0 8px 0' }}>

                                    <Button
                                        onClick={() => {
                                            setCarouselIndices(() => {
                                                return carouselIndices.map((actualIndex, tempIndex) => {
                                                    if (tempIndex === index) {
                                                        return actualIndex === 0 ? props.referenceGroups[index].referenceDatas.length - 1 : actualIndex - 1
                                                    }
                                                    else return actualIndex
                                                })
                                            });
                                        }}
                                        disabled={carouselIndices[index] === 0}
                                        style={{ color: carouselIndices[index] === 0 ? '#747474' : '#e9e9e9' }}
                                    >
                                        <ArrowBackIosIcon />
                                    </Button>

                                    {
                                        <div style={{ maxHeight: '100%', display: 'flex', flexDirection: 'column', width: '100%', justifyContent: 'end', alignSelf: 'center' }}>
                                            <div style={{ maxHeight: '100%', display: 'flex', position: 'relative', justifyContent: mediaType(group.referenceDatas[carouselIndices[index]]?.fileName) === "image" ? "center" : 'end', alignItems: 'center', overflow: 'hidden', marginLeft: 'auto', width: '100%', borderRadius: '30px' }}>
                                                {
                                                    mediaType(group.referenceDatas[carouselIndices[index]]?.fileName) === "image" &&
                                                    <div style={{maxWidth: '100%', borderRadius: '30px', display: 'flex', justifyContent: "center", alignItems: "center", height: "200px", overflow: 'hidden', objectFit: 'cover' }}>
                                                        <ImageAspectRatioScalerViaParent
                                                            key={group.referenceDatas[carouselIndices[index]].fileUrl}
                                                            imageUrl={group.referenceDatas[carouselIndices[index]].fileUrl}
                                                            imageElementAltText='Reference image'
                                                            style={{
                                                                border: group.exampleType === "good" ? 'solid yellowgreen 4px' : (group.exampleType === 'defective' ? 'solid red 3px' : ''),
                                                                borderRadius: "30px"
                                                            }}
                                                        />
                                                    </div>
                                                }
                                                {
                                                    mediaType(group.referenceDatas[carouselIndices[index]]?.fileName) === "audio" &&
                                                    <div style={{ width: '100%', minHeight: '120px', display: 'flex', backgroundColor: 'white', padding: '36px', borderRadius: '30px', border: group.exampleType === "good" ? 'solid yellowgreen 4px' : (group.exampleType === 'defective' ? 'solid red 3px' : '') }}>
                                                        <audio src={group.referenceDatas[carouselIndices[index]].fileUrl} controls style={{ width: '100%', margin: 'auto' }} />
                                                    </div>
                                                }
                                                {
                                                    mediaType(group.referenceDatas[carouselIndices[index]]?.fileName) === "video" &&
                                                    <video src={group.referenceDatas[carouselIndices[index]].fileUrl} controls={true} style={{ width: '100%', height: '100%', objectFit: 'cover', borderRadius: '30px', border: group.exampleType === "good" ? 'solid yellowgreen 4px' : (group.exampleType === 'defective' ? 'solid red 3px' : '') }} />
                                                }
                                                {
                                                    mediaType(group.referenceDatas[carouselIndices[index]]?.fileName) === "text" &&
                                                    <object data={group.referenceDatas[carouselIndices[index]].fileUrl} width="100%" style={{ backgroundColor: 'white', borderRadius: '30px', objectFit: 'cover', border: group.exampleType === "good" ? 'solid yellowgreen 4px' : (group.exampleType === 'defective' ? 'solid red 3px' : '') }} />
                                                }
                                                {
                                                    mediaType(group.referenceDatas[carouselIndices[index]]?.fileName) === "unsupported" &&
                                                    <Typography>Unsupported file format :(</Typography>
                                                }
                                                {
                                                    (mediaType(group.referenceDatas[carouselIndices[index]]?.fileName) === "image"
                                                        // || mediaType(group.referenceDatas[carouselIndices[index]]?.fileName) === "video"
                                                        || mediaType(group.referenceDatas[carouselIndices[index]]?.fileName) === "text") &&
                                                    <FullscreenIcon style={{ opacity: 0.5, height: '28px', width: '28px', zIndex: 1, position: 'absolute', bottom: 8, right: 8, cursor: 'pointer', backgroundColor: 'black', borderRadius: '8px' }}
                                                        onClick={() => {
                                                            setReferenceDataSelectedForFullScreenUrl(group.referenceDatas[carouselIndices[index]]?.fileUrl)
                                                            setReferenceDataSelectedForFullScreenFileName(group.referenceDatas[carouselIndices[index]]?.fileName)
                                                            setReferenceDataSelectedForFullScreenCaption(group.referenceDatas[carouselIndices[index]]?.caption || "")
                                                            if (mediaType(group.referenceDatas[carouselIndices[index]]?.fileName) === "image") {
                                                                setReferenceDataSourceType("image");
                                                                setReferenceDataFullScreenDialogIsOpen(true)
                                                            }
                                                            if (mediaType(group.referenceDatas[carouselIndices[index]]?.fileName) === "video") {
                                                                setReferenceDataSourceType("video");
                                                                setReferenceDataFullScreenDialogIsOpen(true)
                                                            }
                                                            if (mediaType(group.referenceDatas[carouselIndices[index]]?.fileName) === "text") {
                                                                setReferenceDataSourceType("text");
                                                                setReferenceDataFullScreenDialogIsOpen(true)
                                                            }
                                                        }}
                                                    />
                                                }
                                            </div>
                                            <Typography style={{ textAlign: 'center', padding: '8px' }}>{group.referenceDatas[carouselIndices[index]]?.caption}</Typography>
                                        </div>
                                    }
                                    <Button
                                        onClick={() => {
                                            setCarouselIndices(() => {
                                                return carouselIndices.map((actualIndex, tempIndex) => {
                                                    if (tempIndex === index) {
                                                        return (actualIndex + 1) % props.referenceGroups[index].referenceDatas.length
                                                    }
                                                    else return actualIndex
                                                })
                                            });
                                        }}
                                        disabled={carouselIndices[index] === props.referenceGroups[index].referenceDatas.length - 1}
                                        style={{ color: carouselIndices[index] === (props.referenceGroups[index].referenceDatas.length - 1) ? '#747474' : '#e9e9e9' }}
                                    >
                                        <ArrowForwardIosIcon />
                                    </Button>
                                </div>
                                {
                                    props.showSlideNumbers && <Typography style={{ color: '#a9a9a9' }}>{carouselIndices[index] + 1}/{props.referenceGroups[index].referenceDatas.length}</Typography>
                                }
                            </>
                        </div>
                    )
                })
            }
        </>
    );
};



// =============================================================================================================================================================




const FullScreenData = (props: {
    srcType: urlSourceDataType | undefined,
    isOpen: boolean,
    srcUrl: string,
    srcCaption?: string,
    srcFileName?: string,
    onClose: () => void,
    showLeftArrow: boolean,
    showRightArrow: boolean
}) => {
    const classes = useStyles();
    const videoRef = useRef<HTMLVideoElement | null>(null);
    const [isPlaying, setIsPlaying] = useState(false);

    const [currentTime, setCurrentTime] = useState(0);
    const [duration, setDuration] = useState(0);

    useEffect(() => {
        setTimeout(() => {
            if (videoRef.current) {
                setDuration(videoRef.current.duration)
                // videoRef.current.addEventListener('loadedmetadata', handleLoadedMetadata);
                videoRef.current.addEventListener('timeupdate', handleTimeUpdate);

                return () => {
                    if (videoRef.current) {
                        videoRef.current.removeEventListener('timeupdate', handleTimeUpdate);
                        // videoRef.current.removeEventListener('loadedmetadata', handleLoadedMetadata);
                    }
                };
            }
        }, 2000)
    }, []);


    const formatTime = (time: number): string => {
        const minutes = Math.floor(time / 60);
        const seconds = Math.floor(time % 60);
        return `${String(minutes).padStart(2, '0')}:${String(seconds).padStart(2, '0')}`;
    };

    const handleTimeUpdate = () => {

        if (videoRef.current) {
            setCurrentTime(videoRef.current.currentTime);
        }
    };

    const handlePlayPause = () => {
        if (videoRef.current) {
            if (videoRef.current.paused) {
                videoRef.current.play();
                setIsPlaying(true);
            } else {
                videoRef.current.pause();
                setIsPlaying(false);
            }
        }
    };

    const handleStop = () => {
        if (videoRef.current) {
            videoRef.current.pause();
            videoRef.current.currentTime = 0;
            setIsPlaying(false);
        }
    };

    const handleTimelineChange = (event: ChangeEvent<{}>, value: number | number[]) => {
        if (typeof value === 'number' && videoRef.current) {
            videoRef.current.currentTime = value;
            setCurrentTime(value);
        }
    };

    return (
        <Dialog
            open={props.isOpen}
            // onClose={handleClose}
            maxWidth="lg"
            fullWidth
            aria-labelledby="alert-dialog-title"
            aria-describedby="alert-dialog-description"
        >
            <DialogContent style={{ padding: 0 }}>
                <div className={classes.linearGradient} />
                <div className={classes.dialogCloseButton}
                    onClick={props.onClose}
                >
                    <CloseIcon />
                </div>
                {
                    // props.showLeftArrow &&
                    // <div className={classes.dialogLeftArrowButton}
                    //     onClick={props.handleClickLeftArrow}
                    // >
                    //     <ArrowBackIosIcon />
                    // </div>
                }
                {
                    // props.showRightArrow &&
                    // <div className={classes.dialogRightArrowButton}
                    //     onClick={props.handleClickRightArrow}
                    // >
                    //     <ArrowForwardIosIcon />
                    // </div>
                }
                {
                    props.srcType === "image" &&
                    <img style={{ width: "100%", objectFit: "contain", height: "85vh" }} src={props.srcUrl} alt={props.srcCaption} />
                }
                {
                    props.srcType === "video" &&
                    <video style={{ width: "100%" }} ref={videoRef} controls={false} onClick={handlePlayPause}>
                        <source src={props.srcUrl} type="video/mp4" />
                        Your browser does not support the video tag.
                    </video>
                }
                {
                    props.srcType === "text" &&
                    <object data={props.srcUrl} height="600px" width="100%" />
                }
            </DialogContent>
            {
                props.srcType === "video" &&
                <DialogActions className={classes.dialogActionContainer}>
                    <div className={classes.dialogActionContainer}>
                        <div className={classes.customControls}>

                            {
                                isPlaying ?
                                    <PauseIcon onClick={handlePlayPause} className={classes.videoActionIcon} /> : <PlayArrowIcon onClick={handlePlayPause} className={classes.videoActionIcon} />
                            }
                            <Typography>{formatTime(currentTime)}</Typography>
                            <Typography>/</Typography>
                            <Typography>{formatTime(duration)}</Typography>
                            <Typography>{props.srcCaption}</Typography>
                        </div>

                        <div className={classes.timeLineSlider}>
                            <Slider
                                classes={{ track: classes.track }}
                                style={{
                                    color: "#ff3b4e", margin: 0
                                }}
                                value={currentTime}
                                min={0}
                                max={duration || 0}
                                step={1}
                                onChange={handleTimelineChange}
                                valueLabelDisplay="auto"
                                valueLabelFormat={(value) => formatTime(value as number)}
                            />
                        </div>
                    </div>
                </DialogActions>
            }
            {(props.srcType === "image" || props.srcType === "text") && <DialogActions className={classes.dialogActionContainer}>
                <div className={classes.dialogActionContainer}>
                    <div className={classes.customControls}>
                        <Typography style={{ width: "100%", textAlign: "center" }}>{props.srcCaption}</Typography>
                    </div>
                </div>
            </DialogActions>}
        </Dialog>)
}

export default ReferenceGuide;
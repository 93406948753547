import { IModel } from './models';
import { ResourceImageGroupAnnotation, ResourceImageAnnotation, TrimmedText } from './data';
import { ICopilot } from './copilot';
import { FileMetaData, IProject } from '.';
import { WordMetadata } from '../../../features/admin/features/projects/features/models/features/model/features/data/components/AudioTrimUI/AudioTrimUI';
import { ReferredResource } from './referredResource.type';
/**
 * CoPilotResource
 */

export const CoPilotResourceTimerEndAt = "resourceTimerEndAt";

export type CoPilotResourceStatus = "approved" | "expired" | "rejected" | "active" | "deleted";

type ActionButtonOnClickBehaviourType = "approved" | "reject" | "reject without reason" | "editScores" | "approve"
// | "rejected without reason"
;

export interface ICoPilotResource {
  ansQuestion: string | undefined;
  _id: string;
  status: CoPilotResourceStatus;
  parentResourceId?: string;

  // not applicable for image annotation
  label?: string;

  filename: string;
  tag: string;

  // not applicable for image annotation
  confidenceScore?: number;

  /** used only for resources which are part of textAnnotation copilots */
  trimmedTexts?: TrimmedText[] 
  
  /** used only for resources which are part of DLP Copilots */
  isPiiData ?: boolean;
  
  statusLastModifiedAt: string;
  created_at: string;
  resourceTimerEndAt: string;
  resourceTimerEndAtResetCount: number;
  resource: string;
  userTimeLeftInSecondsToTakeAction: number;
  csv: string;
  remarks: {
    text: string;
  };
  isResourceStatusBeingUpdatedToApprovedInDB?: boolean;
  isResourceStatusBeingUpdatedToRejectedInDB?: boolean;
  isInvalidated?: boolean;
  coPilot: ICopilot;
  rejectedReason: string;
  files?:{
    filename:string;
    fileUrl:string;
    imageAnnotations: ResourceImageAnnotation[];
    _id:string;
 }[];
  /** used only for resources which are part of imageAnnotation Copilots */
  imageAnnotations?: ResourceImageAnnotation[];

  /** used only for resources which are part of imageGroupAnnotation Copilots */
  imageGroupAnnotations?: ResourceImageGroupAnnotation[];

  isResourceRemarksBeingUpdatedInDB?: boolean;

  /**
   * used only when updating imageKeyPoints in DB
   */
  areImageKeyPointsBeingUpdatedInUI?: boolean;

  // @TODO: Will be removed later
  // dataBoost is not applicable to CoPilot resources
  dataBoost ?: string;

  // used only for typescript fix
  prediction?: string;
  
  // used only for typescript fix
  confidence_score?: number

  // used only for typescript purpose
  resource500WUrl?: string

  // used only for typescript purpose
  resourceMetaData?: FileMetaData;
  
  // used only for typescript purpose
  resource500WMetaData?: FileMetaData;  

  // applicable for textFile model type
  textFileContent?: string  

  // applicable for textFile model type
  isFetchingTextFileContents?: string  

  /** used only for resources which are part of prompt models */
  ansOfPrompt: string;
  prompt: string;

   // copilot resource best answer/ evaluate answer properties
   question?: string;

   answerOptions?: {
    _id: string,
    text: string,
    isSelected: boolean,
    AIEvaluationScores: Object
   }[];

   referenceContexts?: {
    text: string
   }[]
   // copilot resource best answer/ evaluate answer properties end


   activePageActions?: {
    affirmationButton?: {
      onClickBehaviour: ActionButtonOnClickBehaviourType,
      buttonText: string
    },
    negativeButton?: {
      onClickBehaviour: ActionButtonOnClickBehaviourType,
      buttonText: string
    },
   },
   showConfidenceScore?: boolean,
   showLabelDropdown?: boolean,
   showContentMetaInformation?: boolean,
   referenceDataFiles?: string[],
   sendDataToAModelOnReject?: {
    modelId: string,
    modelTab: IModel["type"]
   },
   onApproveSetLabel?: string,
   onRejectSetLabel?: string
   resourceSecondsAllocatedDuringTimerSet: number,

  //  for spectrogramAudio Copilot type
  spectrogram? : string,
  trimmedAudioAnnotation?: ITrimmedAudioAnnotation[],
  // used in textComparison task type
  textDifferencePercentage?: number,
  textsDifferences?: {
    // string character 
    value: string,

    inText: "one" | "two",

    characterIndexPosition: number
  }[]
  textOne?: string,
  textTwo?: string,
  // used in textComparison task type ends

  // not being used
  metadataFileName?: string

  /**
   * for audioTranscription copilot type
   */
  audioTranscriptionWordsMetadatas?:WordMetadata[];

  referredResources?: ReferredResource[];

  projectObject?: IProject;
}

export interface ITrimmedAudioAnnotation {
  _id: string,
  color: string,
  startTimeInSeconds: number,
  endTimeInSeconds: number,
  label: string,
  confidenceScore: number,
  spectrogramCoordinates: {
    y1: number,
    x1: number,
    y2: number,
    x2: number
  },
  createdAt: string
}

export interface ICoPilotResourceNotification {
	_id: string;
	status?: CoPilotResourceStatus;

	// not applicable for image annotation
	label?: string;

	filename?: string;
	tag?: string;

	// not applicable for image annotation
	confidenceScore?: number;
	
	statusLastModifiedAt?: string;
	created_at?: string;
	resourceTimerEndAt?: string;
	resource?: string,
	userTimeLeftInSecondsToTakeAction?: number;
	csv?: string;
	remarks?: {
		text: string;
	};
	isResourceStatusBeingUpdatedToApprovedInDB?: boolean;
	isResourceStatusBeingUpdatedToRejectedInDB?: boolean;
	coPilot?: ICopilot;
	rejectedReason?: string;
	/** used only for resources which are part of imageAnnotation Copilots */
	imageAnnotations?: ResourceImageAnnotation[];

	/** used only for resources which are part of imageGroupAnnotation Copilots */
	imageGroupAnnotations?: ResourceImageGroupAnnotation[];

	isResourceRemarksBeingUpdatedInDB?: boolean;

	/**
	 * used only when updating imageKeyPoints in DB
	 */	
	areImageKeyPointsBeingUpdatedInUI?: boolean
}

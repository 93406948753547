import React, { Dispatch, SetStateAction } from 'react';
import { Tooltip, IconButton, makeStyles } from "@material-ui/core";
import { IModelArchitecturesAvailable, IModelCollection } from '../../../../../../../../../../../common';
import DeploySVGIcon from "../../../../../../../../../../../assets/svg/deploy.svg";


const useStyles = makeStyles((theme) => ({
  deployButton: {
    color: "#008D9C",
  },
  tableDisabledCell: {
    pointerEvents: "none",
    opacity: "0.5",
  },
}));
function ModelCollectionDeployButton(props: { modelRow?: IModelCollection, disabledDeployButton?: boolean, architecturesAvailable?: IModelArchitecturesAvailable, setPerformModelCollectionDeploymentPopupState?: Dispatch<SetStateAction<{ isOpen: boolean, selected: IModelCollection | undefined }>> }) {

  console.log("architecturesAvailable", props.architecturesAvailable)
  
  const classes = useStyles();
  return (
    <div>
      <Tooltip title={props?.modelRow &&
        (props?.modelRow?.checkpointFiles?.length <= 0) ?
        "Checkpoint file should be there to perform deployment" :
        (props.architecturesAvailable && props.architecturesAvailable.find(architecture => architecture.label === props?.modelRow?.modelArchitecture) === undefined) ?
          "Model collection architecture is Invalid" :
          (props.architecturesAvailable && props.architecturesAvailable.find(architecture => (architecture.label === props?.modelRow?.modelArchitecture && architecture.isDeploymentFeatureEnabled)) === undefined) ?
            "Deployment feature is not enabled" :
            (props.architecturesAvailable && props.architecturesAvailable.find(architecture => (architecture.label === props?.modelRow?.modelArchitecture && architecture.apiUrlToCallForDeployment !== "")) === undefined) ?
              "Api Url to perform deployment is not available" :
              // (!props.disabledDeployButton) ? "Model’s integrity is less than Model Integrity Passing % defined in Configuration" :
                "Deploy"
      }>
        <div>
          <IconButton
            classes={{ root: `${classes.deployButton} ${(props?.modelRow && props?.modelRow?.checkpointFiles?.length > 0) && (props.architecturesAvailable && props.architecturesAvailable.find(architecture => architecture.label === (props?.modelRow && props?.modelRow.modelArchitecture) && architecture.apiUrlToCallForDeployment !== "" && architecture.isDeploymentFeatureEnabled)) ? "" : classes.tableDisabledCell}` }}
            onClick={() => {
              props.setPerformModelCollectionDeploymentPopupState && props.setPerformModelCollectionDeploymentPopupState((oldState: any) => ({ ...oldState, isOpen: true, selected: props.modelRow }))
            }}
            // disabled={!props?.disabledDeployButton}
          >
            {/* <img alt="Deplyment Button" style={{ width: "30px", transform: "scale(1.5)", opacity: `${props?.disabledDeployButton ? "100%" : "40%"}` }} src={DeploySVGIcon} /> */}
            <img alt="Deplyment Button" style={{ width: "30px", transform: "scale(1.5)", opacity: `${"100%"}` }} src={DeploySVGIcon} />
          </IconButton>
        </div>
      </Tooltip>
    </div>
  )
}

export default ModelCollectionDeployButton
import './StateElement.css'
import { stateValues } from '../constant'
import Substate from '../SubState/SubState';
import { useEffect, useState } from 'react';

type props = {
    value : string,
    time : string, 
    currIdx : number, 
    eleIdx : number
}

function StateElement({ value, time, currIdx, eleIdx } : props) {

    const [elementState, setElementState] = useState({value, eleIdx, currIdx, time})

    const updateValues = () => setElementState({value, eleIdx, currIdx, time})

    useEffect(()=>{
        let timeoutId : NodeJS.Timeout
        if(elementState.currIdx < eleIdx && eleIdx <= currIdx) {
            const timer = (eleIdx - elementState.currIdx)/(currIdx - elementState.currIdx)*1000;
            timeoutId = setTimeout(updateValues, timer)
        }
        else if(value !== elementState.value || currIdx !== elementState.currIdx) updateValues()
        return () => clearTimeout(timeoutId)
    }, [currIdx, value])

    return (
        <div className='state__element__body'>
            {elementState.eleIdx < elementState.currIdx ? <Substate state='done' /> : null}
            {elementState.eleIdx === elementState.currIdx ? (stateValues[elementState.value] ? <Substate state={stateValues[elementState.value][2]} /> : '') : null}
            {elementState.eleIdx > elementState.currIdx ? <Substate state='pending' /> : null}

            <div>
                <div className={`state__element__value state__element__value--${elementState.eleIdx <= elementState.currIdx}`}>
                    {elementState.eleIdx === elementState.currIdx ? stateValues[elementState.value]?.[0] || '' : elementState.value}
                </div>
                {elementState.eleIdx <= elementState.currIdx ? <div className='state__element__time'>
                    {elementState.time}
                </div> : null}
            </div>
        </div>
    )
}

export default StateElement
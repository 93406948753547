import { PropTypes, Tooltip, Typography } from "@material-ui/core";
import moment from "moment";

export const TimeDateFormat = (props: {
    timeFormat?: string;
    dateFormat?: string;
    tooltipTimeFormat?: string;
    timeString: string;
    dateString?: string;
    timeStyleClass?: string;
    dateStyleClass?: string;
    textAlign?: PropTypes.Alignment;
    isForecastDate?: boolean;
    className?: string;
}) => {
    return (
        <Tooltip 
        title={
            !props.isForecastDate
            ?   props.tooltipTimeFormat ? moment(props.timeString).format(props.tooltipTimeFormat) : moment(props.timeString).format(props.timeFormat).toString() + " Hrs, " + moment(props.timeString).format(props.dateFormat)
            :   new Date(props.timeString).toLocaleString('en-US', {timeZone: 'America/New_York', hour12: false, timeStyle: "short"}) + " Hrs, " + new Date(props.timeString).toLocaleString('en-US', {timeZone: 'America/New_York', dateStyle: "medium"}).replaceAll(",", "th,")
            }
        >
            <div className={props.className}>
                <Typography classes={{ root: props.timeStyleClass }} component="p" noWrap align={props.textAlign}>
                    {
                        !props.isForecastDate
                        ? moment(props.timeString).format(props.timeFormat).toString() + ` Hrs`
                        : new Date(props.timeString).toLocaleString('en-US', {timeZone: 'America/New_York', hour12: false, timeStyle: "short"}) + ` Hrs`
                    }
                </Typography>
                <Typography classes={{ root: props.dateStyleClass }} component="p" align={props.textAlign} variant="subtitle2" noWrap>
                    {
                        !props.isForecastDate
                        ? moment(props.dateString ? props.dateString : props.timeString).format(props.dateFormat)
                        : new Date(props.timeString).toLocaleString('en-US', {timeZone: 'America/New_York', dateStyle: "medium"}).replaceAll(",", "th,")
                    }
                </Typography>
            </div>
        </Tooltip>
    );
};

TimeDateFormat.defaultProps = {
    timeFormat: "kk:mm",
    dateFormat: "MMM Do, YYYY",
    textAlign: 'left',
    isForecastDate: false
}

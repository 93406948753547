import React, { useEffect, useContext, useState } from 'react';
import PropTypes from 'prop-types';
import { Events, scrollSpy, scroller } from 'react-scroll';
import { ListGroup } from 'reactstrap';
import TwoDimensionalImageContext from '../TwoDimensionalImage/twoDimensionalImageContext';
import AnnotationItem from './AnnotationItem/AnnotationItem.jsx';
import { IconButton, MenuItem, TextField, Tooltip } from '@material-ui/core';
import DeleteIcon from '@material-ui/icons/Delete';
import { AddCircleOutline } from '@material-ui/icons';
import './annotationList.scss';

const AnnotationList = ({
	className,
}) => {
	const twoDimensionalImageContext = useContext(TwoDimensionalImageContext);
	const {
		entities,
		annotations,
		height,
		focusedName,
		scenario
	} = twoDimensionalImageContext;

		/** @type {import('../../../../../../../../common').IModelType} */
		const modelType = twoDimensionalImageContext.modelType;	

	useEffect(() => {
		Events.scrollEvent.register('begin', () => {});
		Events.scrollEvent.register('end', () => {});
		scrollSpy.update();
		return () => {
			Events.scrollEvent.remove('begin');
			Events.scrollEvent.remove('end');
		};
	});
	useEffect(() => {
		if (focusedName) {
			try {
				scroller.scrollTo(focusedName, { containerId: 'annotation-list' });
			} catch (error) {
				console.warn(error.message)	
			}
		}
	}, [focusedName]);

	const AnnotationGroupItem = (props) => {

		const {
			handleAddImageAnnotationInExistingGroupButtonClick,
			isAdding,
			focusedGroupAnnotationId,
			handleAnnotationGroupDeleteClick,
			isViewOnlyMode,
			entities,
			handleAnnotationGroupLabelChange
		} = twoDimensionalImageContext;

		const { options } = entities;

		const name = props.annotationGroupName;

		const [groupName, setGroupName] = useState(name);

		const annotations = props.allAnnotations;
		const id = props.annotationGroupId;
		const [groupImageAnnotations, setGroupImageAnnotations] = useState([]);
		const [optionsArray, setOptionsArray] = useState([]);

		useEffect(()=>{
			if (
				id &&
				Array.isArray(Object.keys(annotations))
			) {
				const groupImageAnnotationsToSet = [];
				for (let index = 0; index < Object.keys(annotations).length; index++) {
					const imageAnnotationId = Object.keys(annotations)[index];
					const imageAnnotation = annotations[imageAnnotationId];
					if (imageAnnotation.groupAnnotationId === id) {
						groupImageAnnotationsToSet.push({...imageAnnotation});
					}
				}
				setGroupImageAnnotations([...groupImageAnnotationsToSet]);
			}
		}, [id, annotations])

		useEffect(()=>{
			const optionsArrayToUpdate = [];
			for (let index = 0; index < Object.keys(options).length; index++) {
				
				//** @type {number} */
				const objectId = Object.keys(options)[index];
				
				// const object = options[index];
				const object = options[objectId];				


				if (object.id === '0') {
					continue;
				}
				optionsArrayToUpdate.push(object);
			}
			setOptionsArray(optionsArrayToUpdate);
		}, [options])		

		return (
			<div
				style={{
					backgroundColor: "#dddddd82",
					padding: "10px",
					paddingLeft: "20px",
					paddingRight: "20px",
					marginBottom: "20px"
				}}
			>
				<div
					style={{
						display: "flex",
						justifyContent: "space-between",
						alignItems: "center",
						marginBottom: "15px"
					}}
				>
					<div
						style={{
							width: "100%"
						}}
					>
						{isViewOnlyMode && name}
						{
							!isViewOnlyMode &&
							<TextField
							style={{
								width: "100%"
							}}
							size="small"
							select
							label="Select annotation group label"
							value={groupName}
							disabled={isViewOnlyMode}
							onChange={(event)=>{
								const value = event.target.value;
				
								handleAnnotationGroupLabelChange(value, id);
								setGroupName(value);
							}}
							// helperText="Please select annotation group label"
							helperText=""
						>
							{
							[
								...optionsArray,
								{
									id: "",
									value: "",
									children: []
								}
							]
							.map((option) => (
								<MenuItem key={option.value} value={option.value}>
									{option.value}
								</MenuItem>
							))}
							
						</TextField>							
						}
					</div>
					{
						!isViewOnlyMode &&
						<div
							style={{
								cursor: "pointer",
								alignSelf: "end"
							}}
						>
							<DeleteIcon 
								size="small"
								style={{
									color: "#dc3545"
								}}
								onClick={()=>{
									handleAnnotationGroupDeleteClick(id)
								}}
							/>
						</div>
					}
				</div>
				<div>
					{
						groupImageAnnotations
						.map((groupImageAnnotation, index)=>{
							return (
								<AnnotationItem
									key={ index }
									itemData={ groupImageAnnotation }
								/>
							)
						})
					}
				</div>
				{
					!isViewOnlyMode &&
					<div
						style={{
							textAlign: "end"
						}}
					>
						<Tooltip
							title={
								isAdding && focusedGroupAnnotationId === id
								? "Adding Annotation"
								: "Add Annotation"
							}
						>
							<IconButton
								size="small"
								onClick={()=>{
									handleAddImageAnnotationInExistingGroupButtonClick(
										id
									)
								}}
							>
								{/* {
									isAdding && focusedGroupAnnotationId === id
									? "Adding Annotation"
									: "Add Annotation"
								} */}
									<AddCircleOutline 
										style={{
											color: "#007bff"
										}}
									/>
							</IconButton>
						</Tooltip>

					</div>

				}
			</div>
		)
	}


	const itemsUI = annotations.map(ann => (
		<AnnotationItem
			key={ ann }
			itemData={ entities.annotations[ann] }
		/>
	));

	const AnnotationGroups = () => {
		const {annotationGroups, annotations} = entities;
		
		return (
			<>
				{
					annotationGroups
					.map(annotationGroup=>{
						return (
							<AnnotationGroupItem 
								annotationGroupName={annotationGroup.name}
								annotationGroupId={annotationGroup.id}
								allAnnotations={annotations}
							/>
						)
					})
				}
			</>
		)
	}

	if (itemsUI.length === 0) {
		return (
			/** if no anotation is created by user yet, then not taking extra space */
			// <div className='d-flex align-items-center justify-content-center' style={ { height } }>
			// 	{emptyAnnotationReminderText}
			// </div>
			<></>
		);
	}
	const rootClassName = `annotation-list${className ? ` ${className}` : ''}`;
	return (
		<ListGroup 
			className={`
				${rootClassName}
				${scenario === "coPilotActivePageEditAnnotation" ? "annotation-list-whenCoPilotActivePageEditAnnotationScenario" : ""}
			`} 
			id='annotation-list' style={ { maxHeight: height } }>
			{
				modelType === 'imageAnnotationGroup'
				? <AnnotationGroups />
				: itemsUI
			}
		</ListGroup>
	);
};

AnnotationList.propTypes = {
	className: PropTypes.string,
};
AnnotationList.defaultProps = {
	className: '',
};
export default React.memo(AnnotationList);

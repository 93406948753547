import { useCallback, useEffect, useRef, useState } from "react";
import {
  Box,
  Button,
  Chip,
  CircularProgress,
  FormControl,
  Grid,
  InputAdornment,
  makeStyles,
  MenuItem,
  Slider,
  TextField,
  Tooltip,
  Typography,
  ValueLabelProps,
} from "@material-ui/core";
import { useForm } from "react-hook-form";
import {
  IAddCollectionPayload,
  Collection,
  CustomButton,
  IReduxState,
  IModelArchitecturesAvailable,
  IModelLabelsAvailable,
  ITrainingDataDirectory,
  IModelCollection,
  IModelCollectionVersion,
} from "../../../../../../../../../../../common";
// import InfoIcon from "@material-ui/icons/Info";
import {
  addCollection,
  addCollectionPageInit,
  getTags,
  setShouldFetchModelCollections,
  setSideDrawer,
} from "../../../../../../../../../../../store/actions";
import { connect, ConnectedProps } from "react-redux";
// import { useLocation } from "react-router-dom";
import DeleteIcon from "@material-ui/icons/Delete";
import {
  DataService,
  ModelService,
} from "../../../../../../../../../../../services";
import {
  IModelCollectionCheckpointFileDataReqPayloadForAddModelCollectionScenario,
  IModelCollectionPostReqPayload,
} from "../../../../../../../../../../../services/DataService";
import { useURLQuery } from "../../../../../../../../../../../hooks/useURLQuery";
import { isEmpty } from "../../../../../../../../../../../services/inputFieldHelperService";
import { Autocomplete } from "@material-ui/lab";
import { deleteButtonIconColor } from "../../data/constants/constants";
import AddCircleIcon from "@material-ui/icons/AddCircle";
// import FillDataViaJSONInfoDialog from "./FillDataViaJSONInfoDialog";
// import { isValidCheckpointFile } from "../../../../../../../../../../../services/checkpointFileHelperService";
import { InputFieldState } from "../../../../../../../../../../../common/constants/interfaces/inputFieldState";
import { setTrainingDataDirectoriesSelectedStateForAddModelCollection } from "../../../../../../../../../../../store/actions/collectionActions";
import { trainingDataDirectoriesSelectedStateForAddModelCollectionInitialValue } from "../../../../../../../../../../../store/reducers/collection";
import { removeDuplicateElementsFromArray } from "../../../../../../../../../../../services/arrayHelperService";
import Analytics from "../../../../../../../components/Analytics";
// import { isNullOrUndefined, isNumber, isString } from '../../../../../../../../../../../services/variableHelperService';
import {
  convertVersionInMajorMinorPatchVersionFormat,
  decrementVersionBy0Point0Point1,
  incrementVersionBy0Point0Point1,
  isFirstParamVersionLessThanSecondParamVersion,
  isValidMajorMinorPatchVersion,
  sortVersionsInDescendingOrder,
} from "../../../../../../../../../../../services/versionHelperService";
import { ModelCollectionStartCheckpointUndefinedValue } from "../constants/constants";

/**
 * @description
 * addModelCollection scenario means dataSet Collection(s) has already been trained, now just registering it
 * to database
 * trainingDataSetCollections scenario means dataSetCollection(s) about to be trained
 * reTrainingDataSetCollections scenario means dataSetCollection(s) about to be re-trained
 */
export type AddModelCollectionScenario =
  | "addModelCollection"
  | "trainingDataSetCollections"
  | "reTrainingDataSetCollections";

function SliderValueLabelComponent(props: ValueLabelProps) {
  const { children, open, value } = props;

  return (
    <Tooltip open={open} enterTouchDelay={0} placement="top" title={value}>
      {children}
    </Tooltip>
  );
}

/*
function labelExistInModelArchitecturesAvailable(labelToCheck: string, modelArchitecturesAvailable: IModelArchitecturesAvailable): boolean {
  try {
   for (const modelArchitecture of modelArchitecturesAvailable) {
     if (modelArchitecture.label === labelToCheck) {
       return true;
     }
   } 
  } catch (error) {
  }
  return false;
}
*/

const useStyles = makeStyles((theme) => ({
  root: {
    // marginTop: "auto",
    // marginBottom: "auto",
    padding: 25,
    height: "100%",
  },
  illustration: {
    // width: "150px",
    height: "150px",
    width: "100%",
    textAlign: "center",
  },
  form: {
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    padding: 5,
    height: "100%",
  },
  formControl: {
    // minWidth: 250,
    // maxWidth: 300,
    width: "100%",
    "& legend": {
      float: "unset",
    },
  },
  floating: {
    "& legend": {
      float: "unset",
    },
  },
  formControlContainer: {
    width: "100%",
  },
  center: {
    justifyContent: "center",
  },
  inputSection: {
    marginBottom: "20px",
  },
  multipleInputFieldSection: {
    backgroundColor: "#eff4fb",
    padding: "10px",
    borderRadius: "6px",
  },
  inputSectionTitle: {
    fontWeight: "bold",
  },
  selectTextFieldInput: {
    maxWidth: "248px",
  },
  chip: {
    margin: theme.spacing(0.5),
    maxWidth: "30px",
  },
  autoCompleteChipRoot: {
    maxWidth: "170px",
  },
  sliderInputFieldSection: {
    paddingLeft: "6px",
    paddingRight: "6px",
  },
  sliderMarkLabelStyleBetween0To1: {
    "&[data-index='0']": {
      left: "4px !important",
    },
    "&[data-index='1']": {
      left: "99% !important",
    },
  },
  sliderMarkLabelStyleBetween0To100Percent: {
    "&[data-index='0']": {
      left: "12px !important",
    },
    "&[data-index='1']": {
      left: "94% !important",
    },
  },
  sliderMarkLabelStyleBetween0To100: {
    "&[data-index='0']": {
      left: "0px !important",
    },
    "&[data-index='1']": {
      left: "97% !important",
    },
  },
  visibilityHidden: {
    visibility: "hidden",
  },
  numberInputWithoutArrows: {
    "&[type=number]": {
      "-moz-appearance": "textfield",
      appearance: "textfield",
      "-webkit-appearance": "textfield",
    },
    "&[type=number]::-webkit-inner-spin-button, &[type=number]::-webkit-outer-spin-button":
      {
        "-webkit-appearance": "none",
        margin: 0,
      },
  },
  defaultDataSet: {
    pointerEvents: "none",
    opacity: "0.7",
  },
}));

/*
const BootstrapInput = withStyles((theme: Theme) =>
  createStyles({
    root: {
      "label + &": {
        marginTop: theme.spacing(1),
      },
    },
    input: {
      position: "relative",
      border: "1px solid #ced4da",
      fontSize: 14,
      padding: "10px 26px 10px 12px",
      transition: theme.transitions.create(["border-color", "box-shadow"]),
      "&:focus": {
        borderRadius: 4,
        borderColor: "#80bdff",
        boxShadow: "0 0 0 0.2rem rgba(0,123,255,.25)",
      },
    },
  })
)(InputBase);
*/

// const ITEM_HEIGHT = 48;
// const ITEM_PADDING_TOP = 8;
// const MenuProps = {
//   PaperProps: {
//     style: {
//       maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
//       width: 250,
//     },
//   },
// };

type NumberInputStateValue = number | undefined;

interface FileState {
  description: string;
  isDefaultCheckpoint: boolean;
  name: string;
  fileObject: any;
  files: {
    models: {
      // name: string[],
      fileObjects: File[];
      description: "";
    };
    additionalFiles: {
      // name: string[],
      fileObjects: File[];
      description: string;
    }[];
  };
}

type NumberInputFieldEndAdormentProps = {
  setStateFunction: React.Dispatch<React.SetStateAction<InputFieldState<any>>>;
};
function NumberInputFieldEndAdorment(props: NumberInputFieldEndAdormentProps) {
  const { setStateFunction } = props;

  function handleNumberInputIncrementDecrementClick(
    setStateFunction: React.Dispatch<
      React.SetStateAction<InputFieldState<NumberInputStateValue>>
    >,
    type: "increment" | "decrement"
  ) {
    setStateFunction((oldState: InputFieldState<any>): InputFieldState<any> => {
      let oldValue = oldState.value;
      if (oldValue === undefined) {
        // return { ...oldState };
        oldValue = "0.0.1";
      }
      return {
        ...oldState,
        isDirty: true,
        isTouched: true,
        value:
          // Number(oldValue) +
          // (type === "increment" ? 1 : type === "decrement" ? -1 : 0),
          // Number(oldValue) +
          // (type === "increment" ? 1 : type === "decrement" ? -1 : 0),
          type === "increment"
            ? incrementVersionBy0Point0Point1(oldValue)
            : type === "decrement"
            ? decrementVersionBy0Point0Point1(oldValue)
            : "",
      };
    });
  }

  return (
    <InputAdornment
      style={{ cursor: "pointer", height: "100%" }}
      position="end"
    >
      <span
        style={{
          height: "100%",
          width: "22px",
          textAlign: "center",
          cursor: "pointer",
        }}
        onClick={() =>
          handleNumberInputIncrementDecrementClick(
            setStateFunction,
            "decrement"
          )
        }
      >
        -
      </span>
      <span
        style={{
          height: "100%",
          width: "22px",
          textAlign: "center",
          cursor: "pointer",
        }}
        onClick={() =>
          handleNumberInputIncrementDecrementClick(
            setStateFunction,
            "increment"
          )
        }
      >
        +
      </span>
    </InputAdornment>
  );
}

const AddModelCollection = (props: Props) => {
  // const [selected, setSelected] = useState<string[]>([]);
  const {
    // tags,
    // isAddSuccess,
    // isTagsFetching,
    // isAdding,
    // getTags,
    // addCollection,
    // addErrorMessage,
    // addCollectionPageInit,
    setShouldFetchModelCollections,
    trainingDataDirectoryState,
    setTrainingDataDirectoryState,
    shouldFetchModelCollections,
    setSideDrawer,
  } = props;
  // const location = useLocation();
  // tags
  const [isModelBeingUpdatedInDB, setIsModelBeingUpdatedInDB] = useState(false);
  const [tagsState, setTagsState] = useState<InputFieldState<string[]>>({
    value: [],
  });
  function handleTagsInputChangeEvent(eventValue: string) {
    if (eventValue.includes(",")) {
      const values: string[] = eventValue
        .split(",")
        .map((value) => value.trim())
        .filter((value: string) => {
          if (!value) {
            return false;
          }
          return true;
        });

      if (values?.length > 0) {
        const oldValue = tagsState.value;
        for (const value of values) {
          if (!oldValue.includes(value)) {
            oldValue.push(value);
          }
        }

        const tagsToSave = removeDuplicateElementsFromArray(oldValue);

        setTagsState({
          ...tagsState,
          isDirty: true,
          isTouched: true,
          value: [...tagsToSave],
        });
      }
    }
  }
  useEffect(() => {
    return () => {
      setTrainingDataDirectoryState({
        ...trainingDataDirectoriesSelectedStateForAddModelCollectionInitialValue,
      });
    };
  }, []);

  const urlQuery = useURLQuery();
  const modelId: string = urlQuery.get("model") as string;

  const [
    isAvailableTrainingDataDirectoriesFetchedAtleastOnce,
    setIsAvailableTrainingDataDirectoriesFetchedAtleastOnce,
  ] = useState<boolean>(false);

  const scenario: AddModelCollectionScenario = props.scenario;

  // const [modelCollectionForStartCheckpoint, setModelCollectionForStartCheckpoint] = useState<{_id: string, version: string}>({_id: "", version: ""});
  const [
    modelCollectionIdForStartCheckpoint,
    setModelCollectionIdForStartCheckpoint,
  ] = useState<string>();
  // const [modelCollectionVersionForStartCheckpoint, setModelCollectionVersionForStartCheckpoint] = useState<IModelCollectionVersion>();
  const [
    modelCollectionsHavingDefaultCheckpoints,
    setModelCollectionsHavingDefaultCheckpoints,
  ] = useState<IModelCollection[]>([]);

  const getModelCollectionFromId = useCallback(
    (
      modelCollectionId: string,
      modelCollections: IModelCollection[]
    ): IModelCollection | null => {
      if (modelCollections?.length > 0) {
        for (const modelCollection of modelCollections) {
          if (modelCollection._id === modelCollectionId) {
            return modelCollection;
          }
        }
      }
      return null;
    },
    []
  );

  /**
   * This effect is used to fetch model-collections which have files in their files section
   */
  useEffect(() => {
    const executeFunction = async () => {
      const apiResponse =
        await DataService.getModelCollectionsHavingDefaultCheckpoints({
          modelId: modelId,
        });

      apiResponse.data = sortVersionsInDescendingOrder(
        apiResponse.data
      ) as IModelCollection[];

      setModelCollectionsHavingDefaultCheckpoints(apiResponse.data);
    };
    if (modelId && scenario === "reTrainingDataSetCollections") {
      executeFunction();
    }
  }, [scenario, modelId, shouldFetchModelCollections]);

  /**
   * Whenever user selects a model collection in startCheckpoint section,
   * This effect is used to set default checkpoint value corresponding that model collection
   */
  useEffect(() => {
    if (modelCollectionsHavingDefaultCheckpoints?.length > 0) {
      let modelCollectionForStartCheckpoint: IModelCollection =
        null as unknown as IModelCollection;

      // fetching modelCollection object which is selected in startCheckpoint section
      for (const modelCollection of modelCollectionsHavingDefaultCheckpoints) {
        if (modelCollection._id === modelCollectionIdForStartCheckpoint) {
          modelCollectionForStartCheckpoint = modelCollection;
          break;
        }
      }
      if (modelCollectionForStartCheckpoint?.checkpointFiles?.length > 0) {
        for (const modelCollectionFile of modelCollectionForStartCheckpoint?.checkpointFiles) {
          if (modelCollectionFile.isDefaultCheckpoint) {
            setStartCheckpointState((oldState) => {
              return {
                ...oldState,
                value: modelCollectionFile._id,
              };
            });
            break;
          }
        }
      }
    }
  }, [
    modelCollectionIdForStartCheckpoint,
    modelCollectionsHavingDefaultCheckpoints,
  ]);

  const {
    formState: { errors },
  } = useForm<IAddCollectionPayload>();
  const classes = useStyles();

  const fileInputRef = useRef<null | HTMLInputElement>(null);
  const modelFileInputRef = useRef<null | HTMLInputElement>(null);
  const additionalFileInputRef = useRef<null | HTMLInputElement>(null);
  const [filesState, setFilesState] = useState<
    InputFieldState<FileState | null>
  >({
    value: null,
  });
  const parentCheckpointFile = filesState.value;
  useEffect(() => {
    if (scenario === "addModelCollection") {
      if (!parentCheckpointFile) {
        setFilesState((oldState) => {
          return {
            ...oldState,
            errorMessage: "Checkpoint File Required",
          };
        });
      } else {
        setFilesState((oldState) => {
          return { ...oldState, errorMessage: "" };
        });
      }
    }
  }, [parentCheckpointFile]);

  const [
    isGetDataSetCollectionUniqueNamesApiExecuting,
    setIsGetDataSetCollectionUniqueNamesApiExecuting,
  ] = useState<boolean>(false);
  const [
    trainingDataDirectoriesAvailable,
    setTrainingDataDirectoriesAvailable,
  ] = useState<string[]>([]);
  useEffect(() => {
    const executeFunction = async () => {
      try {
        setIsGetDataSetCollectionUniqueNamesApiExecuting(true);
        const apiResponse = await DataService.getDataSetCollectionUniqueNames({
          modelId: modelId,
        });

        setTrainingDataDirectoriesAvailable([...apiResponse.data]);
      } catch (error) {}
      setIsAvailableTrainingDataDirectoriesFetchedAtleastOnce(true);
      setIsGetDataSetCollectionUniqueNamesApiExecuting(false);
    };
    if (modelId) {
      executeFunction();
    }
  }, [modelId]);

  const trainingDataDirectories = trainingDataDirectoryState.value;
  useEffect(() => {
    if (trainingDataDirectories.length === 0) {
      setTrainingDataDirectoryState({
        ...trainingDataDirectoryState,
        errorMessage: "Training Data Directory Required",
      });
    } else {
      setTrainingDataDirectoryState({
        ...trainingDataDirectoryState,
        errorMessage: "",
      });
    }
  }, [trainingDataDirectories]);
  // useEffect(() => {
  //   if (tagsState.value.length === 0) {
  //     setTagsState({
  //       ...tagsState,
  //       errorMessage: "Tag field is required",
  //     });
  //   } else {
  //     setTagsState({
  //       ...tagsState,
  //       errorMessage: "",
  //     });
  //   }
  // }, [tagsState.value]);

  // const [labels, setLabels] = useState<string[]>([
  //   "silence",
  //   "unknown",
  //   "door_open",
  //   "door",
  //   "open",
  // ]);

  const [labelsAvailable, setLabelsAvailable] = useState<IModelLabelsAvailable>(
    []
  );

  // const [labelsState, setLabelsState] = useState<InputFieldState<string[]>>({
  //   value: [],
  // });
  // const labels = labelsState.value;
  // useEffect(() => {
  //   if (labels.length === 0) {
  //     setLabelsState((oldState) => {
  //       return { ...oldState, errorMessage: "Labels Required" };
  //     });
  //   } else {
  //     setLabelsState((oldState) => {
  //       return { ...oldState, errorMessage: "" };
  //     });
  //   }
  // }, [labels])

  /**
   * This effect is used to not allow labels which are not part of labelsAvailable
   * in labels input field
   */
  // useEffect(() => {
  //   if (labels?.length>0 && labelsAvailable?.length>0) {
  //     let labelsValueToUpdate: boolean = false;
  //     let oldLabels = labels;
  //     for (let labelIndex = 0; labelIndex < oldLabels.length; labelIndex++) {
  //       const label = oldLabels[labelIndex];
  //       if (labelsAvailable.indexOf(label) === -1) {
  //         labelsValueToUpdate = true;
  //         oldLabels.splice(labelIndex, 1);
  //         labelIndex = labelIndex - 1;
  //       }
  //     }
  //     if (labelsValueToUpdate) {
  //       setLabelsState((oldState) => {
  //         return { ...oldState, value: [...oldLabels]}
  //       })
  //     }
  //   }
  // }, [labels, labelsAvailable])

  /**
   * This effect is used for below reasons:
   * -  to not allow trainingDataDirectories which are not part of trainingDataDirectoriesAvailable
   *    in trainingDataDirectories input field
   */
  useEffect(() => {
    if (
      trainingDataDirectories?.length > 0 &&
      isAvailableTrainingDataDirectoriesFetchedAtleastOnce &&
      scenario !== "addModelCollection"
    ) {
      let trainingDataDirectoriesValueToUpdate: boolean = false;
      let oldtrainingDataDirectories = trainingDataDirectories;
      for (let index = 0; index < oldtrainingDataDirectories.length; index++) {
        const trainingDataDirectory = oldtrainingDataDirectories[index];
        if (
          trainingDataDirectoriesAvailable.indexOf(trainingDataDirectory) === -1
        ) {
          trainingDataDirectoriesValueToUpdate = true;
          oldtrainingDataDirectories.splice(index, 1);
          index = index - 1;
        }
      }
      if (trainingDataDirectoriesValueToUpdate) {
        setTrainingDataDirectoryState({
          ...trainingDataDirectoryState,
          value: [...oldtrainingDataDirectories],
        });
      }
    }
  }, [
    trainingDataDirectories,
    trainingDataDirectoriesAvailable,
    isAvailableTrainingDataDirectoriesFetchedAtleastOnce,
    scenario,
  ]);

  useEffect(() => {
    const uniqueTrainingDataDirectories =
      removeDuplicateElementsFromArray<ITrainingDataDirectory>(
        trainingDataDirectories
      );
    if (
      uniqueTrainingDataDirectories.length !== trainingDataDirectories.length
    ) {
      setTrainingDataDirectoryState({
        ...trainingDataDirectoryState,
        value: [...uniqueTrainingDataDirectories],
      });
    }
  }, [trainingDataDirectories]);

  const [modelVersionState, setModelVersionState] = useState<
    InputFieldState<string | undefined>
  >({
    value: undefined,
    isDirty: false,
    isTouched: false,
  });
  const [highestModelVersionValue, setHighestModelVersionValue] =
    useState<string>();
  const modelVersion = modelVersionState.value;
  useEffect(() => {
    // console.log(" Highest Model Version Value and Current Model Version Value are ::: ", highestModelVersionValue, "  :::  ", modelVersion, "  :::  ", modelVersionState.value)

    if (isEmpty(modelVersion as string)) {
      setModelVersionState((oldState) => {
        return { ...oldState, errorMessage: "Model Version Required" };
      });
    } else if (
      modelVersion &&
      highestModelVersionValue &&
      isFirstParamVersionLessThanSecondParamVersion(
        modelVersion,
        highestModelVersionValue
      )
    ) {
      // console.log(" Highest Model Version Value and Current Model Version Value are ::: ", highestModelVersionValue, "  :::  ", modelVersion)
      setModelVersionState((oldState) => {
        return {
          ...oldState,
          errorMessage:
            "Model version can't be less than " + highestModelVersionValue,
        };
      });
    } else if (!isValidMajorMinorPatchVersion(modelVersion || "0.0.0")) {
      setModelVersionState((oldState) => {
        return {
          ...oldState,
          errorMessage: `Model version format is incorrect. It should be in following form: <MajorNo>.<MinorNo>.<PatchNo> where number value cannot be more than 255`,
        };
      });
    } else {
      setModelVersionState((oldState) => {
        return { ...oldState, errorMessage: "" };
      });
    }
  }, [modelVersion, highestModelVersionValue]);

  // const [backgroundVolumeState, setBackgroundVolumeState] = useState<
  //   InputFieldState<NumberInputStateValue>
  // >({ value: 0.23 });
  // const backgroundVolume = backgroundVolumeState.value;
  // useEffect(() => {
  //   if (isEmpty(backgroundVolume)) {
  //     setBackgroundVolumeState((oldState) => {
  //       return { ...oldState, errorMessage: "Background Volume Required" };
  //     });
  //   } else {
  //     setBackgroundVolumeState((oldState) => {
  //       return { ...oldState, errorMessage: "" };
  //     });
  //   }
  // }, [backgroundVolume]);

  // const [backgroundFrequencyState, setBackgroundFrequencyState] = useState<InputFieldState<number>>({value: 0.13});
  // const backgroundFrequency = backgroundFrequencyState.value;
  // const backgroundFrequencyErrorMessage = backgroundFrequencyState.errorMessage;
  // useEffect(() => {
  //   if (!isNumber(backgroundFrequency)) {
  //     setBackgroundFrequencyState((oldState) => {
  //       return { ...oldState, errorMessage: "Background Frequency must be a number" };
  //     })
  //   } else if (backgroundFrequency < 0) {
  //     setBackgroundFrequencyState((oldState) => {
  //       return { ...oldState, value: 0 };
  //     })
  //   } else if (backgroundFrequency > 1) {
  //     setBackgroundFrequencyState((oldState) => {
  //       return { ...oldState, value: 1 };
  //     })
  //   } else {
  //     setBackgroundFrequencyState((oldState) => {
  //       return { ...oldState, errorMessage: "" };
  //     })
  //   }
  // }, [backgroundFrequency, backgroundFrequencyErrorMessage])

  const [numberOfLayersToFreezeState, setNumberOfLayersToFreezeState] =
    useState<InputFieldState<number>>({ value: 1 });
  const numberOfLayersToFreeze = numberOfLayersToFreezeState.value;
  // const numberOfLayersToFreezeErrorMessage = numberOfLayersToFreezeState.errorMessage;

  useEffect(() => {
    if (scenario !== "reTrainingDataSetCollections") {
      setNumberOfLayersToFreezeState({
        value: 1,
        isDirty: false,
        isTouched: false,
        errorMessage: "",
        canShowErrorToUser: false,
      });
    }
  }, [scenario]);

  // const [silencePercentage, setSilencePercentage] = useState<number>(10);

  // const [unknownPercentageState, setUnknownPercentageState] = useState<
  //   InputFieldState<NumberInputStateValue>
  // >({ value: 10 });
  // const unknownPercentage = unknownPercentageState.value;
  // useEffect(() => {
  //   if (isEmpty(unknownPercentage)) {
  //     setUnknownPercentageState((oldState) => {
  //       return { ...oldState, errorMessage: "Unknown Percentage Required" };
  //     });
  //   } else {
  //     setUnknownPercentageState((oldState) => {
  //       return { ...oldState, errorMessage: "" };
  //     });
  //   }
  // }, [unknownPercentage]);

  // const [testingPercentage, setTestingPercentage] = useState<number>(10);

  // const [validationPercentage, setValidationPercentage] = useState<number>(10);

  // const jsonTextFieldDataDefaultValue = "";
  // const [jsonTextFieldData, setJsonTextFieldData] = useState<string>(
  //   jsonTextFieldDataDefaultValue
  // );

  const [isShowingFormErrorsToUser, setIsShowingFormErrorsToUser] =
    useState<boolean>(false);

  // const [
  //   isFillDataViaFileInfoDialogShowing,
  //   setIsFillDataViaFileInfoDialogShowing,
  // ] = useState<boolean>(false);

  const isFormValid = (): boolean => {
    if (
      filesState.errorMessage ||
      // labelsState.errorMessage ||
      modelVersionState.errorMessage ||
      trainingDataDirectoryState.errorMessage ||
      // tagsState.errorMessage ||
      // timeShiftState.errorMessage ||
      // sampleRateState.errorMessage ||
      // clipDurationState.errorMessage ||
      // dctCoefficientCountState.errorMessage ||
      // windowSizeState.errorMessage ||
      // windowStrideState.errorMessage ||
      // trainingStepsState.errorMessage ||
      // learningRateState.errorMessage ||
      // evaluationStepIntervalState.errorMessage ||
      // saveStepIntervalState.errorMessage ||
      // batchSizeState.errorMessage ||
      startCheckpointState.errorMessage ||
      modelArchitectureState.errorMessage ||
      // modelSizeInfoState.errorMessage ||
      modelDescriptionState.errorMessage
      // unknownPercentageState.errorMessage ||
      // backgroundFrequencyState.errorMessage ||
      // backgroundVolumeState.errorMessage ||
    ) {
      return false;
    }

    return true;
  };

  function makeAllFormFieldsTouched() {
    setFilesState((oldState) => {
      return { ...oldState, isTouched: true };
    });
    // setLabelsState((oldState) => {
    //   return { ...oldState, isTouched: true };
    // });
    setModelVersionState((oldState) => {
      return { ...oldState, isTouched: true };
    });
    setTrainingDataDirectoryState({
      ...trainingDataDirectoryState,
      isTouched: true,
    });
    // setTagsState({
    //   ...tagsState,
    //   isTouched: true,
    // });
    setTimeShiftState((oldState) => {
      return { ...oldState, isTouched: true };
    });
    setSampleRateState((oldState) => {
      return { ...oldState, isTouched: true };
    });
    setClipDurationState((oldState) => {
      return { ...oldState, isTouched: true };
    });
    setDctCoefficientCountState((oldState) => {
      return { ...oldState, isTouched: true };
    });
    setWindowSizeState((oldState) => {
      return { ...oldState, isTouched: true };
    });
    setWindowStrideState((oldState) => {
      return { ...oldState, isTouched: true };
    });
    setTrainingStepsState((oldState) => {
      return { ...oldState, isTouched: true };
    });
    setLearningRateState((oldState) => {
      return { ...oldState, isTouched: true };
    });
    setEvaluationStepIntervalState((oldState) => {
      return { ...oldState, isTouched: true };
    });
    setSaveStepIntervalState((oldState) => {
      return { ...oldState, isTouched: true };
    });
    setBatchSizeState((oldState) => {
      return { ...oldState, isTouched: true };
    });
    setStartCheckpointState((oldState) => {
      return { ...oldState, isTouched: true };
    });
    setModelArchitectureState((oldState) => {
      return { ...oldState, isTouched: true };
    });
    // setModelSizeInfoState((oldState) => {
    //   return { ...oldState, isTouched: true };
    // });
    setModelDescriptionState((oldState) => {
      return { ...oldState, isTouched: true };
    });
    // setUnknownPercentageState((oldState) => {
    //   return { ...oldState, isTouched: true };
    // });
    // setBackgroundVolumeState((oldState) => {
    //   return { ...oldState, isTouched: true };
    // });
  }

  useEffect(() => {
    if (isShowingFormErrorsToUser) {
      makeAllFormFieldsTouched();
    }
  }, [isShowingFormErrorsToUser]);

  const [timeShiftState, setTimeShiftState] = useState<
    InputFieldState<NumberInputStateValue>
  >({
    value: 100,
    isDirty: false,
    isTouched: false,
  });
  const timeShift = timeShiftState.value;
  useEffect(() => {
    if (isEmpty(timeShift)) {
      setTimeShiftState((oldState) => {
        return { ...oldState, errorMessage: "Time Shift Required" };
      });
    } else {
      setTimeShiftState((oldState) => {
        return { ...oldState, errorMessage: "" };
      });
    }
  }, [timeShift]);

  const [sampleRateState, setSampleRateState] = useState<
    InputFieldState<NumberInputStateValue>
  >({
    value: 16000,
    isDirty: false,
    isTouched: false,
  });
  const sampleRate = sampleRateState.value;
  useEffect(() => {
    if (isEmpty(sampleRate)) {
      setSampleRateState((oldState) => {
        return { ...oldState, errorMessage: "Sample Rate Required" };
      });
    } else {
      setSampleRateState((oldState) => {
        return { ...oldState, errorMessage: "" };
      });
    }
  }, [sampleRate]);

  const [clipDurationState, setClipDurationState] = useState<
    InputFieldState<NumberInputStateValue>
  >({
    value: 1000,
    isDirty: false,
    isTouched: false,
  });
  const clipDuration = clipDurationState.value;
  useEffect(() => {
    if (isEmpty(clipDuration)) {
      setClipDurationState((oldState) => {
        return { ...oldState, errorMessage: "Clip Duration Required" };
      });
    } else {
      setClipDurationState((oldState) => {
        return { ...oldState, errorMessage: "" };
      });
    }
  }, [clipDuration]);

  const [dctCoefficientCountState, setDctCoefficientCountState] = useState<
    InputFieldState<NumberInputStateValue>
  >({
    value: 400,
    isDirty: false,
    isTouched: false,
  });
  const dctCoefficientCount = dctCoefficientCountState.value;
  useEffect(() => {
    if (isEmpty(dctCoefficientCount)) {
      setDctCoefficientCountState((oldState) => {
        return { ...oldState, errorMessage: "DCT Coefficient Count Required" };
      });
    } else {
      setDctCoefficientCountState((oldState) => {
        return { ...oldState, errorMessage: "" };
      });
    }
  }, [dctCoefficientCount]);

  const [windowSizeState, setWindowSizeState] = useState<
    InputFieldState<NumberInputStateValue>
  >({
    value: 30,
    isDirty: false,
    isTouched: false,
    errorMessage: "",
  });
  const windowSize = windowSizeState.value;
  useEffect(() => {
    if (isEmpty(windowSize)) {
      setWindowSizeState((oldState) => {
        return { ...oldState, errorMessage: "Window Size Required" };
      });
    } else {
      setWindowSizeState((oldState) => {
        return { ...oldState, errorMessage: "" };
      });
    }
  }, [windowSize]);

  const [windowStrideState, setWindowStrideState] = useState<
    InputFieldState<NumberInputStateValue>
  >({
    value: 10,
    isDirty: false,
    isTouched: false,
  });
  const windowStride = windowStrideState.value;
  useEffect(() => {
    if (isEmpty(windowStride)) {
      setWindowStrideState((oldState) => {
        return { ...oldState, errorMessage: "Window Stride Required" };
      });
    } else {
      setWindowStrideState((oldState) => {
        return { ...oldState, errorMessage: "" };
      });
    }
  }, [windowStride]);

  const [trainingStepsState, setTrainingStepsState] = useState<
    InputFieldState<string>
  >({
    isDirty: false,
    isTouched: false,
    value: "15000,3000",
  });
  const trainingSteps = trainingStepsState.value;
  useEffect(() => {
    if (isEmpty(trainingSteps)) {
      setTrainingStepsState((oldState) => {
        return { ...oldState, errorMessage: "Training Steps Required" };
      });
    } else {
      setTrainingStepsState((oldState) => {
        return { ...oldState, errorMessage: "" };
      });
    }
  }, [trainingSteps]);

  // const [learningRate, setLearningRate] = useState<string>("0.001,0.0001");
  const [learningRateState, setLearningRateState] = useState<
    InputFieldState<string>
  >({
    value: "0.001,0.0001",
  });
  const learningRate = learningRateState.value;
  useEffect(() => {
    if (isEmpty(learningRate)) {
      setLearningRateState((oldState) => {
        return { ...oldState, errorMessage: "Learning Rate Required" };
      });
    } else {
      setLearningRateState((oldState) => {
        return { ...oldState, errorMessage: "" };
      });
    }
  }, [learningRate]);

  // const [evaluationStepInterval, setEvaluationStepInterval] = useState<
  //   NumberInputStateValue
  // >(400);
  const [evaluationStepIntervalState, setEvaluationStepIntervalState] =
    useState<InputFieldState<NumberInputStateValue>>({ value: 400 });
  const evaluationStepInterval = evaluationStepIntervalState.value;
  useEffect(() => {
    if (isEmpty(evaluationStepInterval)) {
      setEvaluationStepIntervalState((oldState) => {
        return {
          ...oldState,
          errorMessage: "Evaluation Step Interval Required",
        };
      });
    } else {
      setEvaluationStepIntervalState((oldState) => {
        return { ...oldState, errorMessage: "" };
      });
    }
  }, [evaluationStepInterval]);

  const [saveStepIntervalState, setSaveStepIntervalState] = useState<
    InputFieldState<NumberInputStateValue>
  >({ value: 100 });
  const saveStepInterval = saveStepIntervalState.value;
  useEffect(() => {
    if (isEmpty(saveStepInterval)) {
      setSaveStepIntervalState((oldState) => {
        return {
          ...oldState,
          errorMessage: "Save Step Interval Required",
        };
      });
    } else {
      setSaveStepIntervalState((oldState) => {
        return { ...oldState, errorMessage: "" };
      });
    }
  }, [saveStepInterval]);

  const [batchSizeState, setBatchSizeState] = useState<
    InputFieldState<NumberInputStateValue>
  >({ value: 100 });
  const batchSize = batchSizeState.value;
  useEffect(() => {
    if (isEmpty(batchSize)) {
      setBatchSizeState((oldState) => {
        return {
          ...oldState,
          errorMessage: "Batch Size Required",
        };
      });
    } else {
      setBatchSizeState((oldState) => {
        return { ...oldState, errorMessage: "" };
      });
    }
  }, [batchSize]);

  const [startCheckpointState, setStartCheckpointState] = useState<
    InputFieldState<string>
  >({
    value:
      scenario === "reTrainingDataSetCollections"
        ? ""
        : ModelCollectionStartCheckpointUndefinedValue,
  });
  const startCheckpoint = startCheckpointState.value;
  useEffect(() => {
    if (
      isEmpty(startCheckpoint) &&
      scenario === "reTrainingDataSetCollections"
    ) {
      setStartCheckpointState((oldState) => {
        return {
          ...oldState,
          errorMessage: "Start Checkpoint Required",
        };
      });
    } else {
      setStartCheckpointState((oldState) => {
        return { ...oldState, errorMessage: "" };
      });
    }
  }, [startCheckpoint, scenario]);

  const [modelArchitectureState, setModelArchitectureState] = useState<
    InputFieldState<string>
  >({ value: "" });
  const modelArchitecture = modelArchitectureState.value;
  useEffect(() => {
    if (isEmpty(modelArchitecture)) {
      setModelArchitectureState((oldState) => {
        return {
          ...oldState,
          errorMessage: "Model Architecture Required",
        };
      });
    } else {
      setModelArchitectureState((oldState) => {
        return { ...oldState, errorMessage: "" };
      });
    }
  }, [modelArchitecture]);

  // const [modelSizeInfoState, setModelSizeInfoState] = useState<
  //   InputFieldState<string>
  // >({ value: "128 128 128" });
  // const modelSizeInfo = modelSizeInfoState.value;
  // useEffect(() => {
  //   if (isEmpty(modelSizeInfo)) {
  //     setModelSizeInfoState((oldState) => {
  //       return {
  //         ...oldState,
  //         errorMessage: "Model Size Info Required",
  //       };
  //     });
  //   } else {
  //     setModelSizeInfoState((oldState) => {
  //       return { ...oldState, errorMessage: "" };
  //     });
  //   }
  // }, [modelSizeInfo]);

  const [modelDescriptionState, setModelDescriptionState] = useState<
    InputFieldState<string>
  >({ value: "" });
  const modelDescription = modelDescriptionState.value;
  useEffect(() => {
    if (isEmpty(modelDescription)) {
      setModelDescriptionState((oldState) => {
        return {
          ...oldState,
          errorMessage: "Model Description Required",
        };
      });
    } else {
      setModelDescriptionState((oldState) => {
        return { ...oldState, errorMessage: "" };
      });
    }
  }, [modelDescription]);

  const [isAddCollectionApiExecuting, setIsAddCollectionApiExecuting] =
    useState<boolean>(false);

  const [architecturesAvailable, setArchitecturesAvailable] =
    useState<IModelArchitecturesAvailable>([]);

  const [
    isGetArchitectureAvailableApiExecuting,
    setIsGetArchitectureAvailableApiExecuting,
  ] = useState<boolean>(false);
  useEffect(() => {
    const executeFunction = async () => {
      try {
        if (modelId) {
          setIsGetArchitectureAvailableApiExecuting(true);
          const apiResponse = await ModelService.getModelArchitecturesAvailable(
            {
              modelId: modelId,
            }
          );
          setArchitecturesAvailable(apiResponse.data);
        }
      } catch (error: any) {
        alert(error.message);
      }
      setIsGetArchitectureAvailableApiExecuting(false);
    };
    executeFunction();
  }, [modelId]);

  const [
    isGetLabelsAvailableApiExecuting,
    setIsGetLabelsAvailableApiExecuting,
  ] = useState<boolean>(false);
  useEffect(() => {
    const executeFunction = async () => {
      try {
        if (modelId) {
          setIsGetLabelsAvailableApiExecuting(true);
          const apiResponse = await ModelService.getModelLabelsAvailable({
            modelId: modelId,
          });
          setLabelsAvailable(apiResponse.data);
        }
      } catch (error: any) {
        alert(error.message);
      }
      setIsGetLabelsAvailableApiExecuting(false);
    };
    executeFunction();
  }, [modelId]);

  /**
   * This effect is used to restrict mouse wheel scroll in input type number elements
   */
  useEffect(() => {
    const handleWheelEvent = function (event: any) {
      // @ts-ignore
      if (document?.activeElement?.type === "number") {
        // @ts-ignore
        document?.activeElement?.blur();
      }
    };
    document.addEventListener("wheel", handleWheelEvent);
    return () => {
      document.removeEventListener("wheel", handleWheelEvent);
    };
  }, []);

  /**
   * This effect is used to restrict 'e' on input type number elements
   */
  useEffect(() => {
    const handleKeyDownEvent = function (event: any) {
      // @ts-ignore
      if (document?.activeElement?.type === "number") {
        if (event?.key === "e") {
          event.preventDefault();
        }
      }
    };

    document.addEventListener("keydown", handleKeyDownEvent);
    return () => {
      document.removeEventListener("keydown", handleKeyDownEvent);
    };
  }, []);

  /**
   * This effect is used to maintain string correct format which is like following: sbcd fds,fjkdf
   */
  useEffect(() => {
    let stateValue = trainingStepsState.value;
    let newValue: string[] = [];
    let valueNeedsToUpdate: boolean = false;
    for (let value of stateValue.split(",")) {
      const trimValue = value.trim();
      if (!trimValue || trimValue !== value) {
        valueNeedsToUpdate = true;
      }
      if (trimValue) {
        newValue.push(trimValue);
      }
    }
    if (valueNeedsToUpdate) {
      setTrainingStepsState((oldState) => {
        return {
          ...oldState,
          value: newValue.toString(),
        };
      });
    }
  }, [trainingStepsState.value]);

  /**
   * This effect is used to maintain string correct format which is like following: sbcd fds,fjkdf
   */
  useEffect(() => {
    let stateValue = learningRateState.value;
    let newValue: string[] = [];
    let valueNeedsToUpdate: boolean = false;
    for (let value of stateValue.split(",")) {
      const trimValue = value.trim();
      if (!trimValue || trimValue !== value) {
        valueNeedsToUpdate = true;
      }
      if (trimValue) {
        newValue.push(trimValue);
      }
    }
    if (valueNeedsToUpdate) {
      setLearningRateState((oldState) => {
        return {
          ...oldState,
          value: newValue.toString(),
        };
      });
    }
  }, [learningRateState.value]);

  /**
   * This effect is used to set default value of model version input field
   * which will be biggestVersion number out of all model collections
   */
  useEffect(() => {
    const executeFunction = async () => {
      const apiResponse =
        await DataService.getBiggestVersionOutOfAllModelCollectionsFromDB({
          modelId: modelId,
          modelArchitecture: modelArchitectureState.value,
        });
      // console.log("Api response for currect highest version  ", apiResponse.data);
      setModelVersionState((oldState) => {
        // return { ...oldState, value: Number((apiResponse.data + 0.01).toFixed(2)) }
        return {
          ...oldState,
          value: incrementVersionBy0Point0Point1(apiResponse.data),
        };
      });
      // setHighestModelVersionValue(Number((apiResponse.data + 0.01).toFixed(2)));
      setHighestModelVersionValue(
        incrementVersionBy0Point0Point1(apiResponse.data)
      );
    };
    if (modelId && modelArchitectureState.value) {
      executeFunction();
    }
  }, [modelId, shouldFetchModelCollections, modelArchitectureState.value]);

  const handleInputFileChangeEvent = (files: FileList | null): void => {
    // @ts-ignore
    const file = files[0];

    // // @ts-ignore
    // file.v = true;
    // // @ts-ignore
    // file.description = "";
    // // @ts-ignore
    // file.files={
    //   // @ts-ignore
    //   models:{
    //     // @ts-ignore
    //     name: [],
    //     // @ts-ignore
    //     description: ""
    //   },
    //   // @ts-ignore
    //   additionalFiles: []
    // }

    // @ts-ignore
    setFilesState((oldState) => {
      let oldValue = oldState.value;
      oldValue = {
        isDefaultCheckpoint: true,
        description: "",
        name: file.name,
        files: {
          models: {
            fileObjects: [],
            description: "",
          },
          additionalFiles: [],
        },
        fileObject: file as any,
      };
      return {
        ...oldState,
        isDirty: true,
        isTouched: true,
        value: { ...oldValue },
      };
    });

    // @ts-ignore
    fileInputRef.current.value = "";
  };

  const handleModelInputFileChangeEvent = (files: FileList | null): void => {
    // @ts-ignore
    const file = files[0];

    // @ts-ignore
    setFilesState((oldState) => {
      let oldValue = oldState.value;
      oldValue?.files?.models.fileObjects.push(file);
      return {
        ...oldState,
        isDirty: true,
        isTouched: true,
        value: { ...oldValue },
      };
    });

    // @ts-ignore
    modelFileInputRef.current.value = "";
  };

  const handleAdditionalInputFileChangeEvent = (
    files: FileList | null
  ): void => {
    // @ts-ignore
    const file = files[0];

    // @ts-ignore
    setFilesState((oldState) => {
      let oldValue = oldState.value;
      oldValue?.files?.additionalFiles.push({
        fileObjects: [file],
        description: "",
      });
      return {
        ...oldState,
        isDirty: true,
        isTouched: true,
        value: { ...oldValue },
      };
    });

    // @ts-ignore
    modelFileInputRef.current.value = "";
  };

  // function handleJSONTextFieldDataSubmitButtonClick() {

  //   try {
  //     let fileFieldsData: IAddModelCollectionJsonFieldData;
  //     try {
  //       fileFieldsData = JSON.parse(jsonTextFieldData);
  //     } catch (error) {
  //       alert('Invalid JSON text field data. Please refer information dialog of this section for help')
  //       return;
  //     }

  //     if (
  //       isValidBackgroundFrequencyValue(fileFieldsData.backgroundFrequency)
  //     ) {
  //       setBackgroundFrequencyState(oldState=>{
  //         return {
  //           ...oldState,
  //           isDirty: true,
  //           isTouched: true,
  //           value: fileFieldsData.backgroundFrequency
  //         }
  //       })
  //     }
  //     if (isValidBackgroundVolumeValue(fileFieldsData.backgroundVolume)) {
  //       setBackgroundVolumeState((oldState) => {
  //         return {
  //           ...oldState,
  //           isDirty: true,
  //           isTouched: true,
  //           value: fileFieldsData.backgroundVolume,
  //         };
  //       });
  //     }
  //     if (isValidBatchSizeValue(fileFieldsData.batchSize)) {
  //       setBatchSizeState((oldState) => {
  //         return {
  //           ...oldState,
  //           isDirty: true,
  //           isTouched: true,
  //           value: fileFieldsData.batchSize,
  //         };
  //       });
  //     }
  //     if (isValidClipDurationValue(fileFieldsData.clipDuration)) {
  //       setClipDurationState((oldState) => {
  //         return {
  //           ...oldState,
  //           isDirty: true,
  //           isTouched: true,
  //           value: fileFieldsData.clipDuration,
  //         };
  //       });
  //     }
  //     if (isValidDCTCoefficientCountValue(fileFieldsData.dctCoefficientCount)) {
  //       setDctCoefficientCountState((oldState) => {
  //         return {
  //           ...oldState,
  //           isDirty: true,
  //           isTouched: true,
  //           value: fileFieldsData.dctCoefficientCount,
  //         };
  //       });
  //     }
  //     if (isValidEvaluationStepIntervalValue(fileFieldsData.evaluationStepInterval)) {
  //       setEvaluationStepIntervalState((oldState) => {
  //         return {
  //           ...oldState,
  //           isDirty: true,
  //           isTouched: true,
  //           value: fileFieldsData.evaluationStepInterval,
  //         };
  //       });
  //     }
  //     if (isValidLearningRateValue(fileFieldsData.learningRate)) {
  //       setLearningRateState((oldState) => {
  //         return {
  //           ...oldState,
  //           isDirty: true,
  //           isTouched: true,
  //           value: fileFieldsData.learningRate,
  //         };
  //       });
  //     }
  //     if (isValidModelArchitectureValue(fileFieldsData.modelArchitecture, architecturesAvailable)) {
  //       setModelArchitectureState((oldState) => {
  //         return {
  //           ...oldState,
  //           isDirty: true,
  //           isTouched: true,
  //           value: fileFieldsData.modelArchitecture,
  //         };
  //       });
  //     }
  //     if (isValidModelDescriptionValue(fileFieldsData.modelDescription)) {
  //       setModelDescriptionState((oldState) => {
  //         return {
  //           ...oldState,
  //           isDirty: true,
  //           isTouched: true,
  //           value: fileFieldsData.modelDescription,
  //         };
  //       });
  //     }
  //     if (isValidModelSizeInfoValue(fileFieldsData.modelSizeInfo)) {
  //       setModelSizeInfoState((oldState) => {
  //         return {
  //           ...oldState,
  //           isDirty: true,
  //           isTouched: true,
  //           value: fileFieldsData.modelSizeInfo,
  //         };
  //       });
  //     }
  //     if (isValidSampleRateValue(fileFieldsData.sampleRate)) {
  //       setSampleRateState((oldState) => {
  //         return {
  //           ...oldState,
  //           isDirty: true,
  //           isTouched: true,
  //           value: fileFieldsData.sampleRate,
  //         };
  //       });
  //     }
  //     if (isValidSaveStepIntervalValue(fileFieldsData.saveStepInterval)) {
  //       setSaveStepIntervalState((oldState) => {
  //         return {
  //           ...oldState,
  //           isDirty: true,
  //           isTouched: true,
  //           value: fileFieldsData.saveStepInterval,
  //         };
  //       });
  //     }
  //     if (
  //       isValidSilencePercentageValue(fileFieldsData.silencePercentage)
  //     ) {
  //       setSilencePercentage(fileFieldsData.silencePercentage);
  //     }
  //     if (isValidTestingPercentageValue(fileFieldsData.testingPercentage)) {
  //       setTestingPercentage(fileFieldsData.testingPercentage);
  //     }
  //     if (isValidTimeShiftValue(fileFieldsData.timeShift)) {
  //       setTimeShiftState((oldState) => {
  //         return {
  //           ...oldState,
  //           isDirty: true,
  //           isTouched: true,
  //           value: fileFieldsData.timeShift,
  //         };
  //       });
  //     }
  //     if (isValidTrainingStepsValue(fileFieldsData.trainingSteps)) {
  //       setTrainingStepsState((oldState) => {
  //         return {
  //           ...oldState,
  //           isDirty: true,
  //           isTouched: true,
  //           value: fileFieldsData.trainingSteps,
  //         };
  //       });
  //     }
  //     if (isValidUnknownPercentageValue(fileFieldsData.unknownPercentage)) {
  //       setUnknownPercentageState((oldState) => {
  //         return {
  //           ...oldState,
  //           isDirty: true,
  //           isTouched: true,
  //           value: fileFieldsData.unknownPercentage,
  //         };
  //       });
  //     }
  //     if (isValidValidationPercentageValue(fileFieldsData.validationPercentage)) {
  //       setValidationPercentage(fileFieldsData.validationPercentage);
  //     }
  //     if (isValidWindowSizeValue(fileFieldsData.windowSize)) {
  //       setWindowSizeState((oldState) => {
  //         return {
  //           ...oldState,
  //           isDirty: true,
  //           isTouched: true,
  //           value: fileFieldsData.windowSize,
  //         };
  //       });
  //     }
  //     if (isValidWindowStrideValue(fileFieldsData.windowStride)) {
  //       setWindowStrideState((oldState) => {
  //         return {
  //           ...oldState,
  //           isDirty: true,
  //           isTouched: true,
  //           value: fileFieldsData.windowStride,
  //         };
  //       });
  //     }

  //     setJsonTextFieldData(jsonTextFieldDataDefaultValue);
  //   } catch (error: any) {
  //     alert(error?.response?.data || error?.message);
  //   }
  // }
  const handleAddFileButtonClick = () => {
    fileInputRef.current?.click();
  };

  const handleAddModelFileButtonClick = () => {
    modelFileInputRef.current?.click();
  };

  const handleAddAdditionalFileButtonClick = () => {
    additionalFileInputRef.current?.click();
  };

  const handleAddModelCollectionClick = async () => {
    if (!isFormValid()) {
      setIsShowingFormErrorsToUser(true);
      return;
    }

    const parentCheckpointFileData: IModelCollectionCheckpointFileDataReqPayloadForAddModelCollectionScenario =
      {
        name: parentCheckpointFile?.name || "",
        description: parentCheckpointFile?.description || "",
        isDefaultCheckpoint: true,
        files: {
          models: {
            name: [],
            description: parentCheckpointFile?.files.models.description || "",
          },
          additionalFiles: [],
        },
      };

    if (scenario === "addModelCollection") {
      if (parentCheckpointFile?.files?.models?.fileObjects) {
        if (parentCheckpointFile?.files?.models?.fileObjects?.length > 0) {
          for (const fileObject of parentCheckpointFile?.files?.models
            ?.fileObjects) {
            parentCheckpointFileData.files.models.name.push(fileObject.name);
          }
        }
      }
      if (parentCheckpointFile?.files?.additionalFiles) {
        if (parentCheckpointFile?.files?.additionalFiles?.length > 0) {
          for (const additionalFile of parentCheckpointFile?.files
            ?.additionalFiles) {
            parentCheckpointFileData?.files?.additionalFiles?.push({
              name: [additionalFile.fileObjects[0]?.name],
              description: additionalFile?.description,
            });
          }
        }
      }
    }

    const selectedModelArchitectureHyperParameter: string = (() => {
      try {
        if (modelArchitecture) {
          if (Array.isArray(architecturesAvailable)) {
            const selectedArchitecture = architecturesAvailable.find(
              (architecture) => architecture.label === modelArchitecture
            );
            if (selectedArchitecture) {
              return selectedArchitecture.hyperParameter || "";
            }
          }
        }
      } catch (error) {}
      return "";
    })();

    let requestPayload: IModelCollectionPostReqPayload = {
      tags: tagsState.value,
      // backgroundFrequency: backgroundFrequency as number,
      // backgroundVolume: backgroundVolume as number,
      // batchSize: batchSize as number,
      // clipDuration: clipDuration as number,
      // dctCoefficientCount: dctCoefficientCount as number,
      // evaluationStepInterval: evaluationStepInterval as number,
      // learningRate: learningRate,
      model: modelId,
      trainingDataDirectory: trainingDataDirectories,
      modelArchitecture: modelArchitecture,
      hyperParameter: selectedModelArchitectureHyperParameter,
      modelDescription: modelDescription,
      // modelSizeInfo: modelSizeInfo,
      // sampleRate: sampleRate as number,
      // saveStepInterval: saveStepInterval as number,
      // silencePercentage: silencePercentage as number,
      startCheckpoint: startCheckpoint,
      // testingPercentage: testingPercentage as number,
      // timeShift: timeShift as number,
      // labels: labels,
      // trainingSteps: trainingSteps,
      // unknownPercentage: unknownPercentage as number,
      // validationPercentage: validationPercentage as number,
      version: modelVersion || "",
      // windowSize: windowSize as number,
      // windowStride: windowStride as number,
      // filesToUpload: [...parentCheckpointFile],
      // files: [...parentCheckpointFile],
      parentCheckpointFile:
        scenario === "addModelCollection"
          ? parentCheckpointFile?.fileObject
          : null,
      childCheckpointFilesForModel:
        scenario === "addModelCollection"
          ? parentCheckpointFile?.files?.models?.fileObjects || null
          : null,
      childCheckpointFilesForAdditionalFiles:
        scenario === "addModelCollection"
          ? parentCheckpointFile?.files?.additionalFiles?.map(
              (additionalFile) => additionalFile?.fileObjects[0]
            ) || null
          : null,
      // files: [],
      parentCheckpointFileData:
        scenario === "addModelCollection" ? parentCheckpointFileData : null,
      status: scenario === "addModelCollection" ? "Trained" : "Queued",
      scenario: scenario,
    };
    if (props.defaultDatasetId) {
      requestPayload.defaultDataSetCollectionId = props.defaultDatasetId;
    }
    if (scenario === "reTrainingDataSetCollections") {
      requestPayload.numberOfLayersToFreeze = numberOfLayersToFreeze;
    }

    try {
      setIsAddCollectionApiExecuting(true);
      await DataService.addModelCollectionApi(requestPayload);
      setShouldFetchModelCollections({ shouldFetchModelCollections: true });

      setSideDrawer({
        type: "projectAnalytics",
        component: Analytics,
        isOpen: false,
      });
    } catch (error: any) {
      alert(error.response.data);
    }
    setIsAddCollectionApiExecuting(false);
  };

  // function handleLabelInputChangeEvent(eventValue: string) {
  //   if (eventValue.includes(",")) {
  //     const values: string[] = eventValue
  //       .split(",")
  //       .map((value) => value.trim())
  //       .filter((value: string) => {
  //         if (!value) {
  //           return false;
  //         }
  //         return true;
  //       });

  //     if (values.length > 0) {
  //       setLabelsState((oldState) => {
  //         let oldValue = oldState.value;
  //         for (const value of values) {
  //           if (!oldValue.includes(value)) {
  //             oldValue.push(value);
  //           }
  //         }
  //         return {
  //           ...oldState,
  //           isDirty: true,
  //           isTouched: true,
  //           value: [...oldValue],
  //         };
  //       });
  //     }
  //   }
  // }

  function handleTrainingDataDirectoryInputChangeEvent(eventValue: string) {
    if (eventValue.includes(",")) {
      const values: string[] = eventValue
        .split(",")
        .map((value) => value.trim())
        .filter((value: string) => {
          if (!value) {
            return false;
          }
          return true;
        });

      if (values.length > 0) {
        const oldValue = trainingDataDirectoryState.value;
        for (const value of values) {
          if (!oldValue.includes(value)) {
            oldValue.push(value);
          }
        }
        setTrainingDataDirectoryState({
          ...trainingDataDirectoryState,
          isDirty: true,
          isTouched: true,
          value: [...oldValue],
        });
      }
    }
  }

  // function handleTrainingStepsInputChangeEvent(eventValue: string) {
  //   if (eventValue.includes(",")) {
  //     const values: string[] = eventValue
  //       .split(",")
  //       .map((value) => value.trim())
  //       .filter((value: string) => {
  //         if (!value) {
  //           return false;
  //         }
  //         return true;
  //       });

  //     if (values.length > 0) {
  //       setTrainingStepsState((oldState) => {
  //         let oldValue = oldState.value.split(",");
  //         for (const value of values) {
  //           // if (!oldValue.includes(value)) {
  //             oldValue.push(value);
  //           // }
  //         }
  //         return {
  //           ...oldState,
  //           isDirty: true,
  //           isTouched: true,
  //           value: oldValue.toString(),
  //         };
  //       });
  //     }
  //   }
  // }

  // function handleLearningRateInputChangeEvent(eventValue: string) {
  //   if (eventValue.includes(",")) {
  //     const values: string[] = eventValue
  //       .split(",")
  //       .map((value) => value.trim())
  //       .filter((value: string) => {
  //         if (!value) {
  //           return false;
  //         }
  //         return true;
  //       });

  //     if (values.length > 0) {
  //       setLearningRateState((oldState) => {
  //         let oldValue = oldState.value.split(",");
  //         for (const value of values) {
  //           // if (!oldValue.includes(value)) {
  //             oldValue.push(value);
  //           // }
  //         }
  //         return {
  //           ...oldState,
  //           isDirty: true,
  //           isTouched: true,
  //           value: oldValue.toString(),
  //         };
  //       });
  //     }
  //   }
  // }

  // function handleSetDefaultCheckpointClick(fileIndexWhichGotChecked: number) {
  //   setFilesState((oldState) => {
  //     const oldValue = oldState.value;

  //     for (let index = 0; index < oldValue.length; index++) {
  //       const file = oldValue[index];
  //       if (index === fileIndexWhichGotChecked) {
  //         file.isDefaultCheckpoint = true;
  //       } else {
  //         file.isDefaultCheckpoint = false;
  //       }
  //     }

  //     return {
  //       ...oldState,
  //       value: oldValue,
  //       isDirty: true,
  //       isTouched: true,
  //     }
  //   })
  // }

  /**
   * This effect prefills model collection description based on collection create scenario
   */
  useEffect(() => {
    const executeFunction = async () => {
      if (trainingDataDirectories?.length > 0) {
        let descriptionText = "";
        if (trainingDataDirectories.length === 1) {
          if (scenario === "addModelCollection") {
            descriptionText = `Uploaded on ${trainingDataDirectories[0]}`;
          } else if (scenario === "trainingDataSetCollections") {
            descriptionText = `Training with ${trainingDataDirectories[0]}`;
          } else if (scenario === "reTrainingDataSetCollections") {
            descriptionText = `Re-training ${
              getModelCollectionFromId(
                modelCollectionIdForStartCheckpoint || "",
                modelCollectionsHavingDefaultCheckpoints
              )?.version
                ? `on ${
                    getModelCollectionFromId(
                      modelCollectionIdForStartCheckpoint || "",
                      modelCollectionsHavingDefaultCheckpoints
                    )?.version
                  } `
                : ``
            }with ${trainingDataDirectories[0]}`;
          }
        } else {
          if (modelVersion) {
            const modelCollectionTrainingDataSetExpectedNameResponse =
              await DataService.getModelCollectionTrainingDataSetNameIfMoreThanOneTrainingDataSets(
                {
                  modelCollectionVersion: modelVersion,
                  modelId: modelId,
                }
              );
            if (modelCollectionTrainingDataSetExpectedNameResponse.data) {
              if (scenario === "addModelCollection") {
                descriptionText = `Uploaded on ${modelCollectionTrainingDataSetExpectedNameResponse.data}`;
              } else if (scenario === "trainingDataSetCollections") {
                descriptionText = `Training with ${modelCollectionTrainingDataSetExpectedNameResponse.data}`;
              } else if (scenario === "reTrainingDataSetCollections") {
                descriptionText = `Re-training ${
                  getModelCollectionFromId(
                    modelCollectionIdForStartCheckpoint || "",
                    modelCollectionsHavingDefaultCheckpoints
                  )?.version
                    ? `on ${
                        getModelCollectionFromId(
                          modelCollectionIdForStartCheckpoint || "",
                          modelCollectionsHavingDefaultCheckpoints
                        )?.version
                      } `
                    : ``
                }with ${
                  modelCollectionTrainingDataSetExpectedNameResponse.data
                }`;
              }
            }
          }
        }
        setModelDescriptionState((oldState) => ({
          ...oldState,
          isDirty: true,
          value: descriptionText,
        }));
      }
    };
    if (modelId) {
      executeFunction();
    }
  }, [
    scenario,
    trainingDataDirectories,
    modelCollectionIdForStartCheckpoint,
    modelVersion,
    modelId,
  ]);

  return (
    <Grid
      classes={{ root: classes.root }}
      container
      direction="column"
      alignItems="center"
      justify="center"
    >
      <form
        className={classes.form}
        onSubmit={(e) => {
          e.preventDefault();
          handleAddModelCollectionClick();
        }}
        noValidate
      >
        <Box style={{ maxHeight: "90%", overflow: "auto", overflowX: "clip" }}>
          <Collection className={classes.illustration} />
          <Box m={1.5} />
          <Typography
            component="h3"
            style={{ textAlign: "center" }}
            variant="h5"
          >
            {scenario === "addModelCollection"
              ? "Create Model Collection"
              : scenario === "trainingDataSetCollections"
              ? "Train From Data-Set Collections"
              : scenario === "reTrainingDataSetCollections"
              ? "Re-Train From Data-Set Collections"
              : ""}
          </Typography>
          <Box m={1.5} />

          {/* files section */}
          {scenario === "addModelCollection" && (
            <Box
              className={`${classes.inputSection} ${classes.multipleInputFieldSection}`}
              style={{ minWidth: "308px" }}
            >
              <Box className={classes.inputSectionTitle}>Checkpoint File</Box>
              <input
                onChange={(e) => handleInputFileChangeEvent(e.target.files)}
                ref={fileInputRef}
                style={{ display: "none" }}
                type="file"
                // multiple
              />

              {filesState.errorMessage && filesState.isTouched && (
                <Box style={{ color: "red" }}>{filesState.errorMessage}</Box>
              )}
              {/* selected files section */}
              <Box style={{ paddingLeft: 10, marginTop: 10 }}>
                {/* {parentCheckpointFile.map((file, fileIndex) => { */}
                {/* // selected file section */}
                {/* return ( */}

                {parentCheckpointFile?.name && (
                  <Box
                    // key={fileIndex}
                    style={{ display: "flex", marginBottom: "20px" }}
                  >
                    <Box style={{ width: "100%" }}>
                      <Box>
                        <Box
                          style={{
                            display: "flex",
                            justifyContent: "space-between",
                          }}
                        >
                          <Tooltip title={parentCheckpointFile?.name || ""}>
                            <Typography
                              color="primary"
                              style={{
                                overflow: "hidden",
                                whiteSpace: "nowrap",
                                textOverflow: "ellipsis",
                                maxWidth: "250px",
                              }}
                            >
                              {parentCheckpointFile?.name || ""}
                            </Typography>
                          </Tooltip>
                          <Box style={{ alignSelf: "center" }}>
                            <DeleteIcon
                              style={{
                                color: deleteButtonIconColor,
                                cursor: "pointer",
                              }}
                              onClick={() => {
                                setFilesState((oldState) => {
                                  // const oldValue = oldState.value;
                                  // oldValue.splice(fileIndex, 1);
                                  return {
                                    ...oldState,
                                    isDirty: true,
                                    isTouched: true,
                                    value: null,
                                  };
                                });
                              }}
                            />
                          </Box>
                        </Box>
                        <FormControl
                          className={classes.formControl}
                          style={{ marginBottom: "14px" }}
                        >
                          <TextField
                            variant="outlined"
                            size="small"
                            // margin="normal"
                            fullWidth
                            label="description"
                            value={parentCheckpointFile?.description || ""}
                            onChange={(e) =>
                              // @ts-ignore
                              setFilesState((oldState) => {
                                const oldValue = oldState.value;
                                return {
                                  ...oldState,
                                  isDirty: true,
                                  isTouched: true,
                                  value: {
                                    ...oldValue,
                                    description: e.target.value || "",
                                  },
                                };
                              })
                            }
                            error={Boolean(errors.name)}
                            helperText={errors.name?.message}
                            autoFocus
                          />
                        </FormControl>

                        <div id="modelsSection">
                          <div
                            style={{
                              fontWeight: 500,
                            }}
                          >
                            Model Files
                          </div>
                          <div id="modelFilesContainer">
                            {parentCheckpointFile?.files?.models?.fileObjects?.map(
                              (modelFileObject, modelFileIndex) => {
                                return (
                                  <Box key={modelFileIndex}>
                                    <Box
                                      style={{
                                        display: "flex",
                                        justifyContent: "space-between",
                                      }}
                                    >
                                      <Tooltip
                                        title={modelFileObject?.name || ""}
                                      >
                                        <Typography
                                          color="primary"
                                          style={{
                                            overflow: "hidden",
                                            whiteSpace: "nowrap",
                                            textOverflow: "ellipsis",
                                            maxWidth: "250px",
                                          }}
                                        >
                                          {modelFileObject?.name || ""}
                                        </Typography>
                                      </Tooltip>
                                      <Box style={{ alignSelf: "center" }}>
                                        <DeleteIcon
                                          style={{
                                            color: deleteButtonIconColor,
                                            cursor: "pointer",
                                          }}
                                          onClick={() => {
                                            // @ts-ignore
                                            setFilesState((oldState) => {
                                              const oldValue = oldState.value;
                                              oldValue?.files?.models?.fileObjects.splice(
                                                modelFileIndex,
                                                1
                                              );
                                              return {
                                                ...oldState,
                                                isDirty: true,
                                                isTouched: true,
                                                value: { ...oldValue },
                                              };
                                            });
                                          }}
                                        />
                                      </Box>
                                    </Box>
                                  </Box>
                                );
                              }
                            )}
                            {parentCheckpointFile?.files?.models?.fileObjects
                              ?.length > 0 && (
                              <FormControl className={classes.formControl}>
                                <TextField
                                  variant="outlined"
                                  size="small"
                                  // margin="normal"
                                  fullWidth
                                  label="description"
                                  value={
                                    parentCheckpointFile?.files?.models
                                      ?.description || ""
                                  }
                                  onChange={(e) =>
                                    // @ts-ignore
                                    setFilesState((oldState) => {
                                      const oldValue = oldState.value;
                                      return {
                                        ...oldState,
                                        isDirty: true,
                                        isTouched: true,
                                        value: {
                                          ...oldValue,
                                          files: {
                                            ...oldValue?.files,
                                            models: {
                                              ...oldValue?.files.models,
                                              description: e.target.value || "",
                                            },
                                          },
                                        },
                                      };
                                    })
                                  }
                                  error={Boolean(errors.name)}
                                  helperText={errors.name?.message}
                                />
                              </FormControl>
                            )}
                          </div>
                          <Box
                            style={{
                              textAlign: "end",
                              marginTop: "10px",
                            }}
                          >
                            <Tooltip title="Add Model File">
                              <Button
                                variant="contained"
                                color="primary"
                                startIcon={<AddCircleIcon />}
                                onClick={() => handleAddModelFileButtonClick()}
                                style={{
                                  fontSize: "11px",
                                }}
                              >
                                Add Model File
                              </Button>
                            </Tooltip>
                            <input
                              onChange={(e) =>
                                handleModelInputFileChangeEvent(e.target.files)
                              }
                              ref={modelFileInputRef}
                              style={{ display: "none" }}
                              type="file"
                            />
                          </Box>
                        </div>

                        <div
                          id="additionalSection"
                          style={{
                            marginTop: "10px",
                          }}
                        >
                          <div
                            style={{
                              fontWeight: 500,
                            }}
                          >
                            Additional Files
                          </div>
                          <div id="additionalFilesContainer">
                            {parentCheckpointFile?.files?.additionalFiles?.map(
                              (additionalFile, additionalFileIndex) => {
                                return (
                                  <Box key={additionalFileIndex}>
                                    <Box
                                      style={{
                                        display: "flex",
                                        justifyContent: "space-between",
                                      }}
                                    >
                                      <Tooltip
                                        title={
                                          additionalFile?.fileObjects[0]
                                            ?.name || ""
                                        }
                                      >
                                        <Typography
                                          color="primary"
                                          style={{
                                            overflow: "hidden",
                                            whiteSpace: "nowrap",
                                            textOverflow: "ellipsis",
                                            maxWidth: "250px",
                                          }}
                                        >
                                          {additionalFile?.fileObjects[0]
                                            ?.name || ""}
                                        </Typography>
                                      </Tooltip>
                                      <Box style={{ alignSelf: "center" }}>
                                        <DeleteIcon
                                          style={{
                                            color: deleteButtonIconColor,
                                            cursor: "pointer",
                                          }}
                                          onClick={() => {
                                            // @ts-ignore
                                            setFilesState((oldState) => {
                                              const oldValue = oldState.value;
                                              oldValue?.files?.additionalFiles.splice(
                                                additionalFileIndex,
                                                1
                                              );
                                              return {
                                                ...oldState,
                                                isDirty: true,
                                                isTouched: true,
                                                value: { ...oldValue },
                                              };
                                            });
                                          }}
                                        />
                                      </Box>
                                    </Box>
                                    <FormControl
                                      className={classes.formControl}
                                      style={{
                                        marginBottom: "10px",
                                      }}
                                    >
                                      <TextField
                                        variant="outlined"
                                        size="small"
                                        // margin="normal"
                                        fullWidth
                                        label="description"
                                        value={
                                          additionalFile?.description || ""
                                        }
                                        onChange={(e) =>
                                          // @ts-ignore
                                          setFilesState((oldState) => {
                                            const oldValue = oldState.value;

                                            if (oldValue) {
                                              oldValue.files.additionalFiles[
                                                additionalFileIndex
                                              ].description =
                                                e.target.value || "";
                                            }

                                            return {
                                              ...oldState,
                                              value: {
                                                ...oldValue,
                                              },
                                            };
                                          })
                                        }
                                        error={Boolean(errors.name)}
                                        helperText={errors.name?.message}
                                      />
                                    </FormControl>
                                  </Box>
                                );
                              }
                            )}
                          </div>
                          <Box style={{ textAlign: "end" }}>
                            <Tooltip title="Add Additional File">
                              <Button
                                variant="contained"
                                color="primary"
                                startIcon={<AddCircleIcon />}
                                onClick={() =>
                                  handleAddAdditionalFileButtonClick()
                                }
                                style={{
                                  fontSize: "11px",
                                }}
                              >
                                Add Additional File
                              </Button>
                            </Tooltip>
                            <input
                              onChange={(e) =>
                                handleAdditionalInputFileChangeEvent(
                                  e.target.files
                                )
                              }
                              ref={additionalFileInputRef}
                              style={{ display: "none" }}
                              type="file"
                            />
                          </Box>
                        </div>
                      </Box>
                    </Box>
                  </Box>
                )}
                {/* );
                })} */}
              </Box>

              {!parentCheckpointFile?.name && (
                <Box style={{ textAlign: "end" }}>
                  <Tooltip title="Add Checkpoint File">
                    <Button
                      variant="contained"
                      color="primary"
                      startIcon={<AddCircleIcon />}
                      onClick={() => handleAddFileButtonClick()}
                      style={{
                        fontSize: "11px",
                      }}
                    >
                      Add Checkpoint File
                    </Button>
                    {/* <AddCircleIcon
                        color="primary"
                        text
                        style={{ cursor: "pointer" }}
                        onClick={() => handleAddFileButtonClick()}
                      /> */}
                  </Tooltip>
                </Box>
              )}
            </Box>
          )}

          {/* start checkpoint section */}
          {scenario === "reTrainingDataSetCollections" && (
            <Box
              className={`${classes.inputSection} ${classes.multipleInputFieldSection}`}
            >
              <Box className={classes.inputSectionTitle}>Start Checkpoint</Box>
              <Box style={{ paddingLeft: 10, marginTop: 10 }}>
                <FormControl className={classes.formControl}>
                  <TextField
                    id="standard-select-currency"
                    select
                    label="Select Model Collection Version"
                    value={modelCollectionIdForStartCheckpoint}
                    onChange={(event) => {
                      setModelCollectionIdForStartCheckpoint(
                        event.target.value
                      );
                    }}
                    helperText="Please select model collection version of checkpoint file to choose"
                  >
                    {modelCollectionsHavingDefaultCheckpoints.map((option) => (
                      <MenuItem key={option._id} value={option._id}>
                        <div style={{ width: "100%" }}>
                          <Tooltip title={option.version} enterDelay={200}>
                            <div>{option.version}</div>
                          </Tooltip>
                          <div
                            style={{
                              fontSize: "12px",
                              color: "gray",
                              fontStyle: "italic",
                            }}
                          >
                            <Tooltip
                              title={`Architecture:  ${option.modelArchitecture}`}
                              enterDelay={200}
                            >
                              <div>
                                Architecture: {option.modelArchitecture}
                              </div>
                            </Tooltip>
                            <Tooltip
                              title={`Training Data-Set:  ${option.trainingDataDirectory?.join(
                                ", "
                              )}`}
                              enterDelay={200}
                            >
                              <div
                                style={{
                                  maxWidth: "249px",
                                  overflow: "hidden",
                                  textOverflow: "ellipsis",
                                  whiteSpace: "nowrap",
                                }}
                              >
                                Training Data-Set:{" "}
                                {option.trainingDataDirectory?.join(", ")}
                              </div>
                            </Tooltip>
                          </div>
                        </div>
                      </MenuItem>
                    ))}
                  </TextField>
                </FormControl>
                {modelCollectionIdForStartCheckpoint && (
                  <FormControl
                    className={classes.formControl}
                    style={{ marginTop: 17 }}
                  >
                    <TextField
                      id="standard-select-currency"
                      select
                      label="Select Start Checkpoint"
                      value={startCheckpoint}
                      InputProps={{
                        classes: { input: classes.selectTextFieldInput },
                      }}
                      onChange={(event) => {
                        setStartCheckpointState((oldState) => {
                          return {
                            ...oldState,
                            value: event.target.value,
                          };
                        });
                      }}
                      helperText="Please select model collection's checkpoint file to select"
                    >
                      {modelCollectionsHavingDefaultCheckpoints
                        .filter(
                          (modelCollection) =>
                            modelCollection._id ===
                            modelCollectionIdForStartCheckpoint
                        )[0]
                        .checkpointFiles.map((option) => (
                          <MenuItem key={option._id} value={option._id}>
                            <Tooltip title={option.name}>
                              <span>{option.name}</span>
                            </Tooltip>
                          </MenuItem>
                        ))}
                    </TextField>
                  </FormControl>
                )}

                {startCheckpointState.errorMessage &&
                  startCheckpointState.isTouched && (
                    <Box style={{ color: "red" }}>
                      {startCheckpointState.errorMessage}
                    </Box>
                  )}
              </Box>
            </Box>
          )}

          {/* labels section */}
          <Box>
            {/* <Box style={{ display: "flex" }}>
            <FormControl className={classes.formControl}>
              <TextField
                variant="outlined"
                size="small"
                margin="normal"
                required
                fullWidth
                label="Labels"
                value={labelInputFieldValue}
                onChange={(e) => setLabelInputFieldValue(e.target.value)}
                error={Boolean(errors.name)}
                helperText={errors.name?.message}
              />
            </FormControl>
            <Box style={{ alignSelf: "center" }}>
              <Tooltip title="Add Label">
                <AddCircleIcon
                  style={{ alignSelf: "center", fontSize: 35, marginTop: 11 }}
                  color="primary"
                  onClick={() => {
                    setLabelsState((oldState) => {
                      const oldValue = oldState.value;
                      oldValue.push(labelInputFieldValue);
                      return {
                        ...oldState,
                        value: [...oldValue],
                        isDirty: true,
                        isTouched: true,
                      };
                    });
                    setLabelInputFieldValue("");
                  }}
                />
              </Tooltip>
            </Box>
          </Box> */}

            {/* {labelsState.errorMessage && labelsState.isTouched && (
            <Box style={{ color: "red" }}>{labelsState.errorMessage}</Box>
          )} */}

            {/* <Autocomplete
              multiple
              id="labels"
              freeSolo
              options={labelsAvailable}
              value={labelsState.value}
              loading={isGetLabelsAvailableApiExecuting ? true : false}
              loadingText={
                isGetLabelsAvailableApiExecuting ? "loading" : ""
              }              
              onChange={(event, value) => {
                setLabelsState((oldState) => {
                  return {
                    ...oldState,
                    isDirty: true,
                    isTouched: true,
                    value: [...(value as string[])],
                  };
                });
              }}
              renderTags={(value, getTagProps) => {
                return value.map((option, index) => (
                  <Chip
                    variant="outlined"
                    color="primary"
                    className={`${classes.chip}`}
                    classes={{ root: classes.autoCompleteChipRoot }}
                    label={
                      <Tooltip title={option}>
                        <span>
                          {index + 1}: {option}
                        </span>
                      </Tooltip>
                    }
                    {...getTagProps({ index })}
                  />
                ));
              }}
              renderInput={(params) => (
                <TextField
                  {...params}
                  variant="outlined"
                  size="small"
                  margin="normal"
                  fullWidth
                  className={classes.floating}
                  label="Labels"
                  placeholder="Add Label"
                  onChange={(e) => {
                    handleLabelInputChangeEvent(e.target.value);
                  }}
                  onBlur={(e) => {
                    handleLabelInputChangeEvent(e.target.value + ",");
                  }}
                  error={
                    labelsState.isTouched && labelsState.errorMessage
                      ? true
                      : false
                  }
                  helperText={
                    labelsState.isTouched && labelsState.errorMessage
                      ? labelsState.errorMessage
                      : "Add multiple labels with <enter> or <comma>"
                  }
                />
              )}
            /> */}

            {/* selected labels section */}
            {/* <Box style={{ display: "flex", flexWrap: "wrap" }}>
            {labels.map((label, labelIndex) => {
              return (
                <Box key={labelIndex} style={{ display: "flex" }}>
                  <Chip
                    label={label}
                    color="primary"
                    className={`${classes.chip}`}
                    onDelete={() => {
                      setLabelsState((oldState) => {
                        const oldValue = oldState.value;
                        oldValue.splice(labelIndex, 1);
                        return {
                          ...oldState,
                          value: [...oldValue],
                          isDirty: true,
                          isTouched: true,
                        };
                      });
                    }}
                  />
                </Box>
              );
            })}
          </Box> */}
          </Box>

          {/* Model Architecture Section */}
          <Box>
            <Autocomplete
              id="modelArchitecture"
              // options={architecturesAvailable}
              options={architecturesAvailable.map(
                (architectureAvailable) => architectureAvailable.label
              )}
              value={modelArchitectureState.value}
              loading={isGetArchitectureAvailableApiExecuting ? true : false}
              loadingText={
                isGetArchitectureAvailableApiExecuting ? "loading" : ""
              }
              onChange={(event, value) => {
                setModelArchitectureState((oldState) => {
                  return {
                    ...oldState,
                    isDirty: true,
                    isTouched: true,
                    value: value as string,
                  };
                });
              }}
              renderTags={(value, getTagProps) => {
                return value.map((option, index) => (
                  <Chip
                    variant="outlined"
                    color="primary"
                    className={`${classes.chip}`}
                    classes={{ root: classes.autoCompleteChipRoot }}
                    label={
                      <Tooltip title={option}>
                        <span>{option}</span>
                      </Tooltip>
                    }
                    {...getTagProps({ index })}
                  />
                ));
              }}
              renderInput={(params) => (
                <TextField
                  {...params}
                  variant="outlined"
                  size="small"
                  margin="normal"
                  fullWidth
                  label="Model Architecture"
                  className={classes.floating}
                  placeholder="Select Model Architecture"
                  // onChange={(e) => {
                  //   handleLearningRateInputChangeEvent(e.target.value);
                  // }}
                  // onBlur={(e) => {
                  //   handleLearningRateInputChangeEvent(e.target.value + ",");
                  // }}
                  error={
                    modelArchitectureState.isTouched &&
                    modelArchitectureState.errorMessage
                      ? true
                      : false
                  }
                  helperText={
                    modelArchitectureState.isTouched &&
                    modelArchitectureState.errorMessage
                      ? modelArchitectureState.errorMessage
                      : ""
                  }
                />
              )}
            />
          </Box>

          {/* Model Version Section */}
          <Box className={`${classes.formControlContainer}`}>
            <FormControl className={classes.formControl}>
              <TextField
                variant="outlined"
                size="small"
                margin="normal"
                required
                fullWidth
                disabled={!modelArchitectureState.value}
                label="Model Version"
                value={modelVersion}
                InputLabelProps={{
                  shrink: modelVersion === undefined ? false : true,
                }}
                InputProps={{
                  type: "text",
                  classes: {
                    input: `${classes.numberInputWithoutArrows}`,
                  },
                  endAdornment: modelArchitectureState.value && (
                    <NumberInputFieldEndAdorment
                      setStateFunction={setModelVersionState}
                    />
                  ),
                }}
                onBlur={(e) => {
                  setModelVersionState((oldState) => {
                    return {
                      ...oldState,
                      isTouched: true,
                      value: convertVersionInMajorMinorPatchVersionFormat(
                        oldState.value || "0.0.0"
                      ),
                    };
                  });
                }}
                onChange={(e) => {
                  setModelVersionState((oldState) => {
                    return { ...oldState, isDirty: true };
                  });
                  if (
                    e.target.value === undefined ||
                    e.target.value === null ||
                    e.target.value === ""
                  ) {
                    setModelVersionState((oldState) => {
                      return { ...oldState, value: undefined };
                    });
                  } else {
                    setModelVersionState((oldState) => {
                      return { ...oldState, value: e.target.value };
                    });
                  }
                }}
                error={
                  modelVersionState.isTouched && modelVersionState.errorMessage
                    ? true
                    : false
                }
                helperText={
                  modelVersionState.isTouched
                    ? modelVersionState.errorMessage
                    : ""
                }
              />
            </FormControl>
          </Box>

          {/* Training Data Directory Section */}
          <Box style={{ marginBottom: 12 }}>
            {/* <Box style={{ display: "flex" }}>
            <FormControl className={classes.formControl}>
              <TextField
                variant="outlined"
                size="small"
                margin="normal"
                required
                fullWidth
                label="Training Data Directories"
                value={trainingDataDirectoryInputFieldValue}
                onChange={(e) =>
                  setTrainingDataDirectoryInputFieldValue(e.target.value)
                }
                error={Boolean(errors.name)}
                helperText={errors.name?.message}
              />
            </FormControl>
            <Box>
              <Button
                variant="contained"
                color="primary"
                onClick={() => {
                  setTrainingDataDirectoryState((oldState) => {
                    const oldValue = oldState.value;
                    oldValue.push(trainingDataDirectoryInputFieldValue);
                    return {
                      ...oldState,
                      isDirty: true,
                      isTouched: true,
                      value: [...oldValue],
                    };
                  });
                  setTrainingDataDirectoryInputFieldValue("");
                }}
              >
                Add Training Data Directory
              </Button>
            </Box>
          </Box> */}

            <Autocomplete
              multiple
              id="trainingDataDirectory"
              freeSolo
              options={trainingDataDirectoriesAvailable}
              value={trainingDataDirectoryState.value}
              loading={
                isGetDataSetCollectionUniqueNamesApiExecuting ? true : false
              }
              loadingText={
                isGetDataSetCollectionUniqueNamesApiExecuting ? "loading" : ""
              }
              onChange={(event, value) => {
                setTrainingDataDirectoryState({
                  ...trainingDataDirectoryState,
                  isDirty: true,
                  isTouched: true,
                  value: [...(value as string[])],
                });
              }}
              renderTags={(value, getTagProps) => {
                return value.map((option, index) => (
                  <Chip
                    variant="outlined"
                    color="primary"
                    className={`${classes.chip}`}
                    classes={{ root: classes.autoCompleteChipRoot }}
                    label={
                      <Tooltip title={option}>
                        <span>{option}</span>
                      </Tooltip>
                    }
                    {...getTagProps({ index })}
                  />
                ));
              }}
              renderInput={(params) => (
                <TextField
                  {...params}
                  variant="outlined"
                  size="small"
                  margin="normal"
                  fullWidth
                  className={classes.floating}
                  label="Training Dataset Collection"
                  placeholder="Add Training Dataset Collection"
                  onChange={(e) => {
                    handleTrainingDataDirectoryInputChangeEvent(e.target.value);
                  }}
                  onBlur={(e) => {
                    handleTrainingDataDirectoryInputChangeEvent(
                      e.target.value + ","
                    );
                  }}
                  error={
                    trainingDataDirectoryState.isTouched &&
                    trainingDataDirectoryState.errorMessage
                      ? true
                      : false
                  }
                  helperText={
                    trainingDataDirectoryState.isTouched &&
                    trainingDataDirectoryState.errorMessage
                      ? trainingDataDirectoryState.errorMessage
                      : "Add multiple training dataset collections with <enter> or <comma>"
                  }
                />
              )}
            />
            {props.defaultDatasetName && (
              <Autocomplete
                multiple
                id="defaultDataSet"
                freeSolo
                options={[]}
                value={[props.defaultDatasetName || ""]}
                className={classes.defaultDataSet}
                renderTags={(value, getTagProps) => {
                  return value.map((option, index) => (
                    <Chip
                      variant="outlined"
                      color="primary"
                      className={`${classes.chip}`}
                      classes={{ root: classes.autoCompleteChipRoot }}
                      label={
                        <Tooltip title={option}>
                          <span>{option}</span>
                        </Tooltip>
                      }
                      {...getTagProps({ index })}
                    />
                  ));
                }}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    variant="outlined"
                    size="small"
                    margin="normal"
                    fullWidth
                    className={classes.floating}
                    label="Default Dataset Collection for testing "
                  />
                )}
              />
            )}
            <Autocomplete
              multiple
              id="Tags Available"
              options={[]}
              freeSolo
              disabled={isModelBeingUpdatedInDB}
              value={tagsState.value}
              getOptionSelected={() => false}
              onChange={(event, value) => {
                setTagsState((oldState) => {
                  const tagsToSave = removeDuplicateElementsFromArray(
                    value as string[]
                  );

                  return {
                    ...oldState,
                    isDirty: true,
                    isTouched: true,
                    value: [...tagsToSave],
                  };
                });
              }}
              renderTags={(value, getTagProps) => {
                return value.map((option, index) => (
                  <Chip
                    variant="outlined"
                    color="primary"
                    // className={`${classes.chip}`}
                    // classes={{ root: classes.autoCompleteChipRoot }}
                    label={
                      <Tooltip title={option}>
                        <span>{option}</span>
                      </Tooltip>
                    }
                    {...getTagProps({ index })}
                  />
                ));
              }}
              renderInput={(params) => (
                <TextField
                  {...params}
                  variant="outlined"
                  size="small"
                  margin="normal"
                  className={classes.floating}
                  fullWidth
                  label="Tags"
                  placeholder="Add Tags"
                  onChange={(e) => {
                    handleTagsInputChangeEvent(e.target.value);
                  }}
                  onBlur={(e) => {
                    handleTagsInputChangeEvent(e.target.value + ",");
                  }}
                  error={
                    tagsState.isTouched && tagsState.errorMessage ? true : false
                  }
                  helperText={
                    tagsState.isTouched && tagsState.errorMessage
                      ? tagsState.errorMessage
                      : "Add multiple training dataset collections with <enter> or <comma>"
                  }
                />
              )}
            />

            {/* {trainingDataDirectoryState.errorMessage &&
            trainingDataDirectoryState.isTouched && (
              <Box style={{ color: "red" }}>
                {trainingDataDirectoryState.errorMessage}
              </Box>
            )} */}
            {/* selected training data directories section */}
            {/* <Box>
            {trainingDataDirectory.map((trainingData, trainingDataIndex) => {
              return (
                <Box key={trainingDataIndex} style={{ display: "flex" }}>
                  <Typography>{trainingData}</Typography>
                  <DeleteIcon
                    onClick={() => {
                      setTrainingDataDirectoryState((oldState) => {
                        const oldValue = oldState.value;
                        oldValue.splice(trainingDataIndex, 1);
                        return {
                          ...oldState,
                          isDirty: true,
                          isTouched: true,
                          value: [...oldValue],
                        };
                      });
                    }}
                  />
                </Box>
              );
            })}
          </Box> */}
          </Box>

          {/* <FillDataViaJSONInfoDialog
            open={isFillDataViaFileInfoDialogShowing}
            setOpen={setIsFillDataViaFileInfoDialogShowing}
          /> */}

          {/* FieldsDataViaJSON Section */}
          {/* <Box
            className={classes.inputSection}
            style={{
              backgroundColor: "#eff4fb",
              padding: "10px",
              borderRadius: "6px",
            }}
          >
            <Box
              className={classes.inputSectionTitle}
              style={{
                display: "flex",
                justifyContent: "space-between",
              }}
            >
              <span>Fill data of other fields via json</span>
              <Tooltip title="Click to view Information about this section">
                <InfoIcon
                  color="primary"
                  fontSize="small"
                  style={{ cursor: "pointer" }}
                  onClick={() => {
                    setIsFillDataViaFileInfoDialogShowing(true);
                  }}
                />
              </Tooltip>
            </Box>

              <div id="jsonTextFieldContainer"
                style={{marginBottom: "19px"}}
              >
                <TextField
                  multiline
                  label="Json data of fields"
                  fullWidth
                  className={classes.floating}
                  value={jsonTextFieldData}
                  onChange={(e) => {
                    setJsonTextFieldData(e.target.value);
                  }}
                />
              </div>

            <CustomButton
              variant="outlined"
              style={{ width: "100%" }}
              disabled={
                !jsonTextFieldData
                ? true
                : false
              }
              onClick={()=>{
                handleJSONTextFieldDataSubmitButtonClick()
              }}
            >
              Update data of fields
            </CustomButton>
          </Box> */}

          {/* Background Volume Section */}
          {/* <Box>
            <FormControl className={classes.formControl}>
              <TextField
                variant="outlined"
                size="small"
                margin="normal"
                required
                fullWidth
                label="Background Volume"
                value={backgroundVolume}
                InputLabelProps={{
                  shrink: backgroundVolume === undefined ? false : true,
                }}
                InputProps={{
                  type: "number",
                  classes: {
                    input: `${classes.numberInputWithoutArrows}`,
                  },
                  endAdornment: (
                    <NumberInputFieldEndAdorment
                      setStateFunction={setBackgroundVolumeState}
                    />
                  ),
                }}
                onBlur={(e) => {
                  setBackgroundVolumeState((oldState) => {
                    return { ...oldState, isTouched: true };
                  });
                }}
                onChange={(e) => {
                  if (
                    e.target.value === undefined ||
                    e.target.value === null ||
                    e.target.value === ""
                  ) {
                    setBackgroundVolumeState((oldState) => {
                      return { ...oldState, isDirty: true, value: undefined };
                    });
                  } else {
                    setBackgroundVolumeState((oldState) => {
                      return {
                        ...oldState,
                        isDirty: true,
                        value: Number(e.target.value),
                      };
                    });
                  }
                }}
                error={
                  backgroundVolumeState.isTouched &&
                  backgroundVolumeState.errorMessage
                    ? true
                    : false
                }
                helperText={
                  backgroundVolumeState.isTouched
                    ? backgroundVolumeState.errorMessage
                    : ""
                }
              />
            </FormControl>
          </Box> */}

          {/* Background Frequency Section */}
          {/* <Box className={classes.sliderInputFieldSection}>
            <Typography>Background Frequency</Typography>
            <Slider
              defaultValue={0.0}
              aria-labelledby="background-Frequency-slider"
              max={1.0}
              min={0.0}
              color="primary"
              classes={{ markLabel: classes.sliderMarkLabelStyleBetween0To1 }}
              step={0.01}
              marks={[
                { value: 0.0, label: "0" },
                { value: 1.0, label: "1" },
              ]}
              ValueLabelComponent={SliderValueLabelComponent}
              onChange={(e, value) => setBackgroundFrequencyState(oldState=>{return {...oldState, isDirty: true, isTouched: true, value: value as number}})}
              value={backgroundFrequency}
            />
          </Box> */}

          {/* Silence Percentage Section */}
          {/* <Box className={classes.sliderInputFieldSection}>
            <Typography>Silence Percentage</Typography>
            <Slider
              defaultValue={0}
              aria-labelledby="silence-percentage-slider"
              max={100}
              min={0}
              color="primary"
              classes={{
                markLabel: classes.sliderMarkLabelStyleBetween0To100Percent,
              }}
              step={1}
              marks={[
                { value: 0, label: "0%" },
                { value: 100, label: "100%" },
              ]}
              ValueLabelComponent={SliderValueLabelComponent}
              onChange={(e, value) => setSilencePercentage(value as number)}
              value={silencePercentage}
            />
          </Box> */}

          {/* Unknown Percentage Section */}
          {/* <Box>
            <FormControl className={classes.formControl}>
              <TextField
                variant="outlined"
                size="small"
                margin="normal"
                required
                fullWidth
                label="Unknown Percentage"
                value={unknownPercentage}
                InputLabelProps={{
                  shrink: unknownPercentage === undefined ? false : true,
                }}
                InputProps={{
                  type: "number",
                  classes: {
                    input: `${classes.numberInputWithoutArrows}`,
                  },
                  endAdornment: (
                    <NumberInputFieldEndAdorment
                      setStateFunction={setUnknownPercentageState}
                    />
                  ),
                }}
                onBlur={(e) => {
                  setUnknownPercentageState((oldState) => {
                    return { ...oldState, isTouched: true };
                  });
                }}
                onChange={(e) => {
                  if (
                    e.target.value === undefined ||
                    e.target.value === null ||
                    e.target.value === ""
                  ) {
                    setUnknownPercentageState((oldState) => {
                      return { ...oldState, isDirty: true, value: undefined };
                    });
                  } else {
                    setUnknownPercentageState((oldState) => {
                      return {
                        ...oldState,
                        isDirty: true,
                        value: Number(e.target.value),
                      };
                    });
                  }
                }}
                error={
                  unknownPercentageState.isTouched &&
                  unknownPercentageState.errorMessage
                    ? true
                    : false
                }
                helperText={
                  unknownPercentageState.isTouched
                    ? unknownPercentageState.errorMessage
                    : ""
                }
              />
            </FormControl>
          </Box> */}

          {/* Testing Percentage Section */}
          {/* <Box className={classes.sliderInputFieldSection}>
            <Typography>Testing Percentage</Typography>
            <Slider
              defaultValue={0}
              aria-labelledby="testing-percentage-slider"
              max={100}
              min={0}
              color="primary"
              classes={{
                markLabel: classes.sliderMarkLabelStyleBetween0To100Percent,
              }}
              step={1}
              marks={[
                { value: 0, label: "0%" },
                { value: 100, label: "100%" },
              ]}
              ValueLabelComponent={SliderValueLabelComponent}
              onChange={(e, value) => setTestingPercentage(value as number)}
              value={testingPercentage}
            />
          </Box> */}

          {/* Validation Percentage Section */}
          {/* <Box className={classes.sliderInputFieldSection}>
            <Typography>Validation Percentage</Typography>
            <Slider
              defaultValue={0}
              aria-labelledby="validation-percentage-slider"
              max={100}
              min={0}
              color="primary"
              step={1}
              classes={{
                markLabel: classes.sliderMarkLabelStyleBetween0To100Percent,
              }}
              marks={[
                { value: 0, label: "0%" },
                { value: 100, label: "100%" },
              ]}
              ValueLabelComponent={SliderValueLabelComponent}
              onChange={(e, value) => setValidationPercentage(value as number)}
              value={validationPercentage}
            />
          </Box> */}

          {/* Time Shift Section */}
          {/* <Box>
            <FormControl className={classes.formControl}>
              <TextField
                variant="outlined"
                size="small"
                margin="normal"
                required
                fullWidth
                label="Time Shift"
                value={timeShift}
                InputLabelProps={{
                  shrink: timeShift === undefined ? false : true,
                }}
                InputProps={{
                  type: "number",
                  classes: {
                    input: `${classes.numberInputWithoutArrows}`,
                  },
                  endAdornment: (
                    <NumberInputFieldEndAdorment
                      setStateFunction={setTimeShiftState}
                    />
                  ),
                }}
                onBlur={(e) => {
                  setTimeShiftState((oldState) => {
                    return { ...oldState, isTouched: true };
                  });
                }}
                onChange={(e) => {
                  if (
                    e.target.value === undefined ||
                    e.target.value === null ||
                    e.target.value === ""
                  ) {
                    setTimeShiftState((oldState) => {
                      return { ...oldState, isDirty: true, value: undefined };
                    });
                  } else {
                    setTimeShiftState((oldState) => {
                      return {
                        ...oldState,
                        isDirty: true,
                        value: Number(e.target.value),
                      };
                    });
                  }
                }}
                error={
                  timeShiftState.isTouched && timeShiftState.errorMessage
                    ? true
                    : false
                }
                helperText={
                  timeShiftState.isTouched ? timeShiftState.errorMessage : ""
                }
              />
            </FormControl>
          </Box> */}

          {/* Sample Rate Section */}
          {/* <Box>
            <FormControl className={classes.formControl}>
              <TextField
                variant="outlined"
                size="small"
                margin="normal"
                required
                fullWidth
                label="Sample Rate"
                value={sampleRate}
                InputLabelProps={{
                  shrink: sampleRate === undefined ? false : true,
                }}
                InputProps={{
                  type: "number",
                  classes: {
                    input: `${classes.numberInputWithoutArrows}`,
                  },
                  endAdornment: (
                    <NumberInputFieldEndAdorment
                      setStateFunction={setSampleRateState}
                    />
                  ),
                }}
                onBlur={(e) =>
                  setSampleRateState((oldState) => {
                    return { ...oldState, isTouched: true };
                  })
                }
                onChange={(e) => {
                  if (
                    e.target.value === undefined ||
                    e.target.value === null ||
                    e.target.value === ""
                  ) {
                    setSampleRateState((oldState) => {
                      return { ...oldState, isDirty: true, value: undefined };
                    });
                  } else {
                    setSampleRateState((oldState) => {
                      return {
                        ...oldState,
                        isDirty: true,
                        value: Number(e.target.value),
                      };
                    });
                  }
                }}
                error={
                  sampleRateState.isTouched && sampleRateState.errorMessage
                    ? true
                    : false
                }
                helperText={
                  sampleRateState.isTouched ? sampleRateState.errorMessage : ""
                }
              />
            </FormControl>
          </Box> */}

          {/* Clip Duration Section */}
          {/* <Box>
            <FormControl className={classes.formControl}>
              <TextField
                variant="outlined"
                size="small"
                margin="normal"
                required
                fullWidth
                label="Clip Duration"
                value={clipDuration}
                InputLabelProps={{
                  shrink: clipDuration === undefined ? false : true,
                }}
                InputProps={{
                  type: "number",
                  classes: {
                    input: `${classes.numberInputWithoutArrows}`,
                  },
                  endAdornment: (
                    <NumberInputFieldEndAdorment
                      setStateFunction={setClipDurationState}
                    />
                  ),
                }}
                onBlur={(e) =>
                  setClipDurationState((oldState) => {
                    return { ...oldState, isTouched: true };
                  })
                }
                onChange={(e) => {
                  if (
                    e.target.value === undefined ||
                    e.target.value === null ||
                    e.target.value === ""
                  ) {
                    setClipDurationState((oldState) => {
                      return { ...oldState, isDirty: true, value: undefined };
                    });
                  } else {
                    setClipDurationState((oldState) => {
                      return {
                        ...oldState,
                        isDirty: true,
                        value: Number(e.target.value),
                      };
                    });
                  }
                }}
                error={
                  clipDurationState.isTouched && clipDurationState.errorMessage
                    ? true
                    : false
                }
                helperText={
                  clipDurationState.isTouched
                    ? clipDurationState.errorMessage
                    : ""
                }
              />
            </FormControl>
          </Box> */}

          {/* DCT Coefficient Count Section */}
          {/* <Box>
            <FormControl className={classes.formControl}>
              <TextField
                variant="outlined"
                size="small"
                margin="normal"
                required
                fullWidth
                label="DCT Coefficient Count"
                value={dctCoefficientCount}
                InputLabelProps={{
                  shrink: dctCoefficientCount === undefined ? false : true,
                }}
                InputProps={{
                  type: "number",
                  classes: {
                    input: `${classes.numberInputWithoutArrows}`,
                  },
                  endAdornment: (
                    <NumberInputFieldEndAdorment
                      setStateFunction={setDctCoefficientCountState}
                    />
                  ),
                }}
                onBlur={(e) => {
                  setDctCoefficientCountState((oldState) => {
                    return { ...oldState, isTouched: true };
                  });
                }}
                onChange={(e) => {
                  if (
                    e.target.value === undefined ||
                    e.target.value === null ||
                    e.target.value === ""
                  ) {
                    setDctCoefficientCountState((oldState) => {
                      return { ...oldState, isDirty: true, value: undefined };
                    });
                  } else {
                    setDctCoefficientCountState((oldState) => {
                      return {
                        ...oldState,
                        isDirty: true,
                        value: Number(e.target.value),
                      };
                    });
                  }
                }}
                error={
                  dctCoefficientCountState.isTouched &&
                  dctCoefficientCountState.errorMessage
                    ? true
                    : false
                }
                helperText={
                  dctCoefficientCountState.isTouched
                    ? dctCoefficientCountState.errorMessage
                    : ""
                }
              />
            </FormControl>
          </Box> */}

          {/* Window Size Section */}
          {/* <Box>
            <FormControl className={classes.formControl}>
              <TextField
                variant="outlined"
                size="small"
                margin="normal"
                required
                fullWidth
                label="Window Size"
                value={windowSize}
                onBlur={(e) => {
                  setWindowSizeState((oldState) => {
                    return { ...oldState, isTouched: true };
                  });
                }}
                InputLabelProps={{
                  shrink: windowSize === undefined ? false : true,
                }}
                InputProps={{
                  type: "number",
                  classes: {
                    input: `${classes.numberInputWithoutArrows}`,
                  },
                  endAdornment: (
                    <NumberInputFieldEndAdorment
                      setStateFunction={setWindowSizeState}
                    />
                  ),
                }}
                onChange={(e) => {
                  if (
                    e.target.value === undefined ||
                    e.target.value === null ||
                    e.target.value === ""
                  ) {
                    setWindowSizeState((oldState) => {
                      return { ...oldState, isDirty: true, value: undefined };
                    });
                  } else {
                    setWindowSizeState((oldState) => {
                      return {
                        ...oldState,
                        isDirty: true,
                        value: Number(e.target.value),
                      };
                    });
                  }
                }}
                error={
                  windowSizeState.isTouched && windowSizeState.errorMessage
                    ? true
                    : false
                }
                helperText={
                  windowSizeState.isTouched ? windowSizeState.errorMessage : ""
                }
              />
            </FormControl>
          </Box> */}

          {/* Window Stride Section */}
          {/* <Box>
            <FormControl className={classes.formControl}>
              <TextField
                variant="outlined"
                size="small"
                margin="normal"
                required
                fullWidth
                label="Window Stride"
                value={windowStride}
                InputLabelProps={{
                  shrink: windowStride === undefined ? false : true,
                }}
                InputProps={{
                  type: "number",
                  classes: {
                    input: `${classes.numberInputWithoutArrows}`,
                  },
                  endAdornment: (
                    <NumberInputFieldEndAdorment
                      setStateFunction={setWindowStrideState}
                    />
                  ),
                }}
                onBlur={(e) => {
                  setWindowStrideState((oldState) => {
                    return { ...oldState, isTouched: true };
                  });
                }}
                onChange={(e) => {
                  if (
                    e.target.value === undefined ||
                    e.target.value === null ||
                    e.target.value === ""
                  ) {
                    setWindowStrideState((oldState) => {
                      return { ...oldState, isDirty: true, value: undefined };
                    });
                  } else {
                    setWindowStrideState((oldState) => {
                      return {
                        ...oldState,
                        isDirty: true,
                        value: Number(e.target.value),
                      };
                    });
                  }
                }}
                error={
                  windowStrideState.isTouched && windowStrideState.errorMessage
                    ? true
                    : false
                }
                helperText={
                  windowStrideState.isTouched
                    ? windowStrideState.errorMessage
                    : ""
                }
              />
            </FormControl>
          </Box> */}

          {/* Training Steps Section */}
          {/* <Box>
            <Autocomplete
              multiple
              id="trainingSteps"
              options={[]}
              value={
                trainingStepsState.value.split(",")[0] === "" &&
                !trainingStepsState.value
                  ? []
                  : trainingStepsState.value.split(",")
              }
              freeSolo
              getOptionSelected={()=>false}
              onChange={(event, value) => {
                setTrainingStepsState((oldState) => {
                  return {
                    ...oldState,
                    isDirty: true,
                    isTouched: true,
                    value: value.toString(),
                  };
                });
              }}
              renderTags={(value, getTagProps) => {
                return value.map((option, index) => (
                  <Chip
                    variant="outlined"
                    color="primary"
                    className={`${classes.chip}`}
                    classes={{ root: classes.autoCompleteChipRoot }}
                    label={
                      <Tooltip title={option}>
                        <span>{option}</span>
                      </Tooltip>
                    }
                    {...getTagProps({ index })}
                  />
                ));
              }}
              renderInput={(params) => (
                <TextField
                  {...params}
                  variant="outlined"
                  size="small"
                  margin="normal"
                  fullWidth
                  label="Training Steps"
                  className={classes.floating}
                  placeholder="Add training step"
                  onChange={(e) => {
                    handleTrainingStepsInputChangeEvent(e.target.value);
                  }}
                  onBlur={(e) => {
                    handleTrainingStepsInputChangeEvent(e.target.value + ",");
                  }}
                  error={
                    trainingStepsState.isTouched &&
                    trainingStepsState.errorMessage
                      ? true
                      : false
                  }
                  helperText={
                    trainingStepsState.isTouched &&
                    trainingStepsState.errorMessage
                      ? trainingStepsState.errorMessage
                      : "Add multiple training steps with <enter> or <comma>"
                  }
                />
              )}
            />
          </Box> */}

          {/* Learning Rate Section */}
          {/* <Box>
            <Autocomplete
              multiple
              id="learningRate"
              options={[]}
              value={
                learningRateState.value.split(",")[0] === "" &&
                !learningRateState.value
                  ? []
                  : learningRateState.value.split(",")
              }
              freeSolo
              getOptionSelected={()=>false}
              onChange={(event, value) => {
                setLearningRateState((oldState) => {
                  return {
                    ...oldState,
                    isDirty: true,
                    isTouched: true,
                    value: value.toString(),
                  };
                });
              }}
              renderTags={(value, getTagProps) => {
                return value.map((option, index) => (
                  <Chip
                    variant="outlined"
                    color="primary"
                    className={`${classes.chip}`}
                    classes={{ root: classes.autoCompleteChipRoot }}
                    label={
                      <Tooltip title={option}>
                        <span>{option}</span>
                      </Tooltip>
                    }
                    {...getTagProps({ index })}
                  />
                ));
              }}
              renderInput={(params) => (
                <TextField
                  {...params}
                  variant="outlined"
                  size="small"
                  margin="normal"
                  fullWidth
                  label="Learning Rate"
                  className={classes.floating}
                  placeholder="Add learning rate"
                  onChange={(e) => {
                    handleLearningRateInputChangeEvent(e.target.value);
                  }}
                  onBlur={(e) => {
                    handleLearningRateInputChangeEvent(e.target.value + ",");
                  }}
                  error={
                    learningRateState.isTouched &&
                    learningRateState.errorMessage
                      ? true
                      : false
                  }
                  helperText={
                    learningRateState.isTouched &&
                    learningRateState.errorMessage
                      ? learningRateState.errorMessage
                      : "Add multiple learning rate with <enter> or <comma>"
                  }
                />
              )}
            />
          </Box> */}

          {/* Evaluation Step Interval Section */}
          {/* <Box>
            <FormControl className={classes.formControl}>
              <TextField
                variant="outlined"
                size="small"
                margin="normal"
                required
                fullWidth
                label="Evaluation Step Interval"
                value={evaluationStepInterval}
                InputLabelProps={{
                  shrink: evaluationStepInterval === undefined ? false : true,
                }}
                InputProps={{
                  type: "number",
                  classes: {
                    input: `${classes.numberInputWithoutArrows}`,
                  },
                  endAdornment: (
                    <NumberInputFieldEndAdorment
                      setStateFunction={setEvaluationStepIntervalState}
                    />
                  ),
                }}
                onBlur={(e) => {
                  setEvaluationStepIntervalState((oldState) => {
                    return { ...oldState, isTouched: true };
                  });
                }}
                onChange={(e) => {
                  if (
                    e.target.value === undefined ||
                    e.target.value === null ||
                    e.target.value === ""
                  ) {
                    setEvaluationStepIntervalState((oldState) => {
                      return { ...oldState, isDirty: true, value: undefined };
                    });
                  } else {
                    setEvaluationStepIntervalState((oldState) => {
                      return {
                        ...oldState,
                        isDirty: true,
                        value: Number(e.target.value),
                      };
                    });
                  }
                }}
                error={
                  evaluationStepIntervalState.isTouched &&
                  evaluationStepIntervalState.errorMessage
                    ? true
                    : false
                }
                helperText={
                  evaluationStepIntervalState.isTouched
                    ? evaluationStepIntervalState.errorMessage
                    : ""
                }
              />
            </FormControl>
          </Box> */}

          {/* Save Step Interval Section */}
          {/* <Box>
            <FormControl className={classes.formControl}>
              <TextField
                variant="outlined"
                size="small"
                margin="normal"
                required
                fullWidth
                label="Save Step Interval"
                value={saveStepInterval}
                InputLabelProps={{
                  shrink: saveStepInterval === undefined ? false : true,
                }}
                InputProps={{
                  type: "number",
                  classes: {
                    input: `${classes.numberInputWithoutArrows}`,
                  },
                  endAdornment: (
                    <NumberInputFieldEndAdorment
                      setStateFunction={setSaveStepIntervalState}
                    />
                  ),
                }}
                onBlur={(e) => {
                  setSaveStepIntervalState((oldState) => {
                    return { ...oldState, isTouched: true };
                  });
                }}
                onChange={(e) => {
                  if (
                    e.target.value === undefined ||
                    e.target.value === null ||
                    e.target.value === ""
                  ) {
                    setSaveStepIntervalState((oldState) => {
                      return { ...oldState, isDirty: true, value: undefined };
                    });
                  } else {
                    setSaveStepIntervalState((oldState) => {
                      return {
                        ...oldState,
                        isDirty: true,
                        value: Number(e.target.value),
                      };
                    });
                  }
                }}
                error={
                  saveStepIntervalState.isTouched &&
                  saveStepIntervalState.errorMessage
                    ? true
                    : false
                }
                helperText={
                  saveStepIntervalState.isTouched
                    ? saveStepIntervalState.errorMessage
                    : ""
                }
              />
            </FormControl>
          </Box> */}

          {/* Batch Size Section */}
          {/* <Box>
            <FormControl className={classes.formControl}>
              <TextField
                variant="outlined"
                size="small"
                margin="normal"
                required
                fullWidth
                label="Batch Size"
                value={batchSize}
                InputLabelProps={{
                  shrink: batchSize === undefined ? false : true,
                }}
                InputProps={{
                  type: "number",
                  classes: {
                    input: `${classes.numberInputWithoutArrows}`,
                  },
                  endAdornment: (
                    <NumberInputFieldEndAdorment
                      setStateFunction={setBatchSizeState}
                    />
                  ),
                }}
                onBlur={(e) => {
                  setBatchSizeState((oldState) => {
                    return { ...oldState, isTouched: true };
                  });
                }}
                onChange={(e) => {
                  if (
                    e.target.value === undefined ||
                    e.target.value === null ||
                    e.target.value === ""
                  ) {
                    setBatchSizeState((oldState) => {
                      return { ...oldState, isDirty: true, value: undefined };
                    });
                  } else {
                    setBatchSizeState((oldState) => {
                      return {
                        ...oldState,
                        isDirty: true,
                        value: Number(e.target.value),
                      };
                    });
                  }
                }}
                error={
                  batchSizeState.isTouched && batchSizeState.errorMessage
                    ? true
                    : false
                }
                helperText={
                  batchSizeState.isTouched ? batchSizeState.errorMessage : ""
                }
              />
            </FormControl>
          </Box> */}

          {/* Start Checkpoint Section */}
          {/* {
            scenario === 'reTrainingDataSetCollections' &&
            <Box>
              <FormControl className={classes.formControl}>
                <TextField
                  variant="outlined"
                  size="small"
                  margin="normal"
                  required
                  fullWidth
                  label="Start Checkpoint"
                  value={startCheckpoint}
                  inputProps={{ type: "text" }}
                  onBlur={(e) => {
                    setStartCheckpointState((oldState) => {
                      return { ...oldState, isTouched: true };
                    });
                  }}
                  onChange={(e) =>
                    setStartCheckpointState((oldState) => {
                      return {
                        ...oldState,
                        isDirty: true,
                        value: e.target.value,
                      };
                    })
                  }
                  error={
                    startCheckpointState.isTouched &&
                    startCheckpointState.errorMessage
                      ? true
                      : false
                  }
                  helperText={
                    startCheckpointState.isTouched
                      ? startCheckpointState.errorMessage
                      : ""
                  }
                />
              </FormControl>
            </Box>
          } */}

          {/* Number of Layers to Freeze Section */}
          {scenario === "reTrainingDataSetCollections" && (
            <Box className={classes.sliderInputFieldSection}>
              <Typography>No. of Layers to Freeze</Typography>
              <Slider
                defaultValue={1}
                aria-labelledby="background-Frequency-slider"
                max={100}
                min={1}
                color="primary"
                classes={{
                  markLabel: classes.sliderMarkLabelStyleBetween0To100,
                }}
                step={1}
                marks={[
                  { value: 1, label: "1" },
                  { value: 100, label: "100" },
                ]}
                ValueLabelComponent={SliderValueLabelComponent}
                onChange={(e, value) =>
                  setNumberOfLayersToFreezeState((oldState) => {
                    return {
                      ...oldState,
                      isDirty: true,
                      isTouched: true,
                      value: value as number,
                    };
                  })
                }
                value={numberOfLayersToFreeze}
              />
            </Box>
          )}

          {/* Model Size Info Section */}
          {/* <Box>
            <FormControl className={classes.formControl}>
              <TextField
                variant="outlined"
                size="small"
                margin="normal"
                required
                fullWidth
                label="Model Size Info"
                value={modelSizeInfo}
                inputProps={{ type: "text" }}
                onBlur={(e) => {
                  setModelSizeInfoState((oldState) => {
                    return { ...oldState, isTouched: true };
                  });
                }}
                onChange={(e) =>
                  setModelSizeInfoState((oldState) => {
                    return {
                      ...oldState,
                      isDirty: true,
                      value: e.target.value,
                    };
                  })
                }
                error={
                  modelSizeInfoState.isTouched &&
                  modelSizeInfoState.errorMessage
                    ? true
                    : false
                }
                helperText={
                  modelSizeInfoState.isTouched
                    ? modelSizeInfoState.errorMessage
                    : ""
                }
              />
            </FormControl>
          </Box> */}

          {/* Model Description Section */}
          <Box>
            <FormControl className={classes.formControl}>
              <TextField
                variant="outlined"
                size="small"
                margin="normal"
                required
                fullWidth
                label="Model Description"
                value={modelDescription}
                inputProps={{ type: "text" }}
                onBlur={(e) => {
                  setModelDescriptionState((oldState) => {
                    return { ...oldState, isTouched: true };
                  });
                }}
                onChange={(e) =>
                  setModelDescriptionState((oldState) => {
                    return {
                      ...oldState,
                      isDirty: true,
                      value: e.target.value,
                    };
                  })
                }
                error={
                  modelDescriptionState.isTouched &&
                  modelDescriptionState.errorMessage
                    ? true
                    : false
                }
                helperText={
                  modelDescriptionState.isTouched
                    ? modelDescriptionState.errorMessage
                    : ""
                }
              />
            </FormControl>
          </Box>
        </Box>

        <Box style={{ maxHeight: "10%", paddingTop: "2vh" }}>
          <Box
            className={`${
              !(isShowingFormErrorsToUser && !isFormValid())
                ? classes.visibilityHidden
                : ""
            }`}
            style={{ color: "red", marginBottom: 8 }}
          >
            Please fill valid data in input fields
          </Box>
          <CustomButton
            style={{ width: "100%" }}
            type="submit"
            disabled={
              (isShowingFormErrorsToUser && !isFormValid()) ||
              isAddCollectionApiExecuting
            }
          >
            {isAddCollectionApiExecuting ? (
              <CircularProgress size={20} />
            ) : scenario === "trainingDataSetCollections" ? (
              "Train Collection"
            ) : scenario === "reTrainingDataSetCollections" ? (
              "Re-Train Collection"
            ) : (
              "Add Collection"
            )}
          </CustomButton>
        </Box>
        {/* <Separator width="50%" />
            <CustomButton size="small" disabled>Train Models</CustomButton> */}
      </form>
    </Grid>
  );
};

const mapProps = (state: IReduxState) => {
  return {
    tags: state.tag.tags,
    isTagsFetching: state.tag.isFetching,
    isAdding: state.collection.isAdding,
    isAddSuccess: state.collection.isAddSuccess,
    addErrorMessage: state.collection.addErrorMessage,
    trainingDataDirectoryState:
      state.collection.modelCollection
        .trainingDataDirectoriesSelectedStateForAddModelCollection,
    shouldFetchModelCollections:
      state.collection.modelCollection.shouldFetchModelCollections,
    defaultDatasetName: state.collection.defaultDataSetCollection?.name,
    defaultDatasetId: state.collection.defaultDataSetCollection?.id,
  };
};

const connector = connect(mapProps, {
  addCollection,
  getTags,
  addCollectionPageInit,
  setShouldFetchModelCollections,
  setTrainingDataDirectoryState:
    setTrainingDataDirectoriesSelectedStateForAddModelCollection,
  setSideDrawer,
});

export type TPropsFromRedux = ConnectedProps<typeof connector>;
interface Props extends TPropsFromRedux {
  scenario: AddModelCollectionScenario;
}
export default connector(AddModelCollection);

/*
function isValidBackgroundVolumeValue(backgroundVolume: number): boolean {
  return !isNullOrUndefined(backgroundVolume) && isNumber(backgroundVolume);
}

function isValidBackgroundFrequencyValue(backgroundFrequency: number): boolean {
  return !isNullOrUndefined(backgroundFrequency) &&
    isNumber(backgroundFrequency) &&
    backgroundFrequency >= 0 &&
    backgroundFrequency <= 1;
}

function isValidUnknownPercentageValue(unknownPercentage: number): boolean {
  return !isNullOrUndefined(unknownPercentage) &&
    isNumber(unknownPercentage)
}
function isValidTestingPercentageValue(valueToCheck: number): boolean {
  return !isNullOrUndefined(valueToCheck) &&
    isNumber(valueToCheck) && valueToCheck >= 0 && valueToCheck <= 100
}
function isValidSilencePercentageValue(valueToCheck: number): boolean {
  return !isNullOrUndefined(valueToCheck) &&
    isNumber(valueToCheck) && valueToCheck >= 0 && valueToCheck <= 100
}
function isValidValidationPercentageValue(valueToCheck: number): boolean {
  return !isNullOrUndefined(valueToCheck) &&
    isNumber(valueToCheck) && valueToCheck >= 0 && valueToCheck <= 100
}
function isValidTimeShiftValue(valueToCheck: number): boolean {
  return !isNullOrUndefined(valueToCheck) &&
    isNumber(valueToCheck)
}
function isValidSampleRateValue(valueToCheck: number): boolean {
  return !isNullOrUndefined(valueToCheck) &&
    isNumber(valueToCheck)
}
function isValidClipDurationValue(valueToCheck: number): boolean {
  return !isNullOrUndefined(valueToCheck) &&
    isNumber(valueToCheck)
}
function isValidDCTCoefficientCountValue(valueToCheck: number): boolean {
  return !isNullOrUndefined(valueToCheck) &&
    isNumber(valueToCheck)
}
function isValidWindowSizeValue(valueToCheck: number): boolean {
  return !isNullOrUndefined(valueToCheck) &&
    isNumber(valueToCheck)
}
function isValidWindowStrideValue(valueToCheck: number): boolean {
  return !isNullOrUndefined(valueToCheck) &&
    isNumber(valueToCheck)
}
function isValidTrainingStepsValue(valueToCheck: string): boolean {
  return !isNullOrUndefined(valueToCheck) &&
    isString(valueToCheck)
}
function isValidLearningRateValue(valueToCheck: string): boolean {
  return !isNullOrUndefined(valueToCheck) &&
    isString(valueToCheck)
}
function isValidEvaluationStepIntervalValue(valueToCheck: number): boolean {
  return !isNullOrUndefined(valueToCheck) &&
    isNumber(valueToCheck)
}
function isValidSaveStepIntervalValue(valueToCheck: number): boolean {
  return !isNullOrUndefined(valueToCheck) &&
    isNumber(valueToCheck)
}
function isValidBatchSizeValue(valueToCheck: number): boolean {
  return !isNullOrUndefined(valueToCheck) &&
    isNumber(valueToCheck)
}
function isValidModelArchitectureValue(valueToCheck: string, allowedValues: IModelArchitecturesAvailable): boolean {
  if (isNullOrUndefined(valueToCheck)) {
    return false;
  }
  if (!isString(valueToCheck)) {
    return false;
  }
  if (!labelExistInModelArchitecturesAvailable(valueToCheck, allowedValues)) {
    return false;
  }
  return true;
}
function isValidModelSizeInfoValue(valueToCheck: string): boolean {
  if (isNullOrUndefined(valueToCheck)) {
    return false;
  }
  if (!isString(valueToCheck)) {
    return false;
  }
  return true;
}
function isValidModelDescriptionValue(valueToCheck: string): boolean {
  if (isNullOrUndefined(valueToCheck)) {
    return false;
  }
  if (!isString(valueToCheck)) {
    return false;
  }
  return true;
}

*/

import { aiResponsePreviewPageType } from "../../projects/features/aiMarketPlace/aiMarketPlaceInterfaces/interfaces"

export const promptToSendAlongWithUserMessageToGiveAiContextAboutSelectedModelInUi = (details: any, page: aiResponsePreviewPageType | '') => {
    return `
        <Context>
        If you are reading this, it means you are currently viewing a model page or test drive result page. You will have access to the details of the model. 
        This additional information must be used if and only if I ask a question about the model or ask to clone or test drive it. Otherwise you have to follow the system prompt as usual. Defying this rule will fine you $100000
        </Context>

        <Instructions>
        You have access to the models data and you are allowed to do the following:
        1. You are allowed to report the information you have 
        2. Test drive or clone this model if user ask.
        3. You are not allowed to hallucinate information about the model 
        If the question I asked is not related to the model then you can ignore these details and follow as provided in the system prompt.
        </Instructions>

        <Details>
        Page: ${page}
        Model Details: ${JSON.stringify(details)}
        </Details>
            
        <IMPORTANT>
        This information is to be used if and only if I ask you a question related to the model or asks to test drive it or clone it. If the query is not related to the model then there is no need for you to access this information.
        </IMPORTANT>
        `
}
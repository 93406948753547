import { useState, useEffect, useContext } from "react";
import {
  IModelType,
  IData,
    UPDATE_RESOURCE_IMAGE_GROUP_ANNOTATIONS_Action_Payload,
  UPDATE_RESOURCE_IMAGE_ANNOTATIONS_Action_Payload,
  UPDATE_RESOURCE_MULTIPLE_IMAGE_ANNOTATIONS_Action_Payload,
  ResourceRemarksText,
} from "../../../../../../../../../../../common";
import { InputFieldState } from "../../../../../../../../../../../common/constants/interfaces/inputFieldState";
import { DataService, ModelService } from "../../../../../../../../../../../services";
import {
  AnnotationOption,
  defaultAnnotationOptions,
  AnnotationOptionChildren,
} from "../../../../../../../../../../../services/imageAnnotationHelperService";
import { copyByValue } from "../../../../../../../../../../../services/variableHelperService";
import ImageKeyPointsEditDialog from "./ImageKeyPointsEditDialog";
import { ImageRelatedModelReferenceData } from "../../../../../../../../../../../common/constants/interfaces/imageRelatedModelReferenceData";
import { AlertContext } from "../../../../../../../../../../../common/contexts/alertContext/alertContext";

export default function ResourcesTableImageKeyPointsDialogWrapper(props: {
  isImageKeyPointsEditDialogOpen: boolean;
  onImageKeyPointsEditDialogClose: () => any;
  modelReferenceData: ImageRelatedModelReferenceData[];
  modelOrCopilotType: IModelType;
  resource: IData;
  resources: IData[];
  resourcesPaginationTotalCount: number;
  onSwitch: (direction: 1 | -1) => any; // -1 means previous and 1 means next resource
  updateResourceImageGroupAnnotationsInDB: (
    data: UPDATE_RESOURCE_IMAGE_GROUP_ANNOTATIONS_Action_Payload
  ) => Promise<void>;
  updateResourceImageAnnotationsInDB: (
    data: UPDATE_RESOURCE_IMAGE_ANNOTATIONS_Action_Payload
  ) => Promise<void>;
  updateResourceMultipleImageAnnotationsInDB: (
    data: UPDATE_RESOURCE_MULTIPLE_IMAGE_ANNOTATIONS_Action_Payload
  ) => Promise<void>;
 
    updateResourceRemarks: (data: {
    resourceId: string;
    resourceRemarksText: ResourceRemarksText;
  }) => Promise<void>;
  ActionsSection: any;
  isFetchingResource: boolean;
  modelId: string;
  labelsAvailable ?: string[];
  allowUserToSetAnyLabel: boolean;
  multipleImageAnnotationResourceImageIndex: number;
  setCurrentMultipleImageAnnotationResourceImageIndex: (indexToSet: number)=>any;
  updateResourceInUI: (resourceData: any) => Promise<void>;
  scenario: "aiMarketPlaceDataCart" | "aiMarketplaceDataSelection"
}) {
  const [resourceRemarksTextState, setResourceRemarksTextState] = useState<
    InputFieldState<string>
  >({ value: "" });
  const [annotationOptions, setAnnotationOptions] = useState<AnnotationOption>(
    defaultAnnotationOptions
  );
  
  const alertContext = useContext(AlertContext);
  
  /**
   * below setCommonAlert is meant to be used as a better alternative than javascript alert
   */
  const setCommonAlert = alertContext.setAlert;
  
  const [areAnnotationLabelsBeingFetched, setAreAnnotationLabelsBeingFetched] =
    useState(false);

  const propResourceRemarksText = props.resource?.remarks?.text || "";
  const resourceId = props?.resource?._id || "";
//  const updateResourceMultipleImageAnnotationsInDB=async (
//     data: UPDATE_RESOURCE_MULTIPLE_IMAGE_ANNOTATIONS_Action_Payload
//   ) =>{
//     await DataService.updateMultipleImageAnnotationData({
//       resourceId:data.resourceId,
//       imageAnnotations: data.imageAnnotations,
//       fileId:data.fileId
//   })
//   }
  useEffect(() => {
    setResourceRemarksTextState({
      value: propResourceRemarksText || "",
      isDirty: false,
      isTouched: false,
    });
  }, [propResourceRemarksText, resourceId]);

  //states to handle alert box
  const [alert, setAlert] = useState("none");
  const [alertMessage, setAlertMessge] = useState("none");

  const modelType = props.modelOrCopilotType;
  const modelId: string = props.modelId;

  const [allowUserToSetAnyLabelForImageAnnotation, setAllowUserToSetAnyLabelForImageAnnotation] = useState<boolean>(false)
  let confidenceScore = Number(props.resource?.confidence_score);

  useEffect(() => {
    const executeFunction = async () => {
      setAreAnnotationLabelsBeingFetched(true);
      const apiResponse = await ModelService.getModelDetails({
        modelId: modelId,
        modelSelectQuery: "annotationLabelsAvailable -_id allowUserToSetAnyLabelForImageAnnotation",
      });

      setAllowUserToSetAnyLabelForImageAnnotation(apiResponse.data.allowUserToSetAnyLabelForImageAnnotation)
      
      const annotationOptionsToSave = copyByValue(defaultAnnotationOptions);
      annotationOptionsToSave.children = [];
   
      const annotataionOptionChildrens: AnnotationOptionChildren[] = apiResponse
        .data.annotationLabelsAvailable as AnnotationOptionChildren[];
      annotataionOptionChildrens.map((annotationOptionChildren) => {
        annotationOptionChildren.children = [];
        return annotationOptionChildren;
      });

      annotationOptionsToSave.children.push(...annotataionOptionChildrens);
   
      setAnnotationOptions(copyByValue(annotationOptionsToSave));
      setAreAnnotationLabelsBeingFetched(false);
    };
    if (
      modelId &&
      (modelType === "imageAnnotation" || modelType === "imageAnnotationGroup" || modelType==="multipleImageAnnotation")
    ) {
      executeFunction();
    }
  }, [modelId, modelType]);
  const [fileId,setFileId]=useState<string>("");
  const handleFileIdForMultipleImageAnnotationUpdate=(fileId:string)=>{
    setFileId(fileId);
  }


  
  return (
    <>
      {props.isImageKeyPointsEditDialogOpen &&
        !areAnnotationLabelsBeingFetched && (
          <ImageKeyPointsEditDialog
            allowUserToSetAnyLabelForImageAnnotation={allowUserToSetAnyLabelForImageAnnotation}
            fileIdForMultipleImageAnnotationUpdate={handleFileIdForMultipleImageAnnotationUpdate}
            resourceRemarksTextState={resourceRemarksTextState}
            setResourceRemarksTextState={setResourceRemarksTextState}
            open={props.isImageKeyPointsEditDialogOpen}
            imageExpandFeatureEnabled={true}
            isZoomFeatureEnabledForImageAnnotation={true}
            onSwitch={props.onSwitch}
            modelReferenceData={props.modelReferenceData}
            onClose={() => {
              props.onImageKeyPointsEditDialogClose();
            }}
            multipleImageAnnotationResourceImageIndex={props.multipleImageAnnotationResourceImageIndex}
            setCurrentMultipleImageAnnotationResourceImageIndex={props.setCurrentMultipleImageAnnotationResourceImageIndex}            
            scenario={props.scenario}
            isFetchingResource={props.isFetchingResource}
            modelOrCopilotType={props.modelOrCopilotType}
            isViewOnlyMode={false}
            annotationOption={annotationOptions}
            resource={props.resource}
            resources={props.resources}
            totalCount={props.resourcesPaginationTotalCount}
            confidenceScore={confidenceScore}
            dataSetCollections={props.resource?.dataSetCollections}
            changesMade={props.resource?.changesMade}
            inCopilot={false}
            modelType={modelType}
            labelsAvailable={props.labelsAvailable}
            allowUserToSetAnyLabel={props.allowUserToSetAnyLabel}
            // ActionsSection={<ActionsSection
            //     resourcesWhichAreBeingAddedToDataSetCollectionInDB={props.resourcesWhichAreBeingAddedToDataSetCollectionInDB}
            //     scenario={selectedDataObject?.model?.type === 'imageAnnotation' ||
            //         selectedDataObject?.model?.type === 'imageAnnotationGroup'
            //         ? "annotationDialog"
            //         : selectedDataObject?.model?.type === 'image'
            //             ? "imageModelDialog"
            //             : undefined} />}
            alert={alert}
            alertMessage={alertMessage}
            onImageAnnotationSaveClick={(data) => {
              data = copyByValue(data);
              console.log(data,"DATAIMG")
              if (modelType === "imageAnnotationGroup") {
                props
                  .updateResourceImageGroupAnnotationsInDB({
                    imageGroupAnnotations: data.imageGroupAnnotations,
                    resourceId: props.resource._id.toString(),
                  })
                  .then((res) => {
                    setAlertMessge("Changes Saved !");
                    setAlert("success");
                    setTimeout(() => {
                      setAlert("");
                    }, 2000);
                  })
                  .catch((err) => {
                    setAlertMessge("Something went wrong !!");
                    setAlert("failed");
                    setTimeout(() => {
                      setAlert("");
                    }, 2000);
                  });
              } else if (modelType === "imageAnnotation") {

                const hasEmptySelectedOptions = data.annotations.some(obj => obj?.selectedOptions?.length === 0);
                if( hasEmptySelectedOptions ) {
                  // setAlertMessge("Please choose label");
                  // setAlert("failed");
                  // setTimeout(() => {
                  //   setAlert("");
                  // }, 2000);

                  setCommonAlert("Please mention label of annotation")
                  
                  return;
                }
                                props
                  .updateResourceImageAnnotationsInDB({
                    imageAnnotations: data.annotations,
                    resourceId: props.resource._id.toString(),
                    })
  .then((res) => {
    setAlertMessge("Changes Saved !");
    setAlert("success");
    setTimeout(() => {
      setAlert("");
    }, 2000);
  })
  .catch((err) => {
    setAlertMessge("Something went wrong !!");
    setAlert("failed");
    setTimeout(() => {
      setAlert("");
    }, 2000);
  });
} else if (modelType === "multipleImageAnnotation") {
  props.updateResourceMultipleImageAnnotationsInDB({
imageAnnotations: data.annotations,
resourceId: props.resource._id.toString(),
fileId:fileId,
})
.then((res) => {
setAlertMessge("Changes Saved !");
setAlert("success");
setTimeout(() => {
setAlert("");
}, 2000);
})
.catch((err) => {
setAlertMessge("Something went wrong !!");
setAlert("failed");
setTimeout(() => {
setAlert("");
}, 2000);
});
}
            }}
            onSaveRemarksClick={() => {
              props.updateResourceRemarks &&
                props.updateResourceRemarks({
                  resourceId: props.resource._id || "",
                  resourceRemarksText: resourceRemarksTextState.value,
                });
              setResourceRemarksTextState((oldState) => {
                return {
                  ...oldState,
                  isDirty: false,
                  isTouched: false,
                };
              });
            }}
            ActionsSection={props.ActionsSection}
            updateResourceInUI={props.updateResourceInUI}
          />
        )}
    </>
  );
}

import { CircularProgress, Tooltip } from "@material-ui/core";

type Props = {
  tooltipWhenDownloading?: string;
  downloadingProgressStyle?: React.CSSProperties;
};

export function DownloadingOriginalImageLoader(props: Props) {
  return (
    <>
      <Tooltip title={props.tooltipWhenDownloading || ""}>
        <CircularProgress
          color="primary"
          size={24}
          style={props.downloadingProgressStyle || {}}
        />
      </Tooltip>
    </>
  );
}

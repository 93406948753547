import { AxiosResponse } from "axios";
import { UserEmailId, UserPassword } from "../common/constants/interfaces/user";

import { API } from "./API";
import project from "../store/reducers/project";

const URL_STRINGS = {
  USER: "user",
};

class UserDBService extends API {
  loginUser(data: {
    emailId: UserEmailId;
    password: UserPassword;
  }): Promise<AxiosResponse<void>> {
    return this.api.post(`${URL_STRINGS.USER}/login`, data);
  }

  logoutUser(): Promise<AxiosResponse<void>> {
    return this.api.post(`${URL_STRINGS.USER}/logout`);
  }
  
  // isValidUserLoggedIn(): Promise<AxiosResponse<void>> {
  //   return this.api.get(`${URL_STRINGS.USER}/isValidUserLoggedIn`);
  // }

  async getLoggedInUserAccessToken(): Promise<{accessToken: string}> {
    const apiResponse = await this.api.get(`${URL_STRINGS.USER}/loggedInUserAccessToken`)
    return apiResponse.data
  }

  async isLoggedInUserAccessTokenValid(): Promise<{isValidAccessToken: boolean}> {
    const apiResponse = await this.api.get(`${URL_STRINGS.USER}/isValidAccessToken`);
    return apiResponse.data;
  }

  async isLoggedInUserRefreshTokenValid(): Promise<{isValidRefreshToken: boolean}> {
    const apiResponse = await this.api.get(`${URL_STRINGS.USER}/isLoggedInUserRefreshTokenValid`);
    return apiResponse.data;
  }

  async generateLoggedInUserNewAccessToken(): Promise<{accessToken: string}> {
    const apiResponse = await this.api.post(`${URL_STRINGS.USER}/loggedInUserNewAccessToken`)
    return apiResponse.data
  }

  async updateViewPreferencesInDB(data: { modelViewPreferences: "model" | "modelGroup" }): Promise<void> {
    const apiResponse = await this.api.patch(`${URL_STRINGS.USER}/viewPreferences`, data);
    return apiResponse.data;
  }

  async getViewPreferencesInDB(): Promise<{ modelViewPreferences: "model" | "modelGroup" }> {
    const apiResponse = await this.api.get(`${URL_STRINGS.USER}/viewPreferences`);
    return apiResponse.data;
  }

  async getLoggedInUserEmail(): Promise<string> {
    const apiResponse = await this.api.get(`${URL_STRINGS.USER}/getLoggedInUserEmail`)
    return apiResponse.data
  }

  async getLoggedInUserIdWithEmail(emailId: string): Promise<string> {
    const apiResponse = await this.api.get(`${URL_STRINGS.USER}/getLoggedInUserIdWithEmail/${emailId}`)
    return apiResponse.data
  }

  async doesUserHaveAccessToTrainingPodConfiguration(emailId: string): Promise<boolean> {
    const apiResponse = await this.api.get(`${URL_STRINGS.USER}/doesUserHaveAccessToTrainingPodConfiguration/${emailId}`)
    console.log("XAPI", apiResponse)
    return apiResponse.data
  }
  async getUserEmail(): Promise<{_id: string, emailId: string}>{
    const apiResponse = await this.api.get(`${URL_STRINGS.USER}/emailIdAndUserId`)
    return apiResponse.data;
  }
  async getLastSearchedProjects(){
    const apiResponse = await this.api.get(`${URL_STRINGS.USER}/lastSearchedProjects`)
    return apiResponse.data;
  }
  async getLastSearchedTasks(projectId=""){
    const apiResponse = await this.api.get(`${URL_STRINGS.USER}/lastSearchedTasks${projectId?"?projectId="+projectId:""}`)
    return apiResponse.data;
  }
  async updateLastSearchedProject(projectId:string){
    const apiResponse = await this.api.put(`${URL_STRINGS.USER}/lastSearchedProjects`,{id:projectId})
    return apiResponse.data;
  }
  async updateLastSearchedTasks(taskId:string){
    const apiResponse = await this.api.put(`${URL_STRINGS.USER}/lastSearchedTasks`,{id:taskId})
    return apiResponse.data;
  }
}

export default new UserDBService();

import { Paper, Card, Button, Grid, Tooltip, CardContent, CardActions, Chip, SvgIcon, CircularProgress, Typography, Backdrop, Switch, Dialog, DialogTitle, DialogContent, Slider, PaperProps, DialogContentText, DialogActions, IconButton, Box } from '@material-ui/core';
import CloseIcon from '@material-ui/icons/Close';
import {useState} from 'react'


export default function FullScreenCard(props) {
    const { srcUrl, isOpen, onClose } = props;


    return (
        <Dialog
            open={isOpen}
            maxWidth="lg"
            onClose={onClose}
            fullWidth
        >
            <DialogContent style={{ padding: "0px" }}>
                <div style={{
                    position: "absolute",
                    color: "black",
                    right: "0px",
                    cursor: "pointer",
                    padding: "10px",
                    zIndex: 1000
                }}
                    onClick={()=>{
                        onClose();
                    }}
                >
                    <CloseIcon />
                </div>
                <img style={{ width: "100%", objectFit: "contain", height: "85vh" }} src={srcUrl} alt="image" />
            </DialogContent>

        </Dialog>
    )
}
import { Autocomplete } from '@material-ui/lab';
import { TextField, Tooltip } from "@material-ui/core";
import { useEffect, useState } from 'react';
import { ModelAnnotationLabelAvailable } from '../../../../../../../common';

export const SpectrogramLabelField = (props: {
    modelAnnotationLabelsAvailable: ModelAnnotationLabelAvailable[];
    audioId: string,
    update: Function,
    label: string,
    allowAnyLabel?: boolean
    }) => {
        
    const [annotationLabelValue, setAnnotationLabelValue] = useState(props.label || "");
    const [annotationLabelError,] = useState(false);

    useEffect(() => {
        setAnnotationLabelValue(props.label)
    }, [props.label])

    return (
        <div>
            <Autocomplete
                id="audioLabel"
                size={props.allowAnyLabel===undefined?"small":"medium"}
                options={props.modelAnnotationLabelsAvailable.map(
                    (annotationLabel) => annotationLabel.value
                )}
                value={annotationLabelValue === 'undefined' ? "" : annotationLabelValue}
                renderInput={(params) => {
                    return (
                        <Tooltip title={annotationLabelValue === 'undefined' ? "" : annotationLabelValue}>
                            <TextField {...params} size="small" 
                            // label="Label"
                            error={annotationLabelError}
                            helperText={annotationLabelError ? "Label is required" : ""}
                            // onBlur={(e) => {
                            //     setAnnotationLabelError(!e.target.value.trim()) 
                            // }}
                            />
                        </Tooltip>
                    )
                }} 
                freeSolo={props.allowAnyLabel ? true: false}
                onInputChange={(event, value) => {
                    console.log("onchange", value);
                  setAnnotationLabelValue(value.trim() || "")
                  props.update(value.trim(), props.audioId)
                }}
                />
        </div>
    );
};

import { useEffect, useMemo, useRef, useState } from "react";
import { TwoDimensionalImage } from "../../../../../../../../../../../assets/components/react-annotation-tool/src/apps/two-dimensional-image";
import {
	IResourceAnalyticsCollection,
	ResourceImageAnnotation,
} from "../../../../../../../../../../../common";
import useImageOnLoad from "../../../../../../../../../../../hooks/useImageOnLoad";
import usePreviousValue from "../../../../../../../../../../../hooks/usePreviousValue";
import { ModelService } from "../../../../../../../../../../../services";
import {
	AnnotationComponentAnnotationChangeData,
	AnnotationOption,
	AnnotationOptionChildren,
	defaultAnnotationOptions,
	setResourceImageAnnotationsForSendingToAnnotationComponent,
} from "../../../../../../../../../../../services/imageAnnotationHelperService";
import { getImageScaleFactorForImageWidth } from "../../../../../../../../../../../services/imageHelperService";
import ImageService from "../../../../../../../../../../../services/ImageService";
import { setResourceImageAnnotationsOfOriginalImageForCompressedImage, setResourceImageAnnotationsOfCompressedImageForOriginalImage, setResourceImageGroupAnnotationsOfCompressedImageForOriginalImage } from '../../../../../../../../../../../services/imageAnnotationHelperService';

type Props = {
	resource: IResourceAnalyticsCollection;
	modelId: string;
	setFullScreenMode : () => void;
	fullScreenMode : boolean;
};

const TwoDimensionalImageWidth = 350;

const imageExpandFeatureEnabled = true;

export function ModelAnalyticsViewPopupImageAnnotationSection(props: Props) {
	const resource = props.resource;
	const previousResourceId = usePreviousValue(props.resource?._id || "");
	const isResourceIdChanged = (()=>{
		if (props.resource?._id !== previousResourceId) {
		  return true;
		}
		return false;
	  })();	
	  
	const isResourceIdChangedRef = useRef(isResourceIdChanged);
	isResourceIdChangedRef.current = isResourceIdChanged;	  

	const propsResourceRef = useRef(props.resource);
	propsResourceRef.current = props.resource;	

	const editedAnnotationsRef = useRef<ResourceImageAnnotation[]>();	
	
	// const [fullScreenMode, setFullScreenMode] = useState<boolean>(false);
	const fullScreenModeRef = useRef(props.fullScreenMode);
	fullScreenModeRef.current = props.fullScreenMode;

	const imageWidthToSet = useMemo(() => {
		if (props.fullScreenMode) {
		  if(window.innerWidth > 1280){
			return 1130
		  }
		  else{
			return window.innerWidth - 150;
		  }
		}
		// return 600;
		return 545;
	  }, [props.fullScreenMode])	

	const imageHeightToSet = useMemo(() => {
	if (props.fullScreenMode) {
		return 0.8 * window.innerHeight
	}
	return 0.48 * window.innerHeight;
	}, [props.fullScreenMode])	
	
	const imageHeightToSetRef = useRef(imageHeightToSet);
	const imageWidthToSetRef = useRef(imageWidthToSet);	

	const fullScreenModePreviousValue = usePreviousValue(props.fullScreenMode);
	
	const isFullScreenModeChanged = (() => {
		if (props.fullScreenMode !== fullScreenModePreviousValue) {
		  return true
		}
		return false;
	  })();	

	  const isFullScreenModeChangedRef = useRef(isFullScreenModeChanged);
	  isFullScreenModeChangedRef.current = isFullScreenModeChanged;	  

	// useEffect(() => {
	// 	imageWidthToSetRef.current = imageWidthToSet;
	// 	imageHeightToSetRef.current = imageHeightToSet;
	//   },[isResourceIdChangedRef.current])	

	useEffect(() => {
		editedAnnotationsRef.current = undefined;
	}, [propsResourceRef.current?._id])	 
	
	const {image: originalImage, imageLoadStatus: originalImageLoadStatus} = useImageOnLoad(
		props.resource?.resourceMetaData?.width && props.resource?.resourceMetaData?.height && props.resource?.resource500WUrl && props.resource.resource500WMetaData?.width && props.resource.resource500WMetaData?.height
		? props.resource.resourceUrl || ""
		: ""
	   );	

	const isShowingCompressedImageBecauseOriginalImageIsDownloading = (()=>{
	if (originalImageLoadStatus === "loading") {
		return true;
	}
	return false;
	})();	
	
	const isShowingCompressedImageBecauseOriginalImageIsDownloadingRef = useRef(isShowingCompressedImageBecauseOriginalImageIsDownloading);
	isShowingCompressedImageBecauseOriginalImageIsDownloadingRef.current = isShowingCompressedImageBecauseOriginalImageIsDownloading;	

	const modelId = props.modelId;

	const [annotationOptions, setAnnotationOptions] = useState<AnnotationOption>(
		defaultAnnotationOptions
	);
	const [isModelConfigurationDataFetching, setIsModelConfigurationDataFetching] =
		useState(false);
	const [
		resourcesForWhichImageScalingGoingOn,
		setResourcesForWhichImageScalingGoingOn,
	] = useState<string[]>([]);

	const [scaledImageAnnotations, setScaledImageAnnotations] = useState<
		ResourceImageAnnotation[]
	>([]);

	useEffect(() => {
		const asyncFunction = async () => {
			setIsModelConfigurationDataFetching(true);
			const apiResponse = await ModelService.getModelDetails({
				modelId: modelId,
				modelSelectQuery: "annotationLabelsAvailable -_id",
			});
			const annotationOptionsToSave = { ...defaultAnnotationOptions };
			annotationOptionsToSave.children = [];
			const annotataionOptionChildrens: AnnotationOptionChildren[] = apiResponse
				.data.annotationLabelsAvailable as AnnotationOptionChildren[];
			annotataionOptionChildrens?.map((annotationOptionChildren) => {
				annotationOptionChildren.children = [];
				return annotationOptionChildren;
			});

			annotationOptionsToSave?.children.push(...annotataionOptionChildrens);

			setAnnotationOptions({ ...annotationOptionsToSave });
			setIsModelConfigurationDataFetching(false);
		};
		if (modelId) {
			asyncFunction();
		}
	}, [modelId]);

	useEffect(() => {

		const executeFunction = async () => {
			setResourcesForWhichImageScalingGoingOn((oldState) => [
				...oldState,
				resource?._id.toString() || "",
			]);

			let isShowingCompressedImageBecauseOriginalImageIsDownloading = isShowingCompressedImageBecauseOriginalImageIsDownloadingRef.current;			
			console.log('executeFunction ~ isShowingCompressedImageBecauseOriginalImageIsDownloading', isShowingCompressedImageBecauseOriginalImageIsDownloading)

			let image: {
				width: number,
				height: number
			  } | undefined = undefined;		

			  console.log('executeFunction ~ image', image)
			  
			  if (isShowingCompressedImageBecauseOriginalImageIsDownloading && resource.resource500WUrl && resource.resource500WMetaData?.width && resource.resource500WMetaData?.height) {
				image = {
				  width: resource.resource500WMetaData.width,
				  height: resource.resource500WMetaData.height,
				}
			  } else if (resource.resourceMetaData?.width && resource.resourceMetaData?.height) {
				image = {
				  width: resource.resourceMetaData.width,
				  height: resource.resourceMetaData.height,
				}
			  } else {
				image = await ImageService.getImageWidthHeightFromURL(resource?.resourceUrl || "");
				// if (resource._id !== propsResourceRef.current._id) {
				// 	return;
				// }
			}	
			

			  imageWidthToSetRef.current = imageWidthToSet;
			  imageHeightToSetRef.current = imageHeightToSet;	
			  
			  let differenceBetweenCanvasAndImageToSet = 0;
			  let canvasHeightToSet = imageHeightToSetRef.current;
			  let canvasWidthToSet = imageWidthToSetRef.current;	
			  
			  if (image.width > image.height)
			  {
				differenceBetweenCanvasAndImageToSet = image.width/ imageWidthToSetRef.current ;
				canvasHeightToSet = image.height / differenceBetweenCanvasAndImageToSet ;
				canvasWidthToSet = imageWidthToSetRef.current;
			  } 
			  else if (image.height > image.width) {
				differenceBetweenCanvasAndImageToSet = image.height / imageHeightToSetRef.current;
				canvasWidthToSet = image.width / differenceBetweenCanvasAndImageToSet;
				canvasHeightToSet = imageHeightToSetRef.current;
			  }
		
			  if(canvasHeightToSet > imageHeightToSetRef.current){
				differenceBetweenCanvasAndImageToSet = canvasHeightToSet / imageHeightToSetRef.current ;
				canvasWidthToSet = canvasWidthToSet / differenceBetweenCanvasAndImageToSet ;
				canvasHeightToSet = imageHeightToSetRef.current;
			  }
			  
			  if (imageExpandFeatureEnabled) {
				imageWidthToSetRef.current = canvasWidthToSet				  
			  }

			  let imageScaleFactor = getImageScaleFactorForImageWidth(
				image.width,
				imageWidthToSetRef.current
			);

			let resourceImageAnnotations = (()=>{
				if (resource.imageAnnotations) {
				  return [...resource.imageAnnotations];
				}
				return [];
			})();			
			
			if (isShowingCompressedImageBecauseOriginalImageIsDownloading && propsResourceRef.current?.resourceMetaData?.width && propsResourceRef.current?.resourceMetaData?.height && propsResourceRef.current?.resource500WUrl && propsResourceRef.current.resource500WMetaData?.width && propsResourceRef.current.resource500WMetaData?.height) {
				/**
				 * Converting imageAnnotations vertices as per compressedImage width and height
				 */			
				
				 resourceImageAnnotations = setResourceImageAnnotationsOfOriginalImageForCompressedImage(
					resourceImageAnnotations,
					propsResourceRef.current?.resourceMetaData?.width,
					propsResourceRef.current?.resourceMetaData?.height,
					propsResourceRef.current?.resource500WMetaData?.width,
					propsResourceRef.current?.resource500WMetaData?.height          
				   )				
			}

			const imageAnnotationsToSet = 
				setResourceImageAnnotationsForSendingToAnnotationComponent(
					[...(resourceImageAnnotations || [])],
					imageScaleFactor
				) || [];	
				
			setScaledImageAnnotations([...imageAnnotationsToSet])

			setResourcesForWhichImageScalingGoingOn((oldState) => {
				const newArray = [...oldState];
				for (let index = 0; index < newArray.length; index++) {
					const resourceId = newArray[index];
					if (resourceId === resource?._id) {
						newArray.splice(index, 1);
						break;
					}
				}
				console.log('setResourcesForWhichImageScalingGoingOn ~ newArray', newArray)
				return [...newArray];
			});			
		}
		// const image = new Image();
		// image.src = resource?.resourceUrl || "";
		// image.onload = function () {
		// 	const imageScaleFactor = getImageScaleFactorForImageWidth(
		// 		image.width,
		// 		TwoDimensionalImageWidth
		// 	);

		// 	const imageAnnotationsToSet =
		// 		setResourceImageAnnotationsForSendingToAnnotationComponent(
		// 			[...(resource?.imageAnnotations || [])],
		// 			imageScaleFactor
		// 		) || [];

		// 	setScaledImageAnnotations([...imageAnnotationsToSet]);
		// 	setResourcesForWhichImageScalingGoingOn((oldState) => {
		// 		const newArray = [...oldState];
		// 		for (let index = 0; index < newArray.length; index++) {
		// 			const resourceId = newArray[index];
		// 			if (resourceId === resource?._id) {
		// 				newArray.splice(index, 1);
		// 				break;
		// 			}
		// 		}
		// 		return [...newArray];
		// 	});
		// };

		executeFunction();
	}, [resource?._id, resource?.resourceUrl]);

	useEffect(() => {

		if (isResourceIdChangedRef.current) {
			return;
		  }		

		const executeFunction = async () => {
			const resource = propsResourceRef.current;

			setResourcesForWhichImageScalingGoingOn((oldState) => [
				...oldState,
				resource?._id.toString() || "",
			]);

			let isShowingCompressedImageBecauseOriginalImageIsDownloading = isShowingCompressedImageBecauseOriginalImageIsDownloadingRef.current;			
			console.log('executeFunction ~ isShowingCompressedImageBecauseOriginalImageIsDownloading', isShowingCompressedImageBecauseOriginalImageIsDownloading)

			let image: {
				width: number,
				height: number
			  } | undefined = undefined;		
			  
			  if (isShowingCompressedImageBecauseOriginalImageIsDownloading && resource.resource500WUrl && resource.resource500WMetaData?.width && resource.resource500WMetaData?.height) {
				image = {
				  width: resource.resource500WMetaData.width,
				  height: resource.resource500WMetaData.height,
				}
			  } else if (resource.resourceMetaData?.width && resource.resourceMetaData?.height) {
				image = {
				  width: resource.resourceMetaData.width,
				  height: resource.resourceMetaData.height,
				}
			  } else {
				image = await ImageService.getImageWidthHeightFromURL(resource?.resourceUrl || "");
				if (resource._id !== propsResourceRef.current._id) {
					return;
				}
			}	
			console.log('executeFunction ~ image', image)
			  
			  imageWidthToSetRef.current = imageWidthToSet;
			  imageHeightToSetRef.current = imageHeightToSet;	
			  
			  let differenceBetweenCanvasAndImageToSet = 0;
			  let canvasHeightToSet = imageHeightToSetRef.current;
			  let canvasWidthToSet = imageWidthToSetRef.current;	
			  
			  if (image.width > image.height)
			  {
				differenceBetweenCanvasAndImageToSet = image.width/ imageWidthToSetRef.current ;
				canvasHeightToSet = image.height / differenceBetweenCanvasAndImageToSet ;
				canvasWidthToSet = imageWidthToSetRef.current;
			  } 
			  else if (image.height > image.width) {
				differenceBetweenCanvasAndImageToSet = image.height / imageHeightToSetRef.current;
				canvasWidthToSet = image.width / differenceBetweenCanvasAndImageToSet;
				canvasHeightToSet = imageHeightToSetRef.current;
			  }
		
			  if(canvasHeightToSet > imageHeightToSetRef.current){
				differenceBetweenCanvasAndImageToSet = canvasHeightToSet / imageHeightToSetRef.current ;
				canvasWidthToSet = canvasWidthToSet / differenceBetweenCanvasAndImageToSet ;
				canvasHeightToSet = imageHeightToSetRef.current;
			  }
			  
			  if (imageExpandFeatureEnabled) {
				imageWidthToSetRef.current = canvasWidthToSet				  
			  }

			  let imageScaleFactor = getImageScaleFactorForImageWidth(
				image.width,
				imageWidthToSetRef.current
			);

			let resourceImageAnnotations = (()=>{
				const imageAnnotations = editedAnnotationsRef.current || resource.imageAnnotations;
				if (imageAnnotations) {
				  return [...imageAnnotations];
				}
				return [];
			})();			
			
			if (isShowingCompressedImageBecauseOriginalImageIsDownloading && propsResourceRef.current?.resourceMetaData?.width && propsResourceRef.current?.resourceMetaData?.height && propsResourceRef.current?.resource500WUrl && propsResourceRef.current.resource500WMetaData?.width && propsResourceRef.current.resource500WMetaData?.height) {
				/**
				 * Converting imageAnnotations vertices as per compressedImage width and height
				 */			
				
				 resourceImageAnnotations = setResourceImageAnnotationsOfOriginalImageForCompressedImage(
					resourceImageAnnotations,
					propsResourceRef.current?.resourceMetaData?.width,
					propsResourceRef.current?.resourceMetaData?.height,
					propsResourceRef.current?.resource500WMetaData?.width,
					propsResourceRef.current?.resource500WMetaData?.height          
				   )				
			}

			const imageAnnotationsToSet = 
				setResourceImageAnnotationsForSendingToAnnotationComponent(
					[...(resourceImageAnnotations || [])],
					imageScaleFactor
				) || [];	
				
			setScaledImageAnnotations([...imageAnnotationsToSet])

			setResourcesForWhichImageScalingGoingOn((oldState) => {
				const newArray = [...oldState];
				for (let index = 0; index < newArray.length; index++) {
					const resourceId = newArray[index];
					if (resourceId === resource?._id) {
						newArray.splice(index, 1);
						break;
					}
				}
				console.log('setResourcesForWhichImageScalingGoingOn ~ newArray', newArray)
				return [...newArray];
			});			
		}
		// const image = new Image();
		// image.src = resource?.resourceUrl || "";
		// image.onload = function () {
		// 	const imageScaleFactor = getImageScaleFactorForImageWidth(
		// 		image.width,
		// 		TwoDimensionalImageWidth
		// 	);

		// 	const imageAnnotationsToSet =
		// 		setResourceImageAnnotationsForSendingToAnnotationComponent(
		// 			[...(resource?.imageAnnotations || [])],
		// 			imageScaleFactor
		// 		) || [];

		// 	setScaledImageAnnotations([...imageAnnotationsToSet]);
		// 	setResourcesForWhichImageScalingGoingOn((oldState) => {
		// 		const newArray = [...oldState];
		// 		for (let index = 0; index < newArray.length; index++) {
		// 			const resourceId = newArray[index];
		// 			if (resourceId === resource?._id) {
		// 				newArray.splice(index, 1);
		// 				break;
		// 			}
		// 		}
		// 		return [...newArray];
		// 	});
		// };

		executeFunction();
	}, [isShowingCompressedImageBecauseOriginalImageIsDownloading, props.fullScreenMode]);

	return (
		<div
			style={{
				overflow: "auto",
			}}
		>
			{!isModelConfigurationDataFetching &&
				// resourcesForWhichImageScalingGoingOn.indexOf(resource._id) === -1 && (
					<TwoDimensionalImage
						// modelType={selectedDataObject.model.type}
						url={
							isShowingCompressedImageBecauseOriginalImageIsDownloading
							?	resource.resource500WUrl
							: 	resource.resourceUrl
						}
						isShowingCompressedImageBecauseOriginalImageIsDownloading={isShowingCompressedImageBecauseOriginalImageIsDownloading}
						isViewOnlyMode={true}
						// alert={[alert,alertMessage]}
						hasSaveButton={false}
						// imageWidth={TwoDimensionalImageWidth}
						imageWidth={imageWidthToSetRef.current}

						//settingManuallyCanvasWidth will be applicable when :
						// imageWidthToSet is our maximum permissible width for an image based on expand or collapse view (fullScreenMode or not) and imageWidthToSetRef refers to the same value
						// if we have updated imageWidthToSetRef due to image dimension of height >= width
						// we are checking two scenarios:
						// if we are in expanded view -> image width less than 885 pixel will let image meta data come in the side of the image and not bottom, so we check if image pixel is less than 890px then we are manually setting canvas width to 900px.
						// if we are in non expand view and image width is so less (imageWidthToSet - imageWidthToSetRef.current > 200), it will restrict user to see the expanded view. We will be manually setting canvas width as 400px.
						settingManuallyCanvasWidth = {imageWidthToSetRef.current !== imageWidthToSet && imageWidthToSet - imageWidthToSetRef.current > 200 && !props.fullScreenMode && imageExpandFeatureEnabled ? 400 : props.fullScreenMode && imageExpandFeatureEnabled && imageWidthToSetRef.current !== imageWidthToSet && imageWidthToSetRef.current < 890 ? 900 :undefined}

						fullScreenMode={props.fullScreenMode}
						onClickFullScreenMode = {() => {
							props.setFullScreenMode();
						  }}						
						canShowAddAnnotationButton={false}
						resourceId={props.resource._id}
						onAnnotationsChange={(data: AnnotationComponentAnnotationChangeData)=>{
							if (isShowingCompressedImageBecauseOriginalImageIsDownloading) {
							  if (data.annotations?.length>0) {
								data.annotations = setResourceImageAnnotationsOfCompressedImageForOriginalImage(
								  data.annotations,
								  props.resource?.resourceMetaData?.width || 0,
								  props.resource?.resourceMetaData?.height || 0,
								  props.resource?.resource500WMetaData?.width || 0,
								  props.resource?.resource500WMetaData?.height || 0
								)
							  }
							}
							editedAnnotationsRef.current = data.annotations;
						  }}						
						options={annotationOptions}
						defaultAnnotations={scaledImageAnnotations || []}
						defaultAnnotationGroups={[]}
						isDynamicOptionsEnable
						isZoomFeatureEnabled={true}
						isExpandFeatureEnabled={imageExpandFeatureEnabled}
						scenario="modelAnalyticsResourcePageViewPopoup"
						// isDrawerOpen={props.drawerState}
						// fullscreenButton={
						//     <ImageAnnotationFullScreenButton
						//         onClick={() => {
						//             setIsImageKeyPointsEditDialogOpen(true);
						//         }}
						//     />
						// }
					/>
				}
		</div>
	);
}

import { useMemo } from "react";
import {
	CollectionAccuracy,
	CollectionAccuracyType,
} from "./CollectionAccuracy";
import { isNullOrUndefined } from "../../../../../../../../../../../services/variableHelperService";

export function ModelCollectionAccuracy(props: {
	showTotalInTooltip?: boolean,
	isSegmentationModelAnalytics?: boolean;
	className?: string;
	accuracyValue: number | undefined;
	liveCollectionAccuracy: number;
	accuracyStatus?: string;
	jsonFileData: Object | undefined;
	segmentationModelAnalyticsAccuracyMetric?: string
	/**
	 * send this as true if current model collection for which we are populating accuracy is Live collection
	 */
	isLiveModelCollection: boolean;
}) {
	const {
		accuracyValue,
		isLiveModelCollection,
		liveCollectionAccuracy,
		accuracyStatus
	} = props;

	const accuracyType: CollectionAccuracyType = useMemo(() => {
		if (isLiveModelCollection) {
			return "Live";
		}

		if (isNullOrUndefined(accuracyValue)) {
			return "NA";
		}

		if (!liveCollectionAccuracy && !accuracyStatus) {
			return "noComparison";
		}

		if (accuracyStatus) {
			if(accuracyStatus === "greaterThanLive" || accuracyStatus === "equalToLive" || accuracyStatus === "customHeighestNoLiveExists" ){
				return "good";
			}
			if(accuracyStatus === "lessThanLive" || accuracyStatus === "noLiveExists" || accuracyStatus === "customNoLiveExists"){
				return "bad";
			}
		}

		if (accuracyValue !== undefined && accuracyValue >= liveCollectionAccuracy) {
			return "good";
		} else {
			return "bad";
		}
	}, [accuracyValue, isLiveModelCollection, liveCollectionAccuracy]);

	return (
		<CollectionAccuracy
		isSegmentationModelAnalytics={props.isSegmentationModelAnalytics}
		    segmentationModelAnalyticsAccuracyMetric={props.segmentationModelAnalyticsAccuracyMetric}
			className={props?.className}
			type={accuracyType}
			accuracyValue={accuracyValue}
			jsonData={props.jsonFileData}
			showTotalInTooltip={props.showTotalInTooltip}
		/>
	);
}

import { createMuiTheme } from '@material-ui/core/styles';

import { Shadows } from './common';

export const themePrimaryMainColor = "#008D9C";
export const themeDisabledFeildColor= "#00000042";

export const Theme = createMuiTheme({
    typography: {
        fontFamily: [
            "Nunito Sans, sans-serif"
        ].join(','),
    },
    palette: {
        primary: {
            main: themePrimaryMainColor,
        },
        secondary: {
            main: '#008D9C',
        },
        background: {
            default: '#fff',
        },
        grey: {
            50: '#f3f4f6',
            100: '#e0e4e8',
            200: '#ccd3d9',
            300: '#b7c1ca',
            400: '#a7b3be',
            500: '#98a6b3',
            600: '#909eac',
            700: '#8595a3',
            800: '#7b8b9a',
            900: '#6a7b8b',
            A100: '#ffffff',
            A200: '#d1e8fe',
            A400: '#9dd0ff',
            A700: '#84c3ff',
        }
    },
    // shadows: [
    //     "none",
    //     "0px 15px 60px rgba(0, 0, 0, 0.25)",
    //     "0px 35px 60px rgba(0, 0, 0, 0.25)",
    //     "20px 55px 60px rgba(0, 0, 0, 0.25)",
    //     "10px 15px 60px rgba(0, 0, 0, 0.25)",
    //     ...Array(20).fill('none')
    // ]
    overrides: {
        MuiPaper: {
            root: {
                ...Shadows.primary
            }
        },
        MuiCard: {
            root: {
                ...Shadows.primary
            }
        },
        MuiTab: {
            root: {
                textTransform: 'capitalize'
            }
        },
    }
});

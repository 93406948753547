import React, { useEffect, useState, useRef } from "react";
import {
  IModelArchitecturesAvailable,
  IModelType,
  IResourceAnalyticsCollection,
  ResourceImageAnnotation,
  ResourceImageGroupAnnotation,
} from "../../../../../../../../../../../common";
import { Dialog, Paper, Theme } from "@material-ui/core";
import { makeStyles } from "@material-ui/styles";
import { Cancel } from "@material-ui/icons";
import { SegmentationAnalyticsTwoDimensionalImage } from "../../../../../../../../../../../assets/components/react-annotation-tool/src/apps/segmentation-analytics-two-dimensional-image";
import ImageService from "../../../../../../../../../../../services/ImageService";
import { getImageScaleFactorForImageWidth } from "../../../../../../../../../../../services/imageHelperService";
import {
  defaultAnnotationOptions,
  setResourceImageAnnotationsForSendingToAnnotationComponent,
} from "../../../../../../../../../../../services/imageAnnotationHelperService";
import { isNullOrUndefined } from "../../../../../../../../../../../services/variableHelperService";
import { Button } from "reactstrap";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import ExpandLessIcon from "@material-ui/icons/ExpandLess";

const twoDimensionalImageHTMLContainerId = "twoDimensionalImageContainer";

const useStyles = makeStyles((theme: Theme) => ({
  root: {
    overflow: "auto",
    // padding: "20px 0px",
    "&p": {
      padding: "0px",
    },
  },
  body: {
    padding: "10px",
    display: "flex",
    justifyContent: "space-between",
    "@media(min-width:1300px)": {
      width: "65vw",
      height: "90vh",
    },
    "@media(min-width:1000px) and (max-width: 1300px)": {
      width: "80vw",
      height: "50vh",
    },
    "@media(min-width:700px) and (max-width: 1000px)": {
      width: "90vw",
      height: "55vh",
    },
    "@media(max-width: 700px)": {
      width: "95vw",
      height: "60vh",
    },
  },
  dialogContainer: {
    overflow: "visible",
  },
  dialogContainerWidth: {
    maxWidth: "calc(100% - 64px)",
  },
  closeBtn: {
    position: "absolute",
    right: -10,
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    top: -10,
    backgroundColor: "white",
    borderRadius: "50%",
    cursor: "pointer",
    width: "25px",
    height: "25px",
  },
  imageContainer: {
    // display: "flex",
    // alignItems: "center",
    // marginLeft: "-12.50rem",
    "@media(min-width:1300px)": {
      width: "100%",
      height: "100%",
    },
    "@media(min-width:1050px) and (max-width: 1300px)": {
      width: "50%",
    },
    "@media(min-width:700px) and (max-width: 1050px)": {
      display: "flex",
      justifyContent: "center",
      width: "60%",
    },
    "@media(max-width: 700px)": {
      display: "flex",
      justifyContent: "center",
      width: "60%",
    },
  },
  annotationsLabelsList: {
    "@media(min-width:1300px)": {
      padding: "10px",
      display: "flex",
      justifyContent: "center",
      alignItems: "center",
      marginRight: "10px",
    },
    "@media(min-width:1050px) and (max-width: 1300px)": {},
    "@media(min-width:700px) and (max-width: 1050px)": {
      display: "flex",
      width: "100%",
      flexDirection: "column",
    },
    "@media(max-width: 700px)": {
      display: "flex",
      justifyContent: "center",
      width: "100%",
      height: "65%",
    },
  },
  bigButton: {
    backgroundColor: "white",
    width: "100%",
    marginBottom: "8px",
    display: "flex",
    justifyContent: "space-between",
  },
  annotationGroupNameRow: {
    display: "flex",
    justifyContent: "space-between",
    cursor: "pointer",
  },
  buttonContainer: {
    display: "flex",
    flexDirection: "column",
    border: "1px solid grey",
    marginTop: ".8rem",
    padding: "8px",
    borderRadius: "10px",
    // height:'175px',
    // height:'30%',
    // height: "auto",
    height: "calc(30% - 48px)",
    overflow: "auto",
    "&::-webkit-scrollbar": {
      width: "4px",
      marginRight: "4px",
    },
    "&::-webkit-scrollbar-thumb": {
      backgroundColor: "#888",
      borderRadius: "10px",
      marginRight: "4px",
    },
  },
}));

interface IProps {
  handleSyncForImages:(value:any,value2:any)=>void,
  stageScaleForSync:number,
  zoomCursorPosition:{
    x:number,
    y:number
  },
  disabledDeployButton?: boolean;
  open: boolean;
  handleClose: () => void;
  modelCollectionId?: string;
  architecturesAvailable?: IModelArchitecturesAvailable | [];
  selectedResource: IResourceAnalyticsCollection | null;
  groupAnnotations?: ResourceImageGroupAnnotation[];
  annotations: ResourceImageAnnotation[];
  modelType?: IModelType;
}

function AnnotationsListPopup(props: IProps) {
  const classes = useStyles();
  const dialogRef = useRef<any>(null);
  const [annotationsGroup, setAnnotationsGroup] = useState<ResourceImageGroupAnnotation[]>();

  const onClose = () => {
    props.handleClose();
  };
  const [
    imageWidthForTwoDimensionalImage,
    setImageWidthForTwoDimensionalImage,
  ] = useState(0);
  const [
    imageAnnotationsForTwoDimensionalImage,
    setImageAnnotationsForTwoDimensionalImage,
  ] = useState<ResourceImageAnnotation[]>([]);
  const [annotationNameWhichIsInFocus, setAnnotationNameWhichIsInFocus] =
    useState("");
  const [
    annotationGroupIdsWhichAreExpanded,
    setAnnotationGroupIdsWhichAreExpanded,
  ] = useState<string[]>([]);

  useEffect(() => {
    const executeFunction = async () => {
      let image:
        | {
            width: number;
            height: number;
          }
        | undefined = undefined;

      // let imageUrl: string = props.isSpectrogramAudioContext? (props.selectedResource?.spectrogram||"") : (props?.selectedResource?.resourceUrl || "")
      let imageUrl: string = props?.selectedResource?.resourceUrl || "";

      image = await ImageService.getImageWidthHeightFromURL(imageUrl);
      const imageAspectRatio = image.width / image.height;

      const imageContainerElement = document.getElementById(
        twoDimensionalImageHTMLContainerId
      );

      let imageMaxWidthAllowed = imageContainerElement?.offsetWidth || 400;
      let imageMaxHeightAllowed = imageContainerElement?.offsetHeight || 400;

      let imageWidthToSet = 0;
      let imageHeightToSet = 0;

      if (image.width >= image.height) {
        imageWidthToSet = imageMaxWidthAllowed;
        imageHeightToSet = ImageService.getImageHeight(
          imageWidthToSet,
          imageAspectRatio
        );

        if (imageHeightToSet > imageMaxHeightAllowed) {
          imageHeightToSet = imageMaxHeightAllowed;
          imageWidthToSet = ImageService.getImageWidth(
            imageHeightToSet,
            imageAspectRatio
          );
        }
      } else if (image.height >= image.width) {
        imageHeightToSet = imageMaxHeightAllowed;
        imageWidthToSet = ImageService.getImageWidth(
          imageHeightToSet,
          imageAspectRatio
        );

        if (imageWidthToSet > imageMaxWidthAllowed) {
          imageWidthToSet = imageMaxWidthAllowed;
          imageHeightToSet = ImageService.getImageHeight(
            imageWidthToSet,
            imageAspectRatio
          );
        }
      }

      let imageScaleFactor = getImageScaleFactorForImageWidth(
        image.width,
        imageWidthToSet
      );

      const imageAnnotationsForTwoDimensionalImage =
        setResourceImageAnnotationsForSendingToAnnotationComponent(
          props?.annotations,
          imageScaleFactor
        );

      setImageWidthForTwoDimensionalImage(imageWidthToSet);

      setImageAnnotationsForTwoDimensionalImage([
        ...(imageAnnotationsForTwoDimensionalImage || []),
      ]);
    };

    if (props?.selectedResource?.resourceUrl) {
      executeFunction();
    }
  }, [props.groupAnnotations, props?.selectedResource?.resourceUrl, props?.annotations]);

  useEffect(() => {
    const filteredGroupAnnotations = props?.groupAnnotations?.filter((groupAnnotation) => {
      return groupAnnotation.imageAnnotations?.some(imageAnnotation => 
          props.annotations?.some(annotation => annotation.id === imageAnnotation.id)
            )
    })
    const filteredImageAnnotation = filteredGroupAnnotations?.map(group => ({
      ...group,
      imageAnnotations: group.imageAnnotations.filter(imageAnnotation =>
        props.annotations.some(annotation =>
          imageAnnotation.id === annotation.id
        )
      )
    }))
    setAnnotationsGroup(filteredImageAnnotation);
  }, [props?.groupAnnotations, props?.annotations])

  return (
    <Dialog
      ref={dialogRef}
      onClose={onClose}
      open={props.open}
      maxWidth="lg"
      fullWidth={false}
      classes={{
        paperScrollPaper: classes.dialogContainer,
        paperWidthLg: classes.dialogContainerWidth,
      }}
    >
      <div className={classes.root}>
        <div
          className={classes.closeBtn}
          onClick={() => {
            onClose();
          }}
        >
          <Cancel style={{ fontSize: "40px", padding: "4px" }} />
        </div>

        <div className={classes.body}>
          <div
            id={twoDimensionalImageHTMLContainerId}
            className={classes.imageContainer}
            style={{
              width: "68%",
              height: "100%",
            }}
          >
            <SegmentationAnalyticsTwoDimensionalImage
             handleSyncForImages={props.handleSyncForImages}
             stageScaleForSync={props.stageScaleForSync}
             zoomCursorPosition={props.zoomCursorPosition}
              url={props.selectedResource?.resourceUrl || " "}
              imageWidth={imageWidthForTwoDimensionalImage}
              AnnotationOption={defaultAnnotationOptions}
              focusedName={annotationNameWhichIsInFocus}
              defaultAnnotations={imageAnnotationsForTwoDimensionalImage}
              isDynamicOptionsEnable
              isViewOnlyMode={false}
              isZoomFeatureEnabled={true}
              isLabelOn={true}
            />
          </div>
          
          {props?.annotations?.length > 0 ? <div>
          <div className={classes.annotationsLabelsList}>
            <div style={{ padding: "5px" }}>
                {props.modelType === "imageAnnotationGroup" ? 
              <div className={classes.buttonContainer}>
                {annotationsGroup?.map((groupAnnotation) => {
                  return (
                    <div className="">
                      <div
                        className={classes.annotationGroupNameRow}
                        onClick={() => {
                          setAnnotationGroupIdsWhichAreExpanded((oldState) => {
                            if (oldState.includes(groupAnnotation?.id)) {
                              const newState = oldState.filter(
                                (groupId) => groupId !== groupAnnotation?.id
                              );
                              return [...newState];
                            } else {
                              return [...oldState, groupAnnotation?.id];
                            }
                          });
                        }}
                      >
                        <div>{groupAnnotation.name}</div>
                        <div>
                          {!annotationGroupIdsWhichAreExpanded.includes(
                            groupAnnotation?.id
                          ) ? (
                            <ExpandMoreIcon />
                          ) : (
                            <ExpandLessIcon />
                          )}
                        </div>
                      </div>
                      {annotationGroupIdsWhichAreExpanded.includes(
                        groupAnnotation?.id
                      ) && (
                        <div className="">
                          {groupAnnotation.imageAnnotations?.map(
                            (annotation) => {
                              return (
                                <div>
                                  <Button
                                    variant="contained"
                                    className={classes.bigButton}
                                    style={{
                                      border: `1px solid ${annotation?.color}`,
                                      borderLeft: `5px solid ${annotation?.color}`,
                                    }}
                                    onClick={() => {
                                      setAnnotationNameWhichIsInFocus(
                                        annotation?.name || ""
                                      );
                                    }}
                                  >
                                    <div
                                      style={{
                                        display: "flex",
                                        justifyContent: "space-between",
                                        gap: "7.5rem"
                                      }}
                                    >
                                      <div>
                                        {
                                          annotation?.selectedOptions?.[1]
                                            ?.value
                                        }
                                      </div>
                                      {!isNullOrUndefined(
                                        annotation?.confidenceScore
                                      ) && (
                                        <div
                                          style={{
                                            borderRadius: "1rem",
                                          }}
                                        >
                                          {annotation?.confidenceScore}%
                                        </div>
                                      )}
                                    </div>
                                  </Button>
                                </div>
                              );
                            }
                          )}
                        </div>
                      )}
                    </div>
                  );
                })}
              </div> : 
              <div className={classes.buttonContainer}>
                <div className="">
                          {props.annotations?.map(
                            (annotation) => {
                              return (
                                <div>
                                  <Button
                                    variant="contained"
                                    className={classes.bigButton}
                                    style={{
                                      border: `1px solid ${annotation?.color}`,
                                      borderLeft: `5px solid ${annotation?.color}`,
                                    }}
                                    onClick={() => {
                                      setAnnotationNameWhichIsInFocus(
                                        annotation?.name || ""
                                      );
                                    }}
                                  >
                                    <div
                                      style={{
                                        display: "flex",
                                        justifyContent: "space-between",
                                        gap: "7.5rem"
                                      }}
                                    >
                                      <div>
                                        {
                                          annotation?.selectedOptions?.[1]
                                            ?.value
                                        }
                                      </div>
                                      {!isNullOrUndefined(
                                        annotation?.confidenceScore
                                      ) && (
                                        <div
                                          style={{
                                            borderRadius: "1rem",
                                            marginLeft: "2rem"
                                          }}
                                        >
                                          {annotation?.confidenceScore}%
                                        </div>
                                      )}
                                    </div>
                                  </Button>
                                </div>
                              );
                            }
                          )}
                        </div>
              </div>
              }
            </div>
          </div>
          </div> : <></>}
          
        </div>
      </div>
    </Dialog>
  );
}

export default AnnotationsListPopup;

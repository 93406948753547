import TextFieldsIcon from '@material-ui/icons/TextFields';

type Props = {
  IconComponentProps?: any
}

export function ModelTypeTextIcon(props: Props) {
  return (
    <TextFieldsIcon {...props.IconComponentProps} />
  );
}
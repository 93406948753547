import {
	List,
	ListItem,
	ListItemText,
	makeStyles,
	Tooltip,
} from "@material-ui/core";
import { useCallback, useEffect, useMemo, useState } from "react";
import { generateUniqueId } from "../../../../../../../../../../../services/idHelperService";
import { isNullOrUndefined } from "../../../../../../../../../../../services/variableHelperService";

const useStyles = makeStyles((theme) => ({
	root: {
		border: "none",
		width: "100%",
		height: "24px",
		display: "inline-flex",
		outline: "0px",
		padding: "0px",
		fontSize: "0.8125rem",
		boxSizing: "border-box",
		alignItems: "center",
		fontFamily: "Nunito Sans, sans-serif",
		whiteSpace: "nowrap",
		borderRadius: "50px",
		verticalAlign: "middle",
		justifyContent: "center",
		textDecoration: "none",
		cursor: "default",
		'@media (min-width:1200px)' : {
			width: "116%",
		  },
		  '@media (max-width:1200px)' : {
			width: "100%",
		},
	},
	rootNA: {
		// background:
		// 	"transparent linear-gradient(90deg, #363636 0%, #47586C 100%) 0% 0% no-repeat padding-box",
			backgroundColor: "#717983",
		font: "normal normal bold 13px/22px Nunito Sans",
		letterSpacing: "4px",
		color: "#FFFFFF",
	},
	rootBad: {
		border: "2px solid #FF2366",
		opacity: 0.75,
	},
	rootGood: {
		border: "2px solid #02BC77",
		opacity: 0.75,
	},
	rootNoComparison: {
		border: "1px solid #c6c6c6"
	},
	rootLive: {
		// background: "#008D9C 0% 0% no-repeat padding-box",
		backgroundColor: "#40aab5",
		color: "#FFFFFF",
	},
	tooltip: {
		background: "rgb(61 59 59 / 70%)",
		font: "normal normal bold 13px/22px Nunito Sans",
		color: "#FFFFFF",
		width: "200px",		
	},
	tooltipArrow: {
		"&::before": {
			background: "rgb(61 59 59 / 70%)",
		}
	},
	popover: {
		"& .MuiPaper-root": {
			background: "rgb(61 59 59 / 70%)",
			font: "normal normal bold 13px/22px Nunito Sans",
			color: "#FFFFFF",
			width: "200px",
		},
		"& .MuiListItem-button:hover": {
			background: "rgb(61 59 59 / 70%)",
			font: "normal normal bold 13px/22px Nunito Sans",
			color: "#FFFFFF",
			width: "122px",
		},
	},
	popOverText: {
		font: "normal normal bold 15px/22px Nunito Sans",
		letterSpacing: "0px",
		color: "#FFFFFF",
		cursor: "default"
	},
}));

export type CollectionAccuracyType = "NA" | "Live" | "good" | "bad" | "noComparison";

export function CollectionAccuracy(props: {
	showTotalInTooltip?: boolean,
	isSegmentationModelAnalytics?: boolean,
	segmentationModelAnalyticsAccuracyMetric?: string,
	className?: string;
	type: CollectionAccuracyType;
	accuracyValue: number | undefined;
	jsonData: any;
}) {
	const classes = useStyles();
	const { type, accuracyValue, jsonData } = props;

	const [popoverAnchorElement, setPopoverAnchorElement] =
		useState<HTMLElement | null>(null);

	const popoverElementId = useMemo(() => generateUniqueId(), []);

	const handleMouseEnterEvent = useCallback((e) => {
		setPopoverAnchorElement(e.currentTarget);
	}, []);
	const handleMouseLeaveEvent = useCallback((e) => {
		setPopoverAnchorElement(null);
	}, []);

	const accuracyText = useMemo(() => {
		return type === "NA" || isNullOrUndefined(accuracyValue)
			? "NA"
			: type === "bad" ||
			  type === "good" ||
			  type === "noComparison" ||
			  type === "Live"
			? "" + accuracyValue + " %"
			: "";
	}, [type, accuracyValue]);

	const isPopoverOpen = Boolean(popoverAnchorElement);

	const [selectedMetricsAccuracy, setSelectedMetricsAccuracy] = useState<string| undefined> ()

	useEffect(() => {
		jsonData && Object.keys(jsonData)?.forEach((jsonKey: string) => {
			if(jsonKey === props.segmentationModelAnalyticsAccuracyMetric)
			setSelectedMetricsAccuracy(jsonData[jsonKey])
		})
	}, [])

	console.log("testing", selectedMetricsAccuracy)
	return (
		<div className={props?.className}>
			{props.showTotalInTooltip?
			<Tooltip
				arrow
				interactive
				placement="right"
				classes={{
					tooltip: classes.tooltip,
					arrow: classes.tooltipArrow
				}}
				title={
					jsonData ?
					<div style={{ width: "auto", overflow: "auto", maxHeight: "320px" }}>
						<List>
							{jsonData && Object.keys(jsonData)?.map((jsonKey: string) => {
								return (
									<ListItem button style={{ textAlign: "center", padding: 0 }}>
										<ListItemText
											primary={`${jsonKey}  :  ${typeof(jsonData[jsonKey]) === "object" ? jsonData[jsonKey]?.accuracy : jsonData[jsonKey]}%`}
											classes={{
												primary: classes.popOverText,
											}}
										/>
									</ListItem>
								);
							})}
						</List>
					</div>	
					: ""				
				}
			>
				<div
					className={`
						${classes.root}
						${type === "NA" ? classes.rootNA : ""}
						${type === "bad" ? classes.rootBad : ""}
						${type === "good" ? classes.rootGood : ""}
						${type === "Live" ? classes.rootLive : ""}
						${type === "noComparison" ? classes.rootNoComparison : ""}
					`}
					aria-haspopup="true"
					aria-owns={isPopoverOpen ? popoverElementId : undefined}
					onMouseOver={handleMouseEnterEvent} 
					onMouseOut={handleMouseLeaveEvent}				
				>
					{accuracyText !== "NA" ?  `${parseFloat(parseFloat(props.isSegmentationModelAnalytics === true && selectedMetricsAccuracy ? selectedMetricsAccuracy : accuracyText).toFixed(2))}%` : accuracyText}
				</div>

			</Tooltip>
			:
				<div
					className={`
						${classes.root}
						${type === "NA" ? classes.rootNA : ""}
						${type === "bad" ? classes.rootBad : ""}
						${type === "good" ? classes.rootGood : ""}
						${type === "Live" ? classes.rootLive : ""}
						${type === "noComparison" ? classes.rootNoComparison : ""}
					`}
					aria-haspopup="true"
					aria-owns={isPopoverOpen ? popoverElementId : undefined}
					onMouseOver={handleMouseEnterEvent}
					onMouseOut={handleMouseLeaveEvent}
				>
					{accuracyText !== "NA" ? `${parseFloat(parseFloat(props.isSegmentationModelAnalytics === true && selectedMetricsAccuracy ? selectedMetricsAccuracy : accuracyText).toFixed(2))}%` : accuracyText}
				</div>
			}
			{/* {false && jsonData && (
				<Popover
					open={isPopoverOpen}
					style={{
						pointerEvents: "none",
					}}
					id={popoverElementId}
					anchorEl={popoverAnchorElement}
					className={classes.popover}
					anchorOrigin={{
						vertical: "top",
						horizontal: "right",
					}}
					transformOrigin={{
						vertical: "top",
						horizontal: "left",
					}}
					onClose={handleMouseLeaveEvent}
					transitionDuration={"auto"}
					disableRestoreFocus
				>
					<div style={{ width: "auto" }}>
						<List>
							{Object.keys(jsonData)?.map((jsonKey: string) => {
								return (
									<ListItem button style={{ textAlign: "center", padding: 0 }}>
										<ListItemText
											primary={`${jsonKey}  :  ${jsonData[jsonKey]}%`}
											classes={{
												primary: classes.popOverText,
											}}
										/>
									</ListItem>
								);
							})}
						</List>
					</div>
				</Popover>
			)} */}
		</div>
	);
}

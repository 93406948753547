import { FormControl, InputBase, MenuItem, Radio, Select, Typography } from '@material-ui/core';
import { SearchOutlined } from '@material-ui/icons';

interface IProps {
    selectedValue: any; // Replace `any` with the type of the value
    items: any[];
    onChange: (event: React.ChangeEvent<{ value: unknown }>) => void;
    placeholder?: string;
    valueKey?: string; // Key to access value in item
    displayNameKey?: string; // Key to access display name in item
    disabled?: boolean;
    isItemsLoading?: boolean;
    searchBarVisible?: boolean;
    searchTerm?: string;
    setSearchTerm?: (searchTerm: string) => void;
}

/**
 *can be used to render a dropdown with radio buttons, supports both string values or object values.
 For object type values, following params are needed:
 @param {string} valueKey - the key inside the object that represents the unoque identifier for the obj
 @param {string} displayNameKey - the key inside the object that represents the name to be displayed in the dropdown or in selected field
*/
const DropdownWithRadio = ({
    selectedValue,
    items,
    onChange,
    placeholder = 'Select',
    valueKey,
    displayNameKey,
    disabled,
    isItemsLoading,
    searchBarVisible = false,
    searchTerm = '',
    setSearchTerm,
}: IProps) => {

    const valueType = typeof(items[0] || '')

    const handleSearchChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        onChange(event)
        if(setSearchTerm){
            setSearchTerm(event.target.value)
        }
    }

    // filter items based on the search term
    const filteredItems = searchTerm ? items.filter(item => {
        if(valueType === 'string'){
            return item.toLowerCase().includes(searchTerm.toLowerCase())
        }
        else {
            //@ts-ignore
            return item?.[displayNameKey]?.toString().toLowerCase().includes(searchTerm.toLowerCase())
        }
    }) : items

    return (
        <FormControl variant="outlined" fullWidth >
            {/* <InputLabel>{placeholder}</InputLabel> */}
            <Select
                fullWidth
                style={{ borderRadius: '12px', overflow: 'hidden' }}
                SelectDisplayProps={{ style: { paddingTop: 10, paddingBottom: 10 } }}
                value={selectedValue}
                onChange={onChange}
                renderValue={(selected) => {
                    let selectedItem;
                    if(valueType === 'string'){
                        selectedItem = items.find(item => item === selected);
                    }
                    else {
                        //@ts-ignore
                        selectedItem = items.find(item => item?.[valueKey] === selected?.[valueKey]);
                    }
                    if(selectedItem){
                        if(valueType === 'string'){
                            return selectedItem
                        }
                        else return selectedItem[displayNameKey || '']
                    }
                    else return placeholder
                }}
                MenuProps={{
                    getContentAnchorEl: null,
                    anchorOrigin: {
                        vertical: 'bottom',
                        horizontal: 'left',
                    },
                    transformOrigin: {
                        vertical: 'top',
                        horizontal: 'left',
                    },
                    // TransitionComponent: (props) => <Fade {...props} timeout={100} />,
                    PaperProps: {
                        style: { borderRadius: '12px', overflowY: 'auto', maxHeight: 300 },
                    },
                    MenuListProps: {
                        style: { paddingTop: 0 }
                    },
                    onKeyDown: (event) => {
                        event.stopPropagation();
                    },
                    onEntered: () => {
                        document.getElementById("search-input")?.focus();
                    }
                }}
                disabled={disabled}
            >
                {
                    searchBarVisible && <MenuItem disabled style={{backgroundColor: '#E5E7EB', padding: '8px 14px', borderBottom: 'lightgrey solid 1px'}} >
                        <InputBase
                            autoFocus
                            placeholder="Search..."
                            onBlur={e => {e.target.focus()}}
                            onKeyDown={(e) => {e.stopPropagation()}}
                            value={searchTerm}
                            onChange={handleSearchChange}
                            style={{width: '100%', borderRadius: 8, backgroundColor: 'white', border: 'solid 1px lightgrey', padding: '0 10px'}}
                            endAdornment={<SearchOutlined style={{color: 'grey'}} />}
                        />
                        </MenuItem>
                    // </MenuItem>
                }
                {!isItemsLoading && filteredItems.map((item, index) => (
                    <MenuItem key={index} value={item}
                        autoFocus={false}
                        tabIndex={-1}
                        onMouseDown={(e) => {
                            e.preventDefault();
                        }}
                        >
                        <Radio
                            tabIndex={-1}
                            checked={ valueType === 'object' ? item?.[valueKey || ''] === selectedValue?.[valueKey || ''] : item === selectedValue}
                            style={{ marginRight: 8, padding: 0 }}
                            size='small'
                        />
                        {
                            valueType === 'string' ?
                                item
                                : item[displayNameKey || '']
                        }
                    </MenuItem>
                ))}
                {
                    isItemsLoading && <Typography style={{marginLeft: '1rem', marginTop: '1rem'}}>Loading...</Typography>
                }
                {
                    !isItemsLoading && filteredItems.length === 0 && <Typography style={{marginLeft: '1rem', marginTop: '1rem'}}>No such item...</Typography>
                }
            </Select>
        </FormControl>
    );
}

export default DropdownWithRadio
import { useEffect, useState, useContext } from 'react'
import './VerticalProgressBar.css'
import { stateValues, doneStateList, pendingStateList } from '../constant'
import StateElement from '../StateElement/StateElement'
import ChatContext from '../../../../../chat/contexts/chat.context'
import { isNullOrUndefined } from '../../../../../../../../services/variableHelperService'

type props = {
    stateValue : string
}

function VerticalProgressBar({stateValue} : props) {
    const chatContext = useContext(ChatContext);
    const [stateIndex, setStateIndex] = useState(0)
    const [stateTime, setStateTime] = useState(()=>{
        const arr = new Array(5);
        for(let i=0; i<5; i++) arr[i] = null;
        return arr;
    })

    useEffect(() => {

        if(!isNullOrUndefined(stateValues[stateValue])) setStateIndex(stateValues[stateValue][1]);

        setStateTime(arr=>{
            let idx = stateValues[stateValue]?.[1]

            if(doneStateList.includes(stateValue)) chatContext.updateTestDriveStage(1, (idx+1)*20);
            else chatContext.updateTestDriveStage(1, idx*20)

            for(let i=0; i<=idx; i++) {
                if(arr[i] === null)
                {
                    const now = new Date();
                    let hours = now.getHours();
                    const minutes = now.getMinutes();

                    const amPm = hours >= 12 ? 'PM' : 'AM';
                    hours = hours % 12 || 12;

                    const formattedMinutes = minutes.toString().padStart(2, '0');

                    arr[i]= `${hours}:${formattedMinutes} ${amPm}`;
                }
            }
            return arr;
        })

    }, [stateValue])

    const correspondingValue = (index : number, element : string) => {
        return (index < stateIndex) ? stateValues[element]?.[0]
        : (index === stateIndex) ? stateValue
        : pendingStateList[index]
    }
    
    return (
        <div className="state__body">
            <div className='state__stick'>
                {<div className='done' style={{height: `${25*stateIndex}%`}} />}
                {<div className='pending' style={{height: `${25*(4-stateIndex)}%`}} />}
            </div>
            {doneStateList.map((element, index) =>
                <StateElement 
                    key={index} 
                    value={correspondingValue(index, element)} 
                    eleIdx={index} 
                    currIdx={stateIndex} 
                    time={stateTime[index]}
                 />
            )}
        </div>
    )
}

export default VerticalProgressBar
import React, { useCallback, useEffect, useRef, useState } from 'react'
import { IData, IResourceAnalyticsCollection } from '../../../../../../../../../../../common';
// @ts-ignore
import WaveSurfer from "wavesurfer.js/dist/wavesurfer.min";
// @ts-ignore
import WaveSurferRegionPlugin from "wavesurfer.js/dist/plugin/wavesurfer.regions.min";
// @ts-ignore
import WaveSurferTimelinePlugin from "wavesurfer.js/dist/plugin/wavesurfer.timeline.min";
import Tooltip from '@material-ui/core/Tooltip';
import PlayCircleFilledIcon from "@material-ui/icons/PlayCircleFilled";
import PauseCircleFilledIcon from "@material-ui/icons/PauseCircleFilled";
import { makeStyles } from '@material-ui/core';
const useStyles = makeStyles((theme) => ({
    playPauseButtonOfOriginalAudio: {
        width: "40px",
        height: "40px",
        marginRight: "11px",
        cursor: "pointer"
    },
    trimmedAudiosTableHeadCell: {
        fontWeight: 600,
        // backgroundColor: "#f5f5f5"
    },
    trimmedAudiosTableCellCheckbox: {
        width: "20px"
    },
    trimmedAudiosTableCellAnnotationColor: {
        width: "158px"
    },
    trimmedAudiosTableCellStartTimeColor: {
        width: "72px"
    },
    trimmedAudiosTableCellEndTimeColor: {
        width: "67px"
    },
    trimmedAudiosTableCellActions: {
        width: "122px"
    },
    trimAudioTableRowDisabled: {
        opacity: 0.4,
        pointerEvents: "none"
    },
    trimmedAudiosTableDivContainer: {
        height: "calc(100% - 176px)"
    },
    trimmedAudiosTableDivContainerWhenUnsaveWaarningSignNotShowing: {
        height: "calc(100% - 124px)"
    }
}));

const AudioWaveFormUI = (props: { resourceUrl: string, setTotalTimePlaying?: React.Dispatch<React.SetStateAction<number>>, setCurrentTimePlaying?: React.Dispatch<React.SetStateAction<number>> }) => {
    const classes = useStyles();

    const [originalAudioCurrentTime, setOriginalAudioCurrentTime] = useState(0);
    const [originalAudioTotalTime, setOriginalAudioTotalTime] = useState(0);
    const wavesurferRef = useRef<any>(null);
    const arrBufferRef = useRef<any>(null);
    const audioBufferRef = useRef<any>(null);
    const [isResourceAudioPlaying, setIsResourceAudioPlaying] = useState(false);

    const isWaveFormHTMLElementPresentInDom = useCallback(
        () => {
            const htmlElement = document.getElementById("waveform");
            if (htmlElement) {
                return true;
            }
            return false;
        },
        [],
    );
    const timeInterval = (pxPerSec: number) => {
        let timeInterval = wavesurferRef.current.getDuration() * 0.01;
        // timeInterval = Number(timeInterval.toFixed(2));
        return timeInterval;
    }

    useEffect(() => {
        if(props.setCurrentTimePlaying) {
            props.setCurrentTimePlaying(originalAudioCurrentTime)
        }
    }, [originalAudioCurrentTime])

    useEffect(() => {
        if(props.setTotalTimePlaying) {
            props.setTotalTimePlaying(originalAudioTotalTime)
        }
    }, [originalAudioTotalTime])


    const formatTimeCallback = (seconds: number, pxPerSec: number) => {
        seconds = Number(seconds);
        let minutes = Math.floor(seconds / 60);
        seconds = seconds % 60;

        // fill up seconds with zeroes
        let secondsStr = Math.round(seconds).toString();
        if (pxPerSec >= 25 * 10) {
            secondsStr = seconds.toFixed(2);
        } else if (pxPerSec >= 25 * 1) {
            secondsStr = seconds.toFixed(1);
        }

        if (minutes > 0) {
            if (seconds < 10) {
                secondsStr = '0' + secondsStr;
            }
            return `${minutes}:${secondsStr}`;
        }

        return secondsStr;
    }

    const readAudio = useCallback((file) => {
        return new Promise((resolve, reject) => {
            var reader = new FileReader();
            reader.readAsArrayBuffer(file);

            //Resolve if audio gets loaded
            reader.onload = function () {
                console.log("Audio Loaded");
                resolve(reader);
            };

            reader.onerror = function (error) {
                console.log("Error while reading audio");
                reject(error);
            };

            reader.onabort = function (abort) {
                console.log("Aborted");
                console.log(abort);
                reject(abort);
            };
        });
    }, []);


    const readAndDecodeAudio = useCallback(
        async (audioBlob) => {
            //Read the original Audio
            await readAudio(audioBlob)
                .then((results: any) => {
                    arrBufferRef.current = results.result;
                })
                .catch((error: any) => {
                    window.alert("Some Error occured");
                    return;
                });

            //Decode the original Audio into audioBuffer
            await new AudioContext()
                .decodeAudioData(arrBufferRef.current)
                .then((res) => {
                    audioBufferRef.current = res;
                })
                .catch((err) => {
                    window.alert("Can't decode Audio");
                    return;
                });
        },
        [readAudio]
    );

    const loadAudio =
        async (resource: string) => {

            setOriginalAudioCurrentTime(0.00);
            setOriginalAudioTotalTime(0.00);

            const audioDownloadURL = props.resourceUrl;

            const audioBlobPromise = new Promise(async (resolve, reject) => {
                try {
                    const apiResponse = await fetch(audioDownloadURL);
                    const apiBlobResponse = apiResponse.blob();
                    // resolve( new File([apiBlobResponse], resourceObj.filename));
                    resolve(apiBlobResponse);
                } catch (error: any) {
                    reject(new Error(error.message));
                }
            });

            const audioFile = await audioBlobPromise;

            if (wavesurferRef.current) {
                wavesurferRef.current.destroy();
            }

            if (!isWaveFormHTMLElementPresentInDom()) {
                return;
            }

            wavesurferRef.current = WaveSurfer.create({
                container: "#waveform",
                waveColor: "#d4e8e9",
                progressColor: "#008d9c",
                responsive: true,
                barWidth: 3,
                barRadius: 3,
                cursorWidth: 1,
                height: 100,
                barGap: 3,
                plugins: [
                    WaveSurferRegionPlugin.create({}),
                    WaveSurferTimelinePlugin.create({
                        container: "#waveform-timeline",
                        formatTimeCallback: formatTimeCallback,     // It is used for formatting the timeline notches
                        timeInterval: timeInterval,                 // It is the gap between the secondaryLabelInterval (Note: To get the primaryLabelInterval multiple the timeInterval with 5)
                        // primaryLabelInterval: 5,      // It tells after how much secondary bars primary bar is required
                        secondaryLabelInterval: 10,    // No of secondary bars needed inbetween primary bars 
                    })
                ],
            });

            wavesurferRef.current.on("ready", function () {
                readAndDecodeAudio(audioFile);
                const totalAudioDuration = wavesurferRef.current.getDuration();

                setOriginalAudioTotalTime(totalAudioDuration.toFixed(1));

                const dragSelectionOptions: {
                    maxLength?: number
                } = {
                }

                wavesurferRef.current.enableDragSelection(dragSelectionOptions);

            });
            wavesurferRef.current.on("finish", function () {
                setIsResourceAudioPlaying(false);
            });
            wavesurferRef.current.loadBlob(audioFile)
            wavesurferRef.current.on("audioprocess", function () {
                if (wavesurferRef.current.isPlaying()) {
                    var currentTime = wavesurferRef.current.getCurrentTime();

                    // @ts-ignore
                    setOriginalAudioCurrentTime(currentTime.toFixed(1));
                }
            });
            wavesurferRef.current.on("region-created", function (newRegion: any) {
                return;
            });
            wavesurferRef.current.on("region-update-end", function (newRegion: any) {
                return;
            });
        }

    useEffect(() => {
        if (props.resourceUrl) {
            loadAudio(props.resourceUrl);
        }
    }, [props.resourceUrl]);

    return (
        <>
            <div className="w3-container"
                style={{
                    height: "100%",
                    width: "100%"
                }}
            >
                <br />
                <div
                    id="waveform"
                    className="w3-border w3-round-large"
                    data-step="3"
                    data-intro="Click and drag to select section"
                ></div>
                <div id="waveform-timeline"></div>
                <br />
                <div className="w3-row">
                    <div className="w3-half w3-container w3-hide" id="audio-buttons">
                        {!isResourceAudioPlaying ? (
                            <Tooltip title="Play the audio">
                                <PlayCircleFilledIcon
                                    color="primary"
                                    className={classes.playPauseButtonOfOriginalAudio}
                                    onClick={() => {
                                        setIsResourceAudioPlaying(true);
                                        wavesurferRef.current.play();
                                    }}
                                />
                            </Tooltip>
                        ) : (
                            <Tooltip title="Pause the audio">
                                <PauseCircleFilledIcon
                                    color="primary"
                                    className={classes.playPauseButtonOfOriginalAudio}
                                    onClick={() => {
                                        setIsResourceAudioPlaying(false);
                                        wavesurferRef.current.pause();
                                    }}
                                />
                            </Tooltip>
                        )}
                        <b id="time-current">{originalAudioCurrentTime}</b> / <b id="time-total">{originalAudioTotalTime}</b>

                    </div>
                </div>
            </div>
        </>
    );
}

export default AudioWaveFormUI
// FilterModal.tsx
import React, { useEffect, useState } from 'react';
import {
    Modal,
    Paper,
    Typography,
    Button,
    FormGroup,
    FormControlLabel,
    Checkbox,
    TextField,
    List,
    ListItem,
    Collapse,
    Radio,
    RadioGroup,
    Popper,
    Popover,
    Chip,
    InputAdornment
} from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import SearchIcon from '@material-ui/icons/Search';
import { ExpandLess, ExpandMore } from '@material-ui/icons';
import { tasksData } from '../../projects/features/models/features/model/features/collections/aboutModel/features/ProblemType';

const useStyles = makeStyles((theme) => ({
    modal: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
    },
    paper: {
        width: 350,
        padding: theme.spacing(2),
        backgroundColor: '#fff',
        borderRadius: 8,
        overflowY: 'auto',
    },
    popoverPaper: {
        '&::-webkit-scrollbar': {
            width: '8px',
        },
        '&::-webkit-scrollbar-track': {
            background: '#F0F2F4', /* Background of the scrollbar track */
            borderRadius: '4px',
        },
        '&::-webkit-scrollbar-thumb': {
            backgroundColor: '#5C6A7F', /* Color of the scrollbar thumb */
            borderRadius: '4px',
            border: '2px solid #F0F2F4', /* Padding around thumb for better aesthetics */
        },
        '&::-webkit-scrollbar-thumb:hover': {
            backgroundColor: '#4B5563', /* Darker color on hover */
        },
    },
    filterTitle: {
        color: theme.palette.primary.main,
    },
    applyButton: {
        marginTop: theme.spacing(2),
        borderColor: theme.palette.primary.main,
        textTransform: "capitalize"
    },
    specificChip: {
        borderRadius: '22px',
        background: '#E9EFFA',
        padding: '3.5px 5.6px',
        color: "#2264CD",
        fontFamily:"Inter",
        fontWeight:600
    },
    generalChip: {
        borderRadius: '22px',
        padding: '3.5px 5.6px',
        border: '0px solid #59BB9E',
        background: 'rgba(89, 187, 158, 0.12)',
        color: '#59BB9E',
        fontFamily:"Inter",
        fontWeight:600
    },
    collapsePadding: {
        paddingLeft: 0,
    },
    searchField: {
        // height: '2.5rem',  
        borderRadius: '6px 6px 0px 0px',
        padding:'10.5px 17.5px',
        backgroundColor:'#F9FAFB',
        borderColor:'#D1D5DB',
        '& .MuiOutlinedInput-input': {
            padding: '10.5px 8px 10.5px 10.5px', 
        },
        marginTop:'0.5rem',
        boxShadow: '0px 2px 12px 0px rgba(0, 0, 0, 0.10)',
        borderBottom: '1px solid #E5E7EB'
    },
    searchItemsContainer:{
        boxShadow: '0px 2px 12px 0px rgba(0, 0, 0, 0.10)',
        padding: '10.5px 17.5px'
    }
}));

type ProblemTypeKey = 'Computer Vision' | 'Natural Language Processing' | 'Audio';

interface FilterModalProps {
    open: boolean;
    onClose: () => void;
    onApply: (filters: { purpose: { all: boolean; general: boolean; specific: boolean }; problemType: string }) => void; 
    buttonPosition?: HTMLElement | null;
    onClearFilters?: ()=>void;
    params: {
        purpose: { all: boolean; general: boolean; specific: boolean };
        problemType: string;
    };
}

const FilterModal: React.FC<FilterModalProps> = ({ open, onClose, onApply,buttonPosition ,params}) => {
    const classes = useStyles();

    // States for filter options
    const [purpose, setPurpose] = useState(params.purpose);
    const [selectedProblemType, setSelectedProblemType] = useState(params.problemType);
    const [searchTerm, setSearchTerm] = useState('');
    const initialExpandedState = tasksData.reduce((acc, task) => {
        acc[task.aiTask] = false; // Initially, all sections are collapsed
        return acc;
    }, {} as { [key: string]: boolean });   
    const [expanded, setExpanded] = useState<{ [key: string]: boolean }>(initialExpandedState);

    useEffect(() => {
        if (open) {
            setPurpose(params.purpose);
            setSelectedProblemType(params.problemType);
            setSearchTerm('')
        }
    }, [open, params.purpose, params.problemType]);

    const handlePurposeChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        // setPurpose({ ...purpose, [event.target.name]: event.target.checked });
        const { name, checked } = event.target;

        if (name == 'all') {
            setPurpose({
                all: checked,
                general: checked,
                specific: checked,
            });
        } else {
            setPurpose((prevPurpose) => {
                const newPurpose = {
                    ...prevPurpose,
                    [name]: checked,
                    // "all" should only be true if both "general" and "specific" are true
                    all: checked && prevPurpose[name === 'general' ? 'specific' : 'general'],
                };
                return newPurpose;
            });
        }
    };

    const handleExpandClick = (type: string) => {
        setExpanded((prev) => ({ ...prev, [type]: !prev[type] }));
    };

    const handleSearchChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        setSearchTerm(event.target.value);
    };
    const handleProblemTypeSelection = (type: string) => {
        setSelectedProblemType(type);
    };

    const handleApply = () => {
        onApply({ purpose, problemType: selectedProblemType }); // Pass selected filters to parent
        onClose(); // Close the modal
    };
    const handleClearFilter = () =>{
        setSearchTerm('')
        // onClearFilters()
        onApply({ purpose:{ all: true, general: true, specific: true }, problemType: "ALL" });
        onClose();
    }
    const filteredProblemTypes = tasksData.reduce((result, task) => {
        const matches = task.problemTypes.filter((item) =>
            item.toLowerCase().includes(searchTerm.toLowerCase())
        );
        if (matches.length > 0) {
            result[task.aiTask] = matches;
        }
        return result;
    }, {} as { [key: string]: string[] });
    return (
        <Popover open={open} anchorEl={buttonPosition ? buttonPosition : null} anchorOrigin={{
            vertical: 'bottom',
            horizontal: 'left',
          }} onClose={onClose} style={{zIndex:2}}>
            <Paper className={`${classes.paper} ${classes.popoverPaper}`}>
                <Typography variant="h6" className={classes.filterTitle}>
                    Filter
                </Typography>

                {/* Purpose Filter Section */}
                <Typography variant="subtitle1" style={{color:'#707070'}}>Purpose</Typography>
                <FormGroup style={{ flexDirection: 'column' }}>
                    {['all', 'general', 'specific'].map((key) => (
                        <FormControlLabel
                            key={key}
                            control={
                                <Checkbox
                                    checked={purpose[key as keyof typeof purpose]}
                                    onChange={handlePurposeChange}
                                    name={key}
                                />
                            }
                            label={
                                (key === 'general' || key === 'specific') ? (
                                    <Chip
                                      label={key.charAt(0).toUpperCase() + key.slice(1)}
                                      className={key === 'general' ? classes.generalChip : classes.specificChip}
                                    />
                                  ) : (
                                    key.charAt(0).toUpperCase() + key.slice(1)
                                  )
                            }
                        />
                    ))}
                </FormGroup>

                {/* Problem Type Filter Section */}
                <Typography variant="subtitle1" style={{color:'#707070', marginTop:'2rem'}}>Problem Type</Typography>
                <div style={{ display: 'flex', alignItems: 'center'}}>
                    <TextField
                        placeholder="Search"
                        value={searchTerm}
                        onChange={handleSearchChange}
                        variant="outlined"
                        className={classes.searchField}
                        fullWidth
                        InputProps={{
                            endAdornment: (
                                <InputAdornment position="end">
                                <SearchIcon style={{color:'#6B7280'}}/>
                                </InputAdornment>
                            ),
                        }}
                    />
                </div>
                <RadioGroup value={selectedProblemType} onChange={(e) => handleProblemTypeSelection(e.target.value)} className={classes.searchItemsContainer}>
                    <FormControlLabel value="ALL" control={<Radio />} label="All" />
                    {Object.keys(filteredProblemTypes).map((category) => (
                        <React.Fragment key={category}>
                            <ListItem button onClick={() => handleExpandClick(category)} style={{paddingLeft:'0px'}}>
                                {expanded[category] ? <ExpandLess /> : <ExpandMore />}
                                <strong style={{ marginLeft: 8 ,whiteSpace:'nowrap'}}>{category} {tasksData.find(task => task.aiTask === category)?.problemTypes.includes(selectedProblemType) ? (<span style={{ color: 'green' }}>(Selected)</span>) : ''}</strong>
                            </ListItem>
                            <Collapse in={expanded[category]} timeout="auto" unmountOnExit className={classes.collapsePadding}>
                                <List component="div" disablePadding>
                                    {filteredProblemTypes[category].map((option) => (
                                        <ListItem key={option} button>
                                            <FormControlLabel
                                                value={option}
                                                control={<Radio />}
                                                label={option}
                                            />
                                        </ListItem>
                                    ))}
                                </List>
                            </Collapse>
                        </React.Fragment>
                    ))}
                </RadioGroup>
                {/* Apply Filter Button */}
                <Button
                    variant="outlined"
                    color="primary"
                    className={classes.applyButton}
                    fullWidth
                    onClick={handleApply}
                >
                    Apply Filter
                </Button>
                {/* Clear Filter Button */}
                <Button
                    variant="outlined"
                    style={{color:'red',borderColor:'red'}}
                    className={classes.applyButton}
                    fullWidth
                    onClick={handleClearFilter}
                >
                    Clear Filter
                </Button>
            </Paper>
        </Popover>
    );
};

export default FilterModal;
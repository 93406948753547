import { useCallback } from "react";
import { useHistory } from "react-router";

export default function useDeleteUrlSearchParams() {
  const history = useHistory();

  const historyReplace = history.replace;
  const historyPush = history.push;

  const deleteUrlSearchParams = useCallback(
    (
      queryParamKeysToDelete: string[],
      historySetStyle: "historyPush" | "historyReplace"
    ) => {
      const urlSearchParams = new URLSearchParams(window.location.search || "");

      for (const queryParamKeyToDelete of queryParamKeysToDelete) {
        urlSearchParams.delete(queryParamKeyToDelete);
      }

      let searchValueToSet = `?${urlSearchParams.toString()}`;

      if (historySetStyle === "historyPush") {
        historyPush({
          search: searchValueToSet,
        });
      } else if (historySetStyle === "historyReplace") {
        historyReplace({
          search: searchValueToSet,
        });
      }
    },
    [historyPush, historyReplace]
  );

  return deleteUrlSearchParams;
}

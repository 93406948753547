import { AxiosPromise, AxiosResponse } from "axios";
import { UserEmailId, UserPassword } from "../common/constants/interfaces/user";

import { API } from "./API";
import { IPurposeModel, analysisFile, updatePurposeModelAPIRequestPayload } from "../common/constants/interfaces/purposeModels";
import { ISessionCreationProgress, queryObjectToString, IVmHardwareSessionStatus, IVmApiData } from "../common";
import { tabsCreatorHasSeenPayload } from "../features/admin/features/projects/features/models/features/model/constants/hardcodedConstants";

const URL_STRINGS = {
  PURPOSE_MODEL: "purposeModel",
};

export type ComputePowerType = "no gpu" | "low" | "medium" | "high" | "ultra" | "turbo";

export interface IDockerImageOption {
  description: string,
  display_name: string,
  image: string
}
interface DockerImageListForDeployment {
  dropdown: Array<IDockerImageOption>
}

interface IZonesAndGPUsAPIResponse {
  error: string;
  message: string;
  status: "success";
  response: {
    custom_configurations: {
      [key: string]: Array<ComputePowerType>;
    },
    rlef_configurations: {
      [key: string]: Array<ComputePowerType>;
    }
  }
}

class PurposeModelService extends API {

  async getUserSelectionsData(userId: string, projectId: string, populateMappedModels: boolean = false): Promise<any> {
    const apiResponse = await this.api.get(`${URL_STRINGS.PURPOSE_MODEL}/${userId}/${projectId}${populateMappedModels ? "?populateMappedModels=true" : ""}`)
    return apiResponse.data
  }

  isProblemTypeApplicableForImageAnnotationComparisonViewInModelAnalytics(problemType: IPurposeModel["problemType"] | undefined): boolean {
    if (!problemType) {
      return false;
    }

    if (
      problemType === "Segmentation" ||
      problemType === "Detection"
    ) {
      return true;
    }

    return false;
  }

  startPurposeModelDataMappingCodeExecutionVM() {
    return this.api.post(`${URL_STRINGS.PURPOSE_MODEL}/startPurposeModelDataMappingCodeExecutionVM`)
  }

  async getPurposeModelData(purposeModelId: string, populateMappedModels: boolean = false): Promise<any> {
    const apiResponse = await this.api.get(`${URL_STRINGS.PURPOSE_MODEL}/getPurposeModelById?purposeModelId=${purposeModelId}${populateMappedModels ? "&populateMappedModels=true" : ""}`)
    return apiResponse.data
  }

  async getAllPurposeModelsCreatedByAnUser(userId: string, projectId: string): Promise<any> {
    const apiResponse = await this.api.get(`${URL_STRINGS.PURPOSE_MODEL}/getAllPurposeModelsCreatedByAnUser?userId=${userId}&projectId=${projectId}`)
    return apiResponse.data
  }

  async getAllPurposeModelNames(type?:"general" | "specific"){
    const apiResponse = await this.api.get(`${URL_STRINGS.PURPOSE_MODEL}${type?"?type="+type:""}`)
    return apiResponse.data
  }

  async getAllPurposeModels(projectId?: string){
    const apiResponse = await this.api.get(`${URL_STRINGS.PURPOSE_MODEL}${projectId?"?projectId="+projectId : ""}`)
    return apiResponse.data
  }

  async putUpdatedSelections(requestBody: any): Promise<any> {
    const apiResponse = await this.api.put(`${URL_STRINGS.PURPOSE_MODEL}`, requestBody)
    return apiResponse.data
  }

  async deployTrainingPodAndSendStatus(requestBody: any): Promise<any> {
    const apiResponse = await this.api.post(`${URL_STRINGS.PURPOSE_MODEL}/deploy`, requestBody)
    return apiResponse.data
  }

async checkUniqueModelName(requestBody:{modelName:string}): Promise<any> {
    try {
        console.log(requestBody);
        const apiResponse = await this.api.post(`${URL_STRINGS.PURPOSE_MODEL}/checkUniqueModelName`, requestBody);
        return apiResponse.data;
    } catch (error) {
        console.error('Error checking uniqueness:', error);
        throw error; // Throw the error so it can be caught by the caller
    }
}
async latestDevelopmentStatus(requestBody:{purpose_model_id:string}): Promise<any> {
 
        const apiResponse = await this.api.post(`${URL_STRINGS.PURPOSE_MODEL}/latestDevelopmentStatus`, requestBody);
        return apiResponse.data;
   
}

  async postNewRecordInCollection(requestBody: any): Promise<any> {
    const apiResponse = await this.api.post(`${URL_STRINGS.PURPOSE_MODEL}`, requestBody)
    return apiResponse.data
  }
  async copyPurposeModelData(requestBody: any): Promise<any> {
    
    const apiResponse = await this.api.put(`${URL_STRINGS.PURPOSE_MODEL}/copyPurposeModelData`, requestBody)
    return apiResponse.data
  }
  
  async fileHandle(requestBody: FormData): Promise<any> {
    const apiResponse = await this.api.post(`${URL_STRINGS.PURPOSE_MODEL}/fileUpload`, requestBody)
    return apiResponse.data
  }

  async deleteModelWeightsFile(requestBody: any): Promise<any> {
    const apiResponse = await this.api.put(`${URL_STRINGS.PURPOSE_MODEL}/deleteModelWeightsFile`, requestBody)
    return apiResponse.data
  }

  async fileHandleGlobal(requestBody: FormData): Promise<any> {
    const apiResponse = await this.api.post(`${URL_STRINGS.PURPOSE_MODEL}/globalPackageUpload`, requestBody)
    return apiResponse.data
  }

  async deleteGlobalPackageFile(requestBody: any): Promise<any> {
    const apiResponse = await this.api.put(`${URL_STRINGS.PURPOSE_MODEL}/deleteglobalPackageFile`, requestBody)
    return apiResponse.data
  }
  
  async fileHandleGcpCredentialsJsonForDeployment(requestBody: FormData): Promise<any> {
    const apiResponse = await this.api.post(`${URL_STRINGS.PURPOSE_MODEL}/gcpCredentialsJsonForDeploymentUpload`, requestBody)
    return apiResponse.data
  }

  async deleteGcpCredentialsJsonForDeploymentFile(requestBody: any): Promise<any> {
    const apiResponse = await this.api.put(`${URL_STRINGS.PURPOSE_MODEL}/deleteGcpCredentialsJsonForDeploymentFile`, requestBody)
    return apiResponse.data
  }
  async deleteTrainingPod(requestBody: any): Promise<any> {
    const apiResponse = await this.api.put(`${URL_STRINGS.PURPOSE_MODEL}/deleteTrainingPod`, requestBody)
    return apiResponse.data
  }
  
  async updatePurposeModel(requestBody: updatePurposeModelAPIRequestPayload): Promise<any> {
    const apiResponse = await this.api.put(`${URL_STRINGS.PURPOSE_MODEL}/`, requestBody)
    return apiResponse.data
  }

  async getModelCode(requestBody: any): Promise<any> {
    const apiResponse = await this.api.post(`${URL_STRINGS.PURPOSE_MODEL}/getModelCode`, requestBody)
    return apiResponse.data
  }

  async saveCurrentTabSeenByUser(payload: tabsCreatorHasSeenPayload): Promise<any> {
    const apiResponse = await this.api.put(`${URL_STRINGS.PURPOSE_MODEL}/updateTabsCreatorHasSeen`, payload)
    return apiResponse.data
  }

  async getMappedModelsByPurposeId(purposeId: string): Promise<any> {
    const apiReponse = await this.api.get(`${URL_STRINGS.PURPOSE_MODEL}/getMappedModels/${purposeId}`)
    return apiReponse.data
  }
  async updatePurposeModelLibrariesRequirementFile(reqPayLoad: FormData): Promise<any> {
    const apiReponse = await this.api.put(`${URL_STRINGS.PURPOSE_MODEL}/librariesRequirementFile`, reqPayLoad)
    return apiReponse.data
  }
  async addEmailIds(requestBody: {
    purpose_model_id: string,
    deploymentCompletionEmailRecepients?: string[],
    deploymentCompletionEmailCCRecepients?: string[]
  }): Promise<any> {
    const apiReponse = await this.api.post(`${URL_STRINGS.PURPOSE_MODEL}/addEmailIds`, requestBody)
    return apiReponse.data
  }
  async updateDeploymentStatus(requestBody: {
    purpose_model_id: string,
    status:string
  }): Promise<any> {
    const apiReponse = await this.api.post(`${URL_STRINGS.PURPOSE_MODEL}/updateDeploymentStatus`, requestBody)
    return apiReponse.data
  }
  //   async isLoggedInUserAccessTokenValid(): Promise<{isValidAccessToken: boolean}> {
  //     const apiResponse = await this.api.get(`${URL_STRINGS.USER}/isValidAccessToken`);
  //     return apiResponse.data;
  //   }

  async getPurposeModelByModelName(requestBody: any): Promise<any> {
    const apiReponse = await this.api.post(`${URL_STRINGS.PURPOSE_MODEL}/getPurposeModelByModelName`, requestBody)
    return apiReponse.data
  }

  async fetchAnalysisFiles(requestBody: {purposeModelIds:string, getAnalysisFileDownloadURL: boolean}): Promise<analysisFile[]> {
    
    
    const purposeModelIdsString = requestBody.purposeModelIds;
    
    const url = `${URL_STRINGS.PURPOSE_MODEL}/analysisData?purposeModelIds=${purposeModelIdsString}&getAnalysisFileDownloadURL=${requestBody.getAnalysisFileDownloadURL}`;
  
    const apiResponse = await this.api.get(url);
    return apiResponse.data;

  }

  async downloadCodeBase(requestBody: any, purposeModelId: string): Promise<any> {
    const apiReponse = await this.api.post(`${URL_STRINGS.PURPOSE_MODEL}/${purposeModelId}/downloadCodeZipFile`, requestBody)
    return apiReponse
  }

  async suspend(purposeModelId: string): Promise<any> {
    const apiReponse = await this.api.post(`${URL_STRINGS.PURPOSE_MODEL}/${purposeModelId}/suspendTrainingPod`)
    return apiReponse
  }

  async startTrainingPod(purposeModelId: string): Promise<any> {
    const apiReponse = await this.api.post(`${URL_STRINGS.PURPOSE_MODEL}/${purposeModelId}/resumeTrainingPod`)
        return apiReponse
  }

  async getRealtimeLoadingStatus(reqPayload: {
    purposeModelId: string;
    tabSection: "rlef_to_model" | "training_with_one_epoch" | "model_to_rlef";
    sceneria?: "code" | "test"
}): Promise<any> {
    const apiReponse = await this.api.get(`${URL_STRINGS.PURPOSE_MODEL}/getRealtimeLoadingStatus?tabSection=${reqPayload.tabSection}&purposeModelId=${reqPayload.purposeModelId}&sceneria=${reqPayload.sceneria}`)
    return apiReponse
  }

  async fetchDockerImagesListForDeployment(): Promise<DockerImageListForDeployment> {
    // const url = `${RLEF_TRAINING_POD_GENERATION_DOMAIN}/docker_dropdown`
    const url = `${URL_STRINGS.PURPOSE_MODEL}/docker_dropdown`
    const res = await this.api.get(url)
    return res.data
  }

  async fetchZonesAndGPUsForDeployment(params: {gcs_auth_service_acc_filepath: string}, abortSignal?: AbortSignal): Promise<IZonesAndGPUsAPIResponse> {
    // const url = `${RLEF_TRAINING_POD_GENERATION_DOMAIN}/get_all_locations_and_supported_gpus_in_gcp`
    const url = `${URL_STRINGS.PURPOSE_MODEL}/get_all_locations_and_supported_gpus_in_gcp`
    const res = await this.api.post(url, params, {
      //@ts-ignore
      signal: abortSignal
    })
    return res.data;
  }

  async fetchExistingUniqueTag(): Promise<{ tags: string []}> {
    const url = `${URL_STRINGS.PURPOSE_MODEL}/getAllUniqueTags`
    const res = await this.api.get(url)
    return res.data
  }

  async startTestSession(
    purposeModelId: string,
    requestBody: FormData
  ): Promise<any> {
    const url = `${URL_STRINGS.PURPOSE_MODEL}/${purposeModelId}/startTestSession`;
    const res = await this.api.post(url, requestBody)
    return res.data
  }

  async stopTestSession(
    purposeModelId: string
  ): Promise<any> {
    const url = `${URL_STRINGS.PURPOSE_MODEL}/${purposeModelId}/stopTestSession`;
    const res = await this.api.post(url)
    return res.data
  }

  async getTestSessionStatus(
    purposeModelId: string,
  ): Promise<any> {
    const url = `${URL_STRINGS.PURPOSE_MODEL}/getTestSessionStatus?purposeModelId=${purposeModelId}`;
    const res = await this.api.get(url)
    return res.data
  }

  async getRealtimeStartSessionStatus(
    purposeModelId: string,
  ): Promise<ISessionCreationProgress []> {
    const url = `${URL_STRINGS.PURPOSE_MODEL}/realtimeStartSessionStatus?purposeModelId=${purposeModelId}`;
    const res = await this.api.get(url)
    return res.data
  }

  async getVmHardwareSessionStatus(
    purposeModelId: string,
  ): Promise<IVmHardwareSessionStatus> {
    const url = `${URL_STRINGS.PURPOSE_MODEL}/vmHardwareSessionStatus/?purposeModelId=${purposeModelId}`;
    const res = await this.api.get(url)
    return res.data.data
  }
  
  cloneTrainingPod(payload : { 
    purposeModelId: string, cloneInProjectId: string, cloneInTaskId: string, clonedPurposeModelName: string
  }) {
    return this.api.post(`${URL_STRINGS.PURPOSE_MODEL}/clone`, payload)
  }

  async getComputePowerTable(
  ): Promise<IVmApiData> {
    const url = `${URL_STRINGS.PURPOSE_MODEL}/computePowerTable`;
    const res = await this.api.get(url)
    return res?.data?.vm_configurations
  }
  
  async getComputeEngineDeploymentConfigurations(): Promise<IVmApiData> {
    const url = `${URL_STRINGS.PURPOSE_MODEL}/computeEngineDeploymentConfigurations`;
    const res = await this.api.get(url)
    return res?.data?.vm_configurations
  }

  //   async isLoggedInUserAccessTokenValid(): Promise<{isValidAccessToken: boolean}> {
  //     const apiResponse = await this.api.get(`${URL_STRINGS.USER}/isValidAccessToken`);
  //     return apiResponse.data;
  //   }


  //   async generateLoggedInUserNewAccessToken(): Promise<{accessToken: string}> {
  //     const apiResponse = await this.api.post(`${URL_STRINGS.USER}/loggedInUserNewAccessToken`)
  //     return apiResponse.data
  //   }

  //   async updateViewPreferencesInDB(data: { modelViewPreferences: "model" | "modelGroup" }): Promise<void> {
  //     const apiResponse = await this.api.patch(`${URL_STRINGS.USER}/viewPreferences`, data);
  //     return apiResponse.data;
  //   }

  //   async getViewPreferencesInDB(): Promise<{ modelViewPreferences: "model" | "modelGroup" }> {
  //     const apiResponse = await this.api.get(`${URL_STRINGS.USER}/viewPreferences`);
  //     return apiResponse.data;
  //   }

  //   async getLoggedInUserEmail(): Promise<string> {
  //     const apiResponse = await this.api.get(`${URL_STRINGS.USER}/getLoggedInUserEmail`)
  //     return apiResponse.data
  //   }

  //   async getLoggedInUserIdWithEmail(emailId: string): Promise<string> {
  //     const apiResponse = await this.api.get(`${URL_STRINGS.USER}/getLoggedInUserIdWithEmail/${emailId}`)
  //     return apiResponse.data
  //   }
}

export default new PurposeModelService();

import { isNullOrUndefined } from '../../../../../../../../../../../../services/variableHelperService';

type Color = String;
type ColorsUsage = {
  [color: string]: {
    usageCount: number
  }
}

export class UniqueColorPicker {

  private colors: Color[];

  private colorsUsage: ColorsUsage = {};
  
  constructor(colors: Color[]) {
    this.colors = colors;
    this.initializeColorsUsage(this.colors);
  }

  private initializeColorsUsage(colors: Color[]) {
    this.colorsUsage = {};
    for (const color of colors) {
      // @ts-ignore
      this.colorsUsage[color] = {
        usageCount: 0
      }
    }
  }

  resetColorUsageCount() {
    for (const color in this.colorsUsage) {
      if (Object.prototype.hasOwnProperty.call(this.colorsUsage, color)) {
        const usageCount = this.colorsUsage[color];
        this.colorsUsage[color] = {usageCount: 0};
      }
    }
  }

  getLeastUsedColor(params?: {
    shouldNotAutomaticallyIncrementColorUsage?: boolean
  }): string {
    let leastUsedColor: {
      color: string | undefined,
      count: number | undefined
    } = {
      color: undefined,
      count: undefined
    };
    for (const color in this.colorsUsage) {
      if (Object.prototype.hasOwnProperty.call(this.colorsUsage, color)) {
        const colorUsage = this.colorsUsage[color];
        if (
          isNullOrUndefined(leastUsedColor.color) ||
          // @ts-ignore
          colorUsage.usageCount < leastUsedColor.count
        ) {
          leastUsedColor.color = color;
          leastUsedColor.count = colorUsage.usageCount
        }
      }
    }

    const colorToReturn = leastUsedColor.color;

    if (params?.shouldNotAutomaticallyIncrementColorUsage !== true) {
      this.incrementColorUsageCount(colorToReturn || "");
    }
 
    return colorToReturn || "";
  }

  incrementColorUsageCount(color: Color) {
    // @ts-ignore
    if (this.colorsUsage?.[color]) {
      // @ts-ignore
      this.colorsUsage[color].usageCount += 1;
    }
  }

  decrementColorUsageCount(color: Color) {
    // @ts-ignore
    if (this.colorsUsage[color].usageCount > 0) {
      // @ts-ignore
      this.colorsUsage[color].usageCount -= 1;    
    }
  }
}


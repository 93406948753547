import {
  Box,
  Button,
  Popover,
  Slider,
  Switch,
  Table,
  TableCell,
  TableHead,
  TableRow,
  Theme,
  createStyles,
  makeStyles,
} from "@material-ui/core";
import {
  GetIntegrityAPIModelCollectionsAdditionalDataQueryParamType,
  IIntegrity,
} from "../../../../../../../../../../../common";
import { useEffect, useRef } from "react";
import { IntegrityFilterForModelCollection, URLQueryParams } from "./integrity";
import useSetUrlSearchParamsOfCurrentRoute from "../../../../../../../../../../../hooks/useSetUrlSearchParamsOfCurrentRoute";

type Props = {
  onClose: () => void;
  modelCollectionsAdditionalData: GetIntegrityAPIModelCollectionsAdditionalDataQueryParamType[];
  modelCollectionAdditionalDataFilter: IntegrityFilterForModelCollection[];
  setModelCollectionAdditionalDataFilter: React.Dispatch<
    React.SetStateAction<IntegrityFilterForModelCollection[]>
  >;
  integrity: IIntegrity[];
};

export const INTEGRITY_PAGE_FILTER_ICON_BUTTON_ELEMENT_ID =
  "integrityPageFilterIcon";

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      flexGrow: 1,
    },
    paper: {
      height: 140,
      width: 100,
    },
    control: {
      padding: theme.spacing(2),
    },
    topPerformaingModel: {
      height: "140px",
      overflow: "hidden",
    },
    tableCell: {
      // font: "normal normal normal 600 11px/15px Nunito Sans",
      // fontSize: "11px",
      textAlign: "center",
      color: "#141414",
      padding: "8px 4px",
      "@media (min-width:1500px)": {
        font: "normal normal normal 600 13px/15px Nunito Sans",
      },
      "@media (max-width:1500px) and (min-width:1000px)": {
        font: "normal normal normal 600 13px/15px Nunito Sans",
      },
      "@media (max-width:1000px)": {
        font: "normal normal normal 600 12px/15px Nunito Sans",
      },
    },
    tableCellTitle: {
      textAlign: "left",
      // font: "normal normal normal 600 11px/15px Nunito Sans",
      color: "#838C95",
      opacity: 1,
      padding: "0px 4px 0px 12px",
      width: "120px",
      "@media (min-width:1500px)": {
        font: "normal normal normal 600 12px/15px Nunito Sans",
      },
      "@media (max-width:1500px) and (min-width:1000px)": {
        font: "normal normal normal 600 13px/15px Nunito Sans",
      },
      "@media (max-width:1000px)": {
        font: "normal normal normal 600 12px/15px Nunito Sans",
      },
    },
    heading: {
      height: "40px",
      textAlign: "left",
      font: "normal normal 600 13px/15px Nunito Sans",
      letterSpacing: "0.32px",
      color: "#1A1A1A",
      opacity: 1,
      padding: "0px 0 0px 12px",
      margin: "0px",
      fontWeight: 600,
      display: "flex",
      flexDirection: "row",
      alignItems: "center",
    },
    tableContainer: {
      position: "relative",
      height: "calc(100% - 47.5px)",
    },
    table: {
      // height: "100%"
    },
    tr: {
      height: "44px",
    },
    accuracyModelClass: {
      height: "24px",
      width: "50px",
      margin: "0 auto",
      fontSize: "9px",
      color: "#191919",

      "& div": {
        // fontSize: "9px",
        // font: "normal normal 600 9px/12px Nunito Sans",
        color: "#191919",
        opacity: "unset",
        "@media (min-width:1500px)": {
          font: "normal normal 600 12px/15px Nunito Sans",
        },
        "@media (max-width:1500px) and (min-width:1000px)": {
          font: "normal normal 600 11px/15px Nunito Sans",
        },
        "@media (max-width:1000px)": {
          font: "normal normal 600 10px/15px Nunito Sans",
        },
      },
    },
    liveAccuracyModelClass: {
      height: "24px",
      width: "50px",
      margin: "0 auto",
      fontSize: "9px",
      color: "#191919",

      "& div": {
        // fontSize: "9px",
        // font: "normal normal 600 9px/12px Nunito Sans",
        // color: "#191919",
        opacity: "unset",
        "@media (min-width:1500px)": {
          font: "normal normal 600 12px/15px Nunito Sans",
        },
        "@media (max-width:1500px) and (min-width:1000px)": {
          font: "normal normal 600 11px/15px Nunito Sans",
        },
        "@media (max-width:1000px)": {
          font: "normal normal 600 10px/15px Nunito Sans",
        },
      },
    },
    popOverTableCell: {
      //   display: "flex",
      flexDirection: "row",
      alignItems: "center",
      gap: 20,
      paddingRight: "60px",
    },
  })
);

export default function IntegrityPageFilterPopup(props: Props) {
  const {
    setModelCollectionAdditionalDataFilter,
    integrity,
    modelCollectionAdditionalDataFilter,
    modelCollectionsAdditionalData,
  } = props;

  const classes = useStyles();

  const setUrlSearchParamsOfCurrentRoute =
    useSetUrlSearchParamsOfCurrentRoute();

  const setUrlSearchParamsOfCurrentRouteRef = useRef(
    setUrlSearchParamsOfCurrentRoute
  );
  setUrlSearchParamsOfCurrentRouteRef.current =
    setUrlSearchParamsOfCurrentRoute;

  useEffect(() => {
    const filtersToSet: IntegrityFilterForModelCollection[] = [];
    for (const modelCollection of integrity) {
      filtersToSet.push({
        modelCollectionId: modelCollection._id,
        modelVersion: modelCollection.version,
        iouThreshold:
          modelCollectionsAdditionalData?.find(
            (modelCollectionAdditionalData) =>
              modelCollectionAdditionalData.modelCollectionId ===
              modelCollection._id
          )?.iouThreshold || 0,
      });
    }
    setModelCollectionAdditionalDataFilter([...filtersToSet]);
  }, [
    integrity,
    modelCollectionsAdditionalData,
    setModelCollectionAdditionalDataFilter,
  ]);

  return (
    <Popover
      id="integrity-page-filter-popover-menu"
      anchorEl={document.getElementById(
        INTEGRITY_PAGE_FILTER_ICON_BUTTON_ELEMENT_ID
      )}
      keepMounted
      anchorOrigin={{
        vertical: "bottom",
        horizontal: "right",
      }}
      transformOrigin={{
        vertical: "top",
        horizontal: "right",
      }}
      open={true}
      onClose={props.onClose}
      PaperProps={{
        style: {
          width: "60%",
        },
      }}
    >
      {
        <Box id="integrityPageFilterMenuItemContainer" style={{ padding: 15 }}>
          <div>
            <Table>
              <TableHead>
                <TableRow>
                  <TableCell>
                    <strong>Model</strong>
                  </TableCell>
                  {/* <TableCell>
                      <strong>
                        Confidence Score threshold
                      </strong>
                    </TableCell> */}
                  <TableCell>
                    <strong>IOU Score threshold</strong>
                  </TableCell>
                </TableRow>
              </TableHead>
              {modelCollectionAdditionalDataFilter?.map(
                (modelCollection, index: number) => {
                  return (
                    <TableRow>
                      <TableCell>{modelCollection.modelVersion}</TableCell>
                      {/* <TableCell className={classes.popOverTableCell}>
                          <Slider
                            value={
                                modelCollection?.confidenceScoreThreshold
                            }
                            min={0}
                            max={100}
                            color="primary"
                            step={1}
                            marks={[
                              { value: 0, label: "0%" },
                              { value: 50, label: "50%" },
                              { value: 100, label: "100%" },
                            ]}

                            // @ts-ignore
                            onChange={(e, value: number) => {
                                setModelCollectionAdditionalDataFilter(oldState=>{
                                    for (const modelCollectionToSet of oldState) {
                                        if (modelCollectionToSet.modelCollectionId === modelCollection.modelCollectionId) {
                                            modelCollectionToSet.confidenceScoreThreshold = value;
                                            break;
                                        }
                                    }
                                    return [...oldState]
                                })
                            }}

                            valueLabelDisplay="auto"
                            aria-labelledby="range-slider"
                          />
                        </TableCell> */}
                      <TableCell className={classes.popOverTableCell}>
                        <Slider
                          value={modelCollection?.iouThreshold}
                          min={0}
                          max={1}
                          color="primary"
                          step={0.1}
                          marks={[
                            { value: 0, label: "0" },
                            { value: 0.5, label: "0.5" },
                            { value: 1, label: "1" },
                          ]}
                          // @ts-ignore
                          onChange={(e, value: number) => {
                            setModelCollectionAdditionalDataFilter(
                              (oldState) => {
                                for (const modelCollectionToSet of oldState) {
                                  if (
                                    modelCollectionToSet.modelCollectionId ===
                                    modelCollection.modelCollectionId
                                  ) {
                                    modelCollectionToSet.iouThreshold = value;
                                    break;
                                  }
                                }
                                return [...oldState];
                              }
                            );
                          }}
                          valueLabelDisplay="auto"
                          aria-labelledby="range-slider"
                        />
                      </TableCell>
                    </TableRow>
                  );
                }
              )}
            </Table>
          </div>

          <div
            id="resetFilterButtonContainer"
            style={{
              marginTop: "40px",
              display: "flex",
              justifyContent: "flex-end",
              gap: 20,
            }}
          >
            <div></div>
            <Button
              variant="outlined"
              color="primary"
              onClick={() => {
                props.onClose();
              }}
            >
              Cancel
            </Button>
            <Button
              variant="contained"
              color="primary"
              onClick={() => {
                setUrlSearchParamsOfCurrentRouteRef.current(
                  {
                    [URLQueryParams.MODEL_COLLECTIONS_ADDITIONAL_DATA]:
                      JSON.stringify(modelCollectionAdditionalDataFilter),
                  },
                  "historyReplace"
                );
                props.onClose();
              }}
            >
              Apply
            </Button>
          </div>
        </Box>
      }
    </Popover>
  );
}

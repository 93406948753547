import { Dialog, DialogActions, DialogContent, DialogTitle, Button, makeStyles, Box } from "@material-ui/core";
import ReportProblemIcon from "@material-ui/icons/ReportProblem";
import { Cancel } from "@material-ui/icons";


const useStyles = makeStyles((theme) => ({
    root: {

    },
    dialogContent: {
        maxHeight: "350px",
        overflowY: "auto",
        marginBottom: "10px"
    },
    dialogActions: {
        justifyContent: "space-between",
        padding: "24px"
    },
    closeBtn: {
        position: "absolute",
        right: -10,
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        top: -14,
        backgroundColor: "white",
        borderRadius: "50%",
        cursor: "pointer",
        width: "25px",
        height: "25px"
    },
    cancelIcon: {
        fontSize: "40px", 
        padding: "4px"
    },
    dialogContainer: {
        overflow: 'visible'
    },
    ReportProblemIconColorAndMargin: {
        marginRight: "10px", 
        marginBottom: "5px",
        color: "#f5c609"
    }
}));



interface IProps {
    modelId: string,
    modelName: string,
    open: boolean,
    close: Function,
    deleteModel: Function,
    isDeleteHappening: boolean
    typeofCard?: string
}


const DeleteModelConfirmPopup = (props: IProps) => {
    const classes = useStyles();
    const { typeofCard } = props;
    return (
        <Dialog
            open={props.open}
            onClose={() => props.close()}
            aria-labelledby="delete-model-confirm-popup"
            aria-describedby= "delete-model-confirm"
            classes={{ paperScrollPaper: classes.dialogContainer }}
        >
            <DialogTitle id="delete-model-confirm" classes={{ root: classes.root }}>
                <ReportProblemIcon className={classes.ReportProblemIconColorAndMargin} />
                {typeofCard === "copilot" ? "Delete Copilot" : "Delete Task"}
                <Box className={classes.closeBtn} onClick={() => { props.close() }} >
                    <Cancel className={classes.cancelIcon} />
                </Box>
            </DialogTitle>

            <DialogContent classes={{ root: classes.dialogContent }}>
                <span>
                    { typeofCard === "copilot" ? <span>Are you sure you want to delete Copilot : <span style={{ "fontWeight": "bold" }}>{props.modelName}</span>?</span>
                    : <span>Are you sure you want to delete Task : <span style={{ "fontWeight": "bold" }}>{props.modelName}</span>?</span>
                    }
                </span>
            </DialogContent>

            <DialogActions classes={{ root: classes.dialogActions }}>
                <Button onClick={() => props.close()} autoFocus>
                    Cancel
                </Button>
                <Button variant="contained" onClick={() => props.deleteModel()} color="primary" autoFocus>
                    OK
                </Button>
            </DialogActions>
        </Dialog>
    );
};

export default DeleteModelConfirmPopup;
import './Main.css'
import VerticalProgressBar from '../VerticalProgressBar/VerticalProgressBar'
import Timer from '../Timer/Timer'
import { useCallback, useContext, useEffect, useState } from 'react'
import testDriveService from '../../../../../projects/features/aiMarketPlace/services/testDriveService'
import {stateValues} from '../constant'
import ChatContext from '../../../../../chat/contexts/chat.context'
import useAuth from '../../../../../../../../hooks/useAuth'

type props = {
    requestId : string
}

function Main({requestId}:props) {

    const [state, setState] = useState("Initiating request")
    const [failedRequest, setFailedRequest] = useState(0)
    const [_failedAPiCount, setFailedApiCount] = useState(0);
    const {getResultsForTestDriveAnalysis} = useContext(ChatContext)

    const fetchResponseAndUpdateState = useCallback(async() : Promise<Boolean> => {
        let continueApiCall = true;
        try {
            const { data } = await testDriveService.fetchCurrentProgressState({params : { requestIds: JSON.stringify([requestId])}})
            const responseState = data.data[0].processingStatusMetaData.processingStatus;
            let validValueFlag = 0;
            for (const key in stateValues) {
                if(key === responseState)
                {
                    validValueFlag =1;
                    break;
                }
            }
            
            if(validValueFlag===0) 
            {
                if(responseState === 'Failed') {
                    setFailedRequest(1)
                    continueApiCall = false;
                }
            }
            else {
                let responseSplit = responseState.split(' ')
                if(responseSplit[responseSplit.length-1] === 'Failed') {
                    setFailedApiCount(res => {
                        if(res === 2) {
                            continueApiCall = false;
                            return 0;
                        }
                        return res+1;
                    })
                }
                else setFailedApiCount(0);
    
                if(responseState === 'Uploaded' ) {
                    continueApiCall = false;
                    getResultsForTestDriveAnalysis()
                } 

                setState(responseState)
            }
        } catch (error) {
            setFailedApiCount(res => {
                if(res === 2) {
                    continueApiCall = false;
                    return 0;
                }
                return res+1;
            })
        }
        return continueApiCall;
    }, []) 

    useEffect(()=>{
        let timeout : NodeJS.Timeout
        const executeApiTimeout = async () => {
            const timeoutFlag = await fetchResponseAndUpdateState()
            if(timeoutFlag) timeout = setTimeout(executeApiTimeout, 2 * 1000)
        }
        executeApiTimeout()
        return () => clearTimeout(timeout)
    }, [])

    return (
        <div className='layout'>
            {
                failedRequest ? 
                <div style={{width: '100%', textAlign: 'center', color: 'red', fontSize: '30px'}}>Process Failed</div> :
                <>
                    <VerticalProgressBar stateValue={state} />
                    <div className='ruler' />
                    <Timer stateValue={state} />
                </>
            }
            
        </div>
    )
}

export default Main
import { makeStyles, Paper, Tab, Tabs  } from '@material-ui/core';
import React, { useEffect, useState } from 'react';
import { connect, ConnectedProps } from 'react-redux';
import { useLocation, Link } from 'react-router-dom';

import { IReduxState, useQuery } from '../../../../../../../../../../common';
import { collectionSelectedTab, deleteCollection, deleteModelCollection, getCollections, hideAnalyticsTab, setCollectionsTabActive, setSelectedDataSetCollection, setSideDrawer, setTopActionButton, showAnalyticsTab } from '../../../../../../../../../../store/actions';
import AddDataSetCollection from './components/AddDataSetCollection';
import ModelAnalytics from '../../../../components/Analytics';
import { deleteButtonIconColor } from '../data/constants/constants';
import CollectionRoutes from './CollectionRoutes';
import { useSelector, useDispatch } from 'react-redux'

export const ModelCollectionsTabElementId = "modelCollectionsTab";

export enum CollectionQueryParam {
    OFFSET="offset",
    LIMIT="limit",
    MODEL_ID="model",
    MODEL_TYPE="type",
    PROJECT_ID="project",
    STATUS="status"
  }

const useStyles = makeStyles((theme) => ({
    paper: {
        width: '100%',
    },
    table: {
        minWidth: 750,
    },
    chip: {
        minWidth: '100px',
        backgroundColor: theme.palette.grey[50],
        marginRight: theme.spacing(1),
    },
    deleteButton: {
        color: deleteButtonIconColor
    },
    tableContainer: {
        overflowY: "scroll",
        // maxHeight: 450, height: 450,
    },
    root: {
        marginBottom: theme.spacing(2),
        position: "sticky",
        top: "0px",
        backgroundColor: "#f0f5fc",
        zIndex: 1,
    },   
    indicator: {
        backgroundColor: 'transparent'
    },     
}));
const collectionTabList = [
    // { label: 'Data Set', path: '/data-set', status: 'pending', show:true },
    { label: 'Training', path: '/model', status: 'approved', show:true },
    { label: 'Testing', path: '/testModel', status: 'test', show:true },
    { label: 'Model Analytics', path: '/modelAnalytics/dashboard', status: 'dashboard', show:false },
    { label: 'Model Analytics', path: '/modelAnalytics/integrity', status: 'dashboard', show:false },
    { label: 'Model Analytics', path: '/modelAnalytics/IndividualClassAccuracy', status: 'dashboard', show: false },
    { label: 'Model Analytics', path: '/modelAnalytics/resources', status: 'analytics', show: false }
]
function Collections(props: TPropsFromRedux) {
    // const state = useSelector((state: any) => state)
    const disableAnalyticsDashboardTab = useSelector((state: any) => state.updateDashboardAnalyticTabState?.showAnalyticsTab)
    const selectedCollectionLabel = useSelector((state: any) => state.updateDashboardAnalyticTabState?.selectedTab)
    const [selectedTab, setSelectedTab] = useState<string>('/model')
    const classes = useStyles();
    const location = useLocation();
    const { pathname } = location;
    // const [rowsPerPage, setRowsPerPage] = useState(10);
    // const [page, setPage] = useState(0);

    const [collectionTabs, setCollectionTabs] = useState([...collectionTabList])

    const query = useQuery();
    const dispatch = useDispatch();
    const { getCollections, setSideDrawer, setTopActionButton, setCollectionsTabActive } = props;

    useEffect(() => {
        setCollectionsTabActive()
    }, [])

    useEffect(() => {
        setTopActionButton({ type: 'addCollection', onClickComponentToRender: AddDataSetCollection });
        setSideDrawer({ type: 'projectAnalytics', component: ModelAnalytics, isOpen: false });
    }, []);

   // const selectedTab = collectionTabs.find(item => pathname.includes(item.path))?.path;    
    const rootPath = pathname.split(selectedTab || '')[0].substring(0, pathname.indexOf('collections') + 'collections'.length);

    useEffect(() => {
        if(!disableAnalyticsDashboardTab) {
            setCollectionTabs([...collectionTabList ])
        } else {
            setCollectionTabs([...collectionTabList,
            { label: 'Model Analytics', path: '/modelAnalytics/dashboard', status: 'dashboard', show:true }
        ])
        }
    }, [disableAnalyticsDashboardTab])

    useEffect(() => {
        if(!(selectedTab === "/modelAnalytics/dashboard")) {
            dispatch(hideAnalyticsTab())
        } else {
            dispatch(showAnalyticsTab())
            setSideDrawer({ type: 'hideSideDrawer', component: ModelAnalytics, isOpen: false });
        }
    }, [selectedTab])

    useEffect(() => {
         const selectedTab :any= collectionTabs.find(item => item.label === selectedCollectionLabel)?.path    
        setSelectedTab(selectedTab)
    }, [selectedCollectionLabel])


    useEffect(() => {
        const selectedTab :any= collectionTabs.findIndex(item => item.path.split('/').pop() === pathname.split('/').pop()) 
        dispatch(collectionSelectedTab(collectionTabList[selectedTab]?.label))
    },[pathname])
    return (
        <>
            <Paper className={classes.paper} elevation={0} square >
                <Tabs classes={{ root: classes.root, indicator: classes.indicator }}  textColor="primary" value={selectedTab}>
                    {
                        collectionTabs.map(({ label, path, status, show }) => {
                            const newQuery = new URLSearchParams('');
                            newQuery.set(CollectionQueryParam.STATUS, status);
                            newQuery.set(CollectionQueryParam.OFFSET, '1');
                            newQuery.set(CollectionQueryParam.LIMIT, '10');
                            newQuery.set(CollectionQueryParam.MODEL_ID, query.get(CollectionQueryParam.MODEL_ID) || '');
                            newQuery.set(CollectionQueryParam.MODEL_TYPE, query.get(CollectionQueryParam.MODEL_TYPE) || '');
                            newQuery.set(CollectionQueryParam.PROJECT_ID, query.get(CollectionQueryParam.PROJECT_ID) || '');
                            return show && <Tab
                            onClick={() => dispatch(collectionSelectedTab(label))}
                            label={label}
                            id={label === "Model" ? ModelCollectionsTabElementId : ""}
                            key={label}
                            component={Link}
                            to={`${rootPath}${path}?${newQuery.toString()}`}
                            value={path} />
                        })
                    }
                </Tabs>            
            </Paper>
            <CollectionRoutes />
        </>
    );
};

const mapProps = (state: IReduxState) => {
    return {
        collections: state.collection.collections,
        isFetching: state.collection.isFetching,
        selectedType: state.optionsDrawer.type,
        selected: state.collection.selected,
        // isCollectionAddSuccess: state.collection.isAddSuccess,
        // isCollectionDeleteSuccess: state.collection.isDeleteSuccess
    };
}

const connector = connect(mapProps, { getCollections, setSideDrawer, setSelectedCollection: setSelectedDataSetCollection, setTopActionButton, deleteCollection, setCollectionsTabActive, deleteModelCollection,  });

export type TPropsFromRedux = ConnectedProps<typeof connector>

export default connector(Collections);

import { AxiosResponse } from "axios";
import { queryObjectToString } from "../common";
import { API } from "./API";

const URL_STRINGS = {
	CLOUDSTORAGE: "cloudStorage",
};

class CloudStorageService extends API {
	getResourceBucketFileDownloadURL(data: {
		storageFileName: string;
		projectId?: string;
	}): Promise<AxiosResponse<string>> {
		return this.api.get(
			`${URL_STRINGS.CLOUDSTORAGE}/resourceBucketFileURL?${queryObjectToString(
				data
			)}`
		);
	}

	async getResourceBucketJsonFileData(data: {
		storageFileName: string;
		projectId: string;
	}): Promise<Object> {
		const apiResponse = await this.api.get(
			`${URL_STRINGS.CLOUDSTORAGE}/resourceBucketJsonFileData?${queryObjectToString(
				data
			)}`
		) ;
		return apiResponse.data;
	}

	async getCloudStorageBucketName(projectId: string): Promise<string> {
		const url = `${URL_STRINGS.CLOUDSTORAGE}/getStorageBucketName/${projectId}`
		const res = await this.api.get(url);
		return res.data;
	}
}

export default new CloudStorageService();

import React, { useState, useRef, useEffect, useContext } from 'react';
import { Box, Grid, Typography, IconButton, Link } from '@material-ui/core';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import { useDispatch } from "react-redux";
import homepageCss from './RLEF_homepage.module.css';
import { makeStyles } from '@material-ui/core/styles';
import { ReactComponent as ProfileSvg } from '../../../../assets/svg/profile.svg'
import { ReactComponent as FilterIcon } from '../../../../assets/svg/filterIcon.svg'
import ImportExportIcon from '@material-ui/icons/ImportExport';
import CloseIcon from '@material-ui/icons/Close';
import MenuIcon from '@material-ui/icons/Menu';
import { useHistory, useLocation } from 'react-router';
import UserDBService from '../../../../services/UserDBService';
import {ReactComponent as RLEFLogo} from '../../../../assets/svg/rlef-logo.svg'
import { getLatestModels, getModelCollectionsByID, getPopularModels, insertMessageInChatHistory } from '../projects/features/aiMarketPlace/aiMarketPlaceServices';
import { IGetModelCollectionResponse, ILastestModelQueryParams, ILatestModel, IPopularModel, IPopularModelQueryParams, IMessage, aiResponsePreviewPageType, IAIresponseMetaData } from '../projects/features/aiMarketPlace/aiMarketPlaceInterfaces/interfaces';
import { ShimmerPlaceholder } from './components/ShimmerPlaceHolder';
import DataService from '../../../../services/DataService';
import useDebounce from '../../../../hooks/useDebounce';
import useCheckUserLoginValidity from '../../../../hooks/useCheckUserLoginValidity';
import FilterModel from './components/FilterModel';
import ClickAwayListener from '@material-ui/core/ClickAwayListener';
import HomepageModelsCard from './components/HomepageModelsCard'
import { v4 as uuidv4 } from 'uuid';
import { getChatHistoryAPIService, getFormattedDataOfTrainingPodToSendToAi, LLM_STUDIO_CHAT_ASSISTANT_NAME, LLM_STUDIO_CHAT_WEB_SOCKET_URL, LLM_STUDIO_ORG_NAME, LLM_STUDIO_SELECTED_AI_MODEL_NAME, LLM_STUDIO_USER_ID, resetChatHistoryService } from '../chat/services/llmStudioChat.service';
import useAuth from '../../../../hooks/useAuth';
import { startChatStream } from '@e-llm-studio/streaming-response';
import HomePageChatPreviewWindow from './components/HomepageChatPreviewWindow';
import StickySearchContainer from './components/StickySearchContainer';
import RlefSearchComponent from './components/RlefSearchComponent';
import ChatContext from '../chat/contexts/chat.context';
import TestDatasetPreview from './components/TestDatasetPreview';
import gif from '../../../../assets/gif/AI_Results_section_Gif.gif'
import { promptToSendAlongWithUserMessageToGiveAiContextAboutSelectedModelInUi } from '../chat/services/llmStudioPrompt.service';
import { useDebouncedCallback } from './helpers/useDebouncedCallback';
import Split from 'react-split-grid'
import { replaceAsteriskInAnchor } from './helpers/commonFunctions';
import { GuestSessionIdService } from './guestSessionIdService';
import { getAssistantObj } from '@e-llm-studio/streaming-response';
import { configureRlefUiLibrary } from 'rlef-ui-library';
import { APP_API, LLM_STUDIO_CENTRAL_ASSISTANT_NAME, LLM_STUDIO_MODEL_RECOMMENDATION_ASSISTANT_NAME, LLM_STUDIO_MODEL_TEST_DRIVE_RESULTS_ANALYSIS_ASSISTANT_NAME } from '../../../../services/constants';
import { generateUniqueId } from '../../../../services/idHelperService';
import { modelRecommendationPayload } from '../chat/services/llmStudioPayloadService';

const useStyles = makeStyles((theme) => ({
    root: {
        flexGrow: 1,
        // marginTop: theme.spacing(4),
        width: '93%',
        zIndex:2
    },
    sectionHeader: {
        display: 'flex',
        justifyContent: 'space-between',
        alignItems: 'center',
        marginBottom: theme.spacing(2),
    },
    newsImage: {
        width: '80px',
        borderRadius: '10px',
        // height: '113px'
    },
    actionButton: {
        marginTop: theme.spacing(2),
        // width: '100%',
        borderRadius: '20px',
        alignSelf: 'center',
        textTransform: 'capitalize',
        fontSize: '12px',
        color: "#64748B",
        fontWeight: 500
    },
    sampleImage: {
        width: '100%',
        height: 'auto',
        borderRadius: '10px',
    },
    cardContainer: {
        display: 'flex',
        flexDirection: 'column',
        gap: theme.spacing(2),
        padding: theme.spacing(2),
        backgroundColor: 'rgba(63, 133, 247, 0.10)',  // Adjust based on your design
    },
    card: {
        display: 'flex',
        border: '1px solid #E0E0E0',
        backgroundColor: '#FFF',
        marginBottom: theme.spacing(2),
        borderRadius: '16px',
        boxShadow: '0px 4px 20px rgba(0, 0, 0, 0.1)',
        padding: 0
    },
    cardContent: {
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'center',
        padding: '11px',
        width: '100%',
        '&:last-child': {
            paddingBottom: '11px',
        },
    },
    purposeModelcardContent: {
        padding: '16px',
        '&:last-child': {
            paddingBottom: '16px',
        },
    },
    authorDate: {
        color: '#008D9C',
        fontSize: 12,
        fontFamily: 'Inter, sans-serif',
        fontWeight: 500,
        whiteSpace: 'nowrap'
    },
    newsTitle: {
        color: '#101828',
        fontSize: 14,
        fontFamily: 'Inter, sans-serif',
        fontWeight: 600,
        whiteSpace: 'nowrap'
    },
    newsDescription: {
        color: '#667085',
        fontSize: '11px',
        fontFamily: 'Inter, sans-serif',
        fontWeight: 400,
        whiteSpace: 'nowrap',
        overflow: 'hidden',
        textOverflow: 'ellipsis',
        width: '200px',
        
    },
    designChip: {
        background: '#F0F9FF',
        color: '#026AA2',
        marginRight: '1rem',
        transition: 'background 0.3s ease',
        '&:hover': {
            background: '#A1D5E0'
        }
    }
    ,
    researchChip: {
        background: '#FDF2FA',
        color: '#C11574',
        transition: 'background 0.3s ease',
        '&:hover': {
            background: '#F09EB4',
        },
    },
    TrendingText: {
        color: '#0AB257',
        fontSize: '10px',
        fontWeight: 500
    },
    forkContainer: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        background: "#FBF3D9",
        borderRadius: '8px'
    },
    forkLabel: {
        color: '#F0C808',
        fontWeight: 'bold',
    },
    accuracyLabel: {
        color: '#28a745',
        fontWeight: 'bold',
    },
    integrityLabel: {
        color: '#17a2b8',
        fontWeight: 'bold',
    },
    specificChip: {
        borderRadius: '22px',
        background: '#E9EFFA',
        padding: '3.5px 5.6px',
        color: "#2264CD",
        fontFamily:"Inter",
        fontWeight:600
    },
    generalChip: {
        borderRadius: '22px',
        padding: '3.5px 5.6px',
        border: '0px solid #59BB9E',
        background: 'rgba(89, 187, 158, 0.12)',
        color: '#59BB9E',
        fontFamily:"Inter",
        fontWeight:600
    },
    customLabel:{
        padding: '1px',
    },
    messagesContainer: {
        flex: 1,
        padding: '14px',
        overflowY: 'auto' as 'auto',
        display: 'flex',
        flexDirection: 'column' as 'column',
        gap: '10px',
    },
    // message: {
    // maxWidth: '70%',
    // padding: '10px',
    // borderRadius: '10px',
    // boxShadow: '0px 1px 3px rgba(0, 0, 0, 0.2)',
    // // wordWrap: 'break-word' as 'break-word',
    // },
    chatRestartButton: {
        display: 'flex',
        color: '#667C7F',
        // padding: '6px 16px',
        alignItems: 'center',
        borderRadius: '32px',
        border: '1px solid #667C7F',
        background: 'white',
        alignSelf:'center',
        cursor:'pointer'
    },
    // OpenInNewButton: {
    //     // borderRadius: var(--8, 8px);
    //     border: '1px solid #1E293B',
    //     background: '#FFFFFF',
    //     color: '#1E293B',
    //     textTransform :'capitalize',
    //     alignSelf:'flex-start',
    //     marginTop:'0.6rem',
    //     '&:hover': {
    //         background: '#1E293B',
    //         border: '1px solid #1E293B',
    //         color: '#FFFFFF',
    //     },
    //     '&:focus': {
    //         background: '#1E293B',
    //         border: '1px solid #1E293B',
    //         color: '#FFFFFF',
    //     },
        
    // }
}));
interface IFilterForPopularAndLatestModels{
    purpose: { all: boolean, general: boolean, specific: boolean },
    problemType: string 
}
const useQueryParams = () => new URLSearchParams(useLocation().search);

const getInitialFilters = (query: URLSearchParams, isPopular: boolean) => {
    const purposeAll = query.get(`${isPopular ? "popularModels" : "latestModels"}FilterByPurposeType`) === null;
    return {
        purpose: {
            all: purposeAll,
            general: purposeAll || query.get(`${isPopular ? "popularModels" : "latestModels"}FilterByPurposeType`) === "general",
            specific: purposeAll || query.get(`${isPopular ? "popularModels" : "latestModels"}FilterByPurposeType`) === "specific"
        },
        problemType: query.get(`${isPopular ? "popularModels" : "latestModels"}FilterByProblemType`) || "ALL"
    };
};
// Function to format current time as HH:MM AM/PM
const formatTime = (date: Date) => {
    const hours = date.getHours();
    const minutes = date.getMinutes();
    const ampm = hours >= 12 ? 'PM' : 'AM';
    const formattedHours = hours % 12 || 12; // Convert to 12-hour format
    const formattedMinutes = minutes < 10 ? `0${minutes}` : minutes;
    return `${formattedHours}:${formattedMinutes} ${ampm}`;
  };

const RLEFHomePage = () => {
    const classes = useStyles();
    const history=useHistory()
    const dispatch = useDispatch();
    const chatContext = useContext(ChatContext);
    const { modelCloningStage, setModelCloningStage } = chatContext;
    const { pushPageInWorkModeStack, switchToPreviousPageInWorkModeStack, workModePageStack } = chatContext;

    const [isActive, setIsActive] = useState(false);
    const searchContainerRef = useRef<HTMLDivElement>(null);
    const [searchQuery, setSearchQuery] = useState('');
    const [isHamburgerOpened,setIsHamburgerOpened]=useState<boolean>(false)
    const [showLogout,setShowLogout]=useState<boolean>(false)
    const [availablePopularModelsData, setAvailablePopularModelsData] = useState<IPopularModel[] | []>([])
    const [availableLatestModelsData, setAvailableLatestModelsData] = useState<ILatestModel[] | []>([])
    const [isPopularModelsDataFetching, setIsPopularModelsDataFetching] = useState(false)
    const [isLatestModelsDataFetching, setIsLatestModelsDataFetching] = useState(false)
    const [popularModelFetchStatus, setPopularModelFetchStatus] = useState<Record<string, Record<string, boolean>>>({});
    const [fetchedPopularModelResources, setFetchedPopularModelResources] = useState<Record<string, Record<string, any>>>({});
    const [latestModelFetchStatus, setLatestModelFetchStatus] = useState<Record<string, Record<string, boolean>>>({});
    const [fetchedLatestModelResources, setFetchedLatestModelResources] = useState<Record<string, Record<string, any>>>({})
    const [fetchedSearchResultsResources, setFetchedSearchResultsResources] = useState<Record<string, Record<string, any>>>({});
    const debouncedSearchQuery = useDebounce(searchQuery,1000)
    const [searchApiloading, setSearchApiloading] = useState<boolean>(false);
    const [searchResults, setSearchResults] = useState<Array<IGetModelCollectionResponse>>([]);
    const [streamedResponse, setStreamedResponse] = useState('');
    const [isPopularModelsFilterModalOpen, setIsPopularModelsFilterModalOpen] = useState(false);
    const [isLatestModelsFilterModalOpen, setIsLatestModelsFilterModalOpen] = useState(false);
    const  { search, pathname} = useLocation();
    const location = useLocation();
    const params = new URLSearchParams(location.search);
    const query = new URLSearchParams(search)    
    const [selectedPopularModelsFilters, setSelectedPopularModelsFilters] = useState(getInitialFilters(query, true));
    const [selectedLatestModelsFilters, setSelectedLatestModelsFilters] = useState(getInitialFilters(query, false));
    const { userLoginStatus } = useCheckUserLoginValidity({silentlyGenerateNewAccessTokenBeforeExpiryIfValidUser: true});
    const LatestModelsfilterButtonRef = useRef<HTMLButtonElement>(null);
    const PopularModelsfilterButtonRef = useRef<HTMLButtonElement>(null);
    const [calledResetChatHistoryService, setCalledResetChatHistoryService] = useState<boolean>(false);
    const [homepageSearchChatMessages, setHomepageSearchChatMessages] = useState<IMessage[]>([
        // {
        //     sender: 'assistant',
        //     text: `Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book. It has survived not only five centuries, but also the leap into electronic typesetting, remaining essentially unchanged. It was popularised in the 1960s with the release of Letraset sheets containing Lorem Ipsum passages, and more recently with desktop publishing software like Aldus PageMaker including versions of Lorem Ipsum`,
        //     timestamp: String(new Date()),
        //     metaData: {
        //         pageType: 'testing_data',
        //         button_text: 'testing_data',
        //         trainingPods: [{
        //             modelCollectionId: '6699432cd70035cfd76064a6',
        //             testDataSetId: '6694cce77c8a3f1782165973',
        //             trainingPodId: '6694ccf77c8a3f17821659ef'
        //         }]
        //     }
        // },
        // {
        //     sender: 'assistant',
        //     text: 'summary_page',
        //     timestamp: String(new Date()),
        //     metaData: {
        //         pageType: 'summary_page',
        //         button_text: 'Model summary',
        //         trainingPods: [{
        //             modelCollectionId: '671a2371b90bc27812773a84',
        //             testDataSetId: '670fea0433bea868628ae84e',
        //             trainingPodId: '671a5b6bb90bc278127df3fe'
        //         }]
        //     }
        // },
        // {
        //     sender: 'assistant',
        //     text: 'model_information_page',
        //     timestamp: String(new Date()),
        //     metaData: {
        //         pageType: 'model_information_page',
        //         button_text: 'model_information_page',
        //         trainingPods: [{
        //             modelCollectionId: '6699432cd70035cfd76064a6',
        //             testDataSetId: '6694cce77c8a3f1782165973',
        //             trainingPodId: '6694ccf77c8a3f17821659ef',
        //             similarityScore: '81',
        //         }]
        //     }
        // },
        // {
        //     sender: 'assistant',
        //     text: 'summary_page',
        //     timestamp: String(new Date()),
        //     metaData: {
        //         pageType: 'summary_page',
        //         button_text: 'summary_page',
        //         trainingPods: [{
        //             modelCollectionId: '671a2371b90bc27812773a84',
        //             testDataSetId: '670fea0433bea868628ae84e',
        //             trainingPodId: '671a5b6bb90bc278127df3fe',
        //             similarityScore: '81',
        //         }]
        //     }
        // },
        // {
        //     sender: 'assistant',
        //     text: 'test_dive',
        //     timestamp: String(new Date()),
        //     metaData: {
        //         pageType: 'test_drive',
        //         button_text: 'Test Drive',
        //         trainingPods: [{
        //             modelCollectionId: '6699432cd70035cfd76064a6',
        //             testDataSetId: '6694cce77c8a3f1782165973',
        //             trainingPodId: '6694ccf77c8a3f17821659ef',
        //             similarityScore: '81',
        //         }]
        //     }
        // }
    ]);
    
    
    const [isStreaming, setIsStreaming] = useState(false);
    const isWaitingForAiResponse = useRef<boolean>(false);
    const [isChatHistoryFetched,setIsChatHistoryFetched] = useState(false)
    const [showPreviewWindow, setShowPreviewWindow] = useState(false)
    const [previewWindowHeadingText,setPreviewWindowHeadingText] =useState('')
    const [pageTypeInPreviewWindow, setPageTypeInPreviewWindow] = useState<aiResponsePreviewPageType | null>(null);
    const [modelSummaryPageData, setModelSummaryPageData] = useState<ILatestModel | {}>({})
    const [availableModelCollections, setAvailableModelCollections] = useState<ILatestModel[] | []>([])
    const [modelInformationPageFetchStatus, setModelInformationPageFetchStatus] = useState<Record<string, Record<string, boolean>>>({});
    const [fetchedModelInformationPageResources, setFetchedModelInformationPageResources] = useState<Record<string, Record<string, any>>>({});
    // const [isModelSummaryPageDataFetching,setIsModelSummaryPageDataFetching]=useState(false)
    const [isPreviewWindowLoading,setIsPreviewWindowLoading]=useState(false)
    // const messageEndRef = useRef<HTMLDivElement | null>(null);
    const [streamingMessageIndex, setStreamingMessageIndex] = useState<number | null>(null);
    const responseAbortController = useRef<AbortController | null>(null);
    const [splitIntoTwoColumns,setSplitIntoTwoColumns]=useState<boolean>(false) 
    const firstContainerRef = useRef<HTMLDivElement | null>(null);//for intersection observer to track the position of search element
    const stickyRef = useRef<HTMLDivElement | null>(null);
    const [isShowBrowsingResults,setIsShowBrowsingResults]=useState<boolean>(false)
    const [isFetchingChatBrowsingResults,setIsFetchingChatBrowsingResults]=useState<boolean>(false)
    const [rlefConfigured, setRlefConfigured] = useState<boolean>(false)

    const [shouldCallToRedirectedAssistant, setShouldCallToRedirectedAssistant] = useState(false);
    const [reRouteToAssistantName, setReRouteToAssistantName] = useState(LLM_STUDIO_CHAT_ASSISTANT_NAME);
    const [userQueryForRedirectedAssistant, setUserQueryForRedirectedAssistant] = useState('')
    const currentPageData = workModePageStack.length ? workModePageStack[workModePageStack.length-1] : null;
    const [shouldShowAnalysis, setShouldShowAnalysis] = useState<boolean>(false)

    // console.log({availableModelCollections,modelSummaryPageData})
    useEffect(()=>{
        if (userLoginStatus === 'invalidUser') {
            // redirecting to login page
            history.push("/login");
        }
    }, [userLoginStatus])
    // useEffect(() => {
    //     const handleClickOutside = (event: MouseEvent) => {
    //         if (searchContainerRef.current && !searchContainerRef.current.contains(event.target as Node)) {
    //             setIsActive(false); // Close modal
    //             setSearchQuery("");
    //         }
    //     };

    //     if (isActive) {
    //         document.addEventListener('mousedown', handleClickOutside);
    //     } else {
    //         document.removeEventListener('mousedown', handleClickOutside);
    //     }

    //     return () => {
    //         document.removeEventListener('mousedown', handleClickOutside);
    //     };
    // }, [isActive]);
    const fetchPopularModels = async (selectedPopularModelsFilters : { purpose: { all: boolean; general: boolean; specific: boolean }; problemType?: string }) => {
        const params: IPopularModelQueryParams = {
            sortOrder: 'desc',
            bestModelFilter: 'accuracy',
            testDataSetRandomResourceIdsCnt: 3,
            limit: 3,
            ...(selectedPopularModelsFilters.problemType==="ALL"?{}:{problemType : selectedPopularModelsFilters.problemType}),
            ...(selectedPopularModelsFilters.purpose.all ? {} : { purposeType: selectedPopularModelsFilters.purpose.general ? "general" : "specific" })
        };
        const paramsFromTheURL ={
            problemType: query.get("popularModelsFilterByProblemType"),
            purposeType: query.get("popularModelsFilterByPurposeType")
        } 

        const finalParams = {
            ...params,
            ...(paramsFromTheURL.problemType ? { problemType: paramsFromTheURL.problemType } : {}),
            ...(paramsFromTheURL.purposeType ? { purposeType: paramsFromTheURL.purposeType } : {})
        };
        setIsPopularModelsDataFetching(true)
        const data = await getPopularModels(finalParams);
        if (data) setAvailablePopularModelsData(data.result)
        setIsPopularModelsDataFetching(false)
    }
    const fetchLatestModels = async (selectedLatestModelsFilters : { purpose: { all: boolean; general: boolean; specific: boolean }; problemType: string }) => {
        const params: ILastestModelQueryParams = {
            bestModelFilter: 'accuracy',
            testDataSetRandomResourceIdsCnt: 3,
            limit: 3,
            ...(selectedLatestModelsFilters.problemType==="ALL"?{}:{problemType : selectedLatestModelsFilters.problemType}),
            ...(selectedLatestModelsFilters.purpose.all ? {} : { purposeType: selectedLatestModelsFilters.purpose.general ? "general" : "specific" })

        };
        const paramsFromTheURL ={
            problemType: query.get("latestModelsFilterByProblemType"),
            purposeType: query.get("latestModelsFilterByPurposeType")
        } 
        
        const finalParams = {
            ...params,
            ...(paramsFromTheURL.problemType ? { problemType: paramsFromTheURL.problemType } : {}),
            ...(paramsFromTheURL.purposeType ? { purposeType: paramsFromTheURL.purposeType } : {})
        };
        setIsLatestModelsDataFetching(true)
        const data = await getLatestModels(finalParams);
        if (data) setAvailableLatestModelsData(data.result)
        setIsLatestModelsDataFetching(false)
    }
    useEffect(() => {
        fetchPopularModels(selectedPopularModelsFilters)
    }, [selectedPopularModelsFilters])
    useEffect(() => {
        fetchLatestModels(selectedLatestModelsFilters)
    }, [selectedLatestModelsFilters])

    useEffect(() => {
        const fetchPopularModelResources = async () => {
            try {
                // Loop through each popular model
                for (const model of availablePopularModelsData) {
                    if (model.purposeModel?.purposeType === 'specific') {
                        const modelId = model.purposeModel?._id;
                        // Loop through each checkpoint file's analysis resource IDs
                        // @ts-ignore 
                        const { resources } = model.checkpointFiles.files.analysis.dataSetCollection;
                        // Initialize the fetched resources for this model ID if not already present
                        if (!fetchedPopularModelResources[modelId]) {
                            setFetchedPopularModelResources((prevResources) => ({
                                ...prevResources,
                                [modelId]: {},
                            }));
                        }
                        // Fetch each resource one by one
                        for (const resourceId of resources) {
                            try {
                                // Set fetch status to true for this resource
                                setPopularModelFetchStatus((prevStatus) => ({
                                    ...prevStatus,
                                    [modelId]: {
                                        ...prevStatus[modelId],
                                        [resourceId]: true,
                                    },
                                }));

                                // Make the API call to fetch resource by ID
                                const res = await DataService.getResourceFromDBById({resourceId:resourceId});
                                // Save the fetched resource data in the state
                                setFetchedPopularModelResources((prevResources) => ({
                                    ...prevResources,
                                    [modelId]: {
                                        ...prevResources[modelId],
                                        [resourceId]: res.data,
                                    },
                                }));

                                // After successful fetch, set status to false
                                setPopularModelFetchStatus((prevStatus) => ({
                                    ...prevStatus,
                                    [modelId]: {
                                        ...prevStatus[modelId],
                                        [resourceId]: false,
                                    },
                                }));

                            } catch (resourceError) {
                                console.error(`Error fetching resource with ID ${resourceId}:`, resourceError);

                                // Ensure that even in case of error, fetching is marked as done
                                setPopularModelFetchStatus((prevStatus) => ({
                                    ...prevStatus,
                                    [modelId]: {
                                        ...prevStatus[modelId],
                                        [resourceId]: false,
                                    },
                                }));
                            }
                        }
                    }
                }
            } catch (generalError) {
                console.error('Error fetching popular model resources:', generalError);
            }
        };
        setPopularModelFetchStatus({})
        setFetchedPopularModelResources({})
        fetchPopularModelResources();
    }, [availablePopularModelsData]);

    const getUserCredentials = async() => {
        if(rlefConfigured) return;
        try {
            const {emailId} = await UserDBService.getUserEmail()
            configureRlefUiLibrary({
                rlefBaseUrl: APP_API,
                userEmail : emailId
            })
            setRlefConfigured(true)
        } catch (error) {}
    }
    getUserCredentials()

    useEffect(() => {
        if(!shouldShowAnalysis) return;
        setIsStreaming(true)
        if(chatContext.testDriveAnalysisMessage) {
            setHomepageSearchChatMessages(prev=>{
                const messages = [...prev]
                if(chatContext.testDriveAnalysisMessage === 'call analysis assistant') {
                    if(messages.length && messages[messages.length-1].text !== 'Analysing test drive results') messages.push({ text: 'Analysing test drive results', sender: 'assistant',timestamp: formatTime(new Date()), messageUuid: generateUniqueId(), isOrchestrator: true})
                    setReRouteToAssistantName(LLM_STUDIO_MODEL_TEST_DRIVE_RESULTS_ANALYSIS_ASSISTANT_NAME)
                    setShouldCallToRedirectedAssistant(true)
                }
                else {
                    if(messages.length && messages[messages.length-1].text === 'Analysing test drive results'){
                        messages[messages.length-1].text = chatContext.testDriveAnalysisMessage
                        messages[messages.length-1].hasStreamingEnded = true
                    }
                    else messages.push({ text: chatContext.testDriveAnalysisMessage, sender: 'assistant',timestamp: formatTime(new Date()), messageUuid: generateUniqueId(), isOrchestrator: true, hasStreamingEnded: true})
                    setIsStreaming(false)
                } 
                return messages
            })
            setShouldShowAnalysis(false)
            chatContext.setTestDriveAnalysisMessage('')
        } 
        else {
            let message = 'Analysing test drive results'
            setHomepageSearchChatMessages(prev=>[...prev, { text: message, sender: 'assistant',timestamp: formatTime(new Date()), messageUuid: generateUniqueId(), isOrchestrator: true}])
        }
                // const newMessage:IMessage = { text: 'Do you like the test results?', sender: 'assistant', timestamp: formatTime(new Date()), timeInSecs: Date.now(),
                //     messageUuid: generateUniqueId(), isOrchestrator: true, hasStreamingEnded: true,
                //     metaData: currentPageData.aiResponseMetaData,

					// model recommendation cta buttons
    //                 additionalMetadata : {
    //                     scenario: 'user likability response',
    //                     primaryButtons: [
    //                         {
    //                             buttonText: 'Yes',
    //                             type: 'yes',
    //                             clicked: false,
    //                             disabled: false
    //                         },                        
    //                         {
    //                             buttonText: 'No',
    //                             type: 'no',
    //                             clicked: false,
    //                             disabled: false
    //                         },   
    //                     ]
    //                 }
                // }
    }, [shouldShowAnalysis, chatContext.testDriveAnalysisMessage])

    useEffect(() => {
        const fetchLatestModelResources = async () => {
            try {
                // Loop through each latest model
                for (const model of availableLatestModelsData) {
                    if (model.purposeModel?.purposeType === 'specific') {
                        const modelId = model.purposeModel?._id;
                        // Loop through each checkpoint file's analysis resource IDs
                        // @ts-ignore 
                        const { resources } = model.checkpointFiles.files.analysis.dataSetCollection;

                        // Fetch each resource one by one
                        for (const resourceId of resources) {
                            try {
                                // Set fetch status to true for this resource
                                setLatestModelFetchStatus((prevStatus) => ({
                                    ...prevStatus,
                                    [modelId]: {
                                        ...prevStatus[modelId],
                                        [resourceId]: false,
                                    },
                                }));

                                // Make the API call to fetch resource by ID
                                const res = await DataService.getResourceFromDBById({resourceId:resourceId});

                                // Save the fetched resource data in the state
                                setFetchedLatestModelResources((prevResources) => ({
                                    ...prevResources,
                                    [modelId]: {
                                        ...prevResources[modelId],
                                        [resourceId]: res.data,
                                    },
                                }));

                                // After successful fetch, set status to false
                                setLatestModelFetchStatus((prevStatus) => ({
                                    ...prevStatus,
                                    [modelId]: {
                                        ...prevStatus[modelId],
                                        [resourceId]: false,
                                    },
                                }));

                            } catch (resourceError) {
                                console.error(`Error fetching resource with ID ${resourceId}:`, resourceError);

                                // Ensure that even in case of error, fetching is marked as done
                                setLatestModelFetchStatus((prevStatus) => ({
                                    ...prevStatus,
                                    [modelId]: {
                                        ...prevStatus[modelId],
                                        [resourceId]: false,
                                    },
                                }));
                            }
                        }
                    }
                }
            } catch (generalError) {
                console.error('Error fetching latest model resources:', generalError);
            }
        };
        setFetchedLatestModelResources({})
        setLatestModelFetchStatus({})
        fetchLatestModelResources();
    }, [availableLatestModelsData]);
    
    useEffect(() => {
        const fetchModelInformationPageResources = async () => {
            try {
                // Loop through each model collection
                for (const model of availableModelCollections) {
                    if (model.purposeModel?.purposeType === 'specific') {
                        const modelId = model.purposeModel?._id;
    
                        // Initialize the fetched resources for this model ID if not already present
                        if (!fetchedModelInformationPageResources[modelId]) {
                            setFetchedModelInformationPageResources((prevResources) => ({
                                ...prevResources,
                                [modelId]: {},
                            }));
                        }
    
                        // Loop through each checkpoint file's analysis resource IDs
                        // @ts-ignore 
                        const { resources } = model.checkpointFiles.files.analysis.dataSetCollection;
                        for (const resourceId of resources) {
                            try {
                                // Set fetch status to true for this resource
                                setModelInformationPageFetchStatus((prevStatus) => ({
                                    ...prevStatus,
                                    [modelId]: {
                                        ...prevStatus[modelId],
                                        [resourceId]: true,
                                    },
                                }));
    
                                // Make the API call to fetch resource by ID
                                const res = await DataService.getResourceFromDBById({ resourceId });
                                
                                // Save the fetched resource data in the state
                                setFetchedModelInformationPageResources((prevResources) => ({
                                    ...prevResources,
                                    [modelId]: {
                                        ...prevResources[modelId],
                                        [resourceId]: res.data,
                                    },
                                }));
    
                                // After successful fetch, set status to false
                                setModelInformationPageFetchStatus((prevStatus) => ({
                                    ...prevStatus,
                                    [modelId]: {
                                        ...prevStatus[modelId],
                                        [resourceId]: false,
                                    },
                                }));
    
                            } catch (resourceError) {
                                console.error(`Error fetching resource with ID ${resourceId}:`, resourceError);
    
                                // Ensure that even in case of error, fetching is marked as done
                                setModelInformationPageFetchStatus((prevStatus) => ({
                                    ...prevStatus,
                                    [modelId]: {
                                        ...prevStatus[modelId],
                                        [resourceId]: false,
                                    },
                                }));
                            }
                        }
                    }
                }
            } catch (generalError) {
                console.error('Error fetching model information page resources:', generalError);
            }
        };
        // Reset states before fetching
        setModelInformationPageFetchStatus({});
        setFetchedModelInformationPageResources({});
        fetchModelInformationPageResources();
    }, [availableModelCollections]);
    // Type guard function to validate `ILatestModel`
    const isILatestModel = (item: unknown): item is ILatestModel => {
        return (
            typeof item === 'object' &&
            item !== null &&
            'purposeModel' in item &&
            typeof (item as ILatestModel).purposeModel?._id === 'string'
        );
    };
    const fetchModelSummaryDetails= async (metaData:IAIresponseMetaData)=>{
        const {testDataSetId,modelCollectionId,trainingPodId}=metaData.trainingPods[0]
        const filteredParams={
            modelCollectionId,
            purposeModelId:trainingPodId,
            testDataSetId,
            testDataSetRandomResourceIdsCnt:4,
            getLibrariesRequirements: true
        }
        setIsPreviewWindowLoading(true)
        const data = await getModelCollectionsByID(filteredParams)
        if (data && data?.result) {
            setModelSummaryPageData(data?.result[0])
            setAvailableModelCollections((data?.result as unknown[]).filter((item): item is ILatestModel =>isILatestModel(item)))
        }
        setIsPreviewWindowLoading(false)
        
    }
    
    const fetchModelInformationDetails= async (metaData:IAIresponseMetaData)=>{
        const { trainingPods } = metaData;
        try {
            // Use Promise.all to fetch data for all trainingPods concurrently
            // setIsPreviewWindowLoading(true);
            if(splitIntoTwoColumns){
                setIsPreviewWindowLoading(true)
                setIsFetchingChatBrowsingResults(false)
            }else{
                // setSplitIntoTwoColumns(false)
                setIsPreviewWindowLoading(false)
                setIsFetchingChatBrowsingResults(true)

            }
            const results = await Promise.all(
                trainingPods.map(async (pod) => {
                    const { testDataSetId, modelCollectionId, trainingPodId ,similarityScore='61'} = pod;
                    const filteredParams = {
                        modelCollectionId,
                        purposeModelId: trainingPodId,
                        testDataSetId
                    };
    
                    // Fetch the data for the current pod
                    const data = await getModelCollectionsByID(filteredParams);

                    // Ensure the returned result matches the ILatestModel type
                    if (data?.result) {
                        return (data.result as any[]).map((item)=>{return {...item,['similarityScore']:similarityScore}}).filter((item): item is ILatestModel =>   
                            isILatestModel(item)
                        );
                    }
    
                    return [];
                })
            );
    
            // Flatten the array of results and update state
            const allResults = results.flat();
            setAvailableModelCollections(allResults);
        } catch (error) {
            console.error("Error fetching model collections:", error);
        } finally {
            // Ensure the preview window loading state is cleared
            setIsPreviewWindowLoading(false);
            setIsFetchingChatBrowsingResults(false)
        }
        
    }

    const handleFocus = () => {
        if(!isActive && homepageSearchChatMessages.length>0){
            setIsActive(true)
        }
    };

    const handleBlur = () => {
        setIsActive(false);
    };

    const handleInputChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        setSearchQuery(event.target.value);
    };
    const handleClickHamburgerOpenClose=(isOpened:boolean)=>{
        setIsHamburgerOpened(isOpened)
    }
    const logout = async () => {
        await UserDBService.logoutUser();
        // localStorage.removeItem('loggedIn');
        history.push('/login');
    }
    const handleOpenLatestModelsFilter = () => {
        setIsLatestModelsFilterModalOpen(true);
    };
    const handleToggleLatestModelsFilter = () =>{
        setIsLatestModelsFilterModalOpen(!isLatestModelsFilterModalOpen);
    }
    const handleCloseLatestModelFilterModal = () => {
        setIsLatestModelsFilterModalOpen(false);
    };
    const handleApplyLatestModelsFilters = (filters: IFilterForPopularAndLatestModels) => {
        if(filters.problemType == "ALL"){
            query.delete("latestModelsFilterByProblemType")
        }
        else{
            query.set("latestModelsFilterByProblemType",filters.problemType)
        }
        if(filters.purpose.all){
            query.delete("latestModelsFilterByPurposeType")
        }
        else if(filters.purpose.general){
            query.set("latestModelsFilterByPurposeType",'general')
        }
        else{
            query.set("latestModelsFilterByPurposeType","specific")
        }
        setSelectedLatestModelsFilters(filters); 
        setIsLatestModelsFilterModalOpen(false);
        history.push(`${pathname}?${query}`)

    };
    const handleTogglePopularModelsFilter = () =>{
        setIsPopularModelsFilterModalOpen(!isPopularModelsFilterModalOpen);
    }
    const handleOpenPopularModelsFilter = () => {
        setIsPopularModelsFilterModalOpen(true);
    };
    const handleClosePopularModelsFilterModal = () => {
        setIsPopularModelsFilterModalOpen(false);
    };
    const handleApplyPopularModelsFilters = (filters: IFilterForPopularAndLatestModels) => {
        if(filters.problemType == "ALL"){
            query.delete("popularModelsFilterByProblemType")
        }
        else{
            query.set("popularModelsFilterByProblemType",filters.problemType)
        }
        if(filters.purpose.all){
            query.delete("popularModelsFilterByPurposeType")
        }
        else if(filters.purpose.general){
            query.set("popularModelsFilterByPurposeType",'general')
        }
        else{
            query.set("popularModelsFilterByPurposeType","specific")
        }
        setSelectedPopularModelsFilters(filters);
        setIsPopularModelsFilterModalOpen(false);
        history.push(`${pathname}?${query}`)
    };
    const { userEmail } = useAuth()

    const scrollToTop = () => {
        window.scrollTo({
            top: 0,
            behavior: 'smooth', // Enables smooth scrolling
        });
    };

    const removeQueryParamsFromUrl=()=>{
        const basePath = window.location.pathname; // Gets the path without query params
        window.history.replaceState({}, '', basePath);
    }

    const hiddenPromptWithUserMessage = () => {
        let prompt = ''
        if(showPreviewWindow){
            prompt = promptToSendAlongWithUserMessageToGiveAiContextAboutSelectedModelInUi(getFormattedDataOfTrainingPodToSendToAi(modelSummaryPageData as ILatestModel), currentPageData?.aiResponseMetaData?.pageType || '')
            prompt = `${'```modelInfo'}${prompt}${'```'}`
        }
        console.log(prompt);
        return prompt;
    }



  
    useEffect(() => {
        // When streaming starts, set streamingMessageIndex to the last message index
        if (isStreaming) {
          setStreamingMessageIndex(homepageSearchChatMessages.length - 1);
        } else {
          setStreamingMessageIndex(null); // Clear index when streaming stops
        }
      }, [isStreaming, homepageSearchChatMessages.length]);
    const fetchChatHistory = async () => {
        const chatHistory = await getChatHistoryAPIService();
        // if(chatHistory){
        //   chatHistory.map(msgObj => {
        //     setHomepageSearchChatMessages(prev => {
        //       return [...prev, { text: msgObj.content, sender: msgObj.role,timestamp:msgObj.timestamp }]
        //     })
        //   })
        // }
      }
    
      useEffect(() => {
        if(!isChatHistoryFetched){
            void fetchChatHistory()
            if(!calledResetChatHistoryService){
                handleClearHistory()
            }
            setIsChatHistoryFetched(true)
        }
      }, [isActive, calledResetChatHistoryService]);

      const clearAllStates=()=>{
        setModelSummaryPageData({})
        setAvailableModelCollections([])
        setModelInformationPageFetchStatus({})
        setFetchedModelInformationPageResources({})
        setModelCloningStage('')
      }

    const guestSessionId = GuestSessionIdService.getGuestSessionId({
        organisationName: LLM_STUDIO_ORG_NAME,
        chatAssistantName: LLM_STUDIO_CHAT_ASSISTANT_NAME
    });
    let hasStreamingEnded: boolean = false;
    const chatPreviewId = guestSessionId;

    let accordianMessageArray: {
        responsedata: string,
        responseFrom: "chatbot",
        header_message: string,
        finalResponseStatus: boolean
    }[] = [];

    useEffect(() => {
        console.log(`Current Assistant in action: ${reRouteToAssistantName}`)
    }, [reRouteToAssistantName])

    const handleSendMessage = async() => {
        if(isStreaming || !searchQuery.trim()) return;
        if(!isActive){
            setIsActive(true);
        }
        const messageContentToSend = searchQuery
        scrollToTop()
        setUserQueryForRedirectedAssistant(messageContentToSend)
        const userMessage: IMessage = { text: messageContentToSend, sender: 'user',timestamp: formatTime(new Date()), messageUuid: generateUniqueId()};
        setHomepageSearchChatMessages((prev) => [...prev, userMessage]);
        setSearchQuery(''); // Clear input field

		// Add an empty bot message to indicate loading
		try {
			const assistantDetails:any = await new Promise((resolve, reject)=>{
			    hasStreamingEnded = false;
				const centralAssistantparams = {
					WEBSOCKET_URL: LLM_STUDIO_CHAT_WEB_SOCKET_URL, // replace with actual WebSocket URL
					organizationName: LLM_STUDIO_ORG_NAME,
					chatAssistantName: LLM_STUDIO_CENTRAL_ASSISTANT_NAME,
					selectedAIModel: LLM_STUDIO_SELECTED_AI_MODEL_NAME,
					replyMessage: '', // If there is no previous response, leave empty
					userName: userEmail,
					userEmailId: userEmail,
					userId: LLM_STUDIO_USER_ID,
					guestSessionId: guestSessionId,
					chatPreviewId: chatPreviewId,
					query: userMessage.text + hiddenPromptWithUserMessage(),
					// sessionId: getChatSessionIdFromCookies(), // Generates a unique session ID
					requestId: `requestId-${uuidv4()}`, // Generates a unique request ID
					// customPrompt: '',
					enableForBackend: false,
					// isDocumentRetrieval:false, // To bypass the document retrieval.
					  	onStreamEvent: (data: any) => {
							const msgContent = data?.chatStream?.continueChatResult?.message?.content;
                            const isOrchestrator = data?.chatStream?.continueChatResult?.is_orchestrator;
                            const isThinkingBreak = data?.chatStream?.continueChatResult?.thinking_break;
                            let rejectFlag = false;

							if (data?.chatStream?.continueChatResult?.isEnd) {
                                const match = msgContent.match(/callAsst\s*\{([\s\S]*)\}/);
                                let jsonString = `{${match[1].trim()}}`;
                                jsonString = jsonString.replace(/'/g, '"').replace(/:\s*([\w]+)/g, ': "$1"');
                                const parsedDetails = JSON.parse(jsonString)
                                hasStreamingEnded = true

                                if(parsedDetails?.assistant_name?.trim()) resolve(parsedDetails)
                                else rejectFlag = true
                            }
                            if (!msgContent.startsWith("WaitMessage:")) {
                                if(msgContent.split("```").length - 1 === 1){
                                    return;
                                }
                                if (isWaitingForAiResponse && msgContent.trim().length > 0) {
                                    isWaitingForAiResponse.current = false
                                }
                                const explainabilityMap = new Map<string,{responded_by: string; index: number }>();
                                const assistantObject = getAssistantObj(data, accordianMessageArray)
            
                                setHomepageSearchChatMessages((prev) => {
                                    const updatedMessages = [...prev];
                                    if (updatedMessages[updatedMessages.length - 1].sender === 'assistant') {
                                        // Regular expression to match the extraMetaData block
                                        const extraMetaDataMatch = msgContent.match(/```callAsst\s*([\s\S]*?)```/);
                                        // const match = markdownContent.match(extraMetaDataMatch);
                                        if (extraMetaDataMatch && extraMetaDataMatch.length > 1) {
                                            try {
                                                // Clean the JSON string by removing any newlines or extra spaces
                                                const cleanedMetaData = extraMetaDataMatch[1].replace(/'/g, '"').replace(/:\s*([\w]+)/g, ': "$1"');
                                                // Parse the extracted metadata JSON
                                                const metaData = JSON.parse(cleanedMetaData);
                                                // Remove the extraMetaData from content for display'
                                                if('response' in metaData) {
                                                    updatedMessages[updatedMessages.length - 1] = {
                                                        ...updatedMessages[updatedMessages.length - 1],
                                                        text: metaData.response,
                                                        explainabilityData: assistantObject,
                                                        hasStreamingEnded: hasStreamingEnded,
                                                        isOrchestrator: isOrchestrator,
                                                        isThinkingBreak: isThinkingBreak,
                                                        shouldAutoClick:showPreviewWindow ? false : true,
                                                        // messageUuid: generateUniqueId()
                                                    }
                                                }
                                            } catch (error) {
                                                console.error('Error parsing extraMetaData of central assistant:', error);
                                            }
                                        }
                                    } else {
                                        updatedMessages.push({ text: msgContent, sender: 'assistant', timestamp: formatTime(new Date()), explainabilityData: assistantObject, hasStreamingEnded: hasStreamingEnded, messageUuid: generateUniqueId() });
                                    }
                                    return updatedMessages;
                                });
                            }
							if(rejectFlag) reject('no assistant_name property')
						},
						onStreamEnd: () => setIsStreaming(false),
						onError: reject,
				};
				setIsStreaming(true);
				isWaitingForAiResponse.current = true;
				responseAbortController.current = new AbortController();
				startChatStream({ ...centralAssistantparams, signal: responseAbortController.current.signal });
				setHomepageSearchChatMessages((prev) => [...prev, { text: '', sender: 'assistant', timestamp: formatTime(new Date()), messageUuid: generateUniqueId() }]);
			})
            let redirectedAssistant = assistantDetails.assistant_name.trim().toLowerCase()
            setReRouteToAssistantName(redirectedAssistant)
            if(redirectedAssistant === LLM_STUDIO_MODEL_RECOMMENDATION_ASSISTANT_NAME) {
                setUserQueryForRedirectedAssistant(assistantDetails.user_feedback || '')
            }
            // console.log(assistantDetails.assistant_name)
            hasStreamingEnded = false;
            setShouldCallToRedirectedAssistant(true);
		} catch (error : any) {
            if(error === 'no assistant_name property') {
                hasStreamingEnded = true
                setIsStreaming(false)
                setShouldCallToRedirectedAssistant(false);
            }
            else {
                hasStreamingEnded = false
                setShouldCallToRedirectedAssistant(true);
            } 
            console.error(error)
		}
    }

    const callToRedirectedAssistant = () => {
        setShouldCallToRedirectedAssistant(false);

        const userMessage: IMessage = { text: userQueryForRedirectedAssistant, sender: 'user',timestamp: formatTime(new Date()), messageUuid: generateUniqueId()};
        const getUserQueryForRedirectedAssistant = () => {
            switch(reRouteToAssistantName){
                case LLM_STUDIO_CHAT_ASSISTANT_NAME:
                    return userMessage.text + hiddenPromptWithUserMessage();
                case LLM_STUDIO_MODEL_RECOMMENDATION_ASSISTANT_NAME:
                    const query = modelRecommendationPayload(userMessage.text, chatContext.testDroveTrainingPodIdsInCurrentSession, chatContext.metadataOfCurrentTestDroveTrainingPod?.descriptionOfDataSelectedForTestDrive);
                    // console.log("Query for model recommendation: " + query);
                    return query;
                case LLM_STUDIO_MODEL_TEST_DRIVE_RESULTS_ANALYSIS_ASSISTANT_NAME:
                    // console.log('Query for analysis agent')
                    const payload = {annotatedPairsOfImagesDownloadUrlsTestDriveResults: chatContext.analysisAssistantPayload}
                    chatContext.setAnalysisAssistantPayload([])
                    return payload
            }
        }

	    // Prepare parameters for startChatStream
	    const params = {
	      WEBSOCKET_URL: LLM_STUDIO_CHAT_WEB_SOCKET_URL, // replace with actual WebSocket URL
	      organizationName: LLM_STUDIO_ORG_NAME,
	      chatAssistantName: reRouteToAssistantName,
	      selectedAIModel: LLM_STUDIO_SELECTED_AI_MODEL_NAME,
	      replyMessage: '', // If there is no previous response, leave empty
	      userName: userEmail,
	      userEmailId: userEmail,
	      userId: LLM_STUDIO_USER_ID,
	      guestSessionId: guestSessionId,
	      chatPreviewId: chatPreviewId,
	      query: JSON.stringify(getUserQueryForRedirectedAssistant()),
	      // sessionId: getChatSessionIdFromCookies(), // Generates a unique session ID
	      requestId: `requestId-${uuidv4()}`, // Generates a unique request ID
	      // customPrompt: '',
	      enableForBackend: false,
	      // isDocumentRetrieval:false, // To bypass the document retrieval.
	        onStreamEvent: (data: any) => {
	            const msgContent = data?.chatStream?.continueChatResult?.message?.content;
	            const isOrchestrator = data?.chatStream?.continueChatResult?.is_orchestrator;
	            const isThinkingBreak = data?.chatStream?.continueChatResult?.thinking_break;
	            if (data?.chatStream?.continueChatResult?.isEnd) {
	                hasStreamingEnded = true;
	            }
	            if (!msgContent.startsWith("WaitMessage:")) {
	                if(msgContent.split("```").length - 1 === 1){
	                    return;
	                }
	                if (isWaitingForAiResponse && msgContent.trim().length > 0) {
	                    isWaitingForAiResponse.current = false
	                }

	                const assistantObject = getAssistantObj(data, accordianMessageArray)

	                setHomepageSearchChatMessages((prev) => {
	                    const updatedMessages = [...prev];
	                    if (updatedMessages[updatedMessages.length - 1].sender === 'assistant') {
	                        // Regular expression to match the extraMetaData block
	                        const extraMetaDataMatch = msgContent.match(/```extraMetaData\s+([\s\S]*?)```/);
	                        // const match = markdownContent.match(extraMetaDataMatch);
	                        if (extraMetaDataMatch && extraMetaDataMatch.length > 1) {
	                            try {
	                                // Clean the JSON string by removing any newlines or extra spaces
	                                const cleanedMetaData = extraMetaDataMatch[1].replace(/\n/g, '').replace(/\s+/g, ' ');
	                                // Parse the extracted metadata JSON
	                                const metaData = JSON.parse(cleanedMetaData);
                                    // console.log(metaData)
	                                // Remove the extraMetaData from content for display
	                                const contentWithoutMetaData = msgContent.replace(extraMetaDataMatch[0], '').trim();
	                                updatedMessages[updatedMessages.length - 1] = {
	                                    ...updatedMessages[updatedMessages.length - 1],
	                                    text: contentWithoutMetaData,
	                                    metaData: metaData,
	                                    explainabilityData: assistantObject,
	                                    hasStreamingEnded: hasStreamingEnded,
	                                    isOrchestrator: isOrchestrator,
	                                    isThinkingBreak: isThinkingBreak,
	                                    shouldAutoClick:showPreviewWindow ? false : true,
	                                    // messageUuid: generateUniqueId()
	                                }
	                            } catch (error) {
	                                console.error('Error parsing extraMetaData:', error);
	                            }
	                        } else {
	                            // If no extraMetaData found, store the entire content
	                            updatedMessages[updatedMessages.length - 1].text = msgContent;
	                            updatedMessages[updatedMessages.length - 1].explainabilityData = assistantObject;
	                            updatedMessages[updatedMessages.length - 1].hasStreamingEnded = hasStreamingEnded;
	                            updatedMessages[updatedMessages.length - 1].isOrchestrator = isOrchestrator;
	                            updatedMessages[updatedMessages.length - 1].isThinkingBreak = isThinkingBreak;
	                            // updatedMessages[updatedMessages.length - 1].messageUuid = generateUniqueId();
	                        }
	                    } else {
	                        updatedMessages.push({ text: msgContent, sender: 'assistant', timestamp: formatTime(new Date()), explainabilityData: assistantObject, hasStreamingEnded: hasStreamingEnded, messageUuid: generateUniqueId() });
	                    }
	                    return updatedMessages;
	                });
	            }
	        },
	        onStreamEnd: () => setIsStreaming(false),
	        onError: (error: any) => console.error('Stream error:', error),
	    };
	
	    // Start the streaming process
	    setIsStreaming(true);
	    isWaitingForAiResponse.current = true;
	    responseAbortController.current = new AbortController();
	    startChatStream({ ...params, signal: responseAbortController.current.signal });
        setReRouteToAssistantName(LLM_STUDIO_CHAT_ASSISTANT_NAME)
    };

    useEffect(() => {
        if (shouldCallToRedirectedAssistant
            && (reRouteToAssistantName !== LLM_STUDIO_MODEL_RECOMMENDATION_ASSISTANT_NAME
                || (reRouteToAssistantName === LLM_STUDIO_MODEL_RECOMMENDATION_ASSISTANT_NAME
                    //@ts-ignore
                    // && chatContext.metadataOfCurrentTestDroveTrainingPod?.trainingPodId === currentPageData?.metadata?.trainingPodId
                    && chatContext.metadataOfCurrentTestDroveTrainingPod?.descriptionOfDataSelectedForTestDrive
                )
            )
        ) {
            callToRedirectedAssistant();
        }
    }, [shouldCallToRedirectedAssistant, chatContext.metadataOfCurrentTestDroveTrainingPod, reRouteToAssistantName])

    const handleClearHistory = async () => {
        setCalledResetChatHistoryService(true);
    await resetChatHistoryService();
    if (responseAbortController.current) {
        responseAbortController.current.abort();
        }
    // setHomepageSearchChatMessages([]); 
    setIsStreaming(false);
    setShowPreviewWindow(false)
    GuestSessionIdService.clearGuestSessionId();
    };  
    const handleStopStream = () => {
    setIsStreaming(false);
    // stopChatStream(); 
    setHomepageSearchChatMessages((prev) => {
        const updatedMessages = [...prev];
        // Update the last message to reflect that the stream was stopped
        updatedMessages[updatedMessages.length - 1].text = 'Stream stopped.';
        return updatedMessages;
    });
    };

    const debouncedHandleShowMoreAIDetailsWindow = useDebouncedCallback(
        (metaData: IAIresponseMetaData, messageUuid: string) => {
            // console.log(metaData)
            clearAllStates()
            setPageTypeInPreviewWindow(metaData?.pageType);
            // setShowPreviewWindow(true);
            // setSplitIntoTwoColumns(true);
            // setIsShowBrowsingResults(false);
    
            if (metaData?.pageType === "summary_page") {
                setShowPreviewWindow(true);
                setSplitIntoTwoColumns(true);
                removeQueryParamsFromUrl()
                setIsShowBrowsingResults(false);
                fetchModelSummaryDetails(metaData);
                setPreviewWindowHeadingText(metaData?.button_text || "Model Summary");
                pushPageInWorkModeStack({
                    pageType: metaData?.pageType,
                    aiResponseMetaData: metaData,
                    messageUuid: messageUuid,
                    metadata: {
                        pageTitle: metaData.button_text || "Model Summary",
                        trainingPodId: metaData.trainingPods?.[0]?.trainingPodId,
                    },
                });
            } else if (metaData?.pageType === "model_information_page") {
                chatContext.onCloseWindowSideEffects();
                removeQueryParamsFromUrl()
                // setSplitIntoTwoColumns(false);
                if(splitIntoTwoColumns){
                    pushPageInWorkModeStack({
                        pageType: metaData?.pageType,
                        aiResponseMetaData: metaData,
                        messageUuid,
                        metadata: {
                            pageTitle: metaData?.button_text || "Search Results",
                            // trainingPodId: metaData.trainingPods?.[0]?.trainingPodId,
                        },
                    });
                    setShowPreviewWindow(true);
                    // setSplitIntoTwoColumns(true);
                    setIsShowBrowsingResults(false);
                }
                else{
                    setIsFetchingChatBrowsingResults(true)
                    setIsShowBrowsingResults(true);
                    setShowPreviewWindow(false);
                    setSplitIntoTwoColumns(false)
                }
                fetchModelInformationDetails(metaData);
            } else if (metaData?.pageType === "testing_data") {
                // setSplitIntoTwoColumns(false);
                // setShowPreviewWindow(false);
                // setIsFetchingChatBrowsingResults(true)
                // setIsShowBrowsingResults(true);
                chatContext.onCloseWindowSideEffects();
                removeQueryParamsFromUrl()
                // setSplitIntoTwoColumns(false);
                if(splitIntoTwoColumns){
                    pushPageInWorkModeStack({
                        pageType: metaData?.pageType,
                        aiResponseMetaData: metaData,
                        messageUuid,
                        metadata: {
                            pageTitle: "Test Data",
                            // trainingPodId: metaData.trainingPods?.[0]?.trainingPodId,
                        },
                    });
                    setShowPreviewWindow(true);
                    // setSplitIntoTwoColumns(true);
                    setIsShowBrowsingResults(false);
                }
                else{
                    setIsFetchingChatBrowsingResults(true)
                    setIsShowBrowsingResults(true);
                    setShowPreviewWindow(false);
                    setSplitIntoTwoColumns(false)
                }
                fetchModelInformationDetails(metaData);
            }else if (metaData?.pageType==="test_drive"){
                setSplitIntoTwoColumns(true)
                setShowPreviewWindow(true)
                setIsShowBrowsingResults(false);
                fetchModelSummaryDetails(metaData)
                pushPageInWorkModeStack({
                    pageType: metaData?.pageType,
                    aiResponseMetaData: metaData,
                    searchQueryBeforeThisPage: window.location.search,
                    messageUuid,
                    metadata: {
                        pageTitle: `Test drive "${metaData.trainingPods?.[0]?.training_pod_name}"`,
                        trainingPodId: metaData.trainingPods?.[0]?.trainingPodId,
                        modelCollectionId:  metaData.trainingPods?.[0]?.modelCollectionId === 'undefined' ? '' : metaData.trainingPods?.[0]?.modelCollectionId || '',
                    },
                });
            }
             else if (metaData?.pageType === "model_analytics_page") {
                setShowPreviewWindow(true);
                setSplitIntoTwoColumns(true);
                setIsShowBrowsingResults(false);
                pushPageInWorkModeStack({
                    pageType: metaData?.pageType,
                    aiResponseMetaData: metaData,
                    searchQueryBeforeThisPage: window.location.search,
                    messageUuid,
                    metadata: {
                        pageTitle: metaData.button_text || "Test Data",
                        trainingPodId: metaData.trainingPods?.[0]?.trainingPodId,
                    },
                });
            }
             else if (metaData?.pageType === "model_cloning_page") {
                const currentPageData = workModePageStack.length ? workModePageStack[workModePageStack.length-1] : null
                if(currentPageData) {
                    if(currentPageData.messageUuid === messageUuid && currentPageData.aiResponseMetaData?.pageType === 'model_cloning_page') {
                        if(['initial', 'ready', 'processing'].includes(modelCloningStage)) {
                            setModelCloningStage(modelCloningStage);
                            return;
                        } 
                        else if(modelCloningStage==='done') switchToPreviousPageInWorkModeStack()
                    }
                }
                
                setSplitIntoTwoColumns(true);
                setShowPreviewWindow(true);
                setIsShowBrowsingResults(false);
                fetchModelSummaryDetails(metaData)
                pushPageInWorkModeStack({
                    pageType: metaData?.pageType || 'model_cloning_page',
                    aiResponseMetaData: metaData,
                    searchQueryBeforeThisPage: window.location.search,
                    messageUuid,
                    metadata: {
                        pageTitle: "Clone",
                        trainingPodId: metaData.trainingPods?.[0]?.trainingPodId,
                    },
                })
                setModelCloningStage('initial')
            }
        },
        300 // 300ms debounce delay
    );

    const setStatesOnClickingExplainability = () => {
        setSplitIntoTwoColumns(true);
        setShowPreviewWindow(true);
        setIsShowBrowsingResults(false);
    }

    const handleShowMoreAIDetailsWindow = (metaData:IAIresponseMetaData, messageUuid: string)=>{
        debouncedHandleShowMoreAIDetailsWindow(metaData, messageUuid)
    }
    const closePreviewWindow = ()=>{
        chatContext.onCloseWindowSideEffects();
        removeQueryParamsFromUrl()
        setShowPreviewWindow(false)
        setPreviewWindowHeadingText('')
        setAvailableModelCollections([])
        setModelSummaryPageData([])
        setModelCloningStage('')
    }
    const backArrowOnClickHandler=()=>{
        if(homepageSearchChatMessages.length>0 && splitIntoTwoColumns){
            setIsActive(true)
        }else{
            setIsActive(false)
        }
        removeQueryParamsFromUrl()
        setSplitIntoTwoColumns(false)
        setShowPreviewWindow(false)
    }
    useEffect(() => {
        const firstContainer = firstContainerRef.current;
        const stickyElement = stickyRef.current;
    
        // Ensure both refs are available before proceeding
        if (!firstContainer || !stickyElement|| splitIntoTwoColumns) return;
    
        // Create an IntersectionObserver
        const observer = new IntersectionObserver((entries) => {
            if(!isShowBrowsingResults || !isActive || splitIntoTwoColumns){
                stickyElement.style.display = "none";
                return
            }
          entries.forEach((entry) => {
            if (!entry.isIntersecting) {
                stickyElement.style.display = "flex";
            } else {
              stickyElement.style.display = "none";
            }
          });
        });
    
        // Start observing the first container
        observer.observe(firstContainer);
    
        // Cleanup observer on component unmount
        return () => {
          observer.disconnect();
        };
      }, [isShowBrowsingResults,isActive,splitIntoTwoColumns]);


    //   const onClickViewMoreInTestDataPreview = () => {
    //     const 
    //     pushPageInWorkModeStack({
    //         pageType: metaData?.pageType,
    //         aiResponseMetaData: metaData,
    //         searchQueryBeforeThisPage: window.location.search,
    //         metadata: {
    //             pageTitle: metaData.button_text || "Test Data",
    //             trainingPodId: metaData.trainingPods?.[0]?.trainingPodId
    //         }
    //     })
    //   }

    // const pushPageInModelRecommendationStage = (metaData: IAIresponseMetaData, messageUuid: string, stage: string)=>{
    //     switch (stage) {
    //         case 'model_cloning_page':
    //             setSplitIntoTwoColumns(true);
    //             setShowPreviewWindow(true);
    //             setIsShowBrowsingResults(false);
    //             pushPageInWorkModeStack({
    //                 pageType: stage,
    //                 aiResponseMetaData: metaData,
    //                 searchQueryBeforeThisPage: window.location.search,
    //                 messageUuid,
    //                 metadata: {
    //                     pageTitle: "Clone",
    //                 },
    //             })
    //             setModelCloningStage('initial')
    //             break;
    //         case 'model_information_page':
    //             chatContext.onCloseWindowSideEffects();
    //             removeQueryParamsFromUrl()
    //             if(splitIntoTwoColumns){
    //                 pushPageInWorkModeStack({
    //                     pageType: stage,
    //                     aiResponseMetaData: metaData,
    //                     messageUuid,
    //                     metadata: {
    //                         pageTitle: "Suggest models",
    //                     },
    //                 });
    //                 setShowPreviewWindow(true);
    //                 setIsShowBrowsingResults(false);
    //             }
    //             else{
    //                 setIsFetchingChatBrowsingResults(true)
    //                 setIsShowBrowsingResults(true);
    //                 setShowPreviewWindow(false);
    //                 setSplitIntoTwoColumns(false)
    //             }
    //             fetchModelInformationDetails(metaData);
    //             break;
    //         default:

    //             break;
    //     }
        
    // }

    return (
         <Box className={`${homepageCss.container} ${isActive && splitIntoTwoColumns ? homepageCss['blur-background'] : ''}`}>   
             {isActive && splitIntoTwoColumns && <Box className={homepageCss.blurOverlay}></Box>}
            <Box className={homepageCss.header} style={{alignItems:`${splitIntoTwoColumns ? 'flex-start':'center'}`}}>
                <Box className={homepageCss.rlefLogoWrapper}>
                    {/* {isActive && 
                    <IconButton style={{padding:'4px'}}>
                    <ArrowBackIcon onClick={backArrowOnClickHandler} style={{cursor:'pointer',width:'24px',height:'24px'}}/>
                    </IconButton>
                    } */}
                    <div style={{position:'relative'}}>
                    <RLEFLogo onClick={backArrowOnClickHandler} style={{cursor:'pointer',height:'20px',width:'130px'}}/>
                     {!splitIntoTwoColumns && <Typography variant='h4' style={{width:'200px',marginTop:'4px', color: '#64748B', fontWeight: 500,letterSpacing: '-0.42px',fontSize:'14px',fontFamily:'Inter' ,position:'absolute',top:'125%',opacity:`${isActive?'1':'0'}`,transition: 'all 0.5s ease-in-out',left:`${isActive?'0':'100px'}`}}>AI Marketplace</Typography>}
                    </div>
                    </Box>
                {!isActive && <Box display="flex" flexDirection="column" alignItems="center" justifyContent="center" style={{textAlign:'center',paddingRight:'3rem',position:'relative',paddingTop:'2rem'}}>
                    <Typography variant='h4' style={{ color: '#64748B', fontWeight: 700,letterSpacing: '-1.2px',fontSize:'2.5rem' }}>AI Marketplace</Typography>
                    <Typography style={{ color: '#64748B',fontFamily:'Inter',fontWeight:400}}>Find AI Models to Solve your Problems</Typography>
                </Box>}
                <Box className={homepageCss.hamburgerProfileContainer} style={{display:`${splitIntoTwoColumns ? 'none' : ''}`}}>
                    <ClickAwayListener onClickAway={() => { setShowLogout(false) }}>
                        <Box className={homepageCss.profileIconWrapper}>
                            <ProfileSvg onClick={() => setShowLogout(prev => !prev)} />
                            {showLogout && <Box className={homepageCss.profileIconDrawer}>
                                <Box className={homepageCss.logoutWrapper} onClick={logout}><Typography color="primary">Logout</Typography></Box>
                            </Box>
                            }
                        </Box>
                    </ClickAwayListener>
                    <Box className={homepageCss.hamburgerIconWrapper}>
                        {
                            isHamburgerOpened ? <IconButton color="primary" style={{ padding: '6px' }} onClick={() => handleClickHamburgerOpenClose(false)} >
                                <CloseIcon style={{fontSize:'32px',color:'#64748B'}}/>
                            </IconButton> : <IconButton color="primary" style={{ padding: '6px' }} onClick={() => handleClickHamburgerOpenClose(true)}>
                                <MenuIcon style={{fontSize:'32px',color:'#64748B'}} />
                            </IconButton>
                        }
                        {isHamburgerOpened && <Box className={homepageCss.navLinks}>
                            <Link href='admin/projects'>Projects</Link>
                            <Link href='#'>AI News</Link>
                            <Link href='admin/ai-market-place'>AI Marketplace</Link>
                            <Link href='https://autoai-backend-exjsxe2nda-uc.a.run.app/api-docs' target="_blank" >API Documentation</Link>
                            <Link href='admin/configurations'>Configurations</Link>
                        </Box>}
                    </Box>
                </Box>  
            </Box>
            <Box display="flex" flexDirection={`${(isActive && splitIntoTwoColumns) ? 'row' : 'column'}`} style={{ width: `${(isActive && splitIntoTwoColumns) ? '100%' : '95%'}`, minHeight: '68vh',backgroundColor:'#FFFFFF' }}>
                <Split
                    {...(isActive && showPreviewWindow && splitIntoTwoColumns
                        ? {
                            getGridProps: () => ({
                                style: {
                                    // gridTemplateColumns: '1fr 1fr', // Two columns for split behavior
                                    gridTemplateColumns: '3fr 16px 7fr', // Initial widths for the two divs
                                },
                            }),
                            getGutterProps: (direction: 'column', track: number) => ({
                                className: homepageCss['gutter-col'],
                            }),
                        }
                        : {
                            getGridProps: () => ({
                                style: {
                                    gridTemplateColumns: '1fr', // Single column for no split behavior
                                },
                            }),
                            getGutterProps: () => ({}), // No gutter in single column layout
                        })}
                >
                    {({
                        getGridProps,
                        getGutterProps,
                    }: {
                        getGridProps: () => Record<string, any>;
                        getGutterProps: (direction: 'column' | 'row', track: number) => Record<string, any>;
                    }) => (
                        <div className={isActive && showPreviewWindow && splitIntoTwoColumns ? homepageCss.grid : isActive && !showPreviewWindow && splitIntoTwoColumns ?homepageCss.fullWidth:''} {...getGridProps()}>
                            <div className={`${!splitIntoTwoColumns ? homepageCss.content : homepageCss.contentIntoSplitColumns} ${showPreviewWindow && isActive && splitIntoTwoColumns ? `${homepageCss.contentIntoSplitColumns}` : ""} ${(isActive && splitIntoTwoColumns) ? `${homepageCss.contenHeight}` : ""}`}>
                                {/* <Typography variant='h3' style={{ color: '#64748B', fontWeight: 700 }}>AI Marketplace</Typography>
                    <Typography style={{ color: '#64748B',marginBottom:'64px',fontFamily:'Inter',fontWeight:400 }}>Find AI Models to Solve your Problems</Typography> */}
                    <div
                        // className={`${homepageCss.searchContainer} ${isActive ? homepageCss.active : ''}`}
                        className={`${homepageCss.searchContainer} ${isActive ? homepageCss.active : ''} ${splitIntoTwoColumns ? homepageCss.styleOfSearchContainerInSplitIntoTwoColumns :''}`}
                        // ref={searchContainerRef}
                        ref={firstContainerRef}
                        style={{ width: isActive && showPreviewWindow && splitIntoTwoColumns ? '95%' : '70%' }}
                    >
                        <RlefSearchComponent isActive={isActive} homepageSearchChatMessages={homepageSearchChatMessages} isStreaming={isStreaming} setIsStreaming={setIsStreaming} streamingMessageIndex={streamingMessageIndex} isWaitingForAiResponse={isWaitingForAiResponse} handleShowMoreAIDetailsWindow={handleShowMoreAIDetailsWindow} handleSendMessage={handleSendMessage} handleInputChange={handleInputChange} searchQuery={searchQuery} splitIntoTwoColumns={splitIntoTwoColumns} handleFocusOnSearch={handleFocus} 
                            isPreviewWindowOpen={showPreviewWindow}
                            setStatesOnClickingExplainability={setStatesOnClickingExplainability}
                            // setChatMessages={setHomepageSearchChatMessages} pushPageInModelRecommendationStage={pushPageInModelRecommendationStage}
                        />
                    </div>
                    {
                        !splitIntoTwoColumns && <div ref={!splitIntoTwoColumns ? stickyRef : null} style={{ width: '95vw', backgroundColor: '#FFFFFF', position: 'sticky', display: "none", top: '0', justifyContent: 'space-between', alignItems: 'center', padding: '24px 56px', borderRadius: '0 0 1.5rem 1.5rem', boxShadow: '0 0 16px 0 rgba(0, 0, 0, 0.24)', zIndex: 100000 }}>
                            <StickySearchContainer isActive={isActive} isSearchChatContainerHide={true} homepageSearchChatMessages={homepageSearchChatMessages} isStreaming={isStreaming} streamingMessageIndex={streamingMessageIndex} isWaitingForAiResponse={isWaitingForAiResponse} handleShowMoreAIDetailsWindow={handleShowMoreAIDetailsWindow} handleSendMessage={handleSendMessage} handleInputChange={handleInputChange} searchQuery={searchQuery} splitIntoTwoColumns={splitIntoTwoColumns} handleClearHistory={handleClearHistory} handleFocusOnSearch={handleFocus} />
                        </div>
                    }  
                    
                    
                    {
                        isActive && isShowBrowsingResults && 
                        <div className={homepageCss.chatResultContainer} style={{ overflow: 'auto', width: '71%', zIndex: 1,padding:'8px'}}>
                            {isFetchingChatBrowsingResults ?
                                <div className={homepageCss.gifLoader}>
                                    <img src={gif} style={{ objectFit: 'contain', width: '100%', height: '100%'}} />
                                </div> : <>
                                        <div style={{ display: 'flex', height: '8px', zIndex: 1, margin: '1.5rem auto', width: '95%', alignItems: 'center', gap: '.5rem'}}>
                                            <div
                                                style={{
                                                    height: '1px',
                                                    flexGrow:1,
                                                    zIndex: 1,
                                                    background: 'linear-gradient(to left,#000000 , #ffffff )', // Darker on the right, lighter on the left
                                                }}
                                            />
                                            <span style={{ fontFamily: 'Inter', fontWeight: 500, fontSize: '24px' }}>Results</span>
                                            <div
                                                style={{
                                                    height: '1px',
                                                    flexGrow:1,
                                                    zIndex: 1,
                                                    background: 'linear-gradient(to right, #000000, #ffffff)', // Darker on the left, lighter on the right
                                                }}
                                            />
                                        </div>
                                        {
                                            pageTypeInPreviewWindow === "model_information_page" ? <HomepageModelsCard scenario="homePageChat" handleShowMoreAIDetailsWindow={handleShowMoreAIDetailsWindow} isFetching={isFetchingChatBrowsingResults} availableModelsData={availableModelCollections} fetchedModelResources={fetchedModelInformationPageResources} modelFetchStatus={modelInformationPageFetchStatus} />
                                            : pageTypeInPreviewWindow === "testing_data" ?
                                                <TestDatasetPreview scenario="homePageBrowsingMode" availableModelsData={availableModelCollections} fetchedModelResources={fetchedModelInformationPageResources} modelFetchStatus={modelInformationPageFetchStatus} isFetching={isFetchingChatBrowsingResults} /> :
                                                <>No results</>
                                        }
                                </>
                            }
                        </div>
                        
                    }
                    
                    
                    {/* <Box className={homepageCss.backgroundEllipseWrapper}>
                        <BackgroundEllipseSvg className={homepageCss.ellipse}/>
                    </Box> */}
                            </div>


                            {isActive && showPreviewWindow && splitIntoTwoColumns &&
                                <>
                                    {/* Gutter */}
                                    <div className={`${homepageCss['gutter-col']} ${homepageCss['gutter-col-1']}`} {...getGutterProps('column', 1)} >
                                        <div style={{borderRadius: '16px', background: '#DBDFE5', width: '4px', height: '64px', pointerEvents: 'none'}} />
                                    </div>
                                    <div>
                                    <HomePageChatPreviewWindow
                                        handleShowMoreAIDetailsWindow={handleShowMoreAIDetailsWindow}
                                        isPreviewWindowLoading={isPreviewWindowLoading}
                                        modelSummaryPageData={modelSummaryPageData}
                                        closePreviewWindow={closePreviewWindow}
                                        fetchedModelInformationPageResources={fetchedModelInformationPageResources}
                                        availableModelCollections={availableModelCollections}
                                        setShouldShowAnalysis={setShouldShowAnalysis}
                                        // isModelSummaryPageDataFetching={isModelSummaryPageDataFetching}
                                        modelInformationPageFetchStatus={modelInformationPageFetchStatus} />
                                    </div>
                                </>
                            }
                        </div>)}
            </Split>
            </Box>
            <Box className={classes.root} mt={(isActive && isShowBrowsingResults) ? 6 : 0}>
                <Grid container spacing={4}>
                    {/* AI News */}
                    <Grid item xs={12} md={4}>
                        <Box className={homepageCss.aiNewsContainer}>
                            <div className={classes.sectionHeader}>
                                <Typography variant="h6" className={homepageCss.columnSectionText} style={{fontFamily:"Inter",fontWeight:600}}>AI News</Typography>
                                <Box display="flex" style={{ gap: '0.5rem' }}>
                                    <Box className={homepageCss.filterContainer} >
                                        <IconButton color="primary" style={{ padding: '5px' }}>
                                            <ImportExportIcon style={{ fontSize: '19px' }} />
                                        </IconButton>
                                    </Box>
                                    <Box className={homepageCss.filterContainer}>
                                        <IconButton color="primary" style={{ padding: '6px',borderRadius:'30%' }}>
                                            <FilterIcon />
                                            <Typography variant="body2" className={homepageCss.filterText} style={{fontFamily:"Inter",fontWeight:500}}>Filters</Typography>
                                        </IconButton>
                                    </Box>
                                </Box>
                            </div>
                            {/* News Cards */}
                            
                            {
                            // Array.from({ length: 5 }).map((_, index) => (
                            //     <Card key={index} className={classes.card} style={{ width: '100%' }}>
                            //         <CardContent className={classes.cardContent}>
                            //             <Box display="flex" alignItems="center" justifyContent="space-between" style={{ width: '100%' }} >
                            //                 <img src={AiNewsImage} alt="News" className={classes.newsImage} />
                            //                 <Box ml={2} mr={2} style={{display:'flex',flexDirection:'column',justifyContent:'space-between'}}>
                            //                     <Box display="flex" justifyContent="space-between" alignItems="center" style={{ gap: '0.5rem', flexWrap: 'wrap' }}>
                            //                         <Typography variant="body2" className={classes.authorDate}>
                            //                             Phoenix Baker • 19 Jan 2022
                            //                         </Typography>
                            //                         {/* <Box display="flex" p={0.5} style={{ borderRadius: '4px', border: '0.8px solid #0AB257', background: '#EFF9F4', gap: '0.2rem' }}>
                            //                             <Trendingicon />
                            //                             <Typography variant="body2" className={classes.TrendingText}>
                            //                                 Trending
                            //                             </Typography>
                            //                         </Box> */}
                            //                     </Box>
                            //                     <Typography variant="h6" className={classes.newsTitle}>
                            //                         Migrating to Linear 101
                            //                     </Typography>
                            //                     <Typography variant="body2" className={classes.newsDescription}>
                            //                         A comprehensive guide on migrating your team to Linear, from setup to best practices.
                            //                     </Typography>
                            //                     <Box mt={1}>
                            //                         <Chip label="Design" size="small" style={{fontFamily:'Inter',fontWeight:500}} className={classes.designChip} clickable />
                            //                         <Chip label="Research" size="small" style={{fontFamily:'Inter',fontWeight:500}} className={classes.researchChip} clickable />
                            //                     </Box>
                            //                 </Box>
                            //             </Box>
                            //         </CardContent>
                            //     </Card>
                            // ))
                            }
                            {/* <Box display="flex" justifyContent="center">
                                <Button variant="outlined" className={classes.actionButton} size='small'>View All</Button>
                            </Box> */}
                            <Box display="flex" flexDirection="column" justifyContent="center" style={{ width: '100%', height: 0, paddingBottom: '100%'}}>
                                <Typography variant='body2'>Coming soon...</Typography>
                            </Box>
                        </Box>
                    </Grid>

                    {/* Popular Models */}
                    <Grid item xs={12} md={4} >
                        <Box className={homepageCss.popularModelsContainer}>
                            <div className={classes.sectionHeader}>
                                <Typography variant="h6" className={homepageCss.columnSectionText} style={{fontFamily:"Inter",fontWeight:600}}>Popular Models</Typography>
                                <Box className={homepageCss.filterContainer}>
                                    <IconButton color="primary" style={{ padding: '6px',borderRadius:'30%' }} ref={PopularModelsfilterButtonRef} onClick={handleTogglePopularModelsFilter}>
                                        <FilterIcon />
                                        <Typography variant="body2" className={homepageCss.filterText} style={{fontFamily:"Inter",fontWeight:500}}>Filters</Typography>
                                    </IconButton>
                                </Box>
                                <FilterModel
                                    open={isPopularModelsFilterModalOpen}
                                    onClose={handleClosePopularModelsFilterModal}
                                    onApply={handleApplyPopularModelsFilters}
                                    buttonPosition={PopularModelsfilterButtonRef.current}
                                    // onClearFilters = {handleClearPopularModelsFilters}
                                    //@ts-ignore
                                    params={selectedPopularModelsFilters}
                                />
                            </div>
                            {/* Model Cards */}
                            {isPopularModelsDataFetching ?
                                <ShimmerPlaceholder />
                                :
                                <>
                                    <HomepageModelsCard
                                        availableModelsData={availablePopularModelsData}
                                        modelFetchStatus={popularModelFetchStatus}
                                        fetchedModelResources={fetchedPopularModelResources}
                                        scenario="popularModelsData"
                                    />
                                </>
                            }
                        </Box>
                    </Grid>

                    {/* Latest Models */}
                    <Grid item xs={12} md={4}>
                        <Box className={homepageCss.latestModelsContainer}>
                            <div className={classes.sectionHeader}>
                                <Typography variant="h6" className={homepageCss.columnSectionText} style={{fontFamily:"Inter",fontWeight:600}}>Latest Models</Typography>
                                <Box className={homepageCss.filterContainer}>
                                    <IconButton color="primary" style={{ padding: '6px',borderRadius:'30%' }} ref={LatestModelsfilterButtonRef} onClick={handleToggleLatestModelsFilter}>
                                        <FilterIcon />
                                        <Typography variant="body2" className={homepageCss.filterText} style={{fontFamily:"Inter",fontWeight:500}}>Filters</Typography>
                                    </IconButton>
                                </Box>
                                <FilterModel
                                    open={isLatestModelsFilterModalOpen}
                                    onClose={handleCloseLatestModelFilterModal}
                                    onApply={handleApplyLatestModelsFilters}
                                    buttonPosition={LatestModelsfilterButtonRef.current}
                                    //@ts-ignore
                                    params={selectedLatestModelsFilters}
                                />
                            </div>
                            {/* Model Cards */}
                            {isLatestModelsDataFetching ?
                                <ShimmerPlaceholder />
                                : <>
                                <HomepageModelsCard
                                    availableModelsData={availableLatestModelsData}
                                    modelFetchStatus={latestModelFetchStatus}
                                    fetchedModelResources={fetchedLatestModelResources}
                                    scenario="latestModelsData"
                                />
                                </>
                            }
                        </Box>
                    </Grid>
                </Grid>
            </Box>
            {
                !splitIntoTwoColumns && <Box className={homepageCss.backgroundAnimationAbsoluteWrapper}>
                <div className={homepageCss.blobGreen}></div>
                <div className={homepageCss.blobViolet}></div>
            </Box> 
            }
        </Box>
    );
};

export default RLEFHomePage;
export {useStyles}
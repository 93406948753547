import React, { useEffect, useState, useRef, useContext } from 'react'
import { connect, ConnectedProps } from 'react-redux';
import { useHistory, useLocation } from 'react-router';

import { DataSortFieldDefaultValue, IModel, IReduxState, LastSearched, LastSearchedNormalized, loaders, SortOrderDefaultValue, useQuery } from '../../../../../../../common';

import { setSelectedProject, getProjectModels, setTopActionButton, setSideDrawer, addBreadcrumbs } from '../../../../../../../store/actions';
import ModelCard from './ModelCard';
import { Box, CircularProgress, Grid, makeStyles } from '@material-ui/core';
import AddModel from './AddModel';
import ModelAnalytics from './Analytics';
import { setBreadcrumbsTillProjectSelected } from '../../../../../../../store/actions/coreActions';
import { ResourceLimitQueryParam, ResourceOffsetQueryParam, ResourceSortFieldQueryParam, ResourceSortOrderQueryParam, ResourceStatusQueryParam } from '../features/model/features/data/components/TableContent';
import ModelGroupList from './modelGroupList/modelGroupList';
import useDeleteUrlSearchParams from '../../../../../../../hooks/useDeleteUrlSearchParams';
import css from "./ModelsList.module.css";
import { SearchData, SearchDataContext } from '../../../../../../../common/contexts/searchContext/SearchDataContext';
import UserDBService from '../../../../../../../services/UserDBService';

interface IProps extends TPropsFromRedux {
    goInsideModel: (data: IModel) => void;
    scenario?: "browseCollectionsPopup";
}

const useStyles = makeStyles((theme) => ({
    loader: {
        ...loaders.centralLoader,
        left: "50%"
    }
}));
// function EmptyDiv() {
//     return (
//       <div></div>
//     )
//   }

export enum QUERY_PARAMS {
    projectId="project",
    // fetchModels="fetchModels"
}

function ModelsList(props: IProps) {

    const { isFetching, getProjectModels, models, setTopActionButton, setSideDrawer, addBreadcrumbs } = props;
    const classes = useStyles();
    const history = useHistory();
    const location = useLocation();
    const [activeModels, setActiveModels] = useState<IModel[]>([]);
    const [inactiveModels, setInactiveModels] = useState<IModel[]>([]);

    const urlSearchParams = useQuery();

    const projectId = urlSearchParams.get(QUERY_PARAMS.projectId) || "";
    const {searchData,setSearchData} = useContext(SearchDataContext);
    const [searchResultsActiveModel,setSearchResultsActiveModel] = useState<IModel[]>([]);
    const [searchResultsInActiveModel,setSearchResultsInActiveModel] = useState<IModel[]>([]);
    const [lastSearchedModels,setLastSearchedModels] = useState<LastSearchedNormalized[]>([]);
    // const shouldFetchModels = urlSearchParams.get(QUERY_PARAMS.fetchModels);

    const deleteUrlSearchParams = useDeleteUrlSearchParams();
    const deleteUrlSearchParamsRef = useRef(deleteUrlSearchParams);
    deleteUrlSearchParamsRef.current = deleteUrlSearchParams;

    useEffect(() => {
        setTopActionButton({ type: "addModel", onClickComponentToRender: AddModel });
        if(props.scenario !== "browseCollectionsPopup")
            props.setBreadcrumbsTillProjectSelected();
    }, [])
    useEffect(()=>{
        setSearchData({
            autoCompleteData:[],
            searchQuery:'',
            autoCompleteValueChange:''
        })
        UserDBService.getLastSearchedTasks(projectId)
        .then((res)=>{
            const lastSearchedTasks = res.lastSearchedTasks as LastSearched[];
            // the structure is id:{_id,name} and need to convert to {_id,name}
            const normalized = lastSearchedTasks.map(item=>item.id);
            setLastSearchedModels(normalized)
            setSearchData((prevData: SearchData)=>{
                return {...prevData,autoCompleteData:normalized}
            })
        })
        .catch(err=>console.log(err))
    },[])
    
    const fetchModelsFromDB = () => {
        const query = new URLSearchParams(location?.search);
        const limit = query?.get('limit') || '1000';
        const offset = query?.get('offset') || '1';
        const project = query?.get('project') || '';
        if (project) {
            (async () => {
                await getProjectModels({ project, offset, limit, getModelGroupName: "true" , collectionsExist : true});
                // addBreadcrumbs([{ label: route.params.projectName, path: location.pathname }]);
            })();
            setSideDrawer({ type: 'notification', component: ModelAnalytics, isOpen: false });
        } else {
            // TODO handle error case
        }        
    }

    const fetchModelsRef = useRef(fetchModelsFromDB);
    fetchModelsRef.current = fetchModelsFromDB;

    useEffect(() => {
        fetchModelsRef.current();
    }, [location]);

    useEffect(() => {
      if(models){
        
        const currentDate = new Date();
        let active : IModel[] = [];
        let inActive : IModel[] = [];
        models.forEach(model => {
            if(model?.lastUpdatedAt){
                const modelDate = new Date(model?.lastUpdatedAt)
                if((currentDate.valueOf() - modelDate.valueOf())/(1000*60*60*24) <= 1){
                    active.push(model)
                }else{
                    inActive.push(model)
                }
            }else{
                inActive.push(model);
            }
        })
        setActiveModels(active);
        setInactiveModels(inActive);
        console.log("active and inactive models are : ", active, inActive)
      }
    
      
    }, [models])
    useEffect(()=>{
        if(searchData.searchQuery){
            let active:LastSearchedNormalized[] = [];
            let inActive:LastSearchedNormalized[] = [];
            const reg = new RegExp(searchData.searchQuery,"i")
            if(activeModels.length > 0){
                const result = activeModels.filter(activeModel=>{
                    const shouldBeInResult = reg.test(activeModel.name)
                    return shouldBeInResult 
                })
                setSearchResultsActiveModel(result);
                active = result.map(item=>{
                    return {_id:item._id,name:item.name}
                })
            }
            if(inactiveModels.length > 0){
                const result = inactiveModels.filter(inactiveModel=>{
                    const shouldBeInResult = reg.test(inactiveModel.name)
                    return shouldBeInResult 
                })
                setSearchResultsInActiveModel(result);
                inActive = result.map(item=>{
                    return {_id:item._id,name:item.name}
                })
            }

            setSearchData((prevData: SearchData)=>{
                return {...prevData,autoCompleteData:[...active,...inActive]}
            })
        }
        else{
            setSearchData((prevData: SearchData)=>{
                return {...prevData,autoCompleteData:lastSearchedModels}
            })
        }
    },[searchData.searchQuery,activeModels,inactiveModels])
    useEffect(()=>{
        if(searchData.autoCompleteValueChange){
            let index = activeModels.findIndex(model=>model._id === searchData.autoCompleteValueChange)
            if(index!== -1)
                onClick(activeModels[index])
            index = inactiveModels.findIndex(model=>model._id === searchData.autoCompleteValueChange)
            if(index !== -1)
                onClick(inactiveModels[index])
        }
    },[searchData.autoCompleteValueChange])
    // useEffect(()=>{
    //     if (shouldFetchModels) {
    //         fetchModelsRef.current();
    //         deleteUrlSearchParamsRef.current([QUERY_PARAMS.fetchModels], "historyReplace")
    //     }
    // }, [shouldFetchModels])

    const onClick = (data: IModel) => {
        UserDBService.updateLastSearchedTasks(data._id);
        const queryParamString: string = `?${ResourceStatusQueryParam}=backlog&${ResourceOffsetQueryParam}=1&${ResourceLimitQueryParam}=10&project=${data?.project?._id}&model=${data._id}&type=${data.type}&${ResourceSortOrderQueryParam}=${SortOrderDefaultValue}&${ResourceSortFieldQueryParam}=${DataSortFieldDefaultValue}`;
        const newPath = `${location.pathname}/${data.name}/data/backlog${queryParamString}`;
        addBreadcrumbs([{ label: data.name, path: newPath }]);
        history.push(`${newPath}`);
    };

    return (
        <Grid container spacing={3}
            style={{
                maxHeight: "100%",
                overflow: "auto"
            }}
        >
            {
                isFetching 
                ?
                    <Box className={classes.loader}>
                        <CircularProgress size={30} />
                    </Box> 
                :
                    !models || models?.length === 0
                    ?
                        <div className={css.noModelsFoundText}>No tasks found</div>
                    :
                        <>
                        {(searchData.searchQuery?searchResultsActiveModel:activeModels)?.sort((prevModel: IModel, nextModel: IModel)=>{
                            // sorting by model name alphabetically case insensitive order
                            return prevModel.name.toLowerCase().localeCompare(nextModel.name.toLowerCase())
                        }).map(model => <Grid key={model._id} xs={12} md={4} lg={3} item>
                            <ModelCard model={model} onClick={props.goInsideModel} isActive={true}scenario="Model-View"/>
                        </Grid>)}
                        {(searchData.searchQuery?searchResultsInActiveModel:inactiveModels)?.sort((prevModel: IModel, nextModel: IModel)=>{
                            // sorting by model name alphabetically case insensitive order
                            return prevModel.name.toLowerCase().localeCompare(nextModel.name.toLowerCase())
                        }).map(model => <Grid key={model._id} xs={12} md={4} lg={3} item>
                            <ModelCard model={model} onClick={props.goInsideModel} isActive={false} scenario="Model-View"/>
                        </Grid>)}
                        </>
                        // {/* models?.sort((prevModel: IModel, nextModel: IModel)=>{
                        //     // sorting by model name alphabetically case insensitive order
                        //     return prevModel.name.toLowerCase().localeCompare(nextModel.name.toLowerCase())
                        // }).map(model => 
                        //     <Grid key={model._id} xs={12} md={4} lg={props.isSideDrawerOpen ? 5 : 3} item>
                        //         <ModelCard model={model} onClick={props.goInsideModel} />
                        //     </Grid>
                        // ) */}
            }
        </Grid>
    );
}

const mapProps = (state: IReduxState) => {
    return {
        isFetching: state.model.isFetching,
        selected: state.project.selected,
        models: state.model.models,
        total: state.model.total,
        isSideDrawerOpen: state.optionsDrawer.isOpen
    };
};

const connector = connect(mapProps, { getProjectModels, setSelectedProject, setTopActionButton, setSideDrawer, addBreadcrumbs, setBreadcrumbsTillProjectSelected });

export type TPropsFromRedux = ConnectedProps<typeof connector>

export default connector(ModelsList);

import { useCallback, useEffect, useRef, useState } from "react";
// @ts-ignore
import WaveSurfer from "wavesurfer.js/dist/wavesurfer.min";
// @ts-ignore
import WaveSurferRegionPlugin from "wavesurfer.js/dist/plugin/wavesurfer.regions.min";
// @ts-ignore
import WaveSurferTimelinePlugin from "wavesurfer.js/dist/plugin/wavesurfer.timeline.min";
import {
 IData,
 IModel,
 IModelType,
 ModelAnnotationLabelAvailable,
 ResourceStatus,
 TrimmedAudio,
} from "../../../../../../../../../../../../common";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableContainer from "@material-ui/core/TableContainer";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import Paper from "@material-ui/core/Paper";
import { Button, Checkbox, Chip, CircularProgress, IconButton, makeStyles, TextareaAutosize, TextField, Tooltip, Typography } from "@material-ui/core";
import PlayCircleOutlineIcon from "@material-ui/icons/PlayCircleOutline";
// import PublishIcon from "@material-ui/icons/Publish";
import DeleteOutlineIcon from "@material-ui/icons/DeleteOutline";
import { DataService, ModelService } from "../../../../../../../../../../../../services";
import PlayCircleFilledIcon from "@material-ui/icons/PlayCircleFilled";
import PauseCircleFilledIcon from "@material-ui/icons/PauseCircleFilled";
import { convertMilliSecondsToSeconds } from "../../../../../../../../../../../../services/timeHelperService";
import { LabelField } from "./labelField";
import { Alert, Autocomplete } from "@material-ui/lab";
import { PromiseConcurrency } from '../../../../../../../../../../../../classes/PromiseConcurrency';
// import { getRandomElementFromArray } from '../../../../../../../../../../../../services/arrayHelperService';
// import { getRandomColor } from '../../../../../../../../../../../../services/colorHelperService';
import { UniqueColorPicker } from '../TextTrimUI/UniqueColorPicker';
import styles from "./AudioTrimUi.module.css"
import { TextDiffSection } from "../../../../../../../../../TextDiffSection/TextDiffSection";
import EditIcon from '@material-ui/icons/Edit';
import TextDiffChangeComponent from "../../../../../../../../../TextDiffChangeComponent/TextDiffChangeComponent";
import { Change, diffWords } from 'diff';

//for audio Text transcription
export interface WordMetadata {
  characterIndex: number;
  timeFrameInAudio: {
    startInMilliSeconds: number;
    endInMilliSeconds: number;
  };
  word: string;
}

interface TranscriptProps {
  originalAudioCurrentTime: number;
  id?: string;
  audioTranscriptionWordsMetadatas: WordMetadata[];
  isEditMode?: boolean;
  audioTranscription?: string;
  isHideMistakesEnabled?: boolean;
  setAudioTranscriptionInUI?: React.Dispatch<React.SetStateAction<string>>,
  audioTranscriptionBeforeCorrection?: string,
  audioTranscriptionInUI?: string
}
type Props = {
 resource: IData;
 pauseAudioOnPupupClose?: boolean;
 updateResourceInUI: (resourceDataToUpdate: IData) => any;
 modelAnnotationLabelsAvailable: ModelAnnotationLabelAvailable[];
 modelMaxAudioTrimAllowedInMilliSeconds: number; 
 taskType:IModelType;
 audioTranscriptionWordsMetadatas:WordMetadata[];
 textOneHeadingForTextComparisonUI?: string;
 textTwoHeadingForTextComparisonUI?: string;
 scenario?: "aiMarketPlaceDataCart" | "aiMarketplaceDataSelection";
};

const Annotation_Color_Opacity = 0.4;

function getAudioTranscriptionWordHTML_Element_Id(index: number): string {
  return `audioTranscriptionWord_${index}`;
}

type WaveSurferRegionOptions = {
 id: string;
 start: number;
 end: number;
 color: string;
};

  const trimAudioColors = ['rgba(0,255,81,1)', 'rgba(255,219,0,1)', 'rgba(255,0,0,1)', 'rgba(0,4,255,1)', 'rgba(227,0,255,1)', '#006600', '#d1e0e0', '#eea782', '#00ffff', '#800000', '#52527a'];
  export const AUDIO_TEXT_TRANSCRIPTION_RESOURCE_DEFAULT_TEXT_ONE_HEADING_FOR_UI = "Audio Transcription"
  export const AUDIO_TEXT_TRANSCRIPTION_RESOURCE_DEFAULT_TEXT_TWO_HEADING_FOR_UI = "Advertisement"

/**
 * @description
 * when wavesurfer creates a region, it automatically creates a region id having prefix "wavesurfer"
 */
// const WAVE_SURFER_ID_NAME = "wavesurfer";

const useStyles = makeStyles((theme) => ({
  playPauseButtonOfOriginalAudio: {
    width: "40px",
    height: "40px",
    marginRight: "11px",
    cursor: "pointer"
  },
  trimmedAudiosTableHeadCell: {
    fontWeight: 600,
    // backgroundColor: "#f5f5f5"
  },
  trimmedAudiosTableCellCheckbox: {
    width: "20px"
  },
  trimmedAudiosTableCellAnnotationColor: {
    width: "158px"
  },
  trimmedAudiosTableCellStartTimeColor: {
    width: "72px"
  },
  trimmedAudiosTableCellEndTimeColor: {
    width: "67px"
  },
  trimmedAudiosTableCellActions: {
    width: "122px"
  },
  trimAudioTableRowDisabled: {
    opacity: 0.4,
    pointerEvents: "none"
  },
  trimmedAudiosTableDivContainer: {
    height: "calc(100% - 176px)"
  },
  trimmedAudiosTableDivContainerWhenUnsaveWaarningSignNotShowing: {
    height: "calc(100% - 124px)"
  },
  textComparisonContainer: {
    marginTop: 10,
    width: "100%",
    heigth: "100%",
},
textComparisonParentClass: {
    display: "flex",
    flexDirection: "column",
    width: "100%",
    justifyContent: "space-around",
    height: "100%",
    gap: 40
},
textComparisonColumn: {
    backgroundColor: "#f2f2f2",
    width: "100%",
    height: "90%",
    borderRadius: "5px",
    padding: "20px",
    overflow: "auto"
},
textComparisonHeader: {
    color: "#a2a2a2",
    fontWeight: 500,
    marginTop: 10,
},
audioTranscriptionControlContainer: {
  display: "flex",
  flexDirection: "row",
  justifyContent: "flex-end",
  alignItems: "centre",
  gap: 10
},
audioTranscriptionHeading: {
  display: "flex",
  flexDirection: "row",
  justifyContent: "space-between",
  alignItems: "center",
  marginBottom: 10
}
}));

type UITrimmedAudio = TrimmedAudio & {
  color: string
}

const textDifferenceChipColor = (diff: number) : string => {
  return (
      diff <= 25 ?
          "green" :
          diff <= 70 ?
              "orange" :
              "red"
  )
}

export function AudioTrimUI(props: Props) {
  const classes = useStyles();
 const resource = props.resource;
 const projectId = new URLSearchParams(window.location.search).get("project") || "";
 const resourceTrimmedAudios = props.resource.trimmedAudios || [];
 const wavesurferRef = useRef<any>(null);
 const arrBufferRef = useRef<any>(null);
 const audioBufferRef = useRef<any>(null);
 const updateResourceInUI = props.updateResourceInUI;
 const {modelMaxAudioTrimAllowedInMilliSeconds} = props;
 const [trimmedAudios, setTrimmedAudios] = useState<UITrimmedAudio[]>([]);
 const [
  trimmedAudioIdsBeingDeletedFromDb,
  setTrimmedAudioIdsBeingDeletedFromDb,
 ] = useState<string[]>([]);
 const [trimmedAudioIdsBeingUpdatedInDb, setTrimmedAudioIdsBeingUpdatedInDb] =
  useState<string[]>([]);


  
 const [isResourceAudioPlaying, setIsResourceAudioPlaying] = useState(false);
 const [hasResourceIdChanged, setHasResourceIdChanged] = useState(false);
 const [originalAudioCurrentTime, setOriginalAudioCurrentTime] = useState(0);
 const [originalAudioTotalTime, setOriginalAudioTotalTime] = useState(0);
 const [projectAudioModels, setProjectAudioModels] = useState<IModel[]>([]);
 const [trimAudioIdsSelectedInTable, setTrimAudioIdsSelectedInTable] = useState<string[]>([]);
 const [trimAudioIdsBeingClonedInDb, setTrimAudioIdsBeingClonedInDb] = useState<string[]>([]);
 const [modelIdSelectedToCloneInto, setModelIdSelectedToCloneInto] = useState("");
 const [isDeleteAllTrimAudiosProcessHappening, setIsDeleteAllTrimAudiosProcessHappening] = useState(false);
 const trimAudioColorPickerRef= useRef<UniqueColorPicker>(new UniqueColorPicker(trimAudioColors));


 
 useEffect(() => {
  const executeFunction = async () => {
    const getModelsApiResponse = await ModelService.getProjectModels({
      project: projectId,
      type: props?.taskType
    })
    setProjectAudioModels(getModelsApiResponse.data.models)
  } 
 if (projectId) {
   executeFunction();
 }

 // make surfer section readonly
  if(props.scenario === 'aiMarketPlaceDataCart' || props.scenario === 'aiMarketplaceDataSelection'){
    const surferDiv = document.getElementById('waveform');
    if(surferDiv){
      surferDiv.style.pointerEvents = 'none';
    }
  }

 }, [projectId])
 
 const stopPlayingAudio = () => {
  setIsResourceAudioPlaying(false);
  if (wavesurferRef.current?.pause) {
    wavesurferRef.current.pause();
  }
 }

 const stopPlayingAudioRef = useRef(stopPlayingAudio);
 stopPlayingAudioRef.current = stopPlayingAudio;

 useEffect(() => {
  console.log('execution in useeffect', props.pauseAudioOnPupupClose);

  if( props.pauseAudioOnPupupClose ) {
    stopPlayingAudioRef.current();
  }
 }, [props.pauseAudioOnPupupClose])

 useEffect(() => {
  stopPlayingAudioRef.current();
 }, [props.resource?._id])

 useEffect(() => {
  if (resource._id) {
    trimAudioColorPickerRef.current = new UniqueColorPicker(trimAudioColors);
   setHasResourceIdChanged(true);
  }
 }, [resource._id]);

 const setRegionTooltipTimeInDom = useCallback(
  (regionId: string, startTime: number, endTime: number) => {
    const regionElement = document.querySelector(`[data-id='${regionId}']`)
    if (regionElement) {
     //  @ts-ignore
     regionElement.title = `${startTime.toFixed(2)}-${endTime.toFixed(2)}`;
     //  @ts-ignore
      regionElement.style.opacity = Annotation_Color_Opacity;
    }
  },
  [],
); 

 useEffect(() => {
   
   if (Array.isArray(trimmedAudios)) {
     for (const trimAudio of trimmedAudios) {
       setTimeout(() => {
         setRegionTooltipTimeInDom(trimAudio._id,trimAudio.startTimeInSeconds, trimAudio.endTimeInSeconds)
       }, 500);
     }
   }
 
 }, [setRegionTooltipTimeInDom, trimmedAudios]);

 const readAudio = useCallback((file) => {
  return new Promise((resolve, reject) => {
   var reader = new FileReader();
   reader.readAsArrayBuffer(file);

   //Resolve if audio gets loaded
   reader.onload = function () {
    console.log("Audio Loaded");
    resolve(reader);
   };

   reader.onerror = function (error) {
    console.log("Error while reading audio");
    reject(error);
   };

   reader.onabort = function (abort) {
    console.log("Aborted");
    console.log(abort);
    reject(abort);
   };
  });
 }, []);

 const readAndDecodeAudio = useCallback(
  async (audioBlob) => {
   //Read the original Audio
   await readAudio(audioBlob)
    .then((results: any) => {
     arrBufferRef.current = results.result;
    })
    .catch((error: any) => {
     window.alert("Some Error occured");
     return;
    });

   //Decode the original Audio into audioBuffer
   await new AudioContext()
    .decodeAudioData(arrBufferRef.current)
    .then((res) => {
     audioBufferRef.current = res;
    })
    .catch((err) => {
     window.alert("Can't decode Audio");
     return;
    });
  },
  [readAudio]
 );

 const setPlayButton = useCallback(() => {
  // const icon: any = document.getElementById("play-pause-icon");
  // icon.className = "fa fa-play";
 }, []);

/*
 const playAndPause = useCallback(() => {
  const icon: any = document.getElementById("play-pause-icon");
  if (icon.className === "fa fa-play") {
   icon.className = "fa fa-pause";
   wavesurferRef.current.play();
  } else {
   icon.className = "fa fa-play";
   wavesurferRef.current.pause();
  }
 }, []);
*/

//  const showAndHideMergeOption = useCallback(() => {
//   const audioTracks: any = document.getElementById("audio-tracks");
//   const mergeOption: any = document.getElementById("merge-option");

//   // always hiding merge option
//   if (false && audioTracks.childNodes.length >= 4) {
//    mergeOption.setAttribute("class", "w3-show");
//   } else {
//    mergeOption.setAttribute("class", "w3-hide");
//   }
//  }, []);

 const createAudioRow = useCallback((arr) => {
  let tableRow = document.createElement("tr");
  tableRow.setAttribute("id", arr[0]);
  tableRow.setAttribute("class", "w3-hover-text-green");
  //tableRow.setAttribute("onmouseover", "highlightRegion('over','"+arr[0]+"')");
  //tableRow.setAttribute("onmouseleave", "highlightRegion('leave','"+arr[0]+"')");
  for (let i in arr) {
   let tableData;

   // @ts-ignore
   if (i === 0) {
    /** Not creating checkbox element as its not required */
    // tableData = document.createElement("input");
    // tableData.setAttribute("type", "checkbox");

    tableData = document.createElement("span");
    tableData.setAttribute("class", "w3-check w3-margin-left");
   } else {
    tableData = document.createElement("td");
    tableData.innerText = arr[i].toFixed(4);
   }
   tableData.setAttribute("id", arr[0] + i);
   tableRow.appendChild(tableData);
  }

  var actionsArray = [
   { action: "play", iconClass: "fa fa-play-circle-o" },
   // {"action":"download", "iconClass":"fa fa-download"},
   { action: "upload", iconClass: "fa fa-upload" },
   { action: "delete", iconClass: "fa fa-times" }
  ];
  for (let i = 0; i < actionsArray.length; i++) {
   var tableData = document.createElement("td");
   tableData.setAttribute("id", arr[0] + "-" + actionsArray[i].action);
   var dataIcon = document.createElement("button");
   dataIcon.setAttribute("title", actionsArray[i].action);
   dataIcon.setAttribute(
    "class",
    actionsArray[i].iconClass +
     " w3-button w3-white w3-border w3-border-light-green w3-round-large"
   );
   dataIcon.setAttribute("id", arr[0] + "-" + actionsArray[i].iconClass);
   dataIcon.setAttribute(
    "onClick",
    actionsArray[i].action + "Track('" + arr[0].toString() + "')"
   );
   tableData.appendChild(dataIcon);
   tableRow.appendChild(tableData);
  }
  return tableRow;
 }, []);

 const showSelectedResourceSection = useCallback(() => {
  // @ts-ignore
  document.getElementById("selectedResourceSection").style.display = "block";
 }, []);

//  const preTrimUIChanges = useCallback(() => {
//   setPlayButton();
//   const audioTracks: any = document.getElementById("audio-tracks");
//   const tbody = document.createElement("tbody");
//   audioTracks.tBodies[0].remove();
//   audioTracks.insertBefore(tbody, audioTracks.tFoot[0]);
//  }, [setPlayButton]);

 const handleTrimAudioDeleteClick = useCallback(
  async (trimAudioIdToDelete: string) => {
   const isTrimAudioToDeleteSavedInDB = (trimAudioIdToCheck: string) => {
    const trimAudioToDelete = trimmedAudios.find(
     (trimmedAudio) => trimmedAudio._id === trimAudioIdToCheck
    );

    if (!trimAudioToDelete?.label) {
     return false;
    }

    return true;
   };

   if (isTrimAudioToDeleteSavedInDB(trimAudioIdToDelete)) {
    /**
     * adding trimmed audio to delete in array
     */
    setTrimmedAudioIdsBeingDeletedFromDb((oldState) => [
     ...oldState,
     trimAudioIdToDelete,
    ]);

    await DataService.deleteTrimmedAudioInDB({
     trimmedAudioId: trimAudioIdToDelete,
    });

    /**
     * removing trimmed audio to delete from array
     */
    setTrimmedAudioIdsBeingDeletedFromDb((oldState) => {
     const newState = [...oldState];

     for (let index = 0; index < newState.length; index++) {
      const trimAudioId = newState[index];
      if (trimAudioIdToDelete === trimAudioId) {
       newState.splice(index, 1);
       break;
      }
     }

     return newState;
    });

    if (resourceTrimmedAudios?.length > 0) {
     for (let index = 0; index < resourceTrimmedAudios.length; index++) {
      const resourceTrimmedAudio = resourceTrimmedAudios[index];
      if (resourceTrimmedAudio._id === trimAudioIdToDelete) {
       resourceTrimmedAudios.splice(index, 1);
       updateResourceInUI({
        ...resource,
        ...{
         trimmedAudios: [...resourceTrimmedAudios],
        },
       });
       break;
      }
     }
    }
   }

   setTrimmedAudios((oldState) => {
    const newState = oldState;

    for (let index = 0; index < newState.length; index++) {
     const trimmedAudio = newState[index];
     if (trimmedAudio._id === trimAudioIdToDelete) {
      trimAudioColorPickerRef.current.decrementColorUsageCount(trimmedAudio.color)
      newState.splice(index, 1);
     }
    }

    return [...newState];
   });

   if (wavesurferRef.current.regions.list[trimAudioIdToDelete]) {
    wavesurferRef.current.regions.list[trimAudioIdToDelete].remove();
   }

   setTrimAudioIdsSelectedInTable(oldIdsSelected=>{
    const idAlreadyPresentInArray = oldIdsSelected.find(oldId=>oldId===trimAudioIdToDelete) ? true : false

    if (idAlreadyPresentInArray) {

      for (let index = 0; index < oldIdsSelected.length; index++) {
        const oldId = oldIdsSelected[index];
        if (oldId === trimAudioIdToDelete) {
          oldIdsSelected.splice(index, 1);
          break;
        }
      }

      return [...oldIdsSelected];
    } else {
      return oldIdsSelected;
    }
   })
  },
  [updateResourceInUI, resource, resourceTrimmedAudios, trimmedAudios]
 );

 const handleTrimAudioUpdateInDB = useCallback(
  async (
   scenario: "createInDB" | "updateInDB",
   trimAudioId: string,
   trimAudioToUpdate: UITrimmedAudio
  ) => {
   const trimmedAudioToCreateAndSaveInDB = trimmedAudios.find(
    (trimAudio) => trimAudio._id === trimAudioId
   );

   setTrimmedAudioIdsBeingUpdatedInDb((oldState) => [...oldState, trimAudioId]);

   let newlyCreatedTrimmedAudioInDB: TrimmedAudio | undefined = undefined;
   if (scenario === "createInDB") {
    const saveApiResponse = await DataService.createAndSaveTrimmedAudioInDB({
     resourceId: resource._id,
     startTimeInSeconds:
      trimmedAudioToCreateAndSaveInDB?.startTimeInSeconds || 0,
     endTimeInSeconds: trimmedAudioToCreateAndSaveInDB?.endTimeInSeconds || 0,
     label: trimAudioToUpdate.label,
    });
    newlyCreatedTrimmedAudioInDB = saveApiResponse.data;
   } else if (scenario === "updateInDB") {
    await DataService.updateTrimmedAudioInDB({
     trimmedAudioId: trimAudioId,
     label: trimAudioToUpdate.label,
     startTimeInSeconds: trimAudioToUpdate.startTimeInSeconds,
     endTimeInSeconds: trimAudioToUpdate.endTimeInSeconds
    });
   }

   /**
    * removing trimmed audio to save from array
    */
   setTrimmedAudioIdsBeingUpdatedInDb((oldState) => {
    const newState = [...oldState];

    for (let index = 0; index < newState.length; index++) {
     const trimmedAudioId = newState[index];
     if (trimAudioId === trimmedAudioId) {
      newState.splice(index, 1);
      break;
     }
    }

    return newState;
   });

   setTrimmedAudios((oldState) => {
    const trimmedAudiosToUpdate = [...oldState];

    for (let index = 0; index < trimmedAudiosToUpdate.length; index++) {
     let trimmedAudioToUpdate = trimmedAudiosToUpdate[index];
     if (trimmedAudioToUpdate._id === trimAudioId) {
      if (scenario === "createInDB") {
       if (newlyCreatedTrimmedAudioInDB) {
        trimmedAudioToUpdate = {...newlyCreatedTrimmedAudioInDB, color: trimmedAudioToCreateAndSaveInDB?.color || ""};
        trimmedAudiosToUpdate[index] = trimmedAudioToUpdate;
       }
      } else if (scenario === "updateInDB") {
        trimmedAudiosToUpdate[index].label = trimAudioToUpdate.label;
        trimmedAudiosToUpdate[index].startTimeInSeconds = trimAudioToUpdate.startTimeInSeconds;
        trimmedAudiosToUpdate[index].endTimeInSeconds = trimAudioToUpdate.endTimeInSeconds;
      }

      break;
     }
    }

    return trimmedAudiosToUpdate;
   });

   if (scenario === "createInDB") {
    if (newlyCreatedTrimmedAudioInDB) {
     resourceTrimmedAudios.push(newlyCreatedTrimmedAudioInDB);
    }
   } else if (scenario === "updateInDB") {
    for (let index = 0; index < resourceTrimmedAudios.length; index++) {
     const resourceTrimmedAudio = resourceTrimmedAudios[index];
     if (resourceTrimmedAudio._id === trimAudioId) {
       resourceTrimmedAudio.label = trimAudioToUpdate.label;
       resourceTrimmedAudio.startTimeInSeconds = trimAudioToUpdate.startTimeInSeconds;
       resourceTrimmedAudio.endTimeInSeconds = trimAudioToUpdate.endTimeInSeconds;
      break;
     }
    }
   }

   updateResourceInUI({
    ...resource,
    ...{
     trimmedAudios: [...resourceTrimmedAudios],
    },
   });

   if (scenario === "createInDB") {
    if (wavesurferRef.current.regions.list[trimAudioId]) {
     wavesurferRef.current.regions.list[trimAudioId].remove();
    }
    if (newlyCreatedTrimmedAudioInDB) {
     const newRegion: WaveSurferRegionOptions = {
      id: newlyCreatedTrimmedAudioInDB._id.toString(),
      start: newlyCreatedTrimmedAudioInDB.startTimeInSeconds,
      end: newlyCreatedTrimmedAudioInDB.endTimeInSeconds,
      color: trimmedAudioToCreateAndSaveInDB?.color || ""
     };

     wavesurferRef.current.addRegion(newRegion);
    }

    setTrimAudioIdsSelectedInTable(oldIdsSelected=>{
      const idAlreadyPresentInArray = oldIdsSelected.find(oldId=>oldId===trimAudioId) ? true : false
  
      if (idAlreadyPresentInArray) {
  
        for (let index = 0; index < oldIdsSelected.length; index++) {
          const oldId = oldIdsSelected[index];
          if (oldId === trimAudioId) {
            oldIdsSelected[index] = newlyCreatedTrimmedAudioInDB?._id.toString() || "";
            break;
          }
        }
  
        return [...oldIdsSelected];
      } else {
        return oldIdsSelected;
      }
     })    
   }
  },
  [resource, resourceTrimmedAudios, trimmedAudios, updateResourceInUI]
 );

 const isWaveFormHTMLElementPresentInDom = useCallback(
   () => {
    const htmlElement = document.getElementById("waveform");
    if (htmlElement) {
      return true;
    }
    return false;
   },
   [],
 );

 const labelChanged = useCallback(
  (label: string, audioId: string) => {
    const trimAudio = trimmedAudios.find((audio)=>audio._id === audioId);
    const isTrimAudioNotYetSavedInDBEvenOnce = !trimAudio?.label ? true : false;
    if (trimAudio) {
      trimAudio.label = label;
      handleTrimAudioUpdateInDB(
        isTrimAudioNotYetSavedInDBEvenOnce ? "createInDB" : "updateInDB",
        audioId,
        trimAudio
       );
    }
   },
   [handleTrimAudioUpdateInDB, trimmedAudios],
 );

 const timeInterval = (pxPerSec: number) => {
  let timeInterval = wavesurferRef.current.getDuration() * 0.01;
  // timeInterval = Number(timeInterval.toFixed(2));
  return timeInterval;
 }

 const formatTimeCallback = (seconds: number, pxPerSec: number) => {
  seconds = Number(seconds);
  let minutes = Math.floor(seconds / 60);
  seconds = seconds % 60;

  // fill up seconds with zeroes
  let secondsStr = Math.round(seconds).toString();
  if (pxPerSec >= 25 * 10) {
      secondsStr = seconds.toFixed(2);
  } else if (pxPerSec >= 25 * 1) {
      secondsStr = seconds.toFixed(1);
  }

  if (minutes > 0) {
      if (seconds < 10) {
          secondsStr = '0' + secondsStr;
      }
      return `${minutes}:${secondsStr}`;
  }

  return secondsStr;
}

 const loadAudio = useCallback(
  async (resource: IData) => {

   setOriginalAudioCurrentTime(0.00);
   setOriginalAudioTotalTime(0.00);

   const audioDownloadURL = resource.resource;

   const audioBlobPromise = new Promise(async (resolve, reject) => {
    try {
     const apiResponse = await fetch(audioDownloadURL);
     const apiBlobResponse = apiResponse.blob();
     // resolve( new File([apiBlobResponse], resourceObj.filename));
     resolve(apiBlobResponse);
    } catch (error: any) {
     reject(new Error(error.message));
    }
   });

   const audioFile = await audioBlobPromise;

   if (wavesurferRef.current) {
    wavesurferRef.current.destroy();
   }

   if (!isWaveFormHTMLElementPresentInDom()) {
     return;
   }

   wavesurferRef.current = WaveSurfer.create({
    container: "#waveform",
    waveColor: "#d4e8e9",
    progressColor: "#008d9c",
    responsive: true,
    barWidth: 3,
    barRadius: 3,
    cursorWidth: 1,
    height: 100,
    barGap: 3,
    plugins: [
      WaveSurferRegionPlugin.create({}),
      WaveSurferTimelinePlugin.create({
        container: "#waveform-timeline",
        formatTimeCallback: formatTimeCallback,     // It is used for formatting the timeline notches
        timeInterval: timeInterval,                 // It is the gap between the secondaryLabelInterval (Note: To get the primaryLabelInterval multiple the timeInterval with 5)
        primaryLabelInterval: 5,      // It tells after how much secondary bars primary bar is required
        secondaryLabelInterval: 5,    // No of secondary bars needed inbetween primary bars 
      })
    ],
   });

   wavesurferRef.current.on("ready", function () {
    readAndDecodeAudio(audioFile);
    // preTrimUIChanges();
    const totalAudioDuration = wavesurferRef.current.getDuration();

    setOriginalAudioTotalTime(totalAudioDuration.toFixed(1));

    // wavesurferRef.current.enableDragSelection({
    //  maxLength: 0.5,
    // });

    const dragSelectionOptions: {
      maxLength?: number
    } = {
    }

    if (modelMaxAudioTrimAllowedInMilliSeconds) {
      dragSelectionOptions.maxLength = convertMilliSecondsToSeconds(modelMaxAudioTrimAllowedInMilliSeconds);
    }

    wavesurferRef.current.enableDragSelection(dragSelectionOptions);
    showSelectedResourceSection();

    const resourceTrimmedAudios = resource.trimmedAudios;
    if (resourceTrimmedAudios && resourceTrimmedAudios?.length > 0) {
      const uiTrimmedAudios: UITrimmedAudio[] = [];
     for (const resourceTrimmedAudio of resourceTrimmedAudios) {
      //  const regionColor = getRandomElementFromArray(highContrastingColors)
      //  const regionColor = getRandomColor();
       const regionColor = trimAudioColorPickerRef.current.getLeastUsedColor();
      const newRegionObject: WaveSurferRegionOptions = {
       id: resourceTrimmedAudio._id,
       start: resourceTrimmedAudio.startTimeInSeconds,
       end: resourceTrimmedAudio.endTimeInSeconds,
       color: regionColor
      };

      wavesurferRef.current.addRegion(newRegionObject);
      uiTrimmedAudios.push({
        _id: resourceTrimmedAudio._id,
        startTimeInSeconds: resourceTrimmedAudio.startTimeInSeconds,
        endTimeInSeconds: resourceTrimmedAudio.endTimeInSeconds,
        color: regionColor,
        label: resourceTrimmedAudio.label,
        createdAt: resourceTrimmedAudio.createdAt
      })
     }

     setTrimmedAudios([...uiTrimmedAudios])
    }
   });
   //  wavesurferRef.current.on("finish", setPlayButton);
   wavesurferRef.current.on("finish", function () {
    setIsResourceAudioPlaying(false);
   });
   // wavesurfer.load(URL.createObjectURL(element.files[0]));
  //  wavesurferRef.current.load(audioDownloadURL);
   wavesurferRef.current.loadBlob(audioFile)
   wavesurferRef.current.on("audioprocess", function () {
    if (wavesurferRef.current.isPlaying()) {
     var currentTime = wavesurferRef.current.getCurrentTime();

     // @ts-ignore
    //  document.getElementById("time-current").innerText = currentTime.toFixed(1);
    setOriginalAudioCurrentTime(currentTime.toFixed(1));
    }
   });
   wavesurferRef.current.on("region-created", function (newRegion: any) {
    return;

    // console.log("region-created event");

    // // @ts-ignore
    // var audioTracks = document.getElementById("audio-tracks").tBodies[0];

    // var tableRow = createAudioRow(
    //  new Array(newRegion.id, newRegion.start, newRegion.end)
    // );
    // audioTracks.appendChild(tableRow);
    // showAndHideMergeOption();
   });
   wavesurferRef.current.on("region-update-end", function (newRegion: any) {

    if (modelMaxAudioTrimAllowedInMilliSeconds) {
      const maximumLengthOfTrimAudioInSeconds = convertMilliSecondsToSeconds(modelMaxAudioTrimAllowedInMilliSeconds);
      const lengthSelectedByUser = newRegion.end - newRegion.start;
      if (lengthSelectedByUser > maximumLengthOfTrimAudioInSeconds) {
        newRegion.end = newRegion.start + maximumLengthOfTrimAudioInSeconds;
      }
    }

    setTrimmedAudios((oldState) => {
     const newState = [...oldState];
     const trimAudioMatchingRegionId = newState.find(ele => ele._id === newRegion.id);     
     if (trimAudioMatchingRegionId) {
      trimAudioMatchingRegionId.startTimeInSeconds = newRegion.start;
      trimAudioMatchingRegionId.endTimeInSeconds = newRegion.end;
      if (trimAudioMatchingRegionId.label) {
        handleTrimAudioUpdateInDB(
          "updateInDB",
          trimAudioMatchingRegionId._id,
          trimAudioMatchingRegionId
        )
      }
     } else {
      // const colorToSetInRegion = getRandomElementFromArray(highContrastingColors)
      // const colorToSetInRegion = getRandomColor();
      const colorToSetInRegion = trimAudioColorPickerRef.current.getLeastUsedColor();
      wavesurferRef.current.regions.list[newRegion.id].update({color: colorToSetInRegion});       
       newState.push({
        startTimeInSeconds: newRegion.start,
        endTimeInSeconds: newRegion.end,
        _id: newRegion.id,
        label: "",
        createdAt: "",
        color: colorToSetInRegion
       });
     }     

     return newState;
    });

    return;

    // var regions = newRegion.wavesurfer.regions.list;
    // var keys = Object.keys(regions);
    // if (keys.length > 1) {
    //  regions[keys[0]].remove();
    //  var track = document.getElementById(keys[0]);
    //  if (track) {
    //   // @ts-ignore
    //   track.parentNode.removeChild(track);
    //  }
    // }
    // @ts-ignore
    // document.getElementById(newRegion.id + 1).innerText =
    //  0 >= newRegion.start.toFixed(4) ? 0 : newRegion.start.toFixed(4);

    // @ts-ignore
    // document.getElementById(newRegion.id + 2).innerText =
    //  wavesurferRef.current.getDuration() <= newRegion.end
    //   ? wavesurferRef.current.getDuration().toFixed(4)
    //   : newRegion.end.toFixed(4);
   });
  },
  [
   createAudioRow,
  //  preTrimUIChanges,
   readAndDecodeAudio,
   setPlayButton,
  //  showAndHideMergeOption,
   showSelectedResourceSection,
  ]
 );

 useEffect(() => {
  if (props.resource && hasResourceIdChanged) {
  //  setTrimmedAudios([...(props.resource.trimmedAudios || [])]);
   setTrimmedAudios([])
   setHasResourceIdChanged(false);
   loadAudio(props.resource);
  }
 }, [hasResourceIdChanged, props.resource, loadAudio]);

//  const playTrack = useCallback((regionId: string) => {
//   wavesurferRef.current.regions.list[regionId].play();
//  }, []);
  
  /**
   * To check if all the selected audio have labels
   * @returns boolean value
   */
  const checkSelectedTrimmedAudioLabel = () => {
    return trimAudioIdsSelectedInTable.every(id => {
      const trimmedAudioValue = trimmedAudios.find(audio => audio._id === id);
      return trimmedAudioValue?.label && trimmedAudioValue.label.length ? true : false;
    })
}

  const [isAllTrimmedAudioHaveLabels, setIsAllTrimmedAudioHaveLabels] = useState<boolean>(true);
  useEffect(() => {
    const allLabelAvailable = trimmedAudios.every((audio) => {
      return audio?.label && audio.label.length ? true : false;
    })

    if (allLabelAvailable){
      setIsAllTrimmedAudioHaveLabels(true)
    }
    else{
      setIsAllTrimmedAudioHaveLabels(false)
    }
  },[trimmedAudios])

  const [isEditingAudioTranscription, setIsEditingAudioTranscription] = useState<boolean>(false)
  const [isHideMistakesEnabled, setIsHideMistakesEnables] = useState<boolean>(true)
  const [audioTranscriptionInUI, setAudioTranscriptionInUI] = useState<string>(props?.resource?.label || "")
  const [audioTranscriptionInUIPrevValue, setAudioTranscriptionInUIPrevValue] = useState<string>(props?.resource?.label || "")
  const [isUpdatingAudioTranscription, setIsUpdatingAudioTranscription] = useState<boolean>(false)

  const handleToggleEditAudioTranscription = () => {
    setIsEditingAudioTranscription(prevState => !prevState)
    if(!isEditingAudioTranscription) {
      setIsHideMistakesEnables(true)
      setAudioTranscriptionInUIPrevValue(audioTranscriptionInUI)
    }
  }

  const handleToggleHideMistakes = () => {
    setIsHideMistakesEnables(prevState => !prevState)
  }

  const updateAudioTranscription = async (latestTranscription: string) => {
    try {
      setIsUpdatingAudioTranscription(true)
      const reqPayload: any = {
        id: [props.resource._id],
        // tobemodifiedforLabel
        label: latestTranscription
      }
      if(!props.resource.prediction || props.resource.prediction === "predicted") {
        reqPayload["prediction"] = props.resource.label;
      }      
      const apiResponse = await DataService.updateData(reqPayload)
      await updateResourceInUI({
        ...resource,
        label: latestTranscription,
        prediction: props.resource.prediction === "predicted"? props.resource.label : props.resource.prediction
      })

      setIsUpdatingAudioTranscription(false)
    } catch (error) {
      setIsUpdatingAudioTranscription(false)
      alert("Error")
    }
  }

  const handleSaveAudioTranscripion = async () => {
    await updateAudioTranscription(audioTranscriptionInUI)
    setIsEditingAudioTranscription(false)
  }

  const handleCancelEditAudioTranscription = async () => {
    setIsEditingAudioTranscription(false)
    setAudioTranscriptionInUI(audioTranscriptionInUIPrevValue)
  }

  useEffect(() => {
    setAudioTranscriptionInUI(props.resource?.label || "")
    setAudioTranscriptionInUIPrevValue(props.resource?.label || "")
  }, [props.resource._id])

 return (
  <>
   <div className="w3-container"
    style={{
      height: "100%",
      width: "100%",
     
    }}
   >
    <br />
    <div
     id="waveform"
     className="w3-border w3-round-large"
     data-step="3"
     data-intro="Click and drag to select section"
    ></div>
    <div id="waveform-timeline"></div>
    <br />
    <div id="selectedResourceSection"
      style={{
        height: "calc(100% - 135px)"
      }}
    >
     <div className="w3-row">
      <div className="w3-half w3-container w3-hide" id="audio-buttons">
       {/* <button
        className="w3-button w3-border w3-border-green w3-round-xlarge"
        onClick={() => playAndPause()}
       >
        <i id="play-pause-icon" className="fa fa-play"></i>
       </button> */}
       {!isResourceAudioPlaying ? (
        <Tooltip title="Play the audio">
          <PlayCircleFilledIcon
           color="primary"
           className={classes.playPauseButtonOfOriginalAudio}
           onClick={() => {
            setIsResourceAudioPlaying(true);
            wavesurferRef.current.play();
            if(props.taskType === "audioTextTranscription" && props.resource.audioTranscriptionWordsMetadatas) {
              setIsHideMistakesEnables(true)
            }
           }}
          />
        </Tooltip>
       ) : (
         <Tooltip title="Pause the audio">
           <PauseCircleFilledIcon
            color="primary"
            className={classes.playPauseButtonOfOriginalAudio}
            onClick={() => {
             setIsResourceAudioPlaying(false);
             wavesurferRef.current.pause();
            }}
           />
         </Tooltip>
       )}
       <b id="time-current">{originalAudioCurrentTime}</b> / <b id="time-total">{originalAudioTotalTime}</b>
      </div>
      <div className="w3-half w3-container"></div>
     </div>

      {
        trimmedAudios?.length > 0 &&
        <>
     <hr />
     <div
      data-step="4"
      data-intro="Would you like to know how to merge tracks. Click Next."
      className={`
        ${classes.trimmedAudiosTableDivContainer}
        ${isAllTrimmedAudioHaveLabels ? classes.trimmedAudiosTableDivContainerWhenUnsaveWaarningSignNotShowing: ""}
      `}
     >
      {/* <table
       className="w3-table-all w3-card-4"
       id="audio-tracks"
       data-step="5"
       data-intro="Select atleast 2 checkboxes for merging. Click Next."
       style={{
         marginBottom: "7px"
       }}
      >
       <thead>
        <tr className="w3-border w3-border-teal w3-text-teal">
         <th></th>
         <th>Trimmed</th>
         <th>Audios</th>
         <th></th>
         <th></th>
         <th></th>
        </tr>
       </thead>
       <tbody></tbody>
       <tfoot></tfoot>
      </table> */}

      <div
        style={{
          marginBottom: "7px",
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
          minHeight: "40px"
        }}
      >
        <div
          style={{fontWeight: 600}}
        >
          Trimmed Audios
        </div>
        {
          trimAudioIdsSelectedInTable?.length>0 &&
          <section
            style={{
              display: "flex",
              justifyContent: "end",
              alignItems: "center"
            }}
          >
              <Autocomplete
                style={{
                  width: "362px"
                }}
                size="small"
                disabled={(trimAudioIdsBeingClonedInDb?.length>0 || !checkSelectedTrimmedAudioLabel()) ? true : false}
                // value={currentDefaultDataSetCollectionName()}
                value={modelIdSelectedToCloneInto}
                options={
                projectAudioModels.map(model=>model._id)   
                }
                getOptionLabel={
                  (modelId) => {
                  const modelName = projectAudioModels.find(
                      (model) => model._id.toString() === modelId.toString()
                  )?.name;
                  if (modelName) {
                      return modelName;
                  } else {
                      return modelId;
                  }
                }}
                onChange={async (event, value) => {
                  const modelIdToCloneTrimAudiosInto = value || "";

                  if (!modelIdToCloneTrimAudiosInto) {
                    setModelIdSelectedToCloneInto("");
                    return;
                  }

                  setModelIdSelectedToCloneInto(modelIdToCloneTrimAudiosInto);

                  const trimAudioIdsToClone = [...trimAudioIdsSelectedInTable];

                  setTrimAudioIdsBeingClonedInDb([...trimAudioIdsToClone]);

                  const promiseConcurrency = new PromiseConcurrency({
                    concurrencyLimit: 2
                  });

                  for (let trimAudioIdIndex = 0; trimAudioIdIndex < trimAudioIdsToClone.length; trimAudioIdIndex++) {
                    const trimAudioIdToClone = trimAudioIdsToClone[trimAudioIdIndex];
                    const trimAudioClonePromise = new Promise<void>(async (resolve, reject) => {
                      try {

                        const resourceApprovalStatus: ResourceStatus = "approval";

                        const trimAudio = trimmedAudios.find((trimAudio)=>trimAudio._id === trimAudioIdToClone);

                        const bodyFormData = new FormData();
                        bodyFormData.append("model", modelIdToCloneTrimAudiosInto);
                        bodyFormData.append("status", resourceApprovalStatus);
                        bodyFormData.append("csv", resource.csv);
                        bodyFormData.append("label", trimAudio?.label || "");
                        bodyFormData.append("tag", resource.tag);
                        bodyFormData.append("prediction", resource.prediction);
                        bodyFormData.append("confidence_score", resource.confidence_score);
                        bodyFormData.append("dataBoost", resource.dataBoost || "1");
                        bodyFormData.append("trimFromAudioResource", JSON.stringify({
                          _id: resource._id.toString(),
                          startTime: trimAudio?.startTimeInSeconds,
                          endTime: trimAudio?.endTimeInSeconds
                        }));    
                        await DataService.createResource(bodyFormData);
                        resolve();
                      } catch (error) {
                        reject(new Error(error.message));
                      }
                    });

                    trimAudioClonePromise.then(()=>{
                      setTrimAudioIdsBeingClonedInDb(oldIds=>{
                        for (let index = 0; index < oldIds.length; index++) {
                          const oldId = oldIds[index];
                          if (oldId === trimAudioIdToClone) {
                            oldIds.splice(index, 1);
                            break;
                          }
                        }
                        return [...oldIds]
                      })
                    })

                    promiseConcurrency.addPromise(trimAudioClonePromise);
                    await promiseConcurrency.waitIfConcurrencyLimitReached();
                  }

                  await promiseConcurrency.waitUntilAllPromisesAreResolved();

                  setModelIdSelectedToCloneInto("");

                  // setDataSetCollectionsState((oldState) => {
                  //   const labelsToSave = value as string ;

                  //   return {
                  //     ...oldState,
                  //     isDirty: true,
                  //     isTouched: true,
                  //     value: labelsToSave,
                  //   };
                  // });
                }}
                // defaultValue={currentDefaultDataSetCollectionName()}
                renderTags={(value, getTagProps) => {
                  return value.map((option, index) => (
                      <Chip
                          variant="outlined"
                          color="primary"
                          label={<Tooltip title={option}>
                              <span>
                                  {index + 1}:
                                  {projectAudioModels.find(
                                      (model) => model._id.toString() === option.toString()
                                  )?.name}
                              </span>
                          </Tooltip>}
                          {...getTagProps({ index })} />
                  ));
                } }
                renderInput={(params) => (
                  <Tooltip title={!checkSelectedTrimmedAudioLabel() ? 'One of your selected trimmed audio label is not set, please assign a label to be able to clone it' : ''}>
                    <TextField
                      {...params}
                      size="small"
                      disabled={(trimAudioIdsBeingClonedInDb?.length || !checkSelectedTrimmedAudioLabel()) > 0 ? true : false}
                      InputProps={{
                        ...params.InputProps,
                        endAdornment: <></>
                      }}
                      variant="outlined"
                      // defaultValue={currentDefaultDataSetCollectionName()}
                      // className={classes.floating}
                      label="Select model to clone selected trimmed audios"
                    />
                  </Tooltip>
                )}
              />   

              {
                !isDeleteAllTrimAudiosProcessHappening
                ?
                  <Tooltip title="Delete selected trimmed audios">
                    <DeleteOutlineIcon
                      color="error"
                      style={{
                        cursor: "pointer",
                        marginLeft: "10px"
                      }}
                      onClick={async () => {
                        setIsDeleteAllTrimAudiosProcessHappening(true);
                        const promiseConcurrency = new PromiseConcurrency({concurrencyLimit: 5})
                        const audioIdsSelectedInTable = [...trimAudioIdsSelectedInTable];
                        for (const trimAudioIdSelectedInTable of audioIdsSelectedInTable) {
                          const audioDeletePromise = new Promise<void>(async (resolve, reject) => {
                            await handleTrimAudioDeleteClick(trimAudioIdSelectedInTable);
                            resolve()
                          })
                          promiseConcurrency.addPromise(audioDeletePromise);
                          await promiseConcurrency.waitIfConcurrencyLimitReached();
                        }

                        await promiseConcurrency.waitUntilAllPromisesAreResolved();

                        setIsDeleteAllTrimAudiosProcessHappening(false);
                      }}
                    />             
                  </Tooltip>                     
                :
                  <div style={{marginLeft: "10px"}}>
                    <CircularProgress size={24} style={{color: "#f44336"}} />
                  </div>
              }
          </section>
        }
      </div>

      { !isAllTrimmedAudioHaveLabels &&
        <section
        style={{
          marginTop: "24px",
          marginBottom: "35px"
        }}
      >
        <TrimLabelWontGetSavedWarningAlert>Trimmed Audios won't get saved if label not assigned to them!</TrimLabelWontGetSavedWarningAlert>

      </section>
      }
      <TableContainer component={Paper}
        style={{
          // maxHeight: "300px"
          maxHeight: "calc(100%)"
        }}
      >
       <Table stickyHeader size="small">
        <TableHead>
         <TableRow>
          <TableCell className={`${classes.trimmedAudiosTableHeadCell} ${classes.trimmedAudiosTableCellCheckbox}`}>
            <Checkbox
              indeterminate={trimAudioIdsSelectedInTable?.length>0 && trimAudioIdsSelectedInTable?.length < trimmedAudios?.length} 
              checked={
                trimmedAudios?.length === trimAudioIdsSelectedInTable?.length && trimAudioIdsSelectedInTable?.length>0 
              }
              onChange={(e)=>{
                if (e.target.checked) {
                  const totalAudioIds: string[] = [...trimmedAudios.map(audio => audio._id)];
                  setTrimAudioIdsSelectedInTable([...totalAudioIds])
                } else {
                  setTrimAudioIdsSelectedInTable([])
                }
              }}
              disabled={props.scenario === 'aiMarketPlaceDataCart' || props.scenario === 'aiMarketplaceDataSelection'}
            />
          </TableCell>
          <TableCell className={`${classes.trimmedAudiosTableHeadCell} ${classes.trimmedAudiosTableCellAnnotationColor}`}>Annotation Colour</TableCell>
          <TableCell className={`${classes.trimmedAudiosTableHeadCell} ${classes.trimmedAudiosTableCellStartTimeColor}`}>Start</TableCell>
          <TableCell className={`${classes.trimmedAudiosTableHeadCell} ${classes.trimmedAudiosTableCellEndTimeColor}`}>End</TableCell>
          <TableCell className={classes.trimmedAudiosTableHeadCell}>Label</TableCell>
          <TableCell className={`${classes.trimmedAudiosTableHeadCell} ${classes.trimmedAudiosTableCellActions}`}>Actions</TableCell>
         </TableRow>
        </TableHead>
        <TableBody>
         {trimmedAudios
         .sort((a,b) => {return a.startTimeInSeconds - b.startTimeInSeconds})
         .map((trimmedAudio, trimAudioIndex) => {
          return (
           <TableRow key={trimmedAudio._id}
            className={`
              ${
                trimmedAudioIdsBeingDeletedFromDb.indexOf(trimmedAudio._id) !== -1 ||
                trimmedAudioIdsBeingUpdatedInDb.indexOf(trimmedAudio._id) !== -1 ||
                trimAudioIdsBeingClonedInDb.indexOf(trimmedAudio._id) !== -1
                ? classes.trimAudioTableRowDisabled 
                : ""
              }
            `}
           >
            <TableCell style={{paddingRight: 0}}>
              <Checkbox 
                disabled={props.scenario === 'aiMarketPlaceDataCart' || props.scenario === 'aiMarketplaceDataSelection'}
                checked={
                  trimAudioIdsSelectedInTable.indexOf(trimmedAudio._id) !== -1
                  ? true
                  : false
                }
                onChange={(e) => {
                  if (e.target.checked) {
                    setTrimAudioIdsSelectedInTable(oldIds=>([...oldIds, trimmedAudio._id]))
                  } else {
                    setTrimAudioIdsSelectedInTable(oldIds=>{
                      for (let index = 0; index < oldIds.length; index++) {
                        const oldId = oldIds[index];
                        if (oldId === trimmedAudio._id) {
                          oldIds.splice(index, 1);
                          break;
                        }
                      }
                      return [...oldIds]
                    })
                  }
                }}
              />
            </TableCell>
            <TableCell>
              <div 
                style={{
                  backgroundColor: trimmedAudio.color,
                  width: "30px",
                  height: "30px",
                  borderRadius: "4px",
                  opacity: Annotation_Color_Opacity
                }}
              ></div>
            </TableCell>
            <TableCell>{trimmedAudio.startTimeInSeconds.toFixed(2)}</TableCell>
            <TableCell>{trimmedAudio.endTimeInSeconds.toFixed(2)}</TableCell>
            <TableCell>
             <LabelField label={trimmedAudio?.label} update={labelChanged} audioId={trimmedAudios[trimAudioIndex]?._id} modelAnnotationLabelsAvailable={props.modelAnnotationLabelsAvailable}
              disabled={props.scenario === 'aiMarketPlaceDataCart' || props.scenario === 'aiMarketplaceDataSelection'}
             />
            </TableCell>
            <TableCell>
              {
                trimAudioIdsBeingClonedInDb.indexOf(trimmedAudio._id) !== -1
                ? 
                  <span>Cloning ...</span>
                :
                  <>
                    <Tooltip title="Play the audio">
                      <PlayCircleOutlineIcon
                        color="primary"
                        style={{
                          marginRight: "20px",
                          cursor: "pointer"
                        }}
                        onClick={() => {
                        wavesurferRef.current.regions.list[trimmedAudio._id].play();
                        }}
                      />
                    </Tooltip>
                    {
                      props.scenario !== 'aiMarketPlaceDataCart' && props.scenario !== 'aiMarketplaceDataSelection'
                      &&
                    <Tooltip title="Delete the audio">
                      <DeleteOutlineIcon
                        color="error"
                        style={{
                          cursor: "pointer"
                        }}
                        onClick={() => {
                        handleTrimAudioDeleteClick(trimmedAudio._id);
                        }}
                      />             
                    </Tooltip>
                    }
                  </>
              }
            </TableCell>
           </TableRow>
          );
         })}
        </TableBody>
       </Table>
      </TableContainer>
     </div>
        </>
      }

     <br />
     {/* <div id="merge-option" className="w3-hide">
      <button
       className="w3-button w3-border w3-border-teal w3-round-xlarge"
       data-step="6"
       data-intro="Click to merge selected tracks. Bye bye!! :)"
      >
       <i>Merge tracks</i>
      </button>
      <br />
      <br />
      <div className="w3-row w3-hide" id="merged-track-div">
       <b className="w3-col l1 w3-text-olive">
        <i>Merged Audio : </i>
       </b>
      </div>
     </div> */}
     {
      props.taskType === "audioTextTranscription" && !props.resource?.textOne && !props.resource?.textTwo &&
           <div className={classes.audioTranscriptionHeading}>
            <Typography style={{fontSize:"14px",fontWeight:"bold"}}>Audio Transcription</Typography>
             <div className={classes.audioTranscriptionControlContainer}>
               {isEditingAudioTranscription && <Button
                 variant="outlined"
                 color="primary"
                 onClick={handleCancelEditAudioTranscription}
                 size="small"
               >
                 Cancel
               </Button>}
               {isEditingAudioTranscription && <Button
                 variant="contained"
                 color="primary"
                 disabled={props.resource.label === audioTranscriptionInUI}
                 onClick={handleSaveAudioTranscripion}
                 size="small"
               >
                 Save
                 {
                   isUpdatingAudioTranscription &&
                   <CircularProgress style={{ width: 16, height: 16, color: "white", marginLeft: 10 }} />
                 }
               </Button>}
               {(props.resource.prediction==="predicted")?<></>
               :!isEditingAudioTranscription && <Button
                 variant="outlined"
                 color="primary"
                 onClick={handleToggleHideMistakes}
                 size="small"
               >
                 {
                   isHideMistakesEnabled ?
                     "Show Mistakes"
                     : "Hide Mistakes"}
               </Button>}
               {!isEditingAudioTranscription && <IconButton
                 color="primary"
                 onClick={handleToggleEditAudioTranscription}
                 size="small"
                 disabled={props.scenario === 'aiMarketPlaceDataCart' || props.scenario === 'aiMarketplaceDataSelection'}
               >
                 <EditIcon />
               </IconButton>}
             </div>
           </div>
     }
    {(props.taskType==="audioTextTranscription" && !props.resource.textOne) &&  <div>
     <div style={{border:'2px solid black',height:"250px",width:"100%",borderRadius:"5px", overflow: "auto", padding: "10px"}}>
     <Transcript
          isEditMode={isEditingAudioTranscription}
          id={props.resource._id}
          audioTranscriptionWordsMetadatas={props.audioTranscriptionWordsMetadatas}
          originalAudioCurrentTime={originalAudioCurrentTime}
          audioTranscription={props.resource?.label}
          isHideMistakesEnabled={isHideMistakesEnabled}
          setAudioTranscriptionInUI={setAudioTranscriptionInUI}
          audioTranscriptionBeforeCorrection={props.resource.prediction==="predicted"?props.resource?.label: props.resource.prediction}
          audioTranscriptionInUI={audioTranscriptionInUI}
        />
     </div>
     </div>}
     {
      (props.taskType==="audioTextTranscription" && props.resource.textOne && props.resource.textTwo)&& <>
      <div className={classes.textComparisonParentClass}>
          <div className={classes.textComparisonContainer}>
              <p className={classes.textComparisonHeader}>{props.textOneHeadingForTextComparisonUI}</p>
              <div className={classes.textComparisonColumn}>
                <TextDiffSection forText={'one'} text={props.resource.textOne || ""} textDifferences={props.resource.textsDifferences}
                originalAudioCurrentTime={originalAudioCurrentTime} audioTranscriptionWordsMetadatas={props.audioTranscriptionWordsMetadatas} 
                audioTranscription={props.resource.textOne} /> 
              </div>
          </div>
          <div className={classes.textComparisonContainer}>
              <p className={classes.textComparisonHeader}>{props.textTwoHeadingForTextComparisonUI}</p>
              <div className={classes.textComparisonColumn}>
                  <TextDiffSection forText={'two'} text={props.resource.textTwo || ""} textDifferences={props.resource.textsDifferences}/>
              </div>
          </div>
      </div>
  </>
     }
    </div>

   </div>
  </>
 );
}

const useTrimLabelWontGetSavedWarningAlertStyles = makeStyles((theme) => ({
  alertRoot: {
    backgroundColor: "rgb(255, 244, 229) !important"
  }
}));
export function TrimLabelWontGetSavedWarningAlert(props: {
  children: string,
}) {
  const classes = useTrimLabelWontGetSavedWarningAlertStyles();

  return (
    <Alert severity="warning"
    classes={{
      root: classes.alertRoot
    }}
  >
    {props.children}
  </Alert>     
  )
}

export const Transcript: React.FC<TranscriptProps> = ({ originalAudioCurrentTime,
   audioTranscriptionWordsMetadatas, 
   id,
   isEditMode,
   audioTranscription,
   isHideMistakesEnabled,
   setAudioTranscriptionInUI,
   audioTranscriptionInUI,
   audioTranscriptionBeforeCorrection}) => {
  const [highlightedIndices, setHighlightedIndices] = useState<number[]>([]);

  const highlightedIndicesRef = useRef(highlightedIndices);
  highlightedIndicesRef.current = highlightedIndices;

  useEffect(() => {
    updateHighlightedIndices();
  }, [originalAudioCurrentTime]);

  const updateHighlightedIndices = () => {
    //const { audioTranscriptionWordsMetadatas } = transcriptData;

    const indices: number[] = [];

    for (let i = 0; i < audioTranscriptionWordsMetadatas.length; i++) {
      const wordMetadata = audioTranscriptionWordsMetadatas[i];
      if (originalAudioCurrentTime * 1000 > wordMetadata?.timeFrameInAudio?.startInMilliSeconds) {

        const doesIndiceExistAlready: boolean = (() => {
          return (highlightedIndicesRef.current || []).includes(i);
        })();
        
        indices.push(i);

        if (!doesIndiceExistAlready) {

          const audioTranscriptionWordElement = document.getElementById(getAudioTranscriptionWordHTML_Element_Id(i));

          // @ts-ignore
          if (audioTranscriptionWordElement?.scrollIntoViewIfNeeded) {
            // @ts-ignore
            audioTranscriptionWordElement?.scrollIntoViewIfNeeded();
          }
        }
      } else {
        break;
      }
    }
  
    setHighlightedIndices(indices);
  };

  const handleEditAudioTrascription = (e: React.ChangeEvent<HTMLTextAreaElement>) => {
    let value = e?.currentTarget?.value;
    if (setAudioTranscriptionInUI && value)
      setAudioTranscriptionInUI(e?.currentTarget?.value || "")
  }

  /**
   * in the below useEffect we are creating a new MetaData only to display in the UI.
   * this MetaData is a combination of the existing MetaData in DB (audioTranscriptionWordsMetadatas) and the edited Audio Transcription.
   * The Update metaData has all the words of the Updated Audio Transcription, if a particular word is
   * also present in the audioTranscriptionWordsMetadatas, we take the playbackTime from the audioTranscriptionWordsMetadatas
   * other wise we take the playbackTime of the predecesor word to show Highlight in UI
   */

  // useEffect(() => {
  //   const updateWordDiff: Change[] = 
  //   diffWords(
  //     audioTranscriptionBeforeCorrection || audioTranscription || "", 
  //     audioTranscriptionInUI || "", {
  //     });
  //   // console.log("testing diff", updateWordDiff)
  //   const spreadUpdatedWordDiff: Change [] = [];
  //   updateWordDiff.forEach((phrase) => {
  //     let wordArr = phrase.value.split(" ");
  //     if(wordArr.length==0) return;
  //     for(let word of wordArr) {
  //       if(word.length>0) {
  //         spreadUpdatedWordDiff.push({
  //           ...phrase,
  //           value: word,
  //           count: 1
  //         })
  //       }
  //     }
  //   }) 
  //   const wordMetaDataArray: WordMetadata [] = []

  //   let j=0;
  //   for(let i=0;i<spreadUpdatedWordDiff.length;i++)
  //   {
  //     console.log("testing spreadUpdatedWordDiff", spreadUpdatedWordDiff[i], i, j ,spreadUpdatedWordDiff[i].value, audioTranscriptionWordsMetadatas[j]?.word)

  //     if(spreadUpdatedWordDiff[i].removed) {
  //       j++;
  //     }
  //     else if(spreadUpdatedWordDiff[i].added) {
  //       wordMetaDataArray.push({
  //         ...audioTranscriptionWordsMetadatas[j==0?j:j-1],
  //         word: spreadUpdatedWordDiff[i]?.value || ""
  //       })
  //     }
  //     else {
  //       wordMetaDataArray.push({...audioTranscriptionWordsMetadatas[j],
  //         word: spreadUpdatedWordDiff[i]?.value || ""})
  //       j++;
  //     } 
  //   }

  //   setUpdatedMetaData(wordMetaDataArray)
  // }, [audioTranscriptionBeforeCorrection, audioTranscription, audioTranscriptionInUI, id])

  const [startTimeArrayForCharacter, setStartTimeArrayForCharacter] = useState<number []>([])

  useEffect(() => {
    const timeArray: number [] = [];
    let j=0;
    audioTranscriptionInUI?.split("").forEach((char, index) => {
      if(char===" ") j++;
      if(j>=audioTranscriptionWordsMetadatas.length) {
        timeArray.push(timeArray[index-1])
      }
      else {
        timeArray.push(audioTranscriptionWordsMetadatas?.[j]?.timeFrameInAudio?.startInMilliSeconds)
      }
    })
    setStartTimeArrayForCharacter(timeArray)
  }, [audioTranscriptionInUI])

  return (
    <div
      className={styles.transcriptContainer}>
      {
        !isHideMistakesEnabled ?
          <TextDiffChangeComponent originalText={audioTranscriptionBeforeCorrection || audioTranscription || ""} modifiedText={audioTranscriptionInUI || ""} />
          : isEditMode? 
          <TextareaAutosize 
          value={audioTranscriptionInUI}
          onChange={handleEditAudioTrascription}
          className={styles.audioTranscriptionEditModeTextArea} />
          : <p className={styles.transcriptText}>
            {audioTranscriptionWordsMetadatas.length? audioTranscriptionInUI?.split("").map((character, characterIndex) => {
              return (
                <span
                  key={characterIndex}
                  id={getAudioTranscriptionWordHTML_Element_Id(characterIndex)}
                  className={startTimeArrayForCharacter[characterIndex]<=originalAudioCurrentTime*1000 ? styles.HighlightedWord : ''}
                >
                  {character}
                  {/* {index !== audioTranscriptionWordsMetadatas.length - 1 && ' '} */}
                </span>
              )

            }): audioTranscriptionInUI}
          </p>}
    </div>
  );
};
import { Box, Button, Popover, Theme, Typography, makeStyles } from '@material-ui/core'
import React, { useEffect, useState } from 'react'
import { useLocation } from 'react-router';
import CloseIcon from '@material-ui/icons/Close';


const useStyles = makeStyles((theme: Theme) => ({
    popoverBody : {
        maxWidth : '300px',
        margin : '15px 20px'
    },
    aiAssistantConfirmationHeader : {
        textAlign: 'end',
        marginBottom: '-11px',
    },
    aiAssistantConfirmationPopupCloseIcon :{
        color: '#b4b2b2',
        fontSize: '16px',
        cursor: 'pointer',
        marginBottom: '14px',
        marginTop: '-16px',
        marginRight: '-13px'
    },
    aiAssistantConfirmationMessage: {
        fontSize: '16px',
        lineHeight: '23px',
        color: '#707070',
        paddingLeft: '20px',
        paddingRight: '20px',
        textAlign: 'center'
    },
    aiAssistantConfirmationFooter: {
        textAlign: 'center',
        marginTop: '12px'
    },
    listingLabels : {
        margin : '10px 5px'
    },
    arrowDown: {
        position: "relative",
        mt: "10px",
        // marginBottom: "10px",
        "&::before": {
          backgroundColor: "white",
          content: '""',
          display: "block",
          position: "absolute",
          width: 12,
          height: 12,
          bottom: -6,
          transform: "rotate(45deg)",
          left: "calc(50% - 6px)"
        }
      },
}))
function InfoPopover(props : {anchorEl : HTMLButtonElement | null, handleClose : () => void, labels : string[] | undefined}) {
    const classes = useStyles();
    // const handleClose = () => {
    //     setAnchorEl(null);
    //   };
    const [status, setStatus] = useState('')
    const location = useLocation();
    useEffect(() => {
        const query = new URLSearchParams(location.search); 
        const currentStatus = query.get('resourceStatus') ?? ""
        if(currentStatus === 'backlog'){
            setStatus('approval')
        }
        if(currentStatus === 'approval'){
            setStatus('approved')
        }
        // setStatus(query.get('resourceStatus') ?? "");
    }, [location.search])

      const open = Boolean(props.anchorEl);
      const id = open ? 'simple-popover' : undefined;
  return (
    <Popover 
        id={id}
        open={open}
        anchorEl={props.anchorEl}
        onClose={props.handleClose}
        anchorOrigin={{
            vertical: 'bottom',
            horizontal: 'center',
        }}
        transformOrigin={{
            vertical: 'top',
            horizontal: 'center',
        }}   
        // PaperProps={{
        //     style: {
        //       backgroundColor: "transparent",
        //       boxShadow: "none",
        //       borderRadius: 0
        //     }
        //   }}
    >
         <Box className={classes.arrowDown} />
        <div className={classes.popoverBody}>
            <div className={classes.aiAssistantConfirmationHeader}>
                <CloseIcon classes={{root: classes.aiAssistantConfirmationPopupCloseIcon}} 
                    onClick={props.handleClose}
                />
            </div>
            <Typography className={classes.aiAssistantConfirmationMessage}>{props.labels ? `Please approve or mark as delete below annotations before moving them to ${status}: ` : 'In your selected datas, all the video annotations are not approved'}</Typography>
            <div className={classes.listingLabels}>
                <ul>
                    {props?.labels?.map(data => {
                        return <li style={{textAlign : 'left', color: '#707070',}}>{data}</li>
                    })}
                </ul>
            </div>
            <div className={classes.aiAssistantConfirmationFooter}>
                <Button onClick={props.handleClose} variant="contained" size="medium" style={{backgroundColor: "#409545", color: "white", width: "104px", height: "30px", fontSize: "11px"}}>
                    OK
                </Button>
            </div>
        </div>
    </Popover>
  )
}

export default InfoPopover
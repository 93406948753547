import { Typography } from '@material-ui/core';
import { connect, ConnectedProps } from 'react-redux';

import { IReduxState } from '../../../../../common';

interface IProps extends TPropsFromRedux {

}

function Analytics(props: IProps) {

    return (
        <Typography component="h6" variant="h6">
            Projects Analytics Coming Soon
        </Typography>
    )
}

const mapProps = (state: IReduxState) => {
    return {
    };
}

const connector = connect(mapProps, {});

export type TPropsFromRedux = ConnectedProps<typeof connector>

export default connector(Analytics);
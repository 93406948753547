import { Fragment, useCallback, useEffect, useMemo, useState } from "react";
import { connect, ConnectedProps, useDispatch } from "react-redux";
import { Chip, makeStyles, Tooltip, Typography, Checkbox, CircularProgress, IconButton, Icon, TextField, Button } from "@material-ui/core";
import {
  setCollectionResourceLabelCount,
  setDataSetCollectionDataBoost,
  setDefaultDatasetCollection,
  updateDataSetCollection,
  confirmDeleteCollection
} from "../../../../../../../../../../../store/actions";
import {
  convertDate,
  IReduxState,
  UpdateModelApiRequestPayload,
} from "../../../../../../../../../../../common";
import { DataService, ModelService } from "../../../../../../../../../../../services";
import { Delete, FileCopy, GetApp } from "@material-ui/icons";
import TrainDataSetCollectionButton from "./trainDataSetCollectionButton.component";
import ReTrainDataSetCollectionButton from "./reTrainDataSetCollectionButton.component";
import { copyDataSetToAnotherModelButtonTooltipText, deleteButtonIconColor } from "../../data/constants/constants";
import { AddModelCollectionScenario } from "./AddModelCollection";
import EditIcon from '@material-ui/icons/Edit';
import ClearIcon from '@material-ui/icons/Clear';
import CheckIcon from '@material-ui/icons/Check';
import Autocomplete from "@material-ui/lab/Autocomplete/Autocomplete";
import DeleteIcon from '@material-ui/icons/Delete';
import { InputFieldState } from "../../../../../../../../../../../common/constants/interfaces/inputFieldState";
import dataSetCopyingToAnotherModelProcessService, { getProcessProgressApiResponse } from "../../../../../../../../../../../services/dataSetCopyingToAnotherModelProcessService";
import { Alert } from "@material-ui/lab";
import usePreviousValue from '../../../../../../../../../../../hooks/usePreviousValue';

const duplicateDropdownData  =  Array(100).fill(0).map((e,i)=>(i+1).toString())
const useStyles = makeStyles((theme) => ({
  title: {
    font: '600 16px/24px Nunito Sans',
    color: '#000',
    letterSpacing: '0',
    marginLeft: '30px'
  },
  infoBlock: {
    paddingTop: theme.spacing(4),
    paddingLeft: theme.spacing(3.75)
  },
  actions: {
    display: "flex",
    justifyContent: "space-around",
    width: "200px",
    marginLeft: "auto",
    marginRight: "auto",
  },
  info: {
    display: "flex",
    alignItems: 'center',
    paddingBottom: theme.spacing(2),
  },
  infoLabel: {
    margin: 0,
    width: "38%",
    font: "normal normal normal 13px/22px Nunito Sans",
    letterSpacing: "0px",
    color: "#98A6B3" 
  },
  infoValue: {
    margin: 0,
    overflow: "auto",
    textOverflow: "inherit",
    whiteSpace: "normal",
    maxWidth: "70%",
    font: "normal normal normal 13px/22px Nunito Sans",
    letterSpacing: "0px",
    color: "#212121"
  },
  labelBlock: {
    width: "300px",
    marginLeft: "auto",
    marginRight: "auto",
    marginBottom: theme.spacing(2),
  },
  indicators: {
    position: "absolute",
    bottom: "40px",
    width: "50px",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    cursor: "pointer",
    transition: "opacity 0.25s ease-in-out",
    borderRadius: "50%",
    background: "rgba(2, 141, 154, 0.1)",
  },
  leftIndicator: {
    left: 10,
  },
  rightIndicator: {
    right: 10,
  },
  status: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    justifyContent: "center",
    height: "100%",
  },
  delete: {
    color: "#EF8A8A",
  },
  chip: {
    minWidth: "75px",
    marginBottom: 5,
    backgroundColor: theme.palette.grey[50],
    marginRight: theme.spacing(1),
  },
  checkboxRoot: {
    padding: 0,
  },
  deleteButton: {
    color: deleteButtonIconColor,
  },
  disabledDeleteBtn: {
    opacity: "0.6",
    color: "#EF8A8A !important"
  },
  addDescriptionBtns: {
    justifyContent: 'flex-end'
  },
  flex: {
    display: 'flex'
  },
  flexColumn: {
    flexDirection: 'column'
  },
  flexCenter: {
    justifyContent: 'center',
    alignItems: 'center'
  },
  labelCountSpinnerContainer: {
    width: '100%',
    minHeight: '250px'
  },
  labelsTitle: {
    width: '100%',
    marginLeft: '0 !important'
  },
  labelsValue: {
    marginLeft: '1.5rem'
  },
  lookAsDisabled: {
    opacity: 0.5
  },
  pointerEventsNone: {
    pointerEvents: "none"
  },
  resourceLabelColumnHeading: {
    fontStyle: "italic",
    color: "black"
  }
}));

const CollectionViewWhenNoCollectionPresent = () => {
  return (
    <Typography component="h6" variant="h6">
      Collection Analytics Coming Soon
    </Typography>
  );
};
export interface IAction {
  downloadHandler: Function,
  trainHandler: Function,
  reTrainHandler: Function,
  copyCollectionHandler?: Function,
  shuffleHandler : Function,
  splitHandler : Function
}
export interface DataSetPropType extends TPropsFromRedux {
  defaultCollectionId: string,
  modelId: string;
  updateDefaultCollectionId: Function,
  isFetchDefaultId: boolean,
  setFetchingDefaultId: Function,
  actions: IAction,
  modelCollectionScenario: AddModelCollectionScenario
}

type EditedResourceLabel = {
  action: "remove" | "editCount",
  label: string,
  countEditedValue?: number,
  errorMessage?: string
}

function DataSetCollectionView(props: DataSetPropType) {
  const classes = useStyles();
  const { selected, defaultCollectionId, modelId, updateDefaultCollectionId, isFetchDefaultId, setFetchingDefaultId, actions, modelCollectionScenario, confirmDeleteCollection } = props;
  const dispatch = useDispatch();
  const [isEditingDescription, setIsEditingDescription] = useState(false);
  const [isRemarksBeingUpdatedInDB, setIsRemarksBeingUpdatedInDB] = useState(false);
  const [descriptionValue, setDescriptionValue] = useState(selected?.description || '');
  const [isFetchingResourceLabels, setIsFetchingResourceLabels] = useState(false);

  const {setCollectionResourceLabelCount} = props;

  const [isEditingResourceLabels, setIsEditingResourceLabels] = useState(false);
  const [editedResourceLabels, setEditedResourceLabels] = useState<EditedResourceLabel[]>([]);
  const [remarksTextState, setRemarksTextState] = useState<InputFieldState<string>>({value: (selected?.remarks?.text || "")});

  const [cloningResourceLabelsToNewDataSetCollectionInDbDetails, setCloningResourceLabelsToNewDataSetCollectionInDbDetails] = useState({
    isCloningHappeningInDB: false,
    newDataSetCollectionNameWhichIsBeingCreatedAsPartOfCloning: ""
  });

  const doesAnyResourceLabelHasError = useMemo(() => {
    if (editedResourceLabels?.length>0) {
      for (const editedResourceLabel of editedResourceLabels) {
        if (editedResourceLabel.errorMessage) {
          return true
        }
      }
    }
    
    return false;
  }, [editedResourceLabels])

  const handleDefaultCollectionChange = async (event: any) => {
    const modelPayload: UpdateModelApiRequestPayload = event.target.checked ? {
      id: [modelId],
      defaultDataSetCollection: selected?._id
    } : { id: [modelId], defaultDataSetCollection: null };
    setFetchingDefaultId(true);
    await ModelService.updateModel(modelPayload);
    if (modelPayload.defaultDataSetCollection && selected) {
      props.setDefaultDatasetCollection({name: selected?.name, id: selected?._id})
    }
    else{
      props.setDefaultDatasetCollection(null)
    }
    updateDefaultCollectionId(modelId);
  }

  // const [dropdownOpen, setDropdownOpen] = useState(false);
  const [dataBoost,setDataBoost]  = useState('1')

  // const toggle = () => setDropdownOpen((prevState) => !prevState);

  const onDataBoostChange = async(ele: string) => {
      // if(selectedDataObject?._id) {
      //     updateData({  id: [selectedDataObject?._id],dataBoost: ele }, { }, {shouldFetchLatestData: false});
      // }
      if(selected){
        DataService.setDataSetCollectionDataBoost({ id: selected._id, dataBoost: Number(ele)})
      }
      setDataBoost(ele.toString())
      dispatch(setDataSetCollectionDataBoost({modelCollectionId: selected?._id || "", dataBoost: Number(ele) }))
      // setSelectedDataObject((prev) => (JSON.parse(JSON.stringify({...prev, dataBoost: ele.toString()}))))
  }

  /**
   * To fetch the resources labels and count for specific collection
   */
  const fetchResourceLabelsData = useCallback(async ()=>{
    if (selected) {
      try {
        const data = await DataService.getResourceLabelsAndCountForCollection(selected._id);
        const labelsData = data.data;
        const modifiedLabels = Object.entries(labelsData).length ? Object.entries(labelsData).map(entry => {
          return {
            label: entry[0],
            count: entry[1]
          }
        }) : []
        const updatedLabel = { ...selected, dataSetResourcesLabelCount: [...modifiedLabels] }
        setCollectionResourceLabelCount(updatedLabel)
      } catch (err) {
        console.error(err)
      }
      setIsFetchingResourceLabels(false)
    }
  }, [selected, setCollectionResourceLabelCount])

  const handleOnDataSetCopyToAnotherModelProcessCompleted = useCallback(
    () => {
      fetchResourceLabelsData();
    },
    [fetchResourceLabelsData],
  );

  useEffect(() => {
    // if (selected && !selected.dataSetResourcesLabelCount) {
    if (selected?._id) {
      setIsFetchingResourceLabels(true)
      fetchResourceLabelsData();
    }
  }, [selected?._id])

  /**
   * To update the dataset collection description
   * @param value description value
   */
  const updateDatasetCollectionDescription = async (value: string) => {
    if (selected) {
      try {
        const data = await DataService.updateDatasetCollection({ id: selected._id, description: value });
        if (data.status === 200) {
          props.updateDataSetCollection({...selected, description: data.data.description, updated_at: data.data.updated_at})
        }
      } catch (e) {
        alert(e)
      }
      setIsEditingDescription(false)
    }
  }

  useEffect(() => {
    if(selected?.dataBoost) {
      setDataBoost(selected?.dataBoost?.toString() || '1')
    }
  }, [selected?.dataBoost])

  const onSaveRemarksClick = async() => {
    if (selected) {
      setIsRemarksBeingUpdatedInDB(true)
      try {
        const data = await DataService.updateDatasetCollection({ id: selected._id, remarks: remarksTextState.value });
        if (data.status === 200) {
          props.updateDataSetCollection({...selected, remarks: data.data.remarks, updated_at: data.data.updated_at})
          setRemarksTextState(oldState=>{return {
            ...oldState,
            isDirty: false,
            isTouched: false
          }})   
          setIsRemarksBeingUpdatedInDB(false)
        }
      } catch (e) {
        setIsRemarksBeingUpdatedInDB(false)
        alert(e)
      }
    }
  }

  return (
    <Fragment>
      {selected !== null ? (
        <Typography className={classes.title} style={{ marginTop: 40 }}>Collection:</Typography>
      ) : (
        ""
      )}
      {selected !== null ? (
        <div className={classes.infoBlock}>
          <div className={classes.info}>
            <p className={classes.infoLabel}>Actions:</p>
            <div className={classes.infoValue}>
              {
                selected?.isResourcesFileDownloadLinkBeingFetched
                  ? <Icon style={{ verticalAlign: 'middle', padding: '12px' }}><CircularProgress size="20px" color="primary" /></Icon>
                  : <Tooltip
                    title={
                      !(selected?.totalNoOfResources && selected?.totalNoOfResources > 0)
                        ? "Collection does not have any resources to download"
                        : "Download collection's resources details in a csv file"
                    }
                  >
                      <IconButton
                        color="primary"
                        disabled={
                          !(selected?.totalNoOfResources && selected?.totalNoOfResources > 0)
                            ? true
                            : false
                        }
                        onClick={() => {
                          actions.downloadHandler(selected)
                        }}
                      >
                        <GetApp />
                      </IconButton>
                  </Tooltip>
              }

              {
                !selected?.isDeleteHappening
                ?
                <Tooltip title={"Delete the collection"}>
                  <IconButton
                    id={"deleteBtn_sideDrawer" + selected?._id}
                    classes={{ root: classes.deleteButton, disabled: classes.disabledDeleteBtn }}
                    aria-label="export"
                    disabled={selected?.isDeleteHappening}
                    onClick={() => {
                      confirmDeleteCollection({
                        multiDelete: false,
                        collectionToDelete: selected || null,
                        showConfirmDeleteModal: true,
                        selectedCollectionIds: [],
                        collectionType: "dataSet"
                      })
                    }
                    }
                  >
                    <Delete />
                  </IconButton>
                </Tooltip>
                :
                <Tooltip title="Deleting data-set collection">
                <div style={{marginLeft: "12px", marginRight: "12px", display: "inline"}}>
                  <CircularProgress style={{color: "#EF8A8A", alignSelf: 'center', marginBottom: "-7px"}} size="21px" color="primary" />
                </div>
              </Tooltip>              
              }
              <TrainDataSetCollectionButton
                onClick={() => {
                  actions.trainHandler(selected)
                }}
                isSelected={
                  modelCollectionScenario === 'trainingDataSetCollections' && props.trainingDataDirectoriesSelectedStateForAddModelCollection.value.indexOf(selected?.name || '') !== -1
                    ? true
                    : false
                }
                isDefaultDataSetCollectionExist = {(props.defaultDatasetCollection && props.defaultDatasetCollection.id.length > 0) || false}
              />
              <ReTrainDataSetCollectionButton
                onClick={() => {
                  actions.reTrainHandler(selected)
                }}
                isSelected={
                  modelCollectionScenario === 'reTrainingDataSetCollections' && props.trainingDataDirectoriesSelectedStateForAddModelCollection.value.indexOf(selected?.name || '') !== -1
                    ? true
                    : false
                }
                isDefaultDataSetCollectionExist = {(props.defaultDatasetCollection && props.defaultDatasetCollection.id.length > 0) || false}
              />
              <Tooltip title={copyDataSetToAnotherModelButtonTooltipText}>
                <IconButton
                  color="primary"
                  onClick={() => {
                    props.actions.copyCollectionHandler && props.actions.copyCollectionHandler(selected)
                  }}
                >
                  <FileCopy />
                </IconButton>
              </Tooltip>
            </div>
          </div>
          <div className={classes.info}>
            <p className={classes.infoLabel}>Name:</p>
            <p className={classes.infoValue}>
              <Tooltip title={selected?.name as string}>
                <span>{selected?.name}</span>
              </Tooltip>
            </p>
          </div>

          <div style={{marginTop: "20px"}}></div>
          <DataSetCopyProcessInfoSection 
            dataSetCollectionId={selected?._id || ""}
            onDataSetCopyToAnotherModelProcessCompleted={handleOnDataSetCopyToAnotherModelProcessCompleted}
          />

          {/* <div className={classes.info}>
            <p className={classes.infoLabel}>Tag:</p>
            <Tooltip title={selected?.tags.join(", ") as string}>
              <div
                className={classes.infoValue}
                style={{
                  maxWidth: 201,
                  overflow: "auto",
                  whiteSpace: "normal",
                  textOverflow: "inherit",
                }}
              >
                {selected?.tags.map((tag: string) => (
                  <Chip
                    key={tag}
                    classes={{ root: classes.chip }}
                    label={tag}
                  />
                ))}
              </div>
            </Tooltip>
          </div> */}
          {/* <div className={classes.info}>
                    <p className={classes.infoLabel}>Max Models</p>
                    <p className={classes.infoValue}>{selected?.max_models}</p>
                </div> */}
          {/* <div className={classes.info}>
                    <p className={classes.infoLabel}>Response <br /> Variable:</p>
                    <p className={classes.infoValue}>{selected?.response_variable}</p>
                </div> */}
          {/* <div className={classes.info}>
                    <p className={classes.infoLabel}>Max runtime <br /> secs per <br /> model:</p>
                    <p className={classes.infoValue}>{selected?.max_runtime_secs_per_model}</p>
                </div> */}
          <div className={classes.info}>
            <p className={classes.infoLabel}>Created At:</p>
            <p className={classes.infoValue}>
              {selected && convertDate(selected.created_at)}
            </p>
          </div>
          <div className={classes.info} style={{marginTop: "8px"}}>
                        <p className={classes.infoLabel}>Data Boost:</p>
                        <p className={classes.infoValue} style={{width: '59%', overflow:"hidden", display: "flex", alignItems: "end"}}>
                        <div style={{marginBottom: "4px", marginRight: "4px"}}>x</div> 
                        <Autocomplete
                          onChange={(event: any, newValue: string | null) => {
                            onDataBoostChange(newValue?.toString()  || '1');
                          }}
                          disableClearable
                            value={dataBoost.toString()}
                            // disablePortal
                            id="combo-box-demo"
                            options={duplicateDropdownData}
                            renderInput={(params: any) => <TextField {...params} style={{width: "auto"}} />}
                            />
                         </p>
                        </div>
          {selected?.created_by && <div className={classes.info}>
            <p className={classes.infoLabel}>Created By:</p>
            <p className={classes.infoValue} style={{maxWidth: "200px", wordWrap: "break-word", whiteSpace: "unset"}}>
              {selected?.created_by}
            </p>
          </div>}
          <div className={classes.info}>
            <p className={classes.infoLabel}>Last Updated At:</p>
            <p className={classes.infoValue}>
              {selected && convertDate(selected.updated_at)}
            </p>
          </div>
          {selected?.parentCollections && selected?.parentCollections.length >= 1 &&
          <div className={classes.info} style={{justifyContent: 'flex-start'}}>
            <p className={classes.infoLabel}>Parent Data-Set Collections:</p>
            <p className={classes.infoValue} 
            // style={{width: '65%', textOverflow: 'ellipsis', whiteSpace: 'nowrap', overflow: 'hidden'}}
            >
            {/* <Tooltip title={selected?.parentCollections.map(parentCollections => parentCollections.name).join('  ,  ')}> */}
                <div style={{width: '100%', overflow: 'auto', maxHeight: '200px'}}>
              {
                selected?.parentCollections?.map(parentDataSetCollection=>{
                    return (
                      <Tooltip title={parentDataSetCollection.name}>
                        <Chip
                        key={parentDataSetCollection._id}
                        label={parentDataSetCollection.name}
                        classes={{root: classes.chip}}
                        style={{textOverflow: 'ellipsis', whiteSpace: 'nowrap', overflow: 'hidden'}}
                      />
                      </Tooltip>
                    )
                })
              }
              </div>
              {/* </Tooltip> */}
            </p>
          </div>}
          <div className={classes.info}>
            {!isEditingDescription && <p className={classes.infoLabel}>Description:
              <Tooltip title={"Edit Description"}>
                <IconButton
                  id={"editButton-desciption-btn"}
                  color="primary"
                  aria-label="export"
                  size="small"
                  onClick={() => {
                    setIsEditingDescription(true)
                  }
                  }
                >
                  <EditIcon fontSize="inherit" />
                </IconButton>
              </Tooltip>
            </p>
            }
            {
              !isEditingDescription &&
              <p
                className={classes.infoValue}
                style={{
                  overflow: "auto",
                  textOverflow: "inherit",
                  whiteSpace: "normal",
                  maxWidth: 200,
                }}
              >
                {descriptionValue || selected?.description}
              </p>
            }
          </div>
          {
            isEditingDescription && selected &&
            <div>
              <TextField
                label="Description"
                id="description-input"
                classes={{ root: classes.flex}}
                value={descriptionValue}
                onChange={(e) => {
                  setDescriptionValue(e.target.value)
                }}
                variant="outlined"
                size="small"
              />
              <div className={`${classes.addDescriptionBtns} ${classes.flex}`}>
                <Tooltip title={"Cancel"}>
                  <IconButton
                    id={"cancel-description-btn"}
                    color="primary"
                    classes={{ root: classes.deleteButton }}
                    aria-label="export"
                    onClick={() => {
                      setDescriptionValue(selected.description)
                      setIsEditingDescription(false)
                    }
                    }
                  >
                    <ClearIcon fontSize="small" />
                  </IconButton>
                </Tooltip>
                <Tooltip title={"Save"}>
                  <IconButton
                    id={"save-desciption-btn"}
                    color="primary"
                    aria-label="export"
                    onClick={() => {
                      updateDatasetCollectionDescription(descriptionValue)
                    }
                    }
                  >
                    <CheckIcon fontSize="small" />
                  </IconButton>
                </Tooltip>
              </div>
            </div>
          }
          {
            <div className={classes.info}>
              <p className={classes.infoLabel}>Default Collection:</p>
              <p className={classes.infoValue}>
                {!isFetchDefaultId && <Checkbox checked={defaultCollectionId === selected?._id} classes={{ root: classes.checkboxRoot }} onChange={(e) => handleDefaultCollectionChange(e)} />}
                {isFetchDefaultId && <CircularProgress size={20}/>}
              </p>
            </div>
          }
           {/* Remarks Sections */}
           <>
              <p style={{marginBottom: "10px"}} className={classes.infoLabel}>Add Remarks:</p>
              <textarea 
                style={{width: "100%", minHeight: "83px", marginBottom: "10px", fontSize: '13px', fontFamily: 'Nunito Sans', borderRadius: "5px"}}
                value={remarksTextState.value}
                onChange={(event)=>{
                    setRemarksTextState(oldState=>({
                        ...oldState,
                        isDirty: true,
                        value: event.target.value
                    }))
                }}
                disabled={isRemarksBeingUpdatedInDB}
            />  
            <Button color="primary" variant="contained"
                onClick={()=>{
                onSaveRemarksClick();
                return;
                }}
                disabled={isRemarksBeingUpdatedInDB || !remarksTextState.isDirty ? true : false}
                style= {{marginBottom: "20px"}}
              >
                  Save Remarks
              </Button> 
          </>
          {
            selected?.dataSetResourcesLabelCount && 
              <div className={classes.info + ' ' + classes.flexColumn}>
                {
                  selected.dataSetResourcesLabelCount.length ?
                  <> 
                    <Typography className={classes.labelsTitle + ' ' + classes.title} style={{ marginBottom: 10 }}>
                      Resource Labels:
                      {
                        !isEditingResourceLabels &&
                        <Tooltip title={"Clone resource labels to a new data-set collection"}>
                          <IconButton
                            color="primary"
                            aria-label="export"
                            size="small"
                            onClick={() => {
                              setIsEditingResourceLabels(true)
                            }}
                          >
                            <EditIcon fontSize="inherit" />
                          </IconButton>
                        </Tooltip>              
                      }
                    </Typography>
                    <div
                      className={`${classes.flex}`}
                      style={{
                        width: "100%"
                      }}
                    >
                      <div 
                        className={`${classes.infoLabel} ${classes.resourceLabelColumnHeading}`}
                      >
                        Label
                      </div>
                      <div 
                        className={`${classes.infoValue} ${classes.resourceLabelColumnHeading}`}
                      >
                        Count
                      </div>
                    </div>
                    {selected.dataSetResourcesLabelCount.map(resourceValue => {

                      const resourceEditedLabel = editedResourceLabels?.find((editedResourceLabel)=>{
                        if (editedResourceLabel.label === resourceValue.label) {
                          return true;
                        }
                        return false;
                      })

                      const isResourceLabelActionSetAsRemovedInEditedResourceLabels = 
                        resourceEditedLabel
                        ? resourceEditedLabel.action === 'remove'
                          ? true
                          : false
                        : false
                      
                      return (
                        <div 
                          className={`
                            ${classes.flex}
                            ${isResourceLabelActionSetAsRemovedInEditedResourceLabels ? `${classes.lookAsDisabled} ${classes.pointerEventsNone}` : ""}
                          `} 
                          style={{width: '100%'}}
                        >
                          <p className={classes.infoLabel}>{resourceValue.label || ''}:</p>
                          {
                            isEditingResourceLabels
                            ?
                              <TextField 
                                // label="Count to set for cloning"
                                defaultValue={resourceValue.count}
                                value={resourceEditedLabel?.countEditedValue}
                                inputProps={{
                                  type: "number",
                                  max: resourceValue.count,
                                  min: 0
                                }}
                                size="small"
                                style={{
                                  width: "100px"
                                }}
                                error={resourceEditedLabel?.errorMessage ? true : false}
                                helperText={resourceEditedLabel?.errorMessage || ""}
                                onChange={(event) => {
                                  const countToSet = Number(event.target.value);
                                  setEditedResourceLabels(oldEditedResourceLabels=>{

                                    const editedResourceLabelIndex = oldEditedResourceLabels.findIndex(editedResourceLabel=>editedResourceLabel.label === resourceValue.label)

                                    const editedResourceLabelAlreadyExist = editedResourceLabelIndex !== -1 ? true : false;

                                    const errorMessage: string = countToSet > resourceValue.count
                                      ? `Count cannot be set more than ${resourceValue.count}`
                                      : countToSet < 0
                                        ? `Count cannot be set less than 0`
                                        : ``;
                                    
                                    
                                    if (!editedResourceLabelAlreadyExist) {
                                      if (countToSet !== resourceValue.count) {
                                        oldEditedResourceLabels.push({
                                          action: "editCount",
                                          label: resourceValue.label,
                                          countEditedValue: countToSet,
                                          errorMessage: errorMessage
                                        })
                                      }
                                    } else {
                                      if (countToSet !== resourceValue.count) {
                                        oldEditedResourceLabels[editedResourceLabelIndex] = {
                                          action: "editCount",
                                          label: resourceValue.label,
                                          countEditedValue: countToSet,
                                          errorMessage: errorMessage                                        
                                        }
                                      } else {
                                        oldEditedResourceLabels.splice(editedResourceLabelIndex, 1);
                                      }
                                    }

                                    return [...oldEditedResourceLabels]
                                  })
                                }}
                              />
                            :
                              <p className={classes.infoValue + ' ' + classes.labelsValue}>{resourceValue.count || 'NA'}</p>
                          }
                          {
                            isEditingResourceLabels &&
                            <p
                              style={{
                                marginLeft: "15px"
                              }}
                            >
                              <IconButton
                                aria-label="export"
                                style={{
                                  color: deleteButtonIconColor,
                                  width: "1px",
                                  height: "1px",
                                }}
                                size="small"
                                onClick={() => {
                                  setEditedResourceLabels(oldEditedResourceLabels=>{

                                    const editedResourceLabelIndex = oldEditedResourceLabels.findIndex(editedResourceLabel=>editedResourceLabel.label === resourceValue.label)

                                    const editedResourceLabelAlreadyExist = editedResourceLabelIndex !== -1 ? true : false;

                                    if (!editedResourceLabelAlreadyExist) {
                                      oldEditedResourceLabels.push({
                                        action: "remove",
                                        label: resourceValue.label
                                      })
                                    } else {
                                      oldEditedResourceLabels[editedResourceLabelIndex] = {
                                        action: "remove",
                                        label: resourceValue.label
                                      }
                                    }

                                    return [...oldEditedResourceLabels]
                                  })                                  
                                }}                                
                              >
                                <DeleteIcon />
                              </IconButton>
                            </p>
                          }
                        </div>
                      )
                    })}

                    {
                      isEditingResourceLabels &&
                      <>
                      <div
                        style={{
                          display: "flex",
                          justifyContent: "space-around",
                          width: "100%",
                          marginTop: "10px"
                        }}
                      >
                        <Button
                          onClick={()=>{
                            setEditedResourceLabels([]);
                            setIsEditingResourceLabels(false);
                            setCloningResourceLabelsToNewDataSetCollectionInDbDetails(oldState=>({...oldState, newDataSetCollectionNameWhichIsBeingCreatedAsPartOfCloning: ""}))
                          }}
                          disabled={
                            cloningResourceLabelsToNewDataSetCollectionInDbDetails.isCloningHappeningInDB
                          }
                          variant="contained"
                          size="small"
                          style={{
                            backgroundColor: "white"
                          }}
                        >Cancel</Button>

                        <Button
                          variant="contained"
                          size="small"
                          color="primary"                        
                          disabled={
                            editedResourceLabels?.length === 0 ||
                            cloningResourceLabelsToNewDataSetCollectionInDbDetails.isCloningHappeningInDB ||
                            doesAnyResourceLabelHasError === true
                          }                          
                          onClick={async ()=>{

                            setCloningResourceLabelsToNewDataSetCollectionInDbDetails(oldState=>({...oldState, isCloningHappeningInDB: true}))

                            const uniqueDatSetCollectionName = await DataService.getDataSetCollectionUniqueNameIfAlreadySameNameExistInModel({
                              modelId: modelId,
                              datasetCollectionName: selected.name
                            })

                            setCloningResourceLabelsToNewDataSetCollectionInDbDetails(oldState=>({...oldState, newDataSetCollectionNameWhichIsBeingCreatedAsPartOfCloning: uniqueDatSetCollectionName}))
                            
                            await DataService.addCollection({
                              name: uniqueDatSetCollectionName,
                              description: `DataSet Collection created from ${selected.name}`,
                              model: modelId,
                              copyResourceLabelsFrom: {
                                dataSetCollectionId: selected._id.toString(),
                                labelsToNotIncludeInResources: editedResourceLabels?.filter(editedResourceLabel => {
                                  if (editedResourceLabel.action === 'remove') {
                                    return true;
                                  }
                                  return false;
                                })?.map(editedResourceLabel=>editedResourceLabel.label),
                                labelsCountToSetInRandomOrder: editedResourceLabels?.filter(editedResourceLabel => {
                                  if (editedResourceLabel.action === 'editCount') {
                                    return true;
                                  }
                                  return false;
                                })?.map(editedResourceLabel=>({
                                  label: editedResourceLabel.label,
                                  count: editedResourceLabel.countEditedValue || 0
                                }))
                              }
                            })

                            setCloningResourceLabelsToNewDataSetCollectionInDbDetails(oldState=>({ ...oldState, isCloningHappeningInDB: false}))

                          }}
                        >
                          {
                            cloningResourceLabelsToNewDataSetCollectionInDbDetails.isCloningHappeningInDB
                            ? <CircularProgress size={20} />
                            : <>Clone</>
                          }
                        </Button>
                      </div>

                      {
                        cloningResourceLabelsToNewDataSetCollectionInDbDetails.newDataSetCollectionNameWhichIsBeingCreatedAsPartOfCloning &&
                        <div
                          style={{
                            fontSize: "14px",
                            fontStyle: "italic",
                            color: "#868585",
                            marginTop: "10px"
                          }}
                        >
                          {
                            cloningResourceLabelsToNewDataSetCollectionInDbDetails.isCloningHappeningInDB
                            ? <>Resource Labels are being cloned in <b>{cloningResourceLabelsToNewDataSetCollectionInDbDetails.newDataSetCollectionNameWhichIsBeingCreatedAsPartOfCloning}</b> data-set collection</>
                            : <>Resource Labels are cloned in <b>{cloningResourceLabelsToNewDataSetCollectionInDbDetails.newDataSetCollectionNameWhichIsBeingCreatedAsPartOfCloning}</b> data-set collection</>
                          }
                        </div>
                      }

                      </>
                    }
                    </> :
                    <></>
                }
              </div>
          }
          {
            isFetchingResourceLabels &&
            <div className={`${classes.flex} ${classes.flexColumn} ${classes.infoLabel} ${classes.flexCenter} ${classes.labelCountSpinnerContainer}`}>
              <CircularProgress className="m-3" />
              <span>Fetching resources label...</span>
            </div> 
          }
        </div>
      ) : (
        <CollectionViewWhenNoCollectionPresent />
      )}
      {/* <div className={clsx(classes.leftIndicator, classes.indicators)}>
                <IconButton aria-label="previous" color="primary" disabled={isUpdating || isFetching} onClick={() => switchIndex(selectedCollectionIndex - 1)}><KeyboardArrowLeft />
                </IconButton>
            </div>
            <div className={clsx(classes.rightIndicator, classes.indicators)}>
                <IconButton aria-label="previous" color="primary" disabled={isUpdating || isFetching} onClick={() => switchIndex(selectedCollectionIndex + 1)}><KeyboardArrowRight />
                </IconButton>
            </div> */}
    </Fragment>
  );
}

const mapProps = (state: IReduxState) => {
  return {
    isAdding: state.collection.isAdding,
    selected: state.collection.selected,
    trainingDataDirectoriesSelectedStateForAddModelCollection: state.collection.modelCollection.trainingDataDirectoriesSelectedStateForAddModelCollection,
    defaultDatasetCollection: state.collection.defaultDataSetCollection
  };
};

const connector = connect(mapProps, { setDefaultDatasetCollection, updateDataSetCollection, setCollectionResourceLabelCount, confirmDeleteCollection });

export type TPropsFromRedux = ConnectedProps<typeof connector>;

export default connector(DataSetCollectionView);

const useDataSetCopyProcessInfoSectionStyles = makeStyles((theme) => ({
  statusFailure: {
    color: "red"
  }
}));
function DataSetCopyProcessInfoSection(props: {
  dataSetCollectionId: string,
  onDataSetCopyToAnotherModelProcessCompleted: () => void
}) {
  const classes = useDataSetCopyProcessInfoSectionStyles();

  const [dataSetCopyProcessProgressDetails, setDataSetCopyProcessProgressDetails] = useState<getProcessProgressApiResponse>();
  const previousValueOfDataSetCopyProcessProgressDetails = usePreviousValue(dataSetCopyProcessProgressDetails);

  const dataSetCollectionId = props.dataSetCollectionId;

  const parentDataSetCollectionResourcesCount: number = dataSetCopyProcessProgressDetails?.dataSetCollectionToCopyFrom?.resourcesCount || 0;
  const noOfParentResourcesCopied: number = dataSetCopyProcessProgressDetails?.noOfParentDataSetResourcesCopied || 0;

  const { 
    onDataSetCopyToAnotherModelProcessCompleted
  } = props;

  const copyProcessPercentCompleted: number = useMemo(() => {
    if (
      noOfParentResourcesCopied === 0 &&
      parentDataSetCollectionResourcesCount === 0
    ) {
      return 100;
    }
    return ((noOfParentResourcesCopied/parentDataSetCollectionResourcesCount) * 100)
  }, [noOfParentResourcesCopied, parentDataSetCollectionResourcesCount])

  useEffect(() => {
    const executeFunction = async  () => {
      const response = await dataSetCopyingToAnotherModelProcessService.getProcessProgress({
        dataSetCollectionIdWhereResourcesBeingCopiedIn: dataSetCollectionId
      });
      setDataSetCopyProcessProgressDetails(response);
    }
    if (dataSetCollectionId) {
      executeFunction();
    }
  }, [dataSetCollectionId])
  
  useEffect(() => {

    /**
     * fetching progress details after some time interval
     */

    let timeIntervalId: any;
    if (
      dataSetCollectionId && dataSetCopyProcessProgressDetails &&
      !dataSetCopyProcessProgressDetails.failureMessage
    ) {
      timeIntervalId = setInterval(async ()=>{
        const response = await dataSetCopyingToAnotherModelProcessService.getProcessProgress({
          dataSetCollectionIdWhereResourcesBeingCopiedIn: dataSetCollectionId
        });
        setDataSetCopyProcessProgressDetails(response);        
      }, 3000)
    }
    return () => {
      if (
        timeIntervalId !== null &&
        timeIntervalId !== undefined
      ) {
        clearInterval(timeIntervalId)
      }
    }
  }, [dataSetCollectionId, dataSetCopyProcessProgressDetails])

  useEffect(() => {
    if (
      !dataSetCopyProcessProgressDetails && previousValueOfDataSetCopyProcessProgressDetails
    ) {

      /**
       * data-set copy process has been completed
       */

      onDataSetCopyToAnotherModelProcessCompleted();
    }
  }, [dataSetCopyProcessProgressDetails, previousValueOfDataSetCopyProcessProgressDetails, onDataSetCopyToAnotherModelProcessCompleted])
  

  return (
    <>
    {
      dataSetCopyProcessProgressDetails &&
      <section>
        <Alert severity="info"
          style={{fontSize: "13px "}}
        >
          <strong>{dataSetCopyProcessProgressDetails.dataSetCollectionToCopyFrom.name}</strong> from <strong>{dataSetCopyProcessProgressDetails.dataSetCollectionToCopyFrom.model.name}</strong> task is being copied in this collection <br/>
          <strong>Status:</strong>&nbsp; 
          <span
            className={`
              ${dataSetCopyProcessProgressDetails.failureMessage ? classes.statusFailure : ""}
            `}
          >{dataSetCopyProcessProgressDetails.failureMessage ? "Failed" : "In-progress"}</span> 
          <br/>
          {
          dataSetCopyProcessProgressDetails?.failureMessage &&
          <><div><strong>Error:</strong> {dataSetCopyProcessProgressDetails?.failureMessage}</div></>
          }   
          <strong>Completed:</strong> {Math.round(copyProcessPercentCompleted)}%
        </Alert>        
      </section>
    }
    </>
  );
}
import { makeStyles, Tooltip, Typography } from "@material-ui/core";
import { IStatus } from "../../../../../../../../../../../common";

const useStyles = makeStyles(() => ({
    basicStyle: {
        border: '1px solid gray',
        padding: '6px 15px',
        borderRadius: '5px',
        boxShadow: '8px 8px 24px #00000014',
        display: 'flex',
        width: '100px',
        alignItems: 'center',
        textOverflow: 'ellipsis',
        whiteSpace: 'nowrap',
        overflow: 'hidden',
        font: 'normal normal 600 12px/ 20px Nunito Sans',
        textTransform: 'uppercase'
    },
    trainedStatusClass: {
        color: '#02BC77'
    },
    testedStatusClass: {
        color: '#02BC77'
    },
    reTrainedStatusClass: {
        color: '#02BC77'
    },
    preparingRequestStatusClass: {
        color: '#bc7f02'
    },
    failedStatusClass: {
        color: '#FF2366'
    },
	trainingStatusClass: {
		color: "#838C95"
	},   
    queuedStatusClass: {
        color: '#FFF',
        "& span": {
            // background: 'transparent linear-gradient(90deg, #F00049 0%, #808080 0%, #534348 100%) 0% 0% no-repeat padding-box',
            backgroundColor: "#716c6d",
            border: 'none'
        }
    },
    liveStatusClass: {
        color: '#FFF',
        "& span": {
            background: '#F00049',
            border: 'none'
        }
    },
    dot: {
        height: '9px',
        width: '9px',
        background: '#FFF !important',
        borderRadius: '50%',
        display: 'inline-block',
        boxShadow: '0px 0px 8px 4px white',
        margin: 'auto 15px auto 0'
    },
    basicStyleWhenStatusPreparingRequest: {
        width: "96px"
    }
}));

const ModelStatus = (props: Props) => {
    const classes = useStyles();

    const getClassNameBasedOnStatus = () => {
        switch (props.status) {
            case 'Trained':
                return classes.trainedStatusClass
            case 'Tested':
                return classes.testedStatusClass
            case 'Re-Trained':
                return classes.reTrainedStatusClass
            case 'Training':
                return classes.trainingStatusClass
            case 'Failed':
                return classes.failedStatusClass
            case 'Queued':
                return classes.queuedStatusClass
            case 'Preparing Request':
                return classes.preparingRequestStatusClass
            case 'Live':
                return classes.liveStatusClass
        }
    }

    return (
        <Typography
            component="p"
            variant="subtitle2"
            noWrap
            display="inline"
            classes={{ root: getClassNameBasedOnStatus()}}

            {...(props.rootElementProps ? props.rootElementProps : {})}
        >
            <Tooltip title={props.status}>
                <span className={`${classes.basicStyle} ${props?.className} ${props.status === "Preparing Request" ? classes.basicStyleWhenStatusPreparingRequest : ""}`} 
                style={{ justifyContent: 'center'}}
                >
                    {
                        props.status === 'Live' && <span className={`${classes.dot} ${props?.dotClassName ? props?.dotClassName : ""}`}></span>
                    }
                    {
                        props.status === "Preparing Request" ? "PREPARING"
                            : (props.status === "Training" && (props.trainingTestingCompletionPercentage || props.trainingTestingCompletionPercentage === 0)) ? `${props.trainingTestingCompletionPercentage}% TRAINED`
                                : props.status
                    }
                </span>
            </Tooltip>
        </Typography>
    )
}

interface Props  {
    className?: string;
    dotClassName?: string;
    status: IStatus;
    rootElementProps?: any;
    trainingTestingCompletionPercentage?: Number | undefined;
}
export default ModelStatus;
import styles from "./itemsCounter.module.scss";
import RemoveRoundedIcon from '@material-ui/icons/RemoveRounded';
import AddRoundedIcon from '@material-ui/icons/AddRounded';
import AssignmentTurnedInRoundedIcon from '@material-ui/icons/AssignmentTurnedInRounded';
import ExitToAppRoundedIcon from '@material-ui/icons/ExitToAppRounded';
import LocalMallRoundedIcon from '@material-ui/icons/LocalMall';
import { IconButton } from "@material-ui/core";
import { useRef, useState } from "react";
import clsx from "clsx";
import { CounterAction, IData } from "../../../../../../../../../../../../common";
import { useDispatch } from "react-redux";
import { updateItemsAddCount, updateItemsDiscardedCount, updateItemsSoldCount } from "../../../../../../../../../../../../store/actions";
import { useEffect } from "react";
import { VideoResource } from "../ViewForcastModelPopup";

export const ItemsCounter = (props: {resource: IData, videoPlayOrPauseHandler: Function, videoAlreadyPlaying: boolean, videoResource: VideoResource}) => {
    const [currentMessage, setCurrentMessage] = useState("");
    const dispatch = useDispatch();
    const [currentActiveBtn, setCurrentActiveBtn] = useState<string>(CounterAction.DISCARDED);
    const timeOutRef = useRef<any>()
    const onAdd = () => {
        const video = document.getElementById("popupVideo") as HTMLVideoElement;
        clearTimeout(timeOutRef.current)
        setCurrentMessage(CounterAction.ADDED)
        const addedCount = props.videoResource.addCount;
        dispatch(updateItemsAddCount({resourceId: props.resource._id, count: (addedCount  && addedCount > 0) ? addedCount+1 : 1, videoId: props.videoResource._id, videoDurationInSeconds: video.currentTime}))
        if (props.videoAlreadyPlaying) {
            props.videoPlayOrPauseHandler(false)
        }
        timeOutRef.current = setTimeout(() => {
            setCurrentMessage("");
            if (props.videoAlreadyPlaying) {
                props.videoPlayOrPauseHandler(true)
            }
        }, 1000)
    }
    const counterElementRef = useRef<any>();
    const onRemove = () => {
        if (props.videoAlreadyPlaying) {
            props.videoPlayOrPauseHandler(false)
        } else {
            props.videoPlayOrPauseHandler(false)
        }
        clearTimeout(timeOutRef.current)
        setCurrentMessage(CounterAction.REMOVED)
    }
    
    useEffect(() => {
        const clickListener = (event: any) => {
            if(counterElementRef.current?.contains(event.target)){
               return
            }
            setCurrentMessage("")
        }
        document.body.addEventListener('click', clickListener)
        return ()=> document.body.removeEventListener('click', clickListener)
    }, [])

    const toggleBtnClick = (toggleBtn: string) => {
        const video = document.getElementById("popupVideo") as HTMLVideoElement;
        setCurrentActiveBtn(toggleBtn)
        if (toggleBtn === CounterAction.DISCARDED) {
            const discardedCount = props.videoResource.discardCount;
            dispatch(updateItemsDiscardedCount({ resourceId: props.resource._id, count: (discardedCount && discardedCount > 0) ? discardedCount + 1 : 1, videoId: props.videoResource._id, videoDurationInSeconds: video.currentTime }))
        }
        else if (toggleBtn === CounterAction.SOLD) {
            const soldCount = props.videoResource.soldCount;
            dispatch(updateItemsSoldCount({ resourceId: props.resource._id, count: (soldCount && soldCount > 0) ? soldCount + 1 : 1, videoId: props.videoResource._id,  videoDurationInSeconds: video.currentTime }))
        }
        setCurrentMessage(toggleBtn)
        if (props.videoAlreadyPlaying) {
            props.videoPlayOrPauseHandler(true)
        }
        timeOutRef.current = setTimeout(() => {
            setCurrentMessage("")
        }, 1000)
        return
    }

    return (
        <div ref={counterElementRef} style={{width: "280px",display:'flex',alignItems:'end', justifyContent: 'center'}}>
            <div style={{height: "25px", margin:"0 35px", position: "relative", bottom: "50px"}}>
                <div className={clsx(styles.actionMessageContainer, {[styles.show]:currentMessage === CounterAction.REMOVED })} style={{left: "-50px",bottom: "-5px" ,padding:"4px 8px", width:"212px", justifyContent:"space-between"}}>
                    {/* <div className={clsx(styles.toggler, {[styles.sold]:currentActiveBtn === CounterAction.SOLD })}></div> */}
                    <div onClick={() => toggleBtnClick(CounterAction.DISCARDED)} className={clsx(styles.toggleBtn)}>
                        <ExitToAppRoundedIcon className={clsx(styles.toggleBtnIcon)} style={{ fontSize: 18}}/>
                        <span className={styles.message}>Discarded</span>
                    </div>
                    <div onClick={() => toggleBtnClick(CounterAction.SOLD)} className={clsx(styles.toggleBtn)} style={{paddingRight: "15px"}} >
                        <LocalMallRoundedIcon className={clsx(styles.toggleBtnIcon)} style={{ fontSize: 18}}/>
                        <span className={styles.message}>Sold</span>
                    </div>
                </div>
                <div className={clsx(styles.actionMessageContainer, {[styles.show]:currentMessage === CounterAction.DISCARDED })} style={{left: "-50px",bottom: "-5px" ,width: "200px"}}>
                    <ExitToAppRoundedIcon className={styles.icon}/>
                    <span className={styles.message}>1 Item Discarded</span>
                </div>
                <div className={clsx(styles.actionMessageContainer, {[styles.show]:currentMessage === CounterAction.SOLD })} style={{left: "-50px",bottom: "-5px" ,width: "200px"}}>
                    <LocalMallRoundedIcon className={styles.icon}/>
                    <span className={styles.message}>1 Item Sold</span>
                </div>
                <div className={clsx(styles.actionMessageContainer, {[styles.show]:currentMessage === CounterAction.ADDED })} style={{left: "100px",bottom: "-5px" , width: "160px"}}>
                    <AssignmentTurnedInRoundedIcon className={styles.icon}/>
                    <span className={styles.message}>1 Item Added</span>
                </div>
            </div>
            <div className={styles.counterBtns}>
                <IconButton size="small" style={{background:'#008D9C'}} onClick={onRemove}><RemoveRoundedIcon style={{ fontSize: 28, color: "#ffff", }}/></IconButton>
                <span style={{color:'#008D9C'}}>Counter</span>
                <IconButton size="small" style={{background:'#008D9C'}} onClick={onAdd}><AddRoundedIcon style={{ fontSize: 28, color: "#ffff", }}/></IconButton>
            </div>
        </div>
    )
}

import { createContext } from "react";
import { ISessionCreationProgress, ITrainingPodSessionConfiguration, IVmHardwareSessionStatus, TrainingPodTestSessionStatus } from "../../../../../../../common";
import { IPurposeModel } from "../../../../../../../common/constants/interfaces/purposeModels";

//@ts-ignore
const TrainingPodTestingSessionContext = createContext<{
    trainingPodTestSession: ITrainingPodSessionConfiguration | null; // Active VM Data
    setTrainingPodTestSession: React.Dispatch<React.SetStateAction<ITrainingPodSessionConfiguration | null>>;
    handleStartTestSession: (podData: ITrainingPodSessionConfiguration, // handle start session
        purposeModelId: string,
        testSessionHardwareGcpType?: "Custom GCP" | "RLEF GCP",
        serviceFile?: File|null) => void;
    handleEndTestSession: (purposeModelId: string) => void; // handle stop session
    disableStopSession: boolean, // disable the stop session button if testing is active
    setDisableStopSession: React.Dispatch<React.SetStateAction<boolean>>,
    getPurposeModel: (purposeModelId: string) => void,
    currentSessionStatus: ISessionCreationProgress[] // Session creation Progress data
    setCurrentSessionStatus: React.Dispatch<React.SetStateAction<ISessionCreationProgress[]>>,
    vmHardwareSessionStatus: IVmHardwareSessionStatus | null, // Active VM Session State data for Display
    setVmHardwareSessionStatus: React.Dispatch<React.SetStateAction<IVmHardwareSessionStatus | null>>,
    isProcessingRequest: boolean, // this is variable is true when the user has clicked on "start"/"stop" button, but DB is not yet updated with loading status
    setIsProcessingRequest :React.Dispatch<React.SetStateAction<boolean>>,
    setTrainPodSessionDataUsingPurposeModelData: (purposeModel: IPurposeModel) => void // set trainingPodTestSession, vmHardwareSessionStatus and disableStopSession based on purposeModel Data
}>();

export default TrainingPodTestingSessionContext;
import "./textTrimUI.css"
import { useCallback, useContext, useEffect, useRef, useState } from "react";
import {
 IData,
 IModel,
 IModelType,
 IReduxState,
 ModelAnnotationLabelAvailable,
 ResourceStatus,
} from "../../../../../../../../../../../../common";
import { Button, Chip, CircularProgress, Dialog, DialogActions, makeStyles, TextField, Typography} from "@material-ui/core";
import TextHighlighter from "./TextHighlighter"
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableContainer from "@material-ui/core/TableContainer";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import Paper from "@material-ui/core/Paper";
import { Checkbox, Tooltip } from "@material-ui/core";
import DeleteOutlineIcon from "@material-ui/icons/DeleteOutline";
import { DataService, ModelService } from "../../../../../../../../../../../../services";
import { LabelField } from "../AudioTrimUI/labelField";
import { Autocomplete } from "@material-ui/lab";
import { PromiseConcurrency } from "../../../../../../../../../../../../classes/PromiseConcurrency";
import { TextResourceHeading } from "../ViewAndTrimAudioResource";
import { TrimmedText } from '../../../../../../../../../../../../common/constants/interfaces/data';
import { UniqueColorPicker } from "./UniqueColorPicker";
import { ICoPilotResource } from '../../../../../../../../../../../../common/constants/interfaces/coPilotResource';
import copilotService from "../../../../../../../../../../../../services/copilotService";
import { AxiosResponse } from 'axios';
import { generateUniqueId } from '../../../../../../../../../../../../services/idHelperService';
import { TrimLabelWontGetSavedWarningAlert } from "../AudioTrimUI/AudioTrimUI";
import IsPiiDataCheckBox from "../AudioTrimUI/IsPiiDataCheckBox";
import { ModelResourcePredictedText } from "../../constants/constants";
import { connect, ConnectedProps, useSelector } from "react-redux";
import { deleteCopilotResourceTrimmedTextInUI, deleteResourceTrimmedTextInUI, setResources, updateCopilotResourceLabelInUI, updateData } from "../../../../../../../../../../../../store/actions";
import { isNullOrUndefined } from "../../../../../../../../../../../../services/variableHelperService";
import { Cancel, Edit, Save } from "@material-ui/icons";
import { TextareaAutosize } from "@mui/material";
import { AlertContext } from "../../../../../../../../../../../../common/contexts/alertContext/alertContext";

type updateResourceTrimmedTextsInUIFunctionType = {
  _id: string,
  trimmedTexts: TrimmedText[]
}

interface Props extends TPropsFromRedux {
 resource: IData | ICoPilotResource;
 updateResourceTrimmedTextsInUI: (object: updateResourceTrimmedTextsInUIFunctionType) => any;
 annotationLabelsAvailable?: ModelAnnotationLabelAvailable[];
 isInCopilot: boolean;
 isViewMode?: boolean;
 scenario?: "copilotResourceCard" | "copilotSideDrawer" | "copilotActivePageAnnotationTableHidden" | "copilotActivePageAnnotationsTableShowing" | "copilotNotificationSideDrawer" | "modelAnalyticsResourcesViewPopup"
 resourceTextStyles?: any;
 modelType ?: IModelType;
};

const getTrimmedText = (text: string, startPosition: number, endPosition: number): string => {
  return text.substring(startPosition, endPosition+1);
}

const LabelToSetInDLPModelTrimmedTextIfAnyOneTrimTextIsPiiData = "PII_TRUE";
const LabelToSetInDLPModelTrimmedTextIfNoTrimTextIsPiiData = "PII_FALSE";

function parseConfidenceScoreTo2DecimalPlacesMax(confidenceScore: number | undefined): string {
  try {
    if (!isNullOrUndefined(confidenceScore)) {
      if (String(confidenceScore).indexOf('.') !== -1) {
        // @ts-ignore
        return confidenceScore.toFixed(2);
      }
    }
  } catch (error) {
    
  }
  return `${confidenceScore}`
}

const useStyles = makeStyles((theme) => ({
    container:{
      height: "100%"
    },
    containerWhenScenarioIsModelAnalyticsResourcesViewPopup: {
      width: "100%"
    },
    containerWhenScenarioIsCopilotActivePageAnnotationsTableShowing: {
      display: "flex",
      justifyContent: "space-between",
      width: "100%"
    },
    containerWhenScenarioIsCopilotActivePageAnnotationsTableHidden: {
      width: "100%"
    },
    textTrimContainer:{
      padding: "10px",
      border: "1px solid rgb(118, 118, 118)",
      borderRadius: "8px",
      // maxHeight: "220px",
      // maxHeight: "calc(100% - 408px)",
      // maxHeight: "168px",
      overflow: "auto",
      marginBottom: "13px"

    },
    textTrimContainerWhenScenarioCopilotActivePageAnnotationTableHidden: {
      width: "100%",
      border: "none",
      paddingTop: "30px !important",
      paddingBottom: "30px !important",
      paddingLeft: "15px !important",
      paddingRight: "15px !important"
    },
    textTrimContainerWhenScenariocopilotActivePageAnnotationsTableShowing: {
      // width: "50.6%"
      width: "469px",
      border: "none",
      paddingTop: "30px !important",
      paddingBottom: "30px !important",
      paddingLeft: "15px !important",
      paddingRight: "15px !important"      
    },
    trimmedTextsTableHeadCell: {
      fontWeight: 600,
      // backgroundColor: "#f5f5f5"
    },
    trimmedTextsTableCellCheckbox: {
      width: "10px",
      paddingRight: "0px"
    },
    trimmedTextsTableCellAnnotationColor: {
      width: "44px",
      paddingRight: "0px"
    },
    trimmedTextsTableCellTrimmedText: {
      width: "254px",
      paddingRight: "0px",
      minWidth: "180px",
    },
    trimmedTextsTableCellLabel: {
      width: "180px",
      paddingRight: "0px"
    },
    trimmedTextsTableCellStartTimeColor: {
      width: "72px"
    },
    trimmedTextsTableCellEndTimeColor: {
      width: "67px"
    },
    trimmedTextsTableCellActions: {
      width: "69px",
      paddingRight: "0 !important"
    },
    trimTextTableRowDisabled: {
      opacity: 0.4,
      pointerEvents: "none"
    },
    sandbox: {
      "& span": {
        cursor: "pointer"
      }
    },
    doNotShowSandBoxAnnotationsAsClickable: {
      "& span": {
        cursor: "auto"
      }      
    },
    disablePointerEvents: {
      pointerEvents: "none"
    },
    trimTableContainer: {
      maxHeight: "calc(100% - 117px)"
    },
    trimTableContainerWithoutWarning: {
      maxHeight: "calc(100% - 55px)"
    },
    trimmedTextsSection: {
      height: "calc(100% - 240px)"
    },
    trimmedTextsSectionWhenScenarioIsModelAnalyticsResourcesViewPopup: {
      // height: "calc(100% - 90px)"
      height: "calc(100% - 50px)"
    },
    trimmedTextsSectionWhenScenariocopilotActivePageAnnotationsTableShowing: {
      // width: "49%",
      width: "59%",
      height: "calc(100% - 127px)"
    },    
    trimmedTextsSectionWhenScenariocopilotActivePageAnnotationsTableShowingAndUnSaveWarningNotShowing: {
      height: "calc(100% - 64px)"
    },    
    trimTableContainerWhenScenariocopilotActivePageAnnotationsTableShowing: {
      maxHeight: "100%"
    },
    piiDataWidth : {
      maxWidth: '96px',
      padding : '6px 0px',
      width: '65px'
    },
    confidenceScoreWidth: {
      maxWidth: '125px',
      width: '65px'
    },
    tagWidth: {
      width: '180px',
      paddingRight: "5px",
      paddingLeft: "0px",
      minWidth: "80px"
    },
    chip: {
      backgroundColor: theme.palette.grey[50],
    },
    tagEditField : {
      borderRadius : "25px",
      backgroundColor: theme.palette.grey[50],
      outline : "none",
      "& .MuiOutlinedInput-root .MuiOutlinedInput-notchedOutline" : {
        borderRadius : "25px",
        borderColor : "white",
      },
      "&:hover .MuiOutlinedInput-root .MuiOutlinedInput-notchedOutline" : {
        borderRadius : "25px",
        borderColor : "#008D9C"
      },
      "& .MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline" : {
        borderRadius : "25px",
        borderColor : "#008D9C"
      },
      "& .MuiOutlinedInput-inputMarginDense" : {
        paddingTop: "7px",
        paddingBottom: "7px" ,
        textAlign: "center",
        textOverflow: "ellipsis",
        overflow: "hidden",
        whiteSpace: "nowrap"
      }
    }
}));

type UITrimmedText = TrimmedText & {
  color: string
}

const textTrimColors = [
  "rgb(0, 255, 0)",
  "rgb(130, 238, 221)", 
  "rgb(255, 153, 153)", // red
  "rgb(255, 255, 179)", // yellow
  "rgb(204, 255, 204)", // green
  "rgb(245, 204, 255)",  // light purple tone 2
  "rgb(204, 255, 255)",  // sky blue
  "rgb(255, 209, 179)",  // orange
  "rgb(209, 224, 224)",  // cement color
]

// function debounce<FunctionType> (fun: FunctionType , delay: number = 1000): FunctionType {
//   let timeout: ReturnType<typeof setTimeout>;

//   // @ts-ignore
//   return (...args: any) => {
//     clearTimeout(timeout);
//     timeout = setTimeout(() => {
//       // @ts-ignore
//       fun(...args);
//     }, delay);
//   }
// }

function TextTrimUI(props: Props) {
  const classes = useStyles();
  // const timerRef = useRef<any>(null);
  const [trimTextIdsSelectedInTable, setTrimTextIdsSelectedInTable] = useState<string[]>([]);
  const [trimTextIdsBeingClonedInDb, setTrimTextIdsBeingClonedInDb] = useState<string[]>([]);
  const [trimmedTextIdsBeingUpdatedInDb, setTrimmedTextIdsBeingUpdatedInDb] = useState<string[]>([]);
  const [trimmedTextIdsWhoseTagBeingUpdatedInDb, settrimmedTextIdsWhoseTagBeingUpdatedInDb] = useState<string[]>([]);
  const [trimmedTexts, setTrimmedTexts] = useState<UITrimmedText[]>([]);
  const [trimmedTextIdsBeingDeletedFromDb, setTrimmedTextIdsBeingDeletedFromDb] = useState<string[]>([]);
  const updateResourceInUI = props.updateResourceTrimmedTextsInUI;
  const projectId = new URLSearchParams(window.location.search).get("project") || "";
  const resource = props.resource;
  const [modelIdSelectedToCloneInto, setModelIdSelectedToCloneInto] = useState("");
  const [projectTextAnnotationModels, setProjectTextAnnotationModels] = useState<IModel[]>([]);
  const [isDeleteAllTrimTextsProcessHappening, setIsDeleteAllTrimTextsProcessHappening] = useState(false);
  const trimTextColorPickerRef= useRef<UniqueColorPicker>(new UniqueColorPicker(textTrimColors));
  const [hasResourceIdChanged, setHasResourceIdChanged] = useState(false);
  const [isEditingText, setIsEditingText] = useState<boolean>(false);
  const [isSavingEditedText, setIsSavingEditedText] = useState<boolean>(false);
  const [tempEditedText, setTempEditedText] = useState<string>(resource.resource);

  const alertContext = useContext(AlertContext);
  const setCustomAlert = alertContext.setAlert;

  const modelType = props.modelType || "";

  const sandBoxIdRef = useRef<string>(generateUniqueId());

  const hltr = useRef<any>()
  
  useEffect(()=>{
    if(trimmedTexts.length > 0){
      for(let trimmedText of trimmedTexts){
        if(trimmedText.label){
          const highlightedElements = document.querySelectorAll(`[id='${props.isViewMode ? "" : 'edit'}${trimmedText._id}']`);
          if(highlightedElements.length > 0){
            highlightedElements.forEach((highlightElement)=>{
              (highlightElement as HTMLElement).title = trimmedText.label;
           })
          }
        }
      }
    }
  },[trimmedTexts, props.isViewMode])



  useEffect(() => {
    if (resource._id) {
     setHasResourceIdChanged(true);
    }
   }, [resource._id]);  

  useEffect(()=>{
    // if(hasResourceIdChanged){
    if(true){
      setHasResourceIdChanged(false);
      setTrimmedTexts([]);
      if (hltr.current) {
        hltr.current.removeHighlights();
        hltr.current.destroy();
      }

      // var sandbox = document.getElementById('sandbox')!;
      var sandbox = document.getElementById(sandBoxIdRef.current);

      hltr.current = new TextHighlighter(sandbox as HTMLElement, 
        // @ts-ignore
        {
        isViewMode: props.isViewMode || false
        }
      );

      if (hltr.current) {
        hltr.current.getColorToPutInAnnotation(function () {
          const color = trimTextColorPickerRef.current.getLeastUsedColor();
          return color;
        })
        hltr.current.setPosition(function (selectionPosition: any) {
          const trimmedText: UITrimmedText = {
            label: "",
            createdAt: "",
            startPosition: selectionPosition.startIndex,
            endPosition: selectionPosition.endIndex,
            ...selectionPosition
        }
          setTrimmedTexts(oldState => {
            return [...oldState, trimmedText]
          })
        });
      }      
      
      trimTextColorPickerRef.current = new UniqueColorPicker(textTrimColors);      

      // const clonedTrimmedTexts = props.resource.trimmedTexts.map(text => ({...text, color: "#000000".replace(/0/g,function(){return (~~(Math.random()*16)).toString(16);})}))

      if (resource?.trimmedTexts && resource?.trimmedTexts?.length>0) {
        const clonedTrimmedTexts = resource.trimmedTexts.map(text=>{
          let textTrimColor = trimTextColorPickerRef.current.getLeastUsedColor();
          return {
            ...text, 
            // color: "#000000".replace(/0/g,function(){return (~~(Math.random()*16)).toString(16);})
            color: textTrimColor
          }
        })
        clonedTrimmedTexts.forEach((element)=> {
          hltr.current.doHighlight(undefined, element)
        })
        setTrimmedTexts([...clonedTrimmedTexts])
      }
    }
    return () => {
      if (hltr.current) {
        hltr.current.removeHighlights();
        hltr.current.destroy();
      }
    }
  // },[hasResourceIdChanged, resource.trimmedTexts, props.isViewMode])
  },[props.isViewMode])
  
  useEffect(() => {
    const executeFunction = async () => {
      const getModelsApiResponse = await ModelService.getProjectModels({
        project: projectId,
        type: modelType
      })
      setProjectTextAnnotationModels(getModelsApiResponse.data.models)
    } 
   if (projectId && modelType.length > 0) {
     executeFunction();
   }
  
   }, [projectId, modelType])

  const highlightAnnotationTextInResourceText = (annotationId: string) => {
     /**
     * "edit" keyword is prefixed to id to have unique id while editing, not to collide with view only elements
     */
    const annotationElements = document.querySelectorAll(`[id='edit${annotationId}']`);
    if(annotationElements.length > 0){
      annotationElements[0]?.scrollIntoView({
        behavior: "auto",
        block: "center",
        inline: "center"
      })
      annotationElements.forEach((annotationElement, index)=>{
        annotationElement?.classList.add("highlightTrimTextInResourceText");
        (annotationElement as HTMLElement).style.borderTop = "3px solid";
        (annotationElement as HTMLElement).style.borderBottom = "3px solid";
        if(index === 0){
          (annotationElement as HTMLElement).style.borderLeft = "3px solid";
        }
        if(index === annotationElements.length - 1){
          (annotationElement as HTMLElement).style.borderRight = "3px solid";
        }
        setTimeout(()=>{
          annotationElement?.classList.remove("highlightTrimTextInResourceText");
          (annotationElement as HTMLElement).style.border = "none";
        }, 1500)
     })
    }
      
      
  }

  const scrollToTableRowAndHighlight = (target: any) => {
    if (!window.getSelection()?.toString()) {
      let annotationId = target.id;
      if(annotationId.includes('edit')){
        annotationId = annotationId.slice(4)
      }
      const annotationElement = document.getElementById("annotation_id_" + annotationId);
      if (annotationElement) {
        annotationElement?.scrollIntoView({
          behavior: "auto",
          block: "center",
          inline: "center"
        });
        
        annotationElement?.classList.add("highlightTrimTextInResourceText");
        setTimeout(() => {
          annotationElement?.classList.remove("highlightTrimTextInResourceText");
        }, 1500);
      }
    }
  }

  const onColorHover = (elementId: string)=>{
    // const highlightedElements = document.querySelectorAll(`[id='${elementId}']`);
    // debugger
    // if(highlightedElements.length > 0){
    //   highlightedElements.forEach((element, index)=>{
    //     (element as HTMLElement).style.borderTop = "3px solid";
    //     (element as HTMLElement).style.borderBottom = "3px solid";
    //     if(index === 0){
    //       (element as HTMLElement).style.borderLeft = "3px solid";
    //     }
    //     if(index === highlightedElements.length - 1){
    //       (element as HTMLElement).style.borderRight = "3px solid";
    //     }
    //   })
    // }
  }

  const onColorHoverOut = (elementId: string)=>{
    // const highlightedElements = document.querySelectorAll(`[id='${elementId}']`);
    // if(highlightedElements.length > 0){
    //   highlightedElements.forEach((element)=>{
    //     (element as HTMLElement).style.border = "none"
    //   })
    // }
  }

  const resources = useSelector(
    (state: IReduxState) => state.data.data.resources
  );
  // const { resource, labelsAvailable } = props;

  const [value, setValue] = useState<string | undefined>(resource.label);

  const [scenario, setScenario] = useState<
    "updatingLabel" | "updatingLabelAndPrediction"
  >("updatingLabel");
  useEffect(() => {
    if (resource.prediction === "predicted") {
      setScenario("updatingLabelAndPrediction");
    } else {
      setScenario("updatingLabel");
    }
  }, [resource.label, resource.prediction]);

  useEffect(() => {
    if (scenario === "updatingLabelAndPrediction") {
      setValue(resource.label);
    } else if (scenario === "updatingLabel") {
      setValue(`${resource.prediction}->${resource.label}`);
    }
  }, [resource.label, resource.prediction, scenario]);

  const handleLabelSave = useCallback((labelValueToSave: typeof LabelToSetInDLPModelTrimmedTextIfAnyOneTrimTextIsPiiData | typeof LabelToSetInDLPModelTrimmedTextIfNoTrimTextIsPiiData): Promise<void> =>  {
    return new Promise<void>(async (resolve, reject) => {
    let thingsToUpdate: { label: string; prediction?: string } = {
      label: labelValueToSave,
      prediction:
        scenario === "updatingLabelAndPrediction" ? resource.label : "",
    };

    if (labelValueToSave === props.resource.prediction) {
      /**
       * if user updated label is equal to what was already predicted.
       * then setting prediction as predicted
       */
      thingsToUpdate.prediction = ModelResourcePredictedText;
    }

    if (!thingsToUpdate.prediction) {
      delete thingsToUpdate.prediction;
    }

    const doesNeedUpdationInResource: boolean = (() => {
      if (thingsToUpdate) {
        if (thingsToUpdate.label) {
          if (thingsToUpdate.label !== resource.label) {
            return true
          }
        }
        if (thingsToUpdate.prediction) {
          if (props.isInCopilot) {
            return false
          }
          if (thingsToUpdate.prediction !== resource.prediction) {
            return true
          }
        }
      }
      return false;
    })()

    if (!doesNeedUpdationInResource) {
      return resolve();
    }

    if(props.isInCopilot) {
      // props.resource.label = labelValueToSave;
      // props.setCoPilotResourceData({coPilotResource: resource as unknown as ICoPilotResource, shouldNotFormNewRootState: true})
      props.updateCopilotResourceLabelInUI({
        _id: resource._id,
        label: labelValueToSave
      })
      await copilotService.updateResource({
        id: [resource._id],
        label : labelValueToSave
        })
    }
    else{
    props
      .updateData(
        { id: [props.resource._id], ...thingsToUpdate },
        {},
        { shouldFetchLatestData: false }
      )
      .then(() => {
        for (
          let resourceIndex = 0;
          resourceIndex < resources.length;
          resourceIndex++
        ) {
          const resourceObject = resources[resourceIndex];
          if (resourceObject._id === props.resource._id) {
            if (scenario === "updatingLabelAndPrediction") {
              resourceObject.prediction = resource.label || "";
            }
            resourceObject.label = labelValueToSave;

            if (thingsToUpdate.prediction) {
              resourceObject.prediction = thingsToUpdate.prediction;
            }

            resources[resourceIndex] = resourceObject;
            break;
          }
        }
        props.setResources(resources);
      });
    }
  });
  },
  [props.isInCopilot, props.resource, resources, scenario, resource]
  );

  const handleTrimTextUpdateInDB = useCallback(
    async (
     scenario: "createInDB" | "updateInDB",
     trimTextId: string,
     labelToUpdate?: string,
     isPiiData ?: boolean,
     updatedTag?: string
    ) => {
      let propsResourceTrimmedTexts = [...(resource?.trimmedTexts || [])];
     const trimmedTextToCreateAndSaveInDB = trimmedTexts.find(
      (trimText) => trimText._id === trimTextId
     );
  
     setTrimmedTextIdsBeingUpdatedInDb((oldState) => [...oldState, trimTextId]);

     if (updatedTag !== null && updatedTag !== undefined) {
      settrimmedTextIdsWhoseTagBeingUpdatedInDb((oldState) => [...oldState, trimTextId]);
     }
  
     let newlyCreatedTrimmedTextInDB: TrimmedText | undefined = undefined;
    //  let updateApiResponseForPiiData : "Pii_True" | "Pii_False" | undefined = undefined;
    //  let updateApiResponse: AxiosResponse<UpdatedLabel>;
     if (scenario === "createInDB") {
       let saveApiResponse: AxiosResponse<TrimmedText>;
       if (props.isInCopilot) {
         saveApiResponse = await copilotService.createAndSaveTrimmedTextInDB({
          resourceId: resource._id,
          trimmedTextId: trimmedTextToCreateAndSaveInDB?._id.toString() || "",
          startPosition:
           trimmedTextToCreateAndSaveInDB?.startPosition || 0,
          endPosition: trimmedTextToCreateAndSaveInDB?.endPosition || 0,
          label: labelToUpdate || "",
          isPiiData : isPiiData || false,
         });
       } else {
         saveApiResponse = await DataService.createAndSaveTrimmedTextInDB({
          resourceId: resource._id,
          trimmedTextId: trimmedTextToCreateAndSaveInDB?._id.toString() || "",
          startPosition:
           trimmedTextToCreateAndSaveInDB?.startPosition || 0,
          endPosition: trimmedTextToCreateAndSaveInDB?.endPosition || 0,
          label: labelToUpdate || "",
          isPiiData: isPiiData || false
         });

       }
      newlyCreatedTrimmedTextInDB = saveApiResponse.data;
     } else if (scenario === "updateInDB") {
       if (props.isInCopilot) {
         await copilotService.updateTrimmedTextInDB({
          trimmedTextId: trimTextId,
          label: labelToUpdate,
          isPiiData : isPiiData,
          tag: updatedTag
         });
       } else {
        await DataService.updateTrimmedTextInDB({
          trimmedTextId: trimTextId,
          label: labelToUpdate,
          isPiiData : isPiiData,
          tag: updatedTag
         });
       }
      //  updateApiResponseForPiiData = updateApiResponse.data.updatedLabel;
      //  console.log( " update api response for pii data: " + updateApiResponseForPiiData);
      //  handleLabelSave(updateApiResponseForPiiData);
     }
  
     /**
      * removing trimmed audio to save from array
      */
      setTrimmedTextIdsBeingUpdatedInDb((oldState) => {
        const newState = [...oldState];
    
        for (let index = 0; index < newState.length; index++) {
         const trimmedTextId = newState[index];
         if (trimTextId === trimmedTextId) {
          newState.splice(index, 1);
          break;
         }
        }
    
        return newState;
       });

      if (updatedTag !== null && updatedTag !== undefined) {
        settrimmedTextIdsWhoseTagBeingUpdatedInDb((oldState) => {
          const newState = [...oldState];
          let index = newState.indexOf(trimTextId);
          newState.splice(index, 1);
          return newState;
        });
      }

  
     setTrimmedTexts((oldState) => {
      const trimmedTextsToUpdate = [...oldState];
  
      for (let index = 0; index < trimmedTextsToUpdate.length; index++) {
       let trimmedTextToUpdate = trimmedTextsToUpdate[index];
       if (trimmedTextToUpdate._id === trimTextId) {
        if (scenario === "createInDB") {
         if (newlyCreatedTrimmedTextInDB) {
          trimmedTextToUpdate = {...newlyCreatedTrimmedTextInDB, color: trimmedTextToCreateAndSaveInDB?.color || ""};
          trimmedTextsToUpdate[index] = trimmedTextToUpdate;
         }
        } else if (scenario === "updateInDB") {
         if (labelToUpdate) {
          trimmedTextsToUpdate[index].label = labelToUpdate;
         }
         if (updatedTag !== null && updatedTag !== undefined) {
          trimmedTextsToUpdate[index].tag = updatedTag;
         }
        }
  
        break;
       }
      }
  
      return trimmedTextsToUpdate;
     });
  
     if (scenario === "createInDB") {
      if (newlyCreatedTrimmedTextInDB) {
       propsResourceTrimmedTexts.push(newlyCreatedTrimmedTextInDB);
      }
     } else if (scenario === "updateInDB") {
      for (let index = 0; index < propsResourceTrimmedTexts.length; index++) {
       const resourceTrimmedText = propsResourceTrimmedTexts[index];
       if (resourceTrimmedText._id === trimTextId) {
        if (labelToUpdate) {
          propsResourceTrimmedTexts[index].label = labelToUpdate;
        }
        propsResourceTrimmedTexts[index].isPiiData = isPiiData;
        if (updatedTag !== null && updatedTag !== undefined) {
          propsResourceTrimmedTexts[index].tag = updatedTag;
        }
        break;
       }
      }
     }

     if(modelType === "DLP"){ 
      let updatePiiData : boolean | undefined = undefined;

      for (let index = 0; index < propsResourceTrimmedTexts.length; index++) {
        const resourceTrimmedText = propsResourceTrimmedTexts[index];
        if(resourceTrimmedText.isPiiData === true){
          updatePiiData = true;
          break;
        }else if (resourceTrimmedText.isPiiData === false){
          updatePiiData = false;
        }
      }
      if(updatePiiData !== undefined){
        if(updatePiiData === true){
          handleLabelSave(LabelToSetInDLPModelTrimmedTextIfAnyOneTrimTextIsPiiData);
        }else if(updatePiiData === false){
          handleLabelSave(LabelToSetInDLPModelTrimmedTextIfNoTrimTextIsPiiData);
        }
      }}
     
  
        updateResourceInUI({
         ...resource,
         ...{
          trimmedTexts: [...JSON.parse(JSON.stringify(propsResourceTrimmedTexts))],
         },
        });
  
     if (scenario === "createInDB") {

      setTrimTextIdsSelectedInTable(oldIdsSelected=>{
        const idAlreadyPresentInArray = oldIdsSelected.find(oldId=>oldId===trimTextId) ? true : false
    
        if (idAlreadyPresentInArray) {
    
          for (let index = 0; index < oldIdsSelected.length; index++) {
            const oldId = oldIdsSelected[index];
            if (oldId === trimTextId) {
              oldIdsSelected[index] = newlyCreatedTrimmedTextInDB?._id.toString() || "";
              break;
            }
          }
    
          return [...oldIdsSelected];
        } else {
          return oldIdsSelected;
        }
       })    
     }
    },
    [handleLabelSave, modelType, props.isInCopilot, resource, trimmedTexts, updateResourceInUI]
   );

  const labelChanged = useCallback(
    (label: string, textId: string) => {
      const trimAudio = trimmedTexts.find((audio)=>audio._id === textId);
      handleTrimTextUpdateInDB(
        !trimAudio?.label ? "createInDB" : "updateInDB",
        textId,
        label
       );
     },
     [handleTrimTextUpdateInDB, trimmedTexts],
   ); 
   
   const isPiiDataChanged = useCallback(
    (isPiiData: boolean, textId: string) : Promise<void> => {
      return new Promise<void>(async (resolve, reject) => {
      const trimText = trimmedTexts.find((text)=>text._id === textId);
      try {
        const apiResponse = await handleTrimTextUpdateInDB(
          "updateInDB",
          textId,
          trimText?.label,
          isPiiData
         );
      }
      catch(error){
        console.log(error);
      }})
     },
     [handleTrimTextUpdateInDB, trimmedTexts],
   ); 

  // const updateTag = (tagValue:string, trimextId : string) => {
  //   if(timerRef.current){
  //     return
  //   }
  //   timerRef.current = setTimeout(()=>{
  //     handleTrimTextUpdateInDB("updateInDB", trimextId, undefined, undefined, tagValue)
  //   }, 500)
  // }

  // const updateTag = useMemo(() => debounce(handleTrimTextUpdateInDB), [handleTrimTextUpdateInDB])

  // useEffect(() => {
  //   if (trimmedTexts?.length>0) {
  //     for (const trimmedText of trimmedTexts) {
  //       const trimmedTextId = trimmedText._id;
  //       const trimmedTextAssignedLabel = trimmedText.label;
  //       if (trimmedTextAssignedLabel) {
  //         const annotationHTMLElement = document.getElementById(trimmedTextId);
  //         if (annotationHTMLElement) {
  //           annotationHTMLElement.title = `Assigned Label: ${trimmedTextAssignedLabel}`
  //         }
  //       }
  //     }
  //   }
  
  // }, [trimmedTexts]);

  /**
   * To check if all the selected texts have labels
   * @returns boolean value
   */
   const checkSelectedTrimmedTextLabel = () => {
    return trimTextIdsSelectedInTable.every(id => {
      const trimmedTextValue = trimmedTexts.find(text => text._id === id);
      return trimmedTextValue?.label && trimmedTextValue.label.length ? true : false;
    })
  }   
   
  const [isAllTextAnnotationsHaveLabels, setIsAllTextAnnotationsHaveLabels] = useState<boolean>(true);
  useEffect(() => {
    const allLabelAvailable = trimmedTexts.every((text) => {
      return text?.label && text.label.length ? true : false;
    })

    if (allLabelAvailable){
      setIsAllTextAnnotationsHaveLabels(true)
    }
    else{
      setIsAllTextAnnotationsHaveLabels(false)
    }
  },[trimmedTexts])

  const handleTrimTextDeleteClick = useCallback(
    async (trimTextIdToDelete: string) => {
      const deleteCopilotResourceTrimmedTextInUI = props.deleteCopilotResourceTrimmedTextInUI;
      const deleteResourceTrimmedTextInUI = props.deleteResourceTrimmedTextInUI;
      const propsResourceTrimmedTexts = [...(resource.trimmedTexts || [])]
     const isTrimTextToDeleteSavedInDB = (trimTextIdToDelete: string) => {
      const trimTextToDelete = trimmedTexts.find(
       (trimmedText) => trimmedText._id === trimTextIdToDelete
      );
  
      if (!trimTextToDelete?.label) {
       return false;
      }
  
      return true;
     };
  
     if (isTrimTextToDeleteSavedInDB(trimTextIdToDelete)) {
      /**
       * adding trimmed audio to delete in array
       */
      setTrimmedTextIdsBeingDeletedFromDb((oldState) => [
       ...oldState,
       trimTextIdToDelete,
      ]);
  
      if (props.isInCopilot) {
        await copilotService.deleteTrimmedTextInDB({
         trimmedTextId: trimTextIdToDelete,
        });
      } else {
        await DataService.deleteTrimmedTextInDB({
         trimmedTextId: trimTextIdToDelete,
        });
      }
  
      /**
       * removing trimmed Text to delete from array
       */
      setTrimmedTextIdsBeingDeletedFromDb((oldState) => {
       const newState = [...oldState];
  
       for (let index = 0; index < newState.length; index++) {
        const trimTextId = newState[index];
        if (trimTextIdToDelete === trimTextId) {
         newState.splice(index, 1);
         break;
        }
       }
  
       return newState;
      });
  
      if (propsResourceTrimmedTexts && propsResourceTrimmedTexts?.length > 0) {
       for (let index = 0; index < propsResourceTrimmedTexts.length; index++) {
        const resourceTrimmedText = propsResourceTrimmedTexts[index];
        if (resourceTrimmedText._id === trimTextIdToDelete) {
          propsResourceTrimmedTexts.splice(index, 1);
          if (props.isInCopilot) {
            deleteCopilotResourceTrimmedTextInUI({
              resourceId: resource._id,
              idOfTrimTextToDelete: trimTextIdToDelete
            })
          } else {
            deleteResourceTrimmedTextInUI({
              resourceId: resource._id,
              idOfTrimTextToDelete: trimTextIdToDelete
            })
            // updateResourceInUI({
            //  ...resource,
            //  ...{
            //   trimmedTexts: [...propsResourceTrimmedTexts],
            //  },
            // });
          }
         break;
        }
       }
      }

      if(modelType === "DLP"){
        let updatePiiData : boolean | undefined = undefined;
        if (propsResourceTrimmedTexts) {
          for (let index = 0; index < propsResourceTrimmedTexts.length; index++) {
            const resourceTrimmedText = propsResourceTrimmedTexts[index];
            if(resourceTrimmedText.isPiiData === true){
              updatePiiData = true;
              break;
            }else if (resourceTrimmedText.isPiiData === false){
              updatePiiData = false;
            }
          }
        }
        // if(updatePiiData !== undefined){
          if(updatePiiData === true){
            handleLabelSave(LabelToSetInDLPModelTrimmedTextIfAnyOneTrimTextIsPiiData);
          // }else if(updatePiiData === false){
          }else {
            handleLabelSave(LabelToSetInDLPModelTrimmedTextIfNoTrimTextIsPiiData);
          }
        // }
      }
     }
    const highlightedElements = document.querySelectorAll(`[id='edit${trimTextIdToDelete}']`);
    
     if(highlightedElements.length > 0){
      highlightedElements.forEach((highlightElement)=>{
        hltr.current.removeHighlights(highlightElement)
      })
     }
     setTrimmedTexts((oldState) => {
      const newState = oldState;
  
      for (let index = 0; index < newState.length; index++) {
       const trimmedText = newState[index];
       if (trimmedText._id === trimTextIdToDelete) {
         trimTextColorPickerRef.current.decrementColorUsageCount(trimmedText.color)
        newState.splice(index, 1);
       }
      }
  
      return [...newState];
     });
  
  
     setTrimTextIdsSelectedInTable(oldIdsSelected=>{
      const idAlreadyPresentInArray = oldIdsSelected.find(oldId=>oldId===trimTextIdToDelete) ? true : false
  
      if (idAlreadyPresentInArray) {
  
        for (let index = 0; index < oldIdsSelected.length; index++) {
          const oldId = oldIdsSelected[index];
          if (oldId === trimTextIdToDelete) {
            oldIdsSelected.splice(index, 1);
            break;
          }
        }
  
        return [...oldIdsSelected];
      } else {
        return oldIdsSelected;
      }
     })
    },
    [props.deleteCopilotResourceTrimmedTextInUI, props.deleteResourceTrimmedTextInUI, props.isInCopilot, resource, trimmedTexts, modelType, handleLabelSave]
  );  

  const onSaveEditedText = async () => {
    try{
      setIsSavingEditedText(true);
      await DataService.updateData({
        id: [resource._id],
        resource: tempEditedText
      })

      window.location.reload();
    }
    catch(error) {
      setCustomAlert("Error saving edited text: ", error.message);
    }
    finally {
      setIsEditingText(false);
    }
  }

  const onEditTextButtonClick = () => {
    setTempEditedText(resource.resource);
    setIsEditingText(true);
  }
   
 return (
  <div 
    className={`
      ${classes.container}
        ${props.scenario==="copilotActivePageAnnotationsTableShowing" ? classes.containerWhenScenarioIsCopilotActivePageAnnotationsTableShowing : ""}
        ${props.scenario==="copilotActivePageAnnotationTableHidden" ? classes.containerWhenScenarioIsCopilotActivePageAnnotationsTableHidden : ""}
        ${props.scenario==="modelAnalyticsResourcesViewPopup" ? classes.containerWhenScenarioIsModelAnalyticsResourcesViewPopup : ""}
    `}
  >

    {
      props.scenario !== "copilotResourceCard" &&
      props.scenario !== "copilotNotificationSideDrawer" &&
      props.scenario !== "copilotSideDrawer" &&
      props.scenario !== "copilotActivePageAnnotationsTableShowing" &&
      props.scenario !== "copilotActivePageAnnotationTableHidden" &&
      props.scenario !== "modelAnalyticsResourcesViewPopup" &&
    <div
      style={{
        marginBottom: "20px"
      }}
    >
      <TextResourceHeading />
    </div>
    }

    <div 
      className={`
        ${classes.textTrimContainer}
        ${props.scenario==="copilotActivePageAnnotationTableHidden" ? classes.textTrimContainerWhenScenarioCopilotActivePageAnnotationTableHidden : ""}
        ${props.scenario==="copilotActivePageAnnotationsTableShowing" ? classes.textTrimContainerWhenScenariocopilotActivePageAnnotationsTableShowing : ""}
      `}
      style={{
        ...(props.resourceTextStyles && props.resourceTextStyles),
        height: props.scenario === "copilotResourceCard" 
          ? '310px' 
          : props.scenario === "copilotSideDrawer" 
            ? '240px'
            : props.scenario === "copilotActivePageAnnotationTableHidden"
              ? '94%'
              : '', 
        maxHeight: (props.scenario === "copilotResourceCard" || props.scenario === "copilotSideDrawer")
          ? '' 
          : props.scenario === "copilotActivePageAnnotationsTableShowing" || props.scenario === "copilotActivePageAnnotationTableHidden"
            ? "100%"
            : props.scenario === "copilotNotificationSideDrawer"
              ? "71px"
              : "168px"
      }}      
    >
        {/* <p id="sandbox">Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book. It has survived not only five centuries, but also the leap into electro</p> */}
        <p 
          // id="sandbox" 
          id={sandBoxIdRef.current}
          className={`
            ${classes.sandbox}
            ${props.scenario === "copilotResourceCard" || props.scenario === "copilotNotificationSideDrawer" || props.scenario === "copilotSideDrawer" || props.scenario === "copilotActivePageAnnotationTableHidden"  ? classes.doNotShowSandBoxAnnotationsAsClickable : ""}
          `}
          onClick={ (event) => { 
            if (
              props.scenario !== "copilotResourceCard" &&
              props.scenario !== "copilotNotificationSideDrawer" &&
              props.scenario !== "copilotSideDrawer" &&
              props.scenario !== "copilotActivePageAnnotationTableHidden"
            ) {
              scrollToTableRowAndHighlight(event.target) }
            }
          }
        >
              {resource.resource}
          </p>
    </div>
     <div style={{display: 'flex'}}>
       {
           <Button size="small" startIcon={<Edit />} onClick={onEditTextButtonClick} style={{textTransform: 'none', marginLeft: 'auto', marginRight: '1rem'}}>
             Edit text
           </Button>
       }
     </div>

     {
      <Dialog open={isEditingText} onClose={() => setIsEditingText(false)} fullWidth>
        <TextareaAutosize 
          value={tempEditedText}
          onChange={(e) => setTempEditedText(e.target.value)}
          maxRows={40}
          style={{ margin: 8, padding: 8}}
        />
        <DialogActions>
        <Button size="small" startIcon={<Cancel style={{marginTop: '-2px'}} />} onClick={() => {setIsEditingText(false); setTempEditedText('');}} disabled={isSavingEditedText} style={{textTransform: 'none'}}>
             Cancel
           </Button>
        <Button size="small" startIcon={<Save style={{marginTop: '-2px'}} />} onClick={onSaveEditedText} disabled={isSavingEditedText} style={{textTransform: 'none'}}>
             {
               isSavingEditedText ?
                 "Saving..."
                 : "Save"
             }
           </Button>
        </DialogActions>
      </Dialog>
     }

    {
      props.scenario !== "copilotActivePageAnnotationTableHidden" &&
    <div
      className={`
          ${classes.trimmedTextsSection}
          ${props.scenario === "copilotActivePageAnnotationsTableShowing" ? classes.trimmedTextsSectionWhenScenariocopilotActivePageAnnotationsTableShowing : ""}
          ${
            props.scenario === "copilotActivePageAnnotationsTableShowing" && isAllTextAnnotationsHaveLabels
            ? classes.trimmedTextsSectionWhenScenariocopilotActivePageAnnotationsTableShowingAndUnSaveWarningNotShowing
            : ""
          }
          ${
            props.scenario === "modelAnalyticsResourcesViewPopup"
            ? classes.trimmedTextsSectionWhenScenarioIsModelAnalyticsResourcesViewPopup
            : ""
          }
      `}
    >
    {
      trimmedTexts?.length>0 && 
      props.scenario !== "copilotResourceCard" &&
      props.scenario !== "copilotNotificationSideDrawer" &&
      props.scenario !== "copilotSideDrawer" &&
    <div
        style={{
          marginBottom: "7px",
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
          minHeight: "40px"
        }}
      >
        <div
          style={{fontWeight: 600}}
        >
          Trimmed Texts
        </div>
        {
          trimTextIdsSelectedInTable?.length>0 &&
          <section
            style={{
              display: "flex",
              justifyContent: "end",
              alignItems: "center"
            }}
          >
              {
                props.scenario !== "copilotActivePageAnnotationsTableShowing" &&
                <Autocomplete
                  style={{
                    width: "362px"
                  }}
                  size="small"
                  disabled={(trimTextIdsBeingClonedInDb?.length>0 || !checkSelectedTrimmedTextLabel()) ? true : false}
                  // value={currentDefaultDataSetCollectionName()}
                  value={modelIdSelectedToCloneInto}
                  options={
                  projectTextAnnotationModels.map(model=>model._id)   
                  }
                  getOptionLabel={
                    (modelId) => {
                    const modelName = projectTextAnnotationModels.find(
                        (model) => model._id.toString() === modelId.toString()
                    )?.name;
                    if (modelName) {
                        return modelName;
                    } else {
                        return modelId;
                    }
                  }}
                  onChange={async (event, value) => {
                    const modelIdToCloneTrimTextInto = value || "";
  
                    if (!modelIdToCloneTrimTextInto) {
                      setModelIdSelectedToCloneInto("");
                      return;
                    }
  
                    setModelIdSelectedToCloneInto(modelIdToCloneTrimTextInto);
  
                    const trimTextIdsToClone = [...trimTextIdsSelectedInTable];
  
                    setTrimTextIdsBeingClonedInDb([...trimTextIdsToClone]);
  
                    const promiseConcurrency = new PromiseConcurrency({
                      concurrencyLimit: 2
                    });
  
                    for (let trimTextIdIndex = 0; trimTextIdIndex < trimTextIdsToClone.length; trimTextIdIndex++) {
                      const trimTextIdToClone = trimTextIdsToClone[trimTextIdIndex];
                      const trimTextClonePromise = new Promise<void>(async (resolve, reject) => {
                        try {
  
                          const resourceApprovalStatus: ResourceStatus = "approval";
  
                          const trimText = trimmedTexts.find((trimText)=>trimText._id === trimTextIdToClone);
  
                          const bodyFormData = new FormData();
                          bodyFormData.append("model", modelIdToCloneTrimTextInto);
                          bodyFormData.append("status", resourceApprovalStatus);
                          bodyFormData.append("csv", resource.csv);
                          bodyFormData.append("label", trimText?.label || "");
                          bodyFormData.append("tag", resource.tag);
                          
                          bodyFormData.append("resource", getTrimmedText(resource.resource, trimText?.startPosition || 0, trimText?.endPosition || 0));
  
                          bodyFormData.append("prediction", resource.prediction || "");
                          bodyFormData.append("confidence_score", String(resource.confidence_score) ? String(resource.confidence_score) : String(resource.confidenceScore));
                          bodyFormData.append("dataBoost", resource.dataBoost || "1");
                          bodyFormData.append("trimmedFromTextResource", JSON.stringify({
                            _id: resource._id.toString(),
                            resourceText: resource.resource,
                          }));
                          const cloneApiResponse = await DataService.createResource(bodyFormData);
                          resolve();
                        } catch (error) {
                          reject(new Error(error.message));
                        }
                      });
  
                      trimTextClonePromise.then(()=>{
                        setTrimTextIdsBeingClonedInDb(oldIds=>{
                          for (let index = 0; index < oldIds.length; index++) {
                            const oldId = oldIds[index];
                            if (oldId === trimTextIdToClone) {
                              oldIds.splice(index, 1);
                              break;
                            }
                          }
                          return [...oldIds]
                        })
                      })
  
                      promiseConcurrency.addPromise(trimTextClonePromise);
                      await promiseConcurrency.waitIfConcurrencyLimitReached();
                    }
  
                    await promiseConcurrency.waitUntilAllPromisesAreResolved();
  
                    setModelIdSelectedToCloneInto("");
  
                    // setDataSetCollectionsState((oldState) => {
                    //   const labelsToSave = value as string ;
  
                    //   return {
                    //     ...oldState,
                    //     isDirty: true,
                    //     isTouched: true,
                    //     value: labelsToSave,
                    //   };
                    // });
                  }}
                  // defaultValue={currentDefaultDataSetCollectionName()}
                  renderTags={(value, getTagProps) => {
                    return value.map((option, index) => (
                        <Chip
                            variant="outlined"
                            color="primary"
                            label={<Tooltip title={option}>
                                <span>
                                    {index + 1}:
                                    {projectTextAnnotationModels.find(
                                        (model) => model._id.toString() === option.toString()
                                    )?.name}
                                </span>
                            </Tooltip>}
                            {...getTagProps({ index })} />
                    ));
                  } }
                  renderInput={(params) => (
                    <Tooltip title={!checkSelectedTrimmedTextLabel() ? 'One of your selected trimmed text label is not set, please assign a label to be able to clone it' : ''}>
                      <TextField
                        {...params}
                        size="small"
                        disabled={(trimTextIdsBeingClonedInDb?.length || !checkSelectedTrimmedTextLabel()) > 0 ? true : false}
                        InputProps={{
                          ...params.InputProps,
                          endAdornment: <></>
                        }}
                        variant="outlined"
                        // defaultValue={currentDefaultDataSetCollectionName()}
                        // className={classes.floating}
                        label="Select task to clone selected trimmed texts"
                      />
                    </Tooltip>
                  )}
                />   
              }

              {
                  props.scenario !== "modelAnalyticsResourcesViewPopup" &&
                  <>
                    {
                      !isDeleteAllTrimTextsProcessHappening
                      ?
                        <Tooltip title="Delete selected trimmed texts">
                          <DeleteOutlineIcon
                            color="error"
                            style={{
                              cursor: "pointer",
                              marginLeft: "10px"
                            }}
                            onClick={async () => {
                              setIsDeleteAllTrimTextsProcessHappening(true);
                              const promiseConcurrency = new PromiseConcurrency({concurrencyLimit: 5})
                              const textIdsSelectedInTable = [...trimTextIdsSelectedInTable];
                              for (const trimTextIdSelectedInTable of textIdsSelectedInTable) {
                                const textDeletePromise = new Promise<void>(async (resolve, reject) => {
                                  await handleTrimTextDeleteClick(trimTextIdSelectedInTable);
                                  resolve()
                                })
                                promiseConcurrency.addPromise(textDeletePromise);
                                await promiseConcurrency.waitIfConcurrencyLimitReached();
                              }
      
                              await promiseConcurrency.waitUntilAllPromisesAreResolved();
      
                              setIsDeleteAllTrimTextsProcessHappening(false);
      
                              if (modelType === "DLP") {
                                setTimeout(() => {
                                  handleLabelSave(LabelToSetInDLPModelTrimmedTextIfNoTrimTextIsPiiData)
                                }, 100);
                              }
                            }}
                          />             
                        </Tooltip>                     
                      :
                        <div style={{marginLeft: "10px"}}>
                          <CircularProgress size={24} style={{color: "#f44336"}} />
                        </div>
                    }
                    </>
              }

          </section>
        }
      </div>
    }

    {
      trimmedTexts?.length>0 && !isAllTextAnnotationsHaveLabels &&
      props.scenario !== "copilotResourceCard" &&
      props.scenario !== "copilotNotificationSideDrawer" &&
      props.scenario !== "copilotSideDrawer" &&      
      <section
        style={{
          marginTop: "24px",
          marginBottom: "35px"
        }}
      >
        <TrimLabelWontGetSavedWarningAlert>Trimmed Texts won't get saved if label not assigned to them!</TrimLabelWontGetSavedWarningAlert>
      </section>
    }

    {
      trimmedTexts?.length>0 &&
      props.scenario !== "copilotResourceCard" &&
      props.scenario !== "copilotNotificationSideDrawer" &&
      props.scenario !== "copilotSideDrawer" &&
    <TableContainer component={Paper}
        className={`
          ${!isAllTextAnnotationsHaveLabels ?classes.trimTableContainer: classes.trimTableContainerWithoutWarning}
          ${props.scenario==="copilotActivePageAnnotationsTableShowing" ? classes.trimTableContainerWhenScenariocopilotActivePageAnnotationsTableShowing : ""}
        `}
      >
      <Table stickyHeader size="small">
        <TableHead>
        <TableRow>
          <TableCell className={`${classes.trimmedTextsTableHeadCell} ${classes.trimmedTextsTableCellCheckbox}`}>
            <Checkbox
              indeterminate={trimTextIdsSelectedInTable?.length>0 && trimTextIdsSelectedInTable?.length < trimmedTexts?.length} 
              checked={
                trimmedTexts?.length === trimTextIdsSelectedInTable?.length && trimTextIdsSelectedInTable?.length>0 
              }
              onChange={(e)=>{
                if (e.target.checked) {
                  const totalAudioIds: string[] = [...trimmedTexts.map(audio => audio._id)];
                  setTrimTextIdsSelectedInTable([...totalAudioIds])
                } else {
                  setTrimTextIdsSelectedInTable([])
                }
              }}
            />
          </TableCell>
          {
            props.scenario !== "copilotActivePageAnnotationsTableShowing" &&
            <TableCell className={`${classes.trimmedTextsTableHeadCell} ${classes.trimmedTextsTableCellAnnotationColor}`}>Colour</TableCell>
          }
          <TableCell className={`${classes.trimmedTextsTableHeadCell} ${classes.trimmedTextsTableCellTrimmedText}`}>Trimmed Text</TableCell>
          {/* <TableCell className={`${classes.trimmedTextsTableHeadCell} ${classes.trimmedTextsTableCellStartTimeColor}`}>Start</TableCell>
          <TableCell className={`${classes.trimmedTextsTableHeadCell} ${classes.trimmedTextsTableCellEndTimeColor}`}>End</TableCell> */}
          {
            modelType === 'DLP' &&
            <TableCell className={`${classes.trimmedTextsTableHeadCell} ${classes.piiDataWidth}`}>Pii Data</TableCell>
          }
          {
            (modelType === 'DLP' || modelType === 'textAnnotation') &&
            <TableCell className={`${classes.trimmedTextsTableHeadCell} ${classes.confidenceScoreWidth}`}>Confidence Score</TableCell>
          }
          {
            (modelType === 'DLP') &&
            <TableCell className={`${classes.trimmedTextsTableHeadCell} ${classes.tagWidth}`}>Tag</TableCell>
          }
          <TableCell className={`${classes.trimmedTextsTableHeadCell} ${classes.trimmedTextsTableCellLabel}`}>Label</TableCell>
          {
            props.scenario !== "modelAnalyticsResourcesViewPopup" &&
            <TableCell className={`${classes.trimmedTextsTableHeadCell} ${classes.trimmedTextsTableCellActions}`}>Actions</TableCell>
          }
        </TableRow>
        </TableHead>
        <TableBody>
        {trimmedTexts
        .sort((a,b) => {return a.startPosition - b.startPosition})
        .map((trimmedText, trimTextIndex) => {
          return (
          <TableRow key={trimmedText._id}
            className={`
              ${
                trimmedTextIdsBeingDeletedFromDb.indexOf(trimmedText._id) !== -1 ||
                (trimmedTextIdsBeingUpdatedInDb.indexOf(trimmedText._id) !== -1 && trimmedTextIdsWhoseTagBeingUpdatedInDb.indexOf(trimmedText._id) === -1) ||
                trimTextIdsBeingClonedInDb.indexOf(trimmedText._id) !== -1
                ? classes.trimTextTableRowDisabled 
                : ""
              }
            `}
            onMouseOver={()=> onColorHover(trimmedText._id)}
            onMouseOut={()=> onColorHoverOut(trimmedText._id)}
          >
            <TableCell style={{paddingRight: 0}}>
              <Checkbox 
                checked={
                  trimTextIdsSelectedInTable.indexOf(trimmedText._id) !== -1
                  ? true
                  : false
                }
                onChange={(e) => {
                  if (e.target.checked) {
                    setTrimTextIdsSelectedInTable(oldIds=>([...oldIds, trimmedText._id]))
                  } else {
                    setTrimTextIdsSelectedInTable(oldIds=>{
                      for (let index = 0; index < oldIds.length; index++) {
                        const oldId = oldIds[index];
                        if (oldId === trimmedText._id) {
                          oldIds.splice(index, 1);
                          break;
                        }
                      }
                      return [...oldIds]
                    })
                  }
                }}
              />
            </TableCell>
            {
              props.scenario !== "copilotActivePageAnnotationsTableShowing" &&
              <TableCell className={classes.trimmedTextsTableCellAnnotationColor}>
                <div 
                  style={{
                    backgroundColor: trimmedText.color,
                    width: "30px",
                    height: "30px",
                    borderRadius: "4px",
                    cursor: "pointer"
                  }}
                  onClick={()=>{
                    highlightAnnotationTextInResourceText(trimmedText._id)
                  }}
                ></div>
              </TableCell>
            }
            <TableCell>
              <Tooltip
                title={getTrimmedText(resource.resource, trimmedText.startPosition, trimmedText.endPosition)}
              >
                <div
                  id={ 'annotation_id_' + trimmedText._id }
                  style={{
                    maxHeight: "40px",
                    overflow: "auto",
                    padding: "5px",
                    border: `3px solid ${trimmedText.color}`,
                    borderRadius: "5px",
                    cursor: "pointer"
                  }}
                  onClick={()=>highlightAnnotationTextInResourceText(trimmedText._id)}
                >
                  <span
                    // style={{
                    //   // backgroundColor: trimmedText.color
                    // }}
                  >{getTrimmedText(resource.resource, trimmedText.startPosition, trimmedText.endPosition)}</span>
                </div>
              </Tooltip>
              </TableCell>
            {/* <TableCell>{trimmedText.startPosition + 1}</TableCell>
            <TableCell>{trimmedText.endPosition + 1}</TableCell> */}
            {modelType === 'DLP' &&
              <TableCell className={` ${classes.piiDataWidth}`}>
                {
                  props.scenario === "modelAnalyticsResourcesViewPopup"
                  ? trimmedText?.isPiiData ? "Yes" : "No"
                  :
                    <IsPiiDataCheckBox textId={trimmedTexts[trimTextIndex]?._id} update ={isPiiDataChanged} label={trimmedText?.label} isPiiData={trimmedText?.isPiiData}/>
                }
              </TableCell>
            }
            {
              (modelType === 'DLP' || modelType === 'textAnnotation') &&
                <TableCell className={`${classes.confidenceScoreWidth}`}>
                    <Tooltip title={trimmedText.hasOwnProperty("confidence_score") ? parseConfidenceScoreTo2DecimalPlacesMax(trimmedText.confidence_score) + "%" : "NA"}>
                      <Chip style={{ width: "79px" }} label={trimmedText.hasOwnProperty("confidence_score") ? parseConfidenceScoreTo2DecimalPlacesMax(trimmedText.confidence_score) + "%" : "NA"} classes={{ root: classes.chip }} />
                    </Tooltip>
                </TableCell>
            }
            {
              (modelType === 'DLP') &&
              <TableCell className = {`${classes.tagWidth}`}>
                {
                  props.scenario === 'modelAnalyticsResourcesViewPopup' ? 
                  trimmedText.tag :
                  <Tooltip title = {trimmedText.tag ?? ''}>
                    <TextField 
                      id = {trimmedText._id+"tag"}
                      size = {"small"} 
                      variant = "outlined"
                      className = {`${classes.tagEditField}`}
                      // label="Tag"
                      disabled = {trimmedText?.label?.length > 0 ? false : true} 
                      defaultValue = {trimmedText.tag} 
                      onBlur = {(e) => {e.target.value !== trimmedText.tag && handleTrimTextUpdateInDB("updateInDB", trimmedText._id, undefined, undefined, e.target.value)}}   
                      onKeyPress={(e) => {
                        if (e.key === 'Enter') {
                          document.getElementById(trimmedText._id+"tag")?.blur();
                        }
                      }}                   
                    />
                  </Tooltip>
                }
              </TableCell>
            }
            <TableCell className={classes.trimmedTextsTableCellLabel}>
              {
                props.scenario !== "modelAnalyticsResourcesViewPopup"
                ?
                  <LabelField label={trimmedText?.label} update={labelChanged} audioId={trimmedTexts[trimTextIndex]?._id} modelAnnotationLabelsAvailable={props.annotationLabelsAvailable || []}/>
                :
                  <Typography
                    style={{
                      height: "35px",
                      overflow: "auto"
                    }}
                  >
                    {
                      trimmedText?.label
                    }
                  </Typography>
              }
            </TableCell>
            {
              props.scenario !== "modelAnalyticsResourcesViewPopup" &&
            <TableCell className={trimmedTextIdsWhoseTagBeingUpdatedInDb.indexOf(trimmedText._id) !== -1 ? classes.trimTextTableRowDisabled : ""}>
              {
                trimTextIdsBeingClonedInDb.indexOf(trimmedText._id) !== -1
                ? 
                  <span>Cloning ...</span>
                :
                <Tooltip title="Delete the Text">
                  <DeleteOutlineIcon
                    color="error"
                    style={{
                      cursor: "pointer"
                    }}
                    onClick={() => {
                      handleTrimTextDeleteClick(trimmedText._id);
                    }}
                  />             
                </Tooltip>
              }
            </TableCell>
            }
          </TableRow>
          );
        })}
        </TableBody>
      </Table>
      </TableContainer>
    }
    </div>
    }
  </div>
 );
}

const connector = connect(undefined, {
  updateData,
  setResources,
  // setCoPilotResourceData,
  updateCopilotResourceLabelInUI,
  deleteCopilotResourceTrimmedTextInUI,
  deleteResourceTrimmedTextInUI
});

export default connector(TextTrimUI);

export type TPropsFromRedux = ConnectedProps<typeof connector>;
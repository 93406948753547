import { IconButton } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import { HighlightOff } from "@material-ui/icons";
import { InputFieldState } from "../../../../../../../../../../../common/constants/interfaces/inputFieldState";
import { ImageCoordinate } from "../../../../../../../../../components/ModelData/ImagePlotter";

const useStyles = makeStyles((theme) => ({
  coordinatesListContainerWhenSideDrawerScenario: {
    maxHeight: "150px",
    overflow: "auto",
    "& td": {
      width: "44px",
    },
  },
  coordinatesListContainerWhenDialogScenario: {
    // maxHeight: "150px",
    overflow: "auto",
    minWidth: "238px",
    maxHeight: "60vh",
    "& td": {
      width: "44px",
    },
  },
}));

type Props = {
  canvasId: string;
  scenario: "sideDrawer" | "dialog";
  imageCoordinatesState: InputFieldState<ImageCoordinate[]>;
  setImageCoordinatesState: React.Dispatch<
    React.SetStateAction<InputFieldState<ImageCoordinate[]>>
  >;
  imageDifference: number;
  circleRadius: number;
  shouldNotAllowEditing: boolean;
};

export function CoordinatesList(props: Props) {
  const classes = useStyles();
  const {
    scenario,
    imageCoordinatesState,
    circleRadius,
    imageDifference,
    shouldNotAllowEditing,
  } = props;
  const imageCoordinates = imageCoordinatesState.value;
  return (
    <div
      className={`
    ${
      scenario === "sideDrawer"
        ? classes.coordinatesListContainerWhenSideDrawerScenario
        : ""
    }
    ${
      scenario === "dialog"
        ? classes.coordinatesListContainerWhenDialogScenario
        : ""
    }
    `}
    >
      {imageCoordinates.map((imageCoordinate, index) => {
        return (
          <tr
            key={index}
            style={{
              // padding: "10px",
              display: "flex",
              alignItems: "center",
            }}
          >
            <td
              style={{
                backgroundColor: `${imageCoordinate.colorCode}`,
                // paddingLeft: "2px",
                // paddingRight: "6px",
                width: "11px",
                height: "11px",
                lineHeight: "11.1px",
                display: "inline-block",
                marginRight: "6px",
              }}
            >
              &nbsp;
            </td>
            <td>X:</td>
            <td>{imageCoordinate.xCoordinate},</td>
            <td>Y:</td>
            <td>{imageCoordinate.yCoordinate}</td>
            {!shouldNotAllowEditing && (
              <td>
                <IconButton
                  style={{ padding: "5px" }}
                  onClick={() => {
                    props.setImageCoordinatesState((oldState) => {
                      const oldValue = oldState.value;
                      const newValue = JSON.parse(JSON.stringify(oldValue));
                      for (
                        let indexOfCoordinate = 0;
                        indexOfCoordinate < newValue.length;
                        indexOfCoordinate++
                      ) {
                        // const coordinate = newValue[indexOfCoordinate];
                        if (indexOfCoordinate === index) {
                          newValue.splice(indexOfCoordinate, 1);
                        }
                      }
                      return {
                        ...oldState,
                        isDirty: true,
                        isTouched: true,
                        value: [...newValue],
                      };
                    });
                    let canvas = document.getElementById(props.canvasId);

                    // @ts-ignore
                    let context = canvas?.getContext("2d");
                    // context.clearRect(
                    //   imageCoordinate.split(",")[0],
                    //   imageCoordinate.split(",")[1],
                    //   10,
                    //   10
                    // );
                    context?.beginPath();
                    context?.clearRect(
                      imageCoordinate.xCoordinate / imageDifference -
                        circleRadius -
                        1,
                      imageCoordinate.yCoordinate / imageDifference -
                        circleRadius -
                        1,
                      circleRadius * 2 + 2,
                      circleRadius * 2 + 2
                    );
                    context?.closePath();
                  }}
                >
                  <HighlightOff />
                </IconButton>
              </td>
            )}
          </tr>
        );
      })}
    </div>
  );
}

import {
  makeStyles,
  Theme,
  createStyles,
  lighten,
  Toolbar,
  Typography,
  IconButton,
  Tooltip,
  CircularProgress,
  Button
} from "@material-ui/core";
import { Delete } from "@material-ui/icons";
import clsx from "clsx";
import { ReactElement } from "react";
import { ICollection, IModelCollection, ITestCollection } from "../../../../../../../../../../../common";
import { ReactComponent as BestModelIcon } from '../../../../../../../../../../../assets/svg/best_model_icon.svg';
import { deleteButtonIconColor } from "../../data/constants/constants";

interface IEnhancedTableToolbarProps {
  selected: (ICollection | IModelCollection | ITestCollection)[];
  status: string[];
  updateData?: any;
  resources: (ICollection | IModelCollection | ITestCollection)[];
  onDeleteClick: Function;
  onTestClick?: Function;
  isDeleteHappening: boolean;
  trainButtonComponentElement?: ReactElement;
  reTrainButtonComponentElement?: ReactElement;
  mergeDataSetsElement?: ReactElement;
  shouldShowBestModel?: boolean;
  onBestButtonClick?: Function;
  shouldShowCompareButton?: boolean;
  onCompareButtonClick ?: Function;
  isFetchingModelCollections ?: boolean;
}

const useToolbarStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      paddingLeft: theme.spacing(2),
      paddingRight: theme.spacing(1),
    },
    highlight:
      theme.palette.type === "light"
        ? {
            color: theme.palette.secondary.main,
            backgroundColor: lighten(theme.palette.secondary.light, 0.85),
          }
        : {
            color: theme.palette.text.primary,
            backgroundColor: theme.palette.secondary.dark,
          },
    title: {
      flex: "1 1 100%",
    },
    buttons: {
      display: "flex",
      paddingRight: "8px",
    },
    deleteButton: {
      color: deleteButtonIconColor,
    },
    flex: {
      display: 'flex',
      justifyContent: 'space-between'
    },
    bestModelBtn: {
      width: '170px',
      margin: theme.spacing(1.5),
    },
    bestModelBtnIcon: {
      marginRight: theme.spacing(2)
    },
    btnDisabled: {
      opacity: 0.4,
    },
  })
);

const deleteButtonUseStyles = makeStyles(
  (theme: Theme) =>
createStyles({
    delete: {
        color: deleteButtonIconColor
    },
    progress: {
        padding: '10px',
    },
}));

const CollectionEnhancedTableToolbar = (props: IEnhancedTableToolbarProps) => {
  const classes = useToolbarStyles();
  const { selected, status, onDeleteClick, isDeleteHappening, trainButtonComponentElement, reTrainButtonComponentElement, mergeDataSetsElement, onTestClick, onBestButtonClick, shouldShowCompareButton, onCompareButtonClick, isFetchingModelCollections } = props;

  // const download = (event: React.MouseEvent<unknown>, selected: string[]) => {
  //     (async () => {
  //         try {
  //             const data: AxiosResponse<IDownlaodDataResponse> = await ModelService.downloadData({ image_data: selected, type: "spoof" });
  //             window.open(data.data.filepath, "_self");
  //         } catch (error) {
  //             console.log('error:', error);
  //         }
  //     })();
  // }
  const DeleteButton = () => {
    const deleteButtonClasses = deleteButtonUseStyles();

    return (
      <>
      {
        isDeleteHappening
        ? <CircularProgress classes={{ root: clsx(deleteButtonClasses.progress, deleteButtonClasses.delete) }} size={48} /> 
        : <Tooltip
            title={
              "Delete selected collections"
            }
          >
            <IconButton
              classes={{ root: classes.deleteButton }}
              aria-label="delete"
              onClick={() => onDeleteClick()}
            >
              <Delete />
            </IconButton>
          </Tooltip>
      }
    </>
    );
  };


  const isArchitectureDiff = (selected: IModelCollection[]) => {
    return !(selected.every(selectedCollection => selectedCollection.modelArchitecture === selected[0].modelArchitecture));
  }

  const disableTestTheCollectionBtn = status.includes("test") ? isArchitectureDiff(selected as IModelCollection[]) : false;

  return (
    <Toolbar
       style={{width:selected.length>0?"100%":""}}
      className={clsx(classes.root, {
        [classes.highlight]: selected.length > 0,
        
      })}
    >
      {selected.length > 0 ? (
        <Typography
          className={classes.title}
          color="inherit"
          variant="subtitle1"
          component="div"
        >
          {selected.length} selected
        </Typography>
      ) : (
        <Typography
          className={classes.title}
          variant="h6"
          id="tableTitle"
          component="div"
          classes={{ root: classes.flex}}
        >
            <span style={{ margin: 'auto 0'}}>Collections</span>
            <section style={{float: 'right'}}>
              {
                props.shouldShowBestModel && onBestButtonClick !== undefined &&
                <Button
                  variant="contained"
                  color="primary"
                  classes={{ startIcon: classes.bestModelBtnIcon }}
                  className={classes.bestModelBtn}
                  disabled={isFetchingModelCollections}
                  startIcon={<BestModelIcon fontSize="small" />}
                    onClick={() => onBestButtonClick()}
                >
                  Best Model
                </Button>
              }
            </section>
        </Typography>
      )}
       {selected.length>1 && (
         <div>
           {mergeDataSetsElement}
          </div>
      )}
      {selected.length > 0 && (
        <div className={classes.buttons}>
          {
            status.indexOf("test") !== -1 && onTestClick !== undefined ? (
              <Tooltip title={disableTestTheCollectionBtn ? "Architecture must be same" : "Test the collection"}>
                  <div>
                    <IconButton className={disableTestTheCollectionBtn ? classes.btnDisabled : ''} disabled={disableTestTheCollectionBtn} onClick={() => onTestClick()}>
                      <img alt="" src="https://cdn-icons-png.flaticon.com/512/1581/1581884.png" height={24} width={24} />
                    </IconButton>
                  </div>
                </Tooltip>
            ) : null
          }
          {status.indexOf("deleted") !== -1 ? (
            <>
              {" "}
              <DeleteButton />
            </>
          ) : null}
          {status.indexOf("train") !== -1 ? (
            <>
              {" "}
              {trainButtonComponentElement}
            </>
          ) : null}
          {status.indexOf("reTrain") !== -1 ? (
            <>
              {" "}
              {reTrainButtonComponentElement}
            </>
          ) : null}
        </div>
      )}
      {/* {
                shouldShowCompareButton && onCompareButtonClick !== undefined &&
                <Tooltip title={selected.length < 1 ? "Please select at least 1 model to compare or analyse" : "Compare"}>
                  <div>
                <Button
                  variant="contained"
                  color="primary"
                  className={classes.bestModelBtn}
                  disabled= {selected.length < 1}
                  style={{color: selected.length < 1? "grey" : ""}}
                  // startIcon={<BestModelIcon fontSize="small" />}
                    onClick={() => onCompareButtonClick()}
                >
                  Compare
                </Button>
                </div>
                </Tooltip>

              } */}
     
    </Toolbar>
  );
};

export default CollectionEnhancedTableToolbar;

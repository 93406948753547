import { IconButton } from "@material-ui/core";
import { ThumbUp } from "@material-ui/icons";

export default function SetApprovalStatusButton(props: {
	disabled?: boolean;
	color?: "inherit" | "primary" | "secondary" | "default" | string;
	onMouseOver?: (event: React.MouseEvent<HTMLButtonElement>) => void;
	onClick?: (event: React.MouseEvent<HTMLButtonElement>) => void;
}) {
	return (
		<IconButton
			// color={props.color ? props.color : undefined}
      style={{ color: props.color ? props.color : undefined }}
			disabled={props.disabled ? true : false}
			onClick={(event) => props.onClick && props.onClick(event)}
			onMouseOver={(event) => props.onMouseOver && props.onMouseOver(event)}
		>
			<ThumbUp />
		</IconButton>
	);
}

import {ReactComponent as AiAssistantSvgIcon} from "../../../../../../../../../../../../assets/svg/ICON - Auto AI ASSISTANT.svg"

type Props = {
    onMouseEnter?: (event: any) => any;
    onMouseLeave?: (event: any) => any;
    onClick?: (event: any) => any;
    style?: any;
}

export default function AiAssistantIcon(props: Props) {

    const styles = (() => {
        if (props.style) {
            return props.style || {}
        }
    })();

    return (
        <div>
            <AiAssistantSvgIcon
                onMouseEnter={props.onMouseEnter}
                onMouseLeave={props.onMouseLeave}
                onClick={props.onClick}
                style={styles}
            ></AiAssistantSvgIcon>
        </div>
    );
}
import { useEffect, useRef } from "react";

export function useKey(key:string,handler:(event:KeyboardEvent)=>void){
    const ref = useRef(handler)
    useEffect(()=>{
        ref.current = handler
    },[handler])
    function handleKey(event: KeyboardEvent) {
        if(event.code === key)
            ref.current(event);
    }
    useEffect(()=>{
        document.addEventListener('keydown',handleKey)
        return ()=>document.removeEventListener('keydown',handleKey)
    },[key])
}



import { useEffect, useMemo, useState } from "react";
import Button from "@material-ui/core/Button";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogContentText from "@material-ui/core/DialogContentText";
import DialogTitle from "@material-ui/core/DialogTitle";
import useMediaQuery from "@material-ui/core/useMediaQuery";
import { useTheme } from "@material-ui/core/styles";
import { Autocomplete } from "@material-ui/lab";
import { DataService } from "../../../../../../../../../../../services";
import { InputFieldState } from "../../../../../../../../../../../common/constants/interfaces/inputFieldState";
import {
  Chip,
  CircularProgress,
  makeStyles,
  TextField,
  Tooltip,
} from "@material-ui/core";
import { connect, ConnectedProps, useDispatch } from "react-redux";
import { IReduxState } from "../../../../../../../../../../../common/constants/interfaces/redux";
import { removeDataSetCollectionsFromResources } from "../../../../../../../../../../../store/actions/dataActions";
import { ResourceDataSetCollection } from "../../../../../../../../../../../common";
// import WarningIcon from '@material-ui/icons/Warning';

const useStyles = makeStyles((theme) => ({
  chip: {
    margin: theme.spacing(0.5),
    maxWidth: "30px",
  },
  autoCompleteChipRoot: {
    maxWidth: "170px",
  },
  floating: {
    "& legend": {
      float: "unset",
    },
  },
}));

interface IProps extends TPropsFromRedux {
  resourceIdsSelected: string[];
  dataSetCollectionsOfSelectedResources: ResourceDataSetCollection[];
  onClose: () => void;
}

export function RemoveResourcesFromDataSetCollectionSelectionPopup(
  props: IProps
) {
  const classes = useStyles();
  const theme = useTheme();

  const dispatch = useDispatch();

  const fullScreen = useMediaQuery(theme.breakpoints.down("sm"));
  const [dataSetCollectionsFieldState, setDataSetCollectionsFieldState] =
    useState<InputFieldState<string[]>>({ value: [] });

  const [
    confirmButtonClickProcessHappening,
    setConfirmButtonClickProcessHappening,
  ] = useState(false);

  useEffect(() => {
    let anyValueContainsForwardSlash = false;
    const dataSetCollectionsToUpdate = dataSetCollectionsFieldState.value;

    if (dataSetCollectionsToUpdate?.length > 0) {
      for (let index = 0; index < dataSetCollectionsToUpdate.length; index++) {
        let dataSetCollectionNameOrId = dataSetCollectionsToUpdate[index];
        if (dataSetCollectionNameOrId.indexOf("/") !== -1) {
          anyValueContainsForwardSlash = true;
          dataSetCollectionNameOrId = dataSetCollectionNameOrId.replaceAll(
            "/",
            "_"
          );
          dataSetCollectionsToUpdate[index] = dataSetCollectionNameOrId;
        }
      }
    }

    if (anyValueContainsForwardSlash) {
      /**
       * "/" causes issue in nodejs path that is why converting it to "_"
       */
      setDataSetCollectionsFieldState((oldState) => ({
        ...oldState,
        value: [...dataSetCollectionsToUpdate],
      }));
    }
  }, [dataSetCollectionsFieldState.value]);

  const handleClose = () => {
    props.onClose();
  };

  async function handleConfirmButtonClicked() {
    setConfirmButtonClickProcessHappening(true);

    await DataService.removeResourcesFromDataSetCollections({
      resourceIds: props.resourceIdsSelected,
      dataSetCollectionIds: dataSetCollectionsFieldState.value,
    });

    dispatch(
      removeDataSetCollectionsFromResources(
        props.resourceIdsSelected,
        dataSetCollectionsFieldState.value
      )
    );

    setConfirmButtonClickProcessHappening(false);
    props.onClose();
  }

  return (
    <div>
      <Dialog
        fullScreen={fullScreen}
        open={true}
        onClose={handleClose}
        maxWidth="sm"
        fullWidth={true}
      >
        <DialogTitle>Remove Datas from Data-Set Collections</DialogTitle>
        <DialogContent>
          <DialogContentText>
            Select data-set collections to remove from selected datas
          </DialogContentText>

          {/* {
            props.areAllRowsMatchingTableFilterSelectedByUserAndHasRowsNotShowingInUI && resourceIdsSelected.length>1 &&
            <DialogContentText style={{ display: "flex", fontStyle: "italic", color: "darkorange" }}>
                <WarningIcon fontSize="small" />&nbsp;
                {
                  totalResourceCount && totalResourceCount < 50000 ? `This action will affect all ${totalResourceCount ? totalResourceCount : resourceIdsSelected.length} data matching table filter.` : `This action will affect first 50000 data matching table filter.`
                }
            </DialogContentText>
          } */}
          <div>
            <Autocomplete
              multiple
              // freeSolo
              options={props.dataSetCollectionsOfSelectedResources.map(
                (collection) => collection._id
              )}
              value={dataSetCollectionsFieldState.value}
              getOptionLabel={(collectionId) =>
                props.dataSetCollectionsOfSelectedResources.filter(
                  (collection) =>
                    collection._id.toString() === collectionId.toString()
                )[0]?.name
              }
              // loading={props.isFetchDataSetCollectionApiExecuting ? true : false}
              // loading={false}
              // loadingText={
              //   props.isFetchDataSetCollectionApiExecuting ? "loading" : ""
              // }
              onChange={(event, value) => {
                setDataSetCollectionsFieldState((oldState) => {
                  return {
                    ...oldState,
                    isDirty: true,
                    isTouched: true,
                    value: [...value],
                  };
                });
              }}
              renderTags={(value, getTagProps) => {
                return value.map((option, index) => (
                  <Chip
                    variant="outlined"
                    color="primary"
                    className={`${classes.chip}`}
                    classes={{ root: classes.autoCompleteChipRoot }}
                    label={
                      <Tooltip
                        title={
                          <DataSetCollectionName
                            dataSetCollectionId={option}
                            dataSetCollections={
                              props.dataSetCollectionsOfSelectedResources
                            }
                          />
                        }
                      >
                        <span>
                          {index + 1}:
                          {props.dataSetCollectionsOfSelectedResources.filter(
                            (collection) =>
                              collection._id.toString() === option.toString()
                          )[0]?.name || option}
                        </span>
                      </Tooltip>
                    }
                    {...getTagProps({ index })}
                  />
                ));
              }}
              renderInput={(params) => (
                <TextField
                  className={classes.floating}
                  {...params}
                  variant="outlined"
                  size="small"
                  margin="normal"
                  fullWidth
                  label="Data-Set Collections to remove from selected datas"
                  placeholder="Select Data-Set Collections to remove from selected datas"
                  // onChange={(e) => {
                  //   handleDataSetCollectionInputChangeEvent(e.target.value);
                  // }}
                  // onBlur={(e) => {
                  //   handleDataSetCollectionInputChangeEvent(
                  //     e.target.value + ","
                  //   );
                  // }}
                  error={
                    dataSetCollectionsFieldState.isTouched &&
                    dataSetCollectionsFieldState.errorMessage
                      ? true
                      : false
                  }
                  helperText={
                    dataSetCollectionsFieldState.isTouched &&
                    dataSetCollectionsFieldState.errorMessage
                      ? dataSetCollectionsFieldState.errorMessage
                      : "Add multiple data-set collections with <enter> or <comma>"
                  }
                />
              )}
            />
          </div>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose} color="primary">
            Cancel
          </Button>
          <Button
            onClick={() => handleConfirmButtonClicked()}
            disabled={
              !dataSetCollectionsFieldState.isDirty ||
              confirmButtonClickProcessHappening
            }
            color="primary"
            variant="contained"
          >
            {confirmButtonClickProcessHappening ? (
              <CircularProgress color="primary" size={24} />
            ) : (
              "Remove"
            )}
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
}

const mapProps = (state: IReduxState) => {
  return {};
};

const connector = connect(mapProps, {});

export type TPropsFromRedux = ConnectedProps<typeof connector>;

export default connector(RemoveResourcesFromDataSetCollectionSelectionPopup);

function DataSetCollectionName(props: {
  dataSetCollectionId: string;
  dataSetCollections: {
    _id: string;
    name: string;
  }[];
}) {
  const dataSetCollectionName = useMemo(() => {
    if (Array.isArray(props.dataSetCollections)) {
      for (const dataSetCollection of props.dataSetCollections) {
        if (dataSetCollection._id === props.dataSetCollectionId) {
          return dataSetCollection.name;
        }
      }
    }
    return "";
  }, [props.dataSetCollectionId, props.dataSetCollections]);

  return <>{dataSetCollectionName}</>;
}

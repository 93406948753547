import { AxiosResponse } from "axios";
import { API } from "../../../../../../../services/API";
import { CreateTestDriveVMApiResponse, ICreateVmApiPayload, IStartTestDriveApiPayload, TryOutRequest } from "./types/testDrive";
import { AI_MARKETPLACE_ENDPOINT, TRYOUT_ENDPOINT } from "../aiMarketPlaceAPIStrings";

const CREATE_VM_URL = AI_MARKETPLACE_ENDPOINT + TRYOUT_ENDPOINT + '/createVirtualMachine';
const INITIATE_TRYOUT_URL = AI_MARKETPLACE_ENDPOINT + TRYOUT_ENDPOINT + '/initiateTryOut';
const PROCESS_REQUEST_URL = AI_MARKETPLACE_ENDPOINT + TRYOUT_ENDPOINT + '/getProcessRequests';


class TestDriveService extends API {
    createVM(payload: ICreateVmApiPayload): Promise<AxiosResponse<CreateTestDriveVMApiResponse>> {
        return this.api.post(CREATE_VM_URL, payload);
    }

    startTestDrive(payload: IStartTestDriveApiPayload): Promise<AxiosResponse<TryOutRequest>> {
        return this.api.post(INITIATE_TRYOUT_URL, payload);
    }

    // @ts-ignore
    fetchCurrentProgressState(payload) {
        return this.api.get(PROCESS_REQUEST_URL, payload)
    }
}

export default new TestDriveService();
import { useEffect, useState } from "react";
import {
  Box,
  Chip,
  CircularProgress,
  FormControl,
  Grid,
  makeStyles,
  TextField,
  Tooltip,
  Typography,
} from "@material-ui/core";
import { useForm } from "react-hook-form";

import {
  IAddCollectionPayload,
  CustomButton,
  IReduxState,
  IMergeCollectionPayload,
  ICollection,
} from "../../../../../../../../../../../common";
import {
  // addCollection,
  addCollectionPageInit,
  setSelectedDataSetCollections,
  addSelectedDataSetCollections,
  mergeCollection,
  // getTags,
} from "../../../../../../../../../../../store/actions";
import { connect, ConnectedProps } from "react-redux";
import { useLocation } from "react-router-dom";
import { Autocomplete } from "@material-ui/lab";
import MergeTypeIcon from '@material-ui/icons/MergeType';
import { DataService } from "../../../../../../../../../../../services";

const useStyles = makeStyles((theme) => ({
  root: {
    marginTop: "auto",
    marginBottom: "auto",
  },
  illustration: {
    width: "150px",
    height: "150px",
  },
  form: {
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    "& legend": {
      float: "unset",
    },
  },
  chip: {
    margin: theme.spacing(0.5),
    maxWidth: "30px",
  },
  autoCompleteChipRoot: {
    maxWidth: "170px",
  },
  formControl: {
    // minWidth: 250,
    // maxWidth: 300,
  },
  center: {
    justifyContent: "center",
  },
  floating: {
    "& legend": {
      float: "unset",
    },
  },
}));
// const ITEM_HEIGHT = 48;
// const ITEM_PADDING_TOP = 8;

const MergeDataSetCollection = (props: TPropsFromRedux) => {
  const [selected, setSelected] = useState<string[]>([]);
  const {
    isAddSuccess,
    isAdding,
    // getTags,
    // addCollection,
    addErrorMessage,
    addCollectionPageInit,
  } = props;
  const location = useLocation();
  const {
    register,
    setValue,
    handleSubmit,
    reset,
    formState: { errors },
  } = useForm<IAddCollectionPayload>();
  const classes = useStyles();
  const [totalCollections, setTotalCollections] = useState<ICollection[]>()

  const query = new URLSearchParams(location.search);
  const modelId: string = String(query.get("model"));

  useEffect(() => {
    if(modelId){
      const executeFunction = async () => {
        const apiResponse = await DataService.getCollections({
        model : modelId,
        offset : "-1",
        limit: "-1"
    })
    setTotalCollections(apiResponse.data.collections)
      // props.getCollections(
      //   {
      //     model: modelId,
      //     offset: "-1",
      //     limit: "-1"
      //   }
      // );
    }
    executeFunction();
  }}, [modelId])

  useEffect(() => {
    addCollectionPageInit();
  }, []);
  const onSubmit = (data: IMergeCollectionPayload): void => {
    const selectedDatasetIdList = props.selectedDataSetCollections.map(
      (item) => {
        return { _id: item._id };
      }
    );
    const query = new URLSearchParams(location.search);
    data.model = query.get("model") || "";
    data.parentCollections = selectedDatasetIdList;
    console.log("submitting", data);
    props.mergeCollection(data);
    // addCollection(data);
  };
  useEffect(() => {
    const query = new URLSearchParams(location.search);
    const modelId: string = String(query.get("model"));
  }, [register, setValue]);
  useEffect(() => {
    if (isAddSuccess) {
      setSelected([]);
      reset();
    }
  }, [isAddSuccess]);

  function handleAutoCompleteChange(
    event: any,
    value: any,
    reason: any,
    details: any
  ) {
    if (reason === "select-option") {
      const addedName = details.option;
      const objectToAdd = totalCollections?.find((item) => {
        return item.name === addedName;
      });
      if(objectToAdd ){
        props.addSelectedDataSetCollections(objectToAdd);
      }
    } else if (reason === "remove-option") {
      const removedName = details.option;
      const newObjectList = props.selectedDataSetCollections.filter((item) => {
        return item.name !== removedName;
      });
      props.setSelectedCollections(newObjectList);
      // console.log(newObjectList)
    }
  }

  return (
    <Grid
      classes={{ root: classes.root }}
      container
      direction="column"
      alignItems="center"
      justify="center"
    >
      {/* <Collection className={classes.illustration} /> */}
      <Tooltip title="Merge Selected Dataset Collections">
        <MergeTypeIcon color="primary" style={{transform: "rotate(180deg)", fontSize: "150px"}}></MergeTypeIcon>
      </Tooltip>
      <Box m={1.5} />
      <Typography component="h3" variant="h5">
        Merge Dataset Collections
      </Typography>
      <Box m={1.5} />
      <form
        className={classes.form}
        onSubmit={handleSubmit(onSubmit)}
        noValidate
      >
        <FormControl className={classes.formControl}>
          <TextField
            variant="outlined"
            size="small"
            margin="normal"
            required
            fullWidth
            id="name"
            label="Merged Collection Name"
            autoComplete="name"
            {...register("name", {
              required: `Collection Name is required`,
            })}
            error={Boolean(errors.name)}
            helperText={errors.name?.message}
            autoFocus
          />
        </FormControl>
        <FormControl className={classes.formControl}>
          <TextField
            variant="outlined"
            size="small"
            margin="normal"
            fullWidth
            id="description"
            label="Merged Collection Description"
            autoComplete="description"
            {...register("description", {
              required: `Collection description is required`,
            })}
            error={Boolean(errors.description)}
            helperText={errors.description?.message}
          />
        </FormControl>
        <Autocomplete
          multiple
          id="mergeDataSets"
          freeSolo
          // options={props.resourceListInCollection.map((item) => item.name)}
          options={totalCollections?.map((item) => item.name) || []}  
          value={props.selectedDataSetCollections.map((item) => item.name)}
          // value={totalCollections?.map((item) => item.name)}
          // loading={isGetDataSetCollectionUniqueNamesApiExecuting ? true : false}
          // loadingText={
          //   isGetDataSetCollectionUniqueNamesApiExecuting ? "loading" : ""
          // }
          onChange={(event, value, reason, details) => {
            handleAutoCompleteChange(event, value, reason, details);
          }}
          renderTags={(value, getTagProps) => {
            return value.map((option, index) => (
              <Chip
                variant="outlined"
                color="primary"
                className={`${classes.chip}`}
                classes={{ root: classes.autoCompleteChipRoot }}
                label={
                  <Tooltip title={option}>
                    <span>{option}</span>
                  </Tooltip>
                }
                {...getTagProps({ index })}
              />
            ));
          }}
          renderInput={(params) => (
            <TextField
              {...params}
              variant="outlined"
              size="small"
              margin="normal"
              fullWidth
              className={classes.floating}
              label="Training Dataset Collection"
              placeholder="Add Training Dataset Collection"
              onChange={(e) => {}}
              onBlur={(e) => {}}
              error={false}
              helperText={
                "Add multiple training dataset collections with <enter> or <comma>"
              }
            />
          )}
        />

        <Box m={1.5} />
        {addErrorMessage && (
          <Box style={{ marginBottom: 10, color: "red" }}>
            {addErrorMessage}
          </Box>
        )}
        <CustomButton type="submit" disabled={isAdding}>
          {isAdding ? <CircularProgress size={20} /> : "Add Collection"}
        </CustomButton>
        {/* <Separator width="50%" />
            <CustomButton size="small" disabled>Train Models</CustomButton> */}
      </form>
      <Box m={1.5} />
    </Grid>
  );
};

const mapProps = (state: IReduxState) => {
  return {
    // tags: state.tag.tags,
    // isTagsFetching: state.tag.isFetching,
    isAdding: state.collection.isAdding,
    isAddSuccess: state.collection.isAddSuccess,
    addErrorMessage: state.collection.addErrorMessage,
    resourceListInCollection: state.collection.collections.collections,
    selectedDataSetCollections: state.collection.dataSetCollectionsSelected,
  };
};

const connector = connect(mapProps, {
  // addCollection,
  // getTags,
  addCollectionPageInit,
  setSelectedCollections: setSelectedDataSetCollections,
  addSelectedDataSetCollections,
  mergeCollection
});

export type TPropsFromRedux = ConnectedProps<typeof connector>;

export default connector(MergeDataSetCollection);

import { Button, CircularProgress, IconButton } from "@material-ui/core";
import css from "./audioTranscriptionSection.module.css";
import EditIcon from "@material-ui/icons/Edit";
import {
  Transcript,
  WordMetadata,
} from "../../../models/features/model/features/data/components/AudioTrimUI/AudioTrimUI";

type MistakeMode = "showing" | "hiding";

type Props = {
  label: string;
  prediction: string;
  mode: "view" | "edit";
  editedText: string;
  onEditClick: () => any;
  OnSaveClick: () => any;
  OnCancelClick: () => any;
  isSaving: boolean;
  onEditedTextChange: (changedText: string) => any;
  mistakesMode: MistakeMode;
  onMistakeModeChange: (misTakeModeToSet: MistakeMode) => any;
  audioTranscriptionWordsMetadatas: WordMetadata[];
  audioCurrentTime: number;
  classes?: {
    root?: string;
    transcriptionSection?: string;
  };
  doNotShowEditButton?: boolean;
};

export function AudioTranscriptionSection(props: Props) {
  const rootClasses = (() => {
    let rootClasses = `${css.audioTranscriptionSectionRoot}`;
    if (props.classes?.root) {
      rootClasses += ` ${props.classes?.root}`;
    }
    return rootClasses;
  })();

  return (
    <div className={rootClasses}>
      <div className={css.headerRow}>
        <div className={css.sectionHeading}>Audio Transcription</div>
        {props.mode === "edit" && (
          <div className={css.buttonsContainer}>
            <Button
              variant="outlined"
              color="primary"
              onClick={props.OnCancelClick}
              size="small"
            >
              Cancel
            </Button>
            <Button
              variant="outlined"
              color="primary"
              onClick={props.OnSaveClick}
              size="small"
              disabled={props.label === props.editedText}
            >
              Save
              {props.isSaving && (
                <CircularProgress
                  style={{
                    width: 16,
                    height: 16,
                    color: "primary",
                    marginLeft: 10,
                  }}
                />
              )}
            </Button>
          </div>
        )}
        {props.mode === "view" && (
          <div className={css.buttonsContainer}>
            {props.prediction === "predicted" || !props.prediction ? (
              <></>
            ) : (
              <Button
                variant="outlined"
                color="primary"
                onClick={() => {
                  props.onMistakeModeChange(
                    props.mistakesMode === "showing" ? "hiding" : "showing"
                  );
                }}
                size="small"
              >
                {props.mistakesMode === "hiding"
                  ? "Show Mistakes"
                  : "Hide Mistakes"}
              </Button>
            )}
            {!props.doNotShowEditButton && (
              <IconButton
                color="primary"
                onClick={props.onEditClick}
                size="small"
              >
                <EditIcon />
              </IconButton>
            )}
          </div>
        )}
      </div>
      <div
        className={`${css.transcriptionSection} ${
          props.classes?.transcriptionSection
            ? props.classes?.transcriptionSection
            : ""
        }`}
      >
        <Transcript
          isEditMode={props.mode === "edit"}
          audioTranscriptionWordsMetadatas={
            props.audioTranscriptionWordsMetadatas
          }
          originalAudioCurrentTime={props.audioCurrentTime}
          audioTranscription={props.label}
          isHideMistakesEnabled={props.mistakesMode === "hiding" ? true : false}
          //   @ts-ignore
          setAudioTranscriptionInUI={props.onEditedTextChange}
          audioTranscriptionBeforeCorrection={
            props.prediction === "predicted" ? props.label : props.prediction
          }
          audioTranscriptionInUI={props.editedText}
        />
      </div>
    </div>
  );
}

export const createImage = (url) =>
  new Promise((resolve, reject) => {
    const image = new Image();
    image.addEventListener("load", () => resolve(image));
    image.addEventListener("error", (error) => reject(error));
    image.setAttribute("crossOrigin", "anonymous"); // needed to avoid cross-origin issues on CodeSandbox
    image.src = url;
    
  });

function getRadianAngle(degreeValue) {
  return (degreeValue * Math.PI) / 180;
}

/**
 * This function was adapted from the one in the ReadMe of https://github.com/DominicTobias/react-image-crop
 * @param {File} image - Image File url
 * @param {Object} pixelCrop - pixelCrop Object provided by react-easy-crop
 * @param {number} rotation - optional rotation parameter
 */
export async function createBlobImage(imageSrc){
  const image = await createImage(imageSrc);
  return image;
}
export function rotateSize(width, height, rotation) {
  const rotRad = getRadianAngle(rotation)

  return {
    width:
      Math.abs(Math.cos(rotRad) * width) + Math.abs(Math.sin(rotRad) * height),
    height:
      Math.abs(Math.sin(rotRad) * width) + Math.abs(Math.cos(rotRad) * height),
  }
}
export default async function getCroppedImg(
  imageSrc,
  pixelCrop,
  rotation = 0,
  source=true,
  flip = { horizontal: false, vertical: false }
) {
  let image = null;
  if(source)
    image = await createImage(imageSrc)
  else
    image = imageSrc
  const canvas = document.createElement('canvas')
  const ctx = canvas.getContext('2d')

  if (!ctx) {
    return null
  }

  const rotRad = getRadianAngle(rotation)

  // calculate bounding box of the rotated image
  const { width: bBoxWidth, height: bBoxHeight } = rotateSize(
    image.width,
    image.height,
    rotation
  )

  // set canvas size to match the bounding box
  canvas.width = bBoxWidth
  canvas.height = bBoxHeight

  // translate canvas context to a central location to allow rotating and flipping around the center
  ctx.translate(bBoxWidth / 2, bBoxHeight / 2)
  ctx.rotate(rotRad)
  ctx.scale(flip.horizontal ? -1 : 1, flip.vertical ? -1 : 1)
  ctx.translate(-image.width / 2, -image.height / 2)

  // draw rotated image
  ctx.drawImage(image, 0, 0)

  const croppedCanvas = document.createElement('canvas')

  const croppedCtx = croppedCanvas.getContext('2d')

  if (!croppedCtx) {
    return null
  }

  // Set the size of the cropped canvas
  croppedCanvas.width = pixelCrop.width
  croppedCanvas.height = pixelCrop.height

  // Draw the cropped image onto the new canvas
  croppedCtx.drawImage(
    canvas,
    pixelCrop.x,
    pixelCrop.y,
    pixelCrop.width,
    pixelCrop.height,
    0,
    0,
    pixelCrop.width,
    pixelCrop.height
  )

  // As Base64 string
  // return croppedCanvas.toDataURL('image/jpeg');

  // As a blob
  return new Promise((resolve, reject) => {
    croppedCanvas.toBlob((file) => {
      resolve(URL.createObjectURL(file))
    }, 'image/jpeg')
  })
}
export async function getUnCroppedRotatedImage(imageSrc,rotation = 0) {
  
  const image = await createImage(imageSrc)
  
  const canvas = document.createElement('canvas')
  const ctx = canvas.getContext('2d')

  if (!ctx) {
    return null
  }

  const rotRad = getRadianAngle(rotation)

  // calculate bounding box of the rotated image
  const { width: bBoxWidth, height: bBoxHeight } = rotateSize(
    image.width,
    image.height,
    rotation
  )

  // set canvas size to match the bounding box
  canvas.width = bBoxWidth
  canvas.height = bBoxHeight

  // translate canvas context to a central location to allow rotating and flipping around the center
  ctx.translate(bBoxWidth / 2, bBoxHeight / 2)
  ctx.rotate(rotRad)
  ctx.scale(1,1)
  // ctx.scale(flip.horizontal ? -1 : 1, flip.vertical ? -1 : 1)
  ctx.translate(-image.width / 2, -image.height / 2)

  // draw rotated image
  ctx.drawImage(image, 0, 0)

  
  // As a blob
  return new Promise((resolve, reject) => {
    canvas.toBlob((file) => {
      resolve(URL.createObjectURL(file))
    }, 'image/jpeg')
  })
}
export async function getHorizontalFlippedImage(imageSrc){
  const image = await createImage(imageSrc);
  const canvas = document.createElement('canvas');
  const canvasContext = canvas.getContext('2d');

  canvas.width = image.width;
  canvas.height = image.height;

  canvasContext.translate(canvas.width, 0);
  canvasContext.scale(-1, 1);
  canvasContext.drawImage(image, 0, 0,image.width,image.height);
  canvasContext.setTransform(1,0,0,1,0,0)
  return new Promise((resolve) => {
    canvas.toBlob((file) => {
      console.log(file);
      resolve(URL.createObjectURL(file));
    }, "image/jpeg");
  });
}
export async function getVerticalFlippedImage(imageSrc){
  const image = await createImage(imageSrc);
  const canvas = document.createElement('canvas');
  const canvasContext = canvas.getContext('2d');

  canvas.width = image.width;
  canvas.height = image.height;

  canvasContext.translate(0, canvas.height);
  canvasContext.scale(1, -1);
  canvasContext.drawImage(image, 0, 0,image.width,image.height);
  canvasContext.setTransform(1,0,0,1,0,0)
  return new Promise((resolve) => {
    canvas.toBlob((file) => {
      console.log(file);
      resolve(URL.createObjectURL(file));
    }, "image/jpeg");
  });
}
export function calcScale(id,imageId){
  const {clientWidth:frameWidth,clientHeight:frameHeight} = document.getElementById(id);
  const {naturalWidth,naturalHeight} = document.getElementById(imageId);
  
  let imageWidth = 0;
  let imageHeight = 0;

  
  if(naturalWidth > naturalHeight){
    if(naturalWidth > frameWidth){
      imageWidth = frameWidth;
      imageHeight = (imageWidth / naturalWidth) * naturalHeight;
    }
    else if(naturalHeight > frameHeight){
      imageHeight = frameHeight
      imageWidth = (imageHeight / naturalHeight) * naturalWidth;
    }
    else{
      if(frameHeight < frameWidth){
        imageHeight = frameHeight;
        imageWidth = (imageHeight / naturalHeight) * naturalWidth;
      }
      else{
        imageWidth = frameWidth;
        imageHeight = (imageWidth / naturalWidth) * naturalHeight;
      }
    }
  }
  else{
    if(naturalHeight > frameHeight){
      imageHeight = frameHeight;
      imageWidth = (imageHeight / naturalHeight) * naturalWidth;
    }
    else if(naturalWidth > frameWidth){
      imageWidth = frameWidth;
      imageHeight = (imageWidth / naturalWidth) * naturalHeight;
    }
    else{
      if(frameHeight < frameWidth){
        imageHeight = frameHeight;
        imageWidth = (imageHeight / naturalHeight) * naturalWidth;
      }
      else{
        imageWidth = frameWidth;
        imageHeight = (imageWidth / naturalWidth) * naturalHeight;
      }
    }
  }


  // if(naturalWidth > naturalHeight){
  //   imageWidth = naturalWidth > frameWidth ? frameWidth : naturalWidth;
  //   imageHeight = (imageWidth / naturalWidth) * naturalHeight;
  // }
  // else{
  //   imageHeight = frameHeight;
  //   imageWidth = (imageHeight / naturalHeight) * naturalWidth;
  // }
  const hypo = Math.sqrt((imageWidth * imageWidth) + (imageHeight * imageHeight))
  const min = frameWidth < frameHeight ? frameWidth : frameHeight;
  if(hypo > min)
    return hypo / min;
  else
    return 1
}
import React,{ useCallback, useEffect, useRef, useState} from 'react';
import Paper from '@material-ui/core/Paper';
import { makeStyles, createStyles, withStyles, Theme } from '@material-ui/core/styles';
// import Input from '@material-ui/core/Input';
import Autocomplete from '@material-ui/lab/Autocomplete';
import TextField from '@material-ui/core/TextField';
import Table from '@material-ui/core/Table';
import TableHead from '@material-ui/core/TableHead';
import TableBody from '@material-ui/core/TableBody';
import MuiTableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableRow from '@material-ui/core/TableRow';
// import { useHistory, useLocation } from "react-router";
import { CustomSkeleton, IModelAnalyticsTestDataSet, IModelAnalyticsTestStatisticalData, IModelAnalyticsTrainDataSet } from '../../../../../../../../../../../../common';
import Button from '@material-ui/core/Button';
// import Chip from '@material-ui/core/Chip';
// import { Label } from 'reactstrap';
import { DataService } from '../../../../../../../../../../../../services';
import DropdownWithAccuracyComponent from './DropdownWithAccuracyComponent';
import SearchIcon from '@material-ui/icons/Search';
import Tooltip from '@material-ui/core/Tooltip';
import { useResizeObserver } from '../../../../../../../../../../../../hooks/useResizeObserver';




export type dataSetSeletedType = "Test-Data-Set" | "Train-Data-Set"
const TableCell = withStyles({
    root: {
      borderBottom: "none"
    }
  })(MuiTableCell);
const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      flexGrow: 1,
    },
    paper: {
      height: 140,
      width: 100,
    },
    control: {
      padding: theme.spacing(2),
    },
    testDataSet:{
        width: "100%",
        height: "100%"
     //   height: "743px"
    },
    tableCell:{
        padding: "8px 12px",
        // font: "normal normal 11px/15px Nunito Sans",
        color:"#121212",
        '@media (min-width:1500px)' : {
          font: "normal normal 13px/15px Nunito Sans",
        },
        '@media (max-width:1500px) and (min-width:1000px)' : {
          font: "normal normal 13px/15px Nunito Sans",
        },
        '@media (max-width:1000px)' : {
          font: "normal normal 12px/15px Nunito Sans",
        },
    },
    tableCellClasses:{
      padding: "8px 12px",
      // font: "normal normal 11px/15px Nunito Sans",
      color:"#121212",
      '@media (min-width:1500px)' : {
        font: "normal normal 13px/15px Nunito Sans",
      },
      '@media (max-width:1500px) and (min-width:1000px)' : {
        font: "normal normal 13px/15px Nunito Sans",
      },
      '@media (max-width:1000px)' : {
        font: "normal normal 12px/15px Nunito Sans",
      },
    },
    tableCellFontClasses:{
      '@media (min-width:1500px)' : {
        font: "normal normal 13px/15px Nunito Sans",
      },
      '@media (max-width:1500px) and (min-width:1000px)' : {
        font: "normal normal 13px/15px Nunito Sans",
      },
      '@media (max-width:1000px)' : {
        font: "normal normal 12px/15px Nunito Sans",
      },
    },
    tableCellTotalDataSets:{
        padding: "8px 12px",
        // font: "normal normal 11px/15px Nunito Sans",
        color:"#121212",
        '@media (min-width:1500px)' : {
          font: "normal normal 13px/15px Nunito Sans",
        },
        '@media (max-width:1500px) and (min-width:1000px)' : {
          font: "normal normal 13px/15px Nunito Sans",
        },
        '@media (max-width:1000px)' : {
          font: "normal normal 12px/15px Nunito Sans",
        },
    },
    buttons:{
      textAlign: "center",
      // font: "normal normal bold 11px/15px Nunito Sans",
      opacity: 1,
      '@media (min-width:1500px)' : {
        font: "normal normal bold 13px/15px Nunito Sans",
      },
      '@media (max-width:1500px) and (min-width:1000px)' : {
        font: "normal normal bold 13px/15px Nunito Sans",
      },
      '@media (max-width:1000px)' : {
        font: "normal normal bold 12px/15px Nunito Sans",
      },
    },
    autocomplete: {
      width: "100%",
    margin: "0 auto 25px auto",
    '& .MuiAutocomplete-popupIndicatorOpen':{
      transform: "rotate(0deg)"
    },
    '& .MuiAutocomplete-popupIndicator':{
      transform: "rotate(0deg)",
      // ariaLabel: "Search",
      // title: 'Search'
    }
    },
    tableContainer: {
    //   width:"90%",
    // margin: "0 auto",
    maxHeight: "calc(100% - 76px)",
    position: "relative"
    },
    w50p: {
      width: '50%'
    },
    labelClasses:{
      width: '45%'
    },
    labelTotalDataSets:{
      width: '55%'
    },
    pl12: {
      paddingLeft: "12px"
    },
    pl16: {
      paddingRight: "16px"
    },
    gray: {
      color: "#707070"
    },
    input : {
      fontSize: "11px",
      textAlign: "left",
      // font: "normal normal normal 11px/15px Nunito Sans",
      letterSpacing:"0.22px",
      color: "#707070",
      textTransform: "uppercase",
      opacity: 1,
      '@media (min-width:1500px)' : {
        font: "normal normal normal 14px/15px Nunito Sans",
      },
      '@media (max-width:1500px) and (min-width:1000px)' : {
        font: "normal normal normal 13px/15px Nunito Sans",
      },
      '@media (max-width:1000px)' : {
        font: "normal normal normal 12px/15px Nunito Sans",
      },
    },
    modelsFont:{
      '@media (min-width:1500px)' : {
        font: "normal normal normal 16px/15px Nunito Sans",
            },
      '@media (max-width:1500px) and (min-width:1000px)' : {
        font: "normal normal normal 15px/15px Nunito Sans",
      },
      '@media (max-width:1000px)' : {
        font: "normal normal normal 14px/15px Nunito Sans",
      },
    },
    dataDisplayingComponent : {
      padding : "0px 25px",
      height: "calc(100% - 54px)"
    },
    modelContainer: {
      display: 'flex',
      justifyContent: 'space-between',
      textAlign: 'center',
      marginTop: '10px',
      marginBottom: '15px',
    },
    modelFilter: {
      marginTop: '-20px',
      width: '70%'
    },
    w100p: {
      width:  '100%'
    },
    flexColumn: {
      flexDirection: 'column'
    },
    searchDatasetLabel: {
      '@media (min-width:1500px)' : {
        font: "normal normal normal 15px/15px Nunito Sans",
      },
      '@media (max-width:1500px) and (min-width:1000px)' : {
        font: "normal normal normal 14px/15px Nunito Sans",
      },
      '@media (max-width:1000px)' : {
        font: "normal normal normal 13px/15px Nunito Sans",
      },
    },
  }),
);

function TestDataSet(props: { isSegmentationModelAnalytics: boolean, modelAnalyticsTestDataSet: IModelAnalyticsTestDataSet[], modelAnalyticsTopPerformingModel: IModelAnalyticsTestStatisticalData[], isFetchingModelAnalyticsTestDataSet:boolean, isFetchingModelAnalyticsTestStatisticalData:boolean, 
  iouThreshold: number,
  confidenceScoreThreshold?: number}) {
  // const mockModels: IModelAnalyticsTestStatisticalData[] = [
  //   { version: '2.3', status: 'Live', integrityFrequency: 56.8, integrityFrequencyAccuracy: { value: 78, type: 'isLive' }, testAccuracy: { value: 89.7, type: 'greaterThanLive' } },

  //   { version: '4.7', status: 'Queued', integrityFrequency: 76.3, integrityFrequencyAccuracy: { value: 89, type: 'greaterThanLive' }, testAccuracy: { value: 75.9, type: 'lessThanLive' } },

  //   { version: '5.6', status: 'Trained', integrityFrequency: 83.3, integrityFrequencyAccuracy: { value: 9, type: 'lessThanLive' }, testAccuracy: { value: 5.9, type: 'lessThanLive' } }
  // ];
  /**
   * Can be removed after proper replacement of this variable usage
   * @TODO Priyanshu will remove the comment
   */
  const [selectedModel, setSelectedModel] = useState<IModelAnalyticsTestStatisticalData | undefined>(undefined);
  const [selectedModelIndex, setSelectedModelIndex] = useState<Number>(0);
  const [modelOptions, setModelOptions] = useState<Array<IModelAnalyticsTestStatisticalData>>([])

    const classes = useStyles();
    const [isFetchingModelAnalyticsTrainDataSet, setIsFetchingModelAnalyticsTrainDataSet] = useState<boolean>(false);
    const [modelAnalyticsTrainDataSet, setModelAnalyticsTrainDataSet] = useState<IModelAnalyticsTrainDataSet[]>([]);
    // const [isModelTrainDataSetPresent, setIsModelTrainDataSetPresent] = useState<boolean>(false);
    // const [modelTrainDataSetVersion, setModelTrainDataSetVersion] = useState<string>("")
  const [dataSetSelected, setDataSetSelected] = useState<dataSetSeletedType>("Test-Data-Set");
  const modelContainerRef = useRef(null);
  const [modelContianerwidth, modelContainerheight] = useResizeObserver(modelContainerRef);
  const [showInColumn, setShowInColumn] = useState(false);

    const [updatedModelAnalyticsTestdataSet, setUpdatedModelAnalyticsTestdataSet] = useState<IModelAnalyticsTestDataSet[]>([]);
    const fetchModelAnalyticsTrainDataSet = useCallback(
      (modelIdsToFetch : string = ""): Promise<void> => {
          return new Promise<void>(async(resolve, reject) => {
              const query = new URLSearchParams(window.location.search);
              const modelId = query.get("model") || "";
              if(modelIdsToFetch && modelIdsToFetch !== "")
              {
                try {
                    setIsFetchingModelAnalyticsTrainDataSet(true);
                    const apiResponseForTestDataSet = await DataService.getModelAnanlyticsTrainDataSet({
                        modelId : modelId,
                        modelCollectionId  : modelIdsToFetch
                    })
                    setModelAnalyticsTrainDataSet(oldState => 
                      { const data  = [...oldState, {
                        modelCollectionId: modelIdsToFetch, 
                        labels: apiResponseForTestDataSet.data}]
                        for(let dataSet of data){
                          if(dataSet.modelCollectionId === modelIdsToFetch){
                            setUpdatedModelAnalyticsTestdataSet(dataSet.labels)
                          }
                        }
                        return data})
                    
                    setIsFetchingModelAnalyticsTrainDataSet(false);
                } catch (error) {
                  (console.log(error))
                  setIsFetchingModelAnalyticsTrainDataSet(false)}
              }
          });
      }, [window.location.search]);

  useEffect(() => {
    console.log("checking useEffet")
    if(props.modelAnalyticsTopPerformingModel.length > 0){
      console.log(" Element is getting update")
      setSelectedModel(props.modelAnalyticsTopPerformingModel[0])
      setSelectedModelIndex(0);
      const checkIfLiveDataAvailable = props.modelAnalyticsTopPerformingModel.find(model => model.status === 'Live');
      let updatedData = props.modelAnalyticsTopPerformingModel;
      if (!checkIfLiveDataAvailable) {
        let highestAccuracy = props.modelAnalyticsTopPerformingModel[0].testAccuracy?.value;
        props.modelAnalyticsTopPerformingModel.forEach(model => {
          highestAccuracy = model?.testAccuracy?.value > highestAccuracy ? model?.testAccuracy?.value : highestAccuracy;
        })
        updatedData = props.modelAnalyticsTopPerformingModel.map(model => {
          return model?.testAccuracy?.value !== highestAccuracy ? { ...model, testAccuracy: { ...model?.testAccuracy, type: 'lessThanLive' } } : { ...model, testAccuracy: { ...model?.testAccuracy, type: 'greaterThanLive' } }
        })
      }
      setModelOptions([...updatedData])
    }
    console.log("checking selected model ", selectedModel);
  }, [props.modelAnalyticsTopPerformingModel])
    useEffect(() => {
      setUpdatedModelAnalyticsTestdataSet(props.modelAnalyticsTestDataSet);
    }, [props.modelAnalyticsTestDataSet])
    const selectTestDataSet = () => {
      setDataSetSelected("Test-Data-Set");
      setUpdatedModelAnalyticsTestdataSet(props.modelAnalyticsTestDataSet);
    }
    const selectTrainDataSet = () => {
      setDataSetSelected("Train-Data-Set")
      trainDataSetDropdownChange(props.modelAnalyticsTopPerformingModel[0])
      setSelectedModelIndex(0)
    }
  
  /**
   * Handle Model Version change here
   * @TODO Priyanshu will remove the comment
   */
  // const selectedModelChange = (value?: any) => {
  //   modelAnalyticsTrainDataSet.map((modelVersion, index) => {
  //     return (
  //       modelVersion.modelVersion === value?.version &&
  //       (setIsModelTrainDataSetPresent(true))
  //     )
  //   })
  //   modelAnalyticsTrainDataSet.map((modelVersion, index) => {
  //     return (
  //       modelVersion.modelVersion === value?.version &&
  //       (setModelTrainDataSetVersion(value.version))
  //     )
  //   })
  //   setSelectedModel(mockModels.filter(model => model.version === value.version)[0] || mockModels[0])
  // }

    const trainDataSetDropdownChange = useCallback((modelCollection?: IModelAnalyticsTestStatisticalData) => {
      if(modelAnalyticsTrainDataSet.length === 0 && modelCollection && modelCollection.modelCollectionId){
        fetchModelAnalyticsTrainDataSet(modelCollection.modelCollectionId)
        setSelectedModel(modelCollection)
        // return
      }
      let isModelTrainDataSetPresent = false;
      for(let dataSet of modelAnalyticsTrainDataSet){
        if(modelCollection && dataSet.modelCollectionId === modelCollection.modelCollectionId){
          isModelTrainDataSetPresent = true;
          setUpdatedModelAnalyticsTestdataSet(dataSet.labels)
        }
      }
      if(!isModelTrainDataSetPresent && modelCollection && modelCollection.modelCollectionId){
        fetchModelAnalyticsTrainDataSet(modelCollection.modelCollectionId)
      }
      if(modelCollection){
        setSelectedModel(modelCollection)
      }
    }, [modelAnalyticsTrainDataSet])
  
  useEffect(() => {
    if (modelContianerwidth && modelContianerwidth <= 222 && modelContainerRef && !showInColumn) {
      setShowInColumn(true)
    }
    if (modelContianerwidth && modelContianerwidth > 222 && modelContainerRef && showInColumn) {
      setShowInColumn(false)
    }
    }, [modelContianerwidth])
  
  return (
        <Paper className={`${classes.testDataSet} test-data-set-container`}>
            <div style={{display: 'flex', justifyContent: 'space-between', height: '47px', padding:"0 12px 0 12px"}}>
            <Button style={{marginBottom: "0px", color: dataSetSelected === 'Test-Data-Set' ? "#008D9C" : "grey", outline: "none"}} className={classes.buttons} onClick={selectTestDataSet}>Test Data Set</Button>
            <Button style={{marginBottom: "0px", color: dataSetSelected === 'Train-Data-Set' ? "#008D9C" : "grey", outline: "none"}} className={classes.buttons} onClick={selectTrainDataSet}>Train Data Set</Button>
            </div>
            <hr style={{margin:"0px 0px 4px", borderTop:"1px solid #D4E8EA"}}/>
      <div ref={modelContainerRef} className={classes.dataDisplayingComponent}>
            <div>
              {
                dataSetSelected === "Test-Data-Set" &&
                <Autocomplete
                  className={`${classes.autocomplete}`}
                  classes={{input: classes.input}}
                  id="test-data-set"
                  options={props.modelAnalyticsTestDataSet}
                  getOptionLabel={(option) => option.label}
                  popupIcon={
                  <Tooltip title='Search'><SearchIcon /></Tooltip>}
                  // style={{ width: 300 }}
                  renderInput={(params) => <TextField {...params} classes={{root : classes.searchDatasetLabel}} label="SEARCH DATASET" variant="standard" />}
                  onChange ={ (event, value) => {
                    (value !== null && value !== undefined ) ? 
                    props.modelAnalyticsTestDataSet.map((label, index) => {
                      console.log(label.label, value.label)
                      return (
                        label.label === value?.label && 
                        setUpdatedModelAnalyticsTestdataSet([label])
                      )
                    })
                    :
                    setUpdatedModelAnalyticsTestdataSet(props.modelAnalyticsTestDataSet)
                  }}
                />
              }
              {
                dataSetSelected === "Train-Data-Set" &&
            <div className={`${classes.modelContainer} ${showInColumn ? classes.flexColumn : ''}`}>
                  <div className ={classes.modelsFont} style={{display: 'flex', alignSelf : 'center', color: "#707070"}}>
                    Models
              </div>
                <div className={`${classes.modelFilter} ${showInColumn ? classes.w100p : ''}`} style={{width: showInColumn ? '100%' : '70%'}}>
              <DropdownWithAccuracyComponent isSegmentationModelAnalytics={props.isSegmentationModelAnalytics} modelsList={modelOptions} selectedModel={modelOptions.filter(option => option.modelCollectionId === selectedModel?.modelCollectionId)[0] || selectedModel} updateSelectedModel={trainDataSetDropdownChange} 
                iouThreshold={props.iouThreshold}
                confidenceScoreThreshold={props.confidenceScoreThreshold}
              />
              </div>
                  {/* <Autocomplete
                    id="test-data-set"
                    options={props.modelAnalyticsTopPerformingModel}
                    getOptionLabel={(option : IModelAnalyticsTestStatisticalData) => option?.version}
                    renderOption={(option: IModelAnalyticsTestStatisticalData) => (
                      <li><div style={{display: 'flex' , justifyContent: 'space-evenly'}}>
                          <div style={{paddingRight: '15px'}}>
                            {option.version}
                          </div>
                            <Chip 
                            key={option?.version}
                            label= {option?.testAccuracy?.value}
                            variant= "outlined"                                     
                            style={{width: "80px", backgroundColor: "primary"}} />
                              
                        </div>
                      </li>
                    )}
                    onChange = {(event, value) => trainDataSetDropdownChange(value?.version)}
                    style={{ width: 150 }}
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        variant="standard"
                        size="small"
                        margin="normal"
                        fullWidth
                        // className={classes.floating}
                        // label="Compare Model Collections"
                        // placeholder="Add Model Collections"
                        onChange={(e) => {}}
                        onBlur={(e) => {}}
                        error={false}
                      />
                    )}
                    renderTags={(value, getTagProps) => {
                      return value.map((option, index) => (
                        <div style={{display: 'flex' , justifyContent: 'space-between'}}>
                          <div>
                            {option.version}
                          </div>
                            <Chip 
                            key={option?.version}
                            label= {option?.testAccuracy?.value}
                            variant= "outlined"                                     
                            style={{width: "80px", backgroundColor: "primary"}} 
                            {...getTagProps({ index })}
                            />
                      </div>
                      ));
                    }}
                  /> */}
                </div>
              }
            </div>
            <TableContainer className={classes.tableContainer}>
                <Table>
                    <TableHead  style={{backgroundColor: "white", position: "sticky", top: "0"}}>
                        <TableRow>
                            <TableCell className={`${classes.tableCell} ${classes.labelClasses} ${classes.gray}`} style={{textAlign: "left", paddingLeft: '2px'}}>
                                <div style={{display: 'flex', justifyContent: 'left'}}>
                                CLASSES
                                </div>
                            </TableCell>
                            <TableCell className={`${classes.tableCell} ${classes.labelTotalDataSets} ${classes.gray}`} style={{textAlign: "left", paddingRight: '2px'}}>
                                <div style={{display: 'flex', justifyContent: 'right'}}>
                                {
                                  props.isSegmentationModelAnalytics?
                                  "TOTAL ANNOTATIONS":"TOTAL DATASETS"
                                }
                                </div> 
                               
                            </TableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                      {
                              (isFetchingModelAnalyticsTrainDataSet || props.isFetchingModelAnalyticsTestDataSet) &&
                              new Array(8).fill("").map((item, index) => (
                                <TableRow>
                                <TableCell>
                                  <CustomSkeleton height={20} />
                                </TableCell>
                                <TableCell>
                                  <CustomSkeleton height={20} />
                                </TableCell>
                                </TableRow>
                              ))
                      }
                      {
                        (!isFetchingModelAnalyticsTrainDataSet && !props.isFetchingModelAnalyticsTestDataSet) &&
                        updatedModelAnalyticsTestdataSet?.map((row, index) => {
                          return (
                            <TableRow key={row.label}>
                              <TableCell className={`${classes.tableCellClasses} ${classes.pl12}`} style={{paddingLeft: "16px", textAlign:'left'}}>
                                  <Tooltip title={row?.label || ""}>
                                    <div style={{textAlign: 'left', whiteSpace: "nowrap", textOverflow: "ellipsis"}} className={classes.tableCellFontClasses}>
                                    {row?.label}
                                    </div>
                                  </Tooltip>
                              </TableCell>
                              <TableCell className={`${classes.tableCellTotalDataSets} ${classes.pl16}`} style={{paddingRight: " 16px", display: 'flex', justifyContent:'flex-end', textAlign:'left'}}>
                                <Tooltip title={row?.totalLabelCount || ""}>
                                  <div style={{textAlign:'left', whiteSpace: "nowrap", overflow: "hidden", textOverflow: "ellipsis", width: '3.5rem'}}>
                                  {row?.totalLabelCount}
                                  </div>
                                </Tooltip>
                              </TableCell>
                            </TableRow>
                        )})
                      }
                    </TableBody>
                </Table>
            </TableContainer>
            </div>
        </Paper>
  )
}
export default TestDataSet
/**
 * @description
 * This component is used to show an image in max width and height by using parent element width and height while maintaining its aspect ratio
 * so that it must not look cropped.
 */

import { useEffect, useRef, useState } from "react";
import useImageOnLoad from "../../hooks/useImageOnLoad";
import useForceReRender from "../../hooks/useForceReRender";
import { getMaxImageWidthHeightWhichWeCanSet } from "../../services/imageHelperService";

type Props = {
  imageUrl: string;
  imageElementAltText?: string;
  style?: React.CSSProperties | undefined;
};

export default function ImageAspectRatioScalerViaParent(props: Props) {
  const imageUrl = props.imageUrl;

  const [imageWidthToSet, setImageWidthToSet] = useState("100%");
  const [imageHeightToSet, setImageHeightToSet] = useState("100%");

  useEffect(() => {
    setImageWidthToSet("100%");
    setImageHeightToSet("100%");
  }, [imageUrl]);

  const forceRerender = useForceReRender();

  const imageElementRef = useRef<HTMLImageElement>();

  const { image, imageLoadStatus } = useImageOnLoad(imageUrl);

  const imageOriginalWidth = image?.width;
  const imageOriginalHeight = image?.height;

  const imageParentElement: HTMLElement | undefined | null =
    imageElementRef.current?.parentElement;

  const imageParentElementRef = useRef(imageParentElement);
  imageParentElementRef.current = imageParentElement;

  useEffect(() => {
    const resizeObserver = new ResizeObserver((entries) => {
      window.requestAnimationFrame((): void | undefined => {
        if (!Array.isArray(entries) || !entries.length) {
          return;
        }
        forceRerender();
      });
    });

    if (imageParentElement) {
      resizeObserver.observe(imageParentElement);
    }

    return () => {
      resizeObserver.disconnect();
    };
  }, [forceRerender, imageParentElement]);

  const imageParentElementOffsetWidth = imageParentElement?.offsetWidth;
  const imageParentElementOffsetHeight = imageParentElement?.offsetHeight;

  useEffect(() => {
    function resize() {
      forceRerender();
    }
    window.addEventListener("resize", resize);
    return () => {
      window.removeEventListener("resize", resize);
    };
  }, [forceRerender]);

  useEffect(() => {
    const executeFunction = () => {
      const { imageWidthToSet, imageHeightToSet } =
        getMaxImageWidthHeightWhichWeCanSet({
          maxImageHeightAllowed:
            imageParentElementRef.current?.offsetHeight || 0,
          maxImageWidthAllowed: imageParentElementRef.current?.offsetWidth || 0,
          originalImageHeight: imageOriginalHeight || 0,
          originalImageWidth: imageOriginalWidth || 0,
        });

      setImageHeightToSet(`${imageHeightToSet}px`);
      setImageWidthToSet(`${imageWidthToSet}px`);
    };
    if (imageLoadStatus === "loadingCompleted") {
      executeFunction();
    }
  }, [
    imageLoadStatus,
    imageOriginalHeight,
    imageOriginalWidth,
    imageParentElementOffsetWidth,
    imageParentElementOffsetHeight,
  ]);

  return (
    <img
      src={imageUrl}
      // @ts-ignore
      ref={imageElementRef}
      alt={props.imageElementAltText || "Image"}
      style={{
        width: imageWidthToSet,
        height: imageHeightToSet,
        ...(props.style || {})
      }}
    />
  );
}

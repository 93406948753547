import { ReactElement, useContext, useState } from "react";
import { ISessionCreationProgress, ITrainingPodSessionConfiguration, ITrainingPodVM, IVmHardwareSessionStatus, TrainingPodTestSessionStatus } from "../../../../../../../common";
import TrainingPodTestingSessionContext from "./trainingPodTestingSession.context";
import PurposeModelService from "../../../../../../../services/PurposeModelService";
import { AlertContext } from "../../../../../../../common/contexts/alertContext/alertContext";
import { IPurposeModel } from "../../../../../../../common/constants/interfaces/purposeModels";

export default function TrainingPodTestingSessionContextComponent(props: { children: ReactElement }) {
    const alertContext = useContext(AlertContext);
    const setCommonAlert = alertContext.setAlert;
    const [trainingPodTestSession, setTrainingPodTestSession] = useState<ITrainingPodSessionConfiguration|null>(null)
    const [disableStopSession, setDisableStopSession] = useState<boolean>(false);
    const [currentSessionStatus, setCurrentSessionStatus] = useState<ISessionCreationProgress []> ([]);
    const [vmHardwareSessionStatus, setVmHardwareSessionStatus] = useState<IVmHardwareSessionStatus | null>(null);
    const [isProcessingRequest, setIsProcessingRequest] = useState<boolean>(false);

    const handleStartTestSession = (
        podData: ITrainingPodSessionConfiguration,
        purposeModelId: string,
        testSessionHardwareGcpType?: "Custom GCP" | "RLEF GCP",
        serviceFile?: File | null
    ) => {
        try {
            if(!podData.type) {
                throw Error("Test VM Type is required");
            }
            const reqBodyFormData = new FormData();
            if(testSessionHardwareGcpType) {
                reqBodyFormData.append("testSessionHardwareGcpType", testSessionHardwareGcpType);
            }

            if(podData.DISPLAY_NAME) {
                reqBodyFormData.append("displayName", podData.DISPLAY_NAME)
            }

            reqBodyFormData.append("vm_config", podData.type);
            if(serviceFile) {
                reqBodyFormData.append("serviceFile", serviceFile);
            }
            setIsProcessingRequest(true)

            PurposeModelService.startTestSession(
                purposeModelId,
                reqBodyFormData
            )
            setTrainingPodTestSession(podData);
        } catch (error) {
            console.error("Error in handleStartTestSession: ", error)
            setCommonAlert(`Error while starting Test Session`)
        }
    }

    const handleEndTestSession = async (purposeModelId: string) => {
        try {
            PurposeModelService.stopTestSession(purposeModelId);
            setIsProcessingRequest(true)
        } catch (error) {
            console.error("Error in handleEndTestSession: ", error)
            setCommonAlert(`Error while stopping Test Session`)
        }
    }

    const getPurposeModel = async (purposeModelId: string) => {
        try {
            const response = await PurposeModelService.getPurposeModelData(
                purposeModelId, false
            )

            setDisableStopSession(response.dataMapping.rlefToModelTestLoadingState ||
                response.dataMapping.trainingModelOnEpocTestLoadingState ||
                response.dataMapping.convertingDataFromModelFormatToTestingAnalyticsTestLoadingState
            )

        } catch (error) {
            console.error("Error in getPurposeModel: ", error)
            setCommonAlert(`Error while fetching Training Pod Data`)
        }
    }

    const setTrainPodSessionDataUsingPurposeModelData = (purposeModel: IPurposeModel) => {
        if(purposeModel?.vmDetails?.sessionState && purposeModel.vmDetails?.vm_info?.vmHardwareConfig?.presetConfigType) {
            setVmHardwareSessionStatus({
                machineType: purposeModel?.vmDetails?.sessionState?.machineType ||"",
                type: purposeModel.vmDetails?.vm_info?.vmHardwareConfig?.presetConfigType,
                buttonTypeAvailable: purposeModel?.vmDetails?.sessionState?.buttonTypeAvailable || "",
                loadingTextToShow: purposeModel?.vmDetails?.sessionState?.loadingTextToShow || "",
            })
            setDisableStopSession(purposeModel?.dataMapping?.rlefToModelTestLoadingState ||
                purposeModel?.dataMapping?.trainingModelOnEpocTestLoadingState ||
                purposeModel?.dataMapping?.convertingDataFromModelFormatToTestingAnalyticsTestLoadingState || false)
            if(purposeModel.vmDetails?.vm_info?.vmHardwareConfig?.presetConfigType && purposeModel?.vmDetails?.sessionState?.machineType) {
                setTrainingPodTestSession({
                    type: purposeModel.vmDetails?.vm_info?.vmHardwareConfig?.presetConfigType.toString(),
                    DISPLAY_NAME: purposeModel?.vmDetails?.sessionState?.machineType.toString()
                })    
            }
        }
    }

    return (
        <TrainingPodTestingSessionContext.Provider value={{isProcessingRequest, setIsProcessingRequest, vmHardwareSessionStatus, setVmHardwareSessionStatus, trainingPodTestSession, setTrainingPodTestSession, handleStartTestSession, handleEndTestSession, disableStopSession, setDisableStopSession, getPurposeModel, currentSessionStatus, setCurrentSessionStatus, setTrainPodSessionDataUsingPurposeModelData}}>
            {props.children}
        </TrainingPodTestingSessionContext.Provider>
    )
}

export const useTrainingPodTestSessionContext = () => {
    const context = useContext(TrainingPodTestingSessionContext);
    if (!context) {
      throw new Error('useTrainingPod must be used within a TrainingPodTestingSessionContext');
    }
    return context;
  };
  
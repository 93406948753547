import React, { useState } from "react";
import Button from "@material-ui/core/Button";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogContentText from "@material-ui/core/DialogContentText";
import DialogTitle from "@material-ui/core/DialogTitle";
import useMediaQuery from "@material-ui/core/useMediaQuery";
import { useTheme } from "@material-ui/core/styles";
import {
  IData,
  IModel,
  ResourceImageGroupAnnotation,
} from "../../../../../../../../../../../../common";
import css from "./copyImageInMultipleImageAnnotationToImageAnnotationGroup.module.css";
import { CircularProgress, MenuItem, TextField } from "@material-ui/core";
import { DataService } from "../../../../../../../../../../../../services";
import { generateUniqueId } from "../../../../../../../../../../../../services/idHelperService";

type Props = {
  resource: IData | undefined;
  imageIndexInMultipleImageAnnotationResource: number;
  projectModels: IModel[];
  onClose: () => any;
};

export default function CopyImageInMultipleImageAnnotationToImageAnnotationGroupDialog(
  props: Props
) {
  const theme = useTheme();
  const fullScreen = useMediaQuery(theme.breakpoints.down("sm"));

  const handleClose = () => {
    props.onClose();
  };

  const fileNameOfResource =
    props.resource?.files?.[props.imageIndexInMultipleImageAnnotationResource]
      ?.filename;
  const fileUrlOfResource =
    props.resource?.files?.[props.imageIndexInMultipleImageAnnotationResource]
      ?.fileUrl;

  const [resourceStatusToSet, setResourceStatusToSet] = useState("approval");
  const [resourceTagToSet, setResourceTagToSet] = useState(props.resource?.tag);
  const [resourceConfidenceScoreToSet, setResourceConfidenceScoreToSet] =
    useState(String(props.resource?.confidence_score));
  const [resourceLabelToSet, setResourceLabelToSet] = useState(
    props.resource?.label
  );
  const [modelIdToSendIn, setModelIdToSendIn] = useState("");
  const [resourceFileNameToSet, setResourceFileNameToSet] =
    useState(fileNameOfResource);
  const [resourceCsvToSet, setResourceCsvToSet] = useState(props.resource?.csv);

  const imageAnnotationGroupModels: IModel[] = props.projectModels?.filter(
    (model) => model.type === "imageAnnotationGroup"
  );

  const [isSendingDataToAnotherModel, setIsSendingDataToAnotherModel] =
    useState(false);

  const handleSend = async () => {
    setIsSendingDataToAnotherModel(true);
    try {
      const bodyFormData = new FormData();
      bodyFormData.append("model", modelIdToSendIn || "");
      bodyFormData.append("status", resourceStatusToSet);
      if (fileUrlOfResource && fileUrlOfResource?.length > 0) {
        const response = await fetch(fileUrlOfResource || "");
        const blob = await response.blob();
        const file = new File([blob], `${resourceFileNameToSet}`, {
          type: blob.type,
        });
        bodyFormData.append("resource", file);
      }
      bodyFormData.append(
        "confidence_score",
        resourceConfidenceScoreToSet.toString()
      );
      bodyFormData.append("prediction", "predicted");
      bodyFormData.append("label", resourceLabelToSet || "");
      bodyFormData.append("tag", resourceTagToSet || "");

      bodyFormData.append("csv", resourceCsvToSet || " ");

      const imageGroupAnnotations: ResourceImageGroupAnnotation[] = (() => {
        if (
          !props.resource?.files?.[
            props.imageIndexInMultipleImageAnnotationResource
          ]?.imageAnnotations ||
          !props.resource?.files?.[
            props.imageIndexInMultipleImageAnnotationResource
          ]?.imageAnnotations?.length
        ) {
          return [];
        }

        const imageGroupAnnotation: ResourceImageGroupAnnotation = {
          id: generateUniqueId(),
          name: "",
          imageAnnotations:
            props.resource?.files?.[
              props.imageIndexInMultipleImageAnnotationResource
            ]?.imageAnnotations,
        };

        return [imageGroupAnnotation];
      })();

      if (imageGroupAnnotations?.length > 0) {
        bodyFormData.append(
          "imageGroupAnnotations",
          JSON.stringify(imageGroupAnnotations)
        );
      }

      await DataService.addResourceInModel(bodyFormData);
      props.onClose();
    } catch (error) {
      setIsSendingDataToAnotherModel(false);
      // @ts-ignore
      alert(error.response.data || error.message);
    }
    setIsSendingDataToAnotherModel(false);
  };

  return (
    <div>
      <Dialog fullScreen={fullScreen} open={true} onClose={handleClose}>
        <DialogTitle>
          {"Send Image Data to Image Annotation Group Task"}
        </DialogTitle>
        <DialogContent>
          <div className={css.form}>
            <TextField
              type="text"
              value={resourceFileNameToSet}
              label={`File Name`}
              onChange={(e) => {
                setResourceFileNameToSet(e.target.value);
              }}
            />
            <TextField
              type="text"
              value={resourceLabelToSet}
              label={`Label`}
              onChange={(e) => {
                setResourceLabelToSet(e.target.value);
              }}
            />
            <TextField
              type="text"
              value={resourceCsvToSet}
              label={`Metadata`}
              onChange={(e) => {
                setResourceCsvToSet(e.target.value);
              }}
            />
            <TextField
              type="number"
              value={resourceConfidenceScoreToSet}
              label={`Confidence Score`}
              onChange={(e) => {
                setResourceConfidenceScoreToSet(e.target.value);
              }}
            />
            <TextField
              type="text"
              value={resourceTagToSet}
              label={`Tag`}
              onChange={(e) => {
                setResourceTagToSet(e.target.value);
              }}
            />
            <TextField
              type="select"
              select
              value={resourceStatusToSet}
              label={`Status`}
              onChange={(e) => {
                setResourceStatusToSet(e.target.value);
              }}
            >
              <MenuItem value={"backlog"}>Backlog</MenuItem>
              <MenuItem value={"approval"}>Approval</MenuItem>
              <MenuItem value={"approved"}>Approved</MenuItem>
              <MenuItem value={"rejected"}>Rejected</MenuItem>
              <MenuItem value={"deleted"}>Deleted</MenuItem>
            </TextField>
            <TextField
              type="select"
              select
              value={modelIdToSendIn}
              label={`Task To Send In`}
              onChange={(e) => {
                setModelIdToSendIn(e.target.value);
              }}
            >
              {imageAnnotationGroupModels.map((model) => {
                return (
                  <MenuItem key={model._id} value={model._id}>
                    {model.name}
                  </MenuItem>
                );
              })}
            </TextField>
          </div>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose} color="secondary">
            Cancel
          </Button>
          <Button
            onClick={handleSend}
            color="primary"
            disabled={isSendingDataToAnotherModel}
          >
            {isSendingDataToAnotherModel ? (
              <CircularProgress color="primary" size={24} />
            ) : (
              "Send"
            )}
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
}

import { Tooltip } from "@material-ui/core";
import { MouseEventHandler } from "react";
import { reTrainDataSetCollectionButtonTooltip } from "../constants/constants";
import reTrainIcon from "../../../../../../../../../../../assets/images/7901138.png";

type ITooltip = string;

type Props = {
  onClick: MouseEventHandler<any>;
  tooltip?: ITooltip;
  isSelected?: boolean;
  isDefaultDataSetCollectionExist : boolean;
};

export default function ReTrainDataSetCollectionButton(props: Props) {
  const onClick = props.onClick;
  const isSelected = props.isSelected || false;
  let tooltip: ITooltip =
    props.tooltip || reTrainDataSetCollectionButtonTooltip;

  return (
    <Tooltip title={ props.isDefaultDataSetCollectionExist ? tooltip : 'Default dataset collection does not exist to re-train the collection'}>
      {/* <IconButton
        aria-label="export"
        onClick={onClick}
      >

      </IconButton> */}
      <img
        alt=""
        style={{
          width: 48,
          padding: 12,
          cursor: props.isDefaultDataSetCollectionExist ? 'pointer' : 'default', 
          opacity: isSelected ? 0.2 : !props.isDefaultDataSetCollectionExist ? 0.2 :1
        }}
        src={reTrainIcon}
        onClick={props.isDefaultDataSetCollectionExist ? onClick : undefined}
      />
      {/* <Button
        onClick={onClick}
        size="small"
        variant={isSelected ? "contained" : "outlined"}
        color="primary"
      >
        Train
      </Button> */}
    </Tooltip>
  );
}

import {
  Chip,
  Dialog,
  IconButton,
  Theme,
  Tooltip,
  withStyles,
} from "@material-ui/core";
import { Cancel } from "@material-ui/icons";
import { makeStyles } from "@material-ui/styles";
import React, { useEffect, useRef, useState } from "react";
import { connect, ConnectedProps } from "react-redux";
import { CounterAction, IData, ImageList, IReduxState, ResourceStatus } from "../../../../../../../../../../../common";
// import ColorSelector from "../../../../../../coPilot/features/components/ColorSelector";
// import SetApprovalStatusButton from "./SetApprovalStatusButton";
// import SetRejectedStatusButton from "./SetRejectedStatusButton";
// import KeyboardArrowRightIcon from "@material-ui/icons/KeyboardArrowRight";
// import KeyboardArrowLeftIcon from "@material-ui/icons/KeyboardArrowLeft";
import { ItemsCounter } from "./ItemsCounter/itemsCounter";
// import { useState } from "react";
import NavigateBeforeIcon from '@material-ui/icons/NavigateBefore';
import NavigateNextIcon from '@material-ui/icons/NavigateNext';
import { ShowResourceDetails } from "./ImageKeyPointsEditDialog";
import ImageAspectRatioScaler from "../../../../../../../../../../../common/components/ImageAspectRatioScaler";
import PlayArrowRoundedIcon from '@material-ui/icons/PlayArrowRounded';
import SkipNextRoundedIcon from '@material-ui/icons/SkipNextRounded';
import SkipPreviousRoundedIcon from '@material-ui/icons/SkipPreviousRounded';
import PauseRoundedIcon from '@material-ui/icons/PauseRounded';
import Slider from '@material-ui/core/Slider';
import Divider from '@material-ui/core/Divider';
import LabelEditText from "./LabelEditText";
import {ReactComponent as MenuExitIcon} from "../../../../../../../../../../../assets/svg/log-out-svgrepo-com.svg"
import {ReactComponent as MenuWorkOrderItemIcon} from "../../../../../../../../../../../assets/svg/work-order-item-svgrepo-com.svg"
import {ReactComponent as MenuPathIcon} from "../../../../../../../../../../../assets/svg/Path 15225.svg"
import Popover from '@material-ui/core/Popover';
import CheckIcon from '@material-ui/icons/Check';
import { VideoIterator } from "./VideoIterator";
import { MultipleVideoView } from "./MultipleVideoView";
import { isNullOrUndefined } from "../../../../../../../../../../../services/variableHelperService";
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemText from '@material-ui/core/ListItemText';
import SlowMotionVideoIcon from '@material-ui/icons/SlowMotionVideo';
import { useDispatch } from "react-redux";
import { updateItemsAddCount, updateItemsDiscardedCount, updateItemsSoldCount, updateResourceInUI } from "../../../../../../../../../../../store/actions";
import { AiAssistantMsgWithInfoIcon } from "./VideoAnnotationDialogBox.component";
import { LABEL_GROUP_RESOURCE_LEVEL, LABEL_GROUP_TASK_LEVEL } from "../constants/constants";

const useStyles = makeStyles((theme: Theme)=>({
  root: {
    overflow: "auto",
    padding: "20px 0px",
    "&p": {
      padding: "0px",
    },
  },
  body: {
    padding: "10px",
    width: "100%",
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
  },
  content: {
    // marginRight: "20px",
    marginLeft: "20px",
    // display: "grid",
    // gridAutoFlow: "column",
    display: "flex",
    flexWrap: "wrap",
    justifyContent: "center",
    placeItems: "center",
    gap: "40px",
  },
  videoWrapper: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    width: "550px",
    height: "400px",
    placeItems: "center",
    position: 'relative'
  },
  sideBar: {
    width: "400px",
  },
  sideDrawer: {},
  topMenu: {
    display: "flex",
    justifyContent:'space-between',
    width: "360px",
  },
  topBarLabel: {
    marginLeft: "20px",
    width: "200px",
    marginTop: "25px"
  },
  delete: {
    color: "#EF8A8A",
  },
  middleDetails: {
    width: "100%",
    paddingTop: "30px",
  },
  sideBarItem: {
    display: "flex",
    justifyContent: "space-between",
  },
  footer:{
    // marginTop: "25px",
    display: "flex",
    justifyContent: "space-between",
  },
  counterContainer:{
    textAlign: "center"
  },
  counterTitle:{
    color: "#98A6B3",
    fontSize: "15px",
    marginBottom: "20px",
    // font: "normal normal normal 16px/22px Nunito Sans",
    // color: "#98A6B3",
  },
  compareLink:{
    textDecoration: "underline", color:"#008D9C",
    cursor:"pointer"
  },
  counterStats:{
    display: "flex", gap: "25px", flexBasis: "515px",
    justifyContent: "end",
    paddingLeft: "5px,"
  },
  closeBtn: {
    position: "absolute",
    right: -10,
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    // padding: "2px",
    top: -10,
    backgroundColor: "white",
    borderRadius: "50%",
    // color: "white",
    cursor: "pointer",
    width: "25px",
    height: "25px"
  },
  dialogContainer: {
    overflow: 'visible'
  },
  videoBtns:{
    display: "flex",
    gap: "25px",
    alignItems: "end",
    justifyContent: "center",
    marginBottom: "2px",
    position: "relative",
    left:"100px"
  },
  slider:{
    margin: "5px 0",
    width: "350px",
    marginTop: "auto",
    position: "relative"
    // justifyContent: "center",
  },
  itemCountMenuRoot:{
    display:'flex',
    justifyContent:'space-between',
    // alignItems:'center',
    width:'550px',
    margin:'auto',
    padding:'0px 20px',
    color:'white',
    fontSize:'14px',
    transform:'translateY(-40px)',
    height: "73px",
    background: "transparent linear-gradient(180deg, #00000000 0%, #000000BF 100%) 0% 0% no-repeat padding-box",
    borderRadius: "10px",
    marginTop: "-33px",
  },
  navigateButton:{
    padding: "0px",
    "&:hover":{
      outline: "none",
    },
    "&:focus":{
      outline: "none",
    },
    "&:active":{
      outline: "none",
    },
  },
  videoMenuText:{
    margin:0,
    // fontWeight:'bolder',
    font: "normal normal normal 14px/19px Nunito Sans",
    display: "flex",
    alignItems: "end",
    gap:"10px",
    whiteSpace: "nowrap",
    cursor: "pointer",
  },
  videoInput:{
    maxWidth: "40px",
    background: "transparent",
    border: "1px solid white",
    borderRadius: "4px",
    color: "white",
    outlineWidth: "0",
    textAlign: "center",
  },
  editCountInput:{
    position:"relative",
    top:"4px",
    "& input::-webkit-outer-spin-button":{
      "-webkit-appearance": "none",
      margin: 0,
    },
    "& input::-webkit-inner-spin-button":{
      "-webkit-appearance": "none",
      margin: 0,
    },
    "& input[type=number]": {
      "-moz-appearance": "textfield"
    }
  },
  compareBackButton:{
    display:'flex',
    justifyContent:'end'
  },
  resourceDetailsChip :{
    width: "60px", 
    borderRadius: "5px", 
    background: 'white' , 
    border: "1px solid #707070 ",
    font: "normal normal 600 16px/22px Nunito Sans",
  },
  warmerName:{
    color: "#000000"
  },
  endTime:{
    color: "#98A6B3",
    position: "absolute",
    transform: "translateX(20px)",
    whiteSpace: "nowrap"

  },
  startTime:{
    position: "absolute",
    transform: "translateX(-150%)",
    color: "#98A6B3",
  },
  videoLoader:{
    position: "absolute",
    background: "#d3d3d3f0",
    height: "95%",
    width: "100%",
    zIndex: 1,
    borderRadius: "8px",
    marginTop: "10px"
  },
  videoLoaderText: {
    display: "flex",
    height: "100%",
    justifyContent: "center",
    alignItems: "center",
    fontSize: "24px",
    color: "#000000"
  },
  playbackSpeedCustomBtns: {
    border: '3px solid #008D9C',
    borderRadius: '50%',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    borderStyle: 'solid solid dashed dashed',
    transform: 'rotateZ(45deg)',
    "& span": {
      transform: 'rotateZ(-45deg)'
    }
  },
  popOver: {
    "& .MuiPaper-root": {
      background: "rgb(61 59 59 / 70%)",
      color: "#ffff"
    },
    "& .MuiListItem-button:hover": {
      background: "rgb(0 0 0 / 19%)",
      color: "#ffff"
    },
  }
}));
export interface VideoResource {
  videoSrc: string,
  warmerName: string,
  discardCount: number,
  addCount: number,
  soldCount: number,
  _id: string
}
interface IProps extends TPropsFromRedux {
  open: boolean;
  handleClose: () => void;
  resource: IData;
  onMoveToRight: () => void;
  onMoveToLeft: () => void;
  setShouldBlockDataTableKeyboardEvents : Function;
  onApproveButtonClicked:()=>void;
  onRejectButtonClicked:()=>void;
  onDeleteButtonClicked:()=>void;
  ActionButtonArray:any;
  resourceImageList: ImageList[];
  LabelsAvailable : string[];
  allowUserToSetAnyLabel: boolean;
}


const ItemCountMenu =(props:{discarded:number,sold:number,added:number, resource: IData, videoResource: VideoResource, videoPlayOrPauseHandler: Function, videoAlreadyPlaying: boolean})=>{
  const classes = useStyles();
  const dispatch = useDispatch();

  const {discarded,sold,added}=props
  const [currentEditText, setCurrentEditText] = useState("");
  const [discaredInput, setDiscaredInput] = useState(props.discarded < 0 ? 0 : props.discarded);
  const [soldInput, setSoldInput] = useState(props.sold < 0 ? 0 : props.sold);
  const [addInput, setAddInput] = useState(props.added < 0 ? 0 : props.added);

  const onCountEdit = (event:any, type:string) => {
    if(currentEditText === type){
      return
    }
    setCurrentEditText(type)
  }
  useEffect(()=>{
    setCurrentEditText("");
   
  },[props.resource]);

  useEffect(()=>{
    setCurrentEditText("")
    setDiscaredInput(props.discarded < 0 ? 0 : props.discarded);
    setSoldInput(props.sold < 0 ? 0 : props.sold);
    setAddInput(props.added < 0 ? 0 : props.added);
  },[props.discarded, props.sold, props.added]);
  
  const saveDiscaredInput = () => {
    const video = document.getElementById("popupVideo") as HTMLVideoElement;
    if(props.videoAlreadyPlaying){
      props.videoPlayOrPauseHandler(true);
    }
    if(props.discarded === discaredInput || (props.discarded === -1 && discaredInput === 0)){
      setCurrentEditText("")
      return
    }
    dispatch(updateItemsDiscardedCount({ resourceId: props.resource._id, count: (discaredInput > 0) ? discaredInput : -1, videoId: props.videoResource._id , videoDurationInSeconds: video.currentTime}))
  }
  const saveSoldInput = () => {
    const video = document.getElementById("popupVideo") as HTMLVideoElement;
    if(props.videoAlreadyPlaying){
      props.videoPlayOrPauseHandler(true);
    }
    if(props.sold === soldInput || (props.sold === -1 && soldInput === 0)){
      setCurrentEditText("")
      return
    }
    dispatch(updateItemsSoldCount({ resourceId: props.resource._id, count: (soldInput > 0) ? soldInput : -1, videoId: props.videoResource._id , videoDurationInSeconds: video.currentTime}))
  }
  const saveAddInput = () => {
    const video = document.getElementById("popupVideo") as HTMLVideoElement;
    if(props.videoAlreadyPlaying){
      props.videoPlayOrPauseHandler(true);
    }
    if(props.added === addInput || (props.added === -1 && addInput === 0)){
      setCurrentEditText("")
      return
    }
    dispatch(updateItemsAddCount({ resourceId: props.resource._id, count: (addInput > 0) ? addInput : -1, videoId: props.videoResource._id, videoDurationInSeconds: video.currentTime }))
  }
  const onInputFocus = () =>{
    if(props.videoAlreadyPlaying){
      props.videoPlayOrPauseHandler(false)
    }
  }

  return <div className={classes.itemCountMenuRoot} >
    <div style={{width: '150px', display: 'flex', alignItems: 'flex-end', justifyContent: 'flex-start'}}>
    <div style={{display:'flex',justifyContent:'center', height: "30px",alignItems:'end', paddingBottom: "15px"}}>
      <MenuExitIcon style={{marginRight:'7px'}}/>
      <GreenTooltip title={"Tap to Edit"}>
        <div className={classes.videoMenuText} onClick={(e) => onCountEdit(e, CounterAction.DISCARDED)} >
          <span>Discarded -  </span>
          {currentEditText !== CounterAction.DISCARDED && <div>{discarded<0?"NA":discarded}</div>}
          {currentEditText === CounterAction.DISCARDED && <div className={classes.editCountInput}><input min={0} autoFocus type={"number"} onFocus={onInputFocus} className={classes.videoInput} onBlur={()=>{saveDiscaredInput()}} value={discaredInput.toString()} onChange={(e) => setDiscaredInput(Number(e.target.value))}
            onKeyPress={(e)=>{
              if (e?.key === "Enter") {
                saveDiscaredInput()
              }
            }}
          /></div>}
        </div>
      </GreenTooltip>
    </div>
    </div>
    <div  style={{width: '100px', display: 'flex', alignItems: 'flex-end', justifyContent:'space-between'}}>
    <div style={{display:'flex',justifyContent:'center', height: "30px",alignItems:'end', paddingBottom: "15px"}}>
      <MenuPathIcon style={{marginRight:'7px'}}/>
      <GreenTooltip title={"Tap to Edit"}>
        <div className={classes.videoMenuText} onClick={(e) => onCountEdit(e, CounterAction.SOLD)} >
          <span>Sold -  </span>
          {currentEditText !== CounterAction.SOLD && <div>{sold<0?"NA":sold}</div>}
          {currentEditText === CounterAction.SOLD && <div className={classes.editCountInput}><input min={0} autoFocus type={"number"} onFocus={onInputFocus} className={classes.videoInput} onBlur={()=>{saveSoldInput()}} value={soldInput.toString()} onChange={(e) => setSoldInput(Number(e.target.value))}
            onKeyPress={(e)=>{
              if (e?.key === "Enter") {
                saveSoldInput()
              }
            }}          
          /></div>}
        </div>
      </GreenTooltip>
    </div>
    </div>
    <div  style={{width: '120px', display: 'flex', alignItems: 'flex-end', justifyContent:'space-between'}}>
    <div  style={{display:'flex',justifyContent:'center', height: "30px",alignItems:'end', paddingBottom: "15px"}}>
      <MenuWorkOrderItemIcon style={{marginRight:'7px'}}/>
      <GreenTooltip title={"Tap to Edit"}>
        <div className={classes.videoMenuText} onClick={(e) => onCountEdit(e, CounterAction.ADDED)} >
          <span>Added -  </span>
          {currentEditText !== CounterAction.ADDED && <div>{added<0?"NA":added}</div>}
          {currentEditText === CounterAction.ADDED && <div className={classes.editCountInput}><input  autoFocus type={"number"} onFocus={onInputFocus} className={classes.videoInput} onBlur={()=>{saveAddInput()}} value={addInput.toString()} onChange={(e) => setAddInput(Number(e.target.value))}
            onKeyPress={(e)=>{
              if (e?.key === "Enter") {
                saveAddInput()
              }
            }}          
          /></div>}
        </div>
      </GreenTooltip>
    </div>
    </div>
  </div>
}
ItemCountMenu.defaultProps = {
  discarded: -1,
  sold: -1,
  added: -1
}

const GreenTooltip = withStyles({
  tooltip: {
    backgroundColor: "#CCE8EB",
    color: "#000000"
  }
})(Tooltip);

const ViewForcastModelPopup = (props: IProps) => {
  const classes = useStyles();
  const { open, handleClose, resource, onMoveToLeft, onMoveToRight,resourceImageList, setShouldBlockDataTableKeyboardEvents,onApproveButtonClicked,onRejectButtonClicked, onDeleteButtonClicked ,ActionButtonArray, LabelsAvailable, allowUserToSetAnyLabel } = props;
  const [isCompare, setIsCompare] = useState(false)
  const [resourceReferImage, setResourceReferImage] = useState('')
  const [play, setPlay] = useState(false);
  const [sliderValue, setSliderValue] = useState(0);
  const timeOutRef = useRef<any>()
  const videoPlayingRef = useRef<any>()
  const [selectedSubTab, setSelectedSubTab] = useState<ResourceStatus | string>("");
  const [isVideoPlayed, setIsVideoPlayed] = useState(false);
  const [totalSold, setTotalSold] = useState(-1)
  const [totalDiscarded, setTotalDiscarded] = useState(-1)
  const [totalAdded, setTotalAdded] = useState(-1)

  const [resourceList, setResourceList] = useState<VideoResource[]>([])
  const [currentVideoIndex, setCurrentVideoIndex] = useState(1);
  const [endDuration, setEndDuration] = useState("")
  const [startDuration, setStartDuration] = useState("00 Sec")
  const [showLoader, setShowLoader] = useState(false);
  const [videoLoaded, setVideoLoaded] = useState(false);
  const [canLoaderTurnedOff, setCanLoaderTurnedOff] = useState(false);
  const [anchorEl, setAnchorEl] = useState(null);
  const [playBackSpeed, setPlayBackSpeed] = useState("1x");
  const currentResourceRef = useRef('');

  const updateResourceInUI = props.updateResourceInUI
  const mergeResources = () => {
    const resources = [{
      videoSrc: resource?.resource,
      warmerName: resource?.warmerName || "",
      discardCount: isNullOrUndefined(resource?.itemsDiscardedCount) ? -1 : (resource?.itemsDiscardedCount || 0),
      addCount: isNullOrUndefined(resource?.itemsAddCount) ? -1 : (resource?.itemsAddCount || 0),
      soldCount: isNullOrUndefined(resource?.itemsSoldCount) ? -1 : (resource?.itemsSoldCount || 0),
      _id: resource?._id
    }]
    const additionalResources =resource?.additionalResources?.map(res => ({
      videoSrc: res?.resource,
      warmerName: res?.warmerName || "",
      discardCount: isNullOrUndefined(res?.itemsDiscardedCount) ? -1 : (res?.itemsDiscardedCount || 0),
      addCount: isNullOrUndefined(res?.itemsAddCount) ? -1 : (res?.itemsAddCount || 0),
      soldCount: isNullOrUndefined(res?.itemsSoldCount) ? -1 : (res?.itemsSoldCount || 0),
      _id: res._id
    }))
    if(additionalResources){
      resources.push(...additionalResources)
    }
    return resources
  }
  const handlePopupClick = (event: any) => {
    setAnchorEl(event.currentTarget);
  };

  const handlePopupClose = () => {
    setAnchorEl(null);
  };
  const popupOpen = Boolean(anchorEl);

  useEffect(() => {
    const selectedResourceImage = resourceImageList.filter(resourceImage => resource?.label === resourceImage.name);
    setResourceReferImage(selectedResourceImage[0]?.resourceUrl || '');
   }, [resource, resourceImageList, resource?.label])

   useEffect(() => {
    if(!open){
      currentResourceRef.current = ""
      return
    }
    let resourceChanged = false;
    if(!currentResourceRef.current || (currentResourceRef.current && currentResourceRef.current !== resource?._id)){
      currentResourceRef.current = resource?._id;
      resourceChanged = true;
    }
    const resources = mergeResources()
    setResourceList(resources)
    if(resourceChanged && resource?.resource && open){
      if(resource.additionalResources.length > 0){
        for(let additionalResource of resource.additionalResources){
          loadVideos(additionalResource.resource)
        }
      }
      if(resources.length >0 && resources[0]?.videoSrc){
        setEndDuration("--")
        setStartDuration("00 Sec")
        calculateVideoDuration(resources[0]?.videoSrc)
      }
    }
  }, [open, resource])

   useEffect(() => {
    const query = new URLSearchParams(window.location.search);
    const status = query?.get('status') || 'backlog';
    setSelectedSubTab(status)
   }, [])

  useEffect(() => {
      if(videoLoaded && canLoaderTurnedOff){
        setShowLoader(false)
        setVideoLoaded(false)
        setCanLoaderTurnedOff(false)
        setTimeout(()=>playOrPause(true), 1000)
      }
  },[videoLoaded, canLoaderTurnedOff])

  useEffect(()=>{
    if(playBackSpeed){
      playOrPause(true);
    }
  }, [playBackSpeed])

  const calculateVideoDuration =  (videoSrc: string, autoPlay= false) => {
    const video = document.createElement('video');
    video.preload = 'auto';
    if(autoPlay){
      setShowLoader(true)
      setTimeout(()=>{
        setCanLoaderTurnedOff(true)
      }, 2000)
    }
    video.onloadedmetadata = function() {
      window.URL.revokeObjectURL(video.src);
      const videoDuration = Math.ceil(video?.duration || 0);
      const secondString = videoDuration < 2 ? " Sec" : " Secs"
      setEndDuration((videoDuration < 10 ? `0${videoDuration}` : videoDuration ) + secondString)
      if(autoPlay){
        setVideoLoaded(true)
      }
    }
    video.src = videoSrc;
  }

  const loadVideos = (videoSrc: string) => {
    const video = document.createElement('video');
    video.preload = 'auto';
    video.src = videoSrc;
  }

  useEffect(() => {
    let totalSoldCountValueToSet = 0;
    let shouldSetSoldCountAsNA = true;
    
    let totalDiscardedCountValueToSet = 0;
    let shouldSetDiscardedCountAsNA = true;
    
    let totalAddedCountValueToSet = 0;
    let shouldSetAddedCountAsNA = true;
    
    if (resourceList?.length>0) {
      for (const resource of resourceList) {
        if (resource.soldCount>-1) {
          shouldSetSoldCountAsNA = false;
        }
        if (resource.soldCount>0) {
          totalSoldCountValueToSet += resource.soldCount
        }
        if (resource.discardCount>-1) {
          shouldSetDiscardedCountAsNA = false;
        }
        if (resource.discardCount>0) {
          totalDiscardedCountValueToSet += resource.discardCount
        }
        if (resource.addCount>-1) {
          shouldSetAddedCountAsNA = false;
        }
        if (resource.addCount>0) {
          totalAddedCountValueToSet += resource.addCount
        }
      }
    }

    if (shouldSetSoldCountAsNA) {
      setTotalSold(-1);
    } else {
      setTotalSold(totalSoldCountValueToSet);
    }

    if (shouldSetDiscardedCountAsNA) {
      setTotalDiscarded(-1);
    } else {
      setTotalDiscarded(totalDiscardedCountValueToSet);
    }

    if (shouldSetAddedCountAsNA) {
      setTotalAdded(-1);
    } else {
      setTotalAdded(totalAddedCountValueToSet);
    }

  }, [resourceList])
  

  const onClose = ()=> {
    handleClose();
    setIsCompare(false);
    setPlay(false)
    clearInterval(videoPlayingRef.current)
    setSliderValue(0)
    setCurrentVideoIndex(1)
    setEndDuration("--")
    setStartDuration("00 Sec")
  }

  const playOrPause = (shouldPlay: boolean | null) => {
    clearInterval(videoPlayingRef.current)
    const video = document.getElementById("popupVideo") as HTMLVideoElement;
    if(video && shouldPlay === null){
      video.playbackRate = Number(playBackSpeed.split('x')[0]);
      if(!play){
        video.play();
        setIsVideoPlayed(true)
        videoPlayingRef.current = activateSlider(video)
      }
      else{
        video.pause()
        setIsVideoPlayed(false)
      }
      setPlay(!play);
    } else if (video) {
      if ((shouldPlay && play) || (shouldPlay && !play)) {
        video.playbackRate = Number(playBackSpeed.split('x')[0]);
        video.play();
        videoPlayingRef.current = activateSlider(video)
        setPlay(true);
      } else {
        if(play){
          video.pause();
          setPlay(false);
        }
      }
    }
  }

  const activateSlider = (videoElement: HTMLVideoElement) => {
   return setInterval(() => {
      setSliderPosition(videoElement)
    })
  }

  const setSliderPosition = (videoElement: HTMLVideoElement) => {
    const videoDuration = videoElement.duration;
    const currentTime = videoElement.currentTime;
    const percentage = (currentTime/videoDuration) * 100
    const startVideoDuration = Math.ceil(videoElement.currentTime || 0);
    const secondString = startVideoDuration < 2 ? " Sec" : " Secs"
    setStartDuration((startVideoDuration < 10 ? `0${startVideoDuration}` : startVideoDuration ) + secondString)
    setSliderValue(percentage)
  }

  const goForward = () => {
    const video = document.getElementById("popupVideo") as HTMLVideoElement;
    if(video){
      video.currentTime+=2
      if(!play){
        setSliderPosition(video)
      }
    }
  }

  const goBack = () => {
    const video = document.getElementById("popupVideo") as HTMLVideoElement;
    if(video){
      video.currentTime-=2
      if(!play){
        setSliderPosition(video)
      }
    }
  }
  const arrowClicked = (direction: string) => {
    if(direction === "left"){
      onMoveToLeft()
    }
    else{
      onMoveToRight()
    }
    setPlay(false)
    clearInterval(videoPlayingRef.current)
    setSliderValue(0)
    setCurrentVideoIndex(1)
  }

  const videoEnded = () => {
    setPlay(false);
    clearInterval(videoPlayingRef.current)
    if(currentVideoIndex < resourceList.length){
      videoChanged(currentVideoIndex + 1, true);
    }
  }

  const handleSliderChange = (e: any, newValue: any) => {
    clearTimeout(timeOutRef.current)
    clearInterval(videoPlayingRef.current)
    setSliderValue(newValue)
    timeOutRef.current = setTimeout(() => {
      const video = document.getElementById("popupVideo") as HTMLVideoElement;
      if(video){
        const videoDuration = video.duration;
        if(!isNaN(videoDuration * (newValue / 100))){
          video.currentTime = videoDuration * (newValue / 100)
        }
        videoPlayingRef.current = activateSlider(video)
      }
    }, 200)
  }

  const videoChanged = (currentIndex: number, autoPlay = false) => {
    setCurrentVideoIndex(currentIndex);
    setPlay(false);
    setSliderValue(0)
    clearInterval(videoPlayingRef.current)
    calculateVideoDuration(resourceList[currentIndex - 1].videoSrc, autoPlay)
    setStartDuration("00 Sec")
    setEndDuration("--")
  }
  const popupContent = (
    <div style={{minWidth: "100px", position: "relative"}}>
      <List>
      <ListItem>Playback Speed</ListItem>
      <Divider style={{background:"#ffff"}}/>
        {[0.5, 0.75, 1, 1.25, 1.5, 1.75,  2].map((elem) =>
          <ListItem button style={{textAlign: "center", padding: "4px"}} onClick={()=>{
            setPlayBackSpeed(elem + "x");
            handlePopupClose();
            }}>
              {(Number(playBackSpeed.split("x")[0]) === elem) &&
                <CheckIcon style={{ fontSize: 18, position: "absolute", left: "20px"}}/>
              }
              <ListItemText primary={elem === 1 ? "Normal" : elem} />
          </ListItem>
        )
        }
      </List>
    </div>
  )

  const labelOptions = () => {
    const labelsAvailable = [
        ...(resource.labelOptions?.map(labelObj => {
            return {
                label: labelObj.labelOption,
                group: LABEL_GROUP_RESOURCE_LEVEL,
            }
        }) || []
        ),
        ...(LabelsAvailable?.map(label => { return {label, group: LABEL_GROUP_TASK_LEVEL} }) || []),
    ]
  
    return labelsAvailable
  }

  return (
    <Dialog onClose={onClose} open={open} maxWidth="lg" fullWidth={false} classes={{ paperScrollPaper: classes.dialogContainer }}>
      <div className={classes.root}>
        <div
          className={classes.closeBtn}
          onClick={() => {
            onClose();
          }}
        >
          {/* <CloseIcon /> */}
          <Cancel style={{ fontSize: "40px", padding: "4px" }} />
        </div>

        <AiAssistantMsgWithInfoIcon
          aiAssistantLastCalledAt={(props?.resource as IData)?.aiAssistantLastCalledDetails?.calledAt ? true : false}
          mt='34px'
          ml='80px'
        />

        {/* body of the dialog  */}
        <div className={classes.body}>
          {/* left arrow  */}
          <IconButton onClick={() => arrowClicked("left")} className={classes.navigateButton}>
            <NavigateBeforeIcon style={{color:"black",  fontSize:"xxx-large"}} />
          </IconButton>
          {/* content  */}
          <div>
            <div className={classes.content}>
              {/* video  */}
              {resourceList[currentVideoIndex-1] && 
                <div style={{ height: 'inherit', marginTop: '-15px'}}>
                  <div className={classes.warmerName}>{resourceList[currentVideoIndex-1].warmerName}</div>
                  <div id="videoWrapper" className={classes.videoWrapper}>
                    {resourceList.length > 1 &&
                      <div style={{ height: 'inherit' , width:"100%"}}>
                      <MultipleVideoView videoList={resourceList} currentItem={currentVideoIndex} currentVideoPlayValue={sliderValue}/>
                      </div>
                    }
                    <video
                      id="popupVideo"
                      // style={{ width: "100%", height: "90%" ,borderRadius:'10px'}}
                      style={{ width: "550px", minHeight: '380px', height: "380px", borderRadius: '10px', background: '#000',marginTop:'10px' }}
                      src={resourceList[currentVideoIndex -1].videoSrc}
                      onEnded={videoEnded}
                      preload="auto"
                    />
                    {showLoader && 
                      <div className={classes.videoLoader}>
                        <div className={classes.videoLoaderText}>Loading {resourceList[currentVideoIndex-1].warmerName} ...</div>
                      </div>
                    }
                    <div style={{height:"12px"}}>
                    <ItemCountMenu videoResource={resourceList[currentVideoIndex-1]} resource={props.resource} discarded={resourceList[currentVideoIndex-1].discardCount} sold={resourceList[currentVideoIndex-1].soldCount} added={resourceList[currentVideoIndex-1].addCount} videoPlayOrPauseHandler={playOrPause} videoAlreadyPlaying={isVideoPlayed}/>
                    </div>
                    <div className={classes.slider}>
                    <div style={{pointerEvents: endDuration === "--" ? "none" : "all"}}>
                      <span className={classes.startTime}>{startDuration}</span>
                      <Slider value={sliderValue} onChange={handleSliderChange}/>
                      <span className={classes.endTime}>{endDuration}</span>
                    </div>
                      <VideoIterator totalItem={resourceList.length} currentItem={currentVideoIndex} changeVideo={videoChanged}/>
                    </div>
                  </div>
                </div>
              }
              {/* Side Detail Page  */}
              <div className={classes.sideBar}>
                {/* Top Action Items */}
                <div className={classes.topMenu}>
                  {/* left side image in top menu  */}
                  {resourceReferImage === '' && 
                  <div style={{marginLeft: '-5px', marginTop: '8px'}}>
                  <img
                    height={"118px"}
                    width={"118px"}
                    style={{ borderRadius: '10px' }}
                    alt="referral_image"
                  /></div>}
                  {
                    resourceReferImage !== '' && 
                    <div style={{marginLeft: '-10px', marginTop: '8px'}}>
                    <ImageAspectRatioScaler
                      maxImageWidth={118}
                      maxImageHeight={118}
                      imagePlotterSuffix='ViewForecastModelPopup'
                      imageUrl={resourceReferImage}
                      canvasStyle={{
                        borderRadius: "10px"}}
                        />
                      </div>
                  }
                   <div className={classes.topBarLabel}>
                  <p>
                    {resource &&
                      <LabelEditText 
                        readonly={
                          false && (selectedSubTab === 'approved' || selectedSubTab === 'deleted' || selectedSubTab === 'rejected')
                            ? true
                            : false                                                         
                        }
                        labelsAvailable={labelOptions()}
                        resource={resource}
                        allowUserToSetAnyLabel={allowUserToSetAnyLabel}
                        onEditTextFocus={()=>{
                            setShouldBlockDataTableKeyboardEvents(true)
                        }}
                        onEditTextBlurEvent={()=>{
                            setShouldBlockDataTableKeyboardEvents(false)
                        }}
                        placeWhereComponentBeingUsed="data-table"
                    />}
                  </p>
                  <div
                    style={{
                      display: "flex",
                      alignItems: "center",
                      justifyContent: "center",
                    }}
                  >
                    <ActionButtonArray
                      onActionButtonClick={()=>{
                        videoChanged(1, false);
                      }}
                    />
                    </div>
                  </div>
                </div>
                 <div style={{height: " 300px", overflow: "auto", marginTop: "5px", marginBottom: "20px"}}>
                    <ShowResourceDetails resource={resource} setShouldBlockDataTableKeyboardEvents={setShouldBlockDataTableKeyboardEvents} allowUserToSetAnyLabel={props.allowUserToSetAnyLabel} confidenceScore={Number(resource?.confidence_score)} resourceType={resource?.model?.type} dataSetCollections={resource?.dataSetCollections} changesMade={resource?.changesMade} isShowingInForecastingViewPopup={true} forecastDateTime={resource?.forecastDateTime} updateResourceInUI={updateResourceInUI}/>
                  </div>
                {/* Bottoms Details on the Resource  */}
              </div>
            </div>
            <div className={classes.footer}>
              <div style={{display:'flex',justifyContent:'space-around', marginTop:"60px"}}>
              <Popover
                open={popupOpen}
                anchorEl={anchorEl}
                onClose={handlePopupClose}
                anchorOrigin={{
                  vertical: 'top',
                  horizontal: 'center',
                }}
                transformOrigin={{
                  vertical: 'bottom',
                  horizontal: 'center',
                }}
                className={classes.popOver}
              >
                {popupContent}
              </Popover>
              <div className={classes.videoBtns} style={{pointerEvents: endDuration === "--" ? "none" : "all"}}>
                <GreenTooltip title={"Decrease 2 seconds"}>
                  <IconButton size="small" style={{background:'#008D9C'}} onClick={goBack}><SkipPreviousRoundedIcon style={{ fontSize: 28, color: "#ffff", }}/></IconButton>
                </GreenTooltip>
                <GreenTooltip title={"Increase 2 seconds"}>
                  <IconButton size="small" style={{background:'#008D9C'}} onClick={goForward}><SkipNextRoundedIcon style={{ fontSize: 28, color: "#ffff", }}/></IconButton>
                </GreenTooltip>
                <GreenTooltip title={"Playback speed"}>
                    <>
                      {playBackSpeed === '1x' && <SlowMotionVideoIcon onClick={handlePopupClick} style={{ fontSize: 36, fontWeight: 500, color: "#008D9C", cursor: "pointer" }} />}
                      {playBackSpeed !== '1x' && <div onClick={handlePopupClick} style={{ fontSize: 12, fontWeight: "bolder", color: "#008D9C", cursor: "pointer", height: 36, width: 36 }} className={classes.playbackSpeedCustomBtns}><span>{playBackSpeed.split('x')[0]}</span></div>}
                    </>
                </GreenTooltip>
                <GreenTooltip title={"Play / Pause"}>
                  <IconButton size="small" style={{background:'#008D9C'}} onClick={() => playOrPause(null)}>
                    {!play ? <PlayArrowRoundedIcon style={{ fontSize: 28, color: "#ffff"}}/> :
                      <PauseRoundedIcon style={{ fontSize: 28, color: "#ffff" }}/>
                    }
                  </IconButton>
                </GreenTooltip>
              </div>
              <div style={{marginLeft: "80px"}}>
                <ItemsCounter videoResource={resourceList[currentVideoIndex-1]} resource={props.resource} videoPlayOrPauseHandler={playOrPause} videoAlreadyPlaying={isVideoPlayed}/>
              </div>
              </div>
              {props.resource &&
              <div className={classes.counterContainer} style={{width:'450px', marginTop:"-20px"}}>
                 <div className={classes.compareBackButton}>
                    {
                      !isCompare ? (<span className={classes.compareLink} onClick={()=> setIsCompare(true)}>Compare</span>)
                      : (<span className={classes.compareLink} onClick={()=> setIsCompare(false)}>Back</span>)
                    }
                  </div>
                <div className={classes.counterStats} style={{justifyContent: isCompare ? 'space-between' : 'space-evenly'}}>
                  <div className={classes.counterContainer}>
                    <div className={classes.counterTitle}>Discarded</div>
                    <Chip className={classes.resourceDetailsChip} variant="outlined" 
                      label={
                        totalDiscarded!== undefined 
                        ? totalDiscarded >= 0 
                          ? totalDiscarded >= 10 
                            ? totalDiscarded 
                            : "0" + totalDiscarded 
                          : "NA"
                        : "NA"
                      } 
                    ></Chip>
                  </div>
                  <div className={classes.counterContainer}>
                    <div className={classes.counterTitle}>Sold</div>
                    <Chip className={classes.resourceDetailsChip} variant="outlined" 
                      label={
                        totalSold!== undefined 
                        ? totalSold >= 0 
                          ? totalSold >= 10 
                            ? totalSold 
                            : "0" + totalSold 
                          : "NA"
                        : "NA"
                        } 
                      ></Chip>
                  </div>
                  <div className={classes.counterContainer}>
                    <div className={classes.counterTitle}>Added</div>
                    <Chip className={classes.resourceDetailsChip} variant="outlined" label={totalAdded!== undefined ? totalAdded >= 0 ? totalAdded >= 10 ? totalAdded : "0" + totalAdded : "NA": "NA"} ></Chip>
                  </div>
                  { isCompare &&
                    <>
                      <div className={classes.counterContainer}>
                        <div className={classes.counterTitle}>CV_AI</div>
                        <Chip className={classes.resourceDetailsChip} variant="outlined" 
                          label={
                            props.resource.cv_aiCount!== undefined 
                            ? props.resource.cv_aiCount >= 0 
                              ? props.resource.cv_aiCount >= 10 
                                ? props.resource.cv_aiCount 
                                : "0" + props.resource.cv_aiCount 
                                : "NA"
                              : "NA"
                          } 
                        ></Chip>
                      </div>
                      <div className={classes.counterContainer}>
                        <div className={classes.counterTitle}>Predicted</div>
                        <Chip className={classes.resourceDetailsChip} variant="outlined" 
                          label={
                            props.resource.predictedCount!== undefined 
                            ? props.resource.predictedCount >= 0 
                              ? props.resource.predictedCount >= 10 
                                ? props.resource.predictedCount 
                                : "0" + props.resource.predictedCount 
                              : "NA"
                            : "NA"
                          } 
                        ></Chip>
                      </div>
                    </>
                  }
                 
                </div>
              </div>
              }
            </div>
          </div>
          {/* right arrow  */}
          <IconButton onClick={() => arrowClicked("right")} className={classes.navigateButton}>
            <NavigateNextIcon style={{color:"black", fontSize:"xxx-large"}}/>
          </IconButton>
        </div>
      </div>
    </Dialog>
  );
};

const mapProps = (state: IReduxState) => {
  return {
  };
};
const connector = connect(mapProps, {
  updateResourceInUI
});
export type TPropsFromRedux = ConnectedProps<typeof connector>;
export default connector(ViewForcastModelPopup);

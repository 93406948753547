export function separateFileNameFromExtension(fileName: string): {
  fileNameWithoutExtension: string;
  extensionOfFileName: string;
} {
  let fileNameWithoutExtension: string = "";
  let extensionOfFileName: string = "";

  const dotLastIndex: number = fileName.lastIndexOf(".");
  if (dotLastIndex === -1) {
    throw new Error("file name do not contain extension");
  }
  fileNameWithoutExtension = fileName.substring(0, dotLastIndex);
  extensionOfFileName = fileName.substring(dotLastIndex + 1);

  return { fileNameWithoutExtension, extensionOfFileName };
}

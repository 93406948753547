import { useState, useEffect, DependencyList } from "react";

/**
 *  This hook returns total number of table cells count in table header 
 */

export function useTableHeadCellsCount(
  tableElementId: string,
  dependencies: DependencyList
): number {
  const [tableHeadCellsCount, setTableHeadCellsCount] = useState<number>(0);
  useEffect(() => {
    const tableElement = document.getElementById(tableElementId);
    if (tableElement) {
      if (tableElement?.querySelector("THEAD TR")?.children) {
        setTableHeadCellsCount(
          tableElement?.querySelector("THEAD TR")?.children?.length as number
        );
      }
    }
  }, [...dependencies, tableElementId]);

  return tableHeadCellsCount;
}

import { IClonedPod } from '../../../../chat/contexts/chatContext.provider'
import cloneSuccessfullIcon from '../assets/cloneSuccessfull.gif'

interface IProps {
  clonedPodDetails: IClonedPod
}
function ModelCloneSuccess(props: IProps) {
  const {clonedPodDetails } = props;

  return (
    <div style={{width: '100%', height: '100%', display: "flex", flexDirection:'column', justifyContent: 'center', alignItems: 'center'}}>
        <img src={cloneSuccessfullIcon} height={'30%'} alt="model clonning successfull" />
        <p className='success__text'>Cloning is successful!!</p>
        {
          clonedPodDetails.purposeModelType === 'specific'
          &&
          <p className='success__text'>Best model of this pod was also cloned!</p>
        }
        <p></p>
    </div>
  )
}

export default ModelCloneSuccess
export default class ImageService {
    
  static async getImageWidthHeightFromURL(
    imageUrl: string
  ): Promise<{ width: number; height: number }> {
    try {
      const imageWidthHeightPromise = new Promise<{
        width: number;
        height: number;
      }>((resolve, reject) => {
        try {
          const image = new Image();
          image.onload = () => {
            return resolve({
              width: image.width,
              height: image.height,
            });
          };
          image.src = imageUrl;
        } catch (error: any) {
          reject(new Error(error.message));
        }
      });

      const imageWidthHeight = await imageWidthHeightPromise;
      return imageWidthHeight;
    } catch (error: any) {
      throw new Error(error.message);
    }
  }

  static getImageHeight(imageWidth: number, imageAspectRatio: number) {
    return imageWidth / imageAspectRatio;
  }
  
  static getImageWidth(imageHeight: number, imageAspectRatio: number) {
    return imageHeight * imageAspectRatio;
  }  
}

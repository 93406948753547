import { useEffect, useState } from "react";
import { downloadImageWithErrorHandler } from "../services/imageHelperService";

type Props = {
  compressedImageDownloadUrl: string;
  originalImageDownloadUrl: string;
};

type ImageDownloadStatus =
  | "yetToStart"
  | "downloadInProgress"
  | "downloadGotFailed"
  | "downloadCompleted";

type HookReturnType = {
  compressedImageDownloadStatus: ImageDownloadStatus;
  originalImageDownloadStatus: ImageDownloadStatus;
};

/**
 * @description
 * The hook is designed to manage the loading of compressed and original images. It handles the download status of both image types and provides a way to track the progress of image loading. It first download the compressed image and then download the original image
 */
export function useCompressedAndOriginalImageLoader(
  props: Props
): HookReturnType {
  const [compressedImageDownloadStatus, setCompressedImageDownloadStatus] =
    useState<ImageDownloadStatus>("yetToStart");
  const [originalImageDownloadStatus, setOriginalImageDownloadStatus] =
    useState<ImageDownloadStatus>("yetToStart");

  useEffect(() => {
    if (!props.compressedImageDownloadUrl) {
      setCompressedImageDownloadStatus("downloadGotFailed");
      return;
    }
    const performLogic = async () => {
      try {
        setCompressedImageDownloadStatus("downloadInProgress");
        await downloadImageWithErrorHandler(props.compressedImageDownloadUrl);
        setCompressedImageDownloadStatus("downloadCompleted");
      } catch (error) {
        setCompressedImageDownloadStatus("downloadGotFailed");
      }
    };

    performLogic();
  }, [props.compressedImageDownloadUrl]);

  useEffect(() => {
    if (
      compressedImageDownloadStatus === "yetToStart" ||
      compressedImageDownloadStatus === "downloadInProgress"
    ) {
      return;
    }

    const performLogic = async () => {
      try {
        setOriginalImageDownloadStatus("downloadInProgress");
        await downloadImageWithErrorHandler(props.originalImageDownloadUrl);
        setOriginalImageDownloadStatus("downloadCompleted");
      } catch (error) {
        setOriginalImageDownloadStatus("downloadGotFailed");
      }
    };

    performLogic();
  }, [props.originalImageDownloadUrl, compressedImageDownloadStatus]);

  return {
    compressedImageDownloadStatus,
    originalImageDownloadStatus,
  };
}

import {
  ModelCollectionHistoryData,
  ModelCollectionHistoryDataTrainingDataSet,
} from "../types/ModelCollectionHistoryData.type";
import { useState, useEffect, useCallback, useRef } from "react";
import { DataService } from "../../../../../../../../../../../services";
import { Alert } from "@material-ui/lab";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import ReactFlow, {
  useNodesState,
  useEdgesState,
  addEdge,
  Node,
  Position,
  Edge,
  ConnectionLineType,
  Handle,
  Controls,
  MarkerType,
  Panel
} from "reactflow";
// 👇 you need to import the reactflow styles
import "reactflow/dist/style.css";
import dagre from 'dagre';
import { Chip, CircularProgress, makeStyles, Menu, MenuItem, Tooltip } from "@material-ui/core";
import { generateUniqueId } from "../../../../../../../../../../../services/idHelperService";
import { useQuery } from "../../../../../../../../../../../common";
import ExpandLessIcon from '@material-ui/icons/ExpandLess';
import { ModelCollectionTableFilterQueryParams, ModelCollectionTableQueryParam } from "./Model-Collection";
import { DataSetCollectionTableFilterQueryParams } from "./DataSet-Collection";

const expandNodeTooltip = "Show parent nodes";
const collapseNodeTooltip = "Hide parent nodes";

type ModelCollectionTreeItemProps = ModelCollectionHistoryData & {
  isRetrainedModelCollection: boolean;
  version: string;
  isRootNode?: boolean;
  modelArchitecture ?: string;
  // nodeId?: string;
  prevNodeId ?: string;
  NodesAndEdges : NodesAndEdges;
};

type DataSetCollectionTreeItemProps =
  ModelCollectionHistoryDataTrainingDataSet & {
    parentDataSets: ModelCollectionHistoryDataTrainingDataSet[];
    isParentDataSet: boolean;
    // nodeId ?: string;
    prevNodeId : string;
    NodesAndEdges : NodesAndEdges
  };

type EdgesProps = {
  prevNodeId : string;
  nodeId : string;
  label ?: string;
  Edges ?: Edge[];
}

type NodesAndEdges = {
  nodes : Node[],
  edges : Edge[]
}

const useStyles = makeStyles((theme) => ({
  modelCollectionChip : {
    borderRadius: "3px",
    backgroundColor: `${theme.palette.primary.main}`,
    color: "white",
    border: `1px solid ${theme.palette.primary.main}`,
    // width: '172px',
    width: '75px',
    height : '36px',
    fontSize: 'xx-small',
    "&:focus": {
      backgroundColor: `${theme.palette.primary.main}`,
    },
    "&:hover": {
      backgroundColor: `${theme.palette.primary.main}`,
    }
  },
  dataSetCollectionChip: {
    backgroundColor: "white",
    border: `1px solid ${theme.palette.primary.main}`,
    width: '172px',
    height : '36px' ,
    fontSize: 'xx-small',
    "&:focus": {
      backgroundColor: "white",
    },
    "&:hover": {
      backgroundColor: "white",
    }
  },
  reactFlow: {
    overflow: "auto !important"
  },
  circularProgress : {
    display : 'flex',
    justifyContent: 'center'
  }
}))

const position = { x: 0, y: 0 };
// const edgeType = 'smoothstep';
const edgeType = 'besizer';
// const edgeType = 'straight';

const dagreGraph = new dagre.graphlib.Graph();
dagreGraph.setDefaultEdgeLabel(() => ({}));

// const nodeWidth = 250;
const nodeWidth = 290;
const nodeHeight = 10;

const nodeTypes = {
  modelNode: CustomNode,
  dataSetNode : CustomDataSetNode,
};

const initialNodes: Node[] = [];

const initialEdges: Edge[] = [];

const getLayoutedElements = (nodes : Node[], edges : Edge[]) => {
    dagreGraph.setGraph({ rankdir: 'LR'});
  
    nodes.forEach((node) => {
      dagreGraph.setNode(node.id, { width: nodeWidth, height: nodeHeight });
    });
  
    edges.forEach((edge) => {
      dagreGraph.setEdge(edge.source, edge.target);
    });
  
    dagre.layout(dagreGraph);
  
    nodes.forEach((node) => {
      const nodeWithPosition = dagreGraph.node(node.id);
      node.targetPosition = Position.Left;
      node.sourcePosition = Position.Right;
  
      // We are shifting the dagre node position (anchor=center center) to the top left
      // so it matches the React Flow node anchor point (top left).
      node.position = {
        x: nodeWithPosition.x - nodeWidth / 2,
        y: nodeWithPosition.y - nodeHeight / 2,
      };
  
      return node;
    });
  
    return { nodes, edges };
  };
const { nodes: layoutedNodes, edges: layoutedEdges } = getLayoutedElements(
    initialNodes,
    initialEdges
    );

type CustomNodeData = {
  areParentNodesHidden?: boolean,
  isStartingNode: boolean,
  isLastNode: boolean,
  mouseX ?: number | null,
  mouseY ?: number | null,
  handleClose ?: () => void,
  handleRightClick ?: Function,
  handleNodeClick ?: Function
  // modelCollectionClickHandler ?: Function
}


export default function ModelCollectionHistoryView(props: {
  modelCollectionId: string;
}) {
  const modelCollectionId : string = props.modelCollectionId;
  const [nodes, setNodes, onNodesChange] = useNodesState<CustomNodeData>(layoutedNodes);
  const [edges, setEdges, onEdgesChange] = useEdgesState<Edge[]>(layoutedEdges);
  const [historyData, setHistoryData] = useState<ModelCollectionHistoryData>();
  const [isHistoryDataBeingFetched, setIsHistoryDataBeingFetched] =
    useState<boolean>(false);

  const edgesRef = useRef(edges);
  edgesRef.current = edges;

  // const [defaultExpandedNodeIds, setDefaultExpandedNodeIds] = useState<
  //   string[]
  // >([]);

  // const addDefaultExpandedNodeId = useCallback((nodeIdToAdd: string) => {
  //   setDefaultExpandedNodeIds((oldNodeIds) => [...oldNodeIds, nodeIdToAdd]);
  // }, []);

  const classes = useStyles();

  const hideOrUnhideNode = useCallback(
    (nodeId: string, action: "hide" | "unhide") => {
      setNodes(oldNodes => {
        let nodeChangeSuccessfull: boolean = false;

        for (const node of oldNodes) {
          if (nodeId === node.id) {
            node.hidden = action === "hide" ? true : false
            nodeChangeSuccessfull = true;
            break;
          }
        }

        if (nodeChangeSuccessfull) {
          return [...oldNodes]
        }
        return oldNodes;
      })
    },
    [setNodes]
  );

  const hideOrUnhideEdge = useCallback(
    (edgeId: string, action: "hide" | "unhide") => {
      setEdges(oldEdges => {
        let edgeChangeSuccessfull: boolean = false;

        for (const edge of oldEdges) {
          if (edgeId === edge.id) {
            edge.hidden = action === "hide" ? true : false
            edgeChangeSuccessfull = true;
            break;
          }
        }

        if (edgeChangeSuccessfull) {
          return [...oldEdges]
        }
        return oldEdges;
      })
    },
    [setEdges]
  );

  const setNodeParentNodesHidden = useCallback(
    (nodeId: string, parentNodesHiddenValueToSet: boolean) => {
      setNodes(oldNodes => {
        let nodeChangeSuccessfull: boolean = false;
        for (let nodeIndex = 0; nodeIndex < oldNodes.length; nodeIndex++) {
          let node = oldNodes[nodeIndex];
          if (nodeId === node.id) {
            node = {
              ...node,
              data: {
                ...node.data,
                areParentNodesHidden: parentNodesHiddenValueToSet
              }
            }
            oldNodes[nodeIndex] = {...node};
            nodeChangeSuccessfull = true;
            break;
          }
        }

        if (nodeChangeSuccessfull) {
          return [...oldNodes]
        }
        return oldNodes;
      })
    },
    [setNodes]
  );  

  const hideorUnhideParentNodesAndEdges = useCallback(
    (action: "hideParentNodesAndEdges" | "unhideParentNodesAndEdges", childNodeId: string, edges: Edge[]) => {
        for (const edge of edges) {
          const [parentNodeId, childNodeIdOfEdge] = edge.id.split("->");
          if (childNodeId === childNodeIdOfEdge) {
            hideOrUnhideNode(
              parentNodeId,
              action === "hideParentNodesAndEdges" ? "hide" : "unhide"
            )
            setNodeParentNodesHidden(parentNodeId, action === "hideParentNodesAndEdges" ? true : false)
            hideOrUnhideEdge(
              edge.id,
              action === "hideParentNodesAndEdges" ? "hide" : "unhide"
            )
            hideorUnhideParentNodesAndEdges(action, parentNodeId, edges)
          }
        }
    },
    [hideOrUnhideEdge, hideOrUnhideNode, setNodeParentNodesHidden],
  );  

  const handleModelContextMenuClose = useCallback((nodeId : string) => {
    setNodes(oldNodes=>{
      for (let nodeIndex = 0; nodeIndex < oldNodes.length; nodeIndex++) {
        let node = oldNodes[nodeIndex];
        if(node.id === nodeId){
          node = {
            ...node,
            data: {
              ...node.data,
              mouseX: null,
              mouseY: null
            }
          }

          oldNodes[nodeIndex] = {...node};
          break;
        }
      }
      return [...oldNodes]
    })
    // setContextState(initialState);
  }, [setNodes]);  

  const handleNodeClickEvent = useCallback(
    (_event, nodeClicked: Node<CustomNodeData>) => {
      console.log(" handle node click event data are :: ", _event, nodeClicked)

      if (

        /**
         * Will not show collapse/expand for top most parent data-set collection as it wont have any parent data-set collection
         */
        (nodeClicked?.type === "dataSetNode" && nodeClicked?.data?.isLastNode) ||

        /**
         * Will not show collapse/expand for root model collection
         */
        nodeClicked?.data?.isStartingNode
      ) {
        return;
      }

      setNodes(oldNodes=>{
        for (let nodeIndex = 0; nodeIndex < oldNodes.length; nodeIndex++) {
          let node = oldNodes[nodeIndex];
          if (node.id === nodeClicked.id) {
            node = {
              ...node,
              data: {
                ...node.data,
                areParentNodesHidden: node.data.areParentNodesHidden ? false : true
              }
            }

            oldNodes[nodeIndex] = {...node};

            hideorUnhideParentNodesAndEdges(
              node.data.areParentNodesHidden ? "hideParentNodesAndEdges" : "unhideParentNodesAndEdges",
              nodeClicked.id,
              edgesRef.current
            )

            break;
          }
        }
        return [...oldNodes]
      })
    },
    [hideorUnhideParentNodesAndEdges, setNodes],
  );

  const handleRightClick = useCallback(
    (_event, nodeClicked: Node<CustomNodeData>) => {
      console.log(" handle right click  ::: ", _event, nodeClicked)
      _event.preventDefault();
      setNodes(oldNodes=>{
        for (let nodeIndex = 0; nodeIndex < oldNodes.length; nodeIndex++) {
          let node = oldNodes[nodeIndex];
          if (node.id === nodeClicked.id) {
            node = {
              ...node,
              data: {
                ...node.data,
                mouseX: _event.clientX - 2,
                mouseY: _event.clientY - 2
              }
            }

            oldNodes[nodeIndex] = {...node};

            break;
          }
        }
        // return [...JSON.parse(JSON.stringify(oldNodes))]
        return [...oldNodes]
      })
    },
    [setNodes],
  );

  const DataSetCollectionHistoryTree = useCallback(
    (props : DataSetCollectionTreeItemProps) => {
      let nodeId : string;
      // if(props.nodeId){
      //   nodeId = props.nodeId;
      // }else{
        nodeId = generateUniqueId();
      // }
  
      let newNode : Node;
  
      newNode = {
        id: nodeId || "",
        position: position,
        type: 'dataSetNode',
        data: {name: props.name , isParentDataSet : props.isParentDataSet, isLastNode : props.parentDataSets.length > 0 ? false : true, handleClose : handleModelContextMenuClose, handleRightClick : handleRightClick , handleNodeClick : handleNodeClickEvent},
        draggable:false
      }
      props.NodesAndEdges.nodes.push(newNode);
  
      if(props.prevNodeId){
        props.NodesAndEdges.edges.push(CreateEdges({
          prevNodeId: props.prevNodeId,
          nodeId: nodeId,
          label : props.isParentDataSet ? "Parent Data-Set"  : "Training Data-Set",
        }))
      }
  
      (props?.parentDataSets.map((parentDataSet, parentDataSetIndex) => {
        DataSetCollectionHistoryTree({
          name : parentDataSet.name,
          // nodeId : parentDataSet?._id,
          parentDataSets : parentDataSet?.parentDataSets || [],
          isParentDataSet : true,
          prevNodeId : nodeId,
          NodesAndEdges : props.NodesAndEdges
        })}));      
    },
    [handleModelContextMenuClose, handleNodeClickEvent, handleRightClick],
  )  

  const ModelCollectionHistoryTree = useCallback((props : ModelCollectionTreeItemProps) =>  {
    let nodeId : string;
    // if(props.nodeId){
    //   nodeId = props.nodeId;
    // }else{
      nodeId = generateUniqueId();
    // }

    let newNode : Node;
  
    newNode = {
      id: nodeId || "",
      position: position,
      type: 'modelNode',
      data: {isRetrainedModelCollection: props.isRetrainedModelCollection, version: props.version , isStartingNode : props.isRootNode, architecture: props.modelArchitecture, mouseX : null, mouseY : null, handleClose : handleModelContextMenuClose, handleRightClick : handleRightClick , handleNodeClick : handleNodeClickEvent},
      draggable:false
    }

    props.NodesAndEdges.nodes.push(newNode);
  
    if(props.prevNodeId){
      props.NodesAndEdges.edges.push(CreateEdges({
        prevNodeId: props.prevNodeId,
        nodeId: nodeId,
        label : "Re-Trained From"
      }))
    }
  
    (props?.trainingDataSets.map((trainingDataSet, trainingDataSetIndex) => {
      DataSetCollectionHistoryTree({
        name : trainingDataSet.name,
        // nodeId : trainingDataSet?._id,
        parentDataSets : trainingDataSet?.parentDataSets || [],
        isParentDataSet : false,
        prevNodeId : nodeId,
        NodesAndEdges : props.NodesAndEdges
      })
    }));
  
    (props?.reTrainedFromModelCollection && (
      ModelCollectionHistoryTree({
        version : props?.reTrainedFromModelCollection?.version,
        isRootNode : false,
        // nodeId : props?.reTrainedFromModelCollection?._id,
        isRetrainedModelCollection : true,
        trainingDataSets : props?.reTrainedFromModelCollection?.trainingDataSets || [],
        reTrainedFromModelCollection : props?.reTrainedFromModelCollection?.reTrainedFromModelCollection || undefined,
        prevNodeId : nodeId,
        NodesAndEdges : props.NodesAndEdges,
        modelArchitecture : props?.reTrainedFromModelCollection?.modelArchitecture || ""
      })
    ));

    return(
      props.NodesAndEdges
    )
  }, [DataSetCollectionHistoryTree, handleModelContextMenuClose, handleNodeClickEvent, handleRightClick])  

  useEffect(()=>{
    const executeFunction = async () => {
      setIsHistoryDataBeingFetched(true);
      const data = await DataService.fetchModelCollectionHistoryData({
        modelCollectionId: modelCollectionId,
      });
      setHistoryData(data);
      setIsHistoryDataBeingFetched(false);
    };
    if (modelCollectionId) {
      executeFunction();
    }
  }, [modelCollectionId])

  useEffect(() => {
    const executeFunction = async () => {

      let NodesAndEdges : NodesAndEdges= {
        nodes : [],
        edges : []
      }
      if(historyData){
        // callModelCollectionHistoryTree(data)
        const result : NodesAndEdges = ModelCollectionHistoryTree({
          version : historyData?.version,
          isRootNode : true,
          isRetrainedModelCollection : false,
          trainingDataSets : historyData?.trainingDataSets || [],
          reTrainedFromModelCollection : historyData?.reTrainedFromModelCollection || undefined,
          NodesAndEdges : NodesAndEdges,
          modelArchitecture : historyData?.modelArchitecture || ""
        })

        // console.log("result is ::: ", result);
        const { nodes: layoutedNodes, edges: layoutedEdges } = getLayoutedElements(
          result.nodes,
          result.edges);
    
        setNodes([...layoutedNodes]);
        setEdges([...layoutedEdges]);
      }
    };
    if (historyData) {
      executeFunction();
    }
  }, [ModelCollectionHistoryTree, historyData, setEdges, setNodes]);


  const onConnect = useCallback(
    (params) =>
      setEdges((eds) =>
        addEdge({ ...params, type: ConnectionLineType.SmoothStep, animated: true }, eds)
      ),
    [setEdges]
  );

  // const childTreeItemNodeId = useMemo(() => generateUniqueId(), []);

  // useEffect(() => {
  //   if (childTreeItemNodeId) {
  //     addDefaultExpandedNodeId(childTreeItemNodeId);
  //   }
  // }, [childTreeItemNodeId, addDefaultExpandedNodeId]);

  function CreateEdges (props : EdgesProps) : Edge {
    let newEdge : Edge;
    newEdge = { 
      id: `${props.nodeId}->${props.prevNodeId}`, 
      source: props.nodeId, 
      target: props.prevNodeId, 
      type: edgeType, 
      animated: true, 
      label:props.label || "", 
      markerEnd: {
        type: MarkerType.ArrowClosed,
        width: 20,
        height: 20,
        color: '#008D9C',
      }
    }
        return (newEdge)  
    }

  return (
    <>
      {/* {historyData && !isHistoryDataBeingFetched && (
        <>
          <TreeView
            defaultCollapseIcon={<ExpandMoreIcon />}
            defaultExpandIcon={<ChevronRightIcon />}
            disableSelection
            defaultExpanded={defaultExpandedNodeIds}
          >
            <ModelCollectionTreeItem
              version={historyData?.version}
              isRootNode={true}
              nodeId={childTreeItemNodeId}
              isRetrainedModelCollection={false}
              trainingDataSets={historyData?.trainingDataSets || []}
              reTrainedFromModelCollection={
                historyData?.reTrainedFromModelCollection || undefined
              }
            />
          </TreeView>
        </>
      )}
      {isHistoryDataBeingFetched && (
        <div
          style={{
            width: "100%",
            height: "100%",
          }}
        >
          Loading...
        </div>
      )} */}
          <div
            style={{
                // marginTop: "50%",
                height: "600px",
                width: "100%"
            }}
          >
            {isHistoryDataBeingFetched ?
              <div  className={classes.circularProgress}>
                <CircularProgress/>
              </div>
            :
              <ReactFlow
                nodes={nodes}
                edges={edges}
                draggable={false}
                nodesDraggable={false}
                zoomOnDoubleClick={false}
                attributionPosition={"top-center"}
                preventScrolling={false}
                nodesConnectable={false}
                className={classes.reactFlow}
                onNodesChange={onNodesChange}
                onEdgesChange={onEdgesChange}
                // onNodeClick={handleNodeClickEvent}
                proOptions={{ hideAttribution: true }}
                onConnect={onConnect}
                connectionLineType={ConnectionLineType.SmoothStep}
                nodeTypes={nodeTypes}
                deleteKeyCode={null}
                fitView
                //@ts-ignore
                // onNodeContextMenu={handleRightClick}
                // onContextMenu={(e) => e.preventDefault()}
                >
                {/* <MiniMap /> */}
                <Controls 
                  showInteractive={false}
                  style={{
                    position: "fixed",
                    left: "10px",
                    bottom: "3px"
                  }}
                />
                {/* <Background /> */}

                <CollectionRightClickHintPanel />
                </ReactFlow>}
            </div>
    </>
  );
}


type CustomModelNodeProps ={
  data: {
    isRetrainedModelCollection : boolean,
    version : string,
    isStartingNode : boolean,
    areParentNodesHidden?: boolean,
    architecture ?: string,
    mouseX ?: number | null,
    mouseY ?: number | null,
    handleClose : (nodeId:string) => void,
    handleRightClick : Function,
    handleNodeClick : Function,
    isLastNode ?: boolean
  },
  id : string,
  type : string
}

function CustomNode(data : CustomModelNodeProps) {

  const query = useQuery();
  const modelCollectionClickHandler = (version : string, architecture : string) => {
    data.data.handleClose(data.id)
    if (version && architecture) {
      const model = query.get(ModelCollectionTableQueryParam.MODEL_ID) || '';
      const type = query.get(ModelCollectionTableQueryParam.MODEL_TYPE) || '';
      const project = query.get(ModelCollectionTableQueryParam.PROJECT_ID) || '';
      const newQuery = new URLSearchParams();
      newQuery.set(ModelCollectionTableQueryParam.MODEL_ID, model)
      newQuery.set(ModelCollectionTableQueryParam.MODEL_TYPE, type);
      newQuery.set(ModelCollectionTableQueryParam.PROJECT_ID, project)
      newQuery.set(ModelCollectionTableQueryParam.OFFSET, "1")
      newQuery.set(ModelCollectionTableQueryParam.LIMIT, "10");
      newQuery.set(ModelCollectionTableQueryParam.STATUS, 'approved') 
      newQuery.set(ModelCollectionTableFilterQueryParams.MODEL_COLLECTION_VERION_SEARCH_VALUE_QUERY_PARAM, version)
      newQuery.set(ModelCollectionTableFilterQueryParams.MODEL_COLLECTION_ARCHITECTURE_SEARCH_VALUE_QUERY_PARAM, architecture || "")
      // setIsRedirectingToCollection('');
      const basePath = window.location.pathname;
      window.open(basePath.substring(0, basePath.lastIndexOf('/')) + '/model?' + newQuery.toString(), '_blank');
      // handleClose();
      // setContextState(initialState);
    }
  }

  const classes = useStyles();
  console.log("data is ", data);
  return (
    <div>
      <Chip
      className={`
        ${classes.modelCollectionChip}`}
        label={
          <>
            <Tooltip title={data.data.version}>
              <b>{data.data.version}</b>
            </Tooltip>
          </>
        }
        onContextMenu={(e) => {data.data.handleRightClick(e, data)}}
        onClick={(e) => {data.data.handleNodeClick(e, data)}}
      />
      {
              <Menu
              keepMounted
              open={data.data.mouseY !== null && data.data.mouseY !== undefined}
              //@ts-ignore
              // onClose={(e) => {e.stopPropagation(); data.data.handleClose()}}
              onClose={() => {
                data.data.handleClose(data.id)
              }}
              anchorReference="anchorPosition"
              anchorPosition={
                data.data.mouseY && data.data.mouseX 
                  ? { top: data.data.mouseY, left: data.data.mouseX }
                  : undefined
              }
            >
              <MenuItem onClick={(e) => {e.stopPropagation()
                 modelCollectionClickHandler(data.data.version, data.data.architecture || "");}
                 }>Open Collection in New Tab</MenuItem>
            </Menu> }
            

      {
        data?.data?.isStartingNode
        ?
          null
        :
          data.data.areParentNodesHidden
          ?
            <Tooltip title={expandNodeTooltip}>
              <ExpandMoreIcon style={{cursor: "pointer"}} onClick={(e) => {data.data.handleNodeClick(e, data)}}  />
            </Tooltip>
          : <Tooltip title={collapseNodeTooltip}>
              <ExpandLessIcon style={{cursor: "pointer"}} onClick={(e) => {data.data.handleNodeClick(e, data)}} />
            </Tooltip>
      }      

      <Handle type="target" position={Position.Left} />
      <Handle type="source" position={data.data.isStartingNode ? Position.Left : Position.Right}/>
    </div>
  );
}

type CustomDataSetNodeProps = {
  data: {
    name: string,
    isLastNode: boolean,
    areParentNodesHidden?: boolean,
    mouseX ?: number | null,
    mouseY ?: number | null,
    handleClose : (nodeId : string) => void,
    isStartingNode ?: boolean,
    handleRightClick : Function,
    handleNodeClick : Function,
  },
  id : string,
  type : string
}

function CustomDataSetNode(data : CustomDataSetNodeProps) {

  const query = useQuery();
  const dataSetCollectionClickHandler = async (name : string) => {
    // data.data.areParentNodesHidden = nodeExpandState.current
    if (name) {
      const model = query.get(ModelCollectionTableQueryParam.MODEL_ID) || '';
      const type = query.get(ModelCollectionTableQueryParam.MODEL_TYPE) || '';
      const project = query.get(ModelCollectionTableQueryParam.PROJECT_ID) || '';
      const newQuery = new URLSearchParams();
      newQuery.set(ModelCollectionTableQueryParam.MODEL_ID, model)
      newQuery.set(ModelCollectionTableQueryParam.MODEL_TYPE, type);
      newQuery.set(ModelCollectionTableQueryParam.PROJECT_ID, project)
      newQuery.set(ModelCollectionTableQueryParam.OFFSET, "1")
      newQuery.set(ModelCollectionTableQueryParam.LIMIT, "10");
      newQuery.set(ModelCollectionTableQueryParam.STATUS, 'pending') 
      newQuery.set(DataSetCollectionTableFilterQueryParams.DATASET_COLLECTION_TABLE_NAME_SEARCH_OPERATOR_QUERY_PARAM, "equals")
      newQuery.set(DataSetCollectionTableFilterQueryParams.DATASET_COLLECTION_TABLE_NAME_SEARCH_VALUE_QUERY_PARAM, name)
      // setIsRedirectingToCollection('');
      const basePath = window.location.pathname;
      const updatedPath = basePath.replace('/collections', '/data');
      window.open(updatedPath.substring(0, updatedPath.lastIndexOf('/')) + '/data-set?' + newQuery.toString(), '_blank')
      data.data.handleClose(data.id);
    }
  }
  const classes = useStyles();
  return (
    <div>
      <Chip
      className={`
        ${classes.dataSetCollectionChip}`}
        label={
          <>
            <Tooltip title={data.data.name}>
              <b>{data.data.name}</b>
            </Tooltip>
          </>
        }
        onContextMenu={(e) => {data.data.handleRightClick(e, data)}}
        onClick={(e) => {data.data.handleNodeClick(e, data)}}
      />
      {
              <Menu
              keepMounted
              open={data.data.mouseY !== null && data.data.mouseY !== undefined}
              onClose={() => {data.data.handleClose(data.id)}}
              anchorReference="anchorPosition"
              anchorPosition={
                data.data.mouseY && data.data.mouseX 
                  ? { top: data.data.mouseY, left: data.data.mouseX }
                  : undefined
              }
            >
              <MenuItem onClick={() => dataSetCollectionClickHandler(data.data.name)}>Open Collection in New Tab</MenuItem>
            </Menu> }

      {
        !data?.data?.isLastNode 
        ? data.data.areParentNodesHidden
          ? <Tooltip title={expandNodeTooltip}>
              <ExpandMoreIcon style={{cursor: "pointer"}} onClick={(e) => {data.data.handleNodeClick(e, data)}} />
            </Tooltip>
          : <Tooltip title={collapseNodeTooltip}>
              <ExpandLessIcon style={{cursor: "pointer"}} onClick={(e) => {data.data.handleNodeClick(e, data)}} />
            </Tooltip>
        : null
      }      

      <Handle type="target" position={data.data.isLastNode ? Position.Right : Position.Left}/>
      <Handle type="source" position={Position.Right}/>
    </div>
  );
}

function CollectionRightClickHintPanel() {
  return (
    <Panel
      position="bottom-center"
      style={{
        position: "fixed",
        bottom: "20px"
      }}
    >
      <Alert severity="info"
        style={{
          fontFamily: "'Roboto', 'Helvetica', 'Arial', sans-serif"
        }}
      >
        Right click on collection for more options
      </Alert>
    </Panel>
  );
}


// const useTreeItemStyles = makeStyles((theme) => ({
//   label: {
//     backgroundColor: "unset !important",
//   },
// }));

// const useModelCollectionTreeItemStyles = makeStyles((theme) => ({
//   treeItem: {
//     padding: "14px 10px 0px 10px",
//   },
//   modelCollectionChip: {
//     borderRadius: "3px",
//     backgroundColor: `${theme.palette.primary.main}`,
//     color: "white",
//     border: `1px solid ${theme.palette.primary.main}`,
//   },
//   cursorPointer: {
//     cursor: "pointer",
//   },
// }));

// type ModelCollectionTreeItemProps = ModelCollectionHistoryData & {
//   isRetrainedModelCollection: boolean;
//   version: string;
//   isRootNode?: boolean;
//   nodeId?: string;
// };

// function ModelCollectionTreeItem(props: ModelCollectionTreeItemProps) {
//   const classes = useModelCollectionTreeItemStyles();
//   const treeItemClasses = useTreeItemStyles();

//   const nodeId = useMemo(() => {
//     if (props.nodeId) {
//       return props.nodeId;
//     }
//     return generateUniqueId();
//   }, [props.nodeId]);

//   return (
//     <TreeItem
//       expandIcon={props.isRootNode ? <></> : <ChevronRightIcon />}
//       collapseIcon={props.isRootNode ? <></> : <ExpandMoreIcon />}
//       className={`${classes.treeItem}`}
//       classes={{
//         label: treeItemClasses.label,
//       }}
//       onLabelClick={(event) => {
//         if (props.isRootNode) {
//           event.preventDefault();
//         }
//       }}
//       nodeId={nodeId}
//       label={
//         <Chip
//           className={`
//             ${classes.modelCollectionChip}
//             ${!props.isRootNode ? classes.cursorPointer : ""}
//             `}
//           label={
//             <>
//               {props.isRetrainedModelCollection
//                 ? `Re-Trained from Model Collection`
//                 : `Model Collection`}
//               : <b>{props.version}</b>
//             </>
//           }
//         />
//       }
//     >
//       {props.trainingDataSets?.map((trainingDataSet, trainingDataSetIndex) => {
//         return (
//           <DataSetCollectionTreeItem
//             key={trainingDataSetIndex}
//             name={trainingDataSet.name}
//             parentDataSets={trainingDataSet.parentDataSets || []}
//             isParentDataSet={false}
//           />
//         );
//       })}
//       {props.reTrainedFromModelCollection && (
//         <ModelCollectionTreeItem
//           isRetrainedModelCollection={true}
//           trainingDataSets={props.reTrainedFromModelCollection.trainingDataSets}
//           version={props.reTrainedFromModelCollection.version}
//           reTrainedFromModelCollection={
//             props.reTrainedFromModelCollection.reTrainedFromModelCollection
//           }
//         />
//       )}
//     </TreeItem>
//   );
// }

// const useDataSetCollectionTreeItemStyles = makeStyles((theme) => ({
//   treeItem: {
//     // padding: "10px",
//     padding: "14px 10px 0px 10px",
//   },
//   dataSetCollectionChip: {
//     backgroundColor: "white",
//     border: `1px solid ${theme.palette.primary.main}`,
//   },
//   cursorPointer: {
//     cursor: "pointer",
//   },
// }));
// type DataSetCollectionTreeItemProps =
//   ModelCollectionHistoryDataTrainingDataSet & {
//     parentDataSets: ModelCollectionHistoryDataTrainingDataSet[];
//     isParentDataSet: boolean;
//   };
// function DataSetCollectionTreeItem(props: DataSetCollectionTreeItemProps) {
//   const classes = useDataSetCollectionTreeItemStyles();
//   const treeItemClasses = useTreeItemStyles();
//   const nodeId = useMemo(() => generateUniqueId(), []);

//   return (
//     <TreeItem
//       nodeId={nodeId}
//       className={`${classes.treeItem}`}
//       classes={{
//         label: treeItemClasses.label,
//       }}
//       label={
//         <Chip
//           label={
//             <>
//               {props.isParentDataSet ? `Parent Data-Set` : `Training Data-Set`}:{" "}
//               <b>{props.name}</b>
//             </>
//           }
//           className={`
//           ${classes.dataSetCollectionChip}
//           ${props.parentDataSets?.length > 0 ? classes.cursorPointer : ""}
//           `}
//         />
//       }
//     >
//       {props.parentDataSets?.map((parentDataSet, parentDataSetIndex) => {
//         return (
//           <DataSetCollectionTreeItem
//             key={parentDataSetIndex}
//             name={parentDataSet.name}
//             parentDataSets={parentDataSet.parentDataSets || []}
//             isParentDataSet={true}
//           />
//         );
//       })}
//     </TreeItem>
//   );
// }

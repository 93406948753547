import { API } from "./API";

import { AiAssistantLabellingState, IAutomatedAiAssistantLabelling , IAutomatedAiAssistantLabellingCopyData } from "../common/constants/interfaces/automatedAiAssistantLabelling";
import { IData, queryObjectToString } from "../common";
import { AxiosResponse } from "axios";
import { IDockerImageOption } from "./PurposeModelService";


const URL_STRINGS = {
    AUTOMATE_AI_ASSISTANT_LABELLING: "aiAssistantLabelling",
  };

export enum AiAssistedLabellingDetailedViewQueryParams {
    aiAssistantLabellingId="aiAssistantLabellingId"
}
  
class AiAssistantLabellingService extends API {
    async createAiAssistant(reqPayload: { projectId: string, name: string, description: string}): Promise<any> {
        const apiResponse = await this.api.post(`${URL_STRINGS.AUTOMATE_AI_ASSISTANT_LABELLING}`, reqPayload)
        return apiResponse
    }

    async getAllAiAssistantLabelling(queryParams: { 
        projectId?: string,
        ids?: string[],
        status?: IAutomatedAiAssistantLabelling["status"],
        getGlobalPackageFileDownloadUrl?: string,
        getLibrariesRequirementFileDownloadUrl?: string,
        getModelFileDownloadUrl?: string
    }): Promise<AxiosResponse<{
        aiAssistantLabellings: IAutomatedAiAssistantLabelling[]
    }>> {
        const apiResponse = await this.api.get(`${URL_STRINGS.AUTOMATE_AI_ASSISTANT_LABELLING}?${
            queryObjectToString(queryParams)
        }`)

        return apiResponse
    }

    async updateAiAssistantLabellings(reqPayload: {
        ids: string[],
        name?: string,
        description?: string,
        code?: string,
        isFetchingCode?: boolean,
        isTestingCodeByExecutingIt?: boolean,
        consoleOutput?: string,
        mappedResourceForTestingCodeOn?: string, // resourceId
        savedForLaterResourcesOnWhichCodeCanBetestedOn?: string[], // array of resourceIds
        baseDockerImage?: IDockerImageOption
    }): Promise<any> {
        const apiResponse = await this.api.put(`${URL_STRINGS.AUTOMATE_AI_ASSISTANT_LABELLING}`, reqPayload)
        return apiResponse
    }

    async getAllUniqueNames( projectId: string ): Promise<any> {
        const apiResponse = await this.api.get(`${URL_STRINGS.AUTOMATE_AI_ASSISTANT_LABELLING}/${projectId}/getAllUniqueNames`)

        return apiResponse
    }

    async generateCode( aiAssistedLabellingId: string ): Promise<AxiosResponse<{
        status: "success",
        code: string, // generated code
        message: string
    }>> {
        const apiResponse = await this.api.get(`${URL_STRINGS.AUTOMATE_AI_ASSISTANT_LABELLING}/generateCode?purpose_labelling_id=${aiAssistedLabellingId}`)
        return apiResponse
    }

    async testCode( aiAssistedLabellingId: string, codeToTest: string ): Promise<AxiosResponse<{
        status: "success",
        consoleOutput: string, // log output
        userUpdatedResponseJSON: IData | null
    }>> {

        const reqBody = {aiAssistedLabellingId, codeToTest}

        const apiResponse = await this.api.post(`${URL_STRINGS.AUTOMATE_AI_ASSISTANT_LABELLING}/testCode`, reqBody)
        return apiResponse
    }

    async deploy( reqPayload: { 
        aiAssistantLabellingId: string
    }): Promise<any> {
        const apiResponse = await this.api.post(`${URL_STRINGS.AUTOMATE_AI_ASSISTANT_LABELLING}/deploy`, reqPayload)

        return apiResponse
    }

    async uploadFilesglobalpackageandrequiremnts(
        reqPayload : {
            aiAssistantLabellingId: string,
            globalPackageFileName?: string,
            librariesRequirementsFileName?: string,
            gcsCredentialJsonFileName?: string,
            modelFileName?: string
        }): Promise<any> {
        const apiResponse = await this.api.post(`${URL_STRINGS.AUTOMATE_AI_ASSISTANT_LABELLING}/getSignedUrl`, 
        reqPayload);
        return apiResponse.data;
    }
    async deleteAiAssistantLabellingFiles(queryParams:{
        aiAssistantLabellingId: string,
        deleteGlobalPackageFile?: "true" | "false",
        deleteLibrariesRequirementsFile?: "true" | "false",
        deleteGcsCredentialsJsonFile?: "true" | "false",
        deleteModelFile?: "true" | "false"
    }): Promise<any>{
        const apiResponse = await this.api.delete(`${URL_STRINGS.AUTOMATE_AI_ASSISTANT_LABELLING}?${
            queryObjectToString(queryParams)
        }`)
        return apiResponse.data
    }

    async getUniqueAssistantLabellingName(queryParams:{
            labellingName : string
        }):Promise<boolean>{
        const apiResponse = await this.api.get(`${URL_STRINGS.AUTOMATE_AI_ASSISTANT_LABELLING}/getUniqueAssistantNames?${queryObjectToString(queryParams)}`)
        return apiResponse.data as boolean;

    }
    async copyAiAssistantLabelling(
        reqPayload: { SourceAiAssistantLabellingId : string , DestinationAiAssistantLabellingId  : string }
    ): Promise<IAutomatedAiAssistantLabellingCopyData> {
        const apiResponse = await this.api.put(`${URL_STRINGS.AUTOMATE_AI_ASSISTANT_LABELLING}/copyAiAssistantLabelling` , 
        reqPayload
        )
        return apiResponse.data
    }

    async deleteAutomatedAiAssistantLabellingfromDBandGCS ( queryParams:{
        aiAssistantLabellingId : string
    } ): Promise<any> {
        const apiResponse = await this.api.delete(`${URL_STRINGS.AUTOMATE_AI_ASSISTANT_LABELLING}/deletelabelling?${
            queryObjectToString(queryParams)}`);

        return apiResponse

    }

    async suspendAutomatedAiAssistantLabelling ( queryParams:{
        aiAssistantLabellingId : string
    } ): Promise<any> {
        const apiResponse = await this.api.put(`${URL_STRINGS.AUTOMATE_AI_ASSISTANT_LABELLING}/${queryParams.aiAssistantLabellingId}/suspend`);
        return apiResponse
    }

    async resumeAutomatedAiAssistantLabelling ( queryParams:{
        aiAssistantLabellingId : string
    } ): Promise<any> {
        const apiResponse = await this.api.put(`${URL_STRINGS.AUTOMATE_AI_ASSISTANT_LABELLING}/${queryParams.aiAssistantLabellingId}/resume`);
        return apiResponse
    }

    async updateStatusAutomatedAiAssistantLabelling ( queryParams:{
        _id : string,
        status?: AiAssistantLabellingState,
    } ): Promise<any> {
        const apiResponse = await this.api.put(`${URL_STRINGS.AUTOMATE_AI_ASSISTANT_LABELLING}/cloudStatus`, queryParams);
        return apiResponse
    }
  
    async downloadUserCodeBaseZipFile(reqPayload :{aiAssistantLabellingId: string , code : string} ): Promise<
    AxiosResponse<{
        fileURL : string,
        fileName : string
    }>>{
        const apiResponse = await this.api.post(`${URL_STRINGS.AUTOMATE_AI_ASSISTANT_LABELLING}/downloadZip`, reqPayload);
        return apiResponse;
    }

    async uploadModelFileFromRLEFModelCollection(reqPayload: {
        aiAssistantLabellingId: string,
        modelFileName: string,
        modelsFileIdUsedForModelFile?: string,
        checkpointFileIdUsedForModelFile: string
    }): Promise<any>{
        const apiResponse = await this.api.put(`${URL_STRINGS.AUTOMATE_AI_ASSISTANT_LABELLING}/uploadModelFileFromRLEFModelCollection`, reqPayload);
        return apiResponse;
    }

    async fetchLatestStatusOfAiAssistedLabelling(_id: string): Promise<{ status: string }> {
        const apiResponse = await this.api.get(`${URL_STRINGS.AUTOMATE_AI_ASSISTANT_LABELLING}/latestStatus?aiAssistantLabellingId=${_id}`);
        return apiResponse.data;
    }

}

export default new AiAssistantLabellingService();

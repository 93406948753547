import {
    Button,
    Dialog,
    DialogActions,
    DialogContent,
    DialogTitle,
    makeStyles,
  } from "@material-ui/core";
  import ReportProblemIcon from "@material-ui/icons/ReportProblem";
  import { IReduxState } from "../../../../../../../../../../../common";
  import { closeConfirmDeleteCollection, deleteCollection } from "../../../../../../../../../../../store/actions";
  import { connect, ConnectedProps } from "react-redux";
import { useCallback } from "react";
  
  const useStyles = makeStyles((theme) => ({
    root: {
      "& > *": {
        display: "flex",
        alignItems: "center",
      },
      "& svg": {
        margin: "auto 10px",
        marginLeft: 0,
      },
    },
    dialog:{
      // padding: "20px"
    },
    boldText: {
      fontWeight: "bold",
    },
    dialogContent: {
      maxHeight: "350px",
      overflowY: "auto",
      marginBottom: "10px"
    },
    tableContainer: {
      minWidth: "500px",
    },
    dialogActions:{
        justifyContent: "space-between",
        padding: "24px"
    }
  }));
  
  interface IProps extends TPropsFromRedux {
    singleDeleteCallBack? : Function
    multiDeleteCallBack? : Function
    isParentPopup? : boolean
    iterateResource ?: () => any
  }

  const DeleteCollectionConfirmPopup = (props: IProps) => {
    const classes = useStyles();

    const deleteCollection =  useCallback(() => {
        let collectionIds: string[] = [];
        if(props.multiDelete){
            collectionIds = props.selectedIds
        }
        else{
            collectionIds = [props.collection?._id || ""]
        }
        if(props.collectionType === "dataSet"){
          props.deleteCollection({ collections: collectionIds , iterateResource : props.iterateResource});
        }
        else if(props.collectionType === "model" || props.collectionType === "test"){
          if(props.multiDelete){
            props?.multiDeleteCallBack && props.multiDeleteCallBack()
          }
          else{
            props?.singleDeleteCallBack && props?.singleDeleteCallBack(collectionIds[0])
          }
        }
       
    }, [props.multiDelete, props.collection, props.selectedIds, props.multiDeleteCallBack, props.singleDeleteCallBack])

    const multiCollectionContent = useCallback(() => {
      return (
        <>
          <div>Are you sure you want to delete selected Collections?</div>
          {
            props.collectionType === "dataSet" && 
            props.selectedDataSetCollections.map((collection, index) => {
              return(
                <div style={{marginTop: index=== 0 ? "10px" : "", "fontWeight": "bold"}} key={index}><div style={{marginRight: "15px", fontWeight: "lighter", display: "inline-block", minWidth: "17px"}}>{index + 1}</div>{collection.name}</div>
              )
            })
          }
          {
            props.collectionType === "model" && 
            props.selectedModelCollections.map((collection, index) => {
              return(
                <div style={{marginTop: index=== 0 ? "10px" : "", "fontWeight": "bold"}} key={index}><div style={{marginRight: "15px", fontWeight: "lighter", display: "inline-block", minWidth: "17px"}}>{index + 1}</div>{collection.version}</div>
              )
            })
          }
          {
            props.collectionType === "test" && 
            props.selectedTestCollections.map((collection, index) => {
              return(
                <div style={{marginTop: index=== 0 ? "10px" : "", "fontWeight": "bold"}} key={index}><div style={{marginRight: "15px", fontWeight: "lighter", display: "inline-block", minWidth: "17px"}}>{index + 1}</div>{collection.version}</div>
              )
            })
          }
        </>
      )
    },[props.selectedDataSetCollections, props.selectedModelCollections, props.selectedTestCollections, props.collectionType])

    return (
      <Dialog
        classes={{ paperWidthSm: classes.dialog }}
        open={props.showModal && ((props.deletedCollectionFromPopup && props.isParentPopup) || (!props.deletedCollectionFromPopup && !props.isParentPopup))}
        onClose={()=>props.closeConfirmDeleteCollection()}
        aria-labelledby="delete-collection-confirm-popup"
        aria-describedby="delete-collection-confirm"
      >
        <DialogTitle
          id="delete-collection-confirm"
          classes={{ root: classes.root }}
        >
          <ReportProblemIcon style={{color: "#f5c609"}} />
          {"Delete Collection(s)"}
        </DialogTitle>
        <DialogContent classes={{ root: classes.dialogContent }}>
        <span>
          {// @ts-ignore
            props.multiDelete ? multiCollectionContent() : <span>Are you sure you want to delete Collection : <span style={{"fontWeight": "bold"}}>{props.collection?.name || props.collection?.version}</span>?</span>
          }
        </span>
        </DialogContent>
        <DialogActions classes={{ root: classes.dialogActions }}>
            <Button
                onClick={()=>props.closeConfirmDeleteCollection()}
                autoFocus
            >
            Cancel
          </Button>
          <Button
            variant="contained" 
            onClick={deleteCollection}
            color="primary"
            autoFocus
          >
            OK
          </Button>
        </DialogActions>
      </Dialog>
    );
  };
  
  const mapProps = (state: IReduxState) => {
    return {
      showModal: state.collection.ConfirmDeleteModal.showConfirmDeleteModal,
      multiDelete : state.collection.ConfirmDeleteModal.multiDelete,
      collection : state.collection.ConfirmDeleteModal.collectionToDelete,
      selectedIds: state.collection.ConfirmDeleteModal.selectedCollectionIds,
      collectionType: state.collection.ConfirmDeleteModal.collectionType,
      selectedDataSetCollections : state.collection.dataSetCollectionsSelected,
      selectedModelCollections : state.collection.modelCollection.modelCollectionsSelected,
      selectedTestCollections : state.collection.testCollection.testCollectionsSelected,
      deletedCollectionFromPopup: state.collection.ConfirmDeleteModal.deletedCollectionFromPopup
    };
  };
  
  const connector = connect(mapProps, {
    deleteCollection,
    closeConfirmDeleteCollection
  });
  
  export type TPropsFromRedux = ConnectedProps<typeof connector>;
  
  export default connector(DeleteCollectionConfirmPopup);
  
import React, { Component, useEffect, useState, useRef, useMemo, ChangeEvent } from 'react';
import CloseIcon from '@material-ui/icons/Close';
import clsx from 'clsx';
import { createStyles, Theme, withStyles, makeStyles } from '@material-ui/core/styles';
import ExpandLessOutlinedIcon from '@material-ui/icons/ExpandLessOutlined';
import ThumbUpAltOutlinedIcon from '@material-ui/icons/ThumbUpAltOutlined';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import TableCell from '@material-ui/core/TableCell';
import ArrowForwardIcon from '@material-ui/icons/ArrowForward';
import TableContainer from '@material-ui/core/TableContainer';
import TableRow from '@material-ui/core/TableRow';
import Paper from '@material-ui/core/Paper';
import Checkbox from '@material-ui/core/Checkbox';
import IconButton from '@material-ui/core/IconButton';
import { Delete, Check, Restore, ThumbUp, Edit } from '@material-ui/icons';
import GetAppIcon from '@material-ui/icons/GetApp';
import { Dialog, Avatar, Chip, Grid, Fade, TablePagination, CircularProgress, Tooltip, TableHead, Typography, TableSortLabel, Popover, MenuItem, TextField, Box, Slider, Button, Switch, FormControl, FormGroup, FormControlLabel, ClickAwayListener, List, ListItem, ListItemText, Menu, TextareaAutosize, DialogActions } from '@material-ui/core';
// import { onSaveProps } from 'react-edit-text';
import FullscreenIcon from '@material-ui/icons/Fullscreen';
import { IData, IDataQueryParams, CustomSkeleton, DataSortField, SortOrder, DataTablePromptSearchOperatorQueryParam, DataTableResourceNameSearchValue, DataTableResourceNameSearchValueQueryParam, DataTablePromptSearchValue, DataTablePromptSearchOperatorDefaultValue, DataTablePromptSearchValueQueryParam, DataTableResourceLabelSearchValue, DataTableResourceLabelSearchValueQueryParam, IModelType, ImageList, AddToCart, RemoveFromCart, SaveForLaterInCart, SavedForLaterInCart, CartCheckIcon, CircleCheckIcon } from '../../../../../../../../../../../common';

import Audio from "../../../../../../../../../components/ModelData/Audio";
import { Skeleton } from '@material-ui/lab';
// import { EnhancedTableHead } from './EnhancedTableHead';
import { NoTableData } from '../constants';
import EnhancedTableToolbar from './EnhancedTableToolbar';
import { tableContentConnector, TPropsFromRedux } from '../store';
// import moment from 'moment';
import { match, RouteProps, RouterProps, withRouter } from 'react-router-dom';
import { Location } from 'history';
import DataView from './DataView';
import ModelAnalytics from '../../../../../components/Analytics';
import { deleteButtonIconColor, LABEL_GROUP_RESOURCE_LEVEL, LABEL_GROUP_TASK_LEVEL, tooltipTextForAddResourceDataSetCollectionButton, tooltipTextForApprovedIcon, tooltipTextForBacklogIcon, tooltipTextForDeleteIconWhenDeletingResource, tooltipTextForDeleteIconWhenMovingToDeleteTab, tooltipTextForEditResourceDataSetCollectionButton, tooltipTextForSetApprovalStatusButton, tooltipTextForSetRejectedStatusButton } from '../constants/constants';
import { tableContainerStyle } from '../../../constants/tableStyles';
import MoreVertIcon from '@material-ui/icons/MoreVert';
import FilterListIcon from '@material-ui/icons/FilterList';
import { DataTableResourceConfidenceScoreMaxValueQueryParam, DataTableResourceConfidenceScoreMinValueQueryParam, DataTableResourceStatusLastModifiedDateMaxValueQueryParam, DataTableResourceStatusLastModifiedDateMinValueQueryParam, DataTableResourceLabelSearchOperatorDefaultValue, DataTableResourceLabelSearchOperatorQueryParam, DataTableResourceNameSearchOperatorDefaultValue, DataTableResourceNameSearchOperatorQueryParam, DataTableResourcesWhichAreNotPartOfAnyDataSetCollectionQueryParam, DataTableResourceTagSearchOperatorDefaultValue, DataTableResourceTagSearchOperatorQueryParam, DataTableResourceTagSearchValue, DataTableResourceTagSearchValueQueryParam, IncludeOnlyMispredictedRowsQueryParam, IncludeOnlyMispredictedRowsQueryParamType, resourceConfidenceScoreDefaultMaxValue, resourceConfidenceScoreDefaultMinValue, resourceDataSetCollectionIdQueryParam, ResourceStatus, ResourcesWhichAreNotPartOfAnyDataSetCollectionQueryParamType, TableDataQueryParams, DataSetCollectionQueryParams, ForecastDateMinValueQueryParam, ForecastDateMaxValueQueryParam, DataTableResourcePredictionSearchValue, DataTableResourcePredictionSearchOperatorDefaultValue, DataTableResourcePredictionSearchValueQueryParam, DataTableResourcePredictionSearchOperatorQueryParam, videoAnnotation, ITextOriginalLanguageSentence, ITextTranslatedLanguageSentence,ExcludeMispredictedParagraphsParamType, IncludeOnlyMispredictedParagraphsParamType, ExcludeMispredictedParagraphsQueryParam, IncludeOnlyMispredictedParagraphsParam, PredictionStatusLogic, IDataResponse, ResourceDataSetCollection, resourceIdURLQueryParam } from '../../../../../../../../../../../common/constants/interfaces/data';
import DataRowExpandIconButton from '../../../components/DataRowExpandIconButton';
import { InputFieldState } from '../../../../../../../../../../../common/constants/interfaces/inputFieldState';
import { isElementVisibleToUserInsideScrollElement } from '../../../../../../../../../../../services/domHelperService';
import { convertStringtoContainsExpressionInRegex, convertStringtoEndsWithExpressionInRegex, convertStringtoEqualsExpressionInRegex, convertStringtoStartsWithExpressionInRegex } from '../../../../../../../../../../../services/regexHelperService';
import { CSSProperties } from '@material-ui/styles';
import { TableColumnResetFilterButton } from './TableColumnResetFilterButton';
import { isNullOrUndefined } from '../../../../../../../../../../../services/variableHelperService';
import TagEditText from './TagEditText';
import LabelEditText from './LabelEditText';
import AddResourceToDataSetCollectionButton from './AddResourceToDataSetCollectionButton';
import AddOrDeleteResourcesToDataSetCollectionSelectionPopup from './AddOrDeleteResourcesToDataSetCollectionSelectionPopup';
import { Autocomplete } from '@material-ui/lab';
import { DataService, ModelService } from '../../../../../../../../../../../services';
import Card from '@material-ui/core/Card';
import CardHeader from '@material-ui/core/CardHeader';
import CardMedia from '@material-ui/core/CardMedia';
import CardContent from '@material-ui/core/CardContent';
import CardActions from '@material-ui/core/CardActions';
import { red } from '@material-ui/core/colors';
import SetApprovalStatusButton from './SetApprovalStatusButton';
import SetRejectedStatusButton from './SetRejectedStatusButton';
import ViewForcastModelPopup from './ViewForcastModelPopup';
import { TimeDateFormat } from './TimeDateFormat';
import ViewAndTrimAudioResource from './ViewAndTrimAudioResource';
import { IModel, ModelAnnotationLabelAvailable, ModelFields } from '../../../../../../../../../../../common/constants/interfaces/models';
import { areBothArraysEqual } from '../../../../../../../../../../../services/arrayHelperService';
import axios, { AxiosResponse, Canceler } from 'axios';
import { generateUniqueId } from '../../../../../../../../../../../services/idHelperService';
import AddModelResourceToCopilotDialog from './AddModelResourceToCopilotDialog';
import ResourcesTableImageKeyPointsDialogWrapper from './ResourcesTableImageKeyPointsDialogWrapper';

import css from "./TableContent.module.css";

import InsertDriveFileIcon from '@material-ui/icons/InsertDriveFile';
import useDebounce from '../../../../../../../../../../../hooks/useDebounce';
import VideoAnnotationDialogBox from './VideoAnnotationDialogBox.component';
import AiAssistantIcon from './AiAssistantIcon/AiAssistantIcon';
import InfoPopover from './InfoPopover';
import { themePrimaryMainColor } from '../../../../../../../../../../../theme';
import ReactFlow, { Connection, Edge, PanOnScrollMode, Node } from 'reactflow';
import { useCallback } from 'react';
import { Handle, Position } from 'reactflow';
import { convertStringToUpperCase } from '../../../../../../../../../../../services/stringHelperService';
import "./TableContent.css";
import { ShowResourceDetails } from './ImageKeyPointsEditDialog';
import { useDispatch } from "react-redux";
import { updateResourceRemarks } from '../../../../../../../../../../../store/actions';
import EyeIcon from '../../../../../../../../../../../assets/svg/eye-icon.svg';
import iso6391 from 'iso-639-1';
import { ImageRelatedModelReferenceData } from '../../../../../../../../../../../common/constants/interfaces/imageRelatedModelReferenceData';
import { RESOURCE_IDS_TO_FILTER } from '../../collections/components/ModelAnalyticsResourseComponent';
import EditImage from './EditImage/EditImage';
import DataSetCollection from '../../collections/components/DataSet-Collection';
import BrowseCollectionsPopupContext from '../../../../../browseCollectionsPopupContext/browseCollectionsPopup.context';
import CopyImageInMultipleImageAnnotationToImageAnnotationGroupDialog from './copyImageInMultipleImageAnnotationToImageAnnotationGroup/copyImageInMultipleImageAnnotationToImageAnnotationGroup';
import DeleteIcon from '@material-ui/icons/Delete';
import { AUDIO_TEXT_TRANSCRIPTION_RESOURCE_DEFAULT_TEXT_ONE_HEADING_FOR_UI, AUDIO_TEXT_TRANSCRIPTION_RESOURCE_DEFAULT_TEXT_TWO_HEADING_FOR_UI } from './AudioTrimUI/AudioTrimUI';
import CheckIcon from '@material-ui/icons/Check';
import CheckBoxRoundedIcon from '@material-ui/icons/CheckBoxRounded';
import { AiAssistedLabellingDetailedViewQueryParams } from '../../../../../../../../../../../services/automatedAiAssistantLabellingService';
import { CheckIfComponentRendered } from '../../../../../../../../../../../common/components/CheckIfComponentRendered';
import { RemoveResourcesFromDataSetCollectionSelectionPopup } from './RemoveResourcesFromDataSetCollectionSelectionPopup';
import {ReactComponent as DataSetsResourcesRemoveIcon} from "../../../../../../../../../../../assets/svg/Remove from Group.svg";
import CustomTooltip from '../../../../../../../../../../../common/components/tooltip/Tooltip';
import { BrowserUrlService } from '../../../../../../../../../../../services/browserUrlService';

export const SearchOperatorAvailableValues = [
    'contains' , 'equals' , 'starts with' , 'ends with'
];

export type ResourceTagSearchOperator = 'contains' | 'equals' | 'starts with' | 'ends with';
export type ResourceNameSearchOperator = 'contains' | 'equals' | 'starts with' | 'ends with';
export type PromptSearchOperator = 'contains' | 'equals' | 'starts with' | 'ends with';
export type ResourceLabelSearchOperator = 'contains' | 'equals' | 'starts with' | 'ends with';
export type ResourcePredictionSearchOperator = 'contains' | 'equals' | 'starts with' | 'ends with';

export const ResourceOffsetQueryParam = "resourceOffset";
export const ResourceLimitQueryParam = "resourceLimit";
export const ResourceStatusQueryParam = "resourceStatus";
export const ResourceSortOrderQueryParam = "resourceSortOrder";
export const ResourceSortFieldQueryParam = "resourceSortField";
export const ResourceDataCollectionScenarioValue = "DataSetCollectionViewResourceDetails"
export const OneMB = 1;

export function getLabelsOfVideoAnnotationResourceThatAreNotApprovedOrRejectedByExpert(videoAnnotations: IData["videoAnnotations"]): string[] {
    let labelsOfVideoAnnotationResourceThatAreNotApprovedOrRejectedByExpert: string[] = videoAnnotations?.map((annotation, index) => {
        // if (annotation?.annotationPrediction?.approvalStatus !== "approved") {
        //   if (annotation?.annotationByExpert?.approvalStatus !== "approved") {
        //     return annotation.label;
        //   }
        // }
        if (
            annotation?.annotationByExpert?.approvalStatus ||
            annotation?.annotationPrediction?.approvalStatus
        ) {
            return ""
        } else {
            return annotation.label;
        }
        
        return '';
    }) || [];

    labelsOfVideoAnnotationResourceThatAreNotApprovedOrRejectedByExpert = labelsOfVideoAnnotationResourceThatAreNotApprovedOrRejectedByExpert?.filter(label => label !== '') || [];
    return labelsOfVideoAnnotationResourceThatAreNotApprovedOrRejectedByExpert;
}

export function convertTagSearchOperatorToRegex(searchOperator: ResourceTagSearchOperator, searchValue: string): string {
    if (!searchValue) {
        return searchValue;
    }
    if (searchOperator === 'contains') {
        return convertStringtoContainsExpressionInRegex(searchValue);
    } else if (searchOperator === 'equals') {
        return convertStringtoEqualsExpressionInRegex(searchValue);
    } else if (searchOperator === 'starts with') {
        return convertStringtoStartsWithExpressionInRegex(searchValue);
    } else if (searchOperator === 'ends with') {
        return convertStringtoEndsWithExpressionInRegex(searchValue);
    } else {
        return searchValue;
    }
}

export function convertPromptSearchOperatorToRegex(searchOperator: PromptSearchOperator, searchValue: string): string {
    if (!searchValue) {
        return searchValue;
    }
    if (searchOperator === 'contains') {
        return convertStringtoContainsExpressionInRegex(searchValue);
    } else if (searchOperator === 'equals') {
        return convertStringtoEqualsExpressionInRegex(searchValue);
    } else if (searchOperator === 'starts with') {
        return convertStringtoStartsWithExpressionInRegex(searchValue);
    } else if (searchOperator === 'ends with') {
        return convertStringtoEndsWithExpressionInRegex(searchValue);
    } else {
        return searchValue;
    }
}

export function convertNameSearchOperatorToRegex(searchOperator: ResourceNameSearchOperator, searchValue: string): string {
    if (!searchValue) {
        return searchValue;
    }
    if (searchOperator === 'contains') {
        return convertStringtoContainsExpressionInRegex(searchValue);
    } else if (searchOperator === 'equals') {
        return convertStringtoEqualsExpressionInRegex(searchValue);
    } else if (searchOperator === 'starts with') {
        return convertStringtoStartsWithExpressionInRegex(searchValue);
    } else if (searchOperator === 'ends with') {
        return convertStringtoEndsWithExpressionInRegex(searchValue);
    } else {
        return searchValue;
    }
}

export function convertLabelSearchOperatorToRegex(searchOperator: ResourceLabelSearchOperator, searchValue: string[]): string[] {
    if(searchValue.length === 0) {
        return searchValue;
    }
    if (!searchValue) {
        return searchValue;
    }
    if (searchOperator === 'contains') {
        return searchValue.map((value) => convertStringtoContainsExpressionInRegex(value));
    } else if (searchOperator === 'equals') {
        return searchValue.map((value) => convertStringtoEqualsExpressionInRegex(value));
    } else if (searchOperator === 'starts with') {
        return searchValue.map((value) => convertStringtoStartsWithExpressionInRegex(value));
    } else if (searchOperator === 'ends with') {
        return searchValue.map((value) => convertStringtoEndsWithExpressionInRegex(value));
    } else {
        return searchValue;
    }
}

export function convertPredictionSearchOperatorToRegex(searchOperator: ResourcePredictionSearchOperator, searchValue: string[]): string[] {
    if(searchValue.length === 0) {
        return searchValue;
    }
    if (!searchValue) {
        return searchValue;
    }
    if (searchOperator === 'contains') {
        return searchValue.map((value) => convertStringtoContainsExpressionInRegex(value));
    } else if (searchOperator === 'equals') {
        return searchValue.map((value) => convertStringtoEqualsExpressionInRegex(value));
    } else if (searchOperator === 'starts with') {
        return searchValue.map((value) => convertStringtoStartsWithExpressionInRegex(value));
    } else if (searchOperator === 'ends with') {
        return searchValue.map((value) => convertStringtoEndsWithExpressionInRegex(value));
    } else {
        return searchValue;
    }
}

const ForceFetchDataFromDBQueryParam: string = 'ForceFetchDataFromDB';

const useStyles = (theme: Theme) =>
    createStyles({
        paper: {
            width: "100%",
            marginBottom: theme.spacing(2),
            height: "calc(100% - 47px)",
        },
        paperWhenScenarioDataSetCollectionTableDetailsPopup: {
            height: "calc(100% - -24px)",
        },
        selectAllDataText: {
            cursor: 'pointer',
            color: theme.palette.primary.main
        },
        clearDataSelectionText: {
            cursor: 'pointer',
            color: theme.palette.primary.main
        },
        dataSetCollectionFilterAutoCompleteChipRoot: {
            maxWidth: "159px",
          },
        table: {
            // minWidth: 750,
            tableLayout: 'fixed'
        },
        tableRow: {
            width: "100%"
          },
        tableCellCheckbox: {
            width: `46px !important`
        },        
        tableCellName: {
            width: "212px",
            paddingRight: 0,
            paddingLeft: 16,
        },
        forecastingTableCellName:{
            width: "100px",
            paddingLeft: 16,
        },        
        tableCellLabel: {
            // width: "180px"
            '@media(min-width:1300px)': {
                width: "auto",
            },
            '@media(min-width:1000px) and (max-width: 1300px)': {
                width: "400px",
            },
            '@media(min-width:700px) and (max-width: 1000px)': {
                width: "350px",
            },
            '@media(max-width: 700px)': {
                width: "300px",
            }, 
            paddingRight: 0,
            paddingLeft: 0,
        }, 
        tableCellLabelForForcastingModel: {
            // width: "180px"
            maxWidth: "275px",
            minWidth: "250px !important",
            width: "auto",
            paddingRight: 0,
            paddingLeft: 0,
        },   
        tagEditText: {
            outlineColor: theme.palette.primary.light,
        },        
        labelEditText: {
            outlineColor: theme.palette.primary.light,
            borderWidth: 0, borderRadius: 20,
            textAlign: "center", width: "92% !important"
        },        
        tableCellConfidenceScore: {
            // width: "199px",
            // @ts-ignore
            width: (props)=>props.isSideDrawerOpen ? 'auto' : "199px",
            // width: "auto",
            paddingRight: 0,
            paddingLeft: 0,
            textAlign: 'center'
        },        
        tableCellTags: {
            // width: "161px",
            // width: "191px",
            // @ts-ignore
            width: (props)=>props.isSideDrawerOpen ? 'auto' : "191px",
            paddingRight: 0,
            paddingLeft: 0,
            textAlign: 'center'
        },   
        tableColumnMenuHeading: {
            display: 'flex', justifyContent: 'space-between'
        },     
        tableCellDate: {
            // width: "200px"
            width: "auto",
            paddingRight: 0,
            paddingleft: 0,
            textAlign: 'center'
        }, 
        borderStyled: {
          "& .MuiOutlinedInput-root .MuiOutlinedInput-notchedOutline" : {
            borderColor : "#707070",
          },
        },
        tableCellDateForForecastingModel: {
            // width: "200px"
            maxWidth: "9.7rem !important",
            width: "10%",
            paddingRight: 0,
            paddingleft: 0,
            textAlign: 'center'
        },       
        tableCellActions: {
            // width: "115px",
            width: "180px",
            textAlign: 'center'
        },
        tableCellDataSource: {
            width: 160,
            textAlign: 'center'
        },
        tableCellActionsInAiMarketplaceScenario: {
            width: 100,
            textAlign: 'right',
            padding: 0
        },
        tableCellActionsWhenApprovedStatus: {
            '@media(min-width:1350px)': {
                width: "190px",
            },
            '@media(max-width: 1350px)': {
                width: "150px",
            }, 
        },     
        tableCellActionsWhenApprovalStatus: {
            '@media(min-width:1350px)': {
                width: "190px",
            },
            '@media(max-width: 1350px)': {
                width: "150px",
            }, 
        },
        tableCellActionsForForecastingModel: {
            width: "130px",
        },  
        tableCellLastModified: {
            '@media(min-width:1300px)': {
                width: "auto",
            },
            '@media(min-width:1000px) and (max-width: 1300px)': {
                width: "250px",
            },
            '@media(min-width:700px) and (max-width: 1000px)': {
                width: "200px",
            },
            '@media(max-width: 700px)': {
                width: "150px",
            }, 
            paddingRight: 0,
            paddingleft: 0,
            textAlign: 'center'
        }, 
        detectedAndExpertField: {
          '@media(min-width:1400px)': {
              width: "auto",
          },
          '@media(min-width:1000px) and (max-width: 1400px)': {
              width: "125px",
          },
          '@media(min-width:700px) and (max-width: 1000px)': {
              width: "200px",
          },
          '@media(max-width: 700px)': {
              width: "150px",
          }, 
          paddingRight: 0,
          paddingleft: 0,
          textAlign: 'center'
      },      
        columnOptionsIcon: {
            paddingLeft: 0,
            paddingRight: 0,
        },   
        visuallyHidden: {
            border: 0,
            clip: 'rect(0 0 0 0)',
            height: 1,
            margin: -1,
            overflow: 'hidden',
            padding: 0,
            position: 'absolute',
            top: 20,
            width: 1,
        },
        image: {
            borderWidth: '2px',
            borderColor: '#fff',
            borderStyle: 'solid',
            boxShadow: theme.shadows[1],

        },
        sortLabelIcon: {
            display: 'none'
        },
        sortLabelRoot: {
            "&:hover .MuiTableSortLabel-icon": {
                display: 'flex'
            }
        },
        sortLabelActive: {
            minWidth: '111px',
            "& .MuiTableSortLabel-icon": {
                display: 'flex'
            }
        },
        chip: {
            // minWidth: '100px',
            backgroundColor: theme.palette.grey[50],
        },
        actions: {
            minHeight: '48px',
            display: 'flex',
            alignItems: 'center',
            width: '100%'
        },
        actionsContainer: {
            width: '150px'
        },
        delete: {
            color: deleteButtonIconColor
        },
        progress: {
            padding: '10px',
            width: '48px', 
            height: '39px'
        },
        modelType: {
            background: '#d4e8ea',
            width: '45px',
            height: '45px',
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            borderRadius: '50%',
        },
        modelTypeIcon: {
            fontSize: '20px',
            color: theme.palette.primary.main
        },
        tableFixHeader: {
            position: "sticky",
            top: "0px",
            backgroundColor: "#f0f5fc",
            zIndex: 1,
        },
        tableContainer: tableContainerStyle,
        tableContainerWhenApprovedTab: {
            // height: "calc(100% - 115px)"
        },
        grid: {
            midWidth: '220px',
          },
          media: {
            height: 0,
            paddingTop: '56.25%', // 16:9
          },
          expand: {
            transform: 'rotate(0deg)',
            marginLeft: 'auto',
            transition: theme.transitions.create('transform', {
              duration: theme.transitions.duration.shortest,
            }),
          },
          expandOpen: {
            transform: 'rotate(180deg)',
          },
          avatar: {
            backgroundColor: red[500],
        },
        floating: {
            "& legend": {
                float: "unset"
            }
        },
        dataSetFilterAutoComplete:{
            zIndex: 100,
            position: "absolute",
            background: "#ffff"
        },
        lastModifiedTime: {
            color: '#008D9C',
            fontSize: '16px',
        },
        lastModifiedDate: {
            color: '#212121',
            fontSize: '16px'
        },
        dataCount:{
            maxWidth: "110px",
            width: "7.4%",
            textAlign: 'center'
        },
        autoCompleteChipRoot: {
            maxWidth: "170px",
        },
        tableCellForcastedDate: {
            width: "auto"
        },
        tableCellForcastedDateWhenScenarioDataSetCollectionTableDetailsPopup: {
            width: "153px"
        },
        tableCellForcastPredictedCountWhenScenarioDataSetCollectionTableDetailsPopup: {
            width: "105px"
        },
        tableCellCvAiCountWhenScenarioDataSetCollectionTableDetailsPopup: {
            width: "95px"
        },
        tableCellDataEntryCountWhenScenarioDataSetCollectionTableDetailsPopup: {
            width: "84px"
        }        
    });

interface IProps extends TPropsFromRedux {
    isLoading?: boolean;
    isprediction?: boolean
    match: match<{ id: string }>;
    isPageOpenFromHomePageChatModelSummaryPage?:boolean;
    
    /**
     * @description
     * Below field will be populated when rendering tableContent inside data-set collection details popup
     */
    dataSetCollectionIdSelectedInDataSetCollectionDetailsPopup?: string | undefined;

    scenario: "dataSetCollectionTableDetailsPopup" | "dataCart" | "browseCollectionsPopup" | "savedForLater" | "aiMarketPlaceDataCart" | "aiMarketplaceDataSelection";
    rowsForDataCart?: IData[];
    currentSelectedItems?: IData[];
    setCurrentSelectedItems?: (newState: IData[]) => void;
    classes: any | {
        paper: string;
        table: string;
        tableRow: string;
        tableCellCheckbox: string;
        tableCellName: string;
        tableCellLabel: string;
        tableCellConfidenceScore: string;
        tableCellTags: string;
        tableCellDate: string;
        tableCellActions: string;
        visuallyHidden: string;
        image: string;
        chip: string;
        actions: string;
        actionsContainer: string;
        delete: string;
        progress: string;
        modelType: string;
        modelTypeIcon: string;
        tableFixHeader: string;
        tableContainer: string;
        tagEditText: string;
        labelEditText: string;
    },

    isUsingDataCartForAiAssistedlabellingCodeTesting: boolean
}

export type AiAssistantTooltip = {
    open: boolean;
    scenario: "warning" | "description" | "";
    AiAssistantApiBeingCalledForResourceIds: string[];
};

interface IState {
    // selectedItems: string[];
    changedResources: IData [],
    allSelected: boolean,
    isViewForcastModelPopupOpen: boolean,
    predictionStatusLogic: PredictionStatusLogic,
    maximumMisPredictionDeviationAllowedInNumber: number,
    multipleImageAnnotationResourceImageIndex: number,
    isVideoAnnotationDialogBoxOpen: boolean,
    projectModels: IModel[],
    showMetaDataForTextComparisonModel?: boolean,
    copyImageInMultipleImageAnnotationToImageAnnotationGroupPopup: {
        isOpen: boolean,
        resource: IData | undefined,
    },
    isViewAndTrimAudioResourcePopupOpen : boolean,
    modelMaxAudioTrimAllowedInMilliSeconds: number,
    modelAnnotationLabelsAvailable: ModelAnnotationLabelAvailable[]
    resourceTableHeadColumnsLength: number,
    getDataApiCancelFunction: Canceler | null,
    resourcesTableContainerElementId: string,
    resourcesGridViewContainerElementId: string,    
    imageList: ImageList[],
    currentModelType: IModelType | "",
    textOneHeadingForTextComparisonUI: string | undefined
    textTwoHeadingForTextComparisonUI: string | undefined
    isprediction: Boolean,
    rowsPerPage: number;
    page: number;
    status: ResourceStatus;
    modelId: string;
    label: string;
    index: number;
    expanded: Boolean;
    sort: {
        order: SortOrder,
        field: DataSortField
    },
    resourceIdsSelectedForAddingToDataSetCollection: string[],
    isAddResourcesToDataSetCollectionPopupShowing: boolean,
    labelsAvailable: string[],
    filter: {
        resourcePrompt: {
            searchValue: DataTablePromptSearchValue,
            searchOperator: PromptSearchOperator,
            includeOnlyMispredictedParagraphs:IncludeOnlyMispredictedParagraphsParamType,
            excludeMispredictedParagraphs:ExcludeMispredictedParagraphsParamType,
            menuOpen: boolean
        },
        resourceName: {
            searchValue: DataTableResourceNameSearchValue,
            searchOperator: ResourceNameSearchOperator,
            includeOnlyMispredictedParagraphs:IncludeOnlyMispredictedParagraphsParamType,
            excludeMispredictedParagraphs:ExcludeMispredictedParagraphsParamType,
            menuOpen: boolean
        },
        resourceTag: {
            searchOperator: ResourceTagSearchOperator,
            searchValue: DataTableResourceTagSearchValue,
            menuOpen: boolean
        },
        resourceLabel: {
            searchValue: DataTableResourceLabelSearchValue,
            searchOperator: ResourceLabelSearchOperator,
            includeOnlyMispredictedRows: IncludeOnlyMispredictedRowsQueryParamType,
            menuOpen: boolean,
            predictionSearchValue: DataTableResourcePredictionSearchValue,
            predictionSearchOperator: ResourcePredictionSearchOperator,
        },
        resourceConfidenceScore: {
            minValue: number,
            maxValue: number,
            menuOpen: boolean
        },
        resourceStatusLastModifiedDate: {
            minValue: string,
            maxValue: string,
            menuOpen: boolean            
        },
        forecastDate: {
            minValue: string,
            maxValue: string,
            menuOpen: boolean            
        },
        resourceDataSetCollectionIds: string[]
        resourcesWhichAreNotPartOfAnyDataSetCollection: ResourcesWhichAreNotPartOfAnyDataSetCollectionQueryParamType
    },
    addResourceToCopilotDialogState : {
        isOpen : boolean
    },
    imageKeyPointsDialog: {
        isOpen: boolean,
    },
    uniqueResourceTagsAvailable: string[],
    allowUserToSetAnyLabel: boolean,
    modelAiAssistantEnabled: boolean,
    modelApiToCallOnAiAssistantInvocation: string,
    modelAiAssistantDescription: string,
    aiAssistantTooltip: AiAssistantTooltip   ,
    anchorElement :  HTMLButtonElement | null,
    labelsToPassWhileApprovingVideoForecastingModelInInfoPopup : string[],
    bookTranslationResourceIdWhoseViewButtonIsExpanded: string[],
    bookTranslationResourceIdsEnableApprove: string[]
    bookTranslationParagraphsWhichAreChanged: string[],

    // image related model data (image, imageAnnotation and imageAnnotationGroup)
    isFetchingModelReferenceData: boolean,
    modelReferenceData: ImageRelatedModelReferenceData[],
    isImageEditingPopupOpen: boolean,

    removeResourcesFromDataSetCollectionSelectionPopup: {
        open: boolean
    },
    resourceIdsWhichAreBeingDeleted: string[],
}

function ViewForcastModelPopupOpenButton(props: { 
    onClick?: () => void,
    iconButtonStyles?: {},
    scenario?: "dataSetCollectionTableDetailsPopup" | "dataCart" | "browseCollectionsPopup" | "savedForLater" | "aiMarketPlaceDataCart" | "aiMarketplaceDataSelection"
 }) {

    const FullScreenButton = withStyles({
        root: {
          textTransform: 'none',
        },
      })(Button);

    return (
        <div style={{width:'100%',display:'flex',justifyContent:'center'}}>
            <FullScreenButton 
            startIcon={(props.scenario === "dataSetCollectionTableDetailsPopup" || props.scenario === "browseCollectionsPopup" || props.scenario === "aiMarketPlaceDataCart" || props.scenario === "aiMarketplaceDataSelection") ? undefined :<FullscreenIcon/>}
            onClick={() => {
            if (props.onClick) {
              props.onClick();
            }
          }}
          color='primary' variant='contained'>
              {
                  (props.scenario === "dataSetCollectionTableDetailsPopup" || props.scenario === "browseCollectionsPopup" || props.scenario === "aiMarketPlaceDataCart" || props.scenario === "aiMarketplaceDataSelection")
                  ? <FullscreenIcon />
                  : "View"
              }              
            </FullScreenButton>
        </div>
    );
}

// to be used for validating the type of resource and download only valid resources
const commonFileExtensions = [
    // Audio file extensions
    'mp3',
    'wav',
    'ogg',
    'aac',
    'flac',
    'm4a',
  
    // Video file extensions
    'mp4',
    'avi',
    'mkv',
    'mov',
    'wmv',
  
    // Image file extensions
    'jpg',
    'jpeg',
    'png',
    'gif',
    'bmp',
    'tiff',
  
    // Document file extensions
    'pdf',
    'doc',
    'docx',
    'xls',
    'xlsx',
    'ppt',
    'pptx',
  
    // Archive file extensions
    'zip',
    'rar',
    'tar',
    'gz',
  
    // Text file extensions
    'txt',
    'csv',
    'xml',
  
    // Code file extensions
    'js',
    'jsx',
    'html',
    'css',
    'json',
    'java',
    'py',
  
    // Other common file extensions
    'exe',
    'dll',
    'dat',
    'iso',
    'db',
    'dbf'
  ];

function ViewExpandBookTranslationModelParagraph(props: {
    onClick?: () => void,
    iconButtonStyles?: {},
    scenario?: "dataSetCollectionTableDetailsPopup" | "dataCart" | "browseCollectionsPopup" | "savedForLater" | "aiMarketPlaceDataCart" | "aiMarketplaceDataSelection"
}) {

    const FullScreenButton = withStyles({
        root: {
            textTransform: 'none',
        },
    })(Button);

    const [isIconUp, setIsIconUp] = useState(false);

    return (
        <div style={{ width: '100%', display: 'flex', justifyContent: 'center' }}>
            <Button
                endIcon={isIconUp ? <ExpandLessOutlinedIcon /> : <ExpandMoreIcon />}
                onClick={() => {
                    if (props.onClick) {
                        props.onClick();
                        setIsIconUp((prev) => !prev)
                    }
                }}
                color='primary' variant='contained'>
                View
            </Button>
        </div>
    );
}

type NodeData = {
    id: string,
    type: string,
    data: { label: string, isOriginal: boolean, width: number, confidence: number | string, isApprovedByUser: boolean | undefined, sentenceId: string, hasTranslation: boolean, predictedSentence: string },
    position: { x: number, y: number }
}

const useStylesForLinePairingComp = makeStyles((theme) => ({
  dialogContainer: {
    overflow: 'visible'
  },
  dialogContainerWidth: {
    padding: "2%",
    paddingTop: "25px",
  },
  customTooltip: {
    maxWidth: "500px",
    backgroundColor: '#CCE8EB',
    color: '#008D9C',
    fontSize: '12px',
  },
}));

const LinePairingforParagraphInBookTranslationModel = (props: { paragraphData: IData, LabelsAvailable ?: string[], allowUserToSetAnyLabel: boolean, setShouldBlockDataTableKeyboardEvents : Function, selectedSubTab: ResourceStatus, addParagraphToChangedList: (id: string) => void, addParagraphIdToEnableApprove: (id: string) => void, removeParagraphIdtoDisableApprove: (id: string) => void, updateDataChangedList: (id: string, latestData: IData) => void, updateResourceInUI: (resourceData: any) => Promise<void>}) => {
    const classes = useStylesForLinePairingComp();
    const [nodes, setNodes] = useState<NodeData[]>([]);
    const [edges, setEdges] = useState<Edge[]>([]);
    const [isEnableEdit, setIsEnableEdit] = useState<boolean>(false);
    const [transtionSentencesCopy, setTranstionSentencesCopy] = useState<ITextTranslatedLanguageSentence[] | undefined>(props.paragraphData.textTranslatedLanguageSentences?JSON.parse(JSON.stringify(props.paragraphData.textTranslatedLanguageSentences)):undefined)
    const [translatedLanguageSentences, setTranslatedLanguageSentences] = useState<ITextTranslatedLanguageSentence[] | undefined>(props.paragraphData.textTranslatedLanguageSentences?JSON.parse(JSON.stringify(props.paragraphData.textTranslatedLanguageSentences)):undefined)
    const [originalSentencesCopy, setOriginalSentencesCopy] = useState<ITextOriginalLanguageSentence[] | undefined>(props.paragraphData.textOriginalLanguageSentences?JSON.parse(JSON.stringify(props.paragraphData.textOriginalLanguageSentences)):undefined)
    const [originalLanguageSentences, setOriginalLanguageSentences] = useState<ITextOriginalLanguageSentence[] | undefined>(props.paragraphData.textOriginalLanguageSentences?JSON.parse(JSON.stringify(props.paragraphData.textOriginalLanguageSentences)):undefined)
    const [isApproveLineLoading, setIsApproveLineLoading] = useState<string[]>([])
    const [edgeContextMenu, setEdgeContextMenu] = useState<{
        isOpen: boolean;
        mouseX: number;
        mouseY: number;
        edge: Edge | null;
      }>({
        isOpen: false,
        mouseX: 0,
        mouseY: 0,
        edge: null,
      });
    const [nodeContextMenu, setNodeContextMenu] = useState<{
        isOpen: boolean;
        mouseX: number;
        mouseY: number;
        node: Node | null;
      }>({
        isOpen: false,
        mouseX: 0,
        mouseY: 0,
        node: null,
      });
    const [isLinePairUpdating, setIsLinePairUpdating] = useState<boolean>(false)
    const [showAdditionalDataPopup, setShowAdditionalDataPopup] = useState<boolean>(false);
    const [nodeContextDialog, setSetNodeContextDialog] = useState<{
        isOpen: boolean;
        node: Node | null;
      }>({
        isOpen: false,
        node: null
      })
    const [showEditSentenceDialog, setShowEditSentenceDialog] = useState<boolean>(false);
    const [resourceRemarksTextState, setResourceRemarksTextState] = useState<InputFieldState<string>>({ value: props?.paragraphData?.remarks?.text || "" });
    const dispatch = useDispatch();
    const [translationApiUrl, setTranslationApiUrl] = useState<string>("");
    const [viewPredictedTranslationInOriginalLanguage, setViewPredictedTranslationInOriginalLanguage] = useState<boolean>(false)
    const [disableViewsAsTranslatedSentenceInOriginalLangeAnchorEl, setDisableViewsAsTranslatedSentenceInOriginalLangeAnchorEl] = React.useState<HTMLInputElement | null>(null);
    const [viewsAsBtnDisableText, setViewAsBtnDisableText] = useState<string>("")
    const [fetchingTranslations, setFetchingTranslations] = useState<boolean>(false)
   
  
    const open = Boolean(disableViewsAsTranslatedSentenceInOriginalLangeAnchorEl);
    const id = open ? 'simple-popover' : undefined;

    const toggleApproveLine = async (id: string, isOriginal: boolean) => {
       
        if (isOriginal) {
            setOriginalLanguageSentences((originalSentence) => {
                if (originalSentence === undefined) return;
                const approvedSentence = originalSentence?.find((sentence) => sentence.sentenceId === id)
                if (approvedSentence === undefined) return;
                return [...originalSentence
                    .filter((sentence) => sentence.sentenceId !== id),
                {
                    ...approvedSentence, isApprovedByUser: true
                }
                ]
            })
            if (!isEnableEdit) {
                const approvedSentence = originalLanguageSentences?.find((sentence) => sentence.sentenceId === id)
                if (originalLanguageSentences === undefined || approvedSentence === undefined) return;

                await DataService.updateData({
                    id: [props.paragraphData._id],
                    textOriginalLanguageSentences: [...originalLanguageSentences
                        .filter((sentence) => sentence.sentenceId !== id),
                    {
                        ...approvedSentence, isApprovedByUser: true
                    }
                    ],
                })
                generateNodesAndEdges();
                const {data: lastestResource} = await DataService.getResourceFromDBById({
                    resourceId: props.paragraphData._id
                })
        
                props.updateDataChangedList(props.paragraphData._id, lastestResource)
                checkIfParagraphEnableApprove(id);
            }
        }
        else {
            setTranslatedLanguageSentences((translatedSentence) => {
                if (translatedSentence === undefined) return;
                const approvedSentence = translatedSentence?.find((sentence) => sentence.sentenceId === id)
                if (approvedSentence === undefined) return;
                return [...translatedSentence
                    .filter((sentence) => sentence.sentenceId !== id),
                {
                    ...approvedSentence, isApprovedByUser: true
                }
                ]
            })

            console.log("updated translations", nodes)

            if (!isEnableEdit) {
                const approvedSentence = translatedLanguageSentences?.find((sentence) => sentence.sentenceId === id)
                if (translatedLanguageSentences === undefined || approvedSentence === undefined) return;

                await DataService.updateData({
                    id: [props.paragraphData._id],
                    textTranslatedLanguageSentences: [...translatedLanguageSentences
                        .filter((sentence) => sentence.sentenceId !== id),
                    {
                        ...approvedSentence, isApprovedByUser: true
                    }
                    ],
                })
                generateNodesAndEdges();

                console.log("updated translations", nodes)
                
                const {data: lastestResource} = await DataService.getResourceFromDBById({
                    resourceId: props.paragraphData._id
                })
                checkIfParagraphEnableApprove(id);
                props.updateDataChangedList(props.paragraphData._id, lastestResource)
            }
        }
    }  
    
    const toggleApproveLineRef = useRef(toggleApproveLine);
    toggleApproveLineRef.current = toggleApproveLine;

    const BookTranslationModelSentenceNode = useCallback(
        (props: any) => {
            return (
                <div style={{ display: "flex", flexDirection: "row", width: "100%" }}>
                    {
                        props.data.label !== "" ?
                            (
                                <>
                                    <Handle id={`handle-${props.id}`} type={props.data.isOriginal ? "source" : "target"} position={props.data.isOriginal ? Position.Right : Position.Left} />
                                    <div className={isEnableEdit?css.contextCursor:""} id={props.id} style={{ textAlign: "justify", width: `${props.data.width}px`, padding: "5px 20px", backgroundColor: "#F4F5F7", marginBottom: "5px", display: "flex", flexDirection: "row", gap: 10 }}>
                                        {
                                            (viewPredictedTranslationInOriginalLanguage && !props.data.isOriginal) &&
                                            <Tooltip title={props.data.predictedSentence} classes={{ tooltip: classes.customTooltip }} placement="top-start">
                                                <img
                                                    className={css.eyeIcon} src={EyeIcon} height={8} alt="eye" />
                                            </Tooltip>
                                        }
                                        <p style={{ marginBottom: "0px" }}>{props.data.label}</p>
                                    </div>
                                </>
                            ) : <div style={{ width: `${props.data.width}px` }}></div>
                    }
                    {
                        (!props.data.isOriginal || !props.data.hasTranslation) &&
                        <div id={"action" + props.id} style={{ marginBottom: "5px", display: "flex", flexDirection: "row", justifyContent: "space-evenly", padding: "0px 14px", gap: "20px", width: "15%" }}>
                            <div style={{ padding: "2px 10px", borderRadius: "10px", height: "fit-content", backgroundColor: "#F4F5F7" }}>{props.data.confidence}</div>
                            <div
                            onClick={ async () => {
                                if(props.data.isApprovedByUser) return;
                                setIsApproveLineLoading((prevState)=>{
                                    return [...prevState, props.id]
                                })
                                await toggleApproveLineRef.current(props.data.sentenceId, props.data.isOriginal)
                                setIsApproveLineLoading((prevState)=>{
                                    return [...prevState.filter((id) =>id!=props.id)]
                                })
                            }}
                            className={css.approveBtn}>
                            {
                                (isApproveLineLoading.find((id) => id===props.id)!==undefined)?
                                <CircularProgress size={30} />
                                : props.data.isApprovedByUser?
                                <ThumbUp style={{ color: "#008D9C" }} />
                                : <ThumbUpAltOutlinedIcon style={{ color: "#008D9C" }} />
                            }
                            </div>
                            
                        </div>
                    }
                </div>
        
            )
        }
        , [isApproveLineLoading, fetchingTranslations, viewPredictedTranslationInOriginalLanguage, isEnableEdit]
    )
    
    // const nodeTypes = { bookTranslationModelSentenceNode: BookTranslationModelSentenceNode };    
    const nodeTypes = useMemo(() => {
        return { bookTranslationModelSentenceNode: BookTranslationModelSentenceNode };
    }, [BookTranslationModelSentenceNode]);

    const handleReactFlowOnConnectEvent = useCallback(
        (connection: Connection) => {
            console.log("onConnect")

            setEdges([...edges, {
                source: connection.source ? connection.source : "",
                target: connection.target ? connection.target : "",
                sourceHandle: connection.sourceHandle,
                targetHandle: connection.targetHandle,
                type: 'bezier',
                id: `interaction-e-${props.paragraphData.chapter?.sequenceNumber}-${props.paragraphData.paragraph?.sequenceNumber}-${connection.target}-${connection.source}`,
            }])
            const targetArr: string [] | undefined = connection.target?.split("-")
            const sourceArr: string [] | undefined = connection.source?.split("-")
            console.log(targetArr&&targetArr[targetArr?.length-1])
            const translatedSentenceId = targetArr&&targetArr[targetArr.length-1]
            const sourceSentenceId = sourceArr&&sourceArr[sourceArr.length-1]
            if(translatedSentenceId===undefined||translatedLanguageSentences===undefined) return;

            setTranslatedLanguageSentences((prevtranslatedSentences) => {
                prevtranslatedSentences?.forEach((sentence) => {
                    if (sentence.sentenceId === translatedSentenceId&&sentence.connectedWithOriginalLanguageTextSentenceIds.find((connection)=> connection.sentenceId===sourceSentenceId)===undefined)  {
                        sentence.connectedWithOriginalLanguageTextSentenceIds =
                            [...sentence.connectedWithOriginalLanguageTextSentenceIds,
                            {
                                sentenceId: sourceSentenceId ? sourceSentenceId : ""
                            }]
                    }})
                return prevtranslatedSentences;
            })
            generateNodesAndEdges()
        },
        [setEdges, edges]
    );

    const handleLinePairingEdgeContextMenuEvent = useCallback(
        (event: React.MouseEvent, edge: Edge) => {    

            generateNodesAndEdges()
           event.preventDefault();
          setEdgeContextMenu({
            isOpen: true,
            mouseX: event.clientX - 2,
            mouseY: event.clientY - 4,
            edge: edge,
          });
        },
        [setEdges, edges]
      );


    function LinePairingEdgeContextMenu(props: any) {
        const mouseX = props.mouseX;
        const mouseY = props.mouseY;

        return (
            <Menu
                keepMounted
                open={mouseY !== null}
                onClose={props.onClose}
                anchorReference="anchorPosition"
                anchorPosition={
                    mouseY !== null && mouseX !== null
                        ? { top: mouseY, left: mouseX }
                        : undefined
                }
            >
                <List dense={true}>
                    <ListItem button onClick={props.onDeleteEdgeClick}>
                        <ListItemText primary="Delete" />
                    </ListItem>
                </List>
            </Menu>
        );
    }

    const closeLinePairingEdgeContextMenu = useCallback(() => {
        setEdgeContextMenu((old) => ({ ...old, isOpen: false }));
    }, []);

    const handleLinePairingEdgeContextMenuCloseEvent = useCallback(() => {
        closeLinePairingEdgeContextMenu();
    }, [closeLinePairingEdgeContextMenu]);

    const handleLinePairingEdgeContextMenuDeleteEdgeEvent = useCallback(() => {
        const edgeToDelete = edgeContextMenu.edge;

        if (!edgeToDelete) {
            return;
        }
        console.log("onDelete")
        setEdges([...edges.filter((edge) => edge.id !== edgeToDelete.id)])

        const targetArr: string[] | undefined = edgeToDelete.target?.split("-")
        const sourceArr: string[] | undefined = edgeToDelete.source?.split("-")
        const translatedSentenceId = targetArr && targetArr[targetArr.length - 1]
        const sourceSentenceId = sourceArr && sourceArr[sourceArr.length - 1]
        if (translatedSentenceId === undefined || translatedLanguageSentences === undefined) return;

        setTranslatedLanguageSentences([
            ...translatedLanguageSentences.map((translatedSentence) => {
                if (translatedSentence.sentenceId === translatedSentenceId) return {
                    ...translatedSentence,
                    connectedWithOriginalLanguageTextSentenceIds:
                        [...translatedSentence.connectedWithOriginalLanguageTextSentenceIds
                            .filter((sentence) => sentence.sentenceId !== sourceSentenceId)]
                }
                return translatedSentence;
            })
        ])
        closeLinePairingEdgeContextMenu();
    }, [
        closeLinePairingEdgeContextMenu,
        edgeContextMenu.edge,
    ]);

    const handleNodeContextMenuEvent = useCallback(
        (event: React.MouseEvent, node: Node) => {    

            console.log(event.clientX)
            generateNodesAndEdges()
           event.preventDefault();
          setNodeContextMenu({
            isOpen: true,
            mouseX: event.clientX - 2,
            mouseY: event.clientY - 4,
            node: node,
          });
        },
        [setNodes, nodes]
      );


    function NodeEditContextMenu(props: any) {
        const mouseX = props.mouseX;
        const mouseY = props.mouseY;

        return (
            <Menu
                keepMounted
                open={mouseY !== null}
                onClose={props.onClose}
                anchorReference="anchorPosition"
                anchorPosition={
                    mouseY !== null && mouseX !== null
                        ? { top: mouseY, left: mouseX }
                        : undefined
                }
            >
                <List dense={true}>
                    <ListItem button onClick={() => {
                        props.onEditNodeClick()
                        closeNodeContextMenu()
                    }}>
                        <ListItemText primary="Edit Sentence" />
                    </ListItem>
                </List>
            </Menu>
        );
    }

    const closeNodeContextMenu = useCallback(() => {
        setNodeContextMenu((old) => ({ ...old, isOpen: false }));
    }, []);

    const handleNodeContextMenuCloseEvent = useCallback(() => {
        closeNodeContextMenu();
    }, [closeNodeContextMenu]);

    // const handleNodeContextMenuDeleteEdgeEvent = useCallback(() => {
    //     const edgeToDelete = edgeContextMenu.edge;

    //     if (!edgeToDelete) {
    //         return;
    //     }
    //     console.log("onDelete")
    //     setEdges([...edges.filter((edge) => edge.id !== edgeToDelete.id)])

    //     const targetArr: string[] | undefined = edgeToDelete.target?.split("-")
    //     const sourceArr: string[] | undefined = edgeToDelete.source?.split("-")
    //     const translatedSentenceId = targetArr && targetArr[targetArr.length - 1]
    //     const sourceSentenceId = sourceArr && sourceArr[sourceArr.length - 1]
    //     if (translatedSentenceId === undefined || translatedLanguageSentences === undefined) return;

    //     setTranslatedLanguageSentences([
    //         ...translatedLanguageSentences.map((translatedSentence) => {
    //             if (translatedSentence.sentenceId === translatedSentenceId) return {
    //                 ...translatedSentence,
    //                 connectedWithOriginalLanguageTextSentenceIds:
    //                     [...translatedSentence.connectedWithOriginalLanguageTextSentenceIds
    //                         .filter((sentence) => sentence.sentenceId !== sourceSentenceId)]
    //             }
    //             return translatedSentence;
    //         })
    //     ])
    //     closeLinePairingEdgeContextMenu();
    // }, [
    //     closeLinePairingEdgeContextMenu,
    //     edgeContextMenu.edge,
    // ]);

    const checkOriginalSentenceHasTranslation = (originalSentence: ITextOriginalLanguageSentence): boolean => {
        if (translatedLanguageSentences === undefined) return false;
        return translatedLanguageSentences.find((translatedSentence) => {
            return translatedSentence.connectedWithOriginalLanguageTextSentenceIds.find((sentence) => sentence.sentenceId === originalSentence.sentenceId) !== undefined ? true : false
        }) !== undefined ? true : false
    }

    const generateNodesAndEdges = () => {
        console.log("generateNodesAndEdges")
        const newNodes: NodeData[] = [];
        const newEdges: Edge[] = [];
        let currentY = 20;
        const yOffsetSentence = 20;
        const LineHeight = 20;
        let translationSentenceOffset = 0;

        const containerWidth = document.getElementById(`line-pairing-container-${props.paragraphData._id}`)?.clientWidth;
        const translatedPositionX = (50 / 100) * (containerWidth ? containerWidth : 0)
        const noOfCharactersInALine = Math.floor((containerWidth ? containerWidth : 0) * 71 / 1286)

        const translationSentenceMap: string[] = [];
        let lastSentenceY = currentY;

        originalLanguageSentences?.forEach((originalSentence) => {

            const translatedSentence: ITextTranslatedLanguageSentence | undefined
                = translatedLanguageSentences?.find((tSentence) => {
                    return tSentence.sequenceNumber + translationSentenceOffset === originalSentence.sequenceNumber
                })

            const originalSentenceHasTranslation: boolean = checkOriginalSentenceHasTranslation(originalSentence);
            if (translatedSentence !== undefined && originalSentenceHasTranslation) {
                const originalSentenceNodeId = `original-${props.paragraphData.chapter?.sequenceNumber}-${props.paragraphData.paragraph?.sequenceNumber}-${originalSentence.sentenceId}`
                const translatedSentenceNodeId = `translated-${props.paragraphData.chapter?.sequenceNumber}-${props.paragraphData.paragraph?.sequenceNumber}-${translatedSentence.sentenceId}`
                const originalSentenceNoOfLines = Math.ceil(originalSentence.sentence.text.length / noOfCharactersInALine);
                const translatedSentenceNoOfLines = Math.ceil(translatedSentence.sentence.text.length / noOfCharactersInALine);

                const translatedSentenceNode = {
                    id: translatedSentenceNodeId,
                    type: "bookTranslationModelSentenceNode",
                    data: { label: viewPredictedTranslationInOriginalLanguage? translatedSentence.sentence.englishTranslatedText||"fetching..." : translatedSentence.sentence.text, isOriginal: false, width: 40 / 100 * (containerWidth ? containerWidth : 0), confidence: translatedSentence.confidenceScore, hasTranslation: false, isApprovedByUser: translatedSentence.isApprovedByUser, sentenceId: translatedSentence.sentenceId, predictedSentence: translatedSentence.sentence.text },
                    position: { x: translatedPositionX, y: lastSentenceY },
                }
                const originalSentenceNode = {
                    id: originalSentenceNodeId,
                    type: "bookTranslationModelSentenceNode",
                    data: { label: originalSentence.sentence.text, isOriginal: true, width: 40 / 100 * (containerWidth ? containerWidth : 0), confidence: '-', hasTranslation: true, isApprovedByUser: originalSentence.isApprovedByUser, sentenceId: originalSentence.sentenceId, predictedSentence: originalSentence.sentence.text },
                    position: { x: 20, y: lastSentenceY },
                };

                translatedSentence.connectedWithOriginalLanguageTextSentenceIds.forEach((sentence) => {
                    const edge = {
                        id: `interaction-e-${props.paragraphData.chapter?.sequenceNumber}-${props.paragraphData.paragraph?.sequenceNumber}-${translatedSentence.sentenceId}-${sentence.sentenceId}`,
                        source: `original-${props.paragraphData.chapter?.sequenceNumber}-${props.paragraphData.paragraph?.sequenceNumber}-${sentence.sentenceId}`,
                        target: translatedSentenceNodeId,
                        sourceHandle: `handle-original-${props.paragraphData.chapter?.sequenceNumber}-${props.paragraphData.paragraph?.sequenceNumber}-${sentence.sentenceId}`,
                        targetHandle: `handle-${translatedSentenceNodeId}`,
                        type: 'bezier'
                    };
                    newEdges.push(edge)
                })

                newNodes.push(translatedSentenceNode)
                translationSentenceMap.push(translatedSentence.sentenceId)
                newNodes.push(originalSentenceNode)
                lastSentenceY += Math.max(originalSentenceNoOfLines, translatedSentenceNoOfLines) * LineHeight;
                getLineHeight(translatedSentenceNode.id, originalSentenceNode.id, Math.max(originalSentenceNoOfLines, translatedSentenceNoOfLines) * LineHeight)
            }
            else {
                const originalSentenceNodeId = `original-${props.paragraphData.chapter?.sequenceNumber}-${props.paragraphData.paragraph?.sequenceNumber}-${originalSentence.sentenceId}`
                const originalSentenceNoOfLines = Math.ceil(originalSentence.sentence.text.length / noOfCharactersInALine);
                const originalSentenceNode = {
                    id: originalSentenceNodeId,
                    type: "bookTranslationModelSentenceNode",
                    data: { label: originalSentence.sentence.text, isOriginal: true, width: 40 / 100 * (containerWidth ? containerWidth : 0), confidence: 100, hasTranslation: true, isApprovedByUser: originalSentence.isApprovedByUser, sentenceId: originalSentence.sentenceId, predictedSentence: originalSentence.sentence.text},
                    position: { x: 20, y: lastSentenceY },
                };

                const translatedSentenceNode = {
                    id: `vacant-translation-${originalSentenceNodeId}`,
                    type: "bookTranslationModelSentenceNode",
                    data: { label: "", isOriginal: true, width: 40 / 100 * (containerWidth ? containerWidth : 0), confidence: originalSentence.confidenceScore ? originalSentence.confidenceScore : '-', hasTranslation: false, isApprovedByUser: originalSentence.isApprovedByUser, sentenceId: originalSentence.sentenceId, predictedSentence: originalSentence.sentence.text},
                    position: { x: translatedPositionX, y: lastSentenceY },
                }
                lastSentenceY += originalSentenceNoOfLines * LineHeight;
                newNodes.push(originalSentenceNode)
                newNodes.push(translatedSentenceNode)
                translationSentenceOffset++;
            }
            lastSentenceY += yOffsetSentence
        })

        translatedLanguageSentences?.forEach((translatedSentence) => {
            if (translationSentenceMap.find((id) => id === translatedSentence.sentenceId) === undefined) {
                const translatedSentenceNodeId = `translated-${props.paragraphData.chapter?.sequenceNumber}-${props.paragraphData.paragraph?.sequenceNumber}-${translatedSentence.sentenceId}`
                const translatedSentenceNoOfLines = Math.ceil(translatedSentence.sentence.text.length / noOfCharactersInALine);

                const translatedSentenceNode = {
                    id: translatedSentenceNodeId,
                    type: "bookTranslationModelSentenceNode",
                    data: { label: viewPredictedTranslationInOriginalLanguage? translatedSentence.sentence.englishTranslatedText||"fetching..." : translatedSentence.sentence.text, isOriginal: false, width: 40 / 100 * (containerWidth ? containerWidth : 0), confidence: translatedSentence.confidenceScore, hasTranslation: false, isApprovedByUser: translatedSentence.isApprovedByUser, sentenceId: translatedSentence.sentenceId, predictedSentence: translatedSentence.sentence.text },
                    position: { x: translatedPositionX, y: lastSentenceY },
                }

                translatedSentence.connectedWithOriginalLanguageTextSentenceIds.forEach((sentence) => {
                    const edge = {
                        id: `interaction-e-${props.paragraphData.chapter?.sequenceNumber}-${props.paragraphData.paragraph?.sequenceNumber}-${translatedSentence.sentenceId}-${sentence.sentenceId}`,
                        source: `original-${props.paragraphData.chapter?.sequenceNumber}-${props.paragraphData.paragraph?.sequenceNumber}-${sentence.sentenceId}`,
                        target: translatedSentenceNodeId,
                        sourceHandle: `handle-original-${props.paragraphData.chapter?.sequenceNumber}-${props.paragraphData.paragraph?.sequenceNumber}-${sentence.sentenceId}`,
                        targetHandle: `handle-${translatedSentenceNodeId}`,
                        type: 'bezier'
                    };
                    newEdges.push(edge)
                })

                newNodes.push(translatedSentenceNode)
                lastSentenceY += translatedSentenceNoOfLines * LineHeight;
                lastSentenceY += yOffsetSentence
            }
        })

        setNodes(newNodes);
        setEdges(newEdges);
    };

    const saveParagraphChanges = async () => {
        if (translatedLanguageSentences === undefined) return;
        if (transtionSentencesCopy === undefined) return;
        console.log("saveParagraphChanges")
        setTranslatedLanguageSentences((translatedSentence) => {
            translatedSentence?.forEach(
                (sentence) => {
                    const predictedTranslation = transtionSentencesCopy.find((tSentence) => tSentence.sentenceId === sentence.sentenceId)
                    if (predictedTranslation?.connectedWithOriginalLanguageTextSentenceIdsPredicted.length === 0&&JSON.stringify(predictedTranslation.connectedWithOriginalLanguageTextSentenceIds)!==JSON.stringify(sentence.connectedWithOriginalLanguageTextSentenceIds)){
                        props.addParagraphToChangedList(props.paragraphData._id)
                        sentence.connectedWithOriginalLanguageTextSentenceIdsPredicted = JSON.parse(JSON.stringify(predictedTranslation.connectedWithOriginalLanguageTextSentenceIds))
                    }
                }
            )
            
            return translatedSentence;
        })

     //   console.log("confindenceUpdated",translatedLanguageSentences)
        setIsLinePairUpdating(true)

        await DataService.updateData({
            id: [props.paragraphData._id],
            textTranslatedLanguageSentences: translatedLanguageSentences,
        })

        await DataService.updateData({
            id: [props.paragraphData._id],
            textOriginalLanguageSentences: originalLanguageSentences,
        })

        const {data: lastestResource} = await DataService.getResourceFromDBById({
            resourceId: props.paragraphData._id
        })

        props.updateDataChangedList(props.paragraphData._id, lastestResource)

        setIsEnableEdit(false)
        setIsLinePairUpdating(false)
        checkIfParagraphEnableApprove();
       
    }

    const checkIfParagraphEnableApprove = ( id?: string) => {
        let allLinesapprove: boolean = true;
        nodes.filter((node) => node.data.sentenceId!==id).forEach((node) =>{
            if((!node.data.isOriginal||!node.data.hasTranslation)&&!node.data.isApprovedByUser)
            {
                console.log("is Approve failed", node)
                allLinesapprove=false
            }
        })

        if(allLinesapprove)
        props.addParagraphIdToEnableApprove(props.paragraphData._id)
        // generateNodesAndEdges()        
    }

    const getLineHeight = (targetId: string, sourceId: string, estimatedHeight: number) => {
        
        setTimeout(() => {
            const translated = document.getElementById(targetId)
            const original = document.getElementById(sourceId)
            if (translated === null || original === null) return;
            var height = 0;
            if (translated?.clientHeight > original.clientHeight)
                height = translated.clientHeight
            else
                height = original.clientHeight
                //console.log("getLineHeight" ,estimatedHeight, height)
            translated.style.height = `${Math.max(estimatedHeight+5, height)}px`;
            original.style.height = `${Math.max(estimatedHeight+5, height)}px`;
        }, 0.01);
        return 0;
    }


    // const ComputeLineHeight = () => {
    //     edges.forEach((edge) => {
    //         getLineHeight(edge.target, edge.source)
    //     })
    // }

    useEffect(() => {
        setNodes([])
        setEdges([])
        generateNodesAndEdges()
    }, [isEnableEdit, isApproveLineLoading])

    useEffect(() => {
        setNodes([])
        setEdges([])
        generateNodesAndEdges();
    }, [translatedLanguageSentences, originalLanguageSentences])

    // console.log("isLineApproveFlag", isLineApproveFlags)
    // console.log("translatedLanguageSentences", translatedLanguageSentences)
    // console.log("props.translationSentences", props.paragraphData.textTranslatedLanguageSentences)
    // console.log("transtionSentencesCopy", transtionSentencesCopy)
    // console.log("nodes", nodes)
    useEffect(() => {
        generateNodesAndEdges();
    }, [fetchingTranslations, viewPredictedTranslationInOriginalLanguage])

    // console.log("edges", edges)
    // console.log("original Sentence", originalLanguageSentences)
    // console.log("translatedLanguageSentences", translatedLanguageSentences)
    // console.log("props.translationSentences", props.paragraphData.textTranslatedLanguageSentences)
    // console.log("transtionSentencesCopy", transtionSentencesCopy)
    // console.log("translationApiUrl", translationApiUrl)
    console.log("node Selected", nodeContextDialog.node)

    function languageToCode(languageName: string) {
        const languageCode = iso6391.getCode(languageName);
        return languageCode || 'Not Found';
    }

    const EditSentenceDialog = () => {
        const [ textAreaValue, setTextAreaValue] = useState<string>("");
        const [ defaultValue, setDefaultValue] = useState<string>(
            nodeContextDialog.node?
            nodeContextDialog.node.data.label
            :""
            )
        const [isFetching, setIsFetching] = useState<boolean>(false);
            
        const closeEditSentenceDialog = () => {
            setSetNodeContextDialog({
                isOpen: false,
                node: null
            })
        }

        const getUpdatedTranslatedText = async (sentence: string) => {
            const apiResponse = await axios.post(translationApiUrl, {
                "sentences": [sentence],
                "originaLanguage": props.paragraphData.textOriginalLanguage?.toLowerCase(),
                "targetLanguage": props.paragraphData.textTranslatedLanguage?.toLowerCase(),
                "target_language_unicode": languageToCode(props.paragraphData.textTranslatedLanguage?.toLowerCase()||"")
            })
            return apiResponse.data;
        }

        const updateEditedSentence = async () => {
            if(nodeContextDialog.node?.data.predictedSentence===textAreaValue)
            return;
            if(nodeContextDialog.node?.data.isOriginal === false&&viewPredictedTranslationInOriginalLanguage)
            {
                setIsFetching(true)
                await getUpdatedTranslatedText(textAreaValue)
                .then((updateSentence) => {
                   // console.log("updateSentence ---->", updateSentence)
                    setTranslatedLanguageSentences((translatedSentence) => {
                        for (const sentence of (translatedSentence || [])) {
                            if (sentence.sentenceId === nodeContextDialog.node?.data.sentenceId) {
                                sentence.sentence.predictedText = sentence.sentence.predictedText ? sentence.sentence.predictedText : sentence.sentence.text
                                sentence.sentence.text = updateSentence.sentences[0];
                                sentence.sentence.englishTranslatedText = textAreaValue;
                            }
                        }
                        return [...(translatedSentence || [])]
                    })
                })
                setIsFetching(false)
                return;
            }
            if (nodeContextDialog.node?.data.isOriginal === true) {
                setOriginalLanguageSentences((originalSentence) => {
                    for (const sentence of (originalSentence || [])) {
                        if (sentence.sentenceId === nodeContextDialog.node?.data.sentenceId) {
                            sentence.sentence.predictedText = sentence.sentence.predictedText ? sentence.sentence.predictedText : sentence.sentence.text
                            sentence.sentence.text = textAreaValue;
                        }
                    }
                    return [...(originalSentence || [])]
                })
            }
            else{
                setTranslatedLanguageSentences((translatedSentence) => {
                    for (const sentence of (translatedSentence || [])) {
                        if (sentence.sentenceId === nodeContextDialog.node?.data.sentenceId) {
                            sentence.sentence.predictedText = sentence.sentence.predictedText ? sentence.sentence.predictedText : sentence.sentence.text
                            sentence.sentence.text = textAreaValue;
                            sentence.sentence.englishTranslatedText = "";
                        }
                    }
                    return [...(translatedSentence || [])]
                })
            }
        }

        const handleSentenceChange = (event: ChangeEvent<HTMLTextAreaElement>) => {
            const newText = event.target.value;
            setTextAreaValue(newText)
          };

        useEffect(() => {
            setTextAreaValue(nodeContextDialog.node? nodeContextDialog.node.data.label: "");
        }, [nodeContextDialog])
        
        return(
          <Dialog onClose={closeEditSentenceDialog} open={nodeContextDialog.isOpen} maxWidth="lg" fullWidth classes={{ paperScrollPaper: classes.dialogContainer, paperWidthLg: classes.dialogContainerWidth }}>
          <Box style={{ 
            display: "flex",
            justifyContent: "center",
            flexDirection: "column",
            width: "100%",
            paddingRight: "28px",
            paddingLeft: "28px"
          }}>
            <Box style={{ display: "flex", justifyContent: "center", marginBottom: "20px", marginTop: "15px", }}>
              <Typography variant="h6" color="primary">Edit Sentence</Typography>
              <Box style={{ position: "absolute", top: "9px", right: "1px" }}>
                <Button startIcon={<CloseIcon />} onClick={closeEditSentenceDialog}></Button>
              </Box>
            </Box>

            <Box style={{ display: "flex", flexDirection: "column", alignItems: "center", justifyContent: "center", gap: "10" }}>
                {/* <p> rhgsedhtbw<p/> */}
                        <p style={{ color: "#8e969e" }}>{defaultValue}</p>
                        <p style={{ marginBottom: "10px", font: 'normal normal normal 16px/22px Nunito Sans', letterSpacing: '0px' }}><strong>Enter the Correct Sentence</strong></p>
                        <TextareaAutosize
                            value={textAreaValue}
                            onChange={handleSentenceChange}
                            style={{ width: "100%", height: "60px" }}
                            aria-label="maximum height"
                            placeholder="Type the Correct Sentence."
                            defaultValue={defaultValue}
                        />

            </Box>
          </Box>
          <DialogActions style={{ display: "flex", alignItems: "center", justifyContent: "center"}}>
            <Button variant='contained' style={{ backgroundColor: "#0193a3", color: "white"}}
            onClick={() => {
                updateEditedSentence().then(()=>{
                    closeEditSentenceDialog();
                    //generateNodesAndEdges();
                }).catch((err) => {
                    alert(`Error: ${err}`)
                })
            }}
            >
                {
                    isFetching?
                    <CircularProgress style={{ color: "white"}} />:
                    <>Confirm Change</>
                }
                
            </Button>
          </DialogActions>
          </Dialog>
        )
    }

    const showEditSentenceDialogClickHandler = () => {
        setShowAdditionalDataPopup(true);
    }

    const handleEditSentenceNodeContextMenuEvent = (node: Node) => {
        setSetNodeContextDialog({
            isOpen: true,
            node: node,
        });
    }


    const showAdditionalDataPopupClickHandler = () => {
      setShowAdditionalDataPopup(true);
    }

    const closePopup = () => {
      setShowAdditionalDataPopup(false);
    }

    const onChangeRemark = (event: React.ChangeEvent<HTMLTextAreaElement>) => {
      setResourceRemarksTextState(oldState => ({
        ...oldState,
        isDirty: true,
        value: event.target.value
      }))
    }

    const onBlurRemarkField = () => {
      setResourceRemarksTextState(oldState => ({
        ...oldState,
        isTouched: true,
      }))
      props.setShouldBlockDataTableKeyboardEvents(false)
    }

    const onSaveRemarksClick = () => {
      dispatch(updateResourceRemarks({ resourceId: props.paragraphData._id || "", resourceRemarksText: resourceRemarksTextState.value }));
      setResourceRemarksTextState(oldState => {
        return {
          ...oldState,
          isDirty: false,
          isTouched: false
        }
      })
    }

    const fetchTranslations = async () => {
        try {
            const sentencesMap: any[] = [];
            const sentencesToBeTranslated: string[] = [];
            translatedLanguageSentences?.forEach((sentence) => {
                if (!sentence.sentence.englishTranslatedText) {
                    sentencesMap.push({
                        _id: sentence.sentenceId, text: sentence.sentence.text
                    })
                    sentencesToBeTranslated.push(sentence.sentence.text)
                }
            })
            //console.log(sentencesToBeTranslated)
            if(sentencesToBeTranslated.length!==0)
            {
                const apiResponse = await axios.post(translationApiUrl, {
                    "sentences": sentencesToBeTranslated,
                    "originaLanguage": props.paragraphData.textOriginalLanguage?.toLowerCase(),
                    "targetLanguage": props.paragraphData.textTranslatedLanguage?.toLowerCase(),
                    "target_language_unicode": languageToCode(props.paragraphData.textOriginalLanguage?.toLowerCase()||"")
                })
    
                console.log("apiResponse", apiResponse)
    
                sentencesMap.forEach((sentence, index) => {
                    sentence.text = apiResponse.data.sentences[index]
                })
    
                sentencesMap.forEach((sentence) => {
                    setTranslatedLanguageSentences((prevState) => {
                        if (!prevState) return;
                        const sentencetoUpdate = prevState.find((state) => state.sentenceId === sentence._id)
                        if (!sentencetoUpdate?.sentence) return;
                        sentencetoUpdate.sentence.englishTranslatedText = sentence.text;
                        return [...prevState?.filter((state) => state.sentenceId !== sentence._id),
                            sentencetoUpdate
                        ]
                    })
                })
    
                await DataService.updateData({
                    id: [props.paragraphData._id],
                    textTranslatedLanguageSentences: translatedLanguageSentences,
                })
    
            }
            console.log("sentencesMap", sentencesMap)
            setFetchingTranslations(false)
        } catch (err) {
            setFetchingTranslations(false)
            setViewPredictedTranslationInOriginalLanguage(false)
            alert("The Translation API URL specified in the Configuration Tab is throwing an error")
        }
    }

    useEffect(() => {
        const getTranslationApiUrl = async () => {
            const model = await ModelService.getModelDetails({
                modelId: props.paragraphData.model._id,
            })
            setTranslationApiUrl(model.data.translateApiUrl||"");
        }

        getTranslationApiUrl();
        
    }, [])


    return (
        <div id={`line-pairing-container-${props.paragraphData._id}`}>
          <EditSentenceDialog />
          <Dialog onClose={closePopup} open={showAdditionalDataPopup} maxWidth="xs" fullWidth classes={{ paperScrollPaper: classes.dialogContainer, paperWidthLg: classes.dialogContainerWidth }}>
          <Box style={{ 
            display: "flex",
            justifyContent: "center",
            flexDirection: "column",
            width: "100%",
            paddingRight: "28px",
            paddingLeft: "28px"
          }}>
            <Box style={{ display: "flex", justifyContent: "center", marginBottom: "20px", marginTop: "15px", }}>
              <Typography variant="h6" color="primary">ADDITIONAL DETAILS</Typography>
              <Box style={{ position: "absolute", top: "9px", right: "1px" }}>
                <Button startIcon={<CloseIcon />} onClick={closePopup}></Button>
              </Box>
            </Box>

            <Box style={{ display: "flex", justifyContent: "center" }}>
              <ShowResourceDetails 
              updateResourceInUI={props.updateResourceInUI}
                resource={props.paragraphData}
                setShouldBlockDataTableKeyboardEvents={props.setShouldBlockDataTableKeyboardEvents} 
                // @ts-ignore
                confidenceScore={Number(props.paragraphData?.confidence_score)} 
                // @ts-ignore
                resourceType={props.paragraphData?.model?.type} 
                // @ts-ignore
                dataSetCollections={props.paragraphData.dataSetCollections}
                // @ts-ignore 
                changesMade={props.paragraphData?.changesMade} 
                inCopilot={false} 
                isShowingInForecastingViewPopup={false}
                LabelsAvailable ={props.LabelsAvailable}
                selectedSubTab = {props.selectedSubTab}
                allowUserToSetAnyLabel={props.allowUserToSetAnyLabel} 
                />
            </Box>

            <Box style={{ display: "flex", justifyContent: "center"}}>
              <Box style={{width: "490px", paddingRight: "15px"}}>
                <p style={{ marginBottom: "10px", color: `${"#8e969e"}`, font: 'normal normal normal 16px/22px Nunito Sans', letterSpacing: '0px' }}>Add Remarks:</p>
                  <textarea
                    style={{ width: "100%", minHeight: "100px", marginBottom: "10px", fontSize: "15px", fontFamily: 'Nunito Sans', borderRadius: "14px", padding: "10px" }}
                    value={resourceRemarksTextState.value}
                    onFocus={() => props.setShouldBlockDataTableKeyboardEvents(true)}
                    onChange={(event) => { onChangeRemark(event) }}
                    onBlur={() => { onBlurRemarkField() }}
                    disabled={props.paragraphData?.isResourceRemarksBeingUpdatedInDB ? true : false}
                  />
                  <Button
                    color="primary"
                    variant="contained"
                    onClick={onSaveRemarksClick}
                    style={{ marginBottom: "20px" }}
                    disabled={props.paragraphData?.isResourceRemarksBeingUpdatedInDB || !resourceRemarksTextState.isDirty ? true : false}
                  >
                    Save Remarks
                  </Button>
              </Box>
            </Box>
          </Box>
          </Dialog>

            <div style={{ display: "flex", flexDirection: "row", justifyContent: "space-between", padding: "10px" }}>
                <p style={{ color: "#707070", paddingLeft: "10px" }}>Please review the lines below</p>
                <div>
                    {!isEnableEdit ?
                        <>
                        <button className={css.editLinePairingBtn}
                            style={{ marginRight: "15px" }}
                            onClick={() => {
                                if (translatedLanguageSentences === undefined) return;
                                setOriginalSentencesCopy(JSON.parse(JSON.stringify(originalLanguageSentences)))
                                setTranstionSentencesCopy(JSON.parse(JSON.stringify(translatedLanguageSentences)))
                                setIsEnableEdit(true)
                            }}
                        >
                          Edit
                        </button>
                        <button className={css.editLinePairingBtn} onClick={showAdditionalDataPopupClickHandler}>
                          Show Additional Details
                        </button>
                        </> :
                        <div className={css.bookTranslationChangesActionBtns}>
                            <Button style={{ color: "#0193a3" }}
                                onClick={() => {
                                    console.log("onCancel")
                                    setIsEnableEdit(false)
                                    if (transtionSentencesCopy !== undefined){
                                        setTranslatedLanguageSentences(JSON.parse(JSON.stringify(transtionSentencesCopy)))
                                    }
                                    if(originalSentencesCopy !== undefined)
                                    setOriginalLanguageSentences(JSON.parse(JSON.stringify(originalSentencesCopy)))
                                }}
                            >
                                Cancel
                            </Button>
                            <Button style={{ color: "white", backgroundColor: "#0193a3" }}
                                onClick={saveParagraphChanges}
                            >
                                Save Changes
                            </Button>
                        </div>}
                </div>
            </div>

            <div style={{
                display: "flex", flexDirection: "row"
            }}>
                <div style={{ width: "45%" }}>
                    <p style={{ textAlign: "center", color: "#98A6B3" }}>{convertStringToUpperCase(props.paragraphData.textOriginalLanguage || "")}</p>
                </div>
                <div style={{ width: "20px" }}></div>
                <div style={{ width: "45%", display: "flex", flexDirection: "row", justifyContent: "flex-end", gap: "30%" }}>
                    <p style={{ textAlign: "center", color: "#98A6B3" }}>{convertStringToUpperCase(props.paragraphData.textTranslatedLanguage || "")}</p>
                    <div className={css.translateToOriginalLanguage}>
                        <Popover
                            id={id}
                            open={open}
                            anchorEl={disableViewsAsTranslatedSentenceInOriginalLangeAnchorEl}
                            onClose={() => setDisableViewsAsTranslatedSentenceInOriginalLangeAnchorEl(null)}
                            anchorOrigin={{
                                vertical: 'bottom',
                                horizontal: 'center',
                            }}
                            transformOrigin={{
                                vertical: 'top',
                                horizontal: 'center',
                            }}
                        >
                            <Typography style={{ padding: 10}}>{viewsAsBtnDisableText}</Typography>
                        </Popover>
                        <p style={{ color: "#98A6B3"}}>{props.paragraphData.textOriginalLanguage || ""} Mode</p>
                        <div style={{ marginBottom: '1rem' }}>
                            <Switch
                                checked={viewPredictedTranslationInOriginalLanguage}
                                onChange={(event: ChangeEvent<HTMLInputElement>) => {
                                    let allsentencehastranslation=true;
                                    translatedLanguageSentences?.forEach((sentence) => {
                                        if(!sentence.sentence.englishTranslatedText)
                                        allsentencehastranslation=false;
                                    })
                                    if(!allsentencehastranslation&&translationApiUrl==="")
                                    {
                                        setViewAsBtnDisableText("Please specify the translation API URL in Configuration Tab")
                                        setDisableViewsAsTranslatedSentenceInOriginalLangeAnchorEl(event.currentTarget)
                                        return;
                                    }
                                        setFetchingTranslations(prevState => !prevState)
                                        setViewPredictedTranslationInOriginalLanguage( prevState => !prevState )
                                        fetchTranslations();
                                }}
                                color="primary"
                                name="checkedB"
                                inputProps={{ 'aria-label': 'primary checkbox' }}
                            />
                        </div>
                    </div>
                </div>
                <div style={{ width: "10%" }}>
                    <p style={{ color: "#98A6B3", textAlign: "center", marginBottom: "0px", display: "flex", flexDirection: "row", justifyContent: "flex-start" }}>Confidence <br></br> Score</p>
                </div>
            </div>

            {isLinePairUpdating ? (
                <div className={`${css.reactflowContainer} bookTranslationReactFlow`} style={{ display: "flex", justifyContent: "center", alignItems: "center" }}>
                    <CircularProgress />
                </div>) : <ReactFlow
                    className={`${css.reactflowContainer} bookTranslationReactFlow`}
                    nodeTypes={nodeTypes}
                    nodes={[...nodes]}
                    edges={[...edges]}
                    proOptions={{ hideAttribution: true }}
                    elementsSelectable={true}
                    onNodeContextMenu={isEnableEdit ? handleNodeContextMenuEvent : () => { }}
                    onEdgeContextMenu={isEnableEdit ? handleLinePairingEdgeContextMenuEvent : () => { }}
                    onConnect={isEnableEdit ? handleReactFlowOnConnectEvent : () => { }}
                    translateExtent={[
                        [0, 0],
                        [
                            100000000000000000000000000000000000000000000000000,
                            100000000000000000000000000000000000000000000000000
                        ]
                    ]}
                    zoomOnDoubleClick={false}
                    elevateEdgesOnSelect={true}
                    nodesConnectable={isEnableEdit ? true : false}
                    nodesDraggable={false}
                    preventScrolling={false}
                    zoomOnScroll={false}
                    zoomOnPinch={false}
                    panOnDrag={isEnableEdit?true:false}
                    panOnScroll={isEnableEdit?true:false}    
                    panOnScrollMode={PanOnScrollMode.Vertical}                
                ></ReactFlow>}


            {edgeContextMenu.isOpen && (
                <LinePairingEdgeContextMenu
                    mouseX={edgeContextMenu.mouseX}
                    mouseY={edgeContextMenu.mouseY}
                    edge={edgeContextMenu.edge as Edge}
                    onClose={handleLinePairingEdgeContextMenuCloseEvent}
                    onDeleteEdgeClick={handleLinePairingEdgeContextMenuDeleteEdgeEvent}
                />
            )}
             {nodeContextMenu.isOpen && (
                <NodeEditContextMenu
                    mouseX={nodeContextMenu.mouseX}
                    mouseY={nodeContextMenu.mouseY}
                    edge={nodeContextMenu.node as Node}
                    onClose={handleNodeContextMenuCloseEvent}
                    onEditNodeClick={() => {
                        handleEditSentenceNodeContextMenuEvent(nodeContextMenu.node as Node)
                    }}
                />
            )}
            
            
        </div>
    );
}

function ViewAndTrimAudioResourcePopupOpenButton(props: { 
    onClick?: () => void,
    iconButtonStyles?: {},
    scenario?: "dataSetCollectionTableDetailsPopup" | "dataCart" | "browseCollectionsPopup" | "savedForLater" | "aiMarketPlaceDataCart" | "aiMarketplaceDataSelection"
 }) {

    const FullScreenButton = withStyles({
        root: {
          textTransform: 'none',
        },
      })(Button);

    return (
        <div style={{width:'100%',display:'flex',justifyContent:'center'}}>
            <FullScreenButton 
            startIcon={(props.scenario === "dataSetCollectionTableDetailsPopup" || props.scenario === "browseCollectionsPopup" || props.scenario === "aiMarketPlaceDataCart" || props.scenario === "aiMarketplaceDataSelection") ? undefined :<FullscreenIcon/>}
            onClick={() => {
            if (props.onClick) {
              props.onClick();
            }
          }}
          color='primary' variant='contained'>
              {
                  (props.scenario === "dataSetCollectionTableDetailsPopup" || props.scenario === "browseCollectionsPopup")
                  ? <FullscreenIcon />
                  : "View"
              }
            </FullScreenButton>
        </div>
    );
}

function ViewImageAnnotationResourcePopupOpenButton(props: {
    onClick?: () => void,
    iconButtonStyles?: {},
    scenario?: "dataSetCollectionTableDetailsPopup" | "dataCart" | "browseCollectionsPopup" | "savedForLater" | "aiMarketPlaceDataCart" | "aiMarketplaceDataSelection"
}) {
    return <ViewAndTrimAudioResourcePopupOpenButton {...props} />
}

const VideoAnnotationDialogBoxOpenButton = (props: { onClick?: () => void, iconButtonStyles?: {}}) => {
  return <ViewAndTrimAudioResourcePopupOpenButton {...props} />
}

interface ISetAndGetDataProps {
    location?: Location;
    initialize?: boolean;
    forceFetchDataFromDB?: boolean;

    /** @description 
     * set this as true if user wants to select all resources matching the page filter and
     * doesn't want it to get limited by pagination
      */
    userWantsToSelectAllResourcesMatchingThisFilterScenario?: boolean
}

const resourceTableHeadRowId = "resourceTableHeadRow";

class TableContent extends Component<IProps & RouterProps & RouteProps, IState> {

    static contextType = BrowseCollectionsPopupContext

    unlisten: any;
    isComponentUnmount: boolean;
    customTooltipRef: React.RefObject<{closeTooltip: () => void}>;
    constructor(props: IProps & RouterProps & String) {
        super(props);

        this.isComponentUnmount = false;

        const urlSearchParams = new URLSearchParams(window.location.search);

        const modelIdURLQueryParamValue = urlSearchParams.get("model") || "";

        this.customTooltipRef = React.createRef();

        this.state = {
            // selectedItems: [],
            changedResources: [],
            copyImageInMultipleImageAnnotationToImageAnnotationGroupPopup: {
                isOpen: false,
                resource: undefined
            },
            projectModels: [],
            showMetaDataForTextComparisonModel: false,
            multipleImageAnnotationResourceImageIndex: 0,
            bookTranslationResourceIdsEnableApprove: [],
            bookTranslationResourceIdWhoseViewButtonIsExpanded: [],
            bookTranslationParagraphsWhichAreChanged: [],
            allSelected: false,
            predictionStatusLogic: 'label',
            maximumMisPredictionDeviationAllowedInNumber: 0,
            modelMaxAudioTrimAllowedInMilliSeconds: 0,
            modelAnnotationLabelsAvailable: [],
            isViewForcastModelPopupOpen: false,
            isVideoAnnotationDialogBoxOpen: false,
            isViewAndTrimAudioResourcePopupOpen: false,
            resourceTableHeadColumnsLength: 0,
            getDataApiCancelFunction: null,
            resourcesTableContainerElementId: generateUniqueId(),
            resourcesGridViewContainerElementId: generateUniqueId(),
            imageList: [],
            currentModelType: "",
            textOneHeadingForTextComparisonUI: "",
            textTwoHeadingForTextComparisonUI: "",
            modelId: modelIdURLQueryParamValue,
            rowsPerPage: 10,
            page: 0,
            index: -1,
            expanded: false,
            isprediction: true,
            status: 'backlog',
            label: "",
            sort: {
                order: 'descending',
                field: 'statusLastModifiedAt'
            },
            labelsAvailable: [],
            resourceIdsSelectedForAddingToDataSetCollection: [],
            isAddResourcesToDataSetCollectionPopupShowing: false,
            filter: {
                resourceDataSetCollectionIds: [],
                resourcesWhichAreNotPartOfAnyDataSetCollection: '',
                resourcePrompt: {
                    searchValue: '',
                    searchOperator: DataTablePromptSearchOperatorDefaultValue,
                    includeOnlyMispredictedParagraphs:"false",
                    excludeMispredictedParagraphs:"false",
                    menuOpen: false
                },
                resourceName: {
                    searchValue: '',
                    searchOperator: DataTableResourceNameSearchOperatorDefaultValue,
                    includeOnlyMispredictedParagraphs:"false",
                    excludeMispredictedParagraphs:"false",
                    menuOpen: false
                },
                resourceTag: {
                    searchValue: '',
                    searchOperator: DataTableResourceTagSearchOperatorDefaultValue,
                    menuOpen: false                    
                },
                resourceLabel: {
                    searchValue: [],
                    searchOperator: DataTableResourceLabelSearchOperatorDefaultValue,
                    includeOnlyMispredictedRows: 'false',
                    menuOpen: false,
                    predictionSearchValue: [],
                    predictionSearchOperator: DataTableResourcePredictionSearchOperatorDefaultValue,
                },
                resourceConfidenceScore: {
                    minValue: resourceConfidenceScoreDefaultMinValue,
                    maxValue: resourceConfidenceScoreDefaultMaxValue,
                    menuOpen: false
                },
                resourceStatusLastModifiedDate: {
                    minValue: '',
                    maxValue: '',
                    menuOpen: false
                },
                forecastDate: {
                    minValue: '',
                    maxValue: '',
                    menuOpen: false
                }
            },
            addResourceToCopilotDialogState : {
                isOpen: false
            },
            imageKeyPointsDialog: {
                isOpen: false
            },
            uniqueResourceTagsAvailable: [],
            allowUserToSetAnyLabel: false,
            modelAiAssistantEnabled: false,
            modelApiToCallOnAiAssistantInvocation: "",
            modelAiAssistantDescription: "",
            aiAssistantTooltip: {
                open: false,
                scenario: "",
                AiAssistantApiBeingCalledForResourceIds: []
            },
            anchorElement : null,
            labelsToPassWhileApprovingVideoForecastingModelInInfoPopup : [],

            isFetchingModelReferenceData: false,
            modelReferenceData: [],
            isImageEditingPopupOpen : false,

            removeResourcesFromDataSetCollectionSelectionPopup: {
                open: false
            },
            resourceIdsWhichAreBeingDeleted: [],
        };
        this.onKeyEnter = this.onKeyEnter.bind(this);
        this.handleWindowResizeEvent = this.handleWindowResizeEvent.bind(this);
        this.handleChangePage = this.handleChangePage.bind(this);
        this.handleChangeRowsPerPage = this.handleChangeRowsPerPage.bind(this);
    }
   
    adjustResourceGridViewContainerWidth = () => {
        const resourcesTableContainerElement = document.getElementById(this.state.resourcesTableContainerElementId);
        if (resourcesTableContainerElement) {
            const resourcesGridViewElement = document.getElementById(this.state.resourcesGridViewContainerElementId);
            if (resourcesGridViewElement) {
                resourcesGridViewElement.style.width = `${resourcesTableContainerElement.offsetWidth}px`
            }
        }
    }    

    handleWindowResizeEvent() {
        this.adjustResourceGridViewContainerWidth();
    }

    setIsFetchingModelReferenceData = (valueToSet: boolean) => {
        this.setState(oldState=>({
            ...oldState,
            isFetchingModelReferenceData: valueToSet
        }))
    }

    fetchAndSetImageModelReferenceData = async () => {
        const urlSearchParams = new URLSearchParams(window.location.search);
        const modelId = urlSearchParams.get("model");
        if (modelId) {
            this.setIsFetchingModelReferenceData(true)
            const referenceDataAPiResponse = await ModelService.getModelReferenceData({modelId});
            this.setState(oldState=>({
                ...oldState,
                modelReferenceData: [...referenceDataAPiResponse.data]
            }))

            for (const referenceImage of referenceDataAPiResponse.data) {
                const image = new Image();
                image.src = referenceImage.fileUrl;
                image.onload = function(){}
            }
        }
    }
   
    async componentDidMount() {
        const { setSideDrawer, history, scenario } = this.props;
        if (scenario !== "dataSetCollectionTableDetailsPopup" && scenario !== "browseCollectionsPopup" && scenario !== "dataCart" && scenario !== "savedForLater" && scenario !== "aiMarketPlaceDataCart" && scenario !== "aiMarketplaceDataSelection") {
            setSideDrawer({ type: 'projectAnalytics', component: ModelAnalytics, isOpen: false})
        }
        if(scenario === "aiMarketplaceDataSelection" && !this.props.rowsForDataCart){
            this.props.setSelectedItems(this.context.aiMarketplaceDataCartState.resourceIds)
        }
        this.setAndGetData({ initialize: true });
        window.addEventListener('keyup', this.onKeyEnter);
        // this.props.setTopActionButton({ type: 'uploadData', onClickComponentToRender: UploadData });
        this.props.setTopActionButton({ type: 'uploadData' });
        this.unlisten = this.props.history.listen((location, action) => {
            const query = new URLSearchParams(location?.search);
            if (window?.location?.pathname?.indexOf("copilots") !== -1) {
                return;
            }
            if (query.get(ForceFetchDataFromDBQueryParam)) {
                query.delete(ForceFetchDataFromDBQueryParam);
                history.push(`${location?.pathname}?${query.toString()}`);
                this.setAndGetData({ location, forceFetchDataFromDB: true });
            } else {
                this.setAndGetData({ location });
            }
        });
        this.setLabelsAvailable();
        this.setAllowUserToSetAnyLabel();
        const query = new URLSearchParams(this.props?.history?.location?.search);
        const projectId = query.get("project");
        let modelId = query.get("model") || "";

        if (!modelId) {
            if (this.props.rowsForDataCart && this.props.rowsForDataCart?.length>0) {
                if (this.props.rowsForDataCart?.[0]?.model?._id) {
                    modelId = this.props.rowsForDataCart?.[0]?.model?._id || ""
                }
            }
        }

            if(modelId){
                const executeFunction = async () => {
                    const apiResponse = await ModelService.getModelDetails({
                    modelId : modelId,
                    modelSelectQuery: `type forecastingLabelsAvailable maxAudioTrimAllowedInMilliSeconds predictionStatusLogic annotationLabelsAvailable maximumMisPredictionDeviationAllowedInNumber ${ModelFields.AiAssistantEnabledField} ${ModelFields.ApiToCallOnAiAssistantInvocationField} ${ModelFields.AiAssistantDescriptionField} textOneHeadingForUI textTwoHeadingForUI`
                })
                this.setState({
                    maximumMisPredictionDeviationAllowedInNumber: Number(apiResponse.data.maximumMisPredictionDeviationAllowedInNumber||0),
                    predictionStatusLogic: apiResponse.data.predictionStatusLogic,
                    currentModelType: apiResponse.data.type,
                    textOneHeadingForTextComparisonUI: apiResponse.data.textOneHeadingForUI || AUDIO_TEXT_TRANSCRIPTION_RESOURCE_DEFAULT_TEXT_ONE_HEADING_FOR_UI,
                    textTwoHeadingForTextComparisonUI: apiResponse.data.textTwoHeadingForUI || AUDIO_TEXT_TRANSCRIPTION_RESOURCE_DEFAULT_TEXT_TWO_HEADING_FOR_UI,
                    imageList: [...(apiResponse.data.forecastingLabelsAvailable || [])],
                    modelAnnotationLabelsAvailable: apiResponse.data.annotationLabelsAvailable,
                    modelMaxAudioTrimAllowedInMilliSeconds: apiResponse.data.maxAudioTrimAllowedInMilliSeconds,
                    modelAiAssistantEnabled: apiResponse.data.aiAssistantEnabled || false,
                    modelApiToCallOnAiAssistantInvocation: apiResponse.data.apiToCallOnAiAssistantInvocation || "",
                    modelAiAssistantDescription: apiResponse.data.aiAssistantDescription || ""
                });
                }
                executeFunction();
            }

        window.addEventListener("resize", this.handleWindowResizeEvent)

        this.fetchAndSetUniqueResourceTagsForStatus();

        this.fetchAndSetImageModelReferenceData();

        if (projectId) {
            const projectModelsApiResponse = await ModelService.getProjectModels({project: projectId || "", type: "imageAnnotationGroup"});
    
            if (projectModelsApiResponse?.data?.models?.length>0) {
                this.setState({
                    projectModels: [...projectModelsApiResponse.data.models]
                })
            }
        }
    }

    async setLabelsAvailable() {
        const { location } = this.props;
        const urlQuery = new URLSearchParams(location?.search);
        const modelId: string = urlQuery.get('model') || '';
        if (!modelId) {
            console.error('modelId not found')
            return;
        }
        const apiResponse = await ModelService.getModelLabelsAvailable({modelId: modelId})
        if (apiResponse.data) {
            this.setState({labelsAvailable: [...apiResponse.data]})
        } else {
            this.setState({labelsAvailable: []})
        }
    }
   
    async setAllowUserToSetAnyLabel() {
        const { location } = this.props;
        const urlQuery = new URLSearchParams(location?.search);
        const modelId: string = urlQuery.get('model') || '';
        if (!modelId) {
            console.error('modelId not found')
            return;
        }
        const apiResponse = await ModelService.getAllowUserToSetAnyLabelInModel({modelId: modelId})
        this.setState({allowUserToSetAnyLabel: apiResponse?.data ? apiResponse?.data : false})
    }

    componentDidUpdate(prevProps: IProps, prevState: IState) {
        const { location } = this.props;

        const urlSearchParams = new URLSearchParams(location?.search);

        const modelIdURLQueryParamValue = urlSearchParams.get("model") || "";
        if (this.state.modelId !== modelIdURLQueryParamValue) {
            this.setState({modelId: modelIdURLQueryParamValue})
            console.log(this.state.status,"status");
        }

        if (
            (
                this.state.status !== prevState.status &&
                this.state.status 
            )
        ) {
            /**
             * Resource Status Tab in UI got changed 
             */

            this.fetchAndSetUniqueResourceTagsForStatus();
        }

        if (this.props.dataView === "grid-view") {
            this.adjustResourceGridViewContainerWidth();
        }

        const resourceTableHeadColumnsLength = (()=>{
            const resourceTableHeadRowElement = document.getElementById(resourceTableHeadRowId);
            if (resourceTableHeadRowElement?.children) {
                return resourceTableHeadRowElement.children.length;
            } 
            return 0;
        })();
        if (this.state.resourceTableHeadColumnsLength !== resourceTableHeadColumnsLength) {
            this.setState({resourceTableHeadColumnsLength: resourceTableHeadColumnsLength})
        }

        const urlQuery = new URLSearchParams(location?.search);
        const modelId: string = urlQuery.get('model') || '';
        if (this.props.selected !== prevProps.selected) {
                this.scrollSelectedResourceInTableToView(prevProps.selected as string, this.props.selected as string);
        }
        if (prevState.status !== this.state.status) {
            this.setState({allSelected: false})
        }
        if (this.props.selected) {

            let doesSelectedIdExistsInResources: boolean = false;
            for (let resourceIndex = 0; resourceIndex < this.props.data.resources.length; resourceIndex++) {
                const resource = this.props.data.resources[resourceIndex];
                if (resource._id === this.props.selected) {
                    doesSelectedIdExistsInResources = true;
                    break;
                }
            }
            if (!doesSelectedIdExistsInResources) {
                /**
                 * if selected resourceId is not available in resources,
                 * then removing that resourceId
                 */
                this.props.setSelectedData('');
            }
            // if (this.props.selected) {
            //     let indexToSet = this.getIndexFromResourceId(this.props.selected);
            //     if (!isNullOrUndefined(indexToSet)) {
            //         if (indexToSet !== this.state.index) {
            //             this.setState({index: indexToSet as number});
            //             this.setIndexValueInUrlQueryParam(indexToSet)
            //         }
            //     }
            // }
        }
        if (!this.props.selected && !isNullOrUndefined(this.state.index)) {
            if (this.props.data.resources[this.state.index]) {
                this.props.setSelectedData(this.props.data.resources[this.state.index]._id);
            }
        }
        if (
            prevState.status !== this.state.status &&
            this.state.status === 'approved' &&
            modelId
        ) {
            if (this.state.allSelected) {
                this.setState(prevSate => {
                    return { ...prevSate, allSelected: false }
                })
            }
            this.props.fetchDataSetCollectionsForDataTableFilter({modelId: modelId, projectionQuery: JSON.stringify({name: 1})});
        }
        if (prevProps.data.totalCount !== this.props.data.totalCount && this.props.data.totalCount === 0) {
            this.setState({ isViewForcastModelPopupOpen: false })
            this.setState({ isViewAndTrimAudioResourcePopupOpen: false }) 
            this.setState({ imageKeyPointsDialog: {isOpen: false} }) 
        }
    }

    detectedVideoAnnotations(videoAnnotation: Array<videoAnnotation>): number | "NA" {
      if (videoAnnotation.length) {
        let detectedFeedbackCount: number = 0;
        videoAnnotation.forEach((obj: videoAnnotation) => {
          if (obj.hasOwnProperty('annotationPrediction') && obj.annotationPrediction) {
            detectedFeedbackCount++;
          }
        });
        return detectedFeedbackCount ? detectedFeedbackCount : "NA";
      }
      else {
        return "NA";
      }
    }

    expertVideoAnnotations(videoAnnotation: Array<videoAnnotation>): number | "NA" {
      if (videoAnnotation.length) {
        let expertFeedbackCount: number = 0;
        videoAnnotation.forEach((obj: videoAnnotation) => {
          if (obj?.annotationPrediction?.approvalStatus || obj?.annotationByExpert?.approvalStatus) {
            expertFeedbackCount++;
          }
        });
        return expertFeedbackCount ? expertFeedbackCount : "NA";
      }
      else {
        return "NA";
      }
    }

    setIndexValueInUrlQueryParam(indexValueToSet: number | null) {
        if (!isNullOrUndefined(indexValueToSet)) {
            this.pushHistory({index: `${indexValueToSet}`, sortField: this.state.sort.field, sortOrder: this.state.sort.order})
        }
    }
   
    getIndexFromResourceId(resourceId: string): number | null {
        const resourceTableRow: HTMLElement | null = document.getElementById(`tableRow${resourceId}`);
        if (resourceTableRow) {
            const index = Number(resourceTableRow.dataset.tablerowindex)
            if (!isNullOrUndefined(index)) {
                return index;
            }
        }
        return null;
    }

    async scrollSelectedResourceInTableToView(previousSelectedResourceId: string, presentSelectedResourceId: string) {
        let selectedTableRowElement = document.getElementById(`tableRow${presentSelectedResourceId}`);
        let selectedTableRowIndex: number;
        let previousSelectedTableRowElement = document.getElementById(`tableRow${previousSelectedResourceId}`);
        let previousSelectedTableRowIndex: number | undefined;
        let tableScrollingScenario: 'going up' | 'going down' | '' = '';

        if (!selectedTableRowElement) {
            await new Promise<void>((resolve, reject) => {
                setTimeout(() => {
                    selectedTableRowElement = document.getElementById(`tableRow${presentSelectedResourceId}`);
                    previousSelectedTableRowElement = document.getElementById(`tableRow${previousSelectedResourceId}`);
                    resolve();
                }, 600);
            });
        }
        if (selectedTableRowElement) {
            selectedTableRowIndex = Number(selectedTableRowElement.dataset.tablerowindex);
            if (previousSelectedTableRowElement) {
                previousSelectedTableRowIndex = Number(previousSelectedTableRowElement.dataset.tablerowindex);
            }

            if (previousSelectedTableRowIndex !== null && previousSelectedTableRowIndex !== undefined) {
                if (selectedTableRowIndex > previousSelectedTableRowIndex) {
                    tableScrollingScenario = 'going down';
                } else if (selectedTableRowIndex < previousSelectedTableRowIndex) {
                    tableScrollingScenario = 'going up';
                } else {
                    tableScrollingScenario = '';
                }
            }

            if (!isElementVisibleToUserInsideScrollElement(selectedTableRowElement, document.getElementById(this.state.resourcesTableContainerElementId) as HTMLElement)) {
                if (selectedTableRowIndex === 0) {
                    const tableContainerElement = document.getElementById(this.state.resourcesTableContainerElementId);
                    if (tableContainerElement) {
                        tableContainerElement.scrollTop = 0;
                    }
                } else {
                    document.querySelector(`tr[data-tablerowindex='${tableScrollingScenario === 'going down' ? selectedTableRowIndex : selectedTableRowIndex - 1}']`)?.scrollIntoView({ block: tableScrollingScenario === 'going down' ? 'end' : 'start', inline: 'nearest' });
                }
            }
        }
    }

    isNameFilterApplied(): boolean {
        return this.state.filter.resourceName.searchValue || this.state.filter.resourceName.includeOnlyMispredictedParagraphs === "true" || this.state.filter.resourceName.excludeMispredictedParagraphs==="true"? true : false;
    }

    isPromptFilterApplied(): boolean {
        return this.state.filter.resourcePrompt.searchValue || this.state.filter.resourcePrompt.includeOnlyMispredictedParagraphs === "true" || this.state.filter.resourcePrompt.excludeMispredictedParagraphs==="true"? true : false;
    }

    isTagFilterApplied(): boolean {
        return this.state.filter.resourceTag.searchValue ? true : false;
    }
    isLabelFilterApplied(): boolean {
        return this.state.filter.resourceLabel.searchValue.length>0 || this.state.filter.resourceLabel.predictionSearchValue.length>0 || this.state.filter.resourceLabel.includeOnlyMispredictedRows === 'true'
         ? true 
         : false;
    }
    isConfidenceScoreFilterApplied(): boolean {
        if (
            this.state.filter.resourceConfidenceScore.minValue !== resourceConfidenceScoreDefaultMinValue ||
            this.state.filter.resourceConfidenceScore.maxValue !== resourceConfidenceScoreDefaultMaxValue
        ) {
            return true
        }
        return false;
    }
    isResourceStatusLastModifiedDateFilterApplied(): boolean {
        if (
            this.state.filter.resourceStatusLastModifiedDate.minValue !== '' ||
            this.state.filter.resourceStatusLastModifiedDate.maxValue !== ''
        ) {
            return true
        }
        return false;
    }

    isForecastDateFilterApplied(): boolean {
        if (
            this.state.filter.forecastDate.minValue !== '' ||
            this.state.filter.forecastDate.maxValue !== ''
        ) {
            return true
        }
        return false;
    }

    resetResourceStatusLastModifiedDateFilters(): Promise<void> {
        return new Promise<void>((resolve, reject)=>{
            this.setState(oldState=>{
                return {
                    ...oldState,
                    filter: {
                        ...oldState.filter,
                        resourceStatusLastModifiedDate: {
                            ...oldState.filter.resourceStatusLastModifiedDate,
                            maxValue: '',
                            minValue: ''
                        }
                    }
                }
            }, ()=>resolve())         
        })
    }

    resetForecastDateFilters(): Promise<void> {
        return new Promise<void>((resolve, reject)=>{
            this.setState(oldState=>{
                return {
                    ...oldState,
                    filter: {
                        ...oldState.filter,
                        forecastDate: {
                            ...oldState.filter.forecastDate,
                            maxValue: '',
                            minValue: ''
                        }
                    }
                }
            }, ()=>resolve())         
        })
    }

    resetTagFilters(): Promise<void> {
        return new Promise<void>((resolve, reject)=>{
            this.setState(oldState=>{
                return {
                    ...oldState,
                    filter: {
                        ...oldState.filter,
                        resourceTag: {
                            ...oldState.filter.resourceTag,
                            searchValue: '',
                            searchOperator: DataTableResourceTagSearchOperatorDefaultValue,
                        }
                    }
                }
            }, ()=>resolve())         
        })
    }

    resetNameFilters(): Promise<void> {
        return new Promise<void>((resolve, reject)=>{
            this.setState(oldState=>{
                return {
                    ...oldState,
                    filter: {
                        ...oldState.filter,
                        resourceName: {
                            ...oldState.filter.resourceName,
                            searchValue: '',
                            searchOperator: DataTableResourceNameSearchOperatorDefaultValue,
                            includeOnlyMispredictedParagraphs:"false",
                            excludeMispredictedParagraphs:"false"
                        }
                    }
                }
            }, ()=>resolve())         
        })
    }

    resetPromptFilters(): Promise<void> {
        return new Promise<void>((resolve, reject)=>{
            this.setState(oldState=>{
                return {
                    ...oldState,
                    filter: {
                        ...oldState.filter,
                        resourcePrompt: {
                            ...oldState.filter.resourcePrompt,
                            searchValue: '',
                            searchOperator: DataTableResourceNameSearchOperatorDefaultValue,
                            includeOnlyMispredictedParagraphs:"false",
                            excludeMispredictedParagraphs:"false"
                        }
                    }
                }
            }, ()=>resolve())         
        })
    }

    resetLabelFilters(): Promise<void> {
        return new Promise<void>((resolve, reject)=>{
            this.setState(oldState=>{
                return {
                    ...oldState,
                    filter: {
                        ...oldState.filter,
                        resourceLabel: {
                            ...oldState.filter.resourceLabel,
                            searchValue: [],
                            searchOperator: DataTableResourceLabelSearchOperatorDefaultValue,
                            includeOnlyMispredictedRows: 'false',
                            predictionSearchValue : [],
                            predictionSearchOperator: DataTableResourcePredictionSearchOperatorDefaultValue
                        }
                    }
                }
            }, ()=>resolve())         
        })
    }

    resetConfidenceScoreFilters(): Promise<void> {
        return new Promise<void>((resolve, reject)=>{
            this.setState(oldState=>{
                return {
                    ...oldState,
                    filter: {
                        ...oldState.filter,
                        resourceConfidenceScore: {
                            ...oldState.filter.resourceConfidenceScore,
                            maxValue: resourceConfidenceScoreDefaultMaxValue,
                            minValue: resourceConfidenceScoreDefaultMinValue
                        }
                    }
                }
            }, ()=>resolve())         
        })
    }

    componentWillUnmount() {
        this.isComponentUnmount = true;
          this.props.setSelectedItems([]);
        window.removeEventListener('keyup', this.onKeyEnter);
        window.removeEventListener('resize',this.handleWindowResizeEvent);
        this.unlisten();
    }

    componentWillReceiveProps(nextProps: IProps) {
        // if(nextProps.selected){
            // const element =document.getElementsByClassName(nextProps.selected);
        // }

        // if (nextProps.drawerData && nextProps.drawerData !== this.props.drawerData) {
        //     const { setSideDrawer, clearDrawerDataInData, setSelectedData } = this.props;
        //     console.log(nextProps.drawerData, '123::')
        //     setSideDrawer({ type: 'dataSelected', component: DataView});
        //     setSelectedData(nextProps.drawerData._id);
        //     clearDrawerDataInData();
        // } 
        if (this.props.isUpdating && !nextProps.isUpdating) {
            const { data, setSideDrawer } = this.props;
            const { index } = this.state;

            if (data?.resources[index]) {
                setSideDrawer({ type: 'dataSelected', component: DataView});
                // setSelectedData(data?.resources[index]?._id);
            } else {
                setSideDrawer({ type: 'projectAnalytics', component: ModelAnalytics})
            }
        }
    }

    addToCollectionsPayload(): DataSetCollectionQueryParams {
        const { location } = this.props;
        const { location: currentLocation } = this.props;
        const loc = location || currentLocation;
        const query = new URLSearchParams(loc?.search);
        const status = query?.get(ResourceStatusQueryParam) as ResourceStatus || 'approved';
        const model = query?.get('model') || '';
        return {
            model,
            status,
            ...this.formatQueryParamIntoPayload(query)
        }
    }

    formatQueryParamIntoPayload(query: URLSearchParams): TableDataQueryParams {
        const sortOrder: SortOrder = query?.get(ResourceSortOrderQueryParam) as SortOrder || 'descending';
        const sortField: DataSortField = query?.get(ResourceSortFieldQueryParam) as DataSortField || 'statusLastModifiedAt';

        const resourceNameSearchValue: DataTableResourceNameSearchValue = query?.get(DataTableResourceNameSearchValueQueryParam) || '' as DataTableResourceNameSearchValue;
        const resourceNameSearchOperator: ResourceNameSearchOperator = query?.get(DataTableResourceNameSearchOperatorQueryParam) as ResourceNameSearchOperator || DataTableResourceNameSearchOperatorDefaultValue;
        const includeOnlyMispredictedParagraphs=query?.get(IncludeOnlyMispredictedParagraphsParam) as IncludeOnlyMispredictedParagraphsParamType || "false";
        const excludeMispredictedParagraphs=query?.get(ExcludeMispredictedParagraphsQueryParam) as ExcludeMispredictedParagraphsParamType || "false";
        const resourceTagSearchValue: DataTableResourceTagSearchValue = query?.get(DataTableResourceTagSearchValueQueryParam) || '' as DataTableResourceTagSearchValue;
        const resourceTagSearchOperator: ResourceTagSearchOperator = query?.get(DataTableResourceTagSearchOperatorQueryParam) as ResourceTagSearchOperator || DataTableResourceTagSearchOperatorDefaultValue;
        const resourcePromptSearchValue: DataTablePromptSearchValue = query?.get(DataTablePromptSearchValueQueryParam) || '' as DataTableResourceNameSearchValue;
        const resourcePromptSearchOperator: ResourceNameSearchOperator = query?.get(DataTablePromptSearchOperatorQueryParam) as PromptSearchOperator || DataTablePromptSearchOperatorDefaultValue;

        console.log("format query",resourcePromptSearchValue, resourcePromptSearchOperator)

        const resourceConfidenceScoreMinValue: number = query?.get(DataTableResourceConfidenceScoreMinValueQueryParam) ? Number(query?.get(DataTableResourceConfidenceScoreMinValueQueryParam)) : resourceConfidenceScoreDefaultMinValue;
        const resourceConfidenceScoreMaxValue: number = query?.get(DataTableResourceConfidenceScoreMaxValueQueryParam) ? Number(query?.get(DataTableResourceConfidenceScoreMaxValueQueryParam)) : resourceConfidenceScoreDefaultMaxValue;

        const resourceStatusLastModifiedDateMinValue: string = query?.get(DataTableResourceStatusLastModifiedDateMinValueQueryParam) || '';
        const resourceStatusLastModifiedDateMaxValue: string = query?.get(DataTableResourceStatusLastModifiedDateMaxValueQueryParam) || '';

        const forecastDateMinValue: string = query?.get(ForecastDateMinValueQueryParam) || '';
        const forecastDateMaxValue: string = query?.get(ForecastDateMaxValueQueryParam) || '';

        let resourceLabelSearchValue: DataTableResourceLabelSearchValue
        if( query?.getAll(DataTableResourceLabelSearchValueQueryParam).length ===1 && query?.getAll(DataTableResourceLabelSearchValueQueryParam)[0] ===""){
            resourceLabelSearchValue = [] as DataTableResourceLabelSearchValue;
        }else{
            resourceLabelSearchValue = query?.get(DataTableResourceLabelSearchValueQueryParam)?.split(',') || [] as DataTableResourceLabelSearchValue;
        }
        const resourceLabelSearchOperator: ResourceLabelSearchOperator = query?.get(DataTableResourceLabelSearchOperatorQueryParam) as ResourceLabelSearchOperator || DataTableResourceLabelSearchOperatorDefaultValue;

        let resourcePredictionSearchValue: DataTableResourcePredictionSearchValue
        if( query?.getAll(DataTableResourcePredictionSearchValueQueryParam).length ===1 && query?.getAll(DataTableResourcePredictionSearchValueQueryParam)[0] ===""){
            resourcePredictionSearchValue = [] as DataTableResourcePredictionSearchValue;
        }else{
            resourcePredictionSearchValue = query?.get(DataTableResourcePredictionSearchValueQueryParam)?.split(',') || [] as DataTableResourcePredictionSearchValue;
        }
        const resourcePredictionSearchOperator: ResourcePredictionSearchOperator = query?.get(DataTableResourcePredictionSearchOperatorQueryParam) as ResourcePredictionSearchOperator || DataTableResourcePredictionSearchOperatorDefaultValue;

        const includeOnlyMispredictedRows: IncludeOnlyMispredictedRowsQueryParamType = query?.get(IncludeOnlyMispredictedRowsQueryParam) as IncludeOnlyMispredictedRowsQueryParamType || 'false';

        const resourceDataSetCollectionIds = query?.get(resourceDataSetCollectionIdQueryParam) ? JSON.parse(query?.get(resourceDataSetCollectionIdQueryParam) || "") : [];

        const resourcesWhichAreNotPartOfAnyDataSetCollection = query?.get(DataTableResourcesWhichAreNotPartOfAnyDataSetCollectionQueryParam) as ResourcesWhichAreNotPartOfAnyDataSetCollectionQueryParamType || '';

        const labelRegexArray = convertLabelSearchOperatorToRegex(resourceLabelSearchOperator,resourceLabelSearchValue)
        const predictionRegexArray = convertPredictionSearchOperatorToRegex(resourcePredictionSearchOperator, resourcePredictionSearchValue)
        return {
            sortField,
            sortOrder,
            promptRegex: convertPromptSearchOperatorToRegex(resourcePromptSearchOperator, resourcePromptSearchValue),
            nameRegex: convertNameSearchOperatorToRegex(resourceNameSearchOperator, resourceNameSearchValue),
            tagRegex: convertTagSearchOperatorToRegex(resourceTagSearchOperator, resourceTagSearchValue),
            includeOnlyMispredictedParagraphs,
            excludeMispredictedParagraphs,
            labelRegex: labelRegexArray,
            predictionRegex: predictionRegexArray,
            includeOnlyMispredictedRows,
            resourceConfidenceScoreMinValue,
            resourceConfidenceScoreMaxValue,
            resourceStatusLastModifiedDateMinValue: resourceStatusLastModifiedDateMinValue,
            resourceStatusLastModifiedDateMaxValue: resourceStatusLastModifiedDateMaxValue,
            forecastDateMinValue: forecastDateMinValue,
            forecastDateMaxValue: forecastDateMaxValue,
            resourceDataSetCollectionIds,
            resourcesWhichAreNotPartOfAnyDataSetCollection,
            limit: "50000"
        }
    }

    setAndGetData({ location, initialize = false, forceFetchDataFromDB=false, userWantsToSelectAllResourcesMatchingThisFilterScenario=false }: ISetAndGetDataProps) {
        const { getData, location: currentLocation,  setSideDrawer } = this.props;
        const loc = location || currentLocation;
        // const query = new URLSearchParams(loc?.search);
        const query = new URLSearchParams(window.location.search);
        const limit = query?.get(ResourceLimitQueryParam) || '10';
        const offset = query?.get(ResourceOffsetQueryParam) || '1';
        const status = query?.get(ResourceStatusQueryParam) as ResourceStatus || 'backlog';

        const resourceIdFromUrlQueryParam = query.get(resourceIdURLQueryParam) || ""

        // delete resourceIdqueryParam from url
        BrowserUrlService.removeQueryParamFromBrowser({propertyToRemove: resourceIdURLQueryParam})


        // const model_type = query?.get('type') || '';
        const { sortField, sortOrder, promptRegex, nameRegex, tagRegex,includeOnlyMispredictedParagraphs ,excludeMispredictedParagraphs,labelRegex, predictionRegex, includeOnlyMispredictedRows, resourceConfidenceScoreMinValue, resourceConfidenceScoreMaxValue, resourceStatusLastModifiedDateMinValue, resourceStatusLastModifiedDateMaxValue, forecastDateMinValue, forecastDateMaxValue, resourceDataSetCollectionIds, resourcesWhichAreNotPartOfAnyDataSetCollection } = this.formatQueryParamIntoPayload(query);

        console.log("location------>", query)
        console.log("promptRegex--->", promptRegex)
        const resourceNameSearchValue: DataTableResourceNameSearchValue = query?.get(DataTableResourceNameSearchValueQueryParam) || '' as DataTableResourceNameSearchValue;
        const resourceNameSearchOperator: ResourceNameSearchOperator = query?.get(DataTableResourceNameSearchOperatorQueryParam) as ResourceNameSearchOperator || DataTableResourceNameSearchOperatorDefaultValue;

        const resourcePromptSearchValue: DataTablePromptSearchValue = query?.get(DataTablePromptSearchValueQueryParam) || '' as DataTablePromptSearchValue;
        const resourcePromptSearchOperator: PromptSearchOperator = query?.get(DataTablePromptSearchOperatorQueryParam) as PromptSearchOperator || DataTablePromptSearchOperatorDefaultValue;

        const resourceTagSearchValue: DataTableResourceTagSearchValue = query?.get(DataTableResourceTagSearchValueQueryParam) || '' as DataTableResourceTagSearchValue;
        const resourceTagSearchOperator: ResourceTagSearchOperator = query?.get(DataTableResourceTagSearchOperatorQueryParam) as ResourceTagSearchOperator || DataTableResourceTagSearchOperatorDefaultValue;
        
        let resourceLabelSearchValue: DataTableResourceLabelSearchValue
        if(query?.getAll(DataTableResourceLabelSearchValueQueryParam).length === 1 && query?.getAll(DataTableResourceLabelSearchValueQueryParam)[0] === ''){
            resourceLabelSearchValue = []
        }else {
            resourceLabelSearchValue = query?.get(DataTableResourceLabelSearchValueQueryParam)?.split(',') || [] as DataTableResourceLabelSearchValue;
        }
        const resourceLabelSearchOperator: ResourceLabelSearchOperator = query?.get(DataTableResourceLabelSearchOperatorQueryParam) as ResourceLabelSearchOperator || DataTableResourceLabelSearchOperatorDefaultValue;

        let resourcePredictionSearchValue: DataTableResourcePredictionSearchValue
        if(query?.getAll(DataTableResourcePredictionSearchValueQueryParam).length === 1 && query?.getAll(DataTableResourcePredictionSearchValueQueryParam)[0] === ''){
            resourcePredictionSearchValue = []
        }else {
            resourcePredictionSearchValue = query?.get(DataTableResourcePredictionSearchValueQueryParam)?.split(',') || [] as DataTableResourcePredictionSearchValue;
        }
        const resourcePredictionSearchOperator: ResourcePredictionSearchOperator = query?.get(DataTableResourcePredictionSearchOperatorQueryParam) as ResourcePredictionSearchOperator || DataTableResourcePredictionSearchOperatorDefaultValue;
       
         
        //const drawer = query?.get('drawer') || 'analytics';
        const model = query?.get('model') || ''; // TODO add condition 
        const index = parseInt(query?.get('index') || '-1', 10) ?? -1;
        const currentModelType= query?.get('type') as IModelType;
        const { page, rowsPerPage, status: stateStatus, sort } = this.state;

        /**
         * On component destroy also api call used to go without any modelId in request payload.
         * Due to this made a check that if modelId is empty which will be in case of component destroying.
         * To not triiger api call
         */
        if (!model) {
            return;
        }

        if (
            userWantsToSelectAllResourcesMatchingThisFilterScenario ||
            forceFetchDataFromDB || 
            resourceStatusLastModifiedDateMinValue !== this.state.filter.resourceStatusLastModifiedDate.minValue || 
            resourceStatusLastModifiedDateMaxValue !== this.state.filter.resourceStatusLastModifiedDate.maxValue || 
            forecastDateMinValue !== this.state.filter.forecastDate.minValue || 
            forecastDateMaxValue !== this.state.filter.forecastDate.maxValue || 
            resourceConfidenceScoreMinValue !== this.state.filter.resourceConfidenceScore.minValue || 
            resourceConfidenceScoreMaxValue !== this.state.filter.resourceConfidenceScore.maxValue ||  
            JSON.stringify(resourceLabelSearchValue) !== JSON.stringify(this.state.filter.resourceLabel.searchValue) || 
            resourceLabelSearchOperator !== this.state.filter.resourceLabel.searchOperator ||
            JSON.stringify(resourcePredictionSearchValue) !== JSON.stringify(this.state.filter.resourceLabel.predictionSearchValue) || 
            resourcePredictionSearchOperator !== this.state.filter.resourceLabel.predictionSearchOperator ||
            includeOnlyMispredictedRows !== this.state.filter.resourceLabel.includeOnlyMispredictedRows ||
            resourceTagSearchValue !== this.state.filter.resourceTag.searchValue || 
            resourceTagSearchOperator !== this.state.filter.resourceTag.searchOperator ||
            resourceNameSearchValue !== this.state.filter.resourceName.searchValue || 
            resourceNameSearchOperator !== this.state.filter.resourceName.searchOperator ||
            resourcePromptSearchValue !== this.state.filter.resourcePrompt.searchValue ||
            resourcePromptSearchOperator !== this.state.filter.resourcePrompt.searchOperator ||
            !areBothArraysEqual(resourceDataSetCollectionIds, this.state.filter.resourceDataSetCollectionIds) ||
            resourcesWhichAreNotPartOfAnyDataSetCollection !== this.state.filter.resourcesWhichAreNotPartOfAnyDataSetCollection ||
            offset !== `${page + 1}` || 
            sortField !== sort.field || 
            sortOrder !== sort.order || 
            limit !== `${rowsPerPage}` || 
            status !== stateStatus || 
            initialize
        ) {
            if (!userWantsToSelectAllResourcesMatchingThisFilterScenario) {
                this.setState({
                    rowsPerPage: parseInt(query?.get(ResourceLimitQueryParam) || '10', 10),
                    page: parseInt((query?.get(ResourceOffsetQueryParam) || '1'), 10) - 1,
                    status,
                    index,
                    // selectedItems: [],
                    sort: {
                        order: sortOrder,
                        field: sortField
                    },
                    filter: {
                        resourcesWhichAreNotPartOfAnyDataSetCollection: resourcesWhichAreNotPartOfAnyDataSetCollection,
                        resourceDataSetCollectionIds: resourceDataSetCollectionIds,
                        resourcePrompt: {
                            ...this.state.filter.resourcePrompt,
                            searchValue: resourcePromptSearchValue,
                            searchOperator: resourcePromptSearchOperator
                        },
                        resourceName: {
                            ...this.state.filter.resourceName,
                            searchValue: resourceNameSearchValue,
                            searchOperator: resourceNameSearchOperator
                        },
                        resourceTag: {
                            ...this.state.filter.resourceTag,
                            searchValue: resourceTagSearchValue,
                            searchOperator: resourceTagSearchOperator
                        },
                        resourceLabel: {
                            ...this.state.filter.resourceLabel,
                            searchValue: resourceLabelSearchValue,
                            searchOperator: resourceLabelSearchOperator,
                            includeOnlyMispredictedRows: includeOnlyMispredictedRows,
                            predictionSearchValue : resourcePredictionSearchValue,
                            predictionSearchOperator: resourcePredictionSearchOperator
                        },
                        resourceConfidenceScore: {
                            ...this.state.filter.resourceConfidenceScore,
                            minValue: resourceConfidenceScoreMinValue,
                            maxValue: resourceConfidenceScoreMaxValue
                        },
                        resourceStatusLastModifiedDate: {
                            ...this.state.filter.resourceStatusLastModifiedDate,
                            minValue: resourceStatusLastModifiedDateMinValue,
                            maxValue: resourceStatusLastModifiedDateMaxValue
                        },
                        forecastDate: {
                            ...this.state.filter.forecastDate,
                            minValue: forecastDateMinValue,
                            maxValue: forecastDateMaxValue
                        }
                    }
                });
                
                //add includeOnlyMispredictedParagraphs & excludeMispredictedParagraphs property
                 if(currentModelType==="bookTranslation" && (includeOnlyMispredictedParagraphs !== this.state.filter.resourceName.includeOnlyMispredictedParagraphs || 
                       excludeMispredictedParagraphs !== this.state.filter.resourceName.excludeMispredictedParagraphs ) ){
                       this.setState(oldState=>{
                        return { 
                        ...oldState,
                        filter: {
                            ...oldState.filter,
                            resourceName: {
                                ...oldState.filter.resourceName,
                                includeOnlyMispredictedParagraphs:includeOnlyMispredictedParagraphs,
                                excludeMispredictedParagraphs:excludeMispredictedParagraphs
                            }
                        }
                      }})
                 }
            }

            const requestPayloadObj  = {
                limit: userWantsToSelectAllResourcesMatchingThisFilterScenario ? '-1' : limit, 
                    offset: userWantsToSelectAllResourcesMatchingThisFilterScenario ? '-1' : offset, 
                    status, 
                    model, 
                    sortField,
                    includeOnlyMispredictedParagraphs:(currentModelType!=="bookTranslation"?undefined:includeOnlyMispredictedParagraphs),
                    excludeMispredictedParagraphs:(currentModelType!=="bookTranslation"?undefined:excludeMispredictedParagraphs),
                    sortOrder, 
                    promptRegex: (promptRegex? promptRegex: undefined),
                    nameRegex, 
                    tagRegex, 
                    deviceId: query.get('resourceDeviceIdSearchValue') || "",
                    labelRegex : ((labelRegex.length > 0 ) ? labelRegex : undefined),
                    predictionRegex : ((predictionRegex.length > 0) ? predictionRegex : undefined),
                    includeOnlyMispredictedRows, 
                    resourceConfidenceScoreMinValue, 
                    resourceConfidenceScoreMaxValue, 
                    resourceStatusLastModifiedDateMinValue: resourceStatusLastModifiedDateMinValue, 
                    resourceStatusLastModifiedDateMaxValue: resourceStatusLastModifiedDateMaxValue,
                    forecastDateMinValue: forecastDateMinValue,
                    forecastDateMaxValue: forecastDateMaxValue,
                    resourceDataSetCollectionIds: JSON.stringify(resourceDataSetCollectionIds), 
                    resourcesWhichAreNotPartOfAnyDataSetCollection,
                    resourceSelectQuery: userWantsToSelectAllResourcesMatchingThisFilterScenario ? '_id -model -dataSetCollections' : '' 
            }

            if (this.props.scenario === "dataSetCollectionTableDetailsPopup") {
                // @ts-ignore
                requestPayloadObj.scenario = 
                ResourceDataCollectionScenarioValue;
            }

            const resourceIds: string = (()=>{
                const storageResourceIds = localStorage.getItem(RESOURCE_IDS_TO_FILTER);
                localStorage.removeItem(RESOURCE_IDS_TO_FILTER);
                if (storageResourceIds) {
                    return storageResourceIds
                }
                return "";
            })();

            if (resourceIds) {
                // @ts-ignore
                requestPayloadObj.ids = resourceIds;
            }

            if (resourceIdFromUrlQueryParam) {
                // @ts-ignore
                requestPayloadObj.ids = JSON.stringify([resourceIdFromUrlQueryParam])
            }

            if(!requestPayloadObj.labelRegex ){
                delete requestPayloadObj.labelRegex;
            }
            if(!requestPayloadObj.predictionRegex ){
                delete requestPayloadObj.predictionRegex;
            }

            if(!requestPayloadObj.promptRegex ){
                delete requestPayloadObj.promptRegex;
            }


            if(currentModelType!=="bookTranslation"){
              delete requestPayloadObj.includeOnlyMispredictedParagraphs
              delete requestPayloadObj.excludeMispredictedParagraphs
            }

            if (this.state.getDataApiCancelFunction) {
                this.state.getDataApiCancelFunction();
                this.setState({getDataApiCancelFunction: null})
            }

            if(!this.props.rowsForDataCart){
            getData(
                requestPayloadObj, 
                false, 
                index,
                userWantsToSelectAllResourcesMatchingThisFilterScenario,
                (cancelApiFunction) => {
                    this.setState({getDataApiCancelFunction: cancelApiFunction})
                }
            )
            .then((response)=>{
                if (!userWantsToSelectAllResourcesMatchingThisFilterScenario) {
                    if (response?.data?.resources?.length===0) {
                        if(loc?.pathname.search("copilots") === -1){
                            if (this.props.scenario !== "dataSetCollectionTableDetailsPopup" && this.props.scenario !== "browseCollectionsPopup" && this.props.scenario !== "aiMarketPlaceDataCart" && this.props.scenario !== 'aiMarketplaceDataSelection' && !this.isComponentUnmount) {
                                setSideDrawer({ type: 'projectAnalytics', component: ModelAnalytics, isOpen: false});
                            }
                        }
                    }
                }
            });
            }
        } else if (index > -1) {
            this.setState({ index });
        }
        // setSideDrawer({ type: 'dataSelected', component: DataView });
    }

    onKeyEnter(e: KeyboardEvent) {

        // if (this.props.shouldBlockDataTableKeyboardEvents) {
        //     /**
        //      * If label, tag input field is selected, then not listening to 
        //      * keyboard events
        //      */
        //     return ;
        // }

        try {
            const focusedElement = document.activeElement;
            if (
                focusedElement?.tagName?.toLowerCase() === "textarea" ||
                (focusedElement?.tagName?.toLowerCase() === "input" &&
                // @ts-ignore
                (focusedElement?.type?.toLowerCase() === "number" || focusedElement?.type?.toLowerCase() === "text")))
            {
                return;
            }            
        } catch (error) {
            
        }

        if (this.props.isFetching) {
            return;
        }

        const { data, isFetching, selected, setSelectedData, setSideDrawer } = this.props;
        const query = new URLSearchParams(this.props.history.location.search);

        const nameSearchValue: DataTableResourceNameSearchValue = query?.get(DataTableResourceNameSearchValueQueryParam) as DataTableResourceNameSearchValue;
        const nameSearchOperator: ResourceNameSearchOperator = query?.get(DataTableResourceNameSearchOperatorQueryParam) as ResourceNameSearchOperator || DataTableResourceNameSearchOperatorDefaultValue;
        const nameRegex = convertNameSearchOperatorToRegex(nameSearchOperator, nameSearchValue);

        const promptSearchValue: DataTablePromptSearchValue = query?.get(DataTablePromptSearchValueQueryParam) as DataTablePromptSearchValue;
        const promptSearchOperator: PromptSearchOperator = query?.get(DataTablePromptSearchOperatorQueryParam) as PromptSearchOperator || DataTablePromptSearchOperatorDefaultValue;
        const promptRegex = convertPromptSearchOperatorToRegex(promptSearchOperator, promptSearchValue);

        const tagSearchValue: DataTableResourceTagSearchValue = query?.get(DataTableResourceTagSearchValueQueryParam) as DataTableResourceTagSearchValue;
        const tagSearchOperator: ResourceTagSearchOperator = query?.get(DataTableResourceTagSearchOperatorQueryParam) as ResourceTagSearchOperator || DataTableResourceTagSearchOperatorDefaultValue;
        const tagRegex = convertTagSearchOperatorToRegex(tagSearchOperator, tagSearchValue);
        
        if (!isFetching && selected) {
            //const { status } = this.state;
            const { page, rowsPerPage } = this.state;
            let selectedIndex = data.resources.findIndex(item => item._id === selected);
            if (e.keyCode === 37) {
                if (selectedIndex > 0) {
                    selectedIndex--;
                    this.pushHistory({ index: `${selectedIndex}`, sortField: this.state.sort.field, sortOrder: this.state.sort.order, nameRegex, tagRegex, promptRegex});
                    setSelectedData(data.resources[selectedIndex]?._id);
                } else if (page > 0) {
                    this.handleChangePage(null, page - 1, rowsPerPage - 1);
                }
            } else if (e.keyCode === 39) {
                if (selectedIndex < data.resources.length - 1) {
                    selectedIndex++;
                    this.pushHistory({ index: `${selectedIndex}`, sortField: this.state.sort.field, sortOrder: this.state.sort.order, nameRegex, tagRegex, promptRegex});
                    setSideDrawer({ type: 'dataSelected', component: DataView, isOpen: this.props.isSideDrawerOpen ? true : false});
                    setSelectedData(data.resources[selectedIndex]?._id);
                } else if ((rowsPerPage * (page + 1)) < data.totalCount) {
                    this.handleChangePage(null, page + 1, 0);
                }
            } else if (e.keyCode === 13 || e.keyCode === 8) {
                // let newStatus = ''
                // if (status === 'backlog') {
                //     newStatus = 'approved';
                // } else if (status === 'approved') {
                //     newStatus = 'deleted';  
                // }
                // if (newStatus) {
                //     updateData({ id: [selected._id], status: newStatus }, { offset: `${page + 1}`, limit: `${rowsPerPage}`, status });
                //     this.setState({ selectedItems: [] });
                // }
            }
        }
    }

    getSelectedData(selectedId: string, resources: IData[]): IData | null {
        if (resources.length > 0) {
            for (const resource of resources) {
                if (resource._id === selectedId) {
                    return resource;
                }
            }
        }
        return null;
    }

    onUpdateData = ({ ids, status: newStatus }: { ids: string[], status: ResourceStatus }) => {
        const { updateData, location, selected } = this.props;
        const selectedData = this.getSelectedData(selected as string, this.props.data.resources);
        const { rowsPerPage, page, status } = this.state;
        const query = new URLSearchParams(location?.search);
        const { sortOrder, sortField, promptRegex, nameRegex, tagRegex, labelRegex, predictionRegex, includeOnlyMispredictedRows, resourceConfidenceScoreMinValue, resourceConfidenceScoreMaxValue, resourceStatusLastModifiedDateMinValue, resourceStatusLastModifiedDateMaxValue, resourceDataSetCollectionIds, forecastDateMaxValue, forecastDateMinValue, resourcesWhichAreNotPartOfAnyDataSetCollection } = this.formatQueryParamIntoPayload(query);
        const modelId = selectedData?.model._id;
        const model = query.get('model') || modelId;
        updateData({ id: ids, status: newStatus }, { limit: `${rowsPerPage}`, offset: `${page + 1}`, status, model, sortOrder, sortField, promptRegex, nameRegex, tagRegex, labelRegex, predictionRegex, resourceConfidenceScoreMinValue, resourceConfidenceScoreMaxValue, resourceStatusLastModifiedDateMinValue, resourceStatusLastModifiedDateMaxValue, includeOnlyMispredictedRows, resourceDataSetCollectionIds: JSON.stringify(resourceDataSetCollectionIds), forecastDateMaxValue, forecastDateMinValue, resourcesWhichAreNotPartOfAnyDataSetCollection}, {shouldFetchLatestData: ids?.length === 1 && !this.state.allSelected ? false : true}, this.state.allSelected);
        this.setState(prevSate => {
            return {
                ...prevSate,
                allSelected: false
            }
        })
        if (ids.length > 1) {
            // this.setState({ selectedItems: [] });
            this.props.setSelectedItems([]);
        } else if (ids.length === 1 && status) {
            let totalCount = this.props.data.totalCount;
            let resources = this.props.data.resources;
            for (let resourceIndex = 0; resourceIndex < resources.length; resourceIndex++) {
                const resource = resources[resourceIndex];
                for (let idIndex = 0; idIndex < ids.length; idIndex++) {
                    const id = ids[idIndex];
                    if (resource._id === id) {
                        totalCount -= 1;
                        // const indexOfIdInSelectedItems: number = this.state.selectedItems.indexOf(id);
                        const indexOfIdInSelectedItems: number = this.props.selectedItems.indexOf(id);
                        if (indexOfIdInSelectedItems !== -1) {
                            let selectedItemsValueToUpdate = [...this.props.selectedItems];
                            selectedItemsValueToUpdate.splice(indexOfIdInSelectedItems, 1);
                            // this.setState({selectedItems: [...selectedItemsValueToUpdate]});
                            this.props.setSelectedItems([...selectedItemsValueToUpdate])
                        }
                        resources.splice(resourceIndex, 1);
                        resourceIndex = resourceIndex - 1;
                    }
                }
            }
            this.props.setResourcesTotalCount(totalCount);
            this.props.setResources(resources);
        }
    }
    changeView = (event: React.MouseEvent<HTMLElement>, newView: string) => {
        this.props.updateDataViewType(newView);
        
    };
    // handleExpandClick ()

    onUpdateLabelData = ({ ids, label: newLabel, prediction: oldLabel }: { ids: string[], label: string, prediction: string }) => {
        const { updateData, location, selected } = this.props;
        const selectedData = this.getSelectedData(selected as string, this.props.data.resources);
        const { rowsPerPage, page, status } = this.state;
        const query = new URLSearchParams(location?.search);
        const modelId = selectedData?.model._id;
        // const model_Type = selectedData?.model.type;
        const model = query.get('model') || modelId;
        // const model_type = query.get('type') || model_Type;
        const sortOrder = query.get(ResourceSortOrderQueryParam) as SortOrder || this.state.sort.order;
        const sortField = query.get(ResourceSortFieldQueryParam) as DataSortField || this.state.sort.field;   

        const nameSearchValue: DataTableResourceNameSearchValue = query?.get(DataTableResourceNameSearchValueQueryParam) as DataTableResourceNameSearchValue;
        const nameSearchOperator: ResourceNameSearchOperator = query?.get(DataTableResourceNameSearchOperatorQueryParam) as ResourceNameSearchOperator || DataTableResourceNameSearchOperatorDefaultValue;
        const nameRegex = convertNameSearchOperatorToRegex(nameSearchOperator, nameSearchValue);

        const promptSearchValue: DataTablePromptSearchValue = query?.get(DataTablePromptSearchValueQueryParam) as DataTablePromptSearchValue;
        const promptSearchOperator: PromptSearchOperator = query?.get(DataTablePromptSearchOperatorQueryParam) as PromptSearchOperator || DataTablePromptSearchOperatorDefaultValue;
        const promptRegex = convertPromptSearchOperatorToRegex(promptSearchOperator, promptSearchValue);

        const tagSearchValue: DataTableResourceTagSearchValue = query?.get(DataTableResourceTagSearchValueQueryParam) as DataTableResourceTagSearchValue;
        const tagSearchOperator: ResourceTagSearchOperator = query?.get(DataTableResourceTagSearchOperatorQueryParam) as ResourceTagSearchOperator || DataTableResourceTagSearchOperatorDefaultValue;
        const tagRegex = convertTagSearchOperatorToRegex(tagSearchOperator, tagSearchValue);

        updateData({ id: ids, label: newLabel, prediction: oldLabel }, { limit: `${rowsPerPage}`, offset: `${page + 1}`, status, model, sortOrder, sortField, nameRegex, tagRegex, promptRegex},{shouldFetchLatestData: false});
        // this.setState({ selectedItems: [] });

        const resources = this.props.data.resources;
        if (ids?.length>0) {
            for (const id of ids) {
                for (let resourceIndex = 0; resourceIndex < resources.length; resourceIndex++) {
                    const resource = resources[resourceIndex];
                    if (resource._id === id) {
                        resource.label = newLabel;
                        resource.prediction = oldLabel;
                        resources[resourceIndex] = resource;
                        break;
                    }
                }
            }
            this.props.setResources(resources);
        }         
    }

    onUpdateTagData = ({ ids, tag }: {ids: string[], tag: string}) => {
        const { updateData, location, selected } = this.props;
        const selectedData = this.getSelectedData(selected as string, this.props.data.resources);
        const { rowsPerPage, page, status } = this.state;
        const query = new URLSearchParams(location?.search);
        const modelId = selectedData?.model._id;
        // const model_Type = selectedData?.model.type;
        const model = query.get('model') || modelId;
        // const model_type = query.get('type') || model_Type;
        const sortOrder = query.get(ResourceSortOrderQueryParam) as SortOrder || this.state.sort.order;
        const sortField = query.get(ResourceSortFieldQueryParam) as DataSortField || this.state.sort.field;        

        const nameSearchValue: DataTableResourceNameSearchValue = query?.get(DataTableResourceNameSearchValueQueryParam) as DataTableResourceNameSearchValue;
        const nameSearchOperator: ResourceNameSearchOperator = query?.get(DataTableResourceNameSearchOperatorQueryParam) as ResourceNameSearchOperator || DataTableResourceNameSearchOperatorDefaultValue;
        const nameRegex = convertNameSearchOperatorToRegex(nameSearchOperator, nameSearchValue);      
        
        const promptSearchValue: DataTablePromptSearchValue = query?.get(DataTablePromptSearchValueQueryParam) as DataTablePromptSearchValue;
        const promptSearchOperator: PromptSearchOperator = query?.get(DataTablePromptSearchOperatorQueryParam) as PromptSearchOperator || DataTablePromptSearchOperatorDefaultValue;
        const promptRegex = convertPromptSearchOperatorToRegex(promptSearchOperator, promptSearchValue);
        
        updateData({ id: ids, tag }, { limit: `${rowsPerPage}`, offset: `${page + 1}`, status, model, sortOrder, sortField, nameRegex, promptRegex}, {shouldFetchLatestData: false});
        // this.setState({ selectedItems: [] });

        const resources = this.props.data.resources;
        if (ids?.length>0) {
            for (const id of ids) {
                for (let resourceIndex = 0; resourceIndex < resources.length; resourceIndex++) {
                    const resource = resources[resourceIndex];
                    if (resource._id === id) {
                        resource.tag = tag;
                        resources[resourceIndex] = resource;
                    }
                }
            }
            this.props.setResources(resources);
        }

    }

    /*
    onUpdateLabelData1 = ({ ids: ids, label: newLabel }: { ids: string[], label: string }) => {
        const { updateData, location, selected } = this.props;
        const selectedData = this.getSelectedData(selected as string, this.props.data.resources);
        const { rowsPerPage, page, status } = this.state;
        const query = new URLSearchParams(location?.search);
        const modelId = selectedData?.model._id;
        // const model_Type = selectedData?.model.type;
        const model = query.get('model') || modelId;
        // const model_type = query.get('type') || model_Type;
        const sortOrder = query.get(ResourceSortOrderQueryParam) as SortOrder || this.state.sort.order;
        const sortField = query.get(ResourceSortFieldQueryParam) as DataSortField || this.state.sort.field;             
        // const resourceNameContains: DataTableResourceNameSearchValue = query.get(DataTableResourceNameSearchValueQueryParam) || '' as DataTableResourceNameSearchValue;
        updateData({ id: ids, label: newLabel }, { limit: `${rowsPerPage}`, offset: `${page + 1}`, status, model, sortOrder, sortField, nameRegex: this.state.filter.resourceName.searchValue }, {shouldFetchLatestData: false});
        // this.setState({ selectedItems: [] });

        const resources = this.props.data.resources;
        if (ids?.length>0) {
            for (const id of ids) {
                for (let resourceIndex = 0; resourceIndex < resources.length; resourceIndex++) {
                    const resource = resources[resourceIndex];
                    if (resource._id === id) {
                        resource.label = newLabel;
                        resources[resourceIndex] = resource;
                        break;
                    }
                }
            }
            this.props.setResources(resources);
        }        
    }
    */

    handleSelectAllClick = (event: React.ChangeEvent<HTMLInputElement>) => {
        const { data } = this.props;
        if (event.target.checked) {
            const newSelecteds = data.resources?.map((n) => n._id);
            // this.setState({ selectedItems: newSelecteds });
            this.props.setSelectedItems(newSelecteds);
            return;
        }
        // this.setState({ selectedItems: [] });
        this.props.setSelectedItems([]);
        this.setState(prevSate => {
            return {
                ...prevSate,
                allSelected: false
            }
        })
    };

    handleClick = (data: IData, index: number) => {
        // const { updateData, location, selected } = this.props;
        // const { rowsPerPage, page, status } = this.state;
        this.props.setSideDrawer({ type: 'dataSelected', component: DataView, isOpen: true });
        this.props.setSelectedData(data._id);
        this.pushHistory({ index: `${index}`, sortOrder: this.state.sort.order, sortField: this.state.sort.field, nameRegex: this.state.filter.resourceName.searchValue});
        // const search = this.props.history.location.search;
        //const modelId = selected?.model._id;
        //const modelType = selected?.model.type;
        //const modelProject = selected?.model.project;
        //backlog?status=backlog&offset=1&limit=10&project=611389c0a251af51edd3586a&model=6148608dc4100e3c7c60d0a7&type=audio&index=0
        //const searchRoute = search?.search;
        /* this.props.history.push({
            pathname: this.props.history.location.pathname,
            search: '?status='+status+'&offset='+(page + 1)+"&limit="+rowsPerPage+"&project="+modelProject+"&model="+modelId+"&type="+modelType+"&index="+index,
            state: this.props.data.resources
        }) */
    }

    onCheck = (event: React.MouseEvent<unknown>, resourceId: string) => {
        // const { selectedItems } = this.state;
        const selectedItems = this.props.selectedItems
        const selectedIndex = selectedItems.indexOf(resourceId);
        let newSelected: string[] = [];

        if (selectedIndex === -1) {
            newSelected = newSelected.concat(selectedItems, resourceId);
        } else if (selectedIndex === 0) {
            newSelected = newSelected.concat(selectedItems.slice(1));
        } else if (selectedIndex === selectedItems.length - 1) {
            newSelected = newSelected.concat(selectedItems.slice(0, -1));
        } else if (selectedIndex > 0) {
            newSelected = newSelected.concat(
                selectedItems.slice(0, selectedIndex),
                selectedItems.slice(selectedIndex + 1),
            );
        }

        if (selectedIndex !== -1) {
            // means an element is being unselected

            /**
             * when user untick a resource in UI. if all resources are selected which are not limited by pagination.
             * Then tick only those resources which are showing in UI 
             */
            newSelected  = this.removeSelectedItemsWhichAreNotPresentInRowsInUI(
                newSelected,
                this.props.data.resources
            )
        }

        // this.setState({ selectedItems: newSelected });
        this.props.setSelectedItems(newSelected);
    };

    doesAnyResourceContainThisId(resources: {_id: string}[], resourceId: string): boolean {
        for (const resource of resources) {
            if (resource._id.toString() === resourceId.toString()) {
                return true;
            }
        }
        return false;
    }

    removeSelectedItemsWhichAreNotPresentInRowsInUI(selectedIds: string[], resourcesShowingInUI: {_id: string}[]): string[] {
        const filteredSelectedIds: typeof selectedIds = [];

        for (const selectedId of selectedIds) {
            if (this.doesAnyResourceContainThisId(resourcesShowingInUI, selectedId)) {
                filteredSelectedIds.push(selectedId);
            }
        }

        return filteredSelectedIds;
    }    

    isSelected = (name: string) => this.props.selectedItems.indexOf(name) !== -1;

    showName(data: IData) {
        let resouceString = data.filename;
        if (resouceString?.length > 15) {
            resouceString = "..." + resouceString.substring(resouceString.length - 13, resouceString.length);
        }
        //  let resouceString =data.resource.substring(start,end).split('').reverse().join('').substring(0,20)+'..........';
        // resouceString = resouceString.split('').reverse().join('')

        return resouceString;
    }
    showNameInGridView(data: IData) {
        let resouceString = data.filename;
        if (resouceString.length > 20) {
            resouceString = " ..." + resouceString.substring(resouceString.length - 18, resouceString.length);
        }
        //  let resouceString =data.resource.substring(start,end).split('').reverse().join('').substring(0,20)+'..........';
        // resouceString = resouceString.split('').reverse().join('')

        return resouceString;
    }


    handleChangePage(event: unknown, newPage: number, index: number = 0) {
        const { isFetching } = this.props;
        if (!isFetching) {
            this.pushHistory({ offset: `${newPage + 1}`, index: `${index}`, sortOrder: this.state.sort.order, sortField: this.state.sort.field, nameRegex: this.state.filter.resourceName.searchValue});
        }
    }

    pushHistory({sortField = this.state.sort.field, sortOrder = this.state.sort.order, limit = `${this.state.rowsPerPage}`, offset = `${this.state.page + 1}`, index = '0' }: IDataQueryParams) {
        const { location, history } = this.props;
        const { status } = this.state;
        const query = new URLSearchParams(location?.search);
        query.set(ResourceLimitQueryParam, limit);
        query.set(ResourceOffsetQueryParam, offset);
        query.set('index', index);
        query.set(ResourceStatusQueryParam, status);
        query.set(ResourceSortFieldQueryParam, sortField);
        query.set(ResourceSortOrderQueryParam, sortOrder);
        query.set(DataTableResourceNameSearchValueQueryParam, this.state.filter.resourceName.searchValue);
        query.set(DataTablePromptSearchOperatorQueryParam, this.state.filter.resourcePrompt.searchValue);
        history.push(`${location?.pathname}?${query.toString()}`);
    }

    handleChangeRowsPerPage(event: React.ChangeEvent<HTMLInputElement>) {
        console.log(event.target.value, '310::')
        const { location, history, isFetching } = this.props;
        // const val= event.target.value;
        this.setState({ rowsPerPage: parseInt(event.target.value, 10) })
        if (!isFetching) {
            const { status } = this.state;
            const query = new URLSearchParams(location?.search);
            query.set(ResourceStatusQueryParam, status);
            query.set(ResourceLimitQueryParam, event.target.value);
            history.push(`${location?.pathname}?${query.toString()}`);
        }
    }

    getSelectedSubTab(): ResourceStatus {
        const {history} = this.props;
        const query = new URLSearchParams(history.location.search);
        const status = query?.get(ResourceStatusQueryParam) || 'backlog';
        return status as ResourceStatus;
    }

    handleResourceConfidenceScoreApplyFilterButtonClick() {
        const { location, history } = this.props;

        this.setState(oldState=>{
            return {
                ...oldState,
                filter: {
                    ...oldState.filter,
                    resourceConfidenceScore: {
                        ...oldState.filter.resourceConfidenceScore,
                        menuOpen: false
                    }
                }
            }
        }, ()=>{
            const urlQuery = new URLSearchParams(location?.search);
            if (
                Number(urlQuery.get(DataTableResourceConfidenceScoreMinValueQueryParam)) !== this.state.filter.resourceConfidenceScore.minValue ||
                Number(urlQuery.get(DataTableResourceConfidenceScoreMaxValueQueryParam)) !== this.state.filter.resourceConfidenceScore.maxValue
            ) {
                urlQuery.set(ForceFetchDataFromDBQueryParam, 'true');
            }
            urlQuery.set(DataTableResourceConfidenceScoreMinValueQueryParam, String(this.state.filter.resourceConfidenceScore.minValue));
            urlQuery.set(DataTableResourceConfidenceScoreMaxValueQueryParam, String(this.state.filter.resourceConfidenceScore.maxValue));
            history.push(`${location?.pathname}?${urlQuery.toString()}`);
        });

    }

    handleResourceCreatedDateApplyFilterButtonClick() {
        const { location, history } = this.props;

        this.setState(oldState=>{
            return {
                ...oldState,
                filter: {
                    ...oldState.filter,
                    resourceStatusLastModifiedDate: {
                        ...oldState.filter.resourceStatusLastModifiedDate,
                        menuOpen: false
                    }
                }
            }
        }, ()=>{
            const urlQuery = new URLSearchParams(location?.search);
            if (
                urlQuery.get(DataTableResourceStatusLastModifiedDateMinValueQueryParam) !== this.state.filter.resourceStatusLastModifiedDate.minValue ||
                urlQuery.get(DataTableResourceStatusLastModifiedDateMaxValueQueryParam) !== this.state.filter.resourceStatusLastModifiedDate.maxValue
            ) {
                urlQuery.set(ForceFetchDataFromDBQueryParam, 'true');
            }
            urlQuery.set(DataTableResourceStatusLastModifiedDateMinValueQueryParam, this.state.filter.resourceStatusLastModifiedDate.minValue);
            urlQuery.set(DataTableResourceStatusLastModifiedDateMaxValueQueryParam, this.state.filter.resourceStatusLastModifiedDate.maxValue);
            history.push(`${location?.pathname}?${urlQuery.toString()}`);
        });

    }

    handleForecastDateApplyFilterButtonClick() {
        const { location, history } = this.props;

        this.setState(oldState=>{
            return {
                ...oldState,
                filter: {
                    ...oldState.filter,
                    forecastDate: {
                        ...oldState.filter.forecastDate,
                        menuOpen: false
                    }
                }
            }
        }, ()=>{
            const urlQuery = new URLSearchParams(location?.search);
            if (
                urlQuery.get(ForecastDateMinValueQueryParam) !== this.state.filter.forecastDate.minValue ||
                urlQuery.get(ForecastDateMaxValueQueryParam) !== this.state.filter.forecastDate.maxValue
            ) {
                urlQuery.set(ForceFetchDataFromDBQueryParam, 'true');
            }
            urlQuery.set(ForecastDateMinValueQueryParam, this.state.filter.forecastDate.minValue);
            urlQuery.set(ForecastDateMaxValueQueryParam, this.state.filter.forecastDate.maxValue);
            history.push(`${location?.pathname}?${urlQuery.toString()}`);
        });

    }

    handleTagApplyFilterButtonClick() {
        const { location, history } = this.props;

        this.setState(oldState=>{
            return {
                ...oldState,
                filter: {
                    ...oldState.filter,
                    resourceTag: {
                        ...oldState.filter.resourceTag,
                        menuOpen: false
                    }
                }
            }
        }, ()=>{
            const urlQuery = new URLSearchParams(location?.search);
            if (
                urlQuery.get(DataTableResourceTagSearchValueQueryParam) !== this.state.filter.resourceTag.searchValue ||
                urlQuery.get(DataTableResourceTagSearchOperatorQueryParam) !== this.state.filter.resourceTag.searchOperator
            ) {
                urlQuery.set(ForceFetchDataFromDBQueryParam, 'true');
            }
            urlQuery.set(DataTableResourceTagSearchValueQueryParam, this.state.filter.resourceTag.searchValue);
            urlQuery.set(DataTableResourceTagSearchOperatorQueryParam, this.state.filter.resourceTag.searchOperator);
            history.push(`${location?.pathname}?${urlQuery.toString()}`);
        });
    }

    handleNameApplyFilterButtonClick() {
        const { location, history } = this.props;

        this.setState(oldState=>{
            return {
                ...oldState,
                filter: {
                    ...oldState.filter,
                    resourceName: {
                        ...oldState.filter.resourceName,
                        menuOpen: false
                    }
                }
            }
        }, ()=>{
            const urlQuery = new URLSearchParams(location?.search);
            if (
                urlQuery.get(DataTableResourceNameSearchValueQueryParam) !== this.state.filter.resourceName.searchValue ||
                urlQuery.get(DataTableResourceNameSearchOperatorQueryParam) !== this.state.filter.resourceName.searchOperator 
            ) {
                urlQuery.set(ForceFetchDataFromDBQueryParam, 'true');
            }
            urlQuery.set(DataTableResourceNameSearchValueQueryParam, this.state.filter.resourceName.searchValue);
            urlQuery.set(DataTableResourceNameSearchOperatorQueryParam, this.state.filter.resourceName.searchOperator);
           
            // add includeOnlyMispredictedParagraphs & excludeMispredictedParagraphs property in urlQuery
            if(this.state.currentModelType==="bookTranslation"){
                if(urlQuery.get(IncludeOnlyMispredictedRowsQueryParam)!== this.state.filter.resourceName.excludeMispredictedParagraphs ||
                  urlQuery.get(ExcludeMispredictedParagraphsQueryParam)!== this.state.filter.resourceName.includeOnlyMispredictedParagraphs){
                      urlQuery.set(ForceFetchDataFromDBQueryParam, 'true');
                  }
                urlQuery.set(IncludeOnlyMispredictedParagraphsParam, this.state.filter.resourceName.includeOnlyMispredictedParagraphs);
                urlQuery.set(ExcludeMispredictedParagraphsQueryParam, this.state.filter.resourceName.excludeMispredictedParagraphs);
            }

            history.push(`${location?.pathname}?${urlQuery.toString()}`);
        });
    }

    handlePromptApplyFilterButtonClick() {
        const { location, history } = this.props;

        console.log("prompt here")

        this.setState(oldState=>{
            return {
                ...oldState,
                filter: {
                    ...oldState.filter,
                    resourcePrompt: {
                        ...oldState.filter.resourcePrompt,
                        menuOpen: false
                    }
                }
            }
        }, ()=>{
            const urlQuery = new URLSearchParams(location?.search);
            console.log("prompt", this.state.filter.resourcePrompt)
            if (
                urlQuery.get(DataTablePromptSearchValueQueryParam) !== this.state.filter.resourcePrompt.searchValue ||
                urlQuery.get(DataTablePromptSearchOperatorQueryParam) !== this.state.filter.resourcePrompt.searchOperator 
            ) {
                urlQuery.set(ForceFetchDataFromDBQueryParam, 'true');
            }
            urlQuery.set(DataTablePromptSearchValueQueryParam, this.state.filter.resourcePrompt.searchValue);
            urlQuery.set(DataTablePromptSearchOperatorQueryParam, this.state.filter.resourcePrompt.searchOperator);
           
            // add includeOnlyMispredictedParagraphs & excludeMispredictedParagraphs property in urlQuery

            history.push(`${location?.pathname}?${urlQuery.toString()}`);
        });
    }

    areAllRowsMatchingTableFilterSelectedByUserAndHasRowsNotShowingInUI(): boolean {
        return (this.areAllRowsWhichIsShowingInUISelectedByUser(this.props.data.resources, this.props.selectedItems) &&
        this.state.rowsPerPage < this.props.data.totalCount) &&
        this.state.allSelected
    }

    handleLabelApplyFilterButtonClick() {
        const { location, history } = this.props;

        this.setState(oldState=>{
            return {
                ...oldState,
                filter: {
                    ...oldState.filter,
                    resourceLabel: {
                        ...oldState.filter.resourceLabel,
                        menuOpen: false
                    }
                }
            }
        }, ()=>{
            const urlQuery = new URLSearchParams(window.location?.search);
            // const searchValue = this.state.filter.resourceLabel.searchValue.map(value=>value)
            // if(this.state.filter.resourceLabel.searchValue.length > 0){
                let resourceLabelSearchValue;
                let resourcePredictionSearchValue;
                if(urlQuery.get(DataTableResourceLabelSearchValueQueryParam)?.split(",").length === 1 &&  urlQuery.get(DataTableResourceLabelSearchValueQueryParam)?.split(",")[0] === ''){
                    resourceLabelSearchValue = []
                }
                else{
                    resourceLabelSearchValue = urlQuery.get(DataTableResourceLabelSearchValueQueryParam)?.split(",")
                }
                if(urlQuery.get(DataTableResourcePredictionSearchValueQueryParam)?.split(",").length === 1 &&  urlQuery.get(DataTableResourcePredictionSearchValueQueryParam)?.split(",")[0] === ''){
                    resourcePredictionSearchValue = []
                }
                else{
                    resourcePredictionSearchValue = urlQuery.get(DataTableResourcePredictionSearchValueQueryParam)?.split(",")
                }
                console.log(" Label Value ::  ",resourceLabelSearchValue, urlQuery.get(DataTableResourceLabelSearchValueQueryParam)?.split(","), (this.state.filter.resourceLabel.searchValue))
                console.log(" Prediction Value ::  ", resourcePredictionSearchValue, urlQuery.get(DataTableResourcePredictionSearchValueQueryParam)?.split(",") ,  (this.state.filter.resourceLabel.predictionSearchValue))
                console.log(" Label Operator :: ",urlQuery.get(DataTableResourceLabelSearchOperatorQueryParam), (this.state.filter.resourceLabel.searchOperator))
                console.log(" Prediction Operator :: ", urlQuery.get(DataTableResourcePredictionSearchOperatorQueryParam) ,  (this.state.filter.resourceLabel.predictionSearchOperator))
                console.log(" include misprediction ::  ",urlQuery.get(IncludeOnlyMispredictedRowsQueryParam), (this.state.filter.resourceLabel.includeOnlyMispredictedRows))
                // let resourceLabelSearchValue;
                // let resourcePredictionSearchValue;
                // if(urlQuery.get(DataTableResourceLabelSearchValueQueryParam)?.split(",").length === 1 &&  urlQuery.get(DataTableResourceLabelSearchValueQueryParam)?.split(",")[0] === ''){
                //     resourceLabelSearchValue = []
                // }
                // else{
                //     resourceLabelSearchValue = urlQuery.get(DataTableResourceLabelSearchValueQueryParam)?.split(",")
                // }
                // if(urlQuery.get(DataTableResourcePredictionSearchValueQueryParam)?.split(",").length === 1 &&  urlQuery.get(DataTableResourcePredictionSearchValueQueryParam)?.split(",")[0] === ''){
                //     resourcePredictionSearchValue = []
                // }
                // else{
                //     resourcePredictionSearchValue = urlQuery.get(DataTableResourcePredictionSearchValueQueryParam)?.split(",")
                // }
            if (
                resourceLabelSearchValue !==  (this.state.filter.resourceLabel.searchValue) ||
                urlQuery.get(DataTableResourceLabelSearchOperatorQueryParam) !== this.state.filter.resourceLabel.searchOperator ||
                urlQuery.get(IncludeOnlyMispredictedRowsQueryParam) !== this.state.filter.resourceLabel.includeOnlyMispredictedRows ||
                resourcePredictionSearchValue !==  (this.state.filter.resourceLabel.predictionSearchValue) ||
                urlQuery.get(DataTableResourcePredictionSearchOperatorQueryParam) !== this.state.filter.resourceLabel.predictionSearchOperator
            ) {
                urlQuery.set(ForceFetchDataFromDBQueryParam, 'true');
            }
            // if (urlQuery.get(DataTableResourceLabelSearchValueQueryParam) || this.state.filter.resourceLabel.searchValue.toString() !== "") {
            urlQuery.set(DataTableResourceLabelSearchValueQueryParam, this.state.filter.resourceLabel.searchValue.join(","));
            urlQuery.set(DataTableResourceLabelSearchOperatorQueryParam, this.state.filter.resourceLabel.searchOperator);
            urlQuery.set(IncludeOnlyMispredictedRowsQueryParam, this.state.filter.resourceLabel.includeOnlyMispredictedRows);
            urlQuery.set(DataTableResourcePredictionSearchValueQueryParam, this.state.filter.resourceLabel.predictionSearchValue.join(","));
            urlQuery.set(DataTableResourcePredictionSearchOperatorQueryParam, this.state.filter.resourceLabel.predictionSearchOperator);
            history.push(`${location?.pathname}?${urlQuery.toString()}`);
        });
    }

    handleResourceTagColumnMenuClose() {
        this.setState(oldState=>{
            return {
                ...oldState,
                filter: {
                    ...oldState.filter,
                    resourceTag: {
                        ...oldState.filter.resourceTag,
                        menuOpen: false
                    }
                }
            }
        })
    }

    handleResourceNameColumnMenuClose() {
        this.setState(oldState=>{
            return {
                ...oldState,
                filter: {
                    ...oldState.filter,
                    resourceName: {
                        ...oldState.filter.resourceName,
                        menuOpen: false
                    }
                }
            }
        })
    }

    handleResourcePromptColumnMenuClose() {
        this.setState(oldState=>{
            return {
                ...oldState,
                filter: {
                    ...oldState.filter,
                    resourcePrompt: {
                        ...oldState.filter.resourcePrompt,
                        menuOpen: false
                    }
                }
            }
        })
    }

    handleResourceConfidenceScoreColumnMenuClose() {
        this.setState(oldState=>{
            return {
                ...oldState,
                filter: {
                    ...oldState.filter,
                    resourceConfidenceScore: {
                        ...oldState.filter.resourceConfidenceScore,
                        menuOpen: false
                    }
                }
            }
        })
    }

    handleResourceCreatedDateColumnMenuClose() {
        this.setState(oldState=>{
            return {
                ...oldState,
                filter: {
                    ...oldState.filter,
                    resourceStatusLastModifiedDate: {
                        ...oldState.filter.resourceStatusLastModifiedDate,
                        menuOpen: false
                    }
                }
            }
        })
    }

    handleForecastDateColumnMenuClose() {
        this.setState(oldState=>{
            return {
                ...oldState,
                filter: {
                    ...oldState.filter,
                    forecastDate: {
                        ...oldState.filter.forecastDate,
                        menuOpen: false
                    }
                }
            }
        })
    }

    handleResourceLabelColumnMenuClose() {
        this.setState(oldState=>{
            return {
                ...oldState,
                filter: {
                    ...oldState.filter,
                    resourceLabel: {
                        ...oldState.filter.resourceLabel,
                        menuOpen: false
                    }
                }
            }
        })
    }

    handleSortLabelClick(labelFieldName: DataSortField) {
        if(this.props.scenario === 'aiMarketPlaceDataCart') return;
        const { location, history } = this.props;
        const urlQuery = new URLSearchParams(location?.search);
        let previousSortOrder: SortOrder = this.state.sort.order;
        let sortOrderToSet: SortOrder = 'ascending';
        if (this.state.sort.field !== labelFieldName) {
            sortOrderToSet = 'ascending';
        } else {
            if (previousSortOrder === 'ascending') {
                sortOrderToSet = 'descending';
            } else {
                sortOrderToSet = 'ascending';
            }
        }
        urlQuery.set(ResourceSortOrderQueryParam, sortOrderToSet);
        urlQuery.set(ResourceSortFieldQueryParam, labelFieldName);
        history.push(`${location?.pathname}?${urlQuery.toString()}`);
    }

    async setTagFilterSearchValue(value: string): Promise<void> {
        return new Promise<void>((resolve, reject) => {
            this.setState(oldState=>{
                return {
                    filter: {
                        ...oldState.filter,
                        resourceTag: {
                            ...oldState.filter.resourceTag,
                            searchValue: value
                        }
                    },
                }
            }, ()=>resolve())
        })
    }

    async setTagFilterSearchOperator(value: ResourceTagSearchOperator): Promise<void> {
        return new Promise<void>((resolve, reject) => {
            this.setState(oldState=>{
                return {
                    filter: {
                        ...oldState.filter,
                        resourceTag: {
                            ...oldState.filter.resourceTag,
                            searchOperator: value
                        }
                    },
                }
            }, ()=>resolve())
        })
    }

    async setConfidenceScoreFilterMinMaxValue(value: [number, number]): Promise<void> {
        return new Promise<void>((resolve, reject) => {
            this.setState(oldState=>{
                return {
                    filter: {
                        ...oldState.filter,
                        resourceConfidenceScore: {
                            ...oldState.filter.resourceConfidenceScore,
                            minValue: value[0],
                            maxValue: value[1]
                        }
                    },
                }
            }, ()=>resolve())
        })
    }

    async setCreatedDateFilterMinMaxValue(value: [string, string]): Promise<void> {
        return new Promise<void>((resolve, reject) => {
            this.setState(oldState=>{
                return {
                    filter: {
                        ...oldState.filter,
                        resourceStatusLastModifiedDate: {
                            ...oldState.filter.resourceStatusLastModifiedDate,
                            minValue: value[0],
                            maxValue: value[1]
                        }
                    },
                }
            }, ()=>resolve())
        })
    }

    async setForecastDateFilterMinMaxValue(value: [string, string]): Promise<void> {
        return new Promise<void>((resolve, reject) => {
            this.setState(oldState=>{
                return {
                    filter: {
                        ...oldState.filter,
                        forecastDate: {
                            ...oldState.filter.forecastDate,
                            minValue: value[0],
                            maxValue: value[1]
                        }
                    },
                }
            }, ()=>resolve())
        })
    }


    async setNameFilterSearchOperator(value: ResourceNameSearchOperator): Promise<void> {
        return new Promise<void>((resolve, reject) => {
            this.setState(oldState=>{
                return {
                    filter: {
                        ...oldState.filter,
                        resourceName: {
                            ...oldState.filter.resourceName,
                            searchOperator: value
                        }
                    },
                }
            }, ()=>resolve())
        })
    }

    async setPromptFilterSearchOperator(value: PromptSearchOperator): Promise<void> {
        return new Promise<void>((resolve, reject) => {
            this.setState(oldState=>{
                return {
                    filter: {
                        ...oldState.filter,
                        resourcePrompt: {
                            ...oldState.filter.resourcePrompt,
                            searchOperator: value
                        }
                    },
                }
            }, ()=>resolve())
        })
    }



    async setNameFilterSearchValue(value: string): Promise<void> {
        return new Promise<void>((resolve, reject) => {
            this.setState(oldState=>{
                return {
                    filter: {
                        ...oldState.filter,
                        resourceName: {
                            ...oldState.filter.resourceName,
                            searchValue: value
                        }
                    },
                }
            }, ()=>resolve())
        })
    }

    async setPromptFilterSearchValue(value: string): Promise<void> {
        return new Promise<void>((resolve, reject) => {
            this.setState(oldState=>{
                return {
                    filter: {
                        ...oldState.filter,
                        resourcePrompt: {
                            ...oldState.filter.resourcePrompt,
                            searchValue: value
                        }
                    },
                }
            }, ()=>resolve())
        })
    }

   async  setNameFilterIncludeOnlyMispredictedParagraphsValue (value: IncludeOnlyMispredictedParagraphsParamType): Promise<void> {
        return new Promise<void>((resolve, reject) => {
            this.setState(oldState=>{
                return {
                    filter: {
                        ...oldState.filter,
                        resourceName: {
                            ...oldState.filter.resourceName,
                            includeOnlyMispredictedParagraphs: value,
                        }
                    },
                }
            }, ()=>resolve())
        })
   } 

   
   async  setNameFilterExcludeMispredictedParagraphsValue (value: ExcludeMispredictedParagraphsParamType): Promise<void> {
    return new Promise<void>((resolve, reject) => {
        this.setState(oldState=>{
            return {
                filter: {
                    ...oldState.filter,
                    resourceName: {
                        ...oldState.filter.resourceName,
                        excludeMispredictedParagraphs: value,
                    }
                },
            }
        }, ()=>resolve())
    })
} 

    async setLabelFilterSearchValue(value: string[]): Promise<void> {
        // if(value.length > 0){
            console.log(" value is  ::: ", value)
        return new Promise<void>((resolve, reject) => {
            this.setState(oldState=>{
                return {
                    filter: {
                        ...oldState.filter,
                        resourceLabel: {
                            ...oldState.filter.resourceLabel,
                            searchValue: value
                        }
                    },
                }
            }, ()=>resolve())
        })
    }

    async setLabelFilterPredictionSearchValue(value: string[]): Promise<void> {
        // if(value.length > 0){
            console.log(" value is  ::: ", value)
        return new Promise<void>((resolve, reject) => {
            this.setState(oldState=>{
                return {
                    filter: {
                        ...oldState.filter,
                        resourceLabel: {
                            ...oldState.filter.resourceLabel,
                            predictionSearchValue: value
                        }
                    },
                }
            }, ()=>resolve())
        })
    }

    async setLabelFilterIncludeOnlyMispredictedRowsValue(value: IncludeOnlyMispredictedRowsQueryParamType): Promise<void> {
        return new Promise<void>((resolve, reject) => {
            this.setState(oldState=>{
                return {
                    filter: {
                        ...oldState.filter,
                        resourceLabel: {
                            ...oldState.filter.resourceLabel,
                            includeOnlyMispredictedRows: value
                        }
                    },
                }
            }, ()=>resolve())
        })
    }

    async setLabelFilterSearchOperator(value: ResourceLabelSearchOperator): Promise<void> {
        return new Promise<void>((resolve, reject) => {
            this.setState(oldState=>{
                return {
                    filter: {
                        ...oldState.filter,
                        resourceLabel: {
                            ...oldState.filter.resourceLabel,
                            searchOperator: value
                        }
                    },
                }
            }, ()=>resolve())
        })
    }

    async setLabelFilterPredictionSearchOperator(value: ResourcePredictionSearchOperator): Promise<void> {
        return new Promise<void>((resolve, reject) => {
            this.setState(oldState=>{
                return {
                    filter: {
                        ...oldState.filter,
                        resourceLabel: {
                            ...oldState.filter.resourceLabel,
                            predictionSearchOperator: value
                        }
                    },
                }
            }, ()=>resolve())
        })
    }

    areAllRowsWhichIsShowingInUISelectedByUser(resources: {_id: string}[], selectedIds: string[]): boolean {
        for (const resource of resources) {
            if (selectedIds.indexOf(resource._id.toString()) === -1) {
                return false;
            }
        }
        return true;
    }

    async fetchAndSetUniqueResourceTagsForStatus(value?: string) {
        const query = new URLSearchParams(window.location.search);
        if(query.get("purposeModelId") || query.get(AiAssistedLabellingDetailedViewQueryParams.aiAssistantLabellingId)) return;

        const apiResponse = await DataService.getTags(this.state.modelId, this.state.status, value, 400);
        this.setState({uniqueResourceTagsAvailable: [...apiResponse.data.tags]})
    }

    iterateOverResourcesOnArrowClick(direction: any){
        const { data, isFetching, selected, setSelectedData, setSideDrawer } = this.props;
        const query = new URLSearchParams(this.props.history.location.search);

        const nameSearchValue: DataTableResourceNameSearchValue = query?.get(DataTableResourceNameSearchValueQueryParam) as DataTableResourceNameSearchValue;
        const nameSearchOperator: ResourceNameSearchOperator = query?.get(DataTableResourceNameSearchOperatorQueryParam) as ResourceNameSearchOperator || DataTablePromptSearchOperatorDefaultValue;
        const nameRegex = convertNameSearchOperatorToRegex(nameSearchOperator, nameSearchValue);

        const promptSearchValue: DataTablePromptSearchValue = query?.get(DataTablePromptSearchValueQueryParam) as DataTablePromptSearchValue;
        const promptSearchOperator: PromptSearchOperator = query?.get(DataTablePromptSearchValueQueryParam) as PromptSearchOperator || DataTableResourceNameSearchOperatorDefaultValue;
        const promptRegex = convertNameSearchOperatorToRegex(promptSearchOperator, promptSearchValue);

        const tagSearchValue: DataTableResourceTagSearchValue = query?.get(DataTableResourceTagSearchValueQueryParam) as DataTableResourceTagSearchValue;
        const tagSearchOperator: ResourceTagSearchOperator = query?.get(DataTableResourceTagSearchOperatorQueryParam) as ResourceTagSearchOperator || DataTableResourceTagSearchOperatorDefaultValue;
        const tagRegex = convertTagSearchOperatorToRegex(tagSearchOperator, tagSearchValue);
        if (!isFetching && selected) {
            //const { status } = this.state;
            const { page, rowsPerPage } = this.state;
            let selectedIndex = data.resources.findIndex(item => item._id === selected);
            if (direction===-1) {
                if (selectedIndex > 0) {
                    selectedIndex--;
                    this.pushHistory({ index: `${selectedIndex}`, sortField: this.state.sort.field, sortOrder: this.state.sort.order, nameRegex, tagRegex});
                    setSelectedData(data.resources[selectedIndex]?._id);
                } else if (page > 0) {
                    this.handleChangePage(null, page - 1, rowsPerPage - 1);
                }
            } else if (direction===1) {
                if (selectedIndex < data.resources.length - 1) {
                    selectedIndex++;
                    this.pushHistory({ index: `${selectedIndex}`, sortField: this.state.sort.field, sortOrder: this.state.sort.order, nameRegex, tagRegex});
                    setSideDrawer({ type: 'dataSelected', component: DataView, isOpen: this.props.isSideDrawerOpen ? true : false});
                    setSelectedData(data.resources[selectedIndex]?._id);
                } else if ((rowsPerPage * (page + 1)) <= data.totalCount) {
                    this.handleChangePage(null, page + 1, 0);
                }
            }
        }
    }


    getTotalResourcesGoingToBeAffectCount(): number {
        const { status } = this.state;
        const resourceTotalCount = this.props.data.totalCount;
        if (status === 'deleted' && resourceTotalCount < 10000) {
            return resourceTotalCount;
        } else if (status === 'deleted') {
            return 10000;
        } else if (resourceTotalCount < 50000) {
            return resourceTotalCount;
        }
        return 50000;
    }

    tableResourcesFilterCallback = (resource: IData): boolean => {
        if (!this.props.dataSetCollectionIdSelectedInDataSetCollectionDetailsPopup) {
            return true;
        }

        if (
            !resource?.dataSetCollections ||
            resource?.dataSetCollections?.length === 0
        ) {
            return true;
        }

        if (this.props.dataSetCollectionIdSelectedInDataSetCollectionDetailsPopup) {
            if (resource?.dataSetCollections?.length>0) {
                const isDataSetCollectionPresentInResource: boolean = (()=>{
                    const dataSetCollection =  resource.dataSetCollections.find(dataSetCollection=>{
                        if (dataSetCollection._id.toString() === this.props.dataSetCollectionIdSelectedInDataSetCollectionDetailsPopup) {
                            return true;
                        }
                        return false;
                    });

                    if (dataSetCollection) {
                        return true;
                    } else {
                        return false
                    }
                })();

                if (isDataSetCollectionPresentInResource) {
                    return true
                } else {
                    return false
                }
            }
        }

        return true;
    }

    setMultipleImageAnnotationResourceImageIndex = (indexToSet: number) => {
        this.setState({
            multipleImageAnnotationResourceImageIndex: indexToSet
        })
    }    

    handleImageAnnotationResourceDetailButtonClick(index: number) {
        this.setState({
            index: index,
            imageKeyPointsDialog: {
                isOpen: true
            }
        })        
    }

    videoAnnotationButtonClickHandler(index: number) {
        this.setState({
            index: index,
            isVideoAnnotationDialogBoxOpen: true
        })        
    }


     checkIsParagraphMispredited(resource:IData){
        let isInclude=false;
        resource?.textTranslatedLanguageSentences?.forEach((sentences)=>{
            if(sentences?.connectedWithOriginalLanguageTextSentenceIdsPredicted.length > 0 || sentences?.predictedSentence?.text){
                isInclude= true;
            }
        })
        return isInclude
    }

    render() {
        const { rowsPerPage, page, currentModelType } = this.state;
        const {isPageOpenFromHomePageChatModelSummaryPage=false} = this.props
        const selectedItems = this.props.selectedItems;
        const { data, classes, selected, drawerType, isFetching, approvedWaitingList, deletedWaitingList, approvalWaitingList, rejectedWaitingList, isApiRequestCancelled } = this.props;
        const {itemsAddedToCart, toggleItemInCart} = this.context;
        // console.log("", data)
        // const data : IDataResponse = {
        //     totalCount: 100, 
        //     resources: [
        //         {
        //             _id: "234698462344023",
        //             expertFeedbackCount: 0,
        //             additionalResources: [],
        //             textOriginalLanguage: "english",
        //             textTranslatedLanguage: "dutch",
        //             authorName: "Chetan Bhagat",
        //             paragraph: {
        //                 sequenceNumber: 1
        //             },
        //             chapter: {
        //                 sequenceNumber: 1
        //             },

        //             textOriginalLanguageSentences: [
        //                 {
        //                     sentenceId: "423854370990",
        //                     sentence: {
        //                         text: "A MIST WRAITH\nThe autumn afternoon was fading into evening."
        //                     },
        //                     sequenceNumber: 1
        //                 }
        //             ],

        //             textTranslatedLanguageSentences: [
        //                 {
        //                     sentenceId: "31242342354554",
        //                     predictedSentence: {
        //                         text: "Op ruim een mijl afstands van de plaats, waar Beatrice stond en visioenen\nzag, en verder langs de kustlijn, steekt een tweede groep rotsen, om haar\nkleur bekend als de Roode Rotsen, of soms, om een andere reden, als de\nBelrotsen, ongeveer drie kwart mijl ver in de zee vooruit."
        //                     },
        //                     sentence: {
        //                         text: "Op ruim een mijl afstands van de plaats, waar Beatrice stond en visioenen\nzag, en verder langs de kustlijn, steekt een tweede groep rotsen, om haar\nkleur bekend als de Roode Rotsen, of soms, om een andere reden, als de\nBelrotsen, ongeveer drie kwart mijl ver in de zee vooruit."
        //                     },
        //                     connectedWithOriginalLanguageTextSentenceIdsPredicted: [
        //                         {
        //                             sentenceId: "283224342389"
        //                         }
        //                     ],
        //                     connectedWithOriginalLanguageTextSentenceIds: [
        //                         {
        //                             sentenceId: "283224342389"
        //                         }
        //                     ],
        //                     sequenceNumber: 1,
        //                     isApprovedByUser: false,
        //                     confidenceScore: 100
        //                 }
        //             ],
        //             tag: "oppenhiemer",
        //             csv: "csv data",
        //             created_at: "2022-04-06T09:58:39.278Z",
        //             updated_at: "2022-04-06T09:58:39.278Z",
        //             draw: "",
        //             resource: "",
        //             label: "",
        //             model: {
        //                 headers: "",
        //                 name: "Book Translation",
        //                 _id: "64d0b835e2cbd148b84bd1b4",
        //                 project: "64d0b31d29064f5c88a17a31",
        //                 type: "bookTranslation"
        //             },
        //             status: "backlog",
        //             dataBoost: "",
        //             prediction: "",
        //             confidence_score: "100",
        //             filename: "",
        //             dataSetCollections: [],
        //             statusLastModifiedAt: "2022-04-06T09:58:39.278Z",
        //             changesMade: [],
        //             ansOfPrompt: "",
        //             prompt: "",
        //         },
        //         {
        //             _id: "2346982552344023",
        //             expertFeedbackCount: 0,
        //             additionalResources: [],
        //             textOriginalLanguage: "english",
        //             textTranslatedLanguage: "dutch",
        //             authorName: "Chetan Bhagat",
        //             paragraph: {
        //                 sequenceNumber: 2
        //             },
        //             chapter: {
        //                 sequenceNumber: 1
        //             },

        //             textOriginalLanguageSentences: [
        //                 {
        //                     sentenceId: "423854370990",
        //                     sentence: {
        //                         text: "A MIST WRAITH\nThe autumn afternoon was fading into evening."
        //                     },
        //                     sequenceNumber: 1
        //                 }
        //             ],

        //             textTranslatedLanguageSentences: [
        //                 {
        //                     sentenceId: "31242342354554",
        //                     predictedSentence: {
        //                         text: "Op ruim een mijl afstands van de plaats, waar Beatrice stond en visioenen\nzag, en verder langs de kustlijn, steekt een tweede groep rotsen, om haar\nkleur bekend als de Roode Rotsen, of soms, om een andere reden, als de\nBelrotsen, ongeveer drie kwart mijl ver in de zee vooruit."
        //                     },
        //                     sentence: {
        //                         text: "Op ruim een mijl afstands van de plaats, waar Beatrice stond en visioenen\nzag, en verder langs de kustlijn, steekt een tweede groep rotsen, om haar\nkleur bekend als de Roode Rotsen, of soms, om een andere reden, als de\nBelrotsen, ongeveer drie kwart mijl ver in de zee vooruit."
        //                     },
        //                     connectedWithOriginalLanguageTextSentenceIdsPredicted: [
        //                         {
        //                             sentenceId: "283224342389"
        //                         }
        //                     ],
        //                     connectedWithOriginalLanguageTextSentenceIds: [
        //                         {
        //                             sentenceId: "283224342389"
        //                         }
        //                     ],
        //                     sequenceNumber: 1,
        //                     isApprovedByUser: false,
        //                     confidenceScore: 100
        //                 }
        //             ],
        //             tag: "oppenhiemer",
        //             csv: "csv data",
        //             created_at: "2022-04-06T09:58:39.278Z",
        //             updated_at: "2022-04-06T09:58:39.278Z",
        //             draw: "",
        //             resource: "",
        //             label: "",
        //             model: {
        //                 headers: "",
        //                 name: "Book Translation",
        //                 _id: "64d0b835e2cbd148b84bd1b4",
        //                 project: "64d0b31d29064f5c88a17a31",
        //                 type: "bookTranslation"
        //             },
        //             status: "backlog",
        //             dataBoost: "",
        //             prediction: "",
        //             confidence_score: "100",
        //             filename: "",
        //             dataSetCollections: [],
        //             statusLastModifiedAt: "2022-04-06T09:58:39.278Z",
        //             changesMade: [],
        //             ansOfPrompt: "",
        //             prompt: "",
        //         }
        //     ],
        // }
        let selectedIndex = data.resources.findIndex(item => item._id === selected);
        const { status } = this.state;

        const deleteResourcesFromAiMarketplaceDataCart = async (resourceIds: string[]) => {
            this.setState({
                ...this.state, resourceIdsWhichAreBeingDeleted: [...resourceIds]
            })
            await this.context.removeResourceFromAiMarketplaceDataCart(resourceIds)
            this.setState({
                ...this.state, resourceIdsWhichAreBeingDeleted: []
            })
        }

        const uniqueDataSetCollectionsOfSelectedResources = (()=>{
            const uniqueDataSetCollectionsOfSelectedResources: ResourceDataSetCollection[] = [];
            const uniqueDataSetCollectionIdsOfSelectedResources: string[] = [];

            for (const selectedResourceId of (selectedItems || [])) {
                for (const resource of (data?.resources || [])) {
                    if (resource._id === selectedResourceId) {
                        for (const dataSetCollection of (resource?.dataSetCollections || [])) {
                            if (!uniqueDataSetCollectionIdsOfSelectedResources.includes(dataSetCollection._id)) {
                                uniqueDataSetCollectionIdsOfSelectedResources.push(dataSetCollection._id);
                                uniqueDataSetCollectionsOfSelectedResources.push(dataSetCollection)
                            }
                        }
                    }
                }
            }

            return uniqueDataSetCollectionsOfSelectedResources;
        })();
        
        const BookTranslationModelThumbsUpButton = (props: { id: string }) => {
            const [thumbsUpIconAnchorEl, setThumbsUpIconAnchorEl] = useState<HTMLButtonElement | null>(null);
            const buttonRef = useRef(null);

            const handleClosePopover = () => {
                setThumbsUpIconAnchorEl(null);
            };

            const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
                event.stopPropagation();
                setThumbsUpIconAnchorEl(event.currentTarget)
            };

            const open = Boolean(thumbsUpIconAnchorEl);
            const id = open ? `simple-popover${props.id}` : undefined;

            return (
                <>
                    <IconButton ref={buttonRef} id={`thumbs-up-inactive-${props.id}`} aria-label="approve" color="primary"
                        onClick={handleClick}
                    >
                        <ThumbUp style={{ color: "#CCCCCC" }} />
                    </IconButton>
                    <Popover
                        id={id}
                        open={open}
                        anchorEl={thumbsUpIconAnchorEl}
                        anchorOrigin={{
                            vertical: 'top',
                            horizontal: 'right',
                        }}
                        transformOrigin={{
                            vertical: 'bottom',
                            horizontal: 'left',
                        }}
                    >
                        <div className={classes.popoverBody}>
                            <div style={{ display: "flex", flexDirection: "row", justifyContent: "flex-end", alignItems: "end" }} className={classes.aiAssistantConfirmationHeader}>
                                <CloseIcon style={{ width: "12px", marginRight: "3px", cursor: "pointer", color: "gray" }} classes={{ root: classes.aiAssistantConfirmationPopupCloseIcon }}
                                    onClick={handleClosePopover}
                                />
                            </div>
                            {/* <Typography style={{ fontSize: "12px", textAlign: "center", margin: "0px 15px 15px 15px", color: "gray" }} className={classes.aiAssistantConfirmationMessage}>Kindly approve the lines first to be able <br></br> to approve the whole paragraph </Typography> */}
                            <Typography style={{ fontSize: "12px", textAlign: "center", margin: "0px 15px 15px 15px", color: "gray" }} className={classes.aiAssistantConfirmationMessage}>Please review each line first and click the thumbs-up icon once reviewed,<br></br> in order to approve the entire paragraph </Typography>
                        </div>
                    </Popover>

                </>

            );
        }

        const addParagraphIdToEnableApprove = (id: string) => {
            this.setState({
                ...this.state, bookTranslationResourceIdsEnableApprove: [ ...this.state.bookTranslationResourceIdsEnableApprove, id]
            })
        }

        const updateDataChangedList = (id: string, latestData: IData) => {
            this.setState({
                ...this.state, 
                changedResources: [...this.state.changedResources.filter((data) => data._id!=id), latestData]
            })
        }

        const removeParagraphIdtoDisableApprove = (id: string) => {
            this.setState({
                ...this.state, bookTranslationResourceIdsEnableApprove: [ ...this.state.bookTranslationResourceIdsEnableApprove.filter((lineId)=>id!=lineId)]
            })
        }

        const isParagraphEnableApprove = ( id: string ) => {
            let isParagraphEnableApprove = true;
            const paragraphData = data.resources.find((res)=> res._id===id)
            paragraphData?.textTranslatedLanguageSentences?.forEach((sentence) => {
                if(!sentence?.isApprovedByUser)
                isParagraphEnableApprove = false;
            })
            paragraphData?.textOriginalLanguageSentences?.forEach((sentence) => {
                if(sentence?.confidenceScore&&!sentence?.isApprovedByUser)
                isParagraphEnableApprove = false;
            })
            return isParagraphEnableApprove;
        }
        const addParagraphToChangedList = ( id: string ) => {
            this.setState({
                ...this.state, bookTranslationParagraphsWhichAreChanged: [...this.state.bookTranslationParagraphsWhichAreChanged, id]
            })
        }
       
        const BookTranslationModelParagraphRow=(props:{resource:IData,addStyles:any})=>{

            const {resource,addStyles} =props;
            const [anchorEl, setAnchorEl] = useState<HTMLButtonElement | null>(null);

            const handleClosePopover = () => {
                setAnchorEl(null);
              };
               

                
              const checkIsParagraphMispredited=()=>{
                let isInclude=false;
                resource?.textTranslatedLanguageSentences?.forEach((sentences)=>{
                    if(sentences?.connectedWithOriginalLanguageTextSentenceIdsPredicted?.length > 0 || sentences?.predictedSentence?.text){
                        isInclude= true;
                    }
                })
                if(this.state.bookTranslationParagraphsWhichAreChanged.find((id)=> id===resource._id)!==undefined)
                isInclude = true;
                return isInclude
             }

              const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
                event.stopPropagation();
                if(checkIsParagraphMispredited()){
                    setAnchorEl(event.currentTarget);
                }
              };
              const open = Boolean(anchorEl);
              //console.log("Anchor",anchorEl)

              return <>
                  { resource.resource && <button style={addStyles} onClick={handleClick}  className={ resource.resource && checkIsParagraphMispredited()?css.highlightMispredictedParagraph:resource.resource ?css.highlightParagraph:""}>
                       {/* Chapter {row.chapter?.sequenceNumber} Paragraph {row.paragraph?.sequenceNumber} */}
                      {resource.resource}                                                    
                 </button>}

                  <Popover
                         id={"Save-Popover"}
                         open={open}
                         anchorEl={anchorEl}
                         anchorOrigin={{
                         vertical: 'top',
                         horizontal: 'left',
                        }}
                        transformOrigin={{
                        vertical: 'bottom',
                        horizontal: 'left',
                        }}
                        PaperProps={{
                            style: {
                              width: "250px",
                              backgroundColor:"white",
                              borderRadius: 5,
                              overflow: "visible",
                              marginTop:"-10px"
                            }
                          }}
                        >
                           
                            <div className={classes.popoverBody}>
                                <div style={{ display: "flex", flexDirection: "row", justifyContent: "flex-end", alignItems: "end"}} className={classes.aiAssistantConfirmationHeader}>
                                    <CloseIcon style={{width: "13px", marginRight: "5px", cursor: "pointer", color: "gray"}} classes={{root: classes.aiAssistantConfirmationPopupCloseIcon}} 
                                    onClick={handleClosePopover}
                                    />
                                </div>
                                <Typography style={{ fontSize: "13px", textAlign: "center", margin: "0px 20px 20px 20px", color: "gray"}} className={classes.aiAssistantConfirmationMessage}>This paragraph is mispredicted</Typography>
                             </div>
                             <Box  className={css.arrowDown} />
                        </Popover>
              </>
        }

        if(currentModelType === "forecasting" || currentModelType === "audio" || currentModelType === "text" || currentModelType === "textAnnotation" || currentModelType === "DLP" || currentModelType === "video" || currentModelType === "image") {
            if(currentModelType === "forecasting"){
                if (this.props.scenario !== "dataSetCollectionTableDetailsPopup" && this.props.scenario !== "browseCollectionsPopup" && this.props.scenario !== "aiMarketPlaceDataCart" && this.props.scenario !== 'aiMarketplaceDataSelection') {
                    this.props.updateDataViewType("list-view");
                }
            }
            if (this.props.scenario !== "dataSetCollectionTableDetailsPopup" && this.props.scenario !== "browseCollectionsPopup" && this.props.scenario !== "aiMarketPlaceDataCart" && this.props.scenario !== 'aiMarketplaceDataSelection') {
                this.props.setSideDrawer({type: "hideSideDrawer"});
            }
        }
        const ActionButtonArray=(props: {
            onActionButtonClick?: ()=>any,
            gap?: string,
            closePopup?: Function,
            annotationLabelsForVideoAnnotationPopup?: string[]
        })=>{
            const { closePopup, annotationLabelsForVideoAnnotationPopup } = props;
            const modelType=this.props?.data?.resources[this.state.index]?.model?.type;
            const [anchorEl, setAnchorEl] = useState<HTMLButtonElement | null>(null);

            const handleClosePopover = () => {
              setAnchorEl(null);
            };

            return <div className={classes.actions} style={{marginLeft: modelType!== "forecasting" ? -12 : 0, gap: props.gap ? props.gap : "0px"}}>
                        {
                this.state.status === 'approved' &&
                (
                        this.props.resourcesWhichAreBeingAddedToDataSetCollectionInDB.indexOf(this.props.data.resources[this.state.index]?._id) === -1
                        ? 
                            (
                                <Tooltip title={tooltipTextForEditResourceDataSetCollectionButton}>
                                    <span>
                                        <AddResourceToDataSetCollectionButton
                                            onClick={()=>{
                                                this.setState({
                                                    resourceIdsSelectedForAddingToDataSetCollection: [this.props.data.resources[this.state.index]?._id],
                                                    isAddResourcesToDataSetCollectionPopupShowing: true
                                                });
                                                // if ((selectedIndex === data.resources.length - 1) && (((rowsPerPage * page) + (this.state.index + 1)) < data.totalCount)) {
                                                //     // this.setState({...this.state,isViewAndTrimAudioResourcePopupOpen:false})
                                                //     this.handleChangePage(null, page + 1, 0);
                                                // }
                                                // else if ((((rowsPerPage * page) + (this.state.index + 1)) === data.totalCount)) {
                                                //     this.setState({...this.state,isViewAndTrimAudioResourcePopupOpen:false})
                                                //     this.setState({...this.state,isViewForcastModelPopupOpen:false})
                                                //     // this.handleChangePage(null, page + 1, 0);
                                                // }
                                            }}
                                        />
                                    </span>
                                </Tooltip>
                            )
                        : <CircularProgress classes={{ root: classes.progress }} /> 
                )
               }
            {
                (this.state.status === "backlog" ||
                this.state.status === "approval") &&
                this.state.modelAiAssistantEnabled &&
                <>
                        <Tooltip 
                            classes={{
                                tooltip: this.state.aiAssistantTooltip.open && this.state.aiAssistantTooltip.scenario === "warning" ? css.aiAssistantTooltip : "",
                                arrow: this.state.aiAssistantTooltip.open && this.state.aiAssistantTooltip.scenario === "warning" ? css.aiAssistantTooltipArrow : ""
                            }}
                            interactive={this.state.aiAssistantTooltip.open && this.state.aiAssistantTooltip.scenario === "warning" ? true : false}
                            placement={"bottom-end"}
                            disableFocusListener
                            disableHoverListener
                            disableTouchListener
                            // disableFocusListener={this.state.aiAssistantTooltip.open && this.state.aiAssistantTooltip.scenario === "warning" ? true : false}
                            // disableHoverListener={this.state.aiAssistantTooltip.open && this.state.aiAssistantTooltip.scenario === "warning" ? true : false}
                            // disableTouchListener={this.state.aiAssistantTooltip.open && this.state.aiAssistantTooltip.scenario === "warning" ? true : false}                        
                            arrow
                            open={this.state.aiAssistantTooltip.open}
                            // title="Hola Amigo"
                            title={
                                this.state.aiAssistantTooltip.scenario === "description"
                                ? this.state.modelAiAssistantDescription
                                : this.state.aiAssistantTooltip.scenario === "warning"
                                    ?   
                                        (
                                            <ClickAwayListener onClickAway={() => {
                                                this.setState(oldState=>({
                                                    ...oldState,
                                                    aiAssistantTooltip: {
                                                        ...oldState.aiAssistantTooltip,
                                                        open: false,
                                                        scenario: ""
                                                    }
                                                }))
                                            }}>
                                            <div className="aiAssistantConfirmation">
                                                <div className={css.aiAssistantConfirmationHeader}>
                                                    <CloseIcon classes={{root: css.aiAssistantConfirmationPopupCloseIcon}} 
                                                        onClick={()=>{
                                                            this.setState(oldState=>({
                                                                ...oldState,
                                                                aiAssistantTooltip: {
                                                                    ...oldState.aiAssistantTooltip,
                                                                    open: false,
                                                                    scenario: ""
                                                                }
                                                            }))
                                                        }}
                                                    />
                                                </div>
                                                <div className="aiAssistantConfirmationBody">
                                                    <div className={css.aiAssistantConfirmationMessage}>
                                                       Are you sure you would like to call the AI Assisted Feedback?
                                                    </div>
                                                </div>
                                                <div className={css.aiAssistantConfirmationFooter}>
                                                    <Button variant="contained" size="medium" style={{backgroundColor: "#409545", color: "white", width: "104px", height: "30px", fontSize: "11px"}}
                                                        onClick={async ()=>{

                                                            const resourceIdOnWhichAiAssistantIsAboutToBePerform = this.props.data.resources[this.state.index]?._id;

                                                                /**
                                                                 * setting resourceId for which Ai Assistant api ie being called
                                                                 */
                                                                this.setState(oldState=>({
                                                                    aiAssistantTooltip: {
                                                                        ...oldState.aiAssistantTooltip,
                                                                        open: false,
                                                                        scenario: "",
                                                                        AiAssistantApiBeingCalledForResourceIds: [
                                                                            ...oldState.aiAssistantTooltip.AiAssistantApiBeingCalledForResourceIds,
                                                                            resourceIdOnWhichAiAssistantIsAboutToBePerform
                                                                        ]
                                                                    } 
                                                                }));

                                                                let aiAssistantApiResponse: AxiosResponse<{
                                                                    updatedResource: IData;
                                                                }> | undefined;

                                                                try {
                                                                    const aiAssistantApiResponseInTry = await DataService.performAiAssistanceOnResource(resourceIdOnWhichAiAssistantIsAboutToBePerform);
                                                                    aiAssistantApiResponse = aiAssistantApiResponseInTry
                                                                } catch (error) {
                                                                    //@ts-ignore
                                                                    alert(error.message)
                                                                }
                                                                
                                                                if (
                                                                    aiAssistantApiResponse?.data?.updatedResource &&
                                                                    Object.keys(aiAssistantApiResponse?.data?.updatedResource)?.length>0
                                                                ) {
                                                                    this.props.updateResourceInUI({
                                                                        ...aiAssistantApiResponse?.data?.updatedResource,
                                                                        _id: resourceIdOnWhichAiAssistantIsAboutToBePerform
                                                                    })
                                                                }

                                                                // await new Promise<void>((resolve, reject) => {
                                                                //     setTimeout(() => {
                                                                //         // @ts-ignore
                                                                //         resolve()
                                                                //     }, 2000);
                                                                // })

                                                                /**
                                                                 * removing resourceId after aiAssistant api got completed
                                                                 */
                                                                this.setState(oldState=>{

                                                                    let AiAssistantApiBeingCalledForResourceIdsToSet = oldState.aiAssistantTooltip.AiAssistantApiBeingCalledForResourceIds;

                                                                    for (let resourceIdIndex = 0; resourceIdIndex < AiAssistantApiBeingCalledForResourceIdsToSet.length; resourceIdIndex++) {
                                                                        const resourceId = AiAssistantApiBeingCalledForResourceIdsToSet[resourceIdIndex];
                                                                        if (resourceId === resourceIdOnWhichAiAssistantIsAboutToBePerform) {
                                                                            AiAssistantApiBeingCalledForResourceIdsToSet.splice(resourceIdIndex, 1);
                                                                            AiAssistantApiBeingCalledForResourceIdsToSet = [...AiAssistantApiBeingCalledForResourceIdsToSet];
                                                                        }
                                                                    }

                                                                    return {
                                                                        ...oldState,
                                                                        aiAssistantTooltip: {
                                                                            ...oldState.aiAssistantTooltip,
                                                                            AiAssistantApiBeingCalledForResourceIds: [
                                                                                ...AiAssistantApiBeingCalledForResourceIdsToSet
                                                                            ]
                                                                        } 
                                                                    }

                                                                })                                                                 
                                                        }}
                                                    >Yes!</Button>
                                                </div>
                                            </div>
                                            </ClickAwayListener>
                                        )
                                    :   ""
                            }
                        >
                            <div>
                                {
                                    this.state.aiAssistantTooltip.AiAssistantApiBeingCalledForResourceIds?.indexOf(this.props.data.resources[this.state.index]?._id) !== -1
                                    ?
                                        <Tooltip 
                                            title="AI Assisted Feedback is being performed"
                                        >
                                            <CircularProgress 
                                                style={{
                                                    width: "22px",
                                                    height: "22px",
                                                    cursor: "pointer",
                                                    marginLeft: "12px",
                                                    marginRight: "12px",                                                
                                                }}
                                            />
                                        </Tooltip>
                                    :
                                        <AiAssistantIcon 
                                            style={{
                                                width: "22px",
                                                height: "22px",
                                                cursor: "pointer",
                                                marginLeft: "12px",
                                                marginRight: "12px",
                                            }}
                                            onClick={()=>{
                                                this.setState(oldState => ({
                                                    ...oldState,
                                                    aiAssistantTooltip: {
                                                        ...oldState.aiAssistantTooltip,
                                                        open: true,
                                                        scenario: "warning",
                                                    }
                                                }))

                                            }}
                                            onMouseEnter={(event)=>{
                                                console.log("hola mouse enter")
                                                if (this.state.aiAssistantTooltip.scenario !== "warning") {
                                                    this.setState((oldState) => ({
                                                        ...oldState,
                                                        aiAssistantTooltip: {
                                                            ...oldState.aiAssistantTooltip,
                                                            open: true,
                                                            scenario: "description"
                                                        }
                                                    }))
                                                }
                                            }}
                                            onMouseLeave={(event)=>{
                                                console.log("hola mouse leave")
                                                if (this.state.aiAssistantTooltip.scenario === "description") {
                                                    this.setState(oldState => ({
                                                        ...oldState,
                                                        aiAssistantTooltip: {
                                                            ...oldState.aiAssistantTooltip,
                                                            open: false,
                                                            scenario: ""
                                                        }
                                                    }))
                                                }
                                            }}
                                        />
                                }

                            </div>
                        </Tooltip>
                </>
            }
        
                {
                    this.state.status === 'approval' 
                    ? (
                        approvedWaitingList.indexOf(this.props.data.resources[this.state.index]?._id) >= 0 
                        ?   <CircularProgress classes={{ root: classes.progress }} /> 
                        :   <>
                            <Tooltip title={!annotationLabelsForVideoAnnotationPopup?.length ? tooltipTextForApprovedIcon : ""}>
                                <IconButton aria-label="approve"
                                 style={{ color: !annotationLabelsForVideoAnnotationPopup?.length ? themePrimaryMainColor : "#CCCCCC"  }}
                                 disabled={
                                     deletedWaitingList.indexOf(this.props.data.resources[this.state.index]?._id) >= 0 ||
                                     this.props.resourcesWhichAreBeingAddedToDataSetCollectionInDB.indexOf(this.props.data.resources[this.state.index]?._id) !== -1
                                } 
                                // onMouseOver={(event: React.MouseEvent<HTMLButtonElement>) => {
                                //   if (annotationLabelsForVideoAnnotationPopup?.length) {
                                //     setAnchorEl(event.currentTarget);
                                //   }
                                // }}
                                 onClick={(event: React.MouseEvent<HTMLButtonElement>) => {
                                    if (annotationLabelsForVideoAnnotationPopup?.length) {
                                      setAnchorEl(event.currentTarget);
                                      return;
                                    }
                                    if ((selectedIndex === data.resources.length - 1) && (((rowsPerPage * page) + (this.state.index + 1)) < data.totalCount)) {
                                        //  this.setState({...this.state,isViewAndTrimAudioResourcePopupOpen:false})
                                         this.handleChangePage(null, page + 1, 0);
                                     }
                                     else if ((((rowsPerPage * page) + (this.state.index + 1)) === data.totalCount)) {
                                         this.setState({isViewAndTrimAudioResourcePopupOpen:false})
                                         this.setState({isViewForcastModelPopupOpen:false})
                                         this.setState({imageKeyPointsDialog:{isOpen: false}})
                                         // this.handleChangePage(null, page + 1, 0);
                                     }
                                     this.onUpdateData({ ids: [this.props.data.resources[this.state.index]?._id], status: 'approved' })
                                     if (props.onActionButtonClick) {
                                         props.onActionButtonClick();
                                     }
                                     if (closePopup && !this.props.data.resources.length) {
                                        closePopup();
                                     }
                                     
                                }}
                                >
                                    <Check />
                                </IconButton>
                            </Tooltip>
                            <InfoPopover anchorEl={anchorEl} handleClose={handleClosePopover} labels={annotationLabelsForVideoAnnotationPopup} ></InfoPopover>
                            </>
                    ) 
                    : null
                }
                {
                    (
                        this.state.status === "backlog" ||
                        this.state.status === "rejected"
                    ) 
                        &&
                    (   
                        <Tooltip
                            title={!annotationLabelsForVideoAnnotationPopup?.length ? tooltipTextForSetApprovalStatusButton : ""}
                        >   
                            <span>
                                <SetApprovalStatusButton
                                    // color="primary"
                                    color={annotationLabelsForVideoAnnotationPopup?.length ? "#CCCCCC" : themePrimaryMainColor}
                                    // onMouseOver={(event: React.MouseEvent<HTMLButtonElement>) => {
                                    //   if (annotationLabelsForVideoAnnotationPopup?.length) {
                                    //     setAnchorEl(event.currentTarget);
                                    //   }
                                    // }}
                                    onClick={(event: React.MouseEvent<HTMLButtonElement>) => {
                                        if (annotationLabelsForVideoAnnotationPopup?.length) {
                                          setAnchorEl(event.currentTarget);
                                          return;
                                        }
                                        if ((selectedIndex === data.resources.length - 1) && (((rowsPerPage * page) + (this.state.index + 1)) < data.totalCount)) {
                                            // this.setState({...this.state,isViewAndTrimAudioResourcePopupOpen:false})
                                            this.handleChangePage(null, page + 1, 0);
                                        } 
                                        else if ((((rowsPerPage * page) + (this.state.index + 1)) === data.totalCount)) {
                                            this.setState({isViewAndTrimAudioResourcePopupOpen:false})
                                            this.setState({isViewForcastModelPopupOpen:false})
                                            this.setState({imageKeyPointsDialog:{isOpen: false}})
                                            // this.handleChangePage(null, page + 1, 0);
                                        }
                                        this.onUpdateData({ ids: [this.props.data.resources[this.state.index]?._id], status: 'approval' })
                                        if (props.onActionButtonClick) {
                                            props.onActionButtonClick();
                                        } 
                                        if (closePopup && !this.props.data.resources.length) {
                                          closePopup();
                                        }
                                        
                                    }}
                                />
                                <InfoPopover anchorEl={anchorEl} handleClose={handleClosePopover} labels={annotationLabelsForVideoAnnotationPopup} ></InfoPopover>
                            </span>
                        </Tooltip>
                    )
                }
                {
                    (
                        this.state.status === "approval" ||
                        this.state.status === "approved"
                    ) &&
                    (   
                        <Tooltip
                            title={tooltipTextForSetRejectedStatusButton}
                        >   
                            <span>
                                <SetRejectedStatusButton
                                    color="primary"
                                    onClick={() => {
                                        if ((selectedIndex === data.resources.length - 1) && (((rowsPerPage * page) + (this.state.index + 1)) < data.totalCount)) {
                                            // this.setState({...this.state,isViewAndTrimAudioResourcePopupOpen:false})
                                            this.handleChangePage(null, page + 1, 0);
                                        }        
                                        else if ((((rowsPerPage * page) + (this.state.index + 1)) === data.totalCount)) {
                                            this.setState({isViewAndTrimAudioResourcePopupOpen:false})
                                            this.setState({isViewForcastModelPopupOpen:false})
                                            this.setState({imageKeyPointsDialog:{isOpen: false}})
                                            // this.handleChangePage(null, page + 1, 0);
                                        }  
                                        this.onUpdateData({ ids: [this.props.data.resources[this.state.index]?._id], status: 'rejected' })
                                        if (props.onActionButtonClick) {
                                            props.onActionButtonClick();
                                        }
                                        if (closePopup && !this.props.data.resources.length) {
                                          closePopup();
                                        }
                                                              
                                    }}
                                />
                            </span>
                        </Tooltip>
                    )
                }
                {
                    this.state.status === 'deleted'
                    ? (
                        approvedWaitingList.indexOf(this.props.data.resources[this.state.index]?._id) >= 0 
                        ?   <CircularProgress classes={{ root: classes.progress }} /> 
                        :
                            <Tooltip title={tooltipTextForBacklogIcon}>
                                <IconButton aria-label="approve" color="primary"
                                 disabled={
                                     
                                     deletedWaitingList.indexOf(this.props.data.resources[this.state.index]?._id) >= 0 ||
                                     this.props.resourcesWhichAreBeingAddedToDataSetCollectionInDB.indexOf(this.props.data.resources[this.state.index]?._id) !== -1
                                } 
                                 onClick={() => {
                                    if ((selectedIndex === data.resources.length - 1) && (((rowsPerPage * page) + (this.state.index + 1)) < data.totalCount)) {
                                        // this.setState({...this.state,isViewAndTrimAudioResourcePopupOpen:false})
                                        this.handleChangePage(null, page + 1, 0);
                                    } 
                                    else if ((((rowsPerPage * page) + (this.state.index + 1)) === data.totalCount)) {
                                        this.setState({isViewAndTrimAudioResourcePopupOpen:false})
                                        this.setState({isViewForcastModelPopupOpen:false})
                                        this.setState({imageKeyPointsDialog:{isOpen: false}})
                                        // this.handleChangePage(null, page + 1, 0);
                                    }
                                     this.onUpdateData({ ids: [this.props.data.resources[this.state.index]?._id], status: 'backlog' })
                                     if (props.onActionButtonClick) {
                                        props.onActionButtonClick();
                                    }      
                                    if (closePopup && !this.props.data.resources.length) {
                                      closePopup();
                                    }
                                }}
                                >
                                    <Restore />
                                </IconButton>
                            </Tooltip>
                    ) 
                    : null
                }
                {
                    // status === 'approved' 
                    false
                    ? (
                        approvedWaitingList.indexOf(this.props.data.resources[this.state.index]?._id) >= 0 
                        ?   <CircularProgress classes={{ root: classes.progress }} /> 
                        :
                            <Tooltip title={tooltipTextForBacklogIcon}>
                                <IconButton aria-label="approve" color="primary" 
                                    disabled={
                                        deletedWaitingList.indexOf(this.props.data.resources[this.state.index]?._id) >= 0 ||
                                        this.props.resourcesWhichAreBeingAddedToDataSetCollectionInDB.indexOf(this.props.data.resources[this.state.index]?._id) !== -1
                                    } 
                                    onClick={() => {
                                        if ((selectedIndex === data.resources.length - 1) && (((rowsPerPage * page) + (this.state.index + 1)) < data.totalCount)) {
                                            // this.setState({...this.state,isViewAndTrimAudioResourcePopupOpen:false})
                                            this.handleChangePage(null, page + 1, 0);
                                        } 
                                        else if ((((rowsPerPage * page) + (this.state.index + 1)) === data.totalCount)) {
                                            this.setState({isViewAndTrimAudioResourcePopupOpen:false})
                                            this.setState({isViewForcastModelPopupOpen:false})
                                            this.setState({imageKeyPointsDialog:{isOpen: false}})
                                            // this.handleChangePage(null, page + 1, 0);
                                        }
                                        this.onUpdateData({ ids: [this.props.data.resources[this.state.index]?._id], status: 'backlog' })
                                        if (props.onActionButtonClick) {
                                            props.onActionButtonClick();
                                        }   
                                        if (closePopup && !this.props.data.resources.length) {
                                          closePopup();
                                        }
                                                                            
                                    }}
                                >
                                    <Restore />
                                </IconButton>
                            </Tooltip>
                    ) 
                    : null
                }
                {
                    (
                        deletedWaitingList.indexOf(this.props.data.resources[this.state.index]?._id) >= 0 
                        ?   <CircularProgress classes={{ root: clsx(classes.progress, classes.delete) }} /> 
                        :   <Tooltip 
                                title={
                                    this.state.status === 'deleted'
                                    ?   tooltipTextForDeleteIconWhenDeletingResource
                                    :   tooltipTextForDeleteIconWhenMovingToDeleteTab
                                }
                            >
                                <IconButton classes={{ root: classes.delete }} 
                                    disabled={
                                        approvedWaitingList.indexOf(this.props.data.resources[this.state.index]?._id) >= 0 ||
                                        this.props.resourcesWhichAreBeingAddedToDataSetCollectionInDB.indexOf(this.props.data.resources[this.state.index]?._id) !== -1
                                    } 
                                    aria-label="delete" onClick={() => {
                                        if ((selectedIndex === data.resources.length - 1) && (((rowsPerPage * page) + (this.state.index + 1)) < data.totalCount)) {
                                            // this.setState({...this.state,isViewAndTrimAudioResourcePopupOpen:false})
                                            this.handleChangePage(null, page + 1, 0);
                                        }
                                        else if ((((rowsPerPage * page) + (this.state.index + 1)) === data.totalCount)) {
                                            this.setState({isViewAndTrimAudioResourcePopupOpen:false})
                                            this.setState({isViewForcastModelPopupOpen:false})
                                            this.setState({imageKeyPointsDialog:{isOpen: false}})
                                            // this.handleChangePage(null, page + 1, 0);
                                        }
                                        this.onUpdateData({ ids: [this.props.data.resources[this.state.index]?._id], status: 'deleted' })
                                        if (props.onActionButtonClick) {
                                            props.onActionButtonClick();
                                        }         
                                        if (closePopup && !this.props.data.resources.length) {                                          
                                          closePopup();
                                        }
                                                                       
                                    }}
                                >
                                    <Delete />
                                </IconButton>
                            </Tooltip>
                    )
                }
                {
                    //@ts-ignore
                    this.props.scenario === "browseCollectionsPopup" && !this.props.isUsingDataCartForAiAssistedlabellingCodeTesting
                    &&
                    (
                        itemsAddedToCart.resources?.find((obj: IData) => obj._id === this.props.data.resources[this.state.index]?._id) ?
                            <Tooltip title="remove item from cart">
                                <IconButton onClick={() => toggleItemInCart(-1, this.props.data.resources[this.state.index])}>
                                    <CartCheckIcon style={{ width: '30px', height: '30px' }} fill={themePrimaryMainColor} stroke={themePrimaryMainColor}/>
                                </IconButton>
                            </Tooltip>
                            :
                            <Tooltip title="add item to cart">
                                <IconButton onClick={() => toggleItemInCart(1, this.props.data.resources?.[this.state.index])}>
                                    <AddToCart style={{ width: '30px', height: '30px' }} />
                                </IconButton>
                            </Tooltip>
                    )
                }
                {
                    (
                        this.state.status === "backlog" ||
                        this.state.status === "approval" ||
                        this.state.status === "approved"
                    ) 
                        &&
                    (modelType === 'text' || modelType === 'textAnnotation' || modelType === 'DLP' || modelType === 'image' || modelType === 'imageAnnotation' || modelType === 'video' || modelType === 'audio' || modelType === 'audioTextTranscription')  &&
                    (<Button color='primary' variant='contained' style={{width : '137px', height : '35px', padding : '10px 5px',fontSize:"11px"}}
                    onClick={()=>{
                        // setAddResourceToCopilotDialogState(oldState=>({...oldState, isOpen: true}))
                        this.setState({addResourceToCopilotDialogState : {isOpen : true}})
                    }}>
                        Add to Copilot
                    </Button>)
                }
                {
                        this.state.addResourceToCopilotDialogState.isOpen && this.props.data.resources[this.state.index] &&
                        <AddModelResourceToCopilotDialog
                            open={this.state.addResourceToCopilotDialogState.isOpen}
                            resource={this.props.data.resources[this.state.index] as unknown as IData}
                            onClose={()=>this.setState({addResourceToCopilotDialogState : {isOpen : false}})}
                        />
                }
                {
                    (
                        this.state.status === "backlog" ||
                        this.state.status === "approval" ||
                        this.state.status === "approved"
                    ) &&
                    (modelType === 'image' || modelType === 'imageAnnotation' || modelType === 'imageAnnotationGroup') &&
                    <Tooltip title={"Edit this image"}>
                        <IconButton classes={{ root: classes.delete }} 
                            aria-label="delete" onClick={() => this.setState({ isImageEditingPopupOpen : true })}
                        >
                            <Edit color='primary' />
                        </IconButton>
                    </Tooltip>
                }

                {
                    this.state.currentModelType === "multipleImageAnnotation" &&
                    <Button color="primary" variant="outlined" size="small"
                        onClick={()=>{
                            this.setState(oldState=>{
                                return {
                                    ...oldState,
                                    copyImageInMultipleImageAnnotationToImageAnnotationGroupPopup: {
                                        isOpen: true,
                                        resource: this.props.data.resources[this.state.index],
                                    }
                                }
                            })
                        }}
                    >Send to Image Annotation Group Task</Button>
                }
                {
                    this.state.currentModelType === "textComparison"&&
                    <>
                    {/* <Button variant='outlined' color='primary'>
                        Add to Copilot
                    </Button>
                    <Button variant='outlined' color='primary'>
                        Edit
                    </Button> */}
                    <Button variant='outlined' color='primary'
                    onClick={()=>{
                        this.setState(oldState=>{
                            return {
                                ...oldState,
                                showMetaDataForTextComparisonModel: !oldState.showMetaDataForTextComparisonModel
                            }
                        })
                    }}
                    >
                        {this.state.showMetaDataForTextComparisonModel?"Hide Data":"Show Data"}
                    </Button></>
                }
            </div>
        }

        const evaluateDataArray = (isFetching: boolean, isApiRequestCancelled: boolean, data: IDataResponse) => {

            if(this.props.scenario === "dataCart" || this.props.scenario === "savedForLater" || this.props.rowsForDataCart) return this.props.rowsForDataCart || []
            else if(!isFetching && !isApiRequestCancelled){
                return (
                    data.resources?.slice(0, data.resources.length)
                                .filter(this.tableResourcesFilterCallback) || []
                )
            }        
            else return []
        }

        const labelOptionsForARow = (row: IData) => {
            const labelsAvailable = [
                ...(row.labelOptions?.map(labelObj => {
                    return {
                        label: labelObj.labelOption,
                        group: LABEL_GROUP_RESOURCE_LEVEL,
                    }
                }) || []
                ),
                ...(this.state.labelsAvailable?.map(label => { return {label, group: LABEL_GROUP_TASK_LEVEL} }) || []),
            ]

            return labelsAvailable
        }
  
        return (
            <Paper 
                className={`
                    ${classes.paper}
                    ${(this.props.scenario === "dataSetCollectionTableDetailsPopup" || this.props.scenario === "browseCollectionsPopup" || this.props.scenario === "aiMarketPlaceDataCart" || this.props.scenario === "aiMarketplaceDataSelection") ? classes.paperWhenScenarioDataSetCollectionTableDetailsPopup : ""}
                    ${currentModelType === "bookTranslation" ? css.rootBookTranslationModelType : ""}
                `} 
                variant="outlined" 
            >

                {/* popup coponent for forcast  */}
                <ViewForcastModelPopup 
                open={this.state.isViewForcastModelPopupOpen} 
                handleClose={()=>{this.setState({...this.state,isViewForcastModelPopupOpen:false})}}
                resource={this.props.data.resources[this.state.index]}
                resourceImageList={this.state.imageList}
                setShouldBlockDataTableKeyboardEvents={this.props.setShouldBlockDataTableKeyboardEvents}
                onMoveToLeft={()=>{
                    this.iterateOverResourcesOnArrowClick(-1);
                }}
                onMoveToRight={()=>{
                    this.iterateOverResourcesOnArrowClick(1);
                }}
                onApproveButtonClicked={()=>{console.log("clicked approve")}}
                onRejectButtonClicked={()=>{console.log("clicked reject")}}
                onDeleteButtonClicked={()=>{console.log("clicked delete")}}
                ActionButtonArray={ActionButtonArray}
                LabelsAvailable = {this.state.labelsAvailable}
                allowUserToSetAnyLabel = {this.state.allowUserToSetAnyLabel}
                />
                <ViewAndTrimAudioResource 
                open={this.state.isViewAndTrimAudioResourcePopupOpen} 
                isFetchingResources={isFetching}
                modelRefernceData={this.state.modelReferenceData}
                modelAnnotationLabelsAvailable={this.state.modelAnnotationLabelsAvailable}
                modelMaxAudioTrimAllowedInMilliSeconds={this.state.modelMaxAudioTrimAllowedInMilliSeconds}
                handleClose={()=>{this.setState({...this.state,isViewAndTrimAudioResourcePopupOpen:false})}}
                resource={(this.props.scenario === "dataCart" || this.props.scenario === "savedForLater" || this.props.scenario === 'aiMarketPlaceDataCart') && this.props.rowsForDataCart ? this.props.rowsForDataCart[this.state.index] : this.props.data.resources[this.state.index]}
                // resourceImageList={this.state.imageList}
                setShouldBlockDataTableKeyboardEvents={this.props.setShouldBlockDataTableKeyboardEvents}
                onMoveToLeft={()=>{
                    this.iterateOverResourcesOnArrowClick(-1);
                }}
                onMoveToRight={()=>{
                    this.iterateOverResourcesOnArrowClick(1);
                }}
                previousResource={(this.props.scenario === "dataCart" || this.props.scenario === "savedForLater") && this.props.rowsForDataCart ? this.props.rowsForDataCart[this.state.index - 1] : this.props.data.resources[this.state.index - 1]}
                nextResource={(this.props.scenario === "dataCart" || this.props.scenario === "savedForLater") && this.props.rowsForDataCart ? this.props.rowsForDataCart[this.state.index + 1] : this.props.data.resources[this.state.index + 1]}
                onApproveButtonClicked={()=>{console.log("clicked approve")}}
                onRejectButtonClicked={()=>{console.log("clicked reject")}}
                onDeleteButtonClicked={()=>{console.log("clicked delete")}}
                ActionButtonArray={ActionButtonArray}
                LabelsAvailable = {this.state.labelsAvailable}
                isResourceExistBefore={((rowsPerPage * page) + (this.state.index - 1)) >= 0}
                isResourceExistAfter={((rowsPerPage * page) + (this.state.index + 1)) < data.totalCount}
                allowUserToSetAnyLabel={this.state.allowUserToSetAnyLabel}
                showMetaDataForTextComparisonModel={this.state.showMetaDataForTextComparisonModel}
                textOneHeadingForTextComparisonUI={this.state.textOneHeadingForTextComparisonUI}
                textTwoHeadingForTextComparisonUI={this.state.textTwoHeadingForTextComparisonUI}
                //@ts-ignore
                scenario={this.props.scenario}
                />
                <VideoAnnotationDialogBox
                  isFetchingResources={isFetching}
                  open={this.state.isVideoAnnotationDialogBoxOpen} 
                  handleClose={()=>{this.setState({...this.state,isVideoAnnotationDialogBoxOpen:false})}}
                  resource={(this.props.scenario === "dataCart" || this.props.scenario === "savedForLater" || this.props.scenario === 'aiMarketPlaceDataCart') && this.props.rowsForDataCart ? this.props.rowsForDataCart[this.state.index] : this.props.data.resources[this.state.index]}
                  resourceIndex={this.state.index}
                  setShouldBlockDataTableKeyboardEvents={this.props.setShouldBlockDataTableKeyboardEvents}
                  onMoveToLeft={()=>{ this.iterateOverResourcesOnArrowClick(-1) }}
                  onMoveToRight={()=>{ this.iterateOverResourcesOnArrowClick(1) }}
                  LabelsAvailable = {this.state.labelsAvailable}
                  allowUserToSetAnyLabel = {this.state.allowUserToSetAnyLabel}
                  ActionButtonArray={ActionButtonArray}
                  aiAssistantToolip={this.state.aiAssistantTooltip}
                  annotationLabelsAvailable={this.state.modelAnnotationLabelsAvailable}
                />
                {
                    this.state.imageKeyPointsDialog.isOpen && currentModelType &&
                    <ResourcesTableImageKeyPointsDialogWrapper 
                    updateResourceInUI={this.props.updateResourceInUI}
                        multipleImageAnnotationResourceImageIndex={this.state.multipleImageAnnotationResourceImageIndex}
                        setCurrentMultipleImageAnnotationResourceImageIndex={this.setMultipleImageAnnotationResourceImageIndex}
                        modelReferenceData={this.state.modelReferenceData}
                        isImageKeyPointsEditDialogOpen={this.state.imageKeyPointsDialog.isOpen} 
                        onImageKeyPointsEditDialogClose={() => {
                            this.setState({
                                imageKeyPointsDialog: {
                                    isOpen: false
                                }
                            })
                        }} 
                        modelOrCopilotType={currentModelType} 
                        modelId={this.state.modelId}
                        isFetchingResource={this.props.isFetching}
                        resource={(this.props.scenario === "dataCart" || this.props.scenario === "savedForLater" || this.props.scenario === 'aiMarketPlaceDataCart') && this.props.rowsForDataCart ?  (this.props.rowsForDataCart?.[this.state.index]) : this.props.data.resources[this.state.index]} 
                        resources={(this.props.scenario === "dataCart" || this.props.scenario === "savedForLater" || this.props.scenario === 'aiMarketPlaceDataCart') && this.props.rowsForDataCart ?  (this.props.rowsForDataCart) : this.props.data.resources}
                        allowUserToSetAnyLabel={this.state.allowUserToSetAnyLabel}
                        onSwitch={(direction) => {
                            if (direction === -1) {
                                // navigate to previous resource
                                this.iterateOverResourcesOnArrowClick(-1);
                            } else if (direction === 1) {
                                // navigate to next resource
                                this.iterateOverResourcesOnArrowClick(1);
                            }
                        }} 
                        resourcesPaginationTotalCount={this.props.data.totalCount} 
                        updateResourceImageGroupAnnotationsInDB={this.props.updateResourceImageGroupAnnotationsInDB}
                        updateResourceImageAnnotationsInDB={this.props.updateResourceImageAnnotationsInDB}
                        updateResourceMultipleImageAnnotationsInDB={this.props.updateResourceMultipleImageAnnotationsInDB}
                        updateResourceRemarks={this.props.updateResourceRemarks}
                        labelsAvailable={this.state.labelsAvailable}
                        ActionsSection={
                            <div style={{ backgroundColor: 'white', opacity: 1, zIndex: 5, marginLeft: '4px'}}>
                            <div style={{display:'flex'}}>
                                <p style={{marginBottom: "10px", color: `${"#8e969e"}`, alignSelf:'flex-end', marginRight: "30px", width: '120px'}}>Actions : </p>
                                <div>
                                    <>{<ActionButtonArray />}</>
                                </div>
                            </div>
                        </div>                      
                        }        
                        //@ts-ignore            
                        scenario={this.props.scenario}
                    />
                }
                {
                    this.state.isAddResourcesToDataSetCollectionPopupShowing &&
                    <AddOrDeleteResourcesToDataSetCollectionSelectionPopup
                        modelId={this.props.data?.resources[0]?.model?._id}
                        open={this.state.isAddResourcesToDataSetCollectionPopupShowing}
                        onClose={() => {
                            this.setState({
                                isAddResourcesToDataSetCollectionPopupShowing: false,
                                resourceIdsSelectedForAddingToDataSetCollection: []
                            });
                        } }
                        resourceIdsSelected={this.state.resourceIdsSelectedForAddingToDataSetCollection}
                        totalResourceCount={this.props.data.totalCount}
                        scenario={this.state.resourceIdsSelectedForAddingToDataSetCollection.length === 1
                            ? 'editing'
                            : 'adding'} 
                        dataSetCollectionsAvailable={this.props.dataSetCollectionsForFilter} 
                        isFetchDataSetCollectionApiExecuting={this.props.isDataSetCollectionsBeingFetchedForFilter}
                        areAllRowsMatchingTableFilterSelectedByUserAndHasRowsNotShowingInUI={this.state.allSelected}
                        dataSetPayload={this.state.allSelected ? this.addToCollectionsPayload() : undefined}
                    />
                } 

                {
                    this.state.removeResourcesFromDataSetCollectionSelectionPopup.open &&
                    <RemoveResourcesFromDataSetCollectionSelectionPopup
                        dataSetCollectionsOfSelectedResources={uniqueDataSetCollectionsOfSelectedResources}
                        onClose={() => {
                            this.setState({
                                removeResourcesFromDataSetCollectionSelectionPopup: {
                                    open: false
                                }
                            });
                        } }
                        resourceIdsSelected={selectedItems}     
                    />
                } 

                {
                    this.props.scenario !== "dataCart" && this.props.scenario !== "savedForLater"
                    &&
                    <EnhancedTableToolbar status={status} updateData={this.onUpdateData} resources={data.resources} selected={selectedItems} changeView={this.changeView} currentView={this.props.dataView} currentModelType={currentModelType}
                    scenario={this.props.scenario !== "browseCollectionsPopup" ? this.props.scenario : undefined}
                    addResourceToDataSetCollectionButton={<Tooltip
                        title={this.props.selectedItems?.length === 1
                            ? tooltipTextForEditResourceDataSetCollectionButton
                            : tooltipTextForAddResourceDataSetCollectionButton}
                    >
                        <span>
                            {this.props.resourcesWhichAreBeingAddedToDataSetCollectionInDB?.length > 0
                                ? <CircularProgress style={{ marginTop: '6px' }} classes={{ root: classes.progress }} />
                                : <AddResourceToDataSetCollectionButton
                                    onClick={() => {
                                        this.setState({
                                            resourceIdsSelectedForAddingToDataSetCollection: [...this.props.selectedItems],
                                            isAddResourcesToDataSetCollectionPopupShowing: true
                                        });
                                    } } />}
                        </span>
                    </Tooltip>}
                    removeResourcesFromDataSetCollectionIcon={
                        <Tooltip title="Remove data-set collections from selected datas">
                            <IconButton>
                                <DataSetsResourcesRemoveIcon 
                                    style={{
                                        width: "28px",
                                        height: "28px",
                                        cursor: "pointer",
                                        color: "#008d9c"
                                    }}
                                    onClick={()=>{
                                        this.setState(oldState=>{
                                            return {
                                                ...oldState,
                                                removeResourcesFromDataSetCollectionSelectionPopup: {
                                                    ...oldState.removeResourcesFromDataSetCollectionSelectionPopup,
                                                    open: true
                                                }
                                            }
                                        })
                                    }}
                                />
                            </IconButton>
                        </Tooltip>                        
                    }

                    deleteIconButtonProps={{
                        disabled: this.props.resourcesWhichAreBeingAddedToDataSetCollectionInDB?.length > 0
                    }}
                    backlogIconButtonProps={{
                        disabled: this.props.resourcesWhichAreBeingAddedToDataSetCollectionInDB?.length > 0
                    }}
                    approvedIconButtonProps={{
                        disabled: this.props.resourcesWhichAreBeingAddedToDataSetCollectionInDB?.length > 0
                    }}
                    dataSetCollectionFilter={this.state.status === 'approved' &&
                        this.props.dataSetCollectionsForFilter?.length > 0 &&
                        (this.props.scenario !== "dataSetCollectionTableDetailsPopup" && this.props.scenario !== "browseCollectionsPopup" && this.props.scenario !== "aiMarketPlaceDataCart" && this.props.scenario !== "aiMarketplaceDataSelection"
                            && this.props.scenario !== "aiMarketplaceSelectedDataList"
                        )
                        ?
                        (
                            <div style={{position: "relative", alignSelf:"center", height: "45px"}}>
                                <Autocomplete
                                multiple
                                limitTags={1}
                                style={{ width: '300px' }}
                                id="data-set filter for Data Table"
                                options={this.props.dataSetCollectionsForFilter.map(
                                    (collection) => collection._id
                                )}
                                value={this.state.filter.resourceDataSetCollectionIds}
                                getOptionLabel={(collectionId) => {
                                    const collectionName = this.props.dataSetCollectionsForFilter.filter(
                                        (collection) => collection._id.toString() === collectionId.toString()
                                    )[0]?.name;
                                    if (collectionName) {
                                        return collectionName;
                                    } else {
                                        return collectionId;
                                    }
                                } }
                                // renderOption={(option, state)=>{
                                //     console.log('TableContent ~ render ~ option, state', option, state)
                                //     return <span>ok</span>
                                // }}
                                loading={this.props.isDataSetCollectionsBeingFetchedForFilter ? true : false}
                                loadingText={this.props.isDataSetCollectionsBeingFetchedForFilter ? "loading" : ""}
                                onChange={(event, value) => {
                                    const { location, history } = this.props;
                                    // this.setState(oldState=> { return {
                                    //     ...oldState,
                                    //     filter: {
                                    //         ...oldState.filter,
                                    //         resourceDataSetCollectionId: value || ''
                                    //     }
                                    // }})
                                    const urlQuery = new URLSearchParams(location?.search);
                                    urlQuery.set(resourceDataSetCollectionIdQueryParam, JSON.stringify(value) || '');
                                    history.push(`${location?.pathname}?${urlQuery.toString()}`);
                                } }
                                renderTags={(value, getTagProps) => {
                                    return value.map((option, index) => {

                                        const optionDataSetCollectionName: string = (()=>{
                                            const dataSetCollection = this.props.dataSetCollectionsForFilter?.find(collection=>collection._id.toString() === option.toString())
                                            if (dataSetCollection) {
                                                return dataSetCollection.name;
                                            }
                                            return "";
                                        })();

                                        return (
                                            <Chip
                                                variant="outlined"
                                                color="primary"
                                                className={`${classes.chip}`}
                                                classes={{ root: classes.dataSetCollectionFilterAutoCompleteChipRoot }}
                                                label={<Tooltip title={optionDataSetCollectionName}>
                                                    <span>
                                                        {index + 1}:
                                                        {this.props.dataSetCollectionsForFilter.filter(
                                                            (collection) => collection._id.toString() === option.toString()
                                                        )[0]?.name}
                                                    </span>
                                                </Tooltip>}
                                                {...getTagProps({ index })} />
                                        )
                                    })
                                } }
                                renderInput={(params) => (
                                    <TextField
                                        {...params}
                                        variant="outlined"
                                        size="small"
                                        fullWidth
                                        // value={this.state.filter.resourceDataSetCollectionId}
                                        label="Filter By Data-Set Collection"
                                        className={`${classes.floating} ${classes.dataSetFilterAutoComplete}`}
                                    />
                                )} />
                            </div>
                            
                        )
                        : undefined}
                    resourcesWhichAreNotPartOfAnyDataSetCollectionFilter={this.state.status === 'approved' &&
                        (this.props.scenario !== "dataSetCollectionTableDetailsPopup" && this.props.scenario !== "browseCollectionsPopup" && this.props.scenario !== "aiMarketPlaceDataCart" && this.props.scenario !== "aiMarketplaceDataSelection"
                            && this.props.scenario !== "aiMarketplaceSelectedDataList"
                        )
                        ?
                        (
                            <FormControl component="fieldset"
                                style={{ alignSelf: 'center' }}
                            >
                                <FormGroup aria-label="position" row>
                                    <FormControlLabel
                                        control={<Switch color="primary"
                                            checked={this.state.filter.resourcesWhichAreNotPartOfAnyDataSetCollection === 'true'
                                                ? true
                                                : false}
                                            onChange={(event) => {
                                                const { location, history } = this.props;

                                                const urlQuery = new URLSearchParams(location?.search);
                                                urlQuery.set(DataTableResourcesWhichAreNotPartOfAnyDataSetCollectionQueryParam, event.target.checked ? 'true' : '');
                                                history.push(`${location?.pathname}?${urlQuery.toString()}`);
                                            } } />}
                                        label="Not part of any data-set collection"
                                        labelPlacement="start" />
                                </FormGroup>
                            </FormControl>
                        )
                        : undefined} 
                    totalResourcesCountMatchingTableFilter={this.props.data.totalCount} 
                    areAllRowsMatchingTableFilterSelectedByUserAndHasRowsNotShowingInUI={this.state.allSelected}                
                />
                }
                
                <TableContainer id={this.state.resourcesTableContainerElementId} className={`${classes.tableContainer} ${this.state.status === 'approved' ? classes.tableContainerWhenApprovedTab : ''}`} 
                    style={(this.props.scenario === 'dataCart' || this.props.scenario === "savedForLater") ? {height: '100%'} 
                                                                                                            : this.props.scenario === 'aiMarketPlaceDataCart' ? {height: 'calc(100% - 142px)'}
                                                                                                            : {}}
                >
              
                    <Table
                        className={classes.table}
                        aria-labelledby="dataTable"
                        size="small"
                        aria-label="enhanced table"
                        stickyHeader

                    >
          <TableHead>
            <TableRow classes={{root: classes.tableRow}}
                id={resourceTableHeadRowId}
            >
                {
                    this.props.scenario !== "dataSetCollectionTableDetailsPopup" && this.props.scenario !== "browseCollectionsPopup" &&
                    <TableCell padding="checkbox" className={`header ${css.dataTableHeadCell}`} classes={{root: classes.tableCellCheckbox}}
                        style={(this.props.scenario === "dataCart" || this.props.scenario === "savedForLater") ? {paddingTop: '16px', paddingBottom: '22px'} : {}}
                    >
                        {
                            (this.props.scenario === "dataCart" || this.props.scenario === "savedForLater" || this.props.scenario === 'aiMarketPlaceDataCart') ?
                            <Checkbox
                                checked={(this.props.rowsForDataCart?.length || 0) <= (this.props.currentSelectedItems?.length || 0) && this.props.rowsForDataCart?.length !== 0}
                                onChange={(event) => {
                                    if(event.target.checked){
                                        this.props.setCurrentSelectedItems
                                        &&
                                        this.props.setCurrentSelectedItems([...(this.props.rowsForDataCart || [])])
                                    }
                                    else this.props.setCurrentSelectedItems && this.props.setCurrentSelectedItems([])
                                }}  
                            />
                            :
                            <Checkbox
                                indeterminate={selectedItems.length > 0 && selectedItems.length < data.resources?.length}
                                checked={
                                    this.props.scenario === 'aiMarketplaceDataSelection' ? false
                                    : data.resources?.length > 0 && 
        
                                    /**
                                     * selectedItems.length can be greater than data.resources.length when all resources
                                     * are selected which are not limited by pagination
                                     */
                                    selectedItems.length >= data.resources?.length
                                }
                                style={{marginTop:`${currentModelType==="bookTranslation"?"6px":"0px"}`}}
                                onChange={(e) =>
                                    this.handleSelectAllClick(e)}
                                disabled={this.props.scenario === 'aiMarketplaceDataSelection'}
                            />
                        }
                    </TableCell>
                }
              {currentModelType === "forecasting" ?
              <TableCell classes={{root: classes.forecastingTableCellName}} className={css.dataTableHeadCell} style={{width:"100px"}}></TableCell>
              :
                  <TableCell classes={{root: classes.tableCellName}} className={css.dataTableHeadCell}>
                  <Box style={{display: 'flex', justifyContent: 'space-between',marginTop:`${currentModelType==="bookTranslation"?"-10px":"0px"}`}}>
                
                 {  currentModelType==="multipleImageAnnotation"?<span style={{margin:"auto"}}></span>: 
                    (this.props.scenario === "dataCart" || this.props.scenario === "savedForLater") ?
                     <span style={{marginLeft: '50px'}}>
                        {
                            currentModelType ==="bookTranslation"?
                            "Paragraph Number"
                            : currentModelType === "videoAnnotation" 
                            ? "" 
                            :
                            currentModelType === "text" || currentModelType === "textAnnotation" || currentModelType === "DLP"
                            ? "Text"
                            : currentModelType === "textFile"
                                ? "Text File" :
                                currentModelType === "prompt" 
                                ? "Prompt"
                                : "Name"
                        }
                     </span>
                     :
                     currentModelType === "textComparison"?
                     <div style={{ 
                        display: "flex", 
                        flexDirection: "column", 
                        justifyContent: "center",
                        alignItems: "center",
                    }}>{
                        (this.state.textOneHeadingForTextComparisonUI || "Text 1 Head undefined")}
                     </div>
                    :<TableSortLabel
                        hideSortIcon={this.props.scenario === 'aiMarketPlaceDataCart'}
                        active={this.state.sort.field === 'resource' || this.state.sort.field === 'prompt'}
                        direction={
                            this.state.sort.field === 'resource' || this.state.sort.field === 'prompt'
                            ? this.state.sort.order === 'ascending' ? 'asc' : 'desc'
                            : 'asc'
                        }
                      onClick={(e)=>{
                            this.handleSortLabelClick(currentModelType === "prompt" ? "prompt" : 'resource')
                        }}
                        classes={{
                            icon: classes.sortLabelIcon,
                            root: classes.sortLabelRoot,
                            active: classes.sortLabelActive
                        }}
                        className={css.tableSortLabel}
                    >
                        {
                            currentModelType ==="bookTranslation"?
                            "Paragraph Number"
                            : currentModelType === "videoAnnotation" 
                            ? "" 
                            :
                            currentModelType === "text" || currentModelType === "textAnnotation" || currentModelType === "DLP"
                            ? "Text"
                            : currentModelType === "textFile"
                                ? "Text File" :
                                currentModelType === "prompt" 
                                ? "Prompt"
                                : "Name"
                        }
                    </TableSortLabel>}
                    {
                        currentModelType !== "textComparison" &&
                        this.props.scenario !== "dataCart" && this.props.scenario !== "savedForLater" && this.props.scenario !== "aiMarketPlaceDataCart"
                        &&
                        <Box>
                            {
                            currentModelType==="multipleImageAnnotation"?"":
                                <IconButton
                            aria-label="more"
                            aria-controls="long-menu"
                            aria-haspopup="true"
                            id="nameColumnOptions"
                            classes={{
                                root: classes.columnOptionsIcon
                            }}
                            onClick={()=>{
                                this.setState(oldState=>{
                                    if(currentModelType==="prompt")
                                    {
                                        console.log()
                                        return {
                                            ...oldState,
                                            filter: {
                                                ...oldState.filter,
                                                resourcePrompt: {
                                                    ...oldState.filter.resourcePrompt,
                                                    menuOpen: true
                                                }
                                            }
                                        }
                                    }
                                    else{
                                        return {
                                            ...oldState,
                                            filter: {
                                                ...oldState.filter,
                                                resourceName: {
                                                    ...oldState.filter.resourceName,
                                                    menuOpen: true
                                                }
                                            }
                                        }
                                    }
                                    
                                })
                            }}
                        >
                            {
                                (this.isNameFilterApplied()||(currentModelType==="prompt"&&this.isPromptFilterApplied()))
                                ?   (
                                    <Tooltip title={`Filter applied on ${
                                        currentModelType === "text" || currentModelType === "textAnnotation" || currentModelType === "DLP" 
                                            ? "text" 
                                                :   currentModelType === "textFile"
                                                    ?   "text file":
                                                    // @ts-ignore
                                                    currentModelType === "prompt" 
                                                    ? "prompt" : currentModelType ==="bookTranslation"?"Paragraph": "name"
                                        }`}>
                                        <FilterListIcon />
                                    </Tooltip>
                                )
                                :   <MoreVertIcon />
                            }
                            
                        </IconButton>
                            }
                            {
                                (this.state.filter.resourceName.menuOpen||(currentModelType==="prompt"&&this.state.filter.resourcePrompt.menuOpen)) &&
                                <NameColumnMenu
                                    open={currentModelType==="prompt"?this.state.filter.resourcePrompt.menuOpen:this.state.filter.resourceName.menuOpen}
                                    onClose={() => {
                                        if(currentModelType==='prompt')
                                        return this.handleResourcePromptColumnMenuClose();
                                        else
                                        return this.handleResourceNameColumnMenuClose()
                                    }}
                                    searchValue={currentModelType==="prompt"?this.state.filter.resourcePrompt.searchValue:this.state.filter.resourceName.searchValue}
                                    resetFilters={() => {
                                        if(currentModelType=="prompt")
                                        {
                                            this.resetPromptFilters()
                                            .then(() => this.handlePromptApplyFilterButtonClick())
                                        }
                                        else{
                                            this.resetNameFilters()
                                            .then(() => this.handleNameApplyFilterButtonClick())
                                        }
                                        
                                        
                                    }}
                                    classes={classes}
                                    modelType={currentModelType}
                                    searchOperator={currentModelType==="prompt"?this.state.filter.resourcePrompt.searchOperator:this.state.filter.resourceName.searchOperator}
                                    includeOnlyMispredictedParagraphs={this.state.filter.resourceName.includeOnlyMispredictedParagraphs}
                                    excludeMispredictedParagraphs={this.state.filter.resourceName.excludeMispredictedParagraphs}
                                    isFilterApplied={currentModelType==="prompt"? this.isPromptFilterApplied()? true: false: this.isNameFilterApplied() ? true : false}
                                    onApplyFilterButtonClick={() =>{ 
                                        if(currentModelType==="prompt")
                                        {
                                            return this.handlePromptApplyFilterButtonClick();
                                        }
                                        else
                                        return this.handleNameApplyFilterButtonClick()}
                                    } 
                                    setFilterSearchValue={(value)=>{
                                        if(currentModelType==="prompt"){
                                            return this.setPromptFilterSearchValue(value)
                                        }
                                        else
                                        return this.setNameFilterSearchValue(value)
                                    }}                            
                                    setFilterSearchOperator={(value)=>{
                                        if(currentModelType==="prompt")
                                        return this.setPromptFilterSearchOperator(value)
                                        else
                                        return this.setNameFilterSearchOperator(value)}} 
                                    setNameFilterIncludeOnlyMispredictedParagraphsValue={(value)=>this.setNameFilterIncludeOnlyMispredictedParagraphsValue(value)} 
                                    setNameFilterExcludeMispredictedParagraphsValue={(value)=>this.setNameFilterExcludeMispredictedParagraphsValue(value)}                          
                                />
                            } 

                        </Box> 
                    }
                  </Box>
              </TableCell>}              
              { currentModelType !== "bookTranslation" && this.props.scenario !== "dataCart" && this.props.scenario !== "savedForLater" && this.props.scenario !== "aiMarketplaceDataSelection" ? 
              <TableCell classes={{root: `${currentModelType === "forecasting" ? classes.tableCellLabelForForcastingModel : classes.tableCellLabel}`}}>
              <Box style={{display: 'flex', justifyContent: 'space-between'}}>
                    <div></div>
                    <TableSortLabel
                        active={this.state.sort.field === 'label'}
                        direction={
                            this.state.sort.field === 'label'
                            ? this.state.sort.order === 'ascending' ? 'asc' : 'desc'
                            : 'asc'
                        }
                        onClick={(e)=>{
                            this.handleSortLabelClick('label')
                        }}
                        classes={{
                            icon: classes.sortLabelIcon,
                            root: classes.sortLabelRoot,
                            active: classes.sortLabelActive
                        }}
                        hideSortIcon={this.props.scenario === 'aiMarketPlaceDataCart'}
                    >
                        Label
                    </TableSortLabel>
                    <Box>
                        {
                            this.props.scenario !== 'aiMarketPlaceDataCart'
                            &&
                        <IconButton
                            aria-label="more"
                            aria-controls="long-menu"
                            aria-haspopup="true"
                            id="labelColumnOptions"
                            classes={{
                                root: classes.columnOptionsIcon
                            }}                            
                            onClick={()=>{
                                this.setState(oldState=>{
                                    return {
                                        ...oldState,
                                        filter: {
                                            ...oldState.filter,
                                            resourceLabel: {
                                                ...oldState.filter.resourceLabel,
                                                menuOpen: true
                                            }
                                        }
                                    }
                                })
                            }}
                        >
                            {
                                this.isLabelFilterApplied()
                                ?   (
                                    <Tooltip title="Filter applied on label">
                                        <FilterListIcon />
                                    </Tooltip>
                                )
                                :   <MoreVertIcon />
                            }
                            
                        </IconButton>  
                        }
                        {
                            this.state.filter.resourceLabel.menuOpen &&
                            <LabelColumnMenu 
                                open={this.state.filter.resourceLabel.menuOpen}
                                onClose={() => this.handleResourceLabelColumnMenuClose()}
                                labelSearchValue={this.state.filter.resourceLabel.searchValue}
                                resetLabelFilters={() => {
                                    this.resetLabelFilters()
                                    .then(() => this.handleLabelApplyFilterButtonClick());
                                } }
                                predictionStatusLogic={this.state.predictionStatusLogic}
                                classes={classes}
                                labelSearchOperator={this.state.filter.resourceLabel.searchOperator}
                                isLabelFilterApplied={this.isLabelFilterApplied() ? true : false}
                                onLabelApplyFilterButtonClick={() => this.handleLabelApplyFilterButtonClick()}
                                setLabelFilterSearchValue={(value) => this.setLabelFilterSearchValue(value)}
                                setLabelFilterSearchOperator={(value) => this.setLabelFilterSearchOperator(value)} 
                                includeOnlyMispredictedRowsValue={this.state.filter.resourceLabel.includeOnlyMispredictedRows}                            
                                setLabelFilterIncludeOnlyMispredictedRowsValue={(value) => this.setLabelFilterIncludeOnlyMispredictedRowsValue(value)}
                                predictionSearchValue={this.state.filter.resourceLabel.predictionSearchValue}
                                predictionSearchOperator={this.state.filter.resourceLabel.predictionSearchOperator}
                                setPredictionFilterSearchValue={(value) => this.setLabelFilterPredictionSearchValue(value)}
                                setPredictionFilterSearchOperator={(value) => this.setLabelFilterPredictionSearchOperator(value)}
                            />
                        }
                    </Box> 
                  </Box>                  
              </TableCell> : null }  
              {
                  !((this.props.scenario === "dataSetCollectionTableDetailsPopup" || this.props.scenario === "browseCollectionsPopup" || this.props.scenario === "aiMarketPlaceDataCart" || this.props.scenario === "aiMarketplaceDataSelection") && currentModelType === "forecasting") && (currentModelType !== "videoAnnotation") &&
                <TableCell classes={{root: classes.tableCellConfidenceScore}} className={css.dataTableHeadCell} style={{width: currentModelType === "forecasting" ? "170px" : this.props.isSideDrawerOpen ? "auto" : "199px" }}>
                    {
                        this.props.scenario === "dataCart" || this.props.scenario === "savedForLater" ?
                        <Box style={{display: 'flex', justifyContent: 'center'}}>
                            Confidence Score
                        </Box>
                        :
                        <Box style={{display: 'flex', justifyContent: 'space-between'}}>
                        <div></div>
                        <TableSortLabel
                            active={this.state.sort.field === 'confidence_score'}
                            direction={
                                this.state.sort.field === 'confidence_score'
                                ? this.state.sort.order === 'ascending' ? 'asc' : 'desc'
                                : 'asc'
                            }
                            onClick={(e)=>{
                                this.handleSortLabelClick('confidence_score')
                            }}
                            classes={{
                                icon: classes.sortLabelIcon,
                                root: classes.sortLabelRoot,
                                active: classes.sortLabelActive
                            }}
                            className={`${css.tableSortLabel} ${css.confidenceScoreTableSortLabel}`}
                            hideSortIcon={this.props.scenario === 'aiMarketPlaceDataCart'}
                        >
                            { currentModelType === "bookTranslation"? <p className={currentModelType==="bookTranslation"? css.bookTranslationModelHeadingP: ""}>Confidence Score<br/>(Avg)</p>: "Confidence Score"}
                        </TableSortLabel>
                        <Box>
                        {
                        this.props.scenario !== 'aiMarketPlaceDataCart'
                        &&
                        <IconButton
                            className={currentModelType==="bookTranslation"?css.bookTranslationTableHeadMoreButton:""}
                                aria-label="more"
                                aria-controls="long-menu"
                                aria-haspopup="true"
                                id="confidenceScoreColumnOptions"
                                classes={{
                                    root: classes.columnOptionsIcon
                                }}                            
                                onClick={()=>{
                                    this.setState(oldState=>{
                                        return {
                                            ...oldState,
                                            filter: {
                                                ...oldState.filter,
                                                resourceConfidenceScore: {
                                                    ...oldState.filter.resourceConfidenceScore,
                                                    menuOpen: true
                                                }
                                            }
                                        }
                                    })
                                }}
                            >
                                {
                                    this.isConfidenceScoreFilterApplied()
                                    ?   (
                                        <Tooltip title="Filter applied on confidence score">
                                            <FilterListIcon />
                                        </Tooltip>
                                    )
                                    :   <MoreVertIcon />
                                }
                                
                            </IconButton>
                        }
                            {
                                this.state.filter.resourceConfidenceScore.menuOpen &&
                                <ConfidenceScoreColumnMenu
                                    open={this.state.filter.resourceConfidenceScore.menuOpen}
                                    onClose={() => this.handleResourceConfidenceScoreColumnMenuClose()}
                                    minValue={this.state.filter.resourceConfidenceScore.minValue}
                                    maxValue={this.state.filter.resourceConfidenceScore.maxValue}
                                    resetFilters={() => {
                                        this.resetConfidenceScoreFilters()
                                        .then(()=>this.handleResourceConfidenceScoreApplyFilterButtonClick())
                                    }}
                                    classes={classes}
                                    isFilterApplied={this.isConfidenceScoreFilterApplied() ? true : false}
                                    onApplyFilterButtonClick={() => this.handleResourceConfidenceScoreApplyFilterButtonClick()} 
                                    setFilterMinMaxValue={(value)=>this.setConfidenceScoreFilterMinMaxValue(value)}                            
                                />
                            }                          
                        </Box> 
                    </Box>
                    }
                                      
                </TableCell>  
              }
              {
                  currentModelType === "bookTranslation"&&
                  <TableCell classes={{root: classes.tableCellName}} className={css.dataTableHeadCell}>
                  <Box style={{display: 'flex', justifyContent: 'space-between'}}>
                    <div></div>
                    {/* <TableSortLabel
                        // active={this.state.sort.field === 'resource' || this.state.sort.field === 'prompt'}
                        // direction={
                        //     this.state.sort.field === 'resource' || this.state.sort.field === 'prompt'
                        //     ? this.state.sort.order === 'ascending' ? 'asc' : 'desc'
                        //     : 'asc'
                        // }
                        // onClick={(e)=>{
                        //     this.handleSortLabelClick(currentModelType === "prompt" ? "prompt" : 'resource')
                        // }}
                        classes={{
                            icon: classes.sortLabelIcon,
                            root: classes.sortLabelRoot,
                            active: classes.sortLabelActive
                        }}
                    >
                    </TableSortLabel> */}
                        Language
                    <Box>
                        {/* {
                            currentModelType !== "prompt" && currentModelType!== "bookTranslation" &&
                            <IconButton
                                aria-label="more"
                                aria-controls="long-menu"
                                aria-haspopup="true"
                                id="nameColumnOptions"
                                classes={{
                                    root: classes.columnOptionsIcon
                                }}
                                onClick={()=>{
                                    this.setState(oldState=>{
                                        return {
                                            ...oldState,
                                            filter: {
                                                ...oldState.filter,
                                                resourceName: {
                                                    ...oldState.filter.resourceName,
                                                    menuOpen: true
                                                }
                                            }
                                        }
                                    })
                                }}
                            >
                                {
                                    this.isNameFilterApplied()
                                    ?   (
                                        <Tooltip title={`Filter applied on ${
                                            currentModelType === "text" || currentModelType === "textAnnotation" || currentModelType === "DLP" 
                                                ? "text" 
                                                    :   currentModelType === "textFile"
                                                        ?   "text file":
                                                        // @ts-ignore
                                                        currentModelType === "prompt" 
                                                        ? "prompt"
                                                        : "name"
                                            }`}>
                                            <FilterListIcon />
                                        </Tooltip>
                                    )
                                    :   <MoreVertIcon />
                                }
                                
                            </IconButton>  
                        } */}
                        {/* {
                            this.state.filter.resourceName.menuOpen &&
                            <NameColumnMenu
                                open={this.state.filter.resourceName.menuOpen}
                                onClose={() => this.handleResourceNameColumnMenuClose()}
                                searchValue={this.state.filter.resourceName.searchValue}
                                resetFilters={() => {
                                    this.resetNameFilters()
                                    .then(()=>this.handleNameApplyFilterButtonClick())
                                }}
                                classes={classes}
                                modelType={currentModelType}
                                searchOperator={this.state.filter.resourceName.searchOperator}
                                isFilterApplied={this.isNameFilterApplied() ? true : false}
                                onApplyFilterButtonClick={() => this.handleNameApplyFilterButtonClick()} 
                                setFilterSearchValue={(value)=>this.setNameFilterSearchValue(value)}                            
                                setFilterSearchOperator={(value)=>this.setNameFilterSearchOperator(value)}                            
                            />
                        }  */}

                    </Box> 
                  </Box>
              </TableCell>  
              }           
              {currentModelType !== "forecasting" &&           
              <TableCell classes={{root: classes.tableCellTags}} className={css.dataTableHeadCell}>
                {
                    this.props.scenario === "dataCart" || this.props.scenario === "savedForLater" || this.props.scenario === 'aiMarketPlaceDataCart' ?
                    <Box className={css.tagTableHeadCellBox} style={{display: 'flex', justifyContent: 'center'}}>
                        Tag
                    </Box>
                    :
              <Box className={css.tagTableHeadCellBox} style={{display: 'flex', justifyContent: 'space-between'}}>
                    <div></div>
                    <TableSortLabel
                    
                        active={this.state.sort.field === 'tag'}
                        direction={
                            this.state.sort.field === 'tag'
                            ? this.state.sort.order === 'ascending' ? 'asc' : 'desc'
                            : 'asc'
                        }
                        onClick={(e)=>{
                            this.handleSortLabelClick('tag')
                        }}
                        classes={{
                            icon: classes.sortLabelIcon,
                            root: classes.sortLabelRoot,
                            active: classes.sortLabelActive
                        }}
                        className={css.tableSortLabel}
                    >
                        Tag
                    </TableSortLabel>
                    <Box>
                        <IconButton
                        className={currentModelType==="bookTranslation"?css.bookTranslationTableHeadMoreButton:""}
                            aria-label="more"
                            aria-controls="long-menu"
                            aria-haspopup="true"
                            id="tagColumnOptions"
                            classes={{
                                root: classes.columnOptionsIcon
                            }}                            
                            onClick={()=>{
                                this.setState(oldState=>{
                                    return {
                                        ...oldState,
                                        filter: {
                                            ...oldState.filter,
                                            resourceTag: {
                                                ...oldState.filter.resourceTag,
                                                menuOpen: true
                                            }
                                        }
                                    }
                                })
                            }}
                        >
                            {
                                this.isTagFilterApplied()
                                ?   (
                                    <Tooltip title="Filter applied on tag">
                                        <FilterListIcon />
                                    </Tooltip>
                                )
                                :   <MoreVertIcon />
                            }
                            
                        </IconButton>
                        {
                            this.state.filter.resourceTag.menuOpen &&
                            <TagColumnMenu
                                open={this.state.filter.resourceTag.menuOpen}
                                onClose={() => this.handleResourceTagColumnMenuClose()}
                                tagSearchValue={this.state.filter.resourceTag.searchValue}
                                resetTagFilters={() => {
                                    this.resetTagFilters()
                                    .then(()=>this.handleTagApplyFilterButtonClick())
                                }}
                                classes={classes}
                                uniqueResourceTagsAvailable={this.state.uniqueResourceTagsAvailable}
                                tagSearchOperator={this.state.filter.resourceTag.searchOperator}
                                isTagFilterApplied={this.isTagFilterApplied() ? true : false}
                                onTagApplyFilterButtonClick={() => this.handleTagApplyFilterButtonClick()} 
                                setTagFilterSearchValue={(value)=>this.setTagFilterSearchValue(value)}                            
                                setTagFilterSearchOperator={(value)=>this.setTagFilterSearchOperator(value)}
                                fetchAndSetUniqueResourceTagsForStatus={(value) => this.fetchAndSetUniqueResourceTagsForStatus(value)}                       
                            />
                        }  
                    </Box>
                  </Box>
                }
                </TableCell> 
                }             
              { currentModelType !== "forecasting" && this.props.scenario !== "dataSetCollectionTableDetailsPopup" && this.props.scenario !== "browseCollectionsPopup" && this.props.scenario !== "aiMarketPlaceDataCart" && this.props.scenario !== "aiMarketplaceDataSelection" &&
              <TableCell  classes={{root: classes.tableCellLastModified }} className={css.dataTableHeadCell}>
                {
                    this.props.scenario === "dataCart" || this.props.scenario === "savedForLater" ?
                    <Box className={css.tagTableHeadCellBox} style={{display: 'flex', justifyContent: 'center', verticalAlign: "top"}}>
                        Last Modified
                    </Box>
                    :
              <Box style={{display: 'flex', justifyContent: 'space-between', verticalAlign: "top"}}>
                    <div></div>
                    <TableSortLabel className={`${currentModelType==="bookTranslation"?css.bookTranslationLastModifiedHeading: ""} ${css.tableSortLabel}`}
                        active={this.state.sort.field === 'statusLastModifiedAt'}
                        direction={
                            this.state.sort.field === 'statusLastModifiedAt'
                            ? this.state.sort.order === 'ascending' ? 'asc' : 'desc'
                            : 'asc'
                        }
                        onClick={(e)=>{
                            this.handleSortLabelClick('statusLastModifiedAt')
                        }}
                        classes={{
                            icon: classes.sortLabelIcon,
                            root: classes.sortLabelRoot,
                            active: classes.sortLabelActive
                        }}
                    >
                        Last Modified
                    </TableSortLabel>
                    <Box>
                        <IconButton
                            aria-label="more"
                            aria-controls="long-menu"
                            aria-haspopup="true"
                            id="createdDateColumnOptions"
                            classes={{
                                root: classes.columnOptionsIcon
                            }}
                            
                            className={currentModelType==="bookTranslation"?css.bookTranslationTableHeadMoreButton:""}
                            onClick={()=>{
                                this.setState(oldState=>{
                                    return {
                                        ...oldState,
                                        filter: {
                                            ...oldState.filter,
                                            resourceStatusLastModifiedDate: {
                                                ...oldState.filter.resourceStatusLastModifiedDate,
                                                menuOpen: true
                                            }
                                        }
                                    }
                                })
                            }}
                        >
                            {
                                this.isResourceStatusLastModifiedDateFilterApplied()
                                ?   (
                                    <Tooltip title="Filter applied on date">
                                        <FilterListIcon />
                                    </Tooltip>
                                )
                                :   <MoreVertIcon />
                            }
                            
                        </IconButton>  
                        {
                            this.state.filter.resourceStatusLastModifiedDate.menuOpen &&
                            <CreatedDateColumnMenu
                                open={this.state.filter.resourceStatusLastModifiedDate.menuOpen}
                                onClose={() => this.handleResourceCreatedDateColumnMenuClose()}
                                minValue={this.state.filter.resourceStatusLastModifiedDate.minValue}
                                maxValue={this.state.filter.resourceStatusLastModifiedDate.maxValue}
                                resetFilters={() => {
                                    this.resetResourceStatusLastModifiedDateFilters()
                                    .then(()=>this.handleResourceCreatedDateApplyFilterButtonClick())
                                }}
                                classes={classes}
                                isFilterApplied={this.isResourceStatusLastModifiedDateFilterApplied() ? true : false}
                                onApplyFilterButtonClick={() => this.handleResourceCreatedDateApplyFilterButtonClick()} 
                                setFilterMinMaxValue={(value)=>this.setCreatedDateFilterMinMaxValue(value)}                            
                            />
                        }                        
                        {/* <Menu
                            id="createdDate-column-menu"
                            anchorEl={document.getElementById("createdDateColumnOptions")}
                            keepMounted
                            open={this.state.filter.resourceCreatedDate.menuOpen}
                            onClose={()=>{
                                this.handleResourceCreatedDateColumnMenuClose();
                            }}
                            PaperProps={{
                                style: {
                                    width: '450px',
                                },
                            }}
                        >
                                <Box id="dateMenuItemContainer"
                                    style={{padding: 15}}
                                >
                                    <Typography variant="body1" style={{marginBottom: 20}} color="primary">&#8288;Date Column Filter</Typography>
                                    <TextField
                                        label="Date from"
                                        type="datetime-local"
                                        fullWidth={true}
                                        value={this.state.filter.resourceCreatedDate.minValue}
                                        InputLabelProps={{
                                            shrink: true
                                        }}
                                        onChange={(e) => {
                                            this.setState(oldState=>{
                                                return {
                                                    ...oldState,
                                                    filter: {
                                                        ...oldState.filter,
                                                        resourceCreatedDate: {
                                                            ...oldState.filter.resourceCreatedDate,
                                                            minValue: e.target.value
                                                        }
                                                    }
                                                }
                                            }) 
                                        }}
                                    />         
                                    <div
                                        style={{marginTop: "15px"}}
                                    >
                                        <TextField
                                            label="Date to"
                                            type="datetime-local"
                                            fullWidth={true}
                                            value={this.state.filter.resourceCreatedDate.maxValue}
                                            InputLabelProps={{
                                                shrink: true
                                            }}
                                            onChange={(e) => {
                                                this.setState(oldState=>{
                                                    return {
                                                        ...oldState,
                                                        filter: {
                                                            ...oldState.filter,
                                                            resourceCreatedDate: {
                                                                ...oldState.filter.resourceCreatedDate,
                                                                maxValue: e.target.value
                                                            }
                                                        }
                                                    }
                                                }) 
                                            }}
                                        />                                            
                                    </div>  

                                    <div id="resetFilterButtonContainer"
                                    style={{marginTop: '40px', textAlign: 'right'}}
                                    >
                                        <Button variant="contained" color="primary"
                                            onClick={()=>{
                                                this.resetDateFilters();
                                            }}
                                            disabled={!this.isDateFilterApplied()}
                                        >
                                            Reset Filters
                                        </Button>
                                    </div>
                                </Box>
                        </Menu>                                 */}
                    </Box> 
                  </Box> 
                }                  
                </TableCell>}  

                {
                  currentModelType === "videoAnnotation" && 
                  <TableCell classes={{root: classes.detectedAndExpertField}}>
                    <Box>
                      Detected
                    </Box>
                  </TableCell>
                }
                {
                  currentModelType === "videoAnnotation" && 
                  <TableCell classes={{root: classes.detectedAndExpertField}}>
                    <Box>
                      Expert
                    </Box>
                  </TableCell>
                }
                {currentModelType === "forecasting" &&
                    // <TableCell  style={{width: "10%"}}>
                    // Forcasted At
                    // </TableCell>

<TableCell 
    classes={{
        root: `
            ${classes.tableCellDate}
            ${classes.tableCellForcastedDate}
            ${(this.props.scenario === "dataSetCollectionTableDetailsPopup" || this.props.scenario === "browseCollectionsPopup" || this.props.scenario === "aiMarketPlaceDataCart") ? classes.tableCellForcastedDateWhenScenarioDataSetCollectionTableDetailsPopup : ""}
        `
    }} 
>
<Box style={{display: 'flex', justifyContent: 'space-between'}}>
      <div></div>
      <TableSortLabel
          active={this.state.sort.field === 'forecastDateTime'}
          direction={
              this.state.sort.field === 'forecastDateTime'
              ? this.state.sort.order === 'ascending' ? 'asc' : 'desc'
              : 'asc'
          }
          onClick={(e)=>{
              this.handleSortLabelClick('forecastDateTime')
          }}
          classes={{
              icon: classes.sortLabelIcon,
              root: classes.sortLabelRoot,
              active: classes.sortLabelActive
          }}
      >
          Forcasted At
      </TableSortLabel>
      <Box>
          <IconButton
              aria-label="more"
              aria-controls="long-menu"
              aria-haspopup="true"
              id="forecastDateColumnOptions"
              classes={{
                  root: classes.columnOptionsIcon
              }}                            
              onClick={()=>{
                  this.setState(oldState=>{
                      return {
                          ...oldState,
                          filter: {
                              ...oldState.filter,
                              forecastDate: {
                                  ...oldState.filter.forecastDate,
                                  menuOpen: true
                              }
                          }
                      }
                  })
              }}
          >
              {
                  this.isForecastDateFilterApplied()
                  ?   (
                      <Tooltip title="Filter applied on forecast date">
                          <FilterListIcon />
                      </Tooltip>
                  )
                  :   <MoreVertIcon />
              }
              
          </IconButton>  
          {
              this.state.filter.forecastDate.menuOpen &&
              <CreatedDateColumnMenu
                  open={this.state.filter.forecastDate.menuOpen}
                  onClose={() => this.handleForecastDateColumnMenuClose()}
                  minValue={this.state.filter.forecastDate.minValue}
                  maxValue={this.state.filter.forecastDate.maxValue}
                  scenario="forecastDateFilter"
                  anchorElId="forecastDateColumnOptions"
                  resetFilters={() => {
                      this.resetForecastDateFilters()
                      .then(()=>this.handleForecastDateApplyFilterButtonClick())
                  }}
                  classes={classes}
                  isFilterApplied={this.isForecastDateFilterApplied() ? true : false}
                  onApplyFilterButtonClick={() => this.handleForecastDateApplyFilterButtonClick()} 
                  setFilterMinMaxValue={(value)=>this.setForecastDateFilterMinMaxValue(value)}                            
              />
          }                        
          {/* <Menu
              id="createdDate-column-menu"
              anchorEl={document.getElementById("createdDateColumnOptions")}
              keepMounted
              open={this.state.filter.resourceCreatedDate.menuOpen}
              onClose={()=>{
                  this.handleResourceCreatedDateColumnMenuClose();
              }}
              PaperProps={{
                  style: {
                      width: '450px',
                  },
              }}
          >
                  <Box id="dateMenuItemContainer"
                      style={{padding: 15}}
                  >
                      <Typography variant="body1" style={{marginBottom: 20}} color="primary">&#8288;Date Column Filter</Typography>
                      <TextField
                          label="Date from"
                          type="datetime-local"
                          fullWidth={true}
                          value={this.state.filter.resourceCreatedDate.minValue}
                          InputLabelProps={{
                              shrink: true
                          }}
                          onChange={(e) => {
                              this.setState(oldState=>{
                                  return {
                                      ...oldState,
                                      filter: {
                                          ...oldState.filter,
                                          resourceCreatedDate: {
                                              ...oldState.filter.resourceCreatedDate,
                                              minValue: e.target.value
                                          }
                                      }
                                  }
                              }) 
                          }}
                      />         
                      <div
                          style={{marginTop: "15px"}}
                      >
                          <TextField
                              label="Date to"
                              type="datetime-local"
                              fullWidth={true}
                              value={this.state.filter.resourceCreatedDate.maxValue}
                              InputLabelProps={{
                                  shrink: true
                              }}
                              onChange={(e) => {
                                  this.setState(oldState=>{
                                      return {
                                          ...oldState,
                                          filter: {
                                              ...oldState.filter,
                                              resourceCreatedDate: {
                                                  ...oldState.filter.resourceCreatedDate,
                                                  maxValue: e.target.value
                                              }
                                          }
                                      }
                                  }) 
                              }}
                          />                                            
                      </div>  

                      <div id="resetFilterButtonContainer"
                      style={{marginTop: '40px', textAlign: 'right'}}
                      >
                          <Button variant="contained" color="primary"
                              onClick={()=>{
                                  this.resetDateFilters();
                              }}
                              disabled={!this.isDateFilterApplied()}
                          >
                              Reset Filters
                          </Button>
                      </div>
                  </Box>
          </Menu>                                 */}
      </Box> 
    </Box>                   
  </TableCell>

                }
                {currentModelType === "forecasting" &&
                    <TableCell 
                        className={`
                            ${classes.dataCount}
                            ${(this.props.scenario === "dataSetCollectionTableDetailsPopup" || this.props.scenario === "browseCollectionsPopup" || this.props.scenario === "aiMarketPlaceDataCart" || this.props.scenario === "aiMarketplaceDataSelection") ? classes.tableCellForcastPredictedCountWhenScenarioDataSetCollectionTableDetailsPopup : ""}
                        `}
                    >
                    Predicted
                    </TableCell>}
                {currentModelType === "forecasting" &&
                    <TableCell  
                        className={`
                            ${classes.dataCount}
                            ${(this.props.scenario === "dataSetCollectionTableDetailsPopup" || this.props.scenario === "browseCollectionsPopup" || this.props.scenario === "aiMarketPlaceDataCart" || this.props.scenario === "aiMarketplaceDataSelection") ? classes.tableCellCvAiCountWhenScenarioDataSetCollectionTableDetailsPopup : ""}
                        `}
                    >
                    CV_AI
                    </TableCell>}
                {currentModelType === "forecasting" &&
                    <TableCell  
                        className={`
                            ${classes.dataCount}
                            ${(this.props.scenario === "dataSetCollectionTableDetailsPopup" || this.props.scenario === "browseCollectionsPopup" || this.props.scenario === "aiMarketPlaceDataCart" || this.props.scenario === "aiMarketplaceDataSelection") ? classes.tableCellDataEntryCountWhenScenarioDataSetCollectionTableDetailsPopup : ""}
                        `}
                    >
                    Data Entry
                    </TableCell>}   
                    {currentModelType === "forecasting" && this.props.scenario !== "dataSetCollectionTableDetailsPopup" && this.props.scenario !== "browseCollectionsPopup" && this.props.scenario !== "aiMarketPlaceDataCart" && this.props.scenario !== 'aiMarketplaceDataSelection' &&
                        <TableCell classes={{root: classes.tableCellDateForForecastingModel}} style={{width: "11%" }}>
                <Box style={{display: 'flex', justifyContent: 'space-between'}}>
                    <div></div>
                    <TableSortLabel
                        active={this.state.sort.field === 'statusLastModifiedAt'}
                        direction={
                            this.state.sort.field === 'statusLastModifiedAt'
                            ? this.state.sort.order === 'ascending' ? 'asc' : 'desc'
                            : 'asc'
                        }
                        onClick={(e)=>{
                            this.handleSortLabelClick('statusLastModifiedAt')
                        }}
                        classes={{
                            icon: classes.sortLabelIcon,
                            root: classes.sortLabelRoot,
                            active: classes.sortLabelActive
                        }}
                    >
                        Last Modified
                    </TableSortLabel>
                    <Box>
                        <IconButton
                            aria-label="more"
                            aria-controls="long-menu"
                            aria-haspopup="true"
                            id="createdDateColumnOptions"
                            classes={{
                                root: classes.columnOptionsIcon
                            }}                            
                            onClick={()=>{
                                this.setState(oldState=>{
                                    return {
                                        ...oldState,
                                        filter: {
                                            ...oldState.filter,
                                            resourceStatusLastModifiedDate: {
                                                ...oldState.filter.resourceStatusLastModifiedDate,
                                                menuOpen: true
                                            }
                                        }
                                    }
                                })
                            }}
                        >
                            {
                                this.isResourceStatusLastModifiedDateFilterApplied()
                                ?   (
                                    <Tooltip title="Filter applied on date">
                                        <FilterListIcon />
                                    </Tooltip>
                                )
                                :   <MoreVertIcon />
                            }
                            
                        </IconButton>  
                        {
                            this.state.filter.resourceStatusLastModifiedDate.menuOpen &&
                            <CreatedDateColumnMenu
                                open={this.state.filter.resourceStatusLastModifiedDate.menuOpen}
                                onClose={() => this.handleResourceCreatedDateColumnMenuClose()}
                                minValue={this.state.filter.resourceStatusLastModifiedDate.minValue}
                                maxValue={this.state.filter.resourceStatusLastModifiedDate.maxValue}
                                resetFilters={() => {
                                    this.resetResourceStatusLastModifiedDateFilters()
                                    .then(()=>this.handleResourceCreatedDateApplyFilterButtonClick())
                                }}
                                classes={classes}
                                isFilterApplied={this.isResourceStatusLastModifiedDateFilterApplied() ? true : false}
                                onApplyFilterButtonClick={() => this.handleResourceCreatedDateApplyFilterButtonClick()} 
                                setFilterMinMaxValue={(value)=>this.setCreatedDateFilterMinMaxValue(value)}                            
                            />
                        }                        
                    </Box> 
                  </Box>                   
                </TableCell>}     
                {
                    this.props.scenario !== "dataSetCollectionTableDetailsPopup" && this.props.scenario !== "browseCollectionsPopup" && this.props.scenario !== "aiMarketPlaceDataCart" && this.props.scenario !== 'aiMarketplaceDataSelection' &&
                    // <TableCell style={{ width: currentModelType==="bookTranslation"? "200px": "", textAlign: currentModelType==="bookTranslation"? "left": "center"}} classes={{root: `${classes.tableCellActions} ${currentModelType === "forecasting" ? classes.tableCellActionsForForecastingModel : this.state.status === 'approved' ? classes.tableCellActionsWhenApprovedStatus : ''} ${currentModelType === "forecasting" ? classes.tableCellActionsForForecastingModel :  this.state.status === 'approval' ? classes.tableCellActionsWhenApprovalStatus : ''}  `}} className={css.dataTableHeadCell}>Actions</TableCell>  
                    <TableCell style={{ textAlign: "center", alignItems: this.props.scenario === "dataCart" || this.props.scenario === "savedForLater" ? 'center' : ''}} classes={{root: `${classes.tableCellActions} ${currentModelType === "forecasting" ? classes.tableCellActionsForForecastingModel : this.state.status === 'approved' ? classes.tableCellActionsWhenApprovedStatus : ''} ${currentModelType === "forecasting" ? classes.tableCellActionsForForecastingModel :  this.state.status === 'approval' ? classes.tableCellActionsWhenApprovalStatus : ''}  `}} className={css.dataTableHeadCell}>
                        Actions
                    </TableCell>  
                }
                {
                    this.props.scenario === "aiMarketPlaceDataCart"
                    &&
                    <TableCell classes={{root: classes.tableCellDataSource}}>
                        Status
                    </TableCell>
                }
                {
                    // table cell for showing actions in ai marketplace data cart scenario
                    this.props.scenario === "aiMarketPlaceDataCart"
                    &&
                    <TableCell classes={{root: classes.tableCellActionsInAiMarketplaceScenario}}>
                    </TableCell>
                }
              {(currentModelType === "forecasting") &&
                    <TableCell style={{width: "130px"}}>
                    </TableCell>}
                                {
                                    //@ts-ignore
                                    this.props.scenario === "browseCollectionsPopup"
                                    &&
                                    <TableCell style={{ width: '112px' }}>
                                        {
                                            this.props.isUsingDataCartForAiAssistedlabellingCodeTesting
                                            ? "Actions"
                                            : "Add to cart"
                                        }
                                    </TableCell>
                                }

                {(currentModelType === "audio" || currentModelType === "text" || currentModelType === "video" || currentModelType === "image" || currentModelType === "textAnnotation" || currentModelType === "DLP" || currentModelType === "imageAnnotation" || currentModelType==="multipleImageAnnotation" || currentModelType === "imageAnnotationGroup" || currentModelType === "textFile" || currentModelType === "prompt" || currentModelType === 'videoAnnotation' || currentModelType === "bookTranslation" || currentModelType === "textComparison" || currentModelType === "audioTextTranscription") &&
                    <TableCell className={css.dataTableHeadCell} style={{width: "120px"}}>
                    </TableCell>}          
            </TableRow>
            {
                (
                    this.props.scenario !== 'aiMarketPlaceDataCart' &&
                    this.props.scenario !== 'aiMarketplaceDataSelection' &&
                    this.areAllRowsWhichIsShowingInUISelectedByUser(this.props.data.resources, this.props.selectedItems) &&
                    rowsPerPage < this.props.data.totalCount &&
                    this.props.data.resources.length>0
                )
                && 
                <TableRow classes={{root: classes.tableRow}}  style={{ background: 'white' }}>
                    <TableCell 
                        colSpan={
                            this.state.resourceTableHeadColumnsLength
                            // this.props.scenario === "dataSetCollectionTableDetailsPopup"
                            // ? 5
                            // : currentModelType === "forecasting" 
                            //     ? 11
                            //     : 7
                        } 
                        style={{
                            top: '61px', 
                            textAlign: 'center',
                            paddingTop: '10px',
                            paddingBottom: '10px'
                        }}
                    >
                        {
                            data?.totalCount > this.props.selectedItems.length && !this.state.allSelected && (
                                <>
                                    <span>All <b>{rowsPerPage}</b> data on this page are selected. </span>
                                    <span className={`${classes.selectAllDataText}`}
                                        onClick={()=>{
                                            // const { location } = this.props;
                                            this.setState(prevSate => {
                                                return {
                                                    ...prevSate,
                                                    allSelected: true
                                                }
                                            })
                                            // this.setAndGetData(
                                            //     {
                                            //         location, 
                                            //         userWantsToSelectAllResourcesMatchingThisFilterScenario: true
                                            //     }
                                            // )
                                        }}                                        
                                    >
                                        {`Select first ${this.getTotalResourcesGoingToBeAffectCount()} data matching this table filter`}
                                    </span>
                                </>
                            )
                        }
                        {
                            (data?.totalCount === this.props.selectedItems.length || this.state.allSelected) && (
                                <>
                                    <span>
                                        {data.totalCount < this.getTotalResourcesGoingToBeAffectCount() ? `All ${this.getTotalResourcesGoingToBeAffectCount()} data are selected. ` : `First ${this.getTotalResourcesGoingToBeAffectCount()} data are selected. `}
                                    </span>
                                    <span
                                        className={`${classes.clearDataSelectionText}`}
                                        onClick={()=>{
                                            this.props.setSelectedItems([]);
                                            this.setState(prevSate => {
                                                return {
                                                    ...prevSate,
                                                    allSelected: false
                                                }
                                            })
                                        }}
                                    >
                                        Clear selection
                                    </span>
                                </>
                            )
                        }
                        

                    </TableCell>
                </TableRow>
            }
          </TableHead>   
          {this.props.dataView === "grid-view" ? 
            <TableBody> 
                    <Grid id={this.state.resourcesGridViewContainerElementId} container spacing={4} style={{width: this.props.isSideDrawerOpen ? 'calc(100% + 60vmax)' : 'calc(100% + 88vmax)', 
                    paddingTop: '16px', paddingLeft: '16px', height: 'self.innerHeight', position:'relative'}} > 
                    {
                            (isFetching || isApiRequestCancelled) && new Array(rowsPerPage).fill('').map((item, index) =>
                        <Grid alignItems="center"
                        style={{margin: 0, maxWidth:'220px', padding: '4px'}}
                        item xs={6} sm={4} md={3} lg={2}
                        >
                            <Card className={classes.grid}>
                                <CardHeader
                                            classes={{ root: classes.tableCell}} 
                                            padding="checkbox"
                                            style ={{display : "flex", flexDirection:"row-reverse", width:"auto !important", paddingBottom: "8px"}}
                                            action={
                                                    <Checkbox
                                                        style={{paddingRight: "8px"}}
                                                    />
                                            }
                                            subheader = {<Grid container alignItems="center" spacing={1} style={{margin: 0, paddingLeft:"8px", width : "auto"}}>
                                                <Tooltip title=".">
                                                    <Typography paragraph>
                                                        .
                                                    </Typography>
                                                </Tooltip>
                                                </Grid>
                                                }
                                        />
                                <CardMedia
                                    //
                                />
                                <CardContent>
                                    <CustomSkeleton width="100%">
                                        <Typography variant="body2" color="textSecondary" component="p">
                                            .
                                        </Typography>
                                    </CustomSkeleton>
                                </CardContent>
                                <CardContent>
                                    <CustomSkeleton width="100%">
                                        <Typography variant="body2" color="textSecondary" component="p">
                                            .
                                        </Typography>
                                    </CustomSkeleton>
                                </CardContent>
                                <CardActions disableSpacing>
                                        <CustomSkeleton width="100%">
                                            <Chip></Chip>
                                        </CustomSkeleton>                                    
                                </CardActions>
                                </Card>
                            </Grid>
                        )}
                        {
                        // !isFetching && !isApiRequestCancelled && data.resources?.slice(0, data.resources.length)
                        // .filter(this.tableResourcesFilterCallback)
                        evaluateDataArray(isFetching, isApiRequestCancelled || false, data)
                        .map((row, index) => {
                            const isItemSelected = this.isSelected(row._id);
                            console.log(isItemSelected ,'455::');
                            
                            const labelId = `enhanced-table-checkbox-${index}`;

                            /*
                            const handleLabelEditSave = (e: onSaveProps, scenario: 'updatingLabel' | 'updatingLabelAndPrediction') => {
                                // if (e.previousValue.includes('->')) {
                                if (scenario === 'updatingLabel') {
                                    this.onUpdateLabelData1({ ids: [row._id], label: e.value });
                                } else {
                                    this.onUpdateLabelData({ ids: [row._id], label: e.value, prediction: e.previousValue });
                                }
                            };

                            const handleTagSave = (tagValueToSave: string) => {
                                this.onUpdateTagData({ids: [row._id], tag: tagValueToSave})
                            }
                            */
                            let labelsOfVideoAnnotationResourceThatAreNotApprovedOrRejectedByExpert : string[] = []
                            if(row.model.type === 'videoAnnotation') {
                                labelsOfVideoAnnotationResourceThatAreNotApprovedOrRejectedByExpert = getLabelsOfVideoAnnotationResourceThatAreNotApprovedOrRejectedByExpert(row?.videoAnnotations || [])
                            }

                            const isPredictionStatusLogicThroughDeviationAllowed = this.state.predictionStatusLogic === "labelAndErrorDeviation";

                            console.log("isPredictionStatusLogicThroughDeviationAllowed", isPredictionStatusLogicThroughDeviationAllowed)


                            return (
                                <Fade key={row._id} in={true} timeout={index * 250} mountOnEnter unmountOnExit>
                                    <Grid alignItems="center"
                                        //@ts-ignore
                                        style={{margin: 0,minWidth: (this.props.scenario === "browseCollectionsPopup" || this.props.scenario === "aiMarketPlaceDataCart" || this.props.scenario === 'aiMarketplaceDataSelection') ? '270px' : '228px', maxWidth: this.props.isSideDrawerOpen ? '230px' : '270px', padding: '4px'}}

                                        item xs={'auto'} sm={'auto'} md={4} lg={2}
                                    >
                                    <Card className={classes.grid}
                                        // hover
                                        role="checkbox"
                                        aria-checked={isItemSelected}
                                        tabIndex={-1}
                                        key={row._id}
                                        // className={row._id}
                                        id={`tableRow${row._id}`}
                                        data-tablerowindex={index}
                                        classes={{ root: classes.tableRow}}
                                        // selected={isItemSelected || (selected === row._id)}
                                        onClick={()=>{this.props.setSelectedData(row._id)}}
                                    >
                                        <CardHeader
                                            classes={{ root: classes.tableCell}} 
                                            padding="checkbox"
                                            style ={{display : "flex", flexDirection:"row-reverse", width:"auto !important", paddingBottom: "8px", position:'absolute'}}
                                        />
                                        <CardMedia
                                            classes={{ root: classes.tableCellName}} 
                                            style = {{padding:0, margin: 0, width:"auto"}}
                                            id={`tableCellName${index}`}
                                        >
                                            
                                            <Grid container alignItems="center" wrap="nowrap" spacing={1}
                                            style = {{justifyContent:"center", position: 'relative'}}
                                            >
                                                
                                                {console.log("The row.model.type is : " + row.model.type)}
                                                <Grid item>
                                                    <Checkbox
                                                            style={{paddingRight: "8px", backgroundColor: '#ffffff', position: 'absolute', top:'10px', left:'10px'}}
                                                            onClick={(event) => this.onCheck(event, row._id)}
                                                            checked={isItemSelected}
                                                            inputProps={{ 'aria-labelledby': labelId+'deepak' }}
                                                    />
                                                    {
                                                        row.model.type === 'text' || row.model.type === 'textAnnotation' || row.model.type === 'DLP' || row.model.type === 'prompt' ? 
                                                        (
                                                            <div style={{width: "100%", overflow: 'auto', height: '150px', wordBreak: 'break-word', marginTop: '40px'}}>
                                                                {row.model.type === "prompt" ? row.prompt : row.resource}
                                                            </div>
                                                        )
                                                        : row.model.type === "textFile" ?
                                                        (
                                                            <div
                                                                style={{
                                                                    display: "flex",
                                                                    flexDirection: "column",
                                                                    alignItems: "center",
                                                                    marginTop: "40px",
                                                                    marginBottom: "12px",
                                                                    rowGap: "10px"
                                                                }}
                                                            > 
                                                                <InsertDriveFileIcon color="primary" /> 
                                                                <Tooltip title={row.filename}>
                                                                    <div
                                                                        style={{
                                                                            whiteSpace: "nowrap",
                                                                            overflow: "hidden",
                                                                            textOverflow: "ellipsis",
                                                                            width: "187px",
                                                                            textAlign: "center"
                                                                        }}
                                                                    >{row.filename}</div> 

                                                                </Tooltip>
                                                            </div>
                                                        )
                                                        :
                                                        row.model.type === "bookTranslation"?
                                                        (
                                                            <div
                                                                style={{
                                                                    display: "flex",
                                                                    flexDirection: "column",
                                                                    alignItems: "center",
                                                                    marginTop: "40px",
                                                                    marginBottom: "12px",
                                                                    rowGap: "10px"
                                                                }}
                                                            > 
                                                                <Tooltip title={row.filename}>
                                                                    <BookTranslationModelParagraphRow addStyles={{
                                                                            whiteSpace: "nowrap",
                                                                            overflow: "hidden",
                                                                            textOverflow: "ellipsis",
                                                                            width: "210px",
                                                                            textAlign: "center",
                                                                            marginTop:"9px",
                                                                            paddingTop:"6px",
                                                                            height:"34px"
                                                                        }} resource={row}/>
                                                                </Tooltip>
                                                            </div>
                                                        )
                                                        :
                                                        row.model.type === 'audio'
                                                        // ?   <Avatar variant="rounded" className={classes.image} src={row.resource} /> 
                                                        ?   (
                                                            <div style={{paddingTop:'12px'}}>  
                                                                <Audio src={row.resource} />
                                                            </div>
                                                        )
                                                        :   row.model.type === 'video' || row.model.type === 'forecasting' || row.model.type === 'videoAnnotation'
                                                            ?   (
                                                                <>
                                                                <VideoSection 
                                                                    row={row} 
                                                                    index={index} 
                                                                    dataView='grid-view'
                                                                    parentScrollContainerElementId={this.state.resourcesTableContainerElementId}
                                                                    onVideoThumbnailClick={()=>{
                                                                        row.model.type === 'video' ?
                                                                            this.props.setSelectedData(row._id)
                                                                        :
                                                                        this.setState({...this.state, isViewForcastModelPopupOpen: true, index: index })
                                                                    }}
                                                                />
                                                                </>
                                                            )
                                                            :
                                                            <>
                                                                <ImageThumbnail index={index} classes={classes} 
                                                                dataView='grid-view'
                                                                parentScrollContainerElementId={this.state.resourcesTableContainerElementId}
                                                                resource={row} 
                                                                onThumbnailClick={()=>{
                                                                    this.props.setSelectedData(row._id);
                                                                }}
                                                            />
                                                            </>   
                                                            
                                                    }
                                                </Grid>
                                            </Grid>
                                        </CardMedia>
                                        <CardContent classes={{ root: classes.tableCellLabel}} style={{width : "auto", textAlign: "center", padding:"8px 16px"}}>
                                            <Box
                                                    style={{
                                                        width: '100%',
                                                    }}
                                                >
                                                    {row.model.type=== "bookTranslation"?(
                                                        <Tooltip title={row.textOriginalLanguage + "title"}>
                                                        <Chip style={{ width: "100%",  }} label={<p style={{ marginBottom: '0px' }}>{row.textOriginalLanguage} <ArrowForwardIcon style={{ height: "12px"}}/> {row.textTranslatedLanguage} </p>} classes={{ root: classes.chip }} />
                                                    </Tooltip>
                                                    ):
                                                    <LabelEditText 
                                                        readonly={
                                                            ["dataSetCollectionTableDetailsPopup", "browseCollectionsPopup", "aiMarketPlaceDataCart"].includes(this.props.scenario) ? true :
                                                            false && this.getSelectedSubTab() === 'approved'
                                                            ? true
                                                            : false                                                         
                                                        }
                                                        // labelsAvailable={this.state.labelsAvailable}
                                                        labelsAvailable={labelOptionsForARow(row)}
                                                        resource={row}
                                                        allowUserToSetAnyLabel={this.state.allowUserToSetAnyLabel}
                                                        onEditTextFocus={()=>{
                                                            this.props.setShouldBlockDataTableKeyboardEvents(true)
                                                        }}
                                                        onEditTextBlurEvent={()=>{
                                                            this.props.setShouldBlockDataTableKeyboardEvents(false)
                                                        }}
                                                        placeWhereComponentBeingUsed="data-table"
                                                    />}
                                                </Box>
                                        </CardContent>
                                        <CardContent classes={{root: classes.tableCellConfidenceScore}} style={{width : "auto", padding: '0px 16px'}}>

                                          {
                                            currentModelType !== "videoAnnotation" &&
                                            <Tooltip title={row.confidence_score + "%"}>
                                              <Chip style={{ width: "100%" }} label={row.confidence_score + "%"} classes={{ root: classes.chip }} />
                                            </Tooltip>
                                          }

                                        </CardContent>
                                        <CardActions classes={{ root: `${classes.tableCellActions} ${this.state.status === 'approved' ? classes.tionsWhenApprovedStatus : ''}` }}  style={{width : "auto", justifyContent: "center", padding: 0}}>
                                        {
                                        this.props.scenario !== 'aiMarketPlaceDataCart' && this.props.scenario !== 'aiMarketplaceDataSelection'
                                        &&
                                        <div className={classes.actions} style={{marginLeft: -12}}>
                                            {
                                                row.model.type!=="bookTranslation"&&
                                                <DataRowExpandIconButton
                                                    tooltip={
                                                        this.props.selected === row._id && this.props.isSideDrawerOpen
                                                        ? "Opened in detailed view"
                                                        : "Open in detailed view"
                                                    }
                                                    disabled={(row.model.type === "image" || row.model.type === 'video' || row.model.type === 'audio' || row.model.type === 'text' || row.model.type === 'textAnnotation' || row.model.type === "textFile" || row.model.type === "prompt" || row.model.type === 'DLP') ? (this.props.selected === row._id && this.state.isViewAndTrimAudioResourcePopupOpen) : (this.props.selected === row._id && this.props.isSideDrawerOpen ? true : false)}
                                                    onClick={()=>{
                                                        (row.model.type === "image" || row.model.type === 'video' || row.model.type === "audio" || row.model.type === 'text' || row.model.type === 'textAnnotation' || row.model.type === "textFile" || row.model.type === "prompt" || row.model.type === 'DLP') ?
                                                        this.setState({...this.state,isViewAndTrimAudioResourcePopupOpen:true,index:index})
                                                        : row.model.type === "imageAnnotation" || row.model.type === "imageAnnotationGroup"
                                                            ?   this.handleImageAnnotationResourceDetailButtonClick(index)
                                                            :   row?.model?.type === "videoAnnotation"
                                                                ? this.videoAnnotationButtonClickHandler(index)
                                                                :   this.handleClick(row, index)
                                                    }}
                                                />
                                            }
                                            {
                                                this.state.status === 'approved' &&
                                                (
                                                        this.props.resourcesWhichAreBeingAddedToDataSetCollectionInDB.indexOf(row._id) === -1
                                                        ? 
                                                            (
                                                                <Tooltip title={tooltipTextForEditResourceDataSetCollectionButton}>
                                                                    <span>
                                                                        <AddResourceToDataSetCollectionButton
                                                                            onClick={()=>{
                                                                                this.setState({
                                                                                    resourceIdsSelectedForAddingToDataSetCollection: [row._id],
                                                                                    isAddResourcesToDataSetCollectionPopupShowing: true
                                                                                })
                                                                            }}
                                                                        />
                                                                    </span>
                                                                </Tooltip>
                                                            )
                                                        : <CircularProgress classes={{ root: classes.progress }} /> 
                                                )
                                            }

                                                {
                                                    status === 'approval' 
                                                    ? (
                                                        approvedWaitingList.indexOf(row._id) >= 0 
                                                        ?   <CircularProgress classes={{ root: classes.progress }} /> 
                                                        :   <Tooltip title={tooltipTextForApprovedIcon}>
                                                                <IconButton aria-label="approve" 
                                                                // color="primary"
                                                                style={{color : labelsOfVideoAnnotationResourceThatAreNotApprovedOrRejectedByExpert && labelsOfVideoAnnotationResourceThatAreNotApprovedOrRejectedByExpert.length > 0 ? '#CCCCCC' :themePrimaryMainColor}}
                                                                 disabled={
                                                                     deletedWaitingList.indexOf(row._id) >= 0 ||
                                                                     this.props.resourcesWhichAreBeingAddedToDataSetCollectionInDB.indexOf(row._id) !== -1
                                                                } 
                                                                onClick={(event: React.MouseEvent<HTMLButtonElement>) => 
                                                                    {
                                                                        if(row.model.type === 'videoAnnotation') {
                                                                            if(labelsOfVideoAnnotationResourceThatAreNotApprovedOrRejectedByExpert.length){
                                                                                this.setState({...this.state, anchorElement : event.currentTarget, labelsToPassWhileApprovingVideoForecastingModelInInfoPopup : labelsOfVideoAnnotationResourceThatAreNotApprovedOrRejectedByExpert})
                                                                                return;
                                                                            }

                                                                        }
                                                                        this.onUpdateData({ ids: [row._id], status: 'approved' })
                                                                }}
                                                                >
                                                                    <Check />
                                                                </IconButton>
                                                            </Tooltip>
                                                    ) 
                                                    : null
                                                }
                                                {
                                                    (
                                                        status === "backlog" ||
                                                        status === "rejected"
                                                    ) 
                                                        &&
                                                    (   
                                                        <Tooltip
                                                            title={tooltipTextForSetApprovalStatusButton}
                                                        >   
                                                            <span>
                                                                {(row.model.type === "bookTranslation"&&status==="backlog")? <BookTranslationModelThumbsUpButton id={row._id}/> : <SetApprovalStatusButton
                                                                    // color="primary"
                                                                    color={`${labelsOfVideoAnnotationResourceThatAreNotApprovedOrRejectedByExpert && labelsOfVideoAnnotationResourceThatAreNotApprovedOrRejectedByExpert.length > 0 ? '#CCCCCC' :themePrimaryMainColor}`}
                                                                    onClick={(event: React.MouseEvent<HTMLButtonElement>) => 
                                                                        {
                                                                            if(row.model.type === 'videoAnnotation') {
                                                                                if(labelsOfVideoAnnotationResourceThatAreNotApprovedOrRejectedByExpert.length){
                                                                                    this.setState({...this.state, anchorElement : event.currentTarget, labelsToPassWhileApprovingVideoForecastingModelInInfoPopup : labelsOfVideoAnnotationResourceThatAreNotApprovedOrRejectedByExpert})
                                                                                    return;
                                                                                }

                                                                            }
                                                                        this.onUpdateData({ ids: [row._id], status: 'approval' })
                                                                    }}
                                                                />}
                                                            </span>
                                                        </Tooltip>
                                                    )
                                                }
                                                {
                                                    (
                                                        status === "approval" ||
                                                        status === "approved"
                                                    ) &&
                                                    (   
                                                        <Tooltip
                                                            title={tooltipTextForSetRejectedStatusButton}
                                                        >   
                                                            <span>
                                                                <SetRejectedStatusButton
                                                                    color="primary"
                                                                    onClick={() => this.onUpdateData({ ids: [row._id], status: 'rejected' })}
                                                                />
                                                            </span>
                                                        </Tooltip>
                                                    )
                                                }
                                                {
                                                    status === 'deleted'
                                                    ? (
                                                        approvedWaitingList.indexOf(row._id) >= 0 
                                                        ?   <CircularProgress classes={{ root: classes.progress }} /> 
                                                        :
                                                            <Tooltip title={tooltipTextForBacklogIcon}>
                                                                <IconButton aria-label="approve" color="primary"
                                                                 disabled={
                                                                     
                                                                     deletedWaitingList.indexOf(row._id) >= 0 ||
                                                                     this.props.resourcesWhichAreBeingAddedToDataSetCollectionInDB.indexOf(row._id) !== -1
                                                                } 
                                                                 onClick={() => this.onUpdateData({ ids: [row._id], status: 'backlog' })}
                                                                >
                                                                    <Restore />
                                                                </IconButton>
                                                            </Tooltip>
                                                    ) 
                                                    : null
                                                }
                                                {
                                                    // status === 'approved' 
                                                    false
                                                    ? (
                                                        approvedWaitingList.indexOf(row._id) >= 0 
                                                        ?   <CircularProgress classes={{ root: classes.progress }} /> 
                                                        :
                                                            <Tooltip title={tooltipTextForBacklogIcon}>
                                                                <IconButton aria-label="approve" color="primary" 
                                                                    disabled={
                                                                        deletedWaitingList.indexOf(row._id) >= 0 ||
                                                                        this.props.resourcesWhichAreBeingAddedToDataSetCollectionInDB.indexOf(row._id) !== -1
                                                                    } 
                                                                    onClick={() => this.onUpdateData({ ids: [row._id], status: 'backlog' })}
                                                                >
                                                                    <Restore />
                                                                </IconButton>
                                                            </Tooltip>
                                                    ) 
                                                    : null
                                                }
                                                {
                                                    (
                                                        deletedWaitingList.indexOf(row._id) >= 0 
                                                        ?   <CircularProgress classes={{ root: clsx(classes.progress, classes.delete) }} /> 
                                                        :   <Tooltip 
                                                                title={
                                                                    this.state.status === 'deleted'
                                                                    ?   tooltipTextForDeleteIconWhenDeletingResource
                                                                    :   tooltipTextForDeleteIconWhenMovingToDeleteTab
                                                                }
                                                            >
                                                                <IconButton classes={{ root: classes.delete }} 
                                                                    disabled={
                                                                        approvedWaitingList.indexOf(row._id) >= 0 ||
                                                                        this.props.resourcesWhichAreBeingAddedToDataSetCollectionInDB.indexOf(row._id) !== -1
                                                                    } 
                                                                    aria-label="delete" onClick={() => this.onUpdateData({ ids: [row._id], status: 'deleted' })}
                                                                >
                                                                    <Delete />
                                                                </IconButton>
                                                            </Tooltip>
                                                    )
                                                }


                                                {/* resource grid view */}
                                                {
                                                        //@ts-ignore
                                                        this.props.scenario === "browseCollectionsPopup"
                                                        &&
                                                        (
                                                            itemsAddedToCart.resources?.find((obj: IData) => obj._id === row._id) ?
                                                                <Tooltip title="remove item from cart">
                                                                    <IconButton onClick={() => toggleItemInCart(-1, row)}>
                                                                        <CartCheckIcon style={{ width: '30px', height: '30px' }} fill={themePrimaryMainColor} stroke={themePrimaryMainColor} />
                                                                    </IconButton>
                                                                </Tooltip>
                                                                :
                                                                <Tooltip title="add item to cart">
                                                                    <IconButton onClick={() => toggleItemInCart(1, row)}>
                                                                        <AddToCart style={{ width: '30px', height: '30px' }} />
                                                                    </IconButton>
                                                                </Tooltip>
                                                        )
                                                }
                                                
                                            </div>
                                        }
                                        {
                                            // card actions in ai marketplace datacart scenario
                                            this.props.scenario === 'aiMarketPlaceDataCart'
                                            &&
                                            
                                                    <IconButton onClick={() => deleteResourcesFromAiMarketplaceDataCart([row._id]) } disabled={this.state.resourceIdsWhichAreBeingDeleted.includes(row._id)}>
                                                        {
                                                            this.state.resourceIdsWhichAreBeingDeleted.includes(row._id) ?
                                                            <CircularProgress size={24} style={{color: deleteButtonIconColor}} />
                                                            : <CustomTooltip title="Delete item from the cart">
                                                                <Delete style={{color: deleteButtonIconColor}} />
                                                                </CustomTooltip>
                                                        }
                                                    </IconButton>
                                        }
                                        </CardActions>
                                        </Card>
                                    </Grid>
                                </Fade>
                        );
                        })}
                        {(!isFetching && !isApiRequestCancelled) && !data.resources?.length && this.props.scenario !== "dataCart" && this.props.scenario !== "savedForLater" && !this.props.rowsForDataCart &&
                            <div style={{height:'53px', width: 'calc(100% + 98vmax)', padding: '15px'}}>
                                <Typography component="h5" variant="body1">
                                    No Data Found
                                </Typography>
                                <hr style={{marginLeft: '-15px', width:'100vmax'}}/>
                            </div>}
                        {/* <NoTableData colSpan={12} /> */}
                    </Grid>
            </TableBody>
                        :
                        <TableBody>

                        {
                            this.props.scenario !== "dataCart" && this.props.scenario !== "savedForLater" && (isFetching || isApiRequestCancelled) && new Array(rowsPerPage).fill('')?.map((item, index) =>
                                <TableRow classes={{root: classes.tableRow}}  style={{ background: 'white' }} key={`tableSeleton${index}`}>

                                    {
                                        new Array(this.state.resourceTableHeadColumnsLength).fill('').map((item, index) => {
                                            return (
                                            <TableCell key={index}>
                                                <CustomSkeleton width="100%">
                                                    <Chip></Chip>
                                                </CustomSkeleton>
                                            </TableCell>
                                            );
                                        })
                                    }
                                    

                                </TableRow>
                            )
                        }
                        {
                        
                            evaluateDataArray(isFetching, isApiRequestCancelled || false, data)
                        .map((row, index) => {
                            const isItemChecboxSelected = this.isSelected(row._id);
                            console.log('row', row);
                            const labelId = `enhanced-table-checkbox-${index}`;
                         
                       
                            // preprocessing for valid downloadable resources
                            const hasDot = row.filename?.includes('.') || false; 
                            const invalidModelTypesList = ['text','bookTranslation','textAnnotation', 'DLP', 'forecasting'];
                            const isValidModelTypeForResourceDownload = invalidModelTypesList.includes(row.model.type);
                            /*
                            const handleLabelEditSave = (e: onSaveProps, scenario: 'updatingLabel' | 'updatingLabelAndPrediction') => {
                                // if (e.previousValue.includes('->')) {
                                if (scenario === 'updatingLabel') {
                                    this.onUpdateLabelData1({ ids: [row._id], label: e.value });
                                } else {
                                    this.onUpdateLabelData({ ids: [row._id], label: e.value, prediction: e.previousValue });
                                }
                            };

                            const handleTagSave = (tagValueToSave: string) => {
                                this.onUpdateTagData({ids: [row._id], tag: tagValueToSave})
                            }
                            */

                            let labelsOfVideoAnnotationResourceThatAreNotApprovedOrRejectedByExpert : string[] = []
                            if(row.model.type === 'videoAnnotation') {
                                labelsOfVideoAnnotationResourceThatAreNotApprovedOrRejectedByExpert = getLabelsOfVideoAnnotationResourceThatAreNotApprovedOrRejectedByExpert(row?.videoAnnotations || [])
                            }

                            let totalSoldCount=0
                            let shouldShowTotalSoldCountAsNA=true;

                            if (Number.isInteger(row.itemsSoldCount)) {
                                if ((row?.itemsSoldCount || 0) > -1) {
                                    shouldShowTotalSoldCountAsNA = false;
                                }
                                if (row?.itemsSoldCount && row?.itemsSoldCount > 0) {
                                    totalSoldCount = row?.itemsSoldCount || 0;
                                }
                            }
                            
                            row?.additionalResources?.forEach((video)=>{
                                if (video.itemsSoldCount > -1) {
                                    shouldShowTotalSoldCountAsNA = false;
                                }
                                if (video.itemsSoldCount > 0) {
                                    totalSoldCount+=video.itemsSoldCount;
                                }
                            })

                            if (shouldShowTotalSoldCountAsNA) {
                                totalSoldCount = -1
                            }
                              
                            //  let anchorEl:any=null ; 
                            // const handleClick = (event: React.MouseEvent<HTMLDivElement>) => {
                            //   anchorEl=event.currentTarget;
                            // };
                          
                            // const handleClose = () => {
                            //   anchorEl=null;
                            // };
                          
                            // const open = Boolean(anchorEl);
                            // const id = open ? 'simple-popover' : undefined;
                       
                           

                            return (
                                <>
                                <Fade key={row._id} in={true} timeout={index * 250} mountOnEnter unmountOnExit>
                                    <TableRow
                                        hover
                                        role="checkbox"
                                        aria-checked={isItemChecboxSelected}
                                        tabIndex={-1}
                                        key={row._id}
                                        className={row._id}
                                        id={`tableRow${row._id}`}
                                        data-tablerowindex={index}
                                        classes={{ root: classes.tableRow}}
                                        selected={isItemChecboxSelected || (selected === row._id)}
                                        onClick={()=>{this.props.setSelectedData(row._id)}}
                                    >
                                        {
                                            this.props.scenario !== "dataSetCollectionTableDetailsPopup" && this.props.scenario !== "browseCollectionsPopup" &&
                                            <TableCell classes={{ root: classes.tableCellCheckbox}} padding="checkbox"
                                            >
                                                {
                                                    this.props.scenario === "dataCart" || this.props.scenario === "savedForLater" ?
                                                    <Checkbox
                                                        checked={this.props.currentSelectedItems?.find((item: IData) => row._id === item._id) ? true : false}
                                                        onChange={(event) => {
                                                            if(event.target.checked){
                                                                const newState = [...(this.props.currentSelectedItems || []), row]
                                                                this.props.setCurrentSelectedItems
                                                                &&
                                                                this.props.setCurrentSelectedItems(newState)
                                                            }
                                                            else {
                                                                const newState =  [...(this.props.currentSelectedItems?.filter((item: any) => row._id!== item._id) || [])]
                                                                this.props.setCurrentSelectedItems
                                                                &&
                                                                this.props.setCurrentSelectedItems(newState)
                                                            }
                                                        }}
                                                    />
                                                    :
                                                    <Checkbox
                                                        onClick={(event) => this.onCheck(event, row._id)}
                                                        checked={isItemChecboxSelected || this.state.allSelected}
                                                        inputProps={{ 'aria-labelledby': labelId }}
                                                    />
                                                }
                                                

                                            </TableCell>
                                        }
                                        <TableCell classes={{ root: classes.tableCellName}} padding="none"
                                            id={`tableCellName${index}`}
                                            style={{width: row.model.type === "forecasting" ? "100px" : "212px"}}
                                        >
                                            <Grid container alignItems="center" justify='center' wrap="nowrap" spacing={1} style={{margin: "5px 0px"}}
                                            >
                                                <Grid style={{maxHeight: 60, overflow: "auto"}} item>
                                                    {
                                                        row.model.type === 'image' ||
                                                        row.model.type === 'imageAnnotation' ||
                                                        row.model.type === 'imageAnnotationGroup' || 
                                                        row.model.type==='multipleImageAnnotation' 
                                                        // ?   <Avatar variant="rounded" className={classes.image} src={row.resource} /> 
                                                        ?   (<ImageThumbnail index={index} classes={classes} resource={row} dataView='list-view'
                                                            parentScrollContainerElementId={this.state.resourcesTableContainerElementId}
                                                            onThumbnailClick={()=>{
                                                                this.props.setSelectedData(row._id);
                                                            }}
                                                        />)
                                                        :   row.model.type === 'video' || row.model.type === 'forecasting' || row.model.type === "videoAnnotation"
                                                            ?   (
                                                                <>
                                                                <VideoSection 
                                                                    row={row} 
                                                                    index={index} 
                                                                    dataView='list-view'
                                                                    parentScrollContainerElementId={this.state.resourcesTableContainerElementId}
                                                                    onVideoThumbnailClick={()=>{
                                                                        row.model.type === "videoAnnotation" ? this.videoAnnotationButtonClickHandler(index)
                                                                        :
                                                                        row.model.type === 'video' ?
                                                                            this.props.setSelectedData(row._id)
                                                                        :
                                                                        this.setState({...this.state, isViewForcastModelPopupOpen: true, index: index })
                                                                    }}
                                                                />
                                                                </>
                                                            )
                                                            :  row.model.type === 'text' || row.model.type === 'textAnnotation' || row.model.type === 'DLP' || row.model.type === 'prompt' ? 
                                                            (
                                                                // <div style={{width: '175px', overflow:'auto', height: '60px', wordBreak: 'break-word', margin: '4px'}}>
                                                                //     {row.resource}
                                                                // </div>
                                                                <TextResourceTableCell 
                                                                    textResource={row.model.type === "prompt" ?  row.prompt : row.resource}
                                                                />
                                                            )
                                                            : row.model.type === "textFile"
                                                                ?   <div> 
                                                                        <InsertDriveFileIcon color="primary" />
                                                                    </div>
                                                            : row.model.type === "audio" || row.model.type === "audioTextTranscription" ? <Audio src={row.resource} />
                                                            : row.model.type === "bookTranslation"?<>
                                                            <BookTranslationModelParagraphRow addStyles={{}} resource={row}/>
                                                            </>:
                                                            row.model.type === "textComparison" ?
                                                            row.textOne
                                                            : null                                                       
                                                    }
                                                   
                                                </Grid>
                                                {(row.model.type === 'image' || row.model.type === 'imageAnnotation' || row.model.type === 'imageAnnotationGroup'  || row.model.type === 'video' || row.model.type === 'audio' || row.model.type === "audioTextTranscription" || row?.model?.type === "textFile" || row?.model?.type === "videoAnnotation") &&
                                                <Grid item xs zeroMinWidth wrap="nowrap">
                                                    <Tooltip title={row.filename}>
                                                        <Typography component="p" variant="subtitle2" noWrap>
                                                            {this.showName(row)}
                                                        </Typography>
                                                    </Tooltip>
                                                </Grid>}
                                            </Grid>
                                        </TableCell>
                                        {
                                            this.props.scenario !== "dataCart" && this.props.scenario !== "savedForLater" && this.props.scenario !== 'aiMarketplaceDataSelection'
                                            &&
                                        <TableCell classes={{ root: `${row.model.type === "forecasting" ? classes.tableCellLabelForForcastingModel :classes.tableCellLabel}`}}
                                        >
                                            {/* {row.prediction === 'predicted' ?
                                                <Tooltip title={row.label}>
                                                    <div>
                                                        <EditText
                                                            name="label"
                                                            style={{ backgroundColor: "#f3f4f6" }}
                                                            defaultValue={row.label}
                                                            className={`${classes.labelEditText}`}
                                                            onSave={(e)=>handleLabelEditSave(e, 'updatingLabelAndPrediction')}
                                                            readonly={
                                                                this.getSelectedSubTab() === 'approved'
                                                                ? true
                                                                : false
                                                            }                                                                
                                                        />
                                                    </div>
                                                </Tooltip>
                                                :
                                                <Tooltip title={row.prediction + "->" + row.label}>
                                                    <div>
                                                        <LabelIncorrectPredictionEditText 
                                                            onSave={(e)=>{handleLabelEditSave(e, 'updatingLabel')}}
                                                            labelDefaultValue={row.prediction + "->" + row.label}
                                                            classes={classes}
                                                            readonly={
                                                                this.getSelectedSubTab() === 'approved'
                                                                ? true
                                                                : false                                                                    
                                                            }
                                                            prediction={row.prediction}
                                                            label={row.label}
                                                        />                                                                
                                                    </div>
                                                </Tooltip>
                                                    } */}
                                            {row.model.type==="bookTranslation"?
                                            <Box
                                            style={{
                                                width: '90%',
                                                display: 'flex',
                                                justifyContent: 'center'
                                            }}
                                        >
                                            <Tooltip title={row.confidence_score + "%"}>
                                             <Chip style={{ width: "120px" }} label={row.confidence_score + "%"} classes={{ root: classes.chip }} />
                                             </Tooltip>
                                        </Box>
                                             
                                             :<Box
                                                style={{
                                                    width: '90%'
                                                }}
                                            >{
                                                this.state.predictionStatusLogic === "labelAndErrorDeviation"?
                                                <LabelEditText
                                                    readonly={
                                                        false && this.getSelectedSubTab() === 'approved'
                                                        ? true
                                                        : false}
                                                    // labelsAvailable={
                                                    //     this.state.labelsAvailable
                                                    //     // this.state.labelsAvailable
                                                    // }
                                                    labelsAvailable={labelOptionsForARow(row)}
                                                    resource={row}
                                                    allowUserToSetAnyLabel={this.state.allowUserToSetAnyLabel}
                                                    onEditTextFocus={()=>{
                                                        this.props.setShouldBlockDataTableKeyboardEvents(true)
                                                    }}
                                                    onEditTextBlurEvent={()=>{
                                                        this.props.setShouldBlockDataTableKeyboardEvents(false)
                                                    }}
                                                    placeWhereComponentBeingUsed="data-table"
                                                    scenario={
                                                        "labelAndErrorDeviation"
                                                    }
                                                    maximumMisPredictionDeviationAllowedInNumber={this.state.maximumMisPredictionDeviationAllowedInNumber}
                                                />
                                                :
                                                <LabelEditText 
                                                readonly={
                                                    ["browseCollectionsPopup", "aiMarketPlaceDataCart"].includes(this.props.scenario) ? true :
                                                    false && (this.getSelectedSubTab() === 'approved' || this.getSelectedSubTab() === 'rejected' || this.getSelectedSubTab() === 'deleted')
                                                    ? true
                                                    : false                                                         
                                                }
                                                // labelsAvailable={this.state.labelsAvailable}
                                                labelsAvailable={labelOptionsForARow(row)}
                                                resource={row}
                                                allowUserToSetAnyLabel={this.state.allowUserToSetAnyLabel}
                                                onEditTextFocus={()=>{
                                                    this.props.setShouldBlockDataTableKeyboardEvents(true)
                                                }}
                                                onEditTextBlurEvent={()=>{
                                                    this.props.setShouldBlockDataTableKeyboardEvents(false)
                                                }}
                                                placeWhereComponentBeingUsed="data-table"
                                            />
                                            }
                                            </Box>}
                                        </TableCell>
                                        }
                                        {
                                            currentModelType==='bookTranslation'&&
                                            <TableCell classes={{ root: classes.tableCell}}>
                                                <Tooltip title={row.textOriginalLanguage+"title"}>
                                                    <Chip style={{ width: "100%",  }} label={<p style={{ marginBottom: '0px' }}>{row.textOriginalLanguage} <ArrowForwardIcon style={{ height: "12px"}}/> {row.textTranslatedLanguage} </p>} classes={{ root: classes.chip }} />
                                                </Tooltip>
                                            </TableCell>
                                        }
                                        {
                                            !((this.props.scenario === "dataSetCollectionTableDetailsPopup" || this.props.scenario === "browseCollectionsPopup" || this.props.scenario === "aiMarketPlaceDataCart" || this.props.scenario === 'aiMarketplaceDataSelection') && currentModelType === "forecasting") && (currentModelType !== "videoAnnotation" && currentModelType!== "bookTranslation") &&
                                            <TableCell classes={{ root: classes.tableCellConfidenceScore}}
                                            >
                                                <Tooltip title={row.confidence_score + "%"}>
                                                    <Chip style={{ width: "120px" }} label={row.confidence_score || 0 + "%"} classes={{ root: classes.chip }} />
                                                </Tooltip>
                                            </TableCell>
                                        }
                                        
                                        {
                                            row.model.type !== "forecasting" &&
                                            <TableCell classes={{ root: classes.tableCellTags}}
                                        >
                                            {/* <Tooltip title={row.tag}>
                                                    <Box>
                                                        <EditText
                                                            name="tag"
                                                            className={`${classes.tagEditText}`}
                                                            style={{ width: "120px", borderWidth: 0, borderRadius: 20, backgroundColor: "#f3f4f6", textAlign: "center", margin: "0 auto" }}
                                                            defaultValue={row.tag}
                                                            readonly={
                                                                this.getSelectedSubTab() === 'approved'
                                                                ? true
                                                                : false
                                                            }                                                                
                                                            onSave={(e)=>{
                                                                handleTagSave(e.value)
                                                            }}
                                                        />
                                                    </Box>
                                            </Tooltip> */}
                                            <Tooltip title={row.tag}>
                                                <Box
                                                    style={{width: '90%'}}
                                                >
                                                        <TagEditText 
                                                            defaultValue={row.tag}
                                                            resource={row}
                                                            onEditTextClick={()=>{
                                                                this.props.setShouldBlockDataTableKeyboardEvents(true)
                                                            }}
                                                            onEditTextBlurEvent={()=>{
                                                                this.props.setShouldBlockDataTableKeyboardEvents(false)
                                                            }}
                                                            editTextComponentProps={{
                                                                readonly: this.getSelectedSubTab() === 'approved'
                                                                    ? true : false
                                                            }}                       
                                                        />
                                                </Box>
                                            </Tooltip>
                                        </TableCell>}
                                        {currentModelType !== "forecasting" && this.props.scenario !== "dataSetCollectionTableDetailsPopup" && this.props.scenario !== "browseCollectionsPopup" && this.props.scenario !== "aiMarketPlaceDataCart" && this.props.scenario !== 'aiMarketplaceDataSelection' &&
                                        <TableCell classes={{ root: classes.tableCellDate}}
                                        >
                                            <div style={{ maxWidth: '140px', margin: 'auto'}}>
                                            <TimeDateFormat timeString={row.statusLastModifiedAt} timeStyleClass={classes.lastModifiedTime} textAlign={'left'}/>
                                            </div>
                                            {/* <Tooltip title={moment(row.statusLastModifiedAt).format(`hh:mm${!this.props.isSideDrawerOpen ? ':ss': ''} A DD-MM-YYYY`)}>
                                                <Typography component="p" variant="subtitle2" noWrap>{moment(row.statusLastModifiedAt).format(`hh:mm${!this.props.isSideDrawerOpen ? ':ss': ''} A DD-MM-YYYY`)}</Typography>
                                            </Tooltip> */}
                                        </TableCell>}
                                        
                                        {
                                          currentModelType === "videoAnnotation" &&
                                          <TableCell classes={{ root: classes.tableCellDate }}>
                                            <Box>
                                              <TextField
                                                size="small"
                                                style={{ width: '60%' }}
                                                variant="outlined"
                                                value={ this.detectedVideoAnnotations(row?.videoAnnotations ?? [])}
                                                InputProps={{
                                                  inputProps: {
                                                    style: { textAlign: "center", color: 'black' },
                                                  }
                                                }}
                                                className = {`${classes.borderStyled}`}
                                                disabled
                                              >
                                              </TextField>
                                            </Box>
                                          </TableCell>
                                        }

                                        {
                                          currentModelType === "videoAnnotation" &&
                                          <TableCell classes={{ root: classes.tableCellDate }}>
                                            <Box>
                                              <TextField
                                                size="small"
                                                InputProps={{
                                                  inputProps: {
                                                    style: { textAlign: "center", color: 'black',  },
                                                  }
                                                }}
                                                style={{
                                                  width: '60%',
                                                }}
                                                className = {`${classes.borderStyled}`}
                                                variant="outlined"
                                                value={ this.expertVideoAnnotations(row?.videoAnnotations ?? [])}
                                                disabled
                                              >
                                              </TextField>
                                            </Box>
                                          </TableCell>
                                        }
                                        {row.model.type === "forecasting" &&
                                        <TableCell classes={{ root: classes.tableCellDateForForecastingModel}}
                                        >
                                            {row.forecastDateTime !== undefined ?
                                            row.forecastDateTime !== "" ?
                                            <div style={{ maxWidth: '140px', margin: "auto", paddingLeft: "14px"}}>
                                            <TimeDateFormat timeString={row.forecastDateTime} isForecastDate={true} timeStyleClass={classes.lastModifiedTime} textAlign={'left'}/>
                                            </div>
                                            :<div style={{marginLeft:"-20px"}}> NA </div>
                                            :<div style={{marginLeft:"-20px"}}> NA </div>
                                            }
                                        </TableCell>}
                                        {row.model.type === "forecasting" &&
                                        <TableCell  className={classes.dataCount}>
                                            <Chip style={{ width: "70px", borderRadius: "5px",border:"1px solid #707070",
                                            background: row.predictedCount !== totalSoldCount ? '#ee8a8a' : 'white'
                                            }} variant="outlined" 
                                            label={
                                                row.predictedCount!== undefined 
                                                ? row.predictedCount >= 0 
                                                    ? row.predictedCount >= 10 
                                                        ? row.predictedCount 
                                                        : "0" + row.predictedCount 
                                                    : "NA"
                                                : "NA"} 
                                                classes={{ root: classes.chip }}
                                            ></Chip>
                                            </TableCell>}
                                        {row.model.type === "forecasting" &&
                                        <TableCell  className={classes.dataCount}>
                                            <Chip style={{ width: "70px", borderRadius: "5px", background: 'white' , border:"1px solid #707070"}} variant="outlined" 
                                                label={
                                                    row.cv_aiCount!== undefined 
                                                    ? row.cv_aiCount >= 0 
                                                        ? row.cv_aiCount >= 10 
                                                            ? row.cv_aiCount 
                                                            : "0" + row.cv_aiCount 
                                                        : "NA"
                                                    : "NA"
                                                } 
                                                classes={{ root: classes.chip }}></Chip>
                                            </TableCell>}
                                        {row.model.type === "forecasting" &&
                                        <TableCell  className={classes.dataCount}>
                                            <Chip style={{ width: "70px", borderRadius: "5px", background: 'white', border:"1px solid #707070" }} variant="outlined" 
                                                label={
                                                    totalSoldCount!== undefined 
                                                    ? totalSoldCount >= 0 
                                                        ? totalSoldCount >= 10 
                                                            ? totalSoldCount 
                                                            : "0" + totalSoldCount 
                                                        : "NA"
                                                    : "NA"
                                                } 
                                                classes={{ root: classes.chip }}></Chip>
                                            </TableCell>}
                                        {currentModelType === "forecasting" && this.props.scenario !== "dataSetCollectionTableDetailsPopup" && this.props.scenario !== "browseCollectionsPopup" && this.props.scenario !== "dataCart" && this.props.scenario !== "savedForLater" &&
                                        <TableCell classes={{ root: classes.tableCellDateForForecastingModel}}
                                        >
                                            <div style={{ maxWidth: '140px', margin: 'auto'}}>
                                            <TimeDateFormat timeString={row.statusLastModifiedAt} timeStyleClass={classes.lastModifiedTime} textAlign={'left'}/>
                                            </div>
                                        </TableCell>}
                                        {/* cell to display action buttons  */}
                                        {
                                            this.props.scenario === "dataCart" ?
                                            <TableCell classes={{ root: `${classes.tableCellActions}`}} style={{alignItems: 'center'}}>
                                                {
                                                    !this.props.isUsingDataCartForAiAssistedlabellingCodeTesting &&
                                                    <IconButton 
                                                        style={{height: '45px', width: '45px'}}
                                                        onClick={() => {
                                                            this.context.toggleItemInSavedForLater(this.context.itemsSavedForLater.resources?.find((item: IData) => item._id === row._id) ? -1 : 1, row)
                                                            if(this.props.setCurrentSelectedItems) this.props.setCurrentSelectedItems([...(this.props.currentSelectedItems?.filter(item => item._id !== row._id) || [])])
                                                        }}
                                                    disabled={isPageOpenFromHomePageChatModelSummaryPage}
                                                    >
                                                        {
                                                            this.context.itemsSavedForLater.resources?.find((item: IData) => item._id === row._id) ?
                                                            <SavedForLaterInCart style={{opacity:isPageOpenFromHomePageChatModelSummaryPage?'0.5':'1'}}/>
                                                            :
                                                            <SaveForLaterInCart style={{opacity:isPageOpenFromHomePageChatModelSummaryPage?'0.5':'1'}}/>
                                                        }
                                                    </IconButton>
                                                }
                                                <IconButton 
                                                    style={{height: '45px', width: '45px'}} 
                                                    onClick={() => {
                                                        const newResources = this.context.itemsAddedToCart.resources?.filter((item: IData) => item._id !== row._id)
                                                        this.context.setItemsAddedToCart({resources: [...(newResources || [])]})
                                                    }}
                                                    disabled={isPageOpenFromHomePageChatModelSummaryPage}
                                                >
                                                    <DeleteIcon style={{color: '#EF8A8A',opacity:isPageOpenFromHomePageChatModelSummaryPage?'0.5':'1'}} />
                                                </IconButton>
                                            </TableCell>
                                            : this.props.scenario === "savedForLater" ?
                                                        <TableCell classes={{ root: `${classes.tableCellActions}` }} style={{ alignItems: 'center' }}>
                                                            <IconButton
                                                                style={{ height: '45px', width: '45px' }}
                                                                onClick={() => {
                                                                    this.context.toggleItemInSavedForLater(this.context.itemsSavedForLater.resources?.find((item: IData) => item._id === row._id) ? -1 : 1, row)
                                                                }}
                                                            >
                                                                <AddToCart/>
                                                            </IconButton>
                                                            <IconButton
                                                                style={{ height: '45px', width: '45px' }}
                                                                onClick={() => {
                                                                    const newResources = this.context.itemsSavedForLater.resources?.filter((item: IData) => item._id !== row._id)
                                                                    this.context.setItemsSavedForLater({ resources: [...(newResources || [])] })
                                                                }}
                                                            >
                                                                <DeleteIcon style={{ color: '#EF8A8A' }} />
                                                            </IconButton>
                                                        </TableCell>
                                            :
                                            this.props.scenario !=="dataSetCollectionTableDetailsPopup" && this.props.scenario !== "browseCollectionsPopup" && this.props.scenario !== "aiMarketPlaceDataCart" && this.props.scenario !== 'aiMarketplaceDataSelection' &&
                                            <TableCell classes={{ root: `${classes.tableCellActions} ${row.model.type === "forecasting" ? classes.tableCellActionsForForecastingModel :  this.state.status === 'approved' ? classes.tableCellActionsWhenApprovedStatus : ''}  ${row.model.type === "forecasting" ? classes.tableCellActionsForForecastingModel : this.state.status === 'approval' ? classes.tableCellActionsWhenApprovalStatus : ''} ` }}>
                                                {/* <div className={classes.actions} style={{marginLeft: row.model.type !== "forecasting" ? row.model.type==="bookTranslation"? 25: -12 : 0, justifyContent:"center" , width: currentModelType === "bookTranslation"?"100px":"initial"}} > */}
                                                <div className={classes.actions} style={{marginLeft: row.model.type !== "forecasting" ? row.model.type==="bookTranslation"? 6: -12 : 0, justifyContent: currentModelType=="textComparison"?"center":"flex-start" , width: currentModelType === "bookTranslation"?"100px":"initial"}} >

                                                {/* {(row.model.type === "imageAnnotation" || row.model.type === "imageAnnotationGroup") && 
                                                <DataRowExpandIconButton
                                                    tooltip={
                                                        this.props.selected === row._id && this.props.isSideDrawerOpen
                                                        ? "Opened in detailed view"
                                                        : "Open in detailed view"
                                                    }
                                                    disabled={this.props.selected === row._id && this.props.isSideDrawerOpen ? true : false}
                                                    onClick={()=>{
                                                        this.handleClick(row, index)
                                                    }}
                                                />} */}

                                                {
                                                    this.state.status === 'approved' &&
                                                    (
                                                            this.props.resourcesWhichAreBeingAddedToDataSetCollectionInDB.indexOf(row._id) === -1
                                                            ? 
                                                                (
                                                                    <Tooltip title={tooltipTextForEditResourceDataSetCollectionButton}>
                                                                        <span>
                                                                            <AddResourceToDataSetCollectionButton
                                                                                onClick={()=>{
                                                                                    this.setState({
                                                                                        resourceIdsSelectedForAddingToDataSetCollection: [row._id],
                                                                                        isAddResourcesToDataSetCollectionPopupShowing: true
                                                                                    })
                                                                                }}
                                                                            />
                                                                        </span>
                                                                    </Tooltip>
                                                                )
                                                            : <CircularProgress classes={{ root: classes.progress }} /> 
                                                    )
                                                }

                                                    {
                                                        status === 'approval' 
                                                        ? (
                                                            approvedWaitingList.indexOf(row._id) >= 0 
                                                            ?   <CircularProgress classes={{ root: classes.progress }} /> 
                                                            :   <Tooltip title={tooltipTextForApprovedIcon}>
                                                                    <IconButton aria-label="approve" 
                                                                    // color="primary"
                                                                    style={{color : labelsOfVideoAnnotationResourceThatAreNotApprovedOrRejectedByExpert && labelsOfVideoAnnotationResourceThatAreNotApprovedOrRejectedByExpert.length > 0 ? '#CCCCCC' : themePrimaryMainColor}}
                                                                    disabled={
                                                                        deletedWaitingList.indexOf(row._id) >= 0 ||
                                                                        this.props.resourcesWhichAreBeingAddedToDataSetCollectionInDB.indexOf(row._id) !== -1
                                                                    } 
                                                                    onClick={(event: React.MouseEvent<HTMLButtonElement>) => 
                                                                        {
                                                                            if(row.model.type === 'videoAnnotation') {
                                                                                if(labelsOfVideoAnnotationResourceThatAreNotApprovedOrRejectedByExpert.length){
                                                                                    this.setState({...this.state, anchorElement : event.currentTarget, labelsToPassWhileApprovingVideoForecastingModelInInfoPopup : labelsOfVideoAnnotationResourceThatAreNotApprovedOrRejectedByExpert})
                                                                                    return;
                                                                                }

                                                                            }
                                                                            this.onUpdateData({ ids: [row._id], status: 'approved' })
                                                                    }}
                                                                    >
                                                                        <Check />
                                                                    </IconButton>
                                                                </Tooltip>
                                                        ) 
                                                        : null
                                                    }

                                                    {
                                                        (currentModelType === "bookTranslation"&& (this.state.bookTranslationResourceIdsEnableApprove.find((id)=>id===row._id)===undefined)&&!isParagraphEnableApprove(row._id)&&status==="backlog")&&
                                                        <Tooltip
                                                                title={tooltipTextForApprovedIcon}
                                                            >   
                                                                <BookTranslationModelThumbsUpButton id={row._id} />
                                                            </Tooltip>
                                                    }

                                                    

                                                    {
                                                        (
                                                            (status === "backlog" ||
                                                            status === "rejected")&&(
                                                                currentModelType!=='bookTranslation' ||(
                                                                    currentModelType==='bookTranslation'&&(this.state.bookTranslationResourceIdsEnableApprove.find((id)=>id===row._id)!==undefined||isParagraphEnableApprove(row._id))
                                                                )
                                                            )
                                                        ) &&
                                                        (   
                                                            approvalWaitingList.indexOf(row._id) >= 0 ? 
                                                                <CircularProgress classes={{ root: classes.progress }} />
                                                                :
                                                            <Tooltip
                                                                title={this.state.labelsToPassWhileApprovingVideoForecastingModelInInfoPopup.length ? "" : tooltipTextForSetApprovalStatusButton}
                                                            >   
                                                                <span>
                                                                    <SetApprovalStatusButton
                                                                        // color="primary"
                                                                        color={`${labelsOfVideoAnnotationResourceThatAreNotApprovedOrRejectedByExpert && labelsOfVideoAnnotationResourceThatAreNotApprovedOrRejectedByExpert.length > 0 ? '#CCCCCC' :themePrimaryMainColor }`}
                                                                        onClick={(event: React.MouseEvent<HTMLButtonElement>) => 
                                                                            {
                                                                                if(row.model.type === 'videoAnnotation') {
                                                                                    if(labelsOfVideoAnnotationResourceThatAreNotApprovedOrRejectedByExpert.length){
                                                                                        this.setState({...this.state, anchorElement : event.currentTarget, labelsToPassWhileApprovingVideoForecastingModelInInfoPopup : labelsOfVideoAnnotationResourceThatAreNotApprovedOrRejectedByExpert})
                                                                                        return;
                                                                                    }
    
                                                                                }
                                                                            console.log('here clicking2: ', this.props.approvalWaitingList)
                                                                            this.onUpdateData({ ids: [row._id], status: 'approval' })
                                                                        }}
                                                                    />
                                                                </span>
                                                            </Tooltip>
                                                        )
                                                    }
                                                    {
                                                        (
                                                            status === "approval" ||
                                                            status === "approved"
                                                        ) &&
                                                        (rejectedWaitingList.indexOf(row._id) >= 0 ?
                                                            <CircularProgress classes={{ root: classes.progress }} />
                                                            :
                                                            <Tooltip
                                                                title={tooltipTextForSetRejectedStatusButton}
                                                            >   
                                                                <span>
                                                                    <SetRejectedStatusButton
                                                                        color="primary"
                                                                        onClick={() => this.onUpdateData({ ids: [row._id], status: 'rejected' })}
                                                                    />
                                                                </span>
                                                            </Tooltip>
                                                        )
                                                    }
                                                    {
                                                        status === 'deleted'
                                                        ? (
                                                            approvedWaitingList.indexOf(row._id) >= 0 
                                                            ?   <CircularProgress classes={{ root: classes.progress }} /> 
                                                            :
                                                                <Tooltip title={tooltipTextForBacklogIcon}>
                                                                    <IconButton aria-label="approve" color="primary"
                                                                    disabled={
                                                                        
                                                                        deletedWaitingList.indexOf(row._id) >= 0 ||
                                                                        this.props.resourcesWhichAreBeingAddedToDataSetCollectionInDB.indexOf(row._id) !== -1
                                                                    } 
                                                                    onClick={() => this.onUpdateData({ ids: [row._id], status: 'backlog' })}
                                                                    >
                                                                        <Restore />
                                                                    </IconButton>
                                                                </Tooltip>
                                                        ) 
                                                        : null
                                                    }
                                                    {
                                                        // status === 'approved' 
                                                        false
                                                        ? (
                                                            approvedWaitingList.indexOf(row._id) >= 0 
                                                            ?   <CircularProgress classes={{ root: classes.progress }} /> 
                                                            :
                                                                <Tooltip title={tooltipTextForBacklogIcon}>
                                                                    <IconButton aria-label="approve" color="primary" 
                                                                        disabled={
                                                                            deletedWaitingList.indexOf(row._id) >= 0 ||
                                                                            this.props.resourcesWhichAreBeingAddedToDataSetCollectionInDB.indexOf(row._id) !== -1
                                                                        } 
                                                                        onClick={() => this.onUpdateData({ ids: [row._id], status: 'backlog' })}
                                                                    >
                                                                        <Restore />
                                                                    </IconButton>
                                                                </Tooltip>
                                                        ) 
                                                        : null
                                                    }
                                                    {
                                                        (
                                                            deletedWaitingList.indexOf(row._id) >= 0 
                                                            ?   <CircularProgress classes={{ root: clsx(classes.progress, classes.delete) }} /> 
                                                            :   <Tooltip 
                                                                    title={
                                                                        this.state.status === 'deleted'
                                                                        ?   tooltipTextForDeleteIconWhenDeletingResource
                                                                        :   tooltipTextForDeleteIconWhenMovingToDeleteTab
                                                                    }
                                                                >
                                                                    <IconButton classes={{ root: classes.delete }} 
                                                                        disabled={
                                                                            approvedWaitingList.indexOf(row._id) >= 0 ||
                                                                            this.props.resourcesWhichAreBeingAddedToDataSetCollectionInDB.indexOf(row._id) !== -1
                                                                        } 
                                                                        aria-label="delete" onClick={() => this.onUpdateData({ ids: [row._id], status: 'deleted' })}
                                                                    >
                                                                        <Delete />
                                                                    </IconButton>
                                                                </Tooltip>
                                                        )
}
                                                    {
                                                        //  icon to download the required resource
                                                        ( !isValidModelTypeForResourceDownload ) ? hasDot ? 
                                                        <>
                                                            <Tooltip title='Download the resource'>
                                                                <a href={row.resource} target="_blank" rel="noopener noreferrer">
                                                                    <IconButton>
                                                                        <GetAppIcon style={{color:'#008D9C'}}/>
                                                                    </IconButton>
                                                                </a>
                                                            </Tooltip> 
                                                        </> : null : null
                                                        
                                                    }
                                                      {
                                                        (
                                                            status === "backlog" ||
                                                            status === "approval" ||
                                                            status === "approved"
                                                        ) &&
                                                        (row.model.type === 'image' || row.model.type === 'imageAnnotation'  || row.model.type === 'imageAnnotationGroup') &&
                                                        // <EditImage resource={row}/>
                                                        <Tooltip title={"Edit this image"}>
                                                                    <IconButton classes={{ root: classes.delete }} 
                                                                        // disabled={
                                                                        //     approvedWaitingList.indexOf(row._id) >= 0 ||
                                                                        //     this.props.resourcesWhichAreBeingAddedToDataSetCollectionInDB.indexOf(row._id) !== -1
                                                                        // } 
                                                                        aria-label="delete" onClick={() => this.setState({ isImageEditingPopupOpen : true , index : index})}
                                                                    >
                                                                        <Edit color='primary' />
                                                                    </IconButton>
                                                                </Tooltip>
                                                    }

                                                                                                            
                                                        
                                                    
                                                    {/* {
                                                         row.model.type === 'bookTranslation' &&
                                                             <ViewExpandBookTranslationModelParagraph
                                                             scenario={this.props.scenario}
                                                            onClick={()=>{
                                                                if(this.state.bookTranslationResourceIdWhoseViewButtonIsExpanded.find((id)=> id===String(row._id)))
                                                                {
                                                                    this.setState({
                                                                        ...this.state, bookTranslationResourceIdWhoseViewButtonIsExpanded: [...this.state.bookTranslationResourceIdWhoseViewButtonIsExpanded.filter((id)=> id!==String(row._id))]
                                                                        })
                                                                }
                                                                else{
                                                                    this.setState({
                                                                        ...this.state, bookTranslationResourceIdWhoseViewButtonIsExpanded: [...this.state.bookTranslationResourceIdWhoseViewButtonIsExpanded, String(row._id)]
                                                                    })
                                                                }
                                                                
                                                         }}
                                                         />
                                                    } */}
                                                    
                                                </div>
                                            </TableCell>
                                        }
                                        {
                                            row.model.type === "bookTranslation" &&
                                            <TableCell style={{width: "130px"}}>
                                                             <ViewExpandBookTranslationModelParagraph
                                                             scenario={this.props.scenario}
                                                            onClick={()=>{
                                                                if(this.state.bookTranslationResourceIdWhoseViewButtonIsExpanded.find((id)=> id===String(row._id)))
                                                                {
                                                                    this.setState({
                                                                        ...this.state, bookTranslationResourceIdWhoseViewButtonIsExpanded: [...this.state.bookTranslationResourceIdWhoseViewButtonIsExpanded.filter((id)=> id!==String(row._id))]
                                                                        })
                                                                }
                                                                else{
                                                                    this.setState({
                                                                        ...this.state, bookTranslationResourceIdWhoseViewButtonIsExpanded: [...this.state.bookTranslationResourceIdWhoseViewButtonIsExpanded, String(row._id)]
                                                                    })
                                                                }
                                                         }}
                                                         />                                                
                                            </TableCell>
                                        }
                                        {/* Added cell to open popup  */}
                                        {
                                            row.model.type === 'forecasting' &&
                                            <TableCell style={{width: "130px"}}>
                                            <ViewForcastModelPopupOpenButton 
                                                scenario={this.props.scenario}
                                            onClick={()=>{
                                                this.setState({...this.state,isViewForcastModelPopupOpen:true,index:index})
                                            }}
                                            />
                                        </TableCell>}
                                        {
                                            //@ts-ignore
                                                this.props.scenario === "browseCollectionsPopup"
                                                &&
                                                <TableCell style={{width: '112px'}}>
                                                    <>
                                                    {
                                                        this.props.isUsingDataCartForAiAssistedlabellingCodeTesting &&
                                                        <>
                                                            <div
                                                                style={{
                                                                    display: "flex", alignItems: "center"                                                                    
                                                                }}
                                                            >
                                                                {
                                                                    this.context.itemsAddedToCart.resources?.[0]?._id !== row._id
                                                                    ?
                                                                        <>
                                                                            {/* 
                                                                                Will be used to select the data for testing AI Assisted Labelling code on
                                                                            */}
                                                                            <Tooltip title="Select the data to test AI Assisted Labelling code on">
                                                                                <CheckIcon color="primary"
                                                                                    onClick={()=>{
                                                                                        /**
                                                                                         * Set data selected by user which will be used for testing AI Assisted labelling code on
                                                                                         */
                                                                                        this.context.setItemsAddedToCart({resources: [row]})                                                                
                                                                                    }} 
                                                                                    style={{
                                                                                        cursor: "pointer"
                                                                                    }}
                                                                                />
                                                                            </Tooltip>
                                                                        </>
                                                                    :
                                                                        <>
                                                                            <Tooltip title="Remove this data to test AI Assisted Labelling code on">
                                                                                <CheckBoxRoundedIcon color="primary"
                                                                                    onClick={()=>{
                                                                                        /**
                                                                                         * Remove data selected by user which will be used for testing AI Assisted labelling code on
                                                                                         */
                                                                                        this.context.setItemsAddedToCart({resources: []})                                                                
                                                                                    }} 
                                                                                    style={{
                                                                                        cursor: "pointer"
                                                                                    }}
                                                                                />
                                                                            </Tooltip>                                                            
                                                                        </>
                                                                }
                                                                {/* {
                                                                    this.context.itemsSavedForLater.resources?.find((obj: IData) => obj._id === row._id) ?
                                                                        <IconButton onClick={() => this.context.toggleItemInSavedForLater(-1, row, {scenario: "AI_Assisted_Labelling_Browse_Data"})}>
                                                                            <SavedForLaterInCart style={{width: '1.5rem'}} fill={themePrimaryMainColor}/>
                                                                        </IconButton>
                                                                        :
                                                                        <IconButton onClick={() => this.context.toggleItemInSavedForLater(1, row, {scenario: "AI_Assisted_Labelling_Browse_Data"})}>
                                                                    <SaveForLaterInCart style={{width: '1.5rem'}} />
                                                                        </IconButton>
                                                                }                                                             */}
                                                            </div>
                                                        </>

                                                    }


                                                    {/* 
                                                        Add/remove to data cart action button for browse dataset collection -> data table for training pod browse collection
                                                    */}
                                                    {
                                                        this.props.scenario === "browseCollectionsPopup" && !this.props.isUsingDataCartForAiAssistedlabellingCodeTesting
                                                        &&
                                                        (
                                                            // itemsAddedToCart.resources?.find((obj: IData) => obj._id === this.props.data.resources[this.state.index]?._id) ?
                                                            itemsAddedToCart.resources?.find((obj: IData) => obj._id === row?._id) ?
                                                                <Tooltip title="remove item from cart">
                                                                    {/* <IconButton onClick={() => toggleItemInCart(-1, this.props.data.resources[this.state.index])}> */}
                                                                    <IconButton onClick={() => toggleItemInCart(-1, row)}>
                                                                        <CartCheckIcon style={{ width: '30px', height: '30px' }} fill={themePrimaryMainColor} stroke={themePrimaryMainColor}/>
                                                                    </IconButton>
                                                                </Tooltip>
                                                                :
                                                                <Tooltip title="add item to cart">
                                                                    <IconButton onClick={() => toggleItemInCart(1, row)}>
                                                                        <AddToCart style={{ width: '30px', height: '30px' }} />
                                                                    </IconButton>
                                                                </Tooltip>
                                                        )
                                                    }                                            


                                                    {/* {
                                                        itemsAddedToCart.resources?.find((obj: IData) => obj._id === row._id) ?
                                                            <IconButton onClick={() => toggleItemInCart(-1, row)}>
                                                                <SaveForLaterInCart style={{width: '1.5rem'}} fill={themePrimaryMainColor}/>
                                                            </IconButton>
                                                            :
                                                            <IconButton onClick={() => toggleItemInCart(1, row)}>
                                                        <SaveForLaterInCart style={{width: '1.5rem'}} />
                                                            </IconButton>
                                                    } */}
                                                    </>
                                                </TableCell>
                                        }
                                        {
                                            // data source column in ai marketplace scenario
                                            this.props.scenario === 'aiMarketPlaceDataCart'
                                            &&
                                            <TableCell classes={{root: classes.tableCellDataSource}}>
                                                {row.status}
                                            </TableCell>

                                        }
                                        {
                                            // actions in ai marketplace data cart scenario
                                            this.props.scenario === 'aiMarketPlaceDataCart'
                                            &&
                                            <TableCell classes={{root: classes.tableCellActionsInAiMarketplaceScenario}}>
                                                    <IconButton onClick={() => deleteResourcesFromAiMarketplaceDataCart([row._id]) } disabled={this.state.resourceIdsWhichAreBeingDeleted.includes(row._id)}>
                                                        {
                                                            this.state.resourceIdsWhichAreBeingDeleted.includes(row._id) ?
                                                            <CircularProgress size={24} style={{color: deleteButtonIconColor}} />
                                                            : <CustomTooltip title="Delete item from the cart">
                                                                <Delete style={{color: deleteButtonIconColor}} />
                                                              </CustomTooltip>
                                                        }
                                                    </IconButton>
                                            </TableCell>
                                        }
                                        {
                                            (row.model.type === 'audio'|| row.model.type === 'text' || row.model.type === 'textAnnotation' || row.model.type === 'DLP' || row.model.type === "textFile" || row.model.type === 'video' || row.model.type === 'image'  ||row.model.type==='multipleImageAnnotation' || row.model.type === "imageAnnotation" || row.model.type === "imageAnnotationGroup" || row.model.type === 'prompt' || row.model.type === 'videoAnnotation' || row.model.type === "textComparison" || row.model.type === "audioTextTranscription") &&
                                            <TableCell style={{width: "130px"}}>
                                                {
                                                  (row.model.type === "videoAnnotation") 
                                                  ?
                                                  <VideoAnnotationDialogBoxOpenButton onClick={()=>{ this.videoAnnotationButtonClickHandler(index)}} />
                                                  :
                                                    (
                                                        row.model.type === "imageAnnotation" || 
                                                        row.model.type === "imageAnnotationGroup" || 
                                                        row.model.type==="multipleImageAnnotation"
                                                        
                                                    )
                                                    ?
                                                        <ViewImageAnnotationResourcePopupOpenButton 
                                                            scenario={this.props.scenario}
                                                            onClick={()=>{
                                                                this.handleImageAnnotationResourceDetailButtonClick(index)
                                                            }}                                                            
                                                        />
                                                   :
                                                        <ViewAndTrimAudioResourcePopupOpenButton 
                                                            scenario={this.props.scenario}
                                                        onClick={()=>{
                                                            this.setState({...this.state,isViewAndTrimAudioResourcePopupOpen:true,index:index})
                                                        }}
                                                        />
                                                }
                                        </TableCell>}
                                    </TableRow>
                                    
                                </Fade>
                                {currentModelType==="bookTranslation"&& this.state.bookTranslationResourceIdWhoseViewButtonIsExpanded.find(id=> id===String(row._id))&&
                                    <>
                                    <TableRow id={'row'+row._id} >
                                        <TableCell  colSpan={8} >
                                            <div style={{width: "100%"}}>
                                            <LinePairingforParagraphInBookTranslationModel 
                                            updateResourceInUI={this.props.updateResourceInUI}
                                              updateDataChangedList={updateDataChangedList}
                                              addParagraphIdToEnableApprove={addParagraphIdToEnableApprove} removeParagraphIdtoDisableApprove = {removeParagraphIdtoDisableApprove}
                                              paragraphData={
                                                this.state.changedResources.find((resource)=>resource._id===row._id) || row
                                              } 
                                              LabelsAvailable={this.state.labelsAvailable}
                                              allowUserToSetAnyLabel={this.state.allowUserToSetAnyLabel}
                                              setShouldBlockDataTableKeyboardEvents={this.props.setShouldBlockDataTableKeyboardEvents}
                                              selectedSubTab={this.getSelectedSubTab()}
                                              addParagraphToChangedList={addParagraphToChangedList}
                                              />      
                                            </div>
                                            
                                        </TableCell>
                                        
                                    </TableRow>
                                    </>
                                }

                                    {/* {currentModelType === "bookTranslation" && this.state.bookTranslationResourceIdWhoseViewButtonIsExpanded.find(id => id === String(row._id)) &&
                                        <>
                                            <TableRow id={'row' + row._id} >
                                                <TableCell colSpan={7} >
                                                    <div style={{ width: "100%" }}>
                                                        <p style={{ color: "#707070", paddingLeft: "10px" }}>Please review and approve the lines below</p>
                                                        <div style={{
                                                            display: "flex", flexDirection: "row"
                                                        }}>
                                                            <div style={{ width: "40%" }}>
                                                                <p style={{ textAlign: "center", color: "#98A6B3" }}>{row.textOriginalLanguage}</p>
                                                                <div style={{ paddingLeft: "30px" }}>
                                                                    {
                                                                        row.textOriginalLanguage?.length !== 0 &&
                                                                        row.textOriginalLanguageSentences?.map((sentence) => (
                                                                            <div id={'original' + row._id + sentence.sequenceNumber} style={{ padding: "5px 30px", backgroundColor: "#F4F5F7", marginBottom: "5px" }} key={sentence.sentenceId} >
                                                                                <p style={{ marginBottom: "0px" }} key={sentence.sentenceId}>{sentence.sentence.text}</p>
                                                                            </div>

                                                                        ))
                                                                    }
                                                                </div>
                                                            </div>
                                                            <div style={{ width: "100px" }}></div>
                                                            <div style={{ width: "40%" }}>
                                                                <p style={{ textAlign: "center", color: "#98A6B3" }}>{row.textTranslatedLanguage}</p>
                                                                <div>
                                                                    {
                                                                        row.textTranslatedLanguageSentences?.length !== 0 &&
                                                                        row.textTranslatedLanguageSentences?.map((sentence) => (
                                                                            <div id={'translated' + row._id + sentence.sequenceNumber} style={{ marginBottom: "5px", padding: "5px 30px", backgroundColor: "#F4F5F7" }} key={sentence.sentenceId}>
                                                                                <p style={{ marginBottom: "0px" }}>{sentence.sentence.text}</p>
                                                                            </div>

                                                                        ))
                                                                    }
                                                                </div>
                                                            </div>
                                                            <div>
                                                                <p style={{ color: "#98A6B3", textAlign: "center", marginBottom: "0px", display: "flex", flexDirection: "row", justifyContent: "flex-start" }}>Confidence <br></br> Score</p>
                                                                <div>
                                                                    {
                                                                        row.textTranslatedLanguageSentences?.length !== 0 &&
                                                                        row.textTranslatedLanguageSentences?.map((sentence) => (
                                                                            <div id={"action" + row._id + sentence.sequenceNumber} style={{ marginBottom: "5px", height: `${getLineHeight(row._id, sentence.sequenceNumber)}px`, display: "flex", flexDirection: "row", justifyContent: "space-evenly", padding: "0px 14px", gap: "20px" }} key={sentence.sentenceId}>
                                                                                <div style={{ padding: "2px 10px", borderRadius: "10px", height: "fit-content", backgroundColor: "#F4F5F7" }}>{sentence.confidenceScore}</div>
                                                                                <ThumbUpAltOutlinedIcon style={{ color: "#008D9C" }} />
                                                                            </div>
                                                                        ))
                                                                    }
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>

                                                </TableCell>

                                            </TableRow>
                                        </>
                                    } */}
                                
                                </>
                            );
                        })}
                            
                        {!isFetching && !isApiRequestCancelled && !data.resources?.length && this.props.scenario !== "dataCart" && this.props.scenario !== "savedForLater" && !this.props.rowsForDataCart?.length && <NoTableData colSpan={this.state.resourceTableHeadColumnsLength} />}
                        
                    </TableBody>
                    
                }
                    </Table>
                </TableContainer>
                {this.state.anchorElement!==null && <InfoPopover anchorEl={this.state.anchorElement} handleClose={() => this.setState({...this.state, anchorElement : null, labelsToPassWhileApprovingVideoForecastingModelInInfoPopup : []})} labels={this.state.labelsToPassWhileApprovingVideoForecastingModelInInfoPopup}/>}

                {
                    // (data.resources.length > 0) && <TablePagination
                    this.props.scenario !== "dataCart" && this.props.scenario !== "savedForLater" && !this.props.rowsForDataCart
                    &&
                    <TablePagination
                        rowsPerPageOptions={[5, 10, 25, 50, 100]}
                        component="div"
                        count={data?.totalCount || 0}
                        rowsPerPage={rowsPerPage}
                        page={page}
                        onChangePage={this.handleChangePage}
                        onChangeRowsPerPage={this.handleChangeRowsPerPage}
                    />
                }
                {
                    this.state.isImageEditingPopupOpen &&
                    <EditImage  resource={this.props.data.resources[this.state.index]} openPopup={this.state.isImageEditingPopupOpen} onClosePopup={()=> this.setState({isImageEditingPopupOpen : false})}/>
                }
                {
                    this.state.copyImageInMultipleImageAnnotationToImageAnnotationGroupPopup.isOpen &&
                    <CopyImageInMultipleImageAnnotationToImageAnnotationGroupDialog 
                        resource={this.state.copyImageInMultipleImageAnnotationToImageAnnotationGroupPopup.resource}
                        imageIndexInMultipleImageAnnotationResource={this.state.multipleImageAnnotationResourceImageIndex}
                        projectModels={this.state.projectModels}
                        onClose={()=>{
                            this.setState(oldState=>{
                                return {
                                    ...oldState,
                                    copyImageInMultipleImageAnnotationToImageAnnotationGroupPopup: {
                                        ...oldState.copyImageInMultipleImageAnnotationToImageAnnotationGroupPopup,
                                        isOpen: false
                                    }
                                }
                            })
                        }}
                    />
                }
            </Paper>
        );
    }
}

function TagColumnMenu(props: { 
    open: boolean,
    fetchAndSetUniqueResourceTagsForStatus: (value: string) => void,
    tagSearchValue: string,
    tagSearchOperator: ResourceTagSearchOperator,
    onClose: ((event: {}, reason: "backdropClick" | "escapeKeyDown") => void) | undefined
    resetTagFilters: () => void
    isTagFilterApplied: boolean
    uniqueResourceTagsAvailable: string[]
    onTagApplyFilterButtonClick: () => void
    setTagFilterSearchValue: (value: string) => Promise<void>
    classes: { tableColumnMenuHeading: CSSProperties; floating: CSSProperties }
    setTagFilterSearchOperator: (value: ResourceTagSearchOperator) => Promise<void>
}) {

    const [tagSearchTextFieldState, setTagSearchTextFieldState] = useState<InputFieldState<string>>({value: props.tagSearchValue});
    const [tagSearchOperatorState, setTagSearchOperatorState] = useState<InputFieldState<ResourceTagSearchOperator>>({value: props.tagSearchOperator});
    const debouncedInputValue = useDebounce(tagSearchTextFieldState.value, 350);

    useEffect(() => {
      props.fetchAndSetUniqueResourceTagsForStatus(debouncedInputValue);
    }, [debouncedInputValue]);

    return (
      <Popover
        id="tag-column-menu"
        anchorEl={document.getElementById("tagColumnOptions")}
        keepMounted
        anchorOrigin={{
            vertical: 'bottom',
            horizontal: 'right',
        }}
        transformOrigin={{
            vertical: 'top',
            horizontal: 'right',
        }}        
        open={props.open}
        onClose={props.onClose}
        PaperProps={{
          style: {
            width: "450px",
          },
        }}
      >
        {props.open && (
          <Box id="tagMenuItemContainer" style={{ padding: 15 }}>
              <div className={`${props.classes.tableColumnMenuHeading}`}>
                    <Typography
                    variant="body1"
                    style={{ marginBottom: 20 }}
                    color="primary"
                    >
                    &#8288;Tag Column Filter
                    </Typography>
                    <Tooltip title="Reset Filters">
                        <Box>
                            <TableColumnResetFilterButton 
                                onClick={()=>props.resetTagFilters()}
                                disabled={!props.isTagFilterApplied}
                            />
                        </Box>
                    </Tooltip>
              </div>
            <div style={{display: 'flex', justifyContent: 'space-between'}}>
                <TextField
                    size="small"
                    style={{width: '49%'}}
                    variant="outlined"
                    select
                    label="Search Operator"
                    className={`${props.classes.floating}`}
                    value={tagSearchOperatorState.value}
                    onChange={(e)=>{
                        setTagSearchOperatorState(oldState=>{return {...oldState, value: e.target.value as ResourceTagSearchOperator, isDirty: true, isTouched: true}}) 
                    }}
                    >
                    {SearchOperatorAvailableValues.map((option) => (
                        <MenuItem key={option} value={option}>
                        {option}
                        </MenuItem>
                    ))}
                </TextField>
                <div style={{width: 'auto'}}></div>
                <Autocomplete
                    style={{width: "49%"}} 
                    freeSolo
                    options={props.uniqueResourceTagsAvailable}
                    filterOptions={(options, state) => {
                        if (!tagSearchTextFieldState.value) {
                            return options;
                        }
                        const filteredOptions = options.filter(option=>option?.toLowerCase().indexOf(tagSearchTextFieldState.value.toLowerCase()) !== -1)
                        return filteredOptions;
                    }}
                    onChange={(event, value)=>{
                        setTagSearchTextFieldState(oldState=>{
                            return{...oldState, value: value || "", isDirty: true}
                        });                        
                    }}
                    value={tagSearchTextFieldState.value}
                    renderInput={(params)=>{
                        return (
                            <TextField
                                {...params}
                                size="small"
                                variant="outlined"
                                label="Tag search value"
                                className={`${props.classes.floating}`}
                                style={{width: '100%'}}
                                onChange={(e) => {
                                    setTagSearchTextFieldState(oldState=>{
                                        return{...oldState, value: e.target.value, isDirty: true}
                                    });
                                }}
                                onBlur={()=>{
                                    setTagSearchTextFieldState(oldState=>{
                                        return{...oldState, isTouched: true}
                                    });
                                }}
                            />
                        )
                    }}
                />

            </div>

            <div id="resetFilterButtonContainer"
                style={{marginTop: '40px', display: 'flex', justifyContent: 'space-between'}}
            >
                <div></div>
                <Button variant="contained" color="primary"
                    onClick={()=>{
                        props.setTagFilterSearchValue(tagSearchTextFieldState.value)
                        .then(()=>props.setTagFilterSearchOperator(tagSearchOperatorState.value))
                        .then(()=>{
                            props.onTagApplyFilterButtonClick();
                        })
                    }}
                    disabled={!tagSearchTextFieldState.isDirty && !tagSearchOperatorState.isDirty}
                >
                    Apply Filters
                </Button>
            </div>            
          </Box>
        )}
      </Popover>
    );
}

export function NameColumnMenu(props: { 
    open: boolean,
    searchValue: string,
    searchOperator: ResourceNameSearchOperator,
    onClose: ((event: {}, reason: "backdropClick" | "escapeKeyDown") => void) | undefined
    includeOnlyMispredictedParagraphs?:IncludeOnlyMispredictedParagraphsParamType,
    excludeMispredictedParagraphs?:ExcludeMispredictedParagraphsParamType,
    resetFilters: () => void
    isFilterApplied: boolean
    onApplyFilterButtonClick: (filter: any) => void
    setFilterSearchValue: (value: string) => Promise<void>
    setNameFilterIncludeOnlyMispredictedParagraphsValue?:(value: IncludeOnlyMispredictedParagraphsParamType) => Promise<void>,
    setNameFilterExcludeMispredictedParagraphsValue?:(value: ExcludeMispredictedParagraphsParamType) => Promise<void>,
    classes: { tableColumnMenuHeading: CSSProperties; floating: CSSProperties }
    setFilterSearchOperator: (value: ResourceNameSearchOperator) => Promise<void>
    modelType?: IModelType | ""
    uniqueFilterValues?: string[]
    isUniqueFilterValueAvailable ?: boolean
}) {

    console.log("props",props)

    const [searchTextFieldState, setSearchTextFieldState] = useState<InputFieldState<string>>({value: props.searchValue});
    const [searchOperatorState, setSearchOperatorState] = useState<InputFieldState<ResourceNameSearchOperator>>({value: props.searchOperator});
    const [includeOnlyMispredictedParagraphsState, setIncludeOnlyMispredictedParagraphsState] = useState<InputFieldState<IncludeOnlyMispredictedParagraphsParamType>>({value: props.includeOnlyMispredictedParagraphs as IncludeOnlyMispredictedParagraphsParamType});
    const [excludeMispredictedParagraphsState, setExcludeMispredictedParagraphsState] = useState<InputFieldState<ExcludeMispredictedParagraphsParamType>>({value: props.excludeMispredictedParagraphs as ExcludeMispredictedParagraphsParamType});

    
   const handleSetincludeOnlyMispredictedRowsState =(e:any)=>{
    if(excludeMispredictedParagraphsState.value){
        setExcludeMispredictedParagraphsState(oldState=>{return {
            ...oldState,
            value: "false",
            isDirty: true,
            isTouched: true
        }})
    }
    setIncludeOnlyMispredictedParagraphsState(oldState=>{return {
        ...oldState,
        value: e.target.checked ? 'true' : 'false',
        isDirty: true,
        isTouched: true
    }})
   }

   const handleSetExcludeMispredictedRowsState =(e:any)=>{
    
    if(includeOnlyMispredictedParagraphsState){
        setIncludeOnlyMispredictedParagraphsState(oldState=>{return {
            ...oldState,
            value:'false',
            isDirty: true,
            isTouched: true
        }})
    }

    setExcludeMispredictedParagraphsState(oldState=>{return {
        ...oldState,
        value: e.target.checked ? 'true' : 'false',
        isDirty: true,
        isTouched: true
    }})
   }


  const  handleApplyFilterButtonClick =()=>{
    if(props.modelType==="bookTranslation"){
        props.setFilterSearchValue(searchTextFieldState.value)
       .then(()=>props.setFilterSearchOperator(searchOperatorState.value))
       .then(()=>props?.setNameFilterIncludeOnlyMispredictedParagraphsValue  &&  props?.setNameFilterIncludeOnlyMispredictedParagraphsValue(includeOnlyMispredictedParagraphsState.value))
       .then(()=>props?.setNameFilterExcludeMispredictedParagraphsValue && props?.setNameFilterExcludeMispredictedParagraphsValue(excludeMispredictedParagraphsState.value))
       .then(()=>{
        props.onApplyFilterButtonClick({search: searchTextFieldState.value, operator: searchOperatorState.value,includeOnlyMispredictedParagraphs:includeOnlyMispredictedParagraphsState.value,excludeMispredictedParagraphs:excludeMispredictedParagraphsState.value });
        })
        return ;
    }
    props.setFilterSearchValue(searchTextFieldState.value)
    .then(()=>props.setFilterSearchOperator(searchOperatorState.value))
    .then(()=>{
        props.onApplyFilterButtonClick({search: searchTextFieldState.value, operator: searchOperatorState.value, });
    })
   }

    return (
      <Popover
        id="name-column-menu"
        anchorEl={document.getElementById("nameColumnOptions")}
        keepMounted
        anchorOrigin={{
            vertical: 'bottom',
            horizontal: 'right',
        }}
        transformOrigin={{
            vertical: 'top',
            horizontal: 'left',
        }}        
        open={props.open}
        onClose={props.onClose}
        PaperProps={{
          style: {
            width: "450px",
          },
        }}
      >
        {props.open && (
          <Box id="nameMenuItemContainer" style={{ padding: 15 }}>
              <div className={`${props.classes.tableColumnMenuHeading}`}>
                    <Typography
                    variant="body1"
                    style={{ marginBottom: 20 }}
                    color="primary"
                    >
                    &#8288;{
                    props.modelType === "text" || props.modelType === "textAnnotation" || props.modelType === "DLP" 
                    ? "Text" 
                        : props.modelType === "textFile"
                            ? "Text File" :
                            props.modelType === "prompt" 
                            ? "Prompt": props.modelType==="bookTranslation"?"Paragraph"
                            : "Name"
                    } Column Filter
                    </Typography>
                    <Tooltip title="Reset Filters">
                        <Box>
                            <TableColumnResetFilterButton 
                                onClick={()=>props.resetFilters()}
                                disabled={!props.isFilterApplied}
                            />
                        </Box>
                    </Tooltip>
              </div>
            <div style={{display: 'flex', justifyContent: 'space-between'}}>
                <TextField
                    size="small"
                    style={{width: '49%'}}
                    variant="outlined"
                    select
                    label="Search Operator"
                    className={`${props.classes.floating}`}
                    value={searchOperatorState.value}
                    onChange={(e)=>{
                        setSearchOperatorState(oldState=>{return {...oldState, value: e.target.value as ResourceNameSearchOperator, isDirty: true, isTouched: true}}) 
                    }}
                    >
                    {SearchOperatorAvailableValues.map((option) => (
                        <MenuItem key={option} value={option}>
                        {option}
                        </MenuItem>
                    ))}
                </TextField>
                <div style={{width: 'auto'}}></div>
                {
                    props.uniqueFilterValues && props.isUniqueFilterValueAvailable 
                    ?
                    <Autocomplete
                        freeSolo
                        options={props.uniqueFilterValues}
                        style={{width: '49%'}}
                        value={searchTextFieldState.value}
                        filterOptions={(options, state) => {
                             if (!searchTextFieldState.value) {
                                 return options;
                             }
                             const filteredOptions = options.filter(option=>option.toLowerCase().indexOf(searchTextFieldState.value.toLowerCase()) !== -1)
                             return filteredOptions;
                        }}
                        onChange={(event, value) => {
                                setSearchTextFieldState(oldState=>{
                                    return{...oldState, value: value || "", isDirty: true}
                                });
                        }}
                        renderInput={(params) => {
                            return ( 
                            <TextField
                            {...params}
                            size="small"
                            variant="outlined"
                            label={`${props.modelType === "text" || props.modelType === "textAnnotation" || props.modelType === "DLP" ? "Text" : "Name"} search value`}
                            className={`${props.classes.floating}`}
                            onChange={(e) => {
                                setSearchTextFieldState(oldState=>{
                                    return{...oldState, value: e.target.value, isDirty: true}
                                });
                            }}
                            onBlur={()=>{
                                setSearchTextFieldState(oldState=>{
                                    return{...oldState, isTouched: true}
                                });
                            }}
                            />
                        )}}
                    />
                    :                
                <TextField
                    size="small"
                    variant="outlined"
                    label={`${
                        props.modelType === "text" || props.modelType === "textAnnotation" || props.modelType === "DLP" 
                        ? "Text" 
                        : props.modelType === "textFile"
                            ? "Text file":
                            props.modelType === "prompt" 
                            ? "Prompt" :props.modelType==="bookTranslation"?""
                            : "Name"
                    } search value`}
                    className={`${props.classes.floating}`}
                    style={{width: '49%'}}
                    value={searchTextFieldState.value}
                    onChange={(e) => {
                        setSearchTextFieldState(oldState=>{
                            return{...oldState, value: e.target.value, isDirty: true}
                        });
                    }}
                    onBlur={()=>{
                        setSearchTextFieldState(oldState=>{
                            return{...oldState, isTouched: true}
                        });
                    }}
                />
        }
            </div>
             { props.modelType==="bookTranslation" &&  <div style={{paddingTop:"20px"}}>
                 <div style={{display:"flex",justifyContent:"space-between",alignItems:"center"}}>
                   <Typography>Include only mispredicted rows</Typography>
                 <Switch
                    inputProps={{ 'aria-label': 'controlled' }}
            
                    checked={
                        includeOnlyMispredictedParagraphsState.value === 'true'
                        ? true
                        : false
                    }
                    onChange={(e)=>handleSetincludeOnlyMispredictedRowsState(e)}
                    />
                 </div>
                 <div  style={{display:"flex",justifyContent:"space-between",alignItems:"center"}} >
                   <Typography>Exclude mispredicted rows</Typography>
                 <Switch
                    inputProps={{ 'aria-label': 'controlled' }}
                    checked={
                        excludeMispredictedParagraphsState.value === 'true'
                        ? true
                        : false
                    }
                    onChange={(e)=>handleSetExcludeMispredictedRowsState(e)}
                    />
                </div>
             </div> }
            <div id="resetFilterButtonContainer"
                style={{marginTop: `${props.modelType==="bookTranslation"?'20px':"40px"}`, display: 'flex', justifyContent: 'space-between'}}
            >
                <div></div>
                <Button variant="contained" color="primary"
                    onClick={handleApplyFilterButtonClick}
                    disabled={!searchTextFieldState.isDirty && !searchOperatorState.isDirty &&!includeOnlyMispredictedParagraphsState.isDirty && !excludeMispredictedParagraphsState.isDirty}
                >
                    Apply Filters
                </Button>
            </div>            
          </Box>
        )}
      </Popover>
    );
}

function ConfidenceScoreColumnMenu(props: { 
    open: boolean,
    minValue: number,
    maxValue: number,
    onClose: ((event: {}, reason: "backdropClick" | "escapeKeyDown") => void) | undefined
    resetFilters: () => void
    isFilterApplied: boolean
    onApplyFilterButtonClick: () => void
    setFilterMinMaxValue: (value: [number, number]) => Promise<void>
    classes: { tableColumnMenuHeading: CSSProperties; }
}) {

    const [sliderFieldState, setSliderFieldState] = useState<InputFieldState<[number, number]>>({value: [props.minValue, props.maxValue]})

    return (
      <Popover
        id="confidenceScore-column-menu"
        anchorEl={document.getElementById("confidenceScoreColumnOptions")}
        keepMounted
        anchorOrigin={{
            vertical: 'bottom',
            horizontal: 'right',
        }}
        transformOrigin={{
            vertical: 'top',
            horizontal: 'right',
        }}        
        open={props.open}
        onClose={props.onClose}
        PaperProps={{
          style: {
            width: "450px",
          },
        }}
      >
        {props.open && (
          <Box id="confidenceScoreMenuItemContainer" style={{ padding: 15 }}>
              <div className={`${props.classes.tableColumnMenuHeading}`}>
                    <Typography
                    variant="body1"
                    style={{ marginBottom: 20 }}
                    color="primary"
                    >
                    &#8288;Confidence Score Column Filter
                    </Typography>
                    <Tooltip title="Reset Filters">
                        <Box>
                            <TableColumnResetFilterButton 
                                onClick={()=>props.resetFilters()}
                                disabled={!props.isFilterApplied}
                            />
                        </Box>
                    </Tooltip>
              </div>
              <div id="rangeSection">
                <div>Confidence score range</div>
                <div
                    id="rangeInputFieldWrapper"
                    style={{
                    width: "calc(95% - 15px)",
                    marginLeft: "15px",
                    }}
                >
                    <Slider
                        value={sliderFieldState.value}
                        min={0}
                        max={100}
                        color="primary"
                        step={1}
                        marks={[
                            { value: 0, label: "0%" },
                            { value: 50, label: "50%" },
                            { value: 100, label: "100%" },
                        ]}

                        // @ts-ignore
                        onChange={(e, value: [number, number]) => {
                            setSliderFieldState(oldState=>{return {
                                ...oldState, isTouched: true, isDirty: true,
                                value: [value[0], value[1]]
                            }})
                        }}

                        valueLabelDisplay="auto"
                        aria-labelledby="range-slider"
                    />
                </div>
                </div>
                      
            <div id="resetFilterButtonContainer"
                style={{marginTop: '40px', display: 'flex', justifyContent: 'space-between'}}
            >
                <div></div>
                <Button variant="contained" color="primary"
                    onClick={()=>{
                        props.setFilterMinMaxValue(sliderFieldState.value)
                        .then(()=>{
                            props.onApplyFilterButtonClick();
                        })
                    }}
                    disabled={!sliderFieldState.isDirty}
                >
                    Apply Filters
                </Button>
            </div>            
          </Box>
        )}
      </Popover>
    );
}

export function CreatedDateColumnMenu(props: { 
    open: boolean,
    minValue: string,
    maxValue: string,
    scenario?: 'forecastDateFilter' | '',
    onClose: ((event: {}, reason: "backdropClick" | "escapeKeyDown") => void) | undefined
    resetFilters: () => void
    isFilterApplied: boolean
    anchorElId?: 'forecastDateColumnOptions'
    onApplyFilterButtonClick: (filter: any) => void
    setFilterMinMaxValue: (value: [string, string]) => Promise<void>
    classes: { tableColumnMenuHeading: CSSProperties; floating: CSSProperties}
}) {

    const [dateMinValueState, setDateMinValueState] = useState<InputFieldState<string>>({value: props.minValue});
    const [dateMaxValueState, setDateMaxValueState] = useState<InputFieldState<string>>({value: props.maxValue});

    return (
      <Popover
        id="createdDate-column-menu"
        anchorEl={document.getElementById(props.anchorElId || "createdDateColumnOptions")}
        keepMounted
        anchorOrigin={{
            vertical: 'bottom',
            horizontal: 'right',
        }}
        transformOrigin={{
            vertical: 'top',
            horizontal: 'right',
        }}        
        open={props.open}
        onClose={props.onClose}
        PaperProps={{
          style: {
            width: "450px",
          },
        }}
      >
        {props.open && (
          <Box id="createdDateMenuItemContainer" style={{ padding: 15 }}>
              <div className={`${props.classes.tableColumnMenuHeading}`}>
                    <Typography
                    variant="body1"
                    style={{ marginBottom: 20 }}
                    color="primary"
                    >
                    &#8288;{props.scenario === 'forecastDateFilter' ? 'Forecast Date' : 'Date'} Column Filter
                    </Typography>
                    <Tooltip title="Reset Filters">
                        <Box>
                            <TableColumnResetFilterButton 
                                onClick={()=>props.resetFilters()}
                                disabled={!props.isFilterApplied}
                            />
                        </Box>
                    </Tooltip>
              </div>
              <TextField
                    label="Date from"
                    className={`${props.classes.floating}`}
                    type="datetime-local"
                    fullWidth={true}
                    value={dateMinValueState.value}
                    InputLabelProps={{
                        shrink: true
                    }}
                    onChange={(e) => {
                        setDateMinValueState(oldState=>{return {
                            ...oldState,
                            isTouched: true,
                            isDirty: true,
                            value: e.target.value
                        }})
                    }}
                />         
                <div
                    style={{marginTop: "15px"}}
                >
                    <TextField
                        label="Date to"
                        className={`${props.classes.floating}`}
                        type="datetime-local"
                        fullWidth={true}
                        value={dateMaxValueState.value}
                        InputLabelProps={{
                            shrink: true
                        }}
                        onChange={(e) => {
                            setDateMaxValueState(oldState=>{return {
                                ...oldState,
                                isTouched: true,
                                isDirty: true,
                                value: e.target.value
                            }})
                        }}
                    />                                            
                </div>
                      
            <div id="resetFilterButtonContainer"
                style={{marginTop: '40px', display: 'flex', justifyContent: 'space-between'}}
            >
                <div></div>
                <Button variant="contained" color="primary"
                    onClick={()=>{
                        props.setFilterMinMaxValue([dateMinValueState.value, dateMaxValueState.value])
                        .then(()=>{
                            props.onApplyFilterButtonClick([dateMinValueState.value, dateMaxValueState.value]);
                        })
                    }}
                    disabled={!dateMinValueState.isDirty && !dateMaxValueState.isDirty}
                >
                    Apply Filters
                </Button>
            </div>            
          </Box>
        )}
      </Popover>
    );
}

export function LabelColumnMenu(props: { 
    open: boolean,
    labelSearchValue: string[],
    // classFilterOptions: Array<string>;
    predictionStatusLogic: string,
    labelSearchOperator: ResourceLabelSearchOperator,
    includeOnlyMispredictedRowsValue: IncludeOnlyMispredictedRowsQueryParamType,
    onClose: ((event: {}, reason: "backdropClick" | "escapeKeyDown") => void) | undefined
    resetLabelFilters: () => void
    isLabelFilterApplied: boolean
    classes: { tableColumnMenuHeading: CSSProperties; floating: CSSProperties; chip: CSSProperties; autoCompleteChipRoot: CSSProperties }
    onLabelApplyFilterButtonClick: () => void
    setLabelFilterSearchValue: (value: string[]) => Promise<void>
    setLabelFilterSearchOperator: (value: ResourceLabelSearchOperator) => Promise<void>
    setLabelFilterIncludeOnlyMispredictedRowsValue: (value: IncludeOnlyMispredictedRowsQueryParamType) => Promise<void>
    predictionSearchValue : string[],
    predictionSearchOperator: ResourcePredictionSearchOperator,
    setPredictionFilterSearchValue:(value: string[]) => Promise<void>
    setPredictionFilterSearchOperator: (value: ResourcePredictionSearchOperator) => Promise<void>
}) {
    // const classes : useStyles();
    const [labelSearchTextFieldState, setLabelSearchTextFieldState] = useState<string[]>(props.labelSearchValue);
    const [labelSearchOperatorState, setLabelSearchOperatorState] = useState<InputFieldState<ResourceLabelSearchOperator>>({value: props.labelSearchOperator});
    const [predictionSearchTextFieldState, setPredictionSearchTextFieldState] = useState<string[]>(props.predictionSearchValue);
    const [predictionSearchOperatorState, setPredictionSearchOperatorState] = useState<InputFieldState<ResourcePredictionSearchOperator>>({value: props.predictionSearchOperator});
    const [includeOnlyMispredictedRowsFieldState, setIncludeOnlyMispredictedRowsFieldState] = useState<InputFieldState<IncludeOnlyMispredictedRowsQueryParamType>>({value: props.includeOnlyMispredictedRowsValue});

    const [ uniqueLabels, setUniqueLabels] = useState<string[]>([])
    const [ uniquePredictions, setUniquePredictions] = useState<string[]>([])
    const predictionStatusLogic = props.predictionStatusLogic;
    const query = new URLSearchParams(window.location.search);
    const modelId = query.get("model") || "";
    const status : ResourceStatus = query.get(ResourceStatusQueryParam) as ResourceStatus || "backlog";
    
    useEffect(() => {
        const getUniqueLabelsAndPredictions = async() => {
        if(modelId && status){
            try{
                const apiResponse = await DataService.getUniqueLabelsAndPredictions({modelId: modelId, status: status})
                setUniqueLabels(apiResponse.data.uniqueLabels);
                setUniquePredictions(apiResponse.data?.uniquePredictions || []);
            }
            catch(e){
                console.log(e)
            }
        }
        }
        getUniqueLabelsAndPredictions();

    },[window.location.search])

    return (
      <Popover
        id="label-column-menu"
        anchorEl={document.getElementById("labelColumnOptions")}
        keepMounted
        anchorOrigin={{
            vertical: 'bottom',
            horizontal: 'right',
        }}
        transformOrigin={{
            vertical: 'top',
            horizontal: 'right',
        }}        
        open={props.open}
        onClose={props.onClose}
        PaperProps={{
          style: {
            width: "450px",
          },
        }}
      >
        {props.open && (
          <Box id="labelMenuItemContainer" style={{ padding: 15 }}>
              <div className={`${props.classes.tableColumnMenuHeading}`}>
                    <Typography
                    variant="body1"
                    style={{ marginBottom: 20 }}
                    color="primary"
                    >
                    &#8288;Label Column Filter
                    </Typography>
                    <Tooltip title="Reset Filters">
                        <Box>
                            <TableColumnResetFilterButton 
                                onClick={()=>props.resetLabelFilters()}
                                disabled={!props.isLabelFilterApplied}
                            />
                        </Box>
                    </Tooltip>
              </div>              
            <div style={{display: 'flex', justifyContent: 'space-between'}}>
                <TextField
                    size="small"
                    style={{width: '49%'}}
                    variant="outlined"
                    select
                    label="Search Operator"
                    className={`${props.classes.floating}`}
                    value={labelSearchOperatorState.value}
                    onChange={(e)=>{
                        setLabelSearchOperatorState(oldState=>{return {...oldState, value: e.target.value as ResourceLabelSearchOperator, isDirty: true, isTouched: true}}) 
                    }}
                    >
                    {SearchOperatorAvailableValues.map((option) => (
                        <MenuItem key={option} value={option}>
                        {option}
                        </MenuItem>
                    ))}
                </TextField>
                <div style={{width: 'auto'}}></div>
                <Autocomplete
                    multiple
                    id="label-search-filter-value"
                    freeSolo
                    style={{width: '50%'}}
                    options={uniqueLabels}
                    value={labelSearchTextFieldState}
                    onChange={(event, value) => {
                        setLabelSearchTextFieldState([...value]);
                    }}
                    renderTags={(value, getTagProps) => {
                        return value.map((option, index) => (
                            <Chip
                                variant="outlined"
                                color="primary"
                                className={`${props.classes.chip}`}
                                classes={{ root: `${props.classes.autoCompleteChipRoot}` }}
                                label={
                                    <Tooltip title={option}>
                                        <span>
                                            {/* {index + 1}
                                            {labelSearchTextFieldState.filter(
                                                (classValue) =>
                                                    classValue.toUpperCase() === option.toUpperCase()
                                            )[0] || option} */}
                                            {option}
                                        </span>
                                    </Tooltip>
                                }
                                {...getTagProps({ index })}
                            />
                        ));
                    }}
                    renderInput={(params) => (
                        <TextField
                            className={`${props.classes.floating}`}
                            {...params}
                            variant="outlined"
                            size="small"
                            fullWidth
                            label="Label Filter Value"
                            placeholder="Add Label Filter"
                            onChange={(e) => {
                                if (e.target.value.includes(',') && e.target.value.substring(0, e.target.value.indexOf(',')).length > 1) {
                                    setLabelSearchTextFieldState([...labelSearchTextFieldState, e.target.value.substring(0, e.target.value.indexOf(','))])
                                }
                            }}
                            onBlur={(e) => {
                                if (e.target.value && e.target.value.replaceAll(',', '').length) {
                                    const val = e.target.value.replaceAll(',','');
                                    setLabelSearchTextFieldState([...labelSearchTextFieldState, val])
                                }
                            }}
                        />
                    )}
                />
                {/* <TextField
                    size="small"
                    variant="outlined"
                    label="Label search value"
                    className={`${props.classes.floating}`}
                    style={{width: '49%'}}
                    value={labelSearchTextFieldState.value}
                    onChange={(e) => {
                        setLabelSearchTextFieldState(oldState=>{
                            return{...oldState, value: e.target.value, isDirty: true}
                        });
                    }}
                    onBlur={()=>{
                        setLabelSearchTextFieldState(oldState=>{
                            return{...oldState, isTouched: true}
                        });
                    }}
                /> */}
            </div>

            <div style={{display: 'flex', justifyContent: 'space-between', marginTop: '30px'}}>
                <TextField
                    size="small"
                    style={{width: '49%'}}
                    variant="outlined"
                    select
                    label="Prediction Search Operator"
                    className={`${props.classes.floating}`}
                    value={predictionSearchOperatorState.value}
                    onChange={(e)=>{
                        setPredictionSearchOperatorState(oldState=>{return {...oldState, value: e.target.value as ResourcePredictionSearchOperator, isDirty: true, isTouched: true}}) 
                    }}
                    >
                    {SearchOperatorAvailableValues.map((option) => (
                        <MenuItem key={option} value={option}>
                        {option}
                        </MenuItem>
                    ))}
                </TextField>
                <div style={{width: 'auto'}}></div>
                <Autocomplete
                    multiple
                    id="prediction-search-filter-value"
                    freeSolo
                    style={{width: '50%'}}
                    options={uniquePredictions}
                    value={predictionSearchTextFieldState}
                    onChange={(event, value) => {
                        setPredictionSearchTextFieldState([...value]);
                    }}
                    renderTags={(value, getTagProps) => {
                        return value.map((option, index) => (
                            <Chip
                                variant="outlined"
                                color="primary"
                                className={`${props.classes.chip}`}
                                classes={{ root: `${props.classes.autoCompleteChipRoot}` }}
                                label={
                                    <Tooltip title={option}>
                                        <span>
                                            {/* {index + 1}
                                            {labelSearchTextFieldState.filter(
                                                (classValue) =>
                                                    classValue.toUpperCase() === option.toUpperCase()
                                            )[0] || option} */}
                                            {option}
                                        </span>
                                    </Tooltip>
                                }
                                {...getTagProps({ index })}
                            />
                        ));
                    }}
                    renderInput={(params) => (
                        <TextField
                            className={`${props.classes.floating}`}
                            {...params}
                            variant="outlined"
                            size="small"
                            fullWidth
                            label="Prediction Filter Value"
                            placeholder="Add Prediction Filter"
                            onChange={(e) => {
                                if (e.target.value.includes(',') && e.target.value.substring(0, e.target.value.indexOf(',')).length > 1) {
                                    setPredictionSearchTextFieldState([...predictionSearchTextFieldState, e.target.value.substring(0, e.target.value.indexOf(','))])
                                }
                            }}
                            onBlur={(e) => {
                                if (e.target.value && e.target.value.replaceAll(',', '').length) {
                                    const val = e.target.value.replaceAll(',','');
                                    setPredictionSearchTextFieldState([...predictionSearchTextFieldState, val])
                                }
                            }}
                        />
                    )}
                />
            </div>

            <div className="switchFieldContainer" style={{display: 'flex', justifyContent: 'space-between', marginTop: 20}}>
                {predictionStatusLogic !== "labelAndErrorDeviation"  && <>
                    <div className="label" style={{alignSelf: "center"}}>Include only mispredicted rows</div>
                    <div className="field">
                            <Switch color="primary" 
                                checked={
                                    includeOnlyMispredictedRowsFieldState.value === 'true'
                                    ? true
                                    : false
                                }
                                onChange={(e)=>{
                                    setIncludeOnlyMispredictedRowsFieldState(oldState=>{return {
                                        ...oldState,
                                        value: e.target.checked ? 'true' : 'false',
                                        isDirty: true,
                                        isTouched: true
                                    }})
                                }}
                            />
                    </div>
                </>}
                
                </div>

            <div id="resetFilterButtonContainer"
                style={{marginTop: '40px', display: 'flex', justifyContent: 'space-between'}}
            >
                <div></div>
                <Button variant="contained" color="primary"
                    onClick={()=>{
                        // if(uniqueLabels.length!== 0  && labelSearchTextFieldState.length > 0){
                            console.log(" ::::::::::::::: :: ", labelSearchTextFieldState)
                        props.setLabelFilterSearchValue(labelSearchTextFieldState)
                        .then(()=>props.setPredictionFilterSearchValue(predictionSearchTextFieldState))
                        .then(()=>props.setLabelFilterSearchOperator(labelSearchOperatorState.value))
                        .then(()=>props.setPredictionFilterSearchOperator(predictionSearchOperatorState.value))
                        .then(()=>props.setLabelFilterIncludeOnlyMispredictedRowsValue(includeOnlyMispredictedRowsFieldState.value))
                        .then(()=>{
                            props.onLabelApplyFilterButtonClick();
                        })
                    }}
                    disabled={!labelSearchTextFieldState && !labelSearchOperatorState.isDirty && !includeOnlyMispredictedRowsFieldState.isDirty}
                >
                    Apply Filters
                </Button>
            </div>            
          </Box>
        )}
      </Popover>
    );
}

function ImageThumbnail(props: {
    classes: { image: string | undefined; },
    resource: IData,
    dataView: String,
    index: number,
    parentScrollContainerElementId: string;
    onThumbnailClick: ()=>void
}) {
    const [popoverOpen, setPopoverOpen] = useState<boolean>(false);
    const viewType = props.dataView;
    const defaultCanvasWidth = 220
    const defaultCanvasHeight = 220
    const canvasRef = useRef<HTMLCanvasElement>(null);
    const [canvasWidth, setCanvasWidth] = useState(defaultCanvasWidth);
    const [canvasHeight, setCanvasHeight] = useState(defaultCanvasHeight);
    const [preventLoadImage, setPreventLoadImage] = useState<boolean>(true);
    const [isImageInViewport, setIsImageInViewport] = useState(false);
    const [shouldRenderOriginalImage, setShouldRenderOriginalImage] = useState<boolean>(false);
    const imageRef = useRef<any>();

    useEffect(() => {
        const observer = new IntersectionObserver(([entry])=>{
          if (entry.isIntersecting) {
            setIsImageInViewport(true)
              observer.disconnect();
          }
        }, {
            root: document.getElementById(props.parentScrollContainerElementId),
            rootMargin: viewType === "grid-view" ? "1000px" : "500px"
        })
        if (imageRef.current) {
            observer.observe(imageRef.current)
        }
      
        return () => {
            if (observer?.disconnect) {
                observer.disconnect();
            }
        }
      }, [props.parentScrollContainerElementId, viewType])

    useEffect(()=>{
        if(isImageInViewport && preventLoadImage){
            setPreventLoadImage(false)
        }
    },[isImageInViewport, preventLoadImage])

    useEffect(()=>{
        if(preventLoadImage){
            return
        }
        let base_image = new Image();
        base_image.src = (props.resource.resource500WUrl && props.resource.resource500WUrl.length > 0) ? props.resource.resource500WUrl : props.resource.resource;
    
        let file;
        if(props.resource.resource500WUrl && props.resource.resource500WUrl.length > 0){
            base_image.onload = async function () 
            {
                let imageWidth = base_image.width;
                let imageHeight = base_image.height;
                let differenceBetweenCanvasAndImageToSet: number = 0;
                let canvasHeightToSet: number = canvasHeight;
                let canvasWidthToSet: number = canvasWidth;
        
                if (imageWidth > imageHeight)
                    {
                        differenceBetweenCanvasAndImageToSet = imageWidth / defaultCanvasWidth;
                        canvasHeightToSet = imageHeight / differenceBetweenCanvasAndImageToSet ;
                        canvasWidthToSet = defaultCanvasWidth;
                    } 
                else if (imageHeight > imageWidth) {
                    differenceBetweenCanvasAndImageToSet = imageHeight / defaultCanvasHeight;
                    canvasWidthToSet = imageWidth / differenceBetweenCanvasAndImageToSet;
                    canvasHeightToSet = defaultCanvasHeight;
                } 
        
                if(canvasHeightToSet){
                    setCanvasHeight(canvasHeightToSet);
                }
                if(canvasWidthToSet){
                    setCanvasWidth(canvasWidthToSet);
                }
                console.log("width and height are ::", canvasWidthToSet, canvasHeightToSet)
            }
        }
        else{
        fetch(props.resource.resource)
            .then(res => res.blob())
            .then(blob => {
                file = new File([blob], 'dot.png', blob)
                console.log(file);
                const blobURL = URL.createObjectURL(file);
                console.log(blobURL);
                const img = new Image();
                img.src = blobURL;
                img.onload = function () {
                    // URL.revokeObjectURL(this.src);
                    // const [newWidth, newHeight] = calculateSize(img, MAX_WIDTH, MAX_HEIGHT);
                    let imageWidth = img.width;
                    let imageHeight = img.height;
                    let differenceBetweenCanvasAndImageToSet: number = 0;
                    let canvasHeightToSet: number = canvasHeight;
                    let canvasWidthToSet: number = canvasWidth;
                    let blobSizeInMB = blob.size/(1024*1024)
            
                    if (imageWidth > imageHeight)
                        {
                            differenceBetweenCanvasAndImageToSet = imageWidth / defaultCanvasWidth;
                            canvasHeightToSet = imageHeight / differenceBetweenCanvasAndImageToSet ;
                            canvasWidthToSet = defaultCanvasWidth;
                        } 
                    else if (imageHeight > imageWidth) {
                        differenceBetweenCanvasAndImageToSet = imageHeight / defaultCanvasHeight;
                        canvasWidthToSet = imageWidth / differenceBetweenCanvasAndImageToSet;
                        canvasHeightToSet = defaultCanvasHeight;
                    } 
            
                    if(canvasHeightToSet){
                        setCanvasHeight(canvasHeightToSet);
                    }
                    if(canvasWidthToSet){
                        setCanvasWidth(canvasWidthToSet);
                    }

                    if(blobSizeInMB > OneMB){
                        setShouldRenderOriginalImage(false);
                        let canvas = document.getElementById(`canvasImage${props.resource.resource}`) as HTMLCanvasElement;
                        let context;
                        if(canvas){
                            context = canvas.getContext("2d");
                        }
                        // canvas.width = 'grid-view' ? canvasWidthToSet : 40;
                        // canvas.height = 'grid-view' ? canvasHeightToSet : 40;
                        // const ctx = canvas.getContext("2d");
                        context &&
                        (viewType === 'grid-view' ? context.drawImage(img, 0 , 0, canvasWidthToSet, canvasHeightToSet)
                        : context.drawImage(img, 0 , 0, 37, 37))
                        canvas && 
                        canvas.toBlob(
                            (blob) => {
                                // Handle the compressed image. es. upload or save in local state
                                // displayInfo('Original file', file);
                                // displayInfo('Compressed file', blob);
                            },
                            "image/jpeg",
                            0.7
                        );
                    }else{
                        setShouldRenderOriginalImage(true);
                    }
                    
                    // document.getElementById("root").append(canvas);
                };
            })
        } 
    },[props.resource.resource, preventLoadImage, props.resource.resource500WUrl, canvasHeight, canvasWidth, viewType])
 

    return (
        <div ref={imageRef}>
        { viewType === 'grid-view' ?  
            <>
            <div style={{width: `${defaultCanvasWidth}px`, 
                    height: `${defaultCanvasHeight}px`, display: 'flex', alignItems: 'center', justifyContent : 'center'}}>
                <canvas
                    id={`canvasImage${props.resource.resource}`}
                    ref={canvasRef} 
                    className={props.classes.image}
                    width= {canvasWidth} 
                    height= {canvasHeight}
                    
                    style ={{
                        backgroundImage: `url(${preventLoadImage ? "" : props.resource.resource500WUrl && props.resource.resource500WUrl.length > 0 ? props.resource.resource500WUrl : shouldRenderOriginalImage ? props.resource.resource : ""})`,
                        // width: `${canvasWidth}px`, 
                        // height: `${canvasHeight}px`,
                        backgroundSize : `${canvasWidth}px ${canvasHeight}px`
                    }}
                    
                ></canvas>
            </div>  
            </>
        :
        <>
        { 
            !preventLoadImage ?
            ((props.resource.resource500WUrl && props.resource.resource500WUrl.length > 0 )|| shouldRenderOriginalImage || props.resource.model.type==="multipleImageAnnotation") ?
                <Avatar variant="rounded" className={props.classes.image} style={{cursor: 'pointer'}} src={props.resource.model.type==="multipleImageAnnotation"?(props.resource.files?.[0]?.fileUrl):(props.resource.resource500WUrl && props.resource.resource500WUrl.length > 0) ? props.resource.resource500WUrl : props.resource.resource} 
                 onClick={()=>{
                 props.onThumbnailClick();
                 setPopoverOpen(true);
             }}
            />:
            <canvas
            id={`canvasImage${props.resource.resource}`}
            ref={canvasRef}
            className={props.classes.image}
            width={37}
            height={37}
            style={{ cursor: 'pointer', borderRadius: '5px' }}
            onClick={() => {
              props.onThumbnailClick();
              setPopoverOpen(true);
            }}
          ></canvas>
             :
                <Skeleton 
                    variant="rect"
                    style={{
                        margin: 'auto',
                    }}
                    width="30px"
                    height="30px"
                />
            }
       
            <Popover
                open={popoverOpen}
                anchorEl={document.getElementById(`tableCellName${props.index}`)}
                onClose={()=>{setPopoverOpen(false)}}
                anchorOrigin={{
                    vertical: 'top',
                    horizontal: 'center',
                }}
                transformOrigin={{
                vertical: 'bottom',
                horizontal: 'center',
                }}
            >
              { props?.resource?.model?.type === "multipleImageAnnotation"? <img alt="images" className={props.classes.image} style={{width: '100%', height: '256px'}} src={props?.resource?.files?.[0]?.fileUrl} /> : <img alt="images" className={props.classes.image} style={{width: '100%', height: '256px'}} src={(props.resource.resource500WUrl && props.resource.resource500WUrl.length > 0) ? props.resource.resource500WUrl : props.resource.resource} />}
            </Popover>         
        </>
        }
        </div>  
    );
}

function VideoSection(props: {row: IData, dataView:String, index: number, onVideoThumbnailClick: ()=>void, parentScrollContainerElementId: string}) {
    const index = props.index;
    const row = props.row;
    const viewType = props.dataView;
    const [popoverOpen, setPopoverOpen] = useState<boolean>(false);
    const [preventLoadImage, setPreventLoadImage] = useState<boolean>(true);
    const [isImageInViewport, setIsImageInViewport] = useState(false);
    const imageRef = useRef<any>();

    useEffect(() => {
        const observer = new IntersectionObserver(([entry])=>{
          if (entry.isIntersecting) {
            setIsImageInViewport(true)
              observer.disconnect();
          }
        }, {
            root: document.getElementById(props.parentScrollContainerElementId),
            rootMargin: viewType === "grid-view" ? "1000px" : "500px"            
        })
        if (imageRef.current) {
            observer.observe(imageRef.current)
        }
      
        return () => {
            if (observer?.disconnect) {
                observer.disconnect();
            }
        }
      }, [viewType, props.parentScrollContainerElementId])

      useEffect(()=>{
        if(isImageInViewport && preventLoadImage){
            setPreventLoadImage(false)
        }
    },[isImageInViewport, preventLoadImage])

    return (
        <div ref={imageRef}>
        {
            viewType === "grid-view"
            ?
            <video muted controls id={`video${index}`} style={{width: '220px',height: '200px', cursor: 'pointer'}} src={preventLoadImage ? "" : row.resource} 
            onClick={()=>{
                props.onVideoThumbnailClick();
                // setPopoverOpen(true)
            }}
            />
            :
            !preventLoadImage ?
            <video id={`video${index}`} style={{width: '51px', cursor: 'pointer', borderRadius: "5px"}} src={row.resource} 
            onClick={()=>{
                props.onVideoThumbnailClick();
                if (row.model.type === 'video') {
                    setPopoverOpen(true)
                }
            }}
            />
            :
            <Skeleton 
                variant="rect"
                style={{
                    margin: 'auto',
                }}
                width="30px"
                height="30px"
            />
        }
        
        <Popover
            open={popoverOpen}
            anchorEl={document.getElementById(`tableCellName${index}`)}
            onClose={()=>{setPopoverOpen(false)}}
            anchorOrigin={{
                vertical: 'top',
                horizontal: 'center',
            }}
            transformOrigin={{
            vertical: 'bottom',
            horizontal: 'center',
            }}
        >
            <video muted autoPlay style={{width: '100%', height: '256px'}} src={preventLoadImage ? "" : row.resource} controls />
        </Popover>           
        </div>
    )
}


const useTextResourceTableCellStyles = makeStyles((theme) => ({
  verticallyCenterAlign: {
      display: "flex",
      alignItems: "center"
  }
}));
function TextResourceTableCell(props: {
    textResource: String
}) {

    const classes = useTextResourceTableCellStyles();

    const isTextResourceOverflowingInTableCell = useMemo(() => {
        if (props.textResource?.length < 67) {
            return false;
        }
        return true;
    }, [props.textResource?.length])

    return (
        <div style={{width: '175px', overflow:'auto', height: '60px', wordBreak: 'break-word', margin: '4px'}}
            className={`
                ${!isTextResourceOverflowingInTableCell ? classes.verticallyCenterAlign : ""}
            `}
        >
            {props.textResource}
        </div>        
    )
}

export default tableContentConnector(withStyles(useStyles)(withRouter(TableContent as React.ComponentType<any>)));
import { API } from './API';

const URL_STRINGS = {
    UTIL: 'util',
}

class UtilApiRouteService extends API {

    getUniqueId(): Promise<string> {
        return new Promise(async (resolve, reject) => {
            const apiResponse = await this.api.get(`${URL_STRINGS.UTIL}/uniqueId`);
            resolve(apiResponse.data)
        })
    }
}

export default new UtilApiRouteService();

import homepageCss from '../RLEF_homepage.module.css';
import { Box, Input, InputAdornment, Grid, Card, CardContent, Typography, Button, IconButton, Chip, Avatar, Link, Breadcrumbs, CircularProgress } from '@material-ui/core';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import AIMarketPlaceViewModel from '../../projects/features/aiMarketPlace/components/AIMarketPlaceViewModel';
import HomepageModelsCard from './HomepageModelsCard';
import TestDatasetPreview from './TestDatasetPreview';
import ClearIcon from '@material-ui/icons/Clear';
import { IAIresponseMetaData, IGetModelCollectionResponse, ILatestModel } from '../../projects/features/aiMarketPlace/aiMarketPlaceInterfaces/interfaces';
import { useContext, useEffect, useState } from 'react';
import ChatContext from '../../chat/contexts/chat.context';
import ModelAnalyticsResources from '../../projects/features/models/features/model/features/collections/components/ModelAnalyticsResources';
import { getModelCollectionsOfAiTaskDetail } from '../../projects/features/aiMarketPlace/aiMarketPlaceServices';
import { TestDrive } from './TestDrive';
import { goToNextScreen, useDataSelectorStates } from 'rlef-ui-library';
import TestDriveConfirmationDialog from './TestDriveConfirmationDialog';
import { ForwardEmail, ForwardEmailDisabled, IData } from '../../../../../common';
import ModelCloningStage from './ModelCloningStage';
import savedDataSetService from '../../projects/features/aiMarketPlace/services/savedDataSetService';
import useAuth from '../../../../../hooks/useAuth';
import { ArrowOutward } from '@mui/icons-material';
import temporaryFilesService from '../../../../../services/temporaryFiles/temporaryFilesService';
import { IImageObjectForCompression } from '../../../../../services/temporaryFiles/temporaryFilesInterfaces';
import { getRandomElementsFromArray } from '../../../../../services/arrayHelperService';
import { generateDescriptionOfTestDriveImages } from '../../chat/services/llmStudioAssistants.service';
import { IExplainabilityPageMetaData } from '../../chat/contexts/chatContext.provider';
import ExplainabilityContentMarkdown from './SubComponents/ExplainabilityContentMarkdown';

interface PreviewWindowProps{
    modelSummaryPageData?:IGetModelCollectionResponse | {},
    closePreviewWindow:any,
    availableModelCollections?:ILatestModel[] | [],
    fetchedModelInformationPageResources?:Record<string, Record<string, any>>
    isPreviewWindowLoading?:boolean,
    modelInformationPageFetchStatus?:Record<string, Record<string, boolean>>,
    handleShowMoreAIDetailsWindow?:(metaData: IAIresponseMetaData, messageUuid: string) => void;
    // isModelSummaryPageDataFetching?:boolean
    setShouldShowAnalysis: React.Dispatch<React.SetStateAction<boolean>>
}

type TcheckSavedDatasetStage = 0 | 1 | 2  // 0 - not checking, 1 - initiated check, 2 - duplicate dataset exists 

export const homepageChatAssistantScenarioValue="homepageChatAssistant"
const HomePageChatPreviewWindow = (props:PreviewWindowProps) => {
    const {modelSummaryPageData,closePreviewWindow,availableModelCollections,modelInformationPageFetchStatus,isPreviewWindowLoading
        ,fetchedModelInformationPageResources,handleShowMoreAIDetailsWindow, setShouldShowAnalysis
    }=props

    const { currentScreenState, selectedProject, selectedTask } = useDataSelectorStates();
    const chatContext = useContext(ChatContext);

    const { workModePageStack, switchToPreviousPageInWorkModeStack, pushPageInWorkModeStack, selectedRlefResourcesForTestDrive, modelCloningStage, setModelCloningStage, clonedPodDetails, sendImagesForAnnotation, setTestDriveAnalysisMessage } = chatContext;
    const currentPageData = workModePageStack.length > 0 ? workModePageStack[workModePageStack.length - 1] : null;

    const [isFetchingContent, setIsFetchingContent] = useState<boolean>(false)
    const [ modelDetails, setModelDetails ] = useState<IGetModelCollectionResponse[]>([])
    const [ isTestDriveConfirmationDialogOpen, setIsTestDriveConfirmationDialogOpen ] = useState<boolean>(false)
    const [checkSavedDatasetExistence, setCheckSavedDatasetExistence] = useState<TcheckSavedDatasetStage>(0) 
    const [randomImagesFromDataSelectedForTestDrive, setRandomImagesFromDataSelectedForTestDrive] = useState<IImageObjectForCompression[]>([]);
    const {userEmail} = useAuth()

    const fetchModelDetails = async () => {
        setIsFetchingContent(true);
        const purposeModelId = currentPageData?.aiResponseMetaData?.trainingPods?.[0]?.trainingPodId === 'undefined' ? '' : currentPageData?.aiResponseMetaData?.trainingPods?.[0]?.trainingPodId || ''
        const modelCollectionId = currentPageData?.aiResponseMetaData?.trainingPods?.[0]?.modelCollectionId === 'undefined' ? '' : currentPageData?.aiResponseMetaData?.trainingPods?.[0]?.modelCollectionId || ''
        const testDataSetId = currentPageData?.aiResponseMetaData?.trainingPods?.[0]?.testDataSetId === 'undefined' ? '' : currentPageData?.aiResponseMetaData?.trainingPods?.[0]?.testDataSetId || ''

        const apiRes = await getModelCollectionsOfAiTaskDetail({
            purposeModelId,
            modelCollectionId,
            testDataSetId
        })
        if(apiRes?.result){
            const data = apiRes.result
            setModelDetails(data)
        }
        setIsFetchingContent(false);
    }

    useEffect(() => {
        if(currentPageData?.pageType === 'model_analytics_page' ||
            currentPageData?.pageType === 'model_cloning_page' ||
            (currentPageData?.pageType === 'test_drive' &&
                (currentPageData?.isTestDriveButtonClickFromModelSummaryPage === undefined ||
                    !currentPageData.isTestDriveButtonClickFromModelSummaryPage))){
            fetchModelDetails()
        }
        if(isTestDriveConfirmationDialogOpen){
            setIsTestDriveConfirmationDialogOpen(false)
        }
    }, [currentPageData])

    useEffect(() => {
        const randomElements = getRandomElementsFromArray(chatContext.selectedRlefResourcesForTestDrive, Math.min(10, chatContext.selectedRlefResourcesForTestDrive?.length || 0));
        setRandomImagesFromDataSelectedForTestDrive(randomElements.map(elem => { 
            return {
                filename: elem.filename,
                url: elem.resource
            }
        }));

    }, [chatContext.selectedRlefResourcesForTestDrive])

    
    const onClickBackButton = () => {
        switchToPreviousPageInWorkModeStack()
    }

    const onClickViewMoreTestDataInSummaryPage = () => {
        pushPageInWorkModeStack({
            pageType: 'model_analytics_page',
            aiResponseMetaData: {
                pageType: 'model_analytics_page',
                trainingPods: [...(currentPageData?.aiResponseMetaData?.trainingPods || [])]
            },
            messageUuid: currentPageData?.messageUuid || '',
            searchQueryBeforeThisPage: window.location.search,
            metadata: {
                pageTitle: "Test Data"
            }
        })
    }

    const onClickCloneButtonInFooter = () => {
        switch(currentPageData?.pageType) {
            case "test_drive" :
                pushPageInWorkModeStack({
                    pageType: 'model_cloning_page',
                    aiResponseMetaData : currentPageData.aiResponseMetaData,
                    messageUuid: currentPageData.messageUuid,
                    searchQueryBeforeThisPage: window.location.search,
                    metadata: {
                        pageTitle: "Clone",
                        trainingPodId: currentPageData?.aiResponseMetaData?.trainingPods[0]?.trainingPodId,
                    },
                })
                setModelCloningStage('initial')
                break;
            case "model_cloning_page" :
                setModelCloningStage("processing")
                break;
            default:
                break
        }
    }

    const generateDescriptionOfTestDriveImagesFun = async () => {
        //@ts-ignore
        const currentTrainingPodId = currentPageData?.metadata?.trainingPodId;
        // track this training pod as test drove model
        chatContext.setTestDroveTrainingPodIdsInCurrentSession(prev => [...prev, currentTrainingPodId]);

        try {
            // send 10 random images from selected items for compression
            const apiRes = await temporaryFilesService.compressImagesAndStoreAsTempFiles({
                originalImages: randomImagesFromDataSelectedForTestDrive,
                imageSizeThresholdInMb: 1.5,
                temporaryFilesTtlInDays: 7
            });

            const compressedImages = apiRes.data?.compressedImages || [];

            // start generating description of dataset in the background
            const descriptionOfTestDriveResourcesApiRes = await generateDescriptionOfTestDriveImages({
                userEmail: userEmail || '',
                query: {
                    image_urls: compressedImages.map(obj => obj.url)
                }
            });
            console.log("API response for description of test drive images: ", descriptionOfTestDriveResourcesApiRes);
            if (descriptionOfTestDriveResourcesApiRes) {
                chatContext.setMetadataOfCurrentTestDroveTrainingPod(prev => {
                    return {
                        ...prev,
                        trainingPodId: currentTrainingPodId,
                        descriptionOfDataSelectedForTestDrive: descriptionOfTestDriveResourcesApiRes.description
                    }
                })
            }
        }
        catch (e) {
            console.error("Error generating description of test drive images: ", e)
        }
    }

    const OnClickNextButtonInTestDriveFooter = async() => {
        if(activeTestDriveStage?.stage === 'Select Data'){
            if(currentScreenState === 'selectedDataListScreen'){    // test drive button
                setTestDriveAnalysisMessage('')
                setShouldShowAnalysis(false)
                sendImagesForAnnotation(chatContext.selectedRlefResourcesForTestDrive, 'ground');
                try {
                    const apiResponse = await savedDataSetService.DatasetExistence({
                        resourceIds: selectedRlefResourcesForTestDrive?.map(r => r._id),
                        userEmail
                    })
                    console.log(apiResponse.data)
                    if(!apiResponse.data.doesDatasetExist) {
                        setIsTestDriveConfirmationDialogOpen(true);
                        setCheckSavedDatasetExistence(0);
                    } 
                    else setCheckSavedDatasetExistence(2);
                    await generateDescriptionOfTestDriveImagesFun();
                } catch (error) {
                    console.error(error)
                    setCheckSavedDatasetExistence(0);
                }
                return;
            }
            goToNextScreen();
        }
        else if(activeTestDriveStage?.stage === 'Test Drive'){
            chatContext.updateTestDriveStage(2, 100);
            setShouldShowAnalysis(true)
        }
    }

    const goToTestDriveStageOne = () => {
        chatContext.updateTestDriveStage(0, 50)
    }

    const getTestDriveNextButtonText = () => {
        if(activeTestDriveStage?.stage === 'Select Data'){
            switch(currentScreenState) {
                case 'rlefDataPointsInSelectedDataSetCollectionScreen':
                    return 'Proceed'
                case 'selectedDataListScreen':
                    return `Test drive (${chatContext.selectedRlefResourcesForTestDrive.length})`
                default:
                    return 'Next'
            }
        } 
        else if (activeTestDriveStage?.stage === 'Test Drive'){
            return "Go to results"
        }
    }

    const getIsTestDriveNextButtonDisabled = () => {
        let disabled = false;
        if(activeTestDriveStage?.stage === 'Select Data'){
            switch(currentScreenState) {
                case 'projectAndTaskSelectionScreen':
                    if(!selectedProject || !selectedTask) disabled = true;
                    break;
                case 'selectedDataListScreen':
                    if(chatContext.selectedRlefResourcesForTestDrive.length === 0 || checkSavedDatasetExistence !== 0) disabled = true;
                    break;
                // case 'rlefDataPointsInSelectedDataSetCollectionScreen':
                //     return chatContext.selectedRlefResourcesForTestDrive.length === 0;
                default:
                    break;
            }
        }
        else if (activeTestDriveStage?.stage === 'Test Drive'){
            disabled = !chatContext.isTestDriveCompleted
        }

        return disabled;
    }

    const onCloseTestDriveConfirmationDialog = () => {
        setIsTestDriveConfirmationDialogOpen(false);
    }

    const getClonedPodLink = () => {
        return `${window.location.origin}/admin/projects/${clonedPodDetails.projectName}/${clonedPodDetails.modelName}/addNewModel/aboutModel/problemType?offset=1&limit=10&model=${clonedPodDetails.modelId}&project=${clonedPodDetails.projectId}&purposeModelId=${clonedPodDetails.purposeModelId}`
    }
    
    const shouldShowTestDriveAgainBtn = chatContext?.testDriveStage[2]?.isDone && currentPageData?.pageType !== 'model_cloning_page'
    const isBackArrowButtonDisabled = workModePageStack.length <= 1
    const shouldShowFooter = currentPageData?.pageType === 'test_drive' || currentPageData?.pageType === 'model_cloning_page'
    const activeTestDriveStage = chatContext.testDriveStage.find((stage) => stage.isActive);
    const shouldShowNextButtonForTestDrive = currentPageData?.pageType === 'test_drive' && ((activeTestDriveStage?.stage === 'Select Data' && ['projectAndTaskSelectionScreen', 'rlefDataPointsInSelectedDataSetCollectionScreen', 'selectedDataListScreen', 'savedDataSetListScreen', 'viewSavedDataSetScreen', 'rlefHistoricalCopilotDataScreen'].includes(currentScreenState))
                                                || (activeTestDriveStage?.stage === 'Test Drive' && chatContext.isTestDriveCompleted))
    const shouldShowEmailMeResultsButtonInTestDriveStage2 = activeTestDriveStage?.stage === 'Test Drive' && !chatContext.isTestDriveCompleted && currentPageData?.pageType==='test_drive'
    const isEmailMeResultsButtonDisabled = true;
    const shouldShowCloneButton = (chatContext?.testDriveStage[2]?.isDone && currentPageData?.pageType !== 'model_cloning_page')  || (currentPageData?.pageType === 'model_cloning_page' && ['initial', 'ready'].includes(modelCloningStage))
    const shouldShowCloningText = modelCloningStage==='processing'
    const shouldShowClonedPodButton = modelCloningStage==='done'


    return (
        <Box className={`${homepageCss.showMoreAIDetailsWindowCss}`} style={{position:"relative"}} id={'openRlefLibraryDialogRelativeToThisContainer'}>
            <Box className={homepageCss.showMoreAIDetailsWindowHeader}>
                <Box display="flex" alignItems="center" style={{ gap: '0.5rem' }}>
                    {/* <ArrowBackIcon
                        style={{
                            cursor: isBackArrowButtonDisabled ? 'auto' : 'pointer',
                            opacity: isBackArrowButtonDisabled ? 0.3 : 1
                        }}
                        onClick={!isBackArrowButtonDisabled ? onClickBackButton : undefined}
                    /> */}
                    <Typography variant='h6' style={{ color: '#242424CC', fontWeight: 700 }}>
                        {currentPageData?.metadata?.pageTitle}
                    </Typography>
                </Box>
                <ClearIcon onClick={closePreviewWindow} style={{ cursor: 'pointer' }} />
            </Box>
            <Box className={homepageCss.customScroll} style={{height: '100%', padding:`${currentPageData?.pageType === "test_drive" ? "0" : "16px"}`, pointerEvents: checkSavedDatasetExistence ? 'none' : 'auto',
                ...(currentPageData?.pageType === "explainability_header_content_page" && { backgroundColor: '#fafbff' }),
             }} >
                {
                    isFetchingContent ? (currentPageData?.pageType === 'model_analytics_page' ? '': <CircularProgress />)
                    : currentPageData?.pageType === "summary_page" ?
                        <AIMarketPlaceViewModel viewModelPageData={modelSummaryPageData} messageUuidFromParentPage={currentPageData?.messageUuid || ''} isFetching={isPreviewWindowLoading} scenario={homepageChatAssistantScenarioValue} onClickViewMoreTestData={onClickViewMoreTestDataInSummaryPage} fetchedModelInformationPageResources={fetchedModelInformationPageResources} modelInformationPageFetchStatus={modelInformationPageFetchStatus}/>
                        : currentPageData?.pageType === "model_information_page" ? <HomepageModelsCard scenario="homePageChat" handleShowMoreAIDetailsWindow={handleShowMoreAIDetailsWindow} isFetching={isPreviewWindowLoading} availableModelsData={availableModelCollections || []} fetchedModelResources={fetchedModelInformationPageResources|| {}} modelFetchStatus={modelInformationPageFetchStatus|| {}} />
                        : currentPageData?.pageType === "testing_data" ?
                            <TestDatasetPreview availableModelsData={availableModelCollections} fetchedModelResources={fetchedModelInformationPageResources} modelFetchStatus={modelInformationPageFetchStatus} isFetching={isPreviewWindowLoading} />
                            : currentPageData?.pageType === 'model_analytics_page' ?    
                                <ModelAnalyticsResources
                                    scenario='ChatBotWindow'
                                    queryParamsForNonDefaultScenarios={{
                                        model: modelDetails?.[0]?.model?._id || '',
                                        project: modelDetails?.[0]?.project?._id || '',
                                        limit: 10,
                                        offset: 1,
                                        type: modelDetails?.[0]?.model?.type || '',
                                        status: 'dashboard',
                                        modelCollectionIdFilterSelected: modelDetails?.[0]?._id,
                                        modelCollectionIdsToCompare: modelDetails?.[0]?._id,
                                        scenario: 'integrity',
                                        //@ts-ignore
                                        accuracy: Number(modelDetails?.[0]?.checkpointFiles?.files?.analysis?.cachedMetrics?.accuracy?.accuracy || 0),
                                        bandFilterSelected: 'ALL',
                                        iouThreshold: modelDetails?.[0]?.model?.iouThresholdValue,
                                    }}
                                />
                                : currentPageData?.pageType === "test_drive" ?
                                <TestDrive 
                                //@ts-ignore
                                    trainingPodId={currentPageData.metadata?.trainingPodId || ''}
                                    modelSummaryPageData={  Object.keys(modelSummaryPageData || {}).length === 0 ? modelDetails[0] : modelSummaryPageData}
                                 />
                                 :currentPageData?.pageType === "model_cloning_page" ? 
                                 <ModelCloningStage trainingPodDetails={modelDetails[0]} />
                                //  :currentPageData?.pageType==="testing_data"?
                                //  <TestDatasetPreview scenario="homePageBrowsingMode" availableModelsData={availableModelCollections} fetchedModelResources={fetchedModelInformationPageResources} modelFetchStatus={modelInformationPageFetchStatus} isFetching={isPreviewWindowLoading} />
                                : currentPageData?.pageType === 'explainability_header_content_page' ?
                                <ExplainabilityContentMarkdown
                                    content={'**' + (currentPageData.metadata as IExplainabilityPageMetaData)?.responseFrom + '** <br>\n' + (currentPageData.metadata as IExplainabilityPageMetaData)?.responsedata}
                                    // heading3={(currentPageData.metadata as IExplainabilityPageMetaData)?.responseFrom}
                                />
                                 :<></>

                }
            </Box>
            {
                shouldShowFooter
                &&
                <div style={{width: '100%', height: '4em', borderTop: 'solid 1px #D7D6D6', display: 'flex', alignItems: 'center', justifyContent: 'end', gap: '16px', padding: '4px 16px'}}>
                    {
                        shouldShowTestDriveAgainBtn
                        &&
                            <Button
                                onClick={goToTestDriveStageOne}
                                style={{
                                    width: 195,
                                    textTransform: 'none'
                                }}
                                size='small'
                                color='primary'
                                variant='outlined'
                            >
                                Test drive again
                            </Button>
                    }
                    {
                        shouldShowEmailMeResultsButtonInTestDriveStage2
                        &&
                        <Button variant='outlined' color='primary' startIcon={isEmailMeResultsButtonDisabled ? <ForwardEmailDisabled /> : <ForwardEmail />} size='small' disabled style={{textTransform: 'none', borderRadius: 6}} >
                            Notify results once done
                        </Button>
                    }
                    {
                        // main action button in mui contained variant............
                        shouldShowNextButtonForTestDrive
                        &&
                        <Button
                            onClick={() => OnClickNextButtonInTestDriveFooter()}
                            style={{
                                width: 195,
                                textTransform: 'none'
                            }}
                            size='small'
                            color='primary'
                            variant='contained'
                            disabled={getIsTestDriveNextButtonDisabled()}
                        >
                        {checkSavedDatasetExistence !== 0 && <CircularProgress color={'inherit'} size={18} style={{marginRight: 10}} />}
                        {getTestDriveNextButtonText()}
                    </Button>
                    }
                    {
                        shouldShowCloneButton
                        &&
                        <Button
                            style={{
                                width: 195,
                                textTransform: 'none'
                            }}
                            size='small'
                            color='primary'
                            variant='contained'
                            onClick={onClickCloneButtonInFooter}
                            disabled={modelCloningStage === 'initial'}
                        >
                            Clone
                        </Button>
                    }
                    {
                        shouldShowCloningText
                        &&
                        <div style={{
                            background: 'linear-gradient(to right, #76B0B6, #008998)',
                            WebkitBackgroundClip: "text",
                            WebkitTextFillColor: "transparent",
                        }}>
                            Cloning is in progress.
                        </div>
                    }
                    {
                        shouldShowClonedPodButton
                        &&
                        <Button
                            style={{
                                width: 195,
                                textTransform: 'none',
                                fontSize: 16,
                                fontWeight: 500,
                                color: 'white'
                            }}
                            size='small'
                            color='primary'
                            variant='contained'
                            target='_blank'
                            rel="noreferrer noopener"
                            href={getClonedPodLink()}
                        >
                            <ArrowOutward width={24} height={24} style={{marginInlineEnd: '10px'}} />
                            Go to cloned pod
                        </Button>
                    }
                </div>
            }
            {/* @ts-ignore */}
            <TestDriveConfirmationDialog open={isTestDriveConfirmationDialogOpen} onClose={onCloseTestDriveConfirmationDialog} modelCollectionId={currentPageData?.metadata?.modelCollectionId || ''} checkSavedDatasetExistence={checkSavedDatasetExistence} closeCheckingSavedDatasetExistence={setCheckSavedDatasetExistence} 
                // @ts-ignore
                trainingPodId={currentPageData?.metadata?.trainingPodId}
            />
        </Box>
    )
}

export default HomePageChatPreviewWindow
import React from 'react';
import CircularProgress, { CircularProgressProps } from '@material-ui/core/CircularProgress';
import Typography from '@material-ui/core/Typography';
import Box from '@material-ui/core/Box';
import CheckCircleSharpIcon from '@material-ui/icons/CheckCircleSharp';
import TestDriveModuleCss from './TestDrive.module.css'
import { TestDriveStage } from '../../chat/contexts/chatContext.provider';
function CircularProgressWithLabel(props: CircularProgressProps & { value: number } & { title: string } & { isDone: boolean } & { seqNo: number } & {isActive:boolean}) {
    return (
        <Box position="relative" display="inline-flex" style={{  borderRadius: "50%" }} >
            <CircularProgress
                variant="determinate"
                value={100}
                thickness={6}
                style={{
                    color: '#C9D6D7', // Light gray for the background circle
                    position: 'absolute',
                }}
            />

            <CircularProgress variant="determinate" {...props} style={{ color: '#008D9C' }} thickness={6} />
            <Box
                top={0}
                left={0}
                bottom={0}
                right={0}
                position="absolute"
                display="flex"
                alignItems="center"
                justifyContent="center"
            >
                {props.isDone ? <CheckCircleSharpIcon style={{ width: "25px", height: "25px", color: "#008D9C" }} /> : <span style={{ color: `${(props.isActive || props.isDone) ? '#008D9C' :'#92B4B7'}`, fontSize: "20px", fontWeight: 700 }}>{props.seqNo}</span>}

                {/* <Typography variant="caption" component="div" style={{color:'#65B168',fontFamily:'Inter',fontWeight:700,fontSize:'11px'}} >{`${Math.round(
                    props.value
                )}%`}</Typography> */}

            </Box>
            <span style={{ color: `${(props.isActive || props.isDone) ? '#008D9C' :'#92B4B7'}`, fontSize: "14px", fontWeight: 700, letterSpacing: "0.4px", position: "absolute", bottom: "calc(100% + 2px)", left: "0px", transform: "translateX(-30%)", textAlign: "center", width: "100px" }}>{props.title}</span>
        </Box>
    );
}

export interface ITestDriveProgressBarComponetProps {
    progressState: TestDriveStage[]
    // setProgressState: React.Dispatch<React.SetStateAction<TestDriveStage[]>>
}

const TestDriveProgressComponent = (props: ITestDriveProgressBarComponetProps) => {
    const { progressState } = props
    const getLineColor = (isActive: boolean) => (isActive ? "#C9D6D7" : "#C9D6D7"); // primary for active, grey otherwise (as per navjot suggestion puttin grey aslo as active later we change)
    return (
        <div className={TestDriveModuleCss.testDriveProgressContainer}>
            <Box style={{flexGrow: 1, minWidth: 0}}></Box>
            {progressState.map((stage : TestDriveStage, index) => (
                <React.Fragment key={stage.stage}>
                    <CircularProgressWithLabel
                        value={stage.percentage}
                        title={stage.stage}
                        isDone={stage.isDone && stage.percentage === 100}
                        seqNo={index + 1}
                        isActive={stage.isActive}
                        className={TestDriveModuleCss.circularProgress}
                    />
                    {index < progressState.length - 1 && (
                        <div
                            className={TestDriveModuleCss.testDriveProgressBar}
                            style={{
                                backgroundColor: getLineColor(progressState[index + 1].isActive),
                                transition: "background-color 0.5s ease", // Smooth transition effect
                            }}
                        ></div>
                    )}
                </React.Fragment>
            ))}
            <Box style={{flexGrow: 1, minWidth: 0}}></Box>
            {/* <CircularProgressWithLabel value={85} title={"Select Data"} isDone={true} seqNo={1} className={TestDriveModuleCss.circularProgress} />
            <div className={TestDriveModuleCss.testDriveProgressBar}></div>
            <CircularProgressWithLabel value={20} title={"Test Drive"} isDone={false} seqNo={2} className={TestDriveModuleCss.circularProgress} />
            <div className={TestDriveModuleCss.testDriveProgressBar}></div>
            <CircularProgressWithLabel value={60} title={"Results"} isDone={false} seqNo={3} className={TestDriveModuleCss.circularProgress} /> */}
        </div>
    );
}

export default TestDriveProgressComponent;
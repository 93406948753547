import {
  Box,
  Button,
  Checkbox,
  CircularProgress,
  Dialog,
  DialogActions,
  DialogContent,
  FormControl,
  FormControlLabel,
  FormHelperText,
  Grid,
  IconButton,
  InputLabel,
  makeStyles,
  MenuItem,
  Paper,
  Popover,
  Select,
  Switch,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TablePagination,
  TableRow,
  TableSortLabel,
  TextField,
  Tooltip,
  Typography,
  withStyles,
} from "@material-ui/core";
import React, { CSSProperties, useContext, useEffect, useRef, useState } from "react";
import { connect, ConnectedProps, useDispatch } from "react-redux";
import { Delete } from "@material-ui/icons";
import { useHistory, useLocation } from "react-router-dom";
import FilterListIcon from "@material-ui/icons/FilterList";
import MoreVertIcon from "@material-ui/icons/MoreVert";
import DataSetCollectionView from "./DataSetCollectionView";
import { NoTableData } from "../../data/constants";
import FullscreenIcon from "@material-ui/icons/Fullscreen";
import {
  convertDate,
  CustomSkeleton,
  ICollection,
  IReduxState,
  queryObjectToString,
  useQuery,
  DataSetSortField,
  SortOrder,
  DataTableNameSearchOperatorDefaultValue,
  StringSearchOperator,
  // DataTableNameSearchValueQueryParam,
  // DataTableNameSearchOperatorQueryParam,
  // DataTableCreatedDateMaxValueQueryParam,
  // DataTableCreatedDateMinValueQueryParam,
  // DataTableDescriptionSearchValueQueryParam,
  // DataTableDescriptionSearchOperatorQueryParam,
  IModel,
  Navigation,
  IDataResponse,
  IData,
} from "../../../../../../../../../../../common";
import {
  addSelectedDataSetCollections,
  deleteCollection,
  getCollections,
  setSelectedDataSetCollection,
  setSelectedDataSetCollections,
  setSideDrawer,
  setTopActionButton,
  setDefaultDatasetCollection,
  setDrawerOpen,
} from "../../../../../../../../../../../store/actions";
import AddDataSetCollection from "./AddDataSetCollection";
import ModelAnalytics from "../../../../../components/Analytics";
import { deleteButtonIconColor } from "../../data/constants/constants";
import { useURLQuery } from "../../../../../../../../../../../hooks/useURLQuery";
import { convertURLQueryParamsToJSON } from "../../../../../../../../../../../services/urlQueryParamService";
import CollectionEnhancedTableToolbar from "../components/CollectionEnhancedTableToolbar";
import {
  tableContainerStyle,
  tablePaperStyle,
} from "../../../constants/tableStyles";
import { useTableHeadCellsCount } from "../hooks/useTableHeadCellsCount";
import TrainDataSetCollectionButton from "./trainDataSetCollectionButton.component";
import AddModelCollection, {
  AddModelCollectionScenario,
} from "./AddModelCollection";
import {
  confirmDeleteCollection,
  setIsDataSetCollectionResourcesFileDownloadLinkBeingFetched,
  setTrainingDataDirectoriesSelectedStateForAddModelCollection,
} from "../../../../../../../../../../../store/actions/collectionActions";
import ReTrainDataSetCollectionButton from "./reTrainDataSetCollectionButton.component";
import MergeDataSetsButton from "./MergeDataSetsButton";
import {
  DataService,
  ModelService,
} from "../../../../../../../../../../../services";
import {
  convertNameSearchOperatorToRegex,
  CreatedDateColumnMenu,
  NameColumnMenu,
  SearchOperatorAvailableValues,
} from "../../data/components/TableContent";
import { InputFieldState } from "../../../../../../../../../../../common/constants/interfaces/inputFieldState";
import { TableColumnResetFilterButton } from "../../data/components/TableColumnResetFilterButton";
import DeleteCollectionErrorPopup from "./DeleteCollectionErrorPopup";
import DeleteCollectionConfirmPopup from "./confirmCollectionDelete";
// import Menu from '@material-ui/core/Menu';
// import Fade from '@material-ui/core/Fade';
// import MenuOpenIcon from '@material-ui/icons/MenuOpen';
import SubActionMenu from "./SubAction-Menu";
import { Alert } from "@material-ui/lab";
import DataSetCollectionPopup from "./DataSetCollectionPopup";
import AddPromptAnsInDataSetDialog from "./addPromptAnsInDataSetPopup/addPromptAnsInDataSetPopup";
import useDebounce from "../../../../../../../../../../../hooks/useDebounce";
import BrowseCollectionsPopupContext from "../../../../../browseCollectionsPopupContext/browseCollectionsPopup.context";
import { GET_COLLECTION_SUCCESS } from "../../../../../../../../../../../store/types";
// import DataSetCollectionResourcesTable from "./dataSetCollectionResourcesTable";

const tableCellViewWidth = "120px";

export enum DataSetCollectionTableQueryParam {
  OFFSET = "offset",
  LIMIT = "limit",
  MODEL_ID = "model",
  MODEL_TYPE = "type",
  PROJECT_ID = "project",
}
const defaultTestPercentage = '30'
const defaultTrainPercentage = '70'
export enum DataSetCollectionTableFilterQueryParams {
  DATASET_COLLECTION_TABLE_NAME_SEARCH_VALUE_QUERY_PARAM = "nameSearchValue",
  DATASET_COLLECTION_TABLE_NAME_SEARCH_OPERATOR_QUERY_PARAM = "nameSearchOperator",
  DATASET_COLLECTION_TABLE_DESCRIPTION_SEARCH_VALUE_QUERY_PARAM = "descriptionSearchValue",
  DATASET_COLLECTION_TABLE_DESCRIPTION_SEARCH_OPERATOR_QUERY_PARAM = "descriptionSearchOperator",
  DATASET_COLLECTION_TABLE_CREATED_DATE_MIN_VALUE_QUERY_PARAM = "createdDateMinValue",
  DATASET_COLLECTION_TABLE_CREATED_DATE_MAX_VALUE_QUERY_PARAM = "createdDateMaxValue",
}

const useStyles = makeStyles((theme) => ({
  paper: tablePaperStyle(theme),
  table: {
    // minWidth: 750,
    tableLayout: "fixed",
  },
  tableRow: {
    width: "100%",
  },
  tableCellCheckbox: {
    width: `58px !important`,
  },
  tableCellName: {
    "@media(min-width:1000px)": {
      width: "auto",
    },
    "@media(min-width:700px) and (max-width: 1000px)": {
      width: "350px",
    },
    "@media(max-width: 700px)": {
      width: "325px",
    },
    textAlign: "center",
  },
  tableCellDescription: {
    "@media(min-width:1000px)": {
      width: "auto",
    },
    "@media(min-width:700px) and (max-width: 1000px)": {
      width: "400px",
    },
    "@media(max-width: 700px)": {
      width: "325px",
    },
    textAlign: "center",
  },
  tableCellTags: {
    width: "auto",
    textAlign: "center",
  },
  tableCellCreatedDate: {
    "@media(min-width:1000px)": {
      width: "auto",
    },
    "@media(min-width:700px) and (max-width: 1000px)": {
      width: "220px",
    },
    "@media(max-width: 700px)": {
      width: "180px",
    },
    textAlign: "center",
  },
  tableCellActions: {
    "@media(min-width:1000px)": {
      width: "230px",
    },
    "@media(min-width:700px) and (max-width: 1000px)": {
      width: "150px",
    },
    "@media(max-width: 700px)": {
      width: "125px",
    },
    textAlign: "center",
  },
  chip: {
    minWidth: "100px",
    backgroundColor: theme.palette.grey[50],
    marginRight: theme.spacing(1),
  },
  deleteButton: {
    color: deleteButtonIconColor,
  },
  tableContainer: tableContainerStyle,
  root: {
    marginBottom: theme.spacing(2),
    position: "sticky",
    top: "0px",
    backgroundColor: "#f0f5fc",
    zIndex: 1,
  },
  indicator: {
    backgroundColor: "transparent",
  },
  sortLabelIcon: {
    display: "none",
  },
  sortLabelRoot: {
    "&:hover .MuiTableSortLabel-icon": {
      display: "flex",
    },
  },
  sortLabelActive: {
    minWidth: "111px",
    color: "inherit !important",
    "& .MuiTableSortLabel-icon": {
      display: "flex",
    },
  },
  columnOptionsIcon: {
    padding: 0,
  },
  tableColumnMenuHeading: {
    display: "flex",
    justifyContent: "space-between",
  },
  floating: {
    "& legend": {
      float: "unset",
    },
  },
  disabledDeleteBtn: {
    opacity: "0.6",
    color: "#EF8A8A !important",
  },
  defaultTextColor: {
    color: theme.palette.primary.main,
  },
  defaultDatasetRowBorder: {
    boxShadow: `inset 0px 0px 3px 2px ${theme.palette.primary.main}`,
    "& td": {
      borderBottom: "none",
    },
    borderRadius: "10px",
  },
  tableCellView: {
    width: tableCellViewWidth,
    textAlign: "center",
  },
  rowSelected: {
    backgroundColor: "#CCE8EB !important",
    "&:hover": {
      backgroundColor: "#CCE8EB !important",
      opacity: 1,
    },
  },
  viewIcon: {
    margin: 0,
  },
  highlightRow: {
    background: "#ffffe2",
  },
  copyDialog: {
    minWidth: "500px",
  },
  splitDialog: {
    minWidth: "600px",
  },
  formControl: {
    margin: theme.spacing(1),
    minWidth: 120,
    width: "95%",
  },
  flexContainer: {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
  },
  copyAlert: {
    margin: "10px auto",
  },
  header: {
    marginBottom: theme.spacing(2),
    fontWeight: 'bold',
  },
  inputRow: {
    marginBottom: theme.spacing(2),
  },
}));

/*
const collectionTabs = [
  { label: "Backlog", path: "/backlog", status: "backlog" },
  { label: "Approved", path: "/approved", status: "approved" },
  { label: "Deleted", path: "/deleted", status: "deleted" },
];
*/

function DataSetCollection(props: TPropsFromRedux) {
  const classes = useStyles();
  const history = useHistory();
  const location = useLocation();
  const dispatch = useDispatch();
  const dataCartContext = useContext(BrowseCollectionsPopupContext);
  const { aiMarketplaceDataCartState } = dataCartContext;
  // const { search, pathname } = location;
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [page, setPage] = useState(0);

  const query = useQuery();

  const [addPromptAnsInDataSetPopup, setAddPromptAnsInDataSetPopup] = useState({
    open: false,
    dataSetCollectionIdOfPrompt: "",
    dataSetCollectionIdOfTextFileModelToInsertIn: "",
  });

  const dataSetCollectionOffsetQueryParamValue =
    query.get(DataSetCollectionTableQueryParam.OFFSET) || "";
  const dataSetCollectionLimitQueryParamValue =
    query.get(DataSetCollectionTableQueryParam.LIMIT) || "";
  const dataSetCollectionSortOrderQueryParamValue =
    query.get("sortOrder") || "";
  const dataSetCollectionSortFieldQueryParamValue =
    query.get("sortField") || "";
  const dataSetCollectionNameSearchQueryParamValue =
    query.get(
      DataSetCollectionTableFilterQueryParams.DATASET_COLLECTION_TABLE_NAME_SEARCH_VALUE_QUERY_PARAM
    ) || "";
  const dataSetCollectionNameSearchOperatorQueryParamValue =
    query.get(
      DataSetCollectionTableFilterQueryParams.DATASET_COLLECTION_TABLE_NAME_SEARCH_OPERATOR_QUERY_PARAM
    ) || "";
  const dataSetCollectionDescriptionSearchQueryParamValue =
    query.get(
      DataSetCollectionTableFilterQueryParams.DATASET_COLLECTION_TABLE_DESCRIPTION_SEARCH_VALUE_QUERY_PARAM
    ) || "";
  const dataSetCollectionDescriptionSearchOperatorQueryParamValue =
    query.get(
      DataSetCollectionTableFilterQueryParams.DATASET_COLLECTION_TABLE_DESCRIPTION_SEARCH_OPERATOR_QUERY_PARAM
    ) || "";
  const dataSetCollectionCreatedDateMinQueryParamValue =
    query.get(
      DataSetCollectionTableFilterQueryParams.DATASET_COLLECTION_TABLE_CREATED_DATE_MIN_VALUE_QUERY_PARAM
    ) || "";
  const dataSetCollectionCreatedDateMaxQueryParamValue =
    query.get(
      DataSetCollectionTableFilterQueryParams.DATASET_COLLECTION_TABLE_CREATED_DATE_MAX_VALUE_QUERY_PARAM
    ) || "";

  const highlightedCollectionId = query?.get("highlight") || "";
  const highlightedCollectionIdFromModel =
    query?.get("highlightModelCollectionId") || "";
  const [addModelCollectionScenario, setAddModelCollectionScenario] =
    useState<AddModelCollectionScenario>("trainingDataSetCollections");

  /** Array of Collections selected using checkbox */

  const urlQuery = useURLQuery();
  const {
    collections,
    selected,
    isFetching,
    getCollections,
    setSideDrawer,
    isCollectionAddSuccess,
    setSelectedCollection,
    setTopActionButton,
    deleteCollection,
    confirmDeleteCollection,
    isCollectionDeleteSuccess,
    isCollectionsDeleting,
    isSideDrawerOpen,
    selectedCollections,
    setSelectedCollections,
    addSelectedDataSetCollections,
    trainingDataDirectoriesSelectedStateForAddModelCollection,
    setTrainingDataDirectoriesSelectedStateForAddModelCollection,
  } = props;
  useEffect(() => {
    setSelectedCollections([]);
    return () => {
      setSelectedCollections([]);
    };
  }, []);

  const tableId: string = "table";
  const tableHeadCellsCount: number = useTableHeadCellsCount(tableId, [
    isSideDrawerOpen,
  ]);
  const [defaultCollectionId, setDefaultCollectionId] = useState("");
  const [modelId, setModelId] = useState("");
  const [isFetchingDefaultCollectionId, setIsFetchingDefaultCollectionId] =
    useState(false);
  const isHighlightingCollection = useRef(false);
  // const [moreActionMenu, setMoreActionMenu] = useState<null | HTMLElement>(null);
  // const actionMenuOpen = Boolean(moreActionMenu);
  const [selectedCollectionToCopy, setSelectedCollectionToCopy] =
    useState<ICollection | null>(null);
  const [selectedCollectionToSplit, setSelectedCollectionToSplit] =
    useState<ICollection | null>(null);
  const [selectedCollectionToShuffle, setSelectedCollectionToShuffle] =
    useState<ICollection | null>(null);
  const [modelsList, setModelsList] = useState<IModel[] | []>([]);
  const [parentModel, setParentModel] = useState<IModel | null>(null);
  const [selectedChildModel, setSelectedChildModel] = useState<string>("");
  const [uniqueNameStatus, setUniqueNameStatus] = useState<string>("");
  const [childDatasetName, setChildDatasetName] = useState<string>("");
  const [childDescription, setChildDescription] = useState<string>("");
  const [isCopyingDataset, setIsCopyingDataset] = useState<boolean>(false);
  const [isSplittingDataset, setIsSplittingDataset] = useState<boolean>(false);
  const [isShufflingDataset, setIsShufflingDataset] = useState<boolean>(false);
  const [trainDataSetName, setTrainDataSetName] = useState('');
  const [testDataSetName, setTestDataSetName] = useState('');
  const [trainPercentage, setTrainPercentage] = useState(defaultTrainPercentage);
  const [testPercentage, setTestPercentage] = useState(defaultTestPercentage);
  const [totalTrainResources, setTotalTrainResources] = useState(0);
  const [totalTestResources, setTotalTestResources] = useState(0);
  const [isShuffleCheckboxActive, setIsShuffleCheckboxActive] = useState(false);
  const [callTheGetDataCollectionsApi, setCallTheGetDataCollectionsApi] = useState(false)
  const [trainDataSetNameError, setTrainDataSetNameError] = useState('');
  const [isTrainDataSetNameUniqueError, setIsTrainDataSetNameUniqueError] = useState('');
  const [isTestDataSetNameUniqueError, setIsTestDataSetNameUniqueError] = useState('');
  const [testDataSetNameError, setTestDataSetNameError] = useState('');
  const [shuffleDataSetName, setShuffleDataSetName] = useState('');
  const [shuffleDataSetNameError, setShuffleDataSetNameError] = useState('')
  const [hasUserInteracted, setHasUserInteracted] = useState({
    train: false,
    test: false,
  });
  const [isShuffleDataSetNameTouched, setIsShuffleDataSetNameTouched] = useState(false);
  const [canGenerateUniqueName, setCanGenerateUniqueName] =
    useState<boolean>(true);
  const [isDataSetCollectionPopupOpen, setIsDataSetCollectionPopupOpen] =
    useState<boolean>(false);
  const [showLeftArrow, setShowLeftArrow] = useState<boolean>(false);
  const [showRightArrow, setShowRightArrow] = useState<boolean>(false);
  const [navigation, setNavigation] = useState<Navigation | null>(null);
  const [uniqueDataSetCollectionNames, setUniqueDataSetCollectionNames] =
    useState<string[]>([]);
  const [loadingAiTest, setLoadingAiTest] = useState<boolean>(false);

  const [sortField, setSortField] = useState<{
    order: SortOrder;
    field: string;
  }>({
    order: "ascending",
    field: "",
  });
  const [tableFilter, setTableFilter] = useState({
    filter: {
      name: {
        searchValue: "",
        searchOperator: DataTableNameSearchOperatorDefaultValue,
        menuOpen: false,
      },
      description: {
        searchValue: "",
        searchOperator: DataTableNameSearchOperatorDefaultValue,
        menuOpen: false,
      },
      createdDate: {
        minValue: "",
        maxValue: "",
        menuOpen: false,
      },
    },
  });
  // const downloadCSV = (path: string) => {
  //   window.open(path, "_self");
  // };
  const debouncedTrainDataSetName = useDebounce(trainDataSetName, 500);
  const debouncedTestDataSetName = useDebounce(testDataSetName, 500);

  useEffect(() => {
    const validateTrainDataSetName = async () => {
      if (!hasUserInteracted.train) return;
      setTrainDataSetNameError('');
      setIsTrainDataSetNameUniqueError('');

      if (!debouncedTrainDataSetName) {
        setTrainDataSetNameError('Train Dataset name required');
        return;
      }

      if (debouncedTrainDataSetName === debouncedTestDataSetName) {
        setTrainDataSetNameError('Train dataset collection name cannot be the same as test');
        return;
      }

      // Check for uniqueness after debouncing
      const isUnique = await checkUniqueName(debouncedTrainDataSetName);
      if (!isUnique) {
        setIsTrainDataSetNameUniqueError('Train dataset collection name must be unique.');
      }
    };

    validateTrainDataSetName();
  }, [debouncedTrainDataSetName, debouncedTestDataSetName]);
  console.log({ hasUserInteracted })
  // Validate test dataset name
  useEffect(() => {
    const validateTestDataSetName = async () => {
      if (!hasUserInteracted.test) return;
      setTestDataSetNameError('');
      setIsTestDataSetNameUniqueError('');

      if (!debouncedTestDataSetName) {
        setTestDataSetNameError('Test Dataset name required');
        return;
      }

      if (debouncedTestDataSetName === debouncedTrainDataSetName) {
        setTestDataSetNameError('Test dataset collection name cannot be the same as train');
        return;
      }

      // Check for uniqueness after debouncing
      const isUnique = await checkUniqueName(debouncedTestDataSetName);
      if (!isUnique) {
        setIsTestDataSetNameUniqueError('Test dataset collection name must be unique.');
      }
    };

    validateTestDataSetName();
  }, [debouncedTestDataSetName, debouncedTrainDataSetName]);


  const debouncedShuffleDataSetName = useDebounce(shuffleDataSetName, 500);
  useEffect(() => {
    if (isShuffleDataSetNameTouched) {
      if (debouncedShuffleDataSetName) {
        checkUniqueName(debouncedShuffleDataSetName).then(isUnique => {
          if (!isUnique) {
            setShuffleDataSetNameError('Name must be unique');
          } else {
            setShuffleDataSetNameError('');
          }
        });
      } else {
        shuffleDataSetName === "" && setShuffleDataSetNameError('Dataset name is required');
      }
    }
  }, [debouncedShuffleDataSetName, isShuffleDataSetNameTouched]);
  const handleShuffleDataSetNameChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setShuffleDataSetName(e.target.value);
    setIsShuffleDataSetNameTouched(true);
  };
  const handleChangePage = (event: unknown, newPage: number) => {
    if (!isFetching) {
      let queryParamsJson = convertURLQueryParamsToJSON(urlQuery.entries());
      queryParamsJson = {
        ...queryParamsJson,
        offset: newPage + 1,
      };
      const queryString = `?${queryObjectToString(queryParamsJson)}`;

      history.push(`${location.pathname}${queryString}`);
    }
  };
  const validateForm = () => {
    let valid = true;
    if (trainDataSetName === '') {
      setTrainDataSetNameError('Train dataset collection name is required.');
      valid = false;
    } else {
      setTrainDataSetNameError('');
    }

    if (testDataSetName === '') {
      setTestDataSetNameError('Test dataset collection name is required.');
      valid = false;
    } else {
      setTestDataSetNameError('');
    }
    return valid;
  };
  const handleSplit = async () => {
    try {
      if (validateForm() && !isTestDataSetNameUniqueError && !isTrainDataSetNameUniqueError) {
        setIsSplittingDataset(true);
        setCallTheGetDataCollectionsApi(false)
        const reqBody = {
          modelId: modelId,
          id: selectedCollectionToSplit?._id || '',
          isShuffled: isShuffleCheckboxActive,
          testPercentage: parseInt(testPercentage),
          trainPercentage: parseInt(trainPercentage),
          testDataSetName: testDataSetName.trim(),
          trainDataSetName: trainDataSetName.trim()

        }
        await DataService.splitDataSetCollectionIntoTestTrain(reqBody);
        setIsSplittingDataset(false);
        setCallTheGetDataCollectionsApi(true)
        splitDialogCloseHandler()
      }
      else {
        setCallTheGetDataCollectionsApi(false)
      }
    } catch (err) {
      setIsSplittingDataset(false);
      console.log(err)
    }
  };
  const checkUniqueName = async (name: string) => {
    try {
      const query = {
        modelId: modelId,
        datasetCollectionName: childDatasetName || name,
      };
      const apiResponse = await DataService.checkDatasetCollectionNameUniqueness(query);
      return apiResponse.data.unique;
    } catch (error) {
      console.error('API error:', error);
      return false;
    }
  };
  const handleTrainDataSetNameChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setTrainDataSetName(e.target.value);
    setHasUserInteracted((prev) => ({ ...prev, train: true }));
  };

  const handleTestDataSetNameChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setTestDataSetName(e.target.value);
    setHasUserInteracted((prev) => ({ ...prev, test: true }));
  };

  const handleBlurTrainDataSetName = async () => {
    if (trainDataSetName) {
      setTrainDataSetNameError('')
      if (trainDataSetName == testDataSetName) {
        setTrainDataSetNameError('Train dataset collection name cannot be same as test')
      }
      else {
        setIsTrainDataSetNameUniqueError('');
      }
    }
    else {
      setTrainDataSetNameError('Train Dataset name required')
    }
  };

  const handleBlurTestDataSetName = async () => {
    if (testDataSetName) {
      setTestDataSetNameError('')
      if (testDataSetName == trainDataSetName) {
        setTestDataSetNameError('Test dataset collection name cannot be same as train')
      }
      else {
        setIsTestDataSetNameUniqueError('');
      }
    }
    else {
      setTestDataSetNameError('Test Dataset name required')
    }
  };
  const handlePercentageChange = (isTrain: boolean) => (e: React.ChangeEvent<HTMLInputElement>) => {
    const value = e.target.value;
    if (value === '') {
      if (isTrain) {
        setTrainPercentage('');
        setTestPercentage('100');
        if (selectedCollectionToSplit?.totalNoOfResources !== undefined) {
          setTotalTestResources(selectedCollectionToSplit?.totalNoOfResources)
          setTotalTrainResources(0)
        }
      } else {
        setTestPercentage('');
        setTrainPercentage('100');
        if (selectedCollectionToSplit?.totalNoOfResources !== undefined) {
          setTotalTestResources(0)
          setTotalTrainResources(selectedCollectionToSplit?.totalNoOfResources)
        }
      }
      return;
    }
    if (/^\d{0,2}$/.test(value) || value === '100') { // Allows only numbers 0-100
      const percentage = parseInt(value, 10);
      if (!isNaN(percentage)) {
        if (isTrain) {
          setTrainPercentage(value);
          setTestPercentage((100 - percentage).toString());
          if(selectedCollectionToSplit?.totalNoOfResources !== undefined ){
            const testTotalResources = Math.ceil(((100 - parseInt(value, 10)) / 100) * selectedCollectionToSplit.totalNoOfResources)
            const trainTotalResources = selectedCollectionToSplit.totalNoOfResources - testTotalResources
            setTotalTestResources(testTotalResources)
            setTotalTrainResources(trainTotalResources)
          }
            
        } else {
          setTestPercentage(value);
          setTrainPercentage((100 - percentage).toString());
          if(selectedCollectionToSplit?.totalNoOfResources !== undefined ){
            const testTotalResources = Math.ceil((parseInt(value, 10) / 100) * selectedCollectionToSplit.totalNoOfResources)
            const trainTotalResources = selectedCollectionToSplit.totalNoOfResources - testTotalResources
            setTotalTestResources(testTotalResources)
            setTotalTrainResources(trainTotalResources)
          }
        }
      }
    }
  };

  const handleBlurTrainPercentage = () => {
    const train = parseInt(trainPercentage, 10);
    if (!isNaN(train)) {
      setTestPercentage((100 - train).toString());
    }
    else {
      setTestPercentage('100')
      setTrainPercentage('0')
      setTotalTrainResources(0)
      selectedCollectionToSplit && selectedCollectionToSplit?.totalNoOfResources!=undefined && setTotalTestResources(selectedCollectionToSplit.totalNoOfResources)
    }
  };

  const handleBlurTestPercentage = () => {
    const test = parseInt(testPercentage, 10);
    if (!isNaN(test)) {
      setTrainPercentage((100 - test).toString());
    }
    else {
      setTestPercentage('0')
      setTrainPercentage('100')
      setTotalTestResources(0)
      selectedCollectionToSplit && selectedCollectionToSplit?.totalNoOfResources!=undefined && setTotalTrainResources(selectedCollectionToSplit.totalNoOfResources)
    }
  };

  const getSplitButtonToolTip = () => {
    if (isSplittingDataset) {
      return "Splitting in Process"
    }
    else if (!trainDataSetName && !testDataSetName) {
      return "Please fill the fields"
    }
    else if (!testPercentage || !trainPercentage) {
      return "Split percentages required"
    }
    else if (!totalTrainResources || !totalTestResources) {
      return "Cannot split with No datapoints"
    }
    else if (totalTrainResources + totalTestResources < 2) {
      return "Cannot split data points less than 2"
    }
    else {
      return "split data points"
    }
  }

  const validateShuffleForm = () => {
    let valid = true;
    if (shuffleDataSetName === '') {
      setShuffleDataSetNameError('Dataset collection name is required.');
      valid = false;
    } else {
      setShuffleDataSetNameError('');
    }
    return valid;
  };

  const handleShuffle = async () => {
    try {
      if (validateShuffleForm()) {
        setIsShufflingDataset(true);
        setCallTheGetDataCollectionsApi(false)
        await DataService.shuffleDataSetCollections({
          id: selectedCollectionToShuffle?._id || '',
          modelId: modelId,
          name: shuffleDataSetName.trim()
        });
        shuffleDialogCloseHandler()
        setIsShufflingDataset(false)
        setCallTheGetDataCollectionsApi(true)
      }
      else {
        setCallTheGetDataCollectionsApi(false)
      }
    } catch (err) {
      setIsShufflingDataset(false)
      console.log(err)
    }
  }
  const getUniqueDataSetCollectionNames = async (modelId: string) => {
    const apiResponse = await DataService.getDataSetCollectionUniqueNames({
      modelId: modelId,
    });
    if (apiResponse.data) {
      setUniqueDataSetCollectionNames(apiResponse.data);
    }
  };

  async function handleDataSetCollectionDownloadButtonClick(
    dataSetCollection: ICollection
  ) {
    try {
      props.setIsDataSetCollectionResourcesFileDownloadLinkBeingFetched({
        dataSetCollectionId: dataSetCollection._id.toString(),
        isDownloadLinkBeingFetched: true,
      });
      const apiResponse =
        await DataService.getDownloadURLForDataSetCollectionResourcesFile({
          dataSetCollectionId: dataSetCollection._id.toString(),
        });
      const element = document.createElement("a");
      element.setAttribute("href", apiResponse.data);
      element.setAttribute(
        "download",
        `dataSetCollection_${dataSetCollection.name}_resources.csv`
      );
      document.body.appendChild(element);
      element.click();
    } catch (error) { }
    props.setIsDataSetCollectionResourcesFileDownloadLinkBeingFetched({
      dataSetCollectionId: dataSetCollection._id.toString(),
      isDownloadLinkBeingFetched: false,
    });
  }

  const handleChangeRowsPerPage = (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    if (!isFetching) {
      let queryParamsJson = convertURLQueryParamsToJSON(urlQuery.entries());
      queryParamsJson = {
        ...queryParamsJson,
        limit: Number(event.target.value),
        offset: 1,
      };
      const queryString = `?${queryObjectToString(queryParamsJson)}`;
      history.push(`${location.pathname}${queryString}`);
    }
  };
  // const handleClick = (data: ICollection) => {
  //   setSideDrawer({ type: "collectionView", component: setDataSetViewProps, isOpen: true});
  //   setSelectedCollection(data);
  // };

  useEffect(() => {
    if (
      (!isFetching &&
        collections.collections.length &&
        highlightedCollectionId) ||
      highlightedCollectionIdFromModel
    ) {
      document
        .getElementById(highlightedCollectionId)
        ?.scrollIntoView({ behavior: "smooth", block: "center" });
      document
        .getElementById(highlightedCollectionId)
        ?.classList.add(`${classes.highlightRow}`);
      document
        .getElementById(highlightedCollectionIdFromModel)
        ?.scrollIntoView({ behavior: "smooth", block: "center" });
      document
        .getElementById(highlightedCollectionIdFromModel)
        ?.classList.add(`${classes.highlightRow}`);
      setTimeout(() => {
        document
          .getElementById(highlightedCollectionId)
          ?.classList.remove(`${classes.highlightRow}`);
        document
          .getElementById(highlightedCollectionIdFromModel)
          ?.classList.remove(`${classes.highlightRow}`);
        let queryParamsJson = convertURLQueryParamsToJSON(urlQuery.entries());
        delete queryParamsJson["highlight"];
        delete queryParamsJson["highlightModelCollectionId"];
        const queryString = `?${queryObjectToString(queryParamsJson)}`;
        history.push(`${location.pathname}${queryString}`);
        isHighlightingCollection.current = true;
        setTimeout(() => {
          isHighlightingCollection.current = false;
        }, 100);
      }, 3000);
    }
  }, [isFetching, collections]);

  const [imageModelTypeArr, setImageModelTypeArr] = useState<string[]>([]);

  const getAllModelsList = async () => {
    const project =
      query.get(DataSetCollectionTableQueryParam.PROJECT_ID) || "";
    const modelType =
      query.get(DataSetCollectionTableQueryParam.MODEL_TYPE) || "";
    const modelId = query.get(DataSetCollectionTableQueryParam.MODEL_ID) || "";

    // case when modelType is image or imageAnnotation
    const isModelTypeImageOrImageAnnotation =
      modelType === "image" || modelType === "imageAnnotation" ? true : false;
    let availableModels: any[] = [];
    if (project && modelType) {
      const modelsPromise = new Promise(async (res, rej) => {
        const apiResponse = await ModelService.getProjectModels({
          project: project,
          type: modelType,
          offset: "1",
          limit: "10000",
        });
        res(apiResponse);
      });

      const modelsIfTypeImageOrImageAnnotationPromise = new Promise(
        async (res, rej) => {
          let otherModelType = "";
          if (modelType === "image") {
            otherModelType = "imageAnnotation";
          } else if (modelType === "imageAnnotation") {
            otherModelType = "image";
          }
          const apiResponse = await ModelService.getProjectModels({
            project: project,
            type: otherModelType,
            offset: "1",
            limit: "10000",
          });
          if (isModelTypeImageOrImageAnnotation) {
            res(apiResponse);
          } else {
            res({});
          }
        }
      );
      Promise.all([
        modelsPromise,
        modelsIfTypeImageOrImageAnnotationPromise,
      ]).then((results: any) => {
        results.map((res: any) => {
          if (Object.keys(res).length !== 0) {
            const availableChildModels = [
              ...res?.data.models.filter((model: any) => model._id !== modelId),
            ];
            availableModels = [...availableModels, ...availableChildModels];
          }
        });
        setModelsList(availableModels);
        if (isModelTypeImageOrImageAnnotation) {
          const imageModels = [
            ...results[results.length - 1]?.data.models.filter(
              (model: any) => model._id !== modelId
            ),
          ].map((childModel) => childModel._id);
          setImageModelTypeArr(imageModels);
        }
        setParentModel(
          results[0].data.models.find((model: any) => model._id === modelId) ||
          null
        );
      });
    }
  };

  useEffect(() => {
    const query = new URLSearchParams(location.search);
    getAllModelsList();
    setTopActionButton({
      type: "addCollection",
      onClickComponentToRender: AddDataSetCollection,
    });
    setSideDrawer({
      type: "projectAnalytics",
      component: ModelAnalytics,
      isOpen: false,
    });
    getUniqueDataSetCollectionNames(
      query.get(DataSetCollectionTableQueryParam.MODEL_ID) || ""
    );
  }, []);

  const getSortAndFilterValues = (query: URLSearchParams) => {
    const sortOrder: SortOrder =
      (query?.get("sortOrder") as SortOrder) || "descending";
    const sortField: DataSetSortField =
      (query?.get("sortField") as DataSetSortField) || "created_at";
    const nameSearchValue =
      query?.get(
        DataSetCollectionTableFilterQueryParams.DATASET_COLLECTION_TABLE_NAME_SEARCH_VALUE_QUERY_PARAM
      ) || "";
    const nameSearchOperator: any =
      query?.get(
        DataSetCollectionTableFilterQueryParams.DATASET_COLLECTION_TABLE_NAME_SEARCH_OPERATOR_QUERY_PARAM
      ) || DataTableNameSearchOperatorDefaultValue;
    const nameRegex = convertNameSearchOperatorToRegex(
      nameSearchOperator,
      nameSearchValue
    );
    const descriptionSearchValue =
      query?.get(
        DataSetCollectionTableFilterQueryParams.DATASET_COLLECTION_TABLE_DESCRIPTION_SEARCH_VALUE_QUERY_PARAM
      ) || "";
    const descriptionSearchOperator: any =
      query?.get(
        DataSetCollectionTableFilterQueryParams.DATASET_COLLECTION_TABLE_DESCRIPTION_SEARCH_OPERATOR_QUERY_PARAM
      ) || DataTableNameSearchOperatorDefaultValue;
    const descriptionRegex = convertNameSearchOperatorToRegex(
      descriptionSearchOperator,
      descriptionSearchValue
    );
    const collectionCreatedDateMinValue: string =
      query?.get(
        DataSetCollectionTableFilterQueryParams.DATASET_COLLECTION_TABLE_CREATED_DATE_MIN_VALUE_QUERY_PARAM
      ) || "";
    const collectionCreatedDateMaxValue: string =
      query?.get(
        DataSetCollectionTableFilterQueryParams.DATASET_COLLECTION_TABLE_CREATED_DATE_MAX_VALUE_QUERY_PARAM
      ) || "";

    return {
      sortOrder,
      sortField,
      nameSearchValue,
      nameSearchOperator,
      descriptionSearchValue,
      descriptionSearchOperator,
      nameRegex,
      descriptionRegex,
      collectionCreatedDateMaxValue,
      collectionCreatedDateMinValue,
    };
  };

  useEffect(() => {
    if (isCollectionAddSuccess || isCollectionDeleteSuccess || callTheGetDataCollectionsApi) {
      const query = new URLSearchParams(location.search);
      const limit = query.get(DataSetCollectionTableQueryParam.LIMIT) || "10";
      const model = query.get(DataSetCollectionTableQueryParam.MODEL_ID) || "";
      const offset = isCollectionAddSuccess
        ? "1"
        : query.get(DataSetCollectionTableQueryParam.OFFSET) || "1";
      const sortAndFilterValues = getSortAndFilterValues(query);
      setSortField({
        field: sortAndFilterValues.sortField,
        order: sortAndFilterValues.sortOrder,
      });
      setTableFilter({
        filter: {
          name: {
            searchValue: sortAndFilterValues.nameSearchValue,
            searchOperator: sortAndFilterValues.nameSearchOperator,
            menuOpen: false,
          },
          description: {
            searchValue: sortAndFilterValues.descriptionSearchValue,
            searchOperator: sortAndFilterValues.descriptionSearchOperator,
            menuOpen: false,
          },
          createdDate: {
            minValue: sortAndFilterValues.collectionCreatedDateMinValue,
            maxValue: sortAndFilterValues.collectionCreatedDateMaxValue,
            menuOpen: false,
          },
        },
      });

      setRowsPerPage(parseInt(limit, 10));
      setPage(parseInt(offset, 10) - 1);
      getCollections(
        {
          offset,
          limit,
          model,
          sortField: sortAndFilterValues.sortField,
          sortOrder: sortAndFilterValues.sortOrder,
          nameRegex: sortAndFilterValues.nameRegex,
          descriptionRegex: sortAndFilterValues.descriptionRegex,
          collectionCreatedDateMinValue:
            sortAndFilterValues.collectionCreatedDateMinValue,
          collectionCreatedDateMaxValue:
            sortAndFilterValues.collectionCreatedDateMaxValue,
          projectionQuery: JSON.stringify({
            totalNoOfResources: 1,
            name: 1,
            description: 1,
            dataBoost: 1,
            parentCollections: 1,
            resourcesFileName: 1,
            created_at: 1,
            created_by: 1,
            model: 1,
            updated_at: 1,
            remarks: 1,
            tags: 1,
          }),
        },
        false,
        selected,
        navigation
      );
      setCallTheGetDataCollectionsApi(false)
    }
  }, [isCollectionAddSuccess, isCollectionDeleteSuccess, callTheGetDataCollectionsApi]);

  useEffect(() => {
    const query = new URLSearchParams(location.search);
    const limit = query.get(DataSetCollectionTableQueryParam.LIMIT) || "10";
    const model = query.get(DataSetCollectionTableQueryParam.MODEL_ID) || "";
    const offset = isCollectionAddSuccess
      ? "1"
      : query.get(DataSetCollectionTableQueryParam.OFFSET) || "1";
    const sortAndFilterValues = getSortAndFilterValues(query);
    if (!isHighlightingCollection.current) {
      setTableFilter({
        filter: {
          name: {
            searchValue: sortAndFilterValues.nameSearchValue,
            searchOperator: sortAndFilterValues.nameSearchOperator,
            menuOpen: false,
          },
          description: {
            searchValue: sortAndFilterValues.descriptionSearchValue,
            searchOperator: sortAndFilterValues.descriptionSearchOperator,
            menuOpen: false,
          },
          createdDate: {
            minValue: sortAndFilterValues.collectionCreatedDateMinValue,
            maxValue: sortAndFilterValues.collectionCreatedDateMaxValue,
            menuOpen: false,
          },
        },
      });
      setSortField({
        field: sortAndFilterValues.sortField,
        order: sortAndFilterValues.sortOrder,
      });
      setModelId(model);
      setRowsPerPage(parseInt(limit, 10));
      setPage(parseInt(offset, 10) - 1);

      if(props.scenario === "aiMarketplaceSelectedDataList") {
        getCollections({
          offset,
          limit,
          collectionIds: aiMarketplaceDataCartState.dataSetIds,
          sortField: sortAndFilterValues.sortField,
          sortOrder: sortAndFilterValues.sortOrder,
          nameRegex: sortAndFilterValues.nameRegex,
          descriptionRegex: sortAndFilterValues.descriptionRegex,
          collectionCreatedDateMinValue:
            sortAndFilterValues.collectionCreatedDateMinValue,
          collectionCreatedDateMaxValue:
            sortAndFilterValues.collectionCreatedDateMaxValue,
          projectionQuery: JSON.stringify({
            totalNoOfResources: 1,
            name: 1,
            description: 1,
            dataBoost: 1,
            parentCollections: 1,
            resourcesFileName: 1,
            created_at: 1,
            created_by: 1,
            model: 1,
            updated_at: 1,
            remarks: 1,
            tags: 1,
          }),
        })
      }
      else {
      getCollections(
        {
          offset,
          limit,
          model,
          sortField: sortAndFilterValues.sortField,
          sortOrder: sortAndFilterValues.sortOrder,
          nameRegex: sortAndFilterValues.nameRegex,
          descriptionRegex: sortAndFilterValues.descriptionRegex,
          collectionCreatedDateMinValue:
            sortAndFilterValues.collectionCreatedDateMinValue,
          collectionCreatedDateMaxValue:
            sortAndFilterValues.collectionCreatedDateMaxValue,
          projectionQuery: JSON.stringify({
            totalNoOfResources: 1,
            name: 1,
            description: 1,
            dataBoost: 1,
            parentCollections: 1,
            resourcesFileName: 1,
            created_at: 1,
            created_by: 1,
            model: 1,
            updated_at: 1,
            remarks: 1,
            tags: 1,
          }),
        },
        false,
        selected,
        navigation
      );
      }
      getDefaultCollectionId(model);
    }
    // }, [location]);
  }, [
    // running useEffect when any of data-set collection table query param value changes
    dataSetCollectionOffsetQueryParamValue,
    dataSetCollectionLimitQueryParamValue,
    dataSetCollectionSortOrderQueryParamValue,
    dataSetCollectionSortFieldQueryParamValue,
    dataSetCollectionNameSearchQueryParamValue,
    dataSetCollectionNameSearchOperatorQueryParamValue,
    dataSetCollectionDescriptionSearchQueryParamValue,
    dataSetCollectionDescriptionSearchOperatorQueryParamValue,
    dataSetCollectionCreatedDateMinQueryParamValue,
    dataSetCollectionCreatedDateMaxQueryParamValue,
  ]);

  const getDefaultCollectionId = async (modelID: string) => {
    setIsFetchingDefaultCollectionId(true);
    const res = await ModelService.getDefaultDataSetCollection(modelID);
    setDefaultCollectionId(
      res.data.defaultDataSetCollection ? res.data.defaultDataSetCollection : ""
    );
    setIsFetchingDefaultCollectionId(false);
  };

  useEffect(() => {
    if (isSideDrawerOpen) {
      setSideDrawer({
        type: "collectionView",
        component: setDataSetViewProps,
        isOpen: true,
      });
    }
  }, [defaultCollectionId, isFetchingDefaultCollectionId]);

  const setDataSetViewProps = () => {
    return (
      <DataSetCollectionView
        defaultCollectionId={defaultCollectionId}
        modelId={modelId}
        updateDefaultCollectionId={getDefaultCollectionId}
        isFetchDefaultId={isFetchingDefaultCollectionId}
        setFetchingDefaultId={setIsFetchingDefaultCollectionId}
        actions={{
          downloadHandler: handleDataSetCollectionDownloadButtonClick,
          trainHandler: handleTrainButtonClick,
          reTrainHandler: handleReTrainButtonClick,
          copyCollectionHandler: handleCopyCollectionButtonClick,
          shuffleHandler: handleShuffleDatasetBtnClick,
          splitHandler: handleSplitDatasetBtnClick
        }}
        modelCollectionScenario={addModelCollectionScenario}
      />
    );
  };

  const isCollectionCheckboxChecked = (collectionId: string) => {
    if(props.scenario === 'aiMarketplaceDataSelection'){
      return aiMarketplaceDataCartState.currentSelectedDataSetIds.includes(collectionId)
    }

    for (const collection of selectedCollections) {
      if (collection._id === collectionId) {
        return true;
      }
    }
    return false;
  };

  const handleCollectionRowCheckBoxClick = (collectionClicked: ICollection) => {

    if(props.scenario === 'aiMarketplaceDataSelection'){
      const index = aiMarketplaceDataCartState.currentSelectedDataSetIds.indexOf(collectionClicked._id);
      if(index > -1){
        dataCartContext.removeDataSetsFromAiMarketplaceDataCart([collectionClicked._id])
      } 
      else {
        dataCartContext.addDataSetsToAiMarketplaceDataCart([collectionClicked._id])
      }
      
      return;
    }

    for (let index = 0; index < selectedCollections.length; index++) {
      const selectedCollection = selectedCollections[index];
      if (selectedCollection._id === collectionClicked._id) {
        const oldSelectedCollections = selectedCollections;
        oldSelectedCollections.splice(index, 1);
        setSelectedCollections([...oldSelectedCollections]);
        return;
      }
    }

    addSelectedDataSetCollections(collectionClicked);
  };

  const handleSelectAllCollectionCheckboxClick = (isChecked: boolean) => {
    if (isChecked) {
      setSelectedCollections([...collections.collections]);
    } else {
      setSelectedCollections([]);
    }
  };

  const handleDeleteSelectedCollectionsButtonClick = () => {
    let collectionIdsToDelete: string[] = selectedCollections.map(
      (selectedCollection) => selectedCollection._id
    );

    if (collectionIdsToDelete.length > 0) {
      confirmDeleteCollection({
        multiDelete: true,
        collectionToDelete: null,
        showConfirmDeleteModal: true,
        collectionType: "dataSet",
        selectedCollectionIds: collectionIdsToDelete,
      });
    }
  };

  // const selectedTab = collectionTabs.find((item) =>
  //   pathname.includes(item.path)
  // )?.path;
  // const rootPath = pathname.split(selectedTab || "")[0];

  function handleTrainButtonClick(collection: ICollection) {
    setSideDrawer({
      type: "addCollection",
      component: AddModelCollectionComponentWrapperForTrainingScenario,
      isOpen: true,
    });
    setTrainingDataDirectoriesSelectedStateForAddModelCollection({
      ...trainingDataDirectoriesSelectedStateForAddModelCollection,
      value: [
        ...trainingDataDirectoriesSelectedStateForAddModelCollection.value,
        collection.name,
      ],
      isDirty: true,
      isTouched: true,
    });
    setIsDataSetCollectionPopupOpen(false);
  }
  function handleReTrainButtonClick(collection: ICollection) {
    setSideDrawer({
      type: "addCollection",
      component: AddModelCollectionComponentWrapperForReTrainingScenario,
      isOpen: true,
    });
    setTrainingDataDirectoriesSelectedStateForAddModelCollection({
      ...trainingDataDirectoriesSelectedStateForAddModelCollection,
      value: [
        ...trainingDataDirectoriesSelectedStateForAddModelCollection.value,
        collection.name,
      ],
      isDirty: true,
      isTouched: true,
    });
    setIsDataSetCollectionPopupOpen(false);
  }

  const handleCopyCollectionButtonClick = (collection: ICollection) => {
    setSelectedCollectionToCopy(collection);
    setChildDescription("Copied! " + collection.description);
  };

  const handleAiTestForDatasetCollection = async (collection: ICollection) => {
    setLoadingAiTest(true);
    const aiTestForModelCollectionApi =
      await DataService.aiTestForModelCollection({
        datasetCollectionId: collection._id,
      });
    setLoadingAiTest(false);
  };

  const handleSplitDatasetBtnClick = async (collections: ICollection) => {
    if(collections?.totalNoOfResources!=undefined){
      const testTotalResources = Math.ceil(parseInt(defaultTestPercentage)/100 * collections?.totalNoOfResources)
      const trainTotalResources = collections?.totalNoOfResources - testTotalResources
      setTotalTestResources(testTotalResources)
      setTotalTrainResources(trainTotalResources)
    }
    setSelectedCollectionToSplit(collections)
  }

  const handleShuffleDatasetBtnClick = async (collections: ICollection) => {
    setSelectedCollectionToShuffle(collections)
  }
  useEffect(() => {
    if (uniqueNameStatus === "duplicate") {
      setCanGenerateUniqueName(true);
    }
  }, [uniqueNameStatus]);

  const [isModelTypeImage, setIsModelTypeImage] = useState<boolean>(false);

  const checkNameUniqueness = async (name: string) => {
    const imageModelsArr = imageModelTypeArr.filter(
      (imageModel) => imageModel === selectedChildModel
    );
    if (imageModelsArr.length > 0) {
      setIsModelTypeImage(true);
    }
    if (selectedChildModel) {
      const query = {
        modelId: selectedChildModel,
        datasetCollectionName: childDatasetName || name,
      };
      const apiResponse =
        await DataService.checkDatasetCollectionNameUniqueness(query);
      if (apiResponse.data.unique) {
        setUniqueNameStatus("unique");
      } else {
        setUniqueNameStatus("duplicate");
      }
    }
  };

  const copyDatasetCollectionHandler = async () => {
    if (childDatasetName && selectedCollectionToCopy) {
      const query = {
        modelId: selectedChildModel,
        datasetCollectionName: childDatasetName,
      };
      setIsCopyingDataset(true);
      const apiResponse =
        await DataService.checkDatasetCollectionNameUniqueness(query);
      if (apiResponse.data.unique) {
        setUniqueNameStatus("unique");
        const addCollectionResponse = await DataService.addCollection({
          name: childDatasetName,
          model: selectedChildModel,
          description: childDescription || "",
          remarks: {
            text: `Copied ${selectedCollectionToCopy?.name || ""} of ${parentModel?.name || ""
              } in this collection`,
          },
        });
        if (addCollectionResponse.data._id) {
          const copyCollectionResponse =
            await DataService.copyCollectionToAnotherCollection({
              dataSetCollectionIdToCopyFrom:
                selectedCollectionToCopy?._id || "",
              dataSetCollectionIdToCopyIn: addCollectionResponse.data._id,
              isImageAnnotationsToBeCopied: isImageAnnotationsToBeCopied,
            });
          if (copyCollectionResponse.status === 200) {
            setUniqueNameStatus("started");
            setIsCopyingDataset(false);
          }
        }
      } else {
        setUniqueNameStatus("duplicate");
        setIsCopyingDataset(false);
      }
    }
  };

  const generateUniqueName = async () => {
    if (selectedChildModel) {
      const query = {
        modelId: selectedChildModel,
        datasetCollectionName:
          childDatasetName || selectedCollectionToCopy?.name || "",
      };
      const apiResponse =
        await DataService.getDataSetCollectionUniqueNameIfAlreadySameNameExistInModel(
          query
        );
      setChildDatasetName(apiResponse);
      setUniqueNameStatus("unique");
    }
  };

  const copyDialogCloseHandler = () => {
    setChildDatasetName("");
    setSelectedChildModel("");
    setUniqueNameStatus("");
    setChildDescription("");
    setSelectedCollectionToCopy(null);
  };
  const splitDialogCloseHandler = () => {
    setTrainDataSetNameError('')
    setTestDataSetNameError('')
    setTrainDataSetName('')
    setIsShuffleCheckboxActive(false)
    setTestDataSetName('')
    setTrainPercentage(defaultTrainPercentage)
    setTestPercentage(defaultTestPercentage)
    setHasUserInteracted({ train: false, test: false })
    setSelectedCollectionToSplit(null);
    setIsSplittingDataset(false);
  };
  const shuffleDialogCloseHandler = () => {
    setShuffleDataSetName('')
    setShuffleDataSetNameError('')
    setSelectedCollectionToShuffle(null);
    setIsShuffleDataSetNameTouched(false)
    setIsShufflingDataset(false)
  };
  const handleBlurShuffleDataSetName = async () => {
    if (shuffleDataSetName) {
      setShuffleDataSetNameError('')
    }
    else {
      setShuffleDataSetNameError('Dataset collection name required')
    }
  }
  function AddModelCollectionComponentWrapperForTrainingScenario() {
    setAddModelCollectionScenario("trainingDataSetCollections");
    return <AddModelCollection scenario={"trainingDataSetCollections"} />;
  }

  function AddModelCollectionComponentWrapperForReTrainingScenario() {
    setAddModelCollectionScenario("reTrainingDataSetCollections");
    return <AddModelCollection scenario={"reTrainingDataSetCollections"} />;
  }

  useEffect(() => {
    const modelId: string = urlQuery.get(
      DataSetCollectionTableQueryParam.MODEL_ID
    ) as string;
    (async () => {
      const apiResponse = await ModelService.getModelDetails({
        modelId: modelId,
        modelSelectQuery: "defaultDataSetCollection -_id",
      });
      const collectionId = apiResponse.data.defaultDataSetCollection;
      if (collectionId) {
        const collectionDetailsApiResponse =
          await ModelService.getCollectionDetails(collectionId);
        props.setDefaultDatasetCollection({
          name: collectionDetailsApiResponse.data.name,
          id: collectionId,
        });
      } else {
        props.setDefaultDatasetCollection(null);
      }
    })();
  }, []);

  const handleSort = (labelFieldName: DataSetSortField) => {
    const urlQuery = new URLSearchParams(location?.search);
    let previousSortOrder: SortOrder = sortField.order;
    let sortOrderToSet: SortOrder = "ascending";
    if (sortField.field !== labelFieldName) {
      sortOrderToSet = "ascending";
    } else {
      if (previousSortOrder === "ascending") {
        sortOrderToSet = "descending";
      } else {
        sortOrderToSet = "ascending";
      }
    }
    urlQuery.set("sortOrder", sortOrderToSet);
    urlQuery.set("sortField", labelFieldName);
    history.push(`${location?.pathname}?${urlQuery.toString()}`);
    setSortField({ field: labelFieldName, order: sortOrderToSet });
  };

  function handleResourceCreatedDateApplyFilterButtonClick(
    value: [string, string]
  ) {
    setTableFilter((oldState) => {
      return {
        ...oldState,
        filter: {
          ...oldState.filter,
          createdDate: {
            ...oldState.filter.createdDate,
            menuOpen: false,
            minValue: value[0],
            maxValue: value[1],
          },
        },
      };
    });
    updateUrlForDate(value[0], value[1]);
  }

  function handleNameApplyFilterButtonClick(value: {
    search: string;
    operator: StringSearchOperator;
  }) {
    setTableFilter((oldState) => {
      return {
        ...oldState,
        filter: {
          ...oldState.filter,
          name: {
            ...oldState.filter.name,
            menuOpen: false,
            searchValue: value.search,
            searchOperator: value.operator,
          },
        },
      };
    });
    updateUrlForName(value.search, value.operator);
  }

  function handleDescriptionApplyFilterButtonClick(value: {
    search: string;
    operator: StringSearchOperator;
  }) {
    setTableFilter((oldState) => {
      return {
        ...oldState,
        filter: {
          ...oldState.filter,
          description: {
            ...oldState.filter.description,
            menuOpen: false,
            searchValue: value.search,
            searchOperator: value.operator,
          },
        },
      };
    });
    updateUrlForDescription(value.search, value.operator);
  }

  async function setNameFilterSearchValue(value: string): Promise<void> {
    //   return new Promise<void>((resolve, reject) => {
    //     setTableFilter(oldState=>{
    //       return {
    //           filter: {
    //               ...oldState.filter,
    //               name: {
    //                   ...oldState.filter.name,
    //                   searchValue: value
    //               }
    //           },
    //       }
    //   }, ()=>{
    //     debugger
    //     return resolve()
    //   })
    // })
  }

  async function setNameFilterSearchOperator(
    value: StringSearchOperator
  ): Promise<void> {
    //   return new Promise<void>((resolve, reject) => {
    //     setTableFilter(oldState=>{
    //           return {
    //               filter: {
    //                   ...oldState.filter,
    //                   name: {
    //                       ...oldState.filter.name,
    //                       searchOperator: value
    //                   }
    //               },
    //           }
    //       }, ()=>resolve())
    //   })
  }

  async function setCreatedDateFilterMinMaxValue(
    value: [string, string]
  ): Promise<void> { }

  function resetNameFilter() {
    setTableFilter((oldState) => {
      return {
        ...oldState,
        filter: {
          ...oldState.filter,
          name: {
            ...oldState.filter.name,
            searchValue: "",
            searchOperator: DataTableNameSearchOperatorDefaultValue,
            menuOpen: false,
          },
        },
      };
    });
    updateUrlForName("", DataTableNameSearchOperatorDefaultValue);
  }

  const updateUrlForName = (search: string, operator: StringSearchOperator) => {
    const urlQuery = new URLSearchParams(location?.search);
    // if (
    //     urlQuery.get(DataTableNameSearchValueQueryParam) !== tableFilter.filter.name.searchValue ||
    //     urlQuery.get(DataTableNameSearchOperatorQueryParam) !== tableFilter.filter.name.searchOperator
    // ) {
    //      urlQuery.set(ForceFetchDataFromDBQueryParam, 'true');
    // }
    urlQuery.set(
      DataSetCollectionTableFilterQueryParams.DATASET_COLLECTION_TABLE_NAME_SEARCH_VALUE_QUERY_PARAM,
      search
    );
    urlQuery.set(
      DataSetCollectionTableFilterQueryParams.DATASET_COLLECTION_TABLE_NAME_SEARCH_OPERATOR_QUERY_PARAM,
      operator
    );
    history.push(`${location?.pathname}?${urlQuery.toString()}`);
  };

  const updateUrlForDescription = (
    search: string,
    operator: StringSearchOperator
  ) => {
    const urlQuery = new URLSearchParams(location?.search);
    // if (
    //     urlQuery.get(DataTableNameSearchValueQueryParam) !== tableFilter.filter.name.searchValue ||
    //     urlQuery.get(DataTableNameSearchOperatorQueryParam) !== tableFilter.filter.name.searchOperator
    // ) {
    //      urlQuery.set(ForceFetchDataFromDBQueryParam, 'true');
    // }
    urlQuery.set(
      DataSetCollectionTableFilterQueryParams.DATASET_COLLECTION_TABLE_DESCRIPTION_SEARCH_VALUE_QUERY_PARAM,
      search
    );
    urlQuery.set(
      DataSetCollectionTableFilterQueryParams.DATASET_COLLECTION_TABLE_DESCRIPTION_SEARCH_OPERATOR_QUERY_PARAM,
      operator
    );
    history.push(`${location?.pathname}?${urlQuery.toString()}`);
  };

  const updateUrlForDate = (minValue: string, maxValue: string) => {
    const urlQuery = new URLSearchParams(location?.search);
    // if (
    //     urlQuery.get(DataTableCreatedDateMinValueQueryParam) !== tableFilter.filter.createdDate.minValue ||
    //     urlQuery.get(DataTableCreatedDateMaxValueQueryParam) !== tableFilter.filter.createdDate.maxValue
    // ) {
    //      urlQuery.set(ForceFetchDataFromDBQueryParam, 'true');
    // }
    urlQuery.set(
      DataSetCollectionTableFilterQueryParams.DATASET_COLLECTION_TABLE_CREATED_DATE_MIN_VALUE_QUERY_PARAM,
      minValue
    );
    urlQuery.set(
      DataSetCollectionTableFilterQueryParams.DATASET_COLLECTION_TABLE_CREATED_DATE_MAX_VALUE_QUERY_PARAM,
      maxValue
    );
    history.push(`${location?.pathname}?${urlQuery.toString()}`);
  };

  function resetDateFilter() {
    setTableFilter((oldState) => {
      return {
        ...oldState,
        filter: {
          ...oldState.filter,
          createdDate: {
            ...oldState.filter.createdDate,
            maxValue: "",
            minValue: "",
            menuOpen: false,
          },
        },
      };
    });
    updateUrlForDate("", "");
  }

  function resetDescriptionFilter() {
    setTableFilter((oldState) => {
      return {
        ...oldState,
        filter: {
          ...oldState.filter,
          description: {
            ...oldState.filter.description,
            searchValue: "",
            searchOperator: DataTableNameSearchOperatorDefaultValue,
            menuOpen: false,
          },
        },
      };
    });
    updateUrlForDescription("", DataTableNameSearchOperatorDefaultValue);
  }

  const styleClass: any = classes;
  /*
  const handleClickMoreActions = (event: React.MouseEvent<HTMLElement>) => {
    setMoreActionMenu(event.currentTarget)
  }

  const hancleCloseMoreActions = () => {
    setMoreActionMenu(null)
  }
*/
  function DataSetCollectionPopupOpenButton(props: {
    onClick?: () => void;
    iconButtonStyles?: {};
  }) {
    const FullScreenButton = withStyles({
      root: {
        textTransform: "none",
      },
    })(Button);

    return (
      <div style={{ width: "100%", display: "flex", justifyContent: "center" }}>
        <FullScreenButton
          startIcon={<FullscreenIcon />}
          onClick={() => {
            if (props.onClick) {
              props.onClick();
            }
          }}
          color="primary"
          variant="contained"
        >
          View
        </FullScreenButton>
      </div>
    );
  }

  const handlePopupViewClick = (data: ICollection) => {
    setSelectedCollection(data);
    setIsDataSetCollectionPopupOpen(true);
  };

  useEffect(() => {
    if (!isFetching) {
      setTimeout(() => {
        const element = document.getElementById(props.selected?._id || "");
        if (element) {
          element.scrollIntoView({
            block: "center",
            inline: "nearest",
            behavior: "smooth",
          });
        }
      }, 200);
    }
  }, [isFetching, props.selected?._id]);

  function iterateOverResources(direction: 1 | -1) {
    // direction === 1 indicates moving to the next resource and direction === -1 indicates moving to the previous resource
    if (collections.collections) {
      for (var i = 0; i < collections.collections.length; i++) {
        if (collections.collections[i]._id === props?.selected?._id) {
          if (direction === 1) {
            if (i + 1 < collections.collections.length) {
              // dispatch(setSelectedModelCollection(props.modelCollectionsAvailable[i+1]._id))
              setSelectedCollection(collections.collections[i + 1]);
              // setIndexValue(i+1)
              // setSelectedResource(props.resourcelist[i+1])
            } else if (rowsPerPage * (page + 1) <= collections.totalCount) {
              handleChangePage(null, page + 1);
            }
          } else if (direction === -1) {
            if (i - 1 >= 0) {
              // dispatch(setSelectedModelCollection(props.modelCollectionsAvailable[i-1]._id))
              setSelectedCollection(collections.collections[i - 1]);
              // setIndexValue(i-1)
              // setSelectedResource(props.resourcelist[i-1])
            } else if (page > 0) {
              handleChangePage(null, page - 1);
            }
          }
        }
      }
    }
  }
  useEffect(() => {
    if (collections.collections && collections.collections.length > 0) {
      for (let i = 0; i < collections.collections.length; i++) {
        if (collections.collections[i]._id === props?.selected?._id) {
          addOrRemoveArrows(i);
        }
      }
    }
  }, [props.selected]);

  const addOrRemoveArrows = (index: number) => {
    const OffsetValue: string = urlQuery.get(
      DataSetCollectionTableQueryParam.OFFSET
    ) as string;
    const limit: string = urlQuery.get(
      DataSetCollectionTableQueryParam.LIMIT
    ) as string;
    const globalCurrentRowValue =
      (Number(OffsetValue) - 1) * Number(limit) + (index + 1);
    if (globalCurrentRowValue === 1) {
      setShowLeftArrow(false);
    } else {
      setShowLeftArrow(true);
    }
    if (globalCurrentRowValue === collections.totalCount) {
      setShowRightArrow(false);
    } else {
      setShowRightArrow(true);
    }
  };

  const handleAddPromptAnsInDataSetActionButtonClick = (
    dataSetCollectionId: string
  ) => {
    setAddPromptAnsInDataSetPopup({
      open: true,
      dataSetCollectionIdOfPrompt: dataSetCollectionId,
      dataSetCollectionIdOfTextFileModelToInsertIn: "",
    });
  };

  const [isImageAnnotationsToBeCopied, setIsImageAnnotationsToBeCopied] =
    useState(true);
  const handleToggleCopyImageAnnotations = () => {
    setIsImageAnnotationsToBeCopied(!isImageAnnotationsToBeCopied);
  };
  return (
    <Paper
      className={classes.paper}
      variant="outlined"
      style={
        props.scenario === "browseCollectionsPopup" ? { height: "100%" } : {}
      }
    >
      <CollectionEnhancedTableToolbar
        onDeleteClick={() => handleDeleteSelectedCollectionsButtonClick()}
        status={["deleted", "train", "reTrain"]}
        resources={collections.collections}
        isDeleteHappening={isCollectionsDeleting ? true : false}
        selected={selectedCollections}
        trainButtonComponentElement={
          <TrainDataSetCollectionButton
            onClick={() => {
              setSideDrawer({
                type: "addCollection",
                component:
                  AddModelCollectionComponentWrapperForTrainingScenario,
                isOpen: true,
              });
              if (collections?.collections?.length > 0) {
                const collectionNames = selectedCollections.map(
                  (collection) => collection.name
                );

                setTrainingDataDirectoriesSelectedStateForAddModelCollection({
                  ...trainingDataDirectoriesSelectedStateForAddModelCollection,
                  value: [
                    ...trainingDataDirectoriesSelectedStateForAddModelCollection.value,
                    ...collectionNames,
                  ],
                  isDirty: true,
                  isTouched: true,
                });
              }
            }}
            isDefaultDataSetCollectionExist={
              (props.defaultDatasetCollection &&
                props.defaultDatasetCollection.id.length > 0) ||
              false
            }
          />
        }
        reTrainButtonComponentElement={
          <ReTrainDataSetCollectionButton
            onClick={() => {
              setSideDrawer({
                type: "addCollection",
                component:
                  AddModelCollectionComponentWrapperForReTrainingScenario,
                isOpen: true,
              });
              if (collections?.collections?.length > 0) {
                const collectionNames = selectedCollections.map(
                  (collection) => collection.name
                );

                setTrainingDataDirectoriesSelectedStateForAddModelCollection({
                  ...trainingDataDirectoriesSelectedStateForAddModelCollection,
                  value: [
                    ...trainingDataDirectoriesSelectedStateForAddModelCollection.value,
                    ...collectionNames,
                  ],
                  isDirty: true,
                  isTouched: true,
                });
              }
            }}
            isDefaultDataSetCollectionExist={
              (props.defaultDatasetCollection &&
                props.defaultDatasetCollection.id.length > 0) ||
              false
            }
          />
        }
        mergeDataSetsElement={<MergeDataSetsButton />}
      />
      <DataSetCollectionPopup
        defaultCollectionId={defaultCollectionId}
        modelId={modelId}
        updateDefaultCollectionId={getDefaultCollectionId}
        isFetchDefaultId={isFetchingDefaultCollectionId}
        setFetchingDefaultId={setIsFetchingDefaultCollectionId}
        actions={{
          downloadHandler: handleDataSetCollectionDownloadButtonClick,
          trainHandler: handleTrainButtonClick,
          reTrainHandler: handleReTrainButtonClick,
          copyCollectionHandler: handleCopyCollectionButtonClick,
          aiTestForDatasetCollectionHandler: handleAiTestForDatasetCollection,
          shuffleHandler: handleShuffleDatasetBtnClick,
          splitHandler: handleSplitDatasetBtnClick
        }}
        modelCollectionScenario={addModelCollectionScenario}
        open={isDataSetCollectionPopupOpen}
        handleClose={() => setIsDataSetCollectionPopupOpen(false)}
        onMoveToLeft={() => {
          iterateOverResources(-1);
          setNavigation("moveToLeft");
        }}
        onMoveToRight={() => {
          iterateOverResources(1);
          setNavigation("moveToRight");
        }}
        isResourceExistBefore={showLeftArrow}
        isResourceExistAfter={showRightArrow}
        loadingAiTest={loadingAiTest}
        setIsImageAnnotationsToBeCopied={setIsImageAnnotationsToBeCopied}
        setIsModelTypeImage={setIsModelTypeImage}
        scenario={props.scenario ? props.scenario : undefined}
        isUsingDataCartForAiAssistedlabellingCodeTesting={
          props.aiAssistantSenerio === true ? true : false
        }
      />
      <TableContainer className={classes.tableContainer}>
        <Table
          className={classes.table}
          aria-labelledby="tableTitle"
          size="small"
          aria-label="enhanced table"
          id={tableId}
          stickyHeader
          style={{
            paddingLeft: "10px",
            paddingRight: "10px",
          }}
        >
          <TableHead>
            <TableRow classes={{ root: classes.tableRow }}>
              {props.scenario !== "browseCollectionsPopup" && (
                <TableCell
                  padding="checkbox"
                  className="header"
                  classes={{ root: classes.tableCellCheckbox }}
                >
                  <Checkbox
                    indeterminate={
                      selectedCollections.length > 0 &&
                      selectedCollections.length <
                      collections.collections?.length
                    }
                    checked={
                      collections.collections?.length > 0 &&
                      selectedCollections.length ===
                      collections.collections?.length
                    }
                    onChange={(e) =>
                      handleSelectAllCollectionCheckboxClick(e.target.checked)
                    }
                    hidden={props.scenario === "aiMarketplaceDataSelection" || props.scenario === "aiMarketplaceSelectedDataList"}
                  />
                </TableCell>
              )}
              <TableCell
                classes={{ root: classes.tableCellName }}
                style={{
                  color: "#98A6B3",
                  font: "normal normal normal 14px/22px Nunito Sans",
                }}
              >
                <Box
                  style={{ display: "flex", justifyContent: "space-between" }}
                >
                  {props.scenario !== "browseCollectionsPopup" && props.scenario !== "aiMarketplaceDataSelection" && props.scenario !== "aiMarketplaceSelectedDataList" && <div></div>}
                  <TableSortLabel
                    active={sortField.field === "name"}
                    direction={
                      sortField.field === "name"
                        ? sortField.order === "ascending"
                          ? "asc"
                          : "desc"
                        : "asc"
                    }
                    onClick={(e) => {
                      handleSort("name");
                    }}
                    classes={{
                      icon: classes.sortLabelIcon,
                      root: classes.sortLabelRoot,
                      active: classes.sortLabelActive,
                    }}
                  >
                    Name
                  </TableSortLabel>
                  <div>
                    <IconButton
                      aria-label="more"
                      aria-controls="long-menu"
                      aria-haspopup="true"
                      id="nameColumnOptions"
                      classes={{
                        root: classes.columnOptionsIcon,
                      }}
                      onClick={() => {
                        setTableFilter((oldState) => {
                          return {
                            ...oldState,
                            filter: {
                              ...oldState.filter,
                              name: {
                                ...oldState.filter.name,
                                menuOpen: true,
                              },
                            },
                          };
                        });
                      }}
                    >
                      {tableFilter.filter.name.searchValue ? (
                        <Tooltip title="Filter applied on Name">
                          <FilterListIcon />
                        </Tooltip>
                      ) : (
                        <MoreVertIcon />
                      )}
                    </IconButton>
                    {tableFilter.filter.name.menuOpen && (
                      <NameColumnMenu
                        open={tableFilter.filter.name.menuOpen}
                        onClose={() =>
                          setTableFilter((oldState) => {
                            return {
                              ...oldState,
                              filter: {
                                ...oldState.filter,
                                name: {
                                  ...oldState.filter.name,
                                  menuOpen: false,
                                },
                              },
                            };
                          })
                        }
                        searchValue={tableFilter.filter.name.searchValue}
                        resetFilters={() => resetNameFilter()}
                        classes={styleClass}
                        searchOperator={tableFilter.filter.name.searchOperator}
                        isFilterApplied={
                          tableFilter.filter.name.searchValue.length > 0
                        }
                        onApplyFilterButtonClick={(value) =>
                          handleNameApplyFilterButtonClick(value)
                        }
                        setFilterSearchValue={(value) =>
                          setNameFilterSearchValue(value)
                        }
                        setFilterSearchOperator={(value) =>
                          setNameFilterSearchOperator(value)
                        }
                        uniqueFilterValues={uniqueDataSetCollectionNames}
                        isUniqueFilterValueAvailable={true}
                      />
                    )}
                  </div>
                </Box>
              </TableCell>
              {!isSideDrawerOpen && (
                <TableCell
                  classes={{ root: classes.tableCellDescription }}
                  style={{
                    color: "#98A6B3",
                    font: "normal normal normal 14px/22px Nunito Sans",
                  }}
                >
                  <Box
                    style={{ display: "flex", justifyContent: "space-between" }}
                  >
                    <div></div>
                    <TableSortLabel
                      active={sortField.field === "description"}
                      direction={
                        sortField.field === "description"
                          ? sortField.order === "ascending"
                            ? "asc"
                            : "desc"
                          : "asc"
                      }
                      onClick={(e) => {
                        handleSort("description");
                      }}
                      classes={{
                        icon: classes.sortLabelIcon,
                        root: classes.sortLabelRoot,
                        active: classes.sortLabelActive,
                      }}
                    >
                      Description
                    </TableSortLabel>
                    <div>
                      <IconButton
                        aria-label="more"
                        aria-controls="long-menu"
                        aria-haspopup="true"
                        id="descriptionColumnOptions"
                        classes={{
                          root: classes.columnOptionsIcon,
                        }}
                        onClick={() => {
                          setTableFilter((oldState) => {
                            return {
                              ...oldState,
                              filter: {
                                ...oldState.filter,
                                description: {
                                  ...oldState.filter.description,
                                  menuOpen: true,
                                },
                              },
                            };
                          });
                        }}
                      >
                        {tableFilter.filter.description.searchValue ? (
                          <Tooltip title="Filter applied on description">
                            <FilterListIcon />
                          </Tooltip>
                        ) : (
                          <MoreVertIcon />
                        )}
                      </IconButton>
                      {tableFilter.filter.description.menuOpen && (
                        <DescriptionColumnMenu
                          open={tableFilter.filter.description.menuOpen}
                          onClose={() =>
                            setTableFilter((oldState) => {
                              return {
                                ...oldState,
                                filter: {
                                  ...oldState.filter,
                                  description: {
                                    ...oldState.filter.description,
                                    menuOpen: false,
                                  },
                                },
                              };
                            })
                          }
                          searchValue={
                            tableFilter.filter.description.searchValue
                          }
                          resetFilters={() => resetDescriptionFilter()}
                          classes={styleClass}
                          searchOperator={
                            tableFilter.filter.description.searchOperator
                          }
                          isFilterApplied={
                            tableFilter.filter.description.searchValue.length >
                            0
                          }
                          onApplyFilterButtonClick={(value) =>
                            handleDescriptionApplyFilterButtonClick(value)
                          }
                        />
                      )}
                    </div>
                  </Box>
                </TableCell>
              )}
              {/* <TableCell classes={{root: classes.tableCellTags}}>Tags</TableCell>               */}
              <TableCell
                classes={{ root: classes.tableCellCreatedDate }}
                style={{
                  color: "#98A6B3",
                  font: "normal normal normal 14px/22px Nunito Sans",
                }}
              >
                <Box
                  style={{ display: "flex", justifyContent: "space-between" }}
                >
                  <div></div>
                  <TableSortLabel
                    active={sortField.field === "created_at"}
                    direction={
                      sortField.field === "created_at"
                        ? sortField.order === "ascending"
                          ? "asc"
                          : "desc"
                        : "asc"
                    }
                    onClick={(e) => {
                      handleSort("created_at");
                    }}
                    classes={{
                      icon: classes.sortLabelIcon,
                      root: classes.sortLabelRoot,
                      active: classes.sortLabelActive,
                    }}
                  >
                    Created On
                  </TableSortLabel>
                  <Box>
                    <IconButton
                      aria-label="more"
                      aria-controls="long-menu"
                      aria-haspopup="true"
                      id="createdDateColumnOptions"
                      classes={{
                        root: classes.columnOptionsIcon,
                      }}
                      onClick={() => {
                        setTableFilter((oldState) => {
                          return {
                            ...oldState,
                            filter: {
                              ...oldState.filter,
                              createdDate: {
                                ...oldState.filter.createdDate,
                                menuOpen: true,
                              },
                            },
                          };
                        });
                      }}
                    >
                      {tableFilter.filter.createdDate.minValue !== "" ||
                        tableFilter.filter.createdDate.maxValue !== "" ? (
                        <Tooltip title="Filter applied on date">
                          <FilterListIcon />
                        </Tooltip>
                      ) : (
                        <MoreVertIcon />
                      )}
                    </IconButton>
                    {tableFilter.filter.createdDate.menuOpen && (
                      <CreatedDateColumnMenu
                        open={tableFilter.filter.createdDate.menuOpen}
                        onClose={() =>
                          setTableFilter((oldState) => {
                            return {
                              ...oldState,
                              filter: {
                                ...oldState.filter,
                                createdDate: {
                                  ...oldState.filter.createdDate,
                                  menuOpen: false,
                                },
                              },
                            };
                          })
                        }
                        minValue={tableFilter.filter.createdDate.minValue}
                        maxValue={tableFilter.filter.createdDate.maxValue}
                        resetFilters={() => resetDateFilter()}
                        classes={styleClass}
                        isFilterApplied={
                          tableFilter.filter.createdDate.minValue !== "" ||
                          tableFilter.filter.createdDate.maxValue !== ""
                        }
                        onApplyFilterButtonClick={(value) =>
                          handleResourceCreatedDateApplyFilterButtonClick(value)
                        }
                        setFilterMinMaxValue={(value) =>
                          setCreatedDateFilterMinMaxValue(value)
                        }
                      />
                    )}
                  </Box>
                </Box>
              </TableCell>
              {props.scenario !== "browseCollectionsPopup" && props.scenario !== "aiMarketplaceDataSelection" && props.scenario !== "aiMarketplaceSelectedDataList" && (
                <TableCell
                  classes={{ root: classes.tableCellActions }}
                  style={{
                    color: "#98A6B3",
                    font: "normal normal normal 14px/22px Nunito Sans",
                  }}
                >
                  Actions
                </TableCell>
              )}
              <TableCell classes={{ root: classes.tableCellView }}></TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {isFetching &&
              new Array(10).fill("").map((item, index) => (
                <TableRow key={index}>
                  <TableCell>
                    <CustomSkeleton height={48} />
                  </TableCell>
                  {/* <TableCell>
                    <CustomSkeleton height={48} />
                  </TableCell> */}
                  <TableCell>
                    <CustomSkeleton height={48} />
                  </TableCell>
                  <TableCell>
                    <CustomSkeleton height={48} />
                  </TableCell>
                  <TableCell>
                    <CustomSkeleton height={48} />
                  </TableCell>
                  <TableCell>
                    <CustomSkeleton height={48} />
                  </TableCell>
                  <TableCell>
                    <CustomSkeleton height={48} />
                  </TableCell>
                </TableRow>
              ))}
            {!isFetching &&
              collections.collections?.map((row, index) => {
                const isCheckboxChecked = isCollectionCheckboxChecked(row._id);
                return (
                  <TableRow
                    hover
                    key={row._id}
                    id={row._id}
                    selected={selected?._id === row._id}
                    classes={{
                      root: classes.tableRow,
                      selected: classes.rowSelected,
                    }}
                    className={
                      row._id === defaultCollectionId
                        ? classes.defaultDatasetRowBorder
                        : ""
                    }
                  >
                    {props.scenario !== "browseCollectionsPopup" && (
                      <TableCell
                        padding="checkbox"
                        classes={{ root: classes.tableCellCheckbox }}
                      >
                        <Checkbox
                          inputProps={{ "aria-labelledby": row._id }}
                          checked={isCheckboxChecked}
                          onChange={() => handleCollectionRowCheckBoxClick(row)}
                        />
                      </TableCell>
                    )}
                    <TableCell
                      classes={{ root: classes.tableCellName }}
                      style={{
                        textAlign:
                          (props.scenario === "browseCollectionsPopup")
                            ? "left"
                            : "center",
                      }}
                    >
                      <Typography component="p" variant="subtitle2" noWrap>
                        <Tooltip title={row.name}>
                          <>
                            {defaultCollectionId === row._id && (
                              <span className={classes.defaultTextColor}>
                                [Default]&nbsp;
                              </span>
                            )}
                            <span>{row.name}</span>
                          </>
                        </Tooltip>
                      </Typography>
                    </TableCell>
                    {!isSideDrawerOpen && (
                      <TableCell
                        classes={{ root: classes.tableCellDescription }}
                      >
                        <Typography component="p" variant="subtitle2" noWrap>
                          <Tooltip title={row.description}>
                            <span>{row.description}</span>
                          </Tooltip>
                        </Typography>
                      </TableCell>
                    )}
                    {/* <TableCell
                      classes={{root: classes.tableCellTags}}
                      onClick={() => handleClick(row)}
                    >
                      <Tooltip title={row.tags?.join(", ")}>
                        <Typography
                          component="p"
                          noWrap
                        >
                          {row.tags?.map((tag) => (
                            <Chip
                              key={tag}
                              label={tag}
                              classes={{ root: classes.chip }}
                            />
                          ))}
                        </Typography>
                      </Tooltip>
                    </TableCell> */}
                    <TableCell classes={{ root: classes.tableCellCreatedDate }}>
                      <Tooltip title={convertDate(row.created_at)}>
                        <Typography component="p" variant="subtitle2" noWrap>
                          {convertDate(row.created_at)}
                        </Typography>
                      </Tooltip>
                    </TableCell>
                    {props.scenario !== "browseCollectionsPopup" && props.scenario !== "aiMarketplaceDataSelection" && props.scenario !== "aiMarketplaceSelectedDataList" && (
                      <TableCell classes={{ root: classes.tableCellActions }}>
                        <div
                          style={{ display: "flex", justifyContent: "center" }}
                        >
                          {!row.isDeleteHappening ? (
                            <Tooltip title={"Delete the collection"}>
                              <IconButton
                                id={"deleteBtn" + row._id}
                                classes={{
                                  root: classes.deleteButton,
                                  disabled: classes.disabledDeleteBtn,
                                }}
                                aria-label="export"
                                disabled={row.isDeleteHappening}
                                onClick={() => {
                                  confirmDeleteCollection({
                                    multiDelete: false,
                                    collectionToDelete: row,
                                    showConfirmDeleteModal: true,
                                    selectedCollectionIds: [],
                                    collectionType: "dataSet",
                                  });
                                }}
                              >
                                <Delete />
                              </IconButton>
                            </Tooltip>
                          ) : (
                            <Tooltip title="Deleting data-set collection">
                              <div
                                style={{
                                  marginLeft: "12px",
                                  marginRight: "12px",
                                }}
                              >
                                <CircularProgress
                                  style={{
                                    color: "#EF8A8A",
                                    alignSelf: "center",
                                    margin: "12px",
                                    marginLeft: 0,
                                    marginRight: 0,
                                  }}
                                  size="21px"
                                  color="primary"
                                />
                              </div>
                            </Tooltip>
                          )}
                          {
                            row.isResourcesFileDownloadLinkBeingFetched ? (
                              <Tooltip title="Generating and downloading data-set collection csv file">
                                <div
                                  style={{
                                    padding: "6px 8px",
                                    width: "64px",
                                    height: "48px",
                                  }}
                                >
                                  <CircularProgress
                                    style={{
                                      alignSelf: "center",
                                      margin: "12px",
                                      marginLeft: 0,
                                      marginRight: 0,
                                    }}
                                    size="21px"
                                    color="primary"
                                  />
                                </div>
                              </Tooltip>
                            ) : (
                              <Tooltip title="More Actions">
                                <SubActionMenu
                                  modelId={modelId}
                                  defaultDatasetCollection={
                                    props.defaultDatasetCollection
                                  }
                                  datasetRow={row}
                                  handleAddPromptAnsInDataSetActionButtonClick={() =>
                                    handleAddPromptAnsInDataSetActionButtonClick(
                                      row._id
                                    )
                                  }
                                  modelType={parentModel?.type || "audio"}
                                  handleDataSetCollectionDownloadButtonClick={
                                    handleDataSetCollectionDownloadButtonClick
                                  }
                                  handleTrainButtonClick={
                                    handleTrainButtonClick
                                  }
                                  addModelCollectionScenario={
                                    addModelCollectionScenario
                                  }
                                  handleReTrainButtonClick={
                                    handleReTrainButtonClick
                                  }
                                  trainingDataDirectoriesSelectedStateForAddModelCollection={
                                    trainingDataDirectoriesSelectedStateForAddModelCollection
                                  }
                                  handleDatasetCopyBtnClick={
                                    handleCopyCollectionButtonClick
                                  }
                                  handleDatasetAiTestBtnClick={
                                    handleAiTestForDatasetCollection
                                  }
                                  handleSplitDatasetBtnClick={
                                    handleSplitDatasetBtnClick
                                  }
                                  handleShuffleDatasetBtnClick={
                                    handleShuffleDatasetBtnClick
                                  }
                                  loadingAiTest={loadingAiTest}
                                  collectionType="dataSetCollection"
                                />
                                {/* <Button aria-controls="fade-menu" aria-haspopup="true" onClick={handleClickMoreActions}>
                          <MenuOpenIcon/>
                        </Button> */}
                              </Tooltip>
                            )
                            // <CircularProgress style={{alignSelf: 'center', margin: '12px'}} size="25px" color="primary" />
                          }
                          {/* <Menu
                          id="fade-menu"
                          anchorEl={moreActionMenu}
                          getContentAnchorEl={null}
                          anchorOrigin={{ vertical: "bottom", horizontal: "center" }}
                          transformOrigin={{ vertical: "top", horizontal: "center" }}
                          keepMounted
                          open={actionMenuOpen}
                          onClose={hancleCloseMoreActions}
                          TransitionComponent={Fade}
                          elevation={1}
                        >
                        <MenuItem onClick={hancleCloseMoreActions}>
                        {
                          row.isResourcesFileDownloadLinkBeingFetched
                          ? <CircularProgress style={{alignSelf: 'center', margin: '12px'}} size="25px" color="primary" />
                          : <Tooltip 
                              title={
                              !(row.totalNoOfResources && row.totalNoOfResources > 0)
                                ? "Collection does not have any resources to download"
                                : "Download collection's resources details in a csv file"
                              }
                            >
                              <Box>
                                <IconButton
                                  color="primary"
                                  disabled={
                                    !(row.totalNoOfResources && row.totalNoOfResources > 0)
                                    ? true
                                    : false
                                  }
                                  onClick={() => {
                                    handleDataSetCollectionDownloadButtonClick(row)
                                  }}
                                >
                                  <GetApp />
                                </IconButton>
                              </Box>
                            </Tooltip>
                        }
                        </MenuItem>
                        <MenuItem onClick={hancleCloseMoreActions}>
                        <TrainDataSetCollectionButton 
                          onClick={()=>{handleTrainButtonClick(row)}}
                          isSelected={
                            addModelCollectionScenario === 'trainingDataSetCollections' && trainingDataDirectoriesSelectedStateForAddModelCollection.value.indexOf(row.name) !== -1
                            ? true
                            : false
                          }
                        />
                        </MenuItem>
                        <MenuItem onClick={hancleCloseMoreActions}>
                        <ReTrainDataSetCollectionButton 
                          onClick={()=>{handleReTrainButtonClick(row)}}
                          isSelected={
                            addModelCollectionScenario === 'reTrainingDataSetCollections' && trainingDataDirectoriesSelectedStateForAddModelCollection.value.indexOf(row.name) !== -1
                            ? true
                            : false
                          }
                        />
                        </MenuItem>
                        </Menu> */}
                        </div>
                      </TableCell>
                    )}

                    <TableCell classes={{ root: `${classes.tableCellView}` }}>
                      {/* <Tooltip title="View Details">
                      <div style={{ width: '100%', display: 'flex', justifyContent: 'center' }}>
                        <Button
                          startIcon={<FullscreenIcon />}
                          onClick={() => {
                            if (row._id === selected?._id) {
                              setSideDrawer(({type: "collectionView", component: setDataSetViewProps}))
                              props.setDrawerOpen(!props.isSideDrawerOpen)
                            } else {
                              handleClick(row)
                            }
                          }}
                          color='primary' variant='contained'
                          classes={{startIcon : `${classes.viewIcon}`}}
                          style={{ textTransform: 'none' , padding: "8px 0px", minWidth: "50px"}}></Button>
                      </div>
                      </Tooltip> */}
                      <div
                        style={{
                          width: "100%",
                          display: "flex",
                          justifyContent: "center",
                        }}
                      >
                        <DataSetCollectionPopupOpenButton
                          onClick={() => {
                            handlePopupViewClick(row);
                          }}
                        />
                      </div>
                    </TableCell>
                  </TableRow>
                );
              })}
            {!isFetching && !collections.collections?.length && (
              <NoTableData colSpan={tableHeadCellsCount} />
            )}
          </TableBody>
        </Table>
      </TableContainer>
      {collections.collections.length > 0 && (
        <TablePagination
          rowsPerPageOptions={[10, 25, 50, 100]}
          component="div"
          count={collections?.totalCount || 0}
          rowsPerPage={rowsPerPage}
          page={page}
          onChangePage={handleChangePage}
          onChangeRowsPerPage={handleChangeRowsPerPage}
        />
      )}
      <DeleteCollectionErrorPopup />
      <Dialog
        open={selectedCollectionToCopy !== null}
        onClose={copyDialogCloseHandler}
      >
        <DialogContent classes={{ root: `${classes.copyDialog}` }}>
          <FormControl className={classes.formControl}>
            <InputLabel id="model-selection-helper-label">Task</InputLabel>
            <Select
              labelId="model-selection-helper-label"
              id="model-selection-helper"
              autoWidth
              required
              value={selectedChildModel}
              onChange={(e) => {
                setChildDatasetName(selectedCollectionToCopy?.name || "");
                setSelectedChildModel(e.target.value + "");
                checkNameUniqueness(selectedCollectionToCopy?.name || "");
              }}
            >
              {modelsList.map((model) => {
                return (
                  <MenuItem key={model._id} value={model._id}>
                    {model.name}
                  </MenuItem>
                );
              })}
            </Select>
            <FormHelperText>
              Select task to copy dataset collection in
            </FormHelperText>
          </FormControl>
          {selectedChildModel && (
            <div>
              <FormControl className={classes.formControl}>
                <div className={`${classes.flexContainer}`}>
                  <TextField
                    autoFocus
                    margin="dense"
                    error={uniqueNameStatus === "duplicate" ? true : false}
                    id="name"
                    label="New dataset collection name"
                    type="text"
                    value={childDatasetName}
                    onChange={(e) => {
                      if (canGenerateUniqueName) {
                        setCanGenerateUniqueName(false);
                      }
                      if (!e.target.value) {
                        setCanGenerateUniqueName(true);
                      }
                      setChildDatasetName(e.target.value);
                    }}
                    helperText={
                      uniqueNameStatus === "duplicate"
                        ? "Name should be unique"
                        : "Enter a unique name"
                    }
                    style={{ width: "60%" }}
                    onBlur={(e) => checkNameUniqueness(e.target.value)}
                  />
                  <Button
                    disabled={
                      !canGenerateUniqueName || uniqueNameStatus === "unique"
                    }
                    variant="contained"
                    size="small"
                    color="primary"
                    onClick={generateUniqueName}
                  >
                    Generate Unique Name
                  </Button>
                </div>
                <TextField
                  autoFocus
                  margin="normal"
                  id="Description"
                  label="New dataset collection description"
                  type="text"
                  value={childDescription}
                  onChange={(e) => setChildDescription(e.target.value)}
                  fullWidth
                />
                {uniqueNameStatus === "started" && (
                  <Alert classes={{ root: classes.copyAlert }} severity="info">
                    Copying process has been started. You can check the progress
                    by visiting this new dataset collection.
                  </Alert>
                )}
              </FormControl>
              {parentModel?.type === "imageAnnotation" && (
                <div style={{ display: "flex" }}>
                  <Checkbox
                    checked={isImageAnnotationsToBeCopied}
                    onClick={handleToggleCopyImageAnnotations}
                    disabled={isModelTypeImage ? true : false}
                  />
                  <FormHelperText
                    style={{
                      display: "flex",
                      alignItems: "center",
                      marginLeft: "5px",
                      fontSize: "15px",
                    }}
                  >
                    Copy image annotations
                  </FormHelperText>
                </div>
              )}
            </div>
          )}
        </DialogContent>
        <DialogActions>
          <Button
            onClick={copyDatasetCollectionHandler}
            disabled={isCopyingDataset}
            color="primary"
            size="large"
          >
            {isCopyingDataset ? <CircularProgress size={20} /> : "Copy"}
          </Button>
        </DialogActions>
      </Dialog>
      {/* split dataset popup */}
      <Dialog
        open={selectedCollectionToSplit !== null}
        onClose={splitDialogCloseHandler}
      >
        <DialogContent classes={{ root: `${classes.splitDialog}` }}>
          <FormControl className={classes.formControl}>
            <Typography variant="h6" className={classes.header}>
              Split into train & test data-sets
            </Typography>
            <Grid container spacing={2} className={classes.inputRow}>
              <Grid item xs={7}>
                <TextField
                  fullWidth
                  label="Train Data-set collection name"
                  variant="outlined"
                  value={trainDataSetName}
                  onChange={handleTrainDataSetNameChange}
                  onBlur={handleBlurTrainDataSetName}
                  error={!!trainDataSetNameError || !!isTrainDataSetNameUniqueError}
                  helperText={trainDataSetNameError || isTrainDataSetNameUniqueError}
                />
              </Grid>
              <Grid item xs={5}>
                <TextField
                  fullWidth
                  label="Split allocation (%)"
                  variant="outlined"
                  style={{ whiteSpace: 'nowrap' }}
                  value={trainPercentage}
                  onChange={handlePercentageChange(true)}
                  onBlur={handleBlurTrainPercentage}
                  error={parseInt(trainPercentage, 10) + parseInt(testPercentage, 10) !== 100}
                  helperText={parseInt(trainPercentage, 10) + parseInt(testPercentage, 10) !== 100 ? 'Percentages must add up to 100%' : ''}
                />
                {trainPercentage && selectedCollectionToSplit?.totalNoOfResources !== undefined && (
                  <span style={{ color: totalTrainResources === 0 ? '#fa8072' : '#6c757d', fontSize: '12px' }}>
                    Total Data points count: {totalTrainResources}
                  </span>
                )}
              </Grid>
            </Grid>
            <Grid container spacing={2} className={classes.inputRow}>
              <Grid item xs={7}>
                <TextField
                  fullWidth
                  label="Test Data-set collection name"
                  variant="outlined"
                  value={testDataSetName}
                  onChange={handleTestDataSetNameChange}
                  onBlur={handleBlurTestDataSetName}
                  error={!!testDataSetNameError || !!isTestDataSetNameUniqueError}
                  helperText={testDataSetNameError || isTestDataSetNameUniqueError}
                />
              </Grid>
              <Grid item xs={5}>
                <TextField
                  fullWidth
                  label="Split allocation (%)"
                  variant="outlined"
                  value={testPercentage}
                  style={{ whiteSpace: 'nowrap' }}
                  onChange={handlePercentageChange(false)}
                  onBlur={handleBlurTestPercentage}
                  error={parseInt(trainPercentage, 10) + parseInt(testPercentage, 10) !== 100}
                  helperText={parseInt(trainPercentage, 10) + parseInt(testPercentage, 10) !== 100 ? 'Percentages must add up to 100%' : ''}
                />
                {testPercentage && selectedCollectionToSplit?.totalNoOfResources !== undefined && (
                  <span style={{ color: totalTestResources === 0 ? '#fa8072' : '#6c757d', fontSize: '12px' }}>
                    Total Data points count: {totalTestResources}
                  </span>
                )}
              </Grid>
            </Grid>
            <FormControlLabel
              control={
                <Checkbox checked={isShuffleCheckboxActive} onChange={(e) => setIsShuffleCheckboxActive(e.target.checked)} color="primary" />
              }
              label="Shuffle data points"
              // className={classes.checkboxContainer}
              color="primary"
              style={{ width: 'fit-content' }}
            />
          </FormControl>
        </DialogContent>
        <DialogActions>
          <Button
            onClick={splitDialogCloseHandler}
            color="primary"
            size="large"
            variant="outlined"
            style={{ textTransform: 'capitalize' }}
          >
            Cancel
          </Button>
          <Tooltip title={getSplitButtonToolTip()}>
            <div>
            <Button
              onClick={handleSplit}
              disabled={isSplittingDataset || !trainDataSetName || !trainPercentage || !testDataSetName || !testPercentage || !totalTrainResources || !totalTestResources}
              color="primary"
              size="large"
              variant="contained"
              style={{ textTransform: 'capitalize' }}
            >
              {isSplittingDataset ? <CircularProgress size={20} /> : "Split"}
            </Button>
              </div>
          </Tooltip>
        </DialogActions>
      </Dialog>
      {/* shuffle dataset popup  */}
      <Dialog
        open={selectedCollectionToShuffle !== null}
        onClose={shuffleDialogCloseHandler}
      >
        <DialogContent classes={{ root: `${classes.copyDialog}` }}>
          <FormControl className={classes.formControl}>
            <Typography variant="h6" className={classes.header}>
              Shuffle data-set collection data points
            </Typography>
            <TextField
              fullWidth
              label="new data-set collection name"
              variant="outlined"
              value={shuffleDataSetName}
              onChange={handleShuffleDataSetNameChange}
              onBlur={handleBlurShuffleDataSetName}
              error={!!shuffleDataSetNameError}
              helperText={shuffleDataSetNameError}
            />
          </FormControl>
        </DialogContent>
        <DialogActions>
          <Button
            onClick={shuffleDialogCloseHandler}
            color="primary"
            size="large"
            variant="outlined"
            style={{ textTransform: 'capitalize' }}
          >
            Cancel
          </Button>
          <Button
            onClick={handleShuffle}
            disabled={isShufflingDataset || !!shuffleDataSetNameError || !shuffleDataSetName}
            color="primary"
            size="large"
            variant="contained"
            style={{ textTransform: 'capitalize' }}
          >
            {isShufflingDataset ? <CircularProgress size={20} /> : "Shuffle"}
          </Button>
        </DialogActions>
      </Dialog>
      <DeleteCollectionConfirmPopup />

      {addPromptAnsInDataSetPopup.open && (
        <AddPromptAnsInDataSetDialog
          dataSetCollectionIdOfPrompt={
            addPromptAnsInDataSetPopup.dataSetCollectionIdOfPrompt
          }
          projectId={
            query.get(DataSetCollectionTableQueryParam.PROJECT_ID) || ""
          }
          onClose={() => {
            setAddPromptAnsInDataSetPopup((oldState) => ({
              ...oldState,
              open: false,
            }));
          }}
        />
      )}
    </Paper>
  );
}

function DescriptionColumnMenu(props: {
  open: boolean;
  searchValue: string;
  searchOperator: StringSearchOperator;
  onClose:
  | ((event: {}, reason: "backdropClick" | "escapeKeyDown") => void)
  | undefined;
  resetFilters: () => void;
  isFilterApplied: boolean;
  onApplyFilterButtonClick: (filter: any) => void;
  classes: { tableColumnMenuHeading: CSSProperties; floating: CSSProperties };
}) {
  const [searchTextFieldState, setSearchTextFieldState] = useState<
    InputFieldState<string>
  >({ value: props.searchValue });
  const [searchOperatorState, setSearchOperatorState] = useState<
    InputFieldState<StringSearchOperator>
  >({ value: props.searchOperator });

  return (
    <Popover
      id="description-column-menu"
      anchorEl={document.getElementById("descriptionColumnOptions")}
      keepMounted
      anchorOrigin={{
        vertical: "bottom",
        horizontal: "right",
      }}
      transformOrigin={{
        vertical: "top",
        horizontal: "left",
      }}
      open={props.open}
      onClose={props.onClose}
      PaperProps={{
        style: {
          width: "450px",
        },
      }}
    >
      {props.open && (
        <Box id="nameMenuItemContainer" style={{ padding: 15 }}>
          <div className={`${props.classes.tableColumnMenuHeading}`}>
            <Typography
              variant="body1"
              style={{ marginBottom: 20 }}
              color="primary"
            >
              &#8288;Description Column Filter
            </Typography>
            <Tooltip title="Reset Filters">
              <Box>
                <TableColumnResetFilterButton
                  onClick={() => props.resetFilters()}
                  disabled={!props.isFilterApplied}
                />
              </Box>
            </Tooltip>
          </div>
          <div style={{ display: "flex", justifyContent: "space-between" }}>
            <TextField
              size="small"
              style={{ width: "49%" }}
              variant="outlined"
              select
              label="Search Operator"
              className={`${props.classes.floating}`}
              value={searchOperatorState.value}
              onChange={(e) => {
                setSearchOperatorState((oldState) => {
                  return {
                    ...oldState,
                    value: e.target.value as StringSearchOperator,
                    isDirty: true,
                    isTouched: true,
                  };
                });
              }}
            >
              {SearchOperatorAvailableValues.map((option) => (
                <MenuItem key={option} value={option}>
                  {option}
                </MenuItem>
              ))}
            </TextField>
            <div style={{ width: "auto" }}></div>
            <TextField
              size="small"
              variant="outlined"
              label="Description search value"
              className={`${props.classes.floating}`}
              style={{ width: "49%" }}
              value={searchTextFieldState.value}
              onChange={(e) => {
                setSearchTextFieldState((oldState) => {
                  return { ...oldState, value: e.target.value, isDirty: true };
                });
              }}
              onBlur={() => {
                setSearchTextFieldState((oldState) => {
                  return { ...oldState, isTouched: true };
                });
              }}
            />
          </div>

          <div
            id="resetFilterButtonContainer"
            style={{
              marginTop: "40px",
              display: "flex",
              justifyContent: "space-between",
            }}
          >
            <div></div>
            <Button
              variant="contained"
              color="primary"
              onClick={() =>
                props.onApplyFilterButtonClick({
                  search: searchTextFieldState.value,
                  operator: searchOperatorState.value,
                })
              }
              disabled={
                !searchTextFieldState.isDirty && !searchOperatorState.isDirty
              }
            >
              Apply Filters
            </Button>
          </div>
        </Box>
      )}
    </Popover>
  );
}

const mapProps = (
  state: IReduxState,
  ownProps: {
    aiAssistantSenerio?: boolean;
    scenario?: "browseCollectionsPopup" | "aiMarketplaceDataSelection" | "aiMarketplaceSelectedDataList";
    itemsAddedToCart?: IDataResponse;
    toggleAddItemToCart?: (dir: number, item: IData) => void;
  }
) => {
  return {
    collections: state.collection.collections,
    isFetching: state.collection.isFetching,
    selectedType: state.optionsDrawer.type,
    selected: state.collection.selected,
    isCollectionAddSuccess: state.collection.isAddSuccess,
    isCollectionDeleteSuccess: state.collection.isDeleteSuccess,
    isCollectionsDeleting: state.collection.isDeleting,
    isSideDrawerOpen: state.optionsDrawer.isOpen,
    selectedCollections: state.collection.dataSetCollectionsSelected,
    trainingDataDirectoriesSelectedStateForAddModelCollection:
      state.collection.modelCollection
        .trainingDataDirectoriesSelectedStateForAddModelCollection,
    defaultDatasetCollection: state.collection.defaultDataSetCollection,
    scenario: ownProps.scenario,
    aiAssistantSenerio: ownProps.aiAssistantSenerio,
  };
};

const connector = connect(mapProps, {
  getCollections,
  setSideDrawer,
  setDrawerOpen,
  setSelectedCollection: setSelectedDataSetCollection,
  setTopActionButton,
  deleteCollection,
  setSelectedCollections: setSelectedDataSetCollections,
  addSelectedDataSetCollections,
  setTrainingDataDirectoriesSelectedStateForAddModelCollection,
  setIsDataSetCollectionResourcesFileDownloadLinkBeingFetched,
  setDefaultDatasetCollection,
  confirmDeleteCollection,
});

export type TPropsFromRedux = ConnectedProps<typeof connector>;

export default connector(DataSetCollection);

import { isNullOrUndefined } from "./variableHelperService";

export class NumberService {
  static convertDecimalToInteger(
    decimalNumber: number | undefined
  ): number | undefined {
    if (isNullOrUndefined(decimalNumber)) {
      return decimalNumber;
    }
    // @ts-ignore
    return Math.round(decimalNumber);
  }
}

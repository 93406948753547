import React, { Suspense } from "react";
import { Redirect, Route, Switch, useRouteMatch } from "react-router-dom";

const DataSetCollection = React.lazy(
  () => import("./components/DataSet-Collection")
);
const ModelCollection = React.lazy(
  () => import("./components/Model-Collection")
);
const TestModelCollection = React.lazy(
  () => import("./components/TestModel-Collection")
);
const AnalyticsResources = React.lazy(
  () => import("./components/ModelAnalyticsResources")
)
const Integrity = React.lazy(
  () => import("./components/integrity")
)
const IndividualClassAccuracy = React.lazy(
  () => import("./components/individualClassAccuracy")
)

const ModelAnalyticsDashboard = React.lazy(
  () => import("./components/ModelAnalyticsDashboard")
);

export default function CollectionRoutes() {
  let { path } = useRouteMatch();
  return (
    <Suspense fallback={""}>
      <Switch>
        {/* <Route path={`${path}/data-set`} component={DataSetCollection}></Route> */}
        <Route path={`${path}/model`} component={ModelCollection}></Route>
        <Route path={`${path}/testModel`} component={TestModelCollection}></Route>
        <Route path={`${path}/modelAnalytics/resources`} component={AnalyticsResources}></Route>
        <Route path={`${path}/modelanalytics/integrity`} component={Integrity}></Route>
        <Route path={`${path}/modelanalytics/IndividualClassAccuracy`} component={IndividualClassAccuracy}></Route>
        <Route path={`${path}/modelAnalytics/dashboard`} component={ModelAnalyticsDashboard}></Route>
        <Redirect
          from={path}
          to={`${path}/model?limit=10&offset=1&drawer=analytics`}
          exact
        />
      </Switch>
    </Suspense>
  );
}

import { useEffect, useMemo, useState } from "react";
import { Skeleton } from '@material-ui/lab';
import { OneMB } from "../../features/admin/features/projects/features/models/features/model/features/data/components/TableContent";

/**
 * @description
 * This component is used to show an image in particular image and height while maintaining its aspect ratio
 * so that it must not look cropped.
 *
 * It is advisable to set image width and height to be of same number
 */

type Props = {
	/** same number preferred */
	maxImageWidth: number;
	maxImageHeight: number;
	imagePlotterSuffix ?: string;
	imageUrl: string;
	canvasStyle?: any;
	skeletonHeight?: number;
};

export default function ImageAspectRatioScaler(props: Props) {
	const [isImageLoading, setIsImageLoading] = useState(false);
	const [canvasState, setCanvasState] = useState({
		width: 0,
		height: 0,
	});
	const [shouldRenderOriginalImage, setShouldRenderOriginalImage] = useState<boolean>(false);
	const { imageUrl, maxImageWidth, maxImageHeight, skeletonHeight } = props;

	const canvasStyle = useMemo(()=>{
		return props.canvasStyle || {}
	}, [props.canvasStyle]);

	useEffect(() => {
		const executeFunction = async () => {
			setIsImageLoading(true);
			let base_image = new Image();
			base_image.src = imageUrl;

			let file;
			fetch(imageUrl)
				.then(res => res.blob())
				.then(blob => {
					file = new File([blob], 'dot.png', blob)
					console.log(file);
					const blobURL = URL.createObjectURL(file);
					console.log(blobURL);
					const img = new Image();
					img.src = blobURL;
					img.onload = function () {
						// URL.revokeObjectURL(this.src);
						// const [newWidth, newHeight] = calculateSize(img, MAX_WIDTH, MAX_HEIGHT);
						let imageWidth = img.width;
						let imageHeight = img.height;
						let differenceBetweenCanvasAndImageToSet: number = 0;
						let canvasHeightToSet: number = maxImageHeight;
						let canvasWidthToSet: number = maxImageWidth;
						let blobSizeInMB = blob.size/(1024*1024)
				
						if (imageWidth > imageHeight)
							{
								differenceBetweenCanvasAndImageToSet = imageWidth / maxImageWidth;
								canvasHeightToSet = imageHeight / differenceBetweenCanvasAndImageToSet ;
								canvasWidthToSet = maxImageWidth;
							} 
						else if (imageHeight > imageWidth) {
							differenceBetweenCanvasAndImageToSet = imageHeight / maxImageHeight;
							canvasWidthToSet = imageWidth / differenceBetweenCanvasAndImageToSet;
							canvasHeightToSet = maxImageHeight;
						} 
				
						if (canvasHeightToSet) {
							setCanvasState((oldState) => ({
								...oldState,
								height: canvasHeightToSet,
							}));
						}
		
						if (canvasWidthToSet) {
							setCanvasState((oldState) => ({
								...oldState,
								width: canvasWidthToSet,
							}));
						}
						setIsImageLoading(false);

						if(blobSizeInMB > OneMB){
							setShouldRenderOriginalImage(false);
							let canvas = document.getElementById(`canvasImage${imageUrl}${props.imagePlotterSuffix ? props.imagePlotterSuffix : ""}`) as HTMLCanvasElement;
							let context;
							if(canvas){
								context = canvas.getContext("2d");
							}
							// canvas.width = 'grid-view' ? canvasWidthToSet : 40;
							// canvas.height = 'grid-view' ? canvasHeightToSet : 40;
							// const ctx = canvas.getContext("2d");
							context &&
							(context.drawImage(img, 0 , 0, canvasWidthToSet, canvasHeightToSet))
							canvas &&
							canvas.toBlob(
								(blob) => {
									// Handle the compressed image. es. upload or save in local state
									// displayInfo('Original file', file);
									// displayInfo('Compressed file', blob);
								},
								"image/jpeg",
								0.7
							);
						}else{
							setShouldRenderOriginalImage(true);
						}
						// document.getElementById("root").append(canvas);
					};
				})
			// base_image.onload = function () {
			// 	let differenceBetweenCanvasAndImageToSet: number | undefined = undefined;
			// 	let canvasHeightToSet: number = 0;
			// 	let canvasWidthToSet: number = 0;

			// 	let imageWidth = base_image.width;
			// 	let imageHeight = base_image.height;

			// 	if (imageWidth > imageHeight) {
			// 		differenceBetweenCanvasAndImageToSet = imageWidth / maxImageWidth;
			// 		canvasHeightToSet = imageHeight / differenceBetweenCanvasAndImageToSet;
			// 		canvasWidthToSet = maxImageWidth;
			// 	} else if (imageHeight > imageWidth) {
			// 		differenceBetweenCanvasAndImageToSet = imageHeight / maxImageHeight;
			// 		canvasWidthToSet = imageWidth / differenceBetweenCanvasAndImageToSet;
			// 		canvasHeightToSet = maxImageHeight;
			// 	}
			// 	else if (imageHeight === imageWidth) {
			// 		canvasWidthToSet = maxImageWidth;
			// 		canvasHeightToSet = maxImageHeight;
			// 	}

			// 	if (canvasHeightToSet) {
			// 		setCanvasState((oldState) => ({
			// 			...oldState,
			// 			height: canvasHeightToSet,
			// 		}));
			// 	}

			// 	if (canvasWidthToSet) {
			// 		setCanvasState((oldState) => ({
			// 			...oldState,
			// 			width: canvasWidthToSet,
			// 		}));
			// 	}
				
			// 	setIsImageLoading(false);
				
			// };
		};
		if (imageUrl) {
			executeFunction();
		}
	}, [imageUrl, maxImageHeight, maxImageWidth]);

	return (
			!isImageLoading
			?
				(
					<canvas
						id={`canvasImage${imageUrl}${props.imagePlotterSuffix ? props.imagePlotterSuffix : ""}`}
						width={canvasState.width}
						height={canvasState.height}
						style={{
							// width: `${canvasState.width}px`,
							backgroundImage: `url(${!isImageLoading ? shouldRenderOriginalImage ? imageUrl : "" : ""})`,
							// height: `${canvasState.height}px`,
							backgroundSize: `${canvasState.width}px ${canvasState.height}px`,
							margin: 'auto',
							...canvasStyle
						}}
					></canvas>
				)
			:
				(
					<Skeleton 
						variant="rect"
						style={{
							margin: 'auto',
						}}
						width={maxImageWidth}
						height={skeletonHeight || maxImageHeight}
					/>
				)
	);
}

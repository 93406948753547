import { useEffect, useState } from "react";
import { makeStyles, Theme, createStyles } from "@material-ui/core/styles";
import Modal from "@material-ui/core/Modal";
import Backdrop from "@material-ui/core/Backdrop";
import Fade from "@material-ui/core/Fade";
import Cancel from '@material-ui/icons/Cancel';
import {
  Button,
  FormControl,
  FormControlLabel,
  FormLabel,
  Radio,
  RadioGroup,
  useMediaQuery,
} from "@material-ui/core";
// import ImageAspectRatioScaler from "../../../../../../../../../common/components/ImageAspectRatioScaler";
import ImageAspectRatioScaler from "../../../../models/features/model/features/data/components/imageAspectRatioScalerWithAlignment.component";
import { connect, ConnectedProps, useDispatch } from "react-redux";
import { useHistory, useLocation } from "react-router";
import { getResources, updateCoPilotResource, updateIsDataFetching } from "../../../../../../../../../store/actions/coPilotDetailPageActions";
import { IReduxState, Status } from "../../../../../../../../../common";
import { CopilotService } from "../../../../../../../../../services";
import { useURLQuery } from "../../../../../../../../../hooks/useURLQuery";
import FileNameClipper from "../../../../../../../../../common/components/FileNameClipper";
import clsx from "clsx";
import { convertURLQueryParamsToJSON } from "../../../../../../../../../services/urlQueryParamService";
import TextTrimUI from "../../../../models/features/model/features/data/components/TextTrimUI/textTrimUI";

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    modal: {
      display: "flex",
      alignItems: "center",
      justifyContent: "center",
    },
    paper: {
      minWidth: "50%",
      position: "relative",
      backgroundColor: theme.palette.background.paper,
      boxShadow: theme.shadows[5],
      padding: theme.spacing(2, 4, 3),
      "&p": {
        margin: "0",
      },
    },
    clodeBtn: {
      position: "absolute",
      right: -10,
      display: "flex",
      justifyContent: "center",
      alignItems: "center",
      // padding: "2px",
      top: -10,
      backgroundColor: "white",
      borderRadius: "50%",
      // color: "white",
      cursor: "pointer",
      width: "25px",
      height: "25px"
    },
    row: {
      display: "flex",
      marginTop: "10px",
      justifyContent: "space-around",
    },
    col: {
      margin: "15px",
    },
    textRow: {
      display: "flex",
      // justifyContent: "space-between",
    },
    textRowTitle: {
      color: "gray",
    },
    textElement: {
      minWidth : '120px',
      display:'inline-block',
      fontSize: 'smaller'
    },
    rejectButton:{
      '&:enabled' : {
        backgroundColor: "#ff625c", 
        color:"#ffffff"
      },
      padding: '4px 60px'
    },
    buttonDisabled: {
      pointerEvents: "none",
      opacity: 0.5
    }
  })
);
interface RejectReasons {
  label: string;
  value: string;
}
const coPilotResourcesRejectReasons: Array<RejectReasons> = [
  {
    label: 'High Brightness',
    value: 'High Brightness'
  },
  {
    label: 'Low Brightness',
    value: 'Low Brightness'
  },
  {
    label: 'Blur Image',
    value: 'Blur Image'
  },
  {
    label: 'Other',
    value: 'Other'
  }
]

const coPilotAudioResourcesRejectReasons: Array<RejectReasons> = [
  {
    label: 'Invalid Audio',
    value: 'Invalid Audio'
  }
]

const textCopilotResourceRejectReasons: Array<RejectReasons> = [
  {
    label: "Garbage data",
    value: "Garbage data"
  }
]

const videoCopilotResourceRejectReasons: Array<RejectReasons> = [
  {
    label: "Invalid video",
    value: "Invalid video"
  },
  {
    label: "Not enough detections",
    value: "Not enough detections"
  },
  {
    label: "No detections",
    value: "No detections"
  },
]

const textAnnotationCopilotResourceRejectReasons: Array<RejectReasons> = [
  {
    label: "Garbage data",
    value: "Garbage data"
  },
  {
    label: "Incorrect data",
    value: "Incorrect data"
  },
  {
    label: "Mispredicted data",
    value: "Mispredicted data"
  },
  {
    label: "No data",
    value: "No data"
  },
]

const imageAnnotationResourceRejectReasons: Array<RejectReasons> = [
  {
    label: 'Camera Disoriented',
    value: 'Camera Disoriented'
  },
  {
    label: 'Camera Covered',
    value: 'Camera Covered'
  },
  {
    label: 'Blur',
    value: 'Blur'
  },
  {
    label: 'Other',
    value: 'Other'
  }
]


const imageBorderRadius: number = 4;

//   name: 'test co-pilot', type: 'image', _id: '62577e73fcbf1813875d6a8d', project: '6239ad9e530ae57d1e22a1e3'}
// confidenceScore: 100
// created_at: "2022-04-16T07:59:25.414Z"
// filename: "yt_dp.jpg"
// label: "door"
// resource: "https://storage.googleapis.com/auto-ai_resources_fo/project_6239ad9e530ae57d1e22a1e3/coPilot_62577e73fcbf1813875d6a8d/625a775dd6f746507a228f72?GoogleAccessId=storage%40faceopen-techolution.iam.gserviceaccount.com&Expires=1650106842&Signature=SWdsaTok6UI%2B71GUJUpIj7Rg3tp1GGUcu1ufRofovytqDdO877uf0iugcK0T8lij%2BQwzHg60K0uO5H0%2Bt%2FYZlBLWT0L1Rcn23DfwExgkrrQSpXQPIoqnmRZKpGqUX%2ByyEZqEb9zm7EAW2RITOzfUgzLnov1BlGIT7DlKXRM3q7gzmKRFjalaHxlirKQy%2BH7FOL9ePq49ePmKuzhrU9%2BZpbI65w04oYi1RE56gJTXxmg685%2F2NgZ1R%2FAMO8PzSR21IC7CdHm%2FxXpKRH%2BccGEqWVkpDrCKvzdvrLN2k0vjgd1efQLPhmamfN5zyxMbVTCdlYb%2FxcdRA4S0iJ6mpS%2B6xg%3D%3D"
// status: "active"
// statusLastModifiedAt: "2022-04-16T07:59:25.414Z"
// tag: "v1"
// updated_at: "2022-04-16T07:59:25.414Z"
// userTimeLeftInSecondsToTakeAction: 76
// _id: "625a775dd6f746507a228f72"
interface IProps extends TPropsFromRedux {
  open: boolean;
  setOpen: any;
  resource: any;
  updatedLabel: string;
}

const FilteredRejectedReasons = new Map();
FilteredRejectedReasons.set('text', textCopilotResourceRejectReasons);
FilteredRejectedReasons.set('imageAnnotation', imageAnnotationResourceRejectReasons);
FilteredRejectedReasons.set('textAnnotation', textAnnotationCopilotResourceRejectReasons);
FilteredRejectedReasons.set('DLP', textAnnotationCopilotResourceRejectReasons);

function OnRejectModal(props: IProps) {
  const dispatch = useDispatch();
  const history = useHistory();
  const classes = useStyles();
  const [rejectedReason, setRejectedReason] = useState("");
  const [isUpdateApiBeingCalled, setisUpdateApiBeingCalled] = useState(false);
  const { open, setOpen, resource } = props;
  const location=useLocation();
  const [DynamicRejectedReason, setDyanamicRejectedReasons] = useState<Array<RejectReasons>>([]);

  const urlQuery = useURLQuery();

  const copilotId: string = urlQuery.get("coPilot") || "";

  
  const RejectedLabelHandler = async () => {
    const apiResponse = await CopilotService.getCoPilotById({
      coPilotId: copilotId,
      coPilotSelectQuery: "type labelsForRejectedReasons"
    });

    let copilotType = apiResponse.data.type;
    let DynamicRejectedReasonTemp:any[] = apiResponse.data.labelsForRejectedReasons;
    let DynamicRejectedReasonTempDiscard = DynamicRejectedReasonTemp?.map((state) => {
      return {
        label: state.value,
        value: state.value
      }
    });

    console.log(DynamicRejectedReasonTempDiscard.length);
    if(DynamicRejectedReasonTempDiscard.length === 0) {
      DynamicRejectedReasonTempDiscard = FilteredRejectedReasons.get(copilotType);
    }
    setDyanamicRejectedReasons(DynamicRejectedReasonTempDiscard);
    console.log(apiResponse);
  }
  
  useEffect(() => {
    RejectedLabelHandler();
  }, [copilotId]);

  console.log("constant", DynamicRejectedReason);
  const handleClose = () => {
    setOpen(false);
  };
  const handleReject = async () => {
    const urlSearchParams = new URLSearchParams(window.location.search);

    const currentTab = new URLSearchParams(window.location.search).get('status');
    if (rejectedReason !== "") {
      const data: any = {
        id: [props.resource?._id],
        status: Status.REJECTED,
        label: props.updatedLabel,
        rejectedReason
      }

      let urlOffset = parseInt(new URLSearchParams(location.search).get('offset') || "1");
      let urlLimit = parseInt(new URLSearchParams(location.search).get('limit') || "1");
      urlSearchParams.set("limit", (urlLimit*urlOffset).toString())
      urlSearchParams.set("offset", "-1")
      data["getQueryParams"] = convertURLQueryParamsToJSON(urlSearchParams)

      if (currentTab === 'active') {
        const urlSearchParams = new URLSearchParams(window.location.search);
        urlSearchParams.set("offset", "1");
        urlSearchParams.set("limit", "1");
        data["getQueryParams"]["offset"] = "1";
        data["getQueryParams"]["limit"] = "1";
        history.push(window.location.pathname + "?" + urlSearchParams.toString());
      }
      try{
        dispatch(updateIsDataFetching(true));
        setisUpdateApiBeingCalled(true);
        await dispatch(updateCoPilotResource(data));

        if (currentTab === "active") {
          const urlSearchQueryParams = new URLSearchParams(window.location.search);
          urlSearchQueryParams.set("offset", "1");
          urlSearchQueryParams.set("limit", "1");
          history.push(`${window.location.pathname}?${urlSearchQueryParams.toString()}`)
          const queryParamsJSON = convertURLQueryParamsToJSON(urlSearchQueryParams.entries());
          await dispatch(getResources(queryParamsJSON))
        }
        if(currentTab === Status.EXPIRED){
          if(props.selectedResourceId === props.resource._id){
            const currentIndex = props.resourcelist.findIndex(resource => resource._id === props.resource?._id);
            /** If we are Rejecting the selected resource, we need to remove the resourceId from URL */
            if(currentIndex>=0 && currentIndex < props.resourcelist.length - 1 && props.resourcelist[currentIndex+1]){
              urlSearchParams.set("resourceId", props.resourcelist[currentIndex+1]._id)
            }
            else if(currentIndex>=0 && currentIndex === props.resourcelist.length - 1 && props.resourcelist[currentIndex - 1]){
              urlSearchParams.set("resourceId", props.resourcelist[currentIndex - 1]?._id)
            }
            else{
                urlSearchParams.delete("resourceId")
            }
            const newPath=location.pathname;
            history.push(`${newPath}?${urlSearchParams.toString()}`);
          }
        }
      }catch(e: any){
        console.error('handleReject ~ e', e)
        alert("err "+e?.message);
      }
      dispatch(updateIsDataFetching(false));
      setOpen(false);
      setisUpdateApiBeingCalled(false);
    }
  };
  const isWindowMax720PXWidthMatches = useMediaQuery(
    "@media (max-width:720px)"
  );
  const handleRadioChange=(e:any)=>{
    setRejectedReason(e.target.value);
  }
  return (
    <div>
      <Modal
        aria-labelledby="transition-modal-title"
        aria-describedby="transition-modal-description"
        className={classes.modal}
        open={open}
        onClose={handleClose}
        closeAfterTransition
        BackdropComponent={Backdrop}
        BackdropProps={{
          timeout: 500,
        }}
      >
        <Fade in={open}>
          <div className={classes.paper}>
            <div
              className={classes.clodeBtn}
              onClick={() => {
                setOpen(false);
              }}
            >
              {/* <CloseIcon /> */}
              <Cancel style={{fontSize:"40px",padding:"4px"}}/>
            </div>
            <div className={classes.row}>
              <div className={classes.col}>
                <FormControl component="fieldset">
                  <FormLabel component="legend">Select the reason</FormLabel>
                  <RadioGroup
                    aria-label="gender"
                    name="gender1"
                    value={rejectedReason}
                    onChange={handleRadioChange}
                  >
                    {
                      props.resource.coPilot.type === "video" ?
                        videoCopilotResourceRejectReasons?.map((reason, index) => {
                          return <FormControlLabel key={index} value={reason.value} control={<Radio />} label={reason.label} />
                        }): 
                      props.resource.coPilot.type === "imageAnnotation" ?
                        DynamicRejectedReason?.map((reason, index) => {
                          return <FormControlLabel key={index} value={reason.value} control={<Radio />} label={reason.label} />
                        }) 
                        :
                        (props.resource.coPilot.type === "textAnnotation" || props.resource.coPilot.type === "DLP")
                        ?
                          DynamicRejectedReason?.map((reason, index) => {
                            return <FormControlLabel key={index} value={reason.value} control={<Radio />} label={reason.label} />
                          })                       
                        :
                        (props.resource.coPilot.type === "text")
                        ?
                          DynamicRejectedReason?.map((reason, index) => {
                            return <FormControlLabel key={index} value={reason.value} control={<Radio />} label={reason.label} />
                          })
                          : 
                        (props.resource.coPilot.type === "audio")
                          ?
                            coPilotAudioResourcesRejectReasons?.map((reason, index) => {
                              return <FormControlLabel key={index} value={reason.value} control={<Radio />} label={reason.label} />
                            })                       
                        :
                          DynamicRejectedReason?.map((reason, index) => {
                            return <FormControlLabel key={index} value={reason.value} control={<Radio />} label={reason.label} />
                          }) 
                    }
                    {/* <FormControlLabel
                      value="disabled"
                      disabled
                      control={<Radio />}
                      label="(Disabled option)"
                    /> */}
                  </RadioGroup>
                </FormControl>
              </div>
              {
                resource.coPilot.type !== "bestAnswer" &&  resource.coPilot.type !== "evaluateAnswer" && resource.coPilot.type !== "evaluateAnswerScore" &&
                <div className={classes.col}>
                  {
                    resource.coPilot.type !== "audio"&& resource.coPilot.type !== "audioTextTranscription" && resource.coPilot.type !== "text" && resource.coPilot.type !== "textAnnotation" && resource.coPilot.type !== "DLP" && resource.coPilot.type !== "video" && resource.coPilot.type !== "bestAnswer" &&  resource.coPilot.type !== "evaluateAnswer" && resource.coPilot.type !== "evaluateAnswerScore" &&
                    // <ImageAspectRatioScaler
                    //   maxImageWidth={isWindowMax720PXWidthMatches ? 200 : 200}
                    //   maxImageHeight={isWindowMax720PXWidthMatches ? 200 : 200}
                    //   imagePlotterSuffix='OnRejectModal'
                    //   imageUrl={resource.resource}
                    //   canvasStyle={{
                    //     borderRadius: "4px"
                    //   }}
                    // />
                    <ImageAspectRatioScaler
                      imageUrl={props.resource?.resource}
                      image500Url={props.resource?.resource500WUrl ?? undefined}
                      imageMetaData={props.resource?.resourceMetaData ?? undefined}
                      image500UrlMetaData={props.resource?.resource500WMetaData ?? undefined}
                      maxImageWidthAllowed={200}
                      maxImageHeightAllowed={200}
                      style={{
                          borderRadius: `${imageBorderRadius}px`,
                          marginBottom: "5px"
                      }}
                    />
                  }
                  {
                    resource.coPilot.type === "video" &&
                    <video controls height="200" width="200" src={props.resource?.resource}></video>
                  }
                  {
                    (resource.coPilot.type === "audio" || resource.coPilot.type === "audioTextTranscription") &&
                    <audio controls src={props.resource?.resource}></audio>
                  }
                  {
                    resource.coPilot.type === "text" &&
                    <div
                      style={{
                        overflow: "auto",
                        width: "200px",
                        height: "182px",
                        whiteSpace: "break-spaces",
                        marginBottom: "35px",
                        border: "1px solid rgb(118, 118, 118)",
                        borderRadius: "8px",
                        padding: "10px"
                      }}
                    >
                      {resource.resource}
                    </div>
                  }
                  {
                    (resource.coPilot.type === "textAnnotation" || resource.coPilot.type === "DLP")&&
                    <div
                      style={{
                        overflow: "auto",
                        width: "240px",
                        height: "240px",
                        whiteSpace: "break-spaces",
                        marginBottom: "35px"
                      }}
                    >
                      {/* {resource.resource} */}
                      <TextTrimUI 
                              key={JSON.stringify(resource?.trimmedTexts)}
                              resource={resource} 
                              updateResourceTrimmedTextsInUI={()=>{}}
                              isInCopilot={true}                    
                              isViewMode={true}
                              scenario={"copilotSideDrawer"}
                              modelType={resource?.coPilot?.type}
                          />
                    </div>
                  }
                  {
                    resource.coPilot.type !== "text" && resource.coPilot.type !== "textAnnotation" && resource.coPilot.type !== "DLP" &&
                    <div className={classes.textRow} style={{paddingTop: "10px"}}>
                      <p className={clsx(classes.textElement, classes.textRowTitle)}>File Name:</p>
                      <p className={classes.textElement}><FileNameClipper fileName={resource.filename} currentPage="RejectPopup"/></p>
                    </div>
                  }
                  <div className={classes.textRow}>
                    <p className={clsx(classes.textElement, classes.textRowTitle)}>Tag:</p>
                    <p className={classes.textElement}>{resource.tag}</p>
                  </div>
                  {props.resource.coPilot.type !=="imageAnnotation" && resource.coPilot.type !== "textAnnotation" && resource.coPilot.type !== "DLP" &&
                    <div className={classes.textRow}>
                    <p className={clsx(classes.textElement, classes.textRowTitle)}>Confidence Score:</p>
                    <p className={classes.textElement}>{resource.confidenceScore}%</p>
                  </div>
                  }
                </div>
              }
            </div>
            <div className={classes.row}>
              <Button
                variant="contained"
                color="primary"
                className={`${classes.rejectButton} ${isUpdateApiBeingCalled ? classes.buttonDisabled : ""}`} 
                onClick={handleReject}
                disabled={rejectedReason !== "" ? false : true }
              >
                Reject
              </Button>
            </div>
          </div>
        </Fade>
      </Modal>
    </div>
  );
}

const mapProps = (state: IReduxState) => {
  return {
    selectedResourceId: state.coPilotDetailPage.coPilotResourceIdSelectedForSeeingDetails,
    resourcelist: state.coPilotDetailPage.coPilotResourcesState.coPilotResources
  };
};
const connector = connect(mapProps,{getResources,updateIsDataFetching});

export type TPropsFromRedux = ConnectedProps<typeof connector>;

export default connector(OnRejectModal);

import { Button, CircularProgress, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, FormControl, IconButton, InputLabel, MenuItem, Select, TextField, Tooltip, Typography } from '@material-ui/core'
import React, { useCallback, useEffect, useState } from 'react'
import CloseIcon from '@material-ui/icons/Close';
import styles from './ViewVideoDialog.module.css';
import { IData, IModel, useQuery, videoAnnotation } from '../../../../../../../../../../../common';
import { DataService, ModelService } from '../../../../../../../../../../../services';
import axios from 'axios';

type Scenario = "Save Screenshot" | "Save Trimmed Video"

const ViewVideoDialog = (props: {
    src:string,
    startTime?: Number,
    endTime?: Number,
    title: string,
    resource: IData,
    sendToModelTypes: string [],
    scenario: Scenario,
    isOpen: boolean,
    videoAnnotationSelectedByUser?: videoAnnotation | undefined,
    setIsOpen: (arg0: boolean) => void,
}) => {
    const [modelsList, setModelsList] = useState<IModel[] | []>([])
    const [selectedChildModel, setSelectedChildModel] = useState<string>(props.resource.model._id)
  

    const [name, setName] = useState<string>(props.resource.filename.substring(0, props.resource.filename.lastIndexOf('.')))
    const [label, setLabel] = useState<string>(props.resource.label);

    useEffect(() => {
      if (props.videoAnnotationSelectedByUser?.label) {
        setLabel(props.videoAnnotationSelectedByUser?.label);
      }
    }, [props.videoAnnotationSelectedByUser?.label])

    const [tag, setTag] = useState<string>(props.resource.tag)
    const [status, setStatus] = useState<string>(props.resource.status)
    const [confidenceScore, setConfidenceScore] = useState<number>(props.resource.confidenceScore || 90)
    const [prediction, setPrediction] = useState<string>(props.resource.prediction)
    const [csv, setcsv] = useState<string>(props.resource.csv)
    const [loading, setLoading] = useState<boolean>(false);
    const [isMovingTrimmedVideoToModel, setIsMovingTrimmedVideoToModel] = useState<boolean>(false)
    const query = useQuery();
    const imageUrl=props.src;
    const handleClose = () => {
        props.setIsOpen(false)
    }
    const [model,setModel]=useState<string>("");
    const [statusValue,setStatusValue]=useState([  { id: 0, label: 'backlog' },
    { id: 1, label: 'approval' },
    { id: 2, label: 'rejected' },
    { id: 3, label: 'deleted' },
    { id: 4, label: 'approved' },]);
    const onSubmit = async (): Promise<void> => {
       
        setLoading(true);
        try{
        const bodyFormData = new FormData();
        bodyFormData.append("model", model || "");
        bodyFormData.append("status", status);
        if (imageUrl.length > 0) {
            const response = await fetch(imageUrl);
        const blob = await response.blob();
        const file = new File([blob], `${name}.png`, {type: blob.type});
            bodyFormData.append("resource", file);
            
        }
       
        bodyFormData.append("confidence_score", confidenceScore.toString());
        bodyFormData.append("prediction", prediction);
        bodyFormData.append("label", label);
        bodyFormData.append("tag", tag);
        bodyFormData.append("csv", csv);
    
      

    
        // await addResource(bodyFormData);
        await DataService.addResourceInModel(bodyFormData);
        setLoading(false);
        // window.location.reload();
        handleClose();
    }catch(error)
    {
       console.error("Submission failed:", error);
       setLoading(false);
    }
        
      };
    const modelFetchApi = useCallback(
        async () => {
            const project = query.get('project') || '';
            const modelTypes = props.sendToModelTypes;
            // const modelId = query.get('model') || '';

            if (project && modelTypes) {

                for(let modelType of modelTypes) {
                    const apiResponse = await ModelService.getProjectModels({
                        project: project,
                        type: modelType,
                        offset: '1',
                        limit: '10000'
                    })
                    if (apiResponse) {
                        const availableChildModels = apiResponse.data.models
                        setModelsList(prevState => [...prevState, ...availableChildModels])
                        // setSelectedChildModel(apiResponse.data.models.find(model => model._id === modelId)?._id || props.resource.model._id)
                        // setParentModel(apiResponse.data.models.find(model => model._id === modelId) || null)
                        setSelectedChildModel(props.resource.model._id)
                    }
                }
            }
        },
        [props.resource],
    )

    useEffect(() => {
        modelFetchApi()
    }, [])

    const handleMoveToModel = async () => {
        try{
            setIsMovingTrimmedVideoToModel(true)
            const reqPayLoad = {
                model: model || "",
                signedUrl: props.resource.resource,
                startTime: props.startTime,
                endTime: props.endTime,
                status: status,
                confidence_score: confidenceScore.toString(),
                prediction: prediction,
                label: label,
                tag: tag,
                csv: csv,
                name: name
            }
            const apiResponse = await DataService.moveTrimmedVideoToModel(reqPayLoad)
            setIsMovingTrimmedVideoToModel(false)
            handleClose()
        }catch(error) {
            console.log(error)
            setIsMovingTrimmedVideoToModel(false)
        }
    }

    return (
        <Dialog
            fullWidth
            
            maxWidth={'lg'}
            open={props.isOpen}
            onClose={handleClose}
            aria-labelledby="form-dialog-title">
            <DialogTitle id="form-dialog-title">
                <div style={{ display: 'flex', justifyContent: 'space-between' }}>
                    <Typography variant='h6'>{props.title}</Typography>
                    <IconButton style={{ padding: '0px' }} onClick={handleClose}>
                        <CloseIcon style={{ color: 'black' }} />
                    </IconButton>
                </div>
            </DialogTitle>
            <DialogContent>
                <div className={styles.contentContainer}>
                    <div className={styles.imageAndTools}>
                        <div className={styles.imageContainer}>
                            {
                                props.scenario === "Save Trimmed Video" &&
                                <video
                                    // controls
                                    id="popupVideo"
                                    style={{ width: "550px", minHeight: '380px', height: "380px", borderRadius: '10px', background: '#000', marginTop: '10px' }}
                                    src={props.resource.resource}
                                //   onEnded={videoEnded}
                                //   preload="auto"
                                //   ref={videoHTMLElement}
                                //   onCanPlay={(event) => {
                                //     /**
                                //      * rerendering so that video duration and current time gets updated in variables
                                //      */
                                //     setCannotPlayVideo(false);
                                //     forceRerender();
                                //   }}
                                //   onPlay={() => { forceRerender() }}
                                //   onPause={() => { forceRerender() }}
                                //   onTimeUpdate={(event) => { forceRerender() }}
                                />}
                                 {
                            props.scenario==="Save Screenshot" && <img src={props.src} style={{width:"100%"}} alt=""/>
                            }
                        </div>
                    </div>

                    <div className={styles.labelAndActions}>
                        <div className={styles.labels}>
                            <div className={styles.labeledInput}>
                                <Typography variant='body2' className={styles.label}>Name *</Typography>
                                <TextField value={name} variant="outlined" size="small" fullWidth className={styles.textBox} onChange={(e) => setName(e.target.value)} />
                            </div>
                            <div className={styles.labeledInput}>
                                <Typography variant='body2' className={styles.label}>Label *</Typography>
                                <TextField value={label} variant="outlined" size="small" fullWidth className={styles.textBox} onChange={(e) => setLabel(e.target.value)} />
                            </div>
                            <div className={styles.labeledInput}>
                                <Typography variant='body2' className={styles.label}>Tag *</Typography>
                                <TextField value={tag} variant="outlined" size="small" fullWidth className={styles.textBox} onChange={(e) => setTag(e.target.value)} />
                            </div>
                            <div className={styles.labeledInput}>
                                <Typography variant='body2' className={styles.label}>Status *</Typography>
                                <FormControl variant="outlined" className={styles.textBox} size="small">
                                {/* <InputLabel id="model-selection-helper-label">Status *</InputLabel> */}
                                <Select
                                    labelId="Status"
                                    id="Status"
                                    autoWidth
                                    required
                                    defaultValue={status}
                                    value={status}
                                    onChange={(e:any) => {
                                        setStatus(e.target.value)
                                    }}
                                >
                                    {
                                        statusValue.map(status => {
                                            return <MenuItem key={status.id} value={status.label}>{status.label}</MenuItem>
                                        })
                                    }
                                </Select>
                             
                            </FormControl>
                                {/* <TextField value={status} variant="outlined" size="small" fullWidth className={styles.textBox} onChange={(e) => setStatus(e.target.value)} /> */}
                            </div>
                            <div className={styles.labeledInput}>
                                <Typography variant='body2' className={styles.label}>Confidence Score *</Typography>
                                <TextField value={confidenceScore} type='number' variant="outlined" size="small" fullWidth className={styles.textBox} onChange={(e) => setConfidenceScore(Number(e.target.value))} />
                            </div>
                            <div className={styles.labeledInput}>
                                <Typography variant='body2' className={styles.label}>Prediction *</Typography>
                                <TextField value={prediction} variant="outlined" size="small" fullWidth className={styles.textBox} onChange={(e) => setPrediction(e.target.value)} />
                            </div>
                            <div className={styles.labeledInput}>
                                <Typography variant='body2' className={styles.label}>CSV *</Typography>
                                <TextField value={csv} variant="outlined" size="small" fullWidth className={styles.textBox} onChange={(e) => setcsv(e.target.value)} />
                            </div>
                            {/* {labels.map(label=><div className={styles.labeledInput}>
                        <Typography variant='body2' className={styles.label}>{label}</Typography>
                        <TextField variant="outlined" size="small" fullWidth className={styles.textBox}/>
                    </div>)} */}
                        </div>
                        <div className={styles.actions}>
                            <FormControl style={{ width: '100%' }}>
                                <InputLabel id="model-selection-helper-label">Model to save *</InputLabel>
                                <Select
                                    labelId="model-selection-helper-label"
                                    id="model-selection-helper"
                                    autoWidth
                                    required
                                    // defaultValue={selectedChildModel}
                                    value={model}
                                    onChange={(e) => {
                                        setModel(e.target.value + '')
                                    }}
                                >
                                    {
                                        modelsList.map(model => {
                                            return <MenuItem key={model._id} value={model._id}>{model.name}</MenuItem>
                                        })
                                    }
                                </Select>
                                {/* <Select
                        native
                        fullWidth
                        label="Model"
                            inputProps={{
                                name: 'model',
                                id: 'outlined-model-native-simple',
                            }}
                            >
                            <option aria-label="None" value="" />
                            <option value={10}>Ten</option>
                            <option value={20}>Twenty</option>
                            <option value={30}>Thirty</option>
                        </Select> */}

                                {/* <FormHelperText>Select model to copy dataset collection in</FormHelperText> */}
                            </FormControl>
                            <div className={styles.actionBtnContainer}>
                                <Tooltip title='Cancel changes'>
                                    <div style={{ width: '50%' }}>
                                        <Button variant='outlined' color="default" fullWidth style={{ marginRight: 10 }} className={styles.cancelBtn} onClick={handleClose}>Cancel</Button>
                                    </div>
                                </Tooltip>
                           

                                <div style={{ width: '50%' }}>
                                    {
                                        props.scenario === "Save Trimmed Video" &&
                                        <Button variant='contained' color="primary" fullWidth
                                            disabled={
                                                name?.length === 0 ||
                                                label?.length === 0 ||
                                                tag?.length === 0 ||
                                                !confidenceScore ||
                                                status?.length === 0 ||
                                                prediction?.length === 0 ||
                                                csv?.length === 0 ||
                                                selectedChildModel?.length === 0
                                            }

                                            onClick={handleMoveToModel}
                                        >
                                        {
                                            isMovingTrimmedVideoToModel&&
                                            <CircularProgress style={{color: "white", height: 16, width: 16, marginRight: 20}} />
                                        }
                                        {
                                            isMovingTrimmedVideoToModel?
                                            "Saving"
                                            :"Save Trimmed Video"
                                        }
                                        </Button>}
                                        {
                                        props.scenario === "Save Screenshot" &&
                                       <Button variant='contained' color="primary" fullWidth 
                                            disabled={
                                                name?.length === 0 ||
                                                label?.length === 0 ||
                                                tag?.length === 0 ||
                                                !confidenceScore ||
                                                status?.length === 0 ||
                                                prediction?.length === 0 ||
                                                csv?.length === 0 ||
                                                model?.length === 0 ||
                                                !imageUrl
                                            }
                                            style={{marginLeft:"10px"}}
                                            onClick={onSubmit}
                                        >Upload Screenshot</Button> 
                                       }
                                </div>
                                {loading && (
        <div style={{ position: 'absolute', top: '50%', left: '50%', transform: 'translate(-50%, -50%)' }}>
          <CircularProgress />
        </div>
      )}
                            </div>
                        </div>
                    </div>
                </div>
            </DialogContent>
        </Dialog>
    )
}

export default ViewVideoDialog
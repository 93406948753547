import { Card, CardContent, Typography, makeStyles, CardActionArea, CardMedia } from '@material-ui/core';

import { IProject, LogoPlaceholderImg, convertDate } from '../../../../../common';
import {Tooltip} from '@material-ui/core';
import { Link } from 'react-router-dom';

const useStyles = makeStyles((theme) => ({
    root: {
        maxWidth: 345,
    },
    media: {
        height: 0,
        paddingTop: '56.25%', // 16:9
    },
}));

interface IProps {
    project: IProject;
    onClick: (data: IProject) => void;
    getProjectLink : (data: IProject) => string; 
}

function ProjectCard(props: IProps) {
    const { project, onClick ,getProjectLink} = props;
    const classes = useStyles();
    const handleCardClick = (event: React.MouseEvent) => {
        if (event.ctrlKey || event.metaKey || event.button === 1) {
            return;
        }
        onClick(project);
    };
    return (
        <Card className={classes.root}>
            <Link to={()=>getProjectLink(project)} style={{ textDecoration: 'none',color:'inherit' }}>
            <CardActionArea onClick={handleCardClick}>
                <CardMedia
                    className={classes.media}
                    image={LogoPlaceholderImg}
                    title="Project Logo"
                />
                <CardContent>
                    <Tooltip title={project?.name}>
                    <Typography variant="h6" component="h6" style={{overflow:"hidden", textOverflow: "ellipsis", whiteSpace: "nowrap"}}>
                        {project?.name}
                    </Typography>
                    </Tooltip>
                    <Typography variant="body2" component="p" color="textSecondary">
                        {convertDate(project?.created_at)}
                    </Typography>
                </CardContent>
            </CardActionArea>
            {/* <CardActions disableSpacing>
                <IconButton aria-label="add to favorites">
                    <FavoriteIcon />
                </IconButton>
            </CardActions> */}
            </Link>
        </Card>
    );
}

export default ProjectCard;

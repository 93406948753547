import { IconButton, Tooltip } from "@material-ui/core";
import { OpenInNew } from "@material-ui/icons";
import { MouseEventHandler } from "react";

type Props = {
  tooltip?: string;
  onClick?: MouseEventHandler<SVGSVGElement> | undefined;
  iconButtonComponentProps?: any;
  disabled?: boolean; 
};
export default function DataRowExpandIconButton(props: Props) {
  return (
    <Tooltip title={props.tooltip || ""}>
      <IconButton
        color="primary"
        style={{ cursor: "pointer", opacity: props.disabled ? 0.2 : 1 }}
        {...props.iconButtonComponentProps}
        onClick={props.onClick}
      >
        <OpenInNew />
      </IconButton>
    </Tooltip>
  );
}

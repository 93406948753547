import { Box, IconButton, Typography } from '@material-ui/core'
import {ReactComponent as RLEFLogo} from '../../../../../assets/svg/rlef-logo.svg'
import ReplayIcon from '@mui/icons-material/Replay';
import homepageCss from '../RLEF_homepage.module.css'
import { useStyles } from '../RLEF_homepage';
import RlefSearchComponent from './RlefSearchComponent';
const StickySearchContainer=(props:any)=>{
    const classes = useStyles();
    return <>
        <div style={{ display: 'flex',flexDirection:'column',gap:'11px' }}>
            <RLEFLogo style={{width:'130px',height:"20px"}}/>
            <Typography variant='h4' style={{ width: '150px', color: '#64748B', fontWeight: 500, letterSpacing: '-0.42px', fontSize: '14px',fontFamily:'Inter',textAlign:'left'}}>AI Marketplace</Typography>
        </div>
        <div className={homepageCss.stickySearchContainer}>
            <RlefSearchComponent {...props}/>
        </div>
        {/* <Box className={classes.chatRestartButton} >
            <IconButton style={{ padding: '6px 16px', borderRadius: '40%' }} onClick={props.handleClearHistory} disabled={props.isStreaming} >
                <ReplayIcon style={{ width: '1rem', height: '1rem' }} />
                <Typography variant='body2'>Restart chat</Typography>
            </IconButton>
        </Box> */}
        <div style={{width:'150px'}}>&nbsp;</div>
    </>
}

export default StickySearchContainer

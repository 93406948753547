export const isTypeJson = (variable: any): boolean => {
  try {
    if (Object.keys(variable).length > 0) {
      return true;
    }
  } catch (error) {}
  return false;
};

export const convertJsonToArrayOfKeyValuePair = (jsonToConvert: Object): {key: string, keyValue: any[]}[] => {
  const arrayToSend: {key: string, keyValue: any[]}[] = [];
  for (const key in (jsonToConvert || {})) {
    if (Object.prototype.hasOwnProperty.call(jsonToConvert, key)) {
      // @ts-ignore
      const keyValue = jsonToConvert?.[key];
      arrayToSend.push({
        key: key,
        keyValue: keyValue
      })
    }
  }
  return arrayToSend;
}
import { Button, CircularProgress, Dialog } from "@mui/material";
import { useContext, useState } from "react";
import { AlertContext } from "../../../../../common/contexts/alertContext/alertContext";
import testDriveService from "../../projects/features/aiMarketPlace/services/testDriveService";
import savedDataSetService from "../../projects/features/aiMarketPlace/services/savedDataSetService";
import ChatContext from "../../chat/contexts/chat.context";
import useAuth from "../../../../../hooks/useAuth";
import { themePrimaryMainColor } from "../../../../../theme";

interface IProps {
    open: boolean;
    onClose: () => void;
    modelCollectionId: string;
    checkSavedDatasetExistence: number;
    closeCheckingSavedDatasetExistence: React.Dispatch<React.SetStateAction<number>>;
    trainingPodId: string;
}
const TestDriveConfirmationDialog = (props: IProps) => {
    const alertContext = useContext(AlertContext);
    const chatContext = useContext(ChatContext);
    const [checkForDuplicateSavedDataset, setCheckForDuplicateSavedDataset] = useState(false)
    const { userEmail } = useAuth();

    const [testDriveType, setTestDriveType] = useState<string>("");
    const [dataSetName, setDataSetName] = useState<string>("");
    const [savedDataSetError, setSavedDataSetError] = useState<string>("")
    // const [selectedResourcesForTestDriveUrls, setSelectedResourcesForTestDriveUrls] = useState<string[]>([]);

    // const fetchUrlsOfSelectedResourcesForTestDrive = async () => {
    //     try {
    //         // const apiRes = await DataService.getData({
    //         //     ids: chatContext.selectedRlefResourcesForTestDrive
    //         // })
    //     }
    //     catch (e) {
    //         console.error(e);
    //     }
    // }

    const onClickTestDrive = async (type: 'save' | 'nosave') => {
        const testDriveButtonType = type
        try {
            setTestDriveType(testDriveButtonType);

            if (!chatContext.testDriveVm) {
                throw new Error("No VM is available!");
            }
            else if (testDriveButtonType === 'save') {
                if (!dataSetName.trim()) {
                    setSavedDataSetError("Dataset name cannot be empty");
                    return
                }
                await savedDataSetService.createDataSet({
                    name: dataSetName,
                    rlefResourceIds: chatContext.selectedRlefResourcesForTestDrive?.map(r => r._id),
                    userEmail
                })
            }
            const apiResponse = await testDriveService.startTestDrive({
                tryOutVmId: chatContext.testDriveVm?._id || '',
                resourceIds: chatContext.selectedRlefResourcesForTestDrive?.map(r => r._id),
                modelCollectionId: props.modelCollectionId,
                requestorEmailId: userEmail
            });

            if (apiResponse.data) {
                chatContext.setTestDriveProcessRequest(apiResponse.data)
            }

            chatContext.updateTestDriveStage(1, 20);

            props.onClose();

            if (props.checkSavedDatasetExistence) {
                props.closeCheckingSavedDatasetExistence(0)
                setCheckForDuplicateSavedDataset(false)
            }

            // try {
            //     // send 10 random images from selected items for compression
            //     const apiRes = await temporaryFilesService.compressImagesAndStoreAsTempFiles({
            //         originalImages: randomImagesFromDataSelectedForTestDrive,
            //         imageSizeThresholdInMb: 1.5,
            //         temporaryFilesTtlInDays: 7
            //     });

            //     const compressedImages = apiRes.data?.compressedImages || [];

            //     // start generating description of dataset in the background
            //     const descriptionOfTestDriveResourcesApiRes = await generateDescriptionOfTestDriveImages({
            //         userEmail: userEmail || '',
            //         query: {
            //             image_urls: compressedImages.map(obj => obj.url);
            //         }
            //     });
            //     if (descriptionOfTestDriveResourcesApiRes) {
            //         chatContext.setMetadataOfCurrentTestDroveTrainingPod(prev => {
            //             return {
            //                 ...prev,
            //                 trainingPodId: props.trainingPodId,
            //                 descriptionOfDataSelectedForTestDrive: descriptionOfTestDriveResourcesApiRes.description
            //             }
            //         })
            //     }
            // }
            // catch (e) {
            //     console.error("Couldn't generate description of test drive images", e)
            // }
        }
        catch (err: any) {
            if (testDriveButtonType === 'save' && err.response?.status === 403) setSavedDataSetError("A dataset already exists with same label")
            else {
                console.error("Couldn't initialize test drive", err);
                //@ts-ignore
                alertContext.setAlert("Couldn't initialize test drive: " + err.message)
            }
        }
        finally {
            setTestDriveType("");
            if (props.checkSavedDatasetExistence) {
                props.closeCheckingSavedDatasetExistence(0)
                setCheckForDuplicateSavedDataset(false)
            }
        }
    }
    if (props.checkSavedDatasetExistence === 2 && !checkForDuplicateSavedDataset) {
        setCheckForDuplicateSavedDataset(true)
        onClickTestDrive("nosave")
    }

    const changeInput = (e: any) => {
        setDataSetName(e.target.value)
        setSavedDataSetError("")
    }

    return (
        <Dialog open={props.open} onClose={props.onClose} container={() => document.querySelector(`#openRlefLibraryDialogRelativeToThisContainer`)} BackdropProps={{
            style: { backgroundColor: 'rgba(0, 0, 0, 0.3)' }
        }} PaperProps={{ style: { boxShadow: 'none' } }}>
            <div style={{ padding: "17.5px", fontFamily: "Inter", display: "flex", flexDirection: "column", gap: "24.5px", borderRadius: "6px" }}>
                <h2 style={{ fontSize: "21px", fontWeight: 700, color: "#4B5563", margin: "0" }}>Save selected data for future use?</h2>
                <div style={{ fontSize: "14px", fontWeight: 400, color: "#4B5563" }}>
                    <p style={{ marginBottom: "4px", marginTop: "0" }}>
                        Saving your data in <span style={{ fontSize: "15px", color: "#4B5563", fontWeight: 600 }}>Saved Dataset</span> lets you:
                    </p>
                    <ul style={{ margin: "0" }}>
                        <li style={{ marginBottom: "4px" }}>
                            Easily <span style={{ fontSize: "14px", fontWeight: 600, color: "#4B5563" }}>Test Drive</span> other AI models without selecting the same data again.
                        </li>
                        <li style={{ marginBottom: "4px" }}>Keep it handy for future use.</li>
                    </ul>
                </div>
                <div style={{ display: "flex", flexDirection: "column", gap: "8px" }}>
                    <label style={{ color: "#707070", fontSize: "14px", fontWeight: 400 }}>Label these selected data</label>
                    <input type="text" disabled={testDriveType !== ""} onChange={changeInput} value={dataSetName} style={{ width: "100%", padding: "4px 16px", fontSize: "16px", fontWeight: 500, border: "1px solid #008D9C", borderRadius: "6px" }} />
                    {savedDataSetError ? <span style={{ fontSize: '0.8rem', color: 'red' }}>{savedDataSetError}</span> : null}
                </div>
                <div style={{ display: "flex", gap: "7px", justifyContent: "flex-end", marginTop: "5px" }}>
                    <Button
                        style={{ fontWeight: 500, textTransform: 'none', height: '2.5rem', color: '#DF0002', border: '1px solid #DF0002', fontFamily: 'Inter', padding: '6px 18px' }}
                        onClick={() => onClickTestDrive("nosave")}
                        data-type="nosave"
                        disabled={testDriveType !== ""}
                        size="small"
                        variant="outlined"
                    >
                        {
                            testDriveType === "nosave"
                            &&
                            <CircularProgress color={testDriveType === "nosave" ? 'inherit' : 'error'} size={18} style={{ marginRight: 6 }} />
                        }
                        Test drive without saving
                    </Button>
                    <Button
                        data-type="save"
                        disabled={testDriveType !== ""}
                        style={{ fontWeight: 700, height: '2.5rem', color: "#FFFFFF", textTransform: 'none', fontSize: '14px', borderRadius: '6px', padding: '10.5px 17.5px', backgroundColor: themePrimaryMainColor }}
                        onClick={() => onClickTestDrive("save")}
                        size="small"
                        variant="contained"
                    >
                        {
                            testDriveType === "save"
                            &&
                            <CircularProgress color={testDriveType === "save" ? 'inherit' : 'error'} size={18} style={{ marginRight: 6 }} />
                        }
                        Save & test drive
                    </Button>
                </div>
            </div>
        </Dialog>
    );
};

export default TestDriveConfirmationDialog;

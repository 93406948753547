import { Tooltip } from '@material-ui/core'
import React from 'react'
import { connect, ConnectedProps } from 'react-redux';
import { IReduxState } from '../../../../../../../../../../../common';
import { setSideDrawer } from '../../../../../../../../../../../store/actions';
// import reTrainIcon from "../../../../../../../../../../../assets/images/7901138.png";
// import AddDataSetCollection from './AddDataSetCollection';
import MergeDataSetCollection from './MergeDataSetCollection';
// import AddModelCollection from './AddModelCollection';
import MergeTypeIcon from '@material-ui/icons/MergeType';


const MergeDataSetsButton = (props:TPropsFromRedux) => {
    const {setSideDrawer}=props
    const handleMergeClicked =()=>{
        console.log("clicked merge")
        setSideDrawer({type: 'addCollection', component:()=><MergeDataSetCollection/>, isOpen: true});
    }
  return (
    // <Button variant= 'contained' color="primary" onClick={handleMergeClicked}>Merge</Button>
    <Tooltip title="Merge Selected Collections">
    <MergeTypeIcon color="primary" onClick={handleMergeClicked} style={{transform: "rotate(180deg)", fontSize: "30px", cursor: "pointer"}}></MergeTypeIcon>
    </Tooltip>
  )
}

const mapProps = (state: IReduxState) => {
    return {
        selectedDataSets:state.collection.dataSetCollectionsSelected
    };
}
const connector = connect(mapProps, {setSideDrawer});
export type TPropsFromRedux = ConnectedProps<typeof connector>
export default connector(MergeDataSetsButton);

export const convertMilliSecondsToSeconds = (milliSecondsToConvert: number): number => {
  return milliSecondsToConvert/1000;
}

export const waitForSeconds = (seconds: number) => {
  return new Promise<void>(resolve=>{
    setTimeout(() => {
      resolve()
    }, seconds*1000);
  })
}
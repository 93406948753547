import { ReactComponent as DoneIcon} from '../../assets/Group.svg'
import crossImage from '../../assets/x-mark-512.png'
import loadingIcon from '../../assets/loadingIcon.gif'
import './SubState.css'

type props = {
    state : string
}

function Substates({state} : props) {
    return (
        state === 'done' ? <DoneIcon />
        : <div className={`bg ${state}`}>
            {state === 'process' && <img src={loadingIcon} />}
            {state === 'failed' && <img src={crossImage} className='failed' />}
        </div>
    )
}

export default Substates
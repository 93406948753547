import { Box, Input, InputAdornment, Typography } from "@material-ui/core"
import homepageCss from '../RLEF_homepage.module.css'
import { useStyles } from '../RLEF_homepage';
import {ReactComponent as StarSvg} from '../../../../../assets/svg/star.svg'
import { IMessage } from "../../projects/features/aiMarketPlace/aiMarketPlaceInterfaces/interfaces";
import ChatMessage from "../chatbot/chatMessage.component";
import SendIcon from '@mui/icons-material/Send';
import { forwardRef, useEffect, useRef, useState } from "react"


const RlefSearchComponent = forwardRef(({isActive,homepageSearchChatMessages,isStreaming, setIsStreaming, 
    setStatesOnClickingExplainability,
    streamingMessageIndex,isWaitingForAiResponse,handleShowMoreAIDetailsWindow,handleSendMessage,handleInputChange,searchQuery,isSearchChatContainerHide,splitIntoTwoColumns,handleClearHistory,handleFocusOnSearch, isPreviewWindowOpen, /*setChatMessages, pushPageInModelRecommendationStage*/ }:any,ref) => {
    const messageEndRef = useRef<HTMLDivElement | null>(null);
    const containerRef = useRef<HTMLDivElement>(null); // Ref for the scrollable container
    const lastMessageRef = useRef<HTMLDivElement>(null);
    const classes=useStyles()
    let searchChatContainerStyle=(isActive && !splitIntoTwoColumns && !isSearchChatContainerHide) ? homepageCss.searchBarchatContainerShowTesting : homepageCss.searchBarchatContainerTesting
    if(isActive && splitIntoTwoColumns){
        searchChatContainerStyle=`${homepageCss.searchBarchatContainerShowTesting} ${homepageCss.styleOfChatContainerWhenInSplitIntoTwoColumns}`
    }

    const [selectedExplainabilityHeader, setSelectedExplainabilityHeader] = useState<{
        index: number,
        messageUuid: string
    } | null>(null);

    const scrollToBottom = () => {
        // if (lastMessageRef.current && containerRef.current) {
        //     // messageEndRef.current?.scrollIntoView({ behavior: 'smooth',block: "nearest"  });
        //     lastMessageRef.current?.scrollIntoView({ behavior: 'smooth', block: "nearest" });
        // }

        if (containerRef.current) {
            containerRef.current.scrollTop = containerRef.current.scrollHeight;
        }
    };
    const scrollToTop = () => {
        window.scrollTo({
            top: 0,
            behavior: 'smooth', // Enables smooth scrolling
        });
    };
    
    useEffect(() => {
    // scrollToTop()
    // scrollToBottom();
    }, [homepageSearchChatMessages]);
    return <>
        {/* {(isActive && !splitIntoTwoColumns && !isSearchChatContainerHide) && <hr style={{ width: '80%', margin: 'auto', position: 'absolute', left: "10%", top: '2px', backgroundColor: "rgba(0, 0, 0, 0.08)" }} />} */}
        {<div className={`${searchChatContainerStyle} ${homepageCss.customScroll}`} ref={containerRef}>
            {homepageSearchChatMessages.length == 0 ? <Typography variant="h1" className={homepageCss.chatInterfaceMainHeading}></Typography> :
                <Box className={`${classes.messagesContainer}`}>
                    {
                        homepageSearchChatMessages.map((message: IMessage, index: number) => (
                            <ChatMessage
                                isStreaming={isStreaming}
                                setIsStreaming={setIsStreaming}
                                message={message}
                                isWaitingForAiResponse={isWaitingForAiResponse.current && index === streamingMessageIndex}
                                onClickOpenInSidePanel={handleShowMoreAIDetailsWindow}
                                ref={lastMessageRef}
                                key={`chatMessage_${index}`}
                                isLastMessage={homepageSearchChatMessages.length === index + 1}
                                isWorkModeActive={splitIntoTwoColumns}
                                isPreviewWindowOpen={isPreviewWindowOpen}
                                setStatesOnClickingExplainability={setStatesOnClickingExplainability}
                                selectedExplainabilityHeader={selectedExplainabilityHeader}
                                setSelectedExplainabilityHeader={setSelectedExplainabilityHeader}
                                // setChatMessages={setChatMessages}
                                // pushPageInModelRecommendationStage={pushPageInModelRecommendationStage}
                            />
                        ))
                    }
                    {/* <div ref={lastMessageRef} /> */}
                </Box>
            }
            {/* {homepageSearchChatMessages.length > 0 &&
                                <Box className={classes.chatRestartButton}>
                                    <IconButton style={{ padding: '6px 16px', borderRadius: '40%' }} onClick={handleClearHistory} disabled={isStreaming}>
                                        <ReplayIcon style={{ width: '1rem', height: '1rem' }} />
                                        <Typography variant='body2'>Restart chat</Typography>
                                    </IconButton>
                                </Box>
                            } */}
        </div>
        }
        <Input
            className={homepageCss.searchBar}
            placeholder="Ask me anything to find the best AI model for you"
            startAdornment={
                <InputAdornment position="start">
                    <StarSvg />
                </InputAdornment>
            }
            endAdornment={
                <InputAdornment position="start">
                    {/* {isStreaming ? <StopCircleIcon style={{cursor:'pointer',color:'#1C1B1F'}} onClick={handleStopStream}/>: */}
                    <SendIcon style={{
                        //  color: searchQuery.trim() !== '' ? '#008D9C' : '#008D9C66', cursor: 'pointer' 
                                color: isStreaming
                                ? '#838C9566' // Disabled color when streaming
                                : searchQuery.trim() !== ''
                                ? '#008D9C' // Active color when text is present
                                : '#008D9C66', // Disabled color when no text
                            cursor: isStreaming ? 'not-allowed' : 'pointer',
                         }} onClick={!isStreaming && searchQuery.trim() !== '' ? handleSendMessage : undefined} />
                    {/* } */}
                </InputAdornment>
            }
            disableUnderline
            onKeyDown={(e) => !isStreaming && e.key === 'Enter' && handleSendMessage()}
            inputProps={{ 'aria-label': 'search' }}
            onChange={handleInputChange}
            value={searchQuery}
            onFocus={handleFocusOnSearch}
            // onBlur={handleBlur}
            style={{ fontFamily: 'Inter', color: '#838C95', fontSize: '16px' }}
        />
        
    </>
})

export default RlefSearchComponent
import { Box } from "@material-ui/core"
import ModelAnalyticsResources from "../../projects/features/models/features/model/features/collections/components/ModelAnalyticsResources"
import { useContext, useEffect, useState } from "react";
import ChatContext from "../../chat/contexts/chat.context";
import { IGetModelCollectionResponse } from "../../projects/features/aiMarketPlace/aiMarketPlaceInterfaces/interfaces";
import { CONFIDENCE_SCORE_THRESHOLD_DEFAULT_VALUE, IOU_THRESHOLD_DEFAULT_VALUE_FOR_SEGMENTATION_ANALYTICS } from "../../projects/features/models/features/model/features/collections/components/integrity";
import { isNullOrUndefined } from "../../../../../services/variableHelperService";
interface ITestDriveResultsProps{
    modelSummaryPageData?:IGetModelCollectionResponse | {},
}
export const TestDriveResultsComponnet = (props :ITestDriveResultsProps) => {
    const {modelSummaryPageData} = props
    const chatContext = useContext(ChatContext);
    // the below state will be usefull when we click testdrive button from the chatMssgs based on metaData we can update
    const [ modelDetails, setModelDetails ] = useState<IGetModelCollectionResponse | {}>(modelSummaryPageData || {})

    console.log({modelDetails,modelSummaryPageData})
    useEffect(() => {
        if (modelSummaryPageData && typeof modelSummaryPageData === "object") {
            setModelDetails(modelSummaryPageData);
        } else {
            setModelDetails({});
        }
    }, [modelSummaryPageData])
    const isSegmentationModelAnalyticsEnabled = (modelDetails as IGetModelCollectionResponse)?.model?.isSegmentationModelAnalytics;
    let IouThreshouldValue= IOU_THRESHOLD_DEFAULT_VALUE_FOR_SEGMENTATION_ANALYTICS
    if (isSegmentationModelAnalyticsEnabled) {
        //@ts-ignore
        IouThreshouldValue = !isNullOrUndefined((modelDetails as IGetModelCollectionResponse)?.model?.iouThresholdValue)
                ? (modelDetails as IGetModelCollectionResponse)?.model?.iouThresholdValue
                : IOU_THRESHOLD_DEFAULT_VALUE_FOR_SEGMENTATION_ANALYTICS
    }
    let confidenceScoreThreshold= !isNullOrUndefined((modelDetails as IGetModelCollectionResponse)?.model?.confidenceScoreThresholdForIntegrity) ? (modelDetails as IGetModelCollectionResponse)?.model?.confidenceScoreThresholdForIntegrity : CONFIDENCE_SCORE_THRESHOLD_DEFAULT_VALUE

    const { testDriveVm } = chatContext;
    return (
        <Box display="flex" flexDirection="column" flexGrow="1">
            <ModelAnalyticsResources
                scenario='TestDriveChatBotWindow'
                testDriveVm={testDriveVm}
                queryParamsForNonDefaultScenarios={{
                    model: (modelDetails as IGetModelCollectionResponse)?.model?._id || '',
                    project: (modelDetails as IGetModelCollectionResponse)?.project?._id || '',
                    limit: 10,
                    offset: 1,
                    type: (modelDetails as IGetModelCollectionResponse)?.model?.type || '',
                    status: 'dashboard',
                    modelCollectionIdFilterSelected: (modelDetails as IGetModelCollectionResponse)?._id,
                    modelCollectionIdsToCompare: (modelDetails as IGetModelCollectionResponse)?._id,
                    scenario: 'integrity',
                    //@ts-ignore
                    accuracy: Number((modelDetails as IGetModelCollectionResponse)?.checkpointFiles?.files?.analysis?.cachedMetrics?.accuracy?.accuracy || 0),
                    bandFilterSelected: 'ALL',
                    iouThreshold: IouThreshouldValue,
                    isSegmentationModelAnalyticsEnabled:isSegmentationModelAnalyticsEnabled,
                    confidenceScoreThreshold:confidenceScoreThreshold
                }}
            />
        </Box>
    )
}
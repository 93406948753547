export const saveItemWithExpiryInLocalStorage = (key: string, value: any, ttl: number) => {
    const now = new Date()

    const item = {
        value: value,
        expiry: now.getTime() + ttl
    }

    localStorage.setItem(key, JSON.stringify(item))
}

// gets the item (which was saved with expiry) from localstorage with specified key, deletes it if it is expired otherwise returns the value
export const getItemWithExpiryFromLocalStorage = (key: string) => {
    const itemString = localStorage.getItem(key)

    if (!itemString) return null

    const item = JSON.parse(itemString)
    const now = new Date()

    if (now.getTime() > item.expiry) {
        localStorage.removeItem(key)
        return null
    }

    return item.value
}
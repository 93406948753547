import InfoOutlinedIcon from '@material-ui/icons/InfoOutlined';
import React, { useEffect, useState } from 'react';
import { Link, makeStyles, Box, Button, IconButton, Theme, Typography, TextField, Chip } from "@material-ui/core";
import { useHistory, useLocation } from 'react-router';
import CheckBoxOutlinedIcon from '@material-ui/icons/CheckBoxOutlined';
import UserDBService from '../../../../../../../../../../../../services/UserDBService';
import PurposeModelService from '../../../../../../../../../../../../services/PurposeModelService';
import CloseIcon from '@material-ui/icons/Close';
import { nextTab } from '../../../constants/nextTab';
import TrainingpodConfigurationService from '../../../../../../../../../../../../services/TrainingpodConfigurationService';
import FullAreaLoader from '../../../../../../../../../../../../common/components/FullAreaLoader';
import axios from 'axios';
import { tabsCreatorHasSeen, tabsCreatorHasSeenPayload} from '../../../../constants/hardcodedConstants';
import { Stack } from '@mui/material';
import { Reference, tags, TestDataSetOfPurposeModel } from '../../../../../../../../../../../../common/constants/interfaces/purposeModels';
import { Add, Delete } from '@material-ui/icons';
import { ReactComponent as CV_Image_background } from "../../../../../../../../../../../../assets/svg/CV_image_icon_image.svg";
import AiMarketPlaceCSS from '../../../../../../../aiMarketPlace/aiMarketPlace.module.css'
import noiseImage from '../../../../../../../../../../../../assets/images/noise.png'
import { GetUniqueAiTasksAndProblemTypesResponse } from '../../../../../../../aiMarketPlace/aiMarketPlaceInterfaces/interfaces';
const problemTypes = [
  { name: '', description: '' },
];

const useStyles = makeStyles((theme: Theme) => ({
  container: {
    width:'500px',
    overflow: 'hidden',
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
  },
  popover: {
    height:'2px',
    '&:hover': {
      height: 'auto',
    },
  },
  button : {
    textDecoration: 'none',
    '&:hover': {
      textDecoration: 'underline',
    },
  },
  expandableDiv: {
    width: '200px',
    height: '20px',
    overflow: 'hidden',
    transition: 'height 10s ease'
  },
}));
export const tasksData = [
  {
    aiTask:'Computer Vision - Image',
    problemTypes: ["Classification","Segmentation","Depth Estimation","Detection","Enhancements","Generation","OCR","Pose","3D Dectection"]
  },
  {
    aiTask: 'Natural Language Processing',
    problemTypes : ["Text Classification", "Token Classification", "Translation", "Question Answering", "Summarisation", "Feature Extraction",
      "Text Generation","Sentence Similarity"
    ]
  },
  {
    aiTask: "Computer Vision - Video",
    problemTypes : ["Next frame","Video Pose", "Obejct Tracking","Frame Classifaction","Stabilization","Vid2Image","Transcription"]
  },
]
const ProblemType = () => {
  const classes = useStyles();
  const history = useHistory();
  const  { search, pathname} = useLocation();
  const [anchorEl, setAnchorEl] = React.useState<HTMLElement | null>(null);
  const [hoveredStates, setHoveredStates] = useState(Array(problemTypes.length).fill(false));
  const [selectedProblemName, setSelectedProblemName] = useState<string>('noProblemSelected');
  const [userSelections, setUserSelections] = useState<any>();
  const [infoButtonClicked, setInfoButtonClicked] = useState<boolean>(false);
  const [loggedInUserId, setLoggedinUserId] = useState<string>();
  const [loggedInUserEmail, setLoggedinUserEmail] = useState<string>();
  const [selectedProblemTypes, setSelectedProblemTypes] = useState(problemTypes)
  const [isDataLoading,setIsDataLoading] = useState<boolean>(true);
  const location = useLocation();
  const params = new URLSearchParams(location.search);

  const purposeModelId = params.get("purposeModelId") || "";

  const pathSplit = pathname.split('/')
  pathSplit.pop()
  let nextPage = pathSplit.join('/')
  nextPage=nextPage+nextTab['problemType']


  const projectId = params.get("project") || "";
  const modelId = params.get("model") || "";
  
  console.log('location', projectId);

  const handleMouseClick = (index: number) => {
      setHoveredStates((prev) => {
        const newHoveredStates = [...prev];
        newHoveredStates[index] = !newHoveredStates[index];
        return newHoveredStates;
      });
  };


  async function fetchUserSelections() {
    try {
      const loggedInUserEmail = await UserDBService.getLoggedInUserEmail();
      setLoggedinUserEmail(loggedInUserEmail);
      const loggedInUserId = await UserDBService.getLoggedInUserIdWithEmail(loggedInUserEmail);
      setLoggedinUserId(loggedInUserId);
      // console.log("User Id", loggedInUserId);
      if(purposeModelId){
        const collectionStamp = await PurposeModelService.getPurposeModelData(purposeModelId);
        setSelectedProblemName(collectionStamp.problemType)
        setAiTask(collectionStamp.aiTask)
        console.log("collectionStamp.aiTask",collectionStamp.aiTask)
        setUserSelections(collectionStamp);
        setDateOfRelease(collectionStamp.dateOfRelease);
        setMonthOfRelease(collectionStamp.monthOfRelease);
        setYearOfRelease(collectionStamp.yearOfRelease);
        setTags(collectionStamp.tags || []);
        setTestDataSet(collectionStamp.testDataSet);
        setReferences(collectionStamp.references || [])
      }
      // const collectionStamp = await PurposeModelService.getUserSelectionsData(loggedInUserId, projectId);
      const configurationStamp = await TrainingpodConfigurationService.getConfigurations();
      setSelectedProblemTypes(configurationStamp.problemTypes)
      setIsDataLoading(false);
    } catch (err) {
      setIsDataLoading(false);
      console.log("Error fetching LoggedInUserId", err);
    }
  }
  
  useEffect(() => {
    // fetch UserId using userEmail
    // check if UserId exists in purposeModel collection
    // if yes, setState
    fetchUserSelections();
  },[selectedProblemName,infoButtonClicked])

  const handleProblemSelection = async (problemName: string,aiTask:string) => {

    const saveCurrentTabSeenByUser = async () => {
      const payload:tabsCreatorHasSeenPayload = {
        purposeModelId: purposeModelId,
        tabDetail: {
            tab: "/modelPurpose"
        }
      }
      try{
        const apiResponse = await PurposeModelService.saveCurrentTabSeenByUser(payload);
        console.log('testingAtProblemType', apiResponse);
      } catch(err) {
        console.log(err);
      }
      
    }

    if(selectedProblemName){
      saveCurrentTabSeenByUser()
    }

    const dataMapping = {
      rlefToModelGeneratedCode: "",
      rlefToModelOutputFiles: [],
      trainingModelOnEpocCodeGenerated: "",
      trainingModelOnEpocOutputFiles: [],
      convertingDataFromModelFormatToTestingAnalyticsCodeGenerated: "",
    }

    setSelectedProblemName(problemName);
    // console.log("user Selections here:", userSelections);
    // setSelectedProblemName(problemName)
    const query = new URLSearchParams(search);
    if (purposeModelId) {
    //   PurposeModelService.putUpdatedSelections({
    //     _id: purposeModelId,
    //     'problemType': problemName});
      history.push(`${nextPage}?${query}`);
    } else {
    // console.log("We here!");
      const apiResponse = await PurposeModelService.postNewRecordInCollection({'problemType': problemName, 'projectId': projectId, 'dataMapping': dataMapping, 'modelId': modelId, 'tabsCreatorHasSeen': tabsCreatorHasSeen,
        aiTask: aiTask,
        ...(dateOfRelease ? { dateOfRelease } : {}),
        monthOfRelease,
        yearOfRelease
      });
      history.push(`${nextPage}?${query}&purposeModelId=${apiResponse._id}`);
    }
  }

  useEffect(() => {
    const saveCurrentTabSeenByUser = async () => {
      const payload:tabsCreatorHasSeenPayload = {
        purposeModelId: purposeModelId,
        tabDetail: {
            tab:"/modelPurpose"
        }
      }

      try {
        console.log('testingAtProblemType');
        const apiResponse = await PurposeModelService.saveCurrentTabSeenByUser(payload);
        console.log('apiResponce', apiResponse);
      } catch(err) {
        console.log(err);
      }
    }
    if(selectedProblemName){
      saveCurrentTabSeenByUser()
    }
    
  },[selectedProblemName, purposeModelId]);


  // const [tasksData, setTasksData] = useState<GetUniqueAiTasksAndProblemTypesResponse[] | null>(null); // Tasks data
  const [aiTask, setAiTask] = useState<string>("")
    const [dateOfRelease, setDateOfRelease] = useState<number | null>(null)
    const [monthOfRelease, setMonthOfRelease] = useState<number | null>(null)
    const [yearOfRelease, setYearOfRelease] = useState<number | null>(null)
    const [numParams, setNumParams] = useState<number>(0)
    const [tags, setTags] = useState<tags>([])
    const [testDataSet, setTestDataSet] = useState<TestDataSetOfPurposeModel | null>(null)
    const [tempTagValue, setTempTagValue] = useState<string>('')
    const [references, setReferences] = useState<Array<Reference>>([])
    const [isSavingData, setIsSavingData] = useState<boolean>(false)

    const onChangeAiTask = (event: any) => {
        setAiTask(event.target.value)
    }

    const onChangeDateOfRelease = (event: any) => {
        setDateOfRelease(Number(event.target.value))
    }

    const onChangeMonthOfRelease = (event: any) => {
        setMonthOfRelease(Number(event.target.value))
    }

    const onChangeYearOfRelease = (event: any) => {
        setYearOfRelease(Number(event.target.value))
    }

    const onChangeNumParams = (event: any) => {
        setNumParams(Number(event.target.value))
    }

    const onChangeTags = (event: any) => {
        setTags(event.target.value.split(',').map((tag: string) => tag.trim()))
    }

    const onChangeTestDataSet = (editing: "label" | "link", newValue: string) => {
      if (editing === 'label') {
        // @ts-ignore
        setTestDataSet((prev) => {
          return {...(prev || {}), label: newValue }
        })
      }
      else if(editing === 'link') {
        // @ts-ignore
        setTestDataSet((prev) => {
          return {...(prev || {}), link: newValue }
        })
      }
    }

    const handleKeyDownInTagInput = (event: React.KeyboardEvent) => {
      if ((event.key === 'Enter') && tempTagValue.trim() !== "") {
        setTags([...(tags || []), { value: tempTagValue.trim() }]);
        setTempTagValue("");
      }
    };
  
    const handleDeleteTag = (tagToDelete: string) => () => {
      setTags(tags.filter(tag => tag.value !== tagToDelete));
    };

    const onAddEmptyReference = () => {
      setReferences(prev => {
        return [...(prev || []), {label: '', link: ''}]
      })
    }

    const onDeleteReference = (index: number) => {
      setReferences(prev => {
        return [...(prev || []).slice(0, index),...(prev || []).slice(index + 1)]
      })
    }

    const onChangeReference = (index: number, property: "label" | "link", newValue: string) => {
      setReferences(prev => {
        const updatedReferences = [...(prev || [])];
        updatedReferences[index][property] = newValue;
        return updatedReferences;
      });
    };

    const onSaveModelDetails = async () => {
      try {
        setIsSavingData(true);
        await PurposeModelService.putUpdatedSelections({
          _id: purposeModelId,
          'aiTask': aiTask,
          'dateOfRelease': dateOfRelease,
          'monthOfRelease': monthOfRelease,
          'yearOfRelease': yearOfRelease,
          'tags': tags,
          'testDataSet': testDataSet,
          'references': references,
        });
      } catch(err) {
        console.log('Error saving model details', err);
      }
      finally{
        setIsSavingData(false);
      }
    }


  const returnNewFieldsOfTrainingPod = () => {
    return <div style={{maxWidth: '40%'}}>
        <Typography variant="h6" style={{ color: '#008D9C' }} >
            Provide a few details about your model
        </Typography>

        <Stack>
            <Typography style={{marginTop: '2rem', fontWeight: 'bold'}}>AI Task</Typography>
            <TextField required placeholder='AI Task' value={aiTask} onChange={onChangeAiTask} />

            <Typography style={{marginTop: '2rem', fontWeight: 'bold'}}>Release date <small>(required for general purpose models)</small></Typography>
            <Box style={{width: '100%', gap: 12, display: 'flex'}}>
                <TextField style={{minWidth: '4rem'}} label="Date" type="number" InputProps={{ inputProps: { min: 1, max: 31 } }} value={dateOfRelease} onChange={onChangeDateOfRelease} />
                <TextField style={{minWidth: '4rem'}} required label="Month" type="number" InputProps={{ inputProps: { min: 1, max: 12 } }} value={monthOfRelease} onChange={onChangeMonthOfRelease} />
                <TextField style={{minWidth: '4rem'}} required label="Year" type="number" InputProps={{ inputProps: { min: 1900, max: 3000 } }} value={yearOfRelease} onChange={onChangeYearOfRelease} />
            </Box>

            {/* num params -----------  */}
            {/* <Typography style={{marginTop: '2rem', fontWeight: 'bold'}}>Num params</Typography>
            <TextField style={{maxWidth: '6rem'}} required label="" type="number" value={numParams} onChange={onChangeNumParams} /> */}

            {/* tags -----------  */}
            <Typography style={{ marginTop: '2rem', fontWeight: 'bold' }}>Tags</Typography>
            <TextField
              style={{ maxWidth: '6rem' }}
              label=""
              value={tempTagValue}
              onChange={(e) => setTempTagValue(e.target.value)}
              onKeyDown={handleKeyDownInTagInput}
              placeholder="Press Enter"
            />
            <Box mt={2}>
              {tags.map((tag, index) => (
                <Chip
                  key={index}
                  label={tag.value}
                  variant='outlined'
                  color='primary'
                  onDelete={handleDeleteTag(tag.value)}
                  style={{ margin: '0.2rem' }}
                />
              ))}
            </Box>
                
              {/* test data set ---------- */}
              <Typography style={{ marginTop: '2rem', fontWeight: 'bold' }}>Test dataset <small>(required for general purpose models)</small></Typography>
              <Box style={{width: '100%', gap: 12, display: 'flex'}}>
                <TextField style={{width: '25%'}} required placeholder='Label' value={testDataSet?.label} onChange={(e) => onChangeTestDataSet("label", e.target.value)} />
                <TextField style={{width: '75%'}} required placeholder='Link' value={testDataSet?.link} onChange={(e) => onChangeTestDataSet("link", e.target.value)} />
              </Box>

              {/* references ---------- */}
              <Typography style={{ marginTop: '2rem', fontWeight: 'bold' }}>References <IconButton onClick={onAddEmptyReference} style={{height: '2rem', width: '2rem'}}><Add/></IconButton></Typography>
              {
                references.map((ref, index) => {
                  return (
                  <Box style={{width: '100%', gap: 12, display: 'flex', height: '3rem'}}>
                    <TextField style={{width: '25%'}} required placeholder='Label' value={ref?.label} onChange={(e) => onChangeReference(index, 'label', e.target.value)} />
                    <TextField style={{width: '70%'}} required placeholder='Link' value={ref?.link} onChange={(e) => onChangeReference(index, 'link', e.target.value)} />
                    <IconButton style={{height: '2rem', width: '2rem'}}> <Delete onClick={() => onDeleteReference(index)} style={{color: 'salmon'}} /> </IconButton>
                  </Box>
                  )

                })
              }
              {
                purposeModelId
                &&
                <Button color='primary' onClick={onSaveModelDetails} style={{width: '6rem', marginTop: '2rem', marginBottom: '2rem'}} variant='contained' disabled={isSavingData} >
                  {
                    isSavingData ?
                    "Saving..." :
                    "Save"
                  }
                </Button>
              }
        </Stack>

        <Box p={1} />
    </div>
  }

  if(isDataLoading)
    return <FullAreaLoader customStyle={{height:"calc(100% - 52px)"}}/>
  return (
    <>
    <Typography variant="h6" style={{color:'#45464E',marginBottom:'1rem'}} >
      Problem categories      
    </Typography>
    {tasksData.map((eachTaskData,index:number)=>
    <Box
      key={index}
      className={AiMarketPlaceCSS.ProjectTypeContainer}
      style={{
          background: 'rgba(2,56,62,1)',
          overflow: 'hidden',
          marginBottom:'1rem'
      }}
    >
      <div style={{
          position: 'absolute',
          top: '-30rem',
          right: 0,
          width: '100%',
          height: '500%',
          background: 'radial-gradient(ellipse 13rem 38% at 80% 50%, rgba(0,160,180,1), rgba(2,56,62,0)',
          transformOrigin: '80% 50%',
          transform: 'rotate(70deg)',
      }}></div>
      <img src={noiseImage} style={{
          position: 'absolute',
          top: 0,
          left: 0,
          width: '100%',
          height: '100%',
          mixBlendMode: 'overlay'
      }} alt='' />
      <Typography variant="h5" className={AiMarketPlaceCSS.projectTypeHeading}>
          {eachTaskData.aiTask}
      </Typography>
      <Box className={AiMarketPlaceCSS.subTasksContainer} style={{zIndex: 100}}>
          {eachTaskData.problemTypes.map((problemType, ind) => (
              <Chip
                  key={ind}
                  variant={selectedProblemName===problemType?"default":"outlined"}
                  label={problemType}
                  // color={selectedProblemName===problemType?"primary":"default"}
                  style={{color:`${selectedProblemName===problemType?'rgb(19, 19, 19)':'white'}`,
                          backgroundColor:`${selectedProblemName===problemType?'white':'trasparent'}`}}
                  clickable
                  className={AiMarketPlaceCSS.subTasksChip}
                  onClick={()=>handleProblemSelection(problemType,eachTaskData.aiTask)}
              />
          ))}
      </Box>
      <Box className={AiMarketPlaceCSS.taskBasedBgImage}>
          <CV_Image_background />
      </Box>
      </Box>)
    }

      {/* {returnNewFieldsOfTrainingPod()}
      <Typography variant="h6" style={{color:'#008D9C'}} >
        Select the type of problem you are solving
      </Typography>

      <Box p={1}/>
      
      <Box display="flex" flexDirection="column" style={{ overflowY: 'auto', borderTop: '1px solid #ccc' }}>
        <Box p={3}>
            <Box p={1}/>
              {selectedProblemTypes.map((problem, index) => (
                    <div
                      key={index}
                      style={{
                        width: '500px',
                        height: hoveredStates[index] ? 'auto' : '50px',
                        overflow: 'hidden',
                        transition: 'height 10s ease',
                        padding: hoveredStates[index] ? '2px' : '0px',
                        border: hoveredStates[index] ? '1px solid #d3d3d3' : '0px',
                        boxShadow: hoveredStates[index] ? '20px 20px 20px rgba(0, 0, 0, 0.2)' : '',
                        borderRadius: hoveredStates[index] ? '5px' : ''
                      }}
                    >
                        <div className={classes.container}>
                          <div>
                          <Button 
                            component={Link}
                            style={{color: '#3b74d1', textDecoration:'underline', fontWeight:'bold', fontFamily:'Nunito Sans, sans-serif' }}
                            className={classes.button}
                            onClick={()=>handleProblemSelection(problem.name)}
                          >
                            {problem.name}
                          </Button>
                          {selectedProblemName === problem.name && <CheckBoxOutlinedIcon style={{color:"#008D9C"}}/>}
                          </div>
                          <IconButton 
                            aria-label="info"
                            onClick={()=>handleMouseClick(index)}
                          >
                            {!hoveredStates[index] ? <InfoOutlinedIcon /> : <CloseIcon/>}
                          </IconButton>
                        </div>
                        <Typography variant='body2' style={{margin:'10px', fontFamily:'Nunito Sans, sans-serif'}}>{problem.description}</Typography>
                        <Box p={2}/>
                    </div>
              ))}
        </Box>
      </Box> */}
    </>
  );
};

export default ProblemType;


import { APP_API } from "../../../../../../services/constants"
import { AiTaskQueryParams, IgetModelCollectionsById, ILastestModelQueryParams, IPopularModelQueryParams } from "./aiMarketPlaceInterfaces/interfaces";

export const BASE_URL = APP_API + 'aiMarketPlace'
export const DATA_CART_BASE_URL = BASE_URL + '/dataCart'
export const STREAM_RESPONSE_POST= 'https://dev-egpt.techo.camp/api/chat/streamResponsePost'
export const AI_MARKETPLACE_ENDPOINT = '/aiMarketPlace'
export const TRYOUT_ENDPOINT = '/tryout'


function isValidObjectId(id:any) {
    const regex = /^[0-9a-fA-F]{24}$/;
    return regex.test(id);
}

export const AI_MARKET_PLACE_API_URLS = {
    GET_LIST_OF_AI_TASKS: `${BASE_URL}/getUniqueAiTasksAndProblemTypes`,
    INSERT_MESSAGE_IN_CHAT_HISTORY: `${BASE_URL}/chatHistory/message`,
    GET_TASK_DETAILS: ({ aiTask, problemType, limit, offset, sortField, sortOrder, minAccuracy, maxAccuracy, maxIntegrity, minIntegrity, bestModelFilter, testDataSetId, purposeModelId, viewModelPageOpen, modelCollectionId }: AiTaskQueryParams) => {
        let url = `${BASE_URL}?`;

        // Optional query parameters
        if (aiTask) url += `&aiTask=${aiTask}`;
        if (problemType) url += `&problemType=${problemType}`;
        if (limit !== undefined) url += `&limit=${limit}`;
        if (offset !== undefined) url += `&offset=${offset}`;
        if (sortField) url += `&sortField=${sortField}`;
        if (sortOrder) url += `&sortOrder=${sortOrder}`;
        if (minAccuracy !== undefined) url += `&minAccuracy=${minAccuracy}`;
        if (maxAccuracy !== undefined) url += `&maxAccuracy=${maxAccuracy}`;
        if (maxIntegrity !== undefined) url += `&maxIntegrity=${maxIntegrity}`;
        if (minIntegrity !== undefined) url += `&minIntegrity=${minIntegrity}`;
        if (bestModelFilter !== undefined) url += `&bestModelFilter=${bestModelFilter}`;
        if (viewModelPageOpen !== undefined) url += `&viewModelPageOpen=${viewModelPageOpen}`;
        if (testDataSetId !== undefined) url += `&testDataSetId=${testDataSetId}`;
        if (purposeModelId !== undefined) url += `&purposeModelId=${purposeModelId}`;
        if (modelCollectionId !== undefined) url += `&modelCollectionId=${modelCollectionId}`;

        return url;
    },
    GET_POPULAR_MODELS : ({sortOrder,bestModelFilter,testDataSetRandomResourceIdsCnt,limit,problemType,purposeType}:IPopularModelQueryParams) =>{
        let url = `${BASE_URL}?sortField=clonesCount&sortOrder=${sortOrder}&bestModelFilter=${bestModelFilter}&testDataSetRandomResourceIdsCnt=${testDataSetRandomResourceIdsCnt}`;
        // Optional query parameters
        if (limit !== undefined) url += `&limit=${limit}`;
        if(problemType!== undefined) url += `&problemType=${problemType}`;
        if(purposeType!== undefined) url += `&purposeType=${purposeType}`;
        return url
    },
    GET_LATEST_MODELS : ({bestModelFilter,testDataSetRandomResourceIdsCnt,limit,problemType,purposeType}: ILastestModelQueryParams) =>{
        let url = `${BASE_URL}?bestModelFilter=${bestModelFilter}&testDataSetRandomResourceIdsCnt=${testDataSetRandomResourceIdsCnt}`;
        // Optional query parameters
        if (limit !== undefined) url += `&limit=${limit}`;
        if(problemType!== undefined) url += `&problemType=${problemType}`;
        if(purposeType!== undefined) url += `&purposeType=${purposeType}`;
        return url
    },
    GET_MODEL_COLLECTIONS_BY_ID:({testDataSetId,purposeModelId,modelCollectionId,testDataSetRandomResourceIdsCnt, getLibrariesRequirements}:IgetModelCollectionsById)=>{
        let url = `${BASE_URL}?purposeModelId=${purposeModelId}`;
        if (isValidObjectId(modelCollectionId)) url += `&modelCollectionId=${modelCollectionId}`;
        if (isValidObjectId(testDataSetId)) url += `&testDataSetId=${testDataSetId}`;
        if(isValidObjectId(testDataSetRandomResourceIdsCnt)){
            url+=`&testDataSetRandomResourceIdsCnt=${testDataSetRandomResourceIdsCnt}`;
        } 
        else{
            url+=`&testDataSetRandomResourceIdsCnt=4`;
        }

        if(getLibrariesRequirements){
            url+=`&getLibrariesRequirements=${getLibrariesRequirements}`;
        }

        return url
    }

}
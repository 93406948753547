export function getFileTypeFromFileName(fileName: string): string {
    if (!fileName) return "fileName is not defined"

    if ([".jpeg", ".jpg", ".png", ".gif", ".tiff", ".svg"].some((extention) => fileName.endsWith(extention))) return "image"
    if (["mp3", "wav", "ogg", "wma", "m4a"].some((extention) => fileName.endsWith(extention))) return "audio"
    if (["mp4", "avi", "mkv", "mov", "wmv", "flv", "webm"].some((extention) => fileName.endsWith(extention))) return "video"
    if (["txt", "pdf"].some((extention) => fileName.endsWith(extention))) return "text"

    return "unknown"
}

export function debounce<T extends (...args: any[]) => any>(func: T, wait: number): (...args: Parameters<T>) => any {
    let timeout: NodeJS.Timeout;

    return (...args: Parameters<T>): any => {
        clearTimeout(timeout);
        timeout = setTimeout(() => func(...args), wait);
    };
}
import { Card, CardContent, Typography, makeStyles, CardActionArea, Box, IconButton, Menu, MenuItem } from '@material-ui/core';

import { DataSortFieldDefaultValue, IModel, SortOrderDefaultValue, convertDate } from '../../../../../../../common';
import {ReactComponent as TestCollectionIcon} from "../../../../../../../assets/svg/testIcon.svg"
import {ReactComponent as ModelCollectionIcon} from "../../../../../../../assets/svg/model-Icon.svg"
import {ReactComponent as DataSetCollectionIcon} from "../../../../../../../assets/svg/data-setIcon.svg"

import { Audiotrack, Image, ImageAspectRatio,} from '@material-ui/icons';
import VideoLibraryIcon from '@material-ui/icons/VideoLibrary';
import PhotoLibraryIcon from '@material-ui/icons/PhotoLibrary';
// import FileNameClipper from '../../../../../../../common/components/FileNameClipper';
import { Tooltip } from '@material-ui/core';
import { ModelTypeTextIcon } from '../features/model/components/ModelTypeTextIcon';
import { ModelTypeTextAnnotationIcon } from '../features/model/components/ModelTypeTextAnnotationIcon';
import { ModelTypeDLP } from '../features/model/components/ModelTypeDLP';
import { useContext, useMemo, useRef, useState } from 'react';
import { deleteButtonIconColor } from '../features/model/features/data/constants/constants';
import DeleteModelConfirmPopup from './DeleteModelConfirmPopup';
import { ModelService } from '../../../../../../../services';
import { deleteModel } from '../../../../../../../store/actions';
import { useDispatch } from 'react-redux';
import ModelListContext from '../modelistContext/modelist.context';
import css from "./ModelCard.module.css";
import MoreVertIcon from '@material-ui/icons/MoreVert';
import {ReactComponent as RightArrowIcon} from "../../../../../../../assets/svg/Icon - Right Arrow.svg";
import ModelTypePrompt from '../features/model/components/ModelTypePrompt';
import { camelCaseToTitleCase } from '../../../../../../../services/camelCaseToTitleCaseHelperService';
import { ModelTypeVideoAnnotationIcon } from '../features/model/components/ModelTypeVideoAnnotationIcon';
import AudioTextTranscriptionIcon from '../../../../../../../assets/svg/IconComponents/AudioTextTranscriptionIcon';
import { themePrimaryMainColor } from '../../../../../../../theme';
import { Link, useLocation } from 'react-router-dom';
import { ResourceLimitQueryParam, ResourceOffsetQueryParam, ResourceSortFieldQueryParam, ResourceSortOrderQueryParam, ResourceStatusQueryParam } from '../features/model/features/data/components/TableContent';
const useStyles = makeStyles((theme) => ({
    root: {
        maxWidth: 345,
        // width: 340,
        // height: 170
    },
    groupViewCardDimension : {
        width : 320
    },
    media: {
        height: 0,
        paddingTop: '56.25%', // 16:9
    },
    type: {
        // background: '#d4e8ea', //TODO take color from pallete
        width: '100%',
        height: '61px',
        display: 'flex',
        justifyContent: 'left',
        // alignItems: 'center',
        borderRadius: '50%',
        marginBottom: theme.spacing(1),

    },
    typeInactiveBackground: {
        background: '#ececec',
    },
    typeActiveBackground: {
        background: '#d4e8ea',
    },
    typeIcon: {
        fontSize: '20px',
        margin : '4px 8px 4px 4px'
    },
    typeActiveIcon: {
        fontSize: '20px',
        color: theme.palette.primary.main,
        fill: theme.palette.primary.main
    },
    typeInactiveIcon: {
        fontSize: '20px',
        color: '#898989'
    },
    cardActionsContainer: {
        display: 'flex',
        justifyContent: 'flex-end',
        width: '100%',
        height: '20px',
        padding: '0px',
    },
    deleteButton: {
        color: deleteButtonIconColor,
    },
    rowFlex: {
        display: 'flex',
        flexDirection: 'row',
    },
    zeroPadding: {
        padding: "0px"
    },
    live : {
       color:"#DE0101",
       display:"flex",
       alignItems:"center" ,
       columnGap:"3px",
       width:"4rem",
       fontSize: "14px"
    },
    liveIcon:{
       display:"flex",
       width:"16px",
       height:"16px",
       borderRadius:"50%",
       position:"relative"
    },
    liveIconBg:{
      backgroundColor:"#DE0101",
      borderRadius:"50%",
      width:"16px",
      height:"16px",
      opacity:"0.3",
      position:"absolute"
    },
    liveIconDot:{
        zIndex:100,
        backgroundColor:"#DE0101",
        borderRadius:"50%",
        minWidth:8,
        minHeight:8,
        width:8,
        height:8,
        transform: "translate(-50%, -50%)",
        position :"absolute",
        left:"50%",
        top:"50%"
    }
}));

interface IProps {
    model: IModel;
    onClick: (data: IModel) => void;
    isActive : boolean;
    isReactFlowEditMode?: boolean;
    handleOnMoveModelToDifferentModelGroupSelectInMenu?: (modelId: string) => any;
    scenario : "Model-View" | "Group-View";
}

function ModelCard(props: IProps) {
    const { model, onClick, isActive, scenario } = props;
    const classes = useStyles();
    const location = useLocation();

    const [isDeleteHappening, setIsDeleteHappening] = useState<boolean>(false);
    const [showPopup, setShowPopup] = useState<boolean>(false);
    const dispatch = useDispatch();
 
    const modelistContextValue = useContext(ModelListContext);
    const {
        removeModelFromModelGroups
    } = modelistContextValue || {};

    const removeModelFromModelGroupsRef = useRef(removeModelFromModelGroups);
    removeModelFromModelGroupsRef.current = removeModelFromModelGroups;

    const openDeleteModelConfirmPopup = (event: React.MouseEvent<any>) => {
        event.stopPropagation();
        setShowPopup(true);
        setModelMenu(oldState=>({...oldState, open: false}))
    }

    const closeDeleteModelConfirmPopup = () => {
        setShowPopup(false);
    }

    const deleteModelFromDBAndReduxState = async () => {
        try {
            if (model._id) {
                closeDeleteModelConfirmPopup();
                setIsDeleteHappening(true);
                const apiResponse = await ModelService.deleteModel({ model: model._id });
                dispatch(deleteModel({modelId: model._id}));
                setIsDeleteHappening(false);
                if (removeModelFromModelGroupsRef.current) {
                    removeModelFromModelGroupsRef.current(model._id);
                }
            }
        }
        catch (error) {

        }
    };

    const [modelMenu, setModelMenu] = useState<{
        open: boolean,
        model: IModel | undefined,
        anchorEl: HTMLButtonElement | undefined
    }>({
        open: false,
        model: undefined,
        anchorEl: undefined
    })
    const tagList:string = useMemo(()=>{
        if(model.tags){
            return (model.tags.map(tag=>" "+tag.value)).join(",")
        }
        return ""
    },[model?.tags])

    const getPathToGoInsideModel = (data: IModel): string => {
        const queryParamString: string = `?${ResourceStatusQueryParam}=backlog&${ResourceOffsetQueryParam}=1&${ResourceLimitQueryParam}=10&project=${data?.project?._id}&model=${data._id}&type=${data.type}&${ResourceSortOrderQueryParam}=${SortOrderDefaultValue}&${ResourceSortFieldQueryParam}=${DataSortFieldDefaultValue}`;
        const newPath = `${location.pathname}/${data.name}/data/backlog${queryParamString}`;
        return newPath;
    }

    return (
        <>
            <Card className={`${classes.root} ${scenario === "Group-View" ? classes.groupViewCardDimension : ""} ${isDeleteHappening ? css.modelDeleteHapppening : ""} ${props.isReactFlowEditMode ? css.rootReactFlowEditMode : ""}`}
            >
                <CardActionArea disableRipple={true}>
                    <CardContent classes={{root: css.cardContentRoot}}>
                        <Box className={`${classes.rowFlex} ${css.modelIconRow}`}>
                          
                            <div className={css.modelIconRowLHS}>   
                                <Box className={`${classes.type}`}>
                                    {
                                        (model?.type === "audio") 
                                            ? <Tooltip title={isActive ? `Active  ${camelCaseToTitleCase(model.type)} Task` : `Active  ${camelCaseToTitleCase(model.type)} Task`}>
                                                <Audiotrack className={`${classes.typeIcon} ${isActive ? classes.typeActiveIcon : classes.typeInactiveIcon}`} />
                                            </Tooltip>
                                            :
                                            (model?.type==="audioTextTranscription") ? 
                                            <Tooltip title={isActive ? `Active  ${camelCaseToTitleCase(model.type)} Task` : `Active  ${camelCaseToTitleCase(model.type)} Task`}>
                                                <div><AudioTextTranscriptionIcon size={22} fillColor={isActive ? themePrimaryMainColor:"#898989"} containerStyle={{margin : '0px 8px 4px 4px'}}/></div>
                                            </Tooltip>
                                            : (model?.type === "text" || model?.type === "textFile" )
                                                ? <Tooltip title={isActive ? `Active ${camelCaseToTitleCase(model.type)} Task` : `Inactive ${camelCaseToTitleCase(model.type)} Task`}>
                                                <div><ModelTypeTextIcon
                                                    IconComponentProps={{
                                                        className: `${classes.typeIcon} ${isActive ? classes.typeActiveIcon : classes.typeInactiveIcon}`
                                                    }}
                                                /></div>
                                                </Tooltip>
                                                : model?.type === "videoAnnotation"
                                                    ? <Tooltip title={isActive ? 'Active Video Annotation Task' : 'Inactive Video Annotation Task'}>
                                                    <div><ModelTypeVideoAnnotationIcon
                                                        IconComponentProps={{
                                                            className: `${classes.typeIcon} ${isActive ? classes.typeActiveIcon : classes.typeInactiveIcon}`
                                                        }}
                                                    /></div>                                                        
                                                    </Tooltip>
                                                : model?.type === "textAnnotation"
                                                    ? <Tooltip title={isActive ? 'Active Text Annotation Task' : 'Inactive Text Annotation Task'}>
                                                    <div><ModelTypeTextAnnotationIcon
                                                        IconComponentProps={{
                                                            className: `${classes.typeIcon} ${isActive ? classes.typeActiveIcon : classes.typeInactiveIcon}`
                                                        }}
                                                    /></div>
                                                    </Tooltip>
                                                    : model?.type === "DLP"
                                                        ? <Tooltip title={isActive ? 'Active DLP Task' : 'Inactive DLP Task'}>
                                                        <div><ModelTypeDLP
                                                            IconComponentProps={{
                                                                className: classes.typeIcon
                                                            }}
                                                        isActive={isActive}
                                                        /></div>
                                                    </Tooltip>
                                                        : model?.type === 'video'
                                                        ? <Tooltip title={isActive ? 'Active Video Task' : 'Inactive Video Task'}>
                                                            <VideoLibraryIcon className={`${classes.typeIcon} ${isActive ? classes.typeActiveIcon : classes.typeInactiveIcon}`} />
                                                          </Tooltip>
                                                        : model?.type === 'image'
                                                            ? <Tooltip title={isActive ? 'Active Image Task' : 'Inactive Image Task'}>
                                                                <Image className={`${classes.typeIcon} ${isActive ? classes.typeActiveIcon : classes.typeInactiveIcon}`} />
                                                              </Tooltip>
                                                            : model?.type === 'imageAnnotation'
                                                                ? <Tooltip title={isActive ? 'Active Image Annotation Task' : 'Inactive Image Annotation Task'}>
                                                                    <ImageAspectRatio className={`${classes.typeIcon} ${isActive ? classes.typeActiveIcon : classes.typeInactiveIcon}`} />
                                                                    </Tooltip>
                                                            : model?.type === 'prompt'
                                                                ? <Tooltip title={isActive ? 'Active Prompt Task' : 'Inactive Prompt Task'}>
                                                                    <div><ModelTypePrompt IconComponentProps={{ className: classes.typeIcon }} isActive={isActive}/></div>
                                                                    </Tooltip>
                                                                : <Tooltip title={isActive ? `Active ${camelCaseToTitleCase(model.type)} Task` : `Inactive ${camelCaseToTitleCase(model.type)} Task`}>
                                                                    <PhotoLibraryIcon className={`${classes.typeIcon} ${isActive ? classes.typeActiveIcon : classes.typeInactiveIcon}`} />
                                                                    </Tooltip>
                                    }
                                    <Tooltip title={model?.name}>
                                        <Typography variant="h6" component="h6" style={{ overflow: "hidden", height: "61px", textOverflow: "ellipsis", whiteSpace: "pre-wrap", fontFamily: "NunitoSans-Regular", fontSize: "19px" }}>
                                            {/* <FileNameClipper fileName={model?.name} currentPage="CoPilotHomePage"/> */}
                                            {
                                                model?.name?.length>44
                                                ? `${model?.name?.substring(0,40)}...`
                                                : `${model?.name}`
                                            }
                                            {/* {model?.name} */}
                                        </Typography>
                                    </Tooltip>
                                </Box>
                                <div className={css.modelNameColumn}>
                                    <Tooltip title={model.modelGroup?.name || ""}>
                                        <Typography variant="body2" component="p" color="textSecondary" style={{height : '20px', marginBottom : '8px'}}>
                                            {/* {convertDate(model?.created_at)} */}
                                            {
                                                // @ts-ignore
                                                model.modelGroup?.name?.length>34
                                                ? `${model?.modelGroup?.name?.substring(0, 30)}...`
                                                : model?.modelGroup?.name                                              
                                            }             
                                        </Typography>                                    
                                    </Tooltip>
                                    <Tooltip title={tagList ? tagList: "No Tags available"}>
                                        <div style={{display:"flex",justifyContent:"flex-start",alignItems:"center"}}>
                                            <Typography variant="body2" component="p" color="textSecondary" style={{height : '20px', marginBottom : '8px'}}>
                                                Tags:&nbsp;
                                            </Typography>
                                            <Typography variant="body2" component="p" color="textSecondary" style={{height : '20px', marginBottom : '8px',textOverflow:"ellipsis"}} noWrap={true}>
                                                {tagList ? tagList : "No Tags Available"}
                                            </Typography>
                                        </div>                                    
                                    </Tooltip>
                                    <div style={{display:"flex",alignItems:"center", columnGap:"12px"}}>
                                        {model?.liveModelCollectionExist &&
                                           <div className ={ classes.live }>
                                              <div className={classes.liveIcon}>
                                                <div  className={classes.liveIconBg}>
                                                </div>
                                              <div className={classes.liveIconDot}>
                                             </div>
                                           </div>
                                             LIVE
                                         </div>
                                       }
                                    <Tooltip title={convertDate(model?.created_at) || ""}>
                                        <Typography variant="body2" component="p" color="textSecondary">
                                            {convertDate(model?.created_at) || ""}
                                        </Typography>                                    
                                    </Tooltip> 
                                </div>
                                </div>     
                            </div>
                            <div className={css.modelIconRowRHS}>
                                {
                                    <div>
                                    <Tooltip  title={`Contains ${model?.testCollectionExist ? 'Test' : model?.modelCollectionExist ? 'Model' : 'Data-set'} collection`} arrow >
                                        { model?.testCollectionExist?<TestCollectionIcon/>:model?.modelCollectionExist?<ModelCollectionIcon/>
                                            :model.datasetCollectionExist?<DataSetCollectionIcon/>
                                         :<div style={{display:"none"}}></div>}
                                    </Tooltip>
                                  
                                <IconButton
                                    aria-label="more"
                                    aria-controls="long-menu"
                                    aria-haspopup="true"
                                    onClick={(event)=>{
                                    setModelMenu({
                                        open: true,
                                        anchorEl: event.currentTarget,
                                        model: model
                                    })
                                    }}
                                    size='small'
                                >
                                    <MoreVertIcon />
                                </IconButton>
                                <Menu
                                    anchorEl={modelMenu.anchorEl}
                                    keepMounted
                                    open={modelMenu.model?._id === model._id && modelMenu.open ? true : false}
                                    onClose={()=>{
                                    setModelMenu(oldState=>({...oldState, open: false}))
                                    }}
                                    PaperProps={{
                                    style: {
                                        width: '20ch',
                                    },
                                    }}
                                >
                                        {
                                            props.handleOnMoveModelToDifferentModelGroupSelectInMenu &&
                                            <MenuItem 
                                                onClick={() => {
                                                    props.handleOnMoveModelToDifferentModelGroupSelectInMenu && props.handleOnMoveModelToDifferentModelGroupSelectInMenu(model._id);
                                                    setModelMenu(oldState=>({...oldState, open: false}))
                                                }}
                                            >
                                                Move to different group
                                            </MenuItem>
                                        }
                                    {/* <MenuItem 
                                        onClick={()=>{
                                        }}
                                    >
                                        Edit model info
                                    </MenuItem> */}
                                    <MenuItem 
                                        onClick={openDeleteModelConfirmPopup}
                                    >
                                        Delete task
                                    </MenuItem>
                                </Menu>
                                </div>}
                                

                                <div className={css.viewButton}>
                                    <Link style={{ display: 'flex', alignItems: 'center' }} to={() => getPathToGoInsideModel(model)} className={css.text}>
                                        <div onClick={() => onClick(model)} style={{ display: 'flex', alignItems: 'center' }}>
                                            VIEW
                                            <div className={css.arrow} style={{ display: 'flex', marginLeft: '0.4rem' }}>
                                                <RightArrowIcon className={css.viewRightArrowIcon} />
                                            </div>
                                        </div>
                                    </Link>
                                </div>                                
                            </div>                            
                        </Box>
                        {/* <CardActions disableSpacing className={classes.cardActionsContainer}>
                            <div className={css.viewButton}>
                                <div className={css.text} onClick={() => onClick(model)}>VIEW</div>
                                <div className={css.arrow}>
                                    <RightArrowIcon className={css.viewRightArrowIcon} />
                                </div>
                            </div>
                        </CardActions> */}
                    </CardContent>
                </CardActionArea>
            </Card>
            <DeleteModelConfirmPopup isDeleteHappening={isDeleteHappening} modelId={model?._id} modelName={model?.name} open={showPopup} close={closeDeleteModelConfirmPopup} deleteModel={deleteModelFromDBAndReduxState} />
        </>
    );
}

export default ModelCard;

import React, { useState } from "react";
import { withStyles } from "@material-ui/core/styles";
import Button from "@material-ui/core/Button";
import Dialog from "@material-ui/core/Dialog";
import MuiDialogTitle from "@material-ui/core/DialogTitle";
import MuiDialogContent from "@material-ui/core/DialogContent";
import MuiDialogActions from "@material-ui/core/DialogActions";
import IconButton from "@material-ui/core/IconButton";
import CloseIcon from "@material-ui/icons/Close";
import Typography from "@material-ui/core/Typography";
import { ResourceLabelViewType } from "../DataSetCollectionPopup";
import {
  ButtonGroup,
  InputAdornment,
  TextField,
  Tooltip,
} from "@material-ui/core";

const styles = (theme: any) => ({
  root: {
    margin: 0,
    padding: theme.spacing(2),
  },
  closeButton: {
    position: "absolute",
    right: theme.spacing(1),
    top: theme.spacing(1),
    color: theme.palette.grey[500],
  },
});

// @ts-ignore
const DialogTitle = withStyles(styles)((props) => {
  // @ts-ignore
  const { children, classes, onClose, ...other } = props;
  return (
    <MuiDialogTitle disableTypography className={classes.root} {...other}>
      <Typography variant="h6">{children}</Typography>
      {onClose ? (
        <IconButton
          aria-label="close"
          className={classes.closeButton}
          onClick={onClose}
        >
          <CloseIcon />
        </IconButton>
      ) : null}
    </MuiDialogTitle>
  );
});

const DialogContent = withStyles((theme) => ({
  root: {
    padding: theme.spacing(2),
  },
}))(MuiDialogContent);

const DialogActions = withStyles((theme) => ({
  root: {
    margin: 0,
    padding: theme.spacing(1),
  },
}))(MuiDialogActions);

type Props = {
  onClose: () => any;
  onApplyClick: (numericValue: string, type: ResourceLabelViewType) => any;
  labelViewDefaultValue: ResourceLabelViewType;
};

export default function ApplyResourceLabelvalueToAllPopup(props: Props) {
  const [numericValue, setNumericValue] = useState<string>("");
  const [resourceLabelViewType, setResourceLabelViewType] =
    useState<ResourceLabelViewType>(props.labelViewDefaultValue);

  return (
    <div>
      <Dialog
        onClose={() => props.onClose()}
        open={true}
        maxWidth={"xs"}
        fullWidth={true}
      >
        <DialogTitle>Apply count to all labels</DialogTitle>
        <DialogContent dividers>
          {/* <Typography gutterBottom>
            Cras mattis consectetur purus sit amet fermentum. Cras justo odio, dapibus ac facilisis
            in, egestas eget quam. Morbi leo risus, porta ac consectetur ac, vestibulum at eros.
          </Typography>
          <Typography gutterBottom>
            Praesent commodo cursus magna, vel scelerisque nisl consectetur et. Vivamus sagittis
            lacus vel augue laoreet rutrum faucibus dolor auctor.
          </Typography>
          <Typography gutterBottom>
            Aenean lacinia bibendum nulla sed consectetur. Praesent commodo cursus magna, vel
            scelerisque nisl consectetur et. Donec sed odio dui. Donec ullamcorper nulla non metus
            auctor fringilla.
          </Typography> */}
          <div
            style={{
              display: "flex",
              width: "100%",
              gap: "12px",
              alignItems: "baseline",
              marginBottom: "12px",
            }}
          >
            <div
              style={{
                fontSize: "14px",
              }}
            >
              View
            </div>
            <div>
              <ButtonGroup size="small">
                <Button
                  color="primary"
                  size="small"
                  variant={
                    resourceLabelViewType === "percentage"
                      ? "contained"
                      : "outlined"
                  }
                  onClick={() => {
                    setResourceLabelViewType("percentage");
                  }}
                >
                  <Tooltip title="Set label count as percentage">
                    <span>%</span>
                  </Tooltip>
                </Button>
                <Button
                  color="primary"
                  size="small"
                  variant={
                    resourceLabelViewType === "number"
                      ? "contained"
                      : "outlined"
                  }
                  onClick={() => {
                    setResourceLabelViewType("number");
                  }}
                >
                  <Tooltip title="Set label count as numeric">
                    <span>123</span>
                  </Tooltip>
                </Button>
              </ButtonGroup>
            </div>
          </div>

          <TextField
            // label="Count to set for cloning"
            // defaultValue={resourceValue.count}
            value={numericValue}
            label="Count"
            // resourceEditedLabel?.countEditedValue
            inputProps={{
              type: "number",
              max:
                resourceLabelViewType === "percentage"
                  ? 100
                  : 10000000000000000000000000000000,
              min: 0,
            }}
            InputProps={{
              ...(resourceLabelViewType === "percentage"
                ? {
                    endAdornment: (
                      <InputAdornment position="end">%</InputAdornment>
                    ),
                  }
                : {}),
            }}
            size="small"
            style={{
              width: "80%",
            }}
            onChange={(event) => {
              setNumericValue(event.target.value);
            }}
          />
        </DialogContent>
        <DialogActions>
          <Button
            variant="outlined"
            onClick={() => props.onClose()}
            color="primary"
          >
            Cancel
          </Button>
          <Button
            variant="contained"
            onClick={() =>
              props.onApplyClick(numericValue, resourceLabelViewType)
            }
            color="primary"
          >
            Save changes
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
}

import { useEffect, useRef } from "react";
import TableContent, {
  ResourceLimitQueryParam,
  ResourceOffsetQueryParam,
} from "../../data/components/TableContent";
import useForceReRender from "../../../../../../../../../../../hooks/useForceReRender";
import { ResourceStatusQueryParam } from "../../data/components/TableContent";
import {
  IDataResponse,
  ResourceStatus,
  resourceDataSetCollectionIdQueryParam,
} from "../../../../../../../../../../../common/constants/interfaces/data";
import { useHistory } from "react-router";

type Props = {
  dataSetCollectionId: string;
  scenario?: string;
  isUsingDataCartForAiAssistedlabellingCodeTesting: boolean;
};

export default function DataSetCollectionResourcesTable(props: Props) {
  const dataSetCollectionId = props.dataSetCollectionId;
  const forceRerender = useForceReRender();
  const history = useHistory();
  const historyPush = history.push;
  const areQueryParamsSetForLoadingResourcesTableRef = useRef(false);

  useEffect(() => {
    if (dataSetCollectionId) {
      areQueryParamsSetForLoadingResourcesTableRef.current = false;
      forceRerender();

      const query = new URLSearchParams(window.location?.search);

      const resourceStatus: ResourceStatus = "approved";
      query.set(ResourceStatusQueryParam, resourceStatus);

      query.set(resourceDataSetCollectionIdQueryParam, JSON.stringify([dataSetCollectionId]));

      query.set(ResourceOffsetQueryParam, "1");
      query.set(ResourceLimitQueryParam, "10");

      historyPush(`${window.location?.pathname}?${query.toString()}`);

      areQueryParamsSetForLoadingResourcesTableRef.current = true;

      forceRerender();
    }
  }, [dataSetCollectionId, forceRerender, historyPush]);

  if (!areQueryParamsSetForLoadingResourcesTableRef.current) {
    return null;
  }

  const scenarioToPassOn = () => {
    let scenario = "dataSetCollectionTableDetailsPopup"
    if(props.scenario === 'aiMarketplaceSelectedDataList'){
      scenario = "aiMarketPlaceDataCart";
    }
    else if(props.scenario){
      scenario = props.scenario;
    }

    return scenario;
  }

  return <TableContent scenario={scenarioToPassOn()} 
    dataSetCollectionIdSelectedInDataSetCollectionDetailsPopup={dataSetCollectionId}
    isUsingDataCartForAiAssistedlabellingCodeTesting={props.isUsingDataCartForAiAssistedlabellingCodeTesting}
  />;
}

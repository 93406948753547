export function removeUUID(filename: string) {
    // This regex matches a UUID at the end of the filename, preceded by an underscore or a hyphen
    const uuidPattern =
        /[-_][0-9a-fA-F]{8}-[0-9a-fA-F]{4}-[0-9a-fA-F]{4}-[0-9a-fA-F]{4}-[0-9a-fA-F]{12}$/;
    // Replace the UUID with an empty string
    return filename.replace(uuidPattern, '');
}

export function replaceAsteriskInAnchor(inputString: string): string {
    // Regular expression to match an anchor tag with asterisk inside
    const regex = /<a [^>]*>.*?<\/a>/g;
    // Check if the inputString contains an anchor tag with asterisk and replace it
    const replacedString = inputString?.replace(regex, (match) => {
        // Replace asterisk inside the anchor tag with &ast;
        return match?.replace(/\*/g, '&ast;');
    });
    return replacedString;
}
export const removeQueryParamsFromUrl=()=>{
    const basePath = window.location.pathname; // Gets the path without query params
    window.history.replaceState({}, '', basePath);
}
import { Tooltip } from "@material-ui/core"
import { ReactElement } from "react"
import css from './tooltip.module.css'

type customTooltipProps = {
    children: ReactElement,
    title: string | ReactElement,
    placement?: "top" | "right" | "bottom" | "left" | "top-start" | "top-end" | "bottom-start" | "bottom-end" | "left-end" | "left-start" | "right-end" | "right-start",
    interactive?: boolean
}

const CustomTooltip = (props: customTooltipProps) => {
    return (
        <Tooltip
            title={props.title}
            classes={{ tooltip: css.tooltip, arrow: css.tooltipArrow }}
            placement={props.placement || 'bottom'}
            arrow
            enterDelay={400}
            enterNextDelay={400}
            interactive={props.interactive || false}
        >
            {props.children}
        </Tooltip>
    )
}

export default CustomTooltip
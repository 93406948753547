import {
	CircularProgress,
	MenuItem,
	TextField,
} from "@material-ui/core";
import Button from "@material-ui/core/Button";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
// import DialogContentText from "@material-ui/core/DialogContentText";
import DialogTitle from "@material-ui/core/DialogTitle";
import { useEffect, useState } from "react";
import { connect, ConnectedProps } from "react-redux";
import { IData } from "../../../../../../../../../../../common";
import { IReduxState } from "../../../../../../../../../../../common/constants/interfaces/redux";
import {
	CreateCoPilotResourceApiRequestPayload,
	ICopilot,
} from "../../../../../../../../../../../common/constants/interfaces/copilot";
import copilotService from "../../../../../../../../../../../services/copilotService";
import { copyByValue, isNullOrUndefined } from "../../../../../../../../../../../services/variableHelperService";
import { Skeleton } from "@material-ui/lab";

interface IProps extends TPropsFromRedux {
	open: boolean;
	onClose: () => any;
	resource: IData;
}

export function AddModelResourceToCopilotDialog(props: IProps) {
	const { open, resource } = props;

	const [coPilotsAvailable, setCoPilotsAvailable] = useState<ICopilot[]>([]);

	const [coPilotIdSelectedByUser, setCoPilotIdSelectedByUser] = useState("");

	const [isResourceBeingAddedToCopilot, setIsResourceBeingAddedToCopilot] =
		useState(false);

	const [areCopilotsBeingFetchedFromDB, setAreCopilotsBeingFetchedFromDB] =
		useState(false);

	useEffect(() => {
		const executeFunction = async () => {
			setAreCopilotsBeingFetchedFromDB(true);
			let requestCopilotType = resource.model.type
			if(requestCopilotType === "audio" && resource.spectrogram) {
				requestCopilotType = "spectrogramAudio"
			}
			const apiResponse = await copilotService.getCopilots({
				project: resource.model.project,
				limit: "-1",
				offset: "-1",
				type: requestCopilotType,
			});
			if (apiResponse.data) {
				if (Array.isArray(apiResponse.data.coPilots)) {
					setCoPilotsAvailable(apiResponse.data.coPilots || []);
				}
			}
			setAreCopilotsBeingFetchedFromDB(false);
		};
		if (resource?.model?.project) {
			executeFunction();
		}
	}, [resource?.model.project, resource?.model.type]);

	const handleAddResourceToCopilotClick = async () => {
		let copilotResourceReqPayload: CreateCoPilotResourceApiRequestPayload = {
			coPilot: coPilotIdSelectedByUser,
			csv: resource.csv || "",
			remarks: resource.remarks?.text || "",
			status: "active",
			tag: resource.tag || "",
			resourceFileURL: resource.resource,
			// resourceFileName: resource.filename,
			label : resource.label
		};

		if (resource.model.type === "audioTextTranscription") {
			if (!isNullOrUndefined(resource.audioTranscription)) {
				copilotResourceReqPayload.audioTranscription = resource.audioTranscription;
			}
			if (!isNullOrUndefined(resource.audioTranscriptionWordsMetadatas)) {
				for (const wordMetadata of ([...(resource?.audioTranscriptionWordsMetadatas || [])] || [])) {
					if (wordMetadata?._id) {
						delete wordMetadata?._id;
					}
				}
				copilotResourceReqPayload.audioTranscriptionWordsMetadatas = resource.audioTranscriptionWordsMetadatas;
			}
		}

		if(resource.model.type !== "text"){
			copilotResourceReqPayload.resourceFileName = resource.filename
		}
		if(resource.model.type ===  'text' || resource.model.type ===  'textAnnotation' || resource.model.type ===  'DLP'){
			copilotResourceReqPayload.resource =  resource.resource;

			// @ts-ignore
			delete copilotResourceReqPayload.resourceFileURL;
		}

		if (
			resource.model.type === "image" || 
			resource.model.type === "text" ||
			resource.model.type === "video"||
			resource.model.type === "audio" ||
			resource.model.type === "audioTextTranscription"
		) {
			copilotResourceReqPayload.confidenceScore =
				Number(resource.confidence_score) || 0;
		}
		if (resource.model.type === "imageAnnotation") {
			copilotResourceReqPayload.imageAnnotations = copyByValue(
				resource.imageAnnotations
			);
		}
		if (
			(resource.model.type === "textAnnotation" || resource.model.type === "DLP") &&
			(resource.trimmedTexts || [])?.length > 0
		) {
			copilotResourceReqPayload.trimmedTexts = [
				...resource.trimmedTexts || []
			];
			for (const trimmedText of copilotResourceReqPayload.trimmedTexts) {
				// @ts-ignore
				delete trimmedText._id;
				// @ts-ignore
				delete trimmedText.createdAt;
			}
		}

		if(resource.model.type === "audio" && resource.spectrogram) {
			copilotResourceReqPayload.spectrogramFileURL = resource.spectrogram
			copilotResourceReqPayload.spectrogramFileName = resource.spectrogramFileName
			copilotResourceReqPayload.trimmedAudioAnnotation = JSON.stringify(resource.trimmedAudioAnnotation)
		}

		setIsResourceBeingAddedToCopilot(true);
		try {
			await copilotService.createCoPilotResource(copilotResourceReqPayload);
		} catch (error) {}
		setIsResourceBeingAddedToCopilot(false);
		props.onClose();
	};

	return (
		<div>
			<Dialog
				open={open}
				onClose={() => props.onClose()}
				maxWidth="sm"
				// fullScreen={true}
				fullWidth={true}
				aria-labelledby="responsive-dialog-title"
			>
				<DialogTitle id="responsive-dialog-title">
					Add Resource to Copilot
				</DialogTitle>
				<DialogContent>
					{/* <DialogContentText>
            This action will affect all {resourcesCountWhichWillBeAffected}{" "}
            datas matching table filter. Are you sure you want to continue?
          </DialogContentText> */}

					{/* {
						!areCopilotsBeingFetchedFromDB &&
					} */}
					<TextField
						select
						label="Select Copilot"
						fullWidth={true}
						disabled={isResourceBeingAddedToCopilot ? true : false}
						value={coPilotIdSelectedByUser}
						onChange={(event) => {
							const value = event.target.value;
							setCoPilotIdSelectedByUser(value);
						}}
						style={{
							marginTop: "10px",
							marginBottom: "30px",
						}}
						// helperText="Please select annotation label"
					>
						{areCopilotsBeingFetchedFromDB ? (
							<Skeleton variant="rect"></Skeleton>
						) : (
							coPilotsAvailable.map((coPilotAvailable) => (
								<MenuItem key={coPilotAvailable._id} value={coPilotAvailable._id}>
									{coPilotAvailable.name}
								</MenuItem>
							))
						)}
					</TextField>
				</DialogContent>
				<DialogActions style={{ padding: "16px 24px", paddingTop: "8px" }}>
					<Button size="small" onClick={() => props.onClose()}>
						Cancel
					</Button>
					<Button
						size="small"
						color="primary"
						variant="contained"
						disabled={
							!coPilotIdSelectedByUser || isResourceBeingAddedToCopilot ? true : false
						}
						onClick={() => handleAddResourceToCopilotClick()}
					>
						{isResourceBeingAddedToCopilot ? <CircularProgress size={20} /> : "OK"}
					</Button>
				</DialogActions>
			</Dialog>
		</div>
	);
}

const mapProps = (state: IReduxState) => {
	return {};
};

const connector = connect(mapProps, {});

export type TPropsFromRedux = ConnectedProps<typeof connector>;

export default connector(AddModelResourceToCopilotDialog);

import { useEffect, useRef, useState } from "react";
import {
  Box,
  Chip,
  CircularProgress,
  Grid,
  makeStyles,
  TextField,
  Tooltip,
  Typography,
} from "@material-ui/core";
import { useForm } from "react-hook-form";

import {
  IAddCollectionPayload,
  CustomButton,
  IReduxState,
  IModelCollection,
  IModelCollectionsToCompare,
  CustomSkeleton,
} from "../../../../../../../../../../../common";
import {
  // addCollection,
  addCollectionPageInit,
  setSelectedModelCollections,
  addSelectedModelCollections,
  mergeCollection,
  collectionSelectedTab,
  setSideDrawer,
  setSelectedModelCollectionsToCompare,
  addSelectedModelCollectionsToCompare,
  // getTags,
} from "../../../../../../../../../../../store/actions";
import { connect, ConnectedProps, useDispatch } from "react-redux";
import { useLocation } from "react-router-dom";
import { Autocomplete } from "@material-ui/lab";
// import MergeTypeIcon from '@material-ui/icons/MergeType';
import { DataService } from "../../../../../../../../../../../services";
import CompareIcon from '@material-ui/icons/Compare';
// import ModelAnalyticsDashboard from "./ModelAnalyticsDashboard";
import { useHistory } from 'react-router-dom'  
import { showAnalyticsTab  } from '../../../../../../../../../../../store/actions';
import Analytics from "../../../../../components/Analytics";
const useStyles = makeStyles((theme) => ({
  root: {
    marginTop: "auto",
    marginBottom: "auto",
  },
  illustration: {
    width: "150px",
    height: "150px",
  },
  form: {
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    "& legend": {
      float: "unset",
    },
  },
  chip: {
    margin: theme.spacing(0.5),
    maxWidth: "30px",
  },
  autoCompleteChipRoot: {
    maxWidth: "170px",
  },
  formControl: {
    // minWidth: 250,
    // maxWidth: 300,
  },
  center: {
    justifyContent: "center",
  },
  floating: {
    "& legend": {
      float: "unset",
    },
  },
}));
// const ITEM_HEIGHT = 48;
// const ITEM_PADDING_TOP = 8;

const CompareModelCollectionView = (props: TPropsFromRedux) => {
  const dispatch = useDispatch()
  const [selected, setSelected] = useState<string[]>([]);
  const {
    isAddSuccess,
    isAdding,
    // getTags,
    // addCollection,
    addErrorMessage,
    addCollectionPageInit,
    modelCollections,
    setSideDrawer,
    modelCollectionsSelected
  } = props;
  const location = useLocation();
  const {
    register,
    setValue,
    handleSubmit,
    reset,
    formState: { errors },
  } = useForm<IAddCollectionPayload>();
  const classes = useStyles();
  const history = useHistory();
  const [totalCollections, setTotalCollections] = useState<IModelCollectionsToCompare[]>()
  const [isFetchingModelCollectionsToCompareApi, setIsFetchingModelCollectionsToCompareApi] = useState<boolean>(false)
  const [notSelectedModelCollections, setNotSelectedModelCollections] = useState<IModelCollection[]>([])
  const [notSelectedModelCollectionVersion, setNotSelectedModelCollectionVersion] = useState<string[]>([])

  const query = new URLSearchParams(location.search);
  const modelId: string = String(query.get("model"));

  const isModelTypeBookTranslation = (()=>{
    if (props.modelCollections?.[0].model?.type === "bookTranslation") {
      return true;
    }
    return false;
  })();

  const isModelTypeBookTranslationRef = useRef(isModelTypeBookTranslation);
  isModelTypeBookTranslationRef.current = isModelTypeBookTranslation;

  useEffect(() => {
    if(modelCollections){
      const executeFunction = async () => {
        setIsFetchingModelCollectionsToCompareApi(true);
        const apiResponse = await DataService.fetchModelCollectionsToCompareFromDB({
            modelId: modelId
        })
        setTotalCollections(apiResponse.data)
        setIsFetchingModelCollectionsToCompareApi(false);
    }

    console.log("all model versions to compare are ::: ", props.modelCollectionsToCompare)
    
    // setTotalCollections(modelCollections)
      // props.getCollections(
      //   {
      //     model: modelId,
      //     offset: "-1",
      //     limit: "-1"
      //   }
      // );
    // }
    executeFunction();
  }}, [modelId])

  useEffect(() => {
    addCollectionPageInit();
  }, []);


  useEffect(() => {
    const discaredModelCollections = modelCollectionsSelected.filter((modelCollection) => {
      return props.modelCollectionsToCompare.every((collection) => {
        return modelCollection._id !== collection
      })
    })  
    const discaredModelCollectionVersions = discaredModelCollections.map((version)=> {
      return version.version
    })
    setNotSelectedModelCollections([...discaredModelCollections]);
    setNotSelectedModelCollectionVersion([...discaredModelCollectionVersions]);
  },[modelCollectionsSelected])
//   const onSubmit = (data: IMergeCollectionPayload): void => {

//     const selectedDatasetIdList = props.selectedModelCollections.map(
//       (item) => {
//         return { _id: item._id };
//       }
//     );
//     const query = new URLSearchParams(location.search);
//     data.model = query.get("model") || "";
//     data.parentCollections = selectedDatasetIdList;
//     console.log("submitting", data);
//     props.mergeCollection(data);
//     // addCollection(data);
//   };
// const rquery = useQuery();

  // const selectedModelVersions = props.selectedModelCollections.map(collection => collection.version);
  // const selectedModelIds = props.selectedModelCollections.map(collection => collection._id)
  const onCompareButtonClick = async () => {
    const isModelTypeBookTranslation = isModelTypeBookTranslationRef.current;
    dispatch(showAnalyticsTab())
    dispatch(collectionSelectedTab('Model Analtics'))
    let modelCollectionIds = ''
    props.modelCollectionsToCompare?.forEach((x) => {
      totalCollections?.map((model) => {
        if(x === model?.modelCollection) {
          if(modelCollectionIds?.length) {
            modelCollectionIds = modelCollectionIds+','+model?.modelCollection
          } else {
            modelCollectionIds = model?.modelCollection
          }
        }
      })
    })
    const query = new URLSearchParams(location.search);
    if (isModelTypeBookTranslation) {
      history.push(`modelAnalytics/resources?model=${query.get("model")}&type=${query.get("type")}&project=${query.get("project")}&limit=10&offset=1&status=dashboard&modelCollectionIdsToCompare=${modelCollectionIds}&modelCollectionIdFilterSelected=${props.modelCollectionsToCompare?.[0]}&scenario=integrity&accuracy=0&sortField=predicted_status&sortOrder=descending&bandFilterSelected=ALL&sortByValueFilterSelected=${"INCORRECT FIRST"}&modelCollectionidFilterSelected=${props.modelCollectionsToCompare?.[0]}`);
    } else {
      history.push(`modelAnalytics/dashboard?model=${query.get("model")}&type=${query.get("type")}&project=${query.get("project")}&status=dashboard&modelCollectionIdsToCompare=${modelCollectionIds}`);
    }
    setSideDrawer({ type: 'hideSideDrawer', component: Analytics, isOpen: false })
    //     console.log("location :::: ", location.pathname);
        // <Link to="/modelAnalytics">
        //     <ModelAnalyticsDashboard selectedModelVersions={selectedModelVersions} selectedModelIds={selectedModelIds} />
        // </Link>)
  }
  useEffect(() => {
    const query = new URLSearchParams(location.search);
    const modelId: string = String(query.get("model"));
  }, [register, setValue]);
  useEffect(() => {
    if (isAddSuccess) {
      setSelected([]);
      reset();
    }
  }, [isAddSuccess]);

  function handleAutoCompleteChange(
    event: any,
    value: any,
    reason: any,
    details: any
  ) {
    console.log(" reason and details are  :: ", reason, details);
    if (reason === "select-option") {
      const addedName = details.option;
      const objectToAdd = totalCollections?.find((item) => {
        return item.modelVersion === addedName;
      });
      if(objectToAdd ){
        // props.addSelectedModelCollections(objectToAdd);
        props.addSelectedModelCollectionsToCompare(objectToAdd.modelCollection);
        console.log(" :::::::::  ",props.modelCollectionsToCompare)
      }
    } else if (reason === "remove-option") {
      const removedName = details.option;
      const modelCollectionIdToRemove = totalCollections?.find((item) =>{
        return (item.modelVersion === removedName)
      } )
      console.log(modelCollectionIdToRemove)
      if(props.modelCollectionsToCompare && modelCollectionIdToRemove){
        const newObjectList = props.modelCollectionsToCompare.filter((item) => {
            return item !== modelCollectionIdToRemove.modelCollection;
        });
        const newModelList = newObjectList.map((item) => {
          return item
        })
        // props.setSelectedCollections(newObjectList);
        props.setSelectedModelCollectionsToCompare(newModelList);
      }
      // console.log(newObjectList)
    }
  }

  console.log(`selectedModelCollections: ${props.modelCollectionsToCompare}`)
  return (
    <Grid
      classes={{ root: classes.root }}
      container
      direction="column"
      alignItems="center"
      justify="center"
    >
      {/* <Collection className={classes.illustration} /> */}
      <Tooltip title="Compare Selected Model Collections">
        <CompareIcon color="primary" style={{ fontSize: "150px"}}></CompareIcon>
      </Tooltip>
      <Box m={1.5} />
      <Typography component="h3" variant="h5">
        Compare Model Collections
      </Typography>
      <Box m={1.5} />
      <form
        className={classes.form}
        // onSubmit={onCompareButtonClick}
        noValidate
      >
        <Autocomplete
          multiple
          id="comapreModels"
          freeSolo
          // options={props.resourceListInCollection.map((item) => item.name)}
          options={totalCollections?.map((item) => item.modelVersion) || []}  
          // value={(totalCollections && totalCollections?.length > 0 && props?.modelCollectionsToCompare) ? (props?.modelCollectionsToCompare?.map((item, index) => {
          //   return (totalCollections && totalCollections[index].modelCollection === item ? totalCollections[index].modelVersion : "")
          // })) 
          // : [""]}
          value = {isFetchingModelCollectionsToCompareApi ? new Array(1).fill("").map((item, index) => (
              "Loading ..."))
          :  props.modelCollectionsToCompare.map((item) => {
              const modelCollection = totalCollections && totalCollections.find((modelCollection) => {
                return (modelCollection.modelCollection === item)})
              return modelCollection ? modelCollection?.modelVersion : ""
          })}
          // value={totalCollections?.map((item) => item.name)}
          // loading={isGetDataSetCollectionUniqueNamesApiExecuting ? true : false}
          // loadingText={
          //   isGetDataSetCollectionUniqueNamesApiExecuting ? "loading" : ""
          // }
          onChange={(event, value, reason, details) => {
            handleAutoCompleteChange(event, value, reason, details);
          }}
          renderTags={(value, getTagProps) => {
            return isFetchingModelCollectionsToCompareApi ?
            new Array(props.modelCollectionsToCompare.length).fill("").map((item, index) => (
              <Chip
              label = {<CustomSkeleton height={30} width={40}/>}
              className={`${classes.chip}`}
              variant="outlined"
              style={{minWidth: '60px'}}
              color="primary"/>
            )):
            value.map((option, index) => (
              <Chip
                variant="outlined"
                color="primary"
                className={`${classes.chip}`}
                classes={{ root: classes.autoCompleteChipRoot }}
                label={
                  <Tooltip title={option}>
                    <span>{option}</span>
                  </Tooltip>
                }
                {...getTagProps({ index })}
              />
            ))
            ;
          }}
          renderInput={(params) => (
            <TextField
              {...params}
              variant="outlined"
              size="small"
              margin="normal"
              fullWidth
              className={classes.floating}
              label="Compare Model Collections"
              placeholder="Add Model Collections"
              onChange={(e) => {}}
              onBlur={(e) => {}}
              error={false}
              helperText={
                "Add multiple model collections with <enter> or <comma>"
              }
            />
          )}
        />

        <Box m={1.5} />
        {addErrorMessage && (
          <Box style={{ marginBottom: 10, color: "red" }}>
            {addErrorMessage}
          </Box>
        )}
        {console.log("notSelectedModelCollections notSelectedModelCollections :::: ",notSelectedModelCollections)}
        {
          notSelectedModelCollections.length > 0 && 
          <Box style={{ marginBottom: 10, color: "red" }}>
          <span style={{fontSize: '13px', color: 'red'}}>
            Can't compare following versions due to insufficient data :  
            <span> {notSelectedModelCollectionVersion.join(" , ")}</span>
          </span>
          </Box>
        }
        {
        <Tooltip title={isFetchingModelCollectionsToCompareApi ? "Fetching Model Versions to Compare" : props.modelCollectionsToCompare.length < 1 ? "Please select at least 1 model to compare or analyse" : "Compare"}>
            <div style={{width: "100%"}}>
                <CustomButton onClick={onCompareButtonClick} type="button" 
                disabled={isFetchingModelCollectionsToCompareApi || isAdding || props.modelCollectionsToCompare.length < 1} style={{width: "100%"}}
                >
                {isAdding ? <CircularProgress size={20} /> : "Compare Models"}
                </CustomButton>
            </div>
        </Tooltip>
        }
        {/* <Separator width="50%" />
            <CustomButton size="small" disabled>Train Models</CustomButton> */}
      </form>
      <Box m={1.5} />
    </Grid>
  );
};

const mapProps = (state: IReduxState) => {
  return {
    // tags: state.tag.tags,
    // isTagsFetching: state.tag.isFetching,
    isAdding: state.collection.isAdding,
    isAddSuccess: state.collection.isAddSuccess,
    addErrorMessage: state.collection.addErrorMessage,
    resourceListInCollection: state.collection.collections.collections,
    selectedModelCollections: state.collection.modelCollection.modelCollectionsSelected,
    modelCollections: state.collection.modelCollection.modelCollections,
    modelCollectionsToCompare : state.collection.modelCollection.modelCollectionsSelectedToCompare,
    modelCollectionsSelected : state.collection.modelCollection.modelCollectionsSelected
  };
};

const connector = connect(mapProps, {
  // addCollection,
  // getTags,
  setSideDrawer,
  addCollectionPageInit,
  setSelectedCollections: setSelectedModelCollections,
  addSelectedModelCollections,
  mergeCollection,
  setSelectedModelCollectionsToCompare,
  addSelectedModelCollectionsToCompare
});

export type TPropsFromRedux = ConnectedProps<typeof connector>;

export default connector(CompareModelCollectionView);

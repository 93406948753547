import { Checkbox } from '@material-ui/core';
import React, { useState } from 'react'

function IsPiiDataCheckBox(props: {
    textId: string,
    update: Function,
    label: string,
    isPiiData ?: boolean
    }) {
        
    const [isPiiDataChecked, setIsPiiDataChecked] = useState<boolean>(props?.isPiiData || false);
    return (
        <div>
            <Checkbox 
                    checked ={isPiiDataChecked === true ? true : false}
                    disabled = {props.label.length > 0 ? false : true}
                    onChange={(e) => {
                        if(e.target.checked){
                            setIsPiiDataChecked(true)
                            props.update(true, props.textId);
                        }else{
                            setIsPiiDataChecked(false)
                            props.update(false, props.textId);
                        }
                    }}
                    />
        </div>
    )
}

export default IsPiiDataCheckBox
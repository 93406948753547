import { useContext } from 'react';
import ChatContext from '../../../../chat/contexts/chat.context';
import { ReactComponent as RightArrow } from '../assets/rightArrow.svg';
import { NavigateNext } from '@mui/icons-material';
import './Header.css'

function Header() {
	const chatContext = useContext(ChatContext);
    const {setModelCloningStage, switchToPreviousPageInWorkModeStack, updateTestDriveStage, isTestDriveCompleted, workModePageStack, modelCloningStage, setShouldShowTestDriveResultScreen} = chatContext;
	const currentPageData = workModePageStack[workModePageStack.length-1];
	const shouldShowBreadCrumbs = ['test_drive', 'summary_page'].includes(currentPageData.aiResponseMetaData?.pageType || '')

	const breadCrumbs = [
	{
		text : 'Upload or browse data',
		onClick : ()=>{
			setModelCloningStage('')
			switchToPreviousPageInWorkModeStack()
		}
	},
	{
		text : 'Results',
		onClick : ()=>{
			setModelCloningStage('')
			setShouldShowTestDriveResultScreen(true)
			switchToPreviousPageInWorkModeStack()
		}
	},
	{
		text : 'Clone',
		onClick : ()=>{}
	}];

	const getCloneHeaderText = ()=>{
		const trainingPodName = currentPageData?.aiResponseMetaData?.trainingPods[0]?.training_pod_name || ''
		return `Clone "${trainingPodName}"`
	}
	
	return (
		<div className='header'>
			<div className='header__text'>{getCloneHeaderText()}</div>
			{shouldShowBreadCrumbs && <div className='bread__crumbs' style={{pointerEvents: modelCloningStage==='processing' ? 'none' : 'auto'}}>
				{isTestDriveCompleted && breadCrumbs.map((item, index)=>
				<>
					<div className='crumb' style={{fontWeight: index === breadCrumbs.length-1 ? 600 : 400}} onClick={item.onClick}>
						{item.text}
					</div>
					{index !== breadCrumbs.length-1 ? <NavigateNext /> : null}
				</>)}
			</div>}
		</div>
	)
}

export default Header
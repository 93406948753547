import { ReactComponent as DeleteAnnotationIcon } from "../../../../../../../../../../../assets/svg/Icon - Delete.svg";
import { ReactComponent as UndoAnnotationDeleteIcon } from "../../../../../../../../../../../assets/svg/Icon - Undo Deleting of annotation.svg";
import { ReactComponent as VideoAnnotationPopupInfoIcon } from "../../../../../../../../../../../assets/svg/Icon awesome-info-circle.svg";
import  Screenshot from "../../../../../../../../../../../assets/screenshotImage/ScreenShot.png";
import {
  Box,
  Button,
  CircularProgress,
  Dialog,
  IconButton,
  Theme,
  Tooltip,
  Typography,
  TextField,
  Popover,
  withStyles,
  useMediaQuery,
  ClickAwayListener,
  Menu,
  MenuItem,
  List,
  ListItem,
  Divider,
  ListItemText,
  Link
} from "@material-ui/core";
import css from "./VideoAnnotationDialogBox.module.css";
import InfoOutlinedIcon from '@material-ui/icons/InfoOutlined';
import { Cancel } from "@material-ui/icons";
import CheckIcon from '@material-ui/icons/Check';
import { makeStyles } from "@material-ui/styles";
import React, { useEffect, useRef, useState, useMemo } from "react";
import { connect, ConnectedProps, useDispatch, useSelector } from "react-redux";
import { IData, ImageList, IReduxState, ModelAnnotationLabelAvailable, ResourceStatus, videoAnnotation } from "../../../../../../../../../../../common";
import NavigateBeforeIcon from '@material-ui/icons/NavigateBefore';
import NavigateNextIcon from '@material-ui/icons/NavigateNext';
import PlayArrowRoundedIcon from '@material-ui/icons/PlayArrowRounded';
import SkipNextRoundedIcon from '@material-ui/icons/SkipNextRounded';
import SkipPreviousRoundedIcon from '@material-ui/icons/SkipPreviousRounded';
import SlowMotionVideoIcon from '@material-ui/icons/SlowMotionVideo';
import PauseRoundedIcon from '@material-ui/icons/PauseRounded';
import ZoomInIcon from '@material-ui/icons/ZoomIn';
import ZoomOutIcon from '@material-ui/icons/ZoomOut';
import Slider from '@material-ui/core/Slider';
import LabelEditText from "./LabelEditText";
import { copyByValue, isNullOrUndefined } from "../../../../../../../../../../../services/variableHelperService";
import ReplayIcon from '@material-ui/icons/Replay';
import { ReactComponent as IncrementIcon } from '../../../../../../../../../../../assets/svg/CTA +1s.svg';
import { ReactComponent as DecrementIcon } from '../../../../../../../../../../../assets/svg/CTA -1s.svg';
import { ReactComponent as PlayPauseIcon } from '../../../../../../../../../../../assets/svg/Play Pause.svg';
import { ReactComponent as NextResourceIcon } from '../../../../../../../../../../../assets/svg/Next.svg';
import { ReactComponent as PreviousResourceIcon } from '../../../../../../../../../../../assets/svg/Previous.svg';
import { FileNameComponent, ShowResourceDetails, TagComponent } from "./ImageKeyPointsEditDialog";
import { InputFieldState } from "../../../../../../../../../../../common/constants/interfaces/inputFieldState";
import { updateResourceInUI, updateResourceRemarks, updateResourceVideoAnnotationsInDB } from "../../../../../../../../../../../store/actions";
import FileNameClipper from "../../../../../../../../../../../common/components/FileNameClipper";
import TagEditText from "./TagEditText";
import ExpandLessIcon from '@material-ui/icons/ExpandLess';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import { AiAssistantTooltip, getLabelsOfVideoAnnotationResourceThatAreNotApprovedOrRejectedByExpert } from "./TableContent";
import useForceReRender from "../../../../../../../../../../../hooks/useForceReRender";
import { themePrimaryMainColor } from "../../../../../../../../../../../theme";
import CloseIcon from '@material-ui/icons/Close';
import { useForm } from 'react-hook-form';
import { CustomButton } from '../../../../../../../../../../../common';
import InfoIcon from '@material-ui/icons/Info';
import { generateUniqueId } from "../../../../../../../../../../../services/idHelperService";
import { Autocomplete } from "@material-ui/lab";
import ViewVideoDialog from "./ViewVideoDialog";
import { DataService } from "../../../../../../../../../../../services";
import html2canvas from "html2canvas";
import SkipNextIcon from '@material-ui/icons/SkipNext';
import SkipPreviousIcon from '@material-ui/icons/SkipPrevious';
import FullscreenIcon from '@material-ui/icons/Fullscreen';
import ViewAsCSVDialog from "./ViewAsCSVDialog";
import { VisitParentResourceLink } from "./visitParentResourceLink/visitParentResourceLink";
import { useResourceMetadataDownloadUrlFromResourceId } from "../hooks/useResourceMetadataDownloadUrlFromResourceId";
import { LABEL_GROUP_RESOURCE_LEVEL, LABEL_GROUP_TASK_LEVEL } from "../constants/constants";

const CORRECT_ANNOTATION_OPACITY_WHEN_NOT_HOVERING = 0.8

function isAnyExpertAnnotationCollidingAtThisAnnotationTime(startTimeToCheck: number, endTimeToCheck: number, videoAnnotations: IData["videoAnnotations"]): boolean {
  // TODO: Needs to fix it
  if (videoAnnotations && videoAnnotations?.length > 0) {
    for (const videoAnnotation of videoAnnotations) {
      if (videoAnnotation?.annotationByExpert) {
        if (
          (
            videoAnnotation?.annotationByExpert?.startTimeInSeconds >= startTimeToCheck &&
            videoAnnotation?.annotationByExpert?.startTimeInSeconds <= endTimeToCheck 
          ) ||
          (
            videoAnnotation?.annotationByExpert?.endTimeInSeconds >= startTimeToCheck &&
            videoAnnotation?.annotationByExpert?.endTimeInSeconds <= endTimeToCheck 
          ) ||
          (
            videoAnnotation?.annotationByExpert?.startTimeInSeconds <= startTimeToCheck &&
            videoAnnotation?.annotationByExpert?.endTimeInSeconds >= endTimeToCheck
          )
        ) {
          return true
        }
      }
    }
  }
  return false;
}

const useStyles = makeStyles((theme: Theme) => ({
  root: {
    overflow: "auto",
    padding: "20px 0px",
    "&p": {
      padding: "0px",
    },
  },
  aiAssistantConfirmationPopupCloseIcon: {
    color: "#b4b2b2",
    fontSize: "16px",
    cursor: "pointer",
    marginTop: "5px",
    marginRight: "10px"
  },
  aiAssistantConfirmationHeader: {
    textAlign: "end",
    marginBottom: "-11px",
    backgroundColor: "white",
    width: "100%"
  },
  aiAssistantHasHelped: {
    color: "#838C95",
    font: "normal normal normal 14px/20px Nunito Sans",
    letterSpacing: "0px",
    height: "20px",
    display: "flex",
    alignItems: "center",
    columnGap: "5px"
  },
  visibilityHidden: {
    visibility: "hidden",
  },
  displayNone: {
    display : "none"
  },
  body: {
    padding: "0px 10px 10px 10px",
    marginTop: "-18px",
    width: "100%",
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
  },
  showMoreOrshowLessStyles: {
    fontSize: "15px",
    textAlign: "left",
    cursor: "pointer",
    color: theme.palette.primary.main,
    fontFamily: "Nunito Sans",
    width: "135px",
    // fontWeight: "400",
  },
  editText: {
    backgroundColor: 'transparent !important',
    textAlign: "left !important" as "left",
    padding: "0px 0px 0px 5px !important",
    marginTop: "0px !important",
    marginLeft: "-6px !important"
  },
  details: {
    minWidth: '150px',
    display: 'inline-block',
    marginBottom: '8px',
    font: "normal normal normal 15px/20px Nunito Sans",
    // fontSize: 'smaller'
  },
  content: {
    // marginRight: "20px",
    marginLeft: "20px",
    // display: "grid",
    // gridAutoFlow: "column",
    display: "flex",
    flexWrap: "wrap",
    justifyContent: "center",
    gap: "40px",
  },
  arrowDown: {
    position: "relative",
    // marginBottom: "10px",
    "&::before": {
      backgroundColor: "white",
      content: '""',
      display: "block",
      position: "absolute",
      width: 12,
      height: 12,
      bottom: -6,
      transform: "rotate(45deg)",
      left: "calc(50% - 6px)"
    }
  },
  aiAssistantTooltip: {
    backgroundColor: "white",
    color: "black",
    padding: "20px",
    boxShadow: "5px 5px 20px #028d9a1a",
    border: "1px solid #c7cfd5",
    borderRadius: "10px",
  },
  aiAssistantTooltipArrow: {
    '&::before': {
      background: "white !important",
      border: "1px solid #c7cfd5",
    },
  },
  sideBar: {
    width: "400px",
    marginLeft: "90px",
    // marginTop: "30px"
    marginTop: "47px"
  },
  topMenu: {
    display: "flex",
    justifyContent: 'space-between',
    width: "440px",
  },
  topBarLabel: {
    // marginLeft: "20px",
    // width: "800px",
    marginBottom: "20px",
  },
  footer: {
    // marginTop: "25px",
    display: "flex",
    justifyContent: "space-between",
  },
  closeBtn: {
    position: "absolute",
    right: 30,
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    top: 15,
    backgroundColor: "white",
    borderRadius: "50%",
    cursor: "pointer",
    width: "25px",
    height: "25px"
  },
  // dialogContainer: {
  //   overflow: 'visible',
  //   height: "752.41px"
  //   // width: "62%",
  //   // minWidth: "1200px"
  // },
  videoBtns: {
    display: "flex",
   
    flexDirection:"column",
    // alignItems: "end",
   
    // left: "100px",
  },
  videoIcons:{
    display:"flex",
    flexDirection:"row" , 
    justifyContent: "space-between", 
    marginBottom: "2px",
    position: "relative",
    gap:"8px"
  },
  slider: {
    margin: "5px 0",
    marginTop: "12px",
    // marginLeft: "20px",
    width: "550px",
    position: "relative"
  },
  navigateButton: {
    padding: "0px",
    "&:hover": {
      outline: "none",
    },
    "&:focus": {
      outline: "none",
    },
    "&:active": {
      outline: "none",
    },
  },
  endTime: {
    color: "#98A6B3",
    // position: "absolute",
    // transform: "translateX(20px)",
    whiteSpace: "nowrap",
    fontSize: "13px",
  },
  startTime: {
    // position: "absolute",
    // transform: "translateX(-150%)",
    color: "#98A6B3",
    fontSize: "13px",
    // marginRight: "480px"
  },
  primaryColor: {
    color: theme.palette.primary.main
  },
  containerForAnnotationButtonCSS: {
    display: "flex",
    columnGap: "10px",
    justifyContent: "space-around"
  },
  annotationContainer: {
    position: "relative",
    marginBottom: "55px",
    marginTop: "30px",
    height: "35px",
    borderRadius: "4px",
    backgroundColor: "#f0f0f0"
  },
  verticalBar: {
    width: "2px",
    height: "14px",
    backgroundColor: "white",
  },
  annotationEndDragHandle: {
    display: "flex",
    // justifyContent: "space-evenly",
    // gap: "1px",
    width: "10px",
    height: "100%",
    alignItems: "center",
    // cursor: "grab",
    // marginLeft: "2.5px",
    // marginRight: "1px"
  },
  annotationBeginningDragHandle: {
    display: "flex",
    // justifyContent: "space-evenly",
    // gap: "1px",
    width: "10px",
    height: "100%",
    alignItems: "center",
    // cursor: "grab",
    // marginLeft: "3px",
    // marginRight: "1px"
  },
  cursorGrab: {
    cursor: "grab"
  },
  anotationDragHandleGrabbedByUser: {
    cursor: "grabbing"
  },
  videoAnnotation: {
    backgroundColor: "#008D9C",
    opacity: "0.47",
    height: "34px",
    borderRadius: "4px",
    position: "absolute",
    display: "flex",
    justifyContent: "space-between",
    // "&:hover": {
    //   // backgroundColor: "#008D9C"
    //   opacity: "1",
    // },
    transition: "opacity 0.35s ease-in-out",
  },
  // videoAnnotationTime: {
  //   position: "absolute",
  //   // display: "flex",
  //   // marginBottom: "4px",
  //   justifyContent: "space-between",
  // },
  videoAnnotationLabel: {
    position: "absolute",
    display: "flex",
    justifyContent: "center",
    marginTop: "9px"
  },
  newVideoAnnotation: {
    backgroundColor: "#FF9131"
  },
  buttons: {
    marginTop: "25px",
    display: "flex",
    justifyContent: "space-evenly",
    // columnGap: "2px"
  },
  textStyleForVideoAnnotation: {
    font: "normal normal normal 13px/20px Nunito Sans",
    color: "#8e969e"
  },
  textStyleForWarning: {
    marginTop: "10px",
    font: "normal normal normal 10px/20px Nunito Sans",
    color: "#8e969e"
  },
  form: {
    width: '100%', // Fix IE 11 issue.
    marginTop: theme.spacing(1),
    "& legend": {
      float: "unset"
    }
  },
  helperText: {
    marginBottom: '0px',
    marginLeft: '2px',
  },
  playbackSpeedCustomBtns: {
    border: '3px solid #008D9C',
    borderRadius: '50%',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    borderStyle: 'solid solid dashed dashed',
    transform: 'rotateZ(45deg)',
    "& span": {
      transform: 'rotateZ(-45deg)'
    }
  },
  popOver: {
    "& .MuiPaper-root": {
      background: "rgb(61 59 59 / 70%)",
      color: "#ffff"
    },
    "& .MuiListItem-button:hover": {
      background: "rgb(0 0 0 / 19%)",
      color: "#ffff"
    },
  }
}));

export interface VideoResource {
  videoSrc: string,
  _id: string
}

interface IProps extends TPropsFromRedux {
  open: boolean;
  handleClose: () => void;
  resource: IData;
  onMoveToRight: () => void;
  onMoveToLeft: () => void;
  setShouldBlockDataTableKeyboardEvents: Function;
  LabelsAvailable: string[];
  allowUserToSetAnyLabel: boolean;
  isFetchingResources: boolean;
  ActionButtonArray: any;
  aiAssistantToolip: AiAssistantTooltip;
  resourceIndex: number
  annotationLabelsAvailable:ModelAnnotationLabelAvailable[]
}

/**
 * @description mt stands for marginTop
 */
export const AiAssistantMsgWithInfoIcon = (props: { aiAssistantLastCalledAt: boolean, mt?: string, ml?: string, mb?: string }) => {
  const classes = useStyles();
  const { aiAssistantLastCalledAt, mt, ml, mb } = props;

  return (
    <div 
      className={`${classes.aiAssistantHasHelped} ${!aiAssistantLastCalledAt ? classes.displayNone : ""}`}
      style={{ marginTop: mt ? mt : "0px", marginLeft: ml ? ml : "0px", marginBottom: mb ? mb : "0px" }}  
    >
      <InfoOutlinedIcon style={{ color: "inherit", width: "16px", height: "16px" }} /> AI Assistant has helped
    </div>
  );
}

interface ApproveAndRejectIconComponentProps extends React.SVGProps<SVGSVGElement> {
  status: "approved" | "rejected" | null;
  colorToShow: string;
}

const ApproveIcon = (props: ApproveAndRejectIconComponentProps) => {
  const { status, colorToShow } = props;

  return (
    <Tooltip title={status === "approved" ? `disabled because already approved the annotation` : ""}>
      <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 30 30" {...props}>
        <path id="Approve" d="M19.157.009a2.674,2.674,0,0,0-2.014,1.457c-.557,1.114-4.671,9.382-5.486,10.2a4.234,4.234,0,0,1-3.086,1.2V30h15a2.219,2.219,0,0,0,2.014-1.328S30,16.2,30,15a2.122,2.122,0,0,0-2.143-2.142H21.429a2.206,2.206,0,0,1-2.143-2.142A68.665,68.665,0,0,1,21.3,2.836a2.157,2.157,0,0,0-1.329-2.7,1.808,1.808,0,0,0-.814-.129ZM0,12.861V30H4.286V12.861Z" transform="translate(0 0.003)" fill={colorToShow} />
      </svg>
    </Tooltip>
  );
};


const RejectIcon = (props: ApproveAndRejectIconComponentProps) => {
  const { status, colorToShow } = props;

  return (
    <Tooltip title={status === "rejected" ? `disabled because already rejected the annotation` : ""}>
      <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 30 30" {...props}>
        <path id="Approve" d="M19.157.009a2.674,2.674,0,0,0-2.014,1.457c-.557,1.114-4.671,9.382-5.486,10.2a4.234,4.234,0,0,1-3.086,1.2V30h15a2.219,2.219,0,0,0,2.014-1.328S30,16.2,30,15a2.122,2.122,0,0,0-2.143-2.142H21.429a2.206,2.206,0,0,1-2.143-2.142A68.665,68.665,0,0,1,21.3,2.836a2.157,2.157,0,0,0-1.329-2.7,1.808,1.808,0,0,0-.814-.129ZM0,12.861V30H4.286V12.861Z" transform="translate(0 0.003)" fill={colorToShow} />
      </svg>
    </Tooltip>
  );
};

const ShowMoreFieldsComponent = (props: { toggleShowMore: React.MouseEventHandler<HTMLDivElement>, showMoreFields: boolean, mT: number }) => {
  const classes = useStyles();

  const { toggleShowMore, showMoreFields, mT } = props;

  return (
    <div className={classes.showMoreOrshowLessStyles} onClick={toggleShowMore}>
      {
        showMoreFields
          ?
          <div style={{ marginTop: mT }}>
            <span >Show Less Info</span>
            <ExpandLessIcon></ExpandLessIcon>
          </div>
          :
          <>
            <span>Show More Info</span>
            <ExpandMoreIcon></ExpandMoreIcon>
          </>
      }
    </div>
  )
}


const trimAnnotationLabel=(annotationWidth: number | null, label: string)=>{
  const labelWidth= annotationWidth as number
  let labelLength=Math.round(labelWidth<0?(-2*labelWidth)+labelWidth:labelWidth-1);
 
  labelLength=labelLength<=0?1:labelLength;
  if(labelLength>label.length){
      return label
  }

  const trimmedLabel=label?.slice(0,labelLength)
  const formatLabel=trimmedLabel.length===1?trimmedLabel:trimmedLabel.length===2?trimmedLabel?.slice(0,1)+'..':
  trimmedLabel?.length>=3?trimmedLabel.slice(0,trimmedLabel.length-2)+'..':trimmedLabel; 
  return formatLabel;
}

const AnnotationLabelComponent = (props: { annotationPositionLeft: number | null, annotationWidth: number | null, label: string, classNames?: string }) => {
  const classes = useStyles();
  const { annotationPositionLeft, annotationWidth, label } = props;

  return (
    <div className={`${classes.videoAnnotationLabel} ${props.classNames || ""} `} style={{ left: `${annotationPositionLeft}%`, width: `${annotationWidth}%`, top: "30px", whiteSpace: "nowrap" }} >
      {/* <div className={`${classes.videoAnnotationLabel}`} style={{ left: `${annotationPositionLeft}%`, top: "30px" }} > */}
      <Tooltip title={label}>
      <Typography className={classes.textStyleForVideoAnnotation} >{trimAnnotationLabel(annotationWidth,label)}</Typography>
      </Tooltip>
      
    </div>
  );
}

type DeleteAnnotationConfirmationTooltipProps = {
  open: boolean,
  message: string,
  children: JSX.Element,
  deleteButtonText: string,
  onClose: () => any;
  onDeleteButtonClick: ()=>any
}

const DeleteAnnotationConfirmationTooltip = (props: DeleteAnnotationConfirmationTooltipProps) => {
  return (
    <Tooltip
      classes={{
        tooltip: css.deleteAnnotationConfirmationPopupTooltip,
        arrow: css.deleteAnnotationConfirmationPopupTooltipArrow
      }}
      interactive={true}
      disableFocusListener
      disableHoverListener
      disableTouchListener
      placement='top'
      arrow
      open={props.open}
      title={
        <ClickAwayListener
          onClickAway={()=>{
            props.onClose();
          }}
        >
          <div
            className={css.deleteAnnotationConfirmationPopup}
          >
            <div className={css.deleteAnnotationConfirmationPopupHeader}>
                <CloseIcon classes={{root: css.deleteAnnotationConfirmationPopupCloseIcon}} 
                    onClick={()=>{
                        props.onClose();
                    }}
                />
            </div>
            <div className="deleteAnnotationConfirmationPopupMessageBody">
                <div className={css.deleteAnnotationConfirmationPopupMessage}>
                    {props.message}
                </div>
            </div>
            <div className={css.deleteAnnotationConfirmationPopupFooter}>
                <Button variant="contained" size="medium" style={{
                  backgroundColor: "#EF8A8A", 
                  color: "white", 
                  width: 
                    props.deleteButtonText?.length >= 14
                    ? "131px"
                    : "104px", 
                  height: "30px", fontSize: "11px"}}
                    onClick={async ()=>{
                      props.onDeleteButtonClick();
                    }}
                >{props.deleteButtonText}</Button>
            </div>            
          </div>
        </ClickAwayListener>
      }
    >{props.children}</Tooltip>
  )
}

const AnnotationTagComponent = (props: { annotationPositionLeft: number | null, annotationWidth: number | null, tag: string, show: boolean }) => {
  const classes = useStyles();
  const { annotationPositionLeft, annotationWidth, tag, show } = props;

  return (
    <div className={`${classes.videoAnnotationLabel} ${css.annotationTag}`} style={{ left: `${annotationPositionLeft}%`, width: `${annotationWidth}%`, top: "50px", display: show ? "flex" : "none", whiteSpace: "nowrap" }} >
      <Typography className={classes.textStyleForVideoAnnotation} >Tag:&nbsp;</Typography>
      <Typography style={{ font: "normal normal normal 13px/20px Nunito Sans" }}>{tag}</Typography>
    </div>
  );
}



const AnnotationStartTimeAndEndTimeComponent = (props: { annotationPositionLeft: number | null, annotationWidth: number | null, show: boolean, startTimeInSeconds: number, endTimeInSeconds: number, 
  deleteButton?: {shouldShow: boolean, onClick: () => any, shouldShowItAsReset: boolean}, 
  deleteUndoButton?: {shouldShow: boolean, onClick: () => any} 
}) => {
  const classes = useStyles();
  const { annotationPositionLeft, annotationWidth, startTimeInSeconds, endTimeInSeconds, show } = props;

  return (
    <div
      className={`
        ${css.videoAnnotationTime}
      `}
      // style={{ left: `${annotationPositionLeft! - 4}%`, width: `${annotationWidth! + 7}%`, bottom: "39px", display: show ? "flex" : "none", whiteSpace: "nowrap", gap: annotationWidth! < 9 ? 5 : 0 }}
      // style={{ left: `${annotationPositionLeft! - 6}%`, width: `${annotationWidth! + 11}%`, bottom: "39px", display: show ? "flex" : "none", whiteSpace: "nowrap", gap: annotationWidth! < 9 ? 5 : 0 }}
      style={{ left: `${(annotationPositionLeft! - 6 - (annotationWidth! < 5 ? 3 : 0))}%`, width: `${annotationWidth! + 11}%`, minWidth:"19%", bottom: "39px", display: show ? "flex" : "none", whiteSpace: "nowrap", gap: annotationWidth! < 9 ? 5 : 0 }}
    >
      <Typography className={classes.textStyleForVideoAnnotation} >{convertSecondsIntoMinAndSecFormat(startTimeInSeconds)}</Typography>
      {
        props.deleteButton?.shouldShow
        ? props.deleteButton?.shouldShowItAsReset
          ? <UndoAnnotationDeleteIcon
                style={{
                  cursor: "pointer"
                }} 
                onClick={()=>props.deleteButton?.onClick()}           
            />
          : <DeleteAnnotationIcon
              style={{
                cursor: "pointer"
              }} 
              onClick={()=>props.deleteButton?.onClick()}
            />
          : <></>
      }
      {
        props.deleteUndoButton?.shouldShow &&
        <Tooltip
          title="Mark the detected annotation as undelete"
        >
          <UndoAnnotationDeleteIcon 
            style={{
              cursor: "pointer"
            }}
            onClick={()=>props.deleteUndoButton?.onClick()}
          />
        </Tooltip>
      }
      <Typography className={classes.textStyleForVideoAnnotation} >{convertSecondsIntoMinAndSecFormat(endTimeInSeconds)}</Typography>
    </div>
  )
}

const CancelButton = (props: { cancelAnnotation: () => void, arevideoAnnotationsBeingUpdatedInDB: boolean, editAnnotationMode: boolean }) => {
  const { cancelAnnotation, arevideoAnnotationsBeingUpdatedInDB, editAnnotationMode } = props;

  return (
    <Button
      style={{ width: "150px", paddingTop: "8px", paddingBottom: "8px", font: "normal normal normal 13px/20px Nunito Sans", textTransform: "none" }}
      size="small"
      color="primary"
      variant={editAnnotationMode ? "outlined" : "text"}
      onClick={cancelAnnotation}
      disabled={arevideoAnnotationsBeingUpdatedInDB}
    >
      Cancel
    </Button>
  )
};

const convertSecondsIntoMinAndSecFormat = (seconds: number = 0) => {
  const currentMinutes = Math.floor(seconds / 60);
  const currentSeconds = Math.ceil(seconds % 60);

  const min_UI = currentMinutes.toString().padStart(2, '0');
  const sec_UI = currentSeconds.toString().padStart(2, '0');

  return `${min_UI} : ${sec_UI}`;
}

const SavePopup = (props: {annotationLabelsAvailable:ModelAnnotationLabelAvailable[], closeSavePopup: () => void, setVideoAnnotations: Function, keepOpenSavePopup: () => void, crossIconOfSavePopupClickHandler: () => void, enableEditMode: () => void, setAddingAnnotationMode: Function }) => {
  const classes = useStyles();
  const { closeSavePopup, setVideoAnnotations,annotationLabelsAvailable, keepOpenSavePopup, crossIconOfSavePopupClickHandler, enableEditMode, setAddingAnnotationMode } = props;
  const { register, handleSubmit, setError, clearErrors, formState: { errors } } = useForm();

  const updateNewVideoAnnoationLabelAndTagStateClickHandler = (data: { label: string, tag: string }) => {
    if (data.label && data.tag) {
      setVideoAnnotations((prevState: videoAnnotation[]): videoAnnotation[] => {
        const newState: videoAnnotation[] = copyByValue(prevState);
        newState[newState.length-1].label = data.label;
        newState[newState.length-1].tag = data.tag;
        return newState;
      });
      setAddingAnnotationMode((prevState: IAddingAnnotationMode): IAddingAnnotationMode  => {
        return {
          ...prevState,
          status: "editing"
        }
      });
      enableEditMode();
      closeSavePopup();
    }
  }

  return (
    // <ClickAwayListener onClickAway={keepOpenSavePopup}>
      <div>
        <div className={classes.aiAssistantConfirmationHeader}>
          <Tooltip title={"you will lose your newly generated annotation"}>
            <CloseIcon classes={{ root: classes.aiAssistantConfirmationPopupCloseIcon }} onClick={crossIconOfSavePopupClickHandler} />
          </Tooltip>
        </div>
        <div style={{ backgroundColor: "white", paddingLeft: "25px", paddingRight: "25px", paddingBottom: "30px", width: "100%" }}>
          <form className={classes.form} onSubmit={handleSubmit(updateNewVideoAnnoationLabelAndTagStateClickHandler)} noValidate>
            <Typography className={classes.textStyleForVideoAnnotation} >Label</Typography>
            <Autocomplete   renderInput={(params)=><TextField
             {...params}
              style={{ marginTop: "4px" }}
              variant="outlined"
              margin="normal"
              required
              fullWidth
              id="newVideoAnnotationLabel"
              size="small"
              autoFocus
              {...register("label", {
                required: "Label is required",
              })}
              error={Boolean(errors?.label)}
              helperText={errors?.label?.message}
              FormHelperTextProps={{
                classes: { root: classes.helperText },
              }}
              InputProps={{
                ...params.InputProps,
                endAdornment: <React.Fragment />, // Empty endAdornment
              }}
            />}  options={annotationLabelsAvailable.map((label)=>label.value)}/>
            

            <Typography className={classes.textStyleForVideoAnnotation} style={{ marginTop: "5px" }} >Tag</Typography>
            <TextField
              style={{ marginTop: "4px" }}
              variant="outlined"
              margin="normal"
              required
              fullWidth
              id="newVideoAnnotationTag"
              size="small"
              {...register("tag", {
                required: "Tag is required",
              })}
              error={Boolean(errors?.tag)}
              helperText={errors?.tag?.message}
              FormHelperTextProps={{
                classes: { root: classes.helperText },
              }}
            />
            {errors?.invalid ? <Typography component="p" variant="body1" color="error">{errors?.invalid?.message}</Typography> : null}
            <CustomButton fullWidth type="submit" style={{ marginTop: "10px" }} >SAVE</CustomButton>

            <div style={{ display: "flex", alignItems: "center" }}>
              {/* <InfoIcon style={{ fontSize: "20px", marginTop: "8px" }} /> */}
              <VideoAnnotationPopupInfoIcon style={{ height: "17px", marginTop: "8px" }} />
              <Typography className={classes.textStyleForWarning} > &nbsp;Please note, these can't be changed post saving</Typography>
            </div>
          </form>
        </div>
      </div>
    // </ClickAwayListener>
  )
}

interface IAddingAnnotationMode {
  isOn: boolean;
  status: "yetToStartAdding" | "annotating" | "editing" | "";
}

const AnnotationContextMenu = (props: {
  anchorEl: Element | undefined, 
  setAnchorEl: React.Dispatch<React.SetStateAction<Element | undefined>>,
  onSendtoModel: (videoAnnotation: videoAnnotation, startTime: number, endTime: number) => void,
  selectedResource: IData,
  startTime: number,
  endTime: number,
  selectedVideoAnnotation: videoAnnotation
}) => {
  const handleClose = () => {
    props.setAnchorEl(undefined)
  };

  console.log("videoAnnotationtesting", props.startTime, props.endTime)


  const downloadTrimmedVideo = async() => {
    try{
      console.log("testing", props.selectedVideoAnnotation)
      const apiResponse = await DataService.downloadTrimmedVideo({
        signedUrl: props.selectedResource.resource,
        startTime: props.startTime,
        endTime: props.endTime
      })
      const url = window.URL.createObjectURL(new Blob([apiResponse.data]));
      const a = document.createElement("a");
      a.style.display = "none";
      a.href = url;
      a.download = "trimmedVideo.mp4"; // Set the desired file name
      document.body.appendChild(a);

      // Trigger the download
      a.click();

      // Clean up the temporary URL and anchor element
      window.URL.revokeObjectURL(url);
      document.body.removeChild(a);

      console.log(apiResponse)
    }catch(error) {
      console.log(error)
    }
  }

  return (
      <Menu
        anchorEl={props.anchorEl}
        open={Boolean(props.anchorEl)}
        onClose={handleClose}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'right',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'right',
        }}
      >
        {/* <MenuItem onClick={downloadTrimmedVideo}>Download</MenuItem> */}
        <MenuItem onClick={() => {
          props.onSendtoModel(props.selectedVideoAnnotation, props.startTime, props.endTime)
        }}>Send to Model</MenuItem>
      </Menu>
  )
}

const GreenTooltip = withStyles({
  tooltip: {
    backgroundColor: "#CCE8EB",
    color: "#000000"
  }
})(Tooltip);

const VideoAnnotationDialogBox = (props: IProps) => {
  const classes = useStyles();
  const { open, handleClose, resource,annotationLabelsAvailable, onMoveToLeft, onMoveToRight, ActionButtonArray, setShouldBlockDataTableKeyboardEvents, LabelsAvailable, allowUserToSetAnyLabel, isFetchingResources, resourceIndex } = props;
  const [play, setPlay] = useState(false);
  const [zoomLevel, setZoomLevel] = useState(1);
  const [position, setPosition] = useState({ x: 0, y: 0 });
  const [cannotPlayVideo, setCannotPlayVideo] = useState(true);
  const [sliderValue, setSliderValue] = useState(0);
  const [anchorElPlayback, setAnchorElPlayback] = useState(null);
  // const timeOutRef = useRef<any>();
  const intervalRef = useRef<NodeJS.Timeout>();
  const videoPlayingRef = useRef<any>()
  const [selectedSubTab, setSelectedSubTab] = useState<ResourceStatus | string>("");
  const [resourceList, setResourceList] = useState<VideoResource[]>([])
  const [currentVideoIndex, setCurrentVideoIndex] = useState(1);
  const [endDuration, setEndDuration] = useState("")
  const [startDuration, setStartDuration] = useState("00 : 00")
  const [videoLoaded, setVideoLoaded] = useState(false);
  const [canLoaderTurnedOff, setCanLoaderTurnedOff] = useState(false);
  const currentResourceRef = useRef('');
  const [resourceRemarksTextState, setResourceRemarksTextState] = useState<InputFieldState<string>>({ value: props?.resource?.remarks?.text || "" });
  const dispatch = useDispatch();
  const [showMoreFields, setShowMoreFields] = useState(false);
  const resourceRemarksText = props?.resource?.remarks?.text || "";
  const isSmallScreen = useMediaQuery((theme: Theme) => theme.breakpoints.down('xs'));
  // const [annotationDragHandle, setAnnotationDragHandle] = useState<{ isGrabbedByUser: boolean, videoAnnotationId: string, grabbedFrom: "start" | "end" | "middle" | "" }>({ isGrabbedByUser: false, videoAnnotationId: "", grabbedFrom: "" })
  const videoHTMLElement = useRef<HTMLVideoElement | null>(null);
  const screenRef = useRef<HTMLVideoElement | null>(null);
  const totalVideoDuration = videoHTMLElement?.current?.duration || 0;
  const forceRerender = useForceReRender();
  const [addingAnnotationMode, setAddingAnnotationMode] = useState<IAddingAnnotationMode>({
    isOn: false,
    status: "yetToStartAdding",
  });
  const [isEditMode, setIsEditMode] = useState<boolean>(false);
  const [playBackSpeed, setPlayBackSpeed] = useState("1x");
  const [isDragging, setIsDragging] = useState(false);
  const [dragStart, setDragStart] = useState({ x: 0, y: 0 });
  const [videoAnnotations, setVideoAnnotations] = useState<Array<videoAnnotation>>(copyByValue(props.resource?.videoAnnotations) ?? []);
  const [hoverAnnotationIndex, sethoverAnnotationIndex] = useState<{ index: number, annotationType: "annotationByExpert" | "annotationPrediction" | "" }>();
  const [annotationDragHandle, setAnnotationDragHandle] = useState<{
    isGrabbedByUser: boolean,
    videoAnnotationId: string,
    grabbedFrom: "start" | "end" | "middle" | ""
  }>({
    isGrabbedByUser: false,
    videoAnnotationId: "",
    grabbedFrom: ""
  })

  const [savePopupState, setSavePopupState] = useState<{ open: boolean }>({ open: false });
  const anchorEl = useRef<HTMLElement | null>(null);
  const [annotationDeleteConfirmationPopup, setAnnotationDeleteConfirmationPopup] = useState<{
    open: boolean,
    annotationIndex: number,
    annotationType: "detected" | "expert" | ""
  }>({
    open: false,
    annotationIndex: -1,
    annotationType: ""
  })

    //Screenshot Code
    const [screenShotLink, setScreenShotLink] = useState<string>("");
    const [isScreenShotPopOpen, setIsScreenShotPopOpen] = useState<boolean>(false);
  
  
    const captureVideoScreenshot = async () => {
    
      if (videoHTMLElement.current) {
        const video = videoHTMLElement.current;
    
       
        if (video.readyState >= 2) {
          
          const canvas = await html2canvas(video);
          const dataURL = canvas.toDataURL('image/png');
       
          
          setScreenShotLink(dataURL);
          setIsScreenShotPopOpen(true);
        } else {
          console.log('Video is not loaded yet.');
        }
      }
    };
  
  const [annotationMenuAnchorEl, setAnnotationMenuAnchorEl] = useState<Element | undefined>()

  const [indexOfAnnotationMenu, setIndexOfAnnotationMenu] = useState<String| undefined>()

  const [viewVideoDialogIsOpen, setViewVideoDialogIsOpen] = useState<boolean>(false)
  const [videoAnnotationWhichUserWantToSendToAnotherModel, setVideoAnnotationWhichUserWantToSendToAnotherModel] = useState<videoAnnotation>()
  const infoText: string = (() => {
    return addingAnnotationMode?.status === "yetToStartAdding" && addingAnnotationMode.isOn
    ? "Place the video seek bar at applicable place and click on start annotation"
    : addingAnnotationMode?.status === "annotating" && savePopupState.open && addingAnnotationMode.isOn
      ? "Fill label and tag of new annotation and click save"
      : addingAnnotationMode?.status === "annotating" && addingAnnotationMode.isOn
        ? "Click on End Annotation to complete annotating"
        : ""
  })();

  const isAnyCorrectAnnotationPresent: boolean = (() => {
    for (const videoAnnotation of videoAnnotations) {
      if (
        videoAnnotation.annotationByExpert ||
        videoAnnotation.annotationPrediction
      ) {
        return true
      }
    }
    return false;
  })();

  const isAnyInCorrectAnnotationPresent: boolean = (() => {
    for (const videoAnnotation of videoAnnotations) {
      if (
        videoAnnotation.annotationByExpert &&
        videoAnnotation.annotationPrediction
      ) {
        return true
      }
    }
    return false;
  })();

  const isAnyDeletedDetectedAnnotationPresent: boolean = (() => {
    for (const videoAnnotation of videoAnnotations) {
      if (
        videoAnnotation.annotationPrediction?.isDeleted
      ) {
        return true
      }
    }
    return false;
  })();

  const detectedCountForCountSection: string = useMemo(() => {
    if (videoAnnotations?.length>0) {
      let detectedCount = 0;
      for (const videoAnnotation of videoAnnotations) {
        if (
          videoAnnotation.annotationPrediction &&
          Object.keys(videoAnnotation.annotationPrediction)?.length>0
        ) {
          detectedCount += 1;
        }
      }
      if (detectedCount>0) {
        return ""+detectedCount;
      }
      return "NA"
    } 
    return "";
  }, [videoAnnotations]);

  const expertFeedbackCountForCountSection: string = useMemo(() => {
    if (resource?.videoAnnotations?.length) {
      let expertFeedbackCount = 0;
      for (const videoAnnotation of resource?.videoAnnotations) {
        if (videoAnnotation?.annotationPrediction?.approvalStatus || videoAnnotation?.annotationByExpert?.approvalStatus) {
          expertFeedbackCount += 1;
        }
      }
      if (expertFeedbackCount > 0) {
        return "" + expertFeedbackCount;
      }
      return "NA"
    }
    return "";
  }, [resource?.videoAnnotations]);


  useEffect(() => {
    if (props?.resource?.videoAnnotations?.length) {
      setVideoAnnotations(copyByValue(props?.resource?.videoAnnotations))
    }
  }, [props?.resource?.videoAnnotations])


  useEffect(() => {
    setResourceRemarksTextState(prevValue => ({
      value: resourceRemarksText,
      isDirty: false,
      isTouched: false
    }))
  }, [props.resource?._id, resourceRemarksText])

  useEffect(() => {
    if (!open) {
      currentResourceRef.current = ""
      return
    }
    let resourceChanged = false;
    if (!currentResourceRef.current || (currentResourceRef.current && currentResourceRef.current !== resource?._id)) {
      currentResourceRef.current = resource?._id;
      resourceChanged = true;
    }
    const resources = getResource();
    setResourceList(resources);
    if (resourceChanged && resource?.resource && open) {
      if (resources.length > 0 && resources[0]?.videoSrc) {
        setEndDuration("--")
        setStartDuration("00 : 00")
        calculateVideoDuration(resources[0]?.videoSrc)
      }
    }
  }, [open, resource]);

  useEffect(() => {
    if (videoLoaded && canLoaderTurnedOff) {
      setVideoLoaded(false)
      setCanLoaderTurnedOff(false)
      setTimeout(() => playOrPause(true), 1000)
    }
  }, [videoLoaded, canLoaderTurnedOff]);

  useEffect(() => {
    const query = new URLSearchParams(window.location.search);
    const status = query?.get('status') || 'backlog';
    setSelectedSubTab(status)
  }, []);

  useEffect(() => {
    if (props?.resource?._id) {
      setCannotPlayVideo(true);
    }
  }, [props?.resource?._id]);

  useEffect(() => {
    if (play && addingAnnotationMode.isOn && addingAnnotationMode.status === "annotating") {
      intervalRef.current = setInterval(() => {
        setVideoAnnotations((prevState: videoAnnotation[]) => {
          const newState: videoAnnotation[] = copyByValue(prevState);
            // @ts-ignore
            newState[newState.length-1].annotationByExpert.endTimeInSeconds = videoHTMLElement?.current?.currentTime || 0;
          return newState;
        });
      }, 100);
    }
    else {
      if (intervalRef.current) {
        clearInterval(intervalRef.current);
      }
    }
  }, [play, addingAnnotationMode]);

  const calculateVideoDuration = (videoSrc: string, autoPlay = false) => {
    const video = document.createElement('video');
    video.preload = 'auto';
    if (autoPlay) {
      setTimeout(() => {
        setCanLoaderTurnedOff(true)
      }, 2000)
    }
    video.onloadedmetadata = function () {
      window.URL.revokeObjectURL(video.src);
      const videoDuration = Math.ceil(video?.duration || 0);
      // const secondString = videoDuration < 2 ? " Sec" : " Secs"

      const durationMinutes = Math.floor(videoDuration / 60);
      const durationSeconds = Math.floor(videoDuration % 60);

      const min_UI = durationMinutes.toString().padStart(2, '0');
      const sec_UI = durationSeconds.toString().padStart(2, '0');

      setEndDuration(`${min_UI} : ${sec_UI}`);
      if (autoPlay) {
        setVideoLoaded(true)
      }
    }
    video.src = videoSrc;
  }

  const onClose = () => {
    anchorEl.current = null;
    handleClose();
    setPlay(false);
    clearInterval(videoPlayingRef.current);
    setSliderValue(0);
    setCurrentVideoIndex(1);
    setEndDuration("--");
    setStartDuration("00 : 00");
    setShowMoreFields(false);
    setIsEditMode(false);
    cancelAnnotation();
  }

  const getResource = () => {
    const resources = [{
      videoSrc: resource?.resource,
      _id: resource?._id
    }];
    return resources;
  }

  const playOrPause = (shouldPlay: boolean | null) => {
    clearInterval(videoPlayingRef.current);

    const video = document.getElementById("popupVideo") as HTMLVideoElement;
    if (video && shouldPlay === null) {
      video.playbackRate = Number(playBackSpeed.split('x')[0]);
      if (!play) {
        video.play();
        videoPlayingRef.current = activateSlider(video)
      }
      else {
        video.pause()
      }
      setPlay(!play);
    }
    else if (video) {
      if ((shouldPlay && play) || (shouldPlay && !play)) {
        video.playbackRate = Number(playBackSpeed.split('x')[0]);
        video.play();
        videoPlayingRef.current = activateSlider(video)
        setPlay(true);
      }
      else {
        if (play) {
          video.pause();
          setPlay(false);
        }
      }
    }
  }
  
  const handleNextFrame = () => {
    
    const video = document.getElementById("popupVideo") as HTMLVideoElement;
    if (video) {
      video.currentTime += (1 / 30) * 3; // Move video forward by 10 seconds
      if (!play) {
        setSliderPosition(video)
      }
    }
  };

  const handlePrevFrame = () => {

    const video = document.getElementById("popupVideo") as HTMLVideoElement;

    if (video) {
      video.currentTime -= (1 / 30) * 3; // Move video forward by 10 seconds
      if (!play) {
        setSliderPosition(video)
      }
    }
  };

  const popupContent = (
    <div style={{minWidth: "100px", position: "relative"}}>
      <List>
      <ListItem>Playback Speed</ListItem>
      <Divider style={{background:"#ffff"}}/>
        {[0.25,0.5, 0.75, 1, 1.25, 1.5, 1.75,  2].map((elem) =>
          <ListItem button style={{textAlign: "center", padding: "4px"}} onClick={()=>{
            setPlayBackSpeed(elem + "x");
            handlePopupClose();
            }}>
              {(Number(playBackSpeed.split("x")[0]) === elem) &&
                <CheckIcon style={{ fontSize: 18, position: "absolute", left: "20px"}}/>
              }
              <ListItemText primary={elem === 1 ? "Normal" : elem} />
          </ListItem>
        )
        }
      </List>
    </div>
  )
  
  const activateSlider = (videoElement: HTMLVideoElement) => {
    return setInterval(() => {
      setSliderPosition(videoElement)
    })
  }

  const setSliderPosition = (videoElement: HTMLVideoElement) => {
    const currentTime = videoElement.currentTime;
    
    const currentMinutes = Math.floor(currentTime / 60);
    const currentSeconds = Math.ceil(currentTime % 60);

    const min_UI = currentMinutes.toString().padStart(2, '0');
    const sec_UI = currentSeconds.toString().padStart(2, '0');

    setStartDuration(`${min_UI} : ${sec_UI}`);

    const videoDuration = videoElement.duration;
    const percentage = (currentTime / videoDuration) * 100;
    setSliderValue(percentage);
    // forceRerender();
  }

  const goForward = () => {
    const video = document.getElementById("popupVideo") as HTMLVideoElement;
    if (video) {
      video.currentTime += 1
      if (!play) {
        setSliderPosition(video)
      }
    }
  }

  const goBack = () => {
    const video = document.getElementById("popupVideo") as HTMLVideoElement;
    if (video) {
      video.currentTime -= 1
      if (!play) {
        setSliderPosition(video)
      }
    }
  }

  const arrowClicked = (direction: string) => {
    if (resource?.arevideoAnnotationsBeingUpdatedInDB || isEditMode || addingAnnotationMode.isOn) {
      return;
    }
    if (direction === "left") {
      onMoveToLeft()
    }
    else {
      onMoveToRight()
    }
    setPlay(false);
    clearInterval(videoPlayingRef.current);
    setSliderValue(0);
    setCurrentVideoIndex(1);
    // setShowMoreFields(false);
    setIsEditMode(false);
  }

  const videoEnded = () => {
    setPlay(false);
    clearInterval(videoPlayingRef.current);
  }

  const handleSliderChange = (e: any, newValue: any) => {
    // clearTimeout(timeOutRef.current);
    clearInterval(videoPlayingRef.current);
    setSliderValue(newValue);
    // timeOutRef.current = setTimeout(() => {
      const video = document.getElementById("popupVideo") as HTMLVideoElement;
      if (video) {
        const videoDuration = video.duration;
        if (!isNaN(videoDuration * (newValue / 100))) {
          video.currentTime = videoDuration * (newValue / 100);
        }
        videoPlayingRef.current = activateSlider(video);
      }
    // }, 200);
  }

  const onChangeRemark = (event: React.ChangeEvent<HTMLTextAreaElement>) => {
    setResourceRemarksTextState(oldState => ({
      ...oldState,
      isDirty: true,
      value: event.target.value
    }))
  }

  const onBlurRemarkField = () => {
    setResourceRemarksTextState(oldState => ({
      ...oldState,
      isTouched: true,
    }))
    props.setShouldBlockDataTableKeyboardEvents(false)
  }

  const onSaveRemarksClick = () => {
    dispatch(updateResourceRemarks({ resourceId: props.resource._id || "", resourceRemarksText: resourceRemarksTextState.value }));
    setResourceRemarksTextState(oldState => {
      return {
        ...oldState,
        isDirty: false,
        isTouched: false
      }
    })
  }


  const toggleShowMore = () => {
    setShowMoreFields((previousState: boolean) => {
      return !previousState;
    });
  }

  const handleMouseEnter = (index: number, annotationType: "annotationByExpert" | "annotationPrediction") => {
    sethoverAnnotationIndex({
      index: index,
      annotationType: annotationType
    });
  };

  const handleMouseLeave = () => {
    sethoverAnnotationIndex({
      index: -1,
      annotationType: ''
    });
  };

  /*
  const convertSecondsIntoMinAndSecFormat = (seconds: number = 0) => {
    const currentMinutes = Math.floor(seconds / 60);
    const currentSeconds = Math.ceil(seconds % 60);

    const min_UI = currentMinutes.toString().padStart(2, '0');
    const sec_UI = currentSeconds.toString().padStart(2, '0');

    return `${min_UI} : ${sec_UI}`;
  }
  */

  const editAnnotation = () => {
    playOrPause(false);
    setIsEditMode(prevState => !prevState);
  }

  const resetAnnotationDragHandle = () => {
    setAnnotationDragHandle({
      grabbedFrom: "",
      videoAnnotationId: "",
      isGrabbedByUser: false
    });
  }

  // const resourceFromReduxStore = useSelector((state: IReduxState) => state.data.data.resources);

  const resetAddingAnnoationModeState = () => {
    setAddingAnnotationMode({ status: "", isOn: false });
  };

  const cancelAnnotation = () => {
    // setVideoAnnotations(copyByValue(resourceFromReduxStore[resourceIndex].videoAnnotations as videoAnnotation[]));
    anchorEl.current = null;
    setVideoAnnotations(copyByValue(resource?.videoAnnotations as videoAnnotation[]));
    setIsEditMode(false);
    resetAddingAnnoationModeState();
    playOrPause(false);
    closeSavePopup();
  }

  const closeSavePopup = () => {
    setSavePopupState(prevState => {
      return { open: false };
    });
  }

  const crossIconOfSavePopupClickHandler = () => {
    cancelAnnotation();
  }

  const keepOpenSavePopup = () => {
    setSavePopupState(prevState => {
      return { open: true };
    })
  }

  const autoApprovedExpertAnnotation = (grabbedVideoAnnotationIndex: number) => {
    setVideoAnnotations(oldVideoAnnotations => {
      const newState = copyByValue(oldVideoAnnotations);
      newState[grabbedVideoAnnotationIndex].annotationByExpert!.approvalStatus = "approved";
      return newState;
    });
  }


  const grabFromStartMouseDownHandler = (event: React.MouseEvent<HTMLDivElement, MouseEvent>, grabbedVideoAnnotationIndex: number, annotationType: "annotationByExpert" | "annotationPrediction") => {
    event.preventDefault();

    if (!isEditMode) {
      return;
    }

    if (annotationType === "annotationPrediction" && videoAnnotations[grabbedVideoAnnotationIndex].annotationByExpert) {
      return;
    }

    const slider = document.querySelector(".videoToAnnotateSlider");
    if (!slider) {
      return;
    }

    const thumb = event.currentTarget;
    if (!thumb) {
      return;
    }

    setAnnotationDragHandle({
      isGrabbedByUser: true,
      videoAnnotationId: videoAnnotations[grabbedVideoAnnotationIndex].id,
      grabbedFrom: "start"
    });

    const shiftX = event.clientX - thumb.getBoundingClientRect().left;
    const persistStartTime = videoAnnotations[grabbedVideoAnnotationIndex]?.annotationPrediction?.startTimeInSeconds;

    const onMouseMove = (event: any) => {
      if (!slider || slider == null) {
        return;
      }
      if (event.movementX === 0) {
        // return;
      }

      let newLeft = event.clientX - shiftX - slider.getBoundingClientRect().left;

      // Note: the pointer is out of slider => lock the thumb within the bounaries
      if (newLeft < 0) {
        newLeft = 0;
      }

      // @ts-ignore
      let rightEdge = slider.offsetWidth - thumb.offsetWidth;
      if (newLeft > rightEdge) {
        newLeft = rightEdge;
      }

      setVideoAnnotations(oldVideoAnnotations => {
        const oldVideoAnnotation = oldVideoAnnotations[grabbedVideoAnnotationIndex];
        const incrementPercent = (newLeft / slider.clientWidth) * 100
        const percentFromLeft = incrementPercent;
        const startTimeInSecondsToUpdate = (percentFromLeft / 100) * totalVideoDuration;
        oldVideoAnnotation[annotationType]!.startTimeInSeconds = startTimeInSecondsToUpdate;
        if (videoHTMLElement.current) {
          videoHTMLElement.current.currentTime = oldVideoAnnotation?.[annotationType]?.startTimeInSeconds as number;
          setSliderPosition(videoHTMLElement?.current);
        }
        oldVideoAnnotations[grabbedVideoAnnotationIndex] = { ...oldVideoAnnotation };
        const newVideoAnnotation = [...oldVideoAnnotations];
        return newVideoAnnotation;
      })
    }

    function onMouseUp() {
      resetAnnotationDragHandle();

      if (annotationType === "annotationPrediction") {
        setVideoAnnotations(oldVideoAnnotations => {
          /*
          const oldVideoAnnotation = oldVideoAnnotations[grabbedVideoAnnotationIndex];
          delete oldVideoAnnotation?.annotationPrediction?.approvalStatus;
          oldVideoAnnotation.annotationByExpert = { ...oldVideoAnnotation.annotationPrediction! };
          oldVideoAnnotation.annotationPrediction!.startTimeInSeconds = persistStartTime as number;
          oldVideoAnnotations[grabbedVideoAnnotationIndex] = { ...oldVideoAnnotation };
          const newVideoAnnotation = [...oldVideoAnnotations];
          return newVideoAnnotation;
          */

          const newState = copyByValue(oldVideoAnnotations);
          delete newState?.[grabbedVideoAnnotationIndex]?.annotationPrediction?.approvalStatus;
          newState[grabbedVideoAnnotationIndex].annotationByExpert = { ...newState[grabbedVideoAnnotationIndex].annotationPrediction!, approvalStatus: "approved" };
          newState[grabbedVideoAnnotationIndex].annotationPrediction!.startTimeInSeconds = persistStartTime as number;
          return newState;
        });
      }
      else {
        autoApprovedExpertAnnotation(grabbedVideoAnnotationIndex);
      }

      document.removeEventListener('mouseup', onMouseUp);
      document.removeEventListener('mousemove', onMouseMove);
    }

    document.addEventListener('mousemove', onMouseMove);
    document.addEventListener('mouseup', onMouseUp);
  }

  const grabFromEndMouseDownHandler = (event: React.MouseEvent<HTMLDivElement, MouseEvent>, grabbedVideoAnnotationIndex: number, annotationType: "annotationByExpert" | "annotationPrediction") => {
    event.preventDefault();

    if (!isEditMode && !addingAnnotationMode.isOn) {
      return;
    }

    if (annotationType === "annotationPrediction" && videoAnnotations[grabbedVideoAnnotationIndex].annotationByExpert) {
      return;
    }

    const slider = document.querySelector(".videoToAnnotateSlider");
    if (!slider) {
      return;
    }

    const thumb = event.currentTarget;
    if (!thumb) {
      return;
    }

    setAnnotationDragHandle({
      isGrabbedByUser: true,
      videoAnnotationId: videoAnnotations[grabbedVideoAnnotationIndex].id,
      grabbedFrom: "end"
    });

    let shiftX = event.clientX - thumb.getBoundingClientRect().left;
    const persistEndTime = videoAnnotations[grabbedVideoAnnotationIndex]?.annotationPrediction?.endTimeInSeconds;

    function onMouseMove(event: any) {
      if (!slider) {
        return;
      }
      if (event.movementX === 0) {
        // return;
      }
      let newLeft = event.clientX - shiftX - slider.getBoundingClientRect().left;

      // the pointer is out of slider => lock the thumb within the bounaries
      if (newLeft < 0) {
        newLeft = 0;
      }
      // @ts-ignore
      let rightEdge = slider.offsetWidth - thumb.offsetWidth;
      if (newLeft > rightEdge) {
        newLeft = rightEdge;
      }

      newLeft += 10

      // thumb.style.left = newLeft + 'px';
      setVideoAnnotations(oldVideoAnnotations => {
        const oldVideoAnnotation = oldVideoAnnotations[grabbedVideoAnnotationIndex];
        const incrementPercent = (newLeft / slider.clientWidth) * 100
        const percentFromLeft = incrementPercent;
        let endTimeInSecondsToUpdate = (percentFromLeft / 100) * totalVideoDuration;
        if (endTimeInSecondsToUpdate > (videoHTMLElement?.current?.duration || 0)) {
          endTimeInSecondsToUpdate = videoHTMLElement?.current?.duration || 0;
        }
        oldVideoAnnotation[annotationType]!.endTimeInSeconds = endTimeInSecondsToUpdate;
        if (videoHTMLElement.current) {
          videoHTMLElement.current.currentTime = oldVideoAnnotation?.[annotationType]?.endTimeInSeconds as number;
          setSliderPosition(videoHTMLElement?.current);
        }
        oldVideoAnnotations[grabbedVideoAnnotationIndex] = { ...oldVideoAnnotation };
        const newVideoAnnotation = [...oldVideoAnnotations];
        return newVideoAnnotation;
      })
    }

    function onMouseUp() {
      resetAnnotationDragHandle();

      if (annotationType === "annotationPrediction") {
        setVideoAnnotations(oldVideoAnnotations => {
          /*
          const oldVideoAnnotation = oldVideoAnnotations[grabbedVideoAnnotationIndex];
          delete oldVideoAnnotation?.annotationPrediction?.approvalStatus;
          oldVideoAnnotation.annotationByExpert = { ...oldVideoAnnotation.annotationPrediction! };
          oldVideoAnnotation.annotationPrediction!.endTimeInSeconds = persistEndTime as number;
          oldVideoAnnotations[grabbedVideoAnnotationIndex] = { ...oldVideoAnnotation };
          const newVideoAnnotation = [...oldVideoAnnotations];
          return newVideoAnnotation;
          */

          const newState = copyByValue(oldVideoAnnotations);
          delete newState?.[grabbedVideoAnnotationIndex]?.annotationPrediction?.approvalStatus;
          newState[grabbedVideoAnnotationIndex].annotationByExpert = { ...newState[grabbedVideoAnnotationIndex].annotationPrediction!, approvalStatus: "approved" };
          newState[grabbedVideoAnnotationIndex].annotationPrediction!.endTimeInSeconds = persistEndTime as number;
          return newState;
        });
      }
      else {
        autoApprovedExpertAnnotation(grabbedVideoAnnotationIndex);
      }

      document.removeEventListener('mouseup', onMouseUp);
      document.removeEventListener('mousemove', onMouseMove);
    }

    document.addEventListener('mousemove', onMouseMove);
    document.addEventListener('mouseup', onMouseUp);
  }

  const grabFromMiddleMouseDownHandler = (event: React.MouseEvent<HTMLDivElement, MouseEvent>, grabbedVideoAnnotationIndex: number, annotationType: "annotationByExpert" | "annotationPrediction") => {
    event.preventDefault();

    if (!isEditMode) {
      return;
    }

    if (annotationType === "annotationPrediction" && videoAnnotations[grabbedVideoAnnotationIndex].annotationByExpert) {
      return;
    }

    const slider = document.querySelector(".videoToAnnotateSlider");
    if (!slider) {
      return;
    }

    const thumb = event.currentTarget;
    if (!thumb) {
      return;
    }

    setAnnotationDragHandle({
      isGrabbedByUser: true,
      videoAnnotationId: videoAnnotations[grabbedVideoAnnotationIndex].id,
      grabbedFrom: "middle"
    });

    let shiftX = event.clientX - thumb.getBoundingClientRect().left;

    const persistStartTime = videoAnnotations[grabbedVideoAnnotationIndex]?.annotationPrediction?.startTimeInSeconds;
    const persistEndTime = videoAnnotations[grabbedVideoAnnotationIndex]?.annotationPrediction?.endTimeInSeconds;

    function onMouseMove(event: any) {
      if (!slider) {
        return;
      }

      if (event.movementX === 0) {
        // return;
      }

      let newLeft = event.clientX - shiftX - slider.getBoundingClientRect().left;

      // the pointer is out of slider => lock the thumb within the bounaries
      if (newLeft < 0) {
        newLeft = 0;
      }
      // @ts-ignore
      let rightEdge = slider.offsetWidth - thumb.offsetWidth;
      if (newLeft > rightEdge) {
        newLeft = rightEdge;
      }

      // newLeft += 10

      // thumb.style.left = newLeft + 'px';

      setVideoAnnotations(oldVideoAnnotations => {
        const oldVideoAnnotation = oldVideoAnnotations[grabbedVideoAnnotationIndex];
        const incrementPercent = (newLeft / slider.clientWidth) * 100
        const percentFromLeft = incrementPercent;
        const SecondsToUpdate = (percentFromLeft / 100) * totalVideoDuration;
        let endTimeInSecondsToUpdate = oldVideoAnnotation?.[annotationType]?.endTimeInSeconds as number + (SecondsToUpdate - (oldVideoAnnotation?.[annotationType]?.startTimeInSeconds as number));
        let startSecondsToUpdate = SecondsToUpdate;
        if (endTimeInSecondsToUpdate > (videoHTMLElement?.current?.duration || 0)) {
          endTimeInSecondsToUpdate = oldVideoAnnotation?.[annotationType]?.endTimeInSeconds as number;
          startSecondsToUpdate = oldVideoAnnotation?.[annotationType]?.startTimeInSeconds as number;
        }
        oldVideoAnnotation[annotationType]!.startTimeInSeconds = startSecondsToUpdate;
        oldVideoAnnotation[annotationType]!.endTimeInSeconds = endTimeInSecondsToUpdate;
        if (videoHTMLElement.current) {
          videoHTMLElement.current.currentTime = oldVideoAnnotation?.[annotationType]?.startTimeInSeconds as number;
          setSliderPosition(videoHTMLElement?.current);
        }
        oldVideoAnnotations[grabbedVideoAnnotationIndex] = { ...oldVideoAnnotation };
        const newVideoAnnotation = [...oldVideoAnnotations];
        return newVideoAnnotation;
      })
    }

    function onMouseUp() {
      resetAnnotationDragHandle();

      if (annotationType === "annotationPrediction") {
        setVideoAnnotations(oldVideoAnnotations => {
          /*
          const oldVideoAnnotation = oldVideoAnnotations[grabbedVideoAnnotationIndex];
          delete oldVideoAnnotation?.annotationPrediction?.approvalStatus;
          oldVideoAnnotation.annotationByExpert = { ...oldVideoAnnotation.annotationPrediction! };
          if (oldVideoAnnotation?.annotationPrediction) {
            oldVideoAnnotation.annotationPrediction.endTimeInSeconds = persistEndTime as number;
            oldVideoAnnotation.annotationPrediction.startTimeInSeconds = persistStartTime as number;
          }
          oldVideoAnnotations[grabbedVideoAnnotationIndex] = { ...oldVideoAnnotation };
          const newVideoAnnotation = [...oldVideoAnnotations];
          return newVideoAnnotation;
          */

          const newState = copyByValue(oldVideoAnnotations);
          delete newState?.[grabbedVideoAnnotationIndex]?.annotationPrediction?.approvalStatus;
          newState[grabbedVideoAnnotationIndex].annotationByExpert = { ...newState[grabbedVideoAnnotationIndex].annotationPrediction!, approvalStatus: "approved" };
          newState[grabbedVideoAnnotationIndex].annotationPrediction!.endTimeInSeconds = persistEndTime as number;
          newState[grabbedVideoAnnotationIndex].annotationPrediction!.startTimeInSeconds = persistStartTime as number;
          return newState;
        });
      }
      else {
        autoApprovedExpertAnnotation(grabbedVideoAnnotationIndex);
      }
      document.removeEventListener('mouseup', onMouseUp);
      document.removeEventListener('mousemove', onMouseMove);
    }

    document.addEventListener('mousemove', onMouseMove);
    document.addEventListener('mouseup', onMouseUp);
  }

  /*
  const getZIndexForAnnotation = (annotationByExpertExist: boolean, expertAnnotationPositionLeft: number | null, predictedAnnotationPositionLeft: number) => {
    if (annotationByExpertExist && predictedAnnotationPositionLeft > expertAnnotationPositionLeft! && !isEditMode) {
      return 3;
    }
    return 1;
  }

  const setIdOfNewAnnoation = () => {
    const id =  generateUniqueId().toString();

    setVideoAnnotations((prevState: videoAnnotation[]) => {
      const newState = copyByValue(prevState);
      newState[newState.length-1].id = id;
      return newState;
    });

    console.log("Id set *********************");

    if (anchorEl.current) {
      anchorEl.current.id = `videoAnnotationId_${id}`;
      anchorEl.current = null;
    }
  }
  */


  const saveAnnoationInDb = () => {
    if (addingAnnotationMode.isOn && addingAnnotationMode.status === "editing") {
      const id =  generateUniqueId().toString();

      const updatedStateOfVideoAnnotation = (): videoAnnotation[] => {
        const newState = copyByValue(videoAnnotations);
        newState[newState.length-1].annotationByExpert!.approvalStatus = "approved";
        newState[newState.length-1].id = id;
        return newState;
      }

      if (anchorEl.current) {
        anchorEl.current.id = `videoAnnotationId_${id}`;
        anchorEl.current = null;
      }

      dispatch(updateResourceVideoAnnotationsInDB({ resourceId: resource._id, videoAnnotations: updatedStateOfVideoAnnotation() }));
    }
    else {
      dispatch(updateResourceVideoAnnotationsInDB({ resourceId: resource._id, videoAnnotations: videoAnnotations }));
    }
    setIsEditMode(false);
  }

  useEffect(() => {
    if (resource?.arevideoAnnotationsBeingUpdatedInDB === false) {
      resetAddingAnnoationModeState();
    }
  }, [resource?.arevideoAnnotationsBeingUpdatedInDB]);

  const addAnnotationClickHandler = () => {
    playOrPause(false);
    
    setAddingAnnotationMode((oldState) => ({
      ...oldState,
      isOn: true,
      status: "yetToStartAdding"
    }));
  }

  const startAnnotationClickHandler = () => {
    setAddingAnnotationMode(oldState => {
      return {
        ...oldState,
        status: "annotating"
      }
    });

    setVideoAnnotations((oldState: videoAnnotation[]) => {
      const startTime = videoHTMLElement?.current?.currentTime ?? 0;
      const endTime = startTime;

      if (videoHTMLElement?.current) {
        videoHTMLElement.current.currentTime = endTime;
      }

      return [
        ...oldState,
        {
          id: "",
          label: "",
          tag: "",
          annotationByExpert: {
            startTimeInSeconds: startTime,
            endTimeInSeconds: endTime,
          },
        },
      ]
    });

    // videoHTMLElement.current?.play();
    playOrPause(true);
  }

  const endAnnoationClickHandler = (event: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
    playOrPause(false);
    const newAnnotationHTMLElement = document.getElementById("newVideoAnnotationId") as HTMLElement;
    anchorEl.current = newAnnotationHTMLElement;
    setSavePopupState(prevState => {
      return { open: true };
    });
  }

  const updateStatusOfAnnotationAndSaveInDB = (index: number, status: "rejected" | "approved", annotationType: "annotationByExpert" | "annotationPrediction") => {
    if (videoAnnotations[index][annotationType]?.approvalStatus === status || resource.arevideoAnnotationsBeingUpdatedInDB) {
      return;
    }

    let updatedState = copyByValue(videoAnnotations);
    updatedState[index][annotationType]!.approvalStatus = status;

    setVideoAnnotations((prevState: videoAnnotation[]) => {
      return updatedState;
    });

    /* save In DB and redux store */
    dispatch(updateResourceVideoAnnotationsInDB({ resourceId: resource._id, videoAnnotations: updatedState }));
  }

  const labelsOfVideoAnnotationResourceThatAreNotApprovedOrRejectedByExpert = useMemo(() => {
    // let labels = resource?.videoAnnotations?.map((annotation, index) => {
    //   if (annotation?.annotationPrediction?.approvalStatus !== "approved") {
    //     if (annotation?.annotationByExpert?.approvalStatus !== "approved") {
    //       return annotation.label;
    //     }
    //   }
    //   return null;
    // });

    return getLabelsOfVideoAnnotationResourceThatAreNotApprovedOrRejectedByExpert(resource?.videoAnnotations || [])

    // return labels?.filter(label => label) ?? [];
  }, [resource, videoAnnotations]);
 
  useEffect(()=>{
    if(playBackSpeed){
      if(play)
      playOrPause(true);
    else
    playOrPause(false);
    }
  }, [playBackSpeed])

  const handlePopupClick = (event: any) => {
    setAnchorElPlayback(event.currentTarget);
  };

  const handlePopupClose = () => {
    setAnchorElPlayback(null);
  };

  const handleZoomIn = () => {
    setZoomLevel((prevZoomLevel) => Math.min(prevZoomLevel + 0.1, 2)); // You can adjust
  };

  const handleZoomOut = () => {
    setZoomLevel((prevZoomLevel) => Math.max(prevZoomLevel - 0.1, 1)); // You can adjust the minimum zoom level (e.g., 1x).

    if (zoomLevel < 1.2) {
      setPosition((prevPosition) => ({
        x: 0,
        y: 0,
      }));
      setDragStart({ x: 0, y: 0 });
    }
  };

  const popupOpen = Boolean(anchorElPlayback);

  const hideLabel = (P_index: number, annotationPredictionStartTimeInSeconds: number | undefined, annotationPredictionEndTimeInSeconds: number | undefined, isDeleted: boolean | undefined) => {
    let hide = false;

    if (isDeleted) {
      for (let i = 0; i < videoAnnotations.length; i++) {
        // if (i === P_index) {
        //   return;
        // }
  
        const expertAnnotation = videoAnnotations[i].annotationByExpert;
        
        if ((annotationPredictionStartTimeInSeconds ||  annotationPredictionStartTimeInSeconds === 0) && 
            annotationPredictionEndTimeInSeconds && 
            (expertAnnotation?.startTimeInSeconds || expertAnnotation?.startTimeInSeconds === 0) && 
            expertAnnotation?.endTimeInSeconds
        ) {
          if (annotationPredictionEndTimeInSeconds < expertAnnotation?.startTimeInSeconds || expertAnnotation?.endTimeInSeconds < annotationPredictionStartTimeInSeconds) {
            hide = false;
          }
          else {
            hide = true;
            break;
          }
        }
      }
    }
    else {
      hide = false;
    }

    return hide;
  }
 
  
  const handleMouseDown = (e: any) => {
    if (zoomLevel > 1) {
      setIsDragging(true);
      setDragStart({ x: e.clientX, y: e.clientY });
    }
  };
  const handleMouseMove = (e: any) => {
      
    if (isDragging) { 
      const deltaX = e.clientX - dragStart.x;
      const deltaY = e.clientY - dragStart.y;
      setPosition((prevPosition) => ({
        x: prevPosition.x + deltaX,
        y: prevPosition.y + deltaY,
      }));
      setDragStart({ x: e.clientX, y: e.clientY });
    }
  };

  const handleMouseUp = () => {
    setIsDragging(false);
  };
  const toggleFullscreen = () => {
    if (videoHTMLElement.current) {
      if (document.fullscreenElement) {
        document.exitFullscreen();
      } else {
        videoHTMLElement.current.requestFullscreen();
      }
    }
  };

  const handleAnnotationContextMenu = (event: React.MouseEvent<HTMLDivElement>) => {
    console.log("testing-context")
    event.preventDefault();
    setAnnotationMenuAnchorEl(event.currentTarget)
  }


  const [selectedStartTime, setSelectedStartTime] = useState<Number>(0);
  const [selectedEndTime, setSelectedEndTime] = useState<Number>(0);

  const handleOnSendToModel = (videoAnnotation: videoAnnotation, startTime: number, endTime: number) => {
    setViewVideoDialogIsOpen(true)
    setVideoAnnotationWhichUserWantToSendToAnotherModel(videoAnnotation);
    setSelectedStartTime(startTime)
    setSelectedEndTime(endTime)
  }
  const [isViewAsCSVOpen, setIsViewAsCSVOpen] = useState<boolean>(false)
  const handleCloseCSVDialog = () => {
    setIsViewAsCSVOpen(prevState=>!prevState)
}

const {isFetchingResourceMetadataFileUrl, resourceMetadataFileDownloadUrl} = useResourceMetadataDownloadUrlFromResourceId(
  props.resource?.metadataFileName
  ? props.resource?._id || ""
  : "" 
);

const labelOptions = () => {
  const labelsAvailable = [
      ...(resource.labelOptions?.map(labelObj => {
          return {
              label: labelObj.labelOption,
              group: LABEL_GROUP_RESOURCE_LEVEL,
          }
      }) || []
      ),
      ...(LabelsAvailable?.map(label => { return {label, group: LABEL_GROUP_TASK_LEVEL} }) || []),
  ]

  return labelsAvailable
}

  return (
    <Dialog style={{border: 'solid red'}} onClose={onClose} open={open} maxWidth={isSmallScreen ? "xs" : "lg"} fullWidth={true} fullScreen classes={{ paperScrollPaper: css.dialogContainer }}
      className={`
        ${annotationDragHandle?.isGrabbedByUser ? css.annotationGettingDraggedByUser : ""}
        ${!isEditMode ? css.annotationViewMode : ""}
      `}
    >
      <div className={classes.root}>

        <div className={classes.closeBtn} onClick={() => { onClose() }}>
          {/* <CloseIcon /> */}
          <Cancel style={{ fontSize: "40px", padding: "4px" }} />
        </div>

        {
          isFetchingResources || props.aiAssistantToolip.AiAssistantApiBeingCalledForResourceIds.indexOf(props.resource?._id) !== -1
            ?

            <div style={{
              // width: "1060px", 
              width: "100%",
              height: "604px", display: "flex", justifyContent: "center", alignItems: "center"
            }}>
              <CircularProgress color="primary" />
            </div>
            :

            <>
              {/* body of the dialog  */}
              <div className={classes.body}>

                {/* content  */}
                <div>
                  {
                    <ViewVideoDialog
                    src=""
                      startTime={selectedStartTime || 0}
                      endTime={selectedEndTime|| 0}
                      resource={resource}
                      videoAnnotationSelectedByUser={videoAnnotationWhichUserWantToSendToAnotherModel}
                      title={"Trimmed Video"}
                      sendToModelTypes={["videoAnnotation", "video"]}
                      scenario={"Save Trimmed Video"} isOpen={viewVideoDialogIsOpen} 
                      setIsOpen={setViewVideoDialogIsOpen}/>
                  }
                     { isScreenShotPopOpen &&  <ViewVideoDialog
                      src={screenShotLink}
                      resource={resource}
                      title={"Screenshot Image"}
                      sendToModelTypes={["imageAnnotation", "image"]}
                      scenario={"Save Screenshot"}
                       isOpen={isScreenShotPopOpen} 
                      setIsOpen={setIsScreenShotPopOpen}/>
                    }
                  <div className={classes.content}>

                    {/* video & slider */}
                    {
                      resourceList[currentVideoIndex - 1] &&
                      <div style={{ height: 'inherit', marginTop: '15px', marginLeft: '30px' }}>

                        <AiAssistantMsgWithInfoIcon aiAssistantLastCalledAt={resource?.aiAssistantLastCalledDetails?.calledAt ? true : false} />


                        <div ref={screenRef as any} className="rounded-md  overflow-hidden" style={{ position: 'relative' }}>                         
                          <video
                          crossOrigin="anonymous"
                          id="popupVideo"
                          style={{
                          width: "550px",
                          minHeight: '380px',
                          height: "380px",
                          borderRadius: '10px',
                          background: '#000',
                          marginTop: '10px',
                          transform: `scale(${zoomLevel}) translate(${position.x}px, ${position.y}px)`
                          }}
                        src={resourceList[currentVideoIndex - 1].videoSrc}
                        onEnded={videoEnded}
                        preload="auto"
                        ref={videoHTMLElement}
                        onCanPlay={(event) => {
                        setCannotPlayVideo(false);
                        forceRerender();
                         }}
                       onPlay={() => { forceRerender() }}
                       onPause={() => { forceRerender() }}
                       onTimeUpdate={(event) => { forceRerender() }}
                          onMouseDown={handleMouseDown}
                          onMouseMove={handleMouseMove}
                          onMouseUp={handleMouseUp}
                          onMouseLeave={handleMouseUp}
                       />
                     <button
                     onClick={toggleFullscreen}
                     style={{
                     position: 'absolute',
                     bottom: '10px',
                     right: '5px',
                     cursor: 'pointer',
                     background:'transparent',
                     border:'none'
                    }}
                    >
                    <FullscreenIcon style={{color:"white"}}/>
                   </button>
    </div>
                          {/* <button onClick={toggleFullscreen}>Toggle Fullscreen</button> */}
                        {/* Play, Pause, Increment, Decrement Buttons, next, prevous buttons */}
                        <div className={classes.footer}>
                      
                          <div style={{  marginTop: "25px", width: "100%" }}>
                          <Popover
                open={popupOpen}
                anchorEl={anchorElPlayback}
                onClose={handlePopupClose}
                anchorOrigin={{
                  vertical: 'top',
                  horizontal: 'center',
                }}
                transformOrigin={{
                  vertical: 'bottom',
                  horizontal: 'center',
                }}
                className={classes.popOver}
              >
                {popupContent}
              </Popover>
                            <div className={classes.videoBtns} style={{ pointerEvents: endDuration === "--" ? "none" : "all" }}>
                            <div className={classes.videoIcons}>
                              <Box style={{ display: 'flex', flexDirection: "column", justifyContent: "center", alignItems: "center"}}>
                                <Box style={{ marginBottom: "10px" }}>
                                  <IconButton size="small" style={{ background: '#008D9C' }} onClick={goBack} ><ReplayIcon style={{ fontSize: 25, color: "#ffff", transform: 'rotate(-100deg)', padding: "3px" }} /></IconButton>
                                  {/* <DecrementIcon onClick={goBack}></DecrementIcon> */}
                                </Box>
                                <Typography style={{ color: "#98A6B3",fontSize:10 }} variant="caption">-1</Typography>
                              </Box>

                              <Box style={{ display: 'flex', flexDirection: "column", justifyContent: "center", alignItems: "center" }}>
                                <Box style={{ marginBottom: "10px" }}>
                                  <IconButton size="small" style={{ background: '#7fc6cd' }} onClick={() => handlePrevFrame()}><SkipPreviousIcon style={{ fontSize: 25, color: "#ffff", padding: "3px" }} /></IconButton>
                                  {/* <PreviousResourceIcon onClick={() => arrowClicked("left")}></PreviousResourceIcon> */}
                                </Box>
                                <Typography style={{ color: "#98A6B3",fontSize:10 }} variant="caption">Previous Frame</Typography>
                              </Box>
                       
                              <Box style={{ display: 'flex', flexDirection: "column", justifyContent: "center", alignItems: "center" }}>
                                <Box style={{ marginBottom: "10px" }}>
                                <IconButton size="small" style={{ background: '#7fc6cd' }} onClick={() => arrowClicked("left")}><SkipPreviousRoundedIcon style={{ fontSize: 25, color: "#ffff", padding: "3px" }} /></IconButton>
                                  {/* <PreviousResourceIcon onClick={() => arrowClicked("left")}></PreviousResourceIcon> */}
                                </Box>
                                <Typography style={{ color: "#98A6B3",fontSize:10 }} variant="caption">Previous</Typography>
                              </Box>
                              <Box style={{ display: 'flex', flexDirection: "column", justifyContent: "center", alignItems: "center" }}>
                                <Box style={{ marginBottom: "10px" }}>
                                <IconButton size="small" style={{ background: '#7fc6cd' }} onClick={() =>handleZoomOut()}><ZoomOutIcon style={{ fontSize: 25, color: "#ffff", padding: "3px" }} /></IconButton>
                                  {/* <PreviousResourceIcon onClick={() => arrowClicked("left")}></PreviousResourceIcon> */}
                                </Box>
                                <Typography style={{ color: "#98A6B3",fontSize:10 }} variant="caption">Zoom out</Typography>
                              </Box>
                             
                              <Box style={{ display: 'flex', flexDirection: "column", justifyContent: "center", alignItems: "center" }}>
                                <Box style={{ marginBottom: "10px" }}>
                                  <IconButton size="small" style={{ background: '#008D9C' }} onClick={() => playOrPause(null)}>
                                    {
                                      !play
                                        ? <PlayArrowRoundedIcon style={{ fontSize: 25, color: "#ffff", padding: "3px" }} />
                                        : <PauseRoundedIcon style={{ fontSize: 25, color: "#ffff", padding: "3px" }} />
                                    }
                                  </IconButton>
                                  {/* <PlayPauseIcon onClick={() => playOrPause(null)}></PlayPauseIcon> */}
                                </Box>
                                <Typography style={{ color: "#98A6B3",fontSize:10 }} variant="caption">Play / Pause</Typography>
                              </Box>
                             
                              <Box style={{ display: 'flex', flexDirection: "column", justifyContent: "center", alignItems: "center" }}>
                                <Box style={{ marginBottom: "10px" }}>
                                <IconButton size="small" style={{ background: '#7fc6cd' }} onClick={() => handleZoomIn()}><ZoomInIcon style={{ fontSize: 25, color: "#ffff", padding: "3px" }} /></IconButton>
                                  {/* <PreviousResourceIcon onClick={() => arrowClicked("left")}></PreviousResourceIcon> */}
                                </Box>
                                <Typography style={{ color: "#98A6B3",fontSize:10 }} variant="caption">Zoom In</Typography>
                              </Box>

                              <Box style={{ display: 'flex', flexDirection: "column", justifyContent: "center", alignItems: "center" }}>
                                <Box style={{ marginBottom: "10px" }}>
                                <IconButton size="small" style={{ background: '#7fc6cd' }} onClick={() => arrowClicked("right")}><SkipNextRoundedIcon style={{ fontSize: 25, color: "#ffff", padding: "3px" }} /></IconButton>

                                  {/* <NextResourceIcon onClick={() => arrowClicked("right")}></NextResourceIcon> */}
                                </Box>
                                <Typography style={{ color: "#98A6B3",fontSize:10 }} variant="caption">Next</Typography>
                              </Box>

                              <Box style={{ display: 'flex', flexDirection: "column", justifyContent: "center", alignItems: "center" }}>
                                <Box style={{ marginBottom: "10px" }}>
                                <IconButton size="small" style={{ background: '#7fc6cd' }} onClick={() =>handleNextFrame() }><SkipNextIcon style={{ fontSize: 25, color: "#ffff", padding: "3px" }}/></IconButton>

                                  {/* <PreviousResourceIcon onClick={() => arrowClicked("left")}></PreviousResourceIcon> */}
                                </Box>
                                <Typography style={{ color: "#98A6B3",fontSize:10 }} variant="caption">Next Frame</Typography>
                              </Box>
                              <Box style={{ display: 'flex', flexDirection: "column", justifyContent: "center", alignItems: "center" }}>
                                <Box style={{ marginBottom: "10px" }}>
                                  <IconButton size="small" style={{ background: '#008D9C' }} onClick={goForward}><ReplayIcon style={{ fontSize: 25, color: "#ffff", transform: 'rotate(-273deg) scaleX(-1)', padding: "3px" }} /></IconButton>
                                  {/* <IncrementIcon onClick={goForward}></IncrementIcon> */}
                                </Box>
                                <Typography style={{ color: "#98A6B3",fontSize:10 }} variant="caption">+1</Typography>
                              </Box>
                              </div>
                              <div style={{display:"flex",flexDirection:"row" , justifyContent: "center",marginBottom: "2px",position: "relative",gap:"8px"}}>
                              <Box style={{ display: 'flex', flexDirection: "column", justifyContent: "center", alignItems: "center" }}>
                              <Box style={{ marginBottom: "10px" }}>
                    <>
                      {playBackSpeed === '1x' && <SlowMotionVideoIcon onClick={handlePopupClick} style={{ fontSize: 30, fontWeight: 500, color: "#008D9C", cursor: "pointer" }} />}
                      {playBackSpeed !== '1x' && <div onClick={handlePopupClick} style={{ fontSize: 10, fontWeight: "bolder", color: "#008D9C", cursor: "pointer", height: 30, width: 30 }} className={classes.playbackSpeedCustomBtns}><span>{playBackSpeed.split('x')[0]}</span></div>}
                    </>
          
                    </Box>
                <Typography style={{ color: "#98A6B3",fontSize:10 }} variant="caption">Playback Speed</Typography>
                </Box>
                              <Box style={{ display: 'flex', flexDirection: "column", justifyContent: "center", alignItems: "center" }}>
                                <Box style={{ marginBottom: "10px" }}>
                                  <IconButton size="small" style={{ background: '#008D9C',width:"33px",height:"33px" }} onClick={captureVideoScreenshot}>
                                  <img
                                  className="hover:cursor-pointer"
                                  src={Screenshot}
                                style={{width:"18px",height:"18px"}}
                                  alt=""
                                 
                      /></IconButton>
                                
                                </Box>
                                <Typography style={{ color: "#98A6B3" }} variant="caption">Screenshot</Typography>
                              </Box>
                              </div>
                            </div>
                          </div>
                        </div>

                        <div className={classes.slider}>
                          <div style={{ pointerEvents: endDuration === "--" ? "none" : "all" }}>
                            <div style={{ display: "flex", justifyContent: "space-between" }}>
                              <span className={classes.startTime}>{startDuration}</span>
                              <span className={classes.endTime}>{endDuration}</span>
                            </div>
                            <Slider value={sliderValue} classes={{ root: "videoToAnnotateSlider" }} onChange={handleSliderChange} />
                          </div>
                        </div>
                        
                        {
                          cannotPlayVideo ?
                            <div style={{ marginTop: "40px", display: "flex", justifyContent: "center", alignItems: "center" }}>
                              <CircularProgress color="primary" size={25} />
                            </div>
                            :
                            <div className={classes.annotationContainer}>
                              {
                                videoAnnotations?.length > 0
                                ?
                                videoAnnotations?.map((videoAnnotation, index) => {
                                  const predictedAnnotationPositionLeft = (videoAnnotation?.annotationPrediction?.startTimeInSeconds! / videoHTMLElement?.current?.duration!) * 100;
                                  const predictedAnnotationWidth = ((videoAnnotation?.annotationPrediction?.endTimeInSeconds! - videoAnnotation?.annotationPrediction?.startTimeInSeconds!) / videoHTMLElement?.current?.duration!) * 100;
                                  const annotationByExpertExist = (videoAnnotation?.annotationByExpert) ? true : false;
                                  const annotationByAIExist = (videoAnnotation?.annotationPrediction) ? true : false;
                                  let expertAnnotationPositionLeft: number | null = null;
                                  let expertAnnotationWidth: number | null = null;
                                  if (annotationByExpertExist) {
                                    expertAnnotationPositionLeft = (videoAnnotation?.annotationByExpert?.startTimeInSeconds! / videoHTMLElement?.current?.duration!) * 100;
                                    expertAnnotationWidth = (((videoAnnotation.label ? videoAnnotation?.annotationByExpert?.endTimeInSeconds! : videoHTMLElement?.current?.currentTime || 0) - videoAnnotation?.annotationByExpert?.startTimeInSeconds!) / videoHTMLElement?.current?.duration!) * 100;
                                  }

                                  return (
                                    <>
                                      {/* <div className={`${classes.videoAnnotationTime}`} style={{ left: `${predictedAnnotationPositionLeft - 4}%`, width: `${predictedAnnotationWidth + 7}%`, bottom: "39px", display: hoverAnnotationIndex?.index === index && hoverAnnotationIndex.annotationType === "annotationPrediction" ? "flex" : "none" }} >
                                        <Typography className={classes.textStyleForVideoAnnotation} >{convertSecondsIntoMinAndSecFormat(videoAnnotation.annotationPrediction?.startTimeInSeconds)}</Typography>
                                        <Typography className={classes.textStyleForVideoAnnotation} >{convertSecondsIntoMinAndSecFormat(videoAnnotation.annotationPrediction?.endTimeInSeconds)}</Typography>
                                      </div> */}


                                      {
                                        annotationByAIExist &&
                                        <div
                                          id={`${index}-predicted`}
                                          className={`${css.annotationSection}`}
                                          onMouseEnter={() => handleMouseEnter(index, "annotationPrediction")}
                                          onMouseLeave={handleMouseLeave}
                                          onContextMenu={(event: React.MouseEvent<HTMLDivElement>) => {
                                            handleAnnotationContextMenu(event)
                                            setIndexOfAnnotationMenu(`${index}-predicted`)
                                          }}
                                        >
                                          { `${index}-predicted` === indexOfAnnotationMenu && <AnnotationContextMenu
                                              anchorEl={annotationMenuAnchorEl}
                                              setAnchorEl={setAnnotationMenuAnchorEl}
                                              startTime={videoAnnotation?.annotationPrediction?.startTimeInSeconds as number}
                                              endTime={videoAnnotation?.annotationPrediction?.endTimeInSeconds as number}
                                              onSendtoModel={handleOnSendToModel} selectedVideoAnnotation={videoAnnotation} selectedResource={props.resource} />}
                                          <AnnotationStartTimeAndEndTimeComponent
                                            annotationPositionLeft={predictedAnnotationPositionLeft}
                                            annotationWidth={predictedAnnotationWidth}
                                            // show={hoverAnnotationIndex?.index === index && hoverAnnotationIndex.annotationType === "annotationPrediction"}
                                            show={true}
                                            startTimeInSeconds={videoAnnotation?.annotationPrediction?.startTimeInSeconds as number}
                                            endTimeInSeconds={videoAnnotation?.annotationPrediction?.endTimeInSeconds as number}
                                            deleteButton={{
                                              shouldShowItAsReset: false,
                                              shouldShow: !annotationByExpertExist && !videoAnnotation.annotationPrediction?.isDeleted && isEditMode ? true : false,
                                              onClick:()=>{
                                                setAnnotationDeleteConfirmationPopup({
                                                  open: true,
                                                  annotationIndex: index,
                                                  annotationType: "detected"
                                                })
                                              }
                                            }}
                                            deleteUndoButton={{
                                              shouldShow: 
                                                videoAnnotation?.annotationPrediction?.isDeleted && isEditMode
                                                ? true 
                                                : false,
                                              onClick:()=>{
                                                setVideoAnnotations((oldVideoAnnotations=>{
                                                  for (let oldVideoAnnotationIndex = 0; oldVideoAnnotationIndex < oldVideoAnnotations.length; oldVideoAnnotationIndex++) {
                                                    let oldVideoAnnotation = oldVideoAnnotations[oldVideoAnnotationIndex];
                                                    if (oldVideoAnnotationIndex === index) {
                                                      if (oldVideoAnnotation.annotationPrediction) {
                                                        oldVideoAnnotation.annotationPrediction.isDeleted = false;
                                                        oldVideoAnnotation.annotationPrediction.approvalStatus = 'approved';
                                                        oldVideoAnnotation = {...oldVideoAnnotation}
                                                      }
                                                    }
                                                  }
                                                  return [...oldVideoAnnotations]
                                                }))
                                              }
                                            }}                                            
                                          />

                                        <DeleteAnnotationConfirmationTooltip
                                          open={
                                            annotationDeleteConfirmationPopup.open &&
                                            annotationDeleteConfirmationPopup.annotationIndex === index &&
                                            annotationDeleteConfirmationPopup.annotationType === "detected"
                                            ? true
                                            : false
                                          }
                                          message={
                                            annotationByExpertExist
                                            ? ""
                                            : "Are you sure if you would like to mark this detected annotation as deleted?"
                                          }
                                          deleteButtonText={
                                            annotationByExpertExist
                                            ? ""
                                            : "Mark as Delete"
                                          }
                                          onClose={()=>{
                                            setAnnotationDeleteConfirmationPopup(oldState=>({
                                              ...oldState,
                                              open: false
                                            }))
                                          }}
                                          onDeleteButtonClick={()=>{
                                            setAnnotationDeleteConfirmationPopup(oldState=>({
                                              ...oldState,
                                              open: false
                                            }))

                                            /**
                                             * resetting hoverAnnotationIndex because hover was not getting removed after delete click by default
                                             */
                                            sethoverAnnotationIndex({
                                              index: -1,
                                              annotationType: ""
                                            })

                                            if (!annotationByExpertExist) {
                                              setVideoAnnotations(oldVideoAnnotations=>{
                                                for (let oldVideoAnnotationIndex = 0; oldVideoAnnotationIndex < oldVideoAnnotations.length; oldVideoAnnotationIndex++) {
                                                  let oldVideoAnnotation = oldVideoAnnotations[oldVideoAnnotationIndex];
                                                  if (oldVideoAnnotationIndex === index) {
                                                    if (oldVideoAnnotation.annotationPrediction) {
                                                      oldVideoAnnotation.annotationPrediction.isDeleted = true;
                                                      oldVideoAnnotation.annotationPrediction.approvalStatus = "rejected";
                                                      oldVideoAnnotation = {...oldVideoAnnotation}
                                                    }
                                                  }
                                                }
                                                return [...oldVideoAnnotations]
                                              })
                                            }

                                          }}
                                        >
                                          <div
                                            className={`${classes.videoAnnotation}`}
                                            // style={{ zIndex: getZIndexForAnnotation(annotationByExpertExist, expertAnnotationPositionLeft, predictedAnnotationPositionLeft), left: `${predictedAnnotationPositionLeft}%`, width: `${predictedAnnotationWidth}%`, opacity: (hoverAnnotationIndex?.index === index && hoverAnnotationIndex.annotationType === "annotationPrediction") ? 1 : annotationByExpertExist || videoAnnotation.annotationPrediction?.isDeleted ? 0.4 : CORRECT_ANNOTATION_OPACITY_WHEN_NOT_HOVERING, backgroundColor: annotationByExpertExist || videoAnnotation.annotationPrediction?.isDeleted ? "red" : "#008D9C" }}
                                            style={{ zIndex: 1, left: `${predictedAnnotationPositionLeft}%`, width: `${predictedAnnotationWidth}%`, opacity: (hoverAnnotationIndex?.index === index && hoverAnnotationIndex.annotationType === "annotationPrediction") ? 1 : annotationByExpertExist || videoAnnotation.annotationPrediction?.isDeleted ? 0.4 : CORRECT_ANNOTATION_OPACITY_WHEN_NOT_HOVERING, backgroundColor: annotationByExpertExist || videoAnnotation.annotationPrediction?.isDeleted ? "red" : "#008D9C" }}
                                            // onMouseEnter={() => handleMouseEnter(index, "annotationPrediction")}
                                            // onMouseLeave={handleMouseLeave}
                                          >

                                            <div
                                              style={{ gap: predictedAnnotationWidth >= 3 ? "1px": "0px", marginLeft: predictedAnnotationWidth >= 3 ? "3px" : "0px" }}
                                              className={`
                                                ${classes.annotationBeginningDragHandle} 
                                                ${(isEditMode && !annotationByExpertExist) ? classes.cursorGrab : ""} 
                                                ${!isEditMode || videoAnnotation.annotationPrediction?.isDeleted ? classes.visibilityHidden : ""}
                                                ${annotationDragHandle.videoAnnotationId === videoAnnotation.id && annotationDragHandle.grabbedFrom === "start" ? classes.anotationDragHandleGrabbedByUser : ""}
                                              `}
                                              onMouseDown={(event) => {
                                                grabFromStartMouseDownHandler(event, index, "annotationPrediction")
                                              }}
                                            >
                                              {
                                                (hoverAnnotationIndex?.index === index) && hoverAnnotationIndex.annotationType === "annotationPrediction" && !annotationByExpertExist &&
                                                <>
                                                  <div className={classes.verticalBar}></div>
                                                  <div className={classes.verticalBar}></div>
                                                </>
                                              }
                                            </div>

                                            <div
                                              className={` 
                                                ${(isEditMode && !annotationByExpertExist && !videoAnnotation?.annotationPrediction?.isDeleted) ? classes.cursorGrab : ""} 
                                                ${annotationDragHandle.videoAnnotationId === videoAnnotation.id && annotationDragHandle.grabbedFrom === "middle" ? classes.anotationDragHandleGrabbedByUser : ""}
                                              `}
                                              style={{ width: "calc(100% - 17px)" }}
                                              onMouseDown={(event) => {
                                                if (videoAnnotation.annotationPrediction?.isDeleted) {
                                                  return;
                                                }                                                
                                                grabFromMiddleMouseDownHandler(event, index, "annotationPrediction")
                                              }}>
                                            </div>

                                            <div
                                              style={{ gap: predictedAnnotationWidth >= 3 ? "1px": "0px" }}
                                              className={`
                                                ${classes.annotationEndDragHandle} 
                                                ${(isEditMode && !annotationByExpertExist) ? classes.cursorGrab : ""} 
                                                ${!isEditMode || videoAnnotation?.annotationPrediction?.isDeleted ? classes.visibilityHidden : ""} 
                                                ${annotationDragHandle.videoAnnotationId === videoAnnotation.id && annotationDragHandle.grabbedFrom === "end" ? classes.anotationDragHandleGrabbedByUser : ""}
                                              `}
                                              onMouseDown={(event) => grabFromEndMouseDownHandler(event, index, "annotationPrediction")}>
                                              {
                                                (hoverAnnotationIndex?.index === index) && hoverAnnotationIndex.annotationType === "annotationPrediction" && !annotationByExpertExist &&
                                                <>
                                                  <div className={classes.verticalBar}></div>
                                                  <div className={classes.verticalBar}></div>
                                                </>
                                              }
                                            </div>
                                          </div>
                                        </DeleteAnnotationConfirmationTooltip>
                                          <AnnotationTagComponent
                                            annotationPositionLeft={predictedAnnotationPositionLeft}
                                            annotationWidth={predictedAnnotationWidth}
                                            tag={videoAnnotation.tag}
                                            // show={hoverAnnotationIndex?.index === index && hoverAnnotationIndex.annotationType === "annotationPrediction"}
                                            show={true}
                                          />

                                          {
                                            videoAnnotation.id &&
                                            !annotationByExpertExist &&
                                            !isEditMode &&
                                            // !resource.arevideoAnnotationsBeingUpdatedInDB &&
                                            <div
                                              style={{ position: "absolute", left: `${predictedAnnotationPositionLeft! - 6}%`, width: `${predictedAnnotationWidth! + 8}%`, display: "flex", justifyContent: "space-between" }}
                                            >
                                              <>
                                                <div>
                                                  <RejectIcon
                                                    status={videoAnnotation?.annotationPrediction?.approvalStatus ?? null}
                                                    colorToShow={videoAnnotation?.annotationPrediction?.approvalStatus === "rejected" ? "#CCCCCC" : "#EF8A8A"}
                                                    className={`${css.videoAnnotationRejectIcon}`}
                                                    style={{ top: "40px", cursor: "pointer", zIndex: 1, backgroundColor: "white", visibility: "hidden" }}
                                                    onClick={() => updateStatusOfAnnotationAndSaveInDB(index, "rejected", "annotationPrediction")}
                                                  >
                                                  </RejectIcon>
                                                </div>

                                                <div>
                                                  <ApproveIcon
                                                    status={videoAnnotation?.annotationPrediction?.approvalStatus ?? null}
                                                    colorToShow={videoAnnotation?.annotationPrediction?.approvalStatus === "approved" ? "#CCCCCC" : "#008d9c"}
                                                    className={`${css.videoAnnotationApproveIcon} ${videoAnnotation?.annotationPrediction?.isDeleted ? css.visibilityHidden : ""}`}
                                                    style={{ top: "35px", cursor: "pointer", zIndex: 1, backgroundColor: "white" }}
                                                    onClick={() => updateStatusOfAnnotationAndSaveInDB(index, "approved", "annotationPrediction")}
                                                  >
                                                  </ApproveIcon>
                                                </div>
                                              </>
                                            </div>
                                          }


                                        </div>
                                      }

                                      {/* </div> */}

                                      {
                                        annotationByExpertExist &&
                                        <>
                                        <Popover
                                            id="Save-Popover"
                                            open={savePopupState.open}
                                            anchorEl={anchorEl.current!}
                                            // onClose={closeSavePopup}
                                            // anchorPosition={{
                                            //   top: 0,
                                            //   left: 0 
                                            // }}
                                            anchorOrigin={{
                                              vertical: 'top',
                                              horizontal: 'center',
                                            }}
                                            transformOrigin={{
                                              vertical: 'bottom',
                                              horizontal: 'center',
                                            }}
                                            PaperProps={{
                                              style: {
                                                width: "300px",
                                                backgroundColor: "transparent",
                                                borderRadius: 5,
                                                overflow: "visible"
                                              }
                                            }}
                                          >
                                            <SavePopup 
                                              enableEditMode={editAnnotation} 
                                              closeSavePopup={closeSavePopup} 
                                              crossIconOfSavePopupClickHandler={crossIconOfSavePopupClickHandler} 
                                              setVideoAnnotations={setVideoAnnotations} 
                                              keepOpenSavePopup={keepOpenSavePopup} 
                                              setAddingAnnotationMode={setAddingAnnotationMode} 
                                              annotationLabelsAvailable={annotationLabelsAvailable}
                                            />
                                            <Box className={classes.arrowDown} />
                                          </Popover>
                                          
                                        <div
                                          className={`${css.annotationSection}`}
                                          onMouseEnter={() => handleMouseEnter(index, "annotationByExpert")} onMouseLeave={handleMouseLeave}  
                                          onContextMenu={(event: React.MouseEvent<HTMLDivElement>) => {
                                            handleAnnotationContextMenu(event)
                                            setIndexOfAnnotationMenu(`${index}-by-expert`)
                                          }}
                                        >
                                          { `${index}-by-expert` === indexOfAnnotationMenu && <AnnotationContextMenu
                                              anchorEl={annotationMenuAnchorEl}
                                              setAnchorEl={setAnnotationMenuAnchorEl}
                                              startTime={videoAnnotation.annotationByExpert?.startTimeInSeconds || 0}
                                              endTime={videoAnnotation.annotationByExpert?.endTimeInSeconds||0}
                                              onSendtoModel={handleOnSendToModel} selectedVideoAnnotation={videoAnnotation} selectedResource={props.resource} />}
                                          <AnnotationStartTimeAndEndTimeComponent
                                            annotationPositionLeft={expertAnnotationPositionLeft}
                                            annotationWidth={expertAnnotationWidth}
                                            // show={hoverAnnotationIndex?.index === index && hoverAnnotationIndex.annotationType === "annotationByExpert"}
                                            show={true}
                                            startTimeInSeconds={videoAnnotation.annotationByExpert?.startTimeInSeconds || 0}
                                            endTimeInSeconds={videoAnnotation.annotationByExpert?.endTimeInSeconds || 0}
                                            deleteButton={{
                                              shouldShowItAsReset:
                                                videoAnnotation.annotationByExpert &&
                                                videoAnnotation.annotationPrediction
                                                ? true
                                                : false,
                                              shouldShow: 
                                                isEditMode && 
                                                // below condition will be false if user is creating a new annotation which is not saved which shows up in orange colour
                                                !(!videoAnnotation.id  && ["annotating", "yetToStartAdding", "editing"].includes(addingAnnotationMode.status))
                                                ? true
                                                : false,
                                              onClick: () => {
                                                setAnnotationDeleteConfirmationPopup({
                                                  open: true,
                                                  annotationIndex: index,
                                                  annotationType: "expert"
                                                })
                                              }
                                            }}
                                          />

                                          <DeleteAnnotationConfirmationTooltip
                                            open={
                                              annotationDeleteConfirmationPopup.open &&
                                              annotationDeleteConfirmationPopup.annotationIndex === index &&
                                              annotationDeleteConfirmationPopup.annotationType === "expert"
                                              ? true
                                              : false                                              
                                            }
                                            message={
                                              videoAnnotation.annotationByExpert &&
                                              videoAnnotation.annotationPrediction
                                              ? "Are you sure if you would like to reset the correction made to detected annotation?"
                                              : "Are you sure if you would like to delete this annotation?"
                                            }
                                            deleteButtonText={
                                              videoAnnotation.annotationByExpert &&
                                              videoAnnotation.annotationPrediction
                                              ? "Reset"
                                              : "Delete"
                                            }
                                            onClose={()=>{
                                              setAnnotationDeleteConfirmationPopup(oldState=>({
                                                ...oldState,
                                                open: false
                                              }))                                              
                                            }}
                                            onDeleteButtonClick={()=>{
                                              setAnnotationDeleteConfirmationPopup(oldState=>({
                                                ...oldState,
                                                open: false
                                              }))   
                                              
                                              /**
                                               * resetting hoverAnnotationIndex because hover was not getting removed after delete click by default
                                               */
                                              sethoverAnnotationIndex({
                                                index: -1,
                                                annotationType: ""
                                              })      
                                              
                                              setVideoAnnotations(oldVideoAnnotations=>{
                                                for (let oldVideoAnnotationIndex = 0; oldVideoAnnotationIndex < oldVideoAnnotations.length; oldVideoAnnotationIndex++) {
                                                  let oldVideoAnnotation = oldVideoAnnotations[oldVideoAnnotationIndex];
                                                  if (oldVideoAnnotationIndex === index) {
                                                    oldVideoAnnotation.annotationByExpert = undefined
                                                    if (oldVideoAnnotation?.annotationPrediction) {
                                                      oldVideoAnnotation.annotationPrediction.approvalStatus = 'approved';
                                                    }
                                                    if (
                                                      !oldVideoAnnotation.annotationPrediction ||
                                                      Object.keys(oldVideoAnnotation.annotationPrediction)?.length === 0
                                                    ) {
                                                      oldVideoAnnotations.splice(oldVideoAnnotationIndex, 1);
                                                    } else {
                                                      oldVideoAnnotation = {...oldVideoAnnotation}
                                                    }
                                                  }
                                                }
                                                return [...oldVideoAnnotations]
                                              })                                              
                                            }}
                                          >
                                          <div
                                            className={`${classes.videoAnnotation} ${(!videoAnnotation.id  && ["annotating", "yetToStartAdding", "editing"].includes(addingAnnotationMode.status)) ? classes.newVideoAnnotation : ""}`}
                                            id={videoAnnotation.id && videoAnnotation.label ? `videoAnnotationId_${videoAnnotation.id}` : "newVideoAnnotationId"}
                                            // className={`${classes.videoAnnotation} ${!videoAnnotation.label ? classes.newVideoAnnotation : ""}`}
                                            style={{ zIndex: 2, left: `${expertAnnotationPositionLeft}%`, width: `${expertAnnotationWidth}%`, opacity: (hoverAnnotationIndex?.index === index && hoverAnnotationIndex.annotationType === "annotationByExpert") ? 1 : CORRECT_ANNOTATION_OPACITY_WHEN_NOT_HOVERING }}
                                          >

                                            <div
                                              style={{ gap: expertAnnotationWidth && expertAnnotationWidth >= 2 ? "1px" : "0px", marginLeft: expertAnnotationWidth && expertAnnotationWidth >= 2 ? "3px" : "0px" }}
                                              className={`
                                                ${classes.annotationBeginningDragHandle} 
                                                ${isEditMode ? classes.cursorGrab : ""} 
                                                ${!isEditMode ? classes.visibilityHidden : ""}
                                                ${annotationDragHandle.videoAnnotationId === videoAnnotation.id && annotationDragHandle.grabbedFrom === "start" ? classes.anotationDragHandleGrabbedByUser : ""}
                                              `}
                                              onMouseDown={(event) => grabFromStartMouseDownHandler(event, index, "annotationByExpert")} >
                                              {
                                                (hoverAnnotationIndex?.index === index) && hoverAnnotationIndex.annotationType === "annotationByExpert" &&
                                                <>
                                                  <div className={classes.verticalBar}></div>
                                                  <div className={classes.verticalBar}></div>
                                                </>
                                              }
                                            </div>

                                            <div
                                              className={` 
                                                ${isEditMode ? classes.cursorGrab : ""} 
                                                ${annotationDragHandle.videoAnnotationId === videoAnnotation.id && annotationDragHandle.grabbedFrom === "middle" ? classes.anotationDragHandleGrabbedByUser : ""}
                                              `}
                                              style={{ width: "calc(100% - 17px)" }}
                                              onMouseDown={(event) => grabFromMiddleMouseDownHandler(event, index, "annotationByExpert")}>
                                            </div>

                                            <div
                                              style={{ gap: expertAnnotationWidth && expertAnnotationWidth >= 2 ? "1px": "0px" }}
                                              // ${((isEditMode && hoverAnnotationIndex?.index === index && hoverAnnotationIndex.annotationType === "annotationByExpert") || (!videoAnnotation.tag && !videoAnnotation.label && hoverAnnotationIndex?.index === index && hoverAnnotationIndex.annotationType === "annotationByExpert" && addingAnnotationMode.isOn && addingAnnotationMode.status === "annotating")) ? "" : classes.visibilityHidden} 
                                              className={`
                                                ${classes.annotationEndDragHandle} 
                                                ${(isEditMode) || (addingAnnotationMode.isOn && addingAnnotationMode.status === "annotating") ? classes.cursorGrab : ""} 
                                                ${((isEditMode && hoverAnnotationIndex?.index === index && hoverAnnotationIndex.annotationType === "annotationByExpert") || (!videoAnnotation.tag && !videoAnnotation.label && hoverAnnotationIndex?.index === index && hoverAnnotationIndex.annotationType === "annotationByExpert" && addingAnnotationMode.isOn && addingAnnotationMode.status === "annotating")) ? "": classes.visibilityHidden}
                                                ${annotationDragHandle.videoAnnotationId === videoAnnotation.id && annotationDragHandle.grabbedFrom === "end" ? classes.anotationDragHandleGrabbedByUser : ""}
                                              `}
                                              onMouseDown={(event) => grabFromEndMouseDownHandler(event, index, "annotationByExpert")}>
                                              {
                                                <>
                                                  <div className={classes.verticalBar}></div>
                                                  <div className={classes.verticalBar}></div>
                                                </>
                                              }
                                            </div>
                                          </div>
                                          </DeleteAnnotationConfirmationTooltip>
                                          
                                          {
                                            videoAnnotation.tag &&
                                            <AnnotationTagComponent
                                              annotationPositionLeft={expertAnnotationPositionLeft}
                                              annotationWidth={expertAnnotationWidth}
                                              tag={videoAnnotation.tag}
                                              // show={hoverAnnotationIndex?.index === index && hoverAnnotationIndex.annotationType === "annotationByExpert"}
                                              show={true}
                                            />
                                          }

                                          {
                                            videoAnnotation.id && 
                                            !isEditMode &&
                                            // !resource.arevideoAnnotationsBeingUpdatedInDB &&
                                            <div
                                              style={{ position: "absolute", left: `${expertAnnotationPositionLeft! - 6}%`, width: `${expertAnnotationWidth! + 8}%`, display: "flex", justifyContent: "space-between" }}
                                            >
                                              <>
                                                <div>
                                                  <RejectIcon
                                                    status={videoAnnotation?.annotationByExpert?.approvalStatus ?? null}
                                                    colorToShow={videoAnnotation?.annotationByExpert?.approvalStatus === "rejected" ? "#CCCCCC" : "#EF8A8A"}
                                                    className={`${css.videoAnnotationRejectIcon}`}
                                                    style={{ top: "40px", cursor: "pointer", visibility: "hidden" }}
                                                    onClick={() => updateStatusOfAnnotationAndSaveInDB(index, "rejected", "annotationByExpert")}
                                                  >
                                                  </RejectIcon>
                                                </div>

                                                <div>
                                                  <ApproveIcon
                                                    status={videoAnnotation?.annotationByExpert?.approvalStatus ?? null}
                                                    colorToShow={videoAnnotation?.annotationByExpert?.approvalStatus === "approved" ? "#CCCCCC" : "#008d9c"}
                                                    className={`${css.videoAnnotationApproveIcon}`}
                                                    style={{ top: "35px", cursor: "pointer" }}
                                                    onClick={() => updateStatusOfAnnotationAndSaveInDB(index, "approved", "annotationByExpert")}
                                                  >
                                                  </ApproveIcon>
                                                </div>
                                              </>
                                            </div>
                                          }
                                        </div>
                                        </>
                                      }

                                      {
                                        videoAnnotation.label && !hideLabel(index, videoAnnotation.annotationPrediction?.startTimeInSeconds, videoAnnotation.annotationPrediction?.endTimeInSeconds, videoAnnotation.annotationPrediction?.isDeleted) &&
                                        <AnnotationLabelComponent
                                          annotationPositionLeft={annotationByExpertExist ? expertAnnotationPositionLeft! : predictedAnnotationPositionLeft}
                                          annotationWidth={annotationByExpertExist ? expertAnnotationWidth : predictedAnnotationWidth}
                                          label={videoAnnotation.label}
                                        />
                                      }

                                      {/* {
                                        videoAnnotation.tag &&
                                        <AnnotationTagComponent
                                          annotationPositionLeft={expertAnnotationPositionLeft}
                                          annotationWidth={expertAnnotationWidth}
                                          tag={videoAnnotation.tag}
                                          show={hoverAnnotationIndex?.index === index && hoverAnnotationIndex.annotationType === "annotationByExpert"}
                                        />
                                      } */}
                                    </>
                                  );
                                })
                                : <></>
                              }
                            </div>
                        }

                        {
                          !cannotPlayVideo &&
                          <div className={classes.buttons}>
                            {
                              (addingAnnotationMode.isOn || isEditMode || resource?.arevideoAnnotationsBeingUpdatedInDB) &&
                              <CancelButton
                                editAnnotationMode={!addingAnnotationMode.status ? true : false}
                                arevideoAnnotationsBeingUpdatedInDB={resource?.arevideoAnnotationsBeingUpdatedInDB as boolean}
                                cancelAnnotation={cancelAnnotation}
                              />
                            }
                            {
                              (addingAnnotationMode.isOn && addingAnnotationMode.status === "yetToStartAdding") &&
                              <Button
                                style={{ width: "150px", paddingTop: "8px", paddingBottom: "8px", font: "normal normal normal 13px/20px Nunito Sans", textTransform: "none" }}
                                color="primary"
                                size="small"
                                variant="outlined"
                                onClick={startAnnotationClickHandler}
                              >
                                Start Annotation
                              </Button>
                            }
                            {
                              (addingAnnotationMode.isOn && addingAnnotationMode.status === "annotating") &&
                              <Button
                                style={{ width: "150px", paddingTop: "8px", paddingBottom: "8px", font: "normal normal normal 13px/20px Nunito Sans", textTransform: "none" }}
                                color="primary"
                                size="small"
                                variant="outlined"
                                onClick={(event) => endAnnoationClickHandler(event)}
                              >
                                End Annotation
                              </Button>
                            }
                            {
                              isEditMode || resource?.arevideoAnnotationsBeingUpdatedInDB || (addingAnnotationMode.isOn && addingAnnotationMode.status === "editing")
                                ?
                                <>
                                  <Button
                                    style={{ backgroundColor: themePrimaryMainColor, width: "150px", color: "white", paddingTop: "8px", paddingBottom: "8px", font: "normal normal normal 13px/20px Nunito Sans", textTransform: "none", opacity: resource?.arevideoAnnotationsBeingUpdatedInDB ? 0.7 : 1}}
                                    size="small"
                                    variant="outlined"
                                    onClick={saveAnnoationInDb}
                                    disabled={(resource?.arevideoAnnotationsBeingUpdatedInDB)}
                                  >
                                    {resource?.arevideoAnnotationsBeingUpdatedInDB ? "Saving Changes..." : "Save Changes"}
                                  </Button>
                                </>
                                :
                                <>
                                  {
                                    !addingAnnotationMode.isOn &&
                                    <>
                                      <Button
                                        style={{ width: '150px', paddingTop: "8px", paddingBottom: "8px", font: "normal normal normal 13px/20px Nunito Sans", textTransform: "none" }}
                                        color="primary"
                                        size="small"
                                        variant="outlined"
                                        onClick={editAnnotation}
                                      >
                                        Edit Annotation
                                      </Button>

                                      <Button
                                        style={{ backgroundColor: themePrimaryMainColor, width: "150px", color: "white", paddingTop: "8px", paddingBottom: "8px", font: "normal normal normal 13px/20px Nunito Sans", textTransform: "none" }}
                                        size="small"
                                        variant="outlined"
                                        onClick={addAnnotationClickHandler}
                                      >
                                        Add Annotation
                                      </Button>
                                    </>
                                  }
                                </>
                            }
                          </div>
                        }
                      </div>
                    }

                    {/* Side Detail Page  */}
                    <div className={classes.sideBar}>
                      {/* Top Action Items */}
                      <div className={classes.topMenu}>

                        {/* left side image in top menu  */}
                        <div className={classes.topBarLabel}>
                          <div style={{ display: "flex", gap: "60px" }}>
                            {
                              resource &&
                              <LabelEditText
                                readonly={false && (selectedSubTab === 'approved' || selectedSubTab === 'deleted' || selectedSubTab === 'rejected') ? true : false}
                                // labelsAvailable={LabelsAvailable}
                                labelsAvailable={labelOptions()}
                                resource={resource}
                                allowUserToSetAnyLabel={allowUserToSetAnyLabel}
                                onEditTextFocus={() => { setShouldBlockDataTableKeyboardEvents(true) }}
                                onEditTextBlurEvent={() => { setShouldBlockDataTableKeyboardEvents(false) }}
                                placeWhereComponentBeingUsed="data-table"
                              />
                            }
                            <ActionButtonArray gap={"20px"} closePopup={onClose} annotationLabelsForVideoAnnotationPopup={labelsOfVideoAnnotationResourceThatAreNotApprovedOrRejectedByExpert} />
                          </div>
                        </div>
                      </div>

                      {/* <div style={{ height: "400px", overflow: "auto", marginBottom: "20px", marginLeft: "0px" }}> */}
                      <div style={{ height: "538px", overflow: "auto", marginBottom: "20px", marginLeft: "0px" }}>

                        <FileNameComponent isVideoAnnotationViewPopup={true} filename={resource?.filename} isShowingInForecastingViewPopup={false} />

                            {
                              resource?.parentResourceId &&
                              <div style={{marginBottom: "20px"}}>
                                <VisitParentResourceLink 
                                  parentResourceId={resource?.parentResourceId || ""}
                                />                              
                              </div>
                            }

                        <TagComponent
                          isVideoAnnotationViewPopup={true}
                          setShouldBlockDataTableKeyboardEvents={props.setShouldBlockDataTableKeyboardEvents}
                          tag={resource?.tag}
                          resource={resource}
                          isShowingInForecastingViewPopup={false}
                          inCopilot={false}
                        />

                          {
                            resource?.hdf5FileDownloadUrl &&
                            <div
                              style={{
                                display: "flex",
                                alignItems: "baseline"
                              }}
                            >
                              <div className={classes.details}
                                style={{
                                  minWidth: "170px",
                                  color: "rgb(142, 150, 158)"
                                }}
                              >HDF5 File :</div>
                              <Link href={resource?.hdf5FileDownloadUrl}>{resource?.hdf5FileName}</Link>
                            </div>
                          }

                          <div style={{ marginBottom: "10px", color: `${"#8e969e"}` }}>{"Add Remarks"}:</div>
                            <textarea
                              style={{ width: "100%", minHeight: "83px", marginBottom: "10px", fontSize: "15px", fontFamily: 'Nunito Sans', borderRadius: "5px" }}
                              value={resourceRemarksTextState.value}
                              onFocus={() => props.setShouldBlockDataTableKeyboardEvents(true)}
                              onChange={(event) => { onChangeRemark(event) }}
                              onBlur={() => { onBlurRemarkField() }}
                              disabled={props.resource?.isResourceRemarksBeingUpdatedInDB ? true : false}
                            />
                            <div style={{
                                display: "flex",
                                flexDirection: "row",
                                gap: 10
                            }}>
                            <Button
                              color="primary"
                              variant="contained"
                              onClick={onSaveRemarksClick}
                              style={{marginBottom:"20px"}}
                              disabled={props.resource?.isResourceRemarksBeingUpdatedInDB || !resourceRemarksTextState.isDirty ? true : false}
                            >
                              Save Remarks
                            </Button>
                            <Button color="primary" variant="contained"
                                onClick={()=>{
                                setIsViewAsCSVOpen(true)
                                }}
                            >
                                View As CSV
                            </Button> 
                            {
                              isViewAsCSVOpen&&
                                <ViewAsCSVDialog resources={[props.resource]} open={isViewAsCSVOpen} setOpen={setIsViewAsCSVOpen} onClose={handleCloseCSVDialog}/>
                            }
                            </div>


                        {
                          showMoreFields &&
                          <>
                            <ShowMoreFieldsComponent mT={0} toggleShowMore={toggleShowMore} showMoreFields={showMoreFields} ></ShowMoreFieldsComponent>

                            <ShowResourceDetails
                            updateResourceInUI={props.updateResourceInUI}
                              isVideoAnnotationViewPopup={true}
                              resource={props.resource}
                              isFetchingResourceMetadataFileUrl={isFetchingResourceMetadataFileUrl}
                              resourceMetadataFileDownloadUrl={resourceMetadataFileDownloadUrl}
                              setShouldBlockDataTableKeyboardEvents={props.setShouldBlockDataTableKeyboardEvents}
                              resourceType={props.resource?.model?.type}
                              dataSetCollections={props.resource?.dataSetCollections}
                              changesMade={props.resource?.changesMade}
                              inCopilot={false}
                              isShowingInForecastingViewPopup={false}
                              selectedSubTab={selectedSubTab}
                              allowUserToSetAnyLabel={props.allowUserToSetAnyLabel}
                            />

                          </>
                        }

                        <ShowMoreFieldsComponent mT={25} toggleShowMore={toggleShowMore} showMoreFields={showMoreFields} ></ShowMoreFieldsComponent>

                      </div>

                      <div
                        className={`${css.aiAnnotationsCountSection}`}
                      >
                        <div className={css.aiAnnotationsCountSection_detectedSection}>
                          <div className={css.countSectionLabel}>Detected</div>
                          <div className={css.countSectionCount}>{detectedCountForCountSection}</div>
                        </div>
                        <div className={css.aiAnnotationsCountSection_expertFeedbackSection}>
                          <div className={css.countSectionLabel}>Expert Feedback</div>
                          <div className={css.countSectionCount}>{expertFeedbackCountForCountSection}</div>
                        </div>
                      </div>

                    </div>
                  </div>
                      
                  <div className={css.infoRow}>
                        {
                          infoText &&
                          <div className={css.infoRowLHS}>
                              <VideoAnnotationPopupInfoIcon
                              />

                              <div className={css.annotationInfoText}>
                                {infoText}

                              </div>
                          </div>
                        }
                    <div className={css.infoRowRHS}>
                      <div className={`
                        ${css.annotationColourLegend}
                        ${!isAnyCorrectAnnotationPresent ? css.visibilityHidden : ""}
                      `}>
                        <div className={`${css.annotationColour} ${css.correctAnnotationColour}`}></div>
                        {/* <div className={css.annotationLabel}>Correct</div> */}
                        <div className={css.annotationLabel}>Annotations</div>
                      </div>
                      <div className={`
                        ${css.annotationColourLegend}
                        ${!isAnyDeletedDetectedAnnotationPresent && !isAnyInCorrectAnnotationPresent ? css.visibilityHidden : ""}
                      `}>
                        <div className={`${css.annotationColour} ${css.incorrectAnnotationColour}`}></div>
                        <div className={css.annotationLabel}>
                          {/* {
                            isAnyInCorrectAnnotationPresent
                            ? `Incorrectly predicted ${isAnyDeletedDetectedAnnotationPresent ? "/ deleted " : ""}annotations`
                            : ""
                          } */}
                          Incorrectly predicted annotations
                          {/* {
                            `
                              ${isAnyInCorrectAnnotationPresent ? `Incorrectly predicted annotations` : ``} 
                              ${isAnyInCorrectAnnotationPresent && isAnyDeletedDetectedAnnotationPresent ? " / " : ""}
                              ${isAnyDeletedDetectedAnnotationPresent ? `${!isAnyInCorrectAnnotationPresent ? "D" : "d"}eleted` : ""} annotations
                              `
                          } */}
                          {/* {
                            isAnyDeletedDetectedAnnotationPresent
                            ? "Deleted"
                            : ""
                          } */}
                          {/* Incorrect / Deleted */}
                        </div>
                      </div>
                    </div>
                  </div>
                </div>

              </div>
            </>
        }

      </div>
    </Dialog>

  );
};

const mapProps = (state: IReduxState) => {
  return {
  };
};

const connector = connect(mapProps, {
  updateResourceInUI
});
export type TPropsFromRedux = ConnectedProps<typeof connector>;
export default connector(VideoAnnotationDialogBox);

import React, { useCallback, useEffect, useRef, useState } from 'react';
import Dialog from '@material-ui/core/Dialog';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import { Crop,Rotate90DegreesCcw,Loop,ZoomIn, Save, AspectRatio } from '@material-ui/icons';
import { Button, CircularProgress, FormControl, IconButton, Input, InputLabel, MenuItem, Popover, Select, Slider, TextField, Tooltip, Typography } from '@material-ui/core';
import styles from './editimage.module.css';
import flipHorizontalImage from './assets/FlipHorizontal.svg';
import flipVerticalImage from './assets/FlipVertical.svg';
import flipHorizontalDisabledImage from './assets/FlipHorizontalDisabled.svg';
import flipVerticalDisabledImage from './assets/FlipVerticalDisabled.svg';
import Cropper from 'react-easy-crop';
import getCroppedImg, { calcScale, getHorizontalFlippedImage, getUnCroppedRotatedImage, getVerticalFlippedImage } from './CropImageCanvas';
import { IData, IModel, useQuery } from '../../../../../../../../../../../../common';
import { DataService, ModelService } from '../../../../../../../../../../../../services';
import CloseIcon from '@material-ui/icons/Close';
import DialogBox from './DialogBox';



type AspectRatio = {
  x:number,
  y:number
}
type AspectRatioInput = {
  x:string,
  y:string
}

type ActiveAspectRatioType = "4:3" | "16:9" | "1:1" | "custom"
// let originalImage:string = "";
const rotationMarks = [
    {
      value: 0,
      label: '0°',
    },
    {
      value: 90,
      label: '90°',
    },
    {
      value: 180,
      label: '180°',
    },
    {
      value: 270,
      label: '270°',
    },
    {
      value: 360,
      label: '360°',
    },
  ];
  const zoomScale = [
    {
        value:1,
        label:'1'
    },
    {
        value:2,
        label:'2'
    },
    {
        value:3,
        label:'3'
    },
    {
        value:4,
        label:'4'
    },
    {
        value:5,
        label:'5'
    },
    {
      value:6,
      label:'6'
    },
    {
        value:7,
        label:'7'
    },
    {
        value:8,
        label:'8'
    },
  ]
  
type tools = "crop" | "none"
type IconContainerProps = {
  icon:any,
  isActive?:boolean,
  disabled?:boolean,
  style?:React.CSSProperties,
  onClick?:()=>void,
  tooltipTitle : string
}
function IconContainer({icon,style,disabled,isActive=false,onClick, tooltipTitle}:IconContainerProps){
    return (
      <Tooltip title={tooltipTitle}>
        <div className={`${styles.iconContainer} ${isActive?styles.activeIcon:''} ${disabled?styles.disabled:''}`} style={{...style,pointerEvents:disabled?'none':undefined}} onClick={onClick}>
            {icon}
        </div>
      </Tooltip>
    )
}
let croppedPixelData:any = null;
let shouldShowZoomWarning:boolean = true;
let shouldShowCropWarning:boolean = true;
let shouldShowAspectRatioWarning:boolean = true;
let selectedTool:"zoom" | "crop" | "ar"  | "" = "";
export default function EditImage(props : {resource : IData, openPopup: boolean, onClosePopup : ()=>void}) {
  // const [open, setOpen] = React.useState(false);
  const [activeTool,setActiveTool] = useState<tools>("none")
  
  const [aspectRatio,setAspectRatio] = useState<AspectRatio>({x:4,y:3})
  const [aspectRatioInput,setAspectRatioInput] = useState<AspectRatioInput>({x:'4',y:'3'})
  const [activeAspectRatio,setActiveAspectRatio] = useState<ActiveAspectRatioType>("4:3")
  
  const [crop, setCrop] = useState({ x: 0, y: 0 })
  const [rotation, setRotation] = useState<number | number[]>(0)
  const [isCropRotationActive,setIsNoCropRotationActive] = useState<boolean>(false)
  const [prevRotation,setPrevRotation] = useState<number>(0)
  const [noCropRotation,setNoCropRotation] = useState<number | number[]>(0)
  const [noCropRotationScale,setNoCropRotationScale] = useState<number>(1)
  const [croppedAreaPixels,setCroppedAreaPixels] = useState<any>(null);
  const [zoom, setZoom] = useState<number>(1)
  const [imageUrl,setImageUrl] = useState<string>(props.resource.resource)
  const [imageScale,setImageScale] = useState<number>(0);
  const originalImage = useRef('')
  // popover
  const [anchorEl, setAnchorEl] = useState<HTMLButtonElement | null>(null);
  //states for adding new resource
  const [name, setName] = useState<string>(props.resource.filename.substring(0, props.resource.filename.lastIndexOf('.')) || '')
  const [label, setLabel] = useState<string>(props.resource.label || '')
  const [tag, setTag] = useState<string>(props.resource.tag || '')
  const [csv, setcsv] = useState<string>(props.resource.csv || '')
  const [status, setStatus] = useState<string>(props.resource.status || '')
  const [prediction, setPrediction] = useState<string>(props.resource.prediction || '')
  const [confidenceScore, setConfidenceScore] = useState<number>(Number(props.resource.confidence_score) || 90)
  //data for api calls
  const [modelsList, setModelsList] = useState<IModel[] | []>([])
  // const [parentModel, setParentModel] = useState<IModel | null>(null);
  const [selectedChildModel, setSelectedChildModel] = useState<string>(props.resource.model._id)
  // states for dialog box
  const [open,setOpen] = useState<boolean>(false);
  function onClose(){
    setOpen(false);
  }
  const query = useQuery();
  
  const handleAspectRatio = (event: React.MouseEvent<HTMLButtonElement>) => {
    if(shouldShowAspectRatioWarning){
      selectedTool = "ar";
      setOpen(true);
      return;
    }
    setActiveTool("crop")
    setAnchorEl(event.currentTarget);
  };

  const aspectRatioOnClose = () => {
    setAnchorEl(null);
  };
  
  const isPopupOpen = Boolean(anchorEl);
  const id = props.openPopup ? 'simple-popover' : undefined;

  const onCropComplete = (croppedArea:any, croppedAreaPixels:any) => {
    setCroppedAreaPixels(croppedAreaPixels);
  }
  function disableNoCropRotation(){
    setNoCropRotationScale(1);
    setNoCropRotation(0);
    setIsNoCropRotationActive(false);
    setPrevRotation(noCropRotation as number + prevRotation);
  }
  function onCrop(){
    if(shouldShowCropWarning){
      selectedTool = "crop";
      setOpen(true);
      return;
    }
    setActiveTool('crop')
  }
  async function onCropOrRotate(){
      if(isCropRotationActive){
        let croppedImageUrl = props.resource.resource;
        console.log("Cropped Pixel data",croppedAreaPixels)
        if(croppedPixelData !== null){
          croppedImageUrl = await getCroppedImg(croppedImageUrl,croppedPixelData);
        }
        croppedImageUrl = await getUnCroppedRotatedImage(croppedImageUrl,noCropRotation as number + prevRotation);
        setImageUrl(croppedImageUrl);
        disableNoCropRotation()
      }
      else if(croppedAreaPixels !== null){
        croppedPixelData = {...croppedAreaPixels};
        const croppedImageUrl = await getCroppedImg(props.resource.resource,croppedAreaPixels,rotation as number);
        setImageUrl(croppedImageUrl);
        setCroppedAreaPixels(null)
        setActiveTool('none')
      }
      shouldShowZoomWarning = true;
      shouldShowCropWarning = true;
      shouldShowAspectRatioWarning = true;
      selectedTool = "";
  }
  async function onFlipHorizontal(){
    const flippedImageUrl = await getHorizontalFlippedImage(imageUrl);
    setImageUrl(flippedImageUrl);
  }
  async function onFlipVertical(){
    const flippedImageUrl = await getVerticalFlippedImage(imageUrl);
    setImageUrl(flippedImageUrl);
  }
  function onReset(){
    shouldShowAspectRatioWarning = true;
    shouldShowZoomWarning = true;
    shouldShowCropWarning = true;
    setIsNoCropRotationActive(false);
    setNoCropRotation(0);
    setRotation(0);
    setZoom(1);
    setCroppedAreaPixels(null);
    setAspectRatio({x:4,y:3})
    setActiveAspectRatio('4:3')
    setAspectRatioInput({x:'4',y:'3'})
    setCrop({x:0,y:0})
    setActiveTool('none')
    setNoCropRotationScale(1)
    setImageUrl(originalImage.current)
    croppedPixelData = null;
    setPrevRotation(0)
  }
  const handleClose = () => {
      onReset()
      setAnchorEl(null)
      props.onClosePopup();
  };
  function valuetext(value: number) {
      return `${value}°C`;
  }
  useEffect(()=>{
    originalImage.current = imageUrl;
    modelFetchApi()
  },[])
  function onZoomWarningProceed(){
    if(selectedTool === "zoom"){
        shouldShowZoomWarning = false;
    }
    else if(selectedTool === "crop"){
      shouldShowCropWarning = false;
    }
    else if(selectedTool === "ar"){
      shouldShowAspectRatioWarning = false;
    }
    setOpen(false)
  }
  function onZoomWarningNotProceed(){
    if(selectedTool === "zoom"){
      shouldShowZoomWarning = true;
    }
    else if(selectedTool === "crop"){
      shouldShowCropWarning = true;
    }
    setOpen(false)
  }
  
  function onNoCropRotate(rotation: number | string){
    // setImageUrl(props.resource.resource);
    if(!isCropRotationActive)
      setIsNoCropRotationActive(true);
    if(activeTool !== 'none')
      setActiveTool('none');
    if(noCropRotationScale !== imageScale)
      setNoCropRotationScale(imageScale);
    if(typeof(rotation) === "string"){
      if(rotation && rotation.trim() && /^[0-9]*$/.test(rotation))
        setNoCropRotation(parseFloat(rotation)% 361)
      else
        setNoCropRotation(0)
    }
    else{
      if(rotation < 0)
        setNoCropRotation(0);
      else
        setNoCropRotation(rotation % 361)
    }
  }
  function onZoom(zoom:number | string){
    if(shouldShowZoomWarning){
      selectedTool = "zoom";
      setOpen(true);
      return;
    }
    if(activeTool === 'none')
      setActiveTool('crop');
      if(typeof(zoom) === "string"){
        if(zoom && zoom.trim() && /^[0-9]*$/.test(zoom))
          setZoom(parseFloat(zoom))
        else
          setZoom(1)
      }
      else{
        if(zoom < 0)
          setZoom(1);
        else 
          setZoom(zoom)
      }
  }
  function updatePredefinedAspectRatio(value:AspectRatio,type:ActiveAspectRatioType){
    setActiveAspectRatio(type);
    setAspectRatio(value);
    setAspectRatioInput({x:value.x.toString(),y:value.y.toString()})
  }
  function updateAspectRatio(value: string, type: string): void {
    setAspectRatioInput({...aspectRatioInput,[type]:value})
    if(value !== undefined && value !== null && value !== "" && value.trim().length !== 0){
      setAspectRatio({...aspectRatio,[type]:parseInt(value)})
    }
  }

  const modelFetchApi = useCallback(
    async() => {
      const project = query.get('project') || '';
      const modelType = props.resource.model.type;
      // const modelId = query.get('model') || '';

      if (project && modelType) {
        const apiResponse = await ModelService.getProjectModels({
          project: project,
          type: modelType,
          offset: '1',
          limit: '10000'
        })
        if(apiResponse){
          const availableChildModels = apiResponse.data.models
          setModelsList(availableChildModels)
          // setSelectedChildModel(apiResponse.data.models.find(model => model._id === modelId)?._id || props.resource.model._id)
          // setParentModel(apiResponse.data.models.find(model => model._id === modelId) || null)
          setSelectedChildModel(props.resource.model._id)
        }
      }
    },
    [originalImage],
  )

  useEffect(()=> {
    console.log('selected child model is :: ', selectedChildModel)
  },[selectedChildModel])

  const onSubmit = async (): Promise<void> => {
    // if (!isFormValid()) {
    //   showErrorsOfAllFields();
    //   return;
    // }

    const bodyFormData = new FormData();
    bodyFormData.append("model", selectedChildModel || "");
    bodyFormData.append("status", status);
    if(imageUrl.length > 0) {
        console.log('image url is :: ', imageUrl)
        const response = await fetch(imageUrl);
        const blob = await response.blob();
        const file = new File([blob], `${name}.png`, {type: blob.type});
        bodyFormData.append("resource", file);
        console.log('file is :: ', file)
    }
    /* Required when upload resource with signed URL  */
    // bodyFormData.append("appShouldNotUploadResourceFileToGCS", "true");
    // bodyFormData.append("resourceFileName", file.current?.name!);
    // bodyFormData.append("resourceContentType", file.current?.type!);

    // bodyFormData.append("resource", file?.value!);
    bodyFormData.append("confidence_score", confidenceScore.toString());
    bodyFormData.append("prediction", prediction);
    bodyFormData.append("label", label);
    bodyFormData.append("tag", tag);
    bodyFormData.append("csv", csv);

    console.log('bodyFormData is : ', bodyFormData, bodyFormData.entries())

    // await addResource(bodyFormData);
    await DataService.addResourceInModel(bodyFormData);
    // window.location.reload();
    showSuccessMessage();
  };

  function showSuccessMessage () {
      alert('New Image is successfully added to the selected model!')
  }
  function calculateImageScale(){
    const scale = calcScale('imageContainer','cropImage')
    setImageScale(1 / (scale as number));
  }
  useEffect(()=>{
    if(activeTool === 'crop'){
      setPrevRotation(0)
    }
  },[activeTool])

  const [isUpdatingResource, setIsUpdatingResource] = useState<boolean>(false)

  const handleUpdateOriginalResource = async () => {
    setIsUpdatingResource(true)
    const bodyFormData = new FormData();
    bodyFormData.append("resourceId", props.resource._id)
    bodyFormData.append("status", status);
    if(imageUrl.length > 0) {
        console.log('image url is :: ', imageUrl)
        const response = await fetch(imageUrl);
        const blob = await response.blob();
        const file = new File([blob], `${name}.png`, {type: blob.type});
        bodyFormData.append("resource", file);
        console.log('file is :: ', file)
    }
    bodyFormData.append("confidence_score", confidenceScore.toString());
    bodyFormData.append("prediction", prediction);
    bodyFormData.append("label", label);
    bodyFormData.append("tag", tag);
    bodyFormData.append("csv", csv);
    await DataService.updateOriginalImageResource(bodyFormData);
    setIsUpdatingResource(false)
  }

  return (
    
    <Dialog
        fullWidth
        maxWidth={'xl'}
        open={props.openPopup}
        onClose={handleClose}
        aria-labelledby="edit-dialog"
      >
        <DialogTitle id="edit-dialog-title">
          <div style={{display : 'flex', justifyContent : 'space-between'}}>
            <Typography variant='h6'>Crop and Edit Image</Typography>
            <IconButton style={{padding : '0px'}} onClick={handleClose}>
              <CloseIcon style={{color : 'black'}}/>
            </IconButton>
            </div>
        </DialogTitle>    
        <DialogContent>
          <div className={styles.contentContainer}>
            <div className={styles.imageAndTools}>
                <div className={styles.imageContainer} id='imageContainer'>
                  {(activeTool === 'crop') && <Cropper
                    image={props.resource.resource}
                    crop={crop}
                    rotation={rotation as number}
                    zoom={zoom}
                    aspect={aspectRatio.x / aspectRatio.y}
                    onRotationChange={setRotation}
                    onCropChange={setCrop}
                    onCropComplete={onCropComplete}
                    onZoomChange={setZoom}
                    
                  />}
                  <div className={styles.cropImageContainer}>
                    <img src={imageUrl} className={`${styles.image} ${(activeTool === 'crop') ?styles.hide:''}`} style={{transform:`rotate(${noCropRotation}deg) scale(${noCropRotationScale})`}} onLoad={calculateImageScale} id='cropImage'/>
                  </div>
                </div>
                <div className={styles.iconTray}>
                  <div className={styles.subActionIcon}>
                    <span>Mirror : </span>
                      <IconContainer icon={<img src={activeTool === "none" ? flipHorizontalImage:flipHorizontalDisabledImage} width={25} height={25}/>} onClick={onFlipHorizontal} disabled={activeTool !== "none"} tooltipTitle='Virtical Mirror'/>
                    <IconContainer icon={<img src={activeTool === "none" ? flipVerticalImage:flipVerticalDisabledImage} width={25} height={25}/>} onClick={onFlipVertical} disabled={activeTool !== "none"} tooltipTitle='Horizontal Mirror'/>
                  </div>
                  <div className={styles.subActionIcon}>
                    <IconContainer icon={<Crop  color={activeTool !== "none" || isCropRotationActive ? 'disabled' :'primary'}/>} disabled={activeTool !== "none" || isCropRotationActive} onClick={()=>onCrop()} tooltipTitle='Crop Image'/>
                    <Button aria-describedby={id} variant='contained' size='small' startIcon={<AspectRatio color={isCropRotationActive?'disabled':'primary'}/>} className={styles.aspectRatioBtn} onClick={handleAspectRatio} disabled={isCropRotationActive}>Aspect Ratio</Button>
                  </div>
                  <div className={styles.subActionIcon}>
                    <IconContainer icon={<Save style={{color:'white'}}/>} isActive={activeTool === 'crop' || isCropRotationActive} onClick={onCropOrRotate} disabled={(activeTool === "none" && !isCropRotationActive)} tooltipTitle='Save Editing'/>
                    <Button  variant='contained' size='small' startIcon={<Loop color={'primary'}/>} className={styles.aspectRatioBtn} onClick={onReset}>Reset Changes</Button>
                  </div>
                </div>
                <Popover
                    id={id}
                    open={isPopupOpen}
                    anchorEl={anchorEl}
                    onClose={aspectRatioOnClose}
                    anchorOrigin={{
                      vertical: 'top',
                      horizontal: 'center',
                    }}
                    transformOrigin={{
                      vertical: 'bottom',
                      horizontal: 'center',
                    }}
                  >
                    <div style={{paddingBottom:10,paddingLeft:15,paddingRight:15}}>
                      <div className={styles.aspectRatioContainer}>
                      <Button variant='outlined' size='small' startIcon={<AspectRatio color={'primary'} className={activeAspectRatio === "4:3"?styles.activeIcon:undefined}/>} className={`${activeAspectRatio === "4:3" ? styles.aspectRatioBtnActive:styles.aspectRatioBtn}`} onClick={()=>updatePredefinedAspectRatio({x:4,y:3},"4:3")}>4:3</Button>
                      <Button variant='outlined' size='small' startIcon={<AspectRatio color={'primary'} className={activeAspectRatio === "16:9"?styles.activeIcon:undefined}/>} className={`${activeAspectRatio === "16:9" ? styles.aspectRatioBtnActive:styles.aspectRatioBtn}`} onClick={()=>updatePredefinedAspectRatio({x:16,y:9},"16:9")}>16:9</Button>
                      <Button variant='outlined' size='small' startIcon={<AspectRatio color={'primary'} className={activeAspectRatio === "1:1"?styles.activeIcon:undefined}/>} className={`${activeAspectRatio === "1:1" ? styles.aspectRatioBtnActive:styles.aspectRatioBtn}`} onClick={()=>updatePredefinedAspectRatio({x:1,y:1},"1:1")}>1:1</Button>
                      <Button variant='outlined' size='small' startIcon={<AspectRatio color={'primary'} className={activeAspectRatio === "custom"?styles.activeIcon:undefined}/>} className={`${activeAspectRatio === "custom" ? styles.aspectRatioBtnActive:styles.aspectRatioBtn}`} onClick={()=>updatePredefinedAspectRatio({...aspectRatio},"custom")}>Custom</Button>
                      </div>
                      <div className={styles.customAspectRatio}>
                        <TextField type='number' id="outlined-basic" size='small' label="Width" variant="outlined" value={aspectRatioInput.x} onChange={(e)=>updateAspectRatio(e.target.value,'x')} className={styles.customAspectRatioInput} disabled={activeAspectRatio !== 'custom'}/>
                        <label className={styles.crossLabel}>X</label>
                        <TextField type='number' id="outlined-basic" size='small' label="Height" variant="outlined" value={aspectRatioInput.y} onChange={(e)=>updateAspectRatio(e.target.value,'y')} className={styles.customAspectRatioInput} disabled={activeAspectRatio !== 'custom'}/>
                      </div>
                      <Button onClick={aspectRatioOnClose} variant='contained' fullWidth color='primary'>Save</Button>
                    </div>
                </Popover>
                <div>
                    <div className={styles.imageActions}>
                        <IconContainer icon={<Rotate90DegreesCcw color={'primary'}/>} tooltipTitle='Rotate the Image'/>
                        <Slider
                            defaultValue={0}
                            onChange={(_, rotation) => onNoCropRotate(rotation as number)}
                            getAriaValueText={valuetext}
                            aria-labelledby="discrete-slider"
                            className={styles.slider}
                            valueLabelDisplay="auto"
                            marks={rotationMarks}
                            value={noCropRotation}
                            min={0}
                            max={360}
                        />
                        <TextField variant='standard' size="small"  value={noCropRotation} onChange={(e)=>onNoCropRotate(e.target.value)} className={styles.sliderField} />
                    </div>
                    <div className={styles.imageActions}>
                          <IconContainer icon={<ZoomIn color={isCropRotationActive?'disabled':'primary'}/>} tooltipTitle='Zoom' disabled={isCropRotationActive}/>
                          <Slider
                              disabled={isCropRotationActive}
                              defaultValue={1}
                              onChange={(_, zoom) => onZoom(zoom as number)}
                              getAriaValueText={valuetext}
                              aria-labelledby="discrete-slider"
                              className={styles.slider}
                              valueLabelDisplay="auto"
                              marks={zoomScale}
                              value={zoom}
                              min={1}
                              max={8}
                          />
                        {/* <TextField variant='standard' size="small" value={zoom} onChange={(e)=>onZoom(e.target.value)} className={styles.sliderField}/> */}
                        <Input
                          // variant='standard' 
                          // size="small"
                          className={styles.sliderField}
                          value={zoom}
                          margin="dense"
                          disabled={isCropRotationActive}
                          onChange={(e : any)=>onZoom(e.target.value)}
                          // onBlur={handleBlur}
                          inputProps={{
                            step: 0,
                            min: 1,
                            max: 100,
                            type: 'number',
                            'aria-labelledby': 'input-slider',
                          }}
                        />
                    </div>
                </div>
            </div>
            <div className={styles.labelAndActions}>
                <div className={styles.labels}>
                  <div className={styles.labeledInput}>
                      <Typography variant='body2' className={styles.label}>Name *</Typography>
                      <TextField value={name} variant="outlined" size="small" fullWidth className={styles.textBox} onChange={(e) => setName(e.target.value)}/>
                  </div>
                  <div className={styles.labeledInput}>
                      <Typography variant='body2' className={styles.label}>Label *</Typography>
                      <TextField value={label} variant="outlined" size="small" fullWidth className={styles.textBox} onChange={(e) => setLabel(e.target.value)}/>
                  </div>
                  <div className={styles.labeledInput}>
                      <Typography variant='body2' className={styles.label}>Tag *</Typography>
                      <TextField value={tag} variant="outlined" size="small" fullWidth className={styles.textBox} onChange={(e) => setTag(e.target.value)}/>
                  </div>
                  <div className={styles.labeledInput}>
                      <Typography variant='body2' className={styles.label}>Status *</Typography>
                      <TextField value={status} variant="outlined" size="small" fullWidth className={styles.textBox} onChange={(e) => setStatus(e.target.value)}/>
                  </div>
                  <div className={styles.labeledInput}>
                      <Typography variant='body2' className={styles.label}>Confidence Score *</Typography>
                      <TextField value={confidenceScore} type='number' variant="outlined" size="small" fullWidth className={styles.textBox} onChange={(e) => setConfidenceScore(Number(e.target.value))}/>
                  </div>
                  <div className={styles.labeledInput}>
                      <Typography variant='body2' className={styles.label}>Prediction *</Typography>
                      <TextField value={prediction} variant="outlined" size="small" fullWidth className={styles.textBox} onChange={(e) => setPrediction(e.target.value)}/>
                  </div>
                  <div className={styles.labeledInput}>
                      <Typography variant='body2' className={styles.label}>CSV *</Typography>
                      <TextField value={csv} variant="outlined" size="small" fullWidth className={styles.textBox} onChange={(e) => setcsv(e.target.value)}/>
                  </div>
                    {/* {labels.map(label=><div className={styles.labeledInput}>
                        <Typography variant='body2' className={styles.label}>{label}</Typography>
                        <TextField variant="outlined" size="small" fullWidth className={styles.textBox}/>
                    </div>)} */}
                </div>
                <div className={styles.actions}>
                    <FormControl style={{width:'100%'}}>
                    <InputLabel id="model-selection-helper-label">Task to save *</InputLabel>
                        <Select
                          labelId="model-selection-helper-label"
                          id="model-selection-helper"
                          autoWidth
                          required
                          // defaultValue={selectedChildModel}
                          value={selectedChildModel}
                          onChange={(e) => {
                            setSelectedChildModel(e.target.value + '')
                          }}
                        >
                          {
                            modelsList.map(model => {
                              return <MenuItem key={model._id} value={model._id}>{model.name}</MenuItem>
                            })
                          }
                        </Select>
                        {/* <Select
                        native
                        fullWidth
                        label="Model"
                            inputProps={{
                                name: 'model',
                                id: 'outlined-model-native-simple',
                            }}
                            >
                            <option aria-label="None" value="" />
                            <option value={10}>Ten</option>
                            <option value={20}>Twenty</option>
                            <option value={30}>Thirty</option>
                        </Select> */}
                        
                        {/* <FormHelperText>Select model to copy dataset collection in</FormHelperText> */}
                    </FormControl>
                    <div className={styles.actionBtnContainer}>
                        <Tooltip title='Cancel changes'>
                          <div style={{width: '50%'}}>
                          <Button variant='outlined' color="default" fullWidth style={{marginRight:10}} className={styles.cancelBtn} onClick={handleClose}>Cancel</Button>
                          </div>
                        </Tooltip>
                        <Tooltip title={activeTool === 'crop' ? 'Please save the editing' : (name.length === 0 ||
                            label.length === 0 ||
                            tag.length === 0 ||
                            !confidenceScore ||
                            status.length === 0 ||
                            prediction.length === 0 ||
                            csv.length === 0 || 
                            selectedChildModel.length === 0) ? 'Please fill all the required parameters' :'Save a copy'}>
                          
                          <div style={{width: '50%'}}>
                              <Button variant='contained' color="primary" fullWidth
                            disabled={
                              activeTool === 'crop' ||
                              name.length === 0 ||
                              label.length === 0 ||
                              tag.length === 0 ||
                              !confidenceScore ||
                              status.length === 0 ||
                              prediction.length === 0 ||
                              csv.length === 0 || 
                              selectedChildModel.length === 0 ||
                              !imageUrl
                            }

                            onClick={onSubmit}
                            >Save A Copy</Button>
                          </div>
                        </Tooltip>
                    </div>
                    <Button style={{ marginTop: 10}} variant='contained' color="primary" fullWidth
                    disabled={
                      activeTool === 'crop' ||
                      name.length === 0 ||
                      label.length === 0 ||
                      tag.length === 0 ||
                      !confidenceScore ||
                      status.length === 0 ||
                      prediction.length === 0 ||
                      csv.length === 0 || 
                      selectedChildModel.length === 0 ||
                      !imageUrl
                    }
                     onClick={handleUpdateOriginalResource}>
                      {
                        isUpdatingResource&&
                        <CircularProgress style={{ width: 16, height: 16, marginRight: 10, color: "white"}} />
                      }
                      Update Original Resource
                    </Button>
                </div>
            </div>
          </div>
        </DialogContent>
        <DialogBox 
            open={open} 
            onClose={onClose} 
            title={'Rotation Reset Warning'} 
            content={'Continuing this action will reset the applied rotation to the image. Are you sure you want to proceed'} 
            >
              <Button onClick={onZoomWarningNotProceed} color="primary" autoFocus>
                  No
              </Button>
              <Button onClick={onZoomWarningProceed} color="primary">
                  Yes
              </Button>
        </DialogBox>
      </Dialog>
      
    
  );
}

import { useEffect, useRef, useCallback } from "react";

interface UseInfiniteScrollProps {
  callback: () => void;
  isFetching: boolean;
  hasMore: boolean;
}

const useInfiniteScroll = ({
  callback,
  isFetching,
  hasMore,
}: UseInfiniteScrollProps) => {
  const observerRef = useRef<HTMLDivElement | null>(null);

  const observerCallback = useCallback(
    (entries: IntersectionObserverEntry[]) => {
      const target = entries[0];

      // If the target element is intersecting and we are not already fetching and have more items to load
      if (target.isIntersecting && !isFetching && hasMore) {
        callback();
      }
    },
    [callback, isFetching, hasMore]
  );

  useEffect(() => {
    const observer = new IntersectionObserver(observerCallback, {
      root: null, // default is viewport
      rootMargin: "0px",
      threshold: 1.0, // 100% of the target must be visible to trigger the callback
    });

    if (observerRef.current) {
      observer.observe(observerRef.current);
    }

    // Cleanup the observer on component unmount or when dependencies change
    return () => {
      if (observerRef.current) {
        observer.unobserve(observerRef.current);
      }
    };
  }, [observerCallback]);

  return [observerRef];
};

export default useInfiniteScroll;

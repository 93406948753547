import { useEffect, useState } from "react";
import {
  Box,
  Chip,
  CircularProgress,
  FormControl,
  Grid,
  makeStyles,
  TextField,
  Tooltip,
  Typography,
} from "@material-ui/core";
import { useForm } from "react-hook-form";

import {
  IAddCollectionPayload,
  Collection,
  CustomButton,
  IReduxState,
} from "../../../../../../../../../../../common";
import {
  addCollection,
  addCollectionPageInit,
  // getTags,
} from "../../../../../../../../../../../store/actions";
import { connect, ConnectedProps } from "react-redux";
import { useLocation } from "react-router-dom";
import { Autocomplete } from "@material-ui/lab";
import { InputFieldState } from "../../../../../../../../../../../common/constants/interfaces/inputFieldState";
import { removeDuplicateElementsFromArray } from "../../../../../../../../../../../services/arrayHelperService";

const useStyles = makeStyles((theme) => ({
  root: {
    marginTop: "auto",
    marginBottom: "auto",
  },
  floating: {
    "& legend": {
      float: "unset",
    },
  },
  illustration: {
    width: "150px",
    height: "150px",
  },
  form: {
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    "& legend": {
      float: "unset",
    },
  },
  formControl: {
    minWidth: 250,
    maxWidth: 300,
  },
  center: {
    justifyContent: "center",
  },
}));

/*
const BootstrapInput = withStyles((theme: Theme) =>
  createStyles({
    root: {
      "label + &": {
        marginTop: theme.spacing(1),
      },
    },
    input: {
      position: "relative",
      border: "1px solid #ced4da",
      fontSize: 14,
      padding: "10px 26px 10px 12px",
      transition: theme.transitions.create(["border-color", "box-shadow"]),
      "&:focus": {
        borderRadius: 4,
        borderColor: "#80bdff",
        boxShadow: "0 0 0 0.2rem rgba(0,123,255,.25)",
      },
    },
  })
)(InputBase);

const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
      width: 250,
    },
  },
};
*/

const AddDataSetCollection = (props: TPropsFromRedux) => {
  const [selected, setSelected] = useState<string[]>([]);
  const {
    isAddSuccess,
    isAdding,
    // getTags,
    addCollection,
    addErrorMessage,
    addCollectionPageInit,
  } = props;
  // tags
  const [isModelBeingUpdatedInDB, setIsModelBeingUpdatedInDB] = useState(false);
  const [tagsState, setTagsState] = useState<InputFieldState<string[]>>({
    value: [],
  });
  const location = useLocation();
  const {
    register,
    setValue,
    handleSubmit,
    reset,
    formState: { errors },
  } = useForm<IAddCollectionPayload>();
  const classes = useStyles();
  function handleTagsInputChangeEvent(eventValue: string) {
    if (eventValue.includes(",")) {
      const values: string[] = eventValue
        .split(",")
        .map((value) => value.trim())
        .filter((value: string) => {
          if (!value) {
            return false;
          }
          return true;
        });

      if (values?.length > 0) {
        const oldValue = tagsState.value;
        for (const value of values) {
          if (!oldValue.includes(value)) {
            oldValue.push(value);
          }
        }

        const tagsToSave = removeDuplicateElementsFromArray(oldValue);

        setTagsState({
          ...tagsState,
          isDirty: true,
          isTouched: true,
          value: [...tagsToSave],
        });
      }
    }
  }
  useEffect(() => {
    addCollectionPageInit();
  }, []);

  // const handleChange = (event: React.ChangeEvent<{ value: unknown }>) => {
  //   setSelected([...(event.target.value as string[])]);
  //   // setValue("tags", [...(event.target.value as string[])], {
  //   //   shouldValidate: true,
  //   //   shouldDirty: true,
  //   // });
  // };
  const onSubmit = (data: IAddCollectionPayload): void => {
    const query = new URLSearchParams(location.search);
    data.model = query.get("model") || "";
    data.project = query.get("project") || "";
    if (tagsState.value.length === 0) {
      setTagsState((prevState) => {
        const updatedState = { ...prevState };
        updatedState.isTouched = true;
        updatedState.errorMessage = "Atleast one tag is required";
        return updatedState;
      });
      return;
    }
    // const model_type = query.get("type") || "";
    addCollection({ ...data, tags: tagsState.value });
    setTagsState((prevData) => {
      const updatedData = { ...prevData };
      updatedData.isTouched = false;
      updatedData.isDirty = false;
      updatedData.value = [];
      return updatedData;
    });
  };
  function handleFormSubmit(e: React.FormEvent<HTMLFormElement>) {
    e.preventDefault();
    handleSubmit(onSubmit)();
    if (tagsState.value.length === 0) {
      setTagsState((prevState) => {
        const updatedState = { ...prevState };
        updatedState.isTouched = true;
        updatedState.errorMessage = "Atleast one tag is required";
        return updatedState;
      });
      return;
    }
  }
  useEffect(() => {
    if (tagsState.value.length === 0) {
      setTagsState((prevState) => {
        const updatedState = { ...prevState };
        updatedState.errorMessage = "Atleast one tag is required";
        return updatedState;
      });
    } else {
      setTagsState((prevState) => {
        const updatedState = { ...prevState };
        updatedState.errorMessage = "";
        return updatedState;
      });
    }
  }, [tagsState.value]);
  useEffect(() => {
    // register("tags", {
    //     required: "Tag is required",
    // });
    // setValue("response_variable", "tV", { shouldValidate: true });
    // setValue("max_runtime_secs_per_model", "30", { shouldValidate: true });
    // setValue("max_models", "1", { shouldValidate: true });
    const query = new URLSearchParams(location.search);
    const modelId: string = String(query.get("model"));
    // getTags(modelId);
  }, [register, setValue]);
  useEffect(() => {
    if (isAddSuccess) {
      // setValue("tags", undefined);
      setSelected([]);
      reset();
    }
  }, [isAddSuccess]);
  return (
    <Grid
      classes={{ root: classes.root }}
      container
      direction="column"
      alignItems="center"
      justify="center"
    >
      <Collection className={classes.illustration} />
      <Box m={1.5} />
      <Typography component="h3" variant="h5">
        Create Collection
      </Typography>
      <Box m={1.5} />
      <form className={classes.form} onSubmit={handleFormSubmit} noValidate>
        <FormControl className={classes.formControl}>
          <TextField
            variant="outlined"
            size="small"
            margin="normal"
            required
            fullWidth
            id="name"
            label="Collection Name"
            autoComplete="name"
            {...register("name", {
              required: `Collection Name is required`,
            })}
            error={Boolean(errors.name)}
            helperText={errors.name?.message}
            autoFocus
          />
        </FormControl>
        {/* <FormControl className={classes.formControl}>
                    <TextField
                        variant="outlined"
                        size="small"
                        margin="normal"
                        required
                        fullWidth
                        defaultValue="tV"
                        id='response_variable'
                        label='Response variable'
                        autoComplete='response_variable'
                        {...register('response_variable', {
                            required: `Response variable is required`,
                        })}
                        error={Boolean(errors.response_variable)}
                        helperText={errors.response_variable?.message}
                    />
                </FormControl> */}
        {/* <FormControl className={classes.formControl}>
                    <TextField
                        variant="outlined"
                        size="small"
                        margin="normal"
                        required
                        fullWidth
                        id='max_runtime_secs_per_model'
                        defaultValue="30"
                        label='Max runtime secs per model'
                        autoComplete='max_runtime_secs_per_model'
                        {...register('max_runtime_secs_per_model', {
                            required: `Max runtime secs per model is required`,
                        })}
                        error={Boolean(errors.max_runtime_secs_per_model)}
                        helperText={errors.max_runtime_secs_per_model?.message}
                    />
                </FormControl> */}
        {/* <FormControl className={classes.formControl}>
                    <TextField
                        variant="outlined"
                        size="small"
                        margin="normal"
                        required
                        fullWidth
                        id='max_models'
                        defaultValue="1"
                        label='Max models'
                        autoComplete='max_models'
                        {...register('max_models', {
                            required: `Max models is required`,
                        })}
                        error={Boolean(errors.max_models)}
                        helperText={errors.max_models?.message}
                    />
                </FormControl> */}
        {/* <FormControl
          variant="outlined"
          className={classes.formControl}
          error={Boolean(errors?.tags)}
        >
          <InputLabel id="tags">Tag*</InputLabel>
          <Select
            variant="outlined"
            labelId="tags"
            id="tags"
            multiple
            {...register("tags", {
              required: `Tags are required`,
            })}
            value={selected}
            input={<BootstrapInput />}
            renderValue={(selected) => (selected as string[]).join(", ")}
            MenuProps={MenuProps}
            onChange={handleChange}
          >
            <MenuItem value="" disabled>
              Select Tag(s)
            </MenuItem>
            {isTagsFetching ? (
              <MenuItem classes={{ root: classes.center }} value="" disabled>
                <CircularProgress />
              </MenuItem>
            ) : (
              tags?.map((tag) => (
                <MenuItem key={tag} value={tag}>
                  <Checkbox checked={selected.indexOf(tag) > -1} />
                  <ListItemText primary={tag} />
                </MenuItem>
              ))
            )}
          </Select>
          <FormHelperText>
            {errors?.tags && (errors?.tags as any).message}
          </FormHelperText>
        </FormControl> */}
        <FormControl className={classes.formControl}>
          <TextField
            variant="outlined"
            size="small"
            margin="normal"
            fullWidth
            id="description"
            label="Collection Description"
            autoComplete="description"
            {...register("description", {
              required: `Collection description is required`,
            })}
            error={Boolean(errors.description)}
            helperText={errors.description?.message}
          />
        </FormControl>

        <Autocomplete
          multiple
          id="Tags Available"
          options={[]}
          freeSolo
          disabled={isModelBeingUpdatedInDB}
          value={tagsState.value}
          getOptionSelected={() => false}
          onChange={(event, value) => {
            setTagsState((oldState) => {
              const tagsToSave = removeDuplicateElementsFromArray(
                value as string[]
              );

              return {
                ...oldState,
                isDirty: true,
                isTouched: true,
                value: [...tagsToSave],
              };
            });
          }}
          renderTags={(value, getTagProps) => {
            return value.map((option, index) => (
              <Chip
                variant="outlined"
                color="primary"
                // className={`${classes.chip}`}
                // classes={{ root: classes.autoCompleteChipRoot }}
                label={
                  <Tooltip title={option}>
                    <span>{option}</span>
                  </Tooltip>
                }
                {...getTagProps({ index })}
              />
            ));
          }}
          renderInput={(params) => (
            <TextField
              {...params}
              variant="outlined"
              size="small"
              margin="normal"
              className={classes.floating}
              fullWidth
              label="Tags"
              placeholder="Add Tags"
              onChange={(e) => {
                handleTagsInputChangeEvent(e.target.value);
              }}
              onBlur={(e) => {
                handleTagsInputChangeEvent(e.target.value + ",");
              }}
              error={
                tagsState.isTouched && tagsState.errorMessage ? true : false
              }
              helperText={
                tagsState.isTouched && tagsState.errorMessage
                  ? tagsState.errorMessage
                  : "Add multiple tag with <enter> or <comma> (optional)"
              }
            />
          )}
        />
        {addErrorMessage && (
          <Box style={{ marginBottom: 10, color: "red" }}>
            {addErrorMessage}
          </Box>
        )}
        <Box m={1.5} />
        <CustomButton type="submit" disabled={isAdding}>
          {isAdding ? <CircularProgress size={20} /> : "Add Collection"}
        </CustomButton>
        {/* <Separator width="50%" />
            <CustomButton size="small" disabled>Train Models</CustomButton> */}
      </form>
    </Grid>
  );
};

const mapProps = (state: IReduxState) => {
  return {
    // tags: state.tag.tags,
    // isTagsFetching: state.tag.isFetching,
    isAdding: state.collection.isAdding,
    isAddSuccess: state.collection.isAddSuccess,
    addErrorMessage: state.collection.addErrorMessage,
  };
};

const connector = connect(mapProps, {
  addCollection,
  // getTags,
  addCollectionPageInit,
});

export type TPropsFromRedux = ConnectedProps<typeof connector>;

export default connector(AddDataSetCollection);

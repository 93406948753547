import { makeStyles, Theme, createStyles, lighten, Toolbar, Typography, IconButton, Tooltip, CircularProgress, Button } from "@material-ui/core";
import { Check, Delete, FontDownloadOutlined, GetApp, Restore } from "@material-ui/icons";
import clsx from "clsx";

import { listViewOfDataOfModelsIconButton, gridViewOfDataOfModelsIconButton } from "../constants/constants";
import ListIcon from '@material-ui/icons/List';
import AppsIcon from '@material-ui/icons/Apps';
import ToggleButton from '@material-ui/lab/ToggleButton';
import ToggleButtonGroup from '@material-ui/lab/ToggleButtonGroup';
import { ComponentElement, useMemo, useState } from "react";
import { IData, ResourceStatus, DataView } from '../../../../../../../../../../../common/constants/interfaces/data';
import { deleteButtonIconColor, tooltipTextForApprovedIcon, tooltipTextForDeleteIconWhenDeletingResource, tooltipTextForDeleteIconWhenMovingToDeleteTab, tooltipTextForBacklogIcon, tooltipTextForSetApprovalStatusButton, tooltipTextForSetRejectedStatusButton } from '../constants/constants';
import SetApprovalStatusButton from "./SetApprovalStatusButton";
import SetRejectedStatusButton from "./SetRejectedStatusButton";
import WarnUserBeforeUpdatingDataRowsWhichAreNotShowingDialog from "./WarnUserBeforeUpdatingDataRowsWhichAreNotShowingDialog";
import { IModelType } from "../../../../../../../../../../../common";
import { themePrimaryMainColor } from "../../../../../../../../../../../theme";
import InfoPopover from "./InfoPopover";
import { DataService } from "../../../../../../../../../../../services";
import { generateUniqueId } from "../../../../../../../../../../../services/idHelperService";
import {ReactComponent as DownloadAsCSVIcon} from "../../../../../../../../../../../assets/svg/download as csv icon.svg"

type IconButtonProps = {
    disabled: boolean
}

interface IEnhancedTableToolbarProps {
    selected: string[];
    status?: ResourceStatus;
    updateData: (data: { ids: string[], status: ResourceStatus }) => void;
    resources: IData[];
    addResourceToDataSetCollectionButton: ComponentElement<any, any>
    dataSetCollectionFilter?: ComponentElement<any, any>
    deleteIconButtonProps?: IconButtonProps
    backlogIconButtonProps?: IconButtonProps
    approvedIconButtonProps?: IconButtonProps
    resourcesWhichAreNotPartOfAnyDataSetCollectionFilter?: ComponentElement<any, any>
    changeView: any
    currentView?: DataView
    totalResourcesCountMatchingTableFilter: number;
    areAllRowsMatchingTableFilterSelectedByUserAndHasRowsNotShowingInUI: boolean,
    currentModelType: IModelType | "",
    scenario?: "dataSetCollectionTableDetailsPopup" | "aiMarketplaceDataSelection" | "aiMarketPlaceDataCart" | "aiMarketplaceSelectedDataList",
    // onRemoveResourcesFromDataSetCollectionButtonClick: ()=>void
    removeResourcesFromDataSetCollectionIcon: ComponentElement<any, any>
}



const useToolbarStyles = makeStyles((theme: Theme) =>
    createStyles({
        root: {
            paddingLeft: theme.spacing(2),
            paddingRight: theme.spacing(1),
            display: 'flex',
            justifyContent: 'space-between'
        },
        toolbarLhsSection: {
            display: 'flex',
            justifyContent: "space-between",
            width: "98%"
        },
        highlight:
            theme.palette.type === 'light'
                ? {
                    color: theme.palette.secondary.main,
                    backgroundColor: lighten(theme.palette.secondary.light, 0.85),
                }
                : {
                    color: theme.palette.text.primary,
                    backgroundColor: theme.palette.secondary.dark,
                },
        title: {
            // flex: '1 1 100%',
            alignSelf: 'center'
        },
        buttons: {
            display: 'flex',
            paddingRight: '8px',
        },
        deleteButton: {
            color: deleteButtonIconColor
        }
    }),
);

type WarnUserBeforeUpdatingDataRowsWhichAreNotShowingDialogStateType = {
    isOpen: boolean,
    onConfirmClick: Function
}

const EnhancedTableToolbar = (props: IEnhancedTableToolbarProps) => {
    const classes = useToolbarStyles();
    const { resources, changeView, selected, status, updateData, totalResourcesCountMatchingTableFilter, areAllRowsMatchingTableFilterSelectedByUserAndHasRowsNotShowingInUI, currentModelType } = props;
    const [warnUserBeforeUpdatingDataRowsWhichAreNotShowingDialogState, setWarnUserBeforeUpdatingDataRowsWhichAreNotShowingDialogState] = useState<WarnUserBeforeUpdatingDataRowsWhichAreNotShowingDialogStateType>({
        isOpen: false,
        onConfirmClick: () => { }
    })
    const [anchorElement, setAnchorEl] = useState<HTMLButtonElement | null>(null)

    console.log("totalResourcesCountMatchingTableFilter", areAllRowsMatchingTableFilterSelectedByUserAndHasRowsNotShowingInUI, selected.length)
    const getTotalNumberOfResourcesGoingToBeAffected = () => {
        if (!areAllRowsMatchingTableFilterSelectedByUserAndHasRowsNotShowingInUI) {
            return selected.length
        }
        if (status === 'deleted' && totalResourcesCountMatchingTableFilter < 10000) {
            return totalResourcesCountMatchingTableFilter;
        } else if (status === 'deleted') {
            return 10000;
        }
        if (totalResourcesCountMatchingTableFilter < 50000) {
            return totalResourcesCountMatchingTableFilter;
        } else {
            return 50000;
        }
    }

    const labelsOfVideoAnnotationResourceThatAreNotApprovedByExpert = useMemo(() => {
        if (currentModelType === 'videoAnnotation') {
            let labels = resources.filter((resource) => selected.includes(resource._id)).map((row, i) =>
                row?.videoAnnotations?.map((annotation, index) => {
                    if (annotation?.annotationPrediction?.approvalStatus !== "approved") {
                        if (annotation?.annotationByExpert?.approvalStatus !== "approved") {
                            return annotation.label;
                        }
                    }
                    return '';
                }));
            let newLabels = [];
            for (let label of labels) {
                if (label)
                    for (let each of label) {
                        newLabels.push(each);
                    }
            }
            return newLabels?.filter(label => label !== '') || [];
        }
    }, [currentModelType, resources, selected]);

    const handleAnchorElementClose = () => {
        setAnchorEl(null)
    }

    // const download = (event: React.MouseEvent<unknown>, selected: string[]) => {
    //     (async () => {
    //         try {
    //             const data: AxiosResponse<IDownlaodDataResponse> = await ModelService.downloadData({ image_data: selected, type: "spoof" });
    //             window.open(data.data.filepath, "_self");
    //         } catch (error) {
    //             console.log('error:', error);
    //         }
    //     })();
    // }

    const DeleteButton = () => {
        /** commenting this method as its not being used anywhere */
        // const getFileNamesFromResourcesId = (resourceIdsSelected: string[], resources: IData[]): string[] => {
        //     const fileNames: string[] = [];
        //     for (const resourceIdSelected of resourceIdsSelected) {
        //         let fileName: string = resources.filter((resource)=>resource._id === resourceIdSelected)[0]?.filename
        //         if (!fileName) {
        //             throw new Error("fileName not found");
        //         }
        //         fileNames.push(fileName);
        //     }
        //     return fileNames;
        // }
        const handleDeleteButtonClick = () => {
            // let fileNames: string[] = getFileNamesFromResourcesId(selected, resources);
            updateData({ ids: selected, status: 'deleted' });
        };
        return (
            <Tooltip
                title={
                    status === 'deleted'
                        ? tooltipTextForDeleteIconWhenDeletingResource
                        : tooltipTextForDeleteIconWhenMovingToDeleteTab
                }
            >
                <IconButton classes={{ root: classes.deleteButton }} aria-label="delete" onClick={() => {
                    if (areAllRowsMatchingTableFilterSelectedByUserAndHasRowsNotShowingInUI) {
                        setWarnUserBeforeUpdatingDataRowsWhichAreNotShowingDialogState({
                            isOpen: true,
                            onConfirmClick: () => {
                                handleDeleteButtonClick();
                                setWarnUserBeforeUpdatingDataRowsWhichAreNotShowingDialogState(oldState => {
                                    return {
                                        ...oldState,
                                        isOpen: false
                                    }
                                })
                            }
                        })
                        return;
                    }
                    handleDeleteButtonClick();
                }}
                    {...props.deleteIconButtonProps}
                >
                    <Delete />
                </IconButton>
            </Tooltip>
        )
    }

    const DownloadButton = () => {
        const [isDownloadingCSV, setIsDownloadingCSV] = useState<boolean>(false);

        const handleDownloadButtonClick = async () => {
            try {
                setIsDownloadingCSV(true)
                const ids = selected
                const apiResponse = await DataService.downloadResourceCSVData(ids)
                const element = document.createElement('a');
                element.setAttribute('href', apiResponse.data);
                element.setAttribute('download', `resource${generateUniqueId()}_resources.csv`);
                document.body.appendChild(element);
                element.click();
                setIsDownloadingCSV(false)
            } catch (error) {
                alert(`Error: ${error}`)
            }
        };
        {/* <Button color="primary" variant="contained" style={{ width: "200px"}}
        onClick={handleDownloadButtonClick}>
            <div style={{ display: "flex", flexDirection: "row", alignItems: "center", justifyContent: "center", gap: 20}}>
            {
                isDownloadingCSV&&
                <CircularProgress style={{ width: 15, height: 15, color: "white"}} />
            }
            Download CSV
            </div>
        </Button> */}
        return (
                <>
                    {
                        isDownloadingCSV&&
                        <Tooltip title="Downloading datas as CSV">
                            <CircularProgress size={24} color="primary" 
                                style={{
                                    display: "flex",
                                    alignSelf: "center",
                                    paddingLeft: "12px",
                                    paddingRight: "12px",
                                    width: "48px"
                                }}
                            />
                        </Tooltip>
                    }
                    {
                        !isDownloadingCSV &&
                        <Tooltip title="Download datas as CSV">
                            <IconButton
                                onClick={handleDownloadButtonClick}
                            >
                                <DownloadAsCSVIcon />
                            </IconButton>
                        </Tooltip>
                    }               
                </>
        )
    }

    const RestoreButton = () => {
        return (
            <Tooltip title={tooltipTextForBacklogIcon}>
                <IconButton color="primary" aria-label="delete" onClick={() => {
                    if (areAllRowsMatchingTableFilterSelectedByUserAndHasRowsNotShowingInUI) {
                        setWarnUserBeforeUpdatingDataRowsWhichAreNotShowingDialogState({
                            isOpen: true,
                            onConfirmClick: () => {
                                updateData({ ids: selected, status: 'backlog' });
                                setWarnUserBeforeUpdatingDataRowsWhichAreNotShowingDialogState(oldState => {
                                    return {
                                        ...oldState,
                                        isOpen: false
                                    }
                                })
                            }
                            // onConfirmClick: updateData({ ids: selected, status: 'approved' })
                        })
                        return;
                    }
                    updateData({ ids: selected, status: 'backlog' });

                }}
                    {...props.backlogIconButtonProps}
                >
                    <Restore />
                </IconButton>
            </Tooltip>
        )
    }

    const ApproveButton = () => {
        return (
            <Tooltip title={tooltipTextForApprovedIcon}>
                <IconButton style={{ color: labelsOfVideoAnnotationResourceThatAreNotApprovedByExpert && labelsOfVideoAnnotationResourceThatAreNotApprovedByExpert.length > 0 ? '#CCCCCC' : themePrimaryMainColor }} aria-label="approve"
                    onClick={(event: React.MouseEvent<HTMLButtonElement>) => {
                        if (labelsOfVideoAnnotationResourceThatAreNotApprovedByExpert && labelsOfVideoAnnotationResourceThatAreNotApprovedByExpert.length > 0) {
                            setAnchorEl(event.currentTarget)
                            console.log('event.currentTarget', event.currentTarget);
                            return
                        }
                        if (areAllRowsMatchingTableFilterSelectedByUserAndHasRowsNotShowingInUI) {
                            setWarnUserBeforeUpdatingDataRowsWhichAreNotShowingDialogState({
                                isOpen: true,
                                onConfirmClick: () => {
                                    updateData({ ids: selected, status: 'approved' });
                                    setWarnUserBeforeUpdatingDataRowsWhichAreNotShowingDialogState(oldState => {
                                        return {
                                            ...oldState,
                                            isOpen: false
                                        }
                                    })
                                }
                                // onConfirmClick: updateData({ ids: selected, status: 'approved' })
                            })
                            return;
                        }
                        updateData({ ids: selected, status: 'approved' })
                    }}
                    {...props.approvedIconButtonProps}
                >
                    <Check />
                </IconButton>
            </Tooltip>
        )
    }
    return (
        <>
            {
                warnUserBeforeUpdatingDataRowsWhichAreNotShowingDialogState.isOpen &&
                <WarnUserBeforeUpdatingDataRowsWhichAreNotShowingDialog
                    open={warnUserBeforeUpdatingDataRowsWhichAreNotShowingDialogState.isOpen}
                    onClose={() => {
                        setWarnUserBeforeUpdatingDataRowsWhichAreNotShowingDialogState({
                            isOpen: false,
                            onConfirmClick: () => { }
                        })
                    }}
                    onConfirmClick={() => warnUserBeforeUpdatingDataRowsWhichAreNotShowingDialogState.onConfirmClick()}
                    resourcesCountWhichWillBeAffected={getTotalNumberOfResourcesGoingToBeAffected()}
                />
            }
            <Toolbar
                className={clsx(classes.root, {
                    [classes.highlight]: (selected.length > 0 && props.scenario !== 'aiMarketplaceDataSelection'),
                })}
            >
                <div className={`${classes.toolbarLhsSection}`}>
                    {(selected.length > 0 && props.scenario !== 'aiMarketplaceDataSelection') ? (
                        <Typography className={classes.title} color="inherit" variant="subtitle1" component="div"
                            style={{ width: '115px' }}
                        >
                            {`${getTotalNumberOfResourcesGoingToBeAffected()} selected`}
                        </Typography>
                    ) : (
                        <Typography className={classes.title} variant="h6" id="tableTitle" component="div"
                            style={{ width: '115px' }}
                        >
                            {
                                props.scenario === "dataSetCollectionTableDetailsPopup"
                                    ? "Resources"
                                    : "Data"
                            }
                        </Typography>
                    )}
                    {
                        props.dataSetCollectionFilter &&
                        props.dataSetCollectionFilter
                    }
                    {
                        props.resourcesWhichAreNotPartOfAnyDataSetCollectionFilter &&
                        props.resourcesWhichAreNotPartOfAnyDataSetCollectionFilter
                    }
                    {selected.length <= 0 ?
                        (currentModelType !== "forecasting" &&
                            currentModelType !== "multipleImageAnnotation" &&
                            <Typography >
                                <ToggleButtonGroup
                                    // value=
                                    exclusive
                                    onChange={changeView}
                                    aria-label="Data View"
                                >
                                    <Tooltip title={listViewOfDataOfModelsIconButton}>
                                        <ToggleButton color="primary" value="list-view" aria-label="List View" style={{ marginTop: '12px' }}
                                            selected={props.currentView === "list-view"}
                                        >
                                            <ListIcon />
                                        </ToggleButton>
                                    </Tooltip>
                                    <Tooltip title={gridViewOfDataOfModelsIconButton}>
                                        <ToggleButton color="primary" value="grid-view" aria-label="Grid View" style={{ marginTop: '12px' }}
                                            selected={props.currentView === "grid-view"}
                                        >
                                            <AppsIcon />
                                        </ToggleButton>
                                    </Tooltip>
                                </ToggleButtonGroup>
                            </Typography>)
                        : null
                    }
                </div>
                {
                    props.scenario !== 'aiMarketplaceDataSelection'
                    &&
                <div className="rhsSection"
                    style={{
                        alignItems: "center"
                    }}
                >
                    {selected.length > 0 && (
                        <div className={classes.buttons}>{console.log(status)}
                            {
                                status === 'approved'
                                    ? <>{props.addResourceToDataSetCollectionButton}</>
                                    : null
                            }
                            {
                                status === "approved" && !areAllRowsMatchingTableFilterSelectedByUserAndHasRowsNotShowingInUI
                                ?
                                    <>{props.removeResourcesFromDataSetCollectionIcon}</>
                                : null
                            }
                            {
                                !areAllRowsMatchingTableFilterSelectedByUserAndHasRowsNotShowingInUI ?
                                    <DownloadButton /> : null
                            }
                            {
                                status === "approval" &&
                                <span onClick={(event: React.MouseEvent<HTMLButtonElement>) => {
                                    if (labelsOfVideoAnnotationResourceThatAreNotApprovedByExpert && labelsOfVideoAnnotationResourceThatAreNotApprovedByExpert.length > 0) {
                                        setAnchorEl(event.currentTarget)
                                        console.log('event.currentTarget', event.currentTarget);
                                        return
                                    }
                                }}>
                                    <ApproveButton />
                                </span>
                            }
                            {
                                (
                                    status === 'backlog' ||
                                    status === 'rejected'
                                )
                                    ? <>
                                        <Tooltip title={tooltipTextForSetApprovalStatusButton}>
                                            <span>
                                                <SetApprovalStatusButton
                                                    // color="primary" 
                                                    color={`${labelsOfVideoAnnotationResourceThatAreNotApprovedByExpert && labelsOfVideoAnnotationResourceThatAreNotApprovedByExpert.length > 0 ? '#CCCCCC' : themePrimaryMainColor}`}
                                                    onClick={(event: React.MouseEvent<HTMLButtonElement>) => {
                                                        if (labelsOfVideoAnnotationResourceThatAreNotApprovedByExpert && labelsOfVideoAnnotationResourceThatAreNotApprovedByExpert.length > 0) {
                                                            setAnchorEl(event.currentTarget)
                                                            return
                                                        }
                                                        if (areAllRowsMatchingTableFilterSelectedByUserAndHasRowsNotShowingInUI) {
                                                            setWarnUserBeforeUpdatingDataRowsWhichAreNotShowingDialogState({
                                                                isOpen: true,
                                                                onConfirmClick: () => {
                                                                    updateData({ ids: selected, status: 'approval' });
                                                                    setWarnUserBeforeUpdatingDataRowsWhichAreNotShowingDialogState(oldState => {
                                                                        return {
                                                                            ...oldState,
                                                                            isOpen: false
                                                                        }
                                                                    })
                                                                }
                                                            })
                                                            return;
                                                        }
                                                        updateData({ ids: selected, status: 'approval' })
                                                    }}
                                                />
                                            </span>
                                        </Tooltip>
                                    </>
                                    : null
                            }
                            {
                                status === 'deleted'
                                    ? <RestoreButton />
                                    : null
                            }
                            {
                                (
                                    status === 'approval' ||
                                    status === 'approved'
                                ) &&
                                <Tooltip title={tooltipTextForSetRejectedStatusButton}>
                                    <span>
                                        <SetRejectedStatusButton
                                            color="primary"
                                            onClick={() => {
                                                if (areAllRowsMatchingTableFilterSelectedByUserAndHasRowsNotShowingInUI) {
                                                    setWarnUserBeforeUpdatingDataRowsWhichAreNotShowingDialogState({
                                                        isOpen: true,
                                                        onConfirmClick: () => {
                                                            updateData({ ids: selected, status: 'rejected' });
                                                            setWarnUserBeforeUpdatingDataRowsWhichAreNotShowingDialogState(oldState => {
                                                                return {
                                                                    ...oldState,
                                                                    isOpen: false
                                                                }
                                                            })
                                                        }
                                                    })
                                                    return;
                                                }
                                                updateData({ ids: selected, status: 'rejected' })
                                            }}
                                        />
                                    </span>
                                </Tooltip>
                            }
                            {
                                true
                                    ? <><DeleteButton /></>
                                    : null
                            }

                        </div>
                    )
                    }
                </div>
                }
            </Toolbar >
            <InfoPopover anchorEl={anchorElement} handleClose={handleAnchorElementClose} labels={undefined} />
        </>
    );
};


export default EnhancedTableToolbar;

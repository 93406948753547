import { useEffect, useState } from "react";

export default function useImageOnLoad(imageUrl: string) {
  const [image, setImage] = useState<HTMLImageElement>();
  const [imageLoadStatus, setImageLoadStatus] = useState<
    "loading" | "loadingCompleted" | "loadingError" | undefined
  >(imageUrl ? "loading" : undefined);

  useEffect(() => {
    let imageToLoad: HTMLImageElement | undefined;
    if (imageUrl) {
      imageToLoad = new Image();
      setImageLoadStatus("loading");
      setImage(undefined);
      imageToLoad.onload = function () {
        setImage(imageToLoad);
        setImageLoadStatus("loadingCompleted");
      };
      imageToLoad.onerror = function () {
        setImage(undefined);
        setImageLoadStatus("loadingError");
      };
      imageToLoad.src = imageUrl;
    }
    return () => {
      setImageLoadStatus(undefined);
      setImage(undefined);
      if (imageToLoad) {
        imageToLoad.src = "";
        imageToLoad.onload = null;
        imageToLoad.onerror = null;
        imageToLoad = undefined;
      }
    };
  }, [imageUrl]);

  return {image, imageLoadStatus};
}

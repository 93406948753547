import axios, { AxiosResponse } from 'axios';
import { 
    EGPT_BASE_URL, 
    LLM_STUDIO_CHAT_ASSISTANT_NAME as ENV_LLM_STUDIO_CHAT_ASSISTANT_NAME, 
    LLM_STUDIO_GENERATE_PURPOSE_MODEL_DESCRIPTION_ASSISTANT_NAME as ENV_LLM_STUDIO_GENERATE_PURPOSE_MODEL_DESCRIPTION_ASSISTANT_NAME,
    LLM_STUDIO_USER_ID as ENV_LLM_STUDIO_USER_ID
} from '../../../../../services/constants';
import { IGetModelCollectionResponse, IPopularModel } from '../../projects/features/aiMarketPlace/aiMarketPlaceInterfaces/interfaces';
import { GuestSessionIdService } from '../../homePage/guestSessionIdService';


// ....................................... Constants start ...........................................

export const LLM_STUDIO_CHAT_WEB_SOCKET_URL = `wss://${EGPT_BASE_URL.replace("https://", "")}/graphql`;
export const LLM_STUDIO_ORG_NAME = "techolution";
// export const LLM_STUDIO_CHAT_ASSISTANT_NAME = "trainingpodchat";
export const LLM_STUDIO_CHAT_ASSISTANT_NAME = ENV_LLM_STUDIO_CHAT_ASSISTANT_NAME;
// export const LLM_STUDIO_GENERATE_PURPOSE_MODEL_DESCRIPTION_ASSISTANT_NAME = "trainingpoddescgen"
export const LLM_STUDIO_GENERATE_PURPOSE_MODEL_DESCRIPTION_ASSISTANT_NAME = ENV_LLM_STUDIO_GENERATE_PURPOSE_MODEL_DESCRIPTION_ASSISTANT_NAME
export const LLM_STUDIO_SELECTED_AI_MODEL_NAME = "bestai"
// export const LLM_STUDIO_USER_ID = 'user-123'
export const LLM_STUDIO_USER_ID = ENV_LLM_STUDIO_USER_ID
// ........................................ Constants end .................................................

interface IResetChatHistoryResponse {
    success: boolean;
    message?: string;
}

interface IChatMessage {
    id: string,
    role: 'user' | 'assistant',
    content: string,
    timestamp:string,
    // time: number,
    userDetils: {
        userName: string,
        userEmailId: string,
        llm: string
    }
}

const resetChatHistoryService = async (): Promise<IResetChatHistoryResponse> => {
    const URLParameters = new URLSearchParams();
    URLParameters.set('name', LLM_STUDIO_CHAT_ASSISTANT_NAME);
    URLParameters.set('organizationName', LLM_STUDIO_ORG_NAME);
    const url = `${EGPT_BASE_URL}/api/chat/resetHistory?${URLParameters.toString()}`;

    try {
        const response: AxiosResponse<IResetChatHistoryResponse> = await axios.get(url, {
            withCredentials: true // Include cookies
        });

        GuestSessionIdService.clearGuestSessionId();

        return response.data;
    } catch (error) {
        // eslint-disable-next-line @typescript-eslint/restrict-plus-operands
        throw new Error('Failed to reset chat history: ' + error);
    }
};

const getChatHistoryAPIService = async (): Promise<Array<IChatMessage> | null> => {
    const getHistoryURL = `${EGPT_BASE_URL}/api/chat/history?name=${LLM_STUDIO_CHAT_ASSISTANT_NAME}&organizationName=${LLM_STUDIO_ORG_NAME}`;

    try {
        const requestOptions = {
            method: 'GET',
            credentials: 'include',
            withCredentials: true // Send cookies along with the request
        };
        // @ts-ignore
        const resp = await axios.get(getHistoryURL, requestOptions)
        return resp.data;

    } catch (error) {
        console.error(error);
        return null;
    }
};

const getChatSessionIdFromCookies = (): string => {
    const sessionId = document.cookie.split('; ').find(row => row.startsWith(`Enterprise-GPT-Maker-${LLM_STUDIO_ORG_NAME}-${LLM_STUDIO_CHAT_ASSISTANT_NAME}=`))?.split('=')[1] || '';
    alert(sessionId)

    return sessionId;
}

const getFormattedDataOfTrainingPodToSendToAi = (trainingPodDetails: IPopularModel) => {
    const trainingPod = trainingPodDetails.purposeModel;
    if(!trainingPod) return null;
    const formatedDateForGeneralPurposeTrainingPod = (String(trainingPod.dateOfRelease || '1').padStart(2, '0') + '-' + String(trainingPod.monthOfRelease || '1').padStart(2, '0') + '-' + trainingPod.yearOfRelease)
    const modelAuthor = trainingPod.purposeType === 'specific' ? trainingPodDetails.created_by || '' : trainingPodDetails.purposeModel?.createdByUser?.emailId || '';
    //@ts-ignore
    const modelLastModifiedAt = trainingPod.purposeType === 'specific' ? (new Date(trainingPodDetails.updated_at))?.toISOString() : (new Date(trainingPodDetails?.purposeModel?.updated_at))?.toISOString();


    const formattedData = {
        problem_category: trainingPod.aiTask,
        problem_sub_category: trainingPod.problemType,
        training_pod_name: trainingPod.modelName,
        training_pod_description: trainingPod.modelDescription,
        tags: trainingPod.tags?.map(obj => obj.value),
        model_purpose: trainingPod.purposeType,
        //@ts-ignore
        created_on: trainingPod.purposeType?.toLowerCase() === 'general' ? formatedDateForGeneralPurposeTrainingPod : (new Date(trainingPod.created_at))?.toISOString(),
        author: modelAuthor,
        project: trainingPodDetails.project?.name || '',
        lastModified: modelLastModifiedAt || '',
        //@ts-ignore
        numberOfModelParams: trainingPodDetails.numberOfModelParams || '',
        inferenceHardware: trainingPodDetails.purposeModel?.inferenceHardware || '',
        // inferenceTime: trainingPodDetails.inference,     // field yet to be planned in schemas at first
        references: trainingPodDetails.purposeModel?.references || [],
        //@ts-ignore
        // library_requirements: trainingPod.librariesRequirements || [],
        // hyperparameters: Object.keys(trainingPod.hyperParameters || {})?.map(hyperParameter => {
        //     const obj = trainingPod.hyperParameters?.[hyperParameter];
        //     return {
        //         hyperparameter_name: hyperParameter,
        //         hyperparameter_value: obj?.value,
        //         hyperparameter_description: obj?.example
        //     }
        // }),
        data_format: {
            data_format_title: trainingPod.dataFormatTitle,
            data_format_description: trainingPod.dataFormatDescription,
            actual_data_format: trainingPod.actualDataFormat,
        },
        numberOfClones: trainingPod.clonesCount,
        metrics: {
            //@ts-ignore
            accuracy: trainingPodDetails.checkpointFiles?.files?.analysis?.cachedMetrics?.accuracy?.accuracy,
            //@ts-ignore
            integrity: trainingPodDetails.checkpointFiles?.files?.analysis?.cachedMetrics?.integrity?.integrity,
        },
        additionalMetadata: {
            trainingPodId: trainingPod._id?.toString(),
            modelCollectionId: trainingPodDetails._id?.toString(),
            //@ts-ignore
            testDataSetId: trainingPodDetails.checkpointFiles?.files?.analysis?.dataSetCollectionId
        }
    }

    return formattedData;
}


export { getChatHistoryAPIService, resetChatHistoryService, getChatSessionIdFromCookies, getFormattedDataOfTrainingPodToSendToAi };
import { IconButton } from "@material-ui/core";
import { ThumbDown } from "@material-ui/icons";

export default function SetRejectedStatusButton(props: {
	disabled?: boolean;
	color?: "inherit" | "primary" | "secondary" | "default";
	onClick?: () => void;
}) {
	return (
		<IconButton
			color={props.color ? props.color : undefined}
			disabled={props.disabled ? true : false}
			onClick={() => props.onClick && props.onClick()}
		>
			<ThumbDown />
		</IconButton>
	);
}

import { Dialog, DialogTitle, DialogContent, DialogContentText, TextField, DialogActions, Button, CircularProgress } from "@material-ui/core";
import { Alert, Autocomplete } from "@material-ui/lab";
import { useCallback, useEffect, useState } from "react";
import { InputFieldState } from "../../../../../../../../../common/constants/interfaces/inputFieldState";
import ModelGroup from "../../../../../../../../../store/types/modelGroup.type";

type Props = {
    onClose: () => any;
    modelName: string;
    modelGroupNameOfModel: string;
    isMovingModelToDifferentModelGroup: boolean;
    // modelGroupNameEnteredByUser: string;
    // setModelGroupNameEnteredByUser: (modelGroupName: string) => any;
    moveButtonClicked: (modelGroupName: string) => any;
    modelGroupsAvailableInDB: {
        name: ModelGroup["name"]
    }[]
}

export default function MoveModelToDifferentModelGroupPopup(props: Props) {

    const modelName: string = props.modelName;

    const modelGroups = props.modelGroupsAvailableInDB;

    const [modelGroupNameSelectedState, setModelGroupNameSelectedState] = useState<InputFieldState<string>>({
        value: ""
    })

    const isMovingModelToDifferentModelGroup = props.isMovingModelToDifferentModelGroup

    useEffect(() => {
      if (
        !modelGroupNameSelectedState.value ||
        !modelGroupNameSelectedState.value.trim()
      ) {
        setModelGroupNameSelectedState(oldState=>({
          ...oldState,
          errorMessage: "Task group name is required"
        }))
        return
      }

      if (modelGroupNameSelectedState.value === props.modelGroupNameOfModel) {
        setModelGroupNameSelectedState(oldState=>({
          ...oldState,
          errorMessage: "This task already belongs to this task group"
        }))
        return        
      }

      setModelGroupNameSelectedState(oldState=>({
        ...oldState,
        errorMessage: ""
      }))
    

    }, [modelGroupNameSelectedState.value, props.modelGroupNameOfModel])
    

    const handleMoveButtonClicked = useCallback(
      () => {
        const propsMoveButtonClicked = props.moveButtonClicked;
        if (modelGroupNameSelectedState.errorMessage) {
          setModelGroupNameSelectedState(oldState=>({
            ...oldState,
            canShowErrorToUser: true
          }))
          return;
        }
        propsMoveButtonClicked(modelGroupNameSelectedState.value)
      },
      [modelGroupNameSelectedState, props.moveButtonClicked],
    );

    const hasUserEnteredANewModelGroupName: boolean = (()=>{
      if (!modelGroupNameSelectedState.value.trim()) {
        return false;
      }

      const modelGroupNameExistsInExistingModelGroups = (() => {
        const modelGroup = props.modelGroupsAvailableInDB.find(modelGroup=>{
          if (modelGroup.name === modelGroupNameSelectedState.value) {
            return true
          }
          return false;
        })
        if (modelGroup) {
          return true
        }
        return false;
      })()

      if (modelGroupNameExistsInExistingModelGroups) {
        return false;
      }
      
      return true;
    })();
    
  return (
    <div>
      <Dialog
        open={true}
        onClose={props.onClose}
      >
        <DialogTitle id="form-dialog-title">Moving task to different task group</DialogTitle>
        <DialogContent>
          <DialogContentText>
            Please select task group where you want to move <strong>{modelName}</strong> task.
          </DialogContentText>
          <Autocomplete
            style={{
              marginTop: "25px",
              marginBottom: "18px"
            }}            
            // freeSolo
            options={
                modelGroups.filter(modelGroup=>modelGroup.name !== props.modelGroupNameOfModel)
                .map(modelGroup=>modelGroup.name)
            }
            filterOptions={(options, state) => {
                if (!modelGroupNameSelectedState.value) {
                    return options;
                }
                const filteredOptions = options.filter(option=>option.toLowerCase().indexOf(modelGroupNameSelectedState.value.toLowerCase()) !== -1)
                return filteredOptions;
            }}
            onChange={(event, value)=>{
                setModelGroupNameSelectedState(oldState=>{
                    return{...oldState, value: value || "", isDirty: true}
                });                        
            }}
            value={modelGroupNameSelectedState.value}
            renderInput={(params)=>{
                return (
                    <TextField
                        {...params}
                        size="small"
                        variant="outlined"
                        label="Task Group"
                        // className={`${props.classes.floating}`}
                        style={{width: '100%'}}
                        // onChange={(e) => {
                        //     setModelGroupNameSelectedState(oldState=>{
                        //         return{...oldState, value: e.target.value, isDirty: true}
                        //     });
                        // }}
                        onBlur={()=>{
                            setModelGroupNameSelectedState(oldState=>{
                                return{...oldState, isTouched: true}
                            });
                        }}
                        error={
                            modelGroupNameSelectedState.canShowErrorToUser &&
                            modelGroupNameSelectedState.errorMessage
                                ? true
                                : false
                            }
                            helperText={
                            modelGroupNameSelectedState.canShowErrorToUser &&
                            modelGroupNameSelectedState.errorMessage
                                ? modelGroupNameSelectedState.errorMessage
                                : ""
                            }                                        
                    />
                )
            }}
          />  

          {
            hasUserEnteredANewModelGroupName &&
            <Alert 
              style={{
                marginTop: "9px",
                marginBottom: "8px",
              }}
            severity="info">A new task group <strong>{modelGroupNameSelectedState.value}</strong> will be created</Alert>
          }

          {/* <TextField
            autoFocus
            margin="dense"
            id="name"
            label="Email Address"
            type="email"
            fullWidth
          /> */}
        </DialogContent>
        <DialogActions>
          <Button onClick={props.onClose}>
            Cancel
          </Button>
          <Button disabled={isMovingModelToDifferentModelGroup ? true : false} onClick={handleMoveButtonClicked} color="primary">
            {
              isMovingModelToDifferentModelGroup
              ? <CircularProgress size="20px" color="primary" />
              : "Move"
            }
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
}

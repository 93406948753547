import { API } from "../API";
import { APP_API } from "../constants";
import { ICompressImagesAndStoreAsTempFilesApiPayload, ICompressImagesAndStoreAsTempFilesApiResponse } from "./temporaryFilesInterfaces";

const baseUrl = APP_API;
const tempFilesBaseUrl = baseUrl + '/temporaryFiles';

class TemporaryFilesService extends API {
    compressImagesAndStoreAsTempFiles(data: ICompressImagesAndStoreAsTempFilesApiPayload) {
        return this.api.put<ICompressImagesAndStoreAsTempFilesApiResponse>(tempFilesBaseUrl + '/compressImagesAndStoreAsTempFiles', data);
    }
}

export default new TemporaryFilesService();
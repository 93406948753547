import React, {useEffect, useMemo, useState} from 'react';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import Toolbar from '@material-ui/core/Toolbar';
import IconButton from '@material-ui/core/IconButton';
import Typography from '@material-ui/core/Typography';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import { TransitionProps } from '@material-ui/core/transitions';
import Slide from '@material-ui/core/Slide';
// import toadd from './toadd.png';
import ZoomOutMapIcon from '@material-ui/icons/ZoomOutMap';
import RotateLeftIcon from '@material-ui/icons/RotateLeft';
import LabelOutlinedIcon from '@material-ui/icons/LabelOutlined';
import { createStyles, makeStyles, withStyles, Theme, useTheme } from '@material-ui/core/styles';
import InputLabel from '@material-ui/core/InputLabel';
import MenuItem from '@material-ui/core/MenuItem';
import FormControl from '@material-ui/core/FormControl';
import Select from '@material-ui/core/Select';
import NativeSelect from '@material-ui/core/NativeSelect';
import InputBase from '@material-ui/core/InputBase';
import ButtonGroup from '@material-ui/core/ButtonGroup';
import KeyboardBackspaceOutlinedIcon from '@material-ui/icons/KeyboardBackspaceOutlined';
import DialogContent from '@material-ui/core/DialogContent';
import ArrowDropDownOutlinedIcon from '@material-ui/icons/ArrowDropDownOutlined';
import { SegmentationAnalyticsTwoDimensionalImage } from '../../../../../../../../../../../assets/components/react-annotation-tool/src/apps/segmentation-analytics-two-dimensional-image';
import { FileMetaData, IAnalyticsModelData, IData, ILabelConfidenceScoreObject, IModelType, IResourceAnalyticsCollection, ResourceImageAnnotation, ResourceStatus, TrimmedAudio } from '../../../../../../../../../../../common';
import { AnalyticsResourceReferenceGuideDataType, OtherImageAnnotationPrediction } from './ModelAnalyticsResources';
import ImageService from '../../../../../../../../../../../services/ImageService';
import { getImageScaleFactorForImageWidth } from '../../../../../../../../../../../services/imageHelperService';
import { setResourceImageAnnotationsForSendingToAnnotationComponent } from '../../../../../../../../../../../services/imageAnnotationHelperService';
import { defaultAnnotationOptions } from '../../../../../../../../../../../services/imageAnnotationHelperService';
import css from "./ModelAnalyticsResourseComponent.module.css";
import { isNullOrUndefined } from '../../../../../../../../../../../services/variableHelperService';
import { FormControlLabel, FormGroup, Link, Switch ,Box,Chip} from '@material-ui/core';
import ArrowBackIosIcon from '@material-ui/icons/ArrowBackIos';
import ArrowForwardIosIcon from '@material-ui/icons/ArrowForwardIos';
import { SpectrogramAudioTrimUI } from '../../../../../../coPilot/features/SpectrogramAudioItemCard';
import axios from 'axios';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import ExpandLessIcon from '@material-ui/icons/ExpandLess';
import AnnotationsListPopup from './AnnotationsListPopup';
import { MdAutorenew } from 'react-icons/md';
import { useKey } from '../hooks/useKey';
import { useURLQuery } from '../../../../../../../../../../../hooks/useURLQuery';
import { IPurposeModel } from '../../../../../../../../../../../common/constants/interfaces/purposeModels';
import PurposeModelService from '../../../../../../../../../../../services/PurposeModelService';
import { ModelService, ProjectService } from '../../../../../../../../../../../services';

export const RESOURCE_IDS_TO_FILTER="resourceIdsToFilter";

const useStyles = makeStyles((theme: Theme) => ({
  appBar: {
    position: 'relative',
    backgroundColor: 'white', 
    color:'black',
    display: "flex",
    justifyContent: "space-between"
  },
  title: {
    display:'flex',
    flexDirection:'column',
    fontSize:'1.2rem',
    marginTop:'1.5rem',
    color:'#32a3af'
  },
  dialogContainer: {
    display: 'flex',
    flexDirection: 'column',
    // maxHeight: '81vh',
    // margin: '1rem',
    marginLeft:'4rem',
    height: "calc(100% - 79px)"
    
  },
  headerRow: {
    display: 'flex',
    alignItems: 'center',
    paddingBottom: '1rem',
  },
  contentContainer: {
    display: 'flex',
    height: "calc(100% - 69px)",
    overflow: "auto"
  },
  firstColumn: {
    flex: 2.5,
    paddingRight: '8px',
    position: 'relative',
    display: "flex",
    flexDirection: "column"
    // width:'400rem'
    // margin:'10px'
  },
  secondColumn: {
    flex: 2.5,
    paddingRight: '8px',
    position: 'relative',
    display: "flex",
    flexDirection: "column",
    // justifyContent: "space-between",
    // margin:'10px'
  },
  margin: {
    margin: theme.spacing(1),
  },
  thirdColumn: {
    flex: 1,
    overflowY: 'auto',
    // height:'720px',
    height:'calc(100% - 6px)',
    // marginRight:'4px',
    '&::-webkit-scrollbar': {
      width: '4px',
      marginRight:'4px'
    },
    '&::-webkit-scrollbar-thumb': {
      backgroundColor: '#888',
      borderRadius: '10px',
      marginRight:'4px'
    },
  },
  image: {
    width: '100%',
    borderBottomLeftRadius:'10px',
    borderBottomRightRadius:'10px',
    // width:'200px'
  },
  image1: {
    width: '100%',
    marginTop:'10px',
    marginBottom:'10px'
  },
  buttonContainer: {
    display: 'flex',
    flexDirection: 'column',
    border:'1px solid grey',
    marginTop:'.8rem',
    padding:'8px',
    borderRadius:'10px',
    // height:'175px',
    // height:'30%',
    // height: "auto",
    height: "calc(30% - 48px)",
    overflow:'auto',
    '&::-webkit-scrollbar': {
      width: '4px',
      marginRight:'4px'
    },
    '&::-webkit-scrollbar-thumb': {
      backgroundColor: '#888',
      borderRadius: '10px',
      marginRight:'4px'
    },
  },
  buttonContainerPopup: {
    display: 'flex',
    marginTop:'20%',
    paddingRight:'4px',
    flexDirection: 'column',
    border:'1px solid grey',
    padding:'8px',
    borderRadius:'10px',
    height:'175px',
    overflow:'auto',
    '&::-webkit-scrollbar': {
      width: '4px',
      marginRight:'4px'
    },
    '&::-webkit-scrollbar-thumb': {
      backgroundColor: '#888',
      borderRadius: '10px',
      marginRight:'4px'
    },
  },
  bigButton: {
    backgroundColor:'white',
    width: '100%',
    marginBottom: '8px',
    display:'flex',
    justifyContent:'space-between'
  },
  expandButton: {
    color:'white'
  },
  imageText: {
    backgroundColor: '#1f1f1f',
    color: 'white',
    display:'flex',
    justifyContent:'space-between',
    alignItems:'center',
    borderTopLeftRadius:'6px',
    borderTopRightRadius:'6px',
    // position: 'absolute',
  },
  formControl: {
    margin: theme.spacing(1),
    minWidth: 120,
  },
  selectEmpty: {
    marginTop: theme.spacing(2),
  },
}));

const BootstrapInput = withStyles((theme: Theme) =>
  createStyles({
    root: {
      'label + &': {
        marginTop: theme.spacing(3),
      },
    },
    input: {
      borderRadius: 4,
      position: 'relative',
      backgroundColor: theme.palette.background.paper,
      border: '1px solid #ced4da',
      fontSize: 16,
      padding: '10px 26px 10px 12px',
      transition: theme.transitions.create(['border-color', 'box-shadow']),
      fontFamily: [
        '-apple-system',
        'BlinkMacSystemFont',
        '"Segoe UI"',
        'Roboto',
        '"Helvetica Neue"',
        'Arial',
        'sans-serif',
        '"Apple Color Emoji"',
        '"Segoe UI Emoji"',
        '"Segoe UI Symbol"',
      ].join(','),
      '&:focus': {
        borderRadius: 4,
        borderColor: '#80bdff',
        boxShadow: '0 0 0 0.2rem rgba(0,123,255,.25)',
      },
    },
  })
)(InputBase);

type NativeSelectsProps = {
  uniqueAnnotationLabels: string[];
  annotationLabelSelectedByUser: string;
  onAnnotationLabelChange: (annotationLabelSelectedByUser: string) => any;
}
function NativeSelects(props: NativeSelectsProps) {
  const classes = useStyles();
  const [state, setState] = React.useState<{ age: string | number; name: string }>({
    age: '',
    name: 'hai',
  });

  const handleChange = (event: React.ChangeEvent<{ name?: string; value: unknown }>) => {
    const name = event.target.name as keyof typeof state;
    props.onAnnotationLabelChange(event?.target?.value as string || "");
    setState({
      ...state,
      [name]: event.target.value,
    });
  };

  return (
    <div style={{display:'flex', justifyContent:'center', alignItems:'center', marginLeft:'5rem'}}>
      <p style={{marginTop:'2rem', color:'grey', marginRight:'1rem'}}>Classes</p>
      <FormControl variant="outlined" className={classes.formControl} style={{ height: '25px' }} >
        <InputLabel htmlFor="outlined-age-native-simple">Age</InputLabel>
        <Select
          native
          value={props.annotationLabelSelectedByUser}
          onChange={handleChange}
          label="Age"
          inputProps={{
            name: 'age',
            id: 'outlined-age-native-simple',
          }}
          style={{height:'2.6rem', marginTop:'4px'}}
        >
          <option aria-label="None" value="" />
          {
            props.uniqueAnnotationLabels?.map((annotationLabel,index)=>{
              return (
                <option key={index} value={annotationLabel}>{annotationLabel}</option>
              )
            })
          }
        </Select>
      </FormControl>
      </div>
  );
}


function CustomizedSelects() {
  const classes = useStyles();
  const [age, setAge] = React.useState('');
  const handleChange = (event: React.ChangeEvent<{ value: unknown }>) => {
    setAge(event.target.value as string);
  };
  return (
    <div>
      <FormControl className={classes.margin}>
        <InputLabel htmlFor="demo-customized-textbox">Age</InputLabel>
        <BootstrapInput id="demo-customized-textbox" />
      </FormControl>
      <FormControl className={classes.margin}>
        <InputLabel id="demo-customized-select-label">Age</InputLabel>
        <Select
          labelId="demo-customized-select-label"
          id="demo-customized-select"
          value={age}
          onChange={handleChange}
          input={<BootstrapInput />}
        >
          <MenuItem value="">
            <em>None</em>
          </MenuItem>
          <MenuItem value={10}>Ten</MenuItem>
          <MenuItem value={20}>Twenty</MenuItem>
          <MenuItem value={30}>Thirty</MenuItem>
        </Select>
      </FormControl>
      <FormControl className={classes.margin}>
        <InputLabel htmlFor="demo-customized-select-native">Age</InputLabel>
        <NativeSelect
          id="demo-customized-select-native"
          value={age}
          onChange={handleChange}
          input={<BootstrapInput />}
        >
          <option aria-label="None" value="" />
          <option value={10}>Ten</option>
          <option value={20}>Twenty</option>
          <option value={30}>Thirty</option>
        </NativeSelect>
      </FormControl>
    </div>
  );
}

const Transition = React.forwardRef(function Transition(
  props: TransitionProps & { children?: React.ReactElement },
  ref: React.Ref<unknown>,
) {
  return <Slide direction="up" ref={ref} {...props} />;
});

type Props = {
  pagination: {
    currentPage: number,
    rowsPerPage: number;
    totalCount: number;
  },
  onPageChange:(event:unknown,newPage:number)=>void,
  front: () => void;
  back: () => void;
  resources?: IResourceAnalyticsCollection[] | undefined;
  selectedModelIndex?: number;
  selectedModelCollectionId: string;
  modelInformation?: IAnalyticsModelData;
  selectedResource: IResourceAnalyticsCollection | null;
  showLeftArrow: boolean;
  availableLabelsData: ILabelConfidenceScoreObject | null;
  showRightArrow: boolean;
  actions: {
    modelCollectionToCompareWithChangeHandler: Function;
    onArrowClickHanlder: Function;
    updateLabelConfidenceScores: Function;
  };
  [name: string]: any;
  isFetchingOtherImageAnnotationPredictions: boolean;
  OtherImageAnnotationPredictions: OtherImageAnnotationPrediction[] | undefined;
  modelReferenceGuides: AnalyticsResourceReferenceGuideDataType[];
  isSpectrogramAudioContext?: boolean
  shouldModalOpenedAfterFetchingData:boolean
  setShouldModalOpenedAfterFetchingData:(newState:boolean)=>void
  purposeModelProblemType?: IPurposeModel["problemType"]
};


function ImageResourceHyperlink(props: {
  linkLabel: string;
  resourceFileName: string;
  resourceStatus: ResourceStatus;
  modelId: string;
  resourceId: string;
  projectId: string;
}) {
  // const classes = useAudioResourceHyperlinkStyles();
  
  const urlSearchParams = new URLSearchParams(window.location.search);

  const purposeModelId: string = urlSearchParams.get("purposeModelId") || "";

  localStorage.setItem(RESOURCE_IDS_TO_FILTER, JSON.stringify([props.resourceId]))

  const [resourceLink, setResourceLink] = useState('')
  
  useEffect(() => {
    const fetchAndSetLink = async()=>{
      const apiResponses = await Promise.all([
        ProjectService.getProjectDetails(props.projectId),
        ModelService.getModelDetails({modelId: props.modelId})
      ])
  
      const {data : {name: projectName}} = apiResponses[0]
      const {data : {name: modelName}} = apiResponses[1] 
  
      const strLink =  `${window.location.origin}/admin/projects/${projectName}/${modelName}/data/${props.resourceStatus}?project=${props.projectId}&model=${props.modelId}&type=imageAnnotation&resourceSortOrder=descending&resourceSortField=statusLastModifiedAt&resourceStatus=${props.resourceStatus}&resourceOffset=1&resourceLimit=10&resourceNameSearchValue=${props.resourceFileName}&resourceNameSearchOperator=equals&purposeModelId=${purposeModelId}`
      setResourceLink(strLink)
    }
    fetchAndSetLink()
  }, [props.resourceFileName, props.modelId, props.projectId])

  return (
    <>
      {resourceLink ? <Link color="primary" rel="noreferrer noopener" target="_blank" href={resourceLink}>{props.linkLabel}</Link> : <div style={{color: 'lightblue'}}>loading...</div>}
    </>
  )
}

const groundTruthTwoDimensionalImageHTMLContainerId = "groundTruthTwoDimensionalImageContainer";
const selectedModelResourceTwoDimensionalImageHTMLContainerId = "selectedModelResourceTwoDimensionalImageHTMLContainerId";



function ImageAnnotationSegmentationDialog(props: Props) {
  console.log("testing Image annotation", props.selectedResource?.fileName)
  const urlSearchQuery = useURLQuery()
  const classes = useStyles();
  const theme = useTheme();
  const [open, setOpen] = React.useState(true);
  const [zoomModalOpen, setZoomModalOpen] = useState(false);
  const isSegmentationAnalyticsEnabled = (()=>{
    if (props.purposeModelProblemType) {
      return PurposeModelService.isProblemTypeApplicableForImageAnnotationComparisonViewInModelAnalytics(props.purposeModelProblemType);
    }
    return props?.modelInformation?.model?.isSegmentationModelAnalytics || false;
  })();  
  const modelId = props.modelInformation?.model._id || "";
  const [selectedModelVersion, setSelectedModelVersion] = useState(props.modelInformation?.version);
  const resourceId = props?.selectedResource?._id;
  const [rerenderAnnotationsAndImages, setRerenderAnnotationsAndImages] = useState(false);
  const [doesUserWantsToSeeAllAnnotationsOfThisImage, setDoesUserWantsToSeeAllAnnotationsOfThisImage] = useState(false);
  const isSegmentationModelAnalyticsEnabled = (()=>{
    if (props.purposeModelProblemType) {
      return PurposeModelService.isProblemTypeApplicableForImageAnnotationComparisonViewInModelAnalytics(props.purposeModelProblemType);
    }
    return props.modelInformation?.model?.isSegmentationModelAnalytics;
  })();
  function isNextPageAvailable(){
    const totalPages = Math.floor((props.pagination.totalCount + props.pagination.rowsPerPage - 1) / props.pagination.rowsPerPage);
    return (props.pagination.currentPage + 2 ) <= totalPages; // current page is two because the pagination api supports 0 based page so +1 returns the actual page and +1 provides the next page and checks if it is less than total page 
  }
  function moveNext(){
    if(props.selectedModelIndex !== undefined && props.selectedModelIndex < (props?.resources?.length ?? 0) - 1)
      props.front() // increment the index to index + 1, index refers to the current resource in a page. this is use for moving from one resource to another in a page
    else if(isNextPageAvailable()){
      props.setShouldModalOpenedAfterFetchingData(true);
      props.onPageChange(undefined,props.pagination.currentPage + 1)
    }
  }
  function moveBack(){
    if(props.selectedModelIndex !== undefined && props.selectedModelIndex > 0)
      props.back() // decrement the index to index - 1, index refers to the current resource in a page. this is use for moving from one resource to another in a page
    else if(props.pagination.currentPage !== 0){
      props.setShouldModalOpenedAfterFetchingData(true);
      props.onPageChange(undefined,props.pagination.currentPage - 1)
    }
  }
  useKey("ArrowRight",(event:KeyboardEvent)=>moveNext())
  useKey("ArrowLeft",(event:KeyboardEvent)=>moveBack())
  function shouldNextButtonBeDisabled(){
    if(isNextPageAvailable())
      return false
    else
      return props.selectedModelIndex !== undefined && props.selectedModelIndex >= (props?.resources?.length ?? 0) - 1
  }
  function shouldPreviousButtonBeDisabled(){
    if(props.pagination.currentPage !== 0)
      return false
    else
      return props.selectedModelIndex !== undefined && props.selectedModelIndex <= 0;
  }
  const isCorrectPrediction: boolean = (()=>{
    if (isSegmentationModelAnalyticsEnabled) {
        if (props.selectedResource?.predictionStatus === "CORRECT") {
            return true
        } 
        return false;
    } else {
        return props.selectedResource?.predictionStatus === "CORRECT" ? true : false
    }
})()


  



  useEffect(() => {
    function resize() {
      setRerenderAnnotationsAndImages(oldState=>!oldState);
    }
    
    window.addEventListener("resize", resize);
  
    return () => {
      window.removeEventListener("resize", resize);
    }
  }, [])
 
  const [doesUserWantsToSeeReferenceGuide, setDoesUserWantsToSeeReferenceGuide] = useState(false);
  const [groundDefaultTruthAudioAnnotation, setGroundDefaultTruthAudioAnnotation] = useState<TrimmedAudio []>()
  const [isGroundTruthAnnotationsImageScalingGoingOn, setIsGroundTruthAnnotationsImageScalingGoingOn] = useState(false);
  const [groundTruthImageWidthForTwoDimensionalImage, setGroundTruthImageWidthForTwoDimensionalImage] = useState(0);

  const [SelectedModelResourceImageWidthForTwoDimensionalImage, setSelectedModelResourceImageWidthForTwoDimensionalImage] = useState(0);

  const [annotationGroupIdsWhichAreExpanded, setAnnotationGroupIdsWhichAreExpanded] = useState<string[]>([]);

  const resourceUrl = props.isSpectrogramAudioContext? props.selectedResource?.spectrogram : props?.selectedResource?.resourceUrl;

  const [annotationLabelSelectedByUserForViewing, setAnnotationLabelSelectedByUserForViewing] = useState<string>(
    ""
    // props?.selectedResource?.groundTruthAnnotations?.[0]?.selectedOptions?.[1]?.value || ""
    );

  const [annotationNameWhichIsInFocus, setAnnotationNameWhichIsInFocus] = useState("");
    
  const groundTruthAnnotations = useMemo(() => {
    if (isSegmentationAnalyticsEnabled && !doesUserWantsToSeeAllAnnotationsOfThisImage) {
      if (props?.selectedResource?.groundTruthAnnotation) {
        return [props?.selectedResource?.groundTruthAnnotation]
      } else {
        return []
      }
    }
    return [...(props?.selectedResource?.groundTruthAnnotations || [])];
  }, [isSegmentationAnalyticsEnabled, doesUserWantsToSeeAllAnnotationsOfThisImage, props?.selectedResource?.groundTruthAnnotations, props?.selectedResource?.groundTruthAnnotation]) 

  const groundTruthAnnotationGroups = useMemo(() => {
    return [...(props?.selectedResource?.groundTruthImageAnnotationGroups || [])]
    // if (isSegmentationAnalyticsEnabled && !doesUserWantsToSeeAllAnnotationsOfThisImage) {
    //   if (props?.selectedResource?.groundTruthImageAnnotationGroups) {
    //     return [props?.selectedResource?.groundTruthImageAnnotationGroups]
    //   } else {
    //     return []
    //   }
    // }
    // return [...(props?.selectedResource?.groundTruthAnnotations || [])];
  }, [props?.selectedResource?.groundTruthImageAnnotationGroups]) 

  useEffect(()=>{
    console.log('ImageAnnotationSegmentationDialog ~ groundTruthAnnotationGroups:', groundTruthAnnotationGroups)

  }, [groundTruthAnnotationGroups])

  const predictedAnnotations = useMemo(() => {
    if (isSegmentationAnalyticsEnabled && !doesUserWantsToSeeAllAnnotationsOfThisImage) {
      if (props?.selectedResource?.predictedAnnotation) {
        return [props?.selectedResource?.predictedAnnotation]
      } else {
        return []
      }
    }
    return [...(props?.selectedResource?.predictedAnnotations || [])];    
  }, [doesUserWantsToSeeAllAnnotationsOfThisImage, isSegmentationAnalyticsEnabled, props?.selectedResource?.predictedAnnotation, props?.selectedResource?.predictedAnnotations])
  
  const predictedImageAnnotationGroups = useMemo(() => {
    return [...(props?.selectedResource?.predictedImageAnnotationGroups || [])];    
  }, [props?.selectedResource?.predictedImageAnnotationGroups])

  const [groundTruthImageAnnotationsForTwoDimensionalImage, setGroundTruthImageAnnotationsForTwoDimensionalImage] = useState<ResourceImageAnnotation[]>([]);

  const [selectedModelResourceAudioAnnotations, setSelectedModelResourceAudioAnnotations] = useState<TrimmedAudio []>([])
  const [selectedModelResourceImageAnnotationsForTwoDimensionalImage, setSelectedModelResourceImageAnnotationsForTwoDimensionalImage] = useState<ResourceImageAnnotation[]>([]);
  const [isSelectedModelResourceAnnotationsImageScalingGoingOn, setIsSelectedModelResourceAnnotationsImageScalingGoingOn] = useState(false);

  const [selectedModelCollectionVersion, setSelectedModelCollectionVersion] = useState<string>(props.modelInformation?.version || "");
  const [selectedAnnotationId, setSelectedAnnotationId] = useState("");
  const [selectedAnnotationLabel, setSelectedAnnotationLabel] = useState("");

  const resourceIdRelatedModelsData: OtherImageAnnotationPrediction[] = useMemo(() => {
    const resourceIdRelatedModels: OtherImageAnnotationPrediction[] = [];
    resourceIdRelatedModels.push({
      confidenceScore: String(props.selectedResource?.confidence_score) || "0",
      modelCollectionId: "",
      modelVersion: props.modelInformation?.version || "",
      predictedAnnotations: props.selectedResource?.predictedAnnotations || [],
      resourceId: props.selectedResource?._id || ""
    })
    for (const modelResource of (props.OtherImageAnnotationPredictions || [])) {
      if (modelResource.resourceId === resourceId) {
        resourceIdRelatedModels.push(modelResource)
      }
    }
    return [...resourceIdRelatedModels];
  }, [resourceId, props.OtherImageAnnotationPredictions]);

  const uniqueAnnotationLabels: string[] = useMemo(() => {
    let uniqueAnnotationLabels: string[] = [];
    for (const modelResource of resourceIdRelatedModelsData) {
      if (modelResource?.modelVersion === selectedModelCollectionVersion && modelResource?.predictedAnnotations?.length>0) {
        for (const annotation of modelResource.predictedAnnotations) {
          if (annotation?.selectedOptions?.[1]?.value) {
            uniqueAnnotationLabels = [...uniqueAnnotationLabels, annotation?.selectedOptions?.[1]?.value]
          }
        }
      }
    }
    if (groundTruthAnnotations?.length>0) {
      for (const annotation of groundTruthAnnotations) {
        if (annotation?.selectedOptions?.[1]?.value) {
          uniqueAnnotationLabels = [...uniqueAnnotationLabels, annotation?.selectedOptions?.[1]?.value]
        }          
      }
    }
    return [...uniqueAnnotationLabels];
  }, [groundTruthAnnotations, resourceIdRelatedModelsData])

  const selectedModelResource: OtherImageAnnotationPrediction | undefined = useMemo(() => {
    for (const modelResource of resourceIdRelatedModelsData) {
      if (modelResource.modelVersion === selectedModelCollectionVersion) {
        return {...modelResource};
      }
    }
    return undefined
  }, [resourceIdRelatedModelsData, selectedModelCollectionVersion])




  useEffect(() => {
    const executeFunction = async () => {
      setIsGroundTruthAnnotationsImageScalingGoingOn(true)
      let image: {
        width: number,
        height: number
      } | undefined = undefined;   

      let imageUrl: string = props.isSpectrogramAudioContext? (props.selectedResource?.spectrogram||"") : (props?.selectedResource?.resourceUrl || "")
      
      image = await ImageService.getImageWidthHeightFromURL(imageUrl);      
      const imageAspectRatio = image.width / image.height;

      const groundTruthImageContainerElement =  document.getElementById(groundTruthTwoDimensionalImageHTMLContainerId);


      let imageMaxWidthAllowed = groundTruthImageContainerElement?.offsetWidth || 400;
      let imageMaxHeightAllowed = groundTruthImageContainerElement?.offsetHeight || 400;

      let imageWidthToSet = 0;
      let imageHeightToSet = 0;

      if (image.width >= image.height)
      {
        imageWidthToSet = imageMaxWidthAllowed;
        imageHeightToSet = ImageService.getImageHeight(
          imageWidthToSet,
          imageAspectRatio
        )

        if (imageHeightToSet > imageMaxHeightAllowed) {
          imageHeightToSet = imageMaxHeightAllowed;
          imageWidthToSet = ImageService.getImageWidth(
            imageHeightToSet,
            imageAspectRatio
          );
        }         
      } 
      else if (image.height >= image.width) {
        imageHeightToSet = imageMaxHeightAllowed;
        imageWidthToSet = ImageService.getImageWidth(
          imageHeightToSet,
          imageAspectRatio
        );

        if (imageWidthToSet > imageMaxWidthAllowed) {
          imageWidthToSet = imageMaxWidthAllowed;
          imageHeightToSet = ImageService.getImageHeight(
            imageWidthToSet,
            imageAspectRatio
          )
        }
      } 
      
      let imageScaleFactor = getImageScaleFactorForImageWidth(
        image.width,
        imageWidthToSet
      );

      const filteredGroundTruthAnnotations = groundTruthAnnotations.filter(annotation=>{
        if (!annotationLabelSelectedByUserForViewing || annotation?.selectedOptions?.[1]?.value === annotationLabelSelectedByUserForViewing) {
          return true
        }
        return false
      })
      
      const imageAnnotationsForTwoDimensionalImage = setResourceImageAnnotationsForSendingToAnnotationComponent(
        filteredGroundTruthAnnotations,
        imageScaleFactor
      );

      setGroundTruthImageWidthForTwoDimensionalImage(imageWidthToSet);

      setGroundDefaultTruthAudioAnnotation([...filteredGroundTruthAnnotations.map(annotation => {
        return {
          color: annotation.color||"",
          _id: `${annotation.id}-audio-annotation` || "",
          startTimeInSeconds: Number(annotation.startTimeInSeconds || 0),
          endTimeInSeconds: Number(annotation.endTimeInSeconds|| 0),
          label: annotation.label||"",
          createdAt: `${annotation.created_at}`
        }
      })])

      
      setGroundTruthImageAnnotationsForTwoDimensionalImage([...(imageAnnotationsForTwoDimensionalImage || [])])

      setIsGroundTruthAnnotationsImageScalingGoingOn(false)
      
    }

    if (props?.selectedResource?.resourceUrl||props.selectedResource?.spectrogram) {
      executeFunction()
    }
  
  }, [rerenderAnnotationsAndImages, annotationLabelSelectedByUserForViewing, groundTruthAnnotations, props?.selectedResource?.resourceUrl, props.selectedResource?.spectrogram])

  console.log("selectedModelResourceAudioAnnotations")

  const convertAudioToBase64 = async (wavUrl: string) => {
    try {
      const response = await fetch(wavUrl);
      const blob = await response.blob();
      
      return new Promise<string>((resolve, reject) => {
        const reader = new FileReader();
        reader.onload = (e) => {
          const base64String = btoa(e.target?.result as string);
          resolve(base64String);
        };
        
        reader.onerror = (e) => {
          reject(e);
        };
  
        reader.readAsBinaryString(blob);
      });
    } catch (error) {
      console.error('Error fetching or converting the WAV file:', error);
      throw error;
    }
  }

  useEffect(() => {
    const executeFunction = async () => {
      setIsSelectedModelResourceAnnotationsImageScalingGoingOn(true)
      let image: {
        width: number,
        height: number
      } | undefined = undefined;   
      let imageUrl: string = props.isSpectrogramAudioContext? (props.selectedResource?.spectrogram||""): (props.selectedResource?.resourceUrl||"")
      image = await ImageService.getImageWidthHeightFromURL(imageUrl);      
      const imageAspectRatio = image.width / image.height;


      const selectedModelResourceImageContainerElement =  document.getElementById(groundTruthTwoDimensionalImageHTMLContainerId);


      let imageMaxWidthAllowed = selectedModelResourceImageContainerElement?.offsetWidth || 400;
      let imageMaxHeightAllowed = selectedModelResourceImageContainerElement?.offsetHeight || 400;

      let imageWidthToSet = 0;
      let imageHeightToSet = 0;

      if (image.width >= image.height)
      {
        imageWidthToSet = imageMaxWidthAllowed;
        imageHeightToSet = ImageService.getImageHeight(
          imageWidthToSet,
          imageAspectRatio
        )

        if (imageHeightToSet > imageMaxHeightAllowed) {
          imageHeightToSet = imageMaxHeightAllowed;
          imageWidthToSet = ImageService.getImageWidth(
            imageHeightToSet,
            imageAspectRatio
          );
        }         
      } 
      else if (image.height >= image.width) {
        imageHeightToSet = imageMaxHeightAllowed;
        imageWidthToSet = ImageService.getImageWidth(
          imageHeightToSet,
          imageAspectRatio
        );

        if (imageWidthToSet > imageMaxWidthAllowed) {
          imageWidthToSet = imageMaxWidthAllowed;
          imageHeightToSet = ImageService.getImageHeight(
            imageWidthToSet,
            imageAspectRatio
          )
        }
      } 
      
      let imageScaleFactor = getImageScaleFactorForImageWidth(
        image.width,
        imageWidthToSet
      );


      const filteredPredictedAnnotations = (predictedAnnotations || []).filter(annotation=>{
        if (!annotationLabelSelectedByUserForViewing || annotation?.selectedOptions?.[1]?.value === annotationLabelSelectedByUserForViewing) {
          return true;
        }
        return false;
      });

      const imageAnnotationsForTwoDimensionalImage = setResourceImageAnnotationsForSendingToAnnotationComponent(
        filteredPredictedAnnotations || [],
        imageScaleFactor
      );

      if(props.isSpectrogramAudioContext) {

        const audioBase64 : string = await convertAudioToBase64(props.selectedResource?.resourceUrl||"")

       const audioDuration: any [] = filteredPredictedAnnotations
       ?.filter(annotation=>{
        if (annotation?.vertices?.length>0) {
          return true
        } else {
          return false
        }
       })
       ?.map(async (annotation) => {

          const apiResponse = await axios.post("https://time-extraction-exjsxe2nda-el.a.run.app/process_spectrogram",
            {
              audio: audioBase64,
              start_x_coord: Number(annotation.vertices[0].x || 0),
              end_x_coord: Number(annotation.vertices[1].x || 0),
              spectrogram_width: image?.width,
              spectrogram_height: image?.height
            })
          return apiResponse.data;
        })

        const durationArray = await Promise.all(audioDuration).then(response => response)

        setSelectedModelResourceAudioAnnotations([...filteredPredictedAnnotations.map((annotation, index) => {
                return {
                  color: annotation.color||"",
                  _id: `${annotation.id}-audio-annotation` || "",
                  startTimeInSeconds: Number( durationArray?.[index]?.start_time || 0),
                  endTimeInSeconds: Number( durationArray?.[index]?.end_time || 0),
                  label: annotation.label||"",
                  createdAt: `${annotation.created_at}`
                }
              })])
              } 
        

      setSelectedModelResourceImageWidthForTwoDimensionalImage(imageWidthToSet);

      
      setSelectedModelResourceImageAnnotationsForTwoDimensionalImage([...(imageAnnotationsForTwoDimensionalImage || [])?.filter(annotation=>annotation?.vertices?.length>0)])

      setIsSelectedModelResourceAnnotationsImageScalingGoingOn(false)
      
    }

    if (props?.selectedResource?.resourceUrl||props.selectedResource?.spectrogram) {
      executeFunction()
    }
  
  }, [rerenderAnnotationsAndImages, annotationLabelSelectedByUserForViewing, props.selectedResource?.resourceUrl, props.selectedResource?.spectrogram, resourceUrl, predictedAnnotations, props.selectedResource?.predictedAnnotation, props.selectedResource?.predictedAnnotations])
  
  // const groundTruthImageAnnotations = useMemo(() => {
  //   for (const otherResource of (props.OtherImageAnnotationPredictions || [])) {
  //     if (otherResource.resourceId === resourceId) {
  //       // return otherResource.
  //     }
  //   }
  // }, [])

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    props.onClose();
  };

  const handleZoomModalOpen = () => {
    setZoomModalOpen(true);
  };

  const handleZoomModalClose = () => {
    setZoomModalOpen(false);
  };
 

  
  const [spectrogramResource, setSpectrogramResource] = useState<IData>({
    created_at: '',
    updated_at: '',
    csv: '',
    draw: '',
    resource: props.selectedResource?.resourceUrl||"",
    remarks: undefined,
    label: '',
    model: {
      headers: '',
      name: '',
      _id: '',
      project: '',
      type: {} as IModelType,
    },
    close: undefined,
    status: {} as ResourceStatus,
    _id: '',
    tag: '',
    dataBoost: '',
    prediction: '',
    confidence_score: '',
    filename: '',
    dataSetCollections: [],
    statusLastModifiedAt: '',
    changesMade: [],
    ansOfPrompt: '', // Include this line for ansOfPrompt
    prompt: '',
    trimmedAudios: [],
    trimmedTexts: [],
    isPiiData: undefined,
    predictedCount: undefined,
    cv_aiCount: undefined,
    itemsSoldCount: undefined,
    itemsDiscardedCount: undefined,
    itemsAddCount: undefined,
    videoDurationInSeconds: undefined,
    forecastDateTime: '',
    imageAnnotations: [],
    imageGroupAnnotations: [],
    videoAnnotations: [],
    arevideoAnnotationsBeingUpdatedInDB: false,
    expertFeedbackCount: 0,
    areImageKeyPointsBeingUpdatedInUI: false,
    isResourceRemarksBeingUpdatedInDB: false,
    warmerName: '',
    additionalResources: [], // Include this line for additionalResources
    confidenceScore: undefined,
    resource500WUrl: '',
    resourceMetaData: {} as FileMetaData,
    resource500WMetaData: {} as FileMetaData,
    textFileContent: '',
    isFetchingTextFileContents: false,
    aiAssistantLastCalledDetails: {
      calledAt: '',
    },
    textOriginalLanguage: '',
    textTranslatedLanguage: '',
    authorName: '',
    paragraph: {
      sequenceNumber: 0,
    },
    chapter: {
      sequenceNumber: 0,
    },
    textOriginalLanguageSentences: [],
    textTranslatedLanguageSentences: [],
    spectrogram: '',
    trimmedAudioAnnotation: [],
    spectrogramFileName: '',
    handsData: {
      didUserFoundSameHandHavingMultipleIds: false,
      hands: [],
    }})
  
  useEffect(() =>{

    setSpectrogramResource({
      created_at: '',
      updated_at: '',
      csv: '',
      draw: '',
      resource: props.selectedResource?.resourceUrl||"",
      remarks: undefined,
      label: '',
      model: {
        headers: '',
        name: '',
        _id: '',
        project: '',
        type: {} as IModelType,
      },
      close: undefined,
      status: {} as ResourceStatus,
      _id: '',
      tag: '',
      dataBoost: '',
      prediction: '',
      confidence_score: '',
      filename: '',
      dataSetCollections: [],
      statusLastModifiedAt: '',
      changesMade: [],
      ansOfPrompt: '', // Include this line for ansOfPrompt
      prompt: '',
      trimmedAudios: [],
      trimmedTexts: [],
      isPiiData: undefined,
      predictedCount: undefined,
      cv_aiCount: undefined,
      itemsSoldCount: undefined,
      itemsDiscardedCount: undefined,
      itemsAddCount: undefined,
      videoDurationInSeconds: undefined,
      forecastDateTime: '',
      imageAnnotations: [],
      imageGroupAnnotations: [],
      videoAnnotations: [],
      arevideoAnnotationsBeingUpdatedInDB: false,
      expertFeedbackCount: 0,
      areImageKeyPointsBeingUpdatedInUI: false,
      isResourceRemarksBeingUpdatedInDB: false,
      warmerName: '',
      additionalResources: [], // Include this line for additionalResources
      confidenceScore: undefined,
      resource500WUrl: '',
      resourceMetaData: {} as FileMetaData,
      resource500WMetaData: {} as FileMetaData,
      textFileContent: '',
      isFetchingTextFileContents: false,
      aiAssistantLastCalledDetails: {
        calledAt: '',
      },
      textOriginalLanguage: '',
      textTranslatedLanguage: '',
      authorName: '',
      paragraph: {
        sequenceNumber: 0,
      },
      chapter: {
        sequenceNumber: 0,
      },
      textOriginalLanguageSentences: [],
      textTranslatedLanguageSentences: [],
      spectrogram: '',
      trimmedAudioAnnotation: [],
      spectrogramFileName: '',
      handsData: {
        didUserFoundSameHandHavingMultipleIds: false,
        hands: [],
      }})

    return;

  },[props.selectedResource?.resourceUrl])

  const [playAudioRegionOnGroundTruth, setPlayAudioRegionOnGroundTruth] = useState<string>("")
  const [playAudioRegionOnPredicted, setPlayAudioRegionOnPredicted] = useState<string>("")

  const  handlePlayAudioRegionOnGroundTruth = (id: string) => {
    setPlayAudioRegionOnGroundTruth(id);
  }

  const handlePlayAudioRegionOnPredicted = (id: string) => {
    setPlayAudioRegionOnPredicted(id)
  }
  const [expandView, setExpandView] = useState(false);
  const handleGroundTruthExpandView = () => {
    setExpandView(true);
    setIsGroundTruthAnnotations(true);
  }
  const handleModelExpandView = () => {
    setExpandView(true);
    setIsGroundTruthAnnotations(false);
  }

  
 
  const [isGroundTruthAnnotations, setIsGroundTruthAnnotations] = useState(true);
  const [stageScaleForSync,setStageScaleForSync]=useState<number>(1);
  
  const [zoomCursorPosition, setZoomCursorPosition] = useState<{ x: number, y: number }>({
    x: 0, 
    y: 0,
  });
  const handleResetZoom=()=>{
    setStageScaleForSync(1)
     
   }
  const handleStageScale=(stageScale:number,cursorPosition:any)=>{
    
    setStageScaleForSync(stageScale)
    
  
   setZoomCursorPosition(prevCusorPosition => {
    
     return {
      x: cursorPosition.x,
      y:cursorPosition.y,
    }}); 
   
  }
  const [draggingCoordinates, setDraggingCoordinates] = useState({ x: 0, y: 0 });

  const handleDrag = (x:any, y:any) => {
    setDraggingCoordinates({ x, y });
  };
 

  return (
    <div style={{ margin: '1rem' }}>
      {/* <Button variant="outlined" color="primary" onClick={handleClickOpen}>
        Open full-screen dialog
      </Button> */}
      <Dialog
        fullScreen={true}
        open={open}
        onClose={handleClose}
        // style={{ margin: '1rem' }}
        fullWidth={true}
        maxWidth="lg"
        TransitionComponent={Transition}
      >
        <div className={classes.appBar}>
          <Toolbar>
            <IconButton
              edge="start"
              color="inherit"
              onClick={handleClose}
            >
              <KeyboardBackspaceOutlinedIcon />
            </IconButton>
            <Typography variant="h6" className={classes.title}>
              <span>{props.selectedResource?.fileName}</span>   
              <span style={{ fontSize:'14px', color:'grey'}}>Image name</span>
            </Typography>         
          </Toolbar>
            <div
              style={{
                paddingRight: "20px",
                display: "flex",
                alignItems: "center"
              }}
            >
              <FormControl component="fieldset">
                <FormGroup aria-label="position" row>
                  <FormControlLabel
                    value="start"
                    control={
                      <Switch color="primary" 
                        checked={doesUserWantsToSeeAllAnnotationsOfThisImage}
                        onChange={(e)=>{
                          setDoesUserWantsToSeeAllAnnotationsOfThisImage(e.target.checked)
                        }}
                      />
                    }
                    label="View all predicted and ground truth annotations of this image"
                    labelPlacement="start"
                  />                  
                </FormGroup>
              </FormControl>
            </div>
        </div>

        <div className={classes.dialogContainer}>
          <div className={classes.headerRow} style={{display: "none"}}>
            <ButtonGroup aria-label="outlined grey button group">
              <Button 
                onClick={handleZoomModalOpen}
                style={{display:'flex', justifyContent:'center', alignItems:'center'}}
              >
                <span><ZoomOutMapIcon style={{fontSize:'14px',paddingTop:'5px'}}/></span>
                <span>Zoom</span>
              </Button>
              <Button style={{display:'flex', justifyContent:'center', alignItems:'center'}}>
                <span><RotateLeftIcon style={{fontSize:'17px',paddingTop:'5px'}}/></span>
                <span>Reset Zoom</span>
              </Button>
              <Button style={{display:'flex', justifyContent:'center', alignItems:'center'}}>
                <span><LabelOutlinedIcon style={{fontSize:'19px',paddingTop:'5px'}}/></span>
                <span>Hide Labels</span>
              </Button>
              <Button style={{display:'flex', justifyContent:'space-between', alignItems:'center', width:'12rem'}}>
                <span>Magnifier</span>
                <span>X: 0</span>
                <ArrowDropDownOutlinedIcon style={{fontSize:'19px',paddingTop:'5px'}}
                  // onClick={}
                />
              </Button>
              <Button style={{display:'flex', justifyContent:'center', alignItems:'center'}}>
                X: 0
              </Button>
              <Button style={{display:'flex', justifyContent:'center', alignItems:'center'}}>
                Y: 0
              </Button>
            </ButtonGroup>
            {/* <CustomizedSelects /> */}
            <div>
              <NativeSelects
                uniqueAnnotationLabels={uniqueAnnotationLabels}
                annotationLabelSelectedByUser={annotationLabelSelectedByUserForViewing}
                onAnnotationLabelChange={(annotationLabel)=>setAnnotationLabelSelectedByUserForViewing(annotationLabel)}
              />
            </div>
          </div>

  <div style={{display: 'flex', alignItems: 'center', justifyContent: 'space-between'}}>
  <Box
          style={{
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'flex-start',
          }}
        >
          <p style={{ paddingTop: '10px' }}>Prediction Status:</p>
          <Chip
            label={isCorrectPrediction ? 'CORRECT' : 'INCORRECT'}
            style={{
              width: '120px',
              marginLeft: '4px',
              backgroundColor: isCorrectPrediction ? '#E0E4E8' : '#de7272',
            }}
          />
          <p style={{ paddingTop: '10px', marginLeft: '1rem' }}>Confidence Score:</p>
          <Chip
            label={props.selectedResource?.confidence_score + '%'}
            style={{
              minWidth: '70px',
              marginLeft: '4px',
              backgroundColor: theme.palette.grey[100]
            }}
          />
          <p style={{ paddingTop: '10px', marginLeft: '1rem' }}>IOU Score:</p>
          <Chip
            label={props.selectedResource?.iouScore?.toFixed(2)}
            style={{
              minWidth: '70px',
              marginLeft: '4px',
              backgroundColor: theme.palette.grey[100]
            }}
          />
        </Box>

          {
  props.modelReferenceGuides?.length > 0 && (
    <div className={css.referenceGuideAndGroundTruthButtonGroupContainer}>
      <div >
        {/* Box Component */}
     

        {/* Button Group */}
        <ButtonGroup color="primary" size="small">
          <Button
            variant={doesUserWantsToSeeReferenceGuide ? 'outlined' : 'contained'}
            onClick={() => {
              setDoesUserWantsToSeeReferenceGuide(false);
            }}
          >
            Ground Truth Image
          </Button>
          <Button
            variant={doesUserWantsToSeeReferenceGuide ? 'contained' : 'outlined'}
            onClick={() => {
              setDoesUserWantsToSeeReferenceGuide(true);
            }}
          >
            Reference Guide
          </Button>
        </ButtonGroup>
      </div>
    </div>
  )
}

</div>             
<div>
  <button
    style={{ float: "left", backgroundColor: "white", border: "none" }}
    onClick={moveBack}
    disabled={shouldPreviousButtonBeDisabled()}
  >
    <ArrowBackIosIcon />
    <span> Previous Image</span>
  </button>
  <button
    style={{ float: "right", backgroundColor: "white", border: "none" }}
    onClick={moveNext}
    disabled={shouldNextButtonBeDisabled()}
  >
    Next Image <span> <ArrowForwardIosIcon style={{ fontSize: "12px" }} /></span>
  </button> 
</div>


          <div className={classes.contentContainer}>
            <div className={classes.firstColumn}>
              {
                doesUserWantsToSeeReferenceGuide
                ?
                  <div className={css.referenceGuideContainer}>
                    <iframe title="Reference Guide" className={css.referenceGuideIframe} src={`${window.location.origin}/reference-guide?modelId=${modelId}`}></iframe>
                  </div>
                :
                  <>
                  <div className={classes.imageText}>
                    <span style={{marginLeft:'10px'}}>Ground Truth</span>
                    <IconButton className={classes.expandButton} style={{visibility: "hidden"}}>
                      <ZoomOutMapIcon />
                    </IconButton>
                    <div style={{display:"flex",alignItems:"center"}}><div
                     style={{
                       padding: "6px 12px",
                       display: "flex"
                     }}
                   >
                     <span title="Ctrl + scroll to zoom an image">Zoom:</span>&nbsp;<div title="1 is the default zoom level" style={{minWidth: "35px",color:"white"}}>{stageScaleForSync.toFixed(1)}</div>&nbsp;
                     <Button
                       // variant="contained"
                       style={{
                         paddingTop: 0,
                         paddingBottom: 0,
                         textTransform: "none",
                         cursor: "pointer",
                         fontSize: "16px",
                         color:'white'
                       }}
                       onClick={handleResetZoom}
                       size="small"
                       // color="secondary"
                       startIcon={<MdAutorenew style={{fontSize: "15px"}} />}
                     >
                       Reset Zoom
                     </Button>							
                   </div>		
                   
                    {(props.modelInformation?.modelType === "imageAnnotationGroup" || props.modelInformation?.modelType === "imageAnnotation") && 
	 <span>
                        <button onClick={handleGroundTruthExpandView} style={{background: "#1f1f1f", color: "white", border: "none", display: "none"}}>Expand View</button>
                      </span>}
                      </div>
                  
                  </div>
                  <div
                  style={{
                    height: props.isSpectrogramAudioContext? "90%":"",
                    flexGrow: props.isSpectrogramAudioContext? 0:1
                  }}
                    id={groundTruthTwoDimensionalImageHTMLContainerId}
                    className={css.twoDimensionalImageContainer}
                  >
                    {
                      !isGroundTruthAnnotationsImageScalingGoingOn &&
                      <SegmentationAnalyticsTwoDimensionalImage 
                       condition="groundTruth"
                        handleSyncForImages={handleStageScale}
                        stageScaleForSync={stageScaleForSync}
                        zoomCursorPosition={zoomCursorPosition}
                        metadata={props.selectedResource?.metadata || "{}"}
                        
                        draggingCoordinates={draggingCoordinates}
                        onDrag={handleDrag}
                        scenario={props.isSpectrogramAudioContext? "spectrogramAudio" : ""}
                        url={ props.isSpectrogramAudioContext? (props.selectedResource?.spectrogram || "") : (props.selectedResource?.resourceUrl || " ")}
                        imageWidth={groundTruthImageWidthForTwoDimensionalImage}
                        AnnotationOption={defaultAnnotationOptions}
                        focusedName={annotationNameWhichIsInFocus}
                        defaultAnnotations={groundTruthImageAnnotationsForTwoDimensionalImage}
                        isDynamicOptionsEnable
                        isViewOnlyMode={false}
                        handlePlayAudioRegionOnGroundTruth={handlePlayAudioRegionOnGroundTruth}
                        isLabelOn={true}
                        isZoomFeatureEnabled={true}
                        setSelectedAnnotationId={setSelectedAnnotationId}
                        setSelectedAnnotationLabel={setSelectedAnnotationLabel}
                      />
                    }
                    {
                      props.isSpectrogramAudioContext&&
                      <div style={{marginLeft: -8}}>
                      <SpectrogramAudioTrimUI resource={spectrogramResource} updateResourceInUI={() => {}}
                       copilotSpectrogramAudioAnnotation={groundDefaultTruthAudioAnnotation||[]} 
                       copilotMaxAudioTrimAllowedInMilliSeconds={100} 
                       selectedSpectrogramAnnotation={playAudioRegionOnGroundTruth||""} 
                       setSelectedSpectrogramAnnotation={setPlayAudioRegionOnGroundTruth} 
                       getSpectrogramCoordinatesFromAudioDuration={function (newRegion: any, color: string): Promise<void> {
                          throw new Error('Function not implemented.');
                        } } 
                      setWidth={`${groundTruthImageWidthForTwoDimensionalImage}px`} elementId={0} />
                      </div>
                    }
                  </div>
                  <div>
                    <div>
                  <ImageResourceHyperlink 
                    linkLabel='Open in Task Data Table'
                    resourceFileName={props.selectedResource?.fileName || ""}
                    resourceStatus={props.selectedResource?.status || "approved"}
                    modelId={urlSearchQuery.get("purposeModelId") ? props.selectedResource?.analyticsPurposeModelData?.model?._id || "" : props.modelInformation?.model?._id || urlSearchQuery.get("TestDriveChatBotWindow_model") || ""}     
                    projectId={urlSearchQuery.get("purposeModelId") ? props.selectedResource?.analyticsPurposeModelData?.model?.project || "" : urlSearchQuery.get("project") || urlSearchQuery.get("TestDriveChatBotWindow_project") || ""}
                    resourceId={props.selectedResource?._id || ""}           
                  />
                  </div>
                  </div>
                  
                  {/* <img src={toadd} alt="Image" className={classes.image} /> */}
                  {
                    props.modelInformation?.modelType === "imageAnnotationGroup" && doesUserWantsToSeeAllAnnotationsOfThisImage
                    ?
                      <div className={classes.buttonContainer}>
                        {groundTruthAnnotationGroups?.map(groundTruthAnnotationGroup=>{
                          return (
                          <div className={css.annotationGroup}>
                            <div className={css.annotationGroupNameRow}
                              onClick={()=>{
                                setAnnotationGroupIdsWhichAreExpanded(oldState=>{
                                  if (oldState.includes(groundTruthAnnotationGroup?.id)) {
                                    const newState = oldState.filter(groupId=>groupId !== groundTruthAnnotationGroup?.id)
                                    return [...newState]
                                  } else {
                                    return [...oldState, groundTruthAnnotationGroup?.id]
                                  }
                                })
                              }}
                            >
                              <div>{groundTruthAnnotationGroup.name}</div>
                              <div>{
                                !annotationGroupIdsWhichAreExpanded.includes(groundTruthAnnotationGroup?.id)
                                ?
                                  <ExpandMoreIcon />
                                :  <ExpandLessIcon />
                                }</div>
                            </div>
                            {
                              annotationGroupIdsWhichAreExpanded.includes(groundTruthAnnotationGroup?.id)
                              &&
                              <div className={css.annotationGroupAnnotations}>
                                {groundTruthAnnotationGroup.imageAnnotations?.map(annotation=>{
                                  return (
                                    <Button
                                      variant="contained"
                                      className={classes.bigButton}
                                      style={{border:`1px solid ${annotation?.color}`, borderLeft:`5px solid ${annotation?.color}`}}
                                      onClick={()=>{
                                        setAnnotationNameWhichIsInFocus(annotation?.name || "")
                                      }}
                                    >
                                      <span>{annotation?.selectedOptions?.[1]?.value}</span>
                                      {
                                        !isNullOrUndefined(annotation?.confidenceScore) &&
                                        <span style={{borderRadius:'1rem', width:'5rem', backgroundColor:'#f4f5f7', marginLeft:'10rem'}}>{annotation?.confidenceScore}%</span>
                                      }
                                      <span style={{borderRadius:'1rem', width:'7rem', backgroundColor:'#f4f5f7'}}>{annotation?.isClosed ? "Polygon" : "Line"}</span>
                                  </Button>                                
                                  )
                                })}
                              </div>
                            }
                          </div>
                          )
                        })}
                      </div>
                    :
                      <div className={classes.buttonContainer} style={{display: "none"}}>
                        {
                          groundTruthAnnotations
                          ?.filter(annotation=>{
                            if (!annotationLabelSelectedByUserForViewing) {return true};
                            if (annotation?.selectedOptions?.[1]?.value === annotationLabelSelectedByUserForViewing) {
                              return true
                            }
                            return false
                          })
                          .map(annotation=>{
                            return (
                              <Button
                                variant="contained"
                                className={classes.bigButton}
                                style={{border:`1px solid ${annotation?.color}`, borderLeft:`5px solid ${annotation?.color}`}}
                              >
                                <span>{annotation?.selectedOptions?.[1]?.value}</span>
                                {
                                  !isNullOrUndefined(annotation?.confidenceScore) &&
                                  <span style={{borderRadius:'1rem', width:'5rem', backgroundColor:'#f4f5f7', marginLeft:'10rem'}}>{annotation?.confidenceScore}%</span>
                                }
                                <span style={{borderRadius:'1rem', width:'7rem', backgroundColor:'#f4f5f7'}}>{annotation?.isClosed ? "Polygon" : "Line"}</span>
                              </Button>
                            )
                          })
                        }
                      </div>
                  }
                  </>
              }
            </div>

            <div style={{justifyContent: (doesUserWantsToSeeAllAnnotationsOfThisImage&&props.modelInformation?.modelType==="imageAnnotationGroup")? "space-between":""}} className={classes.secondColumn}>
              <div className={classes.imageText}>
                <span style={{marginLeft:'10px'}}>Model {selectedModelCollectionVersion}</span>
                <IconButton className={classes.expandButton} style={{visibility: "hidden"}}>
                  <ZoomOutMapIcon />
                </IconButton>
                <div style={{display:"flex",alignItems:"center"}}><div
                     style={{
                       padding: "6px 12px",
                       display: "flex"
                     }}
                   >
                     <span title="Ctrl + scroll to zoom an image">Zoom:</span>&nbsp;<div title="1 is the default zoom level" style={{minWidth: "35px",color:"white"}}>{stageScaleForSync.toFixed(1)}</div>&nbsp;
                     <Button
                       // variant="contained"
                       style={{
                         paddingTop: 0,
                         paddingBottom: 0,
                         textTransform: "none",
                         cursor: "pointer",
                         fontSize: "16px",
                         color:'white'
                       }}
                       onClick={handleResetZoom}
                       size="small"
                       // color="secondary"
                       startIcon={<MdAutorenew style={{fontSize: "15px"}} />}
                     >
                       Reset Zoom
                     </Button>							
                   </div>		
                
                {(props.modelInformation?.modelType === "imageAnnotationGroup" || props.modelInformation?.modelType === "imageAnnotation") && 
               	 <span>
                    <button onClick={handleModelExpandView} style={{background: "#1f1f1f", color: "white", border: "none", display: "none"}}>Expand View</button>
                  </span>}
                  </div>
              </div>
                {/* <img src={toadd} alt="Image" className={classes.image} /> */}
                <div
                id={selectedModelResourceTwoDimensionalImageHTMLContainerId}
                className={css.twoDimensionalImageContainer}                
              >
                {
                  !isSelectedModelResourceAnnotationsImageScalingGoingOn &&
                  <SegmentationAnalyticsTwoDimensionalImage 
                  metadata={props.selectedResource?.metadata || "{}"}
                  draggingCoordinates={draggingCoordinates}
                  onDrag={handleDrag}
                  condition="model"
                  handleSyncForImages={handleStageScale}
                  stageScaleForSync={stageScaleForSync}
                  zoomCursorPosition={zoomCursorPosition}
                  scenario={props.isSpectrogramAudioContext? "spectrogramAudio" : ""}
                  url={ props.isSpectrogramAudioContext? (props.selectedResource?.spectrogram || "") : (props.selectedResource?.resourceUrl || " ")}
                    imageWidth={SelectedModelResourceImageWidthForTwoDimensionalImage}
                    AnnotationOption={defaultAnnotationOptions}
                    defaultAnnotations={selectedModelResourceImageAnnotationsForTwoDimensionalImage}
                    isDynamicOptionsEnable
                    focusedName={annotationNameWhichIsInFocus}
                    handlePlayAudioRegionOnGroundTruth={handlePlayAudioRegionOnPredicted}
                    isViewOnlyMode={false}
                    isZoomFeatureEnabled={true}
                    isLabelOn={true}
                    setSelectedAnnotationId={setSelectedAnnotationId}
                    setSelectedAnnotationLabel={setSelectedAnnotationLabel}
                  />
                }

                {
                  props.isSpectrogramAudioContext &&
                  <div style={{ marginLeft: -8 }}>
                    <SpectrogramAudioTrimUI resource={spectrogramResource} updateResourceInUI={() => { }}
                      copilotSpectrogramAudioAnnotation={selectedModelResourceAudioAnnotations || []}
                      copilotMaxAudioTrimAllowedInMilliSeconds={100}
                      selectedSpectrogramAnnotation={playAudioRegionOnPredicted}
                      setSelectedSpectrogramAnnotation={setPlayAudioRegionOnPredicted}
                      getSpectrogramCoordinatesFromAudioDuration={function (newRegion: any, color: string): Promise<void> {
                        throw new Error('Function not implemented.');
                      }}
                      setWidth={`${groundTruthImageWidthForTwoDimensionalImage}px`} elementId={1} />
                  </div>}

              </div>  

              <div style={{visibility: "hidden"}}>
                  <ImageResourceHyperlink 
                    linkLabel='Open in Task Data Table'
                    resourceFileName={props.selectedResource?.fileName || ""}
                    resourceStatus={props.selectedResource?.status || "approved"}
                    modelId={urlSearchQuery.get("purposeModelId") ? props.selectedResource?.analyticsPurposeModelData?.model?._id || "" : props.modelInformation?.model?._id || ""}     
                    projectId={urlSearchQuery.get("purposeModelId") ? props.selectedResource?.analyticsPurposeModelData?.model?.project || "" : urlSearchQuery.get("project") || ""}
                    resourceId={props.selectedResource?._id || ""}           
                  />
                  </div> 
                                
              {
                props.modelInformation?.modelType === "imageAnnotationGroup" && doesUserWantsToSeeAllAnnotationsOfThisImage
                ?
                  <div className={classes.buttonContainer}>
                    {predictedImageAnnotationGroups?.map(predictedAnnotationGroup=>{
                      return (
                      <div className={css.annotationGroup}>
                        <div className={css.annotationGroupNameRow}
                          onClick={()=>{
                            setAnnotationGroupIdsWhichAreExpanded(oldState=>{
                              if (oldState.includes(predictedAnnotationGroup?.id)) {
                                const newState = oldState.filter(groupId=>groupId !== predictedAnnotationGroup?.id)
                                return [...newState]
                              } else {
                                return [...oldState, predictedAnnotationGroup?.id]
                              }
                            })
                          }}
                        >
                          <div>{predictedAnnotationGroup.name}</div>
                          <div>{
                            !annotationGroupIdsWhichAreExpanded.includes(predictedAnnotationGroup?.id)
                            ?
                              <ExpandMoreIcon />
                            :  <ExpandLessIcon />
                            }</div>
                        </div>
                        {
                          annotationGroupIdsWhichAreExpanded.includes(predictedAnnotationGroup?.id)
                          &&
                          <div className={css.annotationGroupAnnotations}>
                            {predictedAnnotationGroup.imageAnnotations?.map(annotation=>{
                              return (
                                <Button
                                  variant="contained"
                                  className={classes.bigButton}
                                  style={{border:`1px solid ${annotation?.color}`, borderLeft:`5px solid ${annotation?.color}`}}
                                  onClick={()=>{
                                    setAnnotationNameWhichIsInFocus(annotation?.name || "")
                                  }}
                                >
                                  <span>{annotation?.selectedOptions?.[1]?.value}</span>
                                  {
                                    !isNullOrUndefined(annotation?.confidenceScore) &&
                                    <span style={{borderRadius:'1rem', width:'5rem', backgroundColor:'#f4f5f7', marginLeft:'10rem'}}>{annotation?.confidenceScore}%</span>
                                  }
                                  <span style={{borderRadius:'1rem', width:'7rem', backgroundColor:'#f4f5f7'}}>{annotation?.isClosed ? "Polygon" : "Line"}</span>
                              </Button>                                
                              )
                            })}
                          </div>
                        }
                      </div>
                      )
                    })}
                  </div>
                :
                    <div className={classes.buttonContainer} style={{display: "none"}}>
                      {
                        selectedModelResource?.predictedAnnotations
                        ?.filter(annotation=>{
                          if (!annotationLabelSelectedByUserForViewing) {return true};
                          if (annotation?.selectedOptions?.[1]?.value === annotationLabelSelectedByUserForViewing) {
                            return true
                          }
                          return false
                        })
                        .map(annotation=>{
                          return (
                          <Button
                            variant="contained"
                            className={classes.bigButton}
                            style={{border:`1px solid ${annotation.color}`, borderLeft:`5px solid ${annotation.color}`}}
                          >
                            <span>{annotation?.selectedOptions?.[1]?.value}</span>
                            {
                              !isNullOrUndefined(annotation?.confidenceScore) &&
                              <span style={{borderRadius:'1rem', width:'5rem', backgroundColor:'#f4f5f7', marginLeft:'10rem'}}>{annotation?.confidenceScore}%</span>
                            }                        
                            <span style={{borderRadius:'1rem', width:'7rem', backgroundColor:'#f4f5f7'}}>{annotation?.isClosed ? "Polygon" : "Line"}</span>
                          </Button>
                          )
                        })                    
                      }
                  </div>
              }             
            </div>

            {expandView &&
              <AnnotationsListPopup 
              handleSyncForImages={handleStageScale}
              stageScaleForSync={stageScaleForSync}
              zoomCursorPosition={zoomCursorPosition}
              open={true} 
              handleClose={() => setExpandView(false)}
              modelCollectionId={''}
              architecturesAvailable={[]}
              selectedResource={props.selectedResource}
              groupAnnotations={isGroundTruthAnnotations ? groundTruthAnnotationGroups : predictedImageAnnotationGroups}
              annotations={isGroundTruthAnnotations ? groundTruthAnnotations : predictedAnnotations}
              modelType={props.modelInformation?.modelType}
            />}

            <div className={classes.thirdColumn} style={{display: "none"}}>
              <div>
                <List>
                  {
                    resourceIdRelatedModelsData?.map((modelResource, index)=>{
                      return (
                        <ListItem key={index}>
                        <div className={`${selectedModelCollectionVersion === modelResource?.modelVersion ? css.selectedModelBackgroundColor : ""}`} style={{borderRadius:'8px', padding:'10px', cursor: "pointer"}}
                          onClick={()=>{
                            setSelectedModelCollectionVersion(modelResource.modelVersion)
                          }}
                        >
                          <div style={{backgroundColor:'#1f1f1f', borderRadius:'10px' }}>
                            <img src={resourceUrl} className={classes.image1} style={{width: "150px", height: "140px", objectFit: "contain"}} />
                          </div>
                          <div style={{display:'flex', justifyContent:'space-between', marginTop:'6px'}}>
                            <span style={{fontSize:'12px'}}><u>Model {modelResource.modelVersion}</u></span>
                            {/* <span style={{fontSize:'12px'}}><u>Accuracy 100%</u></span> */}
                          </div>
                        </div>
                      </ListItem>
                      )
                    })
                  }
                </List>
              </div>
            </div>
          </div>
        </div>
        <Dialog 
          open={zoomModalOpen} 
          onClose={handleZoomModalClose}
          maxWidth="md"
        >
          <DialogContent>
                <Typography variant="h5" gutterBottom>
                  Model 5.5
                </Typography>
                <span>Model Information</span>
                <hr style={{ borderTop: '1px solid #ddd', marginBottom: '10px' }} />

                <div style={{ display: 'flex' }}>
                  <div style={{ flex: 1, marginRight: '10px' }}>
                    <span>Information</span>
                    <table style={{ borderCollapse: 'collapse', width: '100%' }}>
                      <tbody>
                        <tr style={{ marginBottom: '2rem' }}>
                          <td style={{ height: '4rem' }}>5.5</td>
                          <td style={{ height: '3.7rem' }}>Model:</td>
                        </tr> 
                        <tr style={{ marginBottom: '10px' }}>
                          <td style={{ height: '3.7rem' }}>abcd_123.wav</td>
                          <td style={{ height: '3.7rem' }}>File Name:</td>
                        </tr>
                        <tr style={{ marginBottom: '10px' }}>
                          <td style={{ height: '3.7rem' }}>Tag:</td>
                          <td style={{ height: '3.7rem' }}>
                            <span style={{ display:'flex', justifyContent:'center', alignItems:'center', backgroundColor:'#f4f5f7', borderRadius:'2rem', width:'7rem', height:'2.7rem'}}> Loaner box</span>
                          </td>
                        </tr>
                        <tr style={{ marginBottom: '10px' }}>
                          <td style={{ height: '3.7rem' }}>Data Set Collections:</td>
                          <td style={{ height: '3.7rem' }}>Test V1</td>
                        </tr>
                        <tr style={{ marginBottom: '10px' }}>
                          <td style={{ height: '3.7rem' }}>Created On:</td>
                          <td style={{ height: '3.7rem' }}>Data 2</td>
                        </tr>
                        <tr style={{ marginBottom: '10px' }}>
                          <td style={{ height: '3.7rem' }}>Last Modified:</td>
                          <td style={{ height: '3.7rem' }}>05:43:33 | 16-02-2022</td>
                        </tr>
                        <tr style={{ marginBottom: '10px' }}>
                          <td style={{ height: '3.7rem' }}>CSV:</td>
                          <td style={{ height: '3.7rem' }}>date_id.minute_group....<u><a>Show csv</a></u></td>
                        </tr>
                      </tbody>
                    </table>
                  </div>
                  <div style={{ flex: 1 }}>
                  <span>Classes</span>
                  <div className={classes.buttonContainerPopup}>
                    <Button
                      variant="contained"
                      className={classes.bigButton}
                      style={{border:'1px solid green', borderLeft:'5px solid green'}}
                    >
                      <span>sizlli</span>
                      <span style={{borderRadius:'1rem', width:'5rem', backgroundColor:'#f4f5f7', marginLeft:'10rem'}}>100%</span>
                      <span style={{borderRadius:'1rem', width:'7rem', backgroundColor:'#f4f5f7'}}>Polygon</span>
                    </Button>
                    <Button
                      variant="contained"
                      className={classes.bigButton}
                      style={{border:'1px solid red', borderLeft:'5px solid red'}}
                    >
                      <span>sizlli</span>
                      <span style={{borderRadius:'1rem', width:'5rem', backgroundColor:'#f4f5f7', marginLeft:'10rem'}}>100%</span>
                      <span style={{borderRadius:'1rem', width:'7rem', backgroundColor:'#f4f5f7'}}>Polygon</span>
                    </Button>
                    <Button
                      variant="contained"
                      className={classes.bigButton}
                      style={{border:'1px solid red', borderLeft:'5px solid red'}}
                    >
                      <span>sizlli</span>
                      <span style={{borderRadius:'1rem', width:'5rem', backgroundColor:'#f4f5f7', marginLeft:'10rem'}}>100%</span>
                      <span style={{borderRadius:'1rem', width:'7rem', backgroundColor:'#f4f5f7'}}>Polygon</span>
                    </Button>
                    <Button
                      variant="contained"
                      className={classes.bigButton}
                      style={{border:'1px solid blue', borderLeft:'5px solid blue'}}
                    >
                      <span>sizlli</span>
                      <span style={{borderRadius:'1rem', width:'5rem', backgroundColor:'#f4f5f7', marginLeft:'10rem'}}>100%</span>
                      <span style={{borderRadius:'1rem', width:'7rem', backgroundColor:'#f4f5f7'}}>Polygon</span>
                    </Button>
                  </div>
                  </div>
                </div>
              </DialogContent>

          </Dialog>
      </Dialog>
    </div>
  );
}


export default ImageAnnotationSegmentationDialog;
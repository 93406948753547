import { useEffect, useState } from "react"
import UserDBService from "../services/UserDBService"

const useAuth = () => {
    const[userEmail, setUserEmail] = useState(sessionStorage.getItem('userEmail') || '')
    const[userId, setUserId] = useState(sessionStorage.getItem('userId') || '')

    useEffect(() => {
        const getAndSetUserInfo = async () => {
            try {
                const userInfo = await UserDBService.getUserEmail()

                sessionStorage.setItem('userId', userInfo._id)
                setUserId(userInfo._id)
                
                sessionStorage.setItem('userEmail', userInfo.emailId)
                setUserEmail(userInfo.emailId)
            } catch (error) {
                console.error("Error fetching user info:", error);
            }
        }
    
        if(!userEmail || !userId) getAndSetUserInfo();
    }, [])

    return {userEmail, userId}  
}

export default useAuth
import React from "react";
import ReactMarkdown from "react-markdown";
// import remarkGfm from "remark-gfm";
interface ExplainabilityContentMarkdownProps {
    content: string;
    components?: Record<string, React.ElementType>;
    imgStyle?: React.CSSProperties;
    paragraphTagSyle?: React.CSSProperties;
    codeTagStyle?: React.CSSProperties;
    blockquoteTagStyle?: React.CSSProperties;
    ulTagStyle?: React.CSSProperties;
    heading3?: string;
}

const ExplainabilityContentMarkdown: React.FC<ExplainabilityContentMarkdownProps> = ({
    content,
    components = {},
    imgStyle = {},
    paragraphTagSyle = {},
    codeTagStyle = {},
    blockquoteTagStyle = {},
    heading3 = ""
}) => {
    const defaulImgStyle: React.CSSProperties = {
        width: "100%",
        maxWidth: "36rem",
        marginLeft: "auto",
        marginRight: "auto",
        borderRadius: "0.375rem",
        boxShadow: "0 4px 6px rgba(0, 0, 0, 0.1)",
        objectFit: "contain",
        maxHeight: "36rem"
    };
    const defaultParagraphTagStyle: React.CSSProperties = {
        maxWidth: "100%",
        wordWrap: "break-word",
        overflowWrap: "break-word",
        wordBreak: "break-word",
        whiteSpace: "normal",
        overflow: "hidden",
        display: "block",
    };
    const defaulCodeTagStyle: React.CSSProperties = {
        padding: "0.25rem 0.5rem",
        backgroundColor: "#F7FAFC",
        borderRadius: "0.25rem",
        color: "#2D3748",
    };
    const defaultBlockquoteTagStyle: React.CSSProperties = {
        borderLeftWidth: "0.25rem",
        borderLeftColor: "#E2E8F0",
        paddingLeft: "1rem",
        fontStyle: "italic",
        color: "#718096",
    };

    const escapedContent = content?.replace(/```/g, "\\`\\`\\`");


    return (
        <ReactMarkdown
            // remarkPlugins={[remarkGfm]}
            components={{
                img: ({ node, ...props }: any) => (
                    <img
                        {...props}
                        alt={props?.alt || ""}
                        className="w-full max-w-xl mx-auto rounded-md shadow-md"
                        style={{ ...defaulImgStyle, ...imgStyle }}
                    />
                ),
                p: ({ node, children, ...props }: any) => (
                    <p
                        {...props}
                        className="text-base leading-relaxed mb-4 break-words"
                        style={{ ...paragraphTagSyle, ...defaultParagraphTagStyle }}
                    >
                        {React.Children.toArray(children).map((child) =>
                            typeof child === "string" ? child.split("<br>").map((line, index, arr) => (
                                <React.Fragment key={index}>
                                    {line}
                                    {index < arr.length - 1 && <br />}
                                </React.Fragment>
                            )) : child
                        )}
                    </p>
                ),
                pre: ({ node, ...props }: any) => (
                    <pre
                        {...props}
                        className="p-4 rounded-md bg-gray-900 text-white overflow-x-auto"
                    />
                ),
            //     code: ({ node, inline, className, children, ...props }: any) => {
            //         return inline ? (
            //             <code
            //                 className="px-1 py-0.5 bg-gray-200 rounded text-red-600"
            //                 style={{ ...defaulCodeTagStyle, ...codeTagStyle }}
            //                 {...props}
            //             >
            //                 {children}
            //             </code>
            //         ) : (
            //             <pre
            //                 className="p-4 rounded-md bg-gray-900 text-white overflow-x-auto"
            //                 {...props}
            //             >
            //                 <code className="text-sm">{children}</code>
            //             </pre>
            //         );
            //     },
                blockquote: ({ node, ...props }: any) => (
                    <blockquote
                        {...props}
                        className="border-l-4 border-blue-500 pl-4 italic text-gray-600"
                        style={{ ...defaultBlockquoteTagStyle, ...blockquoteTagStyle }}
                    />
                ),
                ul: ({ node, ...props }: any) => (
                    <ul {...props} className="list-disc pl-6 space-y-2" />
                ),
                ol: ({ node, ...props }: any) => (
                    <ol {...props} className="list-decimal pl-6 space-y-2" />
                ),
                li: ({ node, ...props }: any) => <li {...props} className="ml-2" />,
                hr: ({ node, ...props }: any) => (
                    <hr {...props} className="my-6 border-t-2 border-gray-300" />
                ),
                table: ({ node, ...props }: any) => (
                    <table
                        {...props}
                        className="table-auto border-collapse border border-gray-300 w-full"
                    >
                        {props.children}
                    </table>
                ),
                thead: ({ node, ...props }: any) => (
                    <thead {...props} className="bg-gray-200 font-semibold">
                        {props.children}
                    </thead>
                ),
                tbody: ({ node, ...props }: any) => (
                    <tbody {...props} className="divide-y divide-gray-300">
                        {props.children}
                    </tbody>
                ),
                tr: ({ node, ...props }: any) => (
                    <tr {...props} className="border border-gray-300">
                        {props.children}
                    </tr>
                ),
                th: ({ node, ...props }: any) => (
                    <th {...props} className="border border-gray-300 px-4 py-2">
                        {props.children}
                    </th>
                ),
                td: ({ node, ...props }: any) => (
                    <td {...props} className="border border-gray-300 px-4 py-2">
                        {props.children}
                    </td>
                ),
                ...components, // Allow user overrides
            }}
        >
            {`${heading3 ? '###  ' + heading3 + '\n' : ''}` + escapedContent}
        </ReactMarkdown>
    );
};
export default ExplainabilityContentMarkdown;
import React, {useCallback, useEffect, useMemo, useRef, useState} from 'react';
import Grid from '@material-ui/core/Grid';
import Paper from '@material-ui/core/Paper';
import { makeStyles, createStyles, Theme } from '@material-ui/core/styles';
import TestDataSet from './modelAnalyticsDashboard/TestDataSet';
import TopPerformingModel from './modelAnalyticsDashboard/TopPerformingModel';
import Integrity from './modelAnalyticsDashboard/Integrity';
import ModelComparision from './modelAnalyticsDashboard/ModelComparision';
import IndividualClassAccuracy from './modelAnalyticsDashboard/IndividualClassAccuracy';
// import { useHistory, useLocation } from "react-router";
import { IIntegrity, IModel, IModelAnalyticsAccuracy, IModelAnalyticsIntegrity, IModelAnalyticsTestDataSet, IModelAnalyticsTestStatisticalData, IModelCollectionsAdditionalData, IntegrityBand, IReduxState, modelCollectionAdditionalDataRequestPayloadForIndividualClassAccuracy, modelCollectionAdditionalDataRequestPayloadForIntegrity, modelCollectionAdditionalDataRequestPayloadForTopPerforming } from '../../../../../../../../../../../common';
import { DataService, ModelService } from '../../../../../../../../../../../services';
import { connect, ConnectedProps } from 'react-redux';
import { setSideDrawer } from '../../../../../../../../../../../store/actions';
import Analytics from '../../../../../components/Analytics';
import { useURLQuery } from '../../../../../../../../../../../hooks/useURLQuery';
import { ModelAnalyticsIndividualClassAccuracyApiResponseData, ModelAnalyticsIntegrityApiResponseDataClass, ModelAnalyticsInterityApiResponseData } from '../../../../../../../../../../../common/constants/interfaces/modelAnalyticsIndividualClassAccuracyApiResponse';
import { CONFIDENCE_SCORE_THRESHOLD_DEFAULT_VALUE, IOU_THRESHOLD_DEFAULT_VALUE_FOR_SEGMENTATION_ANALYTICS } from './integrity';
import { isNullOrUndefined } from '../../../../../../../../../../../services/variableHelperService';
import { getCollectionAccuracyFromLocalStorage, keyToSaveCollectionAccuracyInLocalStorage, saveCollectionAccuracyInLocalStorage } from '../TrainedModelPopup';

export enum AnalyticsDashboardQueryParams {
    modelId="model",
    modelCollectionIdsToCompare="modelCollectionIdsToCompare",
    modelCollectionsAdditionalDataForIndividualClassAccuracy="modelCollectionsAdditionalDataForIndividualClassAccuracy",
    modelCollectionsAdditionalDataForIntegrity="modelCollectionsAdditionalDataForIntegrity",
    modelCollectionsAdditionalDataForTopPerforming="modelCollectionsAdditionalDataForTopPerforming",
    testCollectionId="testCollectionId"
}

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      flexGrow: 1,
    },
    paper: {
      height: 140,
      width: 100,
    },
    control: {
      padding: theme.spacing(2),
    },
    testDataSet:{
        width: "300px",
        height: "100%",
    },
    topPerformaingModel:{
        height: "200px",
        width: "550px",
    },
    modelComparision:{
        height: "200px",
        width: "550px",
    },
    integrity:{
        height: "200px",
        width: "550px",
    },
    individualClassAccuracy:{
        height: "200px",
        width: "550px",
    },
    collections:{
        width: "100%",
        height: "36px",
       //  margin: "10px 0 10px 22px",
       margin: "0 0 16px 0"
    },
    collectionHeader: {
        display: "flex",
        alignItems: "center",
        height: "100%",
        marginLeft: "22px",
        fontWeight: 600,
        textAlign: "left",
        font: "normal normal 600 12px/26px Nunito Sans",
        letterSpacing: "0.32px",
        color: "#1A1A1A",
        opacity: 1
    },
    allTables: {
        // TODO: Ask Priyanshu about below
        // height: "608px"
        // height: "calc(100vh - 272px)",

        paddingLeft: "2px",
        paddingRight: "2px",
        '@media (min-width:1150px)' : {
            height: "calc(100vh - 207px)",
        }         
    },
    row1 : {
        '@media (min-width:1150px)' : {
            maxWidth: "21.7%", 
            flexBasis:"21.7%", 
            height: "100%",
        },
        '@media (max-width:1150px) and (min-width:750px)' : {
            maxWidth: "35.7%", 
            flexBasis:"35.7%", 
            height: "100%"
        },
        '@media (max-width:750px)' : {
            maxWidth: "100%", 
            flexBasis:"100%", 
            height: "100%"
        },
    },
    row2 : {
        '@media (min-width:1150px)' : {
        maxWidth: "36.9%", 
        flexBasis:"36.9%", 
        height: "100%",
        },
        '@media (max-width:1150px) and (min-width:750px)' : {
            maxWidth: "64.3%", 
            flexBasis:"64.3%", 
            height: "100%"
        },
        '@media (max-width:750px)' : {
            maxWidth: "100%", 
            flexBasis:"100%", 
            height: "100%"
        },
    },
    row3 : {
        '@media (min-width:1150px)' : {
        maxWidth: "41%", 
        flexBasis:"41%", 
        height: "100%",
        },
        '@media (max-width:1150px) and (min-width:750px)' : {
            maxWidth: "100%", 
            flexBasis:"100%", 
            height: "100%"
        },
        '@media (max-width:750px)' : {
            maxWidth: "100%", 
            flexBasis:"100%", 
            height: "100%"
        },
        },
    topPerformingModelSubColumn: {
        '@media (min-width:1150px)' : {
            height: "100%",
        }
    },
    integritySubColumn: {
        '@media (min-width:1150px)' : {
            height: "100%",
        }
    },
    IntegrityGrid: {
        '@media (min-width:1150px)' : {
            height: "169px",
        }
    },
    individualClassAccuracyGrid: {
        '@media (min-width:1150px)' : {
            height: "calc(100% - 153px)",
        }
    },
    modelComparisonGrid: {
        '@media (min-width:1150px)' : {
            height: "calc(100% - 140px)",
        }        
    }
  }),
);

export type ClassAccuracyLabelFilter = {
    label: string;
    confidenceScoreThreshold: number | undefined;
};

export type DashboardIndividualClassAccuracyFilter = {
    modelCollectionId: string;
    labels: ClassAccuracyLabelFilter[];
}[];

export type DashboardIntegrityFilter = {
    modelCollectionId: string;
    confidenceScoreThreshold: number
}[];


function ModelAnalyticsDashboard(props: {selectedModelVersions: string[], selectedModelIds: string[]}) {
    const classes = useStyles();
    // const urlSearchParams = new URLSearchParams(window.location.search);
    // const modelId = urlSearchParams.get("model");
    // const modelCollectionIdsToCompare = urlSearchParams.get("modelCollectionIdsToCompare")?.split(",");
    const [isTestCollectionPresent, setTestCollectionPresent] = useState<boolean>(false);
    // const [modelAnalyticsTopPerformingModel, setModelAnalyticsTopPerformingModel] = useState<IModelAnalyticsTopPerformingModel[]>([]);
    // const [modelAnalyticsModelComparison, setModelAnalyticsModelComparison] = useState<IModelAnalyticsModelComparison[]>([]);
    const [modelAnalyticsTestStatisticalData, setModelAnalyticsTestStatisticalData] = useState<IModelAnalyticsTestStatisticalData[]>([]);
    const [modelAnalyticsTestStatisticalDataForIntegrity, setModelAnalyticsTestStatisticalDataForIntegrity] = useState<IModelAnalyticsTestStatisticalData[]>([]);
    const [modelAnalyticsTestStatisticalDataForTopPerformingModel, setModelAnalyticsTestStatisticalDataForTopPerformingModel] = useState<IModelAnalyticsTestStatisticalData[]>([]);
    const [modelAnalyticsIntegrity, setModelAnalyticsIntegrity] = useState<IModelAnalyticsIntegrity[]>([]);
    const [modelAnalyticsTestDataSet, setModelAnalyticsTestDataSet] = useState<IModelAnalyticsTestDataSet[]>([]);
    const [modelAnalyticsAccuracy, setModelAnalyticsAccuracy] = useState<IModelAnalyticsAccuracy[]>([]);
    const [modelCollectionVersionsToShow, setModelCollectionVersionsToShow] = useState<string[]>([]);

    // const [isFetchingModelAnalyticsTopPerformingModel, setIsFetchingModelAnalyticsTopPerformingModel] = useState<boolean>(false);
    const isFetchingModelAnalyticsTestStatisticalData = useRef<boolean>(false);
    // const [isFetchingModelAnalyticsModelComparison, setIsFetchingModelAnalyticsModelComparison] = useState<boolean>(false);
    // const [isFetchingModelAnalyticsIntegrity, setIsFetchingModelAnalyticsIntegrity] = useState<boolean>(false); 
    const isFetchingModelAnalyticsTestDataSet = useRef<boolean>(false);
    const isFetchingModelAnalyticsAccuracy = useRef<boolean>(false);

    const [individualClassAccuracyData, setIndividualClassAccuracyData] = useState<ModelAnalyticsIndividualClassAccuracyApiResponseData>([]);
    const [integrityData, setIntegrityData] = useState<ModelAnalyticsInterityApiResponseData>([]);
    const [topPerformingData, setTopPerformingData] = useState<ModelAnalyticsInterityApiResponseData>([]);;



    const urlSearchParams = useURLQuery();
    const modelId = urlSearchParams.get(AnalyticsDashboardQueryParams.modelId) || "";
    const modelCollectionIdsToCompare = useMemo(()=>{
        return urlSearchParams.get(AnalyticsDashboardQueryParams.modelCollectionIdsToCompare)?.split(",") || [];
    }, [urlSearchParams.get(AnalyticsDashboardQueryParams.modelCollectionIdsToCompare)])

    const modelCollectionsAdditionalDataQueryParamValueForIndividualClassAccuracy: modelCollectionAdditionalDataRequestPayloadForIndividualClassAccuracy[] = useMemo(()=>{
        return JSON.parse(urlSearchParams.get(AnalyticsDashboardQueryParams.modelCollectionsAdditionalDataForIndividualClassAccuracy) || "[]");
    }, [urlSearchParams.get(AnalyticsDashboardQueryParams.modelCollectionsAdditionalDataForIndividualClassAccuracy)])

    const modelCollectionsAdditionalDataQueryParamValueForIntegrity: modelCollectionAdditionalDataRequestPayloadForIntegrity[] = useMemo(()=>{
        return JSON.parse(urlSearchParams.get(AnalyticsDashboardQueryParams.modelCollectionsAdditionalDataForIntegrity) || "[]");
    }, [urlSearchParams.get(AnalyticsDashboardQueryParams.modelCollectionsAdditionalDataForIntegrity)])

    const modelCollectionsAdditionalDataQueryParamValueForTopPerforming: modelCollectionAdditionalDataRequestPayloadForTopPerforming[] = useMemo(()=>{
        return JSON.parse(urlSearchParams.get(AnalyticsDashboardQueryParams.modelCollectionsAdditionalDataForTopPerforming) || "[]");
    }, [urlSearchParams.get(AnalyticsDashboardQueryParams.modelCollectionsAdditionalDataForTopPerforming)])

    console.log("urlSearchParams",urlSearchParams.get(AnalyticsDashboardQueryParams.modelCollectionsAdditionalDataForIntegrity))

    const testCollectionId = urlSearchParams.get(AnalyticsDashboardQueryParams.testCollectionId)  || "";

    const [isFetchingIndividualClassAccuracyData, setIsFetchingIndividualClassAccuracyData] = useState(false);

    const [individualClassAccuracyFilter, setIndividualClassAccuracyFilter] = useState<DashboardIndividualClassAccuracyFilter>([]);

    const [isFetchingIntegrityData, setIsFetchingIntegrityData] = useState(false);
    const isFetchingTopPerformingData = useRef<boolean>(false);

    const [integrityFilter, setIntegrityFilter] = useState<DashboardIntegrityFilter>([]);
    const [topPerformingFilter, setTopPerformingFilter] = useState<DashboardIntegrityFilter>([]);

    const [modelDetails, setModelDetails] = useState<IModel>();

    useEffect(() => {
        const executeFunction = async () => {
            const apiResponse = await ModelService.getModelDetails({
              modelId: modelId
            })
            setModelDetails(apiResponse.data);
        }
        if (modelId) {
            executeFunction();
        }
    
    }, [modelId])
    

    useEffect(()=>{
        const executeFunction = async () => {
            try {
                setIsFetchingIndividualClassAccuracyData(true);
                const apiResponse = await DataService.getIndividualClassAccuracy({
                    modelCollectionIds: modelCollectionIdsToCompare.toString(),
                    modelId: modelId,
                    modelCollectionsAdditionalData: modelCollectionsAdditionalDataQueryParamValueForIndividualClassAccuracy || [],
                    testCollectionId: testCollectionId
                })
                setIndividualClassAccuracyData(apiResponse.data)
            } catch (error) {
                alert("Error while fetching individual class accuracy data")
            }
            setIsFetchingIndividualClassAccuracyData(false)
        }
        if (modelId && modelDetails && !modelDetails.isSegmentationModelAnalytics) {
            executeFunction();
        }
    }, [modelCollectionIdsToCompare, modelCollectionsAdditionalDataQueryParamValueForIndividualClassAccuracy, modelId, testCollectionId, modelDetails])

    useEffect(()=>{
        const executeFunction = async () => {
            try {
                setIsFetchingIntegrityData(true);
                const testDataSetCollectionId = urlSearchParams.get("testDataSetCollectionId");
                const apiResponse = await DataService.getModelAnalyticsTestStatisticalData({
                    modelCollectionIds: JSON.stringify(modelCollectionIdsToCompare),
                    modelId: modelId,
                    testCollectionId: testCollectionId,
                    getIntegrityFrequency: true,
                    getIntegrityFrequencyAccuracyPercent: true,
                    getIntegrityFrequencyAccuracyPercentComparisonWithLiveCollection: true,
                    ...(testDataSetCollectionId && {dataSetCollectionId: testDataSetCollectionId}),
                    modelCollectionsAdditionalData: JSON.stringify(modelCollectionsAdditionalDataQueryParamValueForIntegrity),
                })
                setModelAnalyticsTestStatisticalDataForIntegrity(apiResponse.data)
            } catch (error) {
                alert("Error while fetching integrity")
            }
            setIsFetchingIntegrityData(false)
        }
        if (modelId&&modelCollectionsAdditionalDataQueryParamValueForIntegrity.length!==0) {
            executeFunction();
        }
        else{
            setModelAnalyticsTestStatisticalDataForIntegrity([])
        }
    }, [modelCollectionIdsToCompare, modelCollectionsAdditionalDataQueryParamValueForIntegrity, modelId, testCollectionId])

    useEffect(()=>{
        const executeFunction = async () => {
            try {
                isFetchingTopPerformingData.current = true;
                const apiResponse = await DataService.getModelAnalyticsTestStatisticalData({
                    modelCollectionIds: JSON.stringify(modelCollectionIdsToCompare),
                    modelId: modelId,
                    getTestAccuracy: true,
                    getTestAccuracyComparisonWithLiveCollection: true,
                    modelCollectionsAdditionalData: JSON.stringify(modelCollectionsAdditionalDataQueryParamValueForTopPerforming),
                })

                //check in localStorage if accuracy values are available
                let accuraciesFoundInLocalStorage = true
                const collectionAccuracies = []
                for(const modelCollectionId of modelCollectionIdsToCompare){
                    //@ts-ignore
                    const accuracy = getCollectionAccuracyFromLocalStorage(testCollectionId, modelCollectionId, !isNullOrUndefined(modelDetails?.iouThresholdValue) ? modelDetails?.iouThresholdValue : IOU_THRESHOLD_DEFAULT_VALUE_FOR_SEGMENTATION_ANALYTICS, !isNullOrUndefined(modelDetails?.confidenceScoreThresholdForIntegrity) ? modelDetails?.confidenceScoreThresholdForIntegrity : CONFIDENCE_SCORE_THRESHOLD_DEFAULT_VALUE)
                    if(!accuracy){
                        accuraciesFoundInLocalStorage = false
                        break
                    }
                    else collectionAccuracies.push({
                        testCollectionId,
                        modelCollectionId,
                        accuracy
                    })
                }

                // overwrite the accuracy values according to the formaula good/total data points
                if(accuraciesFoundInLocalStorage){
                    for(const accuracyObj of collectionAccuracies){
                        let originalObj = apiResponse.data?.find(obj => obj.modelCollectionId === accuracyObj.modelCollectionId)
                        if(originalObj){
                            originalObj.testAccuracy.value = accuracyObj.accuracy
                        }
                    }
                }
                else {
                    if (modelDetails?.isSegmentationModelAnalytics) {

                        const modelCollectionAdditionalData = modelCollectionIdsToCompare.map(id => {
                            return {
                                modelCollectionId: id,
                                iouThreshold: !isNullOrUndefined(modelDetails?.iouThresholdValue) ? modelDetails?.iouThresholdValue : IOU_THRESHOLD_DEFAULT_VALUE_FOR_SEGMENTATION_ANALYTICS
                            }
                        })

                        const apiResponse = await DataService.getIntegrity({
                            modelCollectionIds: JSON.stringify(modelCollectionIdsToCompare),
                            modelId: modelDetails?._id || "",
                            // @ts-ignore
                            modelCollectionsAdditionalData: JSON.stringify(modelCollectionAdditionalData),
                            testCollectionId: testCollectionId
                        })

                        apiResponse.data?.forEach((item: IIntegrity) => {
                            const bands = item.bands
                            let goodDataPointes = 0
                            let totalDataPoints = item.totalDataSetsCount
                            let accuracy = 0

                            bands?.forEach((band: IntegrityBand) => {
                                goodDataPointes += band?.totalGoodDataPointsCount || 0
                            })

                            if(totalDataPoints){
                                 accuracy = (goodDataPointes / totalDataPoints) * 100
                            }

                            //@ts-ignore
                            let originalObj = apiResponse.data?.find(obj => obj.modelCollectionId === item._id)
                            if(originalObj){
                                originalObj.testAccuracy.value = accuracy
                            }

                            //@ts-ignore cache accuracy
                            saveCollectionAccuracyInLocalStorage(accuracy, testCollectionId, item._id, !isNullOrUndefined(modelDetails.iouThresholdValue) ? modelDetails.iouThresholdValue : IOU_THRESHOLD_DEFAULT_VALUE_FOR_SEGMENTATION_ANALYTICS, !isNullOrUndefined(modelDetails?.confidenceScoreThresholdForIntegrity) ? modelDetails?.confidenceScoreForIntegrity : CONFIDENCE_SCORE_THRESHOLD_DEFAULT_VALUE)
                        })

                    }
                }

                setModelAnalyticsTestStatisticalDataForTopPerformingModel(apiResponse.data)
            } catch (error) {
                alert("Error while fetching Top Performing Model")
            } finally {
                isFetchingTopPerformingData.current = false
            }
        }
        if (modelId&&modelCollectionsAdditionalDataQueryParamValueForTopPerforming.length!==0) {
            executeFunction();
        }
        else{
            setModelAnalyticsTestStatisticalDataForTopPerformingModel([])
        }
    }, [modelCollectionIdsToCompare, modelCollectionsAdditionalDataQueryParamValueForTopPerforming, modelId, testCollectionId])

    console.log("selected Model Versions and Selected model ids ::: ", props.selectedModelVersions, props.selectedModelIds);

    // const fetchModelAnalyticsTopPerformingModel = useCallback(
    //     (): Promise<void> => {
    //         return new Promise<void>(async(resolve, reject) => {
    //             const query = new URLSearchParams(window.location.search);
    //             const modelId = query.get("model");
    //             const modelVersionsToCompare = query.get("modelVersionsToCompare")?.split(",");

    //             try {
    //                 setIsFetchingModelAnalyticsTopPerformingModel(true);
    //                 // const apiResponseForTopPerformingModel = await DataService.getModelAnanlyticsTopPerformingModel({
    //                 //     modelId : modelId,
    //                 //     modelVersions : modelVersionsToCompare
    //                 // })
    //                 // setModelAnalyticsTopPerformingModel(apiResponseForTopPerformingModel.data)
    //                 setModelAnalyticsTopPerformingModel([{
    //                     modelVersion : "4.4",
    //                     accuracy : {
    //                         value : 76,
    //                         type : "greaterThanLive"
    //                     }
    //                 },
    //                 {
    //                     modelVersion : "5.4",
    //                     accuracy : {
    //                         value : 76,
    //                         type : "greaterThanLive"
    //                     }
    //                 },
    //                 {
    //                     modelVersion : "6.4",
    //                     accuracy : {
    //                         value : 86,
    //                         type : "greaterThanLive"
    //                     }
    //                 },
    //                 {
    //                     modelVersion : "7.4",
    //                     accuracy : {
    //                         value : 76,
    //                         type : "greaterThanLive"
    //                     }
    //                 },
    //                 {
    //                     modelVersion : "8.4",
    //                     accuracy : {
    //                         value : 76,
    //                         type : "greaterThanLive"
    //                     }
    //                 }])


    //                 setIsFetchingModelAnalyticsTopPerformingModel(false);
    //             } catch (error) {(console.log(error))}
    //         });
    // }, [window.location.search]);

    // const fetchModelAnalyticsModelComparison = useCallback(
    //     (): Promise<void> => {
    //         return new Promise<void>(async(resolve, reject) => {
    //             const query = new URLSearchParams(window.location.search);
    //             const modelId = query.get("model");
    //             const modelVersionsToCompare = query.get("modelVersionsToCompare")?.split(",");

    //             try {
    //                 setIsFetchingModelAnalyticsModelComparison(true);
    //                 // const apiResponseForModelComparison = await DataService.getModelAnanlyticsModelComparison({
    //                 //     modelId : modelId,
    //                 //     modelVersions : modelVersionsToCompare
    //                 // })
    //                 // setModelAnalyticsModelComparison(apiResponseForModelComparison.data)
    //                 setModelAnalyticsModelComparison([{
    //                     modelVersion : "4.4",
    //                     status: "Live",
    //                     totalDataSets: 2500,
    //                     trainingDateAndTime: "2022-06-20T12:28:52.414Z"
    //                 },
    //                 {
    //                     modelVersion : "5.4",
    //                     status: "Queued",
    //                     totalDataSets: 2500,
    //                     trainingDateAndTime: "2022-06-20T12:28:52.414Z"
                        
    //                 },
    //                 {
    //                     modelVersion : "6.4",
    //                     status: "Trained",
    //                     totalDataSets: 2500,
    //                     trainingDateAndTime: "2022-06-20T12:28:52.414Z"
                        
    //                 },
    //                 {
    //                     modelVersion : "7.4",
    //                     status: "Trained",
    //                     totalDataSets: 2500,
    //                     trainingDateAndTime: "2022-06-20T12:28:52.414Z"
                        
    //                 },
    //                 {
    //                     modelVersion : "8.4",
    //                     status: "Re-Trained",
    //                     totalDataSets: 2500,
    //                     trainingDateAndTime: "2022-06-20T12:28:52.414Z"   
    //                 }])


    //                 setIsFetchingModelAnalyticsModelComparison(false);
    //             } catch (error) {(console.log(error))}
    //         });
    // }, [window.location.search]);

    // const fetchModelAnalyticsIntegrity = useCallback(
    //     (): Promise<void> => {
    //         return new Promise<void>(async(resolve, reject) => {
    //             const query = new URLSearchParams(window.location.search);
    //             const modelId = query.get("model");
    //             const modelVersionsToCompare = query.get("modelVersionsToCompare")?.split(",");

    //             try {
    //                 setIsFetchingModelAnalyticsIntegrity(true);
    //                 // const apiResponseForIntegrity = await DataService.getModelAnanlyticsIntegrity({
    //                 //     modelId : modelId,
    //                 //     modelVersions : modelVersionsToCompare
    //                 // })
    //                 // setModelAnalyticsIntegrity(apiResponseForIntegrity.data)

    //                 setModelAnalyticsIntegrity([{
    //                     modelVersion : "27.64.0",
    //                     integrityFrquency : "7777",
    //                     totalAccuracy : "89.0"
    //                 },
    //                 {
    //                     modelVersion : "27.65.0",
    //                     integrityFrquency : "7777",
    //                     totalAccuracy : "89.0"
    //                 },
    //                 {
    //                     modelVersion : "27.65.1",
    //                     integrityFrquency : "7777",
    //                     totalAccuracy : "89.0"
    //                 },
    //                 {
    //                     modelVersion : "27.65.3",
    //                     integrityFrquency : "7777",
    //                     totalAccuracy : "89.0"
    //                 },
    //                 {
    //                     modelVersion : "27.65.4",
    //                     integrityFrquency : "7777",
    //                     totalAccuracy : "89.0"
    //                 }])

    //                 setIsFetchingModelAnalyticsIntegrity(false);
    //             } catch (error) {(console.log(error))}
    //         });
    // }, [window.location.search]);

    const fetchModelAnalyticsTestDataSet = useCallback(
        (): Promise<void> => {
            return new Promise<void>(async(resolve, reject) => {
                const query = new URLSearchParams(window.location.search);
                const modelId = query.get("model") || "";
                const modelCollectionIds = query.get("modelCollectionIdsToCompare")?.split(",") || [""];
                const testCollectionId = query.get("testCollectionId")  || "";

                try {
                    isFetchingModelAnalyticsTestDataSet.current = true
                    let apiResponseForTestDataSet;
                    if(testCollectionId === "") {
                        const testDataSetCollectionId = query.get("testDataSetCollectionId");
                    apiResponseForTestDataSet = await DataService.getModelAnanlyticsTestDataSet({
                        modelId : modelId,
                        modelCollectionId : modelCollectionIds[0],
                        ...(testDataSetCollectionId && { testDataSetCollectionId })
                    })}
                    else{
                    apiResponseForTestDataSet = await DataService.getModelAnanlyticsTestDataSetForTestCollectionScenario({
                        modelId : modelId,
                        testCollectionId : testCollectionId
                    })}
                    
                    setModelAnalyticsTestDataSet(apiResponseForTestDataSet.data)

                } catch (error) {(console.log(error))
                }
                finally{
                    isFetchingModelAnalyticsTestDataSet.current = false
                }
            });
    }, [window.location.search]);

    const fetchModelAnalyticsAccuracy = useCallback(
        (): Promise<void> => {

            return new Promise<void>(async(resolve, reject) => {
                const query = new URLSearchParams(window.location.search);
                const modelId = query.get("model") || "";
                const modelCollectionIdsToCompare = JSON.stringify(query.get("modelCollectionIdsToCompare")?.split(","));
                const modelCollectionIdsToCompareArray = query.get("modelCollectionIdsToCompare")?.split(",") || [];
                const testCollectionId = query.get("testCollectionId")  || "";
                // const modelCollectionIdsToCompare = JSON.stringify(["27.61.0"])
                console.log(modelCollectionIdsToCompare);

                try {
                    isFetchingModelAnalyticsAccuracy.current = true
                    const testDataSetCollectionId = query.get("testDataSetCollectionId");

                    const apiResponseForAccuracy = await DataService.getModelAnanlyticsAccuracy({
                        modelId : modelId,
                        modelCollectionIds : modelCollectionIdsToCompare,
                        accuracyJsonData:true,
                        getStatus:true,
                        getCreatedTime:true,
                        getTotalTrainedDataSetsCount:true,
                        testCollectionId: testCollectionId,
                        ...(testDataSetCollectionId ? {testDataSetCollectionId} : {})
                    })

                    //check in localStorage if accuracy values are available
                    let accuraciesFoundInLocalStorage = true
                    const collectionAccuracies = []
                    for(const modelCollectionId of modelCollectionIdsToCompareArray){
                        //@ts-ignore
                        const accuracy = getCollectionAccuracyFromLocalStorage(testCollectionId, modelCollectionId, !isNullOrUndefined(modelDetails?.iouThresholdValue) ? modelDetails?.iouThresholdValue : IOU_THRESHOLD_DEFAULT_VALUE_FOR_SEGMENTATION_ANALYTICS, !isNullOrUndefined(modelDetails?.confidenceScoreThresholdForIntegrity) ? modelDetails?.confidenceScoreThresholdForIntegrity : CONFIDENCE_SCORE_THRESHOLD_DEFAULT_VALUE)
                        if(!accuracy){
                            accuraciesFoundInLocalStorage = false
                            break
                        }
                        else collectionAccuracies.push({
                            testCollectionId,
                            modelCollectionId,
                            accuracy
                        })
                    }

                    // overwrite the accuracy values according to the formaula good/total data points
                    if(accuraciesFoundInLocalStorage){
                        for(const accuracyObj of collectionAccuracies){
                            let originalObj = apiResponseForAccuracy.data?.find(obj => obj.modelCollectionId === accuracyObj.modelCollectionId)
                            if(originalObj){
                                //@ts-ignore
                                originalObj.accuracy.value = accuracyObj.accuracy
                            }
                        }
                    }
                    else {
                        if (modelDetails?.isSegmentationModelAnalytics) {

                            const modelCollectionAdditionalData = modelCollectionIdsToCompareArray.map(id => {
                                return {
                                    modelCollectionId: id,
                                    iouThreshold: !isNullOrUndefined(modelDetails?.iouThresholdValue) ? modelDetails?.iouThresholdValue : IOU_THRESHOLD_DEFAULT_VALUE_FOR_SEGMENTATION_ANALYTICS
                                }
                            })

                            const apiResponse = await DataService.getIntegrity({
                                modelCollectionIds: modelCollectionIdsToCompare,
                                modelId: modelDetails?._id || "",
                                // @ts-ignore
                                modelCollectionsAdditionalData: JSON.stringify(modelCollectionAdditionalData),
                                testCollectionId: testCollectionId
                            })

                            apiResponse.data?.forEach((item: IIntegrity) => {
                                const bands = item.bands
                                let goodDataPointes = 0
                                let totalDataPoints = item.totalDataSetsCount
                                let accuracy = 0

                                bands?.forEach((band: IntegrityBand) => {
                                    goodDataPointes += band?.totalGoodDataPointsCount || 0
                                })

                                if(totalDataPoints){
                                     accuracy = (goodDataPointes / totalDataPoints) * 100
                                }

                                let originalObj = apiResponseForAccuracy.data?.find(obj => obj.modelCollectionId === item._id)
                                if(originalObj){
                                    //@ts-ignore
                                    originalObj.accuracy.value = accuracy
                                }

                                //@ts-ignore cache accuracy
                                saveCollectionAccuracyInLocalStorage(accuracy, testCollectionId, item._id, !isNullOrUndefined(modelDetails.iouThresholdValue) ? modelDetails.iouThresholdValue : IOU_THRESHOLD_DEFAULT_VALUE_FOR_SEGMENTATION_ANALYTICS, !isNullOrUndefined(modelDetails?.confidenceScoreThresholdForIntegrity) ? modelDetails?.confidenceScoreForIntegrity : CONFIDENCE_SCORE_THRESHOLD_DEFAULT_VALUE)
                            })

                        }
                    }

                    
                    setModelAnalyticsAccuracy(apiResponseForAccuracy.data)
                    console.log("api response for accuracy : ", apiResponseForAccuracy.data, apiResponseForAccuracy.data)
                    console.log(modelAnalyticsAccuracy)
                    console.log("api response for accuracy : ", apiResponseForAccuracy.data, apiResponseForAccuracy.data)
                    
                    console.log(modelAnalyticsAccuracy)
                    resolve()
                } catch (error) {(console.log(error))
                    reject()
                }
                finally{
                    isFetchingModelAnalyticsAccuracy.current = false
                }
            });
    }, [window.location.search, modelDetails]);

    const FetchModelAnalyticsTestStatisticalDataForTopPerformingModel = async (additionalData: IModelCollectionsAdditionalData[]) => {
        const query = new URLSearchParams(window.location.search);
        const modelId = query.get("model") || "";
        const modelCollectionIdsToCompare = JSON.stringify(query.get("modelCollectionIdsToCompare")?.split(","));
        const testCollectionId = query.get("testCollectionId")  || "";
        console.log(modelCollectionIdsToCompare);

        try {
            isFetchingModelAnalyticsTestStatisticalData.current = true;
            const apiResponseForTestStatisticalData = await DataService.getModelAnalyticsTestStatisticalData({
                modelId : modelId,
                modelCollectionIds : modelCollectionIdsToCompare,
                getTestAccuracy : true,
                getTestAccuracyComparisonWithLiveCollection : true,
                modelCollectionsAdditionalData: JSON.stringify(additionalData)                
            })

            const apiResponse = apiResponseForTestStatisticalData.data.sort((a, b) => (a?.testAccuracy?.value && b?.testAccuracy?.value && a.testAccuracy?.value >= b.testAccuracy?.value) ? -1 : 1)
            const finalApiResponse = apiResponse.slice(0,5)
            setModelAnalyticsTestStatisticalData(finalApiResponse)
            const modelVersionsToShow = finalApiResponse.map((data) => {
                return data.version
            })
            setModelCollectionVersionsToShow(modelVersionsToShow)
            console.log("api response for accuracy : ", apiResponseForTestStatisticalData.data, apiResponseForTestStatisticalData.data)
            console.log(modelAnalyticsTestStatisticalData)
        } catch (error) {(console.log(error))
        } finally {
            isFetchingModelAnalyticsTestStatisticalData.current = false
        }

    }

    const FetchModelAnalyticsTestStatisticalDataForIntegrityOfModel = async (additionalData: IModelCollectionsAdditionalData[]) => {
        const query = new URLSearchParams(window.location.search);
        const modelId = query.get("model") || "";
        const modelCollectionIdsToCompare = JSON.stringify(query.get("modelCollectionIdsToCompare")?.split(","));
        const testCollectionId = query.get("testCollectionId")  || "";
        console.log(modelCollectionIdsToCompare);

        try {
            isFetchingModelAnalyticsTestStatisticalData.current = true;
            const apiResponseForTestStatisticalData = await DataService.getModelAnalyticsTestStatisticalData({
                modelId : modelId,
                modelCollectionIds : modelCollectionIdsToCompare,
                getIntegrityFrequency: true,
                getIntegrityFrequencyAccuracyPercent: true,
                getIntegrityFrequencyAccuracyPercentComparisonWithLiveCollection: true,
                modelCollectionsAdditionalData: JSON.stringify(additionalData)                
            })

            const apiResponse = apiResponseForTestStatisticalData.data.sort((a, b) => (a?.testAccuracy?.value && b?.testAccuracy?.value && a.testAccuracy?.value >= b.testAccuracy?.value) ? -1 : 1)
            const finalApiResponse = apiResponse.slice(0,5)
            setModelAnalyticsTestStatisticalData(finalApiResponse)
            const modelVersionsToShow = finalApiResponse.map((data) => {
                return data.version
            })
            setModelCollectionVersionsToShow(modelVersionsToShow)
            console.log("api response for accuracy : ", apiResponseForTestStatisticalData.data, apiResponseForTestStatisticalData.data)
            console.log(modelAnalyticsTestStatisticalData)

            isFetchingModelAnalyticsTestStatisticalData.current = false;
        } catch (error) {(console.log(error))
            isFetchingModelAnalyticsTestStatisticalData.current = false}

    }

    const isIndividualClassAccuracyFilterApplied = (()=>{
        if (modelCollectionsAdditionalDataQueryParamValueForIndividualClassAccuracy?.length>0) {
            return true
        }
        return false;
    })();

    const isIntergrityFilterApplied = (()=>{
        if (modelCollectionsAdditionalDataQueryParamValueForIntegrity?.length>0) {
            return true
        }
        return false;
    })();

    const isTopPerformingFilterApplied = (()=>{
        if (modelCollectionsAdditionalDataQueryParamValueForIntegrity?.length>0) {
            return true
        }
        return false;
    })();



    const fetchModelAnalyticsTestStatisticalData = useCallback(
        (): Promise<void> => {
            return new Promise<void>(async(resolve, reject) => {
                const query = new URLSearchParams(window.location.search);
                const modelId = query.get("model") || "";
                // const modelCollectionIdsToCompare = JSON.stringify(query.get("modelCollectionIdsToCompare")?.split(","));
                const testCollectionId = query.get("testCollectionId")  || "";
                const testDataSetCollectionId = query.get("testDataSetCollectionId") || '';
                // const modelCollectionIdsToCompare = JSON.stringify(["27.61.0"])
                console.log(modelCollectionIdsToCompare);

                try {
                    isFetchingModelAnalyticsTestStatisticalData.current = true;
                    const apiResponseForTestStatisticalData = await DataService.getModelAnalyticsTestStatisticalData({
                        modelId : modelId,
                        modelCollectionIds : JSON.stringify(modelCollectionIdsToCompare),
                        getIntegrityFrequency : true,
                        getIntegrityFrequencyAccuracyPercent : true,
                        getIntegrityFrequencyAccuracyPercentComparisonWithLiveCollection : true,
                        getTestAccuracy : true,
                        getTestAccuracyComparisonWithLiveCollection : true,
                        testCollectionId : testCollectionId,
                        ...(testDataSetCollectionId && { dataSetCollectionId: testDataSetCollectionId }),
                    })

                    const apiResponse = apiResponseForTestStatisticalData.data.sort((a, b) => (a?.testAccuracy?.value && b?.testAccuracy?.value && a.testAccuracy?.value >= b.testAccuracy?.value) ? -1 : 1)
                    const finalApiResponse = apiResponse.slice(0,5)
                    
                    //check in localStorage if accuracy values are available
                    let accuraciesFoundInLocalStorage = true
                    const collectionAccuracies = []
                    for(const modelCollectionId of modelCollectionIdsToCompare){
                        //@ts-ignore
                        const accuracy = getCollectionAccuracyFromLocalStorage(testCollectionId, modelCollectionId, !isNullOrUndefined(modelDetails?.iouThresholdValue) ? modelDetails?.iouThresholdValue : IOU_THRESHOLD_DEFAULT_VALUE_FOR_SEGMENTATION_ANALYTICS, !isNullOrUndefined(modelDetails?.confidenceScoreThresholdForIntegrity) ? modelDetails?.confidenceScoreThresholdForIntegrity : CONFIDENCE_SCORE_THRESHOLD_DEFAULT_VALUE)
                        if(!accuracy){
                            accuraciesFoundInLocalStorage = false
                            break
                        }
                        else collectionAccuracies.push({
                            testCollectionId,
                            modelCollectionId,
                            accuracy
                        })
                    }

                    // overwrite the accuracy values according to the formaula good/total data points
                    if(accuraciesFoundInLocalStorage){
                        for(const accuracyObj of collectionAccuracies){
                            let originalObj = finalApiResponse.find(obj => obj.modelCollectionId === accuracyObj.modelCollectionId)
                            if(originalObj){
                                originalObj.testAccuracy.value = accuracyObj.accuracy
                            }
                        }
                    }
                    else {
                        if (modelDetails?.isSegmentationModelAnalytics) {

                            const modelCollectionAdditionalData = modelCollectionIdsToCompare.map(id => {
                                return {
                                    modelCollectionId: id,
                                    iouThreshold: !isNullOrUndefined(modelDetails?.iouThresholdValue) ? modelDetails?.iouThresholdValue : IOU_THRESHOLD_DEFAULT_VALUE_FOR_SEGMENTATION_ANALYTICS
                                }
                            })

                            const apiResponse = await DataService.getIntegrity({
                                modelCollectionIds: JSON.stringify(modelCollectionIdsToCompare),
                                modelId: modelDetails?._id || "",
                                // @ts-ignore
                                modelCollectionsAdditionalData: JSON.stringify(modelCollectionAdditionalData),
                                testCollectionId: testCollectionId
                            })

                            apiResponse.data?.forEach((item: IIntegrity) => {
                                const bands = item.bands
                                let goodDataPointes = 0
                                let totalDataPoints = item.totalDataSetsCount
                                let accuracy = 0

                                bands?.forEach((band: IntegrityBand) => {
                                    goodDataPointes += band?.totalGoodDataPointsCount || 0
                                })

                                if(totalDataPoints){
                                     accuracy = (goodDataPointes / totalDataPoints) * 100
                                }

                                let originalObj = finalApiResponse.find(obj => obj.modelCollectionId === item._id)
                                if(originalObj){
                                    originalObj.testAccuracy.value = accuracy
                                }

                                //@ts-ignore cache accuracy
                                saveCollectionAccuracyInLocalStorage(accuracy, testCollectionId, item._id, !isNullOrUndefined(modelDetails.iouThresholdValue) ? modelDetails.iouThresholdValue : IOU_THRESHOLD_DEFAULT_VALUE_FOR_SEGMENTATION_ANALYTICS, !isNullOrUndefined(modelDetails?.confidenceScoreThresholdForIntegrity) ? modelDetails?.confidenceScoreForIntegrity : CONFIDENCE_SCORE_THRESHOLD_DEFAULT_VALUE)
                            })

                        }
                    }

                    // overwrite the accuracy values based on formulae good/total data count
                    if (modelDetails) {
                        await Promise.all(
                            finalApiResponse.map(async (obj) => {
                                //@ts-ignore
                                const accuracy = getCollectionAccuracyFromLocalStorage(testCollectionId, obj.modelCollectionId, !isNullOrUndefined(modelDetails?.iouThresholdValue) ? modelDetails.iouThresholdValue : IOU_THRESHOLD_DEFAULT_VALUE_FOR_SEGMENTATION_ANALYTICS, !isNullOrUndefined(modelDetails?.confidenceScoreThresholdForIntegrity) ? modelDetails?.confidenceScoreThresholdForIntegrity : CONFIDENCE_SCORE_THRESHOLD_DEFAULT_VALUE)
                                if (accuracy) {
                                    obj.testAccuracy.value = accuracy
                                }
                                else {   //call API 
                                    if (accuracy) {
                                        localStorage.removeItem(keyToSaveCollectionAccuracyInLocalStorage(testCollectionId, obj.modelCollectionId))
                                    }

                                    if (modelDetails?.isSegmentationModelAnalytics) {
                                        const apiResponse = await DataService.getIntegrity({
                                            modelCollectionIds: `["${obj.modelCollectionId}"]`,
                                            modelId: modelDetails?._id || "",
                                            // @ts-ignore
                                            modelCollectionsAdditionalData: JSON.stringify([{
                                                modelCollectionId: obj.modelCollectionId,
                                                iouThreshold: !isNullOrUndefined(modelDetails?.iouThresholdValue) ? modelDetails?.iouThresholdValue : IOU_THRESHOLD_DEFAULT_VALUE_FOR_SEGMENTATION_ANALYTICS
                                            }]),
                                            testCollectionId: testCollectionId
                                        })

                                        if (apiResponse?.data[0]) {
                                            let totalGoodDataPoints = 0
                                            if (apiResponse.data[0].bands?.length) {
                                                apiResponse.data[0].bands?.map((obj: any) => {
                                                    totalGoodDataPoints += obj.totalGoodDataPointsCount || 0
                                                })
                                            }

                                            if (apiResponse.data[0].totalDataSetsCount) {
                                                const accuracyValue = parseFloat(((totalGoodDataPoints / apiResponse.data[0].totalDataSetsCount) * 100).toFixed(2))

                                                obj.testAccuracy.value = accuracyValue
                                                // @ts-ignore
                                                saveCollectionAccuracyInLocalStorage(accuracyValue, testCollectionId, obj.modelCollectionId, !isNullOrUndefined(modelDetails?.iouThresholdValue) ? modelDetails?.iouThresholdValue : IOU_THRESHOLD_DEFAULT_VALUE_FOR_SEGMENTATION_ANALYTICS, !isNullOrUndefined(modelDetails?.confidenceScoreThresholdForIntegrity) ? modelDetails?.confidenceScoreThresholdForIntegrity : CONFIDENCE_SCORE_THRESHOLD_DEFAULT_VALUE)
                                            }
                                        }
                                    }
                                }
                            })
                        )
                    }
                    
                    setModelAnalyticsTestStatisticalData(finalApiResponse)
                    
                    const modelVersionsToShow = finalApiResponse.map((data) => {
                        return data.version
                    })
                    setModelCollectionVersionsToShow(modelVersionsToShow)
                    console.log("api response for accuracy : ", apiResponseForTestStatisticalData.data, apiResponseForTestStatisticalData.data)
                    console.log(modelAnalyticsTestStatisticalData)

                    isFetchingModelAnalyticsTestStatisticalData.current = false;
                    resolve()
                } catch (error) {(console.log(error))
                    isFetchingModelAnalyticsTestStatisticalData.current = false;}
            });
    }, [window.location.search, modelDetails, testCollectionId]);

    useEffect(() => {
        setSideDrawer({ type: 'hideSideDrawer', component: Analytics, isOpen: false })
    },[])
    
    useEffect(() => {
        const urlSearchParams = new URLSearchParams(window.location.search);
        // const modelId = urlSearchParams.get("model");
        // const version = urlSearchParams.getAll("version");
        const testCollectionId = urlSearchParams.get("testCollectionId") || "";
        console.log("test collection is ::: ",testCollectionId);
        if(testCollectionId !== ""){
            setTestCollectionPresent(true);
        }else{
            setTestCollectionPresent(false);
        }
        setSideDrawer({ type: 'hideSideDrawer', component: Analytics, isOpen: false })

        // fetchModelAnalyticsTopPerformingModel();

        // fetchModelAnalyticsModelComparison();

        // fetchModelAnalyticsIntegrity();

        fetchModelAnalyticsTestDataSet();

        fetchModelAnalyticsAccuracy();

        fetchModelAnalyticsTestStatisticalData();
        // (async () => {
        //   try {
        //     const apiResponse = await DataService.getIntegrity({
        //       model: modelId || "",
        //       version
        //     });
        //     if (apiResponse.data && apiResponse.data.models?.length) {
        //       setIntegrity(apiResponse.data.models);
        //     }
        //   } catch (error) {}
        // })();
      }, [modelDetails]);
  return (
    <div style={{height: "calc(100% - 103px)"}}>
        <Paper className={classes.collections}>
            <div className={classes.collectionHeader}>COLLECTIONS</div>
        </Paper>
        <Grid container spacing={2} className={classes.allTables}>
            {
                // !modelDetails?.isSegmentationModelAnalytics &&
                <Grid item xs={12} sm={4} lg={2} className={classes.row1} >
                                {/* style={{maxWidth: "21.7%", flexBasis:"21.7%", height: "100%"}}> */}
                    <Paper  style={{height: "100%"}}>
                        <TestDataSet isSegmentationModelAnalytics={modelDetails?.isSegmentationModelAnalytics||false} modelAnalyticsTestDataSet={modelAnalyticsTestDataSet} isFetchingModelAnalyticsTestDataSet={isFetchingModelAnalyticsTestDataSet.current} modelAnalyticsTopPerformingModel={modelAnalyticsTestStatisticalData} isFetchingModelAnalyticsTestStatisticalData={isFetchingModelAnalyticsTestStatisticalData.current}
                            //@ts-ignore
                            iouThreshold={!isNullOrUndefined(modelDetails?.iouThresholdValue) ? modelDetails?.iouThresholdValue : IOU_THRESHOLD_DEFAULT_VALUE_FOR_SEGMENTATION_ANALYTICS}
                            confidenceScoreThreshold={!isNullOrUndefined(modelDetails?.confidenceScoreThresholdForIntegrity) ? modelDetails?.confidenceScoreThresholdForIntegrity : CONFIDENCE_SCORE_THRESHOLD_DEFAULT_VALUE}
                        />
                    </Paper>
                </Grid>
            }
            <Grid item xs={12} sm={8} lg={5} className={classes.row2}>
                 {/* style={{maxWidth: "36.9%", flexBasis:"36.9%", height: "100%"}}> */}
                {/* <Grid container direction="column" spacing={2} > */}
                <Grid container spacing={2} className={classes.topPerformingModelSubColumn} >
                    <Grid item xs={12} sm={12} lg={12}>
                        <Paper  style={{height: "100%"}}>
                        <TopPerformingModel 
                         modelDetails={modelDetails}
                         modelAnalyticsTopPerformingModel={ modelAnalyticsTestStatisticalDataForTopPerformingModel.length !==0 ? modelAnalyticsTestStatisticalDataForTopPerformingModel : modelAnalyticsTestStatisticalData}

                        //  modelAnalyticsAccuracy={modelAnalyticsAccuracy}
                        //  // isFetchingModelAnalyticsAccuracy={isFetchingModelAnalyticsAccuracy}
                        //  modelAnalyticsTestDataSet={modelAnalyticsTestDataSet}
                        //  // isFetchingModelAnalyticsTestDataSet={isFetchingModelAnalyticsTestDataSet} 
                        //  modelVersionsToShow={modelCollectionVersionsToShow} 
                        //  topPerformingData={topPerformingData}
                         isFetchingTopPerformingData={isFetchingTopPerformingData.current}
                         isFetchingModelAnalyticsTestStatisticalData={isFetchingModelAnalyticsTestStatisticalData.current}
                        //  isTopPerformingFilterApplied={isTopPerformingFilterApplied}
                        //  topPerformingFilter={topPerformingFilter}
                        //  setTopPerformingFilter={setTopPerformingFilter}
                        //  modelCollectionsAdditionalDataQueryParamValueForTopPerforming={modelCollectionsAdditionalDataQueryParamValueForTopPerforming}


                        // FetchModelAnalyticsTestStatisticalDataForTopPerformingModel={FetchModelAnalyticsTestStatisticalDataForTopPerformingModel} 
                        // modelAnalyticsTopPerformingModel={modelAnalyticsTestStatisticalData} 
                        // isFetchingModelAnalyticsTestStatisticalData={isFetchingModelAnalyticsTestStatisticalData}
                        />
                        </Paper>
                    </Grid>
                    <Grid item xs={12} sm={12} lg={12} className={classes.modelComparisonGrid}>
                        <Paper  style={{height: "100%"}}>
                        <ModelComparision modelDetails={modelDetails} modelAnalyticsAccuracy={modelAnalyticsAccuracy} isFetchingModelAnalyticsAccuracy={isFetchingModelAnalyticsAccuracy.current} modelVersionsToShow = {modelCollectionVersionsToShow} modelAnalyticsTestStatisticalData={modelAnalyticsTestStatisticalData} isFetchingModelAnalyticsTestStatisticalData={isFetchingModelAnalyticsTestStatisticalData.current}
                        />
                        </Paper>
                    </Grid>
                </Grid>
            </Grid>
            <Grid item xs={12} sm={12} lg={5} className={classes.row3}>
                {/* <Grid container direction="column" spacing={2}> */}
                <Grid container spacing={2} className={classes.integritySubColumn}>
                    <Grid item xs={12} sm={12} lg={12} className={classes.IntegrityGrid}>
                        <Paper  style={{height: "100%"}}>
                              <Integrity
                                  modelDetails={modelDetails}
                                  modelAnalyticsIntegrity={ modelAnalyticsTestStatisticalDataForIntegrity.length !==0 ? modelAnalyticsTestStatisticalDataForIntegrity : modelAnalyticsTestStatisticalData}

                                //   modelAnalyticsAccuracy={modelAnalyticsAccuracy}
                                //   // isFetchingModelAnalyticsAccuracy={isFetchingModelAnalyticsAccuracy}
                                //   modelAnalyticsTestDataSet={modelAnalyticsTestDataSet}
                                //   // isFetchingModelAnalyticsTestDataSet={isFetchingModelAnalyticsTestDataSet} 
                                //   modelVersionsToShow={modelCollectionVersionsToShow} modelAnalyticsTestStatisticalData={modelAnalyticsTestStatisticalData}
                                //   integrityData={integrityData}
                                  isFetchingIntegrityData={isFetchingIntegrityData}
                                //   isIntergrityFilterApplied={isIntergrityFilterApplied}
                                //   integrityFilter={integrityFilter}
                                //   setIntegrityFilter={setIntegrityFilter}
                                //   modelCollectionsAdditionalDataQueryParamValueForIntegrity={modelCollectionsAdditionalDataQueryParamValueForIntegrity}
                                  
                              />
                        </Paper>
                    </Grid>
                    <Grid item xs={12} sm={12} lg={12} className={classes.individualClassAccuracyGrid}>
                        <Paper  style={{height: "100%"}}>
                            {
                                // modelAnalyticsAccuracy &&
                                <IndividualClassAccuracy modelAnalyticsAccuracy={modelAnalyticsAccuracy}
                                  modelDetails={modelDetails}
                                  // isFetchingModelAnalyticsAccuracy={isFetchingModelAnalyticsAccuracy}
                                  modelAnalyticsTestDataSet={modelAnalyticsTestDataSet}
                                  // isFetchingModelAnalyticsTestDataSet={isFetchingModelAnalyticsTestDataSet} 
                                  modelVersionsToShow={modelCollectionVersionsToShow} modelAnalyticsTestStatisticalData={modelAnalyticsTestStatisticalData}
                                  // isFetchingModelAnalyticsTestStatisticalData={isFetchingModelAnalyticsTestStatisticalData}
                                  individualClassAccuracyData={individualClassAccuracyData}  // need to check
                                  isFetchingIndividualClassAccuracyData={isFetchingIndividualClassAccuracyData} 
                                  isIndividualClassAccuracyFilterApplied={isIndividualClassAccuracyFilterApplied} 
                                  individualClassAccuracyFilter={individualClassAccuracyFilter} 
                                  setIndividualClassAccuracyFilter={setIndividualClassAccuracyFilter}    
                                  modelCollectionsAdditionalDataQueryParamValueForIndividualClassAccuracy={modelCollectionsAdditionalDataQueryParamValueForIndividualClassAccuracy} 
                                  testDataSetCollectionId={urlSearchParams.get("testDataSetCollectionId") || ''}
                                />
                            }
                        </Paper>
                    </Grid>
                </Grid>
            </Grid>
        </Grid>
    </div>
    
  )
}

const mapProps = (state: IReduxState) => {
    return {
};};

const connector = connect(mapProps, {
    setSideDrawer,
  });
  
  export type TPropsFromRedux = ConnectedProps<typeof connector>;
  
  export default connector(ModelAnalyticsDashboard);
// export default ModelAnalyticsDashboard

import React from 'react';
import {Tooltip} from '@material-ui/core';



function FileNameClipper(props : {fileName: string|undefined; currentPage: string}) {
  if(props.fileName !== undefined){
    let resouceString = props.fileName;
    if (resouceString.length >= 15 && props.currentPage === "RejectPopup") {
          resouceString =  resouceString.substring(0, 3) + "..." + resouceString.substring(resouceString.length - 9, resouceString.length);
      }
    else if (resouceString.length > 20 && props.currentPage === "viewDetails") {
        resouceString =  resouceString.substring(0, 3) + "..." + resouceString.substring(resouceString.length - 14, resouceString.length);
    }
    else if (resouceString.length > 25 && props.currentPage === "CoPilotHomePage") {
      resouceString =  resouceString.substring(0, 22) + "..." ;
      // + resouceString.substring(resouceString.length - 19, resouceString.length)
  }
  return (
    <div>
        <Tooltip title={props.fileName}>
        <div>{resouceString}</div>
        </Tooltip>
    </div>
  )
  }
  else{
    return(
      <div>{props.fileName}</div>
    )
  }
}

export default FileNameClipper
import TextFormatIcon from '@material-ui/icons/TextFormat';

type Props = {
  IconComponentProps?: any
}

export function ModelTypeTextAnnotationIcon(props: Props) {
  return (
    <TextFormatIcon {...props.IconComponentProps} />
  );
}
import { createContext } from "react";
import { CloneState, IAnnotatedImageDetails, IClonedPod, IMetaDataOfCurrentTestDroveTrainingPod, IPageInWorkModeStack, TestDriveStage } from "./chatContext.provider";
import { SelectedRlefResourceType } from "rlef-ui-library";
import { ITestDriveVM, TryOutRequest } from "../../projects/features/aiMarketPlace/services/types/testDrive";
import { IData, IResourceAnalyticsCollection } from "../../../../../common";
import { AnnotatedImageDownloadUrlPair } from "../services/llmStudioAssistants.service";


//@ts-ignore
const ChatContext = createContext<{
    workModePageStack: Array<IPageInWorkModeStack>,
    setWorkModePageStack: React.Dispatch<React.SetStateAction<Array<IPageInWorkModeStack>>>,
    pushPageInWorkModeStack: (page: IPageInWorkModeStack) => void,
    switchToPreviousPageInWorkModeStack: () => void,
    onCloseWindowSideEffects: () => void,
    selectedRlefResourcesForTestDrive: SelectedRlefResourceType[],
    setSelectedRlefResourcesForTestDrive: React.Dispatch<React.SetStateAction<SelectedRlefResourceType[]>>,
    testDriveStage: TestDriveStage[],
    updateTestDriveStage: (stageIndex: number, newPercentage: number) => void,
    testDriveVm: ITestDriveVM | null,
    setTestDriveVm: React.Dispatch<React.SetStateAction<ITestDriveVM | null>>,
    isTestDriveCompleted: boolean,
    setIsTestDriveCompleted: React.Dispatch<React.SetStateAction<boolean>>,
    testDriveProcessRequest: TryOutRequest | null,
    setTestDriveProcessRequest: React.Dispatch<React.SetStateAction<TryOutRequest | null>>,
    modelCloningStage: CloneState,
    setModelCloningStage : React.Dispatch<React.SetStateAction<CloneState>>,
    clonedPodDetails: IClonedPod, 
    setClonedPodDetails: React.Dispatch<React.SetStateAction<IClonedPod>>,
    testDroveTrainingPodIdsInCurrentSession: Array<string>,
    setTestDroveTrainingPodIdsInCurrentSession: React.Dispatch<React.SetStateAction<Array<string>>>,
    metadataOfCurrentTestDroveTrainingPod: IMetaDataOfCurrentTestDroveTrainingPod,
    setMetadataOfCurrentTestDroveTrainingPod: React.Dispatch<React.SetStateAction<IMetaDataOfCurrentTestDroveTrainingPod>>,
    shouldShowTestDriveResultScreen: boolean
    setShouldShowTestDriveResultScreen: React.Dispatch<React.SetStateAction<boolean>>,
    getResultsForTestDriveAnalysis: ()=>void,
    sendImagesForAnnotation: (resources : IData[] | IResourceAnalyticsCollection[], type: 'ground' | 'predict') => void,
    testDriveAnalysisMessage: string,
    setTestDriveAnalysisMessage: React.Dispatch<React.SetStateAction<string>>,
    analysisAssistantPayload: AnnotatedImageDownloadUrlPair[],
    setAnalysisAssistantPayload: React.Dispatch<React.SetStateAction<AnnotatedImageDownloadUrlPair[]>>,
}>();

export default ChatContext;
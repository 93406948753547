import { Background, BackgroundVariant } from "@reactflow/background";
import { Controls } from "@reactflow/controls";
import Accordion from "@material-ui/core/Accordion";
import {
  addEdge,
  applyEdgeChanges,
  applyNodeChanges,
  Connection,
  ConnectionMode,
  Edge,
  EdgeMouseHandler,
  MarkerType,
  Node,
  NodeChange,
  ReactFlow,
} from "@reactflow/core";
import { useCallback, useContext, useEffect, useState } from "react";
import ModelNode from "./modelNode";
import EditIcon from "@material-ui/icons/Edit";
import SaveIcon from "@material-ui/icons/Save";
import CancelIcon from "@material-ui/icons/Cancel";
import "reactflow/dist/style.css";
import {
  AccordionDetails,
  AccordionSummary,
  Button,
  CircularProgress,
  IconButton,
  makeStyles,
  Menu,
  MenuItem,
  Tooltip,
} from "@material-ui/core";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import EdgeContextMenu from "./edgeContextMenu/edgeContextMenu";
import { IModel } from "../../../../../../../../common";
import ModelGroup, { ModelGroupEdge, ModelGroupNode } from "../../../../../../../../store/types/modelGroup.type";
import { ModelService } from "../../../../../../../../services";
import { copyByValue } from "../../../../../../../../services/variableHelperService";
import ModelListContext from "../../modelistContext/modelist.context";
import css from "./modelGroupList.module.css"
import ExpandLessIcon from '@material-ui/icons/ExpandLess';
import MoreVertIcon from '@material-ui/icons/MoreVert';
import CreateModelGroupPopup from "../../../../../../components/createModelGroupPopup/createModelGroupPopup";
import DeleteModelGroupWarningPopup from "./deleteModelGroupWarningPopup/deleteModelGroupWarningPopup";
import NodeContextMenu from "./nodeContextMenu/nodeContextmenu";
import MoveModelToDifferentModelGroupPopup from "./moveModelToDifferentModelGroupPopup/moveModelToDifferentModelGroupPopup";
import { useDispatch } from "react-redux";
import { setSideDrawer, setTopActionButton } from "../../../../../../../../store/actions";
import ModelAnalytics from '../Analytics';
import AddModel from "../AddModel";
import "./modelGroupList.css";
import { SearchData, SearchDataContext } from "../../../../../../../../common/contexts/searchContext/SearchDataContext";
import useInfiniteScroll from "../../../../../../../../hooks/useInfiniteScroll";

export type ModelGroupNodeUI = Node & ModelGroupNode & {
  type: "modelNode";
  data?: any
};

type ModelGroupEdgeUI = Edge & {
  id: ModelGroupEdge["id"],
  // type: string;
  animated?: boolean
};

export type ModelGroupUI = {
  _id: string;
  name: string;
  models: IModel[];
  nodes: ModelGroupNodeUI[];
  colour: ModelGroup["colour"];
  editedNodes: ModelGroupNodeUI[];
  edges: ModelGroupEdgeUI[];
  editedEdges: ModelGroupEdgeUI[];
  mode: "view" | "edit";
  isDirty: boolean;
  isSaving?: boolean;
  isExpanded?: boolean;
};

const useStyles = makeStyles((theme) => ({
  modelGroupAccordian: {
    width: "99.5%",
    marginBottom: "25px",
    borderRadius: "4px"
  },
  modelGroupAccordianSummaryChild: {
    display: "flex",
    justifyContent: "space-between",
    width: "100%",
    cursor: "default"
  },
  reactFlowTopMostContainer: {
    height: "100%",
    width: "100%"
  },
  modelGroupReactFlowContainer: {
    width: "100%",
    height: "580px",
  },
  modelGroupOptions: {
    display: "flex",
    justifyContent: "flex-end",
    // width: "65px",
    columnGap: "20px",
    alignItems: "center"
  },
  modelGroup: {
    width: "90%",
    minHeight: "400px",
    height: "auto",
    display: "flex",
    flexDirection: "column",
    "& .modelGroupToolbar": {
      display: "flex",
      justifyContent: "space-between",
      width: "100%",
    },
    "& .modelGroupModels": {
      width: "100%",
      height: "400px",
    },
  },
  reactFlowViewMode: {
    overflow: "auto !important",
    cursor: "default"
  },
  modelGroupNameSection: {
    display: "flex",
    flexDirection: "column"
  },
  modelGroupName: {
    lineHeight: "30px",
    fontFamily: "NunitoSans-Regular",
    fontSize: "18px"
  },
  noOfModelsText: {
    color: "#838C95",
    fontSize: "13.5px",
    lineHeight: "22px",
    fontFamily: "NunitoSans-Regular"
  }
}));

function removeModel(modelIdToRemove: string, models: IModel[]): IModel[] {
  if (models?.length>0) {
    return models.filter(model=>model._id !== modelIdToRemove)
  }
  
  return [];
}

function getModelGroupIdOfNode(
  nodeId: string,
  modelGroups: ModelGroupUI[]
): string {
  for (const modelGroup of modelGroups) {
    for (const modelGroupNode of modelGroup[
      modelGroup.mode === "view" ? "nodes" : "editedNodes"
    ]) {
      if (modelGroupNode.id === nodeId) {
        return modelGroup._id;
      }
    }
  }

  return "";
}

function getModelGroupIdOfEdge(
  edgeId: string,
  modelGroups: ModelGroupUI[]
): string {
  for (const modelGroup of modelGroups) {
    for (const modelGroupEdge of modelGroup[
      modelGroup.mode === "view" ? "edges" : "editedEdges"
    ]) {
      if (modelGroupEdge.id === edgeId) {
        return modelGroup._id;
      }
    }
  }

  return "";
}

function getModelGroupById(
  modelGroupId: string,
  modelGroups: ModelGroupUI[]
): ModelGroupUI | null {
  for (const modelGroup of modelGroups) {
    if (modelGroup._id === modelGroupId) {
      return modelGroup;
    }
  }
  return null;
}

// const modelGroupsSavedInDB = [
//   {
//     _id: "1",
//     name: "modelGroup1",
//     nodes: [
//       {
//         id: "6347cfad9ae2c50fd19c9932",
//         position: {
//           x: 0,
//           y: 0,
//         },
//         data: {
//           model: {
//             labelsAvailable: [
//               "door",
//               "door_open",
//               "name",
//               "phone_number",
//               "city",
//             ],
//             aiAssistantLabellingEnabled: false,
//             apiToCallIfAiAssistantLabellingEnabled: "",
//             allowUserToSetAnyLabel: false,
//             _id: "6347cfad9ae2c50fd19c9932",
//             project: {
//               _id: "6239ad9e530ae57d1e22a1e3",
//               name: "Dooropen internal testing",
//             },
//             name: "text annotation model",
//             type: "textAnnotation",
//             architecturesAvailable: [],
//             annotationLabelsAvailable: [
//               {
//                 id: "44e5f5d5-c423-45ee-9fa8-cc0b3b585719",
//                 value: "first name",
//               },
//               {
//                 id: "daa3935d-d551-4777-84db-e6d404269235",
//                 value: "last name",
//               },
//               {
//                 id: "a6e34f3c-cd11-4fcd-976b-edb60978c039",
//                 value: "name",
//               },
//               {
//                 id: "56e40c20-387c-4255-accc-76d490722ae1",
//                 value: "phone_number",
//               },
//               {
//                 id: "0a95e566-59a6-42a2-bb81-91ee4af539df",
//                 value: "city",
//               },
//             ],
//             forecastingLabelsAvailable: [],
//             created_at: "2022-10-13T08:43:25.903Z",
//             labelsMapping: [],
//           },
//         },
//       },
//       {
//         id: "633e83078d1ab1194b1a7519",
//         position: {
//           x: 0,
//           y: 0,
//         },
//         data: {
//           model: {
//             labelsAvailable: [],
//             aiAssistantLabellingEnabled: false,
//             apiToCallIfAiAssistantLabellingEnabled: "",
//             allowUserToSetAnyLabel: false,
//             _id: "633e83078d1ab1194b1a7519",
//             project: {
//               _id: "6239ad9e530ae57d1e22a1e3",
//               name: "Dooropen internal testing",
//             },
//             name: "text model",
//             type: "text",
//             architecturesAvailable: [],
//             annotationLabelsAvailable: [],
//             forecastingLabelsAvailable: [],
//             created_at: "2022-10-06T07:25:59.276Z",
//             labelsMapping: [],
//           },
//         },
//       },
//     ],
//     edges: [],
//   },
//   {
//     _id: "2",
//     name: "modelGroup2",
//     nodes: [
//       {
//         id: "63f857e45d0dd3300e26c178",
//         position: {
//           x: 0,
//           y: 0,
//         },
//         data: {
//           model: {
//             labelsAvailable: [],
//             aiAssistantLabellingEnabled: false,
//             apiToCallIfAiAssistantLabellingEnabled: "",
//             allowUserToSetAnyLabel: false,
//             _id: "63f857e45d0dd3300e26c178",
//             name: "video test model",
//             type: "video",
//             project: {
//               _id: "6239ad9e530ae57d1e22a1e3",
//               name: "Dooropen internal testing",
//             },
//             architecturesAvailable: [],
//             annotationLabelsAvailable: [],
//             forecastingLabelsAvailable: [],
//             created_at: "2023-02-24T06:23:32.715Z",
//             labelsMapping: [],
//           },
//         },
//       },
//       {
//         id: "62429d9649fdc9014669ae98",
//         position: {
//           x: 0,
//           y: 0,
//         },
//         data: {
//           model: {
//             labelsAvailable: ["door", "Orange"],
//             aiAssistantLabellingEnabled: false,
//             apiToCallIfAiAssistantLabellingEnabled: "",
//             allowUserToSetAnyLabel: false,
//             _id: "62429d9649fdc9014669ae98",
//             architecturesAvailable: [
//               {
//                 description: "",
//                 isDeploymentFeatureEnabled: false,
//                 apiUrlToCallForDeployment: "",
//                 hyperParameter: "",
//                 label: "python",
//                 ipAddress: "pythoin.com",
//               },
//             ],
//             project: {
//               _id: "6239ad9e530ae57d1e22a1e3",
//               name: "Dooropen internal testing",
//             },
//             name: "test image model",
//             type: "image",
//             created_at: "2022-03-29T05:48:06.571Z",
//             is500WCompressionEnabled: true,
//             annotationLabelsAvailable: [],
//             forecastingLabelsAvailable: [],
//             labelsMapping: [],
//           },
//         },
//       },
//     ],
//     edges: [],
//   },
// ];

const nodeTypes = { modelNode: ModelNode };

type Props = {
  onModelClick: (data: IModel) => any;
  projectId: string;
};

export default function ModelGroupList(props: Props) {
  const modelListContext = useContext(ModelListContext);
  const dispatch = useDispatch();
  const {
    modelGroups,
    setModelGroups,
    fetchModelGroupsFromDB,
    modelGroupsRef,
    isFetchingModelGroupsFromDB,
    modelGroupPage,
    hasMore,
    resetModelListContextFunction,
    fetchModelGroupsFromDBViaPagination,
  } = modelListContext || {};

  const projectId = props.projectId;

  const [editModelGroupPopup, setEditModelGroupPopup] = useState({
    open: false,
    modelGroupId: "",
    modelGroupName: "",
    modelGroupColour: ""
  });
  const [observerRef] = useInfiniteScroll({
    callback: fetchModelGroupsFromDBViaPagination,
    isFetching:isFetchingModelGroupsFromDB,
    hasMore:hasMore
  });

  const [deleteModelGroupPopup, setDeleteModelGroupPopup] = useState({
    open: false,
    modelGroupToDelete: {
      id: "",
      name: "",
      hasModelsLinked: false
    }
  });

  const classes = useStyles();

  const [modelGroupMenu, setModelGroupMenu] = useState({
    isOpen: false,
    modelGroupId: "",
    anchorEl: null
  });

  const [moveModelToDifferentModelGroupPopup, setMoveModelToDifferentModelGroupPopup] = useState({
    isOpen: false,
    model: {
      name: "",
      _id: "",
      modelGroup: {
        name: ""
      }
    },
    isMovingModelToDifferentModelGroup: false,
  })

  const [edgeContextMenu, setEdgeContextMenu] = useState<{
    isOpen: boolean;
    mouseX: number;
    mouseY: number;
    edge: Edge | null;
  }>({
    isOpen: false,
    mouseX: 0,
    mouseY: 0,
    edge: null,
  });

  const {searchData,setSearchData} = useContext(SearchDataContext);
  const [searchResults,setSearchResults] = useState<ModelGroupUI[]>([]);
  const [expandModelGroupId,setExpandModelGroupId]=useState<string>('')
  const [isFetchingModelGroup,setIsFetchingModelGroup] = useState<boolean>(false);

  useEffect(()=>{
    
    setSearchData({
      autoCompleteData:[],
      searchQuery:'',
      autoCompleteValueChange:''
  })    
  },[])
  // const [nodeContextMenu, setNodeContextMenu] = useState<{
  //   isOpen: boolean;
  //   mouseX: number;
  //   mouseY: number;
  //   node: Node | null;
  // }>({
  //   isOpen: false,
  //   mouseX: 0,
  //   mouseY: 0,
  //   node: null,
  // });
  

  
  useEffect(() => {
    if (projectId) {
      
      if(!isFetchingModelGroupsFromDB){
        fetchModelGroupsFromDBViaPagination()
      }
     
    }
    return ()=>{
      resetModelListContextFunction();
      
    }
  }, [projectId]);

 useEffect(()=>{
  
  (async()=>{
    setSearchData({
      autoCompleteData:[],
      searchQuery:'',
      autoCompleteValueChange:''
  }) 
    if(projectId){
      const allModelGroupNamesApiResponse = await ModelService.getAllModelGroupNames({
        projectId: projectId,
      });
      setSearchData({searchQuery:'',autoCompleteValueChange:'',autoCompleteData:allModelGroupNamesApiResponse})
    }
  })()
 },[projectId])

 useEffect(()=>{
  (async()=>{
    if(projectId && searchData.autoCompleteValueChange){
      setIsFetchingModelGroup(true)
      const getModelGroupNameApiResponse = await ModelService.getModelGroups({
        projectId: projectId,
        getModels: "true",
        collectionsExist : true,
        modelGroupId:searchData.autoCompleteValueChange
       
      });
      
      let modelGroupsToSet: ModelGroupUI[] = [];
      for (const mg of getModelGroupNameApiResponse) {
        modelGroupsToSet.push({
          ...mg,
          models: mg.models || [],
          nodes: mg.nodes.map((node) => {
            return {
              ...node,
              data: {},
              type: "modelNode",
            };
          }),
          editedNodes: mg.nodes.map((node) => {
            return {
              ...node,
              data: {},
              type: "modelNode",
            };
          }),
          editedEdges: mg.edges,
          isDirty: false,
          mode: "view",
        });
      }
      setIsFetchingModelGroup(false)
      setSearchResults([...modelGroupsToSet])
    }
  })()
 },[searchData.autoCompleteValueChange,projectId])

useEffect(()=>{
  setSearchResults([])
},[searchData.searchQuery])

//   useEffect(()=>{
//     if(searchData.searchQuery){
//         const reg = new RegExp(searchData.searchQuery,"i")
//         if(modelGroups.length > 0){
//             const result = modelGroups.filter(modelGroups=>{
//                 const shouldBeInResult = reg.test(modelGroups.name)
//                 return shouldBeInResult 
//             })
//             setSearchResults(result)
//             setSearchData((prevData: SearchData)=>{
//                 return {...prevData,autoCompleteData:result.map(item=>{
//                     return {_id:item._id,name:item.name}
//                 })}
//             })
//         }
//     }
//     else{
//         setSearchData((prevData: SearchData)=>{
//             return {...prevData,autoCompleteData:[]}
//         })
//     }
// },[searchData.searchQuery,modelGroups])
  useEffect(() => {
    dispatch(setSideDrawer({ type: 'notification', component: ModelAnalytics, isOpen: false }));
    dispatch(setTopActionButton({ type: "addModel", onClickComponentToRender: AddModel }));
  },[])

  const handleModelGroupDeleteSuccess = () => {
    const modelGroupIdWhichGotDelete = deleteModelGroupPopup.modelGroupToDelete.id;

    setModelGroups(oldModelGroups=>{
      for (let modelGroupIndex = 0; modelGroupIndex < oldModelGroups.length; modelGroupIndex++) {
        const modelGroup = oldModelGroups[modelGroupIndex];
        if (modelGroup._id === modelGroupIdWhichGotDelete) {
          oldModelGroups.splice(modelGroupIndex, 1);
          return [...oldModelGroups]
        }
      }
      return oldModelGroups;
    })
  }

  const handleModelGroupEditButtonClick = useCallback(
    (modelGroupId: string) => {
      setModelGroups((oldModelGroups) => {
        for (
          let modelGroupIndex = 0;
          modelGroupIndex < oldModelGroups.length;
          modelGroupIndex++
        ) {
          let oldModelGroup = oldModelGroups[modelGroupIndex];
          if (oldModelGroup._id === modelGroupId) {
            oldModelGroup.mode = "edit";
            oldModelGroups[modelGroupIndex] = { ...oldModelGroup };
            return [...oldModelGroups];
          }
        }

        return oldModelGroups;
      });
    },
    []
  );

  const setModelGroupUIData = useCallback(
    (
      modelGroupId: string,
      thingsToSet: {
        nodesToSet?: ModelGroupNodeUI[];
        editedNodesToSet?: ModelGroupNodeUI[];
        modeToSet?: ModelGroupUI["mode"];
        isSavingToSet?: boolean;
        edgesToSet?: ModelGroupEdgeUI[];
        editedEdgesToSet?: ModelGroupEdgeUI[];
      }
    ) => {
      const nodesToSet = thingsToSet?.nodesToSet;
      const modeToSet = thingsToSet?.modeToSet;
      const editedNodesToSet = thingsToSet?.editedNodesToSet;
      const edgesToSet = thingsToSet?.edgesToSet;
      const editedEdgesToSet = thingsToSet?.editedEdgesToSet;
      const isSavingToSet = thingsToSet?.isSavingToSet;
      setModelGroups((oldModelGroups) => {
        for (
          let modelGroupIndex = 0;
          modelGroupIndex < oldModelGroups.length;
          modelGroupIndex++
        ) {
          let oldModelGroup = oldModelGroups[modelGroupIndex];
          if (modelGroupId === oldModelGroup._id) {
            if (nodesToSet && nodesToSet?.length > 0) {
              oldModelGroup.nodes = [...nodesToSet];
            }
            if (editedNodesToSet && editedNodesToSet?.length > 0) {
              oldModelGroup.editedNodes = [...editedNodesToSet];
            }
            if (isSavingToSet !== undefined) {
              oldModelGroup.isSaving = isSavingToSet;
            }
            if (modeToSet !== undefined) {
              oldModelGroup.mode = modeToSet;
            }
            if (modeToSet !== undefined) {
              oldModelGroup.mode = modeToSet;
            }
            if (edgesToSet !== undefined) {
              oldModelGroup.edges = [...edgesToSet];
            }
            if (editedEdgesToSet !== undefined) {
              oldModelGroup.editedEdges = [...editedEdgesToSet];
            }
            oldModelGroups[modelGroupIndex] = { ...oldModelGroup };
            return [...oldModelGroups];
          }
        }
        return oldModelGroups;
      });
    },
    [setModelGroups]
  );

  const handleModelGroupCancelButtonClick = useCallback(
    (modelGroupId: string) => {
      const modelGroup = getModelGroupById(modelGroupId, modelGroups);

      setModelGroupUIData(modelGroupId, {
        modeToSet: "view",
        editedNodesToSet: modelGroup?.nodes || [],
        editedEdgesToSet: modelGroup?.edges || [],
      });
    },
    [modelGroups, setModelGroupUIData]
  );

  // let initialNodes: Node[] = models.map((model) => {
  //   return {
  //     id: model._id,
  //     isConnectable: true,
  //     type: "modelNode",
  //     data: {
  //       model: model,
  //     },
  //     draggable: true,
  //     position: { x: 0, y: 0 },
  //   };
  // });

  // initialNodes = [initialNodes[0], initialNodes[1]];

  let initialEdges: Edge[] = [
    // { id: 'edge-1', source: '6347cfad9ae2c50fd19c9932', target: '633e83078d1ab1194b1a7519', targetHandle: `633e83078d1ab1194b1a7519<->top`, sourceHandle: `6347cfad9ae2c50fd19c9932<->bottom` },
    {
      id: "edge-1",
      source: "6347cfad9ae2c50fd19c9932",
      target: "633e83078d1ab1194b1a7519",
      sourceHandle: `6347cfad9ae2c50fd19c9932<->bottom`,
      targetHandle: `633e83078d1ab1194b1a7519<->top`,
    },
    // { id: 'edge-2', source: '6347cfad9ae2c50fd19c9932', target: '633e83078d1ab1194b1a7519', sourceHandle: 'handle2' },
  ];

  // const [nodes, setNodes] = useState(initialNodes);

  // const [edges, setEdges] = useState<any[]>(initialEdges);

  const handleModelGroupSaveButtonClick = useCallback(
    async (modelGroupId: string) => {
      setModelGroupUIData(modelGroupId, {
        isSavingToSet: true,
      });

      const modelGroups = modelGroupsRef.current;

      let modelGroupToSave = getModelGroupById(modelGroupId, modelGroups);

      const modelGroupToSaveInDB = (() => {
        let modelGroupToSaveInDB = {
          ...modelGroupToSave,
          editedNodes: [...(modelGroupToSave?.editedNodes || [])]
        };

        if (modelGroupToSaveInDB?.editedNodes) {
          for (let nodeIndex = 0; nodeIndex < modelGroupToSaveInDB?.editedNodes?.length; nodeIndex++) {
            const node = modelGroupToSaveInDB?.editedNodes[nodeIndex];
           delete node.data;
           modelGroupToSaveInDB.editedNodes[nodeIndex] = copyByValue(node)
          }
        }

        return modelGroupToSaveInDB;
      })();

      await ModelService.updateModelGroup(modelGroupToSave?._id || "", {
        nodes: modelGroupToSaveInDB?.editedNodes,
        edges: modelGroupToSaveInDB?.editedEdges as any
      })

      setModelGroupUIData(modelGroupId, {
        isSavingToSet: false,
        modeToSet: "view",
        nodesToSet: modelGroupToSave?.editedNodes || [],
        editedNodesToSet: modelGroupToSave?.editedNodes || [],
        edgesToSet: modelGroupToSave?.editedEdges || [],
        editedEdgesToSet: modelGroupToSave?.editedEdges || [],
      });
    },
    [modelGroupsRef, setModelGroupUIData]
  );

  const handleReactFlowOnNodesChangeEvent = useCallback(
    (nodeChanges: NodeChange[]) => {
      const firstNodeId: string = (() => {
        if (nodeChanges?.length > 0) {
          // @ts-ignore
          return (nodeChanges && nodeChanges[0] && nodeChanges[0]?.id) || "";
        }
        return "";
      })();

      if (!firstNodeId) {
        return;
      }

      const modelGroupId = getModelGroupIdOfNode(firstNodeId, modelGroups);

      const modelGroup = getModelGroupById(modelGroupId, modelGroups);

      let nodeChangesToApply = applyNodeChanges(
        nodeChanges,
        (modelGroup?.mode === "view"
          ? modelGroup.nodes
          : modelGroup?.editedNodes) || []
      );

      const isAnyNodeXOrYAxisNegative = (() => {
        for (const node of nodeChangesToApply) {
          if (node.position.x < 0 || node.position.y < 0) {
            return true;
          }
        }
        return false;
      })();

      if (isAnyNodeXOrYAxisNegative) {
        return;
      }

      setModelGroupUIData(modelGroupId, {
        nodesToSet:
          modelGroup?.mode === "view" ? (nodeChangesToApply as any) : [],
        editedNodesToSet:
          modelGroup?.mode === "edit" ? (nodeChangesToApply as any) : [],
      });
    },
    [modelGroups, setModelGroupUIData]
  );

  // const onNodesChange = useCallback(
  //   (changes) => setNodes((nds) => applyNodeChanges(changes, nds)),
  //   [setNodes]
  // );

  // const onEdgesChange = useCallback(
  //   (changes) => {
  //     setEdges((eds) => applyEdgeChanges(changes, eds));
  //   },
  //   [setEdges]
  // );

  // const onConnect = useCallback(
  //   (connection) => setEdges((eds) => addEdge(connection, eds)),
  //   [setEdges]
  // );

  const handleReactFlowOnConnectEvent = useCallback(
    (connection: Connection) => {
      const modelGroupId = getModelGroupIdOfNode(
        connection.source || "",
        modelGroups
      );

      const modelGroup = getModelGroupById(modelGroupId, modelGroups);

      const edgeToAdd = {
        ...connection,
        id: `${connection.sourceHandle}<->${connection.targetHandle}`,
      } as Edge;

      // const edgesToSet = addEdge(connection, modelGroup?.editedEdges || [])

      const edgesToSet = [
        ...(modelGroup?.editedEdges || []),
        {
          ...edgeToAdd,
          type: "default",
          animated: true
          // style: {
          //   strokeWidth: 1,
          //   stroke: 'black',
          // },
          // markerEnd: {
          //   type: MarkerType.ArrowClosed,
          //   width: 20,
          //   height: 20,
          //   // color: '#FF0072',
          //   color: "black"
          // }
        },
      ];

      setModelGroupUIData(modelGroupId, {
        editedEdgesToSet: edgesToSet,
      });
    },
    [modelGroups, setModelGroupUIData]
  );

  const handleAccordianOnChangeEvent = useCallback((modelGroupId: string) => {
    
    if(expandModelGroupId===modelGroupId){
      setExpandModelGroupId('')
    }else{
      setExpandModelGroupId(modelGroupId)
    }
    // setModelGroups((oldModelGroups) => {
    //   for (
    //     let modelGroupIndex = 0;
    //     modelGroupIndex < oldModelGroups.length;
    //     modelGroupIndex++
    //   ){ 
    //     const oldModelGroup = oldModelGroups[modelGroupIndex];
    //     if (modelGroupId !== oldModelGroup._id && oldModelGroup.isExpanded) {
    //       oldModelGroup.isExpanded = false;
    //       oldModelGroups[modelGroupIndex] = { ...oldModelGroup };
    //     }
    //     if (modelGroupId === oldModelGroup._id) {
    //       oldModelGroup.isExpanded = oldModelGroup.isExpanded ? false : true;
    //       oldModelGroups[modelGroupIndex] = { ...oldModelGroup };
    //     }
    //   }

    //   return [...oldModelGroups];
    // });
  }, [expandModelGroupId]);

  const handleEdgeContextMenuEvent = useCallback(
    (event: React.MouseEvent, edge: Edge) => {
      const modelGroupId = getModelGroupIdOfEdge(edge.id, modelGroups);

      const modelGroup = getModelGroupById(modelGroupId, modelGroups);

      if (modelGroup?.mode === "view") {
        return;
      }

      event.preventDefault();
      setEdgeContextMenu({
        isOpen: true,
        mouseX: event.clientX - 2,
        mouseY: event.clientY - 4,
        edge: edge,
      });
    },
    [modelGroups]
  );

  // const handleNodeContextMenuEvent = useCallback(
  //   (event: React.MouseEvent, node: Node) => {

  //     const modelGroupIdOfNode = getModelGroupIdOfNode(node.id, modelGroups);

  //     const modelGroup = getModelGroupById(modelGroupIdOfNode, modelGroups);

  //     if (modelGroup?.mode === "view") {
  //       return;
  //     }      

  //     event.preventDefault();
  //     setNodeContextMenu({
  //       isOpen: true,
  //       mouseX: event.clientX - 2,
  //       mouseY: event.clientY - 4,
  //       node: node        
  //     })
  //   },
  //   [modelGroups],
  // );

  const closeEdgeContextMenu = useCallback(() => {
    setEdgeContextMenu((old) => ({ ...old, isOpen: false }));
  }, []);

  // const closeNodeContextMenu = useCallback(() => {
  //   setNodeContextMenu((old) => ({ ...old, isOpen: false }));
  // }, []);

  const handleEdgeContextMenuCloseEvent = useCallback(() => {
    closeEdgeContextMenu();
  }, [closeEdgeContextMenu]);

  // const handleNodeContextMenuCloseEvent = useCallback(() => {
  //   closeNodeContextMenu();
  // }, [closeNodeContextMenu]);

  const handleEdgeContextMenuDeleteEdgeEvent = useCallback(() => {
    const edgeToDelete = edgeContextMenu.edge;

    if (!edgeToDelete) {
      return;
    }

    const modelGroupId = getModelGroupIdOfEdge(edgeToDelete.id, modelGroups);

    const modelGroup = getModelGroupById(modelGroupId, modelGroups);

    let editedEdgesToSet = [...(modelGroup?.editedEdges || [])];

    for (let edgeIndex = 0; edgeIndex < editedEdgesToSet.length; edgeIndex++) {
      let editedEdge = editedEdgesToSet[edgeIndex];
      if (editedEdge.id === edgeToDelete.id) {
        editedEdgesToSet.splice(edgeIndex, 1);
        edgeIndex = edgeIndex - 1;
      }
    }

    setModelGroupUIData(modelGroupId, {
      editedEdgesToSet: [...editedEdgesToSet],
    });

    closeEdgeContextMenu();
  }, [
    closeEdgeContextMenu,
    edgeContextMenu.edge,
    modelGroups,
    setModelGroupUIData,
  ]);

  const handleEdgeContextMenuonEdgeTypeSelectedEvent = useCallback(
    (edgeTypeSelected: string, edgeId: string) => {
      const modelGroupId = getModelGroupIdOfEdge(edgeId, modelGroups);
      const modelGroup = getModelGroupById(modelGroupId, modelGroups);

      let editedEdgesToSet = [...(modelGroup?.editedEdges || [])];

      for (
        let edgeIndex = 0;
        edgeIndex < editedEdgesToSet.length;
        edgeIndex++
      ) {
        let editedEdge = editedEdgesToSet[edgeIndex];
        if (editedEdge.id === edgeId) {
          editedEdge.type = edgeTypeSelected;

          editedEdgesToSet[edgeIndex] = { ...editedEdge };
        }
      }

      setModelGroupUIData(modelGroupId, {
        editedEdgesToSet: [...editedEdgesToSet],
      });

      closeEdgeContextMenu();
    },
    [closeEdgeContextMenu, modelGroups, setModelGroupUIData]
  );

  const handleOnMoveModelToDifferentModelGroupSelectInMenu = useCallback(
    (modelId: string) => {
      // closeNodeContextMenu();
      // const nodeIdToMove = nodeContextMenu.node?.id || "";

      const nodeIdToMove = modelId;

      const modelGroupId = getModelGroupIdOfNode(nodeIdToMove, modelGroups);

      const modelGroup = getModelGroupById(modelGroupId, modelGroups);

      const model = getModelById(nodeIdToMove, modelGroup?.models);

      setMoveModelToDifferentModelGroupPopup({
        isOpen: true,
        isMovingModelToDifferentModelGroup: false,
        // modelGroupNameEnteredByUser: "",
        model: {
          _id: model?._id || "",
          name: model?.name || "",
          modelGroup: {
            name: modelGroup?.name || ""
          }
        }
      })
    },
    [modelGroups],
  ); 
  
  const handleMoveModelPopup_SetModelGroupNameEnteredByUser = useCallback(
    (modelGroupName: string) => {
      setMoveModelToDifferentModelGroupPopup(oldState=>({
        ...oldState,
        modelGroupNameEnteredByUser: modelGroupName
      }))
    },
    [],
  );

  const handleMoveModelPopup_moveButtonClicked = useCallback(
    async (modelGroupNameEnteredByUser: string) => {

      const modelGroupNameAlreadyExistsInDB = (()=>{
        const modelGroup = modelGroups.find(modelGroup=>modelGroup.name === modelGroupNameEnteredByUser);
        return modelGroup ? true : false
      })();

      setMoveModelToDifferentModelGroupPopup(oldState=>({
        ...oldState,
        isMovingModelToDifferentModelGroup: true
      }))

      let createModelGroupResponse: ModelGroup | undefined;

      let modelToMove = (()=>{
        for (const modelGroup of modelGroups) {
          for (const model of modelGroup.models) {
            if (model._id === moveModelToDifferentModelGroupPopup.model._id) {
              return model;
            }
          }
        }
        return null;
      })();

      const modelGroupsToSet: typeof modelGroups = [...modelGroups];

      let idsOfModelGroupsToSaveInDB: Set<string> = new Set([]);

      // if (!modelGroupNameAlreadyExistsInDB) {
      //   createModelGroupResponse = await ModelService.createModelGroup({
      //     name: modelGroupNameEnteredByUser,
      //     projectId: projectId
      //   })

      //   idsOfModelGroupsToSaveInDB.add(createModelGroupResponse._id)

      //   const node: {
      //     data: {};
      //     type: ModelGroupNodeUI["type"];
      //     id: string;
      //     position: {
      //         x: number;
      //         y: number;
      //     };
      // } = {
      //     data: {},
      //     type: "modelNode",
      //     id: moveModelToDifferentModelGroupPopup.model._id,
      //     position: {
      //       x: 0,
      //       y: 0
      //     }
      //   }



      //   if (modelToMove) {
      //     if (modelToMove) {
      //       modelToMove.modelGroupId = createModelGroupResponse?._id || ""
      //     }          
      //     modelGroupsToSet.push(
      //       {
      //         ...createModelGroupResponse,
      //         nodes: [node],
      //         editedNodes: [node],
      //         models: [modelToMove],
      //         mode: "edit",
      //         isDirty: false,
      //         editedEdges: []
      //       }
      //     )
      //   }

      // }

      


      for (let modelGroupIndex = 0; modelGroupIndex < modelGroupsToSet.length; modelGroupIndex++) {
        const modelGroup = modelGroupsToSet[modelGroupIndex];

        if (!createModelGroupResponse) {
          // means user didn't created a new model group to move model in

          if (modelGroup.name === modelGroupNameEnteredByUser) {
            if (modelToMove) {
              modelToMove.modelGroupId = modelGroup._id;

              // pushing model to move in destined model group
              modelGroup.models = [...modelGroup.models, modelToMove];

            }
            modelGroup.mode = "edit";
            modelGroup.editedNodes = [...modelGroup.editedNodes, {
              data: {},
              id: moveModelToDifferentModelGroupPopup.model._id,
              type: "modelNode",
              position: {
                x: 0,
                y: 0
              }
            }]
            idsOfModelGroupsToSaveInDB.add(modelGroup._id);
          }
        }

        if (modelGroup.name === moveModelToDifferentModelGroupPopup.model.modelGroup.name) {
          // removing model from model group as it needs to move to different model group
          modelGroup.models = [...removeModel(moveModelToDifferentModelGroupPopup.model._id, modelGroup.models)];
          idsOfModelGroupsToSaveInDB.add(modelGroup._id);
        }

        if (modelGroup.editedNodes?.length>0) {
          for (let nodeIndex = 0; nodeIndex < modelGroup.editedNodes.length; nodeIndex++) {
            const node = modelGroup.editedNodes[nodeIndex];
            if (
              node.id === moveModelToDifferentModelGroupPopup.model._id &&
              modelGroup.name !== modelGroupNameEnteredByUser
            ) {
              // will be removing node as it has to move to different model group

              modelGroup.editedNodes.splice(nodeIndex, 1);
              nodeIndex -= 1;
              idsOfModelGroupsToSaveInDB.add(modelGroup._id);
            }
          }
        }

        if (modelGroup.editedEdges?.length>0) {
          for (let edgeIndex = 0; edgeIndex < modelGroup.editedEdges.length; edgeIndex++) {
            const edge = modelGroup.editedEdges[edgeIndex];
            if (
              edge.source === moveModelToDifferentModelGroupPopup.model._id ||
              edge.target === moveModelToDifferentModelGroupPopup.model._id
            ) {
              // removing old model edges as it has to move to different model group
              modelGroup.editedEdges.splice(edgeIndex, 1)
              idsOfModelGroupsToSaveInDB.add(modelGroup._id);
            }
          }
        }
      }

      setModelGroups([...modelGroupsToSet]);

      const destinationModelGroup = modelGroupsToSet.find(modelGroup=>modelGroup.name === modelGroupNameEnteredByUser)
      const destinationModelGroupId = destinationModelGroup?._id || "";

      if (!destinationModelGroupId) {
        throw new Error("destinationModelGroupId not found")
      }

      setTimeout(async () => {
        // using timeout to let setModelGroups update the state first


        const updateModel_ModelGroupIdPromise = new Promise(async (resolve, reject) => {

          const modelUpdateResponse = await ModelService.updateModel({
            id: [modelToMove?._id || ""],
            modelGroupId: destinationModelGroupId
          })

          return resolve(modelUpdateResponse);
        })

        const modelGroupSavePromises: Promise<any>[] = [];

        idsOfModelGroupsToSaveInDB.forEach(idOfModelGroupsToSaveInDB=>{
          modelGroupSavePromises.push(
            handleModelGroupSaveButtonClick(idOfModelGroupsToSaveInDB)
          )
        })

        await Promise.all(modelGroupSavePromises)

        await updateModel_ModelGroupIdPromise;

        setMoveModelToDifferentModelGroupPopup(oldState=>({
          ...oldState,
          isOpen: false,
          isMovingModelToDifferentModelGroup: false
        }))
      }, 500);


    },
    [handleModelGroupSaveButtonClick, modelGroups, moveModelToDifferentModelGroupPopup.model._id, moveModelToDifferentModelGroupPopup.model.modelGroup.name, setModelGroups],
  );

  const handleMoveModelPopup_onClose = useCallback(
    () => {
      setMoveModelToDifferentModelGroupPopup(oldState=>({
        ...oldState,
        isOpen: false
      }))
    },
    [],
  );

  return (
    <div
      style={{
        height: "100%",
        overflow: "auto",
        paddingBottom:"20px",
        
      }}
    >

      {
       
         (searchData.autoCompleteValueChange ? searchResults?.length>0 : modelGroups?.length>0 )
          &&
          (searchData.autoCompleteValueChange ? searchResults : modelGroups).map((modelGroup) => {

              const accordianDetailsHTMLElementId = `${modelGroup._id}_accordianDetail`;

              return (
                <>
                  <Accordion
                    key={modelGroup._id}
                    className={`${classes.modelGroupAccordian} ${css.modelGroupAccordian}`}
                    expanded={modelGroup._id===expandModelGroupId ? true : false}
                    // onChange={() => handleAccordianOnChangeEvent(modelGroup._id)}
                    style={{
                      borderColor: modelGroup.colour,
                      borderWidth: "1.6px",
                      borderStyle: "solid"
                    }}
                  >
                    {/* <AccordionSummary expandIcon={<ExpandMoreIcon />}> */}
                    <AccordionSummary
                      classes={{
                        root: css.accordianSummaryRoot
                      }}
                    >
                      <div className={classes.modelGroupAccordianSummaryChild}>
                        <div className={classes.modelGroupNameSection}>
                          <div className={`${classes.modelGroupName} ${css.modelGroupName}`} title={modelGroup.name}>{modelGroup.name}</div>
                          <div className={`${classes.noOfModelsText}`}>{modelGroup.models.length || 0} task{modelGroup.models.length < 2 ? "" : "s"}</div>
                        </div>

                        <div
                          className={`
                            ${classes.modelGroupOptions}
                          `}
                        >

                          <div>
                            <IconButton
                              aria-label="more"
                              aria-controls="long-menu"
                              aria-haspopup="true"
                              onClick={(event)=>{
                                setModelGroupMenu({
                                  isOpen: true,
                                  modelGroupId: modelGroup._id,

                                  // @ts-ignore
                                  anchorEl: event.currentTarget
                                })
                              }}
                            >
                              <MoreVertIcon />
                            </IconButton>
                            <Menu
                              anchorEl={modelGroupMenu.anchorEl}
                              keepMounted
                              open={modelGroupMenu.modelGroupId === modelGroup._id && modelGroupMenu.isOpen ? true : false}
                              onClose={()=>{
                                setModelGroupMenu(oldState=>({...oldState, isOpen: false}))
                              }}
                              PaperProps={{
                                style: {
                                  // maxHeight: ITEM_HEIGHT * 4.5,
                                  width: '20ch',
                                },
                              }}
                            >
                                <MenuItem 
                                  onClick={()=>{
                                    setEditModelGroupPopup({
                                      open: true,
                                      modelGroupId: modelGroup._id,
                                      modelGroupName: modelGroup.name,
                                      modelGroupColour: modelGroup.colour
                                    })
                                    setModelGroupMenu(oldState=>({...oldState, isOpen: false}))
                                  }}
                                >
                                  Edit Group
                                </MenuItem>
                                {
                                  modelGroup.mode === "view" && (
                                    <MenuItem 
                                      onClick={(event) => {
                                        event.stopPropagation();
                                        setModelGroupMenu(oldState=>({...oldState, isOpen: false}))
                                        if (modelGroup._id!==expandModelGroupId) {
                                          handleAccordianOnChangeEvent(modelGroup._id);
                                        }
                                        handleModelGroupEditButtonClick(modelGroup._id);

                                      }}
                                      onFocus={(event) => {
                                        event.stopPropagation();
                                      }}
                                    >
                                      Edit Task Layouts
                                    </MenuItem>
                                  )
                                }
                                <MenuItem 
                                  onClick={()=>{
                                    setDeleteModelGroupPopup({
                                      open: true,
                                      modelGroupToDelete: {
                                        id: modelGroup._id,
                                        name: modelGroup.name,
                                        hasModelsLinked: modelGroup.models?.length>0 ? true : false
                                      }
                                    })

                                    setModelGroupMenu(oldState=>({...oldState, isOpen: false}))
                                  }}
                                >
                                  Delete Group
                                </MenuItem>
                            </Menu>
                          </div>

                          <Button
                            variant="contained"
                            color="secondary"
                            size="small"
                            endIcon={modelGroup._id===expandModelGroupId ?  <ExpandLessIcon /> : <ExpandMoreIcon />}
                            onClick={()=>{
                              handleAccordianOnChangeEvent(modelGroup._id)
                            }}
                            style={{
                              fontFamily: "Roboto-Medium",
                              fontSize: "15px",
                              paddingLeft: "10px",
                              paddingRight: "10px",
                              paddingTop: "2px",
                              paddingBottom: "2px",
                              width: "128px",
                              height: "37px"
                            }}
                          >
                            VIEW
                          </Button>
                          {/* {modelGroup.mode === "view" && (
                            <div
                              onClick={(event) => {
                                event.stopPropagation();
                                handleModelGroupEditButtonClick(modelGroup._id);
                              }}
                              onFocus={(event) => {
                                event.stopPropagation();
                              }}
                            >
                              <Tooltip title="Edit model group">
                                <EditIcon color="primary" />
                              </Tooltip>
                            </div>
                          )}
                          {modelGroup.mode === "edit" && (
                            <div
                              onClick={(event) => {
                                event.stopPropagation();
                                handleModelGroupCancelButtonClick(modelGroup._id);
                              }}
                              onFocus={(event) => {
                                event.stopPropagation();
                              }}
                            >
                              <Tooltip title="Cancel model group changes">
                                <CancelIcon color="primary" />
                              </Tooltip>
                            </div>
                          )} */}
                          {/* {modelGroup.mode === "edit" && (
                            <div
                              onClick={(event) => {
                                event.stopPropagation();
                                handleModelGroupSaveButtonClick(modelGroup._id);
                              }}
                              onFocus={(event) => {
                                event.stopPropagation();
                              }}
                            >
                              <Tooltip title="Save model group changes">
                                <SaveIcon color="primary" />
                              </Tooltip>
                            </div>
                          )} */}
                        </div>
                      </div>
                    </AccordionSummary>
                    <AccordionDetails id={accordianDetailsHTMLElementId}
                      style={{
                        flexDirection: "column"
                      }}
                    >
                        <div 
                          className={`
                            ${css.modelGroupReactFlowContainer}
                            ${modelGroup.mode === "view" ? css.viewMode : css.editMode }
                          `}
                        >
                          {
                            expandModelGroupId===modelGroup._id &&
                            <ReactFlow
                              // fitView
                              key={modelGroup.mode}
                              nodeTypes={nodeTypes}
                              zoomOnDoubleClick={false}
                              connectionMode={ConnectionMode.Loose}
                              translateExtent={[
                                [0, 0],
                                [
                                  100000000000000000000000000000000000000000000000000,
                                  100000000000000000000000000000000000000000000000000,
                                ],
                              ]}
                              nodesDraggable={modelGroup.mode === "view" ? false : true}
                              // style={{
                              //   backgroundColor: "grey",
                              // }}
                              zoomOnScroll={modelGroup.mode === "view" ? false : true}
                              preventScrolling={modelGroup.mode === "view" ? false : true}
                              panOnDrag={modelGroup.mode === "view" ? false : true}
                              panOnScroll={modelGroup.mode === "view" ? false : true}
                              onEdgeContextMenu={handleEdgeContextMenuEvent}
                              onNodeDrag={(nodeDragHandler) => {
                                console.log('modelGroups.map ~ nodeDragHandler:', nodeDragHandler)
                              }}
                              onEdgeMouseMove={(event) => {
                                console.log('modelGroups.map ~ event:', event)
                              }}
                              nodes={
                                modelGroup.mode === "view"
                                  ? modelGroup.nodes.map((node) => {
                                      if (!node.data) {
                                        node.data={}
                                      }
                                      node.data.modelGroup = modelGroup;
                                      node.data.model = getModelById(node.id, modelGroup.models)
                                      node.data.onModelCardClick = props.onModelClick;
                                      node.data.handleOnMoveModelToDifferentModelGroupSelectInMenu = handleOnMoveModelToDifferentModelGroupSelectInMenu
                                      return node;
                                    })
                                  : modelGroup.editedNodes.map((node) => {
                                      if (!node.data) {
                                        node.data={}
                                      }                          
                                      node.data.modelGroup = modelGroup;
                                      node.data.model = getModelById(node.id, modelGroup.models)
                                      node.data.onModelCardClick = props.onModelClick;
                                      node.data.handleOnMoveModelToDifferentModelGroupSelectInMenu = handleOnMoveModelToDifferentModelGroupSelectInMenu
                                      return node;
                                    })
                              }
                              edges={
                                modelGroup.mode === "view"
                                  ? modelGroup.edges.map(edge=>{
                                    // edge.animated=true
                                    edge.markerEnd={
                                      type: MarkerType.Arrow,
                                      width: 30,
                                      height: 30,
                                    }
                                    return edge
                                  })
                                  : modelGroup.editedEdges.map(edge=>{
                                    // edge.animated=true
                                    edge.markerEnd={
                                      type: MarkerType.Arrow,
                                      width: 30,
                                      height: 30,                                    
                                    }
                                    return edge
                                  })
                              }
                              onConnect={handleReactFlowOnConnectEvent}
                              proOptions={{ hideAttribution: true }}
                              className={`
                                ${
                                  modelGroup.mode === "view"
                                    ? `${css.reactFlowViewMode} reactFlowViewMode`
                                    : css.reactFlowEditMode
                                }
                              `}
                              onNodesChange={handleReactFlowOnNodesChangeEvent}
                            >
                              {modelGroup.mode === "edit" && (
                                <Background variant={BackgroundVariant.Lines} />
                              )}
                            </ReactFlow>
                          }
                        </div>
                        {
                          modelGroup.mode === "edit" &&
                          <div className={css.modelGroupLayoutEditButtonsContainer}>
                            <Button
                              size="medium"
                              variant="contained"
                              className={css.cancelButton}
                              style={{
                                backgroundColor: "white"
                              }}
                              onClick={()=>{
                                handleModelGroupCancelButtonClick(modelGroup._id)
                              }}
                            >Cancel</Button>
                            <Button
                              size="medium"
                              color="primary"
                              variant="contained"
                              className={css.saveChangesButton}
                              onClick={()=>{
                                handleModelGroupSaveButtonClick(modelGroup._id)
                              }}
                            >Save Changes</Button>
                          </div>
                        }
                    </AccordionDetails>
                  </Accordion>
                </>
              );
            })
          }
            {isFetchingModelGroup ? <div
        
        style={{ textAlign: "center", padding: "10px", marginBottom: "40px" }}
      >
        {isFetchingModelGroup
          && <div
          style={{
            textAlign: "center",
            display: `${isFetchingModelGroupsFromDB ? 'none' : 'block'}`
          }}
        >
          <CircularProgress size={30} />
        </div>
          }
      </div> : !isFetchingModelGroupsFromDB && (searchData.autoCompleteValueChange ? searchResults?.length===0 : modelGroups?.length===0) && <div className={css.noModelGroupsFoundText} style={{
              
            }}>No task groups found</div>}
            
      

      {edgeContextMenu.isOpen && (
        <EdgeContextMenu
          mouseX={edgeContextMenu.mouseX}
          mouseY={edgeContextMenu.mouseY}
          edge={edgeContextMenu.edge as Edge}
          onClose={handleEdgeContextMenuCloseEvent}
          onDeleteEdgeClick={handleEdgeContextMenuDeleteEdgeEvent}
          onEdgeTypeSelected={handleEdgeContextMenuonEdgeTypeSelectedEvent}
        />
      )}
      {
        editModelGroupPopup.open &&
        <CreateModelGroupPopup 
          open={true} 
          mode={"edit"} 
          onClose={()=>{setEditModelGroupPopup(oldState=>({...oldState, open: false}))}} 
          editModelGroup={{
            modelGroupName: editModelGroupPopup.modelGroupName,
            modelGroupId: editModelGroupPopup.modelGroupId,
            modelGroupColour: editModelGroupPopup.modelGroupColour
          }}
          refreshModelGroupsInUI={fetchModelGroupsFromDB}
        />
      }
      {
        deleteModelGroupPopup.open &&
        <DeleteModelGroupWarningPopup 
          modelGroupToDelete={{
            id: deleteModelGroupPopup.modelGroupToDelete.id,
            name: deleteModelGroupPopup.modelGroupToDelete.name,
            hasModelsLinked: deleteModelGroupPopup.modelGroupToDelete.hasModelsLinked
          }}
          onClose={() => {
            setDeleteModelGroupPopup(oldState => ({
              ...oldState,
              open: false
            }));
          } } 
          onDeleteSuccess={handleModelGroupDeleteSuccess} />
          
      }
      {/* {nodeContextMenu.isOpen && (
        <NodeContextMenu
          mouseX={nodeContextMenu.mouseX}
          mouseY={nodeContextMenu.mouseY}
          node={nodeContextMenu.node as ModelGroupNodeUI}
          onClose={handleNodeContextMenuCloseEvent}
          onMoveModelToDifferentModelGroupClick={handleOnMoveModelToDifferentModelGroupSelectInNodeContextMenu}
        />
      )} */}
      {
        moveModelToDifferentModelGroupPopup.isOpen &&
        <MoveModelToDifferentModelGroupPopup
          modelName={moveModelToDifferentModelGroupPopup.model.name}
          isMovingModelToDifferentModelGroup={moveModelToDifferentModelGroupPopup.isMovingModelToDifferentModelGroup} 
          // modelGroupNameEnteredByUser={moveModelToDifferentModelGroupPopup.modelGroupNameEnteredByUser} 
          // setModelGroupNameEnteredByUser={handleMoveModelPopup_SetModelGroupNameEnteredByUser} 
          moveButtonClicked={handleMoveModelPopup_moveButtonClicked}          
          onClose={handleMoveModelPopup_onClose}
          modelGroupsAvailableInDB={modelGroups}
          modelGroupNameOfModel={moveModelToDifferentModelGroupPopup.model.modelGroup.name}
        />
      }
      <div
        ref={observerRef}
        style={{ textAlign: "center", padding: "10px", marginBottom: "40px" ,display: `${searchData?.searchQuery ? 'none' : 'block'}`}}
      >
        {isFetchingModelGroupsFromDB
          && <div
          style={{
            textAlign: "center",
            
          }}
        >
          <CircularProgress size={30} />
        </div>
          }
      </div>
    </div>
  );
}
function getModelById(modelId: string, models: IModel[] | undefined): IModel | null {
  if (models && models?.length>0) {
    const model = models.find(model=>model._id === modelId);
    if (model) {
      return model;
    }
  }
  return null
}


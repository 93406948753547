import { useState, useEffect } from "react";
import JWTService from "../services/JWTService";
import UserDBService from "../services/UserDBService";

type UserLoginStatus = "checking" | "validUser" | "invalidUser" | "";

type Options = {
  silentlyGenerateNewAccessTokenBeforeExpiryIfValidUser?: boolean;
};

export default function useCheckUserLoginValidity(
  options: Options | undefined = undefined
) {
  const [userLoginStatus, setUserLoginStatus] = useState<UserLoginStatus>("");

  const silentlyGenerateNewAccessTokenBeforeExpiry: boolean = (() => {
    if (
      options?.silentlyGenerateNewAccessTokenBeforeExpiryIfValidUser !==
        undefined &&
      options?.silentlyGenerateNewAccessTokenBeforeExpiryIfValidUser !== null
    ) {
      return options?.silentlyGenerateNewAccessTokenBeforeExpiryIfValidUser;
    }

    return false;
  })();

  useEffect(() => {
    let timeOutId: NodeJS.Timeout;

    const executeFunction = async () => {
      function watchAndSilentlyGenerateNewAccessTokenBeforeExpiryIfValidUser(
        accessToken: string
      ) {
        type JWTJson = {
          exp: number;
        };

        const jwtJson = JWTService.decodeJWTToken<JWTJson>(accessToken);

        const numberOfSecondsBeforeExpiryToGenerateNewAccessToken = 10;

        /**
         * @description
         * Generating new access token few seconds before the token gets expired
         */
        timeOutId = setTimeout(async () => {
          try {
            const { accessToken } =
              await UserDBService.generateLoggedInUserNewAccessToken();
            if (!accessToken) {
              throw new Error("New access token didn't received");
            }

            setUserLoginStatus("validUser");

            watchAndSilentlyGenerateNewAccessTokenBeforeExpiryIfValidUser(accessToken);
          } catch (error) {
            setUserLoginStatus("invalidUser");
          }
        }, (jwtJson.exp - Date.now() / 1000 - numberOfSecondsBeforeExpiryToGenerateNewAccessToken) * 1000);
      }

      try {
        setUserLoginStatus("checking");

        const { isValidAccessToken } =
          await UserDBService.isLoggedInUserAccessTokenValid();

        if (isValidAccessToken) {
          const { isValidRefreshToken } =
            await UserDBService.isLoggedInUserRefreshTokenValid();

          if (!isValidRefreshToken) {
            throw new Error("Refresh token invalid");
          }

          setUserLoginStatus("validUser");

          if (silentlyGenerateNewAccessTokenBeforeExpiry) {
            const { accessToken } =
              await UserDBService.getLoggedInUserAccessToken();

            watchAndSilentlyGenerateNewAccessTokenBeforeExpiryIfValidUser(accessToken);
          }
        } else {
          const { accessToken } =
            await UserDBService.generateLoggedInUserNewAccessToken();
          if (!accessToken) {
            throw new Error("New access token didn't received");
          }
          setUserLoginStatus("validUser");
          if (silentlyGenerateNewAccessTokenBeforeExpiry) {
            watchAndSilentlyGenerateNewAccessTokenBeforeExpiryIfValidUser(accessToken);
          }
        }
      } catch (error) {
        setUserLoginStatus("invalidUser");
      }
    };
    executeFunction();

    return () => {
      if (timeOutId !== null && timeOutId !== undefined) {
        clearTimeout(timeOutId);
      }
    };
  }, [silentlyGenerateNewAccessTokenBeforeExpiry]);

  return {
    userLoginStatus,
  };
}

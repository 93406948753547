export function incrementVersionBy0Point0Point1(versionNo: string): string {
	if (versionNo.indexOf(".") === -1) {
		return `${versionNo}.0.1`;
	}

	if (versionNo.split(".").length === 2) {
		// means versionNo contains only 1 decimal
		return `${versionNo}.1`;
	}

	if (versionNo.split(".").length === 3) {
		// means versionNo contains only 2 decimals

		const numberStrings = versionNo.split(".");

		for (let index = numberStrings.length - 1; index >= 0; index--) {
			const numberString = numberStrings[index];
			if (Number(numberString) < 255) {
				numberStrings[index] = `${Number(numberString) + 1}`;
				break;
			} else if (Number(numberString) >= 255 && index !== 0) {
				numberStrings[index] = "0";
			}
		}

		return numberStrings.join(".");
	}

	return versionNo;
}

export function decrementVersionBy0Point0Point1(versionNo: string): string {
	versionNo = convertVersionInMajorMinorPatchVersionFormat(versionNo);

	if (versionNo.split(".").length === 3) {
		// means versionNo contains only 2 decimals

		/**
		 * decrement logic:
		 * - 5.0.0 -> 4.255.255
		 * - 5.4.0 -> 5.3.255
		 */

		const numberStrings = versionNo.split(".");

		for (let index = numberStrings.length - 1; index >= 0; index--) {
			const numberString = numberStrings[index];
			if (Number(numberString) > 0) {
				numberStrings[index] = `${Number(numberString) - 1}`;
				break;
			}

			numberStrings[index] = "255";
		}

		return numberStrings.join(".");
	}

	return versionNo;
}

export function isFirstParamVersionLessThanSecondParamVersion(
	versionNo1: string,
	versionNo2: string
): boolean {
	versionNo1 = convertVersionInMajorMinorPatchVersionFormat(versionNo1);
	versionNo2 = convertVersionInMajorMinorPatchVersionFormat(versionNo2);

	const numberStringsV1 = versionNo1.split(".");
	const numberStringsV2 = versionNo2.split(".");

	for (let v1NoIndex = 0; v1NoIndex < numberStringsV1.length; v1NoIndex++) {
		const v1NoString = numberStringsV1[v1NoIndex];
		const v2NoString = numberStringsV2[v1NoIndex];

		if (Number(v1NoString) < Number(v2NoString)) {
			return true;
		} else if (Number(v1NoString) > Number(v2NoString)) {
			return false;
		}
	}

	return false;
}

export function convertVersionInMajorMinorPatchVersionFormat(
	version: string
): string {
	if (version.indexOf(".") === -1) {
		version = `${version}.0.0`;
	}

	if (version.split(".").length === 2) {
		// means version contains only 1 decimal
		version = `${version}.0`;
	}

	return version;
}

export function isValidMajorMinorPatchVersion(version: string): boolean {
	version = convertVersionInMajorMinorPatchVersionFormat(version);

	const numberStrings = version.split(".");

	if (numberStrings.length > 3) {
		return false;
	}

	for (const numberString of numberStrings) {
		if (isNaN(Number(numberString))) {
			return false;
		}
		if (Number(numberString) < 0 || Number(numberString) > 255) {
			return false;
		}
	}

	return true;
}

export function sortVersionsInDescendingOrder(collections: {version: string}[]): {version: string}[] {
	if (Array.isArray(collections)) {
		collections = collections.sort((collectionA, collectionB) => {
			const [majorVersionA, minorVersionA, patchVersionA] = collectionA.version.split(".");
			const [majorVersionB, minorVersionB, patchVersionB] = collectionB.version.split(".");
			if (Number(majorVersionA) > Number(majorVersionB)) {
				return -1
			} else if (Number(majorVersionB) > Number(majorVersionA)) {
				return 1
			} else if (Number(majorVersionA) === Number(majorVersionB)) {
				if (Number(minorVersionA) > Number(minorVersionB)) {
					return -1
				} else if (Number(minorVersionB) > Number(minorVersionA)) {
					return 1
				} else if (Number(minorVersionA) === Number(minorVersionB)) {
					if (Number(patchVersionA) > Number(patchVersionB)) {
						return -1
					} else if (Number(patchVersionB) > Number(patchVersionA)) {
						return 1
					}
				}
			}

			return 0;
		})
	}
	return collections;
}

import { Avatar, Box, Button, CircularProgress, makeStyles, Typography } from "@material-ui/core"
import { IAIresponseMetaData, IFeedbackBtn, IMessage } from "../../projects/features/aiMarketPlace/aiMarketPlaceInterfaces/interfaces"
import AIProfileIcon from '../../../../../assets/images/Animation.png'
import { Skeleton } from "@material-ui/lab"
import ReactMarkdown from "react-markdown"
import {ReactComponent as ModelDescriptionLightIcon} from '../../../../../assets/svg/modelDescriptionlight.svg'
import {ReactComponent as ModelDescriptionDarkIcon} from '../../../../../assets/svg/modelDescriptionDark.svg'
import OpenInNewIcon from '@material-ui/icons/OpenInNew';
import useAuth from "../../../../../hooks/useAuth"
import React, { forwardRef, useContext, useState } from "react";
import { useEffect, useRef } from "react"
import ListIcon from '@material-ui/icons/List';
// @ts-ignore
import { ThinkingResponse } from "@e-llm-studio/uiexplainability"
import thinkingBulbIcon from "../../../../../assets/images/ThinkingBulbIcon.gif";
import arrowDownIcon from "../../../../../assets/svg/ArrowDown.svg";
import css from "./chatMessage.module.css";
import { RoundedButton, TestDriveDark, TestDriveLight } from "../../../../../common"
import ChatContext from "../../chat/contexts/chat.context"
import { Handle } from "reactflow"
import { generateUniqueId } from "../../../../../services/idHelperService"
import { MinimizedThinkingStepsForParallelTools } from "@e-llm-studio/llmstudioui"
import ExplainabilityCompletedIcon from '../../../../../assets/CompleteIconGreen.svg';
import ExplainabilityHeaderIcon from '../../../../../assets/explainabilityHeaderIcon.svg';
import ExplainabilityExpandToTheRightIcon from '../../../../../assets/explainability expand to the right icon.svg';
import { themePrimaryMainColor } from "../../../../../theme"

type TSelectedExplainabilityHeader = {
    index: number,
    messageUuid: string
} | null
interface IProps {
    message: IMessage,
    isWaitingForAiResponse: boolean,
    isStreaming: boolean,
    setIsStreaming: (value: boolean) => void,
    onClickOpenInSidePanel: (metaData:IAIresponseMetaData, messageUuid: string) => void,
    isWorkModeActive: boolean, 
    isLastMessage: boolean,
    isPreviewWindowOpen: boolean,
    // setChatMessages: React.Dispatch<React.SetStateAction<IMessage[]>>,
    // pushPageInModelRecommendationStage: (metaData:IAIresponseMetaData, messageUuid: string, stage: string)=>void
    setStatesOnClickingExplainability: () => void;
    selectedExplainabilityHeader: TSelectedExplainabilityHeader;
    setSelectedExplainabilityHeader: React.Dispatch<React.SetStateAction<TSelectedExplainabilityHeader>>;
}

const useStyles = makeStyles((theme) => ({
    message: {
        maxWidth: '70%',
        padding: '10px',
        borderRadius: '10px',
        boxShadow: '0px 1px 3px rgba(0, 0, 0, 0.2)',
        // wordWrap: 'break-word' as 'break-word',
    },
    markdownContent: {
        textAlign: 'left',
        margin: 0,
        color: "#475569",
        fontFamily: "Inter",
        fontSize: "14px",
        fontStyle: "normal",
        fontWeight: 400,
        lineHeight: "normal",       
    },
    OpenInNewButton: {
        border: '1px solid #1E293B',
        background: '#FFFFFF',
        color: '#1E293B',
        textTransform: 'capitalize',
        alignSelf: 'flex-start',
        marginTop: "8px",
        '&:hover': {
            background: '#1E293B',
            border: '1px solid #1E293B',
            color: '#FFFFFF',
        },
        '&:focus': {
            background: '#1E293B',
            border: '1px solid #1E293B',
            color: '#FFFFFF',
        },

    },
    threeDotLoader: {
        display: 'inline-flex',
        justifyContent: 'end',
        alignItems: 'center',
        marginLeft: '8px',
        gap: '4px',
        '& span': {
            width: '6px',
            height: '6px',
            backgroundColor: 'currentColor',
            borderRadius: '50%',
            animation: '$bounce 1.4s infinite ease-in-out both',
        },
        '& span:nth-child(1)': {
            animationDelay: '-0.32s',
        },
        '& span:nth-child(2)': {
            animationDelay: '-0.16s',
        },
    },
    '@keyframes bounce': {
        '0%, 80%, 100%': {
            transform: 'scale(0)',
        },
        '40%': {
            transform: 'scale(1)',
        },
    },
}))

const ChatMessage = forwardRef<HTMLDivElement, IProps>((props, ref) => {
    const classes = useStyles();
    const chatContext = useContext(ChatContext);
    const { workModePageStack, setWorkModePageStack, pushPageInWorkModeStack } = chatContext;
    const currentPageData = workModePageStack.length > 0 ? workModePageStack[workModePageStack.length - 1] : null;
    const { userEmail } = useAuth();
    const CTAButtonRef = useRef<HTMLButtonElement | null>(null);
    const [isCTAButtonHovered, setIsCTAButtonHovered] = useState(false);
    const [isCTAButtonFocused, setIsCTAButtonFocused] = useState(false);
    
    
    const { message, isWaitingForAiResponse, isStreaming, onClickOpenInSidePanel, isWorkModeActive, isLastMessage, /*setChatMessages, pushPageInModelRecommendationStage*/ } = props;
    const isPageOpenInSideWindowCorrespondsToCurrentMessage = props.isPreviewWindowOpen && currentPageData?.messageUuid === message.messageUuid;
    const isLightIcon = isCTAButtonHovered || isCTAButtonFocused || isPageOpenInSideWindowCorrespondsToCurrentMessage;

    const [openAccordions, setOpenAccordions] = useState([]);
    const [expandedResponses, setExpandedResponses] = useState<number[]>([]);
    const toggleExpandedResponse = (index: number) => {
        setExpandedResponses((prev) =>
          prev.includes(index) ? prev.filter((i) => i !== index) : [...prev, index]
        );
      };

    const [selectedResponseData, setSelectedResponseData] = useState<{
        header_message: string;
        responseFrom: string;
        responsedata: string
    } | undefined>();
    const [isWorkflowAccordionExpanded, setIsWorkflowAccordionExpanded] = useState<boolean>(true);

    const selectedHeaderMessage = props.selectedExplainabilityHeader?.messageUuid === message.messageUuid ? props.selectedExplainabilityHeader?.index : null;


    const setSelectedHeaderMessage = (index: number | null) => {
        // console.trace("called this function with index value " + index);
        if (index === null && selectedHeaderMessage) {
            props.setSelectedExplainabilityHeader(null);
        }
        else if(index !== null) {
            props.setSelectedExplainabilityHeader({
                index: index,
                messageUuid: message.messageUuid,
            })
        }
    }

    const clearExplainabilityPagesFromWorkModeStack = () => {
        if(workModePageStack.find(page => page.pageType === 'explainability_header_content_page')){
            setWorkModePageStack(prev => {
                return prev.filter(page => page.pageType !== 'explainability_header_content_page');
            })
        }
    }

      
    const cleanedUserMessage = (message: string) => {
        // hide the section wrapped between triple backticks and return remaining message
        const start = message.indexOf('```modelInfo');
        const end = message.lastIndexOf('```');
        if (start === -1 || end === -1) {
            return message;
        }
        else {
            return message.slice(0, start);
        }
    }

    useEffect(() => {
        let timer: NodeJS.Timeout | null = null;
    
        if (
            !isStreaming &&
            CTAButtonRef.current && // Ensure CTAButtonRef.current is valid
            // !isWorkModeActive &&
            !props.isPreviewWindowOpen &&
            // !hasAutoClicked 
            message?.shouldAutoClick
            // (message?.metaData?.pageType === 'model_information_page' || message?.metaData?.pageType === 'testing_data')
        ) {
            // Delay the click to allow state updates
            // console.log('ranger insideuseeff of chatmsg',hasAutoClicked)
            timer = setTimeout(() => {
                
                CTAButtonRef.current?.click();
                CTAButtonRef?.current?.focus()
                // setHasAutoClicked(true); // Mark the auto-click as completed
                message.shouldAutoClick=false
            }, 50); // Small debounce delay
        }
    
        return () => {
            if (timer) clearTimeout(timer); // Clean up the timer on re-renders or unmount
        };
    }, [isStreaming, isWorkModeActive, message]);

    useEffect(() => {
        if(selectedResponseData && selectedHeaderMessage !== null){
            clearExplainabilityPagesFromWorkModeStack();
            
            pushPageInWorkModeStack({
                messageUuid: '',
                pageType: 'explainability_header_content_page',
                metadata: {
                    header_message: selectedResponseData.header_message,
                    responseFrom: selectedResponseData.responseFrom,
                    responsedata: selectedResponseData.responsedata,
                    pageTitle: selectedResponseData.header_message,
                }
            });
            props.setStatesOnClickingExplainability();
        }
    }, [props.selectedExplainabilityHeader, selectedHeaderMessage, selectedResponseData])

    const lastPageInWorkModeStack = workModePageStack[workModePageStack.length - 1];


    // mimic the streaming in rhs window for explainability
    useEffect(() => {
        if(lastPageInWorkModeStack && lastPageInWorkModeStack.pageType === 'explainability_header_content_page' && selectedHeaderMessage !== null){
            setWorkModePageStack(prev => {
                // update the content in last message
                const lastIndex = prev.length - 1;
                //@ts-ignore
                prev[lastIndex].metadata.responsedata = message.explainabilityData?.orchestratorAndAgentsMessages?.[selectedHeaderMessage]?.responsedata;
                //@ts-ignore
                prev[lastIndex].metadata.pageTitle = message.explainabilityData?.orchestratorAndAgentsMessages?.[selectedHeaderMessage]?.header_message;
                return prev;
            })
        }
    }, [message.explainabilityData, selectedHeaderMessage, setWorkModePageStack, lastPageInWorkModeStack])


    const onClickCtaButtonInMessage = (message: IMessage) => {
        // console.log("called this function")
        clearExplainabilityPagesFromWorkModeStack();
        props.setSelectedExplainabilityHeader(null);
        
        //@ts-ignore
        if(!(isLastMessage && isStreaming)){onClickOpenInSidePanel(message.metaData, message.messageUuid)}
    }


    const isShowingAiFinalResponseToUser: boolean = (!message?.isThinkingBreak && message?.isOrchestrator && (message?.hasStreamingEnded || !message?.hasStreamingEnded))
        ? true
        : false;
    
    const shouldShowThreeDotLoader: boolean = !message.text.trim()
    // const formatTime = (date: Date) => {
    //     const hours = date.getHours();
    //     const minutes = date.getMinutes();
    //     const ampm = hours >= 12 ? 'PM' : 'AM';
    //     const formattedHours = hours % 12 || 12; // Convert to 12-hour format
    //     const formattedMinutes = minutes < 10 ? `0${minutes}` : minutes;
    //     return `${formattedHours}:${formattedMinutes} ${ampm}`;
    // };
    
    // const handlePrimaryBtnClick = async(primaryBtn : IFeedbackBtn) => {
    //     if(!(isLastMessage && !message.additionalMetadata?.primaryButtons.find(btn=>btn.clicked))) return
    //     let lastMessage:IMessage, newMessage:IMessage;
    //     setChatMessages(prev=>{
    //         const updatedMessages: IMessage[] = [...prev]
    //         lastMessage = {...updatedMessages[updatedMessages.length-1]}
    //         lastMessage.additionalMetadata?.primaryButtons.map(currBtn=>{
    //             if(currBtn.type===primaryBtn.type) currBtn.clicked = true;
    //             else currBtn.disabled = true;
    //             return currBtn;
    //         })
    //         updatedMessages[updatedMessages.length-1] = lastMessage

    //         if(primaryBtn.buttonText==='Yes') {
    //             if(lastMessage.additionalMetadata) lastMessage.additionalMetadata.secondaryButtons = [
    //                 {buttonText: 'Clone this model into my project', clicked: false, type: 'clone', disabled: false},
    //                 // {buttonText: 'Bookmark this model', clicked: false, type: 'bookmark', disabled: false},
    //                 {buttonText: 'Explore other suggested models', clicked: false, type: 'explore', disabled: false}
    //             ]
    //             updatedMessages[updatedMessages.length-1] = lastMessage
    //         }
    //         else if(primaryBtn.buttonText==='No') {
    //             newMessage = {
    //                 text: 'Apologies for the unsatisfactory result. Could you please share your feedback? This will help me make better suggestions moving forward.', sender: 'assistant', timestamp: formatTime(new Date()), 
    //                 timeInSecs: Date.now(), messageUuid: generateUniqueId(), isOrchestrator: true, hasStreamingEnded: true,
    //                 metaData: message.metaData, additionalMetadata: {scenario: 'user feedback message', primaryButtons:[], secondaryButtons:[]}
    //             }
    //             updatedMessages.push(newMessage)
    //         }
            
    //         return updatedMessages
    //     })
    //     try {
    //         await Promise.all([
    //             updateOrCreateNewMessage({
    //                 role: 'assistant',
    //                 //@ts-ignore
    //                 id: 'requestId-'+lastMessage.messageUuid,
    //                 //@ts-ignore
    //                 time: lastMessage.timeInSecs,
    //                 //@ts-ignore
    //                 content: lastMessage.text,
    //                 //@ts-ignore
    //                 additionalMetadata: lastMessage.additionalMetadata
    //             }),
    //             updateOrCreateNewMessage({
    //                 role: 'assistant',
    //                 //@ts-ignore
    //                 id: 'requestId-'+newMessage.messageUuid,
    //                 //@ts-ignore
    //                 time: newMessage.timeInSecs,
    //                 //@ts-ignore
    //                 content: newMessage.text,
    //                 //@ts-ignore
    //                 additionalMetadata: newMessage.additionalMetadata
    //             })
    //         ])
    //     } catch (error) {
    //         console.error(error)
    //     }
    // };

    // const handleSecondaryBtnClick = async(secondaryBtn : IFeedbackBtn)=> {
    //     if(!(isLastMessage && !message.additionalMetadata?.secondaryButtons?.find(btn=>btn.clicked) && secondaryBtn.type!=='bookmark')) return
    //     let lastMessage:IMessage;
    //     setChatMessages(prev=>{
    //         const updatedMessages: IMessage[] = [...prev]
    //         lastMessage = {...updatedMessages[updatedMessages.length-1]}
    //         lastMessage.additionalMetadata?.secondaryButtons?.map(currBtn=>{
    //             if(currBtn.type===secondaryBtn.type) currBtn.clicked = true;
    //             else currBtn.disabled = true;
    //             return currBtn;
    //         })
    //         updatedMessages[updatedMessages.length-1] = lastMessage

    //         switch (secondaryBtn.type) {
    //             case 'clone':
    //                 // @ts-ignore
    //                 pushPageInModelRecommendationStage(message.metaData, message.messageUuid, 'model_cloning_page')
    //                 break;
    //             case 'bookmark':
    //                 break;
    //             case 'explore':
    //                 // @ts-ignore
    //                 pushPageInModelRecommendationStage(message.metaData, message.messageUuid, 'model_information_page')
    //                 break;
    //             default:
    //                 break;
    //         }

    //         return updatedMessages
    //     })
    //     try {
    //         await updateOrCreateNewMessage({
    //             role: 'assistant',
    //             //@ts-ignore
    //             id: 'requestId-'+lastMessage.messageUuid,
    //             //@ts-ignore
    //             time: lastMessage.timeInSecs,
    //             //@ts-ignore
    //             content: lastMessage.text,
    //             //@ts-ignore
    //             additionalMetadata: lastMessage.additionalMetadata
    //         })
    //     } catch (error) {
    //         console.error(error)
    //     }
    // }

    return (
        <div
            style={{
                alignSelf: 'flex-start',
                maxWidth: '100%',
                width: '100%',
            }}
            // ref={isLastMessage ? ref : null}
        >
            {
                message.sender === 'user' && (
                    <Box display="flex" style={{ gap: "12px" }}>
                        <Avatar style={{ width: '30px', height: '30px' }}> {userEmail[0]?.toUpperCase()} </Avatar>
                        <Box display="flex" flexDirection="column" alignItems="start" style={{ gap: '6px' }}>
                            <Box display="flex" alignItems="center" style={{ fontSize: '0.75rem', color: '#888', textAlign: 'right', gap: '12px' }}>
                                <Typography variant='body2' style={{ color: '#1E293B', fontWeight: 700 }}>Me</Typography>
                                {message.timestamp}
                            </Box>
                            <Box className={classes.message} style={{ backgroundColor: '#EFEFEF', maxWidth: '100%', textAlign: 'left' }}>
                                {
                                    cleanedUserMessage(message.text)
                                }
                            </Box>
                        </Box>
                    </Box>

                )
            }
            {message.sender === 'assistant' &&
                (
                    <Box display="flex" style={{ gap: "12px" }}>
                        <Avatar src={AIProfileIcon} alt="User Icon" style={{ width: '30px', height: '30px' }} />
                        <Box display="flex" flexDirection="column" alignItems="start" style={{ gap: '6px', flexGrow: 1, width: '90%' }}>
                            {
                                // isWaitingForAiResponse ?
                                //     <Box display="flex" flexDirection="column" alignItems="start" style={{ flexGrow: 1, width: '100%' }}>
                                //         {/* <Skeleton variant="text" width="10%" height={30} animation="wave" style={{ marginBottom: '-6px' }} />
                                //         <Skeleton variant="text" width="35%" height={30} animation="wave" style={{ marginBottom: '-6px' }} />
                                //         <Skeleton variant="text" width="20%" height={30} animation="wave" style={{ marginBottom: '-6px' }} /> */}
                                //         Crafting the best response for you... Hang tight!
                                //     </Box>
                                //     : 
                                    <>
                                        <Box display="flex" alignItems="center" style={{ fontSize: '0.75rem', color: '#888', textAlign: 'right', gap: '12px' }}>
                                            <Typography variant='body2' style={{ color: '#1E293B', fontWeight: 700 }}>RLEF</Typography>
                                            {message.timestamp}
                                        </Box>
                                        <Box className={classes.message} display="flex" flexDirection="column" style={{ gap: "8px", background: 'linear-gradient(93deg, #D8F1F2 0%, #F2E5F8 100%)', maxWidth: '100%' }}>

                                        {
                                            message.explainabilityData && message.explainabilityData?.orchestratorAndAgentsMessages?.length > 0 &&
                                            <div
                                                style={{
                                                    display: "flex",
                                                    flexDirection: "column",
                                                }}
                                            >
                                                <MinimizedThinkingStepsForParallelTools
                                                    thinkingBulbIcon={thinkingBulbIcon}
                                                    isLargeResponse={true}
                                                    // @ts-ignore
                                                    completeIcon={ExplainabilityCompletedIcon}
                                                    workFlowIcon={ExplainabilityHeaderIcon}
                                                    arrowDownIcon={arrowDownIcon}
                                                    redirectIcon={ExplainabilityExpandToTheRightIcon}
                                                    isStreaming={isWorkflowAccordionExpanded}
                                                    setIsStreaming={setIsWorkflowAccordionExpanded}
                                                    openAccordions={openAccordions}
                                                    setOpenAccordions={setOpenAccordions}
                                                    expandedResponses={expandedResponses}
                                                    toggleExpandedResponse={toggleExpandedResponse}
                                                    orchestratorAndAgentsMessages={message.explainabilityData?.orchestratorAndAgentsMessages}
                                                    headerStyle={{ backgroundColor: "#f3f4f6", padding: "10px" }}
                                                    iconStyle={{ height: "18px", width: "18px" }}
                                                    arrowIconStyle={{ height: "14px", width: "14px" }}
                                                    headerMessageStyle={{ color: themePrimaryMainColor, fontSize: "14px", fontWeight: 'normal' }}
                                                    containerClassName="custom-container"
                                                    viewMoreAccordian={{ border: "1px solid #ccc" }}
                                                    viewMoreExpand={{ color: "#b91c1c" }}
                                                    // finalResponse={{ border: "1px solid red" }}
                                                    isSelectedHeaderStyleProps={{fontWeight: 'bold'}}
                                                    selectedHeaderMessage={selectedHeaderMessage}
                                                    setSelectedHeaderMessage={setSelectedHeaderMessage}
                                                    // @ts-ignore
                                                    selectedResponseData={selectedResponseData}
                                                    setSelectedResponseData={setSelectedResponseData}
                                                    isAccordianExpanded={false}
                                                    redirectIconStyle={{width: 'auto', height: 'auto', paddingRight: 0}}
                                                />                                                
                                                {
                                                    false &&
                                                    message.explainabilityData?.orchestratorAndAgentsMessages?.map((explainability, index) => {
                                                        return (
                                                            <div
                                                                className={css.explainabilityParent}
                                                                style={{
                                                                    width: "100%"
                                                                }}
                                                            >
                                                                <ThinkingResponse
                                                                    responseFrom={explainability.responseFrom}  // string
                                                                    responseMessage={explainability.responsedata} // string
                                                                    header_message={explainability.header_message} // string
                                                                    finalResponseStatus={explainability.finalResponseStatus} // string
                                                                    thinkingBulbIcon={thinkingBulbIcon} // string
                                                                    arrowDownIcon={arrowDownIcon} // string
                                                                    containerClassName={`${css.ThinkingComponentHeaderBackground}`}
                                                                    headerClassName={`${css.ThinkingComponentHeaderBackground} ${css.ThinkingComponentHeader}`}
                                                                    headerMessageStyle={{
                                                                        // background: "linear-gradient(91deg, #76B0B6 -6.9%, #006974 113.98%)",
                                                                        // WebkitBackgroundClip: "text",
                                                                        // WebkitTextFillColor: "transparent",
                                                                        color: "rgb(0 105 116 / 73%)",
                                                                        backgroundClip: "text",
                                                                        fontFamily: "Inter",
                                                                        fontSize: "12px",
                                                                        fontWeight: 500,
                                                                        lineHeight: "normal",
                                                                        letterSpacing: "0.12px"
                                                                    }}
                                                                    customCircleStyle={{
                                                                        backgroundColor: "#008D9C",
                                                                        width: "9px",
                                                                        height: "9px"
                                                                    }}
                                                                    expandedContainerStyle={{
                                                                        borderLeftColor: "#008D9C"
                                                                    }}
                                                                    arrowIconStyle={{
                                                                        width: "10px",
                                                                        height: "10px"
                                                                    }}
                                                                />                                             
                                                            </div>
                                                        )
                                                    })
                                                }

                                            </div>
                                        }
                                            
                                            {/* <ReactMarkdown className={classes.markdownContent} components={{ p: ({ node, ...props }) => <p style={{ margin: 0, textAlign: 'left' }} {...props} /> }}>
                                                {message.text}
                                            </ReactMarkdown> */}
                                            {/* un comment the below code to see thinking response */}
                                        {/* {message?.orchestratorAndAgentsMessages &&
                                            message?.orchestratorAndAgentsMessages?.map((data: any, index: number) =>
                                            // arrayAccordian.map((data: any, index: number) =>
                                            (
                                                <ThinkingResponse
                                                    key={`${data.responseFrom}-${index}`}
                                                    header_message={data.header_message}
                                                    responseFrom={data.responseFrom}
                                                    responseMessage={data.responsedata}
                                                    finalResponseStatus={data.finalResponseStatus}
                                                />
                                            ))} */}
                                        <ReactMarkdown
                                            className={`
                                                ${classes.markdownContent}
                                                ${message.explainabilityData && message.explainabilityData?.orchestratorAndAgentsMessages?.length > 0 ? css.markDownContentWhenExplainabilityPresent : ""}
                                            `}
                                            components={{
                                                p: ({ node, ...props }) => (
                                                    <span style={{ margin: 0, textAlign: 'left', display: 'inline' }} {...props}>
                                                        {props.children}
                                                        {
                                                        isShowingAiFinalResponseToUser && !message.hasStreamingEnded &&
                                                        isLastMessage && isStreaming && (
                                                            // three bouncing balls
                                                            <span className={classes.threeDotLoader}>
                                                                { [...Array(3)].map((_, index) => <span key={index} />) }
                                                            </span>
                                                        )}
                                                    </span>
                                                ),
                                            }}
                                        >
                                            {
                                                isShowingAiFinalResponseToUser
                                                ? message.text 
                                                // + (!message.hasStreamingEnded ? "..." : "")
                                                 : ""
                                            }
                                            {/* {message.text} */}

                                        </ReactMarkdown>
                                            {
                                                shouldShowThreeDotLoader ? 
                                                    // three bouncing balls
                                                    <span className={classes.threeDotLoader} style={{justifyContent: 'flex-start', marginLeft: '0px'}} >
                                                        { [...Array(3)].map((_, index) => <span key={index} style={{color: '#475569'}} />) }
                                                    </span> 
                                                : null
                                            }

                                            {
                                                message.metaData &&
                                                message.hasStreamingEnded &&
                                                // !message.additionalMetadata &&
                                                // @ts-ignore
                                                <Button variant='outlined' size="small" onClick={ () => onClickCtaButtonInMessage(message) } className={classes.OpenInNewButton} 
                                                style={isPageOpenInSideWindowCorrespondsToCurrentMessage || isLightIcon ? 
                                                    {backgroundColor: "#1E293B", color: 'white' }
                                                    : {backgroundColor: 'white', color: '#1E293B'}
                                                }
                                                endIcon={ isLastMessage && isStreaming?'':message?.metaData?.pageType === 'model_information_page'|| message?.metaData?.pageType === 'testing_data'|| message?.metaData?.pageType === 'summary_page' || message?.metaData?.pageType === 'test_drive' ? '': <OpenInNewIcon />} 
                                                startIcon={
                                                    isLastMessage && isStreaming? <CircularProgress size={16} style={{ color: 'currentColor' }} />
                                                    :message?.metaData?.pageType === 'model_information_page'|| message?.metaData?.pageType === 'testing_data' ? <ListIcon />
                                                    : message?.metaData?.pageType === 'summary_page' ? isLightIcon
                                                    ? <ModelDescriptionLightIcon />
                                                    : <ModelDescriptionDarkIcon /> 
                                                    : message?.metaData?.pageType === 'test_drive' ? 
                                                        isLightIcon ? <TestDriveLight /> : <TestDriveDark />
                                                    :''} 
                                                ref={ isLastMessage ? CTAButtonRef : null }
                                                onMouseEnter={() => setIsCTAButtonHovered(true)}
                                                onMouseLeave={() => setIsCTAButtonHovered(false)}
                                                onFocus={() => setIsCTAButtonFocused(true)}
                                                onBlur={() => setIsCTAButtonFocused(false)} 
                                                disableRipple>
                                                    {
                                                        message?.metaData?.pageType === 'model_information_page' ? message?.metaData?.button_text || 'Search results'
                                                        : message?.metaData?.pageType === 'summary_page' ? 'Model summary'
                                                        : message?.metaData?.pageType === 'test_drive' ? 'Test Drive'
                                                        : message?.metaData?.pageType === 'model_cloning_page' ? 'Clone'
                                                        : message?.metaData?.button_text || "View more"
                                                    }
                                                </Button>
                                            }
                                            {/* {
                                                message.additionalMetadata && 
                                                <div style={{display: 'flex', alignItems: 'center', gap:'10px', width: 'inherit', maxWidth:'450px'}}>
                                                    {
                                                        message.additionalMetadata.primaryButtons.map(primaryBtn=>
                                                            <RoundedButton isClicked={primaryBtn.clicked} key={primaryBtn.buttonText} onClick={()=>handlePrimaryBtnClick(primaryBtn)} disabled={primaryBtn.disabled}>
                                                                {primaryBtn.buttonText}
                                                            </RoundedButton>
                                                        )
                                                    }
                                                </div>
                                            } */}

                                        </Box>
                                        {/* {
                                            message.additionalMetadata?.secondaryButtons?.length !== 0 &&
                                            <div style={{width: '100%', maxWidth:'450px', display: 'flex', flexDirection: 'column', gap: '10px', marginTop: '10px'}}>
                                                {
                                                    message.additionalMetadata?.secondaryButtons?.map(secondaryBtn=>
                                                        <RoundedButton isClicked={secondaryBtn.clicked} key={secondaryBtn.buttonText} onClick={()=>handleSecondaryBtnClick(secondaryBtn)} disabled={secondaryBtn.disabled}>
                                                            {secondaryBtn.buttonText}
                                                        </RoundedButton>
                                                    )
                                                }
                                            </div>
                                        } */}
                                    </>
                            }
                        </Box>
                    </Box>
                )}
            <div style={{height: 8}} ref={isLastMessage ? ref : null}/>
        </div>
    )
})

export default ChatMessage
export {useStyles}
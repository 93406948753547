import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  makeStyles,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
} from "@material-ui/core";
import ReportProblemIcon from "@material-ui/icons/ReportProblem";
import { IReduxState } from "../../../../../../../../../../../common";
import { resetDelectCollectionErrorState } from "../../../../../../../../../../../store/actions";
import { connect, ConnectedProps } from "react-redux";

const useStyles = makeStyles((theme) => ({
  root: {
    "& > *": {
      display: "flex",
      alignItems: "center",
    },
    "& svg": {
      margin: "auto 10px",
      marginLeft: 0,
    },
  },
  boldText: {
    fontWeight: "bold",
  },
  dialogContent: {
    maxHeight: "350px",
    overflowY: "auto",
  },
  tableContainer: {
    minWidth: "500px",
  },
}));

const DeleteCollectionErrorPopup = (props: TPropsFromRedux) => {
  const classes = useStyles();

  return (
    <Dialog
      open={props.deletecCollectionErrorMessages.length > 0}
      onClose={() => props.resetDelectCollectionErrorState()}
      aria-labelledby="delete-collection-alert-popup"
      aria-describedby="delete-collection-not-successful"
    >
      <DialogTitle
        id="delete-collection-alert"
        classes={{ root: classes.root }}
      >
        <ReportProblemIcon color={"error"} />
        {"Error deleting below dataset collections"}
      </DialogTitle>
      <DialogContent classes={{ root: classes.dialogContent }}>
        <TableContainer
          classes={{ root: classes.tableContainer }}
          component={Paper}
        >
          <Table aria-label="simple table">
            <TableHead>
              <TableRow>
                <TableCell classes={{ root: classes.boldText }}>
                  Collection
                </TableCell>
                <TableCell classes={{ root: classes.boldText }}>
                  Linked To
                </TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {props.deletecCollectionErrorMessages.map((row, index) => {
                return (
                  <TableRow key={row.dataSetCollection + index}>
                    <TableCell component="th" scope="row">
                      {row.dataSetCollection}
                    </TableCell>
                    <TableCell>
                      {row.linkedToCollections.modelCollection &&
                        row.linkedToCollections.modelCollection?.length > 0 && (
                          <span>
                            <strong>Model Versions:</strong>&nbsp;
                            {row.linkedToCollections.modelCollection.join(", ")}
                            <br></br>
                          </span>
                        )}
                      {row.linkedToCollections.testCollection &&
                        row.linkedToCollections.testCollection?.length > 0 && (
                          <span>
                            <strong>Test Versions:</strong>&nbsp;
                            {row.linkedToCollections.testCollection.join(", ")}
                          </span>
                        )}
                    </TableCell>
                  </TableRow>
                );
              })}
            </TableBody>
          </Table>
        </TableContainer>
      </DialogContent>
      <DialogActions>
        <Button
          onClick={() => props.resetDelectCollectionErrorState()}
          color="primary"
          autoFocus
        >
          OK
        </Button>
      </DialogActions>
    </Dialog>
  );
};

const mapProps = (state: IReduxState) => {
  return {
    deletecCollectionErrorMessages:
      state.collection.errorMessagesDueToDataSetLinkedSomewhereElse,
  };
};

const connector = connect(mapProps, {
  resetDelectCollectionErrorState,
});

export type TPropsFromRedux = ConnectedProps<typeof connector>;

export default connector(DeleteCollectionErrorPopup);

/**
 * Socket events names
 */
export const SOCKET_EVENT_CO_PILOT_RESOURCE_CREATED = "SOCKET_EVENT_CO_PILOT_RESOURCE_CREATED";
export const SOCKET_EVENT_CO_PILOT_RESOURCES_DELETED = "SOCKET_EVENT_CO_PILOT_RESOURCES_DELETED";
export const SOCKET_EVENT_CO_PILOT_RESOURCES_UPDATED = "SOCKET_EVENT_CO_PILOT_RESOURCES_UPDATED";

/** ---------------------------------------------------- */


/**
 * Socket event data types
 */
export type SOCKET_EVENT_CO_PILOT_RESOURCE_CREATED_DATA = {
  resourceId: string,
  projectId: string,
  coPilotId: string
}

export type SOCKET_EVENT_RESOURCE_CREATED_DATA = {
  tag: string,
  deviceId: string
}

export type SOCKET_EVENT_CO_PILOT_RESOURCES_UPDATED_DATA = {
  resourceIds: string[],
  status?: string,
  label?: string,
  confidenceScore?: number;
  remarks?: {
		text: string;
	};
  userTimeLeftInSecondsToTakeAction?: number,
  resourceTimerEndAt?: string,
  copilotId?: string;
  projectId?: string;
  oldCoPilotResourceStatusBeforeUpdate?: string;
}
export type SOCKET_EVENT_CO_PILOT_RESOURCES_DELETED_DATA = {
  resourceIds: string[]
}

/** ---------------------------------------------------- */
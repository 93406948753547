import React, { useEffect, useRef, useState } from "react";

import { Button, IconButton, makeStyles } from "@material-ui/core";
import { IModelType, IReduxState } from "../../../../common";
import { Fullscreen } from "@material-ui/icons";
import {
  IData, ResourceImageKeyPoint,
} from "../../../../common/constants/interfaces/data";
import { updateResourceImageGroupAnnotationsInDB } from "../../../../store/actions/dataActions";
import { connect, ConnectedProps } from "react-redux";
import { InputFieldState } from "../../../../common/constants/interfaces/inputFieldState";
import { CoordinatesList } from "../../features/projects/features/models/features/model/features/data/components/CoordinatesList";

interface IImagePlotter extends TPropsFromRedux {
  image?: string;
  draw?: string;
  label?: string;
  modelType: IModelType;
  canvasId: string;
  resourceData: IData;
  scenario: "sideDrawer" | "dialog";
  circleRadius: number;
  imageCoordinatesState: InputFieldState<ImageCoordinate[]>;
  setImageCoordinatesState: React.Dispatch<
    React.SetStateAction<InputFieldState<ImageCoordinate[]>>
  >;
  setIsImageKeyPointsEditDialogOpen: React.Dispatch<
    React.SetStateAction<boolean>
  >;
  imageDifference: number;
  setImageDifference: React.Dispatch<React.SetStateAction<number>>;
  defaultImageDifference: number;
}

const useStyles = makeStyles((theme) => ({
  canvas: {
    // backgroundSize: 'contain',
    // backgroundRepeat: 'no-repeat',
    // marginLeft: '11px',
    // marginTop: '20px'
  },
  coordinatesSectionWhenDialogScenario: {},
  coordinatesSectionWhenSideDrawerScenario: {
    marginTop: "20px",
  },
  imagePlotterRootWhenDialogScenario: {
    display: 'flex',
    justifyContent: 'space-evenly'
  },
  canvasParentWhenSideDrawerScenario: {
    marginLeft: "47px",
    marginTop: "20px",
    display: "flex",
    alignItems: "center",
  },
  coordinatesListContainerWhenSideDrawerScenario: {
    maxHeight: "150px",
    overflow: "auto",
    "& td": {
        width: "44px"
    }      
  },
  coordinatesListContainerWhenDialogScenario: {
    // maxHeight: "150px",
    overflow: "auto",
    "& td": {
        width: "44px"
    }      
  },
}));

// const defaultCanvasWidth = 360;
// const defaultCanvasHeight = 242;
// const defaultCanvasWidth = 300;
// const defaultCanvasHeight = 300;
const defaultCanvasWidth = 280;
const defaultCanvasHeight = 280;
const defaultCanvasWidthWhenDialogScenario = 500;
const defaultCanvasHeightWhenDialogScenario = 500;

export type ImageCoordinate = ResourceImageKeyPoint & {
  colorCode: string;
};

const ImagePlotter = (props: IImagePlotter) => {
  const {
    image = "",
    // draw = "",
    // label = "",
    imageCoordinatesState,
    setImageCoordinatesState,
    scenario,
    circleRadius,
    imageDifference,
    setImageDifference,
    defaultImageDifference
  } = props;

  const classes = useStyles();

  const canvasRef = useRef<HTMLCanvasElement>(null);

  const [hasImageLoaded, setHasImageLoaded] = useState(false);

  const imageCoordinates = imageCoordinatesState.value;

  const [canvasWidth, setCanvasWidth] = useState(
    props.scenario === "sideDrawer"
      ? defaultCanvasWidth
      : defaultCanvasWidthWhenDialogScenario
  );
  const [canvasHeight, setCanvasHeight] = useState(
    props.scenario === "sideDrawer"
      ? defaultCanvasHeight
      : defaultCanvasHeightWhenDialogScenario
  );

  // @ts-ignore
  function setImageInCanvas(context) {
    let base_image = new Image();
    base_image.src = image;

    setHasImageLoaded(false);

    base_image.onload = function () {
      setHasImageLoaded(true);

      let imageWidth = base_image.width;
      let imageHeight = base_image.height;

      let differenceBetweenCanvasAndImageToSet: number = defaultImageDifference;
      let canvasHeightToSet: number;
      let canvasWidthToSet: number;

      if (imageWidth > imageHeight) {
        differenceBetweenCanvasAndImageToSet =
          imageWidth /
          (scenario === "sideDrawer"
            ? defaultCanvasWidth
            : defaultCanvasWidthWhenDialogScenario);
        canvasHeightToSet = imageHeight / differenceBetweenCanvasAndImageToSet;
        canvasWidthToSet =
          scenario === "sideDrawer"
            ? defaultCanvasWidth
            : defaultCanvasWidthWhenDialogScenario;
      } else if (imageHeight > imageWidth) {
        differenceBetweenCanvasAndImageToSet =
          imageHeight /
          (scenario === "sideDrawer"
            ? defaultCanvasHeight
            : defaultCanvasHeightWhenDialogScenario);
        canvasWidthToSet = imageWidth / differenceBetweenCanvasAndImageToSet;
        canvasHeightToSet =
          scenario === "sideDrawer"
            ? defaultCanvasHeight
            : defaultCanvasHeightWhenDialogScenario;
      }
      // @ts-ignore
      if (differenceBetweenCanvasAndImageToSet) {
        setImageDifference(differenceBetweenCanvasAndImageToSet);
      }

      // @ts-ignore
      if (canvasHeightToSet) {
        setCanvasHeight(canvasHeightToSet);
      }
      // @ts-ignore
      if (canvasWidthToSet) {
        setCanvasWidth(canvasWidthToSet);
      }
      // @ts-ignore
      context.canvas.width =
      // @ts-ignore
        canvasWidthToSet ||
        (scenario === "sideDrawer"
          ? defaultCanvasWidth
          : defaultCanvasWidthWhenDialogScenario);
      // @ts-ignore
      context.canvas.height =
      // @ts-ignore
        canvasHeightToSet ||
        (scenario === "sideDrawer"
          ? defaultCanvasHeight
          : defaultCanvasHeightWhenDialogScenario);

      /**
       * setting coordinates in image which are already saved in DB
       */
      if (imageCoordinates?.length > 0) {
        for (const resourceImageKeyPointSavedInDB of imageCoordinates) {
          context.fillStyle = resourceImageKeyPointSavedInDB.colorCode;
          context.beginPath();
          context.arc(
            resourceImageKeyPointSavedInDB.xCoordinate /
              differenceBetweenCanvasAndImageToSet,
            resourceImageKeyPointSavedInDB.yCoordinate /
              differenceBetweenCanvasAndImageToSet,
            circleRadius,
            0,
            2 * Math.PI
          );
          context.fill();
        }
        // context.closePath();
      }
    };
  }

  useEffect(() => {
    // setImageCoordinatesState({
    //     value: [],
    //     isDirty: false,
    //     isTouched: false
    // });
    clearImageKeyPointsInCanvas();
  }, [image]);

  useEffect(() => {
    console.log("hi ola");
    const canvas = canvasRef?.current;

    // @ts-ignore
    let context = canvas?.getContext("2d");
    setImageInCanvas(context);
    // return ()=>{
    //     clearImageKeyPointsInCanvas();
    //     // setImageCoordinatesState({
    //     //     value: [],
    //     //     isDirty: false,
    //     //     isTouched: false
    //     // });
    // }
  }, [image, imageCoordinates]);

  // const onload2promise = <T extends { onload: any, onerror: any }>(obj: T): Promise<T> => {
  //     return new Promise((resolve, reject) => {
  //         obj.onload = () => resolve(obj);
  //         obj.onerror = reject;
  //     });
  // }

  // const fitToContainerAndSetImage = useCallback((canvas: HTMLCanvasElement, height: number) => {
  //     canvas.style.backgroundImage = `url(${image})`;
  //     canvas.style.width = '100%';
  //     canvas.style.height = `${height}px`;
  //     canvas.width = canvas.offsetWidth;
  //     canvas.height = canvas.offsetHeight;
  // }, [image]);

  // const plot = useCallback(async (canvas: HTMLCanvasElement, ctx: CanvasRenderingContext2D) => {
  //     const img: HTMLImageElement = new Image();
  //     img.src = image;
  //     const { width = 0, height = 0 } = await onload2promise(img);
  //     const newHeight = (368 * height / width);
  //     fitToContainerAndSetImage(canvas, newHeight);
  //     const res = draw.replaceAll("'", "\"");
  //     let point;
  //     try {
  //         point = JSON.parse(res).point;
  //     } catch (error) {
  //     }
  //     const color = label === '1' ? "#ff2626" : "#30EE37";
  //     for (let i = 0; i < point?.length; i += 2) {
  //         ctx.fillStyle = color; // Red color
  //         ctx.beginPath();
  //         ctx.arc((point[i] * 368) / width, (point[i + 1] * newHeight) / height, 1.5, 0, Math.PI * 2, true);
  //         ctx.fill();
  //     }
  // }, [fitToContainerAndSetImage, draw, image, label]);

  // useEffect(() => {
  //     const canvas = canvasRef?.current;
  //     const context = canvas?.getContext('2d');
  //     canvas && context && plot(canvas, context);
  // }, [plot])

  const lightColorCodes = [
    // "#FFCCCC",
    // "#FFE5CC",
    // "#FFFFCC",
    // "#E5FFCC",
    // "#CCFFCC",
    // "#99FFCC",
    // "#CCFFFF",
    // "#CCE5FF",
    // "#FFCCFF",
    // "#FFCCE5",
    // "#FFF8DC",
    // "#FFFF00"
    "#FF0000",
    "#FFC000",
    "#FFFC00",
    "#FF0000",
    "#00FFFF",
    "#FF0000",
  ];

  async function handleClearButtonClick() {
    clearImageKeyPointsInCanvas();
    await clearImageKeyPointsForThisResourceInDB(props);
    setImageCoordinatesState({
      value: [],
      isDirty: false,
      isTouched: false,
    });
  }

  function clearImageKeyPointsInCanvas() {
    let canvas = canvasRef.current;
    let context = canvas?.getContext("2d");
    context?.clearRect(0, 0, canvas?.width || 0, canvas?.height || 0);
  }

  function generateRandomLightColor() {
    // var letters = 'BCDEF'.split('');
    // var color = '#';
    // for (var i = 0; i < 6; i++ ) {
    //     color += letters[Math.floor(Math.random() * letters.length)];
    // }
    // return color;

    // return "hsl(" + 360 * Math.random() + ',' +
    // (25 + 70 * Math.random()) + '%,' +
    // (85 + 10 * Math.random()) + '%)'

    return lightColorCodes[Math.floor(Math.random() * lightColorCodes.length)];
  }

  return (
    <div
        className={`
        ${scenario === "dialog" ? classes.imagePlotterRootWhenDialogScenario : ""}
        `}
    >
      <div
        className={`
            ${scenario === 'sideDrawer' ? classes.canvasParentWhenSideDrawerScenario : ''}
        `}
        style={{
          width: `${
            scenario === "sideDrawer"
              ? defaultCanvasWidth
              : defaultCanvasWidthWhenDialogScenario
          }px`,
          height: `${
            scenario === "sideDrawer"
              ? defaultCanvasHeight
              : defaultCanvasHeightWhenDialogScenario
          }px`,
        }}
      >
        <canvas
          ref={canvasRef}
          id={props.canvasId}
          className={classes.canvas}
          style={{
            // display: "block",
            backgroundImage: `url(${hasImageLoaded ? image : ""})`,
            width: `${canvasWidth}px`,
            height: `${canvasHeight}px`,
            backgroundSize: `${canvasWidth}px ${canvasHeight}px`,
          }}
          onClick={(event) => {
            if (
                props.modelType !== "imageAnnotation" ||
                props.resourceData.status === "approved"
            ) {
              return;
            }

            let canvas = canvasRef.current;

            // @ts-ignore
            let context = canvas?.getContext("2d");

            // context.globalCompositeOperation = 'destination-out';

            // @ts-ignore
            // let x = event.pageX - document.getElementById("myCanvas")?.offsetLeft;
            // // @ts-ignore
            // let y = event.pageY - document.getElementById("myCanvas")?.offsetTop;

            let x =
            // @ts-ignore
              (event.clientX - canvas?.getBoundingClientRect().left) *
              imageDifference;
              let y =
              // @ts-ignore
              (event.clientY - canvas?.getBoundingClientRect().top) *
              imageDifference;

            // alert("X Coordinate: " + x + " Y Coordinate: " + y);

            const randomColor = generateRandomLightColor();

            // @ts-ignore
            context.fillStyle = randomColor;
            // context.fillRect(x, y, 10, 10);

            // @ts-ignore
            context.beginPath();
            // @ts-ignore
            context.arc(
              x / imageDifference,
              y / imageDifference,
              circleRadius,
              0,
              2 * Math.PI
            );
            // @ts-ignore
            context.fill();
            setImageCoordinatesState((oldState) => {
              const oldValue = oldState.value;
              const newValue: ImageCoordinate[] = JSON.parse(
                JSON.stringify(oldValue)
              );

              //   newValue.push(`${x},${y},${randomColor}`);
              newValue.push({
                xCoordinate: Math.floor(x),
                yCoordinate: Math.floor(y),
                colorCode: randomColor,
              });
              return {
                ...oldState,
                value: [...newValue],
                isDirty: true,
                isTouched: true,
              };
            });
          }}
        ></canvas>
      </div>

      {props.modelType === "imageAnnotation" && (
        <>
          {props.scenario === "sideDrawer" && props.resourceData.status !== 'approved' && (
            <div style={{ textAlign: "end" }}>
              <IconButton
                onClick={() => {
                  props.setIsImageKeyPointsEditDialogOpen(true);
                }}
                style={{ padding: 0 }}
              >
                <Fullscreen />
              </IconButton>
            </div>
          )}
          {
            <div
              className={`
                        ${
                          scenario === "sideDrawer"
                            ? classes.coordinatesSectionWhenSideDrawerScenario
                            : ""
                        }
                        ${
                          scenario === "dialog"
                            ? classes.coordinatesSectionWhenDialogScenario
                            : ""
                        }
                    `}
            >
                {
                    scenario === 'dialog' &&
                    <CoordinatesList 
                        canvasId={props.canvasId} 
                        scenario={"dialog"} 
                        imageCoordinatesState={imageCoordinatesState}
                        setImageCoordinatesState={setImageCoordinatesState}
                        imageDifference={props.imageDifference} circleRadius={props.circleRadius} 
                        shouldNotAllowEditing={props.resourceData.status==='approved' ? true : false}    
                    />
                }
                {
                    scenario === 'dialog' &&
                    <div style={{ display: "flex", marginTop: "8px" }}>
                        <Button
                        size="small"
                        color="primary"
                        variant="contained"
                        style={{
                            marginRight: "5px",
                            width: "50%",
                        }}
                        onClick={async () => {
                            // await props.updateResourceImageKeyPointsInDB({
                            // resourceId: props?.resourceData?._id.toString() || "",
                            // imageKeyPoints: imageCoordinates,
                            // });
                            setImageCoordinatesState((oldState) => {
                            return {
                                ...oldState,
                                isDirty: false,
                                isTouched: false,
                            };
                            });
                        }}
                        disabled={
                            props.resourceData?.areImageKeyPointsBeingUpdatedInUI ||
                            !imageCoordinatesState.isDirty
                            ? true
                            : false
                        }
                        >
                        Save
                        </Button>
                        <div></div>
                        <Button
                        size="small"
                        variant="contained"
                        style={{
                            backgroundColor: "white",
                            width: "50%",
                        }}
                        onClick={() => {
                            handleClearButtonClick();
                        }}
                        // disabled={
                        //     // props.resourceData?.areImageKeyPointsBeingUpdatedInUI ||
                        //     // (imageCoordinates.length === 0 &&
                        //     // props?.resourceData?.imageKeyPoints?.length === 0)
                        //     // ? true
                        //     // : false
                        // }
                        >
                        Clear
                        </Button>
                        {
                            scenario === 'dialog' &&
                            <Button 
                                size="small"
                                variant="contained"
                                style={{
                                    backgroundColor: "white",
                                    // width: "50%",
                                }}
                            onClick={()=>{
                                props.setIsImageKeyPointsEditDialogOpen(false);
                            }}>
                                Exit
                            </Button>
                        }
                    </div>
                }
            </div>
          }
        </>
      )}
    </div>
  );
};

// export default ImagePlotter;

const mapProps = (state: IReduxState) => {
  return {};
};

const connector = connect(mapProps, {
  updateResourceImageGroupAnnotationsInDB
});

export type TPropsFromRedux = ConnectedProps<typeof connector>;

export default connector(ImagePlotter);
async function clearImageKeyPointsForThisResourceInDB(props: IImagePlotter) {
  if (props.resourceData?._id) {
    // await props.updateResourceImageKeyPointsInDB({
    //   imageAnnotations: [],
    //   resourceId: props?.resourceData._id.toString(),
    // });
  }
}

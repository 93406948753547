import React, { useContext } from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { Button, ListGroupItem } from 'reactstrap';
import { Tooltip } from '@material-ui/core';
import { MdDelete } from 'react-icons/md';
import TwoDimensionalImageContext from '../../TwoDimensionalImage/twoDimensionalImageContext';
import OptionList from '../../OptionList/OptionList.jsx';
import './annotationItem.scss';

const AnnotationItem = ({
	className,
	itemData,
}) => {
  console.log('itemData', itemData)
	const twoDimensionalImageContext = useContext(TwoDimensionalImageContext);
	const {
		focusedName,
		onAnnotationClick,
		onAnnotationDeleteClick,
		rootOptionId,
		isViewOnlyMode
	} = twoDimensionalImageContext;
	const {
		name,
		selectedOptions,
		color,
		isClosed,
		confidenceScore
	} = itemData;
	const { t } = useTranslation('twoDimensionalImage');

	let rootClassName = `annotation-item${className ? ` ${className}` : ''}`;
	if (name !== focusedName) {
		return (
			<ListGroupItem className={ rootClassName } name={ name } onClick={ () => onAnnotationClick(name) } action>
				<div className='d-flex w-100 justify-content-between align-items-center'>
					<div
						style={{
							display: "flex",
							alignItems: "center",
							// gap: "16px"
							gap: "10px",
							width: "100%"
						}}
					>
						{selectedOptions.length > 0 ? `${selectedOptions[selectedOptions.length - 1].value}` : t('optionNotSelected') }
						<small className='pl-1' style={ { color: '#545454' } }><mark>{isClosed ? 'polygon' : 'line'}</mark></small>
						{
							(confidenceScore !== null && confidenceScore !== undefined) &&
							<Tooltip
								title={`Confidence Score: ${confidenceScore}`}
							>
								<div>{confidenceScore}</div>
							</Tooltip>
						}
					</div>
				</div>
			</ListGroupItem>
		);
	}

	rootClassName = `${rootClassName} annotation-item--highlight`;
	return (
		<ListGroupItem className={ rootClassName } name={ name } style={ { borderColor: color.replace(/,1\)/, ',.3)') } }>
			<div className='d-flex align-items-center'>
				<h5 className='annotation-item__title mr-auto' onClick={ () => onAnnotationClick(name) }>
					{selectedOptions.length > 0 ? `${selectedOptions[selectedOptions.length - 1].value}` : t('optionNotSelected') }
					<small className='pl-1' style={ { color: '#545454' } }><mark>{isClosed ? 'polygon' : 'line'}</mark></small>
				</h5>
				{
					!isViewOnlyMode &&
					<Button className='d-flex align-items-center annotation-item__delete-button' color='link' onClick={ () => { onAnnotationDeleteClick(name); } }>
						<MdDelete />
					</Button>
				}
			</div>
			<OptionList annotationName={ name } ancestorOptionIds={ [rootOptionId] } selectedOptions={ selectedOptions } />

			{
				confidenceScore !== undefined && confidenceScore !== null &&
				<div 
					style={{
						display: "flex",
						columnGap: "10px",
						marginTop: "10px"
					}}
				>
					<div
						style={{
							fontSize: "13px"
						}}
					>Confidence Score:</div>
					<div
						style={{
							fontSize: "13px"
						}}
					>{confidenceScore}</div>
				</div>
			}
		</ListGroupItem>
	);
};

AnnotationItem.propTypes = {
	className: PropTypes.string,
    itemData: PropTypes.object,
};
AnnotationItem.defaultProps = {
	className: '',
	itemData: {},
};
export default AnnotationItem;

import { Link } from "@material-ui/core";
import { useEffect, useState } from "react";
import { IData, IProject } from "../../../../../../../../../../../../common";
import {
  DataService,
  ProjectService,
} from "../../../../../../../../../../../../services";
import { Cache } from "../../../../../../../../../../../../classes/Cache";
import css from "./visitParentResourceLink.module.css";
import { RESOURCE_IDS_TO_FILTER } from "../../../collections/components/ModelAnalyticsResourseComponent";

const cache = new Cache();

type Props = {
  parentResourceId: string;
  label?: string;
  tooltipWhenGeneratingLink?: string;
};

export function VisitParentResourceLink(props: Props) {
  const [parentResource, setParentResource] = useState<IData>();
  const [isFetchingResource, setisFetchingResource] = useState(false);

  const urlSearchParams = new URLSearchParams(window.location.search);
  const projectId = urlSearchParams.get("project");

  const [isFetchingProjectDetails, setIsFetchingProjectDetails] =
    useState(false);
  const [projectDetails, setProjectDetails] = useState<IProject>();

  useEffect(() => {
    const executeFunction = async () => {
      try {
        setisFetchingResource(true);

        const apiResponse = await DataService.getData({
          ids: JSON.stringify([props.parentResourceId]),
        });
        cache.set(
          props.parentResourceId,
          JSON.stringify(apiResponse?.data?.resources[0]),
          300 * 1000
        );
        if (apiResponse?.data?.resources?.length > 0) {
          setParentResource({ ...apiResponse?.data?.resources[0] });
        } else {
          setParentResource(undefined);
        }
      } catch (error) {
        // @ts-ignore
        alert(error.response.data || error.message);
      }
      setisFetchingResource(false);
    };

    if (props.parentResourceId) {
      if (cache.get(props.parentResourceId)) {
        setParentResource(JSON.parse(cache.get(props.parentResourceId)));
        return;
      }

      executeFunction();
    } else {
      setParentResource(undefined);
    }
  }, [props.parentResourceId]);

  useEffect(() => {
    const executeFunction = async () => {
      try {
        setIsFetchingProjectDetails(true);
        const apiResponse = await ProjectService.getProjectDetails(
          projectId || ""
        );
        cache.set(
          projectId || "",
          JSON.stringify(apiResponse?.data),
          300 * 1000
        );
        if (apiResponse?.data) {
          setProjectDetails({ ...apiResponse.data });
        } else {
          setProjectDetails(undefined);
        }
      } catch (error) {
        // @ts-ignore
        alert(error?.response?.data || error.message);
      }
      setIsFetchingProjectDetails(false);
    };

    if (projectId) {
      if (cache.get(projectId)) {
        setProjectDetails(JSON.parse(cache.get(projectId)));
        return;
      }

      executeFunction();
    } else {
      setProjectDetails(undefined);
    }
  }, [projectId]);

  const parentResourceLink: string = (() => {
    if (
      isFetchingResource ||
      !parentResource ||
      isFetchingProjectDetails ||
      !projectDetails
    ) {
      return "";
    }

    const linkText = `${window.location.origin}/admin/projects/${projectDetails.name}/${parentResource?.model?.name}/data/${parentResource?.status}?resourceStatus=${parentResource?.status}&project=${projectId}&model=${parentResource.model._id}&type=${parentResource.status}&resourceSortOrder=descending&resourceSortField=statusLastModifiedAt&resourceStatus=${parentResource.status}&resourceOffset=1&resourceLimit=10&resourceNameSearchValue=${parentResource.filename}&resourceNameSearchOperator=equals`;
    return linkText;
  })();

  const handleLinkClick = () => {
    if (isFetchingLinkDetails) {
      alert("Link Details are being fetched. Please wait");
      return;
    }

    if (!parentResourceLink) {
      alert("parent data doesn't exist");
      return;
    }

    localStorage.setItem(
      RESOURCE_IDS_TO_FILTER,
      JSON.stringify([parentResource?._id || ""])
    );

    window.open(parentResourceLink, "_blank");
  };

  const label = props.label || "View Parent Data";

  const isFetchingLinkDetails =
    isFetchingProjectDetails || isFetchingResource ? true : false;

  return (
    <Link
      color="primary"
      component="button"
      onClick={handleLinkClick}
      className={`
                ${css.link} 
                ${isFetchingLinkDetails ? css.linkDisabled : ""}
            `}
    >
      {label}{" "}
    </Link>
  );
}

import {
  Button,
  CircularProgress,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  makeStyles,
} from "@material-ui/core";
import ReportProblemIcon from "@material-ui/icons/ReportProblem";
import { useState } from "react";
import { ModelService } from "../../../../../../../../../services";

const useStyles = makeStyles((theme) => ({
  root: {
    "& > *": {
      display: "flex",
      alignItems: "center",
    },
    "& svg": {
      margin: "auto 10px",
      marginLeft: 0,
    },
  },
  dialog: {
    // padding: "20px"
    minWidth: "32%",
    maxWidth: "650px"
  },
  boldText: {
    fontWeight: "bold",
  },
  dialogContent: {
    maxHeight: "350px",
    overflowY: "auto",
    marginBottom: "10px",
  },
  tableContainer: {
    minWidth: "500px",
  },
  dialogActions: {
    justifyContent: "space-between",
    padding: "24px",
  },
}));

interface IProps {
  modelGroupToDelete: {
    id: string;
    name: string;
    hasModelsLinked: boolean;
  };
  onClose: () => any;
  onDeleteSuccess: () => any;
}

const DeleteModelGroupWarningPopup = (props: IProps) => {
  const classes = useStyles();

  const [isDeletingModelGroupInDB, setIsDeletingModelGroupInDB] =
    useState(false);

  const handleDeleteConfirmClick = async () => {
    setIsDeletingModelGroupInDB(true);
    await ModelService.deleteModelGroup(props.modelGroupToDelete.id);
    setIsDeletingModelGroupInDB(false);
    props.onDeleteSuccess();
    props.onClose();
  };

  return (
    <Dialog
      classes={{ paperWidthSm: classes.dialog }}
      open={true}
      onClose={() => {
        props.onClose();
      }}
    >
      <DialogTitle classes={{ root: classes.root }}>
        <ReportProblemIcon style={{ color: "#f5c609" }} />
        Delete&nbsp;<strong>{props.modelGroupToDelete.name}</strong>&nbsp;model group
      </DialogTitle>
      <DialogContent classes={{ root: classes.dialogContent }}>
        <span>
          {props.modelGroupToDelete.hasModelsLinked ? (
            <>
              Deleting this task group will also delete tasks associated with
              it. Are you sure you want to continue ?
            </>
          ) : (
            <>Are you sure you want to delete this task group ?</>
          )}
        </span>
      </DialogContent>
      <DialogActions classes={{ root: classes.dialogActions }}>
        <Button onClick={() => props.onClose()} disabled={isDeletingModelGroupInDB}>Cancel</Button>
        <Button
          variant="contained"
          onClick={() => {
            handleDeleteConfirmClick();
          }}
          color="primary"
          disabled={isDeletingModelGroupInDB}
          size="small"
        >
          {isDeletingModelGroupInDB ? <CircularProgress size={20} /> : <>Yes</>}
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default DeleteModelGroupWarningPopup;

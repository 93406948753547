import { useContext, useEffect, useRef } from "react";
import { DataSortFieldDefaultValue, IModel, IReduxState, SortOrderDefaultValue } from "../../../../../../../../common";
import { useURLQuery } from "../../../../../../../../hooks/useURLQuery"
import ModelListContext from "../../modelistContext/modelist.context";
import ModelsList from "../ModelsList";
import ModelGroupList from '../modelGroupList/modelGroupList';
import { useHistory, useLocation } from "react-router";
import { ConnectedProps, connect } from "react-redux";
import { addBreadcrumbs } from '../../../../../../../../store/actions/coreActions';
import { ResourceLimitQueryParam, ResourceOffsetQueryParam, ResourceSortFieldQueryParam, ResourceSortOrderQueryParam, ResourceStatusQueryParam } from "../../features/model/features/data/components/TableContent";

export enum QueryParams {
    view="view",
    projectId="project"
}

export type QueryParamsValue = {
    view: "model" | "modelGroup"
}

function ProjectModels() {

    const urlSearchParams = useURLQuery();

    const ModelListContextValue = useContext(ModelListContext);

    const setIsInProjectModelsPageRef = useRef(ModelListContextValue.setIsInProjectModelsPage);
    setIsInProjectModelsPageRef.current = ModelListContextValue.setIsInProjectModelsPage;

    const view: QueryParamsValue["view"] = urlSearchParams.get(QueryParams.view) as QueryParamsValue["view"];

    const projectId = urlSearchParams.get(QueryParams.projectId) || "";

    const history = useHistory();
    const location = useLocation();

    const goInsideModel = (data: IModel) => {
        const queryParamString: string = `?${ResourceStatusQueryParam}=backlog&${ResourceOffsetQueryParam}=1&${ResourceLimitQueryParam}=10&project=${data?.project?._id}&model=${data._id}&type=${data.type}&${ResourceSortOrderQueryParam}=${SortOrderDefaultValue}&${ResourceSortFieldQueryParam}=${DataSortFieldDefaultValue}`;
        const newPath = `${location.pathname}/${data.name}/data/backlog${queryParamString}`;
        addBreadcrumbs([{ label: data.name, path: newPath }]);
        history.push(`${newPath}`);
    }

    useEffect(() => {
        setIsInProjectModelsPageRef.current(true);
        
        return () => {
          setIsInProjectModelsPageRef.current(false);
        }
    }, [])
    

    return (
        <>
        {
            view === "model"
            ? <ModelsList goInsideModel={goInsideModel} />
            : <ModelGroupList
                onModelClick={goInsideModel}
                projectId={projectId} 
            />
        }
        </>
    )
}

const mapProps = (state: IReduxState) => {
    return {
    };
};

const connector = connect(mapProps, { addBreadcrumbs });

export type TPropsFromRedux = ConnectedProps<typeof connector>

export default connector(ProjectModels);
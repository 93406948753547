import React from 'react';
import { Route, Switch, useRouteMatch } from 'react-router-dom';
const AIMarketLandingPage = React.lazy(() => import('./components/AIMarketLandingPage'));
const AIMarketPlaceModelCollectionsTable = React.lazy(() => import("./components/AiMarketPlaceModelCollectionsTable"))

export const AiMarketplacePathStrings = {
    home: 'ai-market-place',
    trainingPods: 'training-pods'
}

export const AiMarketplaceQueryParams = {
    aiTask: 'aiTask',
    problemType: 'problemType',
    minAccuracy: 'minAccuracy',
    maxAccuracy: 'maxAccuracy',
    minIntegrity: 'minIntegrity',
    maxIntegrity: 'maxIntegrity',
    limit: 'limit',
    offset: 'offset',
    viewModelPageOpen: 'viewModelPageOpen',
    modelCollectionId: "modelCollectionId",
    purposeModelId: "purposeModelId",
    testDataSetId: "testDataSetId",
    purposeModelName:"purposeModelName"
}

export default function AiMarketplaceRoutes() {
    let { path } = useRouteMatch();

    return (
        <div style={{ overflowY: 'auto', height: "calc(100% - 115px)", padding: '17px 17px 15px 17px' }}>
            <Switch>
                <Route exact path={`${path}`} component={AIMarketLandingPage} />
                <Route exact path={`${path}/${AiMarketplacePathStrings.trainingPods}`} component={AIMarketPlaceModelCollectionsTable} />
            </Switch>
        </div>
    )
}
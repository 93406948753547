function getLastCharacterOfString(stringToCheck: string): string {
  return stringToCheck[stringToCheck?.length - 1];
}

export function addCircularBracketIfMissingInEndOfRgbaColorString(stringToCheck: string): string {
  const lastCharacter = getLastCharacterOfString(stringToCheck);

  if (stringToCheck.startsWith("rgba(") && lastCharacter !== ")") {
    stringToCheck += ")";
  }
  return stringToCheck;
}

export function convertStringToUpperCase(stringToConvert: string): string {
  return stringToConvert.toLocaleUpperCase();
}

import ModelCard from "../ModelCard";
import { IModel } from "../../../../../../../../common/constants/interfaces/models";
import { Handle, Position } from "@reactflow/core";
import { ModelGroupUI } from "./modelGroupList";
import { makeStyles } from "@material-ui/core";

type modelNodeProps = {
  isConnectable: boolean;
  data: {
    model: IModel;
    modelGroup: ModelGroupUI;
    onModelCardClick: (data: IModel) => any;
    handleOnMoveModelToDifferentModelGroupSelectInMenu: (modelId: string) => any;
  };
};

const useStyles = makeStyles((theme) => ({
  handleWhenNotConnectedWithAnyone: {
    display: "none",
  },
  handleWhenViewMode: {
    visibility: "hidden",
  },
  handleWhenEditMode: {
    // visibility: "hidden",
    width: "15px !important",
    height: "15px !important",
    // padding: "10px !important",
    zIndex: 99999
  },
}));

function isHandleConnectedAnyWhere(
  handleId: string,
  modelGroupEdges: ModelGroupUI["edges"]
): boolean {
  for (const edge of modelGroupEdges) {
    if (edge.sourceHandle === handleId || edge.targetHandle === handleId) {
      return true;
    }
  }

  return false;
}

export default function ModelNode(props: modelNodeProps) {
  
  const classes = useStyles();

  if (
    !props.data ||
    !props.data.model ||
    !props.data.model._id
  ) {
    return (<></>)
  }

  const topHandleId = `${props.data.model._id}-top`;
  const leftHandleId = `${props.data.model._id}-left`;
  const bottomHandleId = `${props.data.model._id}-bottom`;
  const RightHandleId = `${props.data.model._id}-right`;

  return (
    <>
      {}
      <Handle
        id={topHandleId}
        type="source"
        className={`
          ${
            props?.data?.modelGroup?.mode === "view"
              ? classes.handleWhenViewMode
              : classes.handleWhenEditMode
          }
        `}
        position={Position.Top}
      />

      <Handle
        id={leftHandleId}
        type="source"
        position={Position.Left}
        className={`
          ${
            props?.data?.modelGroup?.mode === "view"
              ? classes.handleWhenViewMode
              : classes.handleWhenEditMode
          }
        `}
      />

      <ModelCard
        model={props.data.model}
        onClick={() => {
          if (props.data.modelGroup.mode === "edit") {
            return;
          }
          const result = props.data.onModelCardClick(props.data.model);
          return result
        }}
        isReactFlowEditMode={
          props.data.modelGroup.mode === "edit"
          ? true
          : false
        }
        isActive={( props.data.model.lastUpdatedAt || false) &&((new Date().valueOf() - new Date(props.data.model.lastUpdatedAt).valueOf())/(1000*60*60*24) <= 1)}
        handleOnMoveModelToDifferentModelGroupSelectInMenu={props.data.handleOnMoveModelToDifferentModelGroupSelectInMenu}
        scenario="Group-View"
      />

      <Handle
        id={RightHandleId}
        type="source"
        position={Position.Right}
        className={`
          ${
            props?.data?.modelGroup?.mode === "view"
              ? classes.handleWhenViewMode
              : classes.handleWhenEditMode
          }
        `}
      />

      <Handle
        id={bottomHandleId}
        type="source"
        position={Position.Bottom}
        className={`
          ${
            props?.data?.modelGroup?.mode === "view"
              ? classes.handleWhenViewMode
              : classes.handleWhenEditMode
          }
        `}
      />
      {/* </div> */}
    </>
  );
}

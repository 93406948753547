import { useContext, useEffect, useRef, useState } from "react";
import { useLocation } from "react-router";
import clsx from "clsx";
import { Dialog, DialogActions, DialogContent, DialogTitle ,Box, Chip, CircularProgress, FormControl, FormHelperText, Grid,InputLabel, makeStyles, MenuItem, Select, TextField, Tooltip,} from "@material-ui/core";
import { connect, ConnectedProps } from "react-redux";

import { Autocomplete } from "@material-ui/lab";
import { Cancel } from "@material-ui/icons";
import css from "./createModelGroupPopup.module.css";
import { useQuery, IModelType, ModelAnnotationLabelAvailable, CustomButton, IReduxState } from "../../../../common";
import { InputFieldState } from "../../../../common/constants/interfaces/inputFieldState";
import { ModelService } from "../../../../services";
import { removeDuplicateElementsFromArray } from "../../../../services/arrayHelperService";
import { generateUniqueId } from "../../../../services/idHelperService";
import { valueExistsInAnnotationLabels, getAnnotationLabel } from "../../../../services/imageAnnotationHelperService";
import { addModel, getProjectModels } from "../../../../store/actions";
import ModelGroup from "../../../../store/types/modelGroup.type";
import AddModelPopup from "../../features/projects/features/models/components/AddModelPopup/AddModelPopup";
import ModelListContext from "../../features/projects/features/models/modelistContext/modelist.context";

import {ReactComponent as CreateModelGroupPopupIcon} from "../../../../assets/svg/CreateModelGroupPopupIcon.svg";

const useStyles = makeStyles((theme) => ({
    root: {
        marginTop: 'auto',
        marginBottom: 'auto',
        height: "100%"
    },
    illustration: {
        width: '150px',
        height: '150px',
    },
    form: {
        display: 'flex',
        flexDirection: 'column',
        // justifyContent: 'center',
        "& legend": {
            float: "unset"
        }
    },
    formControl: {
        width: 300,
    },
    center: {
        justifyContent: 'center'
    },
    select: {
        marginTop: theme.spacing(2)
    },
    selectLabel: {
        background: '#e7edf3'
    },
    closeBtn: {
      position: "absolute",
      right: -10,
      display: "flex",
      justifyContent: "center",
      alignItems: "center",
      // padding: "2px",
      top: -10,
      backgroundColor: "white",
      borderRadius: "50%",
      // color: "white",
      cursor: "pointer",
      width: "25px",
      height: "25px"
    },
    dialogContainer: {
      overflow: 'visible'
    },
}));


const ITEM_HEIGHT = 60;
const ITEM_PADDING_TOP = 8;

interface IProps extends TPropsFromRedux {
  mode: "create" | "edit",
  open : boolean,
  editModelGroup?: {
    modelGroupName: string,
    modelGroupColour: string,
    modelGroupId: string
  }
  refreshModelGroupsInUI: () => any;
  onClose : () => void
}

function CreateModelGroupPopup(props : IProps) {
//   const { isAdding, isAddSuccess, addModel, getProjectModels } = props;
  const [fullScreen , setFullScreen] =useState<boolean>(false)


  const classes = useStyles();
    const location = useLocation();
    const urlSearchParams = useQuery();
    const projectId: string = urlSearchParams.get("project") || "";

    const {
        fetchModelGroupsFromDB,
    } = useContext(ModelListContext) || {};

    // useEffect(() => {
    //   const asyncFunction = async () => {
    //     const apiResponse = await ModelService.getProjectModels({
    //         project: projectId,
    //         // todo: in future make api send all models without putting huge limit
    //         limit: "1000000000000000000000000000000",
    //         offset: "1"
    //     });
    //     setProjectModels([...(apiResponse?.data?.models || [])])
    //   }
    //   if (projectId) {
    //     asyncFunction();
    //   }
    // }, [projectId]);

    const fetchModelGroupsFromDBRef = useRef(fetchModelGroupsFromDB)
    fetchModelGroupsFromDBRef.current = fetchModelGroupsFromDB;

    const [isCreatingModelGroupInDB, setIsCreatingModelGroupInDB] = useState(false);

    const [modelGroups, setModelGroups] = useState<{
        name: ModelGroup["name"], 
        _id: ModelGroup["_id"]
    }[]>([]);

    const [isModelGroupNameUniqueInAProject, setIsModelGroupNameUniqueInAProject] = useState(true);

    const checkIfModelGroupNameUniqueInAProject = async (modelGroupNameToCheck: string, projectId: string) => {
        await ModelService.isModelGroupNameAlreadyTaken({
            modelGroupName: modelGroupNameToCheck,
            projectId: projectId
        })
    }

    const [modelGroupNameSelectedState, setModelGroupNameSelectedState] = useState<InputFieldState<string>>({
        value: props.mode === "edit" 
            ? props.editModelGroup?.modelGroupName || ""
            : ""
    })

    const [modelGroupColorSelectedState, setModelGroupColorSelectedState] = useState<InputFieldState<string>>({
        value: props.mode === "edit"
            ?  props.editModelGroup?.modelGroupColour || ""
            : "#008D9C"
    })

    useEffect(()=>{
        if (!modelGroupNameSelectedState.value) {
            setModelGroupNameSelectedState((oldState) => { return {
                ...oldState,
                errorMessage: "Task group is required"
            } })
        } else {
            setModelGroupNameSelectedState((oldState) => { return {
                ...oldState,
                errorMessage: ""
            } })
        }
    }, [modelGroupNameSelectedState.value, modelGroupNameSelectedState.errorMessage])    

    useEffect(()=>{
        if (modelGroupNameSelectedState.isTouched) {
            setModelGroupNameSelectedState(oldState=>{return {
                ...oldState,
                canShowErrorToUser: true
            }})
        }
    }, [modelGroupNameSelectedState.isTouched])

    useEffect(()=>{
        if (modelGroupColorSelectedState.isTouched) {
            setModelGroupColorSelectedState(oldState=>{return {
                ...oldState,
                canShowErrorToUser: true
            }})
        }
    }, [modelGroupColorSelectedState.isTouched])

    const [modelTypeState, setModelTypeState] = useState<InputFieldState<IModelType | string>>({value: ""});
    useEffect(()=>{
        if (modelTypeState.value === '') {
            setModelTypeState((oldState) => { return {
                ...oldState,
                errorMessage: "Task type is required"
            } })
        } else if (modelTypeState.errorMessage) {
            setModelTypeState((oldState) => { return {
                ...oldState,
                errorMessage: ""
            } })
        }
    }, [modelTypeState.value, modelTypeState.errorMessage])    

    useEffect(()=>{
        if (modelTypeState.isTouched) {
            setModelTypeState(oldState=>{return {
                ...oldState,
                canShowErrorToUser: true
            }})
        }
    }, [modelTypeState.isTouched])

    const [annotationLabelsState, setAnnotationLabelsState] = useState<InputFieldState<ModelAnnotationLabelAvailable[]>>({
      value: [],
    });
    const annotatationLabels = annotationLabelsState.value
    useEffect(()=>{
        if (
            modelTypeState.value !== 'imageAnnotation' && 
            modelTypeState.value !== 'imageAnnotationGroup'
        ) {
            if (annotationLabelsState.errorMessage || annotatationLabels.length>0) {
                setAnnotationLabelsState(oldState=>{return {
                    ...oldState,
                    isDirty: false,
                    isTouched: false,
                    value: [],
                    errorMessage: ""
                }})
            }
            return;
        }

        if (annotatationLabels.length === 0) {
            setAnnotationLabelsState((oldState) => { return {
                ...oldState,
                errorMessage: "Task annotation labels are required"
            } })
        } else if (annotationLabelsState.errorMessage) {
            setAnnotationLabelsState((oldState) => { return {
                ...oldState,
                errorMessage: ""
            } })
        }
    }, [annotatationLabels, annotationLabelsState.errorMessage, modelTypeState.value])      
    useEffect(()=>{
        if (annotationLabelsState.isTouched) {
            setAnnotationLabelsState(oldState=>{return {
                ...oldState,
                canShowErrorToUser: true
            }})
        }
    }, [annotationLabelsState.isTouched])

    function isFormValid(): boolean {
      if (
          modelGroupNameSelectedState.errorMessage ||
          modelGroupColorSelectedState.errorMessage
      ) {
          return false;
      }
      return true
  }

  function showErrorsOfAllFields() {
      setModelGroupNameSelectedState(oldState=>{return {
          ...oldState,
          canShowErrorToUser: true
      }})
      setModelGroupColorSelectedState(oldState=>{return {
          ...oldState,
          canShowErrorToUser: true
      }})
  }

  const onSubmit = async (): Promise<void> => {

      if (!isFormValid()) {
          showErrorsOfAllFields();
          return;
      }

      setIsCreatingModelGroupInDB(true);

      if (props.mode === "create") {
          const newModelGroup = await ModelService.createModelGroup({
            name: modelGroupNameSelectedState.value,
            projectId: projectId,
            colour: modelGroupColorSelectedState.value
          })
      } else if (props.mode === "edit") {
        await ModelService.updateModelGroup(props.editModelGroup?.modelGroupId || "", {
            ...(modelGroupNameSelectedState.isDirty ? {name: modelGroupNameSelectedState.value} : {}),
            ...(modelGroupColorSelectedState.isDirty ? {colour: modelGroupColorSelectedState.value} : {}),
        })
      }

      setIsCreatingModelGroupInDB(false);
      props.refreshModelGroupsInUI();
      props.onClose();
  };


//   useEffect(() => {
//       if (isAddSuccess) {
//           resetForm();
//           const query = new URLSearchParams(location.search);
//           const project = query.get('project') || '';
//           getProjectModels({ project, offset: '1', limit: '1000' });
//           if (fetchModelGroupsFromDBRef.current) {
//               fetchModelGroupsFromDBRef.current();
//           }
//       }
//   }, [isAddSuccess])


    return (
        <Dialog
        fullScreen={fullScreen}
        open={props.open}
        onClose={props.onClose}
        aria-labelledby="responsive-dialog-title"
        classes={{ paperScrollPaper: classes.dialogContainer, paper: css.dialog}}
      >
        <DialogTitle id="responsive-dialog-title">
            <div
                className={classes.closeBtn}
                onClick={() => {
                    props.onClose();
                }}
                >
                <Cancel style={{ fontSize: "40px", padding: "4px" }} />
            </div>
              <div style={{alignItems : "center"}}>
                <div style={{textAlign: 'center', padding : '10px'}}>
                    <CreateModelGroupPopupIcon />
                  </div>
                  <div style={{textAlign: 'center', marginTop: "15px"}}>
                    <strong >{props.mode === 'create' ? "Create" : "Edit"} Group</strong>
                  </div>
              </div>
          </DialogTitle>
        <DialogContent>
        <div
                style={{
                    height: '90%',
                    overflow: 'auto',
                    display: "flex",
                    justifyContent: "center"
                }}
            >
                <div className={classes.form} onSubmit={(e) => e.preventDefault()} >
                    <FormControl className={classes.formControl}>
                        <TextField
                            variant="outlined"
                            size="small"
                            margin="normal"
                            required
                            fullWidth
                            id='name'
                            value={modelGroupNameSelectedState.value}
                            onChange={(event)=>{
                                setModelGroupNameSelectedState(oldState=>{return {
                                    ...oldState,
                                    isDirty: true,
                                    value: event.target.value
                                }})
                            }}
                            onBlur={()=>{
                                setModelGroupNameSelectedState(oldState=>{return {
                                    ...oldState,
                                    isTouched: true,
                                }})                                
                            }}
                            label='Group Name'
                            error={
                                modelGroupNameSelectedState.canShowErrorToUser && modelGroupNameSelectedState.errorMessage
                                ? true
                                : false
                            }
                            helperText={
                                modelGroupNameSelectedState.canShowErrorToUser && modelGroupNameSelectedState.errorMessage
                                ? modelGroupNameSelectedState.errorMessage
                                : ""
                            }
                            autoFocus
                        />
                    </FormControl>

                    <FormControl className={classes.formControl}>
                        <TextField
                            variant="outlined"
                            size="small"
                            margin="normal"
                            required
                            fullWidth
                            value={modelGroupColorSelectedState.value}
                            inputProps={{
                                type: "color"
                            }}
                            onChange={(event)=>{
                                setModelGroupColorSelectedState(oldState=>{return {
                                    ...oldState,
                                    isDirty: true,
                                    value: event.target.value
                                }})
                            }}
                            onBlur={()=>{
                                setModelGroupColorSelectedState(oldState=>{return {
                                    ...oldState,
                                    isTouched: true,
                                }})                                
                            }}
                            label='Group Colour'
                            error={
                                modelGroupColorSelectedState.canShowErrorToUser && modelGroupColorSelectedState.errorMessage
                                ? true
                                : false
                            }
                            helperText={
                                modelGroupColorSelectedState.canShowErrorToUser && modelGroupColorSelectedState.errorMessage
                                ? modelGroupColorSelectedState.errorMessage
                                : ""
                            }
                        />
                    </FormControl>


                    <Box m={1.5} />
                </div>
            </div>
        </DialogContent>
        <DialogActions
            style={{
                justifyContent: "center",
                marginBottom: "30px"
            }}
        >
            <div>
                <CustomButton onClick={()=>onSubmit()} className={classes.formControl} disabled={isCreatingModelGroupInDB}>{isCreatingModelGroupInDB ? <CircularProgress size={20} /> : `${props.mode === "create" ? "Create" : "Update"} Group`}</CustomButton>
            </div>
        </DialogActions>
      </Dialog>  
    )
}

const mapProps = () => {
  return {
    //   isAdding: state.model.isAdding,
    //   isAddSuccess: state.model.isAddSuccess,
  };
}

const connector = connect(mapProps, { 
    // addModel, getProjectModels 
});

export type TPropsFromRedux = ConnectedProps<typeof connector>

export default connector(CreateModelGroupPopup);
import React, { useEffect, useState } from 'react';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import useMediaQuery from '@material-ui/core/useMediaQuery';
import { useTheme } from '@material-ui/core/styles';
import { DataService, ModelService } from '../../../../../../../../../../../../services';
import { ICollection, IModel } from '../../../../../../../../../../../../common';
import { Autocomplete } from '@material-ui/lab';
import { CircularProgress, TextField } from '@material-ui/core';

type Props = {
    onClose: ()=>any,
    dataSetCollectionIdOfPrompt: string,
    projectId: string
}

export default function AddPromptAnsInDataSetDialog(props: Props) {
  const theme = useTheme();
  const fullScreen = useMediaQuery(theme.breakpoints.down('sm'));

  const [textFileModels, setTextFileModels] = useState<IModel[]>([]);

  const [idOfTextFileModelSelected, setIdOfTextFileModelSelected] = useState("");

  const [textFileModelDataSetCollections, setTextFileModelDataSetCollections] = useState<ICollection[]>([]);

    const [dataSetCollectionIdOfTextModelToInsertIn, setDataSetCollectionIdOfTextModelToInsertIn] = useState("");

    const [isAddingResource, setIsAddingResource] = useState(false);

    useEffect(() => {
        const executeFunction = async () => {
            setDataSetCollectionIdOfTextModelToInsertIn("");
            const apiResponse = await DataService.getCollections({
                model: idOfTextFileModelSelected,
                limit: "-1",
                offset: "-1"
            });


            setTextFileModelDataSetCollections([...apiResponse.data.collections])
        }

        if (idOfTextFileModelSelected) {
            executeFunction();
        }
    
    }, [idOfTextFileModelSelected])
    

  useEffect(()=>{
    const executeFunction = async () => {
        const textFileModelsToSet = await ModelService.getModelsByType("textFile", props.projectId)
        setTextFileModels([...(textFileModelsToSet || [])])
    }
    if (props.projectId) {
        executeFunction()
    }
  }, [props.projectId])

  const handleAddButtonClick = async () => {
    const formData = new FormData();

    const DataSetToInsertResourceIn = textFileModelDataSetCollections.find(dataset=>dataset._id === dataSetCollectionIdOfTextModelToInsertIn)

    formData.append("model", DataSetToInsertResourceIn?.model || "");
    formData.append("status", "approved");
    formData.append("csv", " ");
    formData.append("tag", `${DataSetToInsertResourceIn?.name}_prompt`);
    formData.append("label", `prompt`);
    formData.append("prediction", `predicted`);
    formData.append("confidence_score", `100`);
    formData.append("createResourceFromDataSetCollection", `true`);
    formData.append("dataSetCollectionIdToInsertResourceIn", DataSetToInsertResourceIn?._id || "");
    formData.append("dataSetCollectionIdFromWhichToCreateTextFileResource", props.dataSetCollectionIdOfPrompt || "");

    setIsAddingResource(true)
    await DataService.createResource(formData);
    setIsAddingResource(false)

    props.onClose();

  }

  return (
    <div>
      <Dialog
        fullScreen={false}
        open={true}
        fullWidth={true}
        maxWidth={"md"}
        onClose={props.onClose}
      >
        <DialogTitle>{"Add prompt answers as txt file in a text file model data-set"}</DialogTitle>
        <DialogContent
            style={{
                display: "flex",
                flexDirection: "column",
                alignItems: "center"
            }}
        >
            <div
                style={{
                    minWidth: "40%"
                }}
            >
                <Autocomplete
                    options={textFileModels}
                    getOptionLabel={(option) => option.name}
                    style={{ width: "100%", marginBottom: "30px" }}
                    onChange={(e, value)=>{
                        setIdOfTextFileModelSelected(value?._id || "")
                    }}
                    renderInput={(params) => <TextField {...params} label="Select text file model" variant="outlined" />}
                />

                <Autocomplete
                    options={textFileModelDataSetCollections}
                    getOptionLabel={(option) => option.name}
                    style={{ width: "100%", marginBottom: "50px" }}
                    onChange={(e, value)=>{
                        setDataSetCollectionIdOfTextModelToInsertIn(value?._id || "")
                    }}
                    renderInput={(params) => <TextField {...params} label="Select data set collection" variant="outlined" />}
                />

                
                    <Button variant="contained" color="primary"
                        style={{
                            width: "100%"
                        }}
                        disabled={isAddingResource}
                        onClick={()=>{
                            handleAddButtonClick();
                        }}
                    >
                        {
                            isAddingResource
                            ? "Adding"
                            : "Add"
                        }
                    </Button>

            </div>


        </DialogContent>


        {/* <DialogActions>
          <Button autoFocus onClick={handleClose} color="primary">
            Disagree
          </Button>
          <Button onClick={handleClose} color="primary" autoFocus>
            Agree
          </Button>
        </DialogActions> */}
      </Dialog>
    </div>
  );
}
import { Box } from "@material-ui/core"
import { removeQueryParamsFromUrl } from "../helpers/commonFunctions"
import Main from "./SubComponents/components/Main/Main"
import { useContext } from "react"
import ChatContext from "../../chat/contexts/chat.context"

export const TestDriveStage2LiveStatusComponet = () => {
    const chatContext = useContext(ChatContext);
    removeQueryParamsFromUrl()

    return (
        <Main requestId={chatContext.testDriveProcessRequest?._id || ''} />
    )
}
import { useCallback, useState } from "react";

export default function useForceReRender() {
  const [stateVariable, setStateVariable] = useState(true);

  const forceReRender = useCallback(() => {
    setStateVariable((prev) => !prev);
  }, []);

  return forceReRender;
}

import { SvgIconTypeMap } from "@material-ui/core";
import { CommonProps } from "@material-ui/core/OverridableComponent";
import CancelIcon from "@material-ui/icons/Cancel";
import { makeStyles } from "@material-ui/styles";
import { ElementType, ReactNode } from "react";

const useStyles = makeStyles((theme) => ({
  disabled: {
    cursor: "initial !important",
    color: "rgba(0, 0, 0, 0.12)",
  },
  iconStyles: {
    cursor: "pointer",
  },
}));

export function TableColumnResetFilterButton(props: {
  onClick: () => void;
  disabled?: boolean;
  iconComponentProps?: JSX.IntrinsicAttributes & {
    component: ElementType<any>;
  } & {
    children?: ReactNode;
    color?:
      | "inherit"
      | "disabled"
      | "action"
      | "primary"
      | "secondary"
      | "error"
      | undefined;
    fontSize?: "small" | "inherit" | "default" | "large" | undefined;
    htmlColor?: string | undefined;
    shapeRendering?: string | undefined;
    titleAccess?: string | undefined;
    viewBox?: string | undefined;
  } & CommonProps<SvgIconTypeMap<{}, "svg">> &
    Pick<any, string | number | symbol>;
}) {
  const classes = useStyles();

  return (
    <CancelIcon
      color="primary"
      onClick={() => !props.disabled && props.onClick()}
      className={` 
        ${classes.iconStyles} 
        ${props.disabled ? classes.disabled : ""}
      `}
      {...props.iconComponentProps}
    />
  );
}

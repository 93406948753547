/**
 * It contains helper functions for html DOM
 */

export function isElementVisibleToUserInsideScrollElement(
  elementToCheckVisibility: HTMLElement,
  containerElementHavingScroll: HTMLElement
): boolean {
  const eleTop = elementToCheckVisibility.offsetTop;
  const eleBottom = eleTop + elementToCheckVisibility.clientHeight;

  const containerTop = containerElementHavingScroll.scrollTop;
  const containerBottom =
    containerTop + containerElementHavingScroll.clientHeight;

  // The element is fully visible in the container
  return eleTop > containerTop && eleBottom < containerBottom;
}

export function isAnyInputFieldElementInFocus(): boolean {
    try {
      const focusedElement = document.activeElement;
      if (
          focusedElement?.tagName?.toLowerCase() === "textarea" ||
          (focusedElement?.tagName?.toLowerCase() === "input" &&
          // @ts-ignore
          (focusedElement?.type?.toLowerCase() === "number" || focusedElement?.type?.toLowerCase() === "text")))
      {
          return true;
      }            
  } catch (error) {}
  return false;
}

// import React, { useEffect } from 'react';
import { CssBaseline, ThemeProvider } from '@material-ui/core'; 

import './App.scss';
import Routes from './Routes';
import { Theme } from './theme';
import { AlertContextWrapper } from './common/contexts/alertContext/alertContextComponent';

// import { socket } from './services/config';

const VERSION = "2"

function App () { 

  console.log("APP version", VERSION)

  // useEffect(() => {
  //   socket.connection.on("connect", () => {
  //     console.log('connected --->', socket.connection.id);
  //     return () => {
  //       socket.connection.disconnect()
  //     };
  //   });
  // }, []);

  return (
    <ThemeProvider theme={Theme}>
      <AlertContextWrapper>
        <>
          <CssBaseline />
          <Routes />
        </>
      </AlertContextWrapper>
    </ThemeProvider>
  );
}

export default App;

import { startChatStream } from "@e-llm-studio/streaming-response";
import { GENERATE_TEST_DRIVE_IMAGES_DESCRIPTION_ASSISTANT_NAME, GENERATE_MODEL_ARCHITECHTURE_DESCRIPTION_ASSISTANT_NAME, LLM_STUDIO_MODEL_TEST_DRIVE_RESULTS_ANALYSIS_ASSISTANT_NAME } from "../../../../../services/constants";
import { GuestSessionIdService } from "../../homePage/guestSessionIdService";
import { LLM_STUDIO_CHAT_WEB_SOCKET_URL, LLM_STUDIO_ORG_NAME, LLM_STUDIO_SELECTED_AI_MODEL_NAME, LLM_STUDIO_USER_ID } from "./llmStudioChat.service";
import { v4 as uuidv4 } from 'uuid';

interface IGenerateDescOfTestDriveImagesServicePayload {
    userEmail: string,
    query: {
        image_urls: string[]
    }
}

interface IGeneratedDescOfTestDriveResources {
    description: string
}

export interface IAnnotationLabelAndVertices {
    className : string,
    coordinates: {x: number, y: number}[]
}

export type AnnotatedImageDownloadUrlPair = {
    groundTruth : string,
    groundTruthAnnotations: IAnnotationLabelAndVertices[],
    prediction : string,
    predictionAnnotations: IAnnotationLabelAndVertices[]
}
interface IAnalyseTestDriveResultsPayload {
    userEmail: string,
    annotatedPairsOfImagesDownloadUrlsTestDriveResults: AnnotatedImageDownloadUrlPair[]
}

interface IAnalyseTestDriveResultsResponse {
    msgContent : string,
    extraMetaData: {
        recommend: boolean
    }
}

export const generateDescriptionOfTestDriveImages = async (data: IGenerateDescOfTestDriveImagesServicePayload): Promise<IGeneratedDescOfTestDriveResources | null> => {
    const guestSessionId = GuestSessionIdService.getGuestSessionId({
        organisationName: LLM_STUDIO_ORG_NAME,
        chatAssistantName: GENERATE_TEST_DRIVE_IMAGES_DESCRIPTION_ASSISTANT_NAME
    });
    const chatPreviewId = guestSessionId;
    let response: IGeneratedDescOfTestDriveResources | null = null;

    return new Promise((resolve, reject) => {
        const params = {
            // WEBSOCKET_URL: LLM_STUDIO_CHAT_WEB_SOCKET_URL, // replace with actual WebSocket URL
            WEBSOCKET_URL: LLM_STUDIO_CHAT_WEB_SOCKET_URL, // replace with actual WebSocket URL
            organizationName: LLM_STUDIO_ORG_NAME,
            chatAssistantName: GENERATE_TEST_DRIVE_IMAGES_DESCRIPTION_ASSISTANT_NAME,
            selectedAIModel: LLM_STUDIO_SELECTED_AI_MODEL_NAME,
            replyMessage: '', // If there is no previous response, leave empty
            userName: data.userEmail,
            userEmailId: data.userEmail,
            userId: LLM_STUDIO_USER_ID,
            guestSessionId: guestSessionId,
            chatPreviewId: chatPreviewId,
            query: JSON.stringify(data.query),
            requestId: `requestId-${uuidv4()}`, // Generates a unique request ID
            enableForBackend: false,
            onStreamEvent: (data: any) => {
                if (data?.chatStream?.continueChatResult?.isEnd) {
                    try {
                        const content = data?.chatStream?.continueChatResult?.message?.content;
                        const regex = /```json([\s\S]*?)```/;
                        const match = content.match(regex);
                        const jsonString = match ? match[1].trim() : null;

                        if (jsonString) {
                            response = JSON.parse(jsonString);
                        }
                        resolve(response);
                    } catch (error) {
                        reject(new Error("Failed to parse JSON response"));
                    }
                }
            },
            onError: (error: any) => {
                console.error('Stream error:', error);
                reject(error);
            },
        };

        startChatStream({ ...params });

    })
}

export const generateDescriptionOfModelArchitechture = async (data: any) => {
    const guestSessionId = GuestSessionIdService.getGuestSessionId({
        organisationName: LLM_STUDIO_ORG_NAME,
        chatAssistantName: GENERATE_MODEL_ARCHITECHTURE_DESCRIPTION_ASSISTANT_NAME
    });
    const chatPreviewId = guestSessionId;
    let response: IGeneratedDescOfTestDriveResources | null = null;

    return new Promise((resolve, reject) => {

        const params = {
            WEBSOCKET_URL: LLM_STUDIO_CHAT_WEB_SOCKET_URL, // replace with actual WebSocket URL
            organizationName: LLM_STUDIO_ORG_NAME,
            chatAssistantName: GENERATE_MODEL_ARCHITECHTURE_DESCRIPTION_ASSISTANT_NAME,
            selectedAIModel: LLM_STUDIO_SELECTED_AI_MODEL_NAME,
            replyMessage: '', // If there is no previous response, leave empty
            userName: data.userEmail,
            userEmailId: data.userEmail,
            userId: LLM_STUDIO_USER_ID,
            guestSessionId: guestSessionId,
            chatPreviewId: chatPreviewId,
            query: JSON.stringify(data.query),
            requestId: `requestId-${uuidv4()}`, // Generates a unique request ID
            enableForBackend: false,
            onStreamEvent: (data: any) => {
                if (data?.chatStream?.continueChatResult?.isEnd) {
                    try {
                        const content = data?.chatStream?.continueChatResult?.message?.content;
                        const regex = /```json([\s\S]*?)```/;
                        const match = content.match(regex);
                        const jsonString = match ? match[1].trim() : null;

                        if (jsonString) {
                            response = JSON.parse(jsonString);
                        }
                        resolve(response);
                    } catch (error) {
                        reject(new Error("Failed to parse JSON response"));
                    }
                }
            },
            onError: (error: any) => {
                console.error('Stream error:', error);
                reject(error);
            },
        };

        startChatStream({ ...params });

    })
}

export const analyseTestDriveResults = async (data: IAnalyseTestDriveResultsPayload): Promise<IAnalyseTestDriveResultsResponse | null> => {
    const guestSessionId = GuestSessionIdService.getGuestSessionId({
        organisationName: LLM_STUDIO_ORG_NAME,
        chatAssistantName: LLM_STUDIO_MODEL_TEST_DRIVE_RESULTS_ANALYSIS_ASSISTANT_NAME
    });
    const chatPreviewId = guestSessionId;
    let response: IAnalyseTestDriveResultsResponse | null = null;

    return new Promise((resolve, reject) => {
        const params = {
            WEBSOCKET_URL: LLM_STUDIO_CHAT_WEB_SOCKET_URL,
            organizationName: LLM_STUDIO_ORG_NAME,
            chatAssistantName: LLM_STUDIO_MODEL_TEST_DRIVE_RESULTS_ANALYSIS_ASSISTANT_NAME,
            selectedAIModel: LLM_STUDIO_SELECTED_AI_MODEL_NAME,
            replyMessage: '',
            userName: data.userEmail,
            userEmailId: data.userEmail,
            userId: LLM_STUDIO_USER_ID,
            guestSessionId: guestSessionId,
            chatPreviewId: chatPreviewId,
            query: JSON.stringify({ annotatedPairsOfImagesDownloadUrlsTestDriveResults: data.annotatedPairsOfImagesDownloadUrlsTestDriveResults }),
            requestId: `requestId-${uuidv4()}`,
            enableForBackend: false,
            onStreamEvent: (data: any) => {
                if (data?.chatStream?.continueChatResult?.isEnd) {
                    try {
                        const content = data?.chatStream?.continueChatResult?.message?.content;
                        const regex = /```extraMetaData([\s\S]*?)```/;
                        const match = content.match(regex);
                        const jsonString = match ? match[1].trim() : null;
                        const message = content.split(regex).join('');
                        console.log(content)
                        if (jsonString) {
                            response = {
                                msgContent: content,
                                extraMetaData: JSON.parse(jsonString)
                            }
                        }
                        resolve(response);
                    } catch (error) {
                        reject(new Error("Failed to parse JSON response"));
                    }
                }
            },
            onError: (error: any) => {
                console.error(`Error in calling ${LLM_STUDIO_MODEL_TEST_DRIVE_RESULTS_ANALYSIS_ASSISTANT_NAME} agent: `, error);
                reject(error);
            },
        };

        startChatStream({ ...params });

    })
}
import React from "react";
import {
  Box,
  makeStyles,
  SvgIcon,
} from "@material-ui/core";
import HistoryIcon from '@material-ui/icons/History';
import clsx from "clsx";
import { ResourceActions } from "../../../../../../../common";
const useStyles = makeStyles((theme) => ({
  button: {
    width: "40px",
    height: "40px",
    display: "flex",
    alignItems: "center",
    borderRadius: "50px",
    padding: "5px",
    cursor: "pointer",
    position: "absolute",
    bottom: "10px",
    transition: "width 0.3s",
    "& p": {
      margin: 0,
    },
    zIndex: 99
  },
  topZero:{
    top:0
  },
  buttonRight:{
    left: "10px",
    "& p": {
      visibility: "hidden",
      zIndex:1,
      fontSize:0,
    },
    "&:hover": {
      width: "130px",
      justifyContent: "space-between",
      transition: "width 0.3s",
      "& p": {
        visibility: "visible",
        fontSize: '16px',
        fontFamily: 'Nunito Sans',
        transition: "0.3s",
      },
    },
  },
  buttonRightStatic:{
    left: "50px",
    width: "130px",
    justifyContent: "space-between",
    "& p": {
      zIndex:1,
      visibility: "visible",
      fontSize: '16px',
      fontFamily: 'Nunito Sans',
      transition: "0.3s",
    },
  },
  rejectButton: {
    justifyContent: "center",
    background: "#EF8A8A",
    color: "#212121",
    border: "2px solid #EF8A8A",
    fontSize: '16px',
    "& svg": {
      color: "#EF8A8A",
      background: "white",
      borderRadius: "50px",
      padding: "3px",
      overflow: "visible"
    },
    "& p": {
      width: "100%",
      textAlign: "center",
    },
    "&:hover": {
      background: "#FAD6D6 0% 0% no-repeat padding-box",
    }
  },
  approveButton: {
    background: "#008D9C 0% 0% no-repeat padding-box",
    border: "2px solid #008D9C",
    justifyContent: "center",
    "& p": {
      width: "100%",
      textAlign: "center",
    },
    "& svg": {
      color: "#008D9C",
      background: "white",
      borderRadius: "50px",
      padding: "7px",
      overflow: "visible"
    },
    "&:hover": {
      background: "#D4E8EA",
    }
  },
  activeButton: {
    background: "#ffdd44 0% 0% no-repeat padding-box",
    border: "2px solid #ffdd44",
    justifyContent: "center",
    "& p": {
      width: "100%",
      textAlign: "center",
    },
    "& svg": {
      color: "#ffdd44",
      background: "white",
      borderRadius: "50px",
      overflow: "visible"
    },
    "&:hover": {
      background: "#fff4c4",
    }
  },
  buttonLeft:{
    right: '10px',
    flexDirection: "row-reverse",
    position: "absolute",
    "& p": {
      visibility: "hidden",
      zIndex: 1,
      fontSize: 0,
    },
    "&:hover": {
      width: "130px",
      justifyContent: "flex-end",
      transition: "width 0.3s",
      "& p": {
        visibility: "visible",
        fontSize:'16px',
        transition: "0.4s",
        fontFamily: "Nunito Sans"
      },
    },
  },
  buttonLeftStatic:{
    right: '50px',
    flexDirection: "row-reverse",
    position: "absolute",
    width: "130px",
    justifyContent: "flex-end",
    "& p": {
      zIndex: 1,
      visibility: "visible",
      fontSize:'16px',
      transition: "0.4s",
      fontFamily: "Nunito Sans"
    },
  },
  approve:{
    color: "white",
    backgroundColor: "#008D9C",
  },
  reject:{
    color: "white",
    backgroundColor: "red",
  },
  active:{
    color: "#ffdd44",
    backgroundColor: "red",
  },
  buttonIcon: {
    fontSize: "30px",
    color: "white",
    zIndex:10
  },
}));
const DrawerButton = (props:{status:String, buttonText?: string , side:String,scenario?:string}) => {
  const {status,side,scenario}=props;
  const classes = useStyles();

  const urlSearchParams = new URLSearchParams(window.location.search);
  const copilotId = urlSearchParams.get("coPilot") || "";

  const copilotsToHardcode = ["6555be0774b67808ca69bf1b", "654a1be8c2f2a5d7eb4b5218"];

  if(copilotsToHardcode.includes(copilotId) && scenario !== "DoNotDiffer") return (
    <div>
      <Box className={clsx(classes.button, {[classes.active && classes.activeButton]: status === ResourceActions.ACTIVE, [classes.reject && classes.rejectButton]: status === "No", [classes.approve && classes.approveButton]: status === "Yes", [classes.buttonLeftStatic]: side === "right", [classes.buttonRightStatic]: side === "left",[classes.topZero]:scenario==="annotationPopup" })}>
        {status === "Yes" && <SvgIcon className={classes.buttonIcon} >
          <path id="Path_73" data-name="Path 73" d="M11.859,23.4a1.286,1.286,0,0,1-1.82,0L2.773,16.134a1.929,1.929,0,0,1,0-2.73l.91-.91a1.929,1.929,0,0,1,2.73,0l4.537,4.537L23.208,4.772a1.929,1.929,0,0,1,2.73,0l.91.91a1.929,1.929,0,0,1,0,2.73Z" transform="translate(-2, -2)" fill="#008d9c" stroke="#008d9c" stroke-width="1" />
        </SvgIcon>}
        {status === "No" && <SvgIcon className={classes.buttonIcon} >
          <path id="Ellipse_94" data-name="Ellipse 94" cx="21" cy="21" rx="21" ry="21" transform="translate(-3, -3)" fill="#ef8a8a" d="M30.545 15.273A15.273 15.273 0 0 1 15.273 30.545A15.273 15.273 0 0 1 0 15.273A15.273 15.273 0 0 1 30.545 15.273z" />
          <path id="Path_15201" data-name="Path 15201" d="M16.364 0A16.364 16.364 0 1 0 32.727 16.364 16.364 16.364 0 0 0 16.364 0Zm6.173 22.6a1.673 1.673 0 0 1 -2.364 0l-3.724 -3.724 -3.893 3.893a1.673 1.673 0 0 1 -2.364 -2.365L14.084 16.509l-3.625 -3.624a1.673 1.673 0 0 1 2.365 -2.365l3.624 3.624L19.904 10.691a1.673 1.673 0 1 1 2.364 2.365L18.814 16.509l3.723 3.724A1.673 1.673 0 0 1 22.537 22.6Z" transform="translate(-4, -4)" fill="#fff" />
        </SvgIcon>}
        {
         status === ResourceActions.ACTIVE && 
           <HistoryIcon style={{ fontSize: 32, color: "#ffdd44"}}/>
        }
        {status !== ResourceActions.ACTIVE && <p>{status}</p>}
        {status === ResourceActions.ACTIVE && <p>Restore</p>}
        {props.buttonText && <p>{props.buttonText}</p>}
      </Box>
    </div>
  )
  else return (
    <div>
     <Box className={clsx(classes.button, {[classes.active && classes.activeButton]: status === ResourceActions.ACTIVE, [classes.reject && classes.rejectButton]: status === ResourceActions.REJECT, [classes.approve && classes.approveButton]: status === ResourceActions.APPROVE, [classes.buttonLeft]: side === "right", [classes.buttonRight]: side === "left",[classes.topZero]:scenario==="annotationPopup" })}>
        {status === ResourceActions.APPROVE && <SvgIcon className={classes.buttonIcon} >
          <path id="Path_73" data-name="Path 73" d="M11.859,23.4a1.286,1.286,0,0,1-1.82,0L2.773,16.134a1.929,1.929,0,0,1,0-2.73l.91-.91a1.929,1.929,0,0,1,2.73,0l4.537,4.537L23.208,4.772a1.929,1.929,0,0,1,2.73,0l.91.91a1.929,1.929,0,0,1,0,2.73Z" transform="translate(-2, -2)" fill="#008d9c" stroke="#008d9c" stroke-width="1" />
        </SvgIcon>}
        {status === ResourceActions.REJECT && <SvgIcon className={classes.buttonIcon} >
          <path id="Ellipse_94" data-name="Ellipse 94" cx="21" cy="21" rx="21" ry="21" transform="translate(-3, -3)" fill="#ef8a8a" d="M30.545 15.273A15.273 15.273 0 0 1 15.273 30.545A15.273 15.273 0 0 1 0 15.273A15.273 15.273 0 0 1 30.545 15.273z" />
          <path id="Path_15201" data-name="Path 15201" d="M16.364 0A16.364 16.364 0 1 0 32.727 16.364 16.364 16.364 0 0 0 16.364 0Zm6.173 22.6a1.673 1.673 0 0 1 -2.364 0l-3.724 -3.724 -3.893 3.893a1.673 1.673 0 0 1 -2.364 -2.365L14.084 16.509l-3.625 -3.624a1.673 1.673 0 0 1 2.365 -2.365l3.624 3.624L19.904 10.691a1.673 1.673 0 1 1 2.364 2.365L18.814 16.509l3.723 3.724A1.673 1.673 0 0 1 22.537 22.6Z" transform="translate(-4, -4)" fill="#fff" />
        </SvgIcon>}
        {
         status === ResourceActions.ACTIVE && 
           <HistoryIcon style={{ fontSize: 32, color: "#ffdd44"}}/>
        }
        {status !== ResourceActions.ACTIVE && <p>{props.buttonText || status}</p>}
        {status === ResourceActions.ACTIVE && <p>Restore</p>}
      </Box>
    </div>
  );
};

export default React.memo(DrawerButton);

// @ts-check

import React, { useRef, useState, useContext, useEffect, useMemo, useCallback } from 'react';
import PropTypes from 'prop-types';
import {
	Stage, Layer, Rect, Group, Line, Text, Circle, Image, Label, Tag
} from 'react-konva';
import TwoDimensionalImageContext from '../TwoDimensionalImage/twoDimensionalImageContext';
import './canvas.scss';
import { addCircularBracketIfMissingInEndOfRgbaColorString } from '../../../../../../../../services/stringHelperService';
import { waitForSeconds } from '../../../../../../../../services/timeHelperService';

const SCALE_LIMIT_FOR_LOW_QUALITY_IMAGE = 8;

const CONST = {
	// DOT_LENGTH: 5,
	MAGNIFIER_LENGTH: 200,
};

const handleMouseLeave = (isAdding) => {
	document.body.style.cursor = isAdding ? 'crosshair' : 'default';
};

const handleMouseOut = (isAdding) => {
	document.body.style.cursor = isAdding ? 'crosshair' : 'default';
};

const handleMouseOver = (isAdding) => {
	if (isAdding) return;
	document.body.style.cursor = 'pointer';
};

const handleFirstVertexMouseOver = () => {
	document.body.style.cursor = 'cell';
};

const handleVertexMouseOver = () => {
	document.body.style.cursor = 'move';
};

const handleVertexDragMove = (e, isAdding, entities) => {
	if (isAdding) return;
	document.body.style.cursor = 'move';
	const activeVertex = e.target;
	const group = activeVertex.getParent();
	const line = group.get ? group.get('Line')[0] : null;
	const linePoints = [];
	entities.annotations[group.name()].vertices.forEach((v) => {
		if (v.name !== activeVertex.name()) {
			linePoints.push(v.x); linePoints.push(v.y);
			return;
		}
		linePoints.push(activeVertex.x()); linePoints.push(activeVertex.y());
	});
	if (line) {
		line.points(linePoints);
	}
};

const Canvas = ({
	className,
	setSelectedAnnotationId,
	setSelectedAnnotationLabel
}) => {
	const imgRef = useRef(null);
	const stageRef = useRef(null);
	const layerRef = useRef(null);

	const [temporaryColorToSetInLineVertice, setTemporaryColorToSetInLineVertice] = useState("");

	const downloadingHighResolutionImageStageRef = useRef(null);

	if (layerRef.current) {
		/**
		 * for showing image in pixel view when zoomed a lot
		 */
		layerRef.current.getContext()._context.imageSmoothingEnabled = false
		layerRef.current.getContext()._context.mozImageSmoothingEnabled = false;
		layerRef.current.getContext()._context.webkitImageSmoothingEnabled = false;
		layerRef.current.getContext()._context.msImageSmoothingEnabled = false;
	}

	const [cursorPosition, setCursorPosition] = useState({ x: 0, y: 0 });
	const twoDimensionalImageContext = useContext(TwoDimensionalImageContext);
	const {
		url,
		width,
		settingManuallyCanvasWidth,
		canvasStageHeight,
		height,
	   annotations,
		entities,
		magnifyingPower,
		isLabelOn,
		isAdding,
		focusedName,
		onCanvasImgLoad: onImgLoad,
		onCanvasStageMouseDown: onStageMouseDown,
		onCanvasVertexMouseDown: onVertexMouseDown,
		onCanvasVertexDragEnd: onVertexDragEnd,
		onCanvasLabelMouseDown: onLabelMouseDown,
		onCanvasLineMouseDown: onLineMouseDown,
		updateCanvasContextMenuState,
		isViewOnlyMode,
		stageScale,
		setStageScale,
		isShowingCompressedImageBecauseOriginalImageIsDownloading,
		isZoomFeatureEnabled,
		scenario,
		setMouseAnnotationCoordinates,
		imageScaleFactor,
		originalImageWidth,
		originalImageHeight,
		naturalWidth,
		naturalHeight,
		isFullScreenMode,
		maxImageHeightAllowed,
		handleSyncForImages,
		stageScaleForSync,
		zoomCursorPosition,
		condition,
		draggingCoordinates,
		onDrag,
	} = twoDimensionalImageContext;

	useEffect(() => {
		const executeFunction = async () => {
			// blinking effect to let user know the line annotation is located where in the image
			setTemporaryColorToSetInLineVertice("black");
			await waitForSeconds(0.5)
			setTemporaryColorToSetInLineVertice("");
			await waitForSeconds(0.5)
			setTemporaryColorToSetInLineVertice("black");
			await waitForSeconds(0.5)
			setTemporaryColorToSetInLineVertice("");
		}
	
		if (focusedName) {
			executeFunction();
		}
	  }, [focusedName])	

	const areAllItemsAvailableToCenterImage = (() => {
		if (stageRef.current && width) {
			return true
		}
		return false;
	})();

	const centerAlignImage = useCallback(
	  () => {
		  if (areAllItemsAvailableToCenterImage && stageRef.current) {
			  const stageWidth = stageRef.current.width();
			  const imageWidth = width;
			  const stageNewXAxis = (stageWidth / 2) - (imageWidth / 2);
			  if (stageNewXAxis && stageNewXAxis>=0) {
				  stageRef.current.setAttr("x", stageNewXAxis);
				} else {
				  stageRef.current.setAttr("x", 0);
			  }
		  }
	  },
	  [areAllItemsAvailableToCenterImage, width, stageRef.current],
	);
	const centerAlignImageRef = useRef(centerAlignImage);
	centerAlignImageRef.current = centerAlignImage;

	useEffect(() => {
		if (areAllItemsAvailableToCenterImage) {
			centerAlignImageRef.current();				
		}
	}, [areAllItemsAvailableToCenterImage, width])
	
		const dotLength = 5/stageScale;

	const handleSelectedAnnotation = (annotationId, label) => {
		setSelectedAnnotationId(annotationId);
		if(setSelectedAnnotationLabel){
			setSelectedAnnotationLabel(label);
		}
	}
	const annotationsUI = annotations.map((annotationId) => {
		const { name, selectedOptions, isClosed, vertices, metadata } = entities.annotations[annotationId];
		let {color} = entities.annotations[annotationId];

		/**
		 * found that for some annotations in database, color is coming as rgba(227,0,255,1. Meaning ) is missing in the end.
		 * So adding that
		 */
		if (color) {
			color = addCircularBracketIfMissingInEndOfRgbaColorString(color);		
		}
		
		const colorWithOpacity = color?.replace(/,1\)/, ',.15)') || "";

		const verticesUI = [];
		const linePoints = [];
		const startPoint = {};

		const circleRadius = (()=>{
			if (stageScale > 200) {
				return 0.02
			}
			if (stageScale > 15) {
				return 0.05
			}
			return dotLength * 1.1;
		})();

		const circleStrokeWidth = (() => {
			if (stageScale > 200) {
				return 0.2
			}
			if (stageScale > 89) {
				return 0.5
			}
			return 1
		})();

		vertices?.forEach((v, i) => {
			if (i === 0) {
				startPoint.x = v.x; startPoint.y = v.y;
			}
			if (isAdding && focusedName === name && i === 0) {
				verticesUI.push(
					<div>
					<Circle
						x={ v.x }
						y={ v.y }
						key={ v.name }
						name={ v.name }
						radius={ circleRadius }
						// radius={ 0.01 }
						stroke={ color }
						fill={ colorWithOpacity }
						strokeWidth={ circleStrokeWidth }
						draggable= { isViewOnlyMode ? false : true}
						dragOnTop={ false }
						onMouseDown={ onVertexMouseDown }
						onMouseOver={ handleFirstVertexMouseOver }
						onMouseOut={ () => handleMouseOut(isAdding) }
						onFocus={ () => {} }
						onBlur={ () => {} }
					/>
					</div>
				);
			} else {
				verticesUI.push(
					<div onClick={() => handleSelectedAnnotation(annotationId, selectedOptions[1]?.value)}>
					<Rect
						offsetX={ dotLength / 2 }
						offsetY={ dotLength / 2 }
						x={ v.x }
						y={ v.y }
						key={ v.name }
						name={ v.name }
						stroke={ color }
						fill={ focusedName === name && temporaryColorToSetInLineVertice ?  temporaryColorToSetInLineVertice : color }
						strokeWidth={ 0 }
						width={ dotLength }
						height={ dotLength }
						draggable= { isViewOnlyMode ? false : true}
						dragOnTop={ false }
						onMouseDown={ onVertexMouseDown }
						onMouseEnter={e => {
							if(!isViewOnlyMode){
								const container = e.target.getStage()?.container();
								if(container) container.style.cursor = "crosshair";
							}
						}}
						onMouseLeave={e => {
							if(!isViewOnlyMode){
								const container = e.target.getStage()?.container();
								if(container) container.style.cursor = "default";
							}
						}}
						onMouseOver={e => {
							if(!isViewOnlyMode){
								const container = e.target.getStage()?.container();
								if(container) container.style.cursor = "move";
						}; 
						handleVertexMouseOver() }}
						onMouseOut={ () => handleMouseOut(isAdding) }
						onDragEnd={ onVertexDragEnd }
						onDragMove={ e => handleVertexDragMove(e, isAdding, entities) }
						onFocus={ () => {} }
						onBlur={ () => {} }
					/>
					</div>
				);
			}
			linePoints.push(v.x); linePoints.push(v.y);
		});

		const labelUI = isLabelOn ? (
			<Label
				offsetY={ scenario==="spectrogramAudio"? -50 : 10 }
				offsetX={ scenario==="spectrogramAudio"? -50 : 0 }
				x={ startPoint.x }
				y={ startPoint.y}
				onMouseDown={ onLabelMouseDown }
				onMouseOver={ () => handleMouseOver(isAdding) }
				onMouseLeave={ () => handleMouseLeave(isAdding) }
				onMouseOut={ () => handleMouseOut(isAdding) }
				onFocus={ () => {} }
				onBlur={ () => {} }
			>
				<Tag
					name={ name }
					fill='#000'
					opacity={ 0.4 }
					pointerDirection='down'
					pointerWidth={ 10 }
					pointerHeight={ 10 }
					lineJoin='round'
					cornerRadius={ 7 }
				/>
				<Text
					name={ name }
					padding={ 5 }
					fontFamily='Calibri'
					text={ selectedOptions?.length > 0 ? `${selectedOptions?.[selectedOptions.length - 1]?.value}` : 'Not selected' }
					fontSize={ 16 }
					lineHeight={ 1.2 }
					fill='#fff'
				/>
			</Label>
		) : null;
		const lineUI = (
			<div onClick={() => handleSelectedAnnotation(annotationId, selectedOptions?.[1]?.value)}>
			<Line
				name={ name }
				points={ linePoints }
				closed={ isClosed }
				fill={ focusedName === name ? colorWithOpacity : '' }
				stroke={ color }
				// strokeWidth={ 1 }
				strokeWidth={ 1/stageScale }
				lineCap='round'
				lineJoin='round'
				onMouseDown={ onLineMouseDown }
				onMouseOver={ () => handleMouseOver(isAdding) }
				onMouseLeave={ () => handleMouseLeave(isAdding) }
				onMouseOut={ () => handleMouseOut(isAdding) }
				onFocus={ () => {} }
				onBlur={ () => {} }
			/>
			</div>
		);
		return (
			<Group key={ name } name={ name }
				onContextMenu={(e) => {
					handleSelectedAnnotation(annotationId, selectedOptions[1]?.value)

					if (isViewOnlyMode) {
						return;
					}
			
					updateCanvasContextMenuState({
						isOpen: true,
						xCoordinate: e.evt.clientX - 2,
						yCoordinate: e.evt.clientY - 4,
						event: e,
						metadata: metadata
					});
				}}
			>
				{lineUI}
				{verticesUI}
				{labelUI}
			</Group>
		);
	});

	const normalizedLength = CONST.MAGNIFIER_LENGTH / magnifyingPower;
	const rootClassName = `canvas${className ? ` ${className}` : ''}`;

	function zoomStage(event) {

		const isCtrlKeyPressedByUser = (() => {
			return event?.evt?.ctrlKey ? true : false;
		})();

		if (!isZoomFeatureEnabled || !isCtrlKeyPressedByUser) {
			return;
		}

		// const scaleBy = 1.01;
		event.evt.preventDefault();
		if (stageRef.current !== null) {
		  const stage = stageRef.current;
		  const oldScale = stage.scaleX();
		  const scaleBy = (()=>{
			  if (oldScale < 10) {
				  /**
				   * since in the beginning, zooming happens slowly, so kept scaleBy to a larger value
				   * in the beginning
				   */
				  return 1.1;
			  } else {
				  return 1.01
			  }
		  })();
		  const { x: pointerX, y: pointerY } = stage.getPointerPosition();
		  const mousePointTo = {
			x: (pointerX - stage.x()) / oldScale,
			y: (pointerY - stage.y()) / oldScale,
		  };
		//   const newScale = event.evt.deltaY > 0 ? oldScale * scaleBy : oldScale / scaleBy;
		  const newScale = event.evt.deltaY > 0 ? oldScale / scaleBy : oldScale * scaleBy;

		//   stage.scale({ x: newScale, y: newScale });
		  
		  
		  const newPos = {
			x: pointerX - mousePointTo.x * newScale,
			y: pointerY - mousePointTo.y * newScale,
		  }
		  if (condition==="groundTruth" || condition==="model")handleSyncForImages(newScale,newPos)
		 { (condition==="groundTruth" || condition==="model")?setStageScale(stageScaleForSync):setStageScale(newScale)}
		// handleSyncForImages(newScale,newPos)
		// setStageScale(stageScaleForSync)
		  stage.position(newPos);
		  stage.batchDraw();
		}
	  }
	
	useEffect(()=>{
		if(condition==="model" || condition==="groundTruth")
		{setStageScale(stageScaleForSync);
		stageRef.current.position(zoomCursorPosition)
		stageRef.current.batchDraw();}
	 },[zoomCursorPosition,stageScaleForSync])

	useEffect(() => {
	  if (stageScale === 1 || scenario === "coPilotActivePageReadOnlyAnnotation") {
		if (stageRef.current) {

			/**
			 * Resetting the position to initial position which was when stage
			 * scale was at 1
			 */

			if (stageScale !== 1) {
				setStageScale(1);
			}

			stageRef.current.position(1, 1);
			centerAlignImageRef.current()
			stageRef.current.batchDraw();
		}
	  }
	}, [stageScale, scenario])

	const hasUserReachedStageScaleLimitForLowQualityImage = (()=>{
		if (isShowingCompressedImageBecauseOriginalImageIsDownloading && stageScale > SCALE_LIMIT_FOR_LOW_QUALITY_IMAGE) {
			return true;
		}
		return false;
	})();

	const stageWidth = (()=>{
		return settingManuallyCanvasWidth && settingManuallyCanvasWidth > 0 ? settingManuallyCanvasWidth : width
	})();
	
	useEffect(() => {
		if(condition==="model" || condition==="groundTruth")
		{
		if (stageRef.current) {
		  stageRef.current.position({ x: draggingCoordinates.x, y: draggingCoordinates.y });
		}
	  }
	}, [draggingCoordinates]);
	return (
		<div className={ rootClassName }
			style={{
				...(!isFullScreenMode && scenario === "dialog" && maxImageHeightAllowed ? {minHeight: maxImageHeightAllowed} : {}),
				// ...(!isFullScreenMode && scenario === "dialog" ? {display: "flex", justifyContent: "center"} : {})
			}}
		>
			<img
				className='canvas__img'
				ref={ imgRef }
				// key={width}
				key={`${width}${url}`}
				width={ width }
				style={ { visibility: 'hidden' } }
				onLoad={ onImgLoad }
				src={ url }
				alt=''
			/>

			{
				hasUserReachedStageScaleLimitForLowQualityImage && 
				<Stage
					ref={downloadingHighResolutionImageStageRef}
					className='konva-wrapper'
					width={ stageWidth }
					height={height}
					onMouseEnter={()=>{
						downloadingHighResolutionImageStageRef.current.container().style.cursor="pointer"
					}}
					onMouseLeave={()=>{
						downloadingHighResolutionImageStageRef.current.container().style.cursor="default"
					}}					
					onClick={()=>{
						setStageScale(1)
					}}
					>
					<Layer
						fill="green"
					>
						<Text
						width={width}
						height={height}
						shadowColor="green"
							text="Downloading high resolution image..."
							// x={1}
							// y={1}
							// offsetX={1}
							// offsetY={1}
							fontSize={20}
							fill="red"
							// padding={20}
							align="center"
							verticalAlign="middle"
						/>
					</Layer>

				</Stage>
			}

			{
				!hasUserReachedStageScaleLimitForLowQualityImage &&
				<Stage
					className='konva-wrapper'
					width={ stageWidth }
					height={ canvasStageHeight || height }
					onMouseDown={ onStageMouseDown }
					onMouseMove={ (e) => {
						const stage = e.target.getStage();
						const position = stage.getPointerPosition();
						setCursorPosition({ x: position.x, y: position.y });
						let { x: relativeX, y: relativeY } = stage.getRelativePointerPosition();

						let xAxis = relativeX/imageScaleFactor;
						
						if (isShowingCompressedImageBecauseOriginalImageIsDownloading) {
							xAxis = (xAxis)*(originalImageWidth/naturalWidth)
						}

						if (xAxis < 0) {
							xAxis = 0
						}
						
						let yAxis = relativeY/imageScaleFactor;

						if (isShowingCompressedImageBecauseOriginalImageIsDownloading) {
							yAxis = yAxis*(originalImageHeight/naturalHeight)
						}

						if (yAxis < 0) {
							yAxis = 0
						}
						
						setMouseAnnotationCoordinates( xAxis, yAxis);
					} }
					onMouseLeave={(e)=>{
						setMouseAnnotationCoordinates(0, 0)
					}}
					onFocus={ () => {} }
					draggable={isZoomFeatureEnabled ? true: false}
					onWheel={(event)=>zoomStage(event)}
					ref={stageRef}
					scaleX={(condition==="groundTruth" || condition==="model")?stageScaleForSync:stageScale}
					scaleY={(condition==="groundTruth" || condition==="model")?stageScaleForSync:stageScale}
					// scaleX={stageScaleForSync}
					// scaleY={stageScaleForSync}
					onDragMove={(e) => {
						if(condition==="model" || condition==="groundTruth")
						{
						const { x, y } = e.target.position();
			
						const offsetX = x - draggingCoordinates.x;
						const offsetY = y - draggingCoordinates.y;
			
						onDrag(x, y);
			
						stageRef.current.position({ x: draggingCoordinates.x + offsetX, y: draggingCoordinates.y + offsetY });
					  }}}
				>
					<Layer ref={layerRef}>
						<Image image={ imgRef.current } width={ width } height={ height } />
						{annotationsUI}
					</Layer>
					{ magnifyingPower > 1 && (
						<Layer>
							<Group>
								<Rect
									x={ cursorPosition.x }
									y={ cursorPosition.y }
									offsetX={ normalizedLength * magnifyingPower / 2 }
									offsetY={ normalizedLength * magnifyingPower / 2 }
									width={ normalizedLength * magnifyingPower }
									height={ normalizedLength * magnifyingPower }
									stroke='#b5b5b5'
									strokeWidth={ 5 }
								/>
								<Group
									x={ cursorPosition.x }
									y={ cursorPosition.y }
									offsetX={ cursorPosition.x }
									offsetY={ cursorPosition.y }
									clipX={ cursorPosition.x - normalizedLength / 2 }
									clipY={ cursorPosition.y - normalizedLength / 2 }
									clipWidth={ normalizedLength }
									clipHeight={ normalizedLength }
									scaleX={ magnifyingPower }
									scaleY={ magnifyingPower }
								>
									<Image image={ imgRef.current } width={ width } height={ height } />
									{annotationsUI}
								</Group>
							</Group>
						</Layer>
					)}
				</Stage>
			}
			

		</div>
	);
};

Canvas.propTypes = {
	className: PropTypes.string,
};
Canvas.defaultProps = {
	className: '',
};

export default Canvas;

import { useEffect, useState } from "react";
import { DataService } from "../services";
import useDebounce from "./useDebounce";

type Props = {
  taskId: string;
  dataSetCollectionNameToCheck: string;
  debounceDelayInMs: number;
};

export function useIsDataSetCollectionNameUniqueInATask(props: Props) {
  const [isCheckingUniqueness, setIsCheckingUniqueness] = useState(false);
  const [isUnique, setIsUnique] = useState(false);

  const dataSetCollectionNameToCheckDebounced = useDebounce(
    props.dataSetCollectionNameToCheck,
    props.debounceDelayInMs
  );

  useEffect(() => {
    const checkUniqueness = async () => {
      const apiResponse =
        await DataService.checkDatasetCollectionNameUniqueness({
          modelId: props.taskId,
          datasetCollectionName: dataSetCollectionNameToCheckDebounced,
        });
      setIsCheckingUniqueness(false);
      if (apiResponse.data.unique) {
        setIsUnique(true);
      } else {
        setIsUnique(false);
      }
    };

    if (props.taskId && dataSetCollectionNameToCheckDebounced) {
      checkUniqueness();
    } else {
      setIsCheckingUniqueness(false);
      setIsUnique(false);
    }
  }, [props.taskId, dataSetCollectionNameToCheckDebounced]);

  return { isUnique, isCheckingUniqueness };
}

import React from 'react'
import { CircularProgress } from '@material-ui/core';

type FullAreaLoaderProps = {
    customStyle?:React.CSSProperties
}
export default function FullAreaLoader({customStyle}:FullAreaLoaderProps) {
  return (
    <div style={{display:'flex',justifyContent:'center',alignItems:'center',flex:1,height:"100%",...customStyle}}>
        <CircularProgress color='primary' size={"2rem"}/>
    </div>
  )
}

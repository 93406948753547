import React, { Fragment, useEffect, useState, useMemo } from 'react';
import { Box, Button, Chip, CircularProgress, Dialog, DialogActions, DialogContent, DialogTitle, IconButton, Link, makeStyles, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, TextField, Tooltip, Typography } from '@material-ui/core';
import { useURLQuery } from '../../../../../../../../../../../hooks/useURLQuery';
import clsx from 'clsx';
import { Change, diffWords } from 'diff';
import { Cancel, KeyboardArrowLeft, KeyboardArrowRight } from "@material-ui/icons";
import { useHistory, useLocation } from 'react-router';
import ModelStatus from './ModelStatus';
import { Autocomplete } from '@material-ui/lab';
import { CustomSkeleton, IAnalyticsModelData, ILabelConfidenceScore, ILabelConfidenceScoreObject, IModelAnalyticsTestStatisticalData, IModelCollection, IModelType, IResourceAnalyticsCollection, ResourceImageAnnotation, ResourceStatus, TrimmedText, WordMetadata } from '../../../../../../../../../../../common';
import AudioPlayer from '../../../../../../../../../components/ModelData/AudioPlayer';
import InfoRoundedIcon from '@material-ui/icons/InfoRounded';
import moment from 'moment';
// import CancelIcon from '@material-ui/icons/Cancel';
import { DataService } from '../../../../../../../../../../../services';
import { ModelAnalyticsViewPopupImageAnnotationSection } from './ModelAnalyticsViewPopupImageAnnotationSection';
import { useCallback } from 'react';
import TextTrimUI from '../../data/components/TextTrimUI/textTrimUI';
import ImageAspectRatioScaler from '../../data/components/imageAspectRatioScalerWithAlignment.component';
import pinIcon from "../components/Icons/Pin.svg";
import { AnalyticsResourceReferenceGuideDataType, OtherModelDutchTranslationPrediction } from './ModelAnalyticsResources';
import { SegmentationAnalyticsTwoDimensionalImage } from '../../../../../../../../../../../assets/components/react-annotation-tool/src/apps/segmentation-analytics-two-dimensional-image';
import ImageService from '../../../../../../../../../../../services/ImageService';
import { getMaxImageWidthHeightWhichWeCanSet } from '../../../../../../../../../../../services/imageHelperService';
import { getImageScaleFactorForImageWidth } from '../../../../../../../../../../../services/imageHelperService';
import { defaultAnnotationOptions, setResourceImageAnnotationsForSendingToAnnotationComponent } from '../../../../../../../../../../../services/imageAnnotationHelperService';
import Crop169Icon from '@material-ui/icons/Crop169' ;
import AiAssistantIcon from '../../data/components/AiAssistantIcon/AiAssistantIcon';
import { copyByValue } from '../../../../../../../../../../../services/variableHelperService';
import AudioWaveFormUI from './AudioWaveFormUI';
import ReferenceDataPopup from './ReferenceDataPopup';
import ArrowBackIcon from '@material-ui/icons/ArrowBack';
import ArrowForwardIcon from '@material-ui/icons/ArrowForward';
import LabelEditText from '../../data/components/LabelEditText';
import { IAnalyticsPurposeModelData } from '../../../../../../../../../../../common/constants/interfaces/purposeModels';
export const RESOURCE_IDS_TO_FILTER="resourceIdsToFilter";

const imageContainerId = "imageContainerId";

const useStyles = makeStyles((theme) => ({

    indicators: {
        width: '50px',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        cursor: 'pointer',
        transition: 'opacity 0.25s ease-in-out',
        borderRadius: '50%',
        "& svg": {
            fill: '#000',
            fontSize: '2.5rem'
        }
    },
    transcriptionCompare: {
        display: "flex",
        flexDirection: "row",
        gap: 50
    },
    transcriptionLabelContainer: {
        borderRadius: "10px",
        border: "0.4px solid black",
        padding: "10px"
    },
    referenceDataLabel: {
        borderRadius: "10px"
    },
    lhsWhenModelTypeBookTranslation: {
        justifyContent: "start !important"
    },
    annotationIndicators : {
        height : '80px',
        position : 'sticky',
        top : '200px',
    },
    disabledIcon: {
        "& svg": {
            fill: 'rgba(0, 0, 0, 0.26)'
        }
    },
    leftIndicator: {
        left: 0,
    },
    rightIndicator: {
        left: 300,
    },
    chip: {
        minWidth: "85px",
        marginBottom: 5,
        backgroundColor: theme.palette.grey[50],
        marginRight: theme.spacing(1),
        color: '#141414',
        fontSize: '12px',
        "& *": {
            color: '#141414',
            fontSize: '12px',
        }
    },
    flex: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center'
    },
    flexWhenDLPModelType: {
        display: 'flex',
        alignItems: 'normal',
        justifyContent: 'normal'
    },
    contentSpaceBetween: {
        justifyContent: 'space-between'
    },
    infoContainer: {
        "& p": {
            width: '48%',
            display: 'flex',
            fontSize: '12px',
            alignItems: 'center',
            justifyContent: 'space-between',
            "& span:nth-of-type(2)": {
                minWidth: '80px'
            },
            "& span:nth-of-type(1)": {
                maxWidth: '115px'
            },
            "&:not(:first-child)": {
                width: '48%'
            }
        },
    },
    infoContainerWhenModelTypeDLP: {
        flexDirection: "column"
    },
    tableContainer: {
        overflowX: 'hidden',
        overflow: 'auto',
        paddingLeft: '50px',
        height: '100%'
    },
    table: {
        tableLayout: 'fixed',
        "& th, td": {
            borderBottom: 'none'
        },
        "& *": {
            color: '#707070',
            font: 'normal normal normal 12px/19px Nunito Sans'
        }
    },
    // dialogContainer: {
    //     maxWidth: '1160px',
    //     boxShadow: 'none',
    //     paddingRight: '12px',
    //     overflow: 'hidden',
    //     background: 'transparent'
    // },
    dialogContentContainer: {
        boxShadow: '0px 11px 15px -7px rgb(0 0 0 / 20%), 0px 24px 38px 3px rgb(0 0 0 / 14%), 0px 9px 46px 8px rgb(0 0 0 / 12%)',
        background: 'white',
        paddingTop: '20px',
        overflow: 'auto'
    },
    dialogTitle: {
        position: 'relative',
        "& svg": {
            position: 'absolute',
            right: '-12px',
            bottom: '-12px',
            "&:hover": {
                cursor: 'pointer'
            }
        }
    },
    tableCellBorder: {
        border: '1px solid #02BC77',
        display: 'inline-block',
        textAlign: 'center',
        borderRadius: '5px',
        width: '80px',
        padding: '5px',
        font: 'normal normal normal 12px/19px Nunito Sans',
        wordBreak : 'break-word'
    },
    compareWithInput: {
        "& div": {
            "&::before": {
                borderBottom: 'none'
            },
            "& input": {
                minWidth: '100px !important'
            }
        },
        "& label": {
            font: "normal normal normal 14px/19px Nunito Sans",
            letterSpacing: "0.28px",
            color: "#707070"
        }
    },
    infoTitle: {
        color: '#98A6B3',
        font: 'normal normal normal 12px/22px Nunito Sans',
        letterSpacing: '0px'
    },
    infoIcon: {
        fill: '#3178B9'
    },
    tooltip: {
        background: '#000000B3',
        padding: '10px 12px',
        font: 'normal normal normal 12px/19px Nunito Sans'
    },
    tooltipArrow: {
        color: '#000000B3'
    },
    outlinedChip: {
        border: '2px solid #838C95',
        background: 'none'
    },
    statusContainer: {
        "& p": {
            justifyContent: 'center'
        }
    },
    sectionWhenModelTypeImageAnnotation: {
        marginTop: "0px !important"
    },
    paddingTop0: {
        paddingTop: "0px"
    },
    centerAlign: {
        textAlign: 'center'
    },
    dialogContainerWidth : {
        maxWidth: "calc(100% - 64px)"
    },
    imageAnnotationDialogConatinerWidth : {
        maxWidth: "1250px",
    },
    dialogContainer: {
        overflow: 'visible'
    },
    imageAnnotationDialogContainer : {
        maxHeight : '640px'
    },
    root: {
        overflow: "auto",
        padding: "20px 0px",
        "&p": {
          padding: "0px",
        },        
    },
    imageRoot: {
        padding: "0px 0px 20px",
        "&p": {
          padding: "0px",
        },        
    },
    body: {
        padding: "10px",
        // width: "95vw",
        // height: "95vh",
        display: "flex",
        justifyContent: "space-between",
        // alignItems: "center",
        '@media(min-width:1300px)': {
            width: "95vw",
            height: "85vh",
        },
        '@media(min-width:1000px) and (max-width: 1300px)': {
            width: "92vw",
            height: "85vh",
        },
        '@media(min-width:700px) and (max-width: 1000px)': {
            width: "90vw",
            height: "86vh",
        },
        '@media(max-width: 700px)': {
            width: "85vw",
            height: "86vh",
        }        
    },
    imageBody : {
        padding : '0px 10px',
        '@media(min-width:1300px)': {
            width: "1225px",
            height: "auto",
        },
        '@media(min-width:1000px) and (max-width: 1300px)': {
            height: "auto",
        },
    },
    audioTextTranscriptionBody: {
        display: "flex",
        flexDirection: "column",
        justifyContent: "space-evenly"
    },
    closeBtn: {
        position: "absolute",
        right: -10,
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        // padding: "2px",
        top: -10,
        backgroundColor: "white",
        borderRadius: "50%",
        // color: "white",
        cursor: "pointer",
        width: "25px",
        height: "25px"
    },
    modelStatusWhenModelTypeDLP: {
        width: "122px !important"
    },
    imageAnnotation:{
        marginBottom: "-10px",
        paddingBottom: 0
      },
    sectionDetailsWhenNotExpanded : {
        overflowY: 'auto', 
        maxWidth: '50%', 
        width: "40%",
    },
    sectionShowResourceWhenNotExpanded : {
        width : '60%'
    },
    sectionDetailsWhenExpanded : {
        overflowY: 'auto', 
        // maxWidth: '50%', 
        width: "60%",
    },
    sectionShowResourceWhenExpanded : {
        width : '100%'
    },
    flexDirectionColumn : {
        flexDirection: 'column'
    }
}));
function ImageResourceHyperlink(props: {
    linkLabel: string;
    resourceFileName: string;
    resourceStatus: ResourceStatus;
    modelId: string;
    resourceId: string;
    projectId: string;
  }) {
    // const classes = useAudioResourceHyperlinkStyles();

    const urlSearchParams = new URLSearchParams(window.location.search);
  
    const purposeModelId: string = urlSearchParams.get("purposeModelId") || "";
  
    localStorage.setItem(RESOURCE_IDS_TO_FILTER, JSON.stringify([props.resourceId]))
  
    const link: string = (()=>{
        const pathNameTillModelDataTable: string = (()=>{
            return `${window.location.pathname.substring(0, window.location.pathname.indexOf("/collections"))}`
        })();
  
        return `${window.location.origin}${pathNameTillModelDataTable}/data/${props.resourceStatus}?project=${props.projectId}&model=${props.modelId}&type=imageAnnotation&resourceSortOrder=descending&resourceSortField=statusLastModifiedAt&resourceStatus=${props.resourceStatus}&resourceOffset=1&resourceLimit=10&resourceNameSearchValue=${props.resourceFileName}&resourceNameSearchOperator=equals&purposeModelId=${purposeModelId}`
    })();
  
    return (
        <Link color="primary" rel="noreferrer noopener" target="_blank" href={link}>{props.linkLabel}</Link>
    )
  }
interface IDialogProps {
    modelCollectionsToCompareWithOptions: Array<IModelAnalyticsTestStatisticalData>,
    selectedModelCollectionId: string,
    modelInformation?: IAnalyticsModelData; 
    purposeModelInformation?: IAnalyticsPurposeModelData;
    selectedResource: IResourceAnalyticsCollection | null,
    showLeftArrow: boolean,
    availableLabelsData: ILabelConfidenceScoreObject | null,
    showRightArrow: boolean,
    fetchedLabelConfidenceScores: ILabelConfidenceScoreObject
    actions: {
        modelCollectionToCompareWithChangeHandler: Function,
        onArrowClickHanlder: Function,
        updateLabelConfidenceScores: Function
    }
    [name: string]: any,
    isFetchingOtherModelTranslatedlanguageTextpredictions: boolean;
    otherModelTranslatedLanguageTextPredictions: OtherModelDutchTranslationPrediction[] ;    
    setTableData: React.Dispatch<React.SetStateAction<IResourceAnalyticsCollection[]>>;
    modelReferenceGuides: AnalyticsResourceReferenceGuideDataType[];
}

type ModelVersionToCompareWithInputFieldComponentProps = {
    scenario?: "modelTypeDLP"
}

type SelectedModelVersionComponentProps = {
    scenario?: "modelTypeDLP"
}

export type SameHandsIdsInputStateType = {
    scenario: "askingUserIFHeIsSeeingSameHandWithMultipleIds" | "showingHandIdInputFieldsToUser";
    didUserFoundSameHandHavingMultipleIds: boolean | undefined;
    hands: {
        ids: string;
    }[];
    savingScenario: "userFoundSameHandWithMultipleIds" | "userDidn'tFoundSameHandWithMultipleIds" | "";
};

interface IAudioTranscriptionCompare {
    groundTruthLabel: string,
    predictedLabel: string,
    wordErrorRate?: number,
    currentTimePlaying: number,
    totalTime: number,
    metaData: WordMetadata []
}


interface ICharMetaData {
    startTime: number,
    char: string,
    color: string
}

export const AudioTranscriptionCompare: React.FC<IAudioTranscriptionCompare> = 
 ({ 
    groundTruthLabel,
    predictedLabel,
    wordErrorRate,
    currentTimePlaying,
    totalTime,
    metaData
}) => {
    const classes = useStyles();
    const [charDataForGroundTruth, setcharDataForGroundTruth] = useState<ICharMetaData []> ([])

    const [charDataForPredicted, setcharDataForPredicted] = useState<ICharMetaData []> ([])

    const textDiffResult: Change[] = useMemo(() => {
        return diffWords(groundTruthLabel, predictedLabel, {
        });
    }, [groundTruthLabel, predictedLabel]);

    useEffect(() => {
        const charDataForGroundTruthTemp: ICharMetaData [] = [];
        const charDataForPredictedTemp: ICharMetaData [] = [];

        let wordIndex = 0;
        let charIndex = 0;
        textDiffResult.forEach((text, textIndex) => {
            text.value.split("").forEach((char) => {
                if(char===" ") wordIndex++;
                if(!text.added) {
                    charIndex++;
                    charDataForGroundTruthTemp.push({
                        startTime: wordIndex>=metaData.length? charDataForGroundTruthTemp[charDataForGroundTruthTemp.length-1].startTime : metaData?.[wordIndex]?.timeFrameInAudio?.startInMilliSeconds,
                        char: char,
                        color: text.removed? "green": ""                 
                    })
                } 
                if(!text.removed) {
                    charDataForPredictedTemp.push({
                        startTime: wordIndex>=metaData.length? charDataForPredictedTemp[charDataForPredictedTemp.length-1].startTime : metaData?.[wordIndex]?.timeFrameInAudio?.startInMilliSeconds,
                        char: char,
                        color: text.added? "red": ""                 
                    })
                }

            })
        })

        setcharDataForGroundTruth(charDataForGroundTruthTemp)
        setcharDataForPredicted(charDataForPredictedTemp)

    }, [textDiffResult, metaData])

   return (
    <Box className={`${classes.transcriptionCompare}`}>
        <Box>
        <h5>Ground Truth</h5>
        <Box className={`${classes.transcriptionLabelContainer}`}>
            {
                charDataForGroundTruth?.map((char) => {
                    return (
                        <span style={{ 
                            margin: 0,
                            background: char.startTime<currentTimePlaying*1000 ? "yellow": "",
                            color: char.color }}>
                            {`${char.char}`}
                        </span>
                    )
                })
            }
        </Box>
        </Box>
        <Box>
            <Box style={{ display: "flex", flexDirection: "row", justifyContent: "space-between" }}>
                <h5>Predicted</h5>
                <p style={{ margin: 0 }}>Word Error Rate: {wordErrorRate} %</p>
            </Box>
        <Box className={`${classes.transcriptionLabelContainer}`}>
                   {
                       charDataForPredicted?.map((char) => {
                           return (
                               <span style={{
                                   margin: 0,
                                   background: char.startTime < currentTimePlaying * 1000 ? "yellow" : "",
                                   color: char.color
                               }}>
                                   {`${char.char}`}
                               </span>
                           )
                       })
                   }
        </Box>
        </Box>
    </Box>
   );
 };

const AnalyticsDataViewDialog = (props: IDialogProps) => {


    const classes = useStyles();
    const history = useHistory();
    const urlQuery = useURLQuery();
    const location = useLocation();
    const [isCallingAnalyticsAiAssistantAPi, setIsCallingAnalyticsAiAssistantAPi] = useState(false);

    const [isImageAnnotationsScalingGoingOn, setIsImageAnnotationsScalingGoingOn] = useState(false);    
    const [imageWidthForTwoDimensionalImage, setImageWidthForTwoDimensionalImage] = useState(0)

    const [imageAnnotationsForTwoDimensionalImage, setImageAnnotationsForTwoDimensionalImage] = useState<ResourceImageAnnotation[]>([])

    const [rerenderAnnotationsAndImages, setRerenderAnnotationsAndImages] = useState(false);    

    const [isReferenceDataDialogOpen, setIsReferenceDataDialogOpen] = useState(false);

    const [currentTimePlayingInAudioTranscription, setCurrentTimePlayingInAudioTranscription] = useState<number>(0);

    const [totalTimePlayingInAudioTranscription, setTotalTimePlayingInAudioTranscription] = useState<number>(0);

    // const [isShowingHandIdsInputToUser, setIsShowingHandIdsInputToUser] = useState(false);

    const [sameHandIdsInputState, setSameHandIdsInputState] = useState
    <SameHandsIdsInputStateType>
    ({
        scenario:  "askingUserIFHeIsSeeingSameHandWithMultipleIds",
        didUserFoundSameHandHavingMultipleIds: undefined,
        hands: [],
        savingScenario: ""
    });

    useEffect(()=>{
        if (props.selectedResource?.handsData) {
            setSameHandIdsInputState({
                ...props.selectedResource?.handsData,
                savingScenario: "",
                scenario: props.selectedResource?.handsData.didUserFoundSameHandHavingMultipleIds === true
                    ? "showingHandIdInputFieldsToUser"
                    : "askingUserIFHeIsSeeingSameHandWithMultipleIds"
            }) 
        } else {
            setSameHandIdsInputState({
                scenario:  "askingUserIFHeIsSeeingSameHandWithMultipleIds",
                didUserFoundSameHandHavingMultipleIds: undefined,
                hands: [],
                savingScenario: ""
            }) 

        }
    }, [props.selectedResource?.handsData])

    useEffect(() => {
        function resize() {
        setRerenderAnnotationsAndImages(oldState=>!oldState);
        }
        
        window.addEventListener("resize", resize);
    
        return () => {
        window.removeEventListener("resize", resize);
        }
    }, [])

  useEffect(() => {
    const executeFunction = async () => {
      setIsImageAnnotationsScalingGoingOn(true)
      let image: {
        width: number,
        height: number
      } | undefined = undefined;

      image = await ImageService.getImageWidthHeightFromURL(props?.selectedResource?.resourceUrl || "");      
      const imageAspectRatio = image.width / image.height;

      const imageContainerElement =  document.getElementById(imageContainerId);


      let imageMaxWidthAllowed = imageContainerElement?.offsetWidth || 400;
      let imageMaxHeightAllowed = imageContainerElement?.offsetHeight || 400;

    //   let imageWidthToSet = 0;
    //   let imageHeightToSet = 0;

      let {imageWidthToSet, imageHeightToSet} = getMaxImageWidthHeightWhichWeCanSet({
        originalImageWidth: image.width,
        originalImageHeight: image.height,
        maxImageWidthAllowed: imageMaxWidthAllowed,
        maxImageHeightAllowed: imageMaxHeightAllowed
      })
      
      let imageScaleFactor = getImageScaleFactorForImageWidth(
        image.width,
        imageWidthToSet
      );

      const imageAnnotationsForTwoDimensionalImage = setResourceImageAnnotationsForSendingToAnnotationComponent(
        props?.selectedResource?.predictedAnnotations || [],
        imageScaleFactor
      );

      setImageAnnotationsForTwoDimensionalImage(imageAnnotationsForTwoDimensionalImage);

        setImageWidthForTwoDimensionalImage(imageWidthToSet);

      setIsImageAnnotationsScalingGoingOn(false)
      
    }

    if (props?.selectedResource?.resourceUrl) {
      executeFunction()
    }
  
  }, [props?.selectedResource?.predictedAnnotations, props?.selectedResource?.resourceUrl, rerenderAnnotationsAndImages])    

    const selectedModelCollectionIdValue = urlQuery.get('modelCollectionIdFilterSelected') || '';

    const [modelCollectionIdToCompareWith, setModelCollectionIdToCompareWith] = useState('');
    // const [selectedModelCollectionVersion, setSelectedModelCollectionVersion] = useState<string>("");
    const [modelCollectionToCompareWith, setModelCollectionToCompareWith] = useState<IModelAnalyticsTestStatisticalData>();
    const [isFetchingVersionDetailsToCompareWith, setIsFetchingVersionDetailsToCompareWith] = useState(false);
    const [isFetchingSelectedModelVersionDetails, setIsFetchingSelectedModelVersionDetails] = useState(false);
    const [labelNames, setLabelNames] = useState<{ name: string }[]>();
    const [selectedVersionLabelValue, setSelectedVersionLabelValue] = useState<ILabelConfidenceScore[]>()
    const [modelId, setModelId] = useState<string>('');
    const [compareWithVersionLabelValue, setCompareWithVersionLabelValue] = useState<Array<ILabelConfidenceScore>>()
    const [isExpandFeatureEnabled, setIsExpandFeatureEnabled] = useState<boolean>(false);
    const[metadataPopup,setMetaDataPopup]=useState<boolean>(false);
    const [refDataPopup, setRefDataPopup] = useState<boolean>(false);
    const [currentIndex, setCurrentIndex] = React.useState(0);

  

    const contentArray1 = props?.selectedResource?.originalResource || [];
    console.log('contentAray', contentArray1);

    const handleCloseRefDataPopup = () => {
        setRefDataPopup(false);
    };

    const handleNext = () => {
        setCurrentIndex((prevIndex) => (prevIndex + 1) % contentArray1.length);
    };

    const handlePrevious = () => {
        setCurrentIndex((prevIndex) => (prevIndex - 1 + contentArray1.length) % contentArray1.length);
    };

  const selectedResourcePrediction = props?.selectedResource?.prediction === "predicted" ? props?.selectedResource?.label : props?.selectedResource?.prediction;

    const handleExpandFeatureChange = () => {
        setIsExpandFeatureEnabled(!isExpandFeatureEnabled)
    }

    useEffect(() => {
        const queryValue = urlQuery.get('resourceToCompareWith') || '';
        setModelCollectionIdToCompareWith(queryValue);
    }, [location])

    useEffect(() => {
        for(let modelCollection of props.modelCollectionsToCompareWithOptions){
            if(modelCollection.modelCollectionId === props.selectedModelCollectionId){
                setModelCollectionToCompareWith(modelCollection);
                break;
            }
        }
    },[props.modelCollectionsToCompareWithOptions, props.selectedModelCollectionId])

    useEffect(() => {
        const model = urlQuery.get('model') || '';
        setModelId(model)
        if (props.selectedResource) {
            setSelectedVersionLabelValue([...(props.selectedResource?.labels || [])])
        }
    }, [props.selectedResource])

    useEffect(() => {
        const selectedVersionLabels = selectedVersionLabelValue?.map(labelValue => {
            return { name: labelValue.name }
        }) || [];
        const compareWithVersionLabels = compareWithVersionLabelValue?.map(labelValue => {
            return { name: labelValue.name }
        }) || [];
        const labels = [...selectedVersionLabels];
        compareWithVersionLabels.forEach(label => {
            if (labels.length && !labels.find(labelValue => labelValue.name === label.name)) {
                labels.push(label)
            }
        });

        setLabelNames([...labels])
    }, [selectedVersionLabelValue, compareWithVersionLabelValue])

    useEffect(() => {
        const isExistingLabelValue = Object.keys(props.availableLabelsData || {}).find(key => key === modelCollectionIdToCompareWith);
        if (isExistingLabelValue && props.availableLabelsData) {
            const labelValue: ILabelConfidenceScore[] = props.availableLabelsData[isExistingLabelValue].labels as unknown as ILabelConfidenceScore[];
            setCompareWithVersionLabelValue([...labelValue])
        }
        if (!isExistingLabelValue && modelId && props.selectedResource && modelCollectionIdToCompareWith) {
            fetchLabelConfidenceScore(modelId, props.selectedResource?._id, modelCollectionIdToCompareWith)
        }
    }, [modelCollectionIdToCompareWith, modelId, props.selectedResource])

    useEffect(() => {
        const isExistingLabelValue = Object.keys(props.availableLabelsData || {}).find(key => key === modelCollectionIdToCompareWith);
        if (props.availableLabelsData && isExistingLabelValue) {
            const labelValue: ILabelConfidenceScore[] = props.availableLabelsData[isExistingLabelValue].labels as unknown as ILabelConfidenceScore[];
            setCompareWithVersionLabelValue([...labelValue])
        }
    }, [props.availableLabelsData])

    const fetchLabelConfidenceScore = async (modelId: string, resourceId: string, modelCollectionId: string) => {
        const urlSearchParams = new URLSearchParams(window.location.search);
        const testCollectionId = urlSearchParams.get("testCollectionId") || "";
        const modelType = props.modelInformation?.modelType;
        setIsFetchingVersionDetailsToCompareWith(true);
        const data = await DataService.getLabelConfidenceScore({ modelId: modelId, modelCollectionId: modelCollectionId, resourceId: resourceId, testCollectionId: testCollectionId, receiveTrimmedTexts: modelType === "DLP" || modelType === "textAnnotation" ? "true" : "false", receiveResourceText: modelType === "DLP" || modelType === "textAnnotation" ? "true" : "false" })
        props.actions.updateLabelConfidenceScores({ modelCollectionId: modelCollectionId, data })
        setIsFetchingVersionDetailsToCompareWith(false)
    }


    const compareWithValueChangeHandler = useCallback((value: string) => {
        urlQuery.set('resourceToCompareWith', value || '');
        location.search = urlQuery.toString();
        setModelCollectionIdToCompareWith(value || '')
        history.push(`${location?.pathname}?${urlQuery.toString()}`);
    }, [history, location, urlQuery])

    const getTooltipTitle = useCallback((selectedVersion: string) => {
        const resourceCreatedAt = props.modelCollectionsToCompareWithOptions.find(modelOption => modelOption.modelCollectionId === selectedVersion)?.created_at || '';

        return (
                <Fragment>
                <span style={{ lineHeight: '21px' }}>Description of the model</span><br></br>
                <span style={{ lineHeight: '21px' }}>Created at: {moment(resourceCreatedAt).format('HH:mm:ss | DD-MM-YYYY')}</span>
                </Fragment>
        )
    }, [props.modelCollectionsToCompareWithOptions])

    const getConfidenceScoreForLabel = (label: string, labelsConfidenceScore?: ILabelConfidenceScore[]) => {
        const labelScoreValue = labelsConfidenceScore?.filter(labelValue => {
            return labelValue.name.toUpperCase() === label.toUpperCase();
        })[0];
        if (labelScoreValue && labelScoreValue?.confidenceScore) {
            return labelScoreValue?.confidenceScore + ' %'
        } else {
            return 'NA'
        }
    }


    const ModelVersionToCompareWithInputField = useCallback((modelVersionToCompareWithInputFieldComponentProps: ModelVersionToCompareWithInputFieldComponentProps)=>{
        return (
            <Autocomplete
            style={{ width: modelVersionToCompareWithInputFieldComponentProps.scenario === "modelTypeDLP" ? '200px' : '130px', border: 'none', position: 'relative' }}
            closeIcon={() => { }}
            options={props.modelCollectionsToCompareWithOptions.filter(option => option.modelCollectionId !== props.selectedModelCollectionId)
                // .map(option => option.version)
            }
            value={props.modelCollectionsToCompareWithOptions.filter(option => option.modelCollectionId === modelCollectionIdToCompareWith)[0]}
            getOptionLabel={(versionOption) => {
                const versionLabel = props.modelCollectionsToCompareWithOptions.filter(
                    (option) => option.version === versionOption.version.toString()
                )[0]?.version;
                if (versionLabel) {
                    return versionLabel.toString();
                } else {
                    return versionOption.version;
                }
            }
            }
            onChange={(event, value) => {
                compareWithValueChangeHandler(value?.modelCollectionId || '')
            }}

            renderInput={(params) => (
                <>
                    <TextField
                        {...params}
                        size="small"
                        margin="normal"
                        fullWidth
                        // value={this.state.filter.resourceDataSetCollectionId}
                        label={
                            modelVersionToCompareWithInputFieldComponentProps.scenario === "modelTypeDLP"
                            ?   `Compare model with`
                            :   `Compare with`
                        }
                        classes={{ root: classes.compareWithInput }}
                    // className={classes.floating}
                    />
                    <Tooltip classes={{ tooltip: classes.tooltip, arrow: classes.tooltipArrow }} title={getTooltipTitle(modelCollectionToCompareWith?.version || "")} placement='top' arrow={true} >
                        <InfoRoundedIcon fontSize='small' color='primary' classes={{ colorPrimary: classes.infoIcon }} style={{ position: 'absolute', top: '54%', right: '15%' }} />
                    </Tooltip>
                </>
            )} />            
        )
    }, [classes.compareWithInput, classes.infoIcon, classes.tooltip, classes.tooltipArrow, compareWithValueChangeHandler, getTooltipTitle, modelCollectionIdToCompareWith, modelCollectionToCompareWith?.version, props.modelCollectionsToCompareWithOptions, props.selectedModelCollectionId])

    const SelectedModelVersionComponent = useCallback((selectedModelVersionComponentProps: SelectedModelVersionComponentProps)=>{
        return (
            <Typography style={{marginTop:'calc(12.25px + 0.5rem)'}} component="div" classes={{root: classes.flex}}>
            <span style={{ marginRight: '10px'}}>
                {
                    selectedModelVersionComponentProps.scenario === "modelTypeDLP" &&
                    <span style={{fontWeight: "bold"}}>Selected Model &nbsp; &nbsp;</span>
                }
                {modelCollectionToCompareWith?.version || 'NA'}
            </span>
            <Tooltip classes={{ tooltip: classes.tooltip, arrow: classes.tooltipArrow }} title={getTooltipTitle(props.selectedModelCollectionId)} placement='top' arrow={true} >
                <InfoRoundedIcon fontSize='small' color='primary' classes={{ colorPrimary: classes.infoIcon }} />
            </Tooltip>
        </Typography>            
        )
    }, [classes.flex, classes.infoIcon, classes.tooltip, classes.tooltipArrow, getTooltipTitle, modelCollectionToCompareWith?.version, props.selectedModelCollectionId])


    const modelVersionToCompareWithTrimmedTexts = useMemo(()=>{
        if (props.availableLabelsData) {
            if (props.availableLabelsData[modelCollectionIdToCompareWith]) {
                if (props.availableLabelsData[modelCollectionIdToCompareWith].trimmedTexts) {
                    return props.availableLabelsData[modelCollectionIdToCompareWith].trimmedTexts;
                }
            }
        }
        return [];
    }, [modelCollectionIdToCompareWith, props.availableLabelsData])

    const modelCollectionIdToCompareWithResourceText: string = useMemo(()=>{
        if (props.availableLabelsData) {
            if (props.availableLabelsData[modelCollectionIdToCompareWith]) {
                if (props.availableLabelsData[modelCollectionIdToCompareWith].resource) {
                    return props.availableLabelsData[modelCollectionIdToCompareWith].resource || "";
                }
            }
        }
        return "";
    }, [modelCollectionIdToCompareWith, props.availableLabelsData])

    const resizeAnnotationCanvas = (e : React.KeyboardEvent<HTMLDivElement>) => {
        console.log("key pressed", e.key, "   ::  ", e)
        if(e?.key === 'f' || e?.key === 'F'){
            console.log(" is expand feature enables", isExpandFeatureEnabled)
          setIsExpandFeatureEnabled(!isExpandFeatureEnabled)
        }else{
          return
        }
      }

      const otherModelTranslatedLanguagePredictions = (()=>{

        const models: {
            modelVersion: string,
            predicted: string,
            confidenceScore: string
        }[] = []

        if (props.otherModelTranslatedLanguageTextPredictions?.length>0) {
            for (const resource of props.otherModelTranslatedLanguageTextPredictions) {
                if (resource.resourceId === props.selectedResource?._id) {
                    models.push({
                        modelVersion: resource.modelVersion,
                        predicted: resource.predicted,
                        confidenceScore: resource.confidenceScore
                    })
                }
            }
        }
        return models;
      })();

      console.log("debug annotations", imageAnnotationsForTwoDimensionalImage)

      const metaDataList: any = (()=>{
        try {
            return Object.entries(JSON.parse(props.selectedResource?.metadata || "{}"));  
            
        } catch (error) {
            return []
        }
      })();

      const handleAiAnalyticsButtonClick = async () => {
            setIsCallingAnalyticsAiAssistantAPi(true)
            await DataService.performAnalyticsAiAssistanceOnResource(props.selectedResource?._id || "", {referenceResourceId: props.selectedResource?.referenceResourceId, annotations: props.selectedResource?.annotations || ""});
            setIsCallingAnalyticsAiAssistantAPi(false)        
      }
      const [selectedAnnotationId, setSelectedAnnotationId] = useState("");
      const [selectedAnnotationLabel, setSelectedAnnotationLabel] = useState("");
      const [isLabelInReferenceData, setIsLabelInReferenceData] = useState(false);

      useEffect(() =>{
        const referenceData = props?.modelInformation?.model?.referenceData;
        if(referenceData) {
            referenceData.forEach(data => {
                if (data?.linkedAnnotationLabels && data?.linkedAnnotationLabels.includes(selectedAnnotationLabel)) {
                    setIsLabelInReferenceData(true);
                }
              });
        }
      }, [selectedAnnotationLabel])

      useEffect(() => {
        // pre-downloading images
        if (props.modelReferenceGuides?.length>0) {
            for (const referenceImage of props.modelReferenceGuides) {
                const image = new Image();
                image.src = referenceImage.fileUrl || "";
                image.onload = function() {}
            }
        }
      }, [props.modelReferenceGuides])
      const shouldShowStatus = useMemo(()=>{
        const urlParams = new URLSearchParams(location.search);
        const purposeId = urlParams.get('purposeModelId');
        console.log("Location :: ",purposeId)
        return purposeId ? false : true
            
      },[location.search])      

    return (
        <Dialog
            open={props.open}
            onClose={() => props.onClose()}
            maxWidth="lg"
            classes={{ 
                // paperWidthMd: classes.dialogContainer, 
                paperScrollPaper: `${classes.dialogContainer} ${(props.modelInformation?.modelType === "imageAnnotation" || props.modelInformation?.modelType === "imageAnnotationGroup" ) && classes.imageAnnotationDialogContainer}`,
                paperWidthLg: (props.modelInformation?.modelType === "imageAnnotation" || props.modelInformation?.modelType === "imageAnnotationGroup" ) ? classes.imageAnnotationDialogConatinerWidth :classes.dialogContainerWidth 
            }}
            fullWidth={false}
            aria-labelledby="Analytics Resource details dialog"
            onKeyPress={(e) => {
                // if (!isExpandFeatureEnabled) {
                //     return;
                // }
                (props.modelInformation?.modelType === "imageAnnotation" ||
                props.modelInformation?.modelType === "imageAnnotationGroup" )&&
                resizeAnnotationCanvas(e)
            }}
        >
            {(props.modelInformation?.modelType === "image" ||
                props.modelInformation?.modelType === "imageAnnotation" ||
                props.modelInformation?.modelType === "imageAnnotationGroup" ||
                props.purposeModelInformation?.model?.type === "image" ||
                props.purposeModelInformation?.model?.type === "imageAnnotation" ||
                props.purposeModelInformation?.model?.type === "imageAnnotationGroup" )&&

                
<DialogTitle id="responsive-dialog-title" className={classes.imageAnnotation} style={{ display: 'flex', justifyContent: 'flex-start', alignItems: 'center', marginLeft: '40px', marginTop: '30px' }}>
  <div style={{ display: 'flex', alignItems: 'center', marginRight: '10px' }}>
    <p style={{ margin: 0 }}>Image Name:</p>
    <span style={{ color: "#008d9c", paddingLeft: "10px" }}>{props.selectedResource?.fileName}
    </span>


    <div style={{ display: 'flex', alignItems: 'center', marginLeft: '150px',paddingTop:"8px" }}>
    <div style={{ marginRight: '10px' }}>
      <Crop169Icon style={{backgroundColor:"red",border:"none",color:"red",height:"8px",width:"15px"}} />
    </div>
    <p style={{ margin: 0,fontSize:"14px" }}>Mispredicted</p>
  </div>
   <div style={{ display: 'flex', alignItems: 'center', marginLeft: '50px',paddingTop:"8px" }}>
    <div style={{ marginRight: '10px' }}>
      <Crop169Icon style={{backgroundColor:"#008D9C",border:"none",color:"#008D9C",height:"8px",width:"15px"}} />
    </div>
    <p style={{ margin: 0,fontSize:"14px" }}>Ground truth</p>
  </div>
  </div>

</DialogTitle>

        }

        
            <div className={`${classes.root} ${(props.modelInformation?.modelType === "image" ||
                props.modelInformation?.modelType === "imageAnnotation" ||
                props.modelInformation?.modelType === "imageAnnotationGroup" ||
                props.purposeModelInformation?.model?.type === "image" ||
                props.purposeModelInformation?.model?.type === "imageAnnotation" ||
                props.purposeModelInformation?.model?.type === "imageAnnotationGroup" ) ? classes.imageRoot : ''}`} >

            {/* <DialogTitle classes={{root: classes.dialogTitle}}>
                <CancelIcon onClick={() => props.onClose()} />
            </DialogTitle> */}
            <div
            className={classes.closeBtn}
            onClick={() => {
                props.onClose();
            }}
            >
                <Cancel style={{ fontSize: "40px", padding: "4px" }} />
            </div>            
            {/* <DialogContent className={classes.flex} classes={{ root: `${classes.dialogContentContainer} ${props.modelInformation?.modelType==="imageAnnotation" ? classes.paddingTop0 : ""}` }} style={{ justifyContent: 'space-between', borderRadius: '10px' }}> */}
                <div className={`${classes.body} ${(props.modelInformation?.modelType === "image" ||
                props.modelInformation?.modelType === "imageAnnotation" ||
                props.modelInformation?.modelType === "imageAnnotationGroup" ||
                props.purposeModelInformation?.model?.type === "image" ||
                props.purposeModelInformation?.model?.type === "imageAnnotation" ||
                props.purposeModelInformation?.model?.type === "imageAnnotationGroup" ) ? classes.imageBody : '' }`}>
                    <div className={clsx(classes.leftIndicator, classes.indicators, ((props.modelInformation?.modelType === "imageAnnotation" || props.modelInformation?.modelType === "imageAnnotationGroup") && classes.annotationIndicators))}>
                        <IconButton
                            aria-label="previous"
                            color="primary"
                            disabled={!props.showLeftArrow}
                            classes={{ disabled: classes.disabledIcon }}
                            onClick={() => props.actions.onArrowClickHanlder(-1)}
                        >
                            <KeyboardArrowLeft />
                        </IconButton>
                    </div>
                    <Box className={`${ (props.modelInformation?.model.type === "audioTextTranscription" || 
                props.purposeModelInformation?.model?.type === "audioTextTranscription")?
                classes.audioTextTranscriptionBody : ""}`}>
                    <Box 
                        className={clsx(classes.flex, (props.modelInformation?.modelType === "DLP" || props.modelInformation?.modelType === "textAnnotation") && classes.flexWhenDLPModelType , (isExpandFeatureEnabled && classes.flexDirectionColumn))} 
                    // style={{minWidth: props.modelInformation && props.modelInformation?.modelType === 'audio' ? '980px' : '1000px', justifyContent: 'space-between', paddingLeft:'40px' }}>
                    style={{width: "calc(100% - 100px)", justifyContent: 'space-between', paddingLeft: (props.modelInformation?.modelType === "image" ||
                    props.modelInformation?.modelType === "imageAnnotation" ||
                    props.modelInformation?.modelType === "imageAnnotationGroup" ||
                    props.purposeModelInformation?.model?.type === "image" ||
                    props.purposeModelInformation?.model?.type === "imageAnnotation" ||
                    props.purposeModelInformation?.model?.type === "imageAnnotationGroup" ) ? '0px' :'40px' }}>
                        {
                            props.selectedResource &&
                            <section  className={`${props.modelInformation?.modelType==="imageAnnotation" ? classes.sectionWhenModelTypeImageAnnotation : ""} ${isExpandFeatureEnabled ? classes.sectionShowResourceWhenExpanded : classes.sectionShowResourceWhenNotExpanded}`} 
                            style={{ margin: (props.modelInformation?.modelType === "image" ||
                            props.modelInformation?.modelType === "imageAnnotation" ||
                            props.modelInformation?.modelType === "imageAnnotationGroup" ||
                            props.purposeModelInformation?.model?.type === "image" ||
                            props.purposeModelInformation?.model?.type === "imageAnnotation" ||
                            props.purposeModelInformation?.model?.type === "imageAnnotationGroup" ) ? "0px" : '25px 0'}}>
                                    <div 
                                        className={`
                                            ${classes.flex}
                                            ${props.modelInformation?.modelType === "DLP" || props.modelInformation?.modelType === "textAnnotation" ? classes.flexWhenDLPModelType : ""}
                                            ${props.modelInformation?.modelType === "bookTranslation" ? classes.lhsWhenModelTypeBookTranslation : ""}
                                        `} 
                                        style={{ 
                                            width: (props.modelInformation?.modelType === 'image' ||
                                            props.modelInformation?.modelType === "imageAnnotation" ||
                                            props.modelInformation?.modelType === "imageAnnotationGroup" ||
                                            props.purposeModelInformation?.model?.type === "image" ||
                                            props.purposeModelInformation?.model?.type === "imageAnnotation" ||
                                            props.purposeModelInformation?.model?.type === "imageAnnotationGroup")
                                                ? '100%' 
                                                : props.modelInformation?.modelType === "DLP" || props.modelInformation?.modelType === "textAnnotation"
                                                    ? '100%'
                                                    : '450px', 
                                            height: (props.modelInformation?.modelType === 'image' || props.modelInformation?.modelType === "imageAnnotation" || props.modelInformation?.modelType === "imageAnnotationGroup" || props.purposeModelInformation?.model?.type === "image" ||
                                            props.purposeModelInformation?.model?.type === "imageAnnotation" ||
                                            props.purposeModelInformation?.model?.type === "imageAnnotationGroup")? isExpandFeatureEnabled ? '' : '500px' : props.modelInformation?.modelType === "audioTextTranscription"? 'auto': '550px', 
                                            flexDirection: 'column', 
                                            marginTop: (props.modelInformation?.modelType !== "DLP" && props.modelInformation?.modelType !== "textAnnotation"
                                            && props.modelInformation?.modelType !== "image" && props.modelInformation?.modelType !== "imageAnnotation" && props.modelInformation?.modelType !== "imageAnnotationGroup" && !urlQuery.get("purposeModelId")) ? '20px' : "0px",
                                            paddingRight: (props.modelInformation?.modelType === 'image' || props.modelInformation?.modelType === "imageAnnotation" || props.modelInformation?.modelType === "imageAnnotationGroup" || props.purposeModelInformation?.model?.type === "image" ||
                                            props.purposeModelInformation?.model?.type === "imageAnnotation" ||
                                            props.purposeModelInformation?.model?.type === "imageAnnotationGroup")? '40px' : '',
                                        }}
                                    >
                                    {
                                        props.modelInformation?.modelType === 'image' || props.purposeModelInformation?.model?.type === "image" 
                                        ?
                                        <div
                                            style={{
                                                width: "100%",
                                                height: `450px`,
                                                display: "flex",
                                                flexDirection:"column",
                                                justifyContent: "center",
                                                alignItems: "left",
                                                backgroundColor: "white",
                                                borderRadius: "10px"
                                            }}>
                                                {/* <ImageComponent resource={props.resource as unknown as IData} 
                                                    canvasElementClassNames={`${classes.image}`}
                                                /> */}
                                                    {/* <ImageAspectRatioScaler 
                                                        imageUrl={props.selectedResource?.resourceUrl || ""}
                                                        image500Url = {props.selectedResource?.resource500WUrl || undefined}
                                                        imageMetaData = {props.selectedResource?.resourceMetaData || undefined}
                                                        image500UrlMetaData = {props.selectedResource?.resource500WMetaData || undefined}
                                                        maxImageWidthAllowed={500}
                                                        maxImageHeightAllowed={350}
                                                        style={{
                                                            borderRadius: `${10}px`
                                                        }}
                                                    /> */}
                                            <div 
                                                id={imageContainerId}
                                                style={{
                                                    width: "100%",
                                                    height: `450px`,
                                                }}
                                              >
                                                {
                                                    
                                                !isImageAnnotationsScalingGoingOn &&
                                                <SegmentationAnalyticsTwoDimensionalImage 
                                                    url={props?.selectedResource?.resourceUrl || ""}
                                                    imageWidth={imageWidthForTwoDimensionalImage}
                                                    AnnotationOption={defaultAnnotationOptions}
                                                    defaultAnnotations={imageAnnotationsForTwoDimensionalImage}
                                                    isDynamicOptionsEnable
                                                    isViewOnlyMode={false}
                                                    isLabelOn={true}
                                                    originalAnnotations={props?.selectedResource?.predictedAnnotations}
                                                    showExpandIcon={true}
                                                    modelInformation={props.modelInformation}
                                                    setIsReferenceDataDialogOpen={setIsReferenceDataDialogOpen}
                                                    setSelectedAnnotationId={setSelectedAnnotationId}
                                                    setSelectedAnnotationLabel={setSelectedAnnotationLabel}
                                                    isLabelInReferenceData={isLabelInReferenceData}
                                                    // isZoomFeatureEnabled={true}
                                                />
                                                }
                                                {
                                                    <ReferenceDataPopup
                                                                open={isReferenceDataDialogOpen}
                                                                onClose={() => setIsReferenceDataDialogOpen(false)}
                                                                referenceData={props.modelInformation?.model?.referenceData}
                                                                selectedResource={props.selectedResource}      
                                                                selectedAnnotationId={selectedAnnotationId}
                                                                modelReferenceGuides={props.modelReferenceGuides}
                                                                selectedAnnotationLabel={selectedAnnotationLabel} />
                                                }
                                                </div>                                                    
                                                      <ImageResourceHyperlink 
                    linkLabel='Open in Task Data Table'
                    resourceFileName={props.selectedResource?.fileName || ""}
                    resourceStatus={props.selectedResource?.status || "approved"}
                    modelId={urlQuery.get("purposeModelId") ? props.selectedResource?.analyticsPurposeModelData?.model?._id || "" : props.modelInformation?.model?._id || ""}     
                    projectId={urlQuery.get("purposeModelId") ? props.selectedResource?.analyticsPurposeModelData?.model?.project || "" : urlQuery.get("project") || ""}
                    resourceId={props.selectedResource?._id || ""}           
                  />
                                            </div>
                                            // <ImageAspectRatioScaler
                                            //     maxImageWidth={500}
                                            //     maxImageHeight={350}
                                            //     imagePlotterSuffix='AnalyticsDataViewDialog'
                                            //     imageUrl={props.selectedResource.resourceUrl}
                                            //     canvasStyle={{
                                            //         borderRadius: "10px"
                                            //     }}
                                            // /> 
                                        : (props.modelInformation?.modelType === "audio" || props.modelInformation?.modelType === "audioTextTranscription" || props.purposeModelInformation?.model?.type === "audio" || props.purposeModelInformation?.model?.type === "audioTextTranscription") ? 
                                        <>
                                        {
                                            props.selectedResource.spectrogram&&
                                            <img width={500} src={props.selectedResource.spectrogram} />
                                        }
                                        {
                                            (props.modelInformation?.modelType === "audioTextTranscription" || props.purposeModelInformation?.model?.type === "audioTextTranscription")?
                                            <AudioWaveFormUI 
                                            resourceUrl={props.selectedResource.resourceUrl} 
                                            setCurrentTimePlaying={setCurrentTimePlayingInAudioTranscription} 
                                            setTotalTimePlaying={setTotalTimePlayingInAudioTranscription}
                                            />
                                            :
                                            <AudioWaveFormUI resourceUrl={props.selectedResource.resourceUrl} />
                                        }
                                        </>
                                        //<AudioPlayer src={props.selectedResource.resourceUrl} startTimeInSeconds={props.selectedResource.startTime} endTimeInSeconds={props.selectedResource.endTime} />
                                        : (props.modelInformation?.modelType === "imageAnnotation") || props.purposeModelInformation?.model?.type === "imageAnnotation"
                                            ? 
                                            props.selectedResource?.predictedAnnotations && props.selectedResource?.predictedAnnotations?.length>0
                                            ? 
                                            <div 
                                                id={imageContainerId}
                                                style={{
                                                    width: "100%",
                                                    height: `450px`,
                                                }}
                                              >
                                                {
                                                !isImageAnnotationsScalingGoingOn &&
                                                <SegmentationAnalyticsTwoDimensionalImage 
                                                    url={props?.selectedResource?.resourceUrl || ""}
                                                    imageWidth={imageWidthForTwoDimensionalImage}
                                                    AnnotationOption={defaultAnnotationOptions}
                                                    defaultAnnotations={imageAnnotationsForTwoDimensionalImage}
                                                    isDynamicOptionsEnable
                                                    isViewOnlyMode={false}
                                                    isLabelOn={true}
                                                    originalAnnotations={props?.selectedResource?.predictedAnnotations}
                                                    showExpandIcon={true}
                                                    // isZoomFeatureEnabled={true}
                                                    setSelectedAnnotationId={setSelectedAnnotationId}
                                                    setSelectedAnnotationLabel={setSelectedAnnotationLabel}
                                                />
                                                }
                                                </div>
                                            :
                                            <ModelAnalyticsViewPopupImageAnnotationSection 
                                                modelId={modelId}
                                                resource={props.selectedResource}
                                                setFullScreenMode = {() => handleExpandFeatureChange()}
                                                fullScreenMode = {isExpandFeatureEnabled}
                                            />
                                            : props.modelInformation?.modelType === "DLP" || props.modelInformation?.modelType === "textAnnotation"
                                                ?
                                                    <ResourceTextSection 
                                                        selectedModelCollectionId={props.selectedModelCollectionId}
                                                        selectedModelVersionResourceText={props.selectedResource?.resource || ""} 
                                                        selectedModelVersionResourceTrimmedTexts={props.selectedResource?.trimmedTexts || []}   
                                                        SelectedModelVersionComponent={SelectedModelVersionComponent}
                                                        ModelVersionToCompareWithInputField={ModelVersionToCompareWithInputField}                                             
                                                        modelCollectionIdToCompareWith={modelCollectionIdToCompareWith}
                                                        isFetchingVersionDetailsToCompareWith={isFetchingVersionDetailsToCompareWith}
                                                        modelCollectionIdToCompareWithResourceText={modelCollectionIdToCompareWithResourceText}
                                                        modelVersionToCompareWithTrimmedTexts={modelVersionToCompareWithTrimmedTexts || []}
                                                        modelType={props.modelInformation?.modelType}
                                                    />
                                                : props.modelInformation?.modelType === "bookTranslation"
                                                        ? 
                                                            <div>
                                                                <Table>
                                                                    <TableBody>
                                                                        <TableRow>
                                                                            <TableCell >
                                                                                <div
                                                                                    style={{display: "flex", columnGap: "42px"}}
                                                                                >
                                                                                    <div
                                                                                        style={{
                                                                                            fontWeight: 800,
                                                                                            minWidth: "95px"
                                                                                        }}
                                                                                    >
                                                                                        Ground Truth:
                                                                                    </div>
                                                                                    <div>{props.selectedResource?.label}</div>
                                                                                </div>
                                                                            </TableCell>
                                                                        </TableRow>
                                                                        {
                                                                                props.isFetchingOtherModelTranslatedlanguageTextpredictions
                                                                                ? <CircularProgress color="primary" style={{marginTop: "30px"}} />
                                                                                :    
                                                                                    <>
                                                                                        <TableRow>
                                                                                            <TableCell>
                                                                                            <div
                                                                                                style={{ display: "flex", columnGap: "92px", marginBottom: "10px" }}
                                                                                            >
                                                                                                <div style={{ fontWeight: 800 }}>
                                                                                                    Model: 
                                                                                                </div>
                                                                                                <div>
                                                                                                    {
                                                                                                        (props.modelCollectionsToCompareWithOptions || [])?.find(modelCollection=>{
                                                                                                            if (modelCollection.modelCollectionId === selectedModelCollectionIdValue) {
                                                                                                                return true
                                                                                                            } else {
                                                                                                                return false
                                                                                                            }
                                                                                                        })?.version
                                                                                                    }                                                                                                    
                                                                                                </div>
                                                                                            </div>
                                                                                            <div
                                                                                                style={{ display: "flex", columnGap: "70px", marginBottom: "10px" }}
                                                                                            >
                                                                                                <div style={{ fontWeight: 800 }}>
                                                                                                    Predicted: 
                                                                                                </div>
                                                                                                <div>
                                                                                                    {
                                                                                                        props.selectedResource?.prediction
                                                                                                    }                                                                                                    
                                                                                                </div>
                                                                                            </div>
                                                                                            <div
                                                                                                style={{ display: "flex", columnGap: "18px" }}
                                                                                            >
                                                                                                <div style={{ fontWeight: 800 }}>
                                                                                                    Confidence Score: 
                                                                                                </div>
                                                                                                <div>
                                                                                                    {
                                                                                                        props.selectedResource?.confidence_score+" %"
                                                                                                    }                                                                                                    
                                                                                                </div>
                                                                                            </div>
                                                                                            </TableCell>
                                                                                        </TableRow>
                                                                                        {
                                                                                            otherModelTranslatedLanguagePredictions?.map(model=>{
                                                                                                return (
                                                                                                        <TableRow>
                                                                                                        <TableCell>
                                                                                                            <div
                                                                                                                style={{ display: "flex", columnGap: "92px", marginBottom: "10px" }}
                                                                                                            >
                                                                                                                <div style={{ fontWeight: 800 }}>
                                                                                                                    Model: 
                                                                                                                </div>
                                                                                                                <div>{model.modelVersion}</div>
                                                                                                            </div>
                                                                                                            <div
                                                                                                                style={{ display: "flex", columnGap: "70px", marginBottom: "10px" }}
                                                                                                            >
                                                                                                                <div style={{ fontWeight: 800 }}>Predicted:</div>
                                                                                                                <div>{model.predicted}</div>
                                                                                                            </div>
                                                                                                            <div
                                                                                                                style={{ display: "flex", columnGap: "18px" }}
                                                                                                            >
                                                                                                                <div style={{ fontWeight: 800 }}>
                                                                                                                    Confidence Score: 
                                                                                                                </div>
                                                                                                                <div>
                                                                                                                    {
                                                                                                                        model.confidenceScore+" %"
                                                                                                                    }                                                                                                    
                                                                                                                </div>
                                                                                                            </div>                                                                                                            
                                                                                                        </TableCell>
                                                                                                    </TableRow>                                                                                                    
                                                                                                )
                                                                                            })
                                                                                        }
                                                                                    </>                                                                         

                                                                        }
                                                                    </TableBody>
                                                                </Table>
                                                            </div>                                                        
                                                        : props.modelInformation?.modelType === "video" || props.purposeModelInformation?.model?.type === "video"
                                                          ? <div style={{width: '100%'}}>
                                                                <Typography variant='h6'>{props.selectedResource.fileName}</Typography>
                                                                <video controls style={{width: "100%"}} src={props.selectedResource.resourceUrl} />
                                                                {
                                                                    // props.modelInformation?.model?.analyticsAiAssistantEnabled &&
                                                                    (
                                                                    ["656f5e0e29b391c0646cc554"].includes(props.modelInformation?.model?._id || "")
                                                                    ?
                                                                    <>
                                                                        {
                                                                            (["askingUserIFHeIsSeeingSameHandWithMultipleIds", "showingHandIdInputFieldsToUser"] as SameHandsIdsInputStateType["scenario"][]).includes(sameHandIdsInputState.scenario) &&
                                                                                <div
                                                                                    style={{
                                                                                        display: "flex",
                                                                                        gap: "10px"
                                                                                    }}
                                                                                >
                                                                                    <div>Are you seeing the same hand with multiple ids ?</div>
                                                                                    <Button size="small" variant={sameHandIdsInputState.didUserFoundSameHandHavingMultipleIds === true ? "contained" : "outlined"} color="primary"
                                                                                        onClick={()=>{
                                                                                            setSameHandIdsInputState(oldState=>{
                                                                                                return {
                                                                                                    ...oldState,
                                                                                                    didUserFoundSameHandHavingMultipleIds: true,
                                                                                                    scenario: "showingHandIdInputFieldsToUser",
                                                                                                    hands: []
                                                                                                }
                                                                                            })
                                                                                        }}
                                                                                    >
                                                                                        {
                                                                                            "Yes"
                                                                                        }                                                                                                                                                                            
                                                                                    </Button>
                                                                                    <Button size="small" variant={sameHandIdsInputState.didUserFoundSameHandHavingMultipleIds === false ? "contained" : "outlined"} color="primary"
                                                                                        disabled={sameHandIdsInputState.savingScenario === "userDidn'tFoundSameHandWithMultipleIds" ? true : false}
                                                                                        onClick={async ()=>{
                                                                                            setIsCallingAnalyticsAiAssistantAPi(true)

                                                                                            setSameHandIdsInputState(oldState=>{
                                                                                                return {
                                                                                                    ...oldState,
                                                                                                    savingScenario: "userDidn'tFoundSameHandWithMultipleIds"
                                                                                                }
                                                                                            })

                                                                                            await DataService.updateData({
                                                                                                id: [props.selectedResource?._id || ""],
                                                                                                handsData: {
                                                                                                    didUserFoundSameHandHavingMultipleIds:  false,
                                                                                                    hands: []
                                                                                                }                                                                                                
                                                                                            })

                                                                                            await handleAiAnalyticsButtonClick();

                                                                                            setIsCallingAnalyticsAiAssistantAPi(false)

                                                                                            props.setTableData(oldState=>{
                                                                                                for (const resource of oldState) {
                                                                                                    if (resource._id === props.selectedResource?._id) {
                                                                                                        resource.handsData = {
                                                                                                            ...sameHandIdsInputState,
                                                                                                            didUserFoundSameHandHavingMultipleIds: false
                                                                                                        }
                                                                                                    }
                                                                                                }
                                                                                                return [
                                                                                                    ...oldState
                                                                                                ]
                                                                                            })                                                                                           

                                                                                            setSameHandIdsInputState(oldState=>{
                                                                                                return {
                                                                                                    ...oldState,
                                                                                                    didUserFoundSameHandHavingMultipleIds: false,
                                                                                                    scenario: "askingUserIFHeIsSeeingSameHandWithMultipleIds",
                                                                                                    savingScenario: ""
                                                                                                }
                                                                                            })
                                                                                        }}
                                                                                    >
                                                                                        {
                                                                                            sameHandIdsInputState.savingScenario === "userDidn'tFoundSameHandWithMultipleIds"
                                                                                            ? <CircularProgress color="primary" size={24} />
                                                                                            : "No"
                                                                                        }
                                                                                    </Button>
                                                                                </div>
                                                                        }
                                                                        {
                                                                            sameHandIdsInputState.scenario === "showingHandIdInputFieldsToUser" &&
                                                                            <div
                                                                                style={{
                                                                                    display: "flex",
                                                                                    gap: "5px",
                                                                                    marginTop: "19px"
                                                                                }}
                                                                            >
                                                                                <TextField 
                                                                                    style={{
                                                                                        flexGrow: 1
                                                                                    }}
                                                                                    value={sameHandIdsInputState.hands[0]}
                                                                                    label={"Enter those ids"}
                                                                                    helperText={"example: 1-5, 8, 11-13"}
                                                                                    onChange={(e)=>{
                                                                                        setSameHandIdsInputState(
                                                                                            // @ts-ignore
                                                                                            oldState=>{
                                                                                            return {
                                                                                                ...oldState,
                                                                                                hands: [e.target.value || ""]
                                                                                            }
                                                                                        })
                                                                                    }}
                                                                                />
                                                                                <Button color="primary" variant="contained" size="small"
                                                                                    disabled={sameHandIdsInputState.savingScenario === "userFoundSameHandWithMultipleIds" ? true : false}
                                                                                    onClick={async ()=>{

                                                                                        setSameHandIdsInputState(oldState=>{
                                                                                            return {
                                                                                                ...oldState,
                                                                                                savingScenario: "userFoundSameHandWithMultipleIds"
                                                                                            }
                                                                                        })                                                                                        

                                                                                        const handsDataRequestPayload = copyByValue(sameHandIdsInputState);
                                                                                        // @ts-ignore
                                                                                        delete handsDataRequestPayload.scenario;

                                                                                        await DataService.updateData({
                                                                                            id: [props.selectedResource?._id || ""],
                                                                                            handsData: handsDataRequestPayload
                                                                                        })                                                                                         

                                                                                        await handleAiAnalyticsButtonClick();

                                                                                        props.setTableData(oldState=>{
                                                                                            for (const resource of oldState) {
                                                                                                if (resource._id === props.selectedResource?._id) {
                                                                                                    resource.handsData = {...sameHandIdsInputState}
                                                                                                }
                                                                                            }
                                                                                            return [
                                                                                                ...oldState
                                                                                            ]
                                                                                        })                                                                                        

                                                                                        setSameHandIdsInputState(oldState=>{
                                                                                            return {
                                                                                                ...oldState,
                                                                                                savingScenario: ""
                                                                                            }
                                                                                        })
                                                                                    }}
                                                                                >
                                                                                    {
                                                                                        sameHandIdsInputState.savingScenario === "userFoundSameHandWithMultipleIds"
                                                                                        ? <CircularProgress size={24} color="primary" />
                                                                                        : "Save"
                                                                                    }
                                                                                </Button>
                                                                            </div>
                                                                        }
                                                                        {

                                                                        }
                                                                                {/* <div
                                                                                    style={{
                                                                                        display: "flex",
                                                                                        gap: "15px",
                                                                                        alignItems: "center"
                                                                                    }}
                                                                                >
                                                                                    <div>{props.modelInformation?.model?.analyticsAiAssistantDescription}</div>
                                                                                    <Button size="small" variant="contained" color="primary"
                                                                                        disabled={isCallingAnalyticsAiAssistantAPi}
                                                                                        onClick={()=>{
                                                                                            // handleSameHandYesClick()
                                                                                            // handleAiAnalyticsButtonClick()
                                                                                        }}
                                                                                    >
                                                                                        {
                                                                                            isCallingAnalyticsAiAssistantAPi
                                                                                            ? <CircularProgress color='primary' />
                                                                                            : "Yes"
                                                                                        }
                                                                                    </Button>
                                                                                </div> */}
                                                                    </>
                                                                        // }
                                                                    :

                                                                    props.modelInformation?.model?.analyticsAiAssistantEnabled && <Tooltip title={props.modelInformation?.model?.analyticsAiAssistantDescription || ""}>
                                                                        <span style={{display: "inline-block", cursor: "pointer"}}>
                                                                            {
                                                                                isCallingAnalyticsAiAssistantAPi
                                                                                ? <CircularProgress color='primary' />
                                                                                :
                                                                                    <AiAssistantIcon 
                                                                                        onClick={async ()=>{
                                                                                            handleAiAnalyticsButtonClick()
                                                                                        }}
                                                                                    />
                                                                            }
                                                                        </span>
                                                                    </Tooltip>

                                                                    )

                                                                }
                                                            </div>
                                                        : <></>
                                    }

                                </div>
                                {/* <div style={{marginTop: '40px'}}>
                                    <div className={clsx(classes.flex, classes.contentSpaceBetween, classes.infoContainer)}>
                                        <p>
                                            <span className={classes.infoTitle}>Version</span>
                                            <span style={{color: '#212121'}}>{props.modelCollectionsToCompareWithOptions.find(option => option.version === props.selectedModelVersion)?.version}</span>
                                        </p>
                                        <p>
                                            <span className={classes.infoTitle}>Confidence</span>
                                            {
                                                props.selectedResource.confidence_score > 0 ?
                                                        <Chip key={props.selectedResource.confidence_score} label={props.selectedResource.confidence_score + " %"} classes={{ root: `${classes.chip} ${classes.outlinedChip}` }} />
                                                    :
                                                    <Chip key={'NA'} label={'NA'} classes={{ root: classes.chip }} />
                                            }
                                        </p>
                                    </div>

                                        <div className={clsx(classes.flex, classes.contentSpaceBetween, classes.infoContainer)} >
                                        <p className={classes.statusContainer}>
                                            <span className={classes.infoTitle}>Status</span>
                                            <ModelStatus status={props.modelInformation?.status || 'Queued'}/>
                                        </p>
                                        <p>
                                            <span className={classes.infoTitle}>Predicted Status</span>
                                            <Chip key={props.selectedResource.prediction} label={props.selectedResource.prediction} classes={{ root: classes.chip }} />
                                        </p>
                                    </div>

                                    <div className={clsx(classes.flex, classes.contentSpaceBetween, classes.infoContainer)} >
                                        <p>
                                                <span className={classes.infoTitle}>Training Dataset Collection</span>
                                                <Tooltip title={props.modelInformation?.trainingDatasetCollection?.join(", ") || "NA"}>
                                                    <Typography
                                                        component="p"
                                                        noWrap
                                                        style={{justifyContent: 'end'}}
                                                    >
                                                    {
                                                        props.modelInformation?.trainingDatasetCollection?.map(
                                                        (trainingDataDirectory) => (
                                                            <Chip
                                                                key={trainingDataDirectory}
                                                                label={trainingDataDirectory}
                                                                classes={{ root: classes.chip }}
                                                                style={{
                                                                    whiteSpace: "nowrap",
                                                                    overflow: "hidden",
                                                                    textOverflow: "ellipsis",
                                                                    maxWidth: "100%",
                                                                }}
                                                            />
                                                        )
                                                        ) || <Chip key={'NA'} label={'NA'} classes={{ root: classes.chip }} />
                                                        }
                                                        </Typography>
                                                </Tooltip>
                                        </p>
                                        <p>
                                            <span className={classes.infoTitle}>Test Dataset</span>
                                            <Chip key={props.modelInformation?.testDatasetCollection?.name || 'NA'} label={props.modelInformation?.testDatasetCollection?.name || 'NA'} classes={{ root: classes.chip }} />
                                        </p>
                                    </div>
                                </div> */}
                            </section>
                        }

                        <section 
                            style={{
                                height: 
                                    props.modelInformation?.modelType === 'image' || 
                                    props.modelInformation?.modelType === 'imageAnnotation' ||
                                    props.modelInformation?.modelType === 'imageAnnotationGroup' || 
                                    props.purposeModelInformation?.model?.type === "image" ||
                                    props.purposeModelInformation?.model?.type === "imageAnnotation" ||
                                    props.purposeModelInformation?.model?.type === "imageAnnotationGroup"
                                    ? '520px' : 
                                    (props.modelInformation?.modelType === "audioTextTranscription"||props.purposeModelInformation?.model?.type === "audioTextTranscription") ?
                                    'auto':'550px', 
                                paddingBottom: 
                                    props.modelInformation?.modelType === 'image' ||
                                    props.modelInformation?.modelType === 'imageAnnotation' ||
                                    props.purposeModelInformation?.model?.type === "image" ||
                                    props.purposeModelInformation?.model?.type === "imageAnnotation"
                                    ? '0' : '25px'
                            }}
                            className ={`${isExpandFeatureEnabled ? classes.sectionDetailsWhenExpanded : classes.sectionDetailsWhenNotExpanded}`}
                        >
                            {
                                props.modelInformation?.modelType !== "audioTextTranscription" &&
                                props.modelInformation?.modelType !== "DLP" &&
                                props.modelInformation?.modelType !== "textAnnotation" &&
                                props.modelInformation?.modelType !== "bookTranslation" &&
                                <div style={{height: "60%"}}>
                                <TableContainer className={classes.tableContainer}>
                                    <Table className={classes.table} stickyHeader size="small">
                                        {
                                            !urlQuery.get("purposeModelId")
                                            &&
                                            <TableHead>
                                            <TableRow>
                                                <TableCell width={100}></TableCell>
                                                <TableCell width={100}>
                                                    {/* <Typography style={{marginTop:'calc(12.25px + 0.5rem)'}} component="div" classes={{root: classes.flex}}>
                                                        <span style={{ marginRight: '10px'}}>
                                                            {props.selectedModelVersion || 'NA'}
                                                        </span>
                                                        <Tooltip classes={{ tooltip: classes.tooltip, arrow: classes.tooltipArrow }} title={getTooltipTitle(props.selectedModelVersion)} placement='top' arrow={true} >
                                                            <InfoRoundedIcon fontSize='small' color='primary' classes={{ colorPrimary: classes.infoIcon }} />
                                                        </Tooltip>
                                                    </Typography> */}
                                                    <SelectedModelVersionComponent />
                                                </TableCell>
                                                    {
                                                        props.modelCollectionsToCompareWithOptions.length > 1 &&
                                                        <TableCell width={150}>
                                                            {/* <Autocomplete
                                                                style={{ width: '130px', border: 'none', position: 'relative' }}
                                                                id="model_field"
                                                                closeIcon={() => { }}
                                                                options={props.modelCollectionsToCompareWithOptions.filter(option => option.version !== props.selectedModelVersion).map(option => option.version)}
                                                                value={modelVersionToCompareWith}
                                                                getOptionLabel={(versionOption) => {
                                                                    const versionLabel = props.modelCollectionsToCompareWithOptions.filter(
                                                                        (option) => option.version === versionOption.toString()
                                                                    )[0]?.version;
                                                                    if (versionLabel) {
                                                                        return versionLabel.toString();
                                                                    } else {
                                                                        return versionOption;
                                                                    }
                                                                }
                                                                }
                                                                onChange={(event, value) => {
                                                                    compareWithValueChangeHandler(value || '')
                                                                }}

                                                                renderInput={(params) => (
                                                                    <>
                                                                        <TextField
                                                                            {...params}
                                                                            size="small"
                                                                            margin="normal"
                                                                            fullWidth
                                                                            // value={this.state.filter.resourceDataSetCollectionId}
                                                                            label="Compare with"
                                                                            classes={{ root: classes.compareWithInput }}
                                                                        // className={classes.floating}
                                                                        />
                                                                        <Tooltip classes={{ tooltip: classes.tooltip, arrow: classes.tooltipArrow }} title={getTooltipTitle(modelVersionToCompareWith)} placement='top' arrow={true} >
                                                                            <InfoRoundedIcon fontSize='small' color='primary' classes={{ colorPrimary: classes.infoIcon }} style={{ position: 'absolute', top: '54%', right: '15%' }} />
                                                                        </Tooltip>
                                                                    </>
                                                                )} /> */}
                                                                <ModelVersionToCompareWithInputField />
                                                        </TableCell>
                                                }
                                            </TableRow>
                                        </TableHead>
                                        }
                                        <TableBody>
                                            {
                                                labelNames?.length &&
                                                labelNames.map((value, index) => {
                                                    return (
                                                        <TableRow key={value.name + "_" + index}>
                                                            <TableCell width={100}>{value.name.toUpperCase()}</TableCell>
                                                            {
                                                                selectedVersionLabelValue &&
                                                                    isFetchingSelectedModelVersionDetails ?
                                                                    <TableCell height={55} width={150}>
                                                                        <CustomSkeleton height={50} width={55} />
                                                                    </TableCell>
                                                                    :
                                                                    <TableCell className={props.modelCollectionsToCompareWithOptions.length < 2 ? `${classes.centerAlign}`: ''} height={55} width={150}>
                                                                        <Tooltip classes={{ tooltip: classes.tooltip, arrow: classes.tooltipArrow }} title={'Confidence Score'} placement='bottom' arrow={true} >
                                                                            <span className={classes.tableCellBorder}
                                                                                style={{
                                                                                    borderColor: 
                                                                                    value.name === props?.selectedResource?.label
                                                                                    ? "#02BC77" // "green"
                                                                                    : value.name === selectedResourcePrediction
                                                                                      ?  "red"
                                                                                      :  "grey"
                                                                                    
                                                                                }}
                                                                            >{getConfidenceScoreForLabel(value.name, selectedVersionLabelValue)}</span>
                                                                        </Tooltip>
                                                                        
                                                                    </TableCell>
                                                            }
                                                            {
                                                                props.modelCollectionsToCompareWithOptions.length > 1 && isFetchingVersionDetailsToCompareWith &&
                                                                    <TableCell height={55} width={150}>
                                                                        <CustomSkeleton height={50} width={55} />
                                                                    </TableCell>
                                                            }
                                                            {
                                                                props.modelCollectionsToCompareWithOptions.length > 1 &&
                                                                !isFetchingVersionDetailsToCompareWith &&
                                                                <TableCell height={55} width={150}>
                                                                    <Tooltip classes={{ tooltip: classes.tooltip, arrow: classes.tooltipArrow }} title={'Confidence Score'} placement='bottom' arrow={true} >
                                                                        <span className={classes.tableCellBorder}
                                                                            style={{
                                                                                borderColor: 
                                                                                value.name === props?.selectedResource?.label
                                                                                ? "#02BC77" // "green"
                                                                                : props.fetchedLabelConfidenceScores[modelCollectionIdToCompareWith]?.prediction === value.name
                                                                                  ?  "red"
                                                                                  :  "grey"                                                                                
                                                                            }}
                                                                        >{getConfidenceScoreForLabel(value.name, compareWithVersionLabelValue)}</span>
                                                                    </Tooltip>
                                                                </TableCell>
                                                            }
                                                        </TableRow>
                                                    )
                                                })
                                            }
                                        </TableBody>
                                    </Table>
                                </TableContainer>

                                
                                {metaDataList && metaDataList.length > 0 && ( <>
                                    <Button size="small" color="primary" variant="outlined" onClick={() => setMetaDataPopup(true)}>
                                     View MetaData
                                    </Button>
                                    <Dialog
                                        open={metadataPopup}
                                        aria-labelledby='dialog-title'
                                        aria-describedby='dialog-description'
                                        maxWidth='md'
                                        >
                                        <DialogTitle id='dialog-title'>Metadata</DialogTitle>
                                        <DialogContent>
                                            {metaDataList.map(([key, keyValue]:[string,any]) => (
                                            <div key={key}>
                                            <strong style={{paddingRight:"10px"}}>{key}  </strong>
                                            {Array.isArray(keyValue) ? (
                                            <ul>
                                            {keyValue.map((item, index) => (
                                            <li key={index}>{item}</li>
                                            ))}
                                            </ul>
                                            ) : (
                                            <span>{keyValue}</span>
                                            )}
                                            </div>
                                            ))}
                                    </DialogContent>
                                    <DialogActions>
                                    <Button onClick={() => {setMetaDataPopup(false)}}>Exit</Button>
                                    </DialogActions>
                                   </Dialog>                              
                        </>)}   

                        {contentArray1 && contentArray1.length > 0 && ( <>

                                    <Button style={{marginLeft:'10px'}} size="small" color="primary" variant="outlined" onClick={() => setRefDataPopup(true)}>
                                        Ref Data
                                    </Button>
                                    <Dialog
                                        open={refDataPopup}
                                        onClose={handleCloseRefDataPopup}
                                        maxWidth="md"
                                        fullWidth
                                        PaperProps={{
                                            style: {
                                                minHeight: '40vh',
                                                maxWidth: '65%', 
                                                maxHeight: '90vh',
                                                display: 'flex',  // Set display to flex
                                                flexDirection: 'column',  // Align children vertically
                                            },
                                        }}
                                    >
                                        <div style={{ flex: 1, position: 'relative', display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'center'}}>
                                         
                                            {contentArray1.map((item, index) =>
                                                    (
                                                        <div key={index} style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', margin: '2rem', width: '100%'}}>
                                                            <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', maxWidth: '50%', minWidth:'35%' }}>
                                                                <img
                                                                    //@ts-ignore
                                                                    src={item.resourceSignedUrl}
                                                                    alt={`Image unable to render`}
                                                                    style={{ width: '100%', height: 'auto', margin: '10px' }}
                                                                />
                                                            </div>
                                                                <table style={{ borderCollapse: 'collapse', width: '50%', marginLeft:'2rem', marginRight:'1rem' }}>
                                                                    <tbody>
                                                                        {
                                                                            <tr style={{ marginBottom: '10px' }}>
                                                                                <td style={{ height: '3rem' }}>File Name:</td>
                                                                                <td style={{ height: '3rem' }}>
                                                                                    {
                                                                                        //@ts-ignore
                                                                                        item.resourceField.resource
                                                                                    }
                                                                                </td>
                                                                            </tr>
                                                                        }
                                                                        {
                                                                            //@ts-ignore
                                                                            item.showPredictionStatus === "true" &&
                                                                            <tr style={{ marginBottom: '2rem' }}>
                                                                                <td style={{ height: '4rem' }}>Label :</td>

                                                                                {/* <td style={{ height: '3.7rem' }}>
                                                                                    {
                                                                                        //@ts-ignore
                                                                                        contentArray1[currentIndex].resourceField.prediction
                                                                                    }
                                                                                </td> */}
                                                                                <td style={{ height: '3.7rem' }}>
                                                                                    <LabelEditText 
                                                                                        // @ts-ignore
                                                                                        resource={item?.resourceField}
                                                                                        readonly={true} 
                                                                                        editTextComponentAdditionalCSSClassNames={classes.referenceDataLabel}
                                                                                        onEditTextFocus={()=>{}} 
                                                                                        onEditTextBlurEvent={()=>{}} 
                                                                                        placeWhereComponentBeingUsed={'reference-resource-analytics'} 
                                                                                        labelsAvailable={[]}                                    />
                                                                                </td>
                                                                            </tr>
                                                                        }
                                                                        {
                                                                            //@ts-ignore
                                                                            item.showConfidenceScore === "true" &&
                                                                            <tr style={{ marginBottom: '10px' }}>
                                                                                <td style={{ height: '3rem' }}>Confidence_Score:</td>
                                                                                <td style={{ height: '3rem' }}>
                                                                                    {
                                                                                        //@ts-ignore
                                                                                        item.resourceField.confidence_score
                                                                                    }
                                                                                </td>
                                                                            </tr>
                                                                        }

                                                                        <tr style={{ marginBottom: '10px' }}>
                                                                            <td style={{ height: '3rem' }}>Tag:</td>
                                                                            <td style={{ height: '3rem' }}>
                                                                                <span style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', backgroundColor: '#f4f5f7', borderRadius: '2rem', width: '7rem', height: '2.7rem' }}>
                                                                                    {
                                                                                        //@ts-ignore
                                                                                        item.resourceField.tag
                                                                                    }
                                                                                </span>
                                                                            </td>
                                                                        </tr>
                                                                    </tbody>
                                                                </table>
                                                            </div>
                                                    )
                                                )
                                            }
                                        </div>
                                    </Dialog>


                                                           
                        </>)}                              
                                </div>
                            }
                            {props.selectedResource && 
                            <div style={{marginTop: '40px'}}>
                                    <div className={clsx(classes.flex, classes.contentSpaceBetween, classes.infoContainer, (props.modelInformation?.modelType === "DLP" || props.modelInformation?.modelType === "textAnnotation") && classes.infoContainerWhenModelTypeDLP)}>
                                        {
                                            !urlQuery.get("purposeModelId")
                                            &&
                                        <p>
                                            <span className={classes.infoTitle}>Version</span>
                                            <span style={{color: '#212121'}}>{props.modelCollectionsToCompareWithOptions.find(option => option.modelCollectionId === props.selectedModelCollectionId)?.version}</span>
                                        </p>
                                        }
                                        <p>
                                            <span className={classes.infoTitle}>Confidence</span>
                                            {
                                                props.selectedResource.confidence_score > 0 ?
                                                        <Chip key={props.selectedResource.confidence_score} label={props.selectedResource.confidence_score + " %"} classes={{ root: `${classes.chip} ${classes.outlinedChip}` }} />
                                                    :
                                                    <Chip key={'NA'} label={'NA'} classes={{ root: classes.chip }} />
                                            }
                                        </p>
                                    </div>

                                    <div className={clsx(classes.flex, classes.contentSpaceBetween, classes.infoContainer, (props.modelInformation?.modelType === "DLP" || props.modelInformation?.modelType === "textAnnotation") && classes.infoContainerWhenModelTypeDLP)} >
                                        <p>
                                                <span className={classes.infoTitle}>Training Dataset Collection</span>
                                                <Tooltip title={props.modelInformation?.trainingDatasetCollection?.join(", ") || "NA"}>
                                                    <Typography
                                                        component="p"
                                                        noWrap
                                                        style={{justifyContent: 'end'}}
                                                    >
                                                    {
                                                        props.modelInformation?.trainingDatasetCollection?.map(
                                                        (trainingDataDirectory) => (
                                                            <Chip
                                                                key={trainingDataDirectory}
                                                                label={trainingDataDirectory}
                                                                classes={{ root: classes.chip }}
                                                                style={{
                                                                    whiteSpace: "nowrap",
                                                                    overflow: "hidden",
                                                                    textOverflow: "ellipsis",
                                                                    maxWidth: "100%",
                                                                }}
                                                            />
                                                        )
                                                        ) || <Chip key={'NA'} label={'NA'} classes={{ root: classes.chip }} />
                                                        }
                                                        </Typography>
                                                </Tooltip>
                                        </p>
                                        <p>
                                            <span className={classes.infoTitle}>Test Dataset</span>
                                            <Chip key={props.modelInformation?.testDatasetCollection?.name || 'NA'} label={props.modelInformation?.testDatasetCollection?.name || 'NA'} classes={{ root: classes.chip }} />
                                        </p>
                                    </div>

                                    <div className={clsx(classes.flex, classes.contentSpaceBetween, classes.infoContainer, (props.modelInformation?.modelType === "DLP" || props.modelInformation?.modelType === "textAnnotation") && classes.infoContainerWhenModelTypeDLP)} >
                                        {shouldShowStatus && <p className={classes.statusContainer}>
                                            <span className={classes.infoTitle}>Status</span>
                                            <ModelStatus 
                                                rootElementProps={{
                                                    className:`
                                                        ${props.modelInformation?.modelType === "DLP" || props.modelInformation?.modelType === "textAnnotation" ? classes.modelStatusWhenModelTypeDLP : ""}
                                                    `
                                                }}
                                            status={props.modelInformation?.status || 'Queued'}/>
                                        </p>}
                                        {
                                            props.modelInformation?.modelType !== "bookTranslation" &&
                                            <p>
                                                <span className={classes.infoTitle}>Predicted Status</span>
                                                <Chip key={props.selectedResource.prediction} 
                                                label={
                                                    props.modelInformation?.model.isAnalyticsBasedOnWordErrorRateEnabled
                                                    ?   props.selectedResource.predictionStatus === "CORRECT"
                                                        ? "Correct"
                                                        : "Incorrect"
                                                    :   props.selectedResource.prediction === "predicted" ||
                                                        props.selectedResource.prediction === props.selectedResource.label
                                                        ? "Correct"
                                                        : "Incorrect"
                                                } 
                                                classes={{ root: classes.chip }} />
                                            </p>
                                        }
                                    </div>                                    

                                    {
                                        (props.modelInformation?.modelType === "audio" || props.modelInformation?.modelType === "audioTextTranscription" || props.modelInformation?.modelType ==="video") &&
                                        <div>
                                            <AudioResourceHyperlink 
                                                linkLabel="Open in Task Data Table"
                                                resourceFileName={props.selectedResource.fileName || ""}
                                                resourceStatus={props.selectedResource.status || "approved"}
                                                modelId={modelId}
                                                resourceId={props?.selectedResource?._id ??""}
                                            />
                                        </div>
                                    }

                                </div>}
                        </section>
                    </Box>
                   { props.modelInformation?.modelType === "audioTextTranscription" && <Box>
                        <AudioTranscriptionCompare 
                            groundTruthLabel={props.selectedResource?.label || ""}
                            predictedLabel={props.selectedResource?.prediction || ""}
                            wordErrorRate={props.selectedResource?.wordErrorRate} 
                            currentTimePlaying={currentTimePlayingInAudioTranscription}
                            totalTime={totalTimePlayingInAudioTranscription}
                            metaData={props.selectedResource?.audioTranscriptionWordsMetadatas || []}
                        />
                    </Box>}
                    </Box>
                    <div className={clsx(classes.rightIndicator, classes.indicators)}>
                        <IconButton
                            aria-label="previous"
                            color="primary"
                            disabled={!props.showRightArrow}
                            classes={{ disabled: classes.disabledIcon }}
                            onClick={() => props.actions.onArrowClickHanlder(1)}
                        >
                            <KeyboardArrowRight />
                        </IconButton>
                    </div>
                </div>
            {/* </DialogContent> */}
            </div>
        </Dialog>
    );
}

function ResourceTextSection(props: {
    selectedModelCollectionId: string;
    selectedModelVersionResourceText: string;
    selectedModelVersionResourceTrimmedTexts: TrimmedText[];
    SelectedModelVersionComponent: (selectedModelVersionComponentProps: SelectedModelVersionComponentProps) => JSX.Element;    
    ModelVersionToCompareWithInputField: (modelVersionToCompareWithInputFieldComponentProps: ModelVersionToCompareWithInputFieldComponentProps) => JSX.Element;
    modelCollectionIdToCompareWith: string;
    isFetchingVersionDetailsToCompareWith: boolean;
    modelCollectionIdToCompareWithResourceText: string;
    modelVersionToCompareWithTrimmedTexts: TrimmedText[];
    modelType: IModelType;
}) {

    const ModelVersionToCompareWithInputField = props.ModelVersionToCompareWithInputField;
    const SelectedModelVersionComponent = props.SelectedModelVersionComponent;

    return (
        <>
            <section
                data-id="selectedModelVersionSection"
                style={{
                    width: "100%",
                    display: "flex",
                    flexDirection: "column",
                    alignItems: "flex-start"
                }}
            >
                <SelectedModelVersionComponent 
                    scenario="modelTypeDLP"
                />

                <br />

                <TextTrimUI 
                    key={JSON.stringify(props.selectedModelVersionResourceTrimmedTexts)}
                    scenario="modelAnalyticsResourcesViewPopup"
                    resource={{
                        resource: props.selectedModelVersionResourceText,
                        trimmedTexts: props.selectedModelVersionResourceTrimmedTexts,
                        _id: "",
                        status: undefined as unknown as any,
                        filename: "",
                        tag: "",
                        coPilot: undefined as unknown as any,
                        created_at: ""
                    } as unknown as any} 
                    isViewMode={true}
                    updateResourceTrimmedTextsInUI={()=>{}} 
                    isInCopilot={false} 
                    modelType={props.modelType}
                />
            </section>

            <div
                style={{
                    borderTop: "1px solid black",
                    marginTop: "42px",
                    marginBottom: "20px"
                }}
            ></div>

            <section
                data-id="compareWithModelSection"
            >
                <ModelVersionToCompareWithInputField 
                    scenario="modelTypeDLP"
                />

                {
                    props.modelCollectionIdToCompareWith &&
                    <>
                    {
                        props.isFetchingVersionDetailsToCompareWith
                        ? "Loading..."
                        :
                            props.modelCollectionIdToCompareWithResourceText
                            ?
                                <TextTrimUI 
                                    key={JSON.stringify(props.modelVersionToCompareWithTrimmedTexts)}
                                    scenario="modelAnalyticsResourcesViewPopup"
                                    resource={{
                                        resource: props.modelCollectionIdToCompareWithResourceText,
                                        trimmedTexts: props.modelVersionToCompareWithTrimmedTexts,
                                        _id: "",
                                        status: undefined as unknown as any,
                                        filename: "",
                                        tag: "",
                                        coPilot: undefined as unknown as any,
                                        created_at: ""
                                    } as unknown as any} 
                                    updateResourceTrimmedTextsInUI={()=>{}} 
                                    isInCopilot={false} 
                                    isViewMode={true}
                                    modelType={props.modelType}
                                />         
                            :
                                <div>No resource found</div>
                    }
                    </>
                }
            </section>

            <br/>
            <br/>
        </>
    );
}

export default AnalyticsDataViewDialog;


const useAudioResourceHyperlinkStyles = makeStyles((theme) => ({
  link: {
      "&:hover": {
          color: theme.palette.primary.main
      }
  }
}));
function AudioResourceHyperlink(props: {
    linkLabel: string;
    resourceFileName: string;
    resourceStatus: ResourceStatus;
    modelId: string;
    resourceId: string
}) {
    const classes = useAudioResourceHyperlinkStyles();

    const urlSearchParams = new URLSearchParams(window.location.search);

    const projectId: string = urlSearchParams.get("project") || "";
      localStorage.setItem(RESOURCE_IDS_TO_FILTER, JSON.stringify([props.resourceId]))


    const link: string = (()=>{
        const pathNameTillModelDataTable: string = (()=>{
            return `${window.location.pathname.substring(0, window.location.pathname.indexOf("/collections"))}`
        })();

        return `${window.location.origin}${pathNameTillModelDataTable}/data/${props.resourceStatus}?project=${projectId}&model=${props.modelId}&type=audio&resourceSortOrder=descending&resourceSortField=statusLastModifiedAt&resourceStatus=${props.resourceStatus}&resourceOffset=1&resourceLimit=10&resourceNameSearchValue=${props.resourceFileName}&resourceNameSearchOperator=equals`
    })();

    return (
        <Link classes={{root: classes.link}} color="primary" rel="noreferrer noopener" target="_blank" href={link}>{props.linkLabel}</Link>
    )
}
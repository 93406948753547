import {
  CircularProgress,
  makeStyles,
  Paper,
  Tooltip,
  withStyles,
  IconButton
} from "@material-ui/core";
import {
  GetIntegrityAPIModelCollectionsAdditionalDataQueryParamType,
  IReduxState,
} from "../../../../../../../../../../../common";
import { connect, ConnectedProps } from "react-redux";
import clsx from "clsx";
import ArrowBackIcon from "@material-ui/icons/ArrowBack";
import ArrowForwardIcon from "@material-ui/icons/ArrowForward";
import { useEffect, useMemo, useRef, useState } from "react";
import { DataService } from "../../../../../../../../../../../services";
import { IIntegrity } from "../../../../../../../../../../../common";
import CheckCircleIcon from "@material-ui/icons/CheckCircle";
import CancelIcon from "@material-ui/icons/Cancel";
import { useHistory, useLocation } from "react-router";
import React from "react";
import { ModelCollectionAccuracy } from "./ModelCollectionAccuracy";
import pinIcon from "../components/Icons/Pin.svg";
import unPinIcon from "../components/Icons/Unpin.svg";
import { SortField, SortOrder } from "./AnalyticsTable";
import { useURLQuery } from "../../../../../../../../../../../hooks/useURLQuery";
import { QUERY_PARAMS } from "../../../../../components/ModelsList";
import FilterListIcon from '@material-ui/icons/FilterList';
import MoreVertIcon from '@material-ui/icons/MoreVert';
import IntegrityPageFilterPopup, { INTEGRITY_PAGE_FILTER_ICON_BUTTON_ELEMENT_ID } from "./IntegrityPageFilterPopup";
import { isNullOrUndefined } from "../../../../../../../../../../../services/variableHelperService";
// const integrityBandArr = Array.from(Array(12));

export enum URLQueryParams {
  MODEL_COLLECTIONS_ADDITIONAL_DATA="modelCollectionsAdditionalData"
}

export type IntegrityFilterForModelCollection = {
  modelCollectionId: string,
  modelVersion: string,
  // confidenceScoreThreshold: number,
  iouThreshold: number
}


function parseValue(value: any) {
  try {
    const myNum = Number(value);
    if (
      typeof myNum === "number" &&
      !Number.isNaN(myNum) &&
      !Number.isInteger(myNum)
    ) {
      return myNum.toFixed(2);
    }
  } catch (e) {
    return value;
  }
  return value;
}

const useStyles = makeStyles((theme) => ({
  paper: {
    width: "100%",
    marginBottom: theme.spacing(2),
    height: "100%",
  },
  container: {
    padding: "15px 15px 0 15px",
    overflow: "auto",
    // height: '630px',
    height: "calc(100% - 102px)",
  },
  title: {
    letterSpacing: "0.76px",
    color: "#333333",
    marginLeft: "15px",
  },
  subtitle: {
    color: "#838C95",
    fontSize: "1rem",
    letterSpacing: "0.3px",
    textAlign: "center",
    marginTop: "10px",
    marginBottom: "10px",
  },
  table: {
    borderSpacing: "20px",
    width: "100%",
    borderCollapse: "separate",
    color: "#838C95",
  },
  textCenter: {
    textAlign: "center",
  },
  flexContainer: {
    display: "flex",
    justifyContent: "center",
    gap: "25px",
  },
  headerRow: {
    borderBottom: "15px solid transparent",
  },
  tableContainer: {
    display: "flex",
    gap: "50px",
    marginLeft: "30px",
    height: "calc(100% - 94px)",
    overflow: "auto",
  },
  dataSets: {
    color: "#008D9C",
    fontWeight: 600,
    font: "normal normal 600 0.9375rem/1.25rem Nunito Sans",
    fontSize: "0.9375rem",
    alignSelf: "center",
    minWidth: "45px",
  },
  confidenceScore: {
    textAlign: "left",
    minWidth: "70px",
    cursor: "pointer",
    width: "80px",
    padding: "0 20px",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    opacity: 1,
    font: "normal normal bold 0.875rem/1.1875rem Nunito Sans;",
    letterSpacing: "0.28px",
    borderRadius: "10px",
    height: "32px",
    margin: "0 auto",
    color: "#838C95",
    transition: "all .3s ease-out",
    fontWeight: "bold",
    "&:hover": {
      font: "normal normal bold 1rem/1.375rem Nunito Sans",
      background: " #D4E8EA 0% 0% no-repeat padding-box",
      transform: "scale(1.0)",
      justifyContent: "center",
    },
    // "&:hover": {
    //   backgroundColor: "#028D9A26",
    //   borderRadius: "5px",
    // },
  },
  badConfidenceScore: {
    color: "#D6100F",
    fontWeight: "bold",
    textAlign: "right",
    width: "80px",
    padding: "0 20px",
    justifyContent: "center",
  },
  accuracyBtn: {
    border: "none",
    width: "80px",
    height: "32px",
    display: "inline-flex",
    outline: "0px",
    padding: "0px",
    fontSize: "0.8125rem",
    boxSizing: "border-box",
    alignItems: "center",
    fontFamily: "Nunito Sans, sans-serif",
    whiteSpace: "nowrap",
    borderRadius: "50px",
    verticalAlign: "middle",
    justifyContent: "center",
    textDecoration: "none",
    cursor: "default",
  },
  accuracyBtnBad: {
    border: "2px solid #FF2366",
    opacity: 0.75,
    color: "#000000",
  },
  accuracyBtnGood: {
    border: "2px solid #02BC77",
    opacity: 0.75,
    color: "#000000",
  },
  accuracyBtnLive: {
    backgroundColor: "#40aab5",
    color: "#FFFFFF",
  },
  tooltipDimensions: {
    width: 340,
    height: 70,
    fontWeight: "bold",
  },
  tooltipMismatch: {
    width: 380,
    height: 75,
    fontWeight: "bold",
  },
  totalDataSetTooltip: {
    width: 140,
    height: 40,
  },
  noOfDataPointsTooltip: {
    width: 200,
    height: 40,
  },
  badConfidenceTooltip: {
    width: 180,
    height: 40,
  },
  accuracyModelWhiteClass: {
    "& div": {
      color: "white !important",
    },
  },
  accuracyModelClass: {
    height: "24px",
    // width: "50px",
    // margin: "0 auto",
    fontSize: "0.5625rem",
    color: "#191919",
    '@media (min-width:1500px)' : {
      width: "60px"
    },
    '@media (max-width:1500px) and (min-width:1000px)' : {
      width: "55px"
    },
    '@media (max-width:1000px)' : {
      width: "54px"
    },

    "& div": {
      // fontSize: "0.5625rem",
      // font: "normal normal 600 0.5625rem/0.75rem Nunito Sans",
      color: "#191919",
      opacity: "unset",
      '@media (min-width:1500px)' : {
        font: "normal normal 600 0.85rem/0.75rem Nunito Sans",
      },
      '@media (max-width:1500px) and (min-width:1000px)' : {
        font: "normal normal 600 0.75rem/0.75rem Nunito Sans",
      },
      '@media (max-width:1000px)' : {
        font: "normal normal 600 0.7rem/0.75rem Nunito Sans",
      },
    },
  },
  gap: {
    gap: "10px !important",
  },
  tdGap: {
    padding: "0px 20px",
    // scrollSnapAlign:"end"
  },
  version: {
    color: "#575757",
    font: "normal normal 600 0.9375rem/1.25rem Nunito Sans",
  },
  arrowContainer: {
    display: "flex",
    justifyContent: "space-between",
    // marginLeft: "290px",
    marginLeft: "250px",
    marginRight: "25px",
    position: "relative",
    top: "30px",
    cursor: "pointer"
  },
  column1Sticky: {
    position: "sticky",
    left: 0,
    background: "#ffff",
    zIndex: 2,
    height: "100%",
    width: "140px",
  },
  horizSnap: {
    overflowX: "auto",
  },
  horizSnap1: {
    scrollBehavior: "smooth",
    scrollSnapType: "x mandatory",
  },
  column2Sticky: {
    position: "sticky",
    left: "110px",
    background: "#ffff",
    zIndex: 2,
  },
  horizSnapChild: {
    scrollSnapAlign: "end",
  },
  pinnedClass:{
    position: "sticky",
    left: "140px",
    zIndex: 1,
    background: "#ffff",
    height: "fit-content"
  },
  disableArrow:{
    color: "#838C95 !important",
  }
}));

const HtmlTooltip = withStyles((theme) => ({
  tooltip: {
    backgroundColor: "#000000B3",
    color: "#FFFFFF",
    // width: 340,
    // height: 70,

    fontSize: theme.typography.pxToRem(14),
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    borderRadius: 9,
  },
  arrow: {
    color: "#000000B3",
  },
}))(Tooltip);
export const IOU_THRESHOLD_DEFAULT_VALUE_FOR_SEGMENTATION_ANALYTICS = 0.5;
export const CONFIDENCE_SCORE_THRESHOLD_DEFAULT_VALUE=0;
const Integrity = (props: TPropsFromRedux) => {
  const classes = useStyles();
  const history = useHistory();
  const location = useLocation();
  const query = new URLSearchParams(location.search);
  const [integrity, setIntegrity] = useState<IIntegrity[]>([]);
  const [isFetchingIntegrity, setIsFetchingIntegrity] =
    useState<boolean>(false);
  // const modelCollectionIds = query
  //   .get("modelCollectionIdsToCompare")
  //   ?.split(",");
  const tableRef = useRef<any>();
  const tableDataRef = useRef<any>();
  const [isLiveModelCollectionExist, setIsLiveModelCollectionExist] = useState<boolean>(false);
  const [isColumnPinned, setIscolumnPinned] = useState<boolean>();
  const [integrityInitialData, setIntegrityInitialData] = useState<IIntegrity[]>([]);
  const [showToolTip, setShowToolTip] = useState<boolean>(true);
  const [showLeftArrow, setShowLeftArrow] = useState<boolean>(true);
  const [showRightArrow, setShowRightArrow] = useState<boolean>(true);
  const timeOutRef = useRef<any>()
  const [maxAccuracy, setMaxAccuracy] = useState<number>();


  const urlSearchParams = useURLQuery();

  const model = integrity?.[0]?.model;

  const modelCollectionsAdditionalDataQueryParamValue: string = urlSearchParams.get(URLQueryParams.MODEL_COLLECTIONS_ADDITIONAL_DATA) || "";

  const modelCollectionsAdditionalData: GetIntegrityAPIModelCollectionsAdditionalDataQueryParamType[] = useMemo(() => {
    if (modelCollectionsAdditionalDataQueryParamValue) {
      return [...JSON.parse(modelCollectionsAdditionalDataQueryParamValue)];
    }
    return [];
  }, [modelCollectionsAdditionalDataQueryParamValue])

  const [isModelCollectionsFilterPopupOpen, setIsModelCollectionsFilterPopupOpen] = useState(false);

  const [modelCollectionAdditionalDataFilter, setModelCollectionAdditionalDataFilter] = useState<IntegrityFilterForModelCollection[]>([]);

  const isModelCollectionAdditionalDataFilterApplied: boolean = (()=>{
    if (!model?.isSegmentationModelAnalytics) {
      return false;
    }

    const areAllModelCollectionsFilterHavingDefaultValue = (()=>{
      if (modelCollectionsAdditionalData?.length>0) {
        for (const modelCollectionAdditionalData of modelCollectionsAdditionalData) {
          if (
            // modelCollectionAdditionalData.confidenceScoreThreshold !== CONFIDENCE_SCORE_THRESHOLD_DEFAULT_VALUE_FOR_SEGMENTATION_ANALYTICS ||
            modelCollectionAdditionalData.iouThreshold !== IOU_THRESHOLD_DEFAULT_VALUE_FOR_SEGMENTATION_ANALYTICS
          ) {
            return false
          }
        }
        return true;
      }
      return false;
    })();

    if (!areAllModelCollectionsFilterHavingDefaultValue) {
      return true;
    }
    return false;
  })();

  useEffect(() => {
    const urlSearchParams = new URLSearchParams(window.location.search);
    const modelId = urlSearchParams.get("model");
    const modelCollectionIds = JSON.stringify(
      query.get("modelCollectionIdsToCompare")?.split(",")
    );
    (async () => {
      try {

        const testCollectionId = urlSearchParams.get("testCollectionId") || "";

        setIsFetchingIntegrity(true)
        const testDataSetCollectionId = query.get("testDataSetCollectionId");
        const apiResponse = await DataService.getIntegrity({
          modelId: modelId || "",
          modelCollectionIds,
          testCollectionId: testCollectionId,
          ...(testDataSetCollectionId ? { dataSetCollectionId: testDataSetCollectionId } : {}),
          modelCollectionsAdditionalData: modelCollectionsAdditionalData?.length>0 ? 
          // @ts-ignore
          JSON.stringify(modelCollectionsAdditionalData)  as GetIntegrityAPIModelCollectionsAdditionalDataQueryParamType[]
          // @ts-ignore
          : "" as GetIntegrityAPIModelCollectionsAdditionalDataQueryParamType[]
        });
        if (apiResponse.data && apiResponse.data?.length) {
          setIsFetchingIntegrity(false)
          const sortedData = apiResponse?.data?.sort((a: any, b: any) => (a?.accuracy?.value && b?.accuracy?.value && a?.accuracy?.value >= b?.accuracy?.value) ? -1 : 1);
          let maxAccuracy = 0;
          sortedData.forEach((x: IIntegrity) => {
            if(x.accuracy?.value > maxAccuracy){
              maxAccuracy = x.accuracy?.value;
            }
            if(x?.accuracy?.type === "isLive"){
              setIsLiveModelCollectionExist(true)
            }
          })
          setMaxAccuracy(maxAccuracy)
          setIntegrityInitialData(sortedData);
          setIntegrity(sortedData);
          setTimeout(adjustDomLayout, 0);
        }
      } catch (error) {
        setIsFetchingIntegrity(false)
        alert(error?.response?.data || error.message)        
      }
    })();
  }, [modelCollectionsAdditionalData]);
  const confidenceScoreClick = (
    band: string,
    integrity: IIntegrity,
    goodConfidence = true
  ) => {
    let query = new URLSearchParams(window.location.search);
    const model = query.get("model") || "";
    const project = query.get("project") || "";
    const modelType = query.get("type") || "";
    const modelCollectionIdsToCompareQuery =
      query.get("modelCollectionIdsToCompare") || "";
    const testCollectionId = query.get("testCollectionId") || "";

    query = new URLSearchParams();

    if (testCollectionId) {
      query.set("testCollectionId", testCollectionId);
    }    
    query.set("model", model);
    query.set("project", project);
    query.set("limit", "10");
    query.set("offset", "1");
    query.set("type", modelType);
    query.set("status", "dashboard");
    query.set("modelCollectionIdFilterSelected", integrity._id);
    query.set("modelCollectionIdsToCompare", modelCollectionIdsToCompareQuery);
    query.set("scenario", "integrity");
    query.set("accuracy", integrity.accuracy.value?.toString() || "0");
    query.set("sortField", SortField.PREDICTED_STATUS)
    query.set("sortOrder", goodConfidence ? SortOrder.ASC : SortOrder.DESC)
    const bands = band.split("-");
    if (bands[0].includes("99")) {
      band = "99% - 99.99%"
    } else if (bands[0].includes("100")) {
      band = "100%"
    } else {
      band = band.split("-").join(" - ")
    }
    query.set("bandFilterSelected", band);
    query.set(
      "sortByValueFilterSelected",
      goodConfidence ? "CORRECT FIRST" : "INCORRECT FIRST"
    );
    if (integrity?.model?.isSegmentationModelAnalytics) {
      const iouThreshold = (()=>{
        const modelCollectionAdditionalDataIouThreshold = modelCollectionsAdditionalData.find(modelCollection=>modelCollection.modelCollectionId===integrity._id)?.iouThreshold;
        return modelCollectionAdditionalDataIouThreshold
      })();
      if (iouThreshold) {
        query.set("iouThreshold", String(iouThreshold))
      }
    }
    history.push({
      pathname: "../modelAnalytics/resources",
      search: query.toString(),
    });
  };
  const backToDashboard = () => {
    const urlToNavigate = window.location.pathname.split("/integrity")[0] || "";
    const query = new URLSearchParams(window.location.search);
    const testCollectionId = query.get("testCollectionId") || "";

    history.goBack();

    return;

    if (urlToNavigate) {
      history.push(
        `${urlToNavigate}/dashboard?model=${query.get(
          "model"
        )}&type=${query.get("type")}&project=${query.get(
          "project"
        )}&status=dashboard&modelCollectionIdsToCompare=${query.get(
          "modelCollectionIdsToCompare"
        )}${testCollectionId ? `&testCollectionId=${testCollectionId}` : ""}`
      );
    }
  };

  const adjustDomLayout = () => {
    if (!tableRef.current) {
      return;
    }
    const tableWidth = tableRef.current.getBoundingClientRect()["width"];
    const firstCol: any = document?.querySelector("#sticky-column");
    const visibleTableWidthInViewPort = tableWidth - firstCol?.clientWidth;
    // const baseColWidth = 290;
    const baseColWidth = 230;
    const cols = Math.floor(visibleTableWidthInViewPort / baseColWidth);
    const spaceLeftForEachCol = Math.floor(
      visibleTableWidthInViewPort - baseColWidth * cols
    );
    const spaceToAddForEachCol = spaceLeftForEachCol / cols;
    const myEle: any = document.querySelectorAll("#table-data-element");
    myEle.forEach((x: any) => {
      x.style.minWidth = spaceToAddForEachCol + baseColWidth + "px";
    });
    checkArrows()
    console.log({
      tableWidth,
      firstCol,
      visibleTableWidthInViewPort,
      baseColWidth,
      cols,
      spaceToAddForEachCol,
      spaceLeftForEachCol,
      minWidth: spaceToAddForEachCol + baseColWidth + "px",
    });
  }

  const onLeftArrowClick = () => {
    const col = document?.querySelector("#table-data-element");
    const colWidth: any = col?.clientWidth;
    console.log("col", colWidth);
    console.log("scroll left", tableRef.current.scrollLeft);
    tableRef.current.scroll({
      top: 0,
      left: tableRef.current.scrollLeft - colWidth,
      behavior: "smooth",
    });
    const stickyCOl: any = document?.querySelector("#sticky-column");
    stickyCOl.style.left = 15;
  };

  const onRightArrowClick = () => {
    const col = document?.querySelector("#table-data-element");
    const colWidth = col?.clientWidth;
    console.log("col", colWidth);
    console.log("scroll left", tableRef.current.scrollLeft);
    console.log("left", tableRef.current.scrollLeft + colWidth);
    tableRef.current.scroll({
      top: 0,
      left: tableRef.current.scrollLeft + colWidth,
      behavior: "smooth",
    });
    const stickyCOl: any = document?.querySelector("#sticky-column");
    stickyCOl.style.left = 15;
  };

  useEffect(()=>{
    function handleResizeEvent(e: any) {
      adjustDomLayout();
    }
    window.addEventListener("resize", handleResizeEvent)
    return () => {
      window.removeEventListener("resize", handleResizeEvent)
    }
  }, [])

  const setColumnPin = (index: number) => {
    const columnsData = [...integrity]
    if(index === 0 && isColumnPinned){
      setIscolumnPinned(false)
      setIntegrity(integrityInitialData);
    }
    else{
      setIscolumnPinned(true)
      const columnToBeMoved = columnsData.splice(index, 1);
      setIntegrity([...columnToBeMoved, ...columnsData]);
    }
    setTimeout(()=>adjustDomLayout());
  }

  const onWheel = (e: any) => {
    setShowToolTip(false)
    clearTimeout(timeOutRef.current)
      timeOutRef.current = setTimeout(() => {
        setShowToolTip(true)
      } , 100)
  };

  const onScroll = (e: any) => {
    checkArrows()
  }

  const checkArrows = () => {
    const totalWidth = tableRef.current.scrollWidth - 10; // 10 for tolerance
    const scrolledWidth = tableRef.current.scrollLeft;
    const elementVisibleWidth = tableRef.current.clientWidth;

    if(totalWidth - elementVisibleWidth > scrolledWidth){
      setShowRightArrow(true)
    }
    else{
      setShowRightArrow(false)
    }
    if(scrolledWidth > 0){
      setShowLeftArrow(true)
    }
    else{
      setShowLeftArrow(false)
    }
  }

  return (
    <Paper
      className={clsx(classes.paper, classes.container)}
      variant="outlined"
    >
      <div>
      <ArrowBackIcon
          style={{
            color: "#008D9C",
            cursor: "pointer",
          }}
          onClick={() => backToDashboard()}
        />
        <span className={classes.title}>
          100% INTEGRITY
          {
            integrity?.[0]?.model?.isSegmentationModelAnalytics &&
          <IconButton
              aria-label="more"
              aria-controls="long-menu"
              aria-haspopup="true"
              id={INTEGRITY_PAGE_FILTER_ICON_BUTTON_ELEMENT_ID}
              onClick={() => {
                setIsModelCollectionsFilterPopupOpen(true)
              }}
            >
              {
                isModelCollectionAdditionalDataFilterApplied
                  ? (
                    <Tooltip title="Filter is applied">
                      <FilterListIcon />
                    </Tooltip>
                  )
                  : <MoreVertIcon />
              }
            </IconButton>
          }
         {
          isModelCollectionsFilterPopupOpen &&
          <IntegrityPageFilterPopup
            integrity={integrity}
            modelCollectionsAdditionalData={modelCollectionsAdditionalData}
            modelCollectionAdditionalDataFilter={modelCollectionAdditionalDataFilter}
            setModelCollectionAdditionalDataFilter={setModelCollectionAdditionalDataFilter}
            onClose={()=>{
              setIsModelCollectionsFilterPopupOpen(false);
            }}
          />
         }
        </span>
      </div>

      <div className={classes.subtitle}>MODELS</div>

      {
        !isFetchingIntegrity &&
        <>
        <div className={classes.arrowContainer}>
          <ArrowBackIcon
            style={{
              color: "#008D9C",
              cursor: "pointer",
              zIndex: 5
            }}
            onClick={onLeftArrowClick}
            className={!showLeftArrow ? `${classes.disableArrow}` : ""}
          />
          <ArrowForwardIcon
            style={{
              color: "#008D9C",
              cursor: "pointer",
              zIndex: 5
            }}
            onClick={onRightArrowClick}
            className={!showRightArrow ? `${classes.disableArrow}` : ""}
          />
        </div>
        <div className={classes.tableContainer}>
          <div style={{ alignSelf: "center", color: "#838C95" }}>BANDS</div>
          <div
            id="table"
            style={{
              overflow: "auto",
              // width: "100%",
              width: "calc(100% - 155px)",
              height: "calc(100% - 12px",
            }}
          >
            <div
              ref={tableRef}
              className={`
                ${classes.table} ${classes.horizSnap} 
                ${
                  !isColumnPinned
                  ? classes.horizSnap1
                  : showToolTip
                    ? classes.horizSnap1
                    : ""
                }
              `}
              style={{
                width: "100%",
                height: "100%",
                display: "flex",
                position: "relative",
              }}
              onWheel = {onWheel}
              onScroll={onScroll}
            >
              <div
                style={{
                  display: "flex",
                  flexDirection: "column",
                  height: "100%",
                  justifyContent: "space-between",
                  minHeight: "573px",
                  alignItems: "baseline",
                }}
                id="sticky-column"
                className={classes.column1Sticky}
              >
                <div  id="table-first-column" style={{
                          height: "40px",
                          alignItems: "center",
                          display: "flex",
                          position: "sticky",
                          top: "0px",
                          backgroundColor: "white",
                          width: "100%"
                        }}
                        className={clsx(classes.textCenter, classes.tdGap)}></div>
                <div  id="table-first-column" style={{
                          height: "40px",
                          alignItems: "center",
                          display: "flex",
                        }}
                        className={clsx(classes.textCenter, classes.tdGap)}>TOTAL</div>
                {integrity[0]?.bands?.map((integrityBand: any) => {
                  return (
                    <>
                      <div
                        id="table-first-column"
                        ref={tableDataRef}
                        style={{
                          height: "40px",
                          alignItems: "center",
                          display: "flex",
                          width: "148px"
                        }}
                        className={clsx(classes.textCenter, classes.tdGap)}
                      >
                        {integrityBand.band === "100%-100%"
                          ? "100%"
                          : integrityBand.band === "99%-100%"
                          ? "99% - 99.99%"
                          : integrityBand.band.split("-").join(" - ")}
                      </div>
                    </>
                  );
                })}
              </div>
              {integrity?.map((integrityElem: any, outerIndex: number) => {
                return (
                  <>
                    <div
                      id="table-data-element"
                      key={integrityElem?.version}
                      ref={tableDataRef}
                      style={{
                        display: "flex",
                        flexDirection: "column",
                        justifyContent: "space-between",
                        minHeight: "573px",
                        alignItems: "center",
                        height: "100%",
                      }}
                      className={clsx(
                        classes.textCenter,
                        classes.tdGap,
                        classes.horizSnapChild,
                        outerIndex === 0 && isColumnPinned ? classes.pinnedClass : ""
                      )}
                    >
                        <div className={clsx(classes.flexContainer, classes.gap)}
                              style={{
                                height: "40px",
                                width: "100%",
                                justifyContent: "center",
                                alignItems: "center",
                                position: "sticky",
                                top: "0px",
                                backgroundColor: "white",
                              }}>
                        {integrityElem.version}&nbsp;&nbsp;&nbsp;
                        {
                          !integrity?.[0]?.model?.isSegmentationModelAnalytics &&
                          (
                            integrityElem.bands[0].goodConfidenceScore === 100 ? (
                              <HtmlTooltip
                                arrow
                                placement="bottom"
                                classes={{ tooltip: classes.tooltipDimensions }}
                                title={
                                  showToolTip ? 
                                  <React.Fragment>
                                    <div>
                                      <div>This model holds 100% Integrity</div>
                                      <div>
                                        Model accuracy matches success criteria.
                                      </div>
                                    </div>
                                  </React.Fragment>
                                  : ""
                                }
                              >
                                <CheckCircleIcon
                                  style={{
                                    color: "#02BC77",
                                    cursor: "pointer",
                                  }}
                                />
                              </HtmlTooltip>
                            ) : 
                            (
                              <HtmlTooltip
                                arrow
                                placement="bottom"
                                classes={{ tooltip: classes.tooltipMismatch }}
                                title={
                                  showToolTip ? 
                                  <React.Fragment>
                                    <div>
                                      <div>This model doesn't hold 100% Integrity</div>
                                      <div>
                                        Model accuracy doesn't matches success criteria.
                                      </div>
                                    </div>
                                  </React.Fragment>
                                  : ""
                                }
                              >
                                <CancelIcon
                                style={{
                                  color: "#FF2366",
                                  cursor: "pointer",
                                }}
                              />
                              </HtmlTooltip>
                            )
                          )
                        }
                        <IconButton style={{outline: "none"}} onClick={() => setColumnPin(outerIndex)}>
                          {outerIndex === 0 && isColumnPinned ?
                            <HtmlTooltip
                            arrow
                            placement="bottom"
                            classes={{ tooltip: classes.badConfidenceTooltip }}
                            title="Unpin model version"
                            >
                              <img alt="" src={unPinIcon} height={22} width={22} />
                            </HtmlTooltip>
                            :
                            <HtmlTooltip
                            arrow
                            placement="bottom"
                            classes={{ tooltip: classes.totalDataSetTooltip }}
                            title="Pin model version"
                            >
                              <img alt="" src={pinIcon} height={22} width={22} />
                            </HtmlTooltip>
                          }
                        </IconButton>
                      </div>
                          <div className={clsx(classes.flexContainer, classes.gap)}
                              style={{
                                height: "40px",
                                width: "100%",
                                justifyContent: "center",
                                alignItems: "center",
                              }}>
                          <HtmlTooltip
                            arrow
                            placement="bottom"
                            classes={{ tooltip: classes.totalDataSetTooltip }}
                            title={
                              showToolTip ? 
                              <React.Fragment>
                                <div>Total Test Datasets</div>
                              </React.Fragment>
                              : ""
                            }
                          >
                            <div className={classes.dataSets}>
                              {integrityElem.totalDataSetsCount}
                            </div>
                          </HtmlTooltip>
                          <ModelCollectionAccuracy 
                                    className={`${(integrityElem?.accuracy?.type === "isLive" || isNullOrUndefined(integrityElem?.accuracy?.value)) && classes.accuracyModelWhiteClass} ${classes.accuracyModelClass}`}
                                    isLiveModelCollection={integrityElem?.accuracy?.type === "isLive"}
                                    liveCollectionAccuracy={integrityElem?.accuracy?.value || 0}
                                    accuracyStatus ={ integrityElem?.accuracy?.value === maxAccuracy && ((integrityElem?.accuracy?.type === "noLiveExists") || !isLiveModelCollectionExist) ? "customHeighestNoLiveExists" : !isLiveModelCollectionExist ? "customNoLiveExists" :  integrityElem?.accuracy?.type}
                                    accuracyValue={isNullOrUndefined(integrityElem?.accuracy?.value as number) ? undefined : integrityElem?.accuracy?.value as number} 
                                    jsonFileData = {undefined}
                                    // modelCollectionFiles={[]} 
                                  />
                        </div>
                      {integrityElem.bands?.map(
                        (integrityBand: any, index: number) => {
                          return (
                            <div
                              className={clsx(classes.flexContainer, classes.gap)}
                              style={{
                                height: "40px",
                                justifyContent: "space-between",
                                alignItems: "center",
                              }}
                            >
                              <HtmlTooltip
                                arrow
                                placement="bottom"
                                classes={{ tooltip: classes.totalDataSetTooltip }}
                                title={
                                  showToolTip ? 
                                  <React.Fragment>
                                    <div>Confidence Score</div>
                                  </React.Fragment>
                                  : ""
                                }
                              >
                                <div
                                  className={classes.confidenceScore}
                                  style={{ height: "40px" }}
                                  onClick={() => confidenceScoreClick(
                                    integrityElem.bands[index].band,
                                    integrityElem
                                  )}
                                >
                                  {integrityBand && (typeof(integrityBand.goodConfidenceScore) === "number") ? parseValue(integrityBand.goodConfidenceScore) + "%" : "NA"}
                                </div>
                              </HtmlTooltip>
                              <HtmlTooltip
                                arrow
                                placement="bottom"
                                classes={{
                                  tooltip: classes.noOfDataPointsTooltip,
                                }}
                                title={
                                  showToolTip ? 
                                  <React.Fragment>
                                    <div>NO OF DATAPOINTS</div>
                                  </React.Fragment>
                                  : ""
                                }
                              >
                                <div
                                  className={classes.dataSets}
                                  style={{
                                    height: "40px",
                                    alignItems: "center",
                                    display: "flex",
                                    justifyContent: "center"
                                  }}
                                >
                                  {integrityBand.noOfDataPoints}
                                </div>
                              </HtmlTooltip>
                              <HtmlTooltip
                                arrow
                                placement="bottom"
                                classes={{
                                  tooltip: classes.badConfidenceTooltip,
                                }}
                                title={
                                  showToolTip ? 
                                  <React.Fragment>
                                    <div>Bad Confidence Score</div>
                                  </React.Fragment>
                                  : ""
                                }
                              >
                                <div
                                  style={{ height: "40px" }}
                                  className={clsx(
                                    classes.confidenceScore,
                                    classes.badConfidenceScore
                                  )}
                                  onClick={() =>
                                    confidenceScoreClick(
                                      integrityElem.bands[index].band,
                                      integrityElem,
                                      false
                                    )}
                                >
                                  {integrityBand && (typeof(integrityBand.goodConfidenceScore) === "number") ? parseValue(100 - integrityBand.goodConfidenceScore) + "%" : "NA"}
                                </div>
                              </HtmlTooltip>
                            </div>
                          );
                        }
                      )}
                    </div>
                  </>
                );
              })}
            </div>
          </div>
        </div>
        </>
      }

      {
        isFetchingIntegrity &&
        <PageDataFetchingLoader />
      }
    </Paper>
  );
};

const mapProps = (state: IReduxState) => {
  return {};
};

const connector = connect(mapProps);

export type TPropsFromRedux = ConnectedProps<typeof connector>;

export default connector(Integrity);

function PageDataFetchingLoader() {
  return (
    <div
      style={{
        display: "flex",
        justifyContent: "center",
        marginTop: "100px",
        width: "100%",
        height: "calc(100% - 173px)"
      }}
    >
      <CircularProgress />
    </div>
  )
}
// import { ReactElement } from "react-markdown";
import ChatContext from "./chat.context";
import { ReactElement, useEffect, useMemo, useState } from "react";
import { aiResponsePreviewPageType, IAIresponseMetaData, IGetModelCollectionResponse } from "../../projects/features/aiMarketPlace/aiMarketPlaceInterfaces/interfaces";
import { isNullOrUndefined } from "../../../../../services/variableHelperService";
import { SelectedRlefResourceType } from "rlef-ui-library";
import { ITestDriveVM, TryOutRequest } from "../../projects/features/aiMarketPlace/services/types/testDrive";
import { PurposeModelPurposeType } from "../../../../../common/constants/interfaces/purposeModels";
import { DataService } from "../../../../../services";
import { IData, IResourceAnalyticsCollection, ResourceImageAnnotation } from "../../../../../common";
import CloudStorageService from "../../../../../services/CloudStorageService";
import { IImageObjectForCompression } from "../../../../../services/temporaryFiles/temporaryFilesInterfaces";
import temporaryFilesService from "../../../../../services/temporaryFiles/temporaryFilesService";
import { RLEF_GCS_BUCKET_NAME } from "../../../../../services/constants";
import PurposeModelService from "../../../../../services/PurposeModelService";
import { analyseTestDriveResults, AnnotatedImageDownloadUrlPair, generateDescriptionOfTestDriveImages, IAnnotationLabelAndVertices } from "../services/llmStudioAssistants.service";
import useAuth from "../../../../../hooks/useAuth";
import aiMarketPlaceServices from "../../projects/features/aiMarketPlace/aiMarketPlaceServices";
import DatacartService from "../../../../../services/DatacartService";


export interface IModelSummaryPageMetadata {
    pageTitle?: string;
    trainingPodId: string;
}

export interface IModelAnalyticsPageMetadata {
    pageTitle?: string;
}

interface ITestDrivePageMetaData {
    trainingPodId: string;
    pageTitle?: string;
    modelCollectionId?: string;
}

export interface IExplainabilityPageMetaData {
    header_message: string;
    responseFrom: string;
    responsedata: string;
    pageTitle: string;
}
export interface IPageInWorkModeStack {
    pageType: aiResponsePreviewPageType;
    isTestDriveButtonClickFromModelSummaryPage?:boolean;
    aiResponseMetaData?: IAIresponseMetaData;
    metadata: IModelSummaryPageMetadata | IModelAnalyticsPageMetadata | ITestDrivePageMetaData | IExplainabilityPageMetaData;
    /** stores the query params string of last page in the stack */
    searchQueryBeforeThisPage?: string;
    messageUuid: string;
}

export interface ISelectedModelMetadataInWorkMode {
    trainingPodId:string,
    modelCollectionId?:string,
    testDataSetId?:string,
    modelDetails?: any,
}

export interface TestDriveStage {
    stage: "Select Data" | "Test Drive" | "Results";      // Name of the stage
    percentage: number; // Progress percentage (0-100)
    isDone: boolean;
    isActive: boolean;  // Whether the stage is currently active
}

export type CloneState = '' | 'initial' | 'ready' | 'processing' | 'done' // initial - model cloning page is initialized, ready - model is ready for clone and can hit click button, processing - model cloning is in process,, done - model cloning is done

export interface IClonedPod {
	purposeModelName : string,
	purposeModelId : string,
    purposeModelType: PurposeModelPurposeType | undefined,
	projectId: string,
	modelId: string,
	projectName: string,
	modelName: string
}

export interface IMetaDataOfCurrentTestDroveTrainingPod {
    trainingPodId: string;
    descriptionOfDataSelectedForTestDrive: string;
    isGeneratingDescOfDataSelectedForTestDrive: boolean;
    /** description generated based on the code files of the training pod which was test drove */
    descriptionOfModelArchitecture: string;
}

export interface IAnnotatedImageDetails {
    Id: string;
    url : string;
    annotations: IAnnotationLabelAndVertices[]
}

// export interface 

export default function ChatContextProvider(props: { children: ReactElement }) {

    const [workModePageStack, setWorkModePageStack] = useState<Array<IPageInWorkModeStack>>([])
    const [selectedModelMetadataInWorkMode, setSelectedModelMetadataInWorkMode] = useState<ISelectedModelMetadataInWorkMode | null>(null)
    const [selectedRlefResourcesForTestDrive, setSelectedRlefResourcesForTestDrive] = useState<SelectedRlefResourceType[]>([])
    const [testDriveStage, setTestDriveStage] = useState<TestDriveStage[]>([
        { stage: "Select Data", percentage: 50, isActive: true, isDone: false },
        { stage: "Test Drive", percentage: 0, isActive: false, isDone: false },
        { stage: "Results", percentage: 0, isActive: false, isDone: false },
    ]);
    const [testDriveVm, setTestDriveVm] = useState<ITestDriveVM | null>(null);
    const [testDriveProcessRequest, setTestDriveProcessRequest] = useState<TryOutRequest | null>(null);
    const [isTestDriveCompleted, setIsTestDriveCompleted] = useState<boolean>(false);
    const [modelsSelectedForTestDrive, setModelsSelectedForTestDrive] = useState<IGetModelCollectionResponse[]>([]);
    const [modelCloningStage, setModelCloningStage] = useState<CloneState>('')
    const [clonedPodDetails, setClonedPodDetails] = useState<IClonedPod>({
        purposeModelName: "",
		purposeModelId : "",
		projectId: "",
        purposeModelType: undefined,
		modelId: "",
		projectName: "",
		modelName: ""
	})
    const [testDroveTrainingPodIdsInCurrentSession, setTestDroveTrainingPodIdsInCurrentSession] = useState<Array<string>>([]);
    /** misc info about the training pod which was just test drove */
    const [metadataOfCurrentTestDroveTrainingPod, setMetadataOfCurrentTestDroveTrainingPod] = useState<IMetaDataOfCurrentTestDroveTrainingPod>({
        trainingPodId: '',
        descriptionOfDataSelectedForTestDrive: "",
        isGeneratingDescOfDataSelectedForTestDrive: false,
        descriptionOfModelArchitecture: "",
    })
    const [shouldShowTestDriveResultScreen, setShouldShowTestDriveResultScreen] = useState<boolean>(false);
    const [groundTruthAnnotatedImageDetails, setGroundTruthAnnotatedImageDetails] = useState<IAnnotatedImageDetails[]>([])
    const [predictedAnnotatedImageDetails, setPredictedAnnotatedImageDetails] = useState<IAnnotatedImageDetails[]>([])
    const [annotatedGroundTruth, setAnnotatedGroundTruth] = useState<boolean>(false)
    const [annotatedPredicted, setAnnotatedPredicted] = useState<boolean>(false)
    const [testDriveAnalysisMessage, setTestDriveAnalysisMessage] = useState<string>('');
    const {userEmail} = useAuth()
    const [analysisAssistantPayload, setAnalysisAssistantPayload] = useState<AnnotatedImageDownloadUrlPair[]>([])

    const pushPageInWorkModeStack = (page: IPageInWorkModeStack) => {
        const firstPageInWindow = workModePageStack[0];
        if(!isNullOrUndefined(firstPageInWindow?.searchQueryBeforeThisPage)){
            window.history.replaceState(null, '', window.location.pathname + firstPageInWindow.searchQueryBeforeThisPage)
        }
        setWorkModePageStack([...workModePageStack, page])
    }

    const switchToPreviousPageInWorkModeStack = () => {
        const currentPage = workModePageStack[workModePageStack.length - 1];
        if(!isNullOrUndefined(currentPage.searchQueryBeforeThisPage)){
            window.history.replaceState(null, '', window.location.pathname + currentPage.searchQueryBeforeThisPage)
        }
        setWorkModePageStack(workModePageStack.slice(0, -1))
    }

    const onCloseWindowSideEffects = () => {
        const firstPageInWindow = workModePageStack[0];
        if(!isNullOrUndefined(firstPageInWindow?.searchQueryBeforeThisPage)){
            window.history.replaceState(null, '', window.location.pathname + firstPageInWindow.searchQueryBeforeThisPage)
        }
    }

    const value = useMemo(() => {
        return {
            workModePageStack,
            setWorkModePageStack,
            pushPageInWorkModeStack,
        }
    }, [workModePageStack])

    const updateTestDriveStage = (stageIndex: number, newPercentage: number) => {
        setTestDriveStage((prevState) =>
            prevState.map((stage, index) => {
                if (index < stageIndex) {
                    // Mark all previous stages as 100% and active
                    return { ...stage, percentage: 100, isActive: false, isDone: true };
                } else if (index === stageIndex) {
                    // Update the current stage percentage and mark it active
                    return { ...stage, percentage: newPercentage, isActive: true, isDone: newPercentage === 100 }
                } else {
                    // Reset all subsequent stages to 0% and inactive
                    return { ...stage, percentage: 0, isActive: false, isDone: false };
                }
            })
        );
    };

    const getResultsForTestDriveAnalysis = async()=>{
        const currentPageData = workModePageStack[workModePageStack.length-1]
        try {
            const purposeModel = await PurposeModelService.getPurposeModelData(testDriveVm?.podId||'')
            //@ts-ignore
            const {data : {resourcesData : {resources}}} = await DataService.getAnalyticsResourceData({
                modelCollectionId: currentPageData.aiResponseMetaData?.trainingPods?.[0]?.modelCollectionId || '', 
                isAnalyticsPageOpenedFromTestDrive : false, 
                tryOutVmId : testDriveVm?._id || '', 
                limit : 999999, 
                requestorEmailId : userEmail,
                modelId:  purposeModel.modelId 
            })
            console.log('resources', resources)
            let obj:Record<string, IResourceAnalyticsCollection> = {};
            resources.forEach(resource=> obj[resource._id] = resource)
            const resourcesToSend:IResourceAnalyticsCollection[] = []
            for (const key in obj) resourcesToSend.push(obj[key])
            console.log(resourcesToSend)
            sendImagesForAnnotation(resourcesToSend, 'predict')
        } catch (error) {
            console.error(error)
            setTestDriveAnalysisMessage('Some error occured while fetching results of test drive for analysing test drive result')
        }
    }

    const sendImagesForAnnotation = async (resources : (IData | IResourceAnalyticsCollection)[], type: 'ground' | 'predict') => {
        try{
            console.log(type)
            const imageDetails : IAnnotatedImageDetails[] = await Promise.all(resources.map(async(resource) => {
                const annotatedData = await DatacartService.sendImageForAnnotating({
                    //@ts-ignore
                    imageDownloadUrl: type === 'ground' ? resource.resource : resource.resourceUrl,
                    //@ts-ignore
                    fileName: type === 'ground' ? resource.filename : resource.fileName,
                    gcsBucketName: RLEF_GCS_BUCKET_NAME,
                    //@ts-ignore
                    imageAnnotations: type === 'ground' ? resource.imageAnnotations || [] : resource.predictedAnnotations || []
                })

                const storageFileName = annotatedData.signed_url_to_upload_file.split(RLEF_GCS_BUCKET_NAME+'/')[1].split('?')[0]
                const annotatedFileUrlResponse = await CloudStorageService.getResourceBucketFileDownloadURL({storageFileName})
                
                //@ts-ignore
                const imageAnnotations:ResourceImageAnnotation[] = type === 'ground' ? resource.imageAnnotations || [] : resource.predictedAnnotations || []

                return {Id: resource._id, url: annotatedFileUrlResponse.data, annotations: imageAnnotations.map(({selectedOptions, vertices})=>({
                    className: selectedOptions[1].value,
                    coordinates: vertices.map(({x, y})=>({x: Math.round(x*100)/100, y: Math.round(y*100)/100}))
                })) || []}
            }))

            if(type === 'ground') {
                setGroundTruthAnnotatedImageDetails(imageDetails)
                setAnnotatedGroundTruth(true)
            }  
            else {
                setPredictedAnnotatedImageDetails(imageDetails)
                setAnnotatedPredicted(true)
            }
        } catch (error) {
            console.error(error)
            setTestDriveAnalysisMessage('Some error occured while sending images for annotating')
        }
    }

    useEffect(() => {
        const mergeAndCallTestDriveAnalysis = () => {
            const groundThruths: IAnnotatedImageDetails[] = [...groundTruthAnnotatedImageDetails]
            const predicted: IAnnotatedImageDetails[] = [...predictedAnnotatedImageDetails]
            setPredictedAnnotatedImageDetails([])
            setGroundTruthAnnotatedImageDetails([])
            groundThruths.sort((a : IAnnotatedImageDetails, b : IAnnotatedImageDetails)=>a.Id < b.Id ? -1 : a.Id === b.Id ? 0 : 1)
            predicted.sort((a : IAnnotatedImageDetails, b : IAnnotatedImageDetails)=>a.Id < b.Id ? -1 : a.Id === b.Id ? 0 : 1)

            const annotatedPairsOfImagesDownloadUrlsTestDriveResults: AnnotatedImageDownloadUrlPair[] = []
            for(let i=0; i<predicted.length; i++) {
                let j = i;
                while(j<groundThruths.length && predicted[i].Id != groundThruths[j].Id) j++;
                if(j==groundThruths.length) break; 
                annotatedPairsOfImagesDownloadUrlsTestDriveResults.push({groundTruth: groundThruths[j].url, groundTruthAnnotations: groundThruths[j].annotations, prediction: predicted[i].url, predictionAnnotations: predicted[i].annotations})
            }
            setAnalysisAssistantPayload(annotatedPairsOfImagesDownloadUrlsTestDriveResults)
            setTestDriveAnalysisMessage('call analysis assistant')
        }

        if(annotatedGroundTruth && annotatedPredicted) {
            setAnnotatedGroundTruth(false);
            setAnnotatedPredicted(false);
            mergeAndCallTestDriveAnalysis()
        }
    }, [annotatedGroundTruth, annotatedPredicted])
    
    return (
        <ChatContext.Provider
            value={{
                ...value,
                switchToPreviousPageInWorkModeStack,
                onCloseWindowSideEffects,
                selectedRlefResourcesForTestDrive,
                setSelectedRlefResourcesForTestDrive,
                testDriveStage,
                updateTestDriveStage,
                testDriveVm,
                setTestDriveVm,
                isTestDriveCompleted,
                setIsTestDriveCompleted,
                testDriveProcessRequest,
                setTestDriveProcessRequest,
                modelCloningStage,
                setModelCloningStage,
                clonedPodDetails,
                setClonedPodDetails,
                testDroveTrainingPodIdsInCurrentSession,
                setTestDroveTrainingPodIdsInCurrentSession,
                metadataOfCurrentTestDroveTrainingPod,
                setMetadataOfCurrentTestDroveTrainingPod,
                shouldShowTestDriveResultScreen,
                setShouldShowTestDriveResultScreen,
                getResultsForTestDriveAnalysis,
                sendImagesForAnnotation,
                testDriveAnalysisMessage,
                setTestDriveAnalysisMessage,
                analysisAssistantPayload,
                setAnalysisAssistantPayload
            }}
        >
            {props.children}
        </ChatContext.Provider>
    )
}
import ChevronRightIcon from '@material-ui/icons/ChevronRight';
import ChevronLeftIcon from '@material-ui/icons/ChevronLeft';
import { makeStyles, Theme } from '@material-ui/core';
import { useEffect, useState } from 'react';

const useStyles = makeStyles((theme: Theme) => ({
    container: {
        display: 'flex',
        color: '#000',
        justifyContent: "center",
        zIndex: 1,
        position: "relative"
    },
    textContainer: {
        "& svg": {
            color: theme.palette.primary.main
        },
        "& svg:hover": {
            cursor: 'pointer',
        }
    },
}));

export const VideoIterator = (props: {currentItem: number, totalItem: number, changeVideo:Function}) => {
    const classes = useStyles();
    const [currentVideo, setCurrentVideo] = useState("01");
    const [totalVideos, setTotalVideos] = useState("01")
    useEffect(() => {
        if (props.currentItem < 10) {
            setCurrentVideo("0"+props.currentItem)
        }
        else{
            setCurrentVideo(`${props.totalItem}`)
        }
    }, [props.currentItem])
    
    useEffect(() => {
        if (props.totalItem < 10) {
            setTotalVideos("0" + props.totalItem)
        }
        else{
            setTotalVideos(`${props.totalItem}`)
        }
    }, [props.totalItem])

    return (
        <div className={`${classes.container}`}>
            <div className={`${classes.textContainer}`}>
                <ChevronLeftIcon onClick={() => props.changeVideo(props.currentItem - 1)} fontSize='default' style={{visibility: props.currentItem > 1 ? "visible" : "hidden"}}/>
                <span style={{padding: "0px 15px"}}> Video {currentVideo} / {totalVideos} </span>
                <ChevronRightIcon onClick={() => props.changeVideo(props.currentItem + 1)} fontSize='default' style={{visibility: props.currentItem < props.totalItem ? "visible" : "hidden"}}/>
            </div>
        </div>
    )
}

import { Link } from "@material-ui/core";

type Props = {
  linkText: string;
  linkProps?: {
    color?: "primary";
    href?: string;
    target?: "_blank";
    className?: string;
  };
};

export const CustomLink = (props: Props) => {
  const linkProps = props.linkProps || {};
  return <Link {...linkProps}>{props.linkText}</Link>;
};

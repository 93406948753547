import {
  Box,
  Button,
  Popover,
  Slider,
  Switch,
  Table,
  TableCell,
  TableHead,
  TableRow,
} from "@material-ui/core";
import { useEffect, useRef, useState } from "react";
import {
  IModelCollectionsAdditionalData,
  modelCollectionAdditionalDataRequestPayloadForIndividualClassAccuracy,
} from "../../../../../../../../../../../../../common";
import { ModelAnalyticsIndividualClassAccuracyApiResponseData } from "../../../../../../../../../../../../../common/constants/interfaces/modelAnalyticsIndividualClassAccuracyApiResponse";
import {
  AnalyticsDashboardQueryParams,
  ClassAccuracyLabelFilter,
  DashboardIndividualClassAccuracyFilter,
} from "../../ModelAnalyticsDashboard";
import { isNullOrUndefined } from "../../../../../../../../../../../../../services/variableHelperService";
import useSetUrlSearchParamsOfCurrentRoute from "../../../../../../../../../../../../../hooks/useSetUrlSearchParamsOfCurrentRoute";

export const DashboardIndividualClassAccuracyFilterPopover = (props: {
  open: boolean;
  onClose: () => void;
  isFilterApplied: boolean;
  isFetchingIndividualClassAccuracyData: boolean;
  //   onApplyFilterButtonClick: () => void;
  individualClassAccuracyData: ModelAnalyticsIndividualClassAccuracyApiResponseData;
  individualClassAccuracyFilter: DashboardIndividualClassAccuracyFilter;
  setIndividualClassAccuracyFilter: React.Dispatch<
    React.SetStateAction<DashboardIndividualClassAccuracyFilter>
  >;
  modelCollectionsAdditionalDataQueryParamValueForIndividualClassAccuracy: modelCollectionAdditionalDataRequestPayloadForIndividualClassAccuracy[];
  // setFilterMinMaxValue: (value: [number, number]) => Promise<void>
}) => {
  //   let modelCollectionsAdditionalData = searchParams.get('modelCollectionsAdditionalData');

  const setUrlSearchParamsOfCurrentRoute =
    useSetUrlSearchParamsOfCurrentRoute();

  const setUrlSearchParamsOfCurrentRouteRef = useRef(
    setUrlSearchParamsOfCurrentRoute
  );
  setUrlSearchParamsOfCurrentRouteRef.current =
    setUrlSearchParamsOfCurrentRoute;

  //   const [sliderFieldState, setSliderFieldState] = useState< IModelCollectionsAdditionalData []>(
  //     sortedModelAnalyticsTopPerformingModel.map((state) => {
  //       let thresholdValue = thresholdApplied?.find((prevState) => prevState.modelCollectionId===state.modelCollectionId)?.confidenceScoreThreshold
  //       if(thresholdValue){
  //         return {
  //           modelCollectionId: state.modelCollectionId,
  //           confidenceScoreThreshold: thresholdValue
  //         }
  //       }
  //       return {
  //         modelCollectionId: state.modelCollectionId,
  //         confidenceScoreThreshold: 90
  //       }
  //     })
  //   );

  //   const [sliderIsEnable, setSliderIsEnable] = useState<boolean []> (
  //     Array(sortedModelAnalyticsTopPerformingModel.length).fill(false)
  //   )

  useEffect(() => {
    const filters: typeof props.individualClassAccuracyFilter = [];
    if (props.individualClassAccuracyData?.length > 0) {
      for (const modelCollectionData of props.individualClassAccuracyData) {
        const labelsFilter: ClassAccuracyLabelFilter[] = [];

        for (const labelData of modelCollectionData.classes) {
          const modelCollectionLabelFilter = (() => {
            if (
              props
                .modelCollectionsAdditionalDataQueryParamValueForIndividualClassAccuracy
                ?.length > 0
            ) {
              for (const modelCollectionFilter of props.modelCollectionsAdditionalDataQueryParamValueForIndividualClassAccuracy) {
                if (
                  modelCollectionFilter.modelCollectionId ===
                  modelCollectionData._id
                ) {
                  for (const labelFilter of modelCollectionFilter.labels) {
                    if (labelFilter.label === labelData.class) {
                      return labelFilter;
                    }
                  }
                }
              }
            }
            return undefined;
          })();
          labelsFilter.push({
            label: labelData.class,
            confidenceScoreThreshold: isNullOrUndefined(
              modelCollectionLabelFilter?.confidenceScoreThreshold
            )
              ? undefined
              : modelCollectionLabelFilter?.confidenceScoreThreshold,
          });
        }

        filters.push({
          modelCollectionId: modelCollectionData._id,
          labels: labelsFilter,
        });
      }
    }
    props.setIndividualClassAccuracyFilter([...filters]);
  }, [
    props.modelCollectionsAdditionalDataQueryParamValueForIndividualClassAccuracy,
    props.individualClassAccuracyData,
  ]);

  const modelVersions: string[] = (() => {
    return (
      props.individualClassAccuracyData.map(
        (modelCollection) => modelCollection.version
      ) || []
    );
  })();

  const modelLabels: string[] = (() => {
    const labels: string[] = [];
    for (const modelCollection of props.individualClassAccuracyFilter) {
      for (const label of modelCollection.labels) {
        if (!labels.includes(label.label)) {
          labels.push(label.label);
        }
      }
    }
    return labels;
  })();

  const handleLabelConfidenceScoreSwitchChange = (
    modelCollectionId: string,
    label: string,
    checked: boolean
  ) => {
    props.setIndividualClassAccuracyFilter((oldFilter) => {
      for (const modelCollection of oldFilter || []) {
        if (modelCollection.modelCollectionId === modelCollectionId) {
          for (const labelDetails of modelCollection.labels) {
            if (labelDetails.label === label) {
              labelDetails.confidenceScoreThreshold = checked ? 100 : undefined;
            }
          }
        }
      }
      return [...(oldFilter || [])];
    });
  };

  const handleLabelConfidenceScoreChange = (
    modelCollectionId: string,
    label: string,
    value: number
  ) => {
    props.setIndividualClassAccuracyFilter((oldFilter) => {
      for (const modelCollection of oldFilter || []) {
        if (modelCollection.modelCollectionId === modelCollectionId) {
          for (const labelDetails of modelCollection.labels) {
            if (labelDetails.label === label) {
              labelDetails.confidenceScoreThreshold = value;
            }
          }
        }
      }
      return [...(oldFilter || [])];
    });
  };

  const handleApplyFilterButtonClick = () => {
    const modelCollectionAdditionalDataQueryParamValueToSet: DashboardIndividualClassAccuracyFilter =
      [];

    for (const modelCollection of props.individualClassAccuracyFilter) {
      const labelsToSet: typeof modelCollection.labels = [];
      for (const labelDetails of modelCollection.labels) {
        if (!isNullOrUndefined(labelDetails.confidenceScoreThreshold)) {
          labelsToSet.push(labelDetails);
        }
      }
      if (labelsToSet?.length > 0) {
        modelCollectionAdditionalDataQueryParamValueToSet.push({
          modelCollectionId: modelCollection.modelCollectionId,
          labels: labelsToSet,
        });
      }
    }

    setUrlSearchParamsOfCurrentRouteRef.current(
      {
        [AnalyticsDashboardQueryParams.modelCollectionsAdditionalDataForIndividualClassAccuracy]:
          JSON.stringify(modelCollectionAdditionalDataQueryParamValueToSet),
      },
      "historyPush"
    );

    props.onClose();
  };

  return (
    <Popover
      anchorEl={document.getElementById(
        "dashboardIndividualClassAccuracyModelOptions"
      )}
      keepMounted
      anchorOrigin={{
        vertical: "bottom",
        horizontal: "right",
      }}
      transformOrigin={{
        vertical: "top",
        horizontal: "right",
      }}
      open={props.open}
      onClose={props.onClose}
      PaperProps={{
        style: {
          width: "800px",
        },
      }}
    >
      {props.open && (
        <Box style={{ padding: 15 }}>
          <div>
            {/* <Table>
                <TableHead>
                  <TableRow>
                    <TableCell>
                      <strong>
                        Model
                      </strong>
                    </TableCell>
                    <TableCell>
                      <strong>
                        Confidence Score threshold
                      </strong>
                    </TableCell>
                  </TableRow>
                </TableHead>
                {!props.isFetchingIndividualClassAccuracyData &&
                  sortedModelAnalyticsTopPerformingModel?.map((cell, index: number) => {
                    return (
                      <TableRow>
                        <TableCell>{cell.version}</TableCell>
                        <TableCell className={classes.popOverTableCell}>
                          <Switch
                            checked={sliderIsEnable[index]}
                            onChange={() => {
                              setSliderIsEnable((prevState) => {
                                prevState[index] = !prevState[index]
                                return [...prevState]
                              })
                             }}
                            name="checkedA"
                            inputProps={{ 'aria-label': 'secondary checkbox' }}
                          />
                          <Slider
                            value={sliderFieldState.find((state)=> state.modelCollectionId===sortedModelAnalyticsTopPerformingModel[index].modelCollectionId)?.confidenceScoreThreshold}
                            min={0}
                            disabled={!sliderIsEnable[index]}
                            max={100}
                            color="primary"
                            step={1}
                            marks={[
                              { value: 0, label: "0%" },
                              { value: 50, label: "50%" },
                              { value: 100, label: "100%" },
                            ]}

                            onChange={(e, value: number) => {
                              setSliderFieldState((oldState) => {
                                return [...oldState.filter(thresold => thresold.modelCollectionId!==sortedModelAnalyticsTopPerformingModel[index].modelCollectionId), {
                                  modelCollectionId: sortedModelAnalyticsTopPerformingModel[index].modelCollectionId,
                                  confidenceScoreThreshold: value
                                }];
                              })
                            }}

                            valueLabelDisplay="auto"
                            aria-labelledby="range-slider"
                          />
                        </TableCell>
                      </TableRow>
                    )
                  })
                }
              </Table> */}
            <Table>
              <TableHead>
                <TableRow>
                  <TableCell></TableCell>
                  {modelVersions.map((modelVersion) => {
                    return (
                      <TableCell style={{ textAlign: "center" }}>
                        <strong>{modelVersion}</strong>
                      </TableCell>
                    );
                  })}
                </TableRow>
              </TableHead>
              <TableRow>
                <TableCell>
                  <strong>Classes</strong>
                </TableCell>
                {modelVersions.map((modelVersion) => {
                  return (
                    <TableCell style={{ textAlign: "center" }}>
                      <strong>Confidence Score Threshold</strong>
                    </TableCell>
                  );
                })}
              </TableRow>
              {modelLabels.map((modelLabel) => {
                return (
                  <TableRow>
                    <TableCell>{modelLabel}</TableCell>
                    {props.individualClassAccuracyFilter.map(
                      (modelCollectionFilter) => {
                        const modelCollectionLabelFilter =
                          modelCollectionFilter.labels.find(
                            (labelFilter) => labelFilter.label === modelLabel
                          );

                        return (
                          <TableCell
                            style={{
                              // paddingLeft: "40px",
                              paddingRight: "40px",
                            }}
                          >
                            <div
                              style={{
                                display: "flex",
                                columnGap: "20px",
                              }}
                            >
                              <Switch
                                checked={
                                  !isNullOrUndefined(
                                    modelCollectionLabelFilter?.confidenceScoreThreshold
                                  )
                                    ? true
                                    : false
                                }
                                onChange={(e) => {
                                  handleLabelConfidenceScoreSwitchChange(
                                    modelCollectionFilter.modelCollectionId,
                                    modelLabel,
                                    e.target.checked
                                  );
                                }}
                              />

                              <Slider
                                value={
                                  !isNullOrUndefined(
                                    modelCollectionLabelFilter?.confidenceScoreThreshold
                                  )
                                    ? modelCollectionLabelFilter?.confidenceScoreThreshold
                                    : 100
                                }
                                min={0}
                                disabled={
                                  isNullOrUndefined(
                                    modelCollectionLabelFilter?.confidenceScoreThreshold
                                  )
                                    ? true
                                    : false
                                }
                                max={100}
                                color="primary"
                                step={1}
                                marks={[
                                  { value: 0, label: "0%" },
                                  { value: 50, label: "50%" },
                                  { value: 100, label: "100%" },
                                ]}
                                // @ts-ignore
                                onChange={(e, value: number) => {
                                  handleLabelConfidenceScoreChange(
                                    modelCollectionFilter.modelCollectionId,
                                    modelLabel,
                                    value
                                  );
                                }}
                                valueLabelDisplay="auto"
                                aria-labelledby="range-slider"
                              />
                            </div>
                          </TableCell>
                        );
                      }
                    )}
                  </TableRow>
                );
              })}
            </Table>
          </div>

          <div
            style={{
              marginTop: "40px",
              display: "flex",
              justifyContent: "flex-end",
              gap: 20,
            }}
          >
            <div></div>
            <Button
              variant="outlined"
              color="primary"
              onClick={() => {
                props.onClose();
              }}
              // disabled={!sliderFieldState.isDirty}
            >
              Cancel
            </Button>
            <Button
              variant="contained"
              color="primary"
              onClick={() => {
                handleApplyFilterButtonClick();
              }}
              // disabled={modelCollectionsAdditionalData===JSON.stringify(sliderFieldState)}
            >
              Apply
            </Button>
          </div>
        </Box>
      )}
    </Popover>
  );
};

import { queryObjectToString } from "../common";

import { API } from "./API";

const URL_STRINGS = {
  DATASET_COPYING_TO_ANOTHER_MODEL_PROCESS:
    "dataSetCopyingToAnotherModelProcess",
};

type getProcessProgressApiReqPayload = {
  dataSetCollectionIdWhereResourcesBeingCopiedIn: string;
};
export type getProcessProgressApiResponse = {
  _id: string;
  dataSetCollectionToCopyFrom: {
    _id: string;
    name: string;
    resourcesCount: number;
    model: {
      name: string;
    };
  };
  dataSetCollectionToCopyIn: {
    _id: string;
    name: string;
  };
  noOfParentDataSetResourcesCopied: number;
  createdAt: string;
  updatedAt: string;
  failureMessage: string;
} | null;

class dataSetCopyingToAnotherModelProcessService extends API {
  async getProcessProgress(
    data: getProcessProgressApiReqPayload
  ): Promise<getProcessProgressApiResponse> {
    const apiResponse = await this.api.get(
      `${
        URL_STRINGS.DATASET_COPYING_TO_ANOTHER_MODEL_PROCESS
      }/progress?${queryObjectToString(data)}`
    );
    return apiResponse.data;
  }
}

export default new dataSetCopyingToAnotherModelProcessService();

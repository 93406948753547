import { useContext, useEffect, useRef, useState } from "react";
import { useLocation } from "react-router";
import clsx from "clsx";
import { Box, Chip, CircularProgress, FormControl, FormHelperText, Grid,InputLabel, makeStyles, MenuItem, Select, TextField, Tooltip,} from "@material-ui/core";
// import { useForm } from "react-hook-form";
import { connect, ConnectedProps } from "react-redux";

import { AddProjectSVG, CustomButton, IReduxState, IModelType, ModelAnnotationLabelAvailable } from "../../../../../../../common";
import { addModel, getProjectModels } from "../../../../../../../store/actions";
import { Autocomplete } from "@material-ui/lab";
import { InputFieldState } from "../../../../../../../common/constants/interfaces/inputFieldState";
import { removeDuplicateElementsFromArray } from "../../../../../../../services/arrayHelperService";
import { getAnnotationLabel, valueExistsInAnnotationLabels } from "../../../../../../../services/imageAnnotationHelperService";
import { generateUniqueId } from "../../../../../../../services/idHelperService";
import ModelGroup from "../../../../../../../store/types/modelGroup.type";
import { useQuery } from '../../../../../../../common/utils/index';
import { ModelService } from "../../../../../../../services";
import ModelListContext from "../modelistContext/modelist.context";

const useStyles = makeStyles((theme) => ({
    root: {
        marginTop: 'auto',
        marginBottom: 'auto',
        height: "100%"
    },
    illustration: {
        width: '150px',
        height: '150px',
    },
    form: {
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        "& legend": {
            float: "unset"
        }
    },
    formControl: {
        width: 300,
    },
    center: {
        justifyContent: 'center'
    },
    select: {
        marginTop: theme.spacing(2)
    },
    selectLabel: {
        background: '#e7edf3'
    }
}));

const types = [
    { label: 'Audio', value: 'audio' },
    { label: 'Video', value: 'video' },
    { label: 'Video Annotation', value: 'videoAnnotation' },
    { label: 'Multiple Image Annotation', value: 'multipleImageAnnotation' },
    { label: 'Image', value: 'image' },
    { label: 'Image Annotation', value: 'imageAnnotation' },
    { label: 'Image Annotation Group', value: 'imageAnnotationGroup' },
    { label: 'Forecasting', value: 'forecasting' },
    { label: 'Text', value: 'text' },
    { label: 'Text Comparison', value: 'textComparison' },
    { label: 'Text Annotation', value: 'textAnnotation' },
    { label: 'Text File', value: 'textFile' },
    { label: 'DLP', value: 'DLP' },
    { label: 'Prompt', value: 'prompt' },
];

const ITEM_HEIGHT = 60;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
    PaperProps: {
        style: {
            maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
            width: 250,
        },
    },
};


const AddModel = (props: TPropsFromRedux) => {
    const { isAdding, isAddSuccess, addModel, getProjectModels } = props;
    const classes = useStyles();
    const location = useLocation();
    const urlSearchParams = useQuery();
    const projectId: string = urlSearchParams.get("project") || "";

    const {
        fetchModelGroupsFromDB
    } = useContext(ModelListContext) || {};

    const fetchModelGroupsFromDBRef = useRef(fetchModelGroupsFromDB)
    fetchModelGroupsFromDBRef.current = fetchModelGroupsFromDB;

    const [isCreatingModelGroupInDB, setIsCreatingModelGroupInDB] = useState(false);

    const [modelGroups, setModelGroups] = useState<{
        name: ModelGroup["name"], 
        _id: ModelGroup["_id"]
    }[]>([]);

    const [areModelGroupsBeingFetchedFromDB, setAreModelGroupsBeingFetchedFromDB] = useState(false);

    const [modelGroupNameSelectedState, setModelGroupNameSelectedState] = useState<InputFieldState<string>>({
        value: ""
    })

    useEffect(() => {
      const executeFunction = async () => {
          setAreModelGroupsBeingFetchedFromDB(true);
          const modelGroupsResponse = await ModelService.getModelGroups({
            projectId: projectId
          })
          setAreModelGroupsBeingFetchedFromDB(false);
          setModelGroups([...modelGroupsResponse])
      }
      executeFunction();
    }, [projectId, props.isSideDrawerOpened])
    

    const handleChange = (event: React.ChangeEvent<{ value: unknown }>) => {
        // setSelected(event.target.value as string[]);
        // setValue("tags", event.target.value as string[], { shouldValidate: true });
        setModelTypeState(oldState=>{return {
            ...oldState,
            isDirty: true,
            isTouched: true,
            value: event.target.value as string
        }})
    };

    const [modelNameState, setModelNameState] = useState<InputFieldState<string>>({value: ""});
    useEffect(()=>{
        if (modelNameState.value === '') {
            setModelNameState((oldState) => { return {
                ...oldState,
                errorMessage: "Task name is required"
            } })
        } else if (modelNameState.errorMessage) {
            setModelNameState((oldState) => { return {
                ...oldState,
                errorMessage: ""
            } })
        }
    }, [modelNameState.value, modelNameState.errorMessage])    

    useEffect(()=>{
        if (!modelGroupNameSelectedState.value) {
            setModelGroupNameSelectedState((oldState) => { return {
                ...oldState,
                errorMessage: "Task group is required"
            } })
        } else if (modelGroupNameSelectedState.errorMessage) {
            setModelGroupNameSelectedState((oldState) => { return {
                ...oldState,
                errorMessage: ""
            } })
        }
    }, [modelGroupNameSelectedState.value, modelGroupNameSelectedState.errorMessage])    

    useEffect(()=>{
        if (modelNameState.isTouched) {
            setModelNameState(oldState=>{return {
                ...oldState,
                canShowErrorToUser: true
            }})
        }
    }, [modelNameState.isTouched])

    const [modelTypeState, setModelTypeState] = useState<InputFieldState<IModelType | string>>({value: ""});
    useEffect(()=>{
        if (modelTypeState.value === '') {
            setModelTypeState((oldState) => { return {
                ...oldState,
                errorMessage: "Task type is required"
            } })
        } else if (modelTypeState.errorMessage) {
            setModelTypeState((oldState) => { return {
                ...oldState,
                errorMessage: ""
            } })
        }
    }, [modelTypeState.value, modelTypeState.errorMessage])    

    useEffect(()=>{
        if (modelTypeState.isTouched) {
            setModelTypeState(oldState=>{return {
                ...oldState,
                canShowErrorToUser: true
            }})
        }
    }, [modelTypeState.isTouched])


    // const [labelsState, setLabelsState] = useState<InputFieldState<string[]>>({
    //     value: [],
    // }); 
    // const labels = labelsState.value; 
    // useEffect(()=>{
    //     if (labels.length === 0) {
    //         setLabelsState((oldState) => { return {
    //             ...oldState,
    //             errorMessage: "Task labels are required"
    //         } })
    //     } else if (labelsState.errorMessage) {
    //         setLabelsState((oldState) => { return {
    //             ...oldState,
    //             errorMessage: ""
    //         } })
    //     }
    // }, [labels, labelsState.errorMessage])
      
    // useEffect(()=>{
    //     if (labelsState.isTouched) {
    //         setLabelsState(oldState=>{return {
    //             ...oldState,
    //             canShowErrorToUser: true
    //         }})
    //     }
    // }, [labelsState.isTouched])

    const [annotationLabelsState, setAnnotationLabelsState] = useState<InputFieldState<ModelAnnotationLabelAvailable[]>>({
        value: [],
      });
      const annotatationLabels = annotationLabelsState.value
    useEffect(()=>{
        if (
            modelTypeState.value !== 'imageAnnotation' && 
            modelTypeState.value !== 'imageAnnotationGroup'
        ) {
            if (annotationLabelsState.errorMessage || annotatationLabels.length>0) {
                setAnnotationLabelsState(oldState=>{return {
                    ...oldState,
                    isDirty: false,
                    isTouched: false,
                    value: [],
                    errorMessage: ""
                }})
            }
            return;
        }

        if (annotatationLabels.length === 0) {
            setAnnotationLabelsState((oldState) => { return {
                ...oldState,
                errorMessage: "Task annotation labels are required"
            } })
        } else if (annotationLabelsState.errorMessage) {
            setAnnotationLabelsState((oldState) => { return {
                ...oldState,
                errorMessage: ""
            } })
        }
    }, [annotatationLabels, annotationLabelsState.errorMessage, modelTypeState.value])      
    useEffect(()=>{
        if (annotationLabelsState.isTouched) {
            setAnnotationLabelsState(oldState=>{return {
                ...oldState,
                canShowErrorToUser: true
            }})
        }
    }, [annotationLabelsState.isTouched])

    // const [architecturesState, setArchitecturesState] = useState<
    //   InputFieldState<string[]>
    // >({ value: [] }); 
    // const architectures = architecturesState.value;

    // useEffect(()=>{
    //     if (architectures.length === 0) {
    //         setArchitecturesState((oldState) => { return {
    //             ...oldState,
    //             errorMessage: "Task architectures are required"
    //         } })
    //     } else if (architecturesState.errorMessage) {
    //         setArchitecturesState((oldState) => { return {
    //             ...oldState,
    //             errorMessage: ""
    //         } })
    //     }
    // }, [architectures, architecturesState.errorMessage])    

    // useEffect(()=>{
    //     if (architecturesState.isTouched) {
    //         setArchitecturesState(oldState=>{return {
    //             ...oldState,
    //             canShowErrorToUser: true
    //         }})
    //     }
    // }, [architecturesState.isTouched])

    // function handleArchitecturesInputChangeEvent(eventValue: string) {
    //     if (eventValue.includes(",")) {
    //       const values: string[] = eventValue
    //         .split(",")
    //         .map((value) => value.trim())
    //         .filter((value: string) => {
    //           if (!value) {
    //             return false;
    //           }
    //           return true;
    //         });
    
    //       if (values.length > 0) {
    //         const oldValue = architecturesState.value;
    //         for (const value of values) {
    //           if (!oldValue.includes(value)) {
    //             oldValue.push(value);
    //           }
    //         }
    
    //         const architecturesToSave = removeDuplicateElementsFromArray(oldValue);
    
    //         setArchitecturesState({
    //           ...architecturesState,
    //           isDirty: true,
    //           isTouched: true,
    //           value: [...architecturesToSave],
    //         });
    //       }
    //     }
    //   }
    
    // function handleLabelsInputChangeEvent(eventValue: string) {
    //     if (eventValue.includes(",")) {
    //       const values: string[] = eventValue
    //         .split(",")
    //         .map((value) => value.trim())
    //         .filter((value: string) => {
    //           if (!value) {
    //             return false;
    //           }
    //           return true;
    //         });
    
    //       if (values.length > 0) {
    //         const oldValue = labelsState.value;
    //         for (const value of values) {
    //           if (!oldValue.includes(value)) {
    //             oldValue.push(value);
    //           }
    //         }
    
    //         const labelsToSave = removeDuplicateElementsFromArray(oldValue);
    
    //         setLabelsState({
    //           ...labelsState,
    //           isDirty: true,
    //           isTouched: true,
    //           value: [...labelsToSave],
    //         });
    //       }
    //     }
    //   }    

    function isFormValid(): boolean {
        if (
            modelNameState.errorMessage ||
            modelGroupNameSelectedState.errorMessage ||
            modelTypeState.errorMessage ||
            // labelsState.errorMessage ||
            // architecturesState.errorMessage ||
            annotationLabelsState.errorMessage
        ) {
            return false;
        }
        return true
    }

    function showErrorsOfAllFields() {
        setModelNameState(oldState=>{return {
            ...oldState,
            canShowErrorToUser: true
        }})
        setModelTypeState(oldState=>{return {
            ...oldState,
            canShowErrorToUser: true
        }})
        setModelGroupNameSelectedState(oldState=>{return {
            ...oldState,
            canShowErrorToUser: true
        }})
        // setLabelsState(oldState=>{return {
        //     ...oldState,
        //     canShowErrorToUser: true
        // }})
        // setArchitecturesState(oldState=>{return {
        //     ...oldState,
        //     canShowErrorToUser: true
        // }})
        setAnnotationLabelsState(oldState=>{return {
            ...oldState,
            canShowErrorToUser: true
        }})
    }

    const onSubmit = async (): Promise<void> => {

        if (!isFormValid()) {
            showErrorsOfAllFields();
            return;
        }

        let selectedModelGroupId = (() => {
            const modelGroup = modelGroups.find(modelGroup=>modelGroup.name === modelGroupNameSelectedState.value)
            if (modelGroup) {
                return modelGroup._id;
            }
            return "";
        })();

        // if (!selectedModelGroupId) {
        //     setIsCreatingModelGroupInDB(true)
        //     const newModelGroup = await ModelService.createModelGroup({
        //         name: modelGroupNameSelectedState.value,
        //         projectId: projectId
        //     })
        //     setIsCreatingModelGroupInDB(false)
        //     selectedModelGroupId = newModelGroup._id;
        // }

        const query = new URLSearchParams(location.search);
        addModel({
            project: query.get('project') || '',
            modelGroupId: selectedModelGroupId,
            name: modelNameState.value,
            type: modelTypeState.value as IModelType,
            labelsAvailable: [],
            architecturesAvailable: [],
            annotationLabelsAvailable: annotationLabelsState.value
        });
    };
    function resetForm(): void {
        setModelNameState(oldState=>{return {
            ...oldState,
            canShowErrorToUser: false,
            isDirty: false,
            isTouched: false,
            value: ''
        }})
        setModelTypeState(oldState=>{return {
            ...oldState,
            canShowErrorToUser: false,
            isDirty: false,
            isTouched: false,
            value: ''
        }})
        setModelGroupNameSelectedState(oldState=>{return {
            ...oldState,
            canShowErrorToUser: false,
            isDirty: false,
            isTouched: false,
            value: ''
        }})
        // setLabelsState(oldState=>{return {
        //     ...oldState,
        //     canShowErrorToUser: false,
        //     isDirty: false,
        //     isTouched: false,
        //     value: []
        // }})
        // setArchitecturesState(oldState=>{return {
        //     ...oldState,
        //     canShowErrorToUser: false,
        //     isDirty: false,
        //     isTouched: false,
        //     value: []
        // }})
        setAnnotationLabelsState(oldState=>{return {
            ...oldState,
            canShowErrorToUser: false,
            isDirty: false,
            isTouched: false,
            value: []
        }})
    }

    function handleAnnotationLabelsInputChangeEvent(eventValue: string) {
        if (eventValue.includes(",")) {
          const values: string[] = eventValue
            .split(",")
            .map((value) => value.trim())
            .filter((value: string) => {
              if (!value) {
                return false;
              }
              return true;
            });
    
          if (values.length > 0) {
            const oldValue = annotationLabelsState.value;
            for (const value of values) {
              if (!valueExistsInAnnotationLabels(value, oldValue)) {
                oldValue.push({id: generateUniqueId(), value: value});
              }
            }
            setAnnotationLabelsState(oldState=>{return {
              ...oldState,
              isDirty: true,
              isTouched: true,
              value: [...oldValue],
            }})
          }
    
        }
      }

    useEffect(() => {
        if (isAddSuccess) {
            resetForm();
            const query = new URLSearchParams(location.search);
            const project = query.get('project') || '';
            getProjectModels({ project, offset: '1', limit: '1000' , getModelGroupName: "true" , collectionsExist : true});
            if (fetchModelGroupsFromDBRef.current) {
                fetchModelGroupsFromDBRef.current();
            }
        }
    }, [isAddSuccess])
    return (
        <Grid classes={{ root: classes.root }} container direction="row" alignItems="center" justify="center">
            <div
                style={{
                    height: '90%',
                    overflow: 'auto'
                }}
            >
                <div style={{textAlign: 'center'}}>
                    <AddProjectSVG className={classes.illustration} />
                </div>
                <Box m={1.5} />
                <form className={classes.form} noValidate>
                    <FormControl className={classes.formControl}>
                        <TextField
                            variant="outlined"
                            size="small"
                            margin="normal"
                            required
                            fullWidth
                            id='name'
                            value={modelNameState.value}
                            onChange={(event)=>{
                                setModelNameState(oldState=>{return {
                                    ...oldState,
                                    isDirty: true,
                                    value: event.target.value
                                }})
                            }}
                            onBlur={(event)=>{
                                setModelNameState(oldState=>{return {
                                    ...oldState,
                                    isTouched: true,
                                }})                                
                            }}
                            label='Task Name'
                            error={
                                modelNameState.canShowErrorToUser && modelNameState.errorMessage
                                ? true
                                : false
                            }
                            helperText={
                                modelNameState.canShowErrorToUser && modelNameState.errorMessage
                                ? modelNameState.errorMessage
                                : ""
                            }
                            autoFocus
                        />
                    </FormControl>

                    <FormControl variant="outlined" size="small" className={clsx(classes.formControl, classes.select)} error={modelTypeState.canShowErrorToUser && modelTypeState.errorMessage ? true : false}>
                        <InputLabel className={classes.selectLabel} id="type">Type*</InputLabel>
                        <Select
                            variant="outlined"
                            labelId="tags"
                            // input={<BootstrapInput />}
                            MenuProps={MenuProps}
                            onChange={handleChange}
                            value={modelTypeState.value}
                        >
                            <MenuItem value="" disabled>
                                Select Type
                            </MenuItem>
                            {
                                types.map((type) => <MenuItem key={type.value} value={type.value}>
                                    {type.label}
                                </MenuItem>)
                            }
                        </Select>
                        <FormHelperText>
                            {
                                modelTypeState.canShowErrorToUser && modelTypeState.errorMessage
                                ? modelTypeState.errorMessage
                                : ''
                            }
                        </FormHelperText>
                    </FormControl>

                    {
                        (
                            modelTypeState.value === 'imageAnnotation' ||
                            modelTypeState.value === 'imageAnnotationGroup' 
                        )   &&
                        <FormControl className={classes.formControl}>
                            <Autocomplete
                                multiple
                                id="annotationLabelsAvailable"
                                options={[]}
                                freeSolo
                                value={
                                    annotationLabelsState.value
                                    .map(annotationLabel=>{
                                    return annotationLabel.value
                                    })
                                }
                                onChange={(event, value) => {
                                    const annotationLabelValues = removeDuplicateElementsFromArray(
                                    value as string[]
                                    );

                                    const updatedAnnotationLabels: ModelAnnotationLabelAvailable[] = [];

                                    for (let index = 0; index < annotationLabelValues.length; index++) {
                                    const annotationLabelValue = annotationLabelValues[index];
                                    const annotationLabel = getAnnotationLabel(annotationLabelValue, annotationLabelsState.value) || {id: generateUniqueId(), value: annotationLabelValue};
                                    updatedAnnotationLabels.push(annotationLabel);
                                    }

                                    setAnnotationLabelsState((oldState) => {
                                    return {
                                        ...oldState,
                                        isDirty: true,
                                        isTouched: true,
                                        value: [...updatedAnnotationLabels],
                                    };
                                    });
                                }}
                                renderTags={(value, getTagProps) => {
                                    return value.map((option, index) => (
                                    <Chip
                                        variant="outlined"
                                        color="primary"
                                        // className={`${classes.chip}`}
                                        // classes={{ root: classes.autoCompleteChipRoot }}
                                        label={
                                        <Tooltip title={option}>
                                            <span>{option}</span>
                                        </Tooltip>
                                        }
                                        {...getTagProps({ index })}
                                    />
                                    ));
                                }}
                                renderInput={(params) => (
                                    <TextField
                                    {...params}
                                    variant="outlined"
                                    size="small"
                                    margin="normal"
                                    fullWidth
                                    label="Annotation Labels Available"
                                    placeholder="Add Annotation Label"
                                    onChange={(e) => {
                                        handleAnnotationLabelsInputChangeEvent(e.target.value);
                                    }}
                                    onBlur={(e) => {
                                        handleAnnotationLabelsInputChangeEvent(e.target.value + ",");
                                    }}
                                    error={
                                        annotationLabelsState.canShowErrorToUser &&
                                        annotationLabelsState.errorMessage
                                        ? true
                                        : false
                                    }
                                    helperText={
                                        annotationLabelsState.canShowErrorToUser &&
                                        annotationLabelsState.errorMessage
                                        ? annotationLabelsState.errorMessage
                                        : "Add multiple annotation labels with <enter> or <comma>"
                                    }
                                    />
                                )}
                            />
                        </FormControl>
                    }

                    <FormControl 
                        className={`${classes.formControl}`}
                    >
                        <Autocomplete
                            style={{
                                marginTop: "28px"
                            }}
                            freeSolo
                            options={modelGroups.map(modelGroup=>modelGroup.name)}
                            filterOptions={(options, state) => {
                                if (!modelGroupNameSelectedState.value) {
                                    return options;
                                }
                                const filteredOptions = options.filter(option=>option.toLowerCase().indexOf(modelGroupNameSelectedState.value.toLowerCase()) !== -1)
                                return filteredOptions;
                            }}
                            onChange={(event, value)=>{
                                setModelGroupNameSelectedState(oldState=>{
                                    return{...oldState, value: value || "", isDirty: true}
                                });                        
                            }}
                            value={modelGroupNameSelectedState.value}
                            renderInput={(params)=>{
                                return (
                                    <TextField
                                        {...params}
                                        size="small"
                                        variant="outlined"
                                        label="Task Group*"
                                        // className={`${props.classes.floating}`}
                                        style={{width: '100%'}}
                                        onChange={(e) => {
                                            setModelGroupNameSelectedState(oldState=>{
                                                return{...oldState, value: e.target.value, isDirty: true}
                                            });
                                        }}
                                        onBlur={()=>{
                                            setModelGroupNameSelectedState(oldState=>{
                                                return{...oldState, isTouched: true}
                                            });
                                        }}
                                        error={
                                            modelGroupNameSelectedState.canShowErrorToUser &&
                                            modelGroupNameSelectedState.errorMessage
                                              ? true
                                              : false
                                          }
                                          helperText={
                                            modelGroupNameSelectedState.canShowErrorToUser &&
                                            modelGroupNameSelectedState.errorMessage
                                              ? modelGroupNameSelectedState.errorMessage
                                              : ""
                                          }                                        
                                    />
                                )
                            }}
                        />                                
                    </FormControl>

                    {/* <FormControl className={classes.formControl}>
                        <Autocomplete
                            multiple
                            options={[]}
                            freeSolo
                            value={labelsState.value}
                            getOptionSelected={() => false}
                            onChange={(event, value) => {
                                setLabelsState((oldState) => {
                                const labelsToSave = removeDuplicateElementsFromArray(
                                    value as string[]
                                );

                                return {
                                    ...oldState,
                                    isDirty: true,
                                    isTouched: true,
                                    value: [...labelsToSave],
                                };
                                });
                            }}
                            renderTags={(value, getTagProps) => {
                                return value.map((option, index) => (
                                <Chip
                                    variant="outlined"
                                    color="primary"
                                    // className={`${classes.chip}`}
                                    // classes={{ root: classes.autoCompleteChipRoot }}
                                    label={
                                    <Tooltip title={option}>
                                        <span>{option}</span>
                                    </Tooltip>
                                    }
                                    {...getTagProps({ index })}
                                />
                                ));
                            }}
                            renderInput={(params) => (
                                <TextField
                                {...params}
                                variant="outlined"
                                size="small"
                                margin="normal"
                                required
                                fullWidth
                                label="Labels"
                                placeholder="Add labels"
                                onChange={(e) => {
                                    handleLabelsInputChangeEvent(e.target.value);
                                }}
                                onBlur={(e) => {
                                    handleLabelsInputChangeEvent(e.target.value + ",");
                                }}
                                error={
                                    labelsState.canShowErrorToUser && labelsState.errorMessage
                                    ? true
                                    : false
                                }
                                helperText={
                                    labelsState.canShowErrorToUser && labelsState.errorMessage
                                    ? labelsState.errorMessage
                                    : "Add multiple labels with <enter> or <comma>"
                                }
                                />
                            )}
                        />
                    </FormControl> */}
                    {/* <FormControl className={classes.formControl}>
                        <Autocomplete
                            multiple
                            options={[]}
                            freeSolo
                            value={architecturesState.value}
                            getOptionSelected={() => false}
                            onChange={(event, value) => {
                                setArchitecturesState((oldState) => {
                                const architecturesToSave = removeDuplicateElementsFromArray(
                                    value as string[]
                                );

                                return {
                                    ...oldState,
                                    isDirty: true,
                                    isTouched: true,
                                    value: [...architecturesToSave],
                                };
                                });
                            }}
                            renderTags={(value, getTagProps) => {
                                return value.map((option, index) => (
                                <Chip
                                    variant="outlined"
                                    color="primary"
                                    // className={`${classes.chip}`}
                                    // classes={{ root: classes.autoCompleteChipRoot }}
                                    label={
                                    <Tooltip title={option}>
                                        <span>{option}</span>
                                    </Tooltip>
                                    }
                                    {...getTagProps({ index })}
                                />
                                ));
                            }}
                            renderInput={(params) => (
                                <TextField
                                {...params}
                                variant="outlined"
                                size="small"
                                margin="normal"
                                fullWidth
                                label="Architectures"
                                placeholder="Add Architectures"
                                onChange={(e) => {
                                    handleArchitecturesInputChangeEvent(e.target.value);
                                }}
                                onBlur={(e) => {
                                    handleArchitecturesInputChangeEvent(e.target.value + ",");
                                }}
                                error={
                                    architecturesState.canShowErrorToUser &&
                                    architecturesState.errorMessage
                                    ? true
                                    : false
                                }
                                helperText={
                                    architecturesState.canShowErrorToUser &&
                                    architecturesState.errorMessage
                                    ? architecturesState.errorMessage
                                    : "Add multiple architectures with <enter> or <comma>"
                                }
                                />
                            )}
                        />                    
                    </FormControl> */}
                    <Box m={1.5} />
                </form>
            </div>
            <div>
                <CustomButton onClick={()=>onSubmit()} className={classes.formControl} disabled={isAdding || isCreatingModelGroupInDB}>{isAdding || isCreatingModelGroupInDB ? <CircularProgress size={20} /> : 'Add Task'}</CustomButton>
            </div>
        </Grid>
    )
}

const mapProps = (state: IReduxState) => {
    return {
        isAdding: state.model.isAdding,
        isAddSuccess: state.model.isAddSuccess,
        isSideDrawerOpened: state.optionsDrawer.isOpen
    };
}

const connector = connect(mapProps, { addModel, getProjectModels });

export type TPropsFromRedux = ConnectedProps<typeof connector>

export default connector(AddModel);

import React, { useEffect, useState } from 'react'
import CloudStorageService from '../../../../../../../../../../../services/CloudStorageService';
import { makeStyles, Theme } from '@material-ui/core/styles';
import Table from '@material-ui/core/Table';
import TableHead from '@material-ui/core/TableHead';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableRow from '@material-ui/core/TableRow';
import { CircularProgress, Button } from '@material-ui/core';

const useStyles = makeStyles((theme: Theme) => ({
    eachRowHeading : {
        position: 'sticky',
        left: 0,
        zIndex: 10,
        backgroundColor: 'white',
        opacity: 1
    }
}));

function ConfusionTable( props: {fileName : string, gcsFileName : string, projectId : string, analysisObjectId : string}) {

    const classes = useStyles();
    const [jsonData, setJsonData] = useState<any>({});
    const [ isFetching, setIsFetching] = useState<boolean>(false);
    const [showConfusionTable, setShowConfusionTable] = useState<{isOpen: boolean, selectedFileName: string | undefined, analysisObjectId : string | undefined}>({
        isOpen: false,
        selectedFileName: undefined,
        analysisObjectId :  undefined
    });
    useEffect(() => {    
      const jsonApiCall = async() => {
        setIsFetching(true);
          const jsonData = await CloudStorageService.getResourceBucketJsonFileData({
            storageFileName: props.gcsFileName,
            projectId: props.projectId              
          });
          setJsonData(jsonData)
         setIsFetching(false);
      }
      jsonApiCall()
    }, [props.gcsFileName, props.projectId])



    
    let rowArray : string[] = []
    let colArray : string[] = []
    let data : any
    if(jsonData){
        Object.keys(jsonData).forEach( function(key){
            rowArray.push(key);
        });

        data = Object.entries(jsonData);
        if(data.length > 0){
        Object.keys(data[0][1]).forEach( function(key){
            colArray.push( key );
        });}
    }

    const onClickShowConfusionTable = ( fileName : string , id:string) => {
        if(showConfusionTable.isOpen && showConfusionTable.analysisObjectId === id){
            setShowConfusionTable(oldState=>({...oldState, isOpen: false, selectedFileName: undefined, analysisObjectId: undefined}))
        }
        else if(showConfusionTable.isOpen && showConfusionTable.analysisObjectId !== id){
            // setShowConfusionTable(oldState=>({...oldState, isOpen: false, selected: undefined, analysisObjectId: undefined}))
            setShowConfusionTable(oldState=>({...oldState, isOpen: true, selectedFileName: fileName, analysisObjectId: id}))
        }
        else if(showConfusionTable.isOpen === false){
            setShowConfusionTable(oldState=>({...oldState, isOpen: true, selectedFileName: fileName, analysisObjectId: id}))
        }
    }
    
    
   const isConfusionJson = () => {
    if(props.fileName.startsWith("confusion_") && props.fileName.endsWith(".json")){
        return true;
    }
    else{
        return false;
    }
   }


  return (
    
    <div>
        <div style={{display:'flex', justifyContent: 'flex-end', margin: '10px 20px 20px'}}>
            <Button onClick={() => {onClickShowConfusionTable(props.fileName, props.analysisObjectId)}} style={{outline: 'none'}} variant='outlined' color='primary'>
                {showConfusionTable.isOpen && (showConfusionTable.selectedFileName === props.fileName) && (showConfusionTable.analysisObjectId === props.analysisObjectId) ? 
                "Hide Confusion Table"
                :
                "Show Confusion Table"}
            </Button>
        </div>
        {
            showConfusionTable.isOpen && (showConfusionTable.selectedFileName === props.fileName) && (showConfusionTable.analysisObjectId === props.analysisObjectId) && isConfusionJson()
            &&
            <div>
            <div style={{fontWeight: 700}}>Confusion Table</div>
            {!isFetching ? 
            <div>
            <TableContainer style={{height: '250px'}}>
                <Table size="small" stickyHeader>
                    <TableHead>
                        
                        <TableRow>
                            <TableCell className = {classes.eachRowHeading} style={{zIndex: 15}}>

                            </TableCell>
                            {
                                rowArray.map((tableHead, index) => {
                                    return <TableCell style={{fontWeight: 700}}>
                                        {tableHead}
                                    </TableCell>
                                })
                            }
                        </TableRow>
                    </TableHead>
                    <TableBody>
                            {
                                colArray.map((col, index) => {
                                    return <TableRow>
                                        <TableCell style={{fontWeight: 700}} className={classes.eachRowHeading}>
                                            {col}
                                        </TableCell>
                                        {
                                            rowArray.map((tableHead, index1) => {
                                                return (
                                                data.map((dataRow : any) => {
                                                    for(let i in dataRow[1]){
                                                        let eachRow : any = dataRow[1]                                                    
                                                        if(dataRow[0] === tableHead && i === col ){
                                                            let value = eachRow[i]
                                                            return <TableCell style={{color: (value === 0 || i === tableHead)? 'black': 'red'}}>
                                                                {value}
                                                            </TableCell>
                                                        }
                                                    }
                                                    
                                                }))
                                            })
                                        }
                                    </TableRow>
                                })
                            }
                    </TableBody>
                </Table>
            </TableContainer>
            </div>
            :
            <div style={{display: 'flex', alignItems: 'center'}}>
                <CircularProgress /> 
            </div>}
            </div>
        }
    </div>
  )
}

export default ConfusionTable
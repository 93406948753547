import { Button, CircularProgress, Dialog, DialogActions, DialogContent, DialogTitle, IconButton, Table, TableBody, TableCell, TableHead, TableRow, Theme, Typography, makeStyles } from '@material-ui/core';
import React, { useEffect, useState } from 'react'
import { IData } from '../../../../../../../../../../../common';
import { ICoPilotResource } from '../../../../../../../../../../../common/constants/interfaces/coPilotResource';
import { DataService } from '../../../../../../../../../../../services';
import CloseIcon from '@material-ui/icons/Close';
import { generateUniqueId } from '../../../../../../../../../../../services/idHelperService';

const useStyles = makeStyles((theme: Theme) => ({
    closeButton: {
        position: 'absolute',
        right: theme.spacing(1),
        top: theme.spacing(1),
        color: theme.palette.grey[500],
      },
    
}))

const ViewAsCSVDialog = (props: {
    resources: (IData | ICoPilotResource)[],
    open: boolean,
    setOpen: React.Dispatch<React.SetStateAction<boolean>>,
    onClose: () => void
}) => {
    const classes = useStyles();

    const [resourceRows, setResourcesRows] = useState<any[]>([]);
    const [resourceHeading, setResourceHeading] = useState<any[]>([]);

    const [downloadingCSV, setDownloadingCSV] = useState<boolean>(false)

    const handleDownloadCSV = async () => {
        try{
            setDownloadingCSV(true)
            const ids = props.resources.map((resource) => {
                return resource._id.toString()
            })
            const apiResponse = await DataService.downloadResourceCSVData(ids)
            const element = document.createElement('a');
            element.setAttribute('href', apiResponse.data);
            element.setAttribute('download', `resource${generateUniqueId()}_resources.csv`);
            document.body.appendChild(element);
            element.click();
            setDownloadingCSV(false)
        }catch(error) {
            console.log("Error:", error)
        }
    }

    useEffect(() => {
        const handleGetCSVData = async () => {
            const ids = props.resources.map((resource) => {
                return resource._id.toString()
            })
            const apiResponse = await DataService.getResourceCSVData(ids)
            setResourceHeading(apiResponse.data.heading)
            setResourcesRows(apiResponse.data.rows)
        }
        handleGetCSVData()
    }, [])

    return (
        <Dialog
            maxWidth={"lg"}
            fullWidth
            aria-labelledby="simple-dialog-title" open={true}>
            <DialogTitle id="simple-dialog-title" style={{height: 50}}>
                <Typography variant="h6">CSV View</Typography>
                <IconButton aria-label="close" className={classes.closeButton} onClick={props.onClose}>
                    <CloseIcon />
                </IconButton>
            </DialogTitle>
            <DialogContent>
                {
                    resourceHeading.length === 0 ?
                    <div style={{display: "flex", flexDirection: "row", justifyContent: "center", alignItems: "center"}}>
                        <CircularProgress />
                        </div>
                        :
                        <Table aria-label="simple table">
                            <TableHead>
                                <TableRow>
                                    {
                                        resourceHeading.map((heading) => (
                                            <TableCell align="left">{`${heading?.[0]}`}</TableCell>
                                        ))
                                    }
                                </TableRow>
                            </TableHead>
                            <TableBody>
                                {resourceRows.map((row) => (
                                    <TableRow key={`${row}`}>
                                        {resourceHeading.map((heading) => (
                                            <TableCell style={{ width: "fit-content", maxHeight: 20}} align="left"><p>{`${row?.[heading?.[0]] || ""}`}</p></TableCell>
                                        ))}
                                    </TableRow>
                                ))}
                            </TableBody>
                        </Table>}
            </DialogContent>
            <DialogActions>
                <Button color="primary" variant="contained"
                onClick={handleDownloadCSV}>
                    <div style={{ display: "flex", flexDirection: "row", alignItems: "flex-start", justifyContent: "center", gap: 20}}>
                    {
                        downloadingCSV&&
                        <CircularProgress style={{ width: 15, height: 15, color: "white"}} />
                    }
                    Download CSV
                    </div>
                </Button>
            </DialogActions>
        </Dialog>
    )
}

export default ViewAsCSVDialog
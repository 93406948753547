export class BrowserUrlService {
  static removeQueryParamFromBrowser(params: { propertyToRemove: string }) {
    let url = new URL(window.location.href);
    url.searchParams.delete(params.propertyToRemove);
    let newUrl = url.toString();

    // Update the browser URL without reloading
    window.history.replaceState({}, document.title, newUrl);
  }
}

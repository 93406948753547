import { Dispatch, SetStateAction, createContext } from "react";

export type SearchData = {
    autoCompleteData:{_id:string,name:string}[],
    searchQuery:string,
    autoCompleteValueChange:string
}

type SearchDataContext = {
    searchData:SearchData,
    setSearchData:Function,
}
export const SearchDataContext = createContext<SearchDataContext>({
    searchData:{autoCompleteData:[],searchQuery:'',autoCompleteValueChange:''},
    setSearchData:()=>{},
})
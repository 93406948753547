import { getEnv } from "./envHelperService";

// export const APP_API: string = 'http://localhost:3000'
// export const APP_API: string = 'https://autoai-backend-qa-exjsxe2nda-uc.a.run.app'
export const APP_API: string = getEnv("REACT_APP_API");
export const CLOUD_TYPE: "azure" | "gcp" = getEnv("REACT_APP_CLOUD_TYPE") as ("azure" | "gcp");
// process.env.REACT_APP_CLOUD_TYPE as ("azure" | "gcp") || "gcp";
export const HYPERVISOR_DOMAIN: string = getEnv("REACT_APP_HYPERVISOR_DOMAIN");
// process.env.REACT_APP_HYPERVISOR_DOMAIN as string;
export const RLEF_TRAINING_POD_GENERATION_DOMAIN: string = getEnv("RLEF_TRAINING_POD_GENERATION_DOMAIN")
export const EGPT_BASE_URL: string = getEnv("EGPT_BASE_URL")
// export const EGPT_BASE_URL: string = "https://dev-egpt.techo.camp" 
// export const EGPT_BASE_URL: string = "https://rlef-llm-studio.techo.camp"


export const LLM_STUDIO_CHAT_ASSISTANT_NAME: string = getEnv("LLM_STUDIO_CHAT_ASSISTANT_NAME");
// export const LLM_STUDIO_CHAT_ASSISTANT_NAME: string = 'trainingpodchat';
export const LLM_STUDIO_GENERATE_PURPOSE_MODEL_DESCRIPTION_ASSISTANT_NAME: string = getEnv("LLM_STUDIO_GENERATE_PURPOSE_MODEL_DESCRIPTION_ASSISTANT_NAME");
// export const LLM_STUDIO_GENERATE_PURPOSE_MODEL_DESCRIPTION_ASSISTANT_NAME: string = 'trainingpoddescgen';
export const GENERATE_TEST_DRIVE_IMAGES_DESCRIPTION_ASSISTANT_NAME: string = getEnv("GENERATE_TEST_DRIVE_IMAGES_DESCRIPTION_ASSISTANT_NAME");
// export const GENERATE_TEST_DRIVE_IMAGES_DESCRIPTION_ASSISTANT_NAME: string = 'imgagedescription'
export const GENERATE_MODEL_ARCHITECHTURE_DESCRIPTION_ASSISTANT_NAME: string = getEnv('GENERATE_MODEL_ARCHITECHTURE_DESCRIPTION_ASSISTANT_NAME');
// export const GENERATE_MODEL_ARCHITECHTURE_DESCRIPTION_ASSISTANT_NAME: string = 'modelarchitecture'
export const LLM_STUDIO_USER_ID: string = getEnv("LLM_STUDIO_USER_ID")
// export const LLM_STUDIO_USER_ID: string = 'user-123';
export const LLM_STUDIO_CENTRAL_ASSISTANT_NAME: string = getEnv("LLM_STUDIO_CENTRAL_ASSISTANT_NAME")
// export const LLM_STUDIO_CENTRAL_ASSISTANT_NAME: string = 'routerast';
export const LLM_STUDIO_MODEL_RECOMMENDATION_ASSISTANT_NAME: string = getEnv("LLM_STUDIO_MODEL_RECOMMENDATION_ASSISTANT_NAME");
// export const LLM_STUDIO_MODEL_RECOMMENDATION_ASSISTANT_NAME: string = 'modelrecommendagent';
export const LLM_STUDIO_MODEL_TEST_DRIVE_RESULTS_ANALYSIS_ASSISTANT_NAME: string = getEnv("LLM_STUDIO_MODEL_TEST_DRIVE_RESULTS_ANALYSIS_ASSISTANT_NAME");
// export const LLM_STUDIO_MODEL_TEST_DRIVE_RESULTS_ANALYSIS_ASSISTANT_NAME: string = "testdriveanalysisast";

export const RLEF_GCS_BUCKET_NAME: string = getEnv("RLEF_GCS_BUCKET_NAME");
// export const RLEF_GCS_BUCKET_NAME: string = 'auto-ai_resources_fo'

export const FIREBASE_EVENT_CONFIG_JSON_STRING = JSON.stringify({
    apiKey: "AIzaSyCQE8kKiIVFvhs5pdfBVxF6_91hKkaRQ_k",
    authDomain: "faceopen-techolution.firebaseapp.com",
    databaseURL: "https://planogram.firebaseio.com/",
    projectId: "faceopen-techolution",
    storageBucket: "faceopen-techolution.appspot.com",
    messagingSenderId: "246989474877",
    appId: "1:246989474877:web:6b594e62bd8c78d33b2e54",
    measurementId: "G-TN6FG6QKXP"
}) ||
getEnv("REACT_APP_FIREBASE_EVENT_CONFIG_JSON_STRING")

export const SHOW_TRAINING_POD_SIMPLIFICATION_FEATURE = getEnv("SHOW_TRAINING_POD_SIMPLIFICATION_FEATURE");

export const SHOW_AI_ASSISTANT_LABELLING_SIMPLIFICATION_FEATURE = getEnv("SHOW_AI_ASSISTANT_LABELLING_SIMPLIFICATION_FEATURE");
export const SHOW_AI_MARKETPLACE_AS_LANDING_PAGE: boolean = getEnv("SHOW_AI_MARKETPLACE_AS_LANDING_PAGE") === "true";

export const DO_NOT_FORCE_MODEL_AND_TEST_COLLECTION_POPUP_SHOWING = getEnv("DO_NOT_FORCE_MODEL_AND_TEST_COLLECTION_POPUP_SHOWING");
export const COPILOT_TEXT_FOR_SIDE_DRAWER: string = getEnv("COPILOT_TEXT_FOR_SIDE_DRAWER")
export const COPILOT_TEXT_FOR_BREADCRUMBS: string = getEnv("COPILOT_TEXT_FOR_BREADCRUMBS")
export const COPILOT_ADD_BUTTON_TITLE: string = getEnv("COPILOT_ADD_BUTTON_TITLE")

export const TASK_IDS_TO_SET_LIMIT_5_FOR: string[] = getEnv("TASK_IDS_TO_SET_LIMIT_5_FOR")?.split(',') || []

// export const TEMP_APP_API : string = 'https://autoai-backend-temp-246989474877.us-central1.run.app/'
export const TEMP_APP_API : string = getEnv("TEMP_APP_API") 

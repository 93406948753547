import { useLocation } from "react-router";
import { useMemo } from "react";

/**
 * Use to fetch query parameters from url.
 *
 * for example "url?foo=bar"
 *
 * const query = useURLQuery();
 * query.get('foo');
 */
export function useURLQuery() {
  const { search } = useLocation();

  return useMemo(() => new URLSearchParams(search), [search]);
}

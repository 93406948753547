import { useEffect, useState } from "react";
import { IData } from "../common";
import { DataService } from "../services";
import { Cache } from "../classes/Cache";

type Props = {
  resourceIds: string[];
  shouldFetchResourceProjectDetails?: boolean;
  resourceCache?: {
    timeToLiveInMilliSeconds?: number;
  };
};

const resourceCache = new Cache();

/**
 * @description A React hook that fetches details for a list of resources, leveraging a cache to minimize API calls.
 */
export const useFetchResourcesDetails = (props: Props) => {
  const [resources, setResources] = useState<IData[]>([]);

  const resourceCacheTimeToLiveInMilliSeconds =
    props.resourceCache?.timeToLiveInMilliSeconds || 1 * 60 * 1000;
  const shouldFetchResourceProjectDetails =
    props.shouldFetchResourceProjectDetails || false;
  const resourceIdsAsString = JSON.stringify(props.resourceIds);

  useEffect(() => {
    const performLogic = async () => {
      let resourceFetchPromises = [];

      const resourceIds = JSON.parse(resourceIdsAsString);

      for (const resourceId of resourceIds) {
        const resourceFetchPromise: Promise<IData> = new Promise(
          async (resolve) => {
            const cachedResource = resourceCache.get(resourceId);
            if (cachedResource) {
              return resolve(JSON.parse(cachedResource));
            }
            const resourceApiResponse = await DataService.getData({
              ids: JSON.stringify([resourceId]),
              getResourceProjectDetails: shouldFetchResourceProjectDetails
                ? "true"
                : "",
            });
            const resourceToSet = resourceApiResponse.data.resources?.[0];
            resourceCache.set(
              resourceId,
              JSON.stringify(resourceToSet),
              resourceCacheTimeToLiveInMilliSeconds
            );
            return resolve(resourceToSet);
          }
        );
        resourceFetchPromises.push(resourceFetchPromise);
      }
      const resourcesToSet = await Promise.all(resourceFetchPromises);
      setResources([...resourcesToSet]);
    };
    performLogic();
  }, [
    resourceCacheTimeToLiveInMilliSeconds,
    resourceIdsAsString,
    shouldFetchResourceProjectDetails,
  ]);

  return {
    resources,
  };
};

import { useEffect, useState } from "react";
import { makeStyles, Theme, createStyles } from "@material-ui/core/styles";
import Modal from "@material-ui/core/Modal";
import Backdrop from "@material-ui/core/Backdrop";
import Fade from "@material-ui/core/Fade";
import Cancel from "@material-ui/icons/Cancel";
import { Button, LinearProgress } from "@material-ui/core";
import { connect, ConnectedProps } from "react-redux";
import {
  getResources,
  updateIsDataFetching,
} from "../../../../../../../../../store/actions/coPilotDetailPageActions";
import { IReduxState } from "../../../../../../../../../common";

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    modal: {
      display: "flex",
      alignItems: "center",
      justifyContent: "center",
    },
    container: {
      minWidth: "45%",
      borderRadius: "10px",
    },
    paper: {
      position: "relative",
      backgroundColor: theme.palette.background.paper,
      boxShadow: theme.shadows[5],
      borderRadius: "10px 10px 0 0",
      padding: theme.spacing(0, 5, 5),
      "&p": {
        margin: "0",
      },
    },
    closeBtn: {
      position: "absolute",
      right: -10,
      display: "flex",
      justifyContent: "center",
      alignItems: "center",
      top: -10,
      backgroundColor: "white",
      borderRadius: "50%",
      cursor: "pointer",
      width: "25px",
      height: "25px",
    },
    row: {
      display: "flex",
      marginTop: "10px",
      justifyContent: "space-around",
    },
    title: {
      color: "#98A6B3",
      margin: "22px 0",
    },
    titleContainer: {
      display: "flex",
      flexDirection: "column",
      fontSize: "22px",
      color: "#212121",
      marginBottom: "40px",
    },
    addButton: {
      backgroundColor: "#008D9C",
      maxWidth: "180px",
      color: "#ffffff",
      padding: "8px 48px",
      "&:hover": {
        backgroundColor: "#008D9C",
      }
    },
    progressBarRoot: {
        height: "8px",
        borderRadius: "0 0 10px 10px",
    },
    progressBarPrimaryColor: {
      backgroundColor: "#fff",
    },
    redProgressBar: {
      backgroundColor: "#FF625C",
    },
    yellowProgressBar: {
      backgroundColor: "#DFB118",
    },
    greenProgressBar: {
      backgroundColor: "#7CD74B",
    },
  })
);

/*
interface IProps extends TPropsFromRedux {
  open: boolean;
  setOpen: any;
  resource: any;
  title: string;
  subtitle: string;
  timeByWhichCollectionTimeShouldBeExtended: number;
  onTimeReachesZero: () => any;
}
*/

export const SimpleModal = (props: any) => {
  const classes = useStyles();
    const { open, setOpen, title, subtitle, btnClickHandler } = props;
  const [progress, setProgress] = useState(100);
  useEffect(() => {
      const timer = setInterval(() => {
          setProgress((prevProgress) => { 
          if (prevProgress === 0) {
            props.onTimeReachesZero();
              setOpen(false)
          }
          return prevProgress - 1 > 0 ? prevProgress - 1 : 0
      });
    }, 70);
    return () => {
      clearInterval(timer);
    };
  }, []);

  return (
    <Modal
      open={open}
      aria-labelledby={title + " Modal"}
      aria-describedby="transition-modal-description"
      className={classes.modal}
      onClose={() => setOpen(false)}
      closeAfterTransition
      BackdropComponent={Backdrop}
      BackdropProps={{
        timeout: 500,
      }}
      disableBackdropClick
    >
      <div className={classes.container}>
        <Fade in={true}>
          <div className={classes.paper}>
            <div
              className={classes.closeBtn}
              onClick={() => {
                setOpen(false);
              }}
            >
              <Cancel style={{ fontSize: "40px", padding: "4px" }} />
            </div>
            <div className={classes.titleContainer}>
              <span className={classes.title}>{title}</span>
              <span>{subtitle}</span>
            </div>
            <div className={classes.row}>
                          <Button variant="contained" className={`${classes.addButton}`} onClick={() => btnClickHandler()}>
                {`Add ${props.timeByWhichCollectionTimeShouldBeExtended}s`}
              </Button>
            </div>
          </div>
        </Fade>
        <LinearProgress
          variant="determinate"
          value={progress}
          classes={{ 
              root: classes.progressBarRoot,
              colorPrimary: classes.progressBarPrimaryColor,
              barColorPrimary: progress < 60 && progress > 30 ? classes.yellowProgressBar : progress < 30 ? classes.redProgressBar : classes.greenProgressBar
          }}
        />
      </div>
    </Modal>
  );
};
const mapProps = (state: IReduxState) => {
  return {
    selectedResourceId:
      state.coPilotDetailPage.coPilotResourceIdSelectedForSeeingDetails,
    resourcelist:
      state.coPilotDetailPage.coPilotResourcesState.coPilotResources,
  };
};
const connector = connect(mapProps, { getResources, updateIsDataFetching });
export type TPropsFromRedux = ConnectedProps<typeof connector>;

import { CircularProgress } from "@material-ui/core"
import DropdownWithRadio from "../../../../../../../common/components/form/dropdown-with-radio"
import { useContext, useEffect, useState } from "react"
import { IModel, IProject } from "../../../../../../../common"
import { ModelService, ProjectService } from "../../../../../../../services"
import { IDataCartProjectsState, IDataCartTasksState } from "../../../../projects/features/models/browseCollectionsPopupContext/browseCollectionsPopupContextComponent"
import './ModelSpecifications.css'
import ChatContext from "../../../../chat/contexts/chat.context"
import PurposeModelService from "../../../../../../../services/PurposeModelService"
import { IGetModelCollectionResponse } from "../../../../projects/features/aiMarketPlace/aiMarketPlaceInterfaces/interfaces"

interface IProps {
	trainingPodDetails: IGetModelCollectionResponse
}

function ModelSpecifications(props: IProps) {

    const debouncePodName = ()=>{
        let timeoutId : NodeJS.Timeout
        return (name: string)=>{
            clearTimeout(timeoutId);
            timeoutId = setTimeout(async()=>{
                if(!name || !name.trim()) return;
                try {
                    const data = await PurposeModelService.checkUniqueModelName({modelName: name.trim()})
                    if(data.unique) setModelCloningStage('ready')
                    else {
                        setpodNameError("A pod with same name exists")
                        setModelCloningStage('initial')
                    }
                } catch (error) {
                    console.log(error)
                }
            }, 500)
        }
    }

    const chatContext = useContext(ChatContext);
    const {modelCloningStage, setModelCloningStage} = chatContext
    const {workModePageStack, setClonedPodDetails} = chatContext
    const currentPageData = workModePageStack[workModePageStack.length-1]
    const trainingPodId = currentPageData.aiResponseMetaData?.trainingPods?.[0].trainingPodId || ""

	const [projectsState, setProjectsState] = useState<IDataCartProjectsState>({
        availableProjects: [],
        isFetchingProjects: false,
        selectedProject: null,
        searchTerm: "",
    })
    const [tasksState, setTasksState] = useState<IDataCartTasksState>({
        availableTasks: [], 
        isFetchingTasks: false,
        selectedTask: null,
        searchTerm: "",
    })
    const [podName, setPodName] = useState('')
    const [podNameError, setpodNameError] = useState('')
    const [checkPodNameUniqueness, _] = useState(()=>debouncePodName())
    const [isPodCloned, setIsPodCloned] = useState(false)

    const handlePodNameChange = (e: any)=>{
        const name = e.target.value
        setPodName(name)
        setpodNameError('')
        setModelCloningStage('initial')
        checkPodNameUniqueness(name)
    }

	const setProjectSearchTerm = (value : string) => {
		setProjectsState(prev => ({...prev, searchTerm: value}))
	}

	const setTasksSearchTerm = (value : string) => {
		setTasksState(prev => ({...prev, searchTerm: value}))
	}

    const clonePod = async()=>{
        if(!podName || !tasksState.selectedTask?._id || !projectsState.selectedProject?._id || !trainingPodId) return;
        try {
            setIsPodCloned(true)

            const modelId = tasksState.selectedTask._id
            const projectId = projectsState.selectedProject._id
            const apiResponses = await Promise.all([PurposeModelService.cloneTrainingPod({
                clonedPurposeModelName: podName,
                cloneInTaskId: tasksState.selectedTask._id,
                cloneInProjectId: projectsState.selectedProject._id,
                purposeModelId: trainingPodId
            }),
            ProjectService.getProjectDetails(projectId),
            ModelService.getModelDetails({modelId})])

            const purposeModelId = apiResponses[0].data.data.clonedPurposeModel._id
            const {data : {name: projectName}} = apiResponses[1]
            const {data : {name: modelName}} = apiResponses[2]
            setClonedPodDetails({
                modelName,
                projectName,
                purposeModelName: podName,
                purposeModelType: props.trainingPodDetails?.purposeModel?.purposeType,
                modelId,
                projectId,
                purposeModelId: purposeModelId
            })

            setModelCloningStage('done')
        } catch (error : any) {
            setModelCloningStage('initial')
            setIsPodCloned(false)
            console.error(error?.response?.message || error?.message || error)
        }
    }

    modelCloningStage==='processing' && !isPodCloned && clonePod()

	const fetchAllTasks = async(projectId : string)=>{
		try {
			setTasksState(prev=>({...prev, isFetchingTasks : true}))
			const apiResponse = await ModelService.getProjectModels({
                project: projectId,
                limit: '1000'
            })
            if(apiResponse.data){
                setTasksState(prev => ({...prev, availableTasks: apiResponse.data.models}))
            }
		} catch (error) {
			console.error(error)
		} finally {
			setTasksState(prev=>({...prev, isFetchingTasks : false}))
		}
	}

	useEffect(() => {
	  	const fetchAllProjects = async()=>{
			try {
				setProjectsState(prev=>({...prev, isFetchingProjects : true}))
				const apiResponse = await ProjectService.getProjects({limit: '1000'})
				setProjectsState(prev=>({...prev, availableProjects : apiResponse.data.projects}))
			} catch (error) {
				console.error(error)
			} finally {
				setProjectsState(prev=>({...prev, isFetchingProjects : false}))
			}
	  	}
		fetchAllProjects()
	}, [])
	
	const handleProjectChange = (event: React.ChangeEvent<{ value: unknown }>) => {
        const currSelectedProject = event.target.value as IProject;
        setProjectsState(prev=>({...prev, selectedProject : currSelectedProject}));
        if (currSelectedProject._id !== projectsState.selectedProject?._id) {
            setTasksState({
				availableTasks: [], 
				isFetchingTasks: false,
				selectedTask: null,
				searchTerm: "",
			})
			fetchAllTasks(currSelectedProject._id)
        }
    };

	const handleTaskChange = (e : any) => {
		const currSelectedTask = e.target.value as IModel;
        setTasksState(prev=>({...prev, selectedTask : currSelectedTask}));
	}

	return (
        <div style={{ gap: '3rem', alignItems: 'center', justifyContent: 'center', height: '100%', display: 'flex', flexDirection: 'column', pointerEvents: isPodCloned ? 'none' : 'auto' }}>
            <div style={{ gap: '1rem', width: '80%', maxWidth: '600px', alignItems: 'center', display: 'flex' }} >
                <div style={{ width: '90%', display: 'flex', flexDirection: 'column', gap: '5px' }}>
					<div className="dropdown__title">Please select the project where you want to clone this training pod</div>
                    <DropdownWithRadio
                        searchBarVisible
                        searchTerm={projectsState.searchTerm}
                        setSearchTerm={setProjectSearchTerm}
                        items={projectsState.availableProjects}
                        valueKey='_id'
                        displayNameKey='name'
                        selectedValue={projectsState.selectedProject}
                        isItemsLoading={projectsState.isFetchingProjects}
                        onChange={handleProjectChange}
                        disabled={projectsState.isFetchingProjects}
                    />
					
                </div>
                {projectsState.isFetchingProjects ? <CircularProgress color="primary" size="20px" style={{ marginTop: '1.6rem' }} /> : null}
            </div>

            <div style={{ gap: '1rem', width: '80%', maxWidth: '600px', display: 'flex', alignItems: 'center' }} >
				<div style={{ width: '90%', display: 'flex', flexDirection: 'column', gap: '5px' }}>
					<div className="dropdown__title" style={{opacity : projectsState.selectedProject ? '100%' : '50%'}}>Please select the task where you want to clone this training pod</div>
                    <DropdownWithRadio
                        items={tasksState.availableTasks}
                        selectedValue={tasksState.selectedTask}
                        isItemsLoading={tasksState.isFetchingTasks}
                        valueKey='_id'
                        displayNameKey='name'
                        onChange={handleTaskChange}
                        searchBarVisible
                        setSearchTerm={setTasksSearchTerm}
                        searchTerm={tasksState.searchTerm}
                        disabled={tasksState.isFetchingTasks ? true : projectsState.selectedProject ? false : true}
                    />
                </div>
                {tasksState.isFetchingTasks ? <CircularProgress color="primary" size="20px" style={{ marginTop: '1.6rem' }} /> : null}
            </div>

            <div style={{ gap: '1rem', width: '80%', maxWidth: '600px', display: 'flex', alignItems: 'center' }} >
				<div style={{ width: '90%', display: 'flex', flexDirection: 'column', gap: '5px' }}>
					<div className="dropdown__title" style={{opacity : tasksState.selectedTask ? '100%' : '50%'}}>Please enter cloned training pod name</div>
                    <input type="text" className="input__label" value={podName} onChange={handlePodNameChange} style={{borderColor: tasksState.selectedTask ? 'gray' : 'var(--Gray-300, #D0D5DD)'}} />
                    {podNameError ? <span style={{fontSize: '0.8rem', color: 'red'}}>{podNameError}</span> : null}
                </div>
            </div>
        </div>
    )
}

export default ModelSpecifications
export const deleteDataSetCollectionButtonTooltip = "Delete the collection";
export const trainDataSetCollectionButtonTooltip = "Train the collection";
export const reTrainDataSetCollectionButtonTooltip = "Re-train the collection";
export const ModelCollectionStartCheckpointUndefinedValue="None"
export const AccuracyStatuses: any = {
    preparingRequest: "Preparing Request",
    queued: "Re-Testing",
    failed: "Failed"
};
export const SNOOZE_TIME_FOR_MODEL_COLLECTION_QNA_NOTIFICATION = 10 * 60 * 1000; // in ms
export const MODEL_TRAINED_NOTIFICATION_API_POLLING_TIME_INTERVAL = 10000;     // in ms 
export const MODEL_TRAINED_QNA_NOTIFICATION_API_POLLING_TIME_INTERVAL = 10000; // in ms
export const MODEL_TRAINED_QNA_NOTIFICATION_DEFAULT_QNA = [
    {
        question: "What real-world scenarios was the model failed on?",
        answer: "",
    },
    {
        question: "What is your action plan to improve your model's performance?",
        answer: "",
    },
] 
export const TTL_FOR_ACCURACY_VALUE_IN_LOCALSTORAGE = 24*60*60*1000;    //ms
import {
  Collapse,
  List,
  ListItem,
  makeStyles,
  Menu,
  MenuItem,
  MenuList,
} from "@material-ui/core";
import ListItemText from "@material-ui/core/ListItemText";
import { ExpandLess, ExpandMore } from "@material-ui/icons";
import { Edge } from "@reactflow/core";
import { useState } from "react";

type Props = {
  mouseX: number;
  mouseY: number;
  onClose: () => any;
  onDeleteEdgeClick: () => any;
  onEdgeTypeSelected: (edgeTypeSelected: string, edgeId: string) => any;
  edge: Edge;
};

const useStyles = makeStyles((theme) => ({
  nested: {
    paddingLeft: theme.spacing(4),
  },
}));

export default function EdgeContextMenu(props: Props) {
  const mouseX = props.mouseX;
  const mouseY = props.mouseY;

  const classes = useStyles();

  const [isEdgeTypeMenuOpen, setIsEdgeTypeMenuOpen] = useState(false);

  return (
    <Menu
      keepMounted
      open={mouseY !== null}
      onClose={props.onClose}
      anchorReference="anchorPosition"
      anchorPosition={
        mouseY !== null && mouseX !== null
          ? { top: mouseY, left: mouseX }
          : undefined
      }
      //   PaperProps={{
      //     style: {
      //       maxHeight: 200,
      //     },
      //   }}
    >
      <List dense={true}>
        <ListItem button onClick={props.onDeleteEdgeClick}>
          <ListItemText primary="Delete edge" />
        </ListItem>
        {/* <ListItem */}
        {/* <ListItem
        //   button
        //   onClick={() => {
        //     setIsEdgeTypeMenuOpen((old) => !old);
        //   }}
        // >
        //   <ListItemText primary="Set edge type" />
        //   {isEdgeTypeMenuOpen ? <ExpandLess /> : <ExpandMore />}
        // </ListItem> */ }
        <Collapse in={isEdgeTypeMenuOpen} timeout="auto" unmountOnExit>
          <List component="div" disablePadding dense={true}>
            <ListItem
              button
              className={classes.nested}
              selected={props.edge.type === "default" ? true : false}
              onClick={() => props.onEdgeTypeSelected("default", props.edge.id)}
            >
              <ListItemText primary="default" />
            </ListItem>
            <ListItem
              button
              className={classes.nested}
              selected={props.edge.type === "straight" ? true : false}
              onClick={() =>
                props.onEdgeTypeSelected("straight", props.edge.id)
              }
            >
              <ListItemText primary="straight" />
            </ListItem>
            <ListItem
              button
              className={classes.nested}
              selected={props.edge.type === "step" ? true : false}
              onClick={() => props.onEdgeTypeSelected("step", props.edge.id)}
            >
              <ListItemText primary="step" />
            </ListItem>
            <ListItem
              button
              className={classes.nested}
              selected={props.edge.type === "smoothstep" ? true : false}
              onClick={() =>
                props.onEdgeTypeSelected("smoothstep", props.edge.id)
              }
            >
              <ListItemText primary="smoothstep" />
            </ListItem>
            <ListItem
              button
              className={classes.nested}
              selected={props.edge.type === "simplebezier" ? true : false}
              onClick={() =>
                props.onEdgeTypeSelected("simplebezier", props.edge.id)
              }
            >
              <ListItemText primary="simplebezier" />
            </ListItem>
          </List>
        </Collapse>
      </List>
      {/* <MenuItem onClick={props.onDeleteEdgeClick}>Delete edge</MenuItem>
        <MenuList>
        <MenuItem onClick={props.onDeleteEdgeClick}>Delete edge 1</MenuItem>
        <MenuItem onClick={props.onDeleteEdgeClick}>Delete edge 2</MenuItem>

        </MenuList> */}
    </Menu>
  );
}

import { useEffect, useRef, useState } from "react";
import {
  Box,
  Chip,
  CircularProgress,
  createStyles,
  FormControl,
  FormControlLabel,
  Checkbox,
  Grid,
  InputAdornment,
  InputBase,
  makeStyles,
  MenuItem,
  TextField,
  Theme,
  Tooltip,
  Typography,
  withStyles,
} from "@material-ui/core";
import DeleteIcon from "@material-ui/icons/Delete";
import AddCircleIcon from "@material-ui/icons/AddCircle";
import { useForm } from "react-hook-form";
import {
  IAddCollectionPayload,
  Collection,
  CustomButton,
  IReduxState,
  IModelCollection,
  TestModelCollectionPayload,
  ITestCollectionRequestPayload,
  ICollection,
} from "../../../../../../../../../../../common";
import {
  addCollection,
  addCollectionPageInit,
  getTags,
  setShouldFetchModelCollections,
  setSideDrawer,
} from "../../../../../../../../../../../store/actions";
import { connect, ConnectedProps } from "react-redux";
import {
  DataService,
} from "../../../../../../../../../../../services";
import { useURLQuery } from "../../../../../../../../../../../hooks/useURLQuery";
import { isEmpty } from "../../../../../../../../../../../services/inputFieldHelperService";
import { Autocomplete } from "@material-ui/lab";
import { InputFieldState } from "../../../../../../../../../../../common/constants/interfaces/inputFieldState";
import {
  setTrainingDataDirectoriesSelectedStateForAddModelCollection,
} from "../../../../../../../../../../../store/actions/collectionActions";
import { trainingDataDirectoriesSelectedStateForAddModelCollectionInitialValue } from "../../../../../../../../../../../store/reducers/collection";
import Analytics from "../../../../../../../components/Analytics";
import { deleteButtonIconColor } from "../../data/constants/constants";
import { convertVersionInMajorMinorPatchVersionFormat, decrementVersionBy0Point0Point1, incrementVersionBy0Point0Point1, isFirstParamVersionLessThanSecondParamVersion, isValidMajorMinorPatchVersion } from '../../../../../../../../../../../services/versionHelperService';

/**
 * @description
 * addModelCollection scenario means dataSet Collection(s) has already been trained, now just registering it
 * to database
 * trainingDataSetCollections scenario means dataSetCollection(s) about to be trained
 * reTrainingDataSetCollections scenario means dataSetCollection(s) about to be re-trained
 */
export type AddModelCollectionScenario =
  | "addModelCollection"
  | "trainingDataSetCollections"
  | "reTrainingDataSetCollections"
  | "testModelCollections";


const useStyles = makeStyles((theme) => ({
  root: {
    // marginTop: "auto",
    // marginBottom: "auto",
    padding: 25,
    height: "100%",
  },
  illustration: {
    // width: "150px",
    height: "150px",
    width: "100%",
    textAlign: "center",
  },
  form: {
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    padding: 5,
    height: "100%",
  },
  formControl: {
    // minWidth: 250,
    // maxWidth: 300,
    width: "100%",
    "& legend": {
      float: "unset",
    },
  },
  floating: {
    "& legend": {
      float: "unset",
    },
  },
  formControlContainer: {
    width: "100%",
  },
  center: {
    justifyContent: "center",
  },
  inputSection: {
    marginBottom: "20px",
  },
  multipleInputFieldSection: {
    backgroundColor: "#eff4fb",
    padding: "10px",
    borderRadius: "6px",
  },
  inputSectionTitle: {
    fontWeight: "bold",
  },
  selectTextFieldInput: {
    maxWidth: "248px",
  },
  chip: {
    margin: theme.spacing(0.5),
    maxWidth: "30px",
  },
  autoCompleteChipRoot: {
    maxWidth: "170px",
  },
  visibilityHidden: {
    visibility: "hidden",
  },
  numberInputWithoutArrows: {
    "&[type=number]": {
      "-moz-appearance": "textfield",
      appearance: "textfield",
      "-webkit-appearance": "textfield",
    },
    "&[type=number]::-webkit-inner-spin-button, &[type=number]::-webkit-outer-spin-button":
      {
        "-webkit-appearance": "none",
        margin: 0,
      },
  },
  disabledBtn: {
    color: 'lightgrey',
    cursor: 'not-allowed !important'
  }
}));

const BootstrapInput = withStyles((theme: Theme) =>
  createStyles({
    root: {
      "label + &": {
        marginTop: theme.spacing(1),
      },
    },
    input: {
      position: "relative",
      border: "1px solid #ced4da",
      fontSize: 14,
      padding: "10px 26px 10px 12px",
      transition: theme.transitions.create(["border-color", "box-shadow"]),
      "&:focus": {
        borderRadius: 4,
        borderColor: "#80bdff",
        boxShadow: "0 0 0 0.2rem rgba(0,123,255,.25)",
      },
    },
  })
)(InputBase);


type NumberInputStateValue = number | undefined;

type NumberInputFieldEndAdormentProps = {
  setStateFunction: React.Dispatch<
    React.SetStateAction<InputFieldState<any>>
  >;
};
function NumberInputFieldEndAdorment(props: NumberInputFieldEndAdormentProps) {
  const { setStateFunction } = props;

  function handleNumberInputIncrementDecrementClick(
    setStateFunction: React.Dispatch<
      React.SetStateAction<InputFieldState<NumberInputStateValue>>
    >,
    type: "increment" | "decrement"
  ) {
    setStateFunction(
      (
        oldState: InputFieldState<any>
      ): InputFieldState<any> => {
        let oldValue = oldState.value;
        if (oldValue === undefined) {
          // return { ...oldState };
          oldValue = "0.0.1";
        }
        return {
          ...oldState,
          isDirty: true,
          isTouched: true,
          value:
            // Number(oldValue) +
            // (type === "increment" ? 1 : type === "decrement" ? -1 : 0),
            type === "increment"
            ? incrementVersionBy0Point0Point1(oldValue)
            : type === "decrement"
              ? decrementVersionBy0Point0Point1(oldValue)
              : ""            
        };
      }
    );
  }

  return (
    <InputAdornment
      style={{ cursor: "pointer", height: "100%" }}
      position="end"
    >
      <span
        style={{
          height: "100%",
          width: "22px",
          textAlign: "center",
          cursor: "pointer",
        }}
        onClick={() =>
          handleNumberInputIncrementDecrementClick(
            setStateFunction,
            "decrement"
          )
        }
      >
        -
      </span>
      <span
        style={{
          height: "100%",
          width: "22px",
          textAlign: "center",
          cursor: "pointer",
        }}
        onClick={() =>
          handleNumberInputIncrementDecrementClick(
            setStateFunction,
            "increment"
          )
        }
      >
        +
      </span>
    </InputAdornment>
  );
}

const AddTestCollection = (props: Props) => {
  const {
    setShouldFetchModelCollections,
    trainingDataDirectoryState,
    setTrainingDataDirectoryState,
    shouldFetchModelCollections,
    setSideDrawer,
    selectedModelCollection
  } = props;

  useEffect(() => {
    return () => {
      setTrainingDataDirectoryState({
        ...trainingDataDirectoriesSelectedStateForAddModelCollectionInitialValue,
      });
    };
  }, []);

  const urlQuery = useURLQuery();
  const modelId: string = urlQuery.get("model") as string;

  const [
    isAvailableTrainingDataDirectoriesFetchedAtleastOnce,
    setIsAvailableTrainingDataDirectoriesFetchedAtleastOnce,
  ] = useState<boolean>(false);

  const scenario: AddModelCollectionScenario = props.scenario;
  const {
    formState: { errors },
  } = useForm<IAddCollectionPayload>();
  const classes = useStyles();


  const testModelStartCheckPointRef = useRef<null | any>(null);
  const [testModelCollectionsHavingDefaultCheckpoints, setTestModelCollectionsHavingDefaultCheckpoints] = useState<IModelCollection[]>([]);

  const [startCheckpointsForTests, setStartCheckpointsForTests] = useState<TestModelCollectionPayload[]>([])

  useEffect(() => {
    const selectedModelCollectionsWithFiles = selectedModelCollection.filter(selectedCollection => selectedCollection.checkpointFiles.length > 0)
    setTestModelCollectionsHavingDefaultCheckpoints((oldState) => {
      selectedModelCollectionsWithFiles.forEach(selectedCollection => {
        const isOldStateHaveSameModelCollection = oldState.some(modelCollection => modelCollection._id === selectedCollection._id)
        if (!isOldStateHaveSameModelCollection) {
          oldState.push(selectedCollection)
        }
      })
      return [...oldState]
    })
    const defaultCheckPointsSelected = selectedModelCollectionsWithFiles.map(selectedCollection => {
      const files = selectedCollection.checkpointFiles.filter(file => file.isDefaultCheckpoint === true)
      return { modelCollection: selectedCollection._id, modelCollectionCheckpointFile: files[0]._id }
    })
    if (defaultCheckPointsSelected.length) {
      setStartCheckpointsForTests([...defaultCheckPointsSelected])
    }
   }, [selectedModelCollection])

  /**
   * Whenever user selects a model collection in startCheckpoint section,
   * This effect is used to set default checkpoint value corresponding that model collection
   */
  useEffect(() => {
    if (testModelCollectionsHavingDefaultCheckpoints?.length > 0 && startCheckpointsForTests.length > 0) {
      let testModelCollectionForStartCheckpoint: IModelCollection = null as unknown as IModelCollection;

      for (const testModelCollection of testModelCollectionsHavingDefaultCheckpoints) {
        if (testModelCollection._id === startCheckpointsForTests[startCheckpointsForTests.length - 1].modelCollection) {
          testModelCollectionForStartCheckpoint = testModelCollection;
          break;
        }
      }

      if (testModelCollectionForStartCheckpoint?.checkpointFiles?.length > 0) {
        for (const testModelCollectionFile of testModelCollectionForStartCheckpoint?.checkpointFiles) {
          if (testModelCollectionFile.isDefaultCheckpoint) {
            setStartCheckpointsForTests(prevState => {
              prevState[prevState.length - 1] = {
                ...prevState[prevState.length - 1],
                modelCollectionCheckpointFile: testModelCollectionFile._id
              }
              return [...prevState]
            })
            break;
          }
        }
      }
    }
  }, [startCheckpointsForTests[startCheckpointsForTests.length - 1]?.modelCollection])

  /**
   * This effect is used to fetch model-collections which have files in their files section
   */
  useEffect(() => {
    const executeFunction = async () => {
      const apiResponse = await DataService.getModelCollectionsHavingFiles(
        { modelId: modelId }
      );
      setTestModelCollectionsHavingDefaultCheckpoints([...apiResponse.data])
    }
    if (modelId) {
      executeFunction();
    }
  }, [modelId, shouldFetchModelCollections])

  const [
    isGetDataSetCollectionUniqueNamesApiExecuting,
    setIsGetDataSetCollectionUniqueNamesApiExecuting,
  ] = useState<boolean>(false);
  const [
    trainingDataDirectoriesAvailable,
    setTrainingDataDirectoriesAvailable,
  ] = useState<ICollection[]>([]);

  useEffect(() => {
    const getDataSetCollections = async () => {
      try {
        setIsGetDataSetCollectionUniqueNamesApiExecuting(true);
        const apiResponse = await DataService.getCollections({
          model: modelId,
          projectionQuery: JSON.stringify({ name: 1 }),
          offset: "-1",
          limit: '-1'
        });
        setTrainingDataDirectoriesAvailable([...apiResponse.data.collections]);
      } catch (error) {}
      setIsAvailableTrainingDataDirectoriesFetchedAtleastOnce(true);
      setIsGetDataSetCollectionUniqueNamesApiExecuting(false);
    };
    if (modelId) {
      getDataSetCollections();
    }
  }, [modelId]);
            
  // const testModelVersions = testModelVersionsState.value;
  const [dataSetCollectionsFieldState, setDataSetCollectionsFieldState] = useState<InputFieldState<string[]>>({ value: [] })

  useEffect(() => {
    if (dataSetCollectionsFieldState.value.length === 0) {
      setDataSetCollectionsFieldState({
        ...dataSetCollectionsFieldState,
        errorMessage: "Training DataSet Collections Required",
      });
    } else {
      setDataSetCollectionsFieldState({
        ...dataSetCollectionsFieldState,
        errorMessage: "",
      });
    }
  }, [dataSetCollectionsFieldState.value])

  /**
   * Update with Model Version actions
   */
  // useEffect(() => {
  //   if (testModelVersions.length === 0) {
  //     setTestModelVersionState({
  //       ...testModelVersionsState,
  //       errorMessage: "Test Model Versions Required",
  //     });
  //   }
  //   else if (highestModelVersionState && testModelVersions && (Number(testModelVersions) < highestModelVersionState)) {
  //     setTestModelVersionState({
  //       ...testModelVersionsState,
  //       errorMessage: "Test Model Versions can't be less than "+highestModelVersionState,
  //     });
  //   }
  //    else {
  //     setTestModelVersionState({
  //       ...testModelVersionsState,
  //       errorMessage: "",
  //     });
  //   }
  // }, [testModelVersions]);

  /**
   * This effect is used for below reasons:
   * -  to not allow trainingDataDirectories which are not part of trainingDataDirectoriesAvailable
   *    in trainingDataDirectories input field
   */
  useEffect(() => {
    if (
      dataSetCollectionsFieldState.value?.length > 0 &&
      isAvailableTrainingDataDirectoriesFetchedAtleastOnce &&
      scenario !== "addModelCollection"
    ) {
      let trainingDataDirectoriesValueToUpdate: boolean = false;
      let oldtrainingDataDirectories = dataSetCollectionsFieldState.value;
      for (let index = 0; index < oldtrainingDataDirectories.length; index++) {
        const trainingDataDirectory = oldtrainingDataDirectories[index];
        if (
          trainingDataDirectoriesAvailable.find(dataDirectory => dataDirectory._id === trainingDataDirectory) === undefined
        ) {
          trainingDataDirectoriesValueToUpdate = true;
          oldtrainingDataDirectories.splice(index, 1);
          index = index - 1;
        }
      }
      if (trainingDataDirectoriesValueToUpdate) {
        setDataSetCollectionsFieldState({
          ...dataSetCollectionsFieldState,
          value: [...oldtrainingDataDirectories],
        });
      }
    }
  }, [
    dataSetCollectionsFieldState.value,
    trainingDataDirectoriesAvailable,
    isAvailableTrainingDataDirectoriesFetchedAtleastOnce,
    scenario,
  ]);

  // useEffect(() => {
  //   if (
  //     testModelVersions?.length > 1 &&
  //     isAvailableTestModelVersionsFetchedAtleastOnce
  //   ) {
  //     let testModelVersionsValueToUpdate: boolean = false;
  //     let oldTestModelVersions = testModelVersions;
  //     for (let index = 0; index < oldTestModelVersions.length; index++) {
  //       const testModels = oldTestModelVersions[index];
  //       if (
  //         testModelDataVersionsAvailable.indexOf(testModels) === -1 &&
  //         parseInt(testModels) === modelVersionState.value
  //       ) {
  //         testModelVersionsValueToUpdate = true;
  //         oldTestModelVersions.splice(index, 1);
  //         index = index - 1;
  //       }
  //     }
  //     if (testModelVersionsValueToUpdate) {
  //       const availableData = testModelVersions.length ? testModelVersions : [];
  //       setTestModelDataVersionsAvailable(availableData);
  //     }
  //   }
  // }, [
  //   testModelVersions,
  //   testModelDataVersionsAvailable,
  //   isAvailableTestModelVersionsFetchedAtleastOnce,
  // ]);

  const [modelVersionState, setModelVersionState] = useState<
    InputFieldState<string | undefined>
  >({
    value: undefined,
    isDirty: false,
    isTouched: false,
  });

  const [highestModelVersionState, setHighestModelVersionState] = useState<string>();

  useEffect(() => {
    // console.log(" Highest Model Version Value and Current Model Version Value are ::: ", highestModelVersionValue, "  :::  ", modelVersion, "  :::  ", modelVersionState.value)

    const modelVersion = modelVersionState.value;
    const highestModelVersionValue = highestModelVersionState;

    if (isEmpty(modelVersion as string)) {
      setModelVersionState((oldState) => {
        return { ...oldState, errorMessage: "Test version Required" };
      });
    }
    else if( modelVersion && highestModelVersionValue && isFirstParamVersionLessThanSecondParamVersion(modelVersion, highestModelVersionValue)){
      // console.log(" Highest Model Version Value and Current Model Version Value are ::: ", highestModelVersionValue, "  :::  ", modelVersion)
      setModelVersionState((oldState) => {
        return { ...oldState, errorMessage: "Test version can't be less than "+ highestModelVersionValue };
      });
    } else if (!isValidMajorMinorPatchVersion(modelVersion || "0.0.0")) {
      setModelVersionState((oldState) => {
        return { ...oldState, errorMessage: `Test version format is incorrect. It should be in following form: <MajorNo>.<MinorNo>.<PatchNo> where number value cannot be more than 255` };
      });      
    }
    else {
      setModelVersionState((oldState) => {
        return { ...oldState, errorMessage: "" };
      });
    }
  }, [modelVersionState.value, highestModelVersionState]);  

  const [isShowingFormErrorsToUser, setIsShowingFormErrorsToUser] =
    useState<boolean>(false);

  const isFormValid = (): boolean => {
    if (
      modelVersionState.errorMessage ||
      dataSetCollectionsFieldState.errorMessage ||
      dataSetCollectionsFieldState.value.length === 0 ||
      testDescriptionState.errorMessage
    ) {
      return false;
    }
    return true;
  };

  function makeAllFormFieldsTouched() {
    setModelVersionState((oldState) => {
      return { ...oldState, isTouched: true };
    });
    setTrainingDataDirectoryState({
      ...trainingDataDirectoryState,
      isTouched: true,
    });
    setTestDescriptionState((oldState) => {
      return { ...oldState, isTouched: true };
    });
  }

  useEffect(() => {
    if (isShowingFormErrorsToUser) {
      makeAllFormFieldsTouched();
    }
  }, [isShowingFormErrorsToUser]);

  const [testDescriptionState, setTestDescriptionState] = useState<InputFieldState<string>>({ value: "" });
  const testDescription = testDescriptionState.value;

  useEffect(() => {
    if (isEmpty(testDescription)) {
      setTestDescriptionState((oldState) => {
        return {
          ...oldState,
          errorMessage: "Model Description Required",
        };
      });
    } else {
      setTestDescriptionState((oldState) => {
        return { ...oldState, errorMessage: "" };
      });
    }
  }, [testDescription]);

  const [isAddTestCollectionApiExecuting, setIsAddTestCollectionApiExecuting] =
    useState<boolean>(false);

  /**
   * This effect is used to restrict mouse wheel scroll in input type number elements
   */
  useEffect(() => {
    const handleWheelEvent = function (event: any) {
      // @ts-ignore
      if (document?.activeElement?.type === "number") {
        // @ts-ignore
        document?.activeElement?.blur();
      }
    };
    document.addEventListener("wheel", handleWheelEvent);
    return () => {
      document.removeEventListener("wheel", handleWheelEvent);
    };
  }, []);

  /**
   * This effect is used to restrict 'e' on input type number elements
   */
  useEffect(() => {
    const handleKeyDownEvent = function (event: any) {
      // @ts-ignore
      if (document?.activeElement?.type === "number") {
        if (event?.key === "e") {
          event.preventDefault();
        }
      }
    };

    document.addEventListener("keydown", handleKeyDownEvent);
    return () => {
      document.removeEventListener("keydown", handleKeyDownEvent);
    };
  }, []);


  const handleTestStartCheckPointClick = () => {
    setStartCheckpointsForTests(prevState => {
      return [...prevState, {
        modelCollection: '',
        modelCollectionCheckpointFile: '',
      }]
    })
  };

  /**
   * This effect is used to set default value of model version input field
   * which will be biggestVersion number out of all model collections
   */
  useEffect(() => {
    const executeFunction = async () => {
      const apiResponse = await DataService.getBiggestVersionOutOfAllTestCollectionsFromDB(modelId);
      setModelVersionState((oldState) => {
        return {
          ...oldState,
          // value: Number((apiResponse.data + 0.01).toFixed(2)),
          value: incrementVersionBy0Point0Point1(String(apiResponse.data))
        };
      });
      setHighestModelVersionState(incrementVersionBy0Point0Point1(String(apiResponse.data)))
      // setTestModelVersionState({
      //   value: [(apiResponse.data + 0.01).toFixed(2).toString()],
      // });
    };
    if (modelId) {
      executeFunction();
    }
  }, [modelId]);

  const handleAddTestCollectionClick = async () => {
    if (dataSetCollectionsFieldState.value.length === 0) {
      setDataSetCollectionsFieldState((prevState) => {
        return {
          ...prevState,
          errorMessage: "Dataset Collection is required",
          isTouched: true
        }
      })
    }
    if (!isFormValid() || !isModelCollectionFormValid()) {
      setIsShowingFormErrorsToUser(true);
      return;
    }

    let requestPayload: ITestCollectionRequestPayload = {
      model: modelId,
      version: String(modelVersionState.value) || '',
      dataSetCollectionIds: dataSetCollectionsFieldState.value,
      modelCollections: startCheckpointsForTests,
      description: testDescription,
    };

    try {
      setIsAddTestCollectionApiExecuting(true);
      await DataService.addTestCollection(
        requestPayload
      );
      setShouldFetchModelCollections({ shouldFetchModelCollections: true });

      setSideDrawer({
        type: "projectAnalytics",
        component: Analytics,
        isOpen: false,
      });
    } catch (error: any) {
      alert(error.response.data);
    }
    setIsAddTestCollectionApiExecuting(false);
  };

  function handleTrainingDataDirectoryInputChangeEvent(eventValue: string) {
    if (eventValue.includes(",")) {
      const values: string[] = eventValue
        .split(",")
        .map((value) => value.trim())
        .filter((value: string) => {
          if (!value) {
            return false;
          }
          return true;
        });

      if (values.length > 0) {
        const oldValue = trainingDataDirectoryState.value;
        for (const value of values) {
          if (!oldValue.includes(value)) {
            oldValue.push(value);
          }
        }
        setDataSetCollectionsFieldState((oldState) => {
          return {
            ...oldState,
            isDirty: true,
            isTouched: true,
            value: [...oldValue]
          }
        })
      }
    }
  }

  const isModelCollectionFormValid = () => {
    if (startCheckpointsForTests.length === 0) {
      return true
    }
    const testCheckPointCount = startCheckpointsForTests.length;
    if (startCheckpointsForTests.length > 0 && startCheckpointsForTests[testCheckPointCount - 1].modelCollectionCheckpointFile !== '' && startCheckpointsForTests[testCheckPointCount - 1].modelCollection !== '' ) {
      return true;
    }
    return false;
  }

  const [showDefaultCheckpoints, setShowDefaultCheckpoints] = useState(true);

	const showDefaultChanged = (e: any) => {
		setShowDefaultCheckpoints(e.target.checked)
	}
  
  return (
    <Grid
      classes={{ root: classes.root }}
      container
      direction="column"
      alignItems="center"
      justify="center"
    >
      <form
        className={classes.form}
        onSubmit={(e) => {
          e.preventDefault();
          handleAddTestCollectionClick();
        }}
        noValidate
      >
        <Box style={{ maxHeight: "90%", overflow: "auto", overflowX: "clip" }}>
          <Collection className={classes.illustration} />
          <Box m={1.5} />
          <Typography
            component="h3"
            style={{ textAlign: "center" }}
            variant="h5"
          >
            {scenario === "testModelCollections"
              ? "Test Models & Collections"
              : ""}
          </Typography>
          <Box m={1.5} />
          {/* Start Checkpoint section */}
          <Box className={`${classes.inputSection} ${classes.multipleInputFieldSection}`}>
            <Box className={classes.inputSectionTitle}>Model Collections*</Box>
            {
              startCheckpointsForTests.map((versionCheckpoint, index) => {
                return (
                  <Box key={index} style={{ paddingLeft: 10, marginTop: 10 }}>
                    <Box style={{ alignSelf: "center", float: 'right' }}>
                      <DeleteIcon
                        style={{
                          color: deleteButtonIconColor,
                          cursor: "pointer",
                        }}
                        onClick={() => {
                          setStartCheckpointsForTests((oldState) => {
                            oldState.splice(index, 1);
                            return [...oldState];
                          });
                        }}
                      />
                    </Box>
                    <FormControl className={classes.formControl}>
                      <TextField
                        id="standard-select-currency"
                        select
                        required
                        ref={testModelStartCheckPointRef}
                        label="Select Model Version to test"
                        value={startCheckpointsForTests[index].modelCollection}
                        onChange={(event) => {
                          setStartCheckpointsForTests(prevState => {
                            prevState[index] = {
                              ...prevState[index],
                              modelCollection: event.target.value
                            }
                            return [...prevState]
                          })
                        }}
                        helperText="Please select model version of checkpoint file to test"
                      >
                        {testModelCollectionsHavingDefaultCheckpoints.map((option) => {
                          const isVersionSelected = startCheckpointsForTests.find(checkpoints => checkpoints.modelCollection === option._id)
                          return (
                          <MenuItem key={option._id} value={option._id} disabled={isVersionSelected !== undefined}>
                            {option.version}
                          </MenuItem>
                        )})}
                      </TextField>
                    </FormControl>
                    {
                      startCheckpointsForTests[index].modelCollection &&
                      <FormControl className={classes.formControl} style={{ marginTop: 17 }}>
                        <TextField
                          id="test-start-checkpoint"
                          select
                          required
                          label="Select Start Checkpoint"
                          value={startCheckpointsForTests[index].modelCollectionCheckpointFile}
                          InputProps={{
                            classes: { input: classes.selectTextFieldInput }
                          }}
                            onChange={(event) => {
                              setStartCheckpointsForTests(prevState => {
                                prevState[index] = {
                                  modelCollection: prevState[index].modelCollection,
                                  modelCollectionCheckpointFile: event.target.value
                                }
                                return [...prevState]
                              })
                          }}
                          helperText="Please select model collection's checkpoint file to select"
                        >
                          {
                            testModelCollectionsHavingDefaultCheckpoints
                                .filter(testModelCollection => {
                                  return testModelCollection._id === startCheckpointsForTests[index].modelCollection
                                })[0]
                              ?.checkpointFiles
                              .map((option) => {
                                if(!showDefaultCheckpoints || (showDefaultCheckpoints && option.isDefaultCheckpoint) || (startCheckpointsForTests[index].modelCollectionCheckpointFile === option._id)){
                                  return (
                                    <MenuItem key={option._id} value={option._id} >
                                      <Tooltip title={option.name}>
                                        <span>{option.name}</span>
                                      </Tooltip>
                                    </MenuItem>
                                  )
                                }
                              }
                              )
                          }
                        </TextField>
                      </FormControl>
                    }

                    {/* {
                      !isModelCollectionFormValid() &&
                      (
                        <Box style={{ color: "red" }}>Please Input correct value</Box>
                      )
                    } */}

                  </Box>
                )
              })
            }
            <div style={{ display: "flex" , justifyContent: "space-between", alignItems:"center"}}>
            <FormControlLabel style={{marginBottom:"0"}}
						control={
							<Checkbox 
							checked={showDefaultCheckpoints}
							onChange={showDefaultChanged}
						/>
						}
						label="Show only Default Checkpoints"
					/>
              <Tooltip title="Add Model">
                <AddCircleIcon
                  color="primary"
                  className={(!isModelCollectionFormValid() ? classes.disabledBtn : '')}
                  style={{ cursor: "pointer", }}
                  onClick={!isModelCollectionFormValid() ? () => { } : () => handleTestStartCheckPointClick()}
                />
              </Tooltip>
            </div>
          </Box>

          {/* Model Version Section */}
          <Box className={`${classes.formControlContainer}`}>
            <FormControl className={classes.formControl}>
              <TextField
                variant="outlined"
                size="small"
                margin="normal"
                required
                fullWidth
                label="Test Version"
                value={modelVersionState.value}
                InputLabelProps={{
                  shrink: modelVersionState.value === undefined ? false : true,
                }}
                InputProps={{
                  type: "text",
                  classes: {
                    input: `${classes.numberInputWithoutArrows}`,
                  },
                  endAdornment: (
                    <NumberInputFieldEndAdorment
                      setStateFunction={setModelVersionState}
                    />
                  ),
                }}
                onBlur={(e) => {
                  setModelVersionState((oldState) => {
                    return { ...oldState, isTouched: true, value: convertVersionInMajorMinorPatchVersionFormat(oldState.value || "0.0.0") };
                  });
                }}
                onChange={(e) => {
                  setModelVersionState((oldState) => {
                    return { ...oldState, isDirty: true };
                  });
                  // console.log(highestModelVersionState, Number(e.target.value), `${highestModelVersionState && highestModelVersionState < Number(e.target.value)}`);
                  if (
                    e.target.value === undefined ||
                    e.target.value === null ||
                    e.target.value === ""
                  ) {
                    setModelVersionState((oldState) => {
                      return { ...oldState, value: "", errorMessage: "Test Version is required", isTouched: true };
                    });
                  }
                  else if (
                    highestModelVersionState &&
                    // highestModelVersionState > Number(e.target.value) &&
                    isFirstParamVersionLessThanSecondParamVersion(e.target.value, highestModelVersionState)
                  ){
                    // console.log(highestModelVersionState, Number(e.target.value))
                    setModelVersionState((oldState) => {
                      return { ...oldState, value: e.target.value, errorMessage: "Test Version can't be less than "+highestModelVersionState, isTouched: true };
                    });
                  } else if (!isValidMajorMinorPatchVersion(e.target.value)) {
                    setModelVersionState((oldState) => {
                      return { ...oldState, value: e.target.value, errorMessage: "Test version format is incorrect. It should be in following form: <MajorNo>.<MinorNo>.<PatchNo> where number value cannot be more than 255", isTouched: true };
                    });
                  }
                  else {
                    setModelVersionState((oldState) => {
                      return { ...oldState, value: e.target.value, errorMessage: undefined };
                    });
                  }
                }}
                error={
                  modelVersionState.isTouched && modelVersionState.errorMessage
                    ? true
                    : false
                }
                helperText={
                  modelVersionState.isTouched
                    ? modelVersionState.errorMessage
                    : ""
                }
              />
            </FormControl>
          </Box>

          {/* Test DataSet Section */}
          <Box style={{ marginBottom: 12 }}>
            <Autocomplete
              multiple
              id="trainingDataDirectory"
              freeSolo
              options={trainingDataDirectoriesAvailable.map(val => val._id)}
              value={dataSetCollectionsFieldState.value}
              getOptionLabel={dataSetCollectionId => 
                trainingDataDirectoriesAvailable.filter(directory => directory._id  === dataSetCollectionId)[0].name
              }
              loading={
                isGetDataSetCollectionUniqueNamesApiExecuting ? true : false
              }
              loadingText={
                isGetDataSetCollectionUniqueNamesApiExecuting ? "loading" : ""
              }
              onChange={(event, value) => {
                setDataSetCollectionsFieldState((oldState) => {
                  return {
                    ...oldState,
                    isDirty: true,
                    isTouched: true,
                    value: [...value]
                  }
                })
                // setTrainingDataDirectoryState({
                //   ...trainingDataDirectoryState,
                //   isDirty: true,
                //   isTouched: true,
                //   value: [...(value as string[])],
                // });
              }}
              renderTags={(value, getTagProps) => {
                return value.map((option, index) => {
                  return <Chip
                    variant="outlined"
                    color="primary"
                    className={`${classes.chip}`}
                    classes={{ root: classes.autoCompleteChipRoot }}
                    label={
                      <Tooltip title={option}>
                        <span>{trainingDataDirectoriesAvailable.filter(dataDirectory => dataDirectory._id === option)[0]?.name || option}</span>
                      </Tooltip>
                    }
                    {...getTagProps({ index })}
                  />
                });
              }}
              renderInput={(params) => (
                <TextField
                  {...params}
                  variant="outlined"
                  size="small"
                  margin="normal"
                  fullWidth
                  required
                  className={classes.floating}
                  label="Dataset Collection"
                  placeholder="Add Dataset Collection"
                  onChange={(e) => {
                    handleTrainingDataDirectoryInputChangeEvent(e.target.value);
                  }}
                  onBlur={(e) => {
                    handleTrainingDataDirectoryInputChangeEvent(
                      e.target.value + ","
                    );
                  }}
                  error={
                    dataSetCollectionsFieldState.isTouched &&
                    dataSetCollectionsFieldState.errorMessage
                      ? true
                      : false
                  }
                  helperText={
                    dataSetCollectionsFieldState.isTouched &&
                    dataSetCollectionsFieldState.errorMessage
                      ? dataSetCollectionsFieldState.errorMessage
                      : "Add multiple training dataset collections with <enter> or <comma>"
                  }
                />
              )}
            />
          </Box>

          {/* Test Models Section */}
          {/* <Box style={{ marginBottom: 12 }}>
            <Autocomplete
              multiple
              id="testModelVersions"
              freeSolo
              options={testModelDataVersionsAvailable}
              value={testModelVersionsState.value}
              loading={isGetModelCollectionVersionssApiExecuting ? true : false}
              loadingText={
                isGetModelCollectionVersionssApiExecuting ? "loading" : ""
              }
              onChange={(event, value) => {
                setTestModelVersionState({
                  ...testModelVersionsState,
                  isDirty: true,
                  isTouched: true,
                  value: [...value],
                });
              }}
              renderTags={(value, getTagProps) => {
                return value.map((option, index) => (
                  <Chip
                    variant="outlined"
                    color="primary"
                    className={`${classes.chip}`}
                    classes={{ root: classes.autoCompleteChipRoot }}
                    label={
                      <Tooltip title={option}>
                        <span>{option}</span>
                      </Tooltip>
                    }
                    {...getTagProps({ index })}
                  />
                ));
              }}
              renderInput={(params) => (
                <TextField
                  {...params}
                  variant="outlined"
                  size="small"
                  margin="normal"
                  fullWidth
                  className={classes.floating}
                  label="Test Models Versions"
                  placeholder="Add Models Versions"
                  onChange={(e) => {
                    handleTestModelVersionsInputChangeEvent(e.target.value);
                  }}
                  onBlur={(e) => {
                    handleTestModelVersionsInputChangeEvent(
                      e.target.value + ","
                    );
                  }}
                  error={
                    testModelVersionsState.isTouched &&
                    testModelVersionsState.errorMessage
                      ? true
                      : false
                  }
                  helperText={
                    testModelVersionsState.isTouched &&
                    testModelVersionsState.errorMessage
                      ? testModelVersionsState.errorMessage
                      : "Add multiple test model versions with <enter> or <comma>"
                  }
                />
              )}
            />
          </Box> */}

          {/* Model Description Section */}
          <Box>
            <FormControl className={classes.formControl}>
              <TextField
                variant="outlined"
                size="small"
                margin="normal"
                required
                fullWidth
                label="Test Description"
                value={testDescription}
                inputProps={{ type: "text" }}
                onBlur={(e) => {
                  setTestDescriptionState((oldState) => {
                    return { ...oldState, isTouched: true };
                  });
                }}
                onChange={(e) =>
                  setTestDescriptionState((oldState) => {
                    return {
                      ...oldState,
                      isDirty: true,
                      value: e.target.value,
                    };
                  })
                }
                error={
                  testDescriptionState.isTouched &&
                  testDescriptionState.errorMessage
                    ? true
                    : false
                }
                helperText={
                  testDescriptionState.isTouched
                    ? testDescriptionState.errorMessage
                    : ""
                }
              />
            </FormControl>
          </Box>
        </Box>

        <Box style={{ maxHeight: "10%", paddingTop: "2vh" }}>
          <Box
            className={`${
              !(isShowingFormErrorsToUser && !isFormValid())
                ? classes.visibilityHidden
                : ""
            }`}
            style={{ color: "red", marginBottom: 8 }}
          >
            Please fill valid data in input fields
          </Box>
          <CustomButton
            style={{ width: "100%" }}
            type="submit"
            disabled={
              (isShowingFormErrorsToUser && !isFormValid()) ||
              isAddTestCollectionApiExecuting  || (startCheckpointsForTests.length === 0 || !isModelCollectionFormValid())
            }
          >
            {isAddTestCollectionApiExecuting ? (
              <CircularProgress size={20} />
            ) : (
              "Test Collection"
            )}
          </CustomButton>
        </Box>
      </form>
    </Grid>
  );
};

const mapProps = (state: IReduxState) => {
  return {
    tags: state.tag.tags,
    isTagsFetching: state.tag.isFetching,
    isAdding: state.collection.isAdding,
    isAddSuccess: state.collection.isAddSuccess,
    addErrorMessage: state.collection.addErrorMessage,
    trainingDataDirectoryState:
      state.collection.modelCollection
        .trainingDataDirectoriesSelectedStateForAddModelCollection,
    shouldFetchModelCollections:
      state.collection.modelCollection.shouldFetchModelCollections,
    testModelVersionsState: state.collection.modelCollection.biggestTestModelVersion,
  };
};

const connector = connect(mapProps, {
  addCollection,
  getTags,
  addCollectionPageInit,
  setShouldFetchModelCollections,
  setTrainingDataDirectoryState:
    setTrainingDataDirectoriesSelectedStateForAddModelCollection,
  setSideDrawer
});

export type TPropsFromRedux = ConnectedProps<typeof connector>;
interface Props extends TPropsFromRedux {
  scenario: AddModelCollectionScenario;
  selectedModelCollection: IModelCollection[]
}
export default connector(AddTestCollection);

import { CircularProgress, MenuItem, TextField, Checkbox, FormControlLabel } from "@material-ui/core";
import Button from "@material-ui/core/Button";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogTitle from "@material-ui/core/DialogTitle";
import { useCallback, useEffect, useState } from "react";
import { connect, ConnectedProps } from "react-redux";
import { IReduxState } from "../../../../../../../../../../../common/constants/interfaces/redux";
import {
	IModelCollection,
	ModelCollectionDeployApiRequestPayload,
} from "../../../../../../../../../../../common/constants/interfaces/collections";
import { DataService } from "../../../../../../../../../../../services";
import { generateUniqueId } from '../../../../../../../../../../../services/idHelperService';

interface IProps extends TPropsFromRedux {
	open: boolean;
	onClose: () => any;
	modelCollection: IModelCollection;
	fetchCollections: Function
}

type ModelFile = {
	fileName: string,
	parentCheckpointFileId: string,
	id: string
};

export function PerformModelCollectionDeploymentDialog(props: IProps) {
	const { open, modelCollection } = props;

	const [isDeploymentApiBeingCalled, setIsDeploymentApiBeingCalled] =
		useState(false);

	// const [checkpointFileIdSelectedByUser, setCheckpointFileIdSelectedByUser] =
	// 	useState("");

	const [modelDeploymentApiReqPayload, setModelDeploymentApiReqPayload] = useState<{
		modelCollectionId: string,
		modelCollectionParentCheckpointFileId: string,
		modelCollectionModelFileName: string,
		fileId?: string,
	}>({
		modelCollectionId: modelCollection._id.toString(),
		modelCollectionParentCheckpointFileId: "",
		modelCollectionModelFileName: "",
		fileId: ""
	})

	const handleDeployClick = async () => {
		delete modelDeploymentApiReqPayload.fileId
		const deployApiReqPayload: ModelCollectionDeployApiRequestPayload = {
			...modelDeploymentApiReqPayload,
			trainingDataSetCollection: {
				name: modelCollection.trainingDataDirectory[0]
			},
			testDataSetCollection: {
				name: modelCollection.defaultDataSetCollectionName || ""
			},
			modelCollectionDescription: modelCollection.modelDescription,
			architecture: {
				label: modelCollection.modelArchitecture
			}
		};

		setIsDeploymentApiBeingCalled(true);
		try {
			await DataService.callModelCollectionDeployApi(deployApiReqPayload);
			props.fetchCollections()
		} catch (error) {
			if (error?.response?.data) {
				alert(error.response.data);
			}
		}
		setIsDeploymentApiBeingCalled(false);
		props.onClose();
	};
	const [showDefaultCheckpoints, setShowDefaultCheckpoints] = useState(true);

	const showDefaultChanged = (e: any) => {
		setShowDefaultCheckpoints(e.target.checked)
	}

	const [modelFiles, setModelFiles] = useState<ModelFile[]>([]);

	useEffect(()=>{
			const modelFiles: ModelFile[] = [];
			for (let checkpointFileIndex = 0; checkpointFileIndex < modelCollection.checkpointFiles.length; checkpointFileIndex++) {
				const checkpointFile = modelCollection.checkpointFiles[checkpointFileIndex];
				if (showDefaultCheckpoints && !checkpointFile.isDefaultCheckpoint) {
					continue;
				}
				if (checkpointFile?.files?.models?.name?.length>0) {
					for (let modelFileIndex = 0; modelFileIndex < checkpointFile?.files?.models?.name.length; modelFileIndex++) {
						const modelFileName = checkpointFile?.files?.models?.name[modelFileIndex];
						modelFiles.push({
							fileName: modelFileName || "",
							parentCheckpointFileId: checkpointFile._id.toString(),
							id: generateUniqueId()
						})
					}
				}
			}

			setModelFiles(
				[...modelFiles]
			)
	}, [showDefaultCheckpoints, modelCollection.checkpointFiles])

	const getModelFileObject = useCallback(
		(modelFileId: string, modelFiles: ModelFile[]): ModelFile | null => {
			for (const modelFile of modelFiles) {
				if (modelFile.id.toString() === modelFileId.toString()) {
					return modelFile
				}
			}
			return null
		},
		[],
	)

	useEffect(() => {
		setModelDeploymentApiReqPayload(oldState=>({
			...oldState,
			fileId: "",
			modelCollectionModelFileName: "",
			modelCollectionParentCheckpointFileId: ""
		}))
	
		
	}, [showDefaultCheckpoints])	
	

	return (
		<div>
			<Dialog
				open={open}
				onClose={() => props.onClose()}
				maxWidth="sm"
				// fullScreen={true}
				fullWidth={true}
				aria-labelledby="responsive-dialog-title"
			>
				<DialogTitle id="responsive-dialog-title">Model Deployment</DialogTitle>
				<DialogContent>
					<TextField
						select
						label="Select Model File"
						fullWidth={true}
						disabled={isDeploymentApiBeingCalled ? true : false}
						value={modelDeploymentApiReqPayload.fileId}
						onChange={(event) => {
							const modelFileId = event.target.value;
							// setCheckpointFileIdSelectedByUser(value);

							const modelFile = getModelFileObject(modelFileId, modelFiles)

							setModelDeploymentApiReqPayload({
								modelCollectionId: modelCollection._id.toString(),
								modelCollectionModelFileName: modelFile?.fileName || "",
								modelCollectionParentCheckpointFileId: modelFile?.parentCheckpointFileId || "",
								fileId: modelFileId
							})
						}}
						style={{
							marginTop: "10px",
							marginBottom: "30px",
						}}
						// helperText="Please select annotation label"
					>
						{modelFiles.map((file, index) =>{
								return (
									<MenuItem key={index} value={file.id}>
										{file.fileName}
									</MenuItem>
								)
						})}
					</TextField>
					
					 <FormControlLabel
						control={
							<Checkbox
							checked={showDefaultCheckpoints}
							onChange={showDefaultChanged}
						/>
						}
						label="Show only Default Checkpoints"
					/>
				</DialogContent>
				<DialogActions style={{ padding: "16px 24px", paddingTop: "8px" }}>
					<Button size="small" onClick={() => props.onClose()}>
						Cancel
					</Button>
					<Button
						size="small"
						color="primary"
						variant="contained"
						disabled={
							!modelDeploymentApiReqPayload.modelCollectionModelFileName || isDeploymentApiBeingCalled
								? true
								: false
						}
						onClick={() => handleDeployClick()}
					>
						{isDeploymentApiBeingCalled ? <CircularProgress size={20} /> : "OK"}
					</Button>
				</DialogActions>
			</Dialog>
		</div>
	);
}

const mapProps = (state: IReduxState) => {
	return {};
};

const connector = connect(mapProps, {});

export type TPropsFromRedux = ConnectedProps<typeof connector>;

export default connector(PerformModelCollectionDeploymentDialog);
